<template>
  <section class="u-display-flex u-flex-direction-column output-screen">
    <div
      v-if="localLoading"
      class="loading-screen u-display-flex u-flex-align-items-center u-flex-justify-content-center"
    >
      <div
        class="u-width-100 u-display-flex u-flex-direction-column u-flex-align-items-center"
      >
        <img
          class="u-width-40"
          src="/images/media_plan_loading_summary.svg"
          alt="Media plan loading summary image"
        />
        <p
          class="u-font-size-5 u-color-grey-base u-font-weight-400 u-spacing-mt-l"
        >
          '{{ status.message }}...'
        </p>
        <div
          class="u-spacing-mt-m u-spacing-mb-s u-width-40 u-spacing-mt-m loader"
          :style="{
            '--percent': `${(status.index + 1) * 14.28}%`
          }"
        />
        <p class="u-spacing-mv-l u-font-size-5">
          <span v-if="maxTimeReached"
            >It is taking longer than expected, please check back after some
            time.</span
          >
          <span v-else
            >Estimated time to generate this plan is
            <span class="u-color-blue-base u-font-weight-600"
              >{{ timer.time }} min{{ timer.seconds > 60 ? 's' : '' }}</span
            >.</span
          >
          You can stay or go back to all plans view.
        </p>
        <rb-button
          style="min-width: 93px"
          text="View All Plans"
          type="hollow"
          class="u-spacing-mt-l"
          size="s"
          icon-left="angled-arrow-left"
          :click-fn="onExit"
        />
      </div>
    </div>
    <Loader
      :loading="!localLoading && rowsData.loading"
      class="fill--parent"
      :color="'#3fadf7'"
      :size="'2rem'"
      :thickness="'.2rem'"
    />
    <div v-if="!localLoading && !rowsData.loading">
      <div
        v-if="changesApplied"
        class="output-reload-panel"
      >
        <div
          class="u-display-flex u-flex-direction-flex-center u-flex-justify-content-space-between u-spacing-p-m u-bg-color-orange-base-025"
        >
          <div class="u-display-flex u-flex-direction-flex-center">
            <rb-icon
              class="rb-icon--medium u-flip-icon u-color-grey-lighter u-spacing-mr-s"
              icon="reset_to_default"
            />
            <span class="u-font-size-3 u-line-height-27"
              >To apply the changes made by you, please reload this plan by
              clicking the reload button.</span
            >
          </div>
          <div>
            <rb-button
              style="min-width: 93px"
              text="Cancel"
              type="hollow"
              size="s"
              :click-fn="cancelChanges"
              class="u-spacing-mr-m cancel-button"
            />
            <rb-button
              style="min-width: 93px"
              text="Reload Plan"
              type="filled"
              size="s"
              :click-fn="reloadPlan"
              :loading="savingOrUpdatingMediaPlan.generatingPlan"
            />
          </div>
        </div>
      </div>

      <div
        class="plan-description u-font-size-4 u-color-grey-light"
        :class="changesApplied ? 'plan-description-with-changes' : ''"
      >
        <PlanDescription
          :get-plan-description="getPlanDescription"
          :enable-steps="true"
          :disable-rest-of-steps="false"
          :force-disabled-steps="disabledSteps"
          @goToStep="goToStep"
        />
      </div>
      <div class="output-screen-chart u-spacing-p-m">
        <CardWidget
          class="u-flex-1"
          :config="{
            meta: {},
            header: { title: 'Summary and Forecast' },
            footer: { show: true, date: new Date() }
          }"
          :data="{}"
        >
          <div
            slot="body"
            class="u-display-flex u-flex-direction-column u-spacing-mh-l"
          >
            <div class="u-spacing-mb-xl">
              <Metrics
                :initial-metrics-shown="metrics.metric1.metricsShown"
                :metrics-config="metrics.metric1.metricsConfig"
                :title="metrics.metric1.title"
                :metric-data="metrics.metric1.metricData"
                :metrics-list="metrics.metric1.metricsList"
              />
            </div>

            <div
              class="u-display-flex u-align-items-flex-center u-flex-wrap-yes"
            >
              <div
                v-for="(__, key, index) in competitivePlans"
                :key="key"
                :class="{
                  'u-spacing-mr-xl':
                    index < Object.keys(competitivePlans).length - 1
                }"
                class="u-spacing-mb-l"
              >
                <Metrics
                  v-if="metrics[key]"
                  :initial-metrics-shown="metrics[key].metricsShown"
                  :metrics-config="metrics[key].metricsConfig"
                  :title="metrics[key].title"
                  :metric-data="metrics[key].metricData"
                  :metrics-list="metrics[key].metricsList"
                />
              </div>
            </div>
          </div>
          <div
            slot="footer"
            class="u-color-grey-light u-font-size-7"
          >
            <b>Disclaimer</b> : {{ historicalDataDisclaimerForDashboard }}
          </div>
        </CardWidget>
      </div>

      <div
        class="u-display-flex u-align-items-flex-center u-spacing-ml-l u-spacing-mv-m"
      >
        <p
          class="u-font-size-5 u-color-grey-base u-font-weight-600 u-spacing-mr-s"
        >
          View Summary and Distribution by:
        </p>
        <div
          v-for="(level, index) in distributionLevels"
          :key="level"
          class="u-display-inline-flex u-flex-align-items-center"
          :class="{ disabled: false }"
        >
          <rb-checkbox
            v-if="index > 0"
            v-model="levelEnabled[level].checked"
            :disabled="!levelEnabled[level].enabled"
            @input="(enabled) => toggleLevel(enabled, index)"
          >
            <div class="u-font-size-5 u-color-grey-base u-spacing-mr-s">
              {{ levelEnabled[level].label }}
            </div>
          </rb-checkbox>
          <div
            v-else
            class="u-font-size-5 u-color-grey-base u-spacing-mr-s"
          >
            {{ levelEnabled[level].label }}
          </div>
          <span
            v-if="index < distributionLevels.length - 1"
            class="u-font-size-5 u-color-grey-lighter u-spacing-mr-m u-spacing-ml-s"
            >></span
          >
        </div>
      </div>
      <div class="output-table">
        <rb-insights-table
          class="u-height-100 u-display-flex u-flex-direction-column"
          :config="tableConfig"
          :grid-options="gridOptions"
          :table-row="rowsData.rows"
          :table-column="columnDef"
          :row-height="50"
          :enable-client-side-sorting="false"
          :enable-server-side-sorting="false"
          :has-expand="true"
          :pagination="false"
          :show-pagination-options="false"
          :levels="levels"
          :expand-cell-width="100"
          :custom-has-expand-column-order="1"
          :row-class-rules="rowClassRules"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import {
  getColumnDef,
  defaultCompetitivePlans as competitivePlans,
  planMetricOptions,
  getOutputMetrics,
  createPlanDescription,
  organizeOptions,
  UPDATE_MEDIA_PLAN_ACTION,
  OUTPUT_GETTER,
  CREATE_OUTPUT_TABLE_ACTION,
  FETCH_OUTPUT_ACTION,
  OUTPUT_SETTER,
  FETCH_STATUS_ACTION,
  MEDIA_PLAN_STATUS,
  SAVING_OR_UPDATING_PLAN_STATUS_GETTER,
  YOY_GROWTH_PERCENTAGE,
  NTB_SALES_PERCENTAGE
} from '@/components/ams/media-planner/config.js';
import {
  MEDIA_PLANNER_TIMERS_LC_KEY,
  HISTORICAL_DATA_DISCLAIMER_FOR_DASHBOARD
} from '@/components/ams/media-planner/constants.js';
import CardWidget from '@/components/widgets/cardWidget';
import Metrics from '@/components/ams/media-planner/generate-plan/output/metrics.vue';
import Loader from '@/components/basic/loader.vue';
import { cloneDeep, set } from 'lodash';
import AdjustPercentage from '@/components/ams/media-planner/generate-plan/steps/goals/adjust-percentage.vue';
import IconWithText from '@/components/globals/dataTable/tableComponentsWrapper/iconWithText.vue';
import PlanDescription from '@/components/ams/media-planner/components/plan-description.vue';
export default {
  components: {
    Metrics,
    CardWidget,
    Loader,
    PlanDescription
  },
  props: {
    storeData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    updateKeyInStore: {
      type: Function,
      default: () => {
        return () => null;
      }
    },
    disabledSteps: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      metrics: {},
      columnDef: [],
      competitivePlans,
      tableConfig: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      rowsData: {
        loading: true,
        rows: []
      },
      levels: [
        {
          level: 1,
          getter: OUTPUT_GETTER,
          action: CREATE_OUTPUT_TABLE_ACTION,
          storeKey: 'rows'
        },
        {
          level: 2,
          getter: OUTPUT_GETTER,
          action: CREATE_OUTPUT_TABLE_ACTION,
          storeKey: 'rows'
        },
        {
          level: 3,
          getter: OUTPUT_GETTER,
          action: CREATE_OUTPUT_TABLE_ACTION,
          storeKey: 'rows'
        },
        {
          level: 4,
          getter: OUTPUT_GETTER,
          action: CREATE_OUTPUT_TABLE_ACTION,
          storeKey: 'rows'
        }
      ],
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: this
        }
      },
      levelEnabled: {
        RETAILER: {
          label: 'Overall',
          enabled: true,
          checked: true
        },
        DIMENSION: {
          label: 'Business Unit',
          enabled: true,
          checked: true
        },
        CAMPAIGN_TYPE: {
          label: 'Campaign Type',
          enabled: true,
          checked: true
        },
        KEYWORD_TYPE: {
          label: 'Keyword Type',
          enabled: true,
          checked: true
        },
        MONTH: {
          label: 'Month',
          enabled: true,
          checked: true
        }
      },
      localLoading: false,
      currentMessageIndex: 0,
      timer: {
        seconds: 0,
        time: '07:00'
      },
      estimatedTimeInSecs: 420,
      changesApplied: false,
      mediaPlanId: '',
      maxTimeReached: false,
      historicalDataDisclaimerForDashboard:
        HISTORICAL_DATA_DISCLAIMER_FOR_DASHBOARD
    };
  },
  computed: {
    output() {
      const output = this.$store.getters[OUTPUT_GETTER];
      if (output.error) {
        this.snackbar(output.error);
      }
      return output;
    },
    onGoingPlanIds() {
      const statuses = this.output.statuses || {};
      const planIds = Object.keys(statuses).filter((planId) => {
        const isCreatedOfFailed =
          statuses[planId] === MEDIA_PLAN_STATUS.MEDIA_PLAN_CREATED.id ||
          statuses[planId] === 'FAILED' ||
          statuses[planId] === null;

        return !isCreatedOfFailed;
      });
      return planIds.length;
    },
    isIC() {
      const { businessUnit, organizeType, filteredBusinessUnit } =
        this.storeData;
      const bU =
        organizeType === organizeOptions.CUSTOMIZE.value
          ? filteredBusinessUnit
          : businessUnit;
      return bU?.dimensionType === 'CLIENT_INTERNAL_CATALOGUE';
    },
    distributionLevels() {
      const levels = ['RETAILER', 'DIMENSION', 'CAMPAIGN_TYPE'];
      if (!this.isIC) {
        levels.push('KEYWORD_TYPE');
      }
      levels.push('MONTH');
      return levels;
    },
    rowClassRules() {
      return {
        rightBorder: () => true,
        hideTopBorder: (params) => params?.data?.plan.charAt(0) === ' '
      };
    },
    savingOrUpdatingMediaPlan() {
      const savingOrUpdatingMediaPlan =
        this.$store.getters[SAVING_OR_UPDATING_PLAN_STATUS_GETTER];
      if (savingOrUpdatingMediaPlan.error) {
        this.snackbar(savingOrUpdatingMediaPlan.error);
      }
      return savingOrUpdatingMediaPlan;
    },
    status() {
      const { statuses } = this.output;
      const status = statuses[this.mediaPlanId] || '';
      if (
        this.mediaPlanId &&
        status === MEDIA_PLAN_STATUS.MEDIA_PLAN_CREATED.id
      ) {
        this.initiateFetchingData(this.mediaPlanId);
        clearInterval(this.interval);
        clearInterval(this.timerInterval);
      } else if (status === 'FAILED') {
        this.onExit();
      }
      return {
        status,
        message:
          MEDIA_PLAN_STATUS[status]?.message ||
          MEDIA_PLAN_STATUS.INPUT_SAVED.message,
        index: MEDIA_PLAN_STATUS[status]?.index
      };
    }
  },
  watch: {
    timer: {
      handler(newValue) {
        const { statuses } = this.output;
        const status = statuses[this.mediaPlanId] || '';
        const diffInSecs = Math.round((new Date() - newValue.seconds) / 1000);
        if (
          diffInSecs >= this.estimatedTimeInSecs &&
          status === MEDIA_PLAN_STATUS.MEDIA_PLAN_CREATED.id
        ) {
          clearInterval(this.timerInterval);
          if (this.output.time?.[this.mediaPlanId]) {
            const updatedOutput = { ...this.output };
            delete updatedOutput.time[this.mediaPlanId];
            this.setOutputTimers(updatedOutput);
          }
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.timerInterval);
  },
  created() {
    Vue.component('AdjustPercentage', AdjustPercentage);
    Vue.component('IconWithText', IconWithText);
    const regenerate = this.$route.query?.regenerate;
    const generatedMediaPlanId = this.$route.params?.id;
    this.mediaPlanId = generatedMediaPlanId;
    const {
      totalModulusTriggers,
      plans,
      months,
      mediaPlanId,
      modulusTriggerTime
    } = this.storeData;
    this.metrics = getOutputMetrics(
      months,
      plans?.[competitivePlans.moderately_aggressive_plan.id]?.enabled,
      plans?.[competitivePlans.highly_aggressive_plan.id]?.enabled,
      modulusTriggerTime
    );
    if (
      (!totalModulusTriggers && generatedMediaPlanId) ||
      (regenerate && generatedMediaPlanId)
    ) {
      this.initiateFetchingStatus(generatedMediaPlanId);
    } else if (totalModulusTriggers) {
      this.initiateFetchingData(mediaPlanId);
    }

    if (regenerate) {
      const newUrl = window.location.href.replace('&regenerate=true', '');
      window.history.replaceState({}, '', newUrl);
    }
  },
  methods: {
    snackbar(message) {
      this.$snackbar.open({
        message: message,
        duration: 5000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    setOutputTimers(updatedOutput) {
      this.$store.dispatch(OUTPUT_SETTER, updatedOutput);
      localStorage.setItem(
        MEDIA_PLANNER_TIMERS_LC_KEY,
        JSON.stringify(updatedOutput.time)
      );
    },
    getPlanDescription() {
      return createPlanDescription(this.storeData);
    },
    cancelChanges() {
      this.changesApplied = false;
      this.rowsData.loading = true;
      this.initiateFetchingData(this.mediaPlanId);
    },
    initiateFetchingStatus(mediaPlanId) {
      this.$store.dispatch(FETCH_STATUS_ACTION, { planIds: [mediaPlanId] });
      this.localLoading = true;
      this.interval = setInterval(() => {
        this.$store.dispatch(FETCH_STATUS_ACTION, { planIds: [mediaPlanId] });
      }, 15000);
      this.timer.seconds = new Date().getTime();
      const updatedOutput = { ...this.output };
      updatedOutput.time = {
        ...updatedOutput.time,
        [mediaPlanId]: this.timer.seconds
      };
      this.setOutputTimers(updatedOutput);
      let timer = this.estimatedTimeInSecs;
      this.timerInterval = setInterval(() => {
        let minutes = parseInt(timer / 60, 10);
        let seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        this.timer.time = minutes + ':' + seconds;
        if (--timer < 1) {
          this.maxTimeReached = true;
        }
      }, 1000);
    },
    initiateFetchingData(mediaPlanId) {
      const hasBUDimensions = this.storeData.showPlansAtBULevel;
      this.columnDef = getColumnDef(
        this.isIC,
        hasBUDimensions,
        this.onGoingPlanIds > 0,
        this.storeData.goal
      );
      const updatedOutput = cloneDeep(this.output);
      updatedOutput.distributionLevelToShow = {
        RETAILER: true,
        DIMENSION: true,
        CAMPAIGN_TYPE: true,
        KEYWORD_TYPE: true,
        MONTH: true
      };
      if (this.isIC) {
        delete updatedOutput.distributionLevelToShow.KEYWORD_TYPE;
      }
      this.$store.dispatch(OUTPUT_SETTER, updatedOutput);
      this.$store.dispatch(FETCH_OUTPUT_ACTION, { mediaPlanId }).then(() => {
        const { plans, backfilledData } = this.storeData;
        this.metrics.metric1.metricData['OPS Previous Period'].tag1 =
          backfilledData?.overall?.ops ||
          plans[competitivePlans.base_plan.id].overall?.historicalData
            ?.total_sales;
        this.metrics.metric1.metricData['Ad Spend (Backfilled)'].tag1 =
          backfilledData?.overall?.spend;
        this.metrics.metric1.metricData['ROAS (Backfilled)'].tag1 =
          backfilledData?.overall?.roi;
        this.metrics.metric1.metricData['Ad Sales (Backfilled)'].tag1 =
          backfilledData?.overall?.paid_sales_14d;
        this.metrics.metric1.metricData[YOY_GROWTH_PERCENTAGE].tag1 =
          backfilledData?.overall?.yoyGrowthPercentage ||
          plans[competitivePlans.base_plan.id].overall?.historicalData
            ?.yoyGrowthPercentage;
        this.metrics.metric1.metricData[NTB_SALES_PERCENTAGE].tag1 =
          backfilledData?.overall?.ntbSales ||
          plans[competitivePlans.base_plan.id].overall?.historicalData
            ?.ntbSales;
        this.metrics.metric1.metricData['Organic Sales (Backfilled)'].tag1 =
          this.metrics.metric1.metricData['OPS Previous Period'].tag1 -
          this.metrics.metric1.metricData['Ad Sales (Backfilled)'].tag1;
        this.createRows();
        this.localLoading = false;
      });
    },
    reloadPlan() {
      const mediaPlanId = this.mediaPlanId || this.$route.params?.id;
      if (mediaPlanId) {
        this.$store
          .dispatch(UPDATE_MEDIA_PLAN_ACTION, {
            isDrafted: false,
            mediaPlanId: mediaPlanId
          })
          .then(() => {
            this.changesApplied = false;
            this.initiateFetchingStatus(mediaPlanId);
          });
      } else {
        this.snackbar('Something went wrong, while reloading the media plan');
      }
    },
    createRows() {
      const { distributions, loading, distributionLevelToShow } = this.output;
      const updatedRows = [];
      const retailerLevelDistribution = distributions.retailer_level || {};
      const { plans } = this.storeData;
      Object.keys(competitivePlans).forEach((plan) => {
        const scenario = competitivePlans[plan].scenario;
        const isBasePlan = plan === competitivePlans.base_plan.id;
        if (
          Array.isArray(retailerLevelDistribution[scenario]) &&
          retailerLevelDistribution[scenario].length > 0 &&
          (isBasePlan || plans?.[plan]?.enabled)
        ) {
          const retailerLevelMetrics = retailerLevelDistribution[scenario][0];
          updatedRows.push({
            distributionLevel: 'Overall',
            plan: competitivePlans[plan].label,
            planId: plan,
            'ag-grid-has-expand': distributionLevelToShow.DIMENSION,
            BUDGET_REQUIRED: retailerLevelMetrics?.BUDGET_REQUIRED,
            PERCENTAGE_CHANGE: retailerLevelMetrics?.PERCENTAGE_CHANGE,
            PERCENTAGE_OPS_GOAL: retailerLevelMetrics?.PERCENTAGE_OPS_GOAL,
            OPS: retailerLevelMetrics?.OPS,
            SHIPPED_COGS: retailerLevelMetrics?.SHIPPED_COGS,
            PERCENTAGE_YOY_ORGANIC_SALES_GROWTH:
              retailerLevelMetrics?.PERCENTAGE_YOY_ORGANIC_SALES_GROWTH,
            ORGANIC_SALES: retailerLevelMetrics?.ORGANIC_SALES,
            AD_SALES: retailerLevelMetrics?.AD_SALES,
            PERCENTAGE_YOY_ROAS_GROWTH:
              retailerLevelMetrics?.PERCENTAGE_YOY_ROAS_GROWTH || 0,
            ROAS: retailerLevelMetrics?.ROAS,
            PERCENTAGE_OPS_GOAL_ORIGINAL:
              retailerLevelMetrics?.PERCENTAGE_OPS_GOAL
          });
          planMetricOptions.forEach(({ title, key }) => {
            this.metrics[plan].metricData[title].tag1 =
              retailerLevelMetrics[key.tag1];
          });
        }
      });
      this.rowsData.loading = loading;
      this.rowsData.rows = updatedRows;
    },

    toggleLevel(enabled, index) {
      const updatedOutput = { ...this.output };
      const updatedColumnsDef = cloneDeep(this.columnDef);
      const distributionLevelToShow = {
        ...updatedOutput.distributionLevelToShow
      };

      this.distributionLevels.forEach((l, i) => {
        if (i === index) {
          distributionLevelToShow[l] = enabled;
        }
        if (index < this.distributionLevels.length - 1) {
          if (!enabled && i > index) {
            distributionLevelToShow[l] = false;
            this.levelEnabled[l].checked = false;
            this.levelEnabled[l].enabled = false;
          } else {
            this.levelEnabled[
              this.distributionLevels[index + 1]
            ].enabled = true;
          }
        }
      });
      updatedOutput.distributionLevelToShow = distributionLevelToShow;
      const distributionLevelText = {
        RETAILER: 'Overall',
        DIMENSION: 'Business Unit',
        CAMPAIGN_TYPE: 'Campaign Type',
        KEYWORD_TYPE: 'Keyword Type',
        MONTH: 'Month'
      };
      if (this.isIC) {
        delete distributionLevelText.KEYWORD_TYPE;
      }
      const header = Object.keys(distributionLevelToShow).reduce(
        (text, key, index) => {
          if (distributionLevelToShow[key]) {
            text =
              text + `${index === 0 ? '' : ' > '}${distributionLevelText[key]}`;
          }
          return text;
        },
        ''
      );
      updatedColumnsDef[2].headerName = header;
      updatedColumnsDef[2].title = header;
      this.columnDef = updatedColumnsDef;
      this.$store.dispatch(OUTPUT_SETTER, updatedOutput).then(() => {
        this.createRows();
      });
    },
    onExit() {
      this.$router.push({ name: 'Media Planner List' });
    },
    setOverallOPS(tableId, value) {
      if (tableId && !isNaN(value)) {
        const updatedPlans = { ...this.storeData.plans };
        updatedPlans[tableId].overall.opsGoal = value;
        this.updateKeyInStore('plans', updatedPlans);
      }
    },
    updateRow(updatedRowData) {
      this.changesApplied = true;
      const { tableId, value, colId } = updatedRowData;
      const showPlansAtBULevel = colId !== 'Overall';
      const integerPercentageValue = value;
      if (!showPlansAtBULevel) {
        this.setOverallOPS(tableId, integerPercentageValue);
      } else {
        const updatedPlans = { ...this.storeData.plans };
        const updatedOutputDimensions = (
          this.output?.distributions?.dimension_level?.[
            competitivePlans[tableId].scenario
          ] || []
        ).map((data) => {
          if (data.DIMENSION === colId) {
            data.PERCENTAGE_OPS_GOAL = value;
          }
          return data;
        });
        if (updatedOutputDimensions.length) {
          this.output.distributions.dimension_level[
            competitivePlans[tableId].scenario
          ] = updatedOutputDimensions;
        }
        const rowId = (
          updatedPlans?.[tableId]?.businessUnitLevel || []
        ).findIndex((row) => colId === row.name);
        set(
          updatedPlans[tableId],
          `businessUnitLevel[${rowId}].opsGoal`,
          integerPercentageValue
        );
        const sumOfOPS = updatedPlans[tableId].businessUnitLevel.reduce(
          (sum, item) => sum + item.opsGoal,
          0
        );
        const avgOPS =
          (sumOfOPS / updatedPlans[tableId].businessUnitLevel.length).toFixed(
            2
          ) * 1;
        this.setOverallOPS(tableId, avgOPS);
        this.updateKeyInStore('plans', updatedPlans);
      }
    },
    goToStep(index) {
      this.$emit('goToStep', index);
    }
  }
};
</script>
<style lang="css">
.output-screen {
  border-bottom: 1px solid #e9eaeb !important;
}
.output-screen .output-screen-chart {
  background: #f5f7fa;
}
.output-screen .rb-button--hollow {
  background: transparent;
  color: #007cf6 !important;
  border: 1px solid #007cf6 !important;
}
.output-screen .rb-button--hollow .rb-icon:before {
  font-size: 11px;
  font-weight: 600;
}
.output-screen .output-table {
  height: 400px;
  position: relative;
}
.output-screen .output-table .ag-header-cell {
  font-size: 1.1rem;
  color: #8b8f93;
  font-weight: 600;
}
.output-screen .output-table .rightBorder .ag-cell:first-child {
  border-right: 1px solid #e9eaeb !important;
}
.output-screen .output-table .ag-pinned-left-cols-container .hideTopBorder {
  border: none !important;
}
.output-screen
  .output-table
  .ag-pinned-left-cols-container
  .hideTopBorder
  .ag-cell:not(:first-child) {
  border-top: 1px solid #e9eaeb !important;
}
.output-screen
  .output-table
  .ag-pinned-left-cols-container
  .hideTopBorder
  .ag-cell:first-child {
  font-size: 0 !important;
}

.loading-screen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #fff;
}

.loader {
  height: 15px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #007bf63b;
  border-radius: 100px;
}
.loader:before {
  display: block;
  position: absolute;
  content: '';
  width: var(--percent);
  height: 15px;
  background-color: #007cf6;
}

.u-line-height-27 {
  line-height: 27px;
}

.output-reload-panel {
  background-color: #fff;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 2;
}

.u-bg-color-orange-base-025 {
  background-color: #ffa80040;
}

.plan-description-with-changes {
  margin-top: 59px;
}

.u-flip-icon {
  transform: scaleX(-1);
}

.output-table-headers > span {
  height: 100%;
  align-items: center;
}

.output-table-headers .textClass {
  padding-top: 0;
}
</style>
