import HttpService from '@/utils/services/http-service';
import requestTemplate from '@/components/ams/ad_group_creation/setup_configs/walmart/adgroupRequestTemplate.js';
import { WalmartCampaignCreateDataService } from '@/components/ams/campaign_creation/walmart_steps/walmartCampaignCreateDataService';
import {
  formProductRequest,
  formKeywordRequest
} from '@/components/ams/campaign_creation/walmart_steps/formRequestPayloadUtility.js';
import { cloneDeep } from 'lodash';
import {
  deepReplaceObjectValues,
  deepReplaceUnusedValuesWithNull
} from '@/pages/entity_details/common_components/helper.js';

export class DataService extends WalmartCampaignCreateDataService {
  campaignId;
  adGroupId;
  advertiserId;
  adgroupType;
  retailer;

  constructor(campaignId, adGroupId, advertiserId, campaignType, retailer) {
    super(campaignId, adGroupId, advertiserId, campaignType, retailer);
    this.campaignId = parseInt(campaignId) || undefined;
    this.adGroupId = parseInt(adGroupId) || undefined;
    this.advertiserId = parseInt(advertiserId) || undefined;
    this.adgroupType = `${campaignType?.split?.('_')?.[1]}Adgroup` || undefined;
    this.retailer = retailer;
  }

  // Campaign Related API's end here

  // Ad group related API's start here
  createAdGroup(allStepsData) {
    const createAdGroupObject = cloneDeep(requestTemplate.requestObject);
    const keyValuePairs = {
      ':state': 'adGroup_create',
      ':adgroupType': this.adgroupType,
      ':campaignId': this.campaignId,
      ':adGroupId': null,
      ':adGroupName': allStepsData?.adGroupName,
      ':advertiserId': this.advertiserId
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(createAdGroupObject, key, keyValuePairs[key]);
    }
    createAdGroupObject.request.productRequest = formProductRequest(
      requestTemplate.productRequest,
      allStepsData.skus,
      this.campaignId,
      this.adGroupId
    );
    deepReplaceUnusedValuesWithNull(createAdGroupObject);
    return HttpService.post(
      'CREATE_AD_GROUP_AMS_ACTIONS_MANAGER',
      createAdGroupObject
    );
  }

  fetchAdGroupDetails() {
    const requestPayload = {
      retailer: this.retailer,
      campaignId: this.campaignId,
      adGroupId: null,
      advertiserId: this.advertiserId,
      filterName: null,
      filterDate: null,
      type: 'ADGROUPS'
    };
    return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', requestPayload);
  }

  updateAdGroupDetails(adGroupUpdateObject) {
    const updateCampaignDetailsRequestObject = cloneDeep(
      requestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'adGroup_update',
      ':adgroupType': this.adgroupType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId,
      ':adGroupId': this.adGroupId,
      ':adGroupName': adGroupUpdateObject?.adGroupName || null
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        updateCampaignDetailsRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    deepReplaceUnusedValuesWithNull(updateCampaignDetailsRequestObject);
    return HttpService.post(
      'CREATE_AD_GROUP_AMS_ACTIONS_MANAGER',
      updateCampaignDetailsRequestObject
    );
  }
  // Ad Group related API's end here

  // SKU related API's start here
  addSkus(skus = [], adGroupName = 'Test Ad Group') {
    const addSkusRequestObject = cloneDeep(requestTemplate.requestObject);
    const keyValuePairs = {
      ':state': 'sku_add',
      ':adgroupType': this.adgroupType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId,
      ':adGroupName': adGroupName,
      ':adGroupId': this.adGroupId
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(addSkusRequestObject, key, keyValuePairs[key]);
    }
    addSkusRequestObject.request.productRequest = formProductRequest(
      requestTemplate.productRequest,
      skus,
      this.campaignId,
      this.adGroupId
    );
    deepReplaceUnusedValuesWithNull(addSkusRequestObject);
    return HttpService.post(
      'CREATE_AD_GROUP_AMS_ACTIONS_MANAGER',
      addSkusRequestObject
    );
  }

  updateOrDeleteSkus(skus = [], adGroupName = 'Test Ad Group') {
    const addSkusRequestObject = cloneDeep(requestTemplate.requestObject);
    const keyValuePairs = {
      ':state': 'sku_update',
      ':adgroupType': this.adgroupType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId,
      ':adGroupName': adGroupName
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(addSkusRequestObject, key, keyValuePairs[key]);
    }
    addSkusRequestObject.request.productRequest = formProductRequest(
      requestTemplate.productRequest,
      skus,
      this.campaignId,
      this.adGroupId
    );
    deepReplaceUnusedValuesWithNull(addSkusRequestObject);
    return HttpService.post(
      'CREATE_AD_GROUP_AMS_ACTIONS_MANAGER',
      addSkusRequestObject
    );
  }

  fetchSkusDetails() {
    const requestPayload = {
      retailer: this.retailer,
      campaignId: this.campaignId,
      adGroupId: null,
      advertiserId: this.advertiserId,
      filterName: null,
      filterDate: null,
      type: 'SKUS'
    };
    return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', requestPayload);
  }
  // SKU related API's end here

  // Keywords related API's start here
  addKeywords(keywords = [], adGroupName) {
    const addKeywordsRequestObject = cloneDeep(requestTemplate.requestObject);
    const keyValuePairs = {
      ':state': 'keyword_add',
      ':adgroupType': this.adgroupType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId,
      ':adGroupId': this.adGroupId,
      ':adGroupName': adGroupName
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        addKeywordsRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    addKeywordsRequestObject.request.keywordRequest = formKeywordRequest(
      requestTemplate.keywordRequest,
      keywords,
      this.campaignId,
      this.adGroupId
    );
    deepReplaceUnusedValuesWithNull(addKeywordsRequestObject);
    return HttpService.post(
      'CREATE_AD_GROUP_AMS_ACTIONS_MANAGER',
      addKeywordsRequestObject
    );
  }

  fetchKeywordsDetails() {
    const requestPayload = {
      retailer: this.retailer,
      campaignId: this.campaignId,
      adGroupId: null,
      advertiserId: this.advertiserId,
      filterName: null,
      filterDate: null,
      type: 'KEYWORDS'
    };
    return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', requestPayload);
  }

  addNegativeKeywords(negativeKeywords, adGroupName) {
    negativeKeywords = negativeKeywords || [];
    const addNegativeKeywordsRequestObject = cloneDeep(
      requestTemplate.requestObject
    );
    const keyValuePairs = {
      ':state': 'keyword_add',
      ':adgroupType': this.adgroupType,
      ':campaignId': this.campaignId,
      ':advertiserId': this.advertiserId,
      ':adGroupId': this.adGroupId,
      ':adGroupName': adGroupName
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(
        addNegativeKeywordsRequestObject,
        key,
        keyValuePairs[key]
      );
    }
    addNegativeKeywordsRequestObject.request.negativeKeywordRequest =
      formKeywordRequest(
        requestTemplate.negativeKeywordRequest,
        negativeKeywords,
        this.campaignId,
        this.adGroupId
      );
    deepReplaceUnusedValuesWithNull(addNegativeKeywordsRequestObject);
    return HttpService.post(
      'CREATE_AD_GROUP_AMS_ACTIONS_MANAGER',
      addNegativeKeywordsRequestObject
    );
  }

  fetchNegativeKeywordsDetails() {
    const requestPayload = {
      retailer: this.retailer,
      campaignId: this.campaignId,
      adGroupId: null,
      advertiserId: this.advertiserId,
      filterName: null,
      filterDate: null,
      type: 'NEGATIVE_KEYWORDS'
    };
    return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', requestPayload);
  }
  // Keywords related API's end here
}
