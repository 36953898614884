import HttpService from '@/utils/services/http-service';
import {
  selectSkuLeftTable,
  selectSkuRightTable
} from '@/pages/dashboard_service_campaign_management/promoteiq/commonUtils';
import { isEmpty, uniq } from 'lodash';

function getAdGroupTableColDef(aggregator) {
  return [
    {
      name: `${aggregator}_cm_adgroup_campaign_name`,
      type: 'STRING',
      uiField: {
        uiLabel: 'Campaign name',
        uiType: 'string',
        uiOrder: 1,
        metadata: {
          toolTipText: '',
          tableColumnName: `${aggregator}_cm_adgroup_campaign_name`,
          showOnUi: true,
          isFixed: false,
          width: 286,
          cellClass: 'hide-header-checkbox'
        }
      }
    },
    {
      name: 'select',
      type: 'STRING',
      uiField: {
        uiLabel: 'Select',
        uiType: 'string',
        uiOrder: 2,
        metadata: {
          toolTipText: '',
          tableColumnName: 'select',
          cellClass: 'checkbox-only',
          showOnUi: true,
          isFixed: false,
          width: 71,
          checkboxSelection: true
        }
      }
    },
    {
      name: `${aggregator}_cm_adgroup_adgroup_name`,
      type: 'STRING',
      uiField: {
        uiLabel: 'Ad Group Name',
        uiType: 'string',
        uiOrder: 3,
        metadata: {
          toolTipText: '',
          tableColumnName: `${aggregator}_cm_adgroup_adgroup_name`,
          showOnUi: true,
          isFixed: false,
          width: 230
        }
      }
    },
    {
      name: `${aggregator}_cm_adgroup_adgroup_status`,
      type: 'STRING',
      uiField: {
        uiLabel: 'Status',
        uiType: 'string',
        uiOrder: 4,
        metadata: {
          toolTipText: '',
          tableColumnName: `${aggregator}_cm_adgroup_adgroup_status`,
          showOnUi: true,
          isFixed: false,
          width: 76
        }
      }
    },
    {
      name: `${aggregator}_cm_adgroup_base_bid`,
      type: 'STRING',
      uiField: {
        uiLabel: 'Base Bid',
        uiType: 'string',
        uiOrder: 4,
        metadata: {
          toolTipText: '',
          tableColumnName: `${aggregator}_cm_adgroup_base_bid`,
          showOnUi: true,
          isFixed: false,
          width: 76
        }
      }
    },
    {
      name: `${aggregator}_cm_adgroup_spend`,
      type: 'currency',
      uiField: {
        uiLabel: 'Spend',
        uiType: 'currency',
        uiOrder: 6,
        metadata: {
          toolTipText: '',
          tableColumnName: `${aggregator}_cm_adgroup_spend`,
          showOnUi: true,
          isFixed: false,
          width: 113
        }
      }
    },
    {
      name: `${aggregator}_cm_adgroup_roas`,
      type: 'currency',
      uiField: {
        uiLabel: 'ROAS',
        uiType: 'currency',
        uiOrder: 7,
        metadata: {
          toolTipText: '',
          tableColumnName: `${aggregator}_cm_adgroup_roas`,
          showOnUi: true,
          isFixed: false,
          width: 69
        }
      }
    }
  ];
}

async function addSkusApply(
  tabName,
  aggregator,
  customActionPanelVueRef,
  selectedSkus
) {
  let widget = '';
  let actionType = '';
  let selectedCampaignIds = [];
  let selectedAdGroups = [];
  let primaryKeyExpression = '';
  if (tabName === 'Campaigns') {
    widget = 'campaign';
    actionType = `${aggregator}CampaignAddProduct`;
    selectedCampaignIds = customActionPanelVueRef.selections.map(
      (item) => item.data?.[`${aggregator}_cm_campaign_campaign_id`]
    );
    selectedAdGroups = customActionPanelVueRef.selectedAdGroups;
    primaryKeyExpression = 'campaignId';
  } else if (tabName === 'Ad Groups') {
    widget = 'adGroup';
    actionType = `${aggregator}AdGroupAddProduct`;
    // uniq here, since we might get ad groups within the same campaign
    selectedCampaignIds = uniq(
      customActionPanelVueRef.selections.map(
        (item) => item.data?.[`${aggregator}_cm_adgroup_campaign_id`]
      )
    );
    selectedAdGroups = customActionPanelVueRef.selections.map(
      (item) => item.data
    );
    primaryKeyExpression = `adGroup.${aggregator}_cm_adgroup_adgroup_id`;
  }
  const payload = [];
  selectedCampaignIds.forEach((campaignId) => {
    selectedSkus.forEach((skuItem) => {
      selectedAdGroups.forEach((adGroup) => {
        if (adGroup[`${aggregator}_cm_adgroup_campaign_id`] === campaignId) {
          payload.push({
            widget: widget,
            actionType: actionType,
            actionSource: {
              pageUrl: window.location.href
            },
            // eslint-disable-next-line no-eval
            primaryKey: eval(primaryKeyExpression),
            actionPayload: {
              campaign_id: campaignId,
              adgroup_id: adGroup[`${aggregator}_cm_adgroup_adgroup_id`],
              sku: skuItem.sku,
              use_base_bid: !skuItem.newBid,
              base_bid: adGroup[`${aggregator}_cm_adgroup_base_bid`],
              new_bid: skuItem.newBid
            },
            viewPayload: {
              adgroup_name: adGroup[`${aggregator}_cm_adgroup_adgroup_name`],
              sku: skuItem.sku,
              sku_title: skuItem.sku_title,
              sku_latest_image_url: skuItem.sku_latest_image_url,
              sku_bid: skuItem.newBid
            }
          });
        }
      });
    });
  });
  try {
    await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
    customActionPanelVueRef.$emit('afterAPIActionToCall');
  } catch (err) {
    customActionPanelVueRef.openSnackbar('Something went wrong.');
  }
}

export function getAdSkusActionObject(retailer, aggregator, tabName) {
  return {
    icon: 'sku-box',
    title: 'Add SKUs',
    id: 'add-skus',
    getValidSelections: (selections) => {
      let metricName;
      if (tabName === 'Campaigns') {
        metricName = `${aggregator}_cm_campaign_campaign_status`;
      } else if (tabName === 'Ad Groups') {
        metricName = `${aggregator}_cm_adgroup_adgroup_status`;
      }
      return selections.filter(
        (selection) => selection.data[metricName] !== 'ENDED'
      );
    },
    invalidSelectionsMessage:
      'Invalid selections have been automatically deselected.',
    customState: {
      component: 'customActionPanel',
      staticHeaderText: "Add SKU's",
      props: {
        widgetsConfig: {
          config: (customActionPanelVueRef) => {
            return {
              primaryKey: 'sku',
              customActionPanelContainerClass: 'u-spacing-mt-l',
              isMultipleAllowed: true,
              default: {
                noRowsRightTable: "Add SKU's to this list",
                selections: '',
                noRowsLeftTable: "No SKU's sources found",
                leftBtnLabel: 'Apply',
                rightBtnLabel: 'Cancel',
                warningMessage: 'Error'
              },
              left: {
                rowHeight: 68,
                tabs: {
                  "All SKU's": {
                    columnDefs: selectSkuLeftTable(
                      customActionPanelVueRef,
                      retailer
                    ),
                    search: {
                      enableSearch: true,
                      searchKey: 'search_text',
                      placeholder:
                        'Search by Sku Name, ID, and Availability Status.'
                    },
                    showFilterCheckBox: false,
                    leftCustomBidValues: [
                      {
                        title: 'Base Bid',
                        value: 'base_bid'
                      },
                      {
                        title: 'Custom Bid',
                        value: 'custom_bid',
                        input: 'currency'
                      }
                    ],
                    showLeftCustomBid: true
                  }
                },
                isColumnDefAutoConfigure: true,
                table: 'left',
                meta: {
                  type: 'table',
                  action: 'fetchTableDataExecuteApi',
                  localFilters: [],
                  updateRows: (vueRef) => {},
                  paginationAction: 'fetchTableDataExecuteApi'
                },
                footer: {
                  show: false
                },
                body: {
                  APIConfig: {
                    cubeName: `${aggregator}_sku_catalog`,
                    getLatestAvailableInsteadOfRollup: false,
                    timeseriesEnabled: false,
                    pvpenabled: false,
                    yoyenabled: false,
                    measuresList: [
                      'sku',
                      'sku_latest_image_url',
                      'sku_title',
                      'availability',
                      'price_current',
                      'suggested_bid_range'
                    ],
                    groupByDimensionsList: ['sku'],
                    orderByList: [
                      {
                        dimension: 'sku_title',
                        direction: 'ASC'
                      }
                    ],
                    limit: 10,
                    page: 1,
                    where: {
                      dimensionNameValueList: []
                    }
                  },
                  gridOptions: {
                    context: {
                      componentParent: customActionPanelVueRef
                    },
                    domLayout: 'normal'
                  },
                  columnDefs: selectSkuLeftTable(
                    customActionPanelVueRef,
                    retailer
                  )
                },
                headerText: "Add SKU's"
              },
              right: {
                rowHeight: 68,
                table: 'right',
                searchKey: 'search',
                footer: {
                  show: false
                },
                isColumnDefAutoConfigure: true,
                body: {
                  gridOptions: {
                    context: {
                      componentParent: customActionPanelVueRef
                    },
                    domLayout: 'normal'
                  },
                  APIConfig: {
                    limit: 10,
                    page: 1
                  },
                  columnDefs: selectSkuRightTable(
                    customActionPanelVueRef,
                    retailer
                  )
                },
                headerText: () =>
                  `Added SKU's (${customActionPanelVueRef.rightTableRows.length})`
              },
              async apply(selectedSkus = []) {
                await addSkusApply.call(
                  this,
                  tabName,
                  aggregator,
                  customActionPanelVueRef,
                  selectedSkus
                );
              },
              applyButtonDisabled() {
                if (
                  isEmpty(customActionPanelVueRef.computedRightTableRows) ||
                  (tabName === 'Campaigns' &&
                    isEmpty(customActionPanelVueRef.selectedAdGroups))
                ) {
                  return true;
                }
              }
            };
          }
        }
      },
      events: {
        rightTableRows(newRightTableRows, customActionPanelVueRef) {
          for (const row of newRightTableRows) {
            if (!row.newBid) {
              customActionPanelVueRef.errorMessage = `If you leave the bid empty, the base bid of the respective adgroup will be considered`;
              return;
            }
          }
          customActionPanelVueRef.errorMessage = '';
        },
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        },
        closePanelMethod() {
          this.removeCustomCustomComponents();
        }
      },
      slots: [
        ...(tabName === 'Campaigns'
          ? [
              {
                slot: 'result-body-header',
                component: 'addSkusAdGroupsButton',
                props: {
                  aggregator: aggregator,
                  groupsTableColDefinition: getAdGroupTableColDef(aggregator)
                }
              }
            ]
          : [])
      ]
    }
  };
}
