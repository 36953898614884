<template>
  <section class="u-spacing-p-m">
    <rb-input
      v-model="textInput"
      input-type="number"
    />
    <div
      v-if="errorMessage"
      class="u-spacing-pv-s u-color-red-base u-font-size-7 u-spacing-mt-s"
    >
      {{ errorMessage }}
    </div>
    <div
      class="u-spacing-pv-m u-display-flex u-flex-justify-content-flex-end u-border-width-s u-border-top u-border-color-grey-xxx-light"
    >
      <rb-button
        :click-fn="handleCancel"
        :text="'Cancel'"
        :size="'s'"
        :type="'hollow'"
        class="u-spacing-ml-m u-cursor-pointer"
      />
      <rb-button
        :click-fn="handleApply"
        :disabled="!isTextFilled"
        :text="'Apply'"
        :size="'s'"
        :type="'filled'"
        class="u-spacing-ml-m u-cursor-pointer"
      />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      default: 'numeric'
    }
  },
  data() {
    return {
      errorMessage: '',
      textInput: ''
    };
  },
  computed: {
    isTextFilled() {
      let condition = this.textInput.length && !this.errorMessage.length;
      if (this.type === 'numeric') {
        condition = condition && !isNaN(this.textInput);
      }
      return condition;
    }
  },
  watch: {
    textInput() {
      this.errorMessage = '';
    }
  },
  methods: {
    createPayload() {
      const infix = { text: this.textInput };
      const payload = { text: this.textInput, infix };
      return payload;
    },
    handleApply() {
      const payload = this.createPayload();
      if (
        this.min !== undefined &&
        this.min !== null &&
        payload.text < this.min
      ) {
        this.errorMessage = `Input cannot be less than ${this.min}`;
        return;
      }
      if (
        this.max !== undefined &&
        this.max !== null &&
        payload.text > this.max
      ) {
        this.errorMessage = `Input cannot exceed ${this.max}`;
        return;
      }
      this.$emit('input', payload);
      this.$emit('apply');
      this.textInput = '';
    },
    handleCancel() {
      this.$emit('cancel');
      this.textInput = '';
    }
  }
};
</script>

<style></style>
