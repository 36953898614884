<template>
  <div class="u-width-100 u-line-height-1">
    <rb-sku
      :show-image="rbSkuData.showImage"
      :title="rbSkuData.title"
      :image-url="rbSkuData.imageUrl"
      :asin="rbSkuData.asin"
      :product-page-url="rbSkuData.productUrl"
      :enable-s-d-p="enableSDP"
      @navigateToSDP="navigateToSDP"
    >
      <div
        v-if="!parentComponentInstance.showAllSpecificColumn"
        slot="sku-context"
        class="u-display-flex u-font-size-7 u-text-overflow-ellipsis"
      >
        <span v-if="params.data.recency == 0">{{
          parentComponentInstance.$t('new')
        }}</span>
        <span
          v-if="
            params.data.recency !== 0 &&
            !(params && params.product && params.product !== 'MarketingIQ')
          "
          >{{ params.data.recency }} times</span
        >
        <span
          v-if="
            params.data.mean_estimate_value &&
            parentComponentInstance.showPerformanceOverview
          "
          v-tippy
          class="u-color-blue-base"
          :title="parentComponentInstance.$t('mean_estimate_value')"
        >
          <span
            v-if="
              params.data.recency == 0 ||
              !(params && params.product && params.product !== 'MarketingIQ')
            "
            >&nbsp;&#183;&nbsp;</span
          >
          {{ params.data.mean_estimate_value | num_format('currency') }}
        </span>
        <span
          v-if="params.data.cached_html_link"
          v-tippy="tippyOptions"
          class="u-display-flex"
          :title="$t('page_snapshot_tooltip')"
        >
          <span
            v-if="
              params.data.recency == 0 ||
              (params.data.mean_estimate_value &&
                parentComponentInstance.showPerformanceOverview) ||
              !(params && params.product && params.product !== 'MarketingIQ')
            "
            >&nbsp;&#183;&nbsp;</span
          >
          <a
            class="link--asin u-color-grey-lighter"
            :href="params.data.cached_html_link"
            target="_blank"
            @click.stop
          >
            Cached Page</a
          >
        </span>
      </div>
    </rb-sku>
  </div>
</template>

<script>
import { compRecommendationList as blackListRecommendation } from '@/utils/helpers/constant.js';
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';

export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'alertsTableSku',
  data() {
    return {
      tippyOptions: {
        theme: 'page-snapshot',
        duration: [0, 0],
        placement: 'right',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      enableSDP: false
    };
  },
  computed: {
    parentComponentInstance() {
      return this.params.context.componentParent;
    },
    rbSkuData() {
      var data = {
        title: null,
        imageUrl: null,
        asin: null,
        productUrl: null,
        showImage: false
      };
      // 3p asin recommendation changes

      if (this.parentComponentInstance.fetchedBcRecommendationType === 'asin') {
        if (
          blackListRecommendation.indexOf(
            this.parentComponentInstance.fetchedRecommendationName
          ) !== -1
        ) {
          this.enableSDP = false;
        } else {
          this.enableSDP = true;
        }
        return {
          title: this.params.data.title,
          imageUrl: this.params.data.image_url,
          asin: this.params.data.asin,
          productUrl: this.params.data.product_url,
          showImage: true
        };
      } else if (
        this.parentComponentInstance.fetchedBcRecommendationType ===
        'search_term'
      ) {
        data.title = this.params.data.search_term;
      } else if (
        this.parentComponentInstance.fetchedBcRecommendationType === 'brand'
      ) {
        data.title = this.params.data.bc_brand_alert_type;
      } else if (
        this.parentComponentInstance.fetchedBcRecommendationType ===
        'keyword_text'
      ) {
        data.title = this.params.data.keyword_text;
      } else if (
        this.parentComponentInstance.fetchedBcRecommendationType === 'campaign'
      ) {
        data.title = this.params.data.campaign_name;
      }
      return data;
    }
  },
  created() {},
  methods: {
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    }
  }
};
</script>
