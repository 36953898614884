/* eslint-disable vue/v-bind-style */
<template>
  <a
    ref="redirectionAnchorTag"
    class="u-width-100 u-flex-0 u-color-grey-base u-text-align-left u-spacing-mv-s generic-redirection"
    data-cy="targetBlank"
    target="_blank"
    @click="openInNewTabClickHandler"
  >
    <div
      v-tippy="tippyOptions"
      class="u-display-flex u-clamp-2"
    >
      <div
        v-tippy="tippyOptionsCamp"
        :title="cellValue"
        data-cy="linkName"
        class="cd-link-hover cell--content u-overflow-hidden"
        :class="{
          'no-hover-styling':
            isSamsclubApiWithSbCampaign || isWalmartApiWithSvCampaign
        }"
      >
        {{ cellValue }}
      </div>
      <customTippy
        id="tooltipTemplateNewTab"
        icon="open-new-window"
        :title="customTippyTitle"
        icon-class="rb-icon--small u-spacing-mr-s"
      />
    </div>
  </a>
</template>

<script>
import customTippy from '@/components/pages/insights/amazon/share-of-voice/atoms/customTippy.vue';
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'redirect',
  components: {
    customTippy
  },
  props: {
    params: {
      type: Object,
      default() {
        return {};
      }
    },
    boolSDRedirect: {
      type: Boolean,
      default: () => false
    },
    tippy: {
      type: Object,
      default: () => {
        return {
          // theme: 'page-snapshot',
          duration: [0, 0],
          placement: 'top-start',
          popperOptions: {
            modifiers: {
              preventOverflow: {
                enabled: false
              },
              hide: {
                enabled: false
              }
            }
          }
        };
      }
    }
  },
  data() {
    const retailer = this.$store.getters.getRetailer;
    const retailerToEntityDetailRouteName = {
      amazon: 'EntityDetails',
      instacart: 'InstacartEntityDetails',
      walmart: 'walmartEntityDetails',
      samsclub_api: 'samsclub_apiEntityDetails'
    };
    const getEntityType = (retailer, params) => {
      const { data } = params;
      switch (retailer) {
        case 'walmart':
        case 'samsclub_api':
          if (data?.campaign_type === 'SP') {
            return data?.targeting_type === 'manual' ? 'sp_manual' : 'sp_auto';
          }
          return 'sb_manual';
        default:
          return data?.campaign_type?.toLowerCase();
      }
    };
    const getEntityDetailProps = (retailer) => {
      const commonProps = [
        { key: 'advertiser_id', paramName: 'advertiser_id' },
        { key: 'campaign_name', paramName: 'parent_entity_name' }
      ];
      const amazonProps = [{ paramName: 'profile_id', key: 'profile_id' }];
      const props = {
        amazon: amazonProps,
        instacart: amazonProps,
        walmart: commonProps,
        samsclub_api: commonProps
      };
      return props[retailer] || [];
    };

    return {
      config: {
        Orders: {
          name: 'Order Details',
          entityType: 'dsp',
          key: 'dsp_orders_order_id',
          text: 'dsp_orders_order_name',
          action: 'entityDetails/updateDspOrdersMetadata'
        },
        'Line Items': {
          name: 'Line Item Details',
          entityType: 'dsp',
          key: 'dsp_line_items_line_item_id',
          text: 'dsp_line_items_line_item_name'
        },
        EntityDetails: {
          routeToAdgroup: true,
          text: 'ams_cm_adgroup_adgroup_name',
          key: 'adgroup_id',
          target: 'ams_cm_adgroup_adgroup_targeting',
          campaignType: 'ams_cm_adgroup_campaign_type'
        },
        Campaigns: {
          name: retailerToEntityDetailRouteName[retailer],
          entityType: getEntityType(retailer, this.params),
          text: 'campaign_name',
          key: 'campaign_id',
          props: getEntityDetailProps(retailer)
        },
        InstacartEntityDetails: {
          routeToAdgroup: true,
          text: 'instacart_cm_adgroup_adgroup_name',
          key: 'adgroup_id',
          customAdGroupRedirect: 'auto'
        },
        samsclub_apiEntityDetails: {
          routeToAdgroup: true,
          text: 'samsclub_api_cm_adgroup_name',
          key: 'ad_group_id',
          customAdGroupRedirect:
            this.$route.params?.entityType?.split?.('_')?.[1]
        },
        walmartEntityDetails: {
          routeToAdgroup: true,
          text: 'walmart_cm_adgroup_name',
          key: 'ad_group_id',
          customAdGroupRedirect:
            this.$route.params?.entityType?.split?.('_')?.[1]
        }
      },
      sdTypeMap: {
        T00020: 'product',
        T00030: 'audience'
      },
      tippyOptions: {
        html: '#' + 'tooltipTemplateNewTab',
        placement: 'right',
        interactive: true,
        reactive: true,
        arrow: false,
        duration: [0, 0],
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      tippyOptionsCamp: {
        placement: 'top-start',
        delay: [1000, 200],
        animation: 'scale',
        interactive: false,
        maxWidth: '250px',
        theme: 'campaign',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: true
            }
          }
        }
      }
    };
  },
  computed: {
    isSamsclubApiWithSbCampaign() {
      return (
        this.$store?.getters?.getRetailer === 'samsclub_api' &&
        this.params.data?.campaign_type === 'SB'
      );
    },
    isWalmartApiWithSvCampaign() {
      return (
        this.$store?.getters?.getRetailer === 'walmart' &&
        this.params.data?.campaign_type === 'SV'
      );
    },
    currentConfig() {
      const campaignManagementPages = [
        'Campaigns',
        'Keywords',
        'AMS SKUs',
        'SKUs',
        'Search Terms',
        'AdGroups'
      ];
      if (
        campaignManagementPages.indexOf(this.$router.history.current.name) !==
        -1
      ) {
        return this.config.Campaigns;
      }
      return this.config[this.$router.history.current.name];
    },
    cellValue() {
      return this.params.data[this.currentConfig.text];
    },
    redirectionLink() {
      if (this.currentConfig.routeToAdgroup) {
        const campaignType =
          this.params.data?.[this.currentConfig?.campaignType] ??
          this.currentConfig?.customAdGroupRedirect;
        let subEntityType = null;
        if (this.boolSDRedirect) {
          const campaignInfo =
            this.$store.getters['entityDetails/getCampaignDetails'];
          subEntityType = 'product_adgroup';
          if (campaignInfo?.data?.tactic) {
            subEntityType = `${
              this.sdTypeMap[campaignInfo.data.tactic]
            }_adgroup`;
          }
        }

        const extraQueryParams = {};
        this.checkIfCampaignIsSBAndV4(extraQueryParams);
        const routeData = this.$router.resolve({
          name: 'SubEntityDetails',
          params: {
            entityType: this.$route.params.entityType,
            entityId: this.$route.params.entityId,
            subEntityId: this.params.data[this.currentConfig.key],
            subEntityType:
              subEntityType ||
              `${
                (campaignType === 'auto' && campaignType) ||
                this.params.data?.[this.currentConfig?.target] ||
                campaignType
              }_adgroup`
          },
          query: {
            ...this.$route.query,
            parent_entity_name: this.$route.query.parent_entity_name,
            ...extraQueryParams
          }
        });
        return routeData.href;
      }
      const routeData = this.$router.resolve({
        name: this.currentConfig.name,
        params: {
          entityType: this.currentConfig.entityType,
          entityId: this.params.data[this.currentConfig.key]
        },
        ...(this.currentConfig?.props
          ? {
              // adding metrics in params.data to query where key is the metric
              // and paramName is the name the metric should have in the query params
              query: this.currentConfig?.props.reduce(
                (acc, { key, paramName }) => {
                  acc[paramName] = this.params.data[key];
                  return acc;
                },
                {}
              )
            }
          : [])
      });
      return routeData.href;
    },
    customTippyTitle() {
      const campaignTypeInTippyText = this.isWalmartApiWithSvCampaign
        ? 'SBV'
        : 'SBA';
      return this.isSamsclubApiWithSbCampaign || this.isWalmartApiWithSvCampaign
        ? `Campaign Details experience is not supported for ${campaignTypeInTippyText} campaigns`
        : 'Open in a new tab';
    }
  },
  mounted() {
    if (this.isSamsclubApiWithSbCampaign || this.isWalmartApiWithSvCampaign) {
      return;
    }
    // adding href link this way to fix sonar issue of security
    const anchorTagRef = this.$refs.redirectionAnchorTag || {};
    anchorTagRef.href = this.redirectionLink;
  },
  methods: {
    openInNewTabClickHandler() {
      if (this.currentConfig.action) {
        this.$store.dispatch(this.currentConfig.action, this.params.data);
      }
    },
    checkIfCampaignIsSBAndV4(extraQueryParams) {
      if (this.$route?.params?.entityType === 'sb') {
        const campaignInfo =
          this.$store.getters['entityDetails/getCampaignDetails'];
        if (campaignInfo?.data?.multiAdGroupsEnabled) {
          extraQueryParams.isV4Campaign = true;
        }
      }
    }
  }
};
</script>

<style lang="css" scoped>
.cd-link-hover {
  text-decoration: underline;
  text-decoration-style: dashed;
}
.cd-link-hover:hover {
  text-decoration: underline;
  text-decoration-style: solid;
  white-space: pre;
}
.cd-link-hover .no-hover-styling {
  text-decoration: none;
}
</style>
