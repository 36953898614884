<template>
  <section>
    <sticky-filter-panel
      ref="remcc-sticky-filter-panel"
      :view-selection-enabled="false"
      :hide-add-filter="true"
      :hide-export="true"
      :enable-date="true"
      default-date-post-range="last90Days"
      :allow-date-compare="true"
      :filter-emit="filterEmit"
      :filter-local-storage-key="dateFilterLocalStorageKey"
    >
      <div
        slot="before-filter"
        class="u-display-flex u-flex-align-items-center u-height-36px"
      >
        <div class="u-font-size-2 u-font-weight-700 u-display-flex capitalize">
          REM Command Centre
        </div>
      </div>
      <div
        slot="footer"
        class="u-bg-color-grey-white u-spacing-p-m"
      >
        <header-filters
          :key="filterChange"
          :filters="headerFilters"
          :selected-filters="selectedFilters"
          :filter-options="filterOptions"
          @filter-state-change="handleFilterUpdate"
        />
      </div>
    </sticky-filter-panel>
  </section>
</template>

<script>
import stickyFilterPanel from '@/components/pages/insights/amazon/share-of-voice/molecules/sticky-filter-panel.vue';
import rbFilterPanel from '@/components/widgets/filter-panel';
import HeaderFilters from './header-filters.vue';
import { eventBus } from '@/utils/services/eventBus';

export default {
  name: 'HeaderSection',
  components: {
    stickyFilterPanel,
    rbFilterPanel,
    HeaderFilters
  },
  data() {
    return {
      addFilterText: 'Choose internal categorization',
      filterLocalStorageKey: 'remcc-top-filters',
      dateFilterLocalStorageKey: 'remcc-date-selection',
      minMaxDate: {},
      defaultDateRange: 'last90Days',
      filterEmit: 'headerFilterChange',
      filterChange: 0,
      selectedFilters: {},
      dateFilterChange: 0
    };
  },
  computed: {
    headerFilters() {
      return [
        {
          filterKey: 'retailer',
          filterLabel: 'Retailer',
          addFilterText: 'Choose Retailer',
          hideRemoveBtn: true,
          isDisabled: true
        },
        {
          filterKey: 'category_hierarchy',
          filterLabel: 'Category',
          addFilterText: 'Choose Category'
        },
        {
          filterKey: 'brand',
          filterLabel: 'Brand',
          addFilterText: 'Choose Brand'
        }
      ];
    },
    filterOptions() {
      return {
        category_hierarchy: {
          values: [
            { title: 'Breakfast cereals', enable: true },
            { title: 'Protein Bars', enable: true }
          ]
        },
        brand: {
          values: [
            { title: 'Monsoon Harvest', enable: true },
            { title: 'Cocosutra', enable: true }
          ]
        }
      };
    }
  },
  mounted() {
    this.getSelectedHeaderFilters();
    eventBus.$on(this.filterEmit, (params) => {
      this.dateFilterChange++;
      this.$emit('filterUpdate', params);
    });
  },
  methods: {
    getSelectedHeaderFilters() {
      let values = localStorage.getItem(this.filterLocalStorageKey);
      if (values) {
        values = JSON.parse(values);
      } else {
        values = {};
      }
      const retailer = [
        this.$options.filters.first_caps(this.$store.getters.getRetailer)
      ];
      values = { ...values, retailer };
      this.selectedFilters = { values };
      this.filterChange++;
    },
    handleFilterUpdate(selection) {
      let storedValues = localStorage.getItem(this.filterLocalStorageKey);
      if (storedValues) {
        storedValues = JSON.parse(storedValues);
        if (selection.selections.length > 0) {
          storedValues[selection.filterKey] = selection.selections;
        } else {
          // this is remove filter event
          delete storedValues[selection.filterKey];
        }
      } else {
        // Remove filter event won't be fired in else case because
        storedValues = {};
        storedValues[selection.filterKey] = selection.selections;
      }
      if (Object.keys(storedValues).length > 0) {
        localStorage.setItem(
          this.filterLocalStorageKey,
          JSON.stringify(storedValues)
        );
      } else {
        localStorage.removeItem(this.filterLocalStorageKey);
      }
      this.$emit('filterUpdate', selection);
    }
  }
};
</script>
