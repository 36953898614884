<template>
  <div class="u-flex-align-items-center u-display-flex">
    <div class="u-spacing-mr-xs">
      Last Updated :
      {{ lastUpdatedDate }}
    </div>
    <rb-icon
      v-tippy="tippy"
      :title="retailerMessage"
      class="rb-icon--small tool-tip-hover-budget-planner u-cursor-pointer"
      icon="info-circle-fill"
    />
  </div>
</template>

<script>
export default {
  props: {
    lastUpdatedDate: {
      type: String,
      default: null
    },
    retailerMessage: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      tippy: {
        placement: 'right',
        arrow: false,
        distance: 8,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      }
    };
  }
};
</script>
