var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"u-font-size-5 u-font-weight-600 u-color-grey-light u-display-inline-block",class:_vm.groupLabelClass},[_vm._v(" "+_vm._s(_vm.groupLabel)+" ")]),_c('div',{staticClass:"u-display-flex",class:{ 'u-flex-direction-column': _vm.isColumn }},_vm._l((_vm.radioOptions),function(ref,index){
var type = ref.type;
var label = ref.label;
var desc = ref.desc;
return _c('div',{key:index,staticClass:"global-radio-wrapper",class:{
        'u-spacing-mr-l': _vm.radioOptions.length !== index + 1,
        'u-spacing-mb-l': _vm.isColumn
      }},[_c('div',[_c('rb-radio',{attrs:{"native-value":type,"data-cy":("targetType-" + label)},model:{value:(_vm.selectedRadio),callback:function ($$v) {_vm.selectedRadio=$$v},expression:"selectedRadio"}},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_vm._v(" "+_vm._s(label)+" "),_vm._t(("right_radio_" + type))],2)])],1),_c('div',{staticClass:"u-font-size-6 u-font-weight-400 u-color-grey-lighter u-spacing-mt-s u-spacing-ml-l u-line-height-1",attrs:{"data-cy":("targetTypeDescription-" + label)}},[_vm._v(" "+_vm._s(desc)+" ")]),_vm._t(("below_radio_" + type))],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }