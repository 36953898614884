<template>
  <div
    class="u-width-100 u-height-100 u-flex-justify-content-flex-end u-spacing-p-m u-color-grey-base u-display-flex u-text-overflow-ellipsis"
  >
    {{ cellValue }}
  </div>
</template>
<script>
export default {
  props: {
    params: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    cellValue() {
      const val = this.params.data?.[this.params.key]?.toFixed(2) || null;
      return val ? `${val}%` : '---';
    }
  }
};
</script>
