import HttpService from '@/utils/services/http-service';
import {
  nativeRetailers,
  UtilServiceForConfig
} from '@/pages/dashboard_service_campaign_management/utils/utilServiceForConfig';
import { dataDiscrepancyVHtmlText } from '@/pages/dashboard_service_campaign_management/promoteiq/commonUtils.js';
import chartingWb from '@/components/widgets/custom_widgets/custom_widget_components/charts/charting-wb.vue';
import dashboardServiceTable from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import plotRowsChip from '@/pages/dashboard_service_campaign_management/common_components/plotRowsChip.vue';
import tableSwitchComponent from '@/pages/dashboard_service_campaign_management/common_components/tableSwitchComponent.vue';
import iconCell from '@/pages/dashboard_service_campaign_management/common_components/iconCell.vue';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import actionsBar from '@/components/widgets/custom_widgets/cw_actionsBar';
import activityLog from '@/components/widgets/collaboration/collaboration.vue';
import iconHeader from '@/pages/dashboard_service_campaign_management/common_components/iconHeader.vue';
import EditBid from '@/pages/dashboard_service_campaign_management/promoteiq/editBid.vue';

function getEditStatusActionObject(retailer, aggregator) {
  return {
    icon: 'task-status',
    title: 'Edit Status',
    id: 'edit-status',
    dropDown: true,
    dropDownOptions: [
      { title: 'Restore', value: 'Restore' },
      { title: 'Remove', value: 'Remove' }
    ],
    async handler(selectionLength, dropDownSelection, selections) {
      const payload = [];
      selections.forEach((adSku) => {
        const adGroupId = adSku?.data?.[`${aggregator}_cm_sku_adgroup_id`];
        const campaignId = adSku?.data?.[`${aggregator}_cm_sku_campaign_id`];
        const sku = adSku?.data?.[`${aggregator}_cm_sku_sku`];
        const deleted = dropDownSelection[0].value === 'Remove';
        const newStatus = deleted ? 'DELETED' : 'ACTIVE';
        payload.push({
          widget: 'skus',
          actionType: `${retailer}AdSkuStatusChange`,
          actionSource: {
            pageUrl: window.location.href
          },
          primaryKey: adSku?.data?.[`${aggregator}_cm_sku_sku_id`],
          actionPayload: {
            campaign_id: campaignId,
            adgroup_id: adGroupId,
            sku,
            deleted
          },
          viewPayload: {
            current_status:
              adSku?.data?.[`${aggregator}_cm_sku_sku_status`]?.toUpperCase(),
            status: newStatus
          }
        });
      });
      try {
        await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
        this.$emit('openSnackbar', 'Action has been submitted');
      } catch (err) {
        console.log(err);
        this.$emit('openSnackbar', 'Something went wrong.');
      }
    }
  };
}

function getEditBidActionObject(retailer, aggregator) {
  return {
    icon: 'dollar',
    title: 'Edit Bid',
    id: 'edit-bid',
    customState: {
      component: 'editBid',
      props: {
        title: 'Edit Bid',
        selectedEntity: 'SKU',
        actionType: `${retailer}ProductBidChange`,
        showInputTitle: true,
        aggregator: aggregator,
        getSingleSelectBidSuggestions: (editBidComponentRef) => ({
          show: true,
          multipleSuggestions: false,
          title: `Ad Group Base bid`,
          value:
            editBidComponentRef.selections?.[0]?.data?.[
              `${aggregator}_cm_sku_adgroup_base_bid`
            ].toString(),
          currentBid: null,
          currentBidNote: '',
          suggestedBidNote: '',
          secondSuggestedBidNote: ''
        }),
        getMultiSelectBidSuggestions: (_) => ({
          show: true,
          value: 'XX',
          valueKey: `${aggregator}_cm_sku_adgroup_base_bid`,
          currentBid: null,
          title: 'Ad Group base bid',
          multipleSuggestions: false,
          currentBidNote: '',
          suggestedBidNote:
            '**XX - Respective Ad Group base bid in the table below'
        }),
        currentBidKey: `${aggregator}_cm_sku_current_bid`,
        defaultMinBid: 0.1,
        defaultMaxBid: 50,
        minBidKey: `${aggregator}_cm_sku_adgroup_min_base_bid`,
        radioConfig: [
          {
            title: 'Set new bid',
            type: 'input',
            value: 'set_new'
          },
          {
            title: 'Increase bid',
            value: 'increase',
            type: 'dropdown',
            dropdown: [
              {
                label: 'Change by percentage',
                value: 'percentage'
              },
              {
                label: 'Change absolute value',
                value: 'absolute_value'
              }
            ]
          },
          {
            title: 'Decrease bid',
            value: 'decrease',
            type: 'dropdown',
            dropdown: [
              {
                label: 'Change by percentage',
                value: 'percentage'
              },
              {
                label: 'Change absolute value',
                value: 'absolute_value'
              }
            ]
          }
        ],
        customCreatePayload(editBidComponentRef) {
          return editBidComponentRef.selections.map((adSku) => {
            const updatedBid = editBidComponentRef.getUpdatedBidValue(adSku);
            const baseBid =
              adSku?.data?.[`${aggregator}_cm_sku_adgroup_base_bid`];
            return {
              widget: 'skus',
              actionType: `${retailer}AdSkuBidChange`,
              actionSource: {
                pageUrl: window.location.href
              },
              primaryKey: adSku?.data?.[`${aggregator}_cm_sku_sku_id`],
              actionPayload: {
                adgroup_id: adSku?.data?.[`${aggregator}_cm_sku_adgroup_id`],
                campaign_id: adSku?.data?.[`${aggregator}_cm_sku_campaign_id`],
                sku: adSku?.data?.[`${aggregator}_cm_sku_sku`],
                new_bid:
                  editBidComponentRef.bidValue?.radioValue === 'set_new' &&
                  editBidComponentRef.bidSuggestionRadioValue === 'suggestedBid'
                    ? null
                    : updatedBid,
                base_bid: baseBid,
                use_base_bid: updatedBid === baseBid
              },
              viewPayload: {
                old_bid: adSku?.data?.[`${aggregator}_cm_sku_current_bid`],
                new_bid: updatedBid
              }
            };
          });
        },
        async handleApply(vueRef, payload) {
          try {
            await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
            vueRef.$emit('afterAPIActionToCall');
          } catch (err) {
            vueRef.openSnackbar('Something went wrong.');
          }
        }
      },
      events: {
        closePanelMethod() {
          this.removeCustomCustomComponents();
        },
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        }
      }
    }
  };
}

export function adSkusConfig(retailer) {
  const listApiPageName = `${retailer}_cm_skus`;
  const chartWidgetName = `${retailer}_cm_skus_charting_wb`;
  const tableWidgetName = `${retailer}_cm_skus_table`;
  const localStorageFilterKey = `${retailer}-skus-filter`;
  const localStorageFilterOrderKey = `${retailer}-skus-filter-order`;
  const tabName = 'Ad SKUs';

  const utilServiceForConfig = new UtilServiceForConfig({
    retailer,
    listApiPageName,
    chartWidgetName,
    tableWidgetName,
    localStorageFilterKey,
    localStorageFilterOrderKey,
    tabName
  });
  let aggregator = nativeRetailers.includes(retailer) ? retailer : 'promoteiq';
  return {
    PRIMARY_KEY: 'sku_id',
    entity: 'adskus',
    styleClass: 'orders generic-campaign-management',
    pageInfo: utilServiceForConfig.getPageInfo(),
    initRequestParams: utilServiceForConfig.getInitRequestParams(),
    filterSettings: {
      ...utilServiceForConfig.getFilterSettings({
        backendCube: `${aggregator}_campaigns_sku_workbench`,
        searchDimension: 'search',
        searchInputPlaceHolder: 'Search for a SKU',
        bulkSearchStringConstants: {
          dimensionNameMapping: 'sku',
          findEntityInputPlaceholder: 'Find a SKU',
          subTitle: 'SKUs'
        },
        enableTaxonomyFilters: true
      }),
      extraCalendarInfoVHtmlText: dataDiscrepancyVHtmlText
    },
    components: {
      [chartWidgetName]: chartingWb,
      [tableWidgetName]: dashboardServiceTable,
      plotRowsChip: plotRowsChip,
      tableSwitchComponent: tableSwitchComponent,
      iconCell: iconCell,
      EditBid: EditBid,
      iconTextActions: iconTextActions,
      actionsBar: actionsBar,
      activityLog: activityLog,
      iconHeader: iconHeader
    },
    widgetNames: utilServiceForConfig.getWidgetNames(),

    headerOptions: function (context) {
      return {
        // context is bound to the DashboardWrapper component
        [chartWidgetName]: {
          props: utilServiceForConfig.getChartWidgetProps(context)
        },
        [tableWidgetName]: {
          props: {
            ...utilServiceForConfig.getTableWidgetProps(context),
            headerOptions: [
              {
                ui_component: 'actionsBar',
                props: {
                  actionBarActions: [
                    utilServiceForConfig.getPlotRowsActionObject(),
                    getEditStatusActionObject(retailer, aggregator),
                    getEditBidActionObject(retailer, aggregator)
                  ],
                  isCustomActionStateVisible:
                    context.isCustomActionStateVisible,
                  selections: context.rowSelections,
                  showPlotRowsChip: context.showPlotRowsChip
                },
                events: {
                  handlePlotRows: context.handlePlotRows,
                  deselectSelections: (args) => {
                    // this is on x click in actions bar;
                    if (Array.isArray(args)) {
                      context.handleSelectionChange(args, true);
                    } else {
                      context.handleSelectionChange(args.selections);
                    }
                  },
                  openSnackbar: context.openSnackbar,
                  toggleCustomActionState: context.toggleCustomActionState,
                  switchBlueBar: context.switchBlueBar
                }
              },
              ...utilServiceForConfig.getEmailActionComponentRenderObjectArray(
                context
              )
            ],
            enableManageColumns: !context.rowSelections.length
          }
        }
      };
    },
    getActivityData: utilServiceForConfig.getGetActivityDataMethod('skus'),
    modal: utilServiceForConfig.getModalBaseConfigForEmail()
  };
}
