<template>
  <div>
    <span
      v-for="(dateMetric, index) in refreshDates"
      :key="index"
    >
      <div
        :id="'date-level-tippy' + index"
        class="u-display-flex u-flex-direction-column"
      >
        <span
          class="u-border-bottom u-border-color-grey-xxx-light updated_date_title_section u-font-size-4 u-color-grey-base u-font-weight-600 u-spacing-p-m u-border-width-s"
          >Updated on {{ formatter(dateMetric.date, 'date') }}</span
        >
        <div class="u-spacing-pv-s u-display-flex u-flex-direction-column">
          <span
            v-for="(metricName, m_index) in dateMetric.metrics"
            :key="m_index"
            class="u-spacing-ph-m u-spacing-pv-s u-font-size-7 u-color-grey-base metric_refresh_date_metrics_item"
            >{{ metricName }}</span
          >
        </div>

        <span
          class="u-font-size-7 u-spacing-p-s u-border-color-grey-xxx-light u-border-all refresh_metrics_info_footer u-border-width-s u-font-weight-400"
        >
          <rb-icon
            icon="info-circle-fill"
            class="rb-icon--small u-color-blue-base u-spacing-mr-xs"
          />
          These metrics now have higher refresh rate
        </span>
      </div>
      <span
        v-tippy="{
          html: '#' + 'date-level-tippy' + index,
          reactive: true,
          distance: 4,
          placement: tippyPlacement,
          interactive: true,
          theme: 'dropdown',
          trigger: 'mouseenter',
          duration: [0, 0],
          boundary: 'viewport'
        }"
        class="u-cursor-pointer refresh_metric_count_trigger u-spacing-ml-s underline-on-focus u-text-decoration-underline"
      >
        {{ dateMetric.metrics.length }} metrics</span
      >
      are updated on
      <span class="u-color-grey-dark">{{
        formatter(dateMetric.date, 'date')
      }}</span>
    </span>
  </div>
</template>

<script>
import { formatter } from '@/utils/helpers/formatter.js';

export default {
  name: 'MetricRefreshDatesView',
  props: {
    refreshPageId: {
      type: String,
      default: ''
    },
    tippyPlacement: {
      type: String,
      default: 'top-start'
    }
  },
  data() {
    return {
      formatter
    };
  },
  computed: {
    refreshDates() {
      return this.$store.getters.getHotMetricRefreshDates(this.refreshPageId);
    }
  }
};
</script>

<style lang="css">
.refresh_metric_count_trigger:hover {
  color: #007cf6;
}

.refresh_metric_count_trigger {
  color: #8b8f93;
}

.refresh_metrics_info_footer {
  display: flex;
  align-items: center;
  border-width: 1px;
  font-weight: 400;
}
.metric_refresh_date_metrics_item {
  font-weight: normal;
}
</style>
