var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"u-font-size-7 u-spacing-mb-xs u-color-grey-mid-light"},[_vm._v(" vs "+_vm._s(_vm.pvpText)+" ")]),_c('div',{staticClass:"u-display-flex u-flex-align-items-flex-end"},[_c('span',{directives:[{name:"trend-indicator",rawName:"v-trend-indicator"}],staticClass:"u-font-size-6",class:{
        'u-color-grey-base':
          _vm.displayPvpValue === 'NA' ||
          _vm.businessTrendMultiplier * _vm.pvpValueReady === 0,
        'u-color-red-base':
          _vm.businessTrendMultiplier * _vm.pvpValueReady < 0 &&
          _vm.displayPvpValue !== 'NA',
        'u-color-green-base':
          _vm.businessTrendMultiplier * _vm.pvpValueReady > 0 &&
          _vm.displayPvpValue !== 'NA'
      },attrs:{"title":_vm.displayPvpValue}},[_c('html-component',{attrs:{"wrapper-element":"span","html-string":_vm.displayPvpValue}})],1),_c('span',{staticClass:"u-spacing-pl-s u-font-size-7 u-color-grey-lighter",attrs:{"title":_vm.displayPvpDiffValue}},[_c('html-component',{attrs:{"wrapper-element":"span","html-string":_vm.displayPvpDiffValue}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }