<template>
  <section
    class="settings-bar u-cursor-pointer u-bg-color-grey-white u-border-top u-border-width-s u-border-color-grey-xxx-light u-display-flex u-flex-justify-content-space-between u-spacing-ph-l u-spacing-pv-m"
    @click="showExpandedState"
  >
    <div
      class="u-width-100 u-font-size-5 u-color-grey-mid-light u-display-flex u-font-size-7"
    >
      <div class="u-display-flex">
        <SelectRow
          v-show="!hideViewBy"
          class="u-spacing-mr-xl"
          :is-expanded="isExpanded"
          :options="types"
          title="View by"
          :select-type="'radio'"
          :original-selections="selectedType"
          @selection="handleTypesSelection"
        />
        <SelectRow
          v-show="!hideDisplayShareIn"
          class="u-spacing-mr-xl"
          :is-expanded="isExpanded"
          :options="keywordType"
          title="Display by"
          select-type="multi"
          :original-selections="selectedKeywordType"
          @selection="handleKeywordsSelection"
        />
        <div>
          <div class="u-display-flex">
            <span
              class="u-spacing-mr-xs"
              :class="{ 'u-font-weight-600': isExpanded }"
              >3P Sales</span
            >
            <span v-tippy="labelTippyOptions">
              <div
                id="label_3p"
                class="u-spacing-p-m"
              >
                <div class="u-spacing-mb-m u-font-size-5">
                  {{ selectedPlacement.label }}
                </div>
                <p
                  class="u-font-size-6 u-color-grey-light u-line-height-1-5 u-font-weight-normal"
                >
                  {{ selectedPlacement.tooltip }}
                </p>
              </div>
              <div
                v-if="!isExpanded"
                class="u-spacing-ml-xs u-color-grey-base u-font-weight-600"
              >
                {{ selectedPlacement.label }}
              </div>
            </span>
          </div>
          <rb-select
            v-if="isExpanded"
            :options="placementOptions"
            :on-close="handleSovPlacementSelection"
            :send-details="true"
            class="u-spacing-mt-m"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-cursor-pointer"
            >
              <div class="u-display-flex u-flex-align-items-center u-width-100">
                <span class="u-color-grey-light">{{
                  internalSelectedPlacement.label
                }}</span>
                <rb-icon
                  class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                  :icon="'caret-down'"
                />
              </div>
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <span
                v-tippy="tippyOptions"
                :title="getTooltip(option)"
              >
                <div>{{ option.label }}</div>
              </span>
            </template>
          </rb-select>
        </div>
        <SelectRow
          v-for="(filter, index) in customerLevelFilters"
          :key="index"
          class="u-spacing-ml-xl"
          :is-expanded="isExpanded"
          :options="filter.options"
          :title="filter.label"
          :original-selections="filter.defaultSelections"
          @selection="
            (data) => handleCustomerFilterSelection(filter.label, data)
          "
        />
      </div>
    </div>
    <div
      v-if="!isExpanded"
      class="edit-button u-color-grey-lighter u-cursor-pointer u-display-flex u-font-weight-600 u-font-size-7"
      @click.stop="showExpandedState"
    >
      <rb-icon
        class="rb-icon--small u-spacing-ml-s"
        :icon="'pencil'"
      />
      <div class="u-spacing-ml-xs">Edit</div>
    </div>
    <div
      v-else
      class="u-display-flex u-flex-align-items-center u-color-grey-lighter"
    >
      <ConfigIcon
        :class="{
          'u-color-blue-base u-cursor-pointer': isApplyButtonActive,
          'u-color-grey-lighter u-pointer-events-none u-cursor-default':
            !isApplyButtonActive
        }"
        class="u-spacing-mr-l"
        :config="applyIcon"
        @click="handleApply"
      />
      <ConfigIcon
        :config="cancelIcon"
        @click="handleCancel"
      />
    </div>
  </section>
</template>

<script>
import { isEqual, cloneDeep, sortBy } from 'lodash';
import SelectRow from '@/components/pages/insights/amazon/share-of-voice/atoms/select-row.vue';
import ViewsSelect from '@/components/pages/insights/amazon/share-of-voice/atoms/views-select';
import ConfigIcon from '@/components/pages/insights/amazon/share-of-voice/atoms/config-icon';
import { get3pOptions } from '@/components/pages/insights/amazon/market-share/utils';

export default {
  components: {
    SelectRow,
    ViewsSelect,
    ConfigIcon
  },
  props: {
    hideDisplayShareIn: {
      type: Boolean,
      default: false
    },
    hideViewBy: {
      type: Boolean,
      default: false
    },
    selectedBrand: {
      type: Object,
      default: () => ({})
    },
    brandsOptions: {
      type: Array,
      default: () => []
    },
    selectedKeywordType: {
      type: [Array, Object],
      default: null
    },
    keywordType: {
      type: Array,
      default: () => []
    },
    selectedType: {
      type: [Array, Object],
      default: null
    },
    selectedPlacement: {
      type: [Array, Object],
      default: null
    },
    types: {
      type: Array,
      default: () => []
    },
    placements: {
      type: Array,
      default: () => []
    },
    customerLevelFilters: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      applyIcon: this.getBaseApplyIcon(),
      cancelIcon: {
        icon: {
          icon: 'cross',
          class: {}
        },
        click: {
          emit: 'click'
        },
        subText: {
          text: 'Cancel'
        }
      },
      isExpanded: false,
      internalSelectedType: [],
      internalKeywordType: [],
      internalSelectedPlacement: {},
      internalBrandSelect: {},
      internalSelectedCustomerFilters: {},
      tippyOptions: {
        theme: 'light',
        placement: 'right',
        reactive: true,
        maxWidth: '440px',
        tippyId: 'optionTippy',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      },
      placementOptions: this.placements
    };
  },
  computed: {
    isApplyButtonActive() {
      const isSelectedTypeSameAsOriginal = isEqual(
        this.selectedType,
        this.internalSelectedType
      );
      const isSelectedPlacementSameAsOriginal = isEqual(
        this.selectedPlacement,
        this.internalSelectedPlacement
      );
      const isSelectedKeywordTypeSameAsOriginal = isEqual(
        this.selectedKeywordType,
        this.internalKeywordType
      );
      const isSelectedCustomerFiltersSameAsOriginal =
        this.customerLevelFilters.every((filter) => {
          // We need to sort the selections array for isEqual() to return true if the selections
          // are same even if not in the same order in the array
          const defaultSelectionsLabels = filter.defaultSelections.map(
            (selection) => selection.label
          );
          const internalSelectedLabels = this.internalSelectedCustomerFilters[
            filter.label
          ].map((selection) => selection.label);
          const result = isEqual(
            sortBy(defaultSelectionsLabels),
            sortBy(internalSelectedLabels)
          );
          return result;
        });

      return (
        !isSelectedTypeSameAsOriginal ||
        !isSelectedPlacementSameAsOriginal ||
        !isSelectedKeywordTypeSameAsOriginal ||
        !isSelectedCustomerFiltersSameAsOriginal
      );
    },
    labelTippyOptions() {
      return {
        ...this.tippyOptions,
        html: '#label_3p',
        placement: 'bottom-start'
      };
    }
  },
  watch: {
    selectedType: {
      immediate: true,
      handler(newSelectedType) {
        this.internalSelectedType = cloneDeep(newSelectedType);
      }
    },
    selectedKeywordType: {
      immediate: true,
      handler(selectedKeywordType) {
        this.internalKeywordType = cloneDeep(selectedKeywordType);
      }
    },
    selectedPlacement: {
      immediate: true,
      handler(selectedPlacement) {
        this.internalSelectedPlacement = cloneDeep(selectedPlacement);
      }
    },
    selectedBrand: {
      immediate: true,
      handler(newSelectedBrand) {
        this.internalBrandSelect = cloneDeep(newSelectedBrand);
      }
    }
  },
  methods: {
    getBaseApplyIcon() {
      return {
        icon: {
          icon: 'check',
          class: {
            color: 'null'
          }
        },
        click: {
          emit: 'click'
        },
        subText: {
          text: 'Apply',
          class: {
            color: 'null'
          }
        }
      };
    },
    getTooltip(option) {
      return `
        <div class="u-spacing-p-m">
          <div class="u-spacing-mb-m u-font-size-5">${option.label}</div>
          <p class="u-font-size-6 u-color-grey-light u-line-height-1-5 u-font-weight-normal">${option.tooltip}</p>
        </div>
      `;
    },
    showExpandedState() {
      this.isExpanded = true;
    },
    hideExpandedState() {
      this.isExpanded = false;
    },
    handleAnchorSelect(context, payload) {
      if (payload && payload[0]) {
        delete payload[0].selectedIndex;
        this.internalBrandSelect = payload[0];
      }
    },
    handleKeywordsSelection(data) {
      if (this.internalKeywordType.shareType !== data.shareType) {
        const ThreePOptions = get3pOptions(data.shareType);
        this.placementOptions = ThreePOptions;
        this.internalSelectedPlacement =
          ThreePOptions.find(
            (d) => d.value === this.internalSelectedPlacement.value
          ) || ThreePOptions[1];
        this.showSnackBar();
      }
      this.internalKeywordType = data;
    },
    handleSovPlacementSelection(_, data) {
      if (data?.[0]) {
        delete data[0].selectedIndex;
        this.internalSelectedPlacement = data[0];
        document.querySelectorAll('.tippy-popper').forEach((popper) => {
          if (popper._tippy?.options?.tippyId === 'optionTippy') {
            popper._tippy.hide();
          }
        });
      }
    },
    handleCustomerFilterSelection(attribute, data) {
      this.internalSelectedCustomerFilters = {
        ...this.internalSelectedCustomerFilters,
        [attribute]: data
      };
    },
    handleTypesSelection(data) {
      this.internalSelectedType = data;
    },
    handleCancel(payload) {
      const { event } = payload;
      event.stopPropagation();
      this.hideExpandedState();
      this.$emit('cancel');
    },
    getMultiSelectionsInOrder(selections, options) {
      const indexToItemMap = {};
      const typesLabelsToIndexMap = {};
      options.forEach((type, index) => {
        typesLabelsToIndexMap[type.label] = index;
      });
      selections.forEach((item, index) => {
        const pushIndex = typesLabelsToIndexMap[item.label];
        indexToItemMap[pushIndex] = item;
      });
      return Object.values(indexToItemMap);
    },
    setSelectedType(data) {
      // const inorderArray = this.getMultiSelectionsInOrder(data, this.types);
      // const primitiveArray = inorderArray.map(item => item.dimensionValue);
      this.$emit('setType', data);
    },
    setKeywordsType(data) {
      // const inorderArray = this.getMultiSelectionsInOrder(
      //   data,
      //   this.keywordType
      // );
      // const primitiveArray = inorderArray.map(item => item.dimensionValue);
      this.$emit('setKeywordType', data);
    },
    setSelectedBrand(data) {
      const payload = {
        title: data.title,
        clientFlag: data.client_flag
      };
      const previousAnchorPayload = {
        title: this.selectedBrand.title,
        clientFlag: this.selectedBrand.client_flag
      };
      if (this.selectedBrand?.title !== data.title) {
        this.$snackbar.open({
          message: 'Share of Voice metrics reflect the new Anchor Brand!',
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
      }
      this.$emit('previousAnchorBrand', cloneDeep(previousAnchorPayload));
      this.$emit('setAnchoredBrand', payload);
    },
    setSelectedPlacement(data) {
      this.$emit('setPlacement', data.metric);
    },
    handleApply(payload) {
      const { event } = payload;
      event.stopPropagation();
      this.hideExpandedState();
      this.setSelectedType(this.internalSelectedType);
      this.setSelectedPlacement(this.internalSelectedPlacement);
      this.setKeywordsType(this.internalKeywordType);
      this.$emit('apply', {
        viewBy: this.internalSelectedType,
        displayShareIn: this.internalKeywordType,
        include3p: this.internalSelectedPlacement,
        customerLevelFilters: this.internalSelectedCustomerFilters
      });
    },
    showSnackBar() {
      this.$snackbar.open({
        message: 'Relevant 3P sales setting is updated',
        position: 'is-top',
        duration: 3000,
        buttonColor: '#f5d908',
        actionText: 'Ok',
        showIcon: false
      });
    }
  }
};
</script>

<style lang="css" scoped>
input[type='radio'] {
  vertical-align: baseline;
  margin: 0;
}
input[type='checkbox'] {
  vertical-align: baseline;
  margin: 0;
}
.settings-bar:hover {
  box-shadow: 0px 8px 8px -8px #d2d4d7;
}
.settings-bar:hover .edit-button {
  color: #007cf6;
}
</style>
