<template>
  <section
    id="action-scheduler"
    ref="action-scheduler"
    class="u-display-flex u-flex-direction-column u-position-relative u-flex-justify-content-flex-start u-spacing-mt-m u-spacing-p-m u-width-100 card action-scheduler"
  >
    <!-- Header -->
    <div
      class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-center header-container"
    >
      <div class="u-display-flex u-flex-direction-column header-left-container">
        <h3 class="u-font-size-4 u-font-weight-bold u-color-black-base">
          Create and schedule actions
        </h3>
      </div>
      <!-- toggle reset button only when a new action is added -->
      <div
        class="u-display-flex"
        :class="{ 'u-visibility-hidden': !showResetAction }"
      >
        <icon-text-actions
          mode="default"
          :action="{ title: 'Reset Actions', icon: 'reset_to_default' }"
          :apply-padding="false"
          @clicked="reset"
        />
      </div>
    </div>
    <!-- Actions menu -->
    <div
      class="u-display-flex u-spacing-mt-m u-spacing-pb-s u-border-bottom u-border-color-grey-xxx-light u-border-width-s actions-container"
    >
      <!-- Tab Actions : Adjust Budget , Adjust Bid -->
      <div class="u-display-flex u-flex-direction-column">
        <span
          class="u-spacing-mb-m u-color-black-base u-font-size-5 u-font-weight-600"
        >
          Adjust:
        </span>

        <!-- Tab Actions : Adjust Budget , Adjust Bid -->
        <splitTabs
          :active-tab="currentSelectedTabLabel"
          :is-tabs-list="true"
          :tabs-list="
            step.actionTypeOptions.map((actionType) => actionType.title)
          "
          @tabClicked="handleTabsClick"
        />
      </div>
      <!-- Change By: Set, Increase/decrease (radio buttons) -->
      <div
        class="u-border-left u-border-color-grey-xxx-light u-border-width-s u-display-flex u-spacing-ml-l"
      >
        <div class="u-display-flex u-flex-direction-column">
          <span
            class="u-spacing-pl-s u-spacing-mb-m u-color-black-base u-font-size-5 u-font-weight-600"
          >
            Change by:
          </span>
          <rbRadioGroup
            :key="currentSelectedAction"
            class="u-spacing-mt-xs"
            :radio-options="
              step.operationTypes[currentSelectedAction].operation
            "
            :value="selectedOperation"
            :custom-label="true"
            @onRadioChanged="handleRadioSelection"
          />
        </div>
      </div>
    </div>
    <div class="time-slots-container">
      <!-- Time slot header -->
      <div
        class="u-display-flex u-border-bottom u-border-color-grey-xxx-light u-border-width-s time-slots-header"
      >
        <div
          class="u-display-flex u-flex-align-items-center u-spacing-pv-s u-flex-1 timezone-header"
        >
          <span class="u-color-grey-base u-font-size-5 u-font-weight-600">{{
            getTimezone.label
          }}</span>
          <div
            v-tippy="{ placement: 'top', arrow: false }"
            :title="(getTimezone || {}).tooltiptext"
          >
            <rb-icon
              class="rb-icon--medium u-spacing-ml-xs u-color-grey-lighter"
              :icon="'info-circle-fill'"
            />
          </div>
        </div>
        <div class="u-display-flex">
          <div
            v-for="timeVal in timeSlotValues"
            :key="timeVal.value"
            class="u-display-flex time-slot time-slot-wrapper"
          >
            <span
              class="u-font-size-6 u-display-flex u-flex-justify-content-center u-flex-align-items-center action-slot action-slot-row-height action-slot-width"
              >{{ timeVal.label }}</span
            >
          </div>
        </div>
      </div>
      <drag-select-container
        selector-class="action-slot"
        @onDrag="onDrag"
        @onDragEnd="onDragEnd"
      >
        <div class="time-slot-rows">
          <!-- Weekdays title -->
          <div class="u-display-flex u-flex-align-items-center u-spacing-mv-s">
            <div
              class="u-display-flex u-flex-align-items-center u-spacing-pv-s timezone-header u-cursor-pointer"
              @click="() => toggleWeekdaysDisplay('showWeekdays')"
            >
              <span
                class="u-color-grey-lighter u-font-size-5 u-font-weight-600"
              >
                Weekdays</span
              >
              <rb-icon
                class="rb-icon--medium u-spacing-ml-s u-color-grey-lighter"
                :icon="showWeekdays ? 'arrow-up' : 'arrow-down'"
              />
            </div>
            <div class="u-display-flex u-flex-direction-column">
              <div
                v-if="computedShowExceedsValueWarningMessage"
                class="u-display-flex u-flex-align-items-center u-spacing-ml-l"
              >
                <span>
                  <rb-icon
                    class="rb-icon--medium u-color-red-base"
                    :icon="'warning'"
                  />
                </span>
                <span class="u-color-red-base u-font-size-5 u-spacing-ml-xxs">
                  {{ computedShowExceedsValueWarningMessage }}
                </span>
              </div>
              <div
                v-if="showNoActionWarning"
                class="u-display-flex u-flex-align-items-center u-spacing-ml-l"
              >
                <span>
                  <rb-icon
                    class="rb-icon--medium u-color-orange-base"
                    :icon="'warning'"
                  />
                </span>
                <span
                  class="u-color-orange-base u-font-size-5 u-spacing-ml-xxs"
                >
                  {{ showNoActionWarning }}
                </span>
              </div>
            </div>
          </div>
          <!-- Weekdays rows -->
          <div v-if="showWeekdays">
            <div
              v-for="(weekday, weekIdx) in weekdays"
              :key="weekday"
              class="u-display-flex day-row"
              :class="{
                'u-border-top u-border-color-grey-xxx-light u-border-width-s':
                  weekIdx !== weekdays.length
              }"
            >
              <div
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-p-s u-flex-1"
              >
                <div
                  class="u-color-grey-mid-light u-font-size-6 u-font-weight-600 u-text-transform-capitalize"
                >
                  {{ weekday.toLowerCase() }}
                </div>
                <span
                  v-if="
                    ((dayWiseAggregateValues[currentSelectedAction] || {})[
                      selectedOperation
                    ] || {})[weekday]
                  "
                >
                  <rb-icon
                    class="rb-icon--medium u-color-red-base"
                    :icon="'warning'"
                  />
                </span>
              </div>
              <div
                class="u-display-flex u-border-radius-s"
                :class="{
                  'u-bg-color-grey-xx-light': ((dayWiseAggregateValues[
                    currentSelectedAction
                  ] || {})[selectedOperation] || {})[weekday]
                }"
              >
                <div
                  v-for="timeVal in timeSlotValues"
                  :id="`${weekday}-${timeVal.value}`"
                  :key="`${weekday}-${timeVal.value}`"
                  class="u-display-flex time-slot-wrapper u-border-radius-s"
                  :class="{
                    'u-bg-color-grey-xx-light':
                      !actionObj[currentSelectedAction][selectedOperation][
                        weekday
                      ][timeVal.value].passStatus
                  }"
                >
                  <TimeSlot
                    v-if="
                      !Object.keys(selectedActions).length &&
                      actionStatus.passStatus &&
                      (currentMergedCellsMap[weekday] || {})[timeVal.value]
                    "
                    class="u-cursor-pointer u-font-size-7 u-font-weight-600 action-slot action-slot-row-height u-display-flex u-flex-justify-content-center u-flex-align-items-center action-slot-width merged-slot"
                    :style="{
                      backgroundColor: step.getTimeSlotValue({
                        day: weekday,
                        time: timeVal.value,
                        dayObj,
                        selectedActions,
                        selectedOperation
                      }).bgColor,
                      color: step.getTimeSlotValue({
                        day: weekday,
                        time: timeVal.value,
                        dayObj,
                        selectedActions,
                        selectedOperation
                      }).color,
                      width: step.getWidth({
                        day: weekday,
                        time: timeVal.value,
                        currentMergedCellsMap
                      }),
                      height: '3.4rem',
                      position: 'absolute',
                      outline: `4px solid ${
                        !actionObj[currentSelectedAction][selectedOperation][
                          weekday
                        ][timeVal.value].passStatus
                          ? '#caccce'
                          : 'transparent'
                      }`
                    }"
                    :data-item="`${weekday}-${timeVal.value}-${
                      (
                        (currentMergedCellsMap[weekday] || {})[timeVal.value] ||
                        {}
                      ).finalIndex
                    }`"
                    @click="
                      (event) =>
                        openPopupActionHandler(
                          event,
                          `${weekday}-${timeVal.value}-${
                            (
                              (currentMergedCellsMap[weekday] || {})[
                                timeVal.value
                              ] || {}
                            ).finalIndex
                          }`
                        )
                    "
                  >
                    {{
                      step.getTimeSlotValue({
                        day: weekday,
                        time: timeVal.value,
                        dayObj,
                        selectedActions,
                        selectedOperation
                      }).value
                    }}
                  </TimeSlot>
                  <TimeSlot
                    class="u-cursor-pointer u-font-size-7 u-font-weight-600 action-slot action-slot-row-height u-display-flex u-flex-justify-content-center u-flex-align-items-center action-slot-width"
                    :style="
                      step.getSlotStyle({
                        day: weekday,
                        time: timeVal.value,
                        dayObj,
                        selectedActions,
                        selectedOperation
                      })
                    "
                    :data-item="`${weekday}-${timeVal.value}`"
                    @click="
                      (event) =>
                        openPopupActionHandler(
                          event,
                          `${weekday}-${timeVal.value}`
                        )
                    "
                  >
                    {{
                      step.getTimeSlotValue({
                        day: weekday,
                        time: timeVal.value,
                        dayObj,
                        selectedActions,
                        selectedOperation
                      }).value
                    }}
                  </TimeSlot>
                </div>
              </div>
            </div>
          </div>

          <!-- Weekend title -->
          <div class="u-display-flex u-flex-align-items-center u-spacing-mv-s">
            <div
              class="u-display-flex u-flex-align-items-center u-spacing-pv-s timezone-header u-flex-1 u-cursor-pointer"
              @click="() => toggleWeekdaysDisplay('showWeekendDays')"
            >
              <span
                class="u-color-grey-lighter u-font-size-5 u-font-weight-600"
              >
                Weekend</span
              >
              <rb-icon
                class="rb-icon--medium u-spacing-ml-s u-color-grey-lighter"
                :icon="showWeekendDays ? 'arrow-up' : 'arrow-down'"
              />
            </div>
          </div>
          <!-- weekend rows -->
          <div v-if="showWeekendDays">
            <div
              v-for="(weekendDay, weekendIdx) in weekendDays"
              :key="weekendDay"
              class="u-display-flex day-row"
              :class="{
                'u-border-top u-border-color-grey-xxx-light u-border-width-s':
                  weekendIdx !== weekendDays.length
              }"
            >
              <div
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-p-s u-flex-1"
              >
                <div
                  class="u-color-grey-mid-light u-font-size-6 u-font-weight-600 u-text-transform-capitalize"
                >
                  {{ weekendDay.toLowerCase() }}
                </div>
                <span
                  v-if="
                    ((dayWiseAggregateValues[currentSelectedAction] || {})[
                      selectedOperation
                    ] || {})[weekendDay]
                  "
                >
                  <rb-icon
                    class="rb-icon--medium u-color-grey-base"
                    :icon="'warning'"
                  />
                </span>
              </div>
              <div
                class="u-display-flex u-border-radius-s"
                :class="{
                  'u-bg-color-grey-xx-light': ((dayWiseAggregateValues[
                    currentSelectedAction
                  ] || {})[selectedOperation] || {})[weekendDay]
                }"
              >
                <div
                  v-for="timeVal in timeSlotValues"
                  :id="`${weekendDay}-${timeVal.value}`"
                  :key="`${weekendDay}-${timeVal.value}`"
                  class="u-display-flex time-slot-wrapper u-border-radius-s"
                  :class="{
                    'u-bg-color-grey-xx-light':
                      !actionObj[currentSelectedAction][selectedOperation][
                        weekendDay
                      ][timeVal.value].passStatus
                  }"
                >
                  <TimeSlot
                    v-if="
                      !Object.keys(selectedActions).length &&
                      actionStatus.passStatus &&
                      (currentMergedCellsMap[weekendDay] || {})[timeVal.value]
                    "
                    class="u-cursor-pointer u-font-size-7 u-font-weight-600 action-slot action-slot-row-height u-display-flex u-flex-justify-content-center u-flex-align-items-center action-slot-width merged-slot"
                    :style="{
                      backgroundColor: step.getTimeSlotValue({
                        day: weekendDay,
                        time: timeVal.value,
                        dayObj,
                        selectedActions,
                        selectedOperation
                      }).bgColor,
                      color: step.getTimeSlotValue({
                        day: weekendDay,
                        time: timeVal.value,
                        dayObj,
                        selectedActions,
                        selectedOperation
                      }).color,
                      width: step.getWidth({
                        day: weekendDay,
                        time: timeVal.value,
                        currentMergedCellsMap
                      }),
                      height: '3.4rem',
                      position: 'absolute',
                      outline: `4px solid ${
                        !actionObj[currentSelectedAction][selectedOperation][
                          weekendDay
                        ][timeVal.value].passStatus
                          ? '#caccce'
                          : 'transparent'
                      }`
                    }"
                    :data-item="`${weekendDay}-${timeVal.value}-${
                      (
                        (currentMergedCellsMap[weekendDay] || {})[
                          timeVal.value
                        ] || {}
                      ).finalIndex
                    }`"
                    @click="
                      (event) =>
                        openPopupActionHandler(
                          event,
                          `${weekendDay}-${timeVal.value}-${
                            (
                              (currentMergedCellsMap[weekendDay] || {})[
                                timeVal.value
                              ] || {}
                            ).finalIndex
                          }`
                        )
                    "
                  >
                    {{
                      step.getTimeSlotValue({
                        day: weekendDay,
                        time: timeVal.value,
                        dayObj,
                        selectedActions,
                        selectedOperation
                      }).value
                    }}
                  </TimeSlot>

                  <TimeSlot
                    class="u-cursor-pointer u-font-size-7 u-font-weight-600 action-slot action-slot-row-height u-display-flex u-flex-justify-content-center u-flex-align-items-center action-slot-width"
                    :style="
                      step.getSlotStyle({
                        day: weekendDay,
                        time: timeVal.value,
                        dayObj,
                        selectedActions,
                        selectedOperation
                      })
                    "
                    :data-item="`${weekendDay}-${timeVal.value}`"
                    @click="
                      (event) =>
                        openPopupActionHandler(
                          event,
                          `${weekendDay}-${timeVal.value}`
                        )
                    "
                  >
                    {{
                      step.getTimeSlotValue({
                        day: weekendDay,
                        time: timeVal.value,
                        dayObj,
                        selectedActions,
                        selectedOperation
                      }).value
                    }}
                  </TimeSlot>
                </div>
              </div>
            </div>
          </div>

          <!-- everyday row -->
          <div
            class="u-display-flex u-border-top u-border-bottom u-border-color-grey-xxx-light u-border-width-s u-spacing-mt-m"
          >
            <div
              class="u-display-flex u-flex-align-items-center u-spacing-pv-s u-flex-1"
            >
              <div
                class="u-color-grey-mid-light u-font-size-6 u-font-weight-600 u-spacing-ml-s u-text-transform-capitalize"
              >
                Everyday
              </div>
            </div>
            <div class="u-display-flex">
              <!-- u-border-bottom u-border-color-grey-xxx-light u-border-width-s -->
              <div
                v-for="timeVal in timeSlotValues"
                :id="`everyday-${timeVal.value}`"
                :key="`everyday-${timeVal.value}`"
                class="u-display-flex time-slot-wrapper u-border-radius-s"
              >
                <TimeSlot
                  class="u-cursor-pointer u-font-size-7 action-slot action-slot-row-height u-display-flex u-flex-justify-content-center u-flex-align-items-center action-slot-width u-bg-color-grey-xxx-light"
                  :data-item="`everyday-${timeVal.value}`"
                  @click="
                    (event) =>
                      openPopupActionHandler(event, `everyday-${timeVal.value}`)
                  "
                />
              </div>
            </div>
          </div>
          <div
            class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-center u-spacing-mt-m"
          >
            <div class="u-display-flex u-flex-align-items-center">
              <span>
                <rb-icon
                  class="rb-icon--medium u-spacing-ml-xs u-color-grey-lighter"
                  :icon="'info-circle-fill'"
                />
              </span>
              <span class="u-color-grey-lighter u-font-size-6 u-spacing-ml-xxs"
                >Press shift key to select multiple cells in different
                columns</span
              >
            </div>
            <div class="u-display-flex">
              <div
                v-for="(legend, legendIndex) in currentFooterLegends"
                :key="legend.name + legendIndex"
                class="u-display-flex"
                :class="{ 'u-spacing-ml-m': legendIndex }"
              >
                <div class="u-display-flex u-flex-align-items-center">
                  <span
                    class="footer-legend-icon"
                    :style="{ backgroundColor: legend.color }"
                  />
                  <span
                    class="u-font-size-7 u-color-grey-base u-font-weight-600 u-spacing-ml-s"
                  >
                    {{ legend.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- time slot popup -->
        </div>
      </drag-select-container>
    </div>
    <!-- hovering popup which opens on click of time slot -->
    <div
      v-if="showPopup && !isShiftKeyPressed"
      class="u-display-flex u-spacing-pb-s popup-container"
      :style="{
        transform: `translateX(min(${popupStyle['--mouse-x']}, calc(100vw - 100%)))  translateY(min(${popupStyle['--mouse-y']}), calc(100vh - 100%)))`
      }"
    >
      <div
        v-show="showPopup"
        ref="dropdownMenu"
        class="popup-dropdown-menu"
      >
        <div class="popup--header">
          <span class="u-display-flex u-flex-align-items-center">
            <span class="u-color-grey-white u-font-size-5">Options</span>
          </span>
          <span @click.stop="closePopup">
            <rb-icon
              :icon="'cross'"
              class="rb-icon--small u-color-grey-white u-cursor-pointer"
            />
          </span>
        </div>
        <div
          class="u-display-flex u-flex-direction-column u-spacing-pv-m u-spacing-ph-m popup-body"
        >
          <div
            v-if="showExceedsDaywiseActionsLimitWarningMessage"
            class="u-display-flex u-flex-align-items-center u-spacing-mb-s"
          >
            <span>
              <rb-icon
                class="rb-icon--medium u-color-blue-base"
                :icon="'light-bulb'"
              />
            </span>
            <span
              class="u-color-blue-base u-font-size-7 u-spacing-ml-xxs u-spacing-mt-xxs"
            >
              {{ showExceedsDaywiseActionsLimitWarningMessage }}
            </span>
          </div>
          <span
            class="u-color-black-base u-font-size-5 u-font-weight-600 u-spacing-mb-s"
          >
            {{
              step.conditionalMarkupForSelectedOperation[
                selectedOperation
              ].inputLabel({ currentSelectedTabLabel, currentSelectedAction })
            }}
          </span>
          <div
            v-if="
              step.conditionalMarkupForSelectedOperation[selectedOperation]
                .showActionMode
            "
            class="u-display-flex u-spacing-mb-m u-flex-justify-content-space-between"
          >
            <div
              v-for="actionMode in step.conditionalMarkupForSelectedOperation[
                selectedOperation
              ].actionModes"
              :key="`action-mode-${actionMode.value}`"
              class="u-display-flex u-flex-align-items-center u-spacing-ph-m u-bg-color-grey-xxx-light u-border-radius-s u-cursor-pointer"
              @click="(e) => toggleActionMode(actionMode.value)"
            >
              <div class="icon-circle">
                <rb-icon
                  class="rb-icon--medium"
                  :class="[
                    {
                      'u-color-grey-lighter':
                        actionPopupState.actionMode !== actionMode.value
                    },
                    {
                      [`u-color-${actionMode.color}-base`]:
                        actionPopupState.actionMode === actionMode.value
                    }
                  ]"
                  :icon="actionMode.icon"
                />
              </div>
              <span
                class="u-font-size-6 u-font-weight-600 u-spacing-ml-s"
                :class="[
                  {
                    'u-color-grey-light':
                      actionPopupState.actionMode !== actionMode.value
                  },
                  {
                    [`u-color-${actionMode.color}-base`]:
                      actionPopupState.actionMode === actionMode.value
                  }
                ]"
                >{{ actionMode.title }}</span
              >
            </div>
          </div>
          <div
            class="u-display-flex u-flex-0 u-flex-align-items-center inputContainer"
          >
            <input
              ref="inputValue"
              type="number"
              class="rb-input u-display-flex u-flex-align-items-center percentage-input u-spacing-p-s u-font-size-7"
              min="0.1"
              step="1"
              max="100"
              autofocus
              :value="actionPopupState.value"
              @keyup="checkForMaxBid($event, actionPopupState, 'value')"
              @change="checkForMaxBid($event, actionPopupState, 'value')"
            />
            <span
              class="appender u-display-flex u-flex-align-items-center u-font-size-7 u-spacing-pr-s u-flex-0"
            >
              %
            </span>
          </div>
          <!-- eslint-disable -->
          <span
            class="u-font-size-7 u-spacing-mt-s"
            v-if="exampleText"
            v-html="exampleText"
          ></span>
          <!-- eslint-enable -->
          <div class="u-display-flex u-flex-direction-column">
            <div class="u-display-flex u-flex-direction-column u-spacing-mt-m">
              <span class="u-font-size-5 u-font-weight-600">Time period:</span>
              <div
                class="u-display-flex u-spacing-mt-s u-flex-justify-content-space-between"
              >
                <rb-select
                  class="time-drop-down typeSelect"
                  :send-details="true"
                  :on-close="selectStartTime"
                  :options="timeZoneOptions"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                  >
                    <div
                      class="u-display-flex u-flex-align-items-center u-spacing-p-s"
                    >
                      <div class="u-display-flex u-flex-direction-column">
                        <span class="u-font-size-7">{{
                          (actionPopupState.selectedStartTime || {}).title
                        }}</span>
                      </div>
                    </div>
                    <rb-icon
                      class="rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                      :icon="'caret-down'"
                    />
                  </div>
                  <template
                    slot="item"
                    slot-scope="option"
                  >
                    <div
                      class="u-display-flex u-flex-align-items-center u-font-size-7"
                    >
                      {{ option.title }}
                    </div>
                  </template>
                </rb-select>
                <rb-select
                  class="time-drop-down typeSelect u-spacing-ml-m"
                  :send-details="true"
                  :on-close="selectEndTime"
                  :options="timeZoneOptions"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                  >
                    <div
                      class="u-display-flex u-flex-align-items-center u-spacing-p-s"
                    >
                      <div class="u-display-flex u-flex-direction-column">
                        <span class="u-font-size-7">{{
                          (actionPopupState.selectedEndTime || {}).title
                        }}</span>
                      </div>
                    </div>
                    <rb-icon
                      class="rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                      :icon="'caret-down'"
                    />
                  </div>
                  <template
                    slot="item"
                    slot-scope="option"
                  >
                    <div
                      class="u-display-flex u-flex-align-items-center u-font-size-7"
                    >
                      {{ option.title }}
                    </div>
                  </template>
                </rb-select>
              </div>
              <span
                class="u-font-size-7 u-spacing-mt-s u-max-width-240px"
                :class="{
                  'u-display-block': !actionPopupState.timeValidation,
                  'u-display-none': actionPopupState.timeValidation
                }"
              >
                <error-message
                  :error-message="'Start time needs to be before the end time. Please choose an appropriate start time.'"
                />
              </span>
            </div>
            <div class="u-display-flex u-flex-direction-column u-spacing-mv-m">
              <span class="u-font-size-5 u-font-weight-600">Frequency :</span>
              <div class="u-display-flex u-spacing-mt-s">
                <div
                  v-for="(day, frequencyIndex) in step.frequencyList"
                  :key="frequencyIndex"
                  class="frequency-list-bubbles"
                  @click="changeFrequencyList(frequencyIndex)"
                >
                  <div
                    class="dayChip u-display-flex u-flex-align-items-center u-flex-justify-content-center"
                    :class="{
                      active: (actionPopupState.activeDays || []).includes(
                        day.value
                      )
                    }"
                  >
                    <span class="u-font-size-6">{{ day.title }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="u-display-flex u-flex-align-items-center u-width-100">
              <div class="u-display-flex u-flex-align-items-center">
                <local-date-filter
                  :tooltip="true"
                  :custom-date="(actionPopupState || {}).startDateRange"
                  :mode="'Single'"
                  :value="'11/09/2022'"
                  @selected-value="setStartDate"
                />
                <span class="u-font-size-6 u-spacing-ml-xs u-spacing-mr-s"
                  >to</span
                >
                <local-date-filter
                  :tooltip="true"
                  :custom-date="(actionPopupState || {}).endDateRange"
                  :mode="'Single'"
                  :value="'11/09/2022'"
                  @selected-value="setEndDate"
                />
              </div>
            </div>
            <span
              class="u-font-size-7 u-spacing-mt-s u-max-width-240px"
              :class="{
                'u-display-block': !actionPopupState.dateValidation,
                'u-display-none': actionPopupState.dateValidation
              }"
            >
              <error-message
                :error-message="'Start date needs to be before the end date. Please choose an appropriate start date.'"
              />
            </span>
          </div>
        </div>
        <footer
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-pv-m u-spacing-ph-m u-border-width-s u-border-top u-border-bottom u-border-color-grey-xxx-light"
        >
          <div class="u-display-flex u-text-align-center">
            <div
              v-for="action in popupActions"
              :key="action.text"
              class="action-container u-cursor-pointer"
              @click="(e) => handlePopupAction(e, action.text)"
            >
              <div>
                <rb-icon
                  :icon="action.icon"
                  :class="action.iconClasses"
                />
              </div>
              <span :class="action.textClasses">
                {{ action.text }}
              </span>
            </div>
          </div>
          <rb-button
            :click-fn="applyAction"
            :disabled="disableApplyButton"
            :class="{
              disabled: disableApplyButton
            }"
            :text="'Apply'"
            :size="'s'"
            :type="'filled'"
            class="u-color-grey-light u-cursor-pointer"
          />
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import boomerangSingleSelect from '@/components/widgets/select/singleSelect.vue';
import localDateFilter from '@/components/basic/localDateFilter.vue';
import Vue from 'vue';
import errorMessage from './errorMessage.vue';
import GenericTabsComponent from '@/components/basic/genericTabsComponent.vue';
import rbRadioGroup from '@/components/basic/rbRadioGroup.vue';
import rbNumberInput from '@/components/widgets/rbNumberInput.vue';
import DragSelectContainer from '@/components/pages/strategies/create/components/DragSelect.vue';
import splitTabs from '@/components/widgets/splitTabs.vue';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import { cloneDeep, isEqual, startCase, capitalize } from 'lodash';
import {
  sanitizeClipboardData,
  createCustomSubArrays,
  getDefaultDateRange,
  orderSelectedActions,
  populateAction,
  pasteContent,
  getAbsoluteActionValue,
  createUniqueActionId,
  getActionId,
  getCellsToPasteCopiedValues
} from '@/components/pages/strategies/create/utils.js';
import helpers from '@/utils/helpers/index.js';
import TimeSlot from '@/components/pages/strategies/create/components/TimeSlot.vue';
import moment from 'moment-timezone';

export default {
  components: {
    boomerangSingleSelect,
    localDateFilter,
    errorMessage,
    GenericTabsComponent,
    rbRadioGroup,
    rbNumberInput,
    DragSelectContainer,
    splitTabs,
    iconTextActions,
    TimeSlot
  },
  props: {
    step: {
      type: Object,
      default: () => {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    },
    allStepsData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    mode: {
      type: String,
      default: 'create'
    },
    actionStatus: {
      type: Object,
      default: {
        start: {
          status: true,
          actions: []
        },
        end: {
          status: true,
          actions: []
        },
        passStatus: true
      }
    }
  },
  data() {
    const hoursInADay = Array(24);
    const timeSlotValues = [...hoursInADay.keys()].map((hours) => {
      let tempHours = Number(hours);
      const ampm = tempHours >= 12 ? 'PM' : 'AM';
      tempHours = tempHours % 12;
      tempHours = tempHours || 12; // the hour '0' should be '12'
      const strTime = tempHours + ' ' + ampm;
      return { label: strTime, value: hours };
    });
    const weekdays = ['MON', 'TUE', 'WED', 'THU', 'FRI'];
    const weekendDays = ['SAT', 'SUN'];
    const everyday = moment
      .weekdaysShort()
      .map((val) => val.toLocaleUpperCase());
    return {
      showNoActionWarning: '',
      isActionTypeVisited: {},
      deletedActionIdStore: new Set(),
      actionIdStore: {},
      timeSlotValues,
      weekdays,
      weekendDays,
      everyday,
      showPopup: false,
      popupStyle: { '--mouse-x': '0px', '--mouse-y': '0px' },
      selectedActions: {},
      isShiftKeyPressed: false,
      initialW: '',
      initialH: '',
      ghostSelectNode: {}, // drag box selection state
      actions: [],
      actionObj: {},
      // actionObj shape -
      // {
      //   'budget':{          action
      //     'EQUALS':{        operation
      //       'SUN' :{        day
      //         0 : {         time slot value
      //           ...data
      //         },
      //         1 : {...}
      //       },
      //       'MON': {...}
      //     },
      //     'INCR/DECR': {...}
      //   },
      // . 'bid':{...}
      // }
      timeZoneOptions: [],
      showExceedsValueWarningMessage: {},
      currentSelectedAction: this.step.currentSelectedAction, // budget, bid
      selectedOperation: this.step.selectedOperation, // set - EQUALS, increase/decrese - INCR/DECR
      mouseClick: false, // state to distinguish between click and mousedown event
      actionPopupState: null,
      mergedCellsMap: {}, // state to store the initial and final index to calculate the span of the merged cell for each actions in a day.
      clonedActions: [], // state that stores existing actions required for resetting.
      copiedContent: {}, // state to stores copied cells content
      showWeekdays: true,
      showWeekendDays: true,
      dayWiseAggregateValues: {}, // state that stores if the sum of daily budget/bid values exceeds the total budget/bid value
      storeLastActiveOperationForAction: {} // state to remember the last operation selected for an action.
      // For example: suppose a user selects adjust budget and increases the daily budget for a certain time period
      // and then selects adjust bid, now on coming back to adjust budget
      // the selected operation radio button should remeber the last selected value for adjust budget
      // i.e. increase/decrease
    };
  },
  computed: {
    computedShowExceedsValueWarningMessage() {
      let errorText = '';
      for (let key in this.showExceedsValueWarningMessage) {
        if (!errorText)
          errorText =
            this.showExceedsValueWarningMessage[key]?.[
              this.storeLastActiveOperationForAction[key]
            ];
      }
      return errorText;
    },
    showResetAction() {
      // show reset action button on any action change only
      const actions = this.actions.map(
        ({ isActionValid, overLapping, ...rest }) => ({ ...rest })
      );
      return !isEqual(actions, this.clonedActions);
    },
    currentSelectedTabLabel() {
      return startCase(this.currentSelectedAction.replaceAll('_', ' ')); // required for Adgroup Bid
    },
    dayObj() {
      return this.actionObj[this.currentSelectedAction][this.selectedOperation];
    },
    currentFooterLegends() {
      return this.step.footerLegends[this.selectedOperation];
    },
    exampleText() {
      let text = '';
      if (this.actionPopupState?.value) {
        text = this.step.conditionalMarkupForSelectedOperation[
          this.selectedOperation
        ].exampleText({
          value: this.actionPopupState.value,
          currentSelectedAction: this.currentSelectedAction,
          actionMode: this.actionPopupState.actionMode
        });
      }
      return text;
    },
    disableApplyButton() {
      let result = true;
      result = !(
        this.actionPopupState?.value &&
        this.actionPopupState?.timeValidation &&
        this.actionPopupState?.dateValidation
      );
      return result;
    },
    popupActions() {
      return [
        {
          icon: 'delete',
          text: 'Delete',
          textClasses: 'u-spacing-mt-xxs u-font-size-8 u-color-grey-lighter',
          iconClasses: 'rb-icon--medium u-color-grey-lighter u-cursor-pointer'
        },
        {
          icon: 'copy-to-clipboard',
          text: 'Copy',
          textClasses: `u-spacing-mt-xxs u-font-size-8 u-color-grey-lighter`,
          iconClasses: `rb-icon--medium u-color-grey-lighter u-cursor-pointer`
        },
        {
          icon: 'action_log',
          text: 'Paste',
          textClasses: `u-spacing-mt-xxs u-font-size-8 u-color-grey-lighter`,
          iconClasses: `rb-icon--medium u-color-grey-lighter u-cursor-pointer`
        }
      ];
    },
    getTimezone() {
      const title = this.allStepsData?.find?.((step) => step?.data?.timezone)
        ?.data?.timezone?.title;
      if (this.mode === 'edit' || title) {
        return {
          label: title?.split?.(' ')?.[2],
          tooltiptext: title
        };
      } else {
        return {
          label: 'PST',
          tooltiptext: 'GMT -08:00 PST America/Ensenada'
        };
      }
    },
    currentMergedCellsMap() {
      return this.mergedCellsMap[this.currentSelectedAction][
        this.selectedOperation
      ];
    },
    disablePaste() {
      let result = true;
      // check if there is content to copy to enable paste
      if (
        this.copiedContent[this.currentSelectedAction][
          this.selectedOperation
        ] &&
        this.selectedActionsKeys.length === 1
      ) {
        result = false;
      }
      return result;
    },
    disableCopy() {
      let result = false;
      if (!this.actionPopupState.value) {
        result = true;
      }
      return result;
    },
    selectedActionsKeys() {
      const keys = Object.keys(this.selectedActions);
      return keys;
    },
    showExceedsDaywiseActionsLimitWarningMessage() {
      if (this.selectedActionsKeys.length) {
        const dayWiseActionCount = this.checkIfActionExceedsDaywiseLimit();
        return Object.values(dayWiseActionCount).some((val) => val >= 6)
          ? 'CIQ recommends 6 actions per day as best practice'
          : '';
      }
    }
  },
  watch: {
    actions: {
      handler(nv, ov) {
        if (!isEqual(nv, ov)) {
          this.validateSave(nv);
        }
      },
      deep: true
    },
    currentSelectedAction: function (nv, ov) {
      if (nv !== ov) {
        // creating a key value pair of action and their respective actions
        const actionTypeActionsMap = this.actions.reduce(
          (acc, action) => {
            const actionType = action.selectedActionType.value;
            acc[actionType] = [...(acc?.[actionType] || []), action];
            return acc;
          },
          this.step.actionTypeOptions.reduce((acc, actionType) => {
            acc[actionType.value] = [];
            return acc;
          }, {})
        );
        const actionTypesWithNoActions = [];
        Object.entries(actionTypeActionsMap).forEach(
          ([actionType, actionsArr]) => {
            const isActionPresent = !!actionsArr.length;
            !isActionPresent &&
              actionType !== nv &&
              actionTypesWithNoActions.push(actionType); // push action type with no actions
          }
        );
        this.handleNoActionWarning(actionTypesWithNoActions);
      }
    },
    actionStatus: {
      handler(nv, ov) {
        if (!nv.passStatus && nv.passStatus !== ov.passStatus) {
          nv.actions.forEach(
            (actionIndex) => (this.actions[actionIndex].passStatus = false)
          );
          const index = nv.actions?.[0];
          const actions = cloneDeep(this.actions);
          if (Number(index)) {
            this.populateExistingActions({
              actions,
              clone: false
            });
            this.handleTabsClick(actions[index].selectedActionType.value);
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    document.body.addEventListener('keyup', (event) => {
      this.isShiftKeyPressed = event.shiftKey;
    });
    this.getTimeOptions();
    this.initActionPopupState();
    this.actions = this.step?.data?.actions || [];
    this.init(this.step?.data?.actions || []);
  },
  mounted() {
    // prevents highlighting of selected row label on shift key press
    document.onselectstart = () => {
      return false;
    };
  },
  beforeDestroy() {
    document.body.removeEventListener('keyup', (event) => {
      this.isShiftKeyPressed = event.shiftKey;
    });
    document.onselectstart = () => {
      return true;
    };
  },
  methods: {
    validateSave(actions) {
      // filter to be deleted actions
      const newActions = actions.filter((action) => !action?.deleted);

      // check if there are actions for current selection in UI
      const areThereSelectedActionTypesActions = newActions.filter(
        (action) =>
          action.selectedActionType.value === this.currentSelectedAction &&
          this.selectedOperation.includes(action.selectedOperation.value)
      );

      // check if there are actions other than current active radio selection
      const otherActionsMap = newActions.reduce((acc, action) => {
        const actionType = action.selectedActionType.value;
        const operation = action.selectedOperation.value;
        acc[actionType] = [];
        if (actionType !== this.currentSelectedAction) {
          if (
            this.storeLastActiveOperationForAction[actionType].includes(
              operation
            )
          )
            acc[actionType] = [...acc[actionType], action];
        }
        return acc;
      }, {});

      const areThereOtherActionTypesActions = Object.values(
        otherActionsMap
      ).some((actionArr) => !!actionArr.length);

      // enable save only if no error text is present, current or
      // other than current selections have valid action
      return this.stepValidation(
        !this.computedShowExceedsValueWarningMessage &&
          (!!areThereSelectedActionTypesActions.length ||
            areThereOtherActionTypesActions)
      );
    },
    handleNoActionWarning(actionTypesWithNoActions) {
      // show warning text if action type was visited but no actions were added
      const finalActionTypesWithNoActions = actionTypesWithNoActions.filter(
        (action) => this.isActionTypeVisited[action]
      );
      this.showNoActionWarning = finalActionTypesWithNoActions.length
        ? `The Current ${finalActionTypesWithNoActions
            .map(capitalize)
            .join(
              ','
            )} selected is null. Please allocate appropriate ${finalActionTypesWithNoActions
            .map((val) => capitalize(val) + 's')
            .join(',')} before proceeding further.`
        : '';
    },
    updateActionStoreId({ day, time, actionId = null }) {
      let currentActionId =
        this.actionIdStore[this.currentSelectedAction][this.selectedOperation][
          day
        ][time];

      // if action id for the cell exists means new action is overlapping
      // with existing action, so break it the existing action
      if (currentActionId)
        this.breakExistingActions({
          currentActionId,
          actionType: this.currentSelectedAction,
          operationType: this.selectedOperation,
          day,
          time
        });
      this.actionIdStore[this.currentSelectedAction][this.selectedOperation][
        day
      ][time] = actionId;
    },
    async pasteEventHandler() {
      try {
        let copiedContent =
          sanitizeClipboardData(await helpers.copyFromClipboard()) || [];
        const newActionIdStore = {};
        copiedContent.forEach((copiedValuesInADay) => {
          copiedValuesInADay.forEach((val) => {
            const actionId = val?.split('--')?.[1];
            if (
              actionId &&
              !['null', 'undefined'].includes(actionId) &&
              actionId !== newActionIdStore?.[actionId]
            ) {
              newActionIdStore[actionId] = createUniqueActionId();
            }
          });
        });
        let error = '';

        if (this.selectedActionsKeys?.length) {
          let orderedSelectedActionsArr = orderSelectedActions(
            this.selectedActionsKeys
          );
          orderedSelectedActionsArr = getCellsToPasteCopiedValues(
            orderedSelectedActionsArr,
            copiedContent
          );
          const startDate = new Date();
          const { endDate } = getDefaultDateRange(startDate);
          for (let selectedAction of orderedSelectedActionsArr) {
            const [day, time] = selectedAction.split('-');
            const existingValue =
              this.actionObj[this.currentSelectedAction][
                this.selectedOperation
              ];
            const everyday = [...this.weekdays, ...this.weekendDays];
            const selectedActionsDayIndex = everyday.findIndex(
              (i) => i === day
            );
            error = pasteContent({
              currentSelectedAction: this.currentSelectedAction,
              selectedOperation: this.selectedOperation,
              newActionIdStore,
              copiedContent,
              everyday,
              selectedActionsDayIndex,
              time,
              existingValue,
              startDate,
              endDate,
              error,
              vueRef: this
            });
          }
          this.createMergedCells();
          this.showPopup = false;
          this.selectedActions = {};
          this.formatActionObj();
          this.initActionPopupState();
          return error || 'Pasted!';
        }
        return null;
      } catch (error) {
        return error;
      }
    },
    stepValidation(valid = true) {
      const stepValid = this.clientSideActionsValidation();

      if (valid && stepValid) {
        this.nextStepStatus(false);
        this.stepDataOut();
      } else {
        this.nextStepStatus(true);
      }
    },
    clientSideActionsValidation() {
      let nextStep = false;
      if (this.actions.some((action) => !action.deleted)) {
        // checking whether all actions are not deleted
        nextStep = true;
      }
      return nextStep;
    },
    // computes if a day has more actions than the limit set
    checkIfActionExceedsDaywiseLimit() {
      const dayWiseActionCount = {};
      for (const actionType of this.step.actionTypeOptions) {
        let operations;
        if (actionType.value === this.currentSelectedTab) {
          operations = [this.selectedOperation];
        } else {
          if (this.storeLastActiveOperationForAction[actionType.value]) {
            operations = [
              this.storeLastActiveOperationForAction[actionType.value]
            ];
          } else {
            operations = Object.keys(this.step.defaultOperation);
          }
        }
        for (const selectedOperation of operations) {
          const actionObj =
            this.mergedCellsMap[actionType.value][selectedOperation];
          this.actionPopupState.activeDays.forEach((day) => {
            if (actionObj[day]) {
              dayWiseActionCount[day] =
                (dayWiseActionCount[day] || 0) +
                Object.keys(actionObj[day]).length;
            }
          });
        }
      }
      return dayWiseActionCount;
    },
    // show hide weekdays / weekend time slots
    toggleWeekdaysDisplay(property) {
      this[property] = !this[property];
    },

    reset() {
      const actions = cloneDeep(this.clonedActions);
      this.init(actions);
      this.actions = actions;
      this.showPopup = false;
      this.stepValidation();
    },

    // event handler for copy, paste and delete action
    async handlePopupAction(event, actionName) {
      event.preventDefault();
      let message = '';
      switch (actionName) {
        case 'Delete':
          message = this.delete();
          break;
        case 'Copy':
          message = this.copy(event);
          break;
        case 'Paste':
          message = await this.pasteEventHandler(event);
          break;
        default:
          break;
      }
      if (message) {
        this.$snackbar.open({
          message,
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: ''
        });
      }
    },
    delete() {
      for (const selectedAction in this.selectedActions) {
        const [day, time] = selectedAction.split('-');
        this.actionObj[this.currentSelectedAction][this.selectedOperation][day][
          time
        ] = {
          ...this.actionPopupState,
          actualValue: 0,
          value: null,
          passStatus: true
        };

        this.updateActionStoreId({ day, time });
      }
      this.createMergedCells();
      this.showPopup = false;
      this.selectedActions = {};
      this.formatActionObj();
      this.initActionPopupState();
      this.stepValidation();
      return 'Deleted!';
    },
    copy(event) {
      let copiedContentString = '';
      let currentDay = null;
      const orderedSelectedActionsArr = orderSelectedActions(
        this.selectedActionsKeys
      );
      orderedSelectedActionsArr.forEach((selectedAction, i) => {
        const [day, time] = selectedAction.split('-');
        if (!currentDay) {
          currentDay = day;
        }
        if (currentDay !== day) {
          currentDay = day;
          copiedContentString += '\n';
        } else {
          if (i) copiedContentString += '\t';
        }
        copiedContentString += `${
          this.actionObj[this.currentSelectedAction][this.selectedOperation][
            day
          ][time].actualValue
        }--${
          this.actionIdStore[this.currentSelectedAction][
            this.selectedOperation
          ][day][time]
        }`;
      });
      helpers.copyToClipboardText(copiedContentString, event);
      return 'Copied!';
    },
    actionDateValidation() {
      const startDate = moment(this.actionPopupState.startDate);
      const endDate = moment(this.actionPopupState.endDate);
      if (startDate.diff(endDate) <= 0) {
        this.actionPopupState.dateValidation = true;
      } else {
        this.actionPopupState.dateValidation = false;
      }
    },
    setStartDate(range, context) {
      this.actionPopupState.startDate = range.from;
      this.actionDateValidation();
    },
    setEndDate(range, context) {
      this.actionPopupState.endDate = range.to;
      this.actionDateValidation();
    },
    // common function which is executed on selection of single action through click or drag event
    setDefaultValuesToPopup(selectedActions) {
      let result = {};
      const selectedActionsKeys = Object.keys(selectedActions);
      if (selectedActionsKeys.length === 1) {
        const key = selectedActionsKeys[0];
        const [day, time] = key.split('-');
        const existingValue =
          this.actionObj[this.currentSelectedAction][this.selectedOperation][
            day
          ][time];
        const startDate = existingValue.startDate || new Date();
        const { endDate, endDateRange, startDateRange } = getDefaultDateRange(
          startDate,
          existingValue.endDate
        );
        const [selectedStartTime, selectedEndTime] =
          this.setTimeSelectionOptions(time);

        const actionMode =
          existingValue.actionMode ||
          this.step.defaultOperation[this.selectedOperation];
        const actualValue = getAbsoluteActionValue(
          existingValue.value,
          actionMode
        );
        result = {
          value: existingValue.value || null,
          actualValue,
          selectedStartTime,
          selectedEndTime,
          startDate,
          endDate: existingValue.endDate || endDate,
          startDateRange: existingValue.startDateRange || startDateRange,
          endDateRange: endDateRange,
          actionMode,
          activeDays: [day]
        };
      } else if (selectedActionsKeys.length > 1) {
        const allTimeValues = Array.from(
          new Set(selectedActionsKeys.map((key) => Number(key.split('-')[1])))
        ).sort((a, b) => a - b);
        const defaultStartTime = allTimeValues[0];
        const defaultEndTime = allTimeValues[allTimeValues.length - 1];
        const [selectedStartTime] =
          this.setTimeSelectionOptions(defaultStartTime);
        const selectedEndTime = this.setTimeSelectionOptions(defaultEndTime)[1];
        result = {
          selectedStartTime,
          selectedEndTime,
          activeDays: Array.from(
            new Set(selectedActionsKeys.map((key) => key.split('-')[0]))
          )
        };
      }
      this.initActionPopupState(result);
    },

    initActionPopupState(defaultValue = {}) {
      const startDate = new Date();
      const { startDateRange, endDateRange, endDate } =
        getDefaultDateRange(startDate);
      this.actionPopupState = {
        actionMode: this.step.defaultOperation[this.selectedOperation],
        value: '',
        selectedStartTime: new Date(),
        selectedEndTime: new Date(),
        timeValidation: true,
        activeDays: [],
        startDateRange,
        endDateRange,
        endDate,
        startDate,
        dateValidation: true,
        actualValue: null,
        ...defaultValue
      };
    },
    checkForMaxBid(event, actionObject, key) {
      const value = event.target.valueAsNumber.toFixed(1);
      const maxBid = 100;
      const minBid = 0;
      actionObject[key] = Number(value);
      if (this.actionPopupState.actionMode !== 'INCR' && value > maxBid) {
        actionObject[key] = maxBid;
      }
      if (value < minBid) {
        actionObject[key] = minBid;
      }
      this.actionPopupState.value = actionObject[key];
      this.actionPopupState.actualValue = getAbsoluteActionValue(
        Number(actionObject[key]),
        this.actionPopupState.actionMode
      );
    },
    // transformer to transform UI action state to BE expected payload format and vice versa
    transformerForEqualsOperation(actions = [], type = 'DETRANSFORM') {
      let result = [];
      for (const actionType of this.step.actionTypeOptions) {
        // budget, bid
        const equalsTypeActions = []; // to store assign(operation) budget/bid(actionType) actions
        actions.forEach((action) => {
          if (
            !action?.deleted && // ignore actions with deleted flags
            actionType.value === action.selectedActionType.value // one action type at time
          ) {
            if (action.selectedOperation.value === 'EQUALS') {
              action.actionId && this.deletedActionIdStore.add(action.actionId);
              equalsTypeActions.push(action);
            } else {
              result.push(action);
            }
          }
        });
        // create day-wise action map
        const equalsTypeActionsDayWiseMap = equalsTypeActions.reduce(
          (acc, action) => {
            for (const activeDay of action.activeDays) {
              acc[activeDay.value] = [
                ...(acc[activeDay.value] || []),
                { ...action, activeDays: [activeDay] }
              ];
            }
            return acc;
          },
          {}
        );
        result = this.transformPayload({
          equalsTypeActionsDayWiseMap,
          type,
          result
        });
      }
      return result;
    },

    transformPayload({ equalsTypeActionsDayWiseMap, type, result }) {
      Object.values(equalsTypeActionsDayWiseMap).forEach((dayWiseActions) => {
        // sort day-wise actions to required to calculate the cumulative daywise action sum
        dayWiseActions.sort((a, b) => {
          const aStartTime = Number(a.selectedStartTime.value.split(':')[0]);
          const bStartTime = Number(b.selectedStartTime.value.split(':')[0]);
          return aStartTime - bStartTime;
        });
        const actionValueArray = [];

        dayWiseActions.forEach((hourlyAction) => {
          actionValueArray.push(hourlyAction.newValue);
        });

        const cumulativeActionValueArray = [];

        actionValueArray.forEach((val, index) => {
          val = Number(val);
          // for transforming the action payload to BE expected payload,
          // hourly action value should be prefix sum of day wise actions
          // if actual values for hours 11-1, 4-5, 7-8 are [10, 20 ,30],
          // then it needs to be updated to [10,30,60]
          if (type === 'TRANSFORM') {
            if (index) {
              cumulativeActionValueArray[index] =
                cumulativeActionValueArray[index - 1] + val;
            } else cumulativeActionValueArray[index] = val;
          } else {
            // for detransforming from BE response to UI state,
            // current value is difference of current val and previous val
            let computedVal = null;
            if (index) {
              const currVal = Number(val) || 0;
              const prevVal = Number(actionValueArray[index - 1]) || 0;
              computedVal = currVal - prevVal;
            }
            cumulativeActionValueArray.push(computedVal || val);
          }
        });
        // add the individual action value from computed action value array
        // back to the individual actions
        dayWiseActions.forEach((hourlyAction, index) => {
          hourlyAction.newValue = String(cumulativeActionValueArray[index]);
          hourlyAction.actionId =
            type === 'DETRANSFORM' ? createUniqueActionId() : '';
          result.push(hourlyAction);
        });
      });

      return result;
    },

    init(actions = []) {
      const hoursInADay = Array(24);
      for (const actionType of this.step.actionTypeOptions) {
        // sets initial state from step configs
        Vue.set(
          this.actionObj,
          actionType.value,
          this.step.operationTypes[actionType.value].operation.reduce(
            (result, currentMode) => {
              result[currentMode.type] = this.everyday.reduce((acc, day) => {
                acc[day] = [...hoursInADay.keys()].reduce((acc, time) => {
                  acc[time] = { value: null, passStatus: true };
                  return acc;
                }, {});
                return acc;
              }, {});
              return result;
            },
            {}
          )
        );
        Vue.set(
          this.mergedCellsMap,
          actionType.value,
          this.step.operationTypes[actionType.value].operation.reduce(
            (result, currentMode) => {
              result[currentMode.type] = this.everyday.reduce((acc, day) => {
                acc[day] = null;
                return acc;
              }, {});
              return result;
            },
            {}
          )
        );
        Vue.set(
          this.copiedContent,
          actionType.value,
          this.step.operationTypes[actionType.value].operation.reduce(
            (result, currentMode) => {
              result[currentMode.type] = null;
              return result;
            },
            {}
          )
        );
        Vue.set(this.storeLastActiveOperationForAction, actionType.value, '');
        Vue.set(
          this.actionIdStore,
          actionType.value,
          this.step.operationTypes[actionType.value].operation.reduce(
            (result, currentMode) => {
              result[currentMode.type] = this.everyday.reduce((acc, day) => {
                acc[day] = [...hoursInADay.keys()].reduce((acc, time) => {
                  acc[time] = null;
                  return acc;
                }, {});
                return acc;
              }, {});
              return result;
            },
            {}
          )
        );
        // error text state for different action types
        Vue.set(this.showExceedsValueWarningMessage, actionType.value, {
          [this.step.checkValidityForKey]: ''
        });
        Vue.set(this.isActionTypeVisited, actionType.value, false);
      }
      this.deletedActionIdStore.clear();
      // transforms existing actions in edit mode into how our component stores it in state.
      if (actions.length) {
        this.populateExistingActions({ actions });
        const operation = this.step.operationTypes[
          actions[0].selectedActionType.value
        ].operation.find((i) =>
          i.type.includes(actions[0].selectedOperation.value)
        )?.type;
        if (operation) {
          this.handleTabsClick(actions[0].selectedActionType.value);
          this.handleRadioSelection(operation);
        }
        this.setDefaultOperationForActions(actions);
      }
      this.createMergedCells();
    },
    setDefaultOperationForActions(actions) {
      // set default operation(assign or incr/decr) for all action types
      for (const actionType of this.step.actionTypeOptions) {
        const matchedAction = actions.find(
          (action) => action.selectedActionType.value === actionType.value
        );
        const matchedOperation = this.step.operationTypes[
          actionType.value
        ].operation.find((op) =>
          op.value.includes(matchedAction?.selectedOperation?.value)
        );
        this.storeLastActiveOperationForAction[actionType.value] =
          matchedOperation?.value ||
          this.step.defaultOperationForAction[actionType.value];
      }
    },
    populateExistingActions({ actions, clone = true }) {
      this.cloneActions(clone, actions);
      actions = this.transformerForEqualsOperation(actions, 'DETRANSFORM');
      actions.forEach((action) => {
        const {
          selectedActionType,
          formatType,
          activeDays,
          selectedStartTime,
          selectedEndTime,
          selectedOperation,
          newValue,
          endDate,
          startDate,
          endDateRange,
          startDateRange,
          actionId,
          passStatus = true
        } = action;
        if (formatType === 'PERCENTAGE') {
          const startTime = Number(selectedStartTime?.value?.split?.(':')?.[0]);
          const endTime =
            selectedEndTime?.value === '23:59'
              ? 24
              : Number(selectedEndTime?.value?.split?.(':')?.[0]);

          for (const activeDay of activeDays) {
            for (let i = startTime; i < endTime; i++) {
              const [formattedStartTime, formattedEndTime] =
                this.setTimeSelectionOptions(startTime);
              const operation = this.step.operationTypes[
                selectedActionType.value
              ].operation.find((i) =>
                i.type.includes(selectedOperation.value)
              )?.type;
              if (operation) {
                this.actionObj[selectedActionType.value][operation][
                  activeDay.value
                ][i] = populateAction({
                  selectedOperation,
                  newValue,
                  formattedEndTime,
                  formattedStartTime,
                  endDate,
                  startDate,
                  startDateRange,
                  endDateRange,
                  actionId,
                  passStatus
                });
                // store the action ids of each populated cell
                this.actionIdStore[selectedActionType.value][operation][
                  activeDay.value
                ][i] = actionId;
              }
            }
          }
        }
      });
    },
    cloneActions(clone, actions) {
      if (clone) {
        this.clonedActions = cloneDeep(actions);
      }
    },
    actionTimeValidation() {
      const startTime = moment(
        this.actionPopupState.selectedStartTime?.value,
        'HH:mm'
      );
      const endTime = moment(
        this.actionPopupState.selectedEndTime?.value,
        'HH:mm'
      );
      if (startTime.diff(endTime) <= 0) {
        Vue.set(this.actionPopupState, 'timeValidation', true);
      } else {
        Vue.set(this.actionPopupState, 'timeValidation', false);
      }
    },
    setSelectedActions() {
      const { selectedStartTime, selectedEndTime, activeDays } =
        this.actionPopupState;
      const startTime = Number(selectedStartTime.value.split(':')[0]);
      const endTime =
        selectedEndTime.value === '23:59'
          ? 24
          : Number(selectedEndTime.value.split(':')[0]);
      const uniqueTimeValues = new Set();
      for (let i = startTime; i < endTime; i++) {
        uniqueTimeValues.add(i);
      }
      this.selectedActions = {};
      for (const day of activeDays) {
        for (const time of Array.from(uniqueTimeValues)) {
          Vue.set(this.selectedActions, `${day}-${String(time)}`, true);
        }
      }
    },
    selectEndTime(context, selectedOption) {
      if (!selectedOption?.length) return;
      Vue.set(this.actionPopupState, 'selectedEndTime', selectedOption[0]);
      this.setSelectedActions();
      this.actionTimeValidation();
    },
    selectStartTime(context, selectedOption) {
      if (!selectedOption?.length) return;
      Vue.set(this.actionPopupState, 'selectedStartTime', selectedOption[0]);
      this.setSelectedActions();
      this.actionTimeValidation();
    },
    changeFrequencyList(frequencyIndex) {
      const activeDays = new Set(
        cloneDeep(this.actionPopupState.activeDays || [])
      );
      const currentDay = this.step.frequencyList[frequencyIndex];
      if (!activeDays.has(currentDay.value)) {
        activeDays.add(currentDay.value);
      } else {
        activeDays.delete(currentDay.value);
      }
      Vue.set(this.actionPopupState, 'activeDays', Array.from(activeDays));
      this.setSelectedActions();
    },
    getTimeStops(start, end) {
      let startTime = moment(start, 'HH:mm');
      let endTime = moment(end, 'HH:mm');
      if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day');
      }
      let timeStops = [];
      while (startTime.isBefore(endTime)) {
        timeStops.push(moment(startTime).format('HH:mm'));
        startTime.add(60, 'minutes');
      }
      return timeStops;
    },
    getTimeOptions() {
      const hoursFormatList = this.getTimeStops('00:00', '24:00');
      hoursFormatList.push('23:59');
      for (const hour of hoursFormatList) {
        const timeObj = {
          title: moment(hour, ['HH:mm']).format('hh:mm A'),
          value: hour
        };
        this.timeZoneOptions.push(timeObj);
      }
    },
    setTimeSelectionOptions(hour) {
      const currentTime = moment().hour(hour).format('HH:mm');
      const defaultIndex = this.timeZoneOptions.findIndex((element) => {
        const elementMomentTime = moment(element.value, ['HH:mm']).format('HH');
        const currentMomentTime = moment(currentTime, ['HH:mm']).format('HH');
        if (elementMomentTime === currentMomentTime) {
          return true;
        }
      });
      return [
        this.timeZoneOptions[defaultIndex],
        this.timeZoneOptions[defaultIndex + 1]
      ];
    },
    transformIntoMergedCellState({ results, actionType, operation, day }) {
      return results.map((result) => {
        const { initialIndex, finalIndex, value } = result;
        if (value !== null) {
          this.mergedCellsMap[actionType.value][operation.type][day] = {
            ...(this.mergedCellsMap[actionType.value][operation.type][day] ||
              {}),
            [initialIndex]: {
              initialIndex,
              span: finalIndex - initialIndex,
              finalIndex,
              value: value?.split?.('--')?.[0]
            }
          };
        }
      });
    },
    createTimeSlotString({ operation, day, time, actionType, currentAction }) {
      return currentAction[operation.type][day][time]?.actualValue &&
        this.actionIdStore[actionType.value][operation.type][day]?.[time]
        ? `${currentAction[operation.type][day][time]?.actualValue}--${
            this.actionIdStore[actionType.value][operation.type][day]?.[time]
          }`
        : null;
    },
    createMergedCells() {
      for (const actionType of this.step.actionTypeOptions) {
        const currentAction = this.actionObj[actionType.value];
        const operations = this.step.operationTypes[actionType.value].operation;
        for (const operation of operations) {
          for (const day of this.everyday) {
            this.mergedCellsMap[actionType.value][operation.type][day] = null;
            const timeSlotValuesArray = [];
            for (let time = 0; time < 24; time++) {
              timeSlotValuesArray.push(
                this.createTimeSlotString({
                  timeSlotValuesArray,
                  operation,
                  day,
                  time,
                  actionType,
                  currentAction
                })
              );
            }
            let results = createCustomSubArrays(
              timeSlotValuesArray,
              timeSlotValuesArray.length
            );
            results = this.transformIntoMergedCellState({
              results,
              actionType,
              operation,
              day
            });
          }
        }
      }
      this.createDayWiseActionCount();
    },
    createDayWiseActionCount() {
      this.dayWiseAggregateValues = {};
      if (this.selectedOperation === this.step.checkValidityForKey) {
        this.showExceedsValueWarningMessage[this.currentSelectedAction][
          this.selectedOperation
        ] = '';
        Object.entries(
          this.mergedCellsMap[this.currentSelectedAction][
            this.selectedOperation
          ]
        ).forEach(([day, values]) => {
          if (values) {
            let value = 100;
            Object.keys(values).forEach((time) => {
              value = value - Number(values[time].value || 0);
            });
            if (!this.dayWiseAggregateValues[this.currentSelectedAction]) {
              this.dayWiseAggregateValues[this.currentSelectedAction] = {};
            }
            if (
              !this.dayWiseAggregateValues[this.currentSelectedAction][
                this.selectedOperation
              ]
            ) {
              this.dayWiseAggregateValues[this.currentSelectedAction][
                this.selectedOperation
              ] = {};
            }
            this.dayWiseAggregateValues[this.currentSelectedAction][
              this.selectedOperation
            ][day] = value !== 0;
          }
        });

        this.createErrorText();
      }
    },
    createErrorText() {
      // show error text if day wise action
      // allocation doesn't sum up to 100%
      this.showExceedsValueWarningMessage[this.currentSelectedAction][
        this.selectedOperation
      ] = Object.values(
        this.dayWiseAggregateValues?.[this.currentSelectedAction]?.[
          this.selectedOperation
        ] || []
      ).some(Boolean)
        ? `Allocation of ${this.currentSelectedAction} for a day should be exactly 100%. Please adjust the ${this.currentSelectedAction}s.`
        : '';
    },
    toggleActionMode(val) {
      Vue.set(this.actionPopupState, 'actionMode', val);
      this.actionPopupState.actualValue = getAbsoluteActionValue(
        Number(this.actionPopupState.value),
        val
      );
    },
    onDrag(selectedItems) {
      if (!this.mouseClick && !this.isShiftKeyPressed) {
        this.selectedActions = {};
        this.selectedActions = selectedItems.reduce((acc, item) => {
          if (item.$el.dataset.item.includes('everyday')) {
            const time = item.$el.dataset.item.split('-')[1];
            this.everyday.forEach((day) => {
              acc[`${day}-${time}`] = true;
            });
          } else {
            if (item.$el.dataset.item.split('-').length === 2) {
              acc[item.$el.dataset.item] = true;
            }
          }
          return acc;
        }, {});
      }
      this.mouseClick = false;
    },
    onDragEnd(event) {
      if (Object.keys(this.selectedActions).length) {
        this.showPopup = true;
        this.$nextTick(() => {
          this.$refs?.inputValue?.focus();
        });
        this.popupStyle = {
          '--mouse-x': `${event.clientX + 30}px`,
          '--mouse-y': `${event.clientY - 30}px`
        };
        this.setDefaultValuesToPopup(this.selectedActions);
      }
    },
    handleRadioSelection(e) {
      this.selectedOperation = e;
      this.actionPopupState.actionMode = this.step.defaultOperation[e];
      this.actionPopupState.actualValue = getAbsoluteActionValue(
        Number(this.actionPopupState.value),
        this.actionPopupState.actionMode
      );
      this.storeLastActiveOperationForAction[this.currentSelectedAction] =
        this.selectedOperation;
      this.createDayWiseActionCount();
      this.formatActionObj();
      this.validateSave(this.actions);
    },
    handleTabsClick(tab) {
      tab = tab?.toLowerCase()?.replaceAll(' ', '_'); // for adgroup_bid
      this.currentSelectedAction = tab;
      this.selectedOperation =
        this.storeLastActiveOperationForAction[this.currentSelectedAction] ||
        this.step.defaultOperationForAction[tab];
      this.storeLastActiveOperationForAction[this.currentSelectedAction] =
        this.selectedOperation;
      this.actionPopupState.actionMode =
        this.step.defaultOperation[this.selectedOperation];
      this.isActionTypeVisited[this.currentSelectedAction] = true;
      this.createDayWiseActionCount();
      this.validateSave(this.actions);
    },
    openPopupActionHandler(event, key) {
      this.mouseClick = true;
      event.stopPropagation();
      this.isShiftKeyPressed = event.shiftKey;
      if (!event.shiftKey) {
        this.selectedActions = {};
        this.showPopup = false;
      }
      if (key.includes('everyday')) {
        const time = key.split('-')[1];
        this.everyday.forEach((day) => {
          Vue.set(this.selectedActions, `${day}-${time}`, true);
        });
      } else {
        if (key.split('-').length === 2) {
          if (this.selectedActionsKeys.includes(key)) {
            delete this.selectedActions[key];
          } else {
            Vue.set(this.selectedActions, key, true);
          }
        }
      }
      if (!this.selectedActionsKeys.length) {
        this.showPopup = false;
        return;
      }
      this.showPopup = true;
      this.$nextTick(() => {
        this.$refs?.inputValue?.focus();
      });
      this.popupStyle = {
        '--mouse-x': `${event.clientX + 30}px`,
        '--mouse-y': `${event.clientY - 30}px`
      };
      this.setDefaultValuesToPopup(this.selectedActions);
    },
    closePopup() {
      this.selectedActions = {};
      this.showPopup = false;
      this.initActionPopupState();
    },
    handleInput(val) {
      Vue.set(this.actionPopupState, 'value', val);
    },
    createActionsObj(actionObj) {
      let actions = [];
      for (const actionType of this.step.actionTypeOptions) {
        const currentAction = actionObj[actionType.value];
        const operations = this.step.operationTypes[actionType.value].operation;
        for (const operation of operations) {
          for (const day of this.everyday) {
            const timeSlotValuesArray = [];
            for (let i = 0; i < 24; i++) {
              const { value, actionMode, startDate, endDate } =
                currentAction[operation.type][day][i];
              const actionId =
                this.actionIdStore[actionType.value][operation.type][day][i];
              timeSlotValuesArray.push(
                `${actionType.value}--${actionMode}--${value}--${new Date(
                  startDate
                ).toDateString()}--${new Date(
                  endDate
                ).toDateString()}--${actionId}`
              );
            }
            const results = createCustomSubArrays(
              timeSlotValuesArray,
              timeSlotValuesArray.length
            );

            actions.push(
              ...results
                .filter((result) => !result.value.includes('null'))
                .map((result) => ({
                  value: `${result.value}--${result.initialIndex}--${result.finalIndex}`,
                  day
                }))
            );
          }
        }
      }
      actions = actions.reduce((acc, action) => {
        if (acc[action.value]) {
          acc[action.value].push(action.day);
        } else {
          acc[action.value] = [action.day];
        }
        return acc;
      }, {});
      return actions;
    },
    formatActionObj() {
      let newActions = this.createActionsObj(this.actionObj);
      const clonedActions = cloneDeep(this.clonedActions);
      let toBeDeletedActions = clonedActions
        .filter(({ actionId }) => this.deletedActionIdStore.has(actionId))
        .map((action) => ({ ...action, deleted: true }));
      let finalActions = [];
      for (const [action, days] of Object.entries(newActions)) {
        let [
          selectedActionType,
          operation,
          newValue,
          startDate,
          endDate,
          actionId,
          startTime,
          endTime
        ] = action.split('--');
        startTime = Number(startTime);
        endTime = Number(endTime) + 1;
        if (
          !this.storeLastActiveOperationForAction[selectedActionType] ||
          this.storeLastActiveOperationForAction[selectedActionType].includes(
            operation
          )
        ) {
          const id = getActionId(actionId);
          const actionObj = {
            selectedActionType: { value: selectedActionType },
            selectedMode: { value: 'PERCENTAGE' },
            selectedOperation: { value: operation },
            newValue,
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            selectedStartTime: {
              value: moment({ hours: startTime, minutes: 0 }).format('HH:mm')
            },
            selectedEndTime: {
              value: moment({
                hours: Number(endTime) === 24 ? 23 : endTime,
                minutes: Number(endTime) === 24 ? 59 : 0
              }).format('HH:mm')
            },
            activeDays: days.map((day) => ({ value: day })),
            actionId: this.deletedActionIdStore.has(id) ? '' : id,
            formatType: 'PERCENTAGE'
          };
          finalActions.push(actionObj);
        }
      }
      finalActions = [
        ...toBeDeletedActions,
        ...this.transformerForEqualsOperation(finalActions, 'TRANSFORM')
      ];
      this.actions = cloneDeep(finalActions);
      this.checkIfActionIsValid();
    },
    checkIfActionIsValid() {
      const isInvalid = Object.keys(this.dayWiseAggregateValues).reduce(
        (acc, tab) => {
          if (this.dayWiseAggregateValues?.[tab]?.[this.selectedOperation]) {
            if (!acc) {
              acc = Object.values(
                this.dayWiseAggregateValues[tab][this.selectedOperation]
              ).some(Boolean);
            }
          }
          return acc;
        },
        false
      );
      if (isInvalid) {
        this.nextStepStatus(true);
        return;
      }
      this.stepDataOut();
    },
    nextStepStatus(value) {
      this.$emit('nextStep', value, this.index);
    },
    formatStepData() {
      const obj = {
        actions: this.actions
      };
      return obj;
    },
    stepDataOut() {
      const obj = this.formatStepData();
      obj.index = this.index;
      this.nextStepStatus(false);
      this.$emit('stepDataOut', obj);
    },
    breakExistingActions({
      currentActionId,
      actionType,
      operationType,
      day,
      time
    }) {
      // find cells with same action id
      const that = this;
      this.deletedActionIdStore.add(currentActionId); // store id of actions to be deleted
      const cellsWithSameActionId = Object.keys(
        this.actionIdStore[actionType][operationType]
      ).reduce((acc, currentDay) => {
        acc[currentDay] = Object.keys(
          that.actionIdStore[actionType][operationType][currentDay]
        ).filter(
          (timeslot) =>
            that.actionIdStore[actionType][operationType][currentDay][
              timeslot
            ] === currentActionId
        );
        return acc;
      }, {});
      // find the old action's non overlapping days with the new action's day
      const nonOverlappingDays = Object.keys(cellsWithSameActionId).filter(
        (actionDay) => actionDay !== day
      );

      // Now we create new action id for the non overlapping days and assign them to the cells
      const newActionIdForNonOverlappingDays = createUniqueActionId();
      nonOverlappingDays.forEach((d) => {
        Object.keys(cellsWithSameActionId[d]).forEach((nonOverlappingTime) => {
          cellsWithSameActionId[d][nonOverlappingTime] =
            newActionIdForNonOverlappingDays;
        });
      });

      // Now we handle breaking and assigning new action id based on the position of overlap
      const overlappingDay = Object.keys(cellsWithSameActionId).find(
        (actionDay) => actionDay === day
      );
      const newActionIdForBeforeOverlappedTime = createUniqueActionId();
      const newActionIdForAfterOverlappedTime = createUniqueActionId();

      // So if previous action was from 2 to 6 PM and new action time is 4, then add a new action id for cells 2 and 3,
      // and another action id for cells 5 and 6
      Object.keys(cellsWithSameActionId[overlappingDay]).forEach(
        (overlappingDaysTime) => {
          overlappingDaysTime = Number(overlappingDaysTime);
          time = Number(time);
          if (overlappingDaysTime < time) {
            cellsWithSameActionId[overlappingDay][overlappingDaysTime] =
              newActionIdForBeforeOverlappedTime;
          } else if (overlappingDaysTime > time) {
            cellsWithSameActionId[overlappingDay][overlappingDaysTime] =
              newActionIdForAfterOverlappedTime;
          } else {
            cellsWithSameActionId[overlappingDay][overlappingDaysTime] = null;
          }
        }
      );
    },
    applyAction() {
      const { value, actionMode, startDate, endDate, actualValue } =
        this.actionPopupState;
      const actionId = createUniqueActionId();
      for (const selectedAction in this.selectedActions) {
        const [day, time] = selectedAction.split('-');
        this.updateActionStoreId({ day, time, actionId });
        this.actionObj[this.currentSelectedAction][this.selectedOperation][day][
          time
        ] = {
          ...this.actionObj[this.currentSelectedAction][this.selectedOperation][
            day
          ][time],
          value,
          startDate,
          endDate,
          actionMode,
          passStatus: true,
          actualValue
        };
      }
      this.storeLastActiveOperationForAction[this.currentSelectedAction] =
        this.selectedOperation;
      this.createMergedCells();
      this.showPopup = false;
      this.selectedActions = {};
      this.formatActionObj();
      this.initActionPopupState();
    }
  }
};
</script>

<style lang="css">
.action-scheduler .day-row:hover .merged-slot {
  display: none;
}
.action-scheduler .merged-slot {
  z-index: 1;
}
.action-scheduler .merged-slot:hover {
  display: none;
}
.action-scheduler .vdp-datepicker__calendar {
  top: -300px;
  left: 5px;
}
.action-scheduler .valueDropdown {
  min-width: 154px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
  background-color: white;
}
.action-scheduler .valueDropdown.typeSelect {
  width: 154px;
}
.action-scheduler .time-drop-down {
  min-width: 80px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
  background-color: white;
}
.action-scheduler .time-drop-down.typeSelect {
  width: 112px;
}
.action-scheduler .time-drop-down .u-font-size-7 {
  font-size: 1.3rem;
}
.action-scheduler .rb-input {
  outline: 0;
  line-height: 1;
  width: 100%;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 1px solid #e9eaeb;
}
.action-scheduler .rb-input::placeholder {
  color: #caccce;
}
.action-scheduler .rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
}
.action-scheduler .rb-input.percentage-input {
  border: 0;
}
.action-scheduler .dayChip {
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #e9eaeb;
}
.action-scheduler .dayChip.active {
  background-color: #007cf6;
  border: 0;
  color: white;
}
.action-scheduler .errorHighlight {
  border: 1px solid #d7263d;
}
.action-scheduler .create-action {
  color: #8b8f93;
}
.action-scheduler .create-action:hover {
  color: #4b5158;
}
.action-scheduler .inputContainer {
  background-color: white;
  border-radius: 2px;
  border: 1px solid #e9eaeb;
}
.action-scheduler .appender {
  line-height: normal;
  border: 0;
}
.action-scheduler .appender.currency-symbol {
  border-right: 0;
}
.action-scheduler .time-slots-container .time-slot:not(:nth-child(6n + 1)) {
  visibility: hidden;
  font-weight: 400;
  color: #2b333b;
}
.action-scheduler
  .time-slots-container:hover
  .time-slot:not(:nth-child(6n + 1)) {
  visibility: visible;
  color: #8b8f93;
}
.action-scheduler .action-slot {
  border-radius: 0.4rem;
  justify-self: center;
  align-self: center;
}
.action-scheduler .action-slot-row-height {
  min-height: 3.4rem;
  height: 100%;
}
.action-scheduler .action-slot-width {
  min-width: 3.4rem;
  justify-self: center;
}
.action-scheduler .icon-circle {
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.action-scheduler .icon-circle > .icon-arrow-down {
  margin-top: 0.2rem;
}
.action-scheduler .action-items-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-inline: -0.8rem;
}
.action-scheduler .copy-to-clipboard {
  grid-column: 1;
}
.action-scheduler .isActive {
  grid-column: 1/3;
}
.action-scheduler .popup-dropdown-menu {
  min-width: 240px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(43, 51, 59, 0.2);
}
.action-scheduler .popup--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4b5158;
  padding: 1.6rem;
  line-height: 0;
}
.action-scheduler .action-popup-footer .rb-button--filled {
  background: #fff !important;
  color: #007cf6 !important;
}
.action-scheduler .action-popup-footer .rb-button--filled:hover {
  background: #e6e6e6 !important;
  color: #007cf6 !important;
}
.action-scheduler .action-popup-footer .rb-button--filled[disabled] {
  background: #aaadb1;
}
.action-scheduler .popup-container {
  position: fixed;
  margin: 0;
  left: 0;
  top: 0;
  z-index: 400;
}
.action-scheduler .time-slot-wrapper {
  justify-self: center;
  align-self: center;
  padding: 0.4rem;
}
.action-scheduler .footer-legend-icon {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 4px;
}
.action-scheduler .frequency-list-bubbles + .frequency-list-bubbles {
  margin-left: 0.8rem;
}
.action-scheduler .action-container {
  display: flex;
  flex-direction: column;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
.action-scheduler .action-container:nth-child(1) {
  padding-left: 0;
}
.action-scheduler .action-container + .action-container {
  border-left: 1px solid #e9eaeb;
}
</style>
