import { ref, onMounted, getCurrentInstance } from '@vue/composition-api';
import { isElementOverflowing } from '@/utils/helpers/utils';

export function useCollapsibleComponent() {
  const overflowing = ref(false);
  const collapsed = ref(false);
  const currentInstance = getCurrentInstance();

  function toggleCollapse() {
    collapsed.value = !collapsed.value;
  }

  onMounted(() => {
    overflowing.value = isElementOverflowing(currentInstance.proxy.$el);
    collapsed.value = overflowing.value;
  });

  return { overflowing, collapsed, toggleCollapse };
}
