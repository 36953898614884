import HttpLayer from '@/utils/services/http-layer';
import { cloneDeep } from 'lodash';
import transformer from '@/utils/services/data-transformer';

const APIConfig = {
  cubeName: 'walmart_sku_catalog',
  getLatestAvailableInsteadOfRollup: false,
  timeseriesEnabled: false,
  pvpenabled: false,
  yoyenabled: false,
  measuresList: [
    'product_title',
    'buy_box_pct',
    'availability_pct',
    'availability_status',
    'brand_name',
    'offer_price',
    'image_url',
    'product_url'
  ],
  groupByDimensionsList: ['sku'],
  orderByList: [],
  where: {
    dimensionNameValueList: []
  },
  limit: 10,
  page: 1
};

const formSKUList = (skus, rightTableRows) => {
  const rightTableSKUs = rightTableRows.map(
    (rightTableSKU) => rightTableSKU.sku
  );
  const validSKUs = skus.filter((sku) => !rightTableSKUs?.includes(sku.trim()));
  let skuList = '';
  for (const sku of validSKUs) {
    skuList += `'${sku.trim()}',`;
  }
  skuList = skuList.slice(0, -1);
  return skuList;
};

const addSkuOnInput = (data, transformRows = false) => {
  const skuList = formSKUList(data.keywords, data?.ref?.rightTableRows);
  const APIConfigDeepClone = cloneDeep(APIConfig);
  let result = [];
  if (skuList === '') {
    data.enterListRef.keywordTextInput = '';
    data.ref.$snackbar.open({
      message: 'SKU(s) in the list are already added',
      duration: 6000,
      buttonColor: '#f5d908',
      actionText: ''
    });
    return;
  }
  delete APIConfigDeepClone.limit;
  delete APIConfigDeepClone.page;
  APIConfigDeepClone.cubeName = data?.cubeName;
  APIConfigDeepClone.where = {
    dimensionNameValueList: [
      {
        dimensionName: 'sku',
        dimensionValue: skuList,
        operator: 'IN'
      }
    ]
  };
  data.ref.rightTableLoad = true;
  HttpLayer.post({
    APIData: APIConfigDeepClone
  })
    .then((response) => {
      result = transformer.mergeResultDimension(response.data, true) || [];
      if (transformRows) {
        result.forEach((rowItem) => {
          if (!rowItem?.suggested_bid) {
            rowItem.newBid = 0.2;
            rowItem.suggestedBidToShow = '-';
          } else {
            rowItem.suggestedBidToShow = rowItem?.suggested_bid;
            rowItem.newBid = rowItem?.suggested_bid;
          }
        });
      }
      data.enterListRef.keywordTextInput = '';
    })
    .finally(() => {
      const invalidSkus = skuList
        .split(',')
        .map((sku) => sku.replaceAll(/[']+/g, ''))
        .filter((sku) => result.findIndex((item) => item.sku === sku) === -1);
      data.ref.$snackbar.open({
        message: result?.length
          ? 'Added SKU(s) from the list'
          : 'Ineligible/Invalid SKU(s) were not added',
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ''
      });
      data.ref.addEntityFromRawObjectArray(result);
      data.ref.rightTableLoad = false;
      if (invalidSkus.length) {
        data.enterListRef.keywordTextInput = invalidSkus.join('\n');
      }
    });
};

export { APIConfig, addSkuOnInput };
