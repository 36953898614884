export const skuData = () => ({
  data: {
    renderParams: {
      name: 'asin_name',
      asin: 'asin',
      imageUrl: 'latest_image_url',
      productPageUrl: 'latest_product_page_url',
      showImage: true,
      enableSDP: true
    }
  }
});

export const tableSwitchData = () => ({
  data: {
    trueVal: 'enabled',
    falseVal: 'disabled',
    overrideToolTipText: '',
    extraClass: ''
  }
});
