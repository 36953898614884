const config = () => {
  return {
    widgets: {
      contentChangeColumns: {
        columnDefs: [
          {
            headerComponentFramework: 'genericTableHeader',
            title: 'Title',
            headerName: 'ASIN',
            headerComponentParams: {
              toolTipText: 'ASIN'
            },
            field: 'asin',
            cellRendererParams: {
              keyType: 'string'
            },
            cellRendererFramework: 'genericTableCell',
            maxWidth: 400,
            minWidth: 250,
            pinned: 'left'
          },
          {
            headerComponentFramework: 'genericTableHeader',
            title: 'Title',
            headerName: 'Product Title',
            headerComponentParams: {
              toolTipText: 'Product Title'
            },
            field: 'title',
            cellRendererParams: {
              keyType: 'string'
            },
            cellRendererFramework: 'genericTableCell',
            maxWidth: 400,
            minWidth: 250
          },
          {
            headerComponentFramework: 'genericTableHeader',
            title: 'Title',
            headerName: 'Bullet Description',
            headerComponentParams: {
              toolTipText: 'Bullet Description'
            },
            field: 'bullet_description',
            cellRendererParams: {
              keyType: 'string'
            },
            cellRendererFramework: 'genericTableCell',
            maxWidth: 400,
            minWidth: 250
          },
          {
            headerComponentFramework: 'genericTableHeader',
            title: 'Title',
            headerName: 'Product Description',
            headerComponentParams: {
              toolTipText: 'Product Description'
            },
            field: 'description',
            cellRendererParams: {
              keyType: 'string'
            },
            cellRendererFramework: 'genericTableCell',
            maxWidth: 400,
            minWidth: 250
          }
        ]
      },
      APIConfig: {
        cubeName: 'client_content_info',
        measuresList: ['title', 'description', 'bullet_description'],
        groupByDimensionsList: ['asin']
      }
    }
  };
};

export default {
  config: config
};
