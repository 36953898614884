import BaseWidgetService from '@/components/pages/insights/amazon/cva/services/BaseWidgetService.js';
import HttpService from '@/utils/services/http-service';
import {
  getColumnDefinition,
  getNodePayload,
  showSearchVolumeColumn,
  rem1V2ConfigEnabled,
  getRetailerName
} from '@/components/pages/insights/amazon/cva/utils';

export default class CategoryPerformanceService extends BaseWidgetService {
  tableData = null;
  getColumnDefs() {
    if (rem1V2ConfigEnabled()) {
      const columns = [
        {
          headerName: 'Category',
          field: 'BROWSE_NODE',
          keyType: 'string',
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            keyType: 'string',
            toolTipText:
              'Category based on ' + getRetailerName() + ' browse node'
          },
          cellStyle: {
            textTransform: 'capitalize'
          },
          cellRendererFramework: 'DynamicCellComponent',
          cellRendererParams: {
            component: 'CategoryCell',
            paramsToProps: (params) => {
              return {
                categoryText: params.data.BROWSE_NODE,
                tooltip: {
                  tooltipText: params.data.node
                },
                categoryTextClass:
                  params.data.rowType === 'category'
                    ? 'u-white-space-normal'
                    : 'u-spacing-ml-l',
                rowType: params.data.rowType || 'category',
                hasSubCategories: params.data.hasSubCategories,
                expandButtonClickFunction: (isExpanded) => {
                  params.context?.componentParent?.handleCategoryExpandButton(
                    params,
                    isExpanded
                  );
                }
              };
            }
          }
        },
        ...(showSearchVolumeColumn()
          ? [
              {
                headerName: 'Avg. Daily Search Volume',
                field: 'search_vol_post_value',
                keyType: 'number',
                cellStyle: {
                  padding: '0.3em 0.3em 0.3em 0'
                },
                sort: 'desc',
                headerComponentFramework: 'genericTableHeader',
                headerComponentParams: {
                  keyType: 'number',
                  alignHeader: 'left',
                  toolTipText:
                    'Daily search volume for the search terms in the category'
                },
                cellRendererFramework: 'progressTableComponent',
                cellRendererParams: {
                  eventMap: {},
                  keyType: 'number',
                  showOnUI: true,
                  type: 'PROGRESS',
                  showBarColorsOnValueSign: true,
                  handleNull: true,
                  hasFillValue: true,
                  unit: 'volume',
                  keys: {
                    keyOne: 'search_vol_post_value',
                    keyTwo: 'search_vol_pre_value',
                    pvp: 'search_volume_pvp',
                    fillVal: 'search_volume_fill_percentage',
                    greaterThanTextColor: '#3ea95e',
                    lesserThanTextColor: '#d7263d',
                    greaterThanColor: '#ECF7EF',
                    lesserThanColor: '#FBEAEC',
                    lesserThanIcon: 'sort-desc',
                    greaterThanIcon: 'sort-asc',
                    equalToColor: '#fafafa',
                    equalToTextColor: '#8b8f93',
                    valueType: 'volume',
                    hideComparator: true
                  }
                }
              }
            ]
          : []),
        {
          headerName: 'Share Of Voice',
          field: 'sov_post_value_percentage',
          keyType: 'number',
          minWidth: '500px',
          headerComponentFramework: 'genericTableHeader',
          cellStyle: {
            padding: '0.3em 0.3em 0.3em 0'
          },
          sort: showSearchVolumeColumn() ? undefined : 'desc',
          headerComponentParams: {
            keyType: 'number',
            alignHeader: 'left',
            toolTipText: 'Your total share of voice in the category'
          },
          cellRendererFramework: 'progressTableComponent',
          cellRendererParams: {
            handleNullTooltip:
              '"Change value" is unavailable for this time period',
            eventMap: {},
            keyType: 'number',
            showOnUI: true,
            type: 'PROGRESS',
            showBarColorsOnValueSign: true,
            handleNull: true,
            hasFillValue: true,
            unit: 'percent',
            keys: {
              keyOne: 'sov_post_value_percentage',
              keyTwo: 'sov_pre_value_percentage',
              pvp: 'sov_pvp',
              fillVal: 'sov_fill_percentage',
              greaterThanTextColor: '#3ea95e',
              lesserThanTextColor: '#d7263d',
              greaterThanColor: '#ECF7EF',
              lesserThanColor: '#FBEAEC',
              lesserThanIcon: 'sort-desc',
              greaterThanIcon: 'sort-asc',
              equalToColor: '#fafafa',
              equalToTextColor: '#8b8f93',
              valueType: 'percentage',
              hideComparator: true
            }
          }
        },
        {
          headerName: 'Top Brands',
          field: 'topBrands',
          key: 'topBrands',
          keyType: 'string',
          type: 'string',
          minWidth: 350,
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            toolTipText: 'Top brands present in the category',
            enableSorting: false
          },
          cellRendererFramework: 'DynamicCellComponent',
          cellRendererParams: {
            eventMap: {},
            cellAlignment: 'u-text-align-right',
            component: 'BrandRankCell',
            paramsToProps: (params) => {
              let brandLogo = ['brand_1', 'brand_2', 'brand_3'];
              let baseCDNUrl = 'https://cdn.rboomerang.com/images/brands/';
              let imgData = brandLogo
                .filter((img) => {
                  return params.data[img] !== undefined;
                })
                .map((imgEle, imgIndex) => {
                  let brandName = params.data[imgEle]
                    ?.trim('')
                    .replaceAll(' ', '_');
                  let clientFlag = params.data[imgEle + '_client_flag'];
                  let brandUrl = baseCDNUrl + brandName + '.jpeg';
                  return {
                    imgUrl: brandUrl,
                    brandRank: imgIndex + 1,
                    brandName: brandName,
                    clientFlag
                  };
                });
              return {
                data: imgData,
                onHoverParams: {
                  showHoverText: true,
                  hoverText: 'View Competitors',
                  hoverClickFunction: () => {
                    params.context?.componentParent?.onCategoryClick?.(params);
                  }
                }
              };
            }
          }
        }
      ];
      return columns.map(getColumnDefinition);
    } else {
      const columns = [
        {
          headerName: 'Category',
          field: 'BROWSE_NODE',
          keyType: 'string',
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            keyType: 'string',
            toolTipText: 'Category based on Amazon browse node'
          },
          cellStyle: {
            textTransform: 'capitalize'
          },
          cellRendererFramework: 'TextWithButton',
          cellRendererParams: {
            renderParams: {
              textClass: 'u-white-space-normal'
            },
            tooltip: true,
            customTooltip: (params) => {
              return params.data.node;
            }
          }
        },
        {
          headerName: 'Avg. Daily Search Volume',
          field: 'search_vol_post_value',
          cellStyle: {
            padding: '0.3em 0.3em 0.3em 0'
          },
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            keyType: 'number',
            alignHeader: 'left',
            toolTipText:
              'Daily search volume for the search terms in the category'
          },
          sort: 'desc',
          cellRendererFramework: 'progressTableComponent',
          cellRendererParams: {
            eventMap: {},
            keyType: 'number',
            showOnUI: true,
            type: 'PROGRESS',
            showBarColorsOnValueSign: true,
            handleNull: true,
            hasFillValue: true,
            unit: 'volume',
            keys: {
              keyOne: 'search_vol_post_value',
              keyTwo: 'search_vol_pre_value',
              pvp: 'search_volume_pvp',
              fillVal: 'search_volume_fill_percentage',
              greaterThanTextColor: '#3ea95e',
              lesserThanTextColor: '#d7263d',
              greaterThanColor: '#ECF7EF',
              lesserThanColor: '#FBEAEC',
              lesserThanIcon: 'sort-desc',
              greaterThanIcon: 'sort-asc',
              equalToColor: '#fafafa',
              equalToTextColor: '#8b8f93',
              valueType: 'volume',
              hideComparator: true
            }
          }
        },
        {
          headerName: 'Share Of Voice',
          field: 'sov_post_value_percentage',
          minWidth: '500px',
          headerComponentFramework: 'genericTableHeader',
          cellStyle: {
            padding: '0.3em 0.3em 0.3em 0'
          },
          sort: 'desc',
          headerComponentParams: {
            keyType: 'number',
            alignHeader: 'left',
            toolTipText: 'Your total share of voice in the category'
          },
          cellRendererFramework: 'progressTableComponent',
          cellRendererParams: {
            handleNullTooltip:
              '"Change value" is unavailable for this time period',
            eventMap: {},
            keyType: 'number',
            showOnUI: true,
            type: 'PROGRESS',
            showBarColorsOnValueSign: true,
            handleNull: true,
            hasFillValue: true,
            unit: 'percent',
            keys: {
              keyOne: 'sov_post_value_percentage',
              keyTwo: 'sov_pre_value_percentage',
              pvp: 'sov_pvp',
              fillVal: 'sov_fill_percentage',
              greaterThanTextColor: '#3ea95e',
              lesserThanTextColor: '#d7263d',
              greaterThanColor: '#ECF7EF',
              lesserThanColor: '#FBEAEC',
              lesserThanIcon: 'sort-desc',
              greaterThanIcon: 'sort-asc',
              equalToColor: '#fafafa',
              equalToTextColor: '#8b8f93',
              valueType: 'percentage',
              hideComparator: true
            }
          }
        },
        {
          headerName: 'Top Brands',
          field: 'topBrands',
          key: 'topBrands',
          type: 'string',
          minWidth: 350,
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            toolTipText: 'Top brands present in the category'
          },
          cellRendererFramework: 'DynamicCellComponent',
          cellRendererParams: {
            eventMap: {},
            cellAlignment: 'u-text-align-right',
            component: 'BrandRankCell',
            paramsToProps: (params) => {
              let brandLogo = ['brand_1', 'brand_2', 'brand_3'];
              let baseCDNUrl = 'https://cdn.rboomerang.com/images/brands/';
              let imgData = brandLogo
                .filter((img) => {
                  return params.data[img] !== undefined;
                })
                .map((imgEle, imgIndex) => {
                  let brandName = params.data[imgEle]
                    ?.trim('')
                    .replaceAll(' ', '_');
                  let clientFlag = params.data[imgEle + '_client_flag'];
                  let brandUrl = baseCDNUrl + brandName + '.jpeg';
                  return {
                    imgUrl: brandUrl,
                    brandRank: imgIndex + 1,
                    brandName: brandName,
                    clientFlag
                  };
                });
              return {
                data: imgData,
                onHoverParams: {
                  showHoverText: true,
                  hoverText: 'View Competitors',
                  hoverClickFunction: () => {
                    params.context?.componentParent?.onCategoryClick?.(
                      params.data.node
                    );
                  }
                }
              };
            }
          }
        }
      ];
      return columns.map(getColumnDefinition);
    }
  }

  fetchData(selectedNode) {
    if (rem1V2ConfigEnabled()) {
      try {
        this.loading = true;
        this.error = false;
        const apiError = 'Error getting data from API';
        let body = {
          page: 'customer_value_assesment',
          widget: 'cva_category_landscape_table_v2',
          entityType: 'BROWSE_NODE',
          metricsList: [
            'cva_search_volume_pvp_v2',
            'cva_search_volume_fill_percentage_v2',
            'cva_sov_pvp_v2',
            'cva_sov_fill_percentage_v2',
            'cva_sov_post_value_percentage_v2',
            'cva_sov_pre_value_percentage_v2'
          ],
          dimensionsList: [
            'module',
            'client_id',
            'time_period',
            'level',
            'search_vol_pre_value',
            'search_vol_post_value',
            'sov_post_value',
            'search_vol_search_terms',
            'sov_search_terms',
            'sov_pre_value',
            'sov_post_value'
          ],
          eventsList: [],
          enablePaginationCount: true,
          operations: {
            system: 'cva',
            enableNewPVPFormulaForSOV: false,
            enableDedupBeforeRollup: false,
            additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
              [],
            orderByList: [
              {
                dimension: showSearchVolumeColumn()
                  ? 'search_vol_post_value'
                  : 'sov_post_value',
                direction: 'DESC'
              }
            ],
            limit: 500,
            page: 1,
            outerWhereClause: {
              dimensionNameValueList: []
            }
          },
          where: {
            dimensionNameValueList: [
              {
                dimensionName: 'time_period',
                dimensionValue: 'L90D',
                operator: 'EQUAL_TO'
              },
              {
                dimensionName: 'module',
                dimensionValue: 'Category Landscape',
                operator: 'EQUAL_TO'
              }
            ]
          }
        };
        body.where.dimensionNameValueList.push(this.getRem1RetailerPayload());

        const filterBody = {
          entityType: 'category',
          operations: {
            system: 'cva'
          },
          where: {
            dimensionNameValueList: []
          }
        };
        filterBody.where.dimensionNameValueList.push(
          this.getRem1RetailerPayload()
        );
        HttpService.post('DASHBOARD_SERVICE', filterBody, {
          append: '/cat_analytics/dms/data'
        })
          .then((response) => {
            this.parseApiResponseV2(response, selectedNode, body, apiError);
          })
          .catch((err) => {
            this.error = true;
            console.log(err);
            this.loading = false;
            this.errMsg = apiError;
            throw Error(this.errMsg);
          });
      } catch (err) {
        this.error = true;
        console.log(err);
        this.loading = false;
        this.errMsg = 'Error fetching data';
        throw Error(this.errMsg);
      }
    } else {
      try {
        this.loading = true;
        this.error = false;
        const apiError = 'Error getting data from API';
        let body = {
          page: 'customer_value_assesment',
          widget: 'cva_category_landscape_table',
          entityType: 'BROWSE_NODE',
          metricsList: [
            'cva_search_volume_pvp',
            'cva_search_volume_fill_percentage',
            'cva_sov_pvp',
            'cva_sov_fill_percentage',
            'cva_sov_post_value_percentage',
            'cva_sov_pre_value_percentage'
          ],
          dimensionsList: [
            'module',
            'client_id',
            'time_period',
            'level',
            'search_vol_pre_value',
            'search_vol_post_value',
            'sov_post_value',
            'search_vol_search_terms',
            'sov_search_terms',
            'sov_pre_value',
            'sov_post_value'
          ],
          eventsList: [],
          enablePaginationCount: true,
          operations: {
            system: 'cva',
            enableNewPVPFormulaForSOV: false,
            enableDedupBeforeRollup: false,
            additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
              [],
            orderByList: [
              {
                dimension: 'search_vol_post_value',
                direction: 'DESC'
              }
            ],
            limit: 500,
            page: 1,
            outerWhereClause: {
              dimensionNameValueList: []
            }
          },
          where: {
            dimensionNameValueList: [
              {
                dimensionName: 'time_period',
                dimensionValue: 'L90D',
                operator: 'EQUAL_TO'
              },
              {
                dimensionName: 'module',
                dimensionValue: 'Category Landscape',
                operator: 'EQUAL_TO'
              }
            ]
          }
        };
        const filterBody = {
          cubeName: 'category_analytics_category_list',
          system: 'cva'
        };
        HttpService.post('FETCH_FILTERS_V2', filterBody)
          .then((response) => {
            this.parseApiResponseV1(response, selectedNode, body, apiError);
          })
          .catch((err) => {
            this.error = true;
            console.log(err);
            this.loading = false;
            this.errMsg = apiError;
            throw Error(this.errMsg);
          });
      } catch (err) {
        this.error = true;
        console.log(err);
        this.loading = false;
        this.errMsg = 'Error fetching data';
        throw Error(this.errMsg);
      }
    }
  }

  parseApiResponseV1(response, selectedNode, body, apiError) {
    const filterData = response.data.response.data;
    const noCategories = 'No Categories set up yet!';
    const noCustomTags = 'No Custom Tags set up yet!';
    if (selectedNode === 'categories') {
      let payloadCategories = getNodePayload(filterData, 'browse_node_label');
      if (!payloadCategories.length) {
        this.error = true;
        this.errMsg = noCategories;
        throw Error(this.errMsg);
      }
      body.where.dimensionNameValueList.push(...payloadCategories);
    }
    if (selectedNode === 'custom tags') {
      let payloadCustomTags = getNodePayload(filterData, 'custom_tag_label');
      if (!payloadCustomTags.length) {
        this.error = true;
        this.errMsg = noCustomTags;
        throw Error(this.errMsg);
      }
      body.where.dimensionNameValueList.push(...payloadCustomTags);
    }
    HttpService.post('DASHBOARD_SERVICE', body, {
      append: '/cat_analytics/data'
    })
      .then((response) => {
        this.data = response.data;
        this.getTableDataV1();
        if (this.data) this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.error = true;
        this.loading = false;
        this.errMsg = apiError;
        throw Error(this.errMsg);
      });
  }

  parseApiResponseV2(response, selectedNode, body, apiError) {
    const filterData = response.data.response;
    const noCategories = 'No Categories set up yet!';
    const noCustomTags = 'No Custom Tags set up yet!';
    if (selectedNode === 'categories') {
      let payloadCategories = getNodePayload(filterData, 'browse_node_label');
      if (!payloadCategories.length) {
        this.error = true;
        this.errMsg = noCategories;
        throw Error(this.errMsg);
      }
      body.where.dimensionNameValueList.push(...payloadCategories);
    }
    if (selectedNode === 'custom tags') {
      let payloadCustomTags = getNodePayload(filterData, 'custom_tag_label');
      if (!payloadCustomTags.length) {
        this.error = true;
        this.errMsg = noCustomTags;
        throw Error(this.errMsg);
      }
      body.where.dimensionNameValueList.push(...payloadCustomTags);
    }
    HttpService.post('DASHBOARD_SERVICE', body, {
      append: '/cat_analytics/data'
    })
      .then((response) => {
        this.data = response.data;
        this.getTableDataV2();
        if (this.data) this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.error = true;
        this.loading = false;
        this.errMsg = apiError;
        throw Error(this.errMsg);
      });
  }

  getTableDataV1() {
    const tableData = (this.data?.entityData || []).map((entity) => {
      return entity.data.reduce((previousVal, currentVal) => {
        if (currentVal.name.includes('brand_')) {
          previousVal[currentVal.name] = currentVal.RESULT?.[currentVal.alias];
        } else if (currentVal.name === 'BROWSE_NODE') {
          // to add for other nodes
          previousVal[currentVal.name] = this.filteredCategoryName(
            currentVal.RESULT?.[currentVal.name]
          );
          previousVal.node = currentVal.RESULT?.[currentVal.name];
        } else
          previousVal[currentVal.name] = currentVal.RESULT?.[currentVal.name];
        return previousVal;
      }, {});
    });
    this.tableData = tableData;
  }

  getTableDataV2() {
    const tableData = (this.data?.entityDataV2 || []).map((entity) => {
      return entity.data.reduce(
        (previousVal, currentVal) => {
          if (currentVal.name.includes('brand_')) {
            previousVal[currentVal.name] =
              currentVal.RESULT?.[currentVal.alias];
          } else if (currentVal.name === 'BROWSE_NODE') {
            previousVal[currentVal.name] = this.filteredCategoryName(
              currentVal.RESULT?.[currentVal.name]
            );
            previousVal.node = currentVal.RESULT?.[currentVal.name];
          } else
            previousVal[currentVal.name] = currentVal.RESULT?.[currentVal.name];
          return previousVal;
        },
        {
          rowType: 'category',
          hasSubCategories: entity.children?.length > 0
        }
      );
    });
    this.tableData = tableData;
  }

  filteredCategoryName(categoryName) {
    let categoryNameArray = categoryName.split('>');
    let categoryNameFiltered = categoryNameArray
      .slice(categoryNameArray.length - 2)
      .join('>')
      .trim(' ');
    return categoryNameFiltered;
  }

  getSubcategoryData(category_request_param) {
    let subCategories = [];
    for (let entity of this.data?.entityDataV2 || []) {
      if (
        entity.entityType === 'BROWSE_NODE' &&
        entity.entityValue === category_request_param &&
        entity.children.length > 0
      ) {
        subCategories = entity.children.map((subEntity) => {
          return subEntity.data.reduce(
            (previousVal, currentVal) => {
              if (currentVal.name.includes('brand_')) {
                previousVal[currentVal.name] =
                  currentVal.RESULT?.[currentVal.alias];
              } else if (currentVal.name === 'BROWSE_NODE') {
                previousVal[currentVal.name] = this.filteredCategoryName(
                  currentVal.RESULT?.[currentVal.name]
                );
                previousVal.node = currentVal.RESULT?.[currentVal.name];
              } else
                previousVal[currentVal.name] =
                  currentVal.RESULT?.[currentVal.name];
              return previousVal;
            },
            {
              rowType: 'sub-category',
              parentCategory: entity.entityValue
            }
          );
        });
        break;
      }
    }
    return subCategories;
  }

  getSortedTableData(dataArray, field, order, fieldType = 'number') {
    if (fieldType.toLowerCase() === 'string') {
      const sortedData = (dataArray || []).sort((rowA, rowB) => {
        return order === 'asc'
          ? rowA[field].localeCompare(rowB[field])
          : rowB[field].localeCompare(rowA[field]);
      });
      return sortedData;
    }
    const sortedData = (dataArray || []).sort((rowA, rowB) => {
      return order === 'asc'
        ? rowA[field] - rowB[field]
        : rowB[field] - rowA[field];
    });
    return sortedData;
  }
}
