var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"settings-bar u-cursor-pointer u-bg-color-grey-white u-border-top u-border-width-s u-border-color-grey-xxx-light u-display-flex u-spacing-ph-l u-spacing-pv-m",on:{"click":_vm.showExpandedState}},[_c('div',{staticClass:"u-font-size-5 u-color-grey-mid-light u-display-flex u-font-size-7"},[(_vm.enableCrawlTypeSelect)?_c('div',{staticClass:"anchor-at-brand u-spacing-mr-m"},[_c('div',{staticClass:"u-display-flex u-font-size-7",class:{
          'u-font-weight-600': _vm.isExpanded
        }},[_c('div',[_vm._v("Crawl Type")]),(!_vm.isExpanded)?_c('div',{staticClass:"u-spacing-ml-xs u-display-flex u-flex-align-items-center"},[(_vm.internalCrawlType.icon)?_c('rb-icon',{staticClass:"u-spacing-mr-xs",class:_vm.internalCrawlType.icon.class,attrs:{"icon":_vm.internalCrawlType.icon.name}}):_vm._e(),_c('div',{staticClass:"u-color-grey-base u-font-weight-600"},[_vm._v(" "+_vm._s(_vm.internalCrawlType.title)+" ")])],1):_vm._e(),(!_vm.isExpanded)?_c('rb-icon',{staticClass:"rb-icon--small pencil-on-hover u-color-grey-lighter u-cursor-pointer u-spacing-ml-xs",attrs:{"icon":'pencil'}}):_vm._e()],1),(_vm.isExpanded)?_c('rb-select-v2',{staticClass:"u-spacing-mt-m",attrs:{"on-close":_vm.handleCrawlTypeSelect,"options":_vm.crawlTypeOptions,"send-details":true}},[_c('div',{staticClass:"u-display-flex",attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[(_vm.internalCrawlType.icon)?_c('rb-icon',{staticClass:"u-spacing-mr-xs",class:_vm.internalCrawlType.icon.class,attrs:{"icon":_vm.internalCrawlType.icon.name}}):_vm._e(),_c('div',{staticClass:"u-color-grey-base",class:{
                'u-font-weight-600': !_vm.isExpanded
              }},[_vm._v(" "+_vm._s(_vm.internalCrawlType.title)+" ")])],1),_c('rb-icon',{staticClass:"u-display-flex u-flex-align-items-center rb-icon--small u-spacing-ml-xs u-color-grey-base",attrs:{"icon":'caret-down'}})],1)]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"anchor-at-brand u-spacing-mr-m"},[_c('div',{staticClass:"u-display-flex u-font-size-7",class:{
          'u-font-weight-600': _vm.isExpanded
        }},[_c('div',[_vm._v("Anchor Shelf at Brand")]),(!_vm.isExpanded)?_c('div',{staticClass:"u-spacing-ml-xs u-color-grey-base u-font-weight-600"},[_vm._v(" "+_vm._s(_vm.internalBrandSelect.title)+" ")]):_vm._e(),(!_vm.isExpanded)?_c('rb-icon',{staticClass:"rb-icon--small pencil-on-hover u-color-grey-lighter u-cursor-pointer u-spacing-ml-xs",attrs:{"icon":'pencil'}}):_vm._e()],1),(_vm.isExpanded)?_c('rb-select',{staticClass:"u-spacing-mt-m",attrs:{"on-close":_vm.handleAnchorSelect,"options":_vm.brandsOptions,"send-details":true}},[_c('div',{staticClass:"u-display-flex",attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{staticClass:"u-color-grey-base u-text-overflow-ellipsis u-max-width-100px",class:{
              'u-font-weight-600': !_vm.isExpanded
            }},[_vm._v(" "+_vm._s(_vm.internalBrandSelect.title)+" ")]),_c('rb-icon',{staticClass:"u-display-flex u-flex-align-items-center rb-icon--small u-spacing-ml-xs u-color-grey-base",attrs:{"icon":'caret-down'}})],1)]):_vm._e()],1),_c('div',{staticClass:"u-display-flex"},[_c('div',{staticClass:"u-spacing-mr-m u-display-flex"},[_c('SelectRow',{attrs:{"is-expanded":_vm.isExpanded,"options":_vm.types,"title":"Share of Voice Type","original-selections":_vm.selectedType},on:{"selection":_vm.handleTypesSelection}}),(!_vm.isExpanded)?_c('rb-icon',{staticClass:"rb-icon--small pencil-on-hover u-color-grey-lighter u-cursor-pointer u-spacing-ml-xs",attrs:{"icon":'pencil'}}):_vm._e()],1),_c('div',{staticClass:"u-spacing-mr-m u-display-flex"},[_c('SelectRow',{attrs:{"is-expanded":_vm.isExpanded,"options":_vm.keywordType,"title":"Keyword Type","original-selections":_vm.selectedKeywordType},on:{"selection":_vm.handleKeywordsSelection}}),(!_vm.isExpanded)?_c('rb-icon',{staticClass:"rb-icon--small pencil-on-hover u-color-grey-lighter u-cursor-pointer u-spacing-ml-xs",attrs:{"icon":'pencil'}}):_vm._e()],1),_c('div',{staticClass:"u-display-flex",class:{
          'u-spacing-mr-l': _vm.isExpanded
        }},[_c('SelectRow',{attrs:{"is-expanded":_vm.isExpanded,"options":_vm.placements,"title":"Share of Voice In","original-selections":_vm.selectedPlacement,"select-type":'radio'},on:{"selection":_vm.handleSovPlacementSelection}}),(!_vm.isExpanded)?_c('rb-icon',{staticClass:"rb-icon--small pencil-on-hover u-color-grey-lighter u-cursor-pointer u-spacing-ml-xs",attrs:{"icon":'pencil'}}):_vm._e()],1)])]),(_vm.isExpanded)?_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-color-grey-lighter"},[_c('ConfigIcon',{staticClass:"u-spacing-mr-m",class:{
        'u-color-blue-base u-cursor-pointer': _vm.isApplyButtonActive,
        'u-color-grey-lighter u-pointer-events-none u-cursor-default':
          !_vm.isApplyButtonActive
      },attrs:{"config":_vm.applyIcon},on:{"click":_vm.handleApply}}),_c('ConfigIcon',{attrs:{"config":_vm.cancelIcon},on:{"click":_vm.handleCancel}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }