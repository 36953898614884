<template>
  <div
    class="u-bg-color-grey-white u-width-100 custom-entity"
    :class="customStyle"
  >
    <div :class="customStyle">
      <!-- body -->
      <div :class="customStyle">
        <div
          class="split-table-section"
          :class="customStyle"
        >
          <!-- tables -->
          <slot name="header" />

          <div
            class="table-container-entity-asin-tagging u-overflow-y-auto u-width-100 u-display-flex"
            :class="customStyle"
          >
            <!-- left table -->
            <slot name="left-table" />
            <!-- right table -->
            <slot name="right-table" />
          </div>
        </div>
        <slot name="error-message" />
        <slot name="action-info" />
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomEntityContainer',
  props: {
    customStyle: {
      type: String,
      default: ''
    }
  }
};
</script>
<style lang="css">
.custom-entity .paginationFooter {
  border-top: 1px solid #e9eaeb;
}
.custom-entity .delete-icon {
  margin-bottom: 12px;
}
.custom-entity .table-container-entity-asin-tagging {
  min-height: 59.1rem;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  gap: 2.4rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
</style>
