<template>
  <div
    v-if="showErrorBlob"
    data-cy="errorBlob"
  >
    <div
      :class="[
        containerClasses,
        hideDropdownContent ? 'u-spacing-mb-m u' : 'error-blob-top-container'
      ]"
      class="u-spacing-mt-m u-spacing-mh-m u-border-radius-s"
    >
      <div
        class="u-display-flex u-flex-align-items-center u-spacing-ph-s u-spacing-pt-s"
      >
        <rb-icon
          class="rb-icon--medium u-color-red-base u-flex-align-self-flex-start"
          :icon="primaryHeaderIcon"
        />
        <span
          class="u-spacing-ph-s u-font-size-7 u-font-weight-400 u-color-grey-base u-line-height-1-3"
          data-cy="errorBlobPrimaryHeaderText"
          >{{ primaryHeaderText }}</span
        >
        <div
          class="u-display-flex u-flex-justify-content-flex-end u-cursor-pointer u"
          @click="closeModal"
        >
          <rb-icon
            class="u-color-grey-mid-light rb-icon--small"
            :icon="'cross'"
            data-cy="errorBlobCloseIcon"
          />
        </div>
      </div>
      <div class="u-spacing-pl-xl u-spacing-pr-l u-line-height-1-4">
        <div
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xs u-cursor-pointer"
          :class="isDropdownOpen ? 'u-spacing-pb-xs' : 'u-spacing-pb-s'"
          data-cy="errorBlobDropdownHeader"
          @click="toggleDropdown"
        >
          <span
            class="u-spacing-pr-xs u-font-size-7 u-font-weight-600 u-color-blue-base"
            :class="dropdownTriggered ? 'dropdownHeaderAnim' : ''"
            >{{ generateDropdownHeader }}</span
          >
          <rb-icon
            class="u-color-blue-base rb-icon--xx-small"
            :icon="generateDropdownHeaderIcon"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!hideDropdownContent"
      :class="[
        containerClasses,
        isDropdownOpen ? 'errorContainerOpenAnim' : 'errorContainerCloseAnim'
      ]"
      class="error-blob-lower-container u-spacing-mb-m u-spacing-mh-m u-border-radius-s"
    >
      <div
        class="u-spacing-pl-xl u-spacing-pb-s u-spacing-pr-l u-line-height-1-4"
      >
        <div
          :class="
            isDropdownOpen
              ? 'dropdownContentAnim'
              : 'dropdownContentReverseAnim'
          "
          data-cy="errorBlobDropdownContent"
        >
          <slot
            v-if="!hideDropdownContent"
            name="dropdownContent"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorBlob',
  props: {
    containerClasses: {
      type: String,
      default: 'default-background'
    },
    primaryHeaderText: {
      type: String,
      default: ''
    },
    primaryHeaderIcon: {
      type: String,
      default: 'warning'
    },
    dropdownClosedHeaderMessage: {
      type: String,
      default: ''
    },
    dropdownOpenHeaderMessage: {
      type: String,
      default: ''
    },
    localStorageKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isDropdownOpen: false,
      showErrorBlob: false,
      dropdownTriggered: false,
      hideDropdownContent: true
    };
  },
  computed: {
    generateDropdownHeader() {
      return this.isDropdownOpen
        ? this.dropdownOpenHeaderMessage
        : this.dropdownClosedHeaderMessage;
    },
    generateDropdownHeaderIcon() {
      return this.isDropdownOpen ? 'arrow1-up' : 'arrow1-down';
    }
  },
  created() {
    const localStorageData = JSON.parse(
      localStorage.getItem(this.localStorageKey)
    );
    if (!localStorageData) {
      this.showErrorBlob = true;
    }
  },
  methods: {
    closeModal() {
      localStorage.setItem(this.localStorageKey, true);
      this.showErrorBlob = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropdownOpen === false) {
        setTimeout(() => {
          this.hideDropdownContent = !this.hideDropdownContent;
        }, 250);
      } else {
        this.hideDropdownContent = false;
      }
      this.dropdownTriggered = true;
      setTimeout(() => {
        this.dropdownTriggered = false;
      }, 1000);
    }
  }
};
</script>

<style style="css" scoped>
.error-blob-top-container {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.error-blob-lower-container {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.default-background {
  background: #d7263d1a;
}

.errorContainerOpenAnim {
  animation: expand_container;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  transform-origin: top;
}

@keyframes expand_container {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

.errorContainerCloseAnim {
  animation: shrink_container;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  transform-origin: top;
}

@keyframes shrink_container {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}

.dropdownContentAnim {
  animation: show_dropdown_content;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
@keyframes show_dropdown_content {
  from {
    display: none;
    opacity: 0;
    z-index: -1;
  }
  to {
    display: inline;
    opacity: 1;
    z-index: 1;
  }
}

.dropdownContentReverseAnim {
  animation: show_dropdown_content_reverse;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
@keyframes show_dropdown_content_reverse {
  from {
    display: inline;
    opacity: 1;
    z-index: 1;
  }
  to {
    display: none;
    opacity: 0;
    z-index: -1;
  }
}

.dropdownHeaderAnim {
  animation: blur_text;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}
@keyframes blur_text {
  from {
    opacity: 0.5;
    z-index: -1;
  }
  to {
    opacity: 1;
    z-index: 1;
  }
}
</style>
