import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import HttpService from '@/utils/services/http-service';
import Vue from 'vue';
import transformAction from '@/components/ams/budgetPlanner/actions/actionsTransformer.js';
import { cloneDeep } from 'lodash';
const state = {
  budgetPlanActionTriggered: false,
  budgetPlannerActionTable: {
    rows: [],
    page: 0,
    error: false,
    totalRows: 0,
    noData: false,
    load: false
  },
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  budgetPlannerActionsSelectedFilters: {}
};

const getters = {
  getBudgetPlanActionTriggered: (state) => state.budgetPlanActionTriggered,
  getBudgetPlannerActionTable: (state) => state.budgetPlannerActionTable,
  getBudgetPlannerActionsSelectedFilters: (state) =>
    state.budgetPlannerActionsSelectedFilters
};

const mutations = {
  BUDGET_PLANNER_ACTIONS_FILTER: (state, data) => {
    Vue.set(state, 'budgetPlannerActionsSelectedFilters', data.values);
    getFiltersInFormatBudgetPlanner(data, state);
  },
  BUDGET_PLAN_ACTION_TRIGGERED: (state, data) => {
    state.budgetPlanActionTriggered = data;
  },
  BUDGET_PLANNER_ACTION_TABLE: (state, actionData) => {
    state.budgetPlannerActionTable = actionData;
  },
  BUDGET_PLANNER_ACTIONS_UPDATE_STATUS: (state) => {
    const _actionData = cloneDeep(state.budgetPlannerActionTable);
    const rows = _actionData.rows;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].status === 'OPEN' || rows[i].status === 'FAILED') {
        rows[i].status = 'APPROVED';
      }
    }
    Vue.set(state, 'budgetPlannerActionTable', _actionData);
  }
};

const actions = {
  setBudgetPlannerActionsFilter: (context, data) => {
    context.commit('BUDGET_PLANNER_ACTIONS_FILTER', data);
  },
  approveBudgetPlannerActionNew: async (context, payload) => {
    try {
      context.commit('BUDGET_PLAN_ACTION_TRIGGERED', true);
      const response = await HttpService.post(
        'UPDATE_BULK_BATCH_ACTIONS_MANAGER',
        payload
      );
      return response;
    } catch (err) {
      context.$snackbar.open({
        message: 'Something Went Wrong !!!',
        duration: 5000,
        actionText: ''
      });
      throw new Error(err);
    } finally {
      context.commit('BUDGET_PLAN_ACTION_TRIGGERED', false);
    }
  },
  approveBudgetPlannerManualAction: async (context, payload) => {
    try {
      context.commit('BUDGET_PLAN_ACTION_TRIGGERED', true);
      const response = await HttpService.post(
        'BUDGET_OPTIMIZER_ACTIONS',
        payload
      );
      return response;
    } catch (err) {
      throw new Error(err);
    } finally {
      context.commit('BUDGET_PLAN_ACTION_TRIGGERED', false);
    }
  },
  downloadManualActionsTableData: (context, data) => {
    return HttpLayer.post({
      APIData: data,
      urlId: 'BUDGET_OPTIMIZER_ACTIONS_TABLE'
    }).then((response) => {
      const _mergeObj =
        transformer.mergeResultDimension(response.data, true) || [];
      return _mergeObj;
    });
  },
  approveBudgetPlannerBulkAction: async (context, payload) => {
    // modify payload when api is ready
    try {
      context.commit('BUDGET_PLAN_ACTION_TRIGGERED', true);
      const response = await HttpService.post(
        'BUDGET_OPTIMIZER_BULK_ACTION',
        payload
      );
      return response;
    } catch (err) {
      throw new Error(err);
    } finally {
      context.commit('BUDGET_PLAN_ACTION_TRIGGERED', false);
    }
  },
  budgetPlannerManualUpdateAllStatus: (context, data) => {
    context.commit('BUDGET_PLANNER_ACTIONS_UPDATE_STATUS');
  },
  budgetPlannerManualActionTable: async (context, data) => {
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters || [],
      context.state.whereClause
    );
    // pushing feed_date dimension value always, as it is required in every api call
    data.body.APIConfig.where.dimensionNameValueList.push({
      dimensionName: 'feed_date',
      dimensionValue: data.body.getMaxFeedData
    });
    const dataObj = {
      rows: [],
      page: data.body.APIConfig.page,
      error: false,
      totalRows: 0,
      noData: false,
      load: true
    };
    // resetting rows
    context.commit('BUDGET_PLANNER_ACTION_TABLE', dataObj);
    try {
      const response = await HttpLayer.post({
        APIData: data.body.APIConfig,
        urlId: 'BUDGET_OPTIMIZER_ACTIONS_TABLE'
      });
      if (response.data && !response.success) {
        dataObj.error = true;
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data);
        transformAction.formatToRecommendationPayload(
          _aArray,
          data.body.actionType
        );
        if (_aArray.length > 0) {
          dataObj.rows = _aArray;
          dataObj.totalRows = _aArray[0].auto_cubesdk_count || _aArray[0].count;
        } else {
          dataObj.noData = true;
        }
      }
    } catch (error) {
      dataObj.error = true;
    } finally {
      dataObj.load = false;
      context.commit('BUDGET_PLANNER_ACTION_TABLE', dataObj);
    }
  }
};

function getFiltersInFormatBudgetPlanner(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      if (oFilter.values[i].length > 0 && oFilter.values[i][0].operator) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][0].value,
          operator: oFilter.values[i][0].operator.operator
        });
      } else {
        for (var j = 0; j < oFilter.values[i].length; j++) {
          where.dimensionNameValueList.push({
            dimensionName: i,
            dimensionValue: oFilter.values[i][j]
          });
        }
      }
    } else {
      var date = oFilter.values[i];
      var _date = {
        from: date.from,
        to: date.to
      };
      var _pvpDate;
      if (date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  state.whereClause = where;
}

export default {
  state,
  getters,
  mutations,
  actions
};
