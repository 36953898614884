<template>
  <div>
    <div
      class="u-color-grey-base u-position-relative u-bg-color-white-base u-width-100 u-spacing-p-l u-display-flex u-flex-direction-column"
    >
      <div
        v-if="loader"
        class="loaderContainer"
      >
        <loader
          class="fill--parent"
          :loading="loader"
          :color="'#007cf6'"
        />
      </div>
      <div class="u-spacing-pb-l">
        <div
          class="title u-font-size-5 u-spacing-pb-l u-font-weight-600 u-text-case-upper"
        >
          Adjust budget ({{ selections.length }} campaign{{
            selections.length > 1 ? 's' : ''
          }}
          selected)
        </div>
        <div class="u-line-height-1 u-display-flex">
          <div
            v-for="item in config.budgetTypeSelections"
            :key="item.value"
            class="u-text-case-upper u-min-width-240px"
          >
            <rb-radio
              v-model="budgetTypeSelection"
              :native-value="item.value"
              class="u-font-size-5"
              @input="onUpdateRadio"
            >
              {{ item.title }}
            </rb-radio>
          </div>
        </div>
        <div
          v-if="budgetTypeSelection === 'retain'"
          class="u-display-flex u-spacing-pt-m"
        >
          <div
            v-if="dailyBudgetCampaignCount"
            class="u-height-100 u-spacing-pr-xl"
            :class="[
              maxImpresssionsCampaignCount &&
                'adjust-budget-dashed-border u-border-width-s u-border-color-grey-xxx-light u-border-right'
            ]"
          >
            <div
              class="u-text-transform-uppercase u-font-weight-600 u-font-size-6"
            >
              <span class="u-color-grey-lighter">Budget Type</span> Daily Budget
              ({{ dailyBudgetCampaignCount }} Campaign{{
                dailyBudgetCampaignCount.length > 1 ? 's' : ''
              }}
              SELECTED)
            </div>
            <radioSelectionWithInput
              title="Adjust daily budget:"
              :radio-config="config.dailyBudgetConfig"
              :default-radio-selected="dailyBudgetRadioDefault"
              :default-input-value="null"
              @onUpdate="adjustDailyBudget"
            />
            <div
              v-if="
                !(
                  updatedBudgetValue &&
                  updatedBudgetValue.daily_budget &&
                  updatedBudgetValue.daily_budget.radioValue ===
                    'dont_change_db'
                )
              "
              class="u-spacing-pt-l"
            >
              <rb-checkbox
                v-model="adjustLifetimeBudgetSpeningLimit"
                native-value="false"
              >
                Adjust campaign lifetime spending limit
                <span class="info-icon-adjust-budget">
                  <rb-icon
                    v-if="adjustLifetimeBudgetSpeningLimit"
                    v-tippy="tippy"
                    class="rb-icon--small u-color-grey-lighter u-cursor-pointer"
                    icon="info-circle-fill"
                    :title="message"
                  />
                </span>
              </rb-checkbox>

              <div
                v-if="!adjustLifetimeBudgetSpeningLimit"
                class="u-spacing-pt-xs u-color-grey-lighter u-font-size-7 u-display-flex u-flex-align-items-baseline"
              >
                <div class="u-spacing-pr-xs">
                  <rb-icon
                    class="rb-icon--small u-cursor-pointer"
                    icon="info-circle-fill"
                  />
                </div>
                <div>
                  Not adjusting the Lifetime Spending Limit will
                  <span class="u-color-grey-base">“Retain”</span> the existing
                  <br />
                  Lifetime Spending Limit on selected campaigns.
                </div>
              </div>
              <radioSelectionWithInput
                v-if="adjustLifetimeBudgetSpeningLimit"
                class="u-spacing-pt-m"
                :radio-config="config.lifetimeSpendingLimitConfig"
                :default-radio-selected="lifetimeBudgetRadioDefault"
                @onUpdate="adjustLifetimeSpendingLimit"
              >
                <div
                  slot="below-right-side-dropdown"
                  class="u-display-flex u-color-grey-lighter u-spacing-pt-xs"
                >
                  <rb-icon
                    class="rb-icon--small u-cursor-pointer u-spacing-pb-l"
                    icon="info-circle-fill"
                  />
                  <div class="u-font-size-7">
                    This change will not make any diffrece to campaigns<br />
                    without any existing lifetime spending limit
                  </div>
                </div>
              </radioSelectionWithInput>
            </div>
          </div>
          <div
            v-if="maxImpresssionsCampaignCount"
            class="u-height-100"
            :class="[dailyBudgetCampaignCount && 'u-spacing-pl-xl']"
          >
            <div
              class="u-text-transform-uppercase u-font-weight-600 u-font-size-6"
            >
              <span class="u-color-grey-lighter">Budget Type</span> Maximize
              Impressions ({{ maxImpresssionsCampaignCount }} Campaign{{
                dailyBudgetCampaignCount.length > 1 ? 's' : ''
              }}
              SELECTED)
            </div>
            <radioSelectionWithInput
              title="Adjust lifetime budget:"
              :radio-config="config.lifetimeBudgetConfig"
              :default-radio-selected="lifetimeBudgetRadioDefault"
              @onUpdate="adjustLifetimeBudget"
            />
          </div>
        </div>
        <div
          v-if="budgetTypeSelection === 'override'"
          class="u-spacing-pt-m"
        >
          <div
            class="u-text-transform-uppercase u-font-weight-600 u-font-size-6"
          >
            <span class="u-color-grey-lighter">Budget Type</span>
          </div>
          <div class="u-spacing-p-m">
            <radioSelectionWithInput
              :radio-config="config.overrideBudgetTypeConfig"
              :override-default-value="overrideBudgetTypeRadioDefault"
              :override-budget-check="overrideBudgetCheck"
              :disable-max-impressions="
                disableMultipleCampaignForMaxImpressions
              "
              @onUpdate="overrideBudgetType"
            >
              <div
                v-if="
                  updatedBudgetValue.budgetType &&
                  updatedBudgetValue.budgetType.radioValue ===
                    'override_daily_budget'
                "
                slot="below-right-side-input"
                class="u-display-flex"
              >
                <div class="u-spacing-pt-l">
                  <rb-checkbox
                    v-model="adjustLifetimeBudgetSpeningLimit"
                    native-value="false"
                    class="u-min-width-320px"
                  >
                    Adjust campaign lifetime spending limit
                    <span class="info-icon-adjust-budget">
                      <rb-icon
                        v-if="adjustLifetimeBudgetSpeningLimit"
                        v-tippy="tippy"
                        class="rb-icon--small u-color-grey-lighter u-cursor-pointer"
                        icon="info-circle-fill"
                        :title="message"
                      />
                    </span>
                  </rb-checkbox>
                  <div
                    v-if="!adjustLifetimeBudgetSpeningLimit"
                    class="u-color-grey-lighter u-spacing-pt-xs u-font-size-7 u-display-flex u-flex-align-items-baseline"
                  >
                    <div class="u-spacing-pr-xs">
                      <rb-icon
                        class="rb-icon--small u-cursor-pointer"
                        icon="info-circle-fill"
                      />
                    </div>
                    <div>
                      Not adjusting the Lifetime Spending Limit will
                      <span class="u-color-grey-base">“Retain”</span> the
                      existing
                      <br />
                      Lifetime Spending Limit on selected campaigns.
                    </div>
                  </div>
                  <radioSelectionWithInput
                    v-if="adjustLifetimeBudgetSpeningLimit"
                    class="u-spacing-pt-m"
                    :radio-config="config.lifetimeSpendingLimitConfig"
                    :default-radio-selected="lifetimeBudgetRadioDefault"
                    @onUpdate="adjustLifetimeSpendingLimit"
                  >
                    <div
                      slot="below-right-side-dropdown"
                      class="u-display-flex u-flex-just u-color-grey-lighter u-spacing-pt-xs"
                    >
                      <rb-icon
                        class="rb-icon--small u-spacing-pb-l"
                        icon="info-circle-fill"
                      />
                      <span class="u-font-size-7">
                        This change will not make any diffrece to campaigns<br />
                        without any existing lifetime spending limit
                      </span>
                    </div>
                  </radioSelectionWithInput>
                </div>
              </div>
            </radioSelectionWithInput>
            <div
              v-if="
                !disableMultipleCampaignForMaxImpressions &&
                updatedBudgetValue.budgetType &&
                updatedBudgetValue.budgetType.radioValue ===
                  'override_max_impressions'
              "
            >
              <div
                class="u-font-size-5 u-font-weight-600 u-spacing-ml-l u-spacing-mt-m"
              >
                Lifetime budget:
                <div class="u-display-flex u-spacing-mt-s">
                  <span
                    class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                  >
                    {{ $currency }}
                  </span>
                  <input
                    v-model="lifetimeBudgetValue"
                    type="number"
                    min="0"
                    class="rb-input currency-input u-display-flex u-flex-align-items-center"
                    step="0.01"
                  />
                </div>
              </div>

              <div class="u-spacing-ml-l u-spacing-mt-s">
                <div class="u-display-flex u-flex-align-items-center">
                  <div>
                    <rb-icon
                      class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-pb-l"
                      icon="info-circle-fill"
                    />
                  </div>
                  <div class="u-font-size-5 u-spacing-ml-xs">
                    Bid setting changed to manual
                  </div>
                </div>
                <div class="u-font-size-6 u-line-height-1-1">
                  Bid setting will be changed from optimized to manual for this
                  campaign.<br />Set your own cpc bids and optional override
                  bids for specific keywords.
                </div>
              </div>

              <div class="u-width-50 u-spacing-ml-l">
                <div
                  v-if="adgroupTableData.rows.length > 0"
                  class="table-right paginationFooter u-border-color-grey-xxx-light u-border-left u-border-width-s u-spacing-mb-m u-spacing-mt-l"
                  style="width: 100%; height: 54vh"
                >
                  <rb-insights-table
                    class="card"
                    :grid-options="gridOptions"
                    :config="tableConfigsLeft"
                    :auto-configure="adgroupTableData"
                    style="width: 100%"
                    :row-height="66"
                    :enable-client-side-sorting="false"
                    :enable-server-side-sorting="false"
                    :pagination="true"
                    :client-side-pagination="true"
                    :pagination-total-key="adgroupTableData.rows.length"
                    :get-current-instance="getCurrentSourceInstance"
                    :pagination-per-page-limit="10"
                    :download-this-table="false"
                    :show-pagination-options="false"
                    :row-no-hover-state="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="u-width-100 u-border-top u-border-bottom u-border-color-grey-xxx-light u-border-width-s u-display-flex u-spacing-pv-m u-spacing-ph-l u-bg-color-white-base"
    >
      <div
        v-if="tooltipErrorMessage"
        v-tippy="{ placement: 'top', arrow: true }"
        :title="tooltipErrorMessage"
      >
        <rb-button
          text="Apply"
          type="filled"
          :click-fn="onApply"
          :disabled="disableApplyButton"
          class="u-spacing-mr-12"
          size="s"
        />
      </div>
      <rb-button
        v-else
        text="Apply"
        type="filled"
        :click-fn="onApply"
        :disabled="disableApplyButton"
        class="u-spacing-mr-12"
        size="s"
      />
      <rb-button
        text="Cancel"
        :click-fn="onClose"
        size="s"
      />
    </div>
  </div>
</template>

<script>
import config from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/adjustBudgetConfig';
import radioSelectionWithInput from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/radioSelectionWithInput.vue';
import loader from '@/components/basic/loader';
import { commons } from '@/components/ams/campaigns/commons.js';
import transformer from '@/utils/services/data-transformer';
import HttpService from '@/utils/services/http-service';
export default {
  components: {
    radioSelectionWithInput,
    loader
  },
  props: {
    parentRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    actionType: {
      type: String,
      default: 'instacartCampaignTotalBudgetChange'
    },
    retailer: {
      type: String,
      default: 'instacart'
    },
    selections: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      loader: false,
      overrideBudgetCheck: false,
      config: null,
      tooltipErrorMessage: '',
      lifetimeBudgetValue: null,
      disableMultipleCampaignForMaxImpressions: false,
      adjustLifetimeBudgetSpeningLimit: false,
      dailyBudgetRadioDefault: 'set_new_db',
      lifetimeBudgetRadioDefault: 'set_new_lb',
      lifetimeSpendingLimitRadioDefault: 'set_new_lsl',
      budgetTypeSelection: 'retain',
      overrideBudgetTypeRadioDefault: '',
      isOptimizedSelected: null,
      updatedBudgetValue: {},
      lifetimeSpendingLimitForDailyBudget: {},
      optimizedBiddingGoals: ['acquire_ntb', 'max_sales'],
      message:
        'On removing the lifetime spending limit, daily budget will no longer average out with respect to the lifetime spending limit value',
      tippy: {
        placement: 'right',
        arrow: false,
        distance: 8,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      },
      adGroupDataForViewPayload: [],
      rbTableSourceInstance: null,
      tableConfigsLeft: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: {}
        }
      },
      adGroupsData: [],
      oldAdGroupData: [],
      adGroupMetadata: []
    };
  },
  computed: {
    updatedGroupData() {
      let result = false;
      for (let i = 0; i < this.adGroupsData?.length; i++) {
        if (
          +this.adGroupsData[i]?.default_bid !==
          this.oldAdGroupData[i]?.default_bid
        ) {
          result = true;
        }
      }
      return result;
    },
    checkMinBidValue() {
      let result = false;
      for (let i = 0; i < this.adGroupsData?.length; i++) {
        if (+this.adGroupsData[i]?.default_bid <= 0.15) {
          result = true;
        }
      }
      return result;
    },
    maxImpresssionsCampaignCount() {
      return (
        this.selections?.filter(
          (item) => item?.data?.budget_type === 'max_impressions'
        ).length || 0
      );
    },
    dailyBudgetCampaignCount() {
      return (
        this.selections?.filter(
          (item) => item?.data?.budget_type === 'daily_budget'
        ).length || 0
      );
    },
    filteredSelection() {
      const dont_change_db =
        this.updatedBudgetValue?.daily_budget?.radioValue === 'dont_change_db';
      const dont_change_max_impressions =
        this.updatedBudgetValue?.max_impressions?.radioValue ===
        'dont_change_lb';
      const filterCheck = [];
      let filteredValues = this.selections || [];
      if (this.budgetTypeSelection === 'retain') {
        if (dont_change_db) {
          filterCheck.push('daily_budget');
        }
        if (dont_change_max_impressions) {
          filterCheck.push('max_impressions');
        }
      }
      if (filterCheck.length) {
        filteredValues = this.selections.filter(
          (item) => !filterCheck.includes(item.data.budget_type)
        );
      }
      return filteredValues;
    },
    disableApplyButton() {
      let disableApply = false;
      const dailyBudgetInput =
        this.updatedBudgetValue?.daily_budget?.inputValue ||
        this.updatedBudgetValue?.daily_budget?.radioValue === 'dont_change_db';
      const maxImpressionsInput =
        this.updatedBudgetValue?.max_impressions?.inputValue ||
        this.updatedBudgetValue?.max_impressions?.radioValue ===
          'dont_change_lb';

      const lifetimeSpendingLimitForDailyBudgetInput =
        this.lifetimeSpendingLimitForDailyBudget?.inputValue ||
        this.lifetimeSpendingLimitForDailyBudget?.radioValue === 'remove_lsl';

      if (this.selections.length > 1 && this.budgetTypeSelection !== 'retain') {
        this.disableMultipleCampaignForMaxImpressions = true;
        disableApply = true;
      } else {
        this.disableMultipleCampaignForMaxImpressions = false;
        disableApply = false;
      }
      if (this.budgetTypeSelection === 'retain') {
        if (this.dailyBudgetCampaignCount && !dailyBudgetInput) {
          disableApply = true;
        }
        if (this.maxImpresssionsCampaignCount && !maxImpressionsInput) {
          disableApply = true;
        }
        if (
          this.adjustLifetimeBudgetSpeningLimit &&
          !lifetimeSpendingLimitForDailyBudgetInput
        ) {
          disableApply = true;
        }
        if (
          this.updatedBudgetValue?.daily_budget?.radioValue ===
            'dont_change_db' &&
          this.updatedBudgetValue?.max_impressions?.radioValue ===
            'dont_change_lb'
        ) {
          disableApply = true;
        }
        if (
          this.isOptimizedSelected !== 0 &&
          this.updatedBudgetValue.daily_budget?.inputValue < 20 &&
          this.updatedBudgetValue?.daily_budget?.radioValue === 'set_new_db'
        ) {
          this.tooltipErrorMessage =
            'Optimized bidding campaigns require a minimum daily budget of $20';
          disableApply = this.isOptimizedSelected === 2;
        } else {
          this.tooltipErrorMessage = '';
        }
      } else if (this.budgetTypeSelection === 'override') {
        if (
          this.adjustLifetimeBudgetSpeningLimit &&
          !lifetimeSpendingLimitForDailyBudgetInput &&
          this.updatedBudgetValue?.budgetType?.radioValue ===
            'override_daily_budget'
        ) {
          this.tooltipErrorMessage = '';
          disableApply = true;
        } else if (
          this.updatedBudgetValue?.budgetType?.radioValue ===
            'override_max_impressions' &&
          this.checkMinBidValue
        ) {
          this.tooltipErrorMessage =
            'Min Bid value should be greater than $0.15';
          disableApply = true;
        } else if (
          this.updatedBudgetValue?.budgetType?.radioValue ===
            'override_max_impressions' &&
          !this.lifetimeBudgetValue
        ) {
          this.tooltipErrorMessage = '';
          disableApply = true;
        } else if (
          this.updatedBudgetValue?.budgetType?.radioValue ===
            'override_daily_budget' &&
          !this.updatedBudgetValue?.budgetType?.inputValue
        ) {
          this.tooltipErrorMessage = '';
          disableApply = true;
        } else if (
          this.updatedBudgetValue?.budgetType?.radioValue !==
            'override_daily_budget' &&
          this.updatedBudgetValue?.budgetType?.radioValue !==
            'override_max_impressions'
        ) {
          this.tooltipErrorMessage = '';
          disableApply = true;
        } else if (
          this.isOptimizedSelected !== 0 &&
          this.updatedBudgetValue.budgetType?.inputValue < 20
        ) {
          this.tooltipErrorMessage =
            'Optimized bidding campaigns require a minimum daily budget of $20';
          disableApply = this.isOptimizedSelected === 2;
        } else {
          this.tooltipErrorMessage = '';
          disableApply = false;
        }
      }
      return disableApply;
    },
    adgroupTableData() {
      return {
        rows: this.adGroupsData,
        columns: this.adGroupMetadata
      };
    }
  },
  watch: {
    selections(newVal) {
      if (newVal?.length === 0) {
        this.$emit('close');
      } else if (newVal?.length === 1) {
        this.getAdGroupsData();
        this.overrideBudgetTypeRadioDefault = 'override_daily_budget';
      }
      this.isOptimizedSelected = this.checkOptimizedBiddingEnabled(newVal);
      this.tooltipErrorMessage = '';
    },
    budgetTypeSelection(val) {
      if (val === 'override' && this.selections.length === 1) {
        this.overrideBudgetTypeRadioDefault = 'override_daily_budget';
        this.overrideBudgetCheck = true;
      } else if (val === 'retain') {
        this.overrideBudgetCheck = false;
      }
    }
  },
  created() {
    this.config = config[this.retailer];
    this.adGroupMetadata = this.config.adGroupColumnMetadata(this);
    this.getAdGroupsData();
    this.isOptimizedSelected = this.checkOptimizedBiddingEnabled(
      this.selections
    );
    this.overrideBudgetTypeRadioDefault = 'override_daily_budget';
  },
  methods: {
    checkOptimizedBiddingEnabled(selectionsArray) {
      const counts = selectionsArray.reduce(
        function (acc, selection) {
          if (selection.data?.optimized_bidding_enabled) {
            acc.trueCount++;
          } else {
            acc.falseCount++;
          }
          return acc;
        },
        { trueCount: 0, falseCount: 0 }
      );

      if (counts.trueCount > 0 && counts.falseCount > 0) {
        return 1; // Mix of optimized and manual
      } else if (counts.trueCount === selectionsArray.length) {
        return 2; // All optimized
      } else {
        return 0; // All manual
      }
    },
    addSuggestedBid(context) {
      let id = context.params.data.ad_group_id;
      this.adGroupsData = this.adGroupsData.map((item) => {
        if (item.ad_group_id === id) {
          item.default_bid = context.params.data.suggested_bid;
        }
        return item;
      });
      this.rbTableSourceInstance.redrawTableRows();
    },

    addAllSuggestedBids(context) {
      this.adGroupsData = this.adGroupsData.map((item) => {
        item.default_bid = item.suggested_bid;
        return item;
      });
      this.rbTableSourceInstance.redrawTableRows();
    },

    getCurrentSourceInstance(context) {
      this.rbTableSourceInstance = context;
    },
    getAdGroupsData() {
      this.config.reqBody.where.dimensionNameValueList[0].dimensionValue =
        this.selections[0]?.data?.campaign_id;
      HttpService.post('EXECUTE_CUBE_API', this.config.reqBody)
        .then((response) => {
          this.adGroupsData = [
            ...transformer.mergeResultDimension(response.data.response.data)
          ];
          this.oldAdGroupData = [
            ...transformer.mergeResultDimension(response.data.response.data)
          ];
        })
        .catch((error) => console.log(error));
    },

    getAdGroupDataForViewPayload() {
      this.adGroupDataForViewPayload = [];
      if (this.optimizedBiddingGoals.includes(this.bidType)) return;
      for (let i = 0; i < this.adGroupsData?.length; i++) {
        if (
          +this.adGroupsData[i]?.default_bid !==
          this.oldAdGroupData[i]?.default_bid
        ) {
          this.adGroupDataForViewPayload.push({
            adgroupId: this.adGroupsData[i].ad_group_id,
            adgroupName: this.adGroupsData[i].ad_group_name,
            oldBidValue: this.oldAdGroupData[i]?.default_bid,
            newBidValue: +this.adGroupsData[i]?.default_bid
          });
        }
      }
    },
    onUpdateRadio() {
      this.adjustLifetimeBudgetSpeningLimit = false;
    },
    updateBudgetValue(type, value) {
      this.updatedBudgetValue[type] = value;
      this.updatedBudgetValue = { ...this.updatedBudgetValue };
    },
    adjustDailyBudget(data) {
      this.updateBudgetValue('daily_budget', data);
    },
    adjustLifetimeBudget(data) {
      this.updateBudgetValue('max_impressions', data);
    },
    overrideBudgetType(data) {
      this.updateBudgetValue('budgetType', data);
    },
    adjustLifetimeSpendingLimit(data) {
      this.lifetimeSpendingLimitForDailyBudget = data;
    },
    calculateInputValue(data, budget) {
      let val = null;
      if (data?.inputType === 'dropdown') {
        if (budget === null && !data?.radioValue?.includes('set_new')) {
          return null;
        }
        const delta = budget * (data?.inputValue / 100);
        if (data?.radioValue?.includes('increase_')) {
          val =
            data?.dropdownValue === 'percentage'
              ? parseFloat((budget + delta).toFixed(2))
              : parseFloat(data?.inputValue) + budget;
        } else if (data?.radioValue?.includes('decrease_')) {
          val =
            data?.dropdownValue === 'percentage'
              ? parseFloat((budget - delta).toFixed(2))
              : budget - parseFloat(data?.inputValue);
        }
      } else if (data?.inputType === 'input') {
        val = data?.inputValue;
      }
      return val;
    },
    onClose() {
      this.$emit('close');
    },
    createPayloadRequestForManual() {
      const payload = [];
      const selectedRow = this.selections[0].data;
      const budget_type = 'daily_budget';
      for (const data of this.adGroupDataForViewPayload) {
        payload.push({
          actionPayload: {
            campaignId: selectedRow?.campaign_id,
            profileId: 0,
            campaignType: selectedRow?.campaign_type,
            newOptimizedBiddingEnabled: false,
            adgroupId: data?.adgroupId,
            newBid: data?.newBidValue,
            previousBid: data?.oldBidValue
          },
          viewPayload: {
            campaignId: selectedRow?.campaign_id,
            campaign_name: selectedRow?.campaign_name,
            oldBudgetType:
              selectedRow.budget_type !== budget_type ? 'Daily Budget' : null,
            newBudgetType:
              selectedRow.budget_type !== budget_type ? 'Daily Budget' : null,
            campaignType: selectedRow?.campaign_type,
            newOptimizedBiddingEnabled: false,
            adgroupName: data?.adgroupName,
            newBid: data?.newBidValue,
            previousBid: data?.oldBidValue
          },
          actionType: 'instacartCampaignAdgroupBidChange',
          actionSource: {
            pageUrl: window.location.href
          }
        });
      }
      return payload;
    },
    onApply() {
      this.getAdGroupDataForViewPayload();
      let payload = [];
      if (this.budgetTypeSelection === 'retain') {
        payload = [...this.createRetainBudgetPayload()];
      } else if (this.budgetTypeSelection === 'override') {
        payload = [...this.createOverrideBudgetPayload()];
      }
      this.callAPI(payload);
    },
    callAPI(payload) {
      var name = 'campaign(s)';
      var that = this;
      var othersObj = {
        loader: that.loader,
        successMessage: 'Action has been submitted for the selected ' + name,
        errorMessage: 'Something went wrong.',
        successFn: () => {
          that.parentRef.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.loader = false;
          this.$emit('afterAPIActionToCall');
        }
      };
      this.loader = true;
      commons.bidChangeApiAction.call(this, {}, payload, othersObj);
      if (
        this.updatedBudgetValue.budgetType.radioValue ===
        'override_max_impressions'
      ) {
        this.onApplyForOverrideMaxImpressions();
      }
    },
    onApplyForOverrideMaxImpressions() {
      this.getAdGroupDataForViewPayload();
      const requestPayload = this.createPayloadRequestForManual();
      var that = this;
      that.loader = true;
      const othersObj = {
        loader: that.loader,
        successMessage: 'Action has been submitted',
        errorMessage: 'Something went wrong.',
        successFn: () => {},
        errorFn: () => {},
        finallyFn: () => {
          that.loader = false;
          this.$emit('afterAPIActionToCall');
        }
      };
      commons.bidChangeApiAction.call(
        this,
        that.selections,
        requestPayload,
        othersObj
      );
    },
    formFinalPayload(selection, budget_type, newBudget, lifetimeBudgetValue) {
      if (
        this.updatedBudgetValue?.budgetType?.radioValue ===
        'override_max_impressions'
      ) {
        return {
          actionPayload: {
            campaignId: selection.data.campaign_id,
            profileId: 0,
            newOptimizedBiddingEnabled: false,
            campaignType: selection.data.campaign_type,
            newBudgetType: budget_type,
            newBudget: newBudget,
            newLifetimeBudget: lifetimeBudgetValue
          },
          viewPayload: {
            name: selection.data.campaign_name,
            campaignId: selection.data.campaign_id,
            profileId: 0,
            campaignType: selection.data.campaign_type,
            oldBudgetType:
              selection.data.budget_type !== budget_type
                ? selection.data.budget_type === 'daily_budget'
                  ? 'Daily Budget'
                  : 'Max Impressions'
                : null,
            newBudgetType:
              selection.data.budget_type !== budget_type
                ? budget_type === 'daily_budget'
                  ? 'Daily Budget'
                  : 'Max Impressions'
                : null,
            newBudget: newBudget,
            lifetime_budget: lifetimeBudgetValue
          },
          actionType: 'instacartCampaignEditBidStatus',
          actionSource: {
            pageUrl: window.location.href
          }
        };
      } else {
        return {
          actionPayload: {
            campaignId: selection.data.campaign_id,
            profileId: 0,
            campaignType: selection.data.campaign_type,
            newBudgetType: budget_type,
            newBudget: newBudget,
            newLifetimeBudget: lifetimeBudgetValue
          },
          viewPayload: {
            name: selection.data.campaign_name,
            campaignId: selection.data.campaign_id,
            profileId: 0,
            campaignType: selection.data.campaign_type,
            oldBudgetType:
              selection.data.budget_type !== budget_type
                ? selection.data.budget_type === 'daily_budget'
                  ? 'Daily Budget'
                  : 'Max Impressions'
                : null,
            newBudgetType:
              selection.data.budget_type !== budget_type
                ? budget_type === 'daily_budget'
                  ? 'Daily Budget'
                  : 'Max Impressions'
                : null,
            newBudget: newBudget,
            newLifetimeBudget: lifetimeBudgetValue
          },
          actionType: this.actionType,
          actionSource: {
            pageUrl: window.location.href
          }
        };
      }
    },
    createOverrideBudgetPayload() {
      const payload = [];
      this.selections.forEach((item) => {
        const budgetType = 'daily_budget';
        const newBudget = this.updatedBudgetValue.budgetType.inputValue;
        let lifetimeBudgetValue = null;
        if (
          budgetType === 'daily_budget' &&
          this.adjustLifetimeBudgetSpeningLimit
        ) {
          lifetimeBudgetValue =
            this.lifetimeSpendingLimitForDailyBudget.radioValue === 'remove_lsl'
              ? 0
              : this.calculateInputValue(
                  this.lifetimeSpendingLimitForDailyBudget,
                  item?.data?.budget
                );
        } else if (budgetType === 'max_impressions') {
          // lifetimeBudgetValue = this.updatedBudgetValue.budgetType.inputValue;
          lifetimeBudgetValue = +this.lifetimeBudgetValue;
        }
        const payloadObj = this.formFinalPayload(
          item,
          budgetType,
          newBudget,
          lifetimeBudgetValue
        );
        if (item.data.budget_type !== budgetType) {
          payloadObj.viewPayload.previousBudgetType =
            item.data.budget_type === 'daily_budget'
              ? 'Daily Budget'
              : 'Max Impressions';
        }
        if (payloadObj.viewPayload.newLifetimeBudget === null) {
          payloadObj.viewPayload.newLifetimeBudget = item?.data?.budget;
        }
        payloadObj.viewPayload.previousLifetimeBudget = item.data.budget;
        payloadObj.viewPayload.previousBudget = item.data.target_daily_budget;
        payload.push(payloadObj);
      });
      return payload;
    },
    createRetainBudgetPayload() {
      const payload = [];
      this.filteredSelection.forEach((item) => {
        const budget_type = item.data.budget_type;
        const newBudgetObjValue = this.updatedBudgetValue[budget_type];
        const prevBudgetValue = item?.data?.target_daily_budget;
        const newBudget =
          budget_type === 'daily_budget'
            ? newBudgetObjValue?.value === 'dont_change_db'
              ? null
              : this.calculateInputValue(newBudgetObjValue, prevBudgetValue)
            : null;
        let lifetimeBudgetValue = null;
        if (
          budget_type === 'daily_budget' &&
          this.adjustLifetimeBudgetSpeningLimit
        ) {
          lifetimeBudgetValue =
            this.lifetimeSpendingLimitForDailyBudget?.radioValue ===
            'remove_lsl'
              ? 0
              : this.calculateInputValue(
                  this.lifetimeSpendingLimitForDailyBudget,
                  item?.data?.budget
                );
        } else if (budget_type === 'max_impressions') {
          lifetimeBudgetValue = this.calculateInputValue(
            this.updatedBudgetValue.max_impressions,
            item?.data?.budget
          );
        }
        const payloadObj = this.formFinalPayload(
          item,
          budget_type,
          newBudget,
          lifetimeBudgetValue
        );
        payloadObj.viewPayload.previousBudget = item?.data?.target_daily_budget;
        payloadObj.viewPayload.previousLifetimeBudget = item?.data?.budget;
        if (payloadObj.viewPayload.newLifetimeBudget === null) {
          payloadObj.viewPayload.newLifetimeBudget = item?.data?.budget;
        }
        payload.push(payloadObj);
      });
      return payload;
    }
  }
};
</script>
<style lang="css">
.info-icon-adjust-budget.rb-icon::before {
  font-size: 12px !important;
}
</style>
<style lang="css" scoped>
.adjust-budget-dashed-border {
  border-right-style: dashed;
}
.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
}
.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 32px;
}
.table-right {
  height: 420px !important;
}
.paginationFooter {
  border: 1px solid #4b5158 !important;
  background: none !important;
  padding-bottom: 14px;
}
</style>
