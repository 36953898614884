<template>
  <div
    class="u-position-relative u-font-size-5 u-spacing-pv-m u-spacing-pr-xxl"
  >
    <p
      v-if="alert.alertType === 'Price Increase Alert'"
      class="u-font-size-3 u-font-weight-600"
    >
      {{ $t('price_increase') }}
    </p>
    <p
      v-if="alert.alertType === 'Price Decrease Alert'"
      class="u-font-size-3 u-font-weight-600"
    >
      {{ $t('price_drop') }}
    </p>
    <p class="u-font-size-4 u-font-weight-600 u-spacing-pt-l">
      <span v-if="alert.alertType === 'Price Decrease Alert'">{{
        $t('price_dropped_to_')
      }}</span
      ><span v-if="alert.alertType === 'Price Increase Alert'">{{
        $t('price_increased_to_')
      }}</span
      >{{ formatter(alert.attributes.offer_price_current, 'currency')
      }}{{ $t('_from_')
      }}{{ formatter(alert.attributes.avg_price_week3, 'currency') }} ({{
        $t('Avg_selling_price_in_the_last_3_weeks')
      }}).
    </p>
    <p class="u-font-size-6 u-spacing-pt-s">
      {{ $t('appeared_on_')
      }}{{ formatter(alert.attributes.recency_date, 'date') }} &bull;
      {{ $t('appeared_') }}{{ alert.attributes.recency
      }}{{ $t('_out_of_last_30_days') }}
    </p>
    <div class="u-display-flex u-spacing-pt-xl">
      <div
        class="u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('category')"
          :item-value="alert.attributes.categoryl3 || 'N/A'"
        />
      </div>
      <div class="u-spacing-ph-s">
        <key-value-pair
          :item-key="$t('priority')"
          :item-value="formatter(alert.attributes.sku_priority_rank, 'number')"
        />
      </div>
    </div>
    <div class="u-display-flex u-spacing-pt-l u-spacing-pb-xl">
      <div
        class="u-display-flex u-flex-align-items-center u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <span class="u-font-weight-600">{{ $t('rating') }}: </span>
        <star-rating
          :active-color="'#f3b000'"
          :inactive-color="'#f3b000'"
          :max="5"
          :value="Math.round(alert.attributes.avg_product_rating)"
          :readonly="true"
        >
          <span
            slot="activeLabel"
            slot-scope="props"
            ><rb-icon :icon="'star'"
          /></span>
          <span
            slot="inactiveLabel"
            slot-scope="props"
            ><rb-icon :icon="'star-empty'"
          /></span>
        </star-rating>
        <span
          >({{ alert.attributes.avg_product_rating || 'NA' }}
          {{ $t('out_of_5') }})</span
        >
      </div>
      <div class="u-spacing-pl-s u-display-flex u-flex-align-items-center">
        <key-value-pair
          :item-key="$t('reviews')"
          :item-value="alert.attributes.customer_reviews_count || 'NA'"
        />
      </div>
    </div>
    <div class="detail-view--card chart--card">
      <div class="detail--chart">
        <chart
          v-if="showChart"
          style="height: 270px"
          class="u-spacing-mh-l u-spacing-pv-l"
          :config="barChartConfig"
          :data="barChartData"
        />
      </div>
      <div class="date--block u-spacing-pv-s u-spacing-ph-l">
        {{ $t('last_updated') }}:
        {{ formatter(alert.attributes.feed_date, 'date') }}
      </div>
    </div>
    <p
      v-if="reasons"
      class="u-spacing-pt-xl u-font-size-5 u-font-weight-600 u-color-grey-lighter"
    >
      POSSIBLE CAUSES
    </p>
    <ol style="list-style: decimal inside">
      <li
        v-for="(value, $index) of reasons"
        :key="$index"
        class="u-font-size-4 u-spacing-pt-s u-spacing-pb-s"
      >
        {{ value }}
      </li>
    </ol>
    <!-- <p class="u-spacing-pt-l u-font-size-5 u-font-weight-600 u-color-grey-lighter">
      {{$t('recommended_action')}}
    </p>
    <p v-if="alert.attributes.mean_estimate_value" class="u-spacing-pv-xs u-color-blue-base">
      Estimated value: {{alert.attributes.min_estimate_value | num_format('$')}} - {{alert.attributes.max_estimate_value | num_format('$')}}
      <el-tooltip effect="dark" :content="'Estimated Value'" placement="top">
        <rb-icon class="u-color-blue-base help-fill-size" :icon="'help-fill'"/>
      </el-tooltip>
    </p>
    <p v-if="alert.alertType === 'Price Increase Alert'" class="u-font-size-4 u-spacing-pt-s u-spacing-pb-m">
      {{$t('price_increase_rec_text')}}
    </p>
    <p v-if="alert.alertType === 'Price Decrease Alert'" class="u-font-size-4 u-spacing-pt-s u-spacing-pb-m">
      {{$t('price_decrease_rec_text1')}}
    </p>
    <p v-if="alert.alertType === 'Price Decrease Alert'" class="u-font-size-4 u-spacing-pt-m u-spacing-pb-s">
      {{$t('price_decrease_rec_text2')}}
    </p>
    <div v-if="alert.alertType === 'Price Decrease Alert'" class="detail-view--card">
      <el-table :data="[{}]">
        <el-table-column
          :label="$t('SKU_details')"
          :width="400">
          <div slot-scope="scope">
            <div>
              <p :title="alert.attributes.recommended_sku_title" class="u-text-overflow-ellipsis">
                {{alert.attributes.recommended_sku_title}}
              </p>
              <p>{{alert.attributes.recommended_client_sku}}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column
          :label="$t('avg_selling_price') + ' (' + $t('last_7_days') + ')'">
          <div slot-scope="scope">
            <div>
              <p>{{formatter(alert.attributes.recommended_sku_avg_price_week1, 'currency')}}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column
          :label="$t('current_selling_price')">
          <div slot-scope="scope">
            <div>
              <p>{{formatter(alert.attributes.recommended_sku_offer_price_current, 'currency')}}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column
          :label="$t('category')">
          <div slot-scope="scope">
            <div>
              <p>{{alert.attributes.recommended_sku_categoryl1 || 'NA'}}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column
          :label="$t('priority')">
          <div slot-scope="scope">
            <div>
              <p>{{alert.attributes.recommended_sku_priority_rank || 'NA'}}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column
          :label="$t('rating') + ' (' + $t('reviews') + ')'">
          <div slot-scope="scope">
            <div>
              <p>{{alert.attributes.recommended_sku_ratings || 'NA'}} ({{alert.attributes.recommended_sku_reviews || 'NA'}})</p>
            </div>
          </div>
        </el-table-column>
      </el-table>
    </div> -->
  </div>
</template>

<script>
import chart from '@/components/basic/chart';
import starRating from '@/components/widgets/starRatings';
import keyValuePair from '@/components/basic/keyValuePair';

export default {
  components: {
    chart,
    starRating,
    keyValuePair
  },
  props: {
    alert: {
      type: Object,
      default: null
    },
    // eslint-disable-next-line vue/require-default-prop
    formatter: {
      type: Function
    }
  },
  data() {
    return {
      showChart: false,
      rollbackArr: [],
      clearanceArr: [],
      barChartConfig: {
        xAxisType: 'timeseries',
        load: false,
        chartOptions: {
          timeseries: {},
          axes: {
            Rollback: 'y2',
            Clearance: 'y2',
            'Search Rank Index': 'y2'
          },
          tooltip_format: {
            Rollback: function (value, ratio, id, index) {
              return this.rollbackArr[index] ? 'Yes' : 'No';
            }.bind(this),
            Clearance: function (value, ratio, id, index) {
              return this.clearanceArr[index] ? 'Yes' : 'No';
            }.bind(this)
          },
          point_format: {
            Rollback: function (data) {
              if (this.rollbackArr[data.index]) {
                return 5;
              } else {
                return 2;
              }
            }.bind(this),
            Clearance: function (data) {
              if (this.clearanceArr[data.index]) {
                return 5;
              } else {
                return 2;
              }
            }.bind(this)
          }
        }
      },
      barChartData: [],
      reasons: null
    };
  },
  mounted() {
    var xs = {};
    var dataArray = [];
    var dateArray = [];
    var chartData = [];
    var entry;

    var offerPriceHistory = [];
    if (this.alert.attributes.offer_price_history_json) {
      offerPriceHistory = JSON.parse(
        this.alert.attributes.offer_price_history_json
      );
      if (offerPriceHistory.length) {
        dataArray = ['Price on ' + offerPriceHistory[0].retailer_site];
        dateArray = ['Price on ' + offerPriceHistory[0].retailer_site + '_x'];
        for (entry of offerPriceHistory) {
          if (entry.sas_offer_price === undefined) continue;
          dataArray.push(entry.sas_offer_price);
          dateArray.push(entry.date);
        }
        chartData.push(dataArray, dateArray);
        xs[dataArray[0]] = dateArray[0];
        this.barChartConfig.chartOptions.tooltip_format[dataArray[0]] = {
          roundoff: 2,
          pre: 'currency'
        };
      }
    }

    var compOfferPriceHistory = [];
    if (this.alert.attributes.comp1_retailer_offer_price_trend) {
      compOfferPriceHistory = JSON.parse(
        this.alert.attributes.comp1_retailer_offer_price_trend
      );
      if (compOfferPriceHistory.length) {
        var comps = Array.from(
          new Set(
            compOfferPriceHistory.map((item) => {
              return item.retailer_site;
            })
          )
        );

        if (comps && comps.length) {
          for (var comp of comps) {
            dataArray = ['Price on ' + comp];
            dateArray = ['Price on ' + comp + '_x'];
            for (entry of compOfferPriceHistory) {
              if (entry.retailer_site === comp) {
                if (entry.offer_price === undefined) continue;
                dataArray.push(entry.offer_price);
                dateArray.push(entry.date);
              }
            }
            chartData.push(dataArray, dateArray);
            xs[dataArray[0]] = dateArray[0];
            this.barChartConfig.chartOptions.tooltip_format[dataArray[0]] = {
              roundoff: 2,
              pre: 'currency'
            };
          }
        }
      }
    }

    var sriTrend = [];
    if (this.alert.attributes.sri_trend) {
      sriTrend = JSON.parse(this.alert.attributes.sri_trend);
      if (sriTrend.length) {
        dataArray = ['Search Rank Index'];
        dateArray = ['Search Rank Index_x'];
        for (entry of sriTrend) {
          if (entry.sri_value === undefined) continue;
          dataArray.push(entry.sri_value);
          dateArray.push(entry.date);
        }
        xs[dataArray[0]] = dateArray[0];
        chartData.push(dataArray, dateArray);
        this.barChartConfig.chartOptions.tooltip_format[dataArray[0]] = {
          roundoff: 2
        };
      }
    }

    var rollbackTrend = [];
    if (this.alert.attributes.rollback_trend) {
      rollbackTrend = JSON.parse(this.alert.attributes.rollback_trend);
      if (rollbackTrend.length) {
        rollbackTrend = rollbackTrend.sort((a, b) => {
          if (a.date < b.date) return -1;
          else if (a.date > b.date) return 1;
          return 0;
        });
        dataArray = ['Rollback'];
        dateArray = ['Rollback_x'];
        for (entry of rollbackTrend) {
          if (entry.is_on_rollback === undefined) continue;
          dateArray.push(entry.date);
          dataArray.push(0);
          this.rollbackArr.push(entry.is_on_rollback);
        }
        xs[dataArray[0]] = dateArray[0];
        chartData.push(dataArray, dateArray);
      }
    }

    var clearanceTrend = [];
    if (this.alert.attributes.clearance_trend) {
      clearanceTrend = JSON.parse(this.alert.attributes.clearance_trend);
      if (clearanceTrend.length) {
        clearanceTrend = clearanceTrend.sort((a, b) => {
          if (a.date < b.date) return -1;
          else if (a.date > b.date) return 1;
          return 0;
        });
        dataArray = ['Clearance'];
        dateArray = ['Clearance_x'];
        for (entry of clearanceTrend) {
          if (entry.is_on_clearance === undefined) continue;
          dateArray.push(entry.date);
          dataArray.push(0);
          this.clearanceArr.push(entry.is_on_clearance);
        }
        xs[dataArray[0]] = dateArray[0];
        chartData.push(dataArray, dateArray);
      }
    }

    // var bsrHistory = JSON.parse(this.alert.attributes.bsr_current_value_history_json);
    // if (bsrHistory && bsrHistory.length) {
    //   dataArray = ['Best Seller Rank (Category: ' + bsrHistory[0].category + ')']
    //   dateArray = ['Best Seller Rank (Category: ' + bsrHistory[0].category + ')_x']
    //   for (entry of bsrHistory) {
    //     if (entry.bsr_rank === undefined) continue
    //     dataArray.push(entry.bsr_rank)
    //     dateArray.push(entry.date)
    //   }
    //   xs[dataArray[0]] = dateArray[0]
    //   chartData.push(dataArray, dateArray)
    //   this.barChartConfig.chartOptions.axes[dataArray[0]] = 'y2'
    //   this.barChartConfig.chartOptions.tooltip_format[dataArray[0]] = {
    //     roundoff: 0
    //   }
    // }

    if (this.alert.attributes.reasons && this.alert.attributes.reasons.length) {
      this.reasons = this.alert.attributes.reasons.split(' | ').filter((a) => {
        return a !== '';
      });
    }

    this.barChartConfig.chartOptions.timeseries = xs;
    this.barChartData = chartData;
    this.showChart = true;
  }
};
</script>

<style scoped lang="css">
.icon--task .rb-icon {
  height: 16px;
  width: 16px;
}
.icon--task .rb-icon::before {
  font-size: 16px;
}
</style>
