<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div
      class="u-display-flex u-flex-justify-content-space-between u-font-size-5 u-spacing-mt-s"
    >
      <div
        class="u-display-flex u-border-radius-m u-spacing-ml-s u-spacing-p-m rca-highlight-banner"
      >
        <img
          src="https://cdn.rboomerang.com/remcc/quick-summary.svg"
          class="u-spacing-mr-s u-spacing-mb-xl"
          alt="quick summary"
        />
        <div v-html="rcaHighlight" />
      </div>
      <div
        class="u-display-flex u-flex-justify-content-space-between u-spacing-mr-m rca-impact-indicators"
      >
        <div
          v-for="(impactIndicator, index) in impactIndicators"
          :key="index"
          class="u-display-flex"
        >
          <img
            class="u-spacing-mr-s u-icon-size-5 u-spacing-mt-s"
            alt="correlation"
            :src="impactImageUrl(impactIndicator)"
          />
          <div class="u-color-grey-light u-spacing-mt-s">
            {{ impactIndicator.label }}
          </div>
        </div>
      </div>
    </div>
    <div class="tree u-display-flex u-flex-justify-content-center">
      <ul class="tree-list pt-10 u-position-relative">
        <node-tree :node="treeData" />
      </ul>
    </div>
  </div>
</template>

<script>
import nodeTree from '@/components/pages/insights/amazon/rem-cc/molecules/node-tree.vue';

export default {
  components: {
    nodeTree
  },
  props: {
    treeData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      impactIndicators: [
        {
          style: 'u-color-purple',
          label: 'High Impact',
          theme: 'purple'
        },
        {
          style: 'u-color-orange',
          label: 'Medium Impact',
          theme: 'yellow'
        },
        {
          style: 'u-color-aqua-dark',
          label: 'Low Impact',
          theme: 'blue'
        }
      ],
      rcaHighlight:
        'Your <span class="u-font-weight-600">Marketshare</span> has dropped to  <span class="u-color-red-base u-font-weight-600">22%</span> majorly because your <span class="u-font-weight-600">Share of Voice</span> went down to  <span class="u-color-red-base u-font-weight-600">17%</span>, <span class="u-font-weight-600">Availability</span> to  <span class="u-color-red-base u-font-weight-600">87%</span> and <span class="u-font-weight-600">Conversion</span> to  <span class="u-color-red-base u-font-weight-600">30%</span>'
    };
  },
  methods: {
    impactImageUrl(impact) {
      return (
        'https://cdn.rboomerang.com/remcc/correlation-' + impact.theme + '.svg'
      );
    }
  }
};
</script>
<style scoped>
.rca-highlight-banner {
  background-color: #e9fafe;
  max-width: 30%;
}
.rca-impact-indicators {
  width: 28%;
}
</style>
