<template>
  <section
    class="u-display-flex u-flex-align-items-center borders-outline tabs-radius dashboard-background"
  >
    <div
      v-for="(tab, index) in tabsData"
      :key="index"
      class="u-height-100 tabs-radius u-spacing-pv-xs u-spacing-ph-m u-cursor-pointer u-font-size-7"
      :class="{
        'u-bg-color-blue-base': index === activeTabIndex,
        'u-color-grey-white': index === activeTabIndex,
        'u-font-weight-600': index === activeTabIndex,
        'chip-size-s': chipSize === 's',
        'chip-size-m': chipSize === 'm',
        'chip-size-xm': chipSize === 'xm'
      }"
      @click="handleTabClick(index)"
    >
      <div
        v-tippy="{ placement: 'top', arrow: false }"
        :title="
          activeTabIndex === index ? '' : 'Switch to ' + tab.label + ' view'
        "
        class="u-display-flex u-flex-justify-content-center u-flex-align-items-center"
      >
        <div class="u-spacing-pv-xs">
          {{ tab.label }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    chipSize: {
      type: String,
      default: 's'
    },
    tabsData: {
      type: Array,
      default: () => []
    },
    activeTabIndex: {
      type: Number,
      default: 0
    }
  },
  methods: {
    handleTabClick(tabIndex) {
      this.$emit('tabClick', tabIndex);
    }
  }
};
</script>

<style lang="css" scoped>
.chip-size-s {
  padding-top: 1.5px;
  padding-bottom: 1.5px;
  font-size: 11px;
}
.chip-size-xm {
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  font-size: 11px;
}
.chip-size-m {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
}
.borders-outline {
  border: 1px solid transparent;
}
.tabs-radius {
  border-radius: 100px;
}
.dashboard-background {
  background-color: #f5f7fa;
}
</style>
