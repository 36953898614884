var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-display-flex u-flex-justify-content-center"},[_vm._l((_vm.computedPlaces),function(place){return [(_vm.topPerformers[place - 1])?_c('div',{key:place,staticClass:"u-display-flex u-flex-direction-column u-flex-justify-content-flex-end u-width-100 u-height-100 u-spacing-mh-s",style:({ overflowWrap: 'anywhere' }),attrs:{"id":'place-' + place}},[_c('div',{staticClass:"stand u-border-radius-s u-width-100 u-position-relative u-display-flex u-flex-align-items-center u-flex-direction-column u-flex-justify-content-flex-end u-spacing-pv-s",style:({ backgroundColor: _vm.colors[place - 1] })},[_c('div',{staticClass:"image-wrapper u-spacing-p-s u-position-absolute u-border-radius-xxxl u-border-all u-display-flex u-flex-justify-content-center u-flex-align-items-center",class:{ clientFlag: _vm.isClientFlag(place) },style:({
            backgroundColor: _vm.colors[place - 1],
            borderColor: _vm.isClient(
              _vm.topPerformers[place - 1].client_flag,
              _vm.colorsDark[place - 1]
            ),
            'border-width': _vm.isClientFlag(place) ? 'thick' : ''
          })},[(_vm.isFirst(place))?_c('span',{staticClass:"image-badge u-display-flex u-flex-align-items-center u-flex-justify-content-center u-position-absolute u-icon-size-1",style:({ backgroundColor: _vm.colors[place - 1] })},[_c('img',{staticClass:"u-icon-size-2",attrs:{"src":"https://cdn.rboomerang.com/cva/Leader_Crown.svg"}})]):_vm._e(),(!_vm.initials[_vm.topPerformers[place - 1].name])?_c('img',{staticClass:"u-border-radius-xxxl u-width-100",attrs:{"src":_vm.topPerformers[place - 1].image},on:{"error":function($event){return _vm.handleNoImage(_vm.topPerformers[place - 1].name)}}}):_c('div',{staticClass:"u-border-radius-xxxl u-width-100 u-height-100 u-display-flex u-flex-align-items-center u-flex-justify-content-center u-font-size-2 u-text-case-upper u-color-white-base",style:({ backgroundColor: _vm.colorsDark[place - 1] })},[_vm._v(" "+_vm._s(_vm.initials[_vm.topPerformers[place - 1].name])+" ")])]),_c('div',{staticClass:"u-display-flex desc-container u-flex-direction-column u-flex-align-items-center",class:{
            'u-flex-justify-content-space-between': (
              _vm.topPerformers[place - 1].leadingIn || ''
            ).length,
            'u-flex-justify-content-space-evenly': !(
              _vm.topPerformers[place - 1].leadingIn || ''
            ).length
          }},[_c('span',{staticClass:"u-font-size-6 u-text-case-title u-text-align-center",style:({
              color: _vm.isClient(
                _vm.topPerformers[place - 1].client_flag,
                _vm.defaultColor
              )
            })},[_vm._v(_vm._s(_vm.topPerformers[place - 1].name))]),_c('div',{staticClass:"badge u-border-radius-xxxl u-font-size-4 u-font-weight-bolder u-display-flex u-flex-justify-content-center u-flex-align-items-center",style:({ backgroundColor: _vm.colorsDark[place - 1] })},[_vm._v(" "+_vm._s(place)+" ")]),((_vm.topPerformers[place - 1].leadingIn || '').length)?_c('div',{staticClass:"u-display-flex u-flex-direction-column u-text-align-center u-font-size-7"},[_c('span',[_vm._v(_vm._s(_vm.leadingText))]),_c('div',{staticClass:"u-spacing-mt-xs u-display-flex u-flex-align-items-center u-flex-justify-content-center u-text-case-title"},[_c('img',{attrs:{"src":"https://cdn.rboomerang.com/cva/Star.svg"}}),_c('span',{staticClass:"u-color-green-base u-spacing-pl-xs u-font-weight-600"},[_vm._v(_vm._s(_vm.topPerformers[place - 1].leadingIn))])])]):_vm._e()])])]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }