<template>
  <div class="u-position-relative">
    <modal
      v-if="showModal"
      :modal-config="modalConfig"
      @clickedOutside="modalVisible(false)"
    >
      <div
        slot="header"
        class="u-text-align-center u-font-size-1 u-font-weight-600 u-width-100 u-spacing-pt-xxl u-spacing-ph-xl"
      >
        Campaign List "{{ selectedTag }}" will be removed from "{{
          entityName
        }}"
      </div>
    </modal>
    <customEntity
      :widgets-config="widgetsConfig"
      class="campaign_lists_container"
      :custom-load="load"
      :filter-v2="filterV2"
      :ingestion-dependent-entities="ingestionDependentEntities"
      :ingestion-tool-tip-text="ingestionToolTipText"
      @entityInstance="entityInstance"
      @localFilter="setLocalFilter"
    >
      <div
        slot="top-right"
        class="u-display-flex"
      >
        <div
          class="u-spacing-pr-l legend-container-height u-flex-align-items-center u-display-flex"
        >
          <!-- <div class="u-spacing-pv-xs u-border-radius-s u-spacing-ph-s tag-background-color" />
          <div class=" u-spacing-pl-xs u-font-size-7">
            Campaign List
          </div>
        </div> -->
          <div class="u-spacing-pt-xxs">
            <div
              class="u-spacing-ph-l u-border-color-grey-xxx-light u-border-width-s u-border-right"
            >
              <rb-button
                slot="trigger"
                :click-fn="createCampaignList"
                text="Create a Campaign List"
                size="s"
                icon-left="plus"
                type="filled"
              />
            </div>
          </div>
          <div
            v-for="(iconData, index) in headerIcons"
            :key="index"
          >
            <div
              class="u-spacing-ml-l u-color-grey-lighter u-position-relative u-cursor-pointer u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center"
              @click="handleIconClick(iconData)"
            >
              <loader
                v-if="iconData.load"
                class="fill--parent u-bg-color-grey-white"
                :loading="true"
                :color="'#007cf6'"
              />
              <rb-icon
                :icon="iconData.icon"
                :class="['rb-icon--' + iconData.size || 'medium']"
              />
              <div
                data-cy="actionTitle"
                class="u-font-size-8 u-spacing-mt-xs"
              >
                {{ iconData.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </customEntity>
  </div>
</template>
<script>
import customEntity from '@/pages/toolsAndSettings/entityTagging/common/viewCustomEntity/index.vue';
import config from './widgets.js';
import moment from 'moment';
import loader from '@/components/basic/loader';
import modal from '@/components/widgets/modal.vue';
import { filters } from '@ciq-dev/ciq-ui';
export default {
  components: {
    customEntity,
    modal,
    loader
  },
  data() {
    return {
      filterV2: filters.config_check('feature.filter_v2.enable') || false,
      ingestionDependentEntities: ['campaign'],
      ingestionToolTipText:
        'Custom list updation or creation is in progress. It will take upto 45 mins for the changes to reflect.',
      localFilters: [],
      widgetsConfig: null,
      childInstance: null,
      entityName: null,
      selectedEntity: null,
      load: false,
      selectedTag: null,
      showModal: false,
      headerIcons: [{ icon: 'download', title: 'DOWNLOAD', load: false }],
      path: 'campaign-lists/tag',
      modalConfig: {
        body: {
          text: 'Are you sure you want to perform this action?'
        },
        footer: {
          leftButton: {
            text: 'Cancel',
            onClick: () => {
              this.showModal = false;
            },
            icon: '',
            show: true
          },
          rightButton: {
            text: 'Remove',
            onClick: () => {
              this.handleRemoveTag();
            },
            icon: '',
            show: true
          }
        }
      }
    };
  },
  created() {
    this.widgetsConfig = config;
  },
  methods: {
    setLocalFilter(data) {
      this.localFilters = data;
    },
    handleIconClick(iconData) {
      if (iconData.title === 'DOWNLOAD') {
        this.download(iconData);
      }
    },
    download(iconData) {
      iconData.load = true;
      let message = '';
      this.$store
        .dispatch('performEntityTagsDownlaod', {
          APIConfig: this.widgetsConfig.APIConfig,
          localFilters: this.localFilters,
          columnMap: [
            {
              field: 'campaign_name',
              title: 'Campaign'
            },
            {
              field: 'tags',
              title: 'Tags'
            }
          ],
          entity: 'campaign',
          fileName: 'Campaign Lists - ' + moment().format('lll')
        })
        .then(() => {
          message = 'Downloaded Campaign Lists successfully';
        })
        .catch(() => {
          message = 'Campaign Lists download failed, please try again';
        })
        .finally(() => {
          this.openSnackbar(message);
          iconData.load = false;
        });
    },
    createCampaignList() {
      this.$router.push({ path: this.path });
    },
    modalVisible(value) {
      this.showModal = value;
    },
    handleRemoveTag() {
      const data = {
        tag: {
          level1: this.selectedTag,
          type: 'custom',
          customType: 'Custom'
        },
        entityDetail: {
          value: this.selectedEntity,
          type: 'campaign'
        }
      };
      let message = '';
      this.load = true;
      this.$store
        .dispatch('removeEntityFromATag', data)
        .then(() => {
          message = 'Campaign list was created successfully';
        })
        .catch(() => {
          message = 'Error, please try again';
        })
        .finally(() => {
          this.openSnackbar(message);
          this.load = false;
          this.childInstance.fetchTableRows();
        });
    },
    openSnackbar(message) {
      const snackbarObject = {
        message: '',
        duration: 5000,
        actionText: ''
      };
      snackbarObject.message = message;
      this.$snackbar.open(snackbarObject);
    },
    entityInstance(that) {
      this.childInstance = that;
      that.customCampaignListDropdown = (params, instance) => {
        this.customCampaignListDropdown(params, instance);
      };
    },
    customCampaignListDropdown(params, instance) {
      this.selectedEntity = instance?.$parent?.params?.data?.campaign_id;
      this.entityName = instance?.$parent?.params?.data?.campaign_name;
      if (!params[0]) return;
      this.selectedTag = params?.title || '';
      if (params[0].key === 'manage_campaign_list') {
        this.$router.push({ path: this.path, query: { tag: params.title } });
      } else if (params[0].key === 'remove_campaign_list') {
        this.modalVisible(true);
      }
    }
  }
};
</script>

<style lang="css">
.campaign_lists_container .is-active .tag_token {
  color: #fff;
  background: #bd10e0;
}
.campaign_lists_container .tag_token {
  background: rgba(189, 16, 224, 0.1);
}
.campaign_lists_container .tag_token:hover {
  color: #fff;
  background: #bd10e0;
}
.campaign_lists_container .legend-container-height {
  height: 30px;
}
.campaign_lists_container .legend-container-height .tag-background-color {
  background: rgba(189, 16, 224, 0.1);
}
</style>
