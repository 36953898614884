import { SelectAllSelector } from './select-all-selector';
import { Selector } from './selector';
import { SelectorGroup } from './selector-group';

export class Radio extends Selector {
  private _selected: boolean | null = false;
  constructor(public id: string, public group: SelectorGroup<Selector>) {
    super(group);
  }

  get icon() {
    return (
      'radio-' +
      (this.isSelected === true || this.isSelected === null
        ? 'selected'
        : 'empty')
    );
  }

  handleClick() {
    if (this.disabled) {
      return;
    }
    // Radio is selected if not selected
    // It is not deselected if already enabled
    if (!this.isSelected) {
      this.select(true);
    }
  }

  get isSelected() {
    return this._selected;
  }

  _select(): void {
    // Unselect all selections parallel
    // Select self
    if (this.disabled !== undefined) {
      return;
    }
    this.group.selectorsArray.map((selector) => selector.deselect());
    this._selected = true;
    this.group.parent?.select(false);
  }

  _deselect(): void {
    if (this.disabled !== undefined) {
      return;
    }
    this._selected = false;
  }
}

export class NestedRadio extends Radio {
  get isSelected() {
    if (
      this.childrenGroup?.selectorsArray.every((x) => x.isSelected === true)
    ) {
      return true;
    } else if (
      this.childrenGroup?.selectorsArray.some(
        (x) => x.isSelected === true || x.isSelected === null
      )
    ) {
      return null;
    } else {
      return false;
    }
  }

  _select(childSelect = true): void {
    this.group.selectorsArray.forEach((sibling) => {
      if (sibling.id !== this.id) {
        sibling.deselect();
      }
    });
    if (childSelect) {
      this.childrenGroup?.selectorsArray.forEach((child) => child.select(true));
    }
    this.group.parent?.select(false);
  }

  _deselect(): void {
    this.childrenGroup?.selectorsArray.forEach((child) => child.deselect());
  }
}

export class RadioGroup extends SelectorGroup<Radio | NestedRadio> {
  multi = false;

  get selectAllSelector(): SelectAllSelector | null {
    return null;
  }
}
