import { render, staticRenderFns } from "./productNameDescription.vue?vue&type=template&id=36f19d65&scoped=true&"
import script from "./productNameDescription.vue?vue&type=script&lang=js&"
export * from "./productNameDescription.vue?vue&type=script&lang=js&"
import style0 from "./productNameDescription.vue?vue&type=style&index=0&id=36f19d65&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36f19d65",
  null
  
)

export default component.exports