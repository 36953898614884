<template>
  <div
    ref="tagsContainer"
    class="u-display-flex u-flex-align-items-space-between u-width-100 u-height-100 u-flex-wrap"
  >
    <rb-select
      v-for="(item, index) in tagsList"
      ref="activeTags"
      :key="index"
      :send-details="true"
      :context="item"
      :on-close="handleDropdownOnClose"
      :options="dropdownOptions"
      @active-change="
        (data) => {
          handleActiveChange(data, index);
        }
      "
    >
      <span slot="trigger">
        <div :ref="`activeTags_${index}`" />
        <tag
          class="u-cursor-pointer"
          :title="item.title"
          :enable-style-api="enableStyleApi"
          :tippy-title="item.tooltip ? item.tooltip : item.title"
          @mouseEnter="
            (data) => {
              handleMouseEnter(data, index);
            }
          "
          @mouseLeave="
            (data) => {
              handleMouseLeave(data, index);
            }
          "
          @tagClicked="
            (data) => {
              handleTagClicked(data, index, item);
            }
          "
        />
      </span>
    </rb-select>
    <div
      ref="width-limiter"
      class="u-position-absolute positioning u-bg-color-grey-white u-font-weight-600"
      :class="[
        hiddenTagsCount ? 'u-visibility-visible' : 'u-visibility-hidden'
      ]"
    >
      <span class="u-spacing-pr-xs">&</span>
      <span class="u-color-blue-base">{{ computedOverflowText }}</span>
    </div>
  </div>
</template>
<script>
import tag from '@/components/widgets/tag';

export default {
  name: 'TagsViewCell',
  components: {
    tag
  },
  props: {
    enableStyleApi: {
      type: Boolean,
      default: false
    },
    tagsList: {
      type: Array,
      default() {
        return [];
      }
    },
    dropdownOptions: {
      type: Array,
      default() {
        return [];
      }
    },
    preferEmitter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lastDropdownTriggerTitle: null,
      lastDropdownTriggerId: null,
      hiddenTagsCount: 0,
      tagStates: {}
    };
  },
  computed: {
    computedOverflowText() {
      return this.hiddenTagsCount + ' more';
    }
  },
  mounted() {},
  methods: {
    handleMouseEnter(data, index) {
      this.$set(this.tagStates, index, {
        ...this.tagStates[index],
        isHovering: true
      });
    },
    handleMouseLeave(data, index) {
      this.$set(this.tagStates, index, {
        ...this.tagStates[index],
        isHovering: false
      });
    },
    handleActiveChange(data, index) {
      this.$set(this.tagStates, index, {
        ...this.tagStates[index],
        isDropdownActive: data
      });
    },
    handleDropdownOnClose(context, data) {
      this.$emit('onDropdownOptionSelect', { ...context, ...data }, this);
    },
    handleTagClicked(data, index, tagItem) {
      if (this.preferEmitter) {
        this.$emit('tagClicked', { tagItem, data, index });
        return;
      }
      if (
        this.$refs['activeTags_' + index] &&
        this.$refs['activeTags_' + index][0] &&
        this.$refs['activeTags_' + index][0].click()
      ) {
        this.$refs['activeTags_' + index][0].click();
      }
    }
  }
};
</script>
<style lang="css" scoped>
.positioning {
  right: 0;
  color: rgba(189, 16, 224, 1);
}
</style>
