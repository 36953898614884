import Vue from 'vue';
import HttpService from '@/utils/services/http-service';
import moment from 'moment-timezone';

const state = {
  subScriptionDetails: {},
  isLoading: false,
  isError: false
};

const getters = {
  getSubscriptionDetails: (state) => {
    return state;
  },
  isLoading: (state) => {
    return state.isLoading;
  },
  isError: (state) => {
    return state.isError;
  }
};

const mutations = {
  SHOW_LOADING: (state) => {
    state.isLoading = true;
  },
  HIDE_LOADING: (state) => {
    state.isLoading = false;
  },
  SUBSCRIPTION_DATA: (state, data) => {
    state.subScriptionDetails = data;
  },
  SHOW_ERROR: (state) => {
    state.isError = true;
  },
  HIDE_ERROR: (state) => {
    state.isError = false;
  }
};

const actions = {
  fetchSubscriptionDetails: (context) => {
    context.commit('SHOW_LOADING');
    HttpService.post('READSUBSCRIPTION', { entityType: 'RECOMMENDATION' }).then(
      (response) => {
        // var entity = response.data.subscriptionDetails[0];
        // let entityTransform = entity.entityInfo.sort((a, b) => {
        //   // return a.entityHierarchy1Order > b.entityHierarchy1Order;
        //   return (a.entityHierarchy1Order > b.entityHierarchy1Order) ? 1 : ((b.entityHierarchy1Order > a.entityHierarchy1Order) ? -1 : 0);
        // }).reduce((initial, item) => {
        //   if (item.hasSubscribed) {
        //     item.hasSubscribed = false;
        //   }
        //   if (initial[item.entityHierarchy1]) {
        //     initial[item.entityHierarchy1].push(item);
        //   } else {
        //     initial[item.entityHierarchy1] = [];
        //     initial[item.entityHierarchy1].push(item);
        //   }
        //   return initial;
        // }, {});
        context.commit('HIDE_LOADING');
        context.commit('SUBSCRIPTION_DATA', response.data);
        context.commit('HIDE_LOADING');
      },
      (err) => {
        this.$snackbar.open({
          message: 'Something Went Wrong !!!' || err,
          duration: 3000,
          actionText: ''
        });
      }
    );
  },
  subscribe: (context, data) => {
    context.commit('SHOW_LOADING');
    let subscribeAlert = {};
    subscribeAlert =
      state.subScriptionDetails &&
      state.subScriptionDetails.subscriptionDetails[0] &&
      state.subScriptionDetails.subscriptionDetails[0].entityInfo &&
      state.subScriptionDetails.subscriptionDetails[0].entityInfo.filter(
        (item) => {
          return item.entityKey === data.entityKey;
        }
      )[0];
    const entity = {};
    entity.notificationMedium = ['EMAIL'];
    entity.schedule = {
      scheduleFrequencyLabel: 'DAILY',
      scheduleType: 'ALWAYS_FRESH'
    };
    entity.internationalisation = {
      locale: Vue.options.filters.config_check('feature.locale') || 'en_US',
      currency: Vue.prototype.$currency,
      // IANA time zone
      timeZone: moment.tz.guess()
    };
    entity.hasCompleteSubscriptionDetail = false;
    if (subscribeAlert) {
      subscribeAlert.entityFilter = data.entityFilter || null;
      subscribeAlert.hasSubscribed = true;
      subscribeAlert.subscribedColumns =
        subscribeAlert.subscribedColumns.filter((column) => {
          if (column.isDefault === true) {
            column.hasSubscribed = true;
            return column;
          }
        });
      entity.entityInfo = [subscribeAlert];
      let apiCall = 'CREATESUBSCRIPTION';
      if (state.subScriptionDetails.subscriptionDetails[0].subscriptionId) {
        apiCall = 'UPDATESUBSCRIPTION';
        entity.subscriptionId =
          state.subScriptionDetails.subscriptionDetails[0].subscriptionId;
      }
      return HttpService.post(apiCall, { subscriptionDetails: [entity] }).then(
        (response) => {
          context.commit('HIDE_LOADING', response);
          return response;
        },
        (error) => {
          context.commit('SHOW_ERROR', error);
          context.commit('HIDE_LOADING');
          return error;
        }
      );
    } else {
      context.commit('HIDE_LOADING');
      context.commit('SHOW_ERROR');
      return new Error('Failure');
    }
  },
  clearSubscribe: (context) => {
    context.commit('HIDE_LOADING');
    context.commit('HIDE_ERROR');
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
