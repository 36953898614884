import AdGroup from '@/components/ams/campaign_creation/walmart_steps/adGroup/index.vue';
import KeywordTargetingWalmart from '@/components/ams/campaign_creation/walmart_steps/campaignTargeting/keywordTargeting.vue';
import CampaignType from '@/components/ams/campaign_creation/walmart_steps/campaignTypes/index.vue';
import {
  keywordTargetingState,
  negativeKeywordTargetingState
} from '@/components/ams/campaign_creation/setup_configs/walmart/common/keywordTargeting.js';
import { adGroupCustomActionPanel } from '@/components/ams/campaign_creation/setup_configs/walmart/common/adGroupConfig.js';
import {
  settings as settingsConfigFromWalmart,
  profile,
  placementAndBidMultiplier
} from '@/components/ams/campaign_creation/setup_configs/walmart/common/index.js';

export const adgroupConfig = {
  name: 'Ad Group',
  description: '',
  targetingType: ['product', 'keyword'],
  customActionPanelCustomState: adGroupCustomActionPanel,
  header: {
    title: 'Ad Group',
    desc: [
      'Ad groups are a way to organize, manage, and track performance of the products within your campaign. Give your ad group a name that is descriptive and meaningful to you, based on the products you want to advertise. For example, by category, like “Barbeque grills.”'
    ]
  },
  key: 'adgroup',
  keyToRead: 'skus',
  breadCrumbTitle: 'Ad Group',
  textMapping: 'skus',
  skusCountValidationObject: {
    min: 2,
    max: 10
  },
  component: AdGroup
};

const negativeStep = {
  name: 'Targeting',
  description: '',
  targetingType: ['keyword'],
  key: 'keywordTarget',
  customActionPanelCustomState: keywordTargetingState,
  customActionPanelNegativeKeywordTargetingCustomState:
    negativeKeywordTargetingState,
  keyToRead: 'targeting',
  breadCrumbTitle: 'What are the negative keywords you would like to target?',
  textMapping: 'targeting',
  noKeywordsErrorMessage: 'One keyword is required for SB campaigns',
  keywordBidValidationObject: {
    min: 1,
    max: 100
  },
  component: KeywordTargetingWalmart
};

export const keywordTargeting = {
  manual: {
    name: 'Targeting',
    description: '',
    targetingType: ['keyword'],
    header: {
      title: 'Keyword Targeting',
      desc: [
        'Targeting uses keywords and products to help your ads appear in search and detail pages.'
      ]
    },
    key: 'keywordTarget',
    customActionPanelCustomState: keywordTargetingState,
    customActionPanelNegativeKeywordTargetingCustomState:
      negativeKeywordTargetingState,
    keyToRead: 'targeting',
    breadCrumbTitle: 'What are the keywords you would like to target?',
    textMapping: 'targeting',
    noKeywordsErrorMessage: 'One keyword is required for SP campaigns',
    keywordBidValidationObject: {
      min: 0.3,
      max: 100
    },
    component: KeywordTargetingWalmart,
    negativeStep: {
      ...negativeStep,
      header: {
        title: 'Negative Keyword Targeting',
        desc: [
          "Negative keywords prevent your ads from displaying when a shopper's search terms match your negative keywords. You can exclude irrelevant searches, reducing your advertising cost."
        ]
      }
    }
  },
  auto: {
    name: 'Negative Targets (Optional)',
    description: '',
    targetingType: ['keyword'],
    header: {
      title: 'Negative Keyword Targeting',
      desc: [
        "Negative keywords prevent your ads from displaying when a shopper's search terms match your negative keywords. You can exclude irrelevant searches, reducing your advertising cost."
      ]
    },
    key: 'keywordTarget',
    customActionPanelCustomState: keywordTargetingState,
    customActionPanelNegativeKeywordTargetingCustomState:
      negativeKeywordTargetingState,
    keyToRead: 'targeting',
    breadCrumbTitle: 'What are the negative keywords you would like to target?',
    textMapping: 'targeting',
    noKeywordsErrorMessage: 'One keyword is required for SP campaigns',
    keywordBidValidationObject: {
      min: 0.3,
      max: 100
    },
    component: KeywordTargetingWalmart,
    negativeStep
  }
};

export const campaignType = {
  name: 'Select Campaign Type',
  description: '',
  header: {
    title: 'Select Campaign Type',
    desc: [
      'Create Sponsored Product Campaign by selecting manual or automatic targeting type.'
    ]
  },
  key: 'campaignType',
  keyToRead: 'campaignType',
  breadCrumbTitle: 'Select Campaign Type',
  textMapping: 'campaignType',
  campaignTypesArray: [
    {
      url: '',
      title: 'Sponsored Products',
      key: 'sponsoredProducts',
      desc: "Promote products to shoppers actively searching with related keywords or viewing similar products on Sam's Club.",
      image: '/images/samsclub_api-sp.png'
    }
  ],
  targetingTypeRadioOptions: [
    {
      type: 'auto',
      label: 'Automatic Campaign',
      desc: 'Automatic campaigns are the easiest way to bid on samsclub.com. Easy setup, high-traffic placements, no keyword management, and higher impression volume.'
    },
    {
      type: 'manual',
      label: 'Manual Campaign',
      desc: "With a manual campaign, advertisers handpick the keywords they want to bid on and match types Based on the SKUs selected users can select keywords recommended by Sam's Club."
    }
  ],
  component: CampaignType
};

export const settings = {
  ...settingsConfigFromWalmart,
  getAdvertisers: {
    ...settingsConfigFromWalmart.getAdvertisers,
    cubeName: 'samsclub_api_advertiser_details',
    where: {
      dimensionNameValueList: []
    }
  }
};

export { profile, placementAndBidMultiplier };
