<template>
  <div
    class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
  >
    <div
      class="filter--token u-spacing-mr-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
      :class="{ isHighlighted: isHighlighted }"
      style="min-height: 20px"
    >
      <ConfigText :text-array="textArray" />
      <div
        v-for="(chipIcon, index) in chipIcons"
        :key="index"
      >
        <div
          class="u-display-flex u-flex-align-items-center"
          @click="$emit(chipIcon.emit, chipIcon)"
        >
          <rb-icon
            :icon="chipIcon.icon"
            class="rb-icon rb-icon--x-small u-cursor-pointer icon-cross u-color-grey-lighter"
            :class="{
              'isHighlighted u-color-grey-white': isHighlighted
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfigText from '@/pages/campaign-management/components/config-text.vue';
export default {
  components: {
    ConfigText
  },
  props: {
    textArray: {
      type: Array,
      default: () => []
    },
    isHighlighted: {
      type: Boolean,
      default: false
    },
    chipIcons: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style></style>
