<template>
  <RbSelectV2
    :send-details="true"
    :options="options"
    :on-close="handleDropdownSelect"
    :is-waterfall-dropdown="true"
  >
    <div
      slot="trigger"
      class="u-font-size-3 u-display-flex u-flex-align-items-center u-cursor-pointer"
    >
      <div class="u-font-weight-600">
        {{ selectedOption.title }}
      </div>
      <rb-icon
        class="u-color-grey-lighter rb-icon--medium u-spacing-mh-s"
        icon="caret-down"
      />
    </div>
    <template
      slot="item"
      slot-scope="option"
    >
      <div
        :class="{
          'u-color-grey-lighter removePointerEvents': option.disable
        }"
        class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
      >
        <div class="u-spacing-pr-m">
          {{ option.popupLabel || option.title }}
        </div>
        <span
          v-if="option.children"
          class="rb-icon u-display-flex u-flex-align-items-center u-flex-justify-content-center rb-icon--small icon-arrow-up"
          style="transform: rotate(90deg)"
        />
      </div>
    </template>
  </RbSelectV2>
</template>

<script>
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
export default {
  components: {
    RbSelectV2
  },
  props: {
    selectedOption: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    handleDropdownSelect(context, selection) {
      this.$emit('optionSelect', selection || {});
    }
  }
};
</script>
