<template>
  <div class="chargebackTypeWidget">
    <widgetContainer
      v-if="showWidget"
      :header-options="headerOptionsComputed"
      :header-container-grid-styles="{
        'grid-template-columns': '1fr'
      }"
      :context="context"
      :title="(metadata || {}).label"
      :is-loading="isWidgetLoading"
      :enable-footer="false"
    >
      <template
        slot="body"
        slot-scope="{}"
      >
        <div
          class="cards-container u-spacing-pb-l u-spacing-pt-s u-spacing-pl-xxs"
        >
          <NoDataState
            v-if="Object.values(chargebackTypesData).length === 0"
            image-src="/images/ChargebacksNoData.svg"
            title="Nothing found under Chargeback Types"
            description="There is nothing under chargeback types yet for this selection."
            class="u-width-100"
          />
          <chargeback-type-card
            v-for="(item, i) in chargebackTypesData"
            :key="i"
            :is-chart-loading="isChartLoading"
            :card-title="item.chargebackType"
            :card-data="item.metricData"
            :subtypes-data="item.subTypesData"
            :chart-data="chargebackTypesChartData[item.chargebackType]"
          />
        </div>
      </template>
    </widgetContainer>
  </div>
</template>
<script>
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import ChargebackTypeCard from './chargeback-type-card.vue';
import widgetTitle from '../atoms/widgetTitle.vue';
import NoDataState from '../atoms/NoDataState.vue';
import { isEqual } from 'lodash';
import Vue from 'vue';
import moment from 'moment-timezone';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default {
  name: 'ChargebackTypes',
  components: {
    widgetContainer,
    ChargebackTypeCard,
    NoDataState
  },
  mixins: [cwMixin],
  inject: {},
  props: {
    dateModeSelected: {
      type: String,
      default: 'week'
    },
    showWidget: {
      type: Boolean,
      default: true
    },
    widgetTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      myKey: 1,
      isWidgetLoading: true,
      metricEventConfig: this.metadata,
      suffMap: {
        PERCENTAGE: '%',
        CURRENCY: 'currency'
      },
      chargebackTypesData: {},
      chargebackTypesChartData: {},
      isChartLoading: true
    };
  },
  computed: {
    headerOptionsComputed() {
      let headerOptions = [];
      headerOptions.push({
        ui_component: 'widgetTitle',
        props: {
          title: this.widgetTitle + ' Chargeback Types',
          count: Object.values(this.chargebackTypesData).length
        }
      });
      return headerOptions;
    }
  },
  watch: {
    widgetRequestParams(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.resetWidget();
      }
    },
    widgetTitle(newVal) {
      this.resetWidget();
    }
  },
  created() {
    Vue.component('widgetTitle', widgetTitle);
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    resetWidget() {
      this.$set(this, 'chargebackTypesData', {});
      this.$set(this, 'chargebackTypesChartData', {});
      this.fetchData();
    },
    fetchData() {
      this.isWidgetLoading = true;
      let api = Object.values(this.metadata.metrics)[0].api;
      let request = api.request;
      request = dashUtils.replacePlaceHolderWithData(
        request,
        this.widgetRequestParams
      );
      if (!this.widgetTitle) return;
      request.where.dimensionNameValueList.push({
        dimensionName: 'chargeback_group',
        dimensionValue: this.widgetTitle,
        operator: 'ILIKE'
      });

      dashUtils
        .fetchDataAPI(request, api)
        .then((args) => {
          this.transformData(args.entityData);
          this.fetchTimeseriesData();
        })
        .catch(() => {
          this.myKey++;
        });
    },
    fetchTimeseriesData() {
      this.isChartLoading = true;
      let api = Object.values(this.metadata.metrics)[0].api;
      let request = api.request;

      request = dashUtils.replacePlaceHolderWithData(
        request,
        this.widgetRequestParams
      );

      request.operations.timeseriesEnabled = true;

      request.where.dimensionNameValueList.push({
        dimensionName: 'chargeback_group',
        dimensionValue: this.widgetTitle,
        operator: 'ILIKE'
      });

      request.where.date.from = moment(request.where.date.to, 'YYYY-MM-DD')
        .subtract(4, this.dateModeSelected)
        .startOf(this.dateModeSelected)
        .format('YYYY-MM-DD');

      dashUtils
        .fetchDataAPI(request, api)
        .then((args) => {
          this.transformChartData(args.entityData);
        })
        .catch(() => {
          this.myKey++;
        });
    },
    transformChartData(entityData) {
      entityData.forEach((entity) => {
        const val = entity.data;
        const chargebackType = val.find((el) => el.name === 'chargeback_type')
          .RESULT.chargeback_type;
        this.$set(this.chargebackTypesChartData, chargebackType, [
          val.find((el) => el.name === 'total_defect_rate'),
          val.find((el) => el.name === 'target')
        ]);
      });
      this.isChartLoading = false;
    },
    transformData(entityData) {
      entityData.forEach((entity) => {
        const val = entity.data;
        const chargebackType = val.find((el) => el.name === 'chargeback_type')
          .RESULT.chargeback_type;
        this.$set(this.chargebackTypesData, chargebackType, {
          chargebackType,
          metricData: {
            total_defect_rate: {
              tag1: val.find((el) => el.name === 'total_defect_rate').RESULT
                .total_defect_rate,
              tag2: val.find((el) => el.name === 'total_defect_rate').PVP
                .PVP_DIFF_total_defect_rate
            },
            target: {
              tag1: val.find((el) => el.name === 'target').RESULT.target
            }
          },
          subTypesData: this.getSubTypesData(val)
        });
      });
      this.isWidgetLoading = false;
    },
    getSubTypesData(data) {
      const subTypesData = [];
      let subTypes = [
        'sub_type_1_contribution',
        'sub_type_2_contribution',
        'sub_type_3_contribution'
      ];
      subTypes.forEach((subType) => {
        const subTypeContribution = data.find((el) => el.name === subType)
          .RESULT[subType];
        if (subTypeContribution) {
          subTypesData.push(subTypeContribution);
        }
      });
      return subTypesData;
    }
  }
};
</script>
<style lang="css">
.chargebackTypeWidget {
  margin-top: 0 !important;
}
.chargebackTypeWidget .custom_widget_container {
  box-shadow: none;
}
.chargebackTypeWidget .custom_widget_container .header-container-cwc {
  padding-bottom: 1.6rem;
}
.chargebackTypeWidget .cards-container {
  display: flex;
  gap: 2rem;
  overflow-x: scroll;
}
</style>
