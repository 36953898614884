<template>
  <div
    v-tippy="getTippyOptions"
    class="u-position-relative"
  >
    <div v-if="downloadEmail">
      <icon-text-actions
        class="u-width-100"
        :class-name="className"
        :mode="disabled ? 'disabled' : 'default'"
        style="font-size: 40px"
        :action="{ title: 'Email', icon: 'email-subscription' }"
        @clicked="onEmailClick"
      />
    </div>
    <div v-else>
      <icon-text-actions
        v-if="isSuccess"
        class="u-width-100"
        :mode="disabled ? 'disabled' : 'default'"
        :action="{ title: downloadText, icon: 'download' }"
        :split-new-line-text="splitNewLineText"
        @clicked="download"
      />
      <icon-text-actions
        v-if="!isSuccess"
        class="u-width-100"
        :mode="disabled ? 'disabled' : 'default'"
        :action="{ title: 'Error', icon: 'caution' }"
        @clicked="download"
      />
      <loader
        :loading="downloadLoading"
        class="fill--parent"
        :color="'#007cf6'"
      />
    </div>
  </div>
</template>
<script>
import loader from '@/components/basic/loader';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
export default {
  name: 'RbDownloadButton',
  components: {
    loader,
    iconTextActions
  },
  props: {
    className: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    columnMap: {
      type: Array,
      default: () => []
    },
    onEmailClick: {
      type: Function,
      default: null
    },
    config: {
      type: Object,
      default: () => {}
    },
    downloadEmail: {
      default: false,
      type: Boolean
    },
    mode: {
      default: 'default',
      type: String
    },
    fileName: {
      default: 'download',
      type: String
    },
    downloadText: {
      default: 'Download',
      type: String
    },
    splitNewLineText: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      downloadLoading: false,
      isSuccess: true
    };
  },
  computed: {
    getTippyOptions() {
      return {
        html: '#' + 'Download' + this._uid || '-',
        reactive: true,
        distance: 4,
        placement: 'bottom-start',
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        duration: [0, 0]
      };
    }
  },
  methods: {
    performDownload(data, fileName) {
      if (data.length > 0) {
        var fileRows = [];
        var dispColumnArray = [];
        var csvKeys = [];
        var tableColumnsMap = this.columnMap;
        if (tableColumnsMap !== undefined && tableColumnsMap.length > 0) {
          for (var obj of tableColumnsMap) {
            if (obj.notDownloadable) {
              continue;
            }
            var displayName = obj.title;
            if (!this.outsideIn && obj.altDisplayName) {
              displayName = obj.altDisplayName;
            }

            if (obj.checkOutsideIn !== undefined) {
              if (this.outsideIn === obj.checkOutsideIn) {
                dispColumnArray.push(displayName);
                csvKeys.push(obj.field);
              }
            } else {
              dispColumnArray.push(displayName);
              csvKeys.push(obj.field);
            }
          }
          fileRows.push(dispColumnArray);
        } else {
          var csvColumns = Object.keys(data[0]);
          fileRows.push(csvColumns);
          csvKeys = Object.keys(data[0]);
        }
        for (var i = 0; i < data.length; i++) {
          var aArray = [];
          for (var k = 0; k < csvKeys.length; k++) {
            // for (var j in data[i]) {
            //   if (csvKeys[k] === j) {
            //     // aArray.push('"' + ('' + data[i][j]).replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"');
            //     aArray[k] = ('"' + ('' + data[i][j]).replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"');
            //   }
            // }
            if (Object.keys(data[i]).indexOf(csvKeys[k]) > -1) {
              if (
                data[i][csvKeys[k]] === undefined ||
                data[i][csvKeys[k]] === null ||
                data[i][csvKeys[k]] === ''
              ) {
                aArray[k] = '"' + ('' + 'NA') + '"';
              } else {
                aArray[k] =
                  '"' + ('' + data[i][csvKeys[k]]).replace(/"/g, '""') + '"';
              }
            } else {
              aArray[k] = '"' + ('' + 'NA') + '"';
            }
          }
          fileRows.push(aArray);
        }

        var file = fileRows.join('\n');
        var pom = document.createElement('a');
        var csvContent = file;
        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        // var blob = new Blob([csvContent], {type: 'application/vnd.ms-excel'});
        var url = URL.createObjectURL(blob);
        pom.href = url;
        pom.setAttribute('download', fileName + '.csv');
        // pom.setAttribute('download', fileName + '.xlsx');
        document.body.appendChild(pom);
        pom.click();
        document.body.removeChild(pom);
      }
    },
    download() {
      this.downloadLoading = true;
      // var _config = Vue.util.extend({}, this.config.body.APIConfig);
      var _config = JSON.parse(JSON.stringify(this.config.body.APIConfig));
      _config.page = undefined;
      _config.limit = undefined;
      var configToSend = {};
      if (
        this.config.header.actions !== undefined &&
        this.config.header.actions.download.cube !== undefined
      ) {
        configToSend = {
          apiConfig: _config,
          cube: this.config.header.actions.download.cube
        };
      } else {
        configToSend = _config;
      }

      var action =
        (this.config.body.download || {}).action !== undefined
          ? this.config.body.download.action
          : 'commonTableDownloadPromiseReturnAction';
      this.$store.dispatch(action, configToSend).then(
        (response) => {
          this.downloadLoading = false;
          if (response.length > 0) {
            this.performDownload(response, this.fileName);
          } else {
            this.isSuccess = false;
          }
        },
        (error) => {
          console.log(error);
          this.isSuccess = false;
          this.downloadLoading = false;
        }
      );
    }
  }
};
</script>
