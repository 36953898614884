import HttpService from '@/utils/services/http-service';
import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';
import { generateWeeklyRange } from '@/utils/helpers/date';
import { modifyPayloadFor3p } from '@/components/pages/insights/amazon/market-share/utils';
export default class DataService {
  widget;
  page;
  pageId;
  globalViewId;
  isLoading;

  metadata = {
    load: false,
    error: false,
    rows: [],
    noData: false
  };

  data = {
    load: true,
    error: false,
    rows: [],
    noData: false
  };

  savedSortState = null;
  dateApiMaxDate = null;

  constructor(page, widget, pageId, globalViewId) {
    this.widget = widget;
    this.page = page;
    this.pageId = pageId;
    this.globalViewId = globalViewId;
  }

  async getMetadata() {
    try {
      this.isLoading = true;
      this.metadata = {
        ...this.metadata,
        load: true,
        error: false
      };
      const body = {};
      body.widget = this.widget;
      body.page = this.page;
      body.globalViewId = this.globalViewId;
      body.pageId = this.pageId;
      const { data } = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/widget/metadata/read'
      });
      this.metadata = {
        ...this.metadata,
        load: false,
        error: false,
        rows: data
      };
      this.isLoading = false;
      return data;
    } catch (e) {
      console.error('e', e);
      this.metadata = {
        ...this.metadata,
        load: false,
        error: true
      };
      this.isLoading = false;
    }
  }

  generateDateRange(column) {
    const maxDate = moment(
      this.dateApiMaxDate || this.metadata.rows.calendar.max_date
    ).format('YYYY-MM-DD');
    const ranges = [1, 4, 13, 26, 52];
    const dateRange = [];
    for (const range of ranges) {
      const weekDef = generateWeeklyRange(range, maxDate);
      dateRange.push({
        date: {
          from: moment(weekDef.startDate).format('YYYY-MM-DD'),
          to: moment(weekDef.endDate).format('YYYY-MM-DD')
        },
        pvpDate: {
          from: moment(weekDef.startDate)
            .subtract(range, 'weeks')
            .startOf('week')
            .format('YYYY-MM-DD'),
          to: moment(weekDef.startDate)
            .subtract(1, 'weeks')
            .endOf('week')
            .format('YYYY-MM-DD')
        },
        key: weekDef.key
      });
    }
    let selectedDateRange = null;
    if (column) {
      selectedDateRange = dateRange.filter((item) => {
        return item.key === column;
      });
      return selectedDateRange[0];
    } else {
      return dateRange;
    }
  }

  getBrandData(element) {
    const tempArray = element.data.map((item) => {
      const obj = {};
      obj[item.name] = item.RESULT[item.name];
      if (item.PVP) {
        obj['PVP_' + item.name] = item.PVP['PVP_DIFF_' + item.name];
      }
      return obj;
    });
    return tempArray;
  }

  metricDetails(metrics, keyName, metricName) {
    let metricDetails = '';
    Object.keys(metrics).forEach((item) => {
      if (metrics[item].name === metricName) {
        metricDetails = metrics[item];
        keyName = metricDetails.keyName;
      }
    });
    return keyName;
  }

  rowData(params, innerElementItem, item) {
    const obj = {};
    const metricUnit =
      params.metrics[params.keyName]?.metadata?.unit || 'NUMERIC';
    const PVP_DIFF_TYPE = metricUnit === 'PERCENTAGE' ? 'PVP_DIFF_' : 'PVP_';
    if (innerElementItem.name !== params.keyName) {
      obj[innerElementItem.name] =
        innerElementItem.RESULT[innerElementItem.name];
    } else if (innerElementItem.name === params.keyName) {
      obj[item + '_' + 'data1'] =
        innerElementItem.RESULT[innerElementItem.name];
      obj[item + '_' + 'data1_type'] = metricUnit;
      if (innerElementItem.PVP) {
        obj[item + '_' + 'data2'] =
          innerElementItem.PVP[PVP_DIFF_TYPE + innerElementItem.name];
        obj[item + '_data2_type'] = 'PERCENTAGE';
      }
    }
    return obj;
  }

  dateRangeResponse(allDateRangeResponse, responseData, params) {
    allDateRangeResponse.forEach((element, index) => {
      const item = params.dateRanges[index];
      if (element !== null) {
        element.forEach((innerElement) => {
          const tempArray = innerElement.data.map((innerElementItem) => {
            return this.rowData(params, innerElementItem, item);
          });
          const tempObj = Object.assign({}, ...tempArray);
          innerElement = { ...innerElement, ...tempObj };
          if (responseData[innerElement[params.entityValuekey]]) {
            responseData[innerElement[params.entityValuekey]][item] =
              cloneDeep(innerElement);
          }
        });
      }
    });
    return responseData;
  }

  constructOperations(requestCopy, shareSummaryWidget, dimensionData) {
    if (requestCopy.operations) {
      if (!shareSummaryWidget(this.widget)) {
        delete requestCopy.operations.page;
        if (
          !requestCopy.operations.outerWhereClause?.dimensionNameValueList
            ?.length
        ) {
          requestCopy.operations.outerWhereClause = {};
          requestCopy.operations.outerWhereClause.dimensionNameValueList = [
            {
              dimensionName: dimensionData.data.entityData[0]?.entityType,
              dimensionValue: dimensionData.joinedDimensionValue,
              operator: 'IN'
            }
          ];
        } else {
          requestCopy.operations.outerWhereClause.dimensionNameValueList.push({
            dimensionName: dimensionData.data.entityData[0]?.entityType,
            dimensionValue: dimensionData.joinedDimensionValue,
            operator: 'IN'
          });
        }
      }
    }
    return requestCopy;
  }

  tableData(responseData, dateRanges) {
    const dateRangesList = Object.keys(dateRanges || {}).map(
      (item) => dateRanges[item]
    );
    const finalData = Object.keys(responseData).map((key) => {
      const obj = responseData[key];
      obj.showIcon = obj.client_flag === 'client';
      dateRangesList.forEach((dateRange) => {
        const dateRangeResponse = responseData[key][dateRange] || {};
        Object.keys(dateRangeResponse).forEach((key) => {
          if (key.indexOf(dateRange) !== -1) {
            obj[key] = dateRangeResponse[key];
          }
        });
      });
      return obj;
    });
    return finalData;
  }

  getMetrics(metaData) {
    const rowData = this.metadata?.rows || {};
    return Object.keys(rowData).length === 0 ? metaData.rows : rowData;
  }

  async getData(
    request,
    apiDetails,
    globalSettings,
    shareSummaryWidget,
    metaData
  ) {
    let requestCopy = cloneDeep(request);
    const apiDetailsCopy = cloneDeep(apiDetails);
    try {
      this.isLoading = true;
      this.data = {
        ...this.data,
        load: true,
        error: false
      };
      requestCopy.widget = this.widget;
      requestCopy.page = this.page;
      requestCopy.globalViewId = this.globalViewId;
      requestCopy.pageId = this.pageId;
      modifyPayloadFor3p(requestCopy);
      const { data } = await HttpService.post(
        apiDetailsCopy?.service || 'DASHBOARD_SERVICE',
        requestCopy,
        { append: apiDetailsCopy?.endPoint || '/entity/metrics/data' }
      );
      // Fire API
      let responseData = {};
      const dimensionValue = [];
      let joinedDimensionValue = '';
      const entityValuekey = shareSummaryWidget(this.widget)
        ? 'category'
        : 'entityValue';
      data.entityData.forEach((element, index) => {
        element.rank =
          index +
          1 +
          (requestCopy?.operations?.page - 1) * requestCopy?.operations?.limit;
        const tempArray = this.getBrandData(element);
        const tempObj = Object.assign({}, ...tempArray);
        element = { ...element, ...tempObj };
        responseData[element[entityValuekey]] = element;
        let entityValue = element[entityValuekey] || '';
        entityValue =
          entityValue.replace(/[-[\]{}()*+?.',\\^$|#]/g, '\\$&') || entityValue;
        dimensionValue.push(`'${entityValue}'`);
      });
      if (dimensionValue.length > 0) {
        joinedDimensionValue = dimensionValue.join(',');
      }
      const dimensionData = {
        data,
        joinedDimensionValue
      };
      requestCopy = this.constructOperations(
        requestCopy,
        shareSummaryWidget,
        dimensionData
      );

      const allDateRange = this.generateDateRange();

      // delete keys -- page and limit from requestCopy, so that except the row on which UI sorting is applied for others
      // all category data will be fetched from API and avoid NA scenarios
      delete requestCopy.operations.page;
      delete requestCopy.operations.limit;

      // Generate the API with different range

      const allDateRangeRequest = [];
      requestCopy.where.date = globalSettings.where.date;
      requestCopy.where.pvpDate = globalSettings.where.pvpDate;
      allDateRangeRequest.push(
        HttpService.post(
          apiDetailsCopy?.service || 'DASHBOARD_SERVICE',
          requestCopy,
          {
            append: apiDetailsCopy?.endPoint || '/entity/metrics/data'
          }
        )
      );
      allDateRange.forEach((element) => {
        const request = cloneDeep(requestCopy);
        request.where.date = element.date;
        request.where.pvpDate = element.pvpDate;
        allDateRangeRequest.push(
          HttpService.post(
            apiDetailsCopy?.service || 'DASHBOARD_SERVICE',
            request,
            { append: apiDetailsCopy?.endPoint || '/entity/metrics/data' }
          )
        );
      });
      let allDateRangeResponse = await Promise.all(allDateRangeRequest);
      const dateRanges = {
        0: 'selectedDateRange',
        1: 'lastWeek',
        2: 'last4Week',
        3: 'last13Week',
        4: 'last26Week',
        5: 'last52Week'
      };

      allDateRangeResponse = allDateRangeResponse
        .filter((item) => {
          return item.status === 200;
        })
        .map((item) => {
          return item.data.entityData;
        });
      const metricName = requestCopy.metricsList[0];
      let keyName = '';
      const { metrics } = this.getMetrics(metaData);
      if (metrics) {
        keyName = this.metricDetails(metrics, keyName, metricName);
      }
      const params = {
        dateRanges,
        metrics,
        keyName,
        entityValuekey
      };
      responseData = this.dateRangeResponse(
        allDateRangeResponse,
        responseData,
        params
      );
      const finalData = this.tableData(responseData, dateRanges);
      if (finalData.length === 0) {
        this.data = {
          rows: [],
          error: false,
          load: false,
          noData: true
        };
      } else {
        this.data = {
          rows: finalData,
          error: false,
          load: false,
          noData: false
        };
      }
      this.isLoading = false;
      return this.data;
    } catch (e) {
      this.isLoading = false;
      console.log(e, 'error');
      this.data = {
        ...this.data,
        load: false,
        error: true
      };
    }
  }
}
