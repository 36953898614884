import { multiRetailerConfig } from '@/pages/budget-planner-v2/dashboard/retailerConfig.js';
import { groupBy } from 'lodash';
import transformer from '@/utils/services/data-transformer';
import HttpLayer from '@/utils/services/http-layer';

const getLevelIdFromData = (entity, level) => {
  return entity.level_id.split(':')[level];
};

const filterBySearch = (search, filteredProfilesAndPortfolios, allProfiles) => {
  let finalFilteredEntities = [];
  if (search.length > 0) {
    for (const item of search) {
      const tempSearchArr = filteredProfilesAndPortfolios.filter((row) =>
        JSON.stringify(row)
          .toLowerCase()
          .includes(item?.dimensionValue?.toLowerCase())
      );
      finalFilteredEntities.push(...tempSearchArr);
    }
    const tempProfiles = [];
    // profile logic
    for (const profile of allProfiles) {
      for (const entity of finalFilteredEntities) {
        if (getLevelIdFromData(entity, 0) === profile.level_id) {
          tempProfiles.push(profile);
        }
      }
    }
    finalFilteredEntities = [...finalFilteredEntities, ...tempProfiles];
  } else {
    finalFilteredEntities = filteredProfilesAndPortfolios;
  }
  return finalFilteredEntities;
};
const getFilteredProfiles = (allProfiles, filterByProfile) => {
  const filteredProfiles = [];
  if (filterByProfile.length > 0) {
    for (const item of filterByProfile) {
      const tempProfileArr = allProfiles.filter((row) =>
        row?.level1?.toLowerCase().includes(item?.dimensionValue?.toLowerCase())
      );
      filteredProfiles.push(...tempProfileArr);
    }
  }
  return filteredProfiles;
};

const getFilteredPortfolio = (
  filterByPortfolio,
  allPortfolios,
  filterByProfile,
  filteredProfiles,
  allProfiles
) => {
  let filteredProtfolios = [];
  if (!filterByPortfolio.length) {
    return filteredProtfolios;
  }

  for (const item of filterByPortfolio) {
    const tempPortfolioArr = allPortfolios.filter(
      (row) => row?.level2 === item.dimensionValue
    );
    filteredProtfolios.push(...tempPortfolioArr);
  }
  // if profiles exist
  if (filterByProfile.length > 0) {
    const tempPortfolioArray = [];
    for (const profile of filteredProfiles) {
      const tempProfileArray = filteredProtfolios.filter((row) => {
        const currentPortFoliosProfileId = row.level_id.split(':')[0];
        return currentPortFoliosProfileId === profile.level_id;
      });
      tempPortfolioArray.push(...tempProfileArray);
    }
    filteredProtfolios = tempPortfolioArray;
  } else {
    // if profiles dont exist
    for (const profile of allProfiles) {
      for (const portfolio of filteredProtfolios) {
        if (getLevelIdFromData(portfolio, 0) === profile.level_id) {
          filteredProfiles.push(profile);
        }
      }
    }
  }
  return filteredProtfolios;
};

const groupTableApiResponse = (response) => {
  return response.reduce(
    (accumulator, currentValue) => {
      if (currentValue.level_id?.includes(':')) {
        accumulator.level2.push(currentValue);
      } else if (currentValue.level_id) {
        accumulator.level1.push(currentValue);
      }
      return accumulator;
    },
    {
      level1: [],
      level2: []
    }
  );
};
export function filterBudgetPlannerCollectiveSummary(response, where) {
  const groupedDimensionList = groupBy(
    where.dimensionNameValueList,
    'dimensionName'
  );
  const groupedResponse = groupTableApiResponse(response);
  const allProfiles = groupedResponse.level1 || [];
  const allPortfolios = groupedResponse.level2 || [];
  const search =
    groupedDimensionList.search?.filter((row) => row.dimensionValue) || [];
  const filterByProfile =
    groupedDimensionList.level1?.filter((row) => row.dimensionValue) || [];
  const filterByPortfolio =
    groupedDimensionList.level2?.filter((row) => row.dimensionValue) || [];
  // if profile filter is applied push profiles.
  const filteredProfiles = getFilteredProfiles(allProfiles, filterByProfile);

  // if portfolio filter is applied push profolios and its parent(profiles).
  let filteredProtfolios = getFilteredPortfolio(
    filterByPortfolio,
    allPortfolios,
    filterByProfile,
    filteredProfiles,
    allProfiles
  );

  let filteredProfilesAndPortfolios = [];
  // adding filtered profiles and portfolions
  if (filterByPortfolio.length > 0 || filterByProfile.length > 0) {
    filteredProfilesAndPortfolios = [
      ...filteredProtfolios,
      ...filteredProfiles
    ];
  } else {
    filteredProfilesAndPortfolios = [...allProfiles, ...allPortfolios];
  }
  // if search filter is present filter by everything.

  let finalFilteredEntities = filterBySearch(
    search,
    filteredProfilesAndPortfolios,
    allProfiles
  );
  // deleting duplicate rows
  finalFilteredEntities = finalFilteredEntities.filter(
    (entity, index, self) =>
      index === self.findIndex((t) => t._key === entity._key)
  );
  return finalFilteredEntities;
}

const handleDateFilter = (filterValue, where, state) => {
  let date = filterValue;
  let _date = {
    from: date.from,
    to: date.to,
    name: date.name,
    page_wise_min_max_key: state.page_wise_min_max_key
  };
  let _pvpDate;
  if (date.compare_from && date.compare_to) {
    _pvpDate = {
      from: date.compare_from,
      to: date.compare_to,
      compare_name: date.compare_name
    };
  }
  where.date = _date;
  where.pvpDate = _pvpDate;
};

const handleNonDateFilter = (key, filterValue, where) => {
  if (filterValue.length > 0 && filterValue[0].operator) {
    where.dimensionNameValueList.push({
      dimensionName: key,
      dimensionValue: filterValue[0].value,
      operator: filterValue[0].operator.operator
    });
  } else {
    for (const value of filterValue) {
      where.dimensionNameValueList.push({
        dimensionName: key,
        dimensionValue: value
      });
    }
  }
};

export function getFiltersInFormatWKBudgetPlanner(oFilter, state) {
  const where = state.whereClause;
  where.dimensionNameValueList = [];
  for (const i in oFilter.values) {
    const filterValue = oFilter.values[i];
    if (i !== 'date_range') {
      handleNonDateFilter(i, filterValue, where);
    } else {
      handleDateFilter(filterValue, where, state);
    }
  }
  state.whereClause = where;
}

const getLevel1Id = (levelId) => {
  return levelId?.includes(':') ? levelId.split(':')[0] : levelId;
};
const updateRowLevelCount = (rowLevelData, level1Id) => {
  if (!rowLevelData[level1Id] && level1Id) {
    rowLevelData[level1Id] = { count: 0 };
  } else if (rowLevelData[level1Id]) {
    rowLevelData[level1Id].count++;
  }
};
const findIndexForLevelId = (tableData, levelId) => {
  return tableData.findIndex((item) => {
    const level2Id =
      item.level_id && !item.level_id?.includes(':')
        ? item.level_id?.split(':')[0]
        : null;
    return levelId === level2Id;
  });
};

export const addChildRowCount = (tableData, retailer) => {
  const rowLevelData = {};
  tableData.forEach((row) => {
    const level1Id = getLevel1Id(row.level_id);
    row['ag-grid-has-expand'] = false;
    updateRowLevelCount(rowLevelData, level1Id);
    if (!row.level_id && row.level_type === 'ENTIRE_BUSINESS') {
      row['ag-grid-has-expand'] = true;
    }
  });
  for (const levelId in rowLevelData) {
    const index = findIndexForLevelId(tableData, levelId);
    if (index > -1) {
      tableData[index].childCount = rowLevelData[levelId].count;
      if (
        multiRetailerConfig.disableCollapseForUncategorized.includes(retailer)
      ) {
        tableData[index]['ag-grid-has-expand'] =
          tableData[index].entity_name !== 'Uncategorized' &&
          rowLevelData[levelId].count > 0;
      } else {
        tableData[index]['ag-grid-has-expand'] =
          rowLevelData[levelId].count > 0;
      }
    }
  }
  return tableData;
};

export const getEntireBussinessDataPayload = (data, whereClause) => {
  data.body.APIConfig.where = whereClause;
  data.body.APIConfig.where.dimensionNameValueList = [];
  if (data.body.APIConfig.page) {
    delete data.body.APIConfig.page;
  }
  return data.body.APIConfig;
};

export const getEntireBusinessData = async (
  retailer,
  data,
  dataObj,
  whereClause
) => {
  try {
    const where = transformer.getCompleteWhereClause(
      data.meta.localFilters || [],
      whereClause
    );
    let response = await HttpLayer.post({
      APIData: getEntireBussinessDataPayload(data, whereClause)
    });

    if (!response.success) {
      dataObj.error = true;
    } else {
      const responseData = response?.data;
      if (responseData.length > 0) {
        let innerData = transformer.mergeResultDimension(responseData);
        const entireBusiness = innerData.filter(
          (row) => row.level_type === 'ENTIRE_BUSINESS'
        );
        innerData = innerData.map((row, index) => {
          row._key = `key_${index}`;
          return row;
        });
        if (
          where.dimensionNameValueList &&
          where.dimensionNameValueList.length > 0
        ) {
          innerData = filterBudgetPlannerCollectiveSummary(innerData, where);
        }
        innerData = addChildRowCount(innerData, retailer);
        return { innerData, entireBusiness };
      } else {
        dataObj.noData = true;
        return {
          innerData: [],
          entireBusiness: []
        };
      }
    }
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const filterTableLevel1Data = (data, dashboardTable) => {
  data.rows = dashboardTable.filter(
    (row) => !row.level_id?.includes(':') && row.level_id
  );
  // sorting in asc order of name
  data.rows.sort((a, b) => (a.level1 > b.level1 ? 1 : -1));
  return data;
};

export const filterTableLevel2Data = (retailer, data, dashboardTable) => {
  data.rows = dashboardTable.filter((row) => {
    const levelId = row.level_id?.includes(':')
      ? row.level_id.split(':')[0]
      : null;
    return levelId === data.row.level_id && levelId;
  });
  if (multiRetailerConfig.hideRestForSinglePortfolio.includes(retailer)) {
    data.rows = data.rows.filter(
      (row) => !(data.rows.length === 1 && row.dimension_name === 'Rest')
    );
  }
  // sorting in asc order of name
  data.rows.sort((a, b) =>
    a.level2.toLowerCase() > b.level2.toLowerCase() ? 1 : -1
  );
  return data;
};

const formatDataKeys = (objKeys, data) => {
  for (const key of objKeys) {
    for (const row of data) {
      if (typeof row[key] === 'number') {
        row[key] = row[key].toFixed(2);
      }
    }
  }
};
export const getSortedEntities = (data, entireBusiness) => {
  const objKeys = Object.keys(data[0] || {});
  formatDataKeys(objKeys, data);
  const groupedData = groupBy(data, 'entity_type') || {};
  const sortedEnities = [];
  const profiles = groupedData.PROFILE || [];
  const portfolios = groupedData.PORTFOLIO || [];
  // pushing by order of (eb) -> (profile) -> (portfolio for each profile.)
  sortedEnities.push(entireBusiness[0]);
  for (const profile of profiles) {
    sortedEnities.push(profile);
    for (const portfolio of portfolios) {
      if (portfolio.related_entity_id === profile.entity_id) {
        sortedEnities.push(portfolio);
      }
    }
  }
  return sortedEnities;
};
