import { cloneDeep } from 'lodash';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';

export function formProductRequest(template, skus) {
  const productRequestArr = [];
  for (const item of skus) {
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':asin': item.asin,
      ':imageUrl': item.image_url,
      ':productTitle': item.product_title,
      ':productUrl': item.product_url
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    productRequestArr.push(requestObject);
  }
  return productRequestArr;
}

export function formKeywordRequest(template, keywords) {
  const keywordsRequest = [];
  for (const item of keywords) {
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':keyword': item.keyword,
      ':bid': item.exact_bid,
      ':matchType':
        item.match_type === 'Exact'
          ? 'exact'
          : item.match_type === 'Broad'
          ? 'broad'
          : item.match_type === 'Phrase'
          ? 'phrase'
          : 'exact'
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    keywordsRequest.push(requestObject);
  }
  return keywordsRequest;
}

export function formProductTargetsRequest(template, targets) {
  const targetsRequest = [];
  for (const item of targets) {
    const requestObject = cloneDeep(template);
    let expressionType = null;
    let value = null;
    let entityName = null;
    if (item?.competitor_asin) {
      expressionType = 'asinSameAs';
      value = item?.competitor_asin;
      entityName = item?.competitor_asin_title;
    }
    if (item.categoryId) {
      value = item.categoryId;
      expressionType = 'asinCategorySameAs';
      entityName = item?.category;
    }
    const keyValuePairs = {
      ':value': value,
      ':expressionType': expressionType,
      ':bid': parseFloat(item.new_bid),
      ':newBid': parseFloat(item.new_bid),
      ':entityName': entityName
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    targetsRequest.push(requestObject);
  }
  return targetsRequest;
}

export function formNegativeKeywordsRequest(template, keywords) {
  const negativeKeywordsRequest = [];
  const matchTypeMap = {
    PHRASE: 'negativePhrase',
    EXACT: 'negativeExact',
    'Negative Phrase': 'negativePhrase',
    'Negative Exact': 'negativeExact'
  };
  for (const item of keywords) {
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':keyword': item.negative_keyword,
      ':matchType': matchTypeMap[item.match_type]
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    negativeKeywordsRequest.push(requestObject);
  }
  return negativeKeywordsRequest;
}

export function formNegativeTargetRequest(template, targets) {
  const negativeTargetsRequest = [];
  const matchTypeMap = {
    targetingExpression: 'TARGETING_EXPRESSION'
  };
  for (const item of targets) {
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':target': item.keyword,
      ':matchType': matchTypeMap[item.match_type]
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    negativeTargetsRequest.push(requestObject);
  }
  return negativeTargetsRequest;
}

export const keyValuePairForSB = (campaignType, item) => {
  const pairs = {
    ':campaignType': campaignType,
    ':adgroupType': item?.targetingType,
    ':profileId': item?.profile?.profile_id?.toString() || null,
    ':portfolioId': item?.portfolio?.portfolio_id?.toString() || null,
    ':campaignName': item?.suggestedCampaignName?.trim(),
    ':budget': item?.budget,
    ':bidMultiplier':
      item?.bidMultiplier && item.bidMultiplier?.type === 'Decrease'
        ? '-' + item.bidMultiplier?.value
        : item.bidMultiplier?.value,
    ':budgetType': item?.budgetType?.key || 'daily',
    ':videoMediaIds': null,
    ':type': null,
    ':startDate': item?.startDate,
    ':endDate': item?.endDate || null,
    ':brandEntityId': item?.storeOnAmazonStore?.brandEntityId || null,
    ':bidOptimization': item?.bidOptimization,
    ':brandName': item?.brandInfo?.brandName?.trim(),
    ':adFormat': item?.adFormat,
    ':assetId': item?.selectedAsset?.assetId,
    ':headline': item?.brandInfo?.headline?.trim(),
    ':creativeAsins': item?.creativeSkus?.map((skuItem) => skuItem?.asin),
    ':skus': null,
    ':subPages': null,
    ':customURL': null
  };

  if (item?.bidOptimization) {
    pairs[':bidMultiplier'] = null;
  }

  if (item?.adFormat === 'productCollection') {
    if (item?.adFormatSubType === 'new_landing_page') {
      pairs[':skus'] = item?.skus?.map((skuItem) => skuItem?.asin);
    } else if (item.adFormatSubType === 'custom_url') {
      pairs[':customURL'] = item?.customURL;
    } else if (item.adFormatSubType === 'store_on_amazon') {
      pairs[':customURL'] = item?.storeOnAmazonPage?.storePageUrl;
    }
  }
  if (item.adFormat === 'video') {
    pairs[':type'] = 'video';
    pairs[':videoMediaIds'] = [item.selectedAsset.assetId];
    pairs[':assetId'] = null;
    pairs[':brandName'] = null;
    pairs[':headline'] = null;
  }
  if (item.adFormat === 'storeSpotlight') {
    pairs[':creativeAsins'] = null;
    pairs[':customURL'] = item?.homePage?.storePageUrl;
    const subPages = [];
    for (let page of item?.selectedPages) {
      subPages.push({
        pageTitle: page?.storePageName,
        asin: page?.selectedSku?.asin,
        url: page?.storePageUrl
      });
    }
    pairs[':subPages'] = subPages;
  }

  return pairs;
};

export const formProductTargetRequestSB = (template, targets) => {
  const targetsRequest = [];
  for (const target of targets) {
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':value': target?.categoryId || target.competitor_asin,
      ':type': target?.categoryId ? 'asinCategorySameAs' : 'asinSameAs',
      ':bid': target?.new_bid
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    targetsRequest.push(requestObject);
  }
  return targetsRequest;
};

export const formKeywordRequestSB = (template, keywords) => {
  const keywordsRequest = [];
  for (const item of keywords) {
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':keyword': item.keyword,
      ':bid': item.exact_bid,
      ':matchType':
        item.match_type === 'Exact'
          ? 'exact'
          : item.match_type === 'Broad'
          ? 'broad'
          : item.match_type === 'Phrase'
          ? 'phrase'
          : 'exact'
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    keywordsRequest.push(requestObject);
  }
  return keywordsRequest;
};

export const keyValuePairForSP = (campaignType, item) => {
  return {
    ':campaignType': campaignType,
    ':adgroupType': item?.targetingType, // keyword / product
    ':targetingType': item?.spTargetingType === 'auto' ? 'auto' : 'manual',
    ':profileId': item?.profile?.profile_id?.toString() || null,
    ':portfolioId': item?.portfolio?.portfolio_id?.toString() || null,
    ':campaignName': item?.suggestedCampaignName?.trim(),
    ':dailyBudget': item.budget,
    ':startDate': item.startDate,
    ':endDate': item?.endDate || null,
    ':biddingStrategy': item.biddingStrategy,
    ':adgroupName': item.adgroupName?.trim() || 'Ad Group 1',
    ':adgroupBid': item.adgroupBid
  };
};

export function formPlacementAdjustmentRequest(
  requestTemplate,
  productPages,
  topOfSearch
) {
  const adjustments = [];
  if (productPages) {
    adjustments.push({
      predicate: 'placementProductPage',
      percentage: productPages
    });
  }
  if (topOfSearch) {
    adjustments.push({
      predicate: 'placementTop',
      percentage: topOfSearch
    });
  }
  return adjustments;
}

export function formTargetsRequestSpAuto(template, campaign) {
  const targetsRequest = [];
  let targets = campaign.targets;
  if (campaign.autoTargetingMetbod !== 'default_bid') {
    targets = targets.filter((item) => {
      return item.checked;
    });
  }

  for (const item of targets) {
    const requestObject = cloneDeep(template);
    const bid =
      campaign.autoTargetingMetbod === 'default_bid'
        ? campaign.auto_default_Bid
        : item.value;
    const keyValuePairs = {
      ':bid': parseFloat(bid),
      ':entityName': item.name,
      ':expressionType': item.expression
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    targetsRequest.push(requestObject);
  }
  return targetsRequest;
}
