<template>
  <div
    class="bulk-search-overview-footer u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-color-grey-base"
  >
    <div class="u-display-flex u-flex-align-items-center u-font-size-7">
      <div class="u-spacing-mr-xs">
        <span class="u-font-weight-600">
          {{ searchOverviewEntityListLength }}
        </span>
        / {{ maxPasteLimit }} inputs
      </div>
      <div
        v-tippy="tippyConfig"
        :title="`You may search up to ${maxPasteLimit} inputs. If you exceed the limit, top ${maxPasteLimit} will be searched.`"
        class="u-display-flex u-flex-align-items-center"
      >
        <rb-icon
          class="rb-icon--small u-color-grey-lighter u-cursor-pointer"
          :icon="'info-circle-fill'"
        />
      </div>
    </div>
    <div
      v-if="invalidEntityCount"
      class="u-display-flex u-flex-align-items-center"
    >
      <div
        class="u-font-size-7 u-spacing-ml-xs u-display-flex u-flex-align-items-center"
      >
        <div
          v-tippy="tippyConfig"
          title="There may be difference in search results due to invalid / duplicate inputs"
          class="u-display-flex u-flex-align-items-center"
        >
          <div class="u-spacing-mr-xs">
            <rb-icon
              :icon="'warning'"
              class="u-color-orange-base rb-icon--small u-cursor-pointer"
            />
          </div>
          <span class="u-font-weight-600 u-spacing-mr-xs"
            >{{ invalidEntityCount }}
          </span>
          duplicate {{ invalidEntityCount > 1 ? 'inputs' : 'input' }}
        </div>
        <span
          class="u-color-blue-base u-cursor-pointer u-spacing-ml-s"
          @click="viewInvalidEntities"
          >View
        </span>
        <span
          class="delete-invalid-text u-spacing-pl-s"
          :disabled="isTextAreaEnabled"
          @click="deleteInvalidEntities"
          >Delete
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchOverviewEntityList: {
      type: Array,
      default: () => []
    },
    invalidEntityCount: {
      type: Number,
      default: null
    },
    maxPasteLimit: {
      type: Number,
      default: 1500
    },
    isTextAreaEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tippyConfig: {
        placement: 'top',
        arrow: false,
        interactive: true,
        distance: 5,
        size: 'regular',
        maxWidth: '300px'
      }
    };
  },
  computed: {
    searchOverviewEntityListLength() {
      return this.searchOverviewEntityList.length > this.maxPasteLimit
        ? this.maxPasteLimit
        : this.searchOverviewEntityList.length;
    }
  },
  methods: {
    viewInvalidEntities() {
      this.$emit('handleViewInvalidEntitiesClick');
    },
    deleteInvalidEntities() {
      if (!this.isTextAreaEnabled)
        this.$emit('handleDeleteInvalidEntitiesClick');
    }
  }
};
</script>

<style lang="css">
.bulk-search-overview-footer .delete-invalid-text {
  color: #007cf6;
  cursor: pointer;
}
.bulk-search-overview-footer .delete-invalid-text[disabled] {
  cursor: not-allowed;
  color: #2690f8;
  opacity: 0.3;
}
</style>
