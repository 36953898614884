<template>
  <div class="custom_widget_container card">
    <Loader
      :loading="isLoading"
      class="fill--parent u-bg-color-grey-white"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
    />
    <div class="header-container-cwc">
      <dynamic-component-composer
        :child-components="headerOptions.sort((a, b) => a.order - b.order)"
        :container-styles="headerContainerGridStyles"
      />
    </div>
    <div class="custom_widget_body">
      <slot name="body" />
    </div>
    <div
      v-if="footerOptions.length && enableFooter"
      class="footer-container-cwc u-width-100 u-font-size-6 u-spacing-pv-s u-width-100 u-spacing-pl-s u-display-flex u-flex-align-items-center u-color-grey-base u-border-width-s u-border-top u-border-color-grey-xxx-light"
    >
      <dynamic-component-composer
        :child-components="footerOptions"
        :container-styles="footerContainerGridStyles"
      />
    </div>
  </div>
</template>
<script>
import RbSearch from '@/components/widgets/search.vue';
import iconText from '@/components/basic/iconText.vue';
import { isEmpty } from 'lodash';
import textContainer from '@/components/basic/text_container.vue';
import Loader from '@/components/basic/loader.vue';
import DynamicComponentComposer from '@/components/layouts/containers/dynamicComponentComposer.vue';
import Vue from 'vue';

export default {
  components: {
    RbSearch,
    iconText,
    Loader,
    textContainer,
    DynamicComponentComposer
  },
  props: {
    headerOptions: {
      type: Array,
      default: () => []
    },
    footerOptions: {
      type: Array,
      default: () => []
    },
    enableFooter: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    context: {
      type: Object,
      default: () => {
        return {};
      }
    },
    lastUpdatedDate: {
      type: String,
      default: ''
    },
    headerContainerGridStyles: {
      type: Object,
      default: () => {
        return {
          'grid-template-columns': 'repeat(12,1fr)',
          'grid-row-gap': '12px'
        };
      }
    },
    footerContainerGridStyles: {
      type: Object,
      default: () => {
        return {
          'grid-template-columns': 'repeat(5,max-content)'
        };
      }
    }
  },
  created() {
    Vue.component('textContainer', textContainer);
    Vue.component('RbSearch', RbSearch);
    Vue.component('iconText', iconText);
  },
  methods: {
    eventMaper(eventMap) {
      if (isEmpty(eventMap)) {
        return {};
      }
      const vueEventMap = {};
      const parentReference = this.context;
      Object.keys(eventMap).forEach((listenerKey) => {
        if (typeof eventMap[listenerKey] === 'function') {
          vueEventMap[listenerKey] = eventMap[listenerKey];
        } else {
          vueEventMap[listenerKey] = parentReference[eventMap[listenerKey]];
        }
      });
      return vueEventMap;
    }
  }
};
</script>

<style lang="css" scoped>
.custom_widget_container {
  background: white;
}

.custom_widget_container .dynamic-component-container {
  display: grid;
}

.header-container-cwc {
  padding: 2.4rem;
}

.footer-container-cwc {
  display: grid;
}

.custom_widget_body {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
</style>
