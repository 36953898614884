import BaseWidgetService from '@/components/pages/insights/amazon/cva/services/BaseWidgetService.js';
import {
  getColumnDefinition,
  skuParamsToProps,
  getActiveColumns,
  rem1V2ConfigEnabled,
  getOrderByList,
  CONSTANTS
} from '@/components/pages/insights/amazon/cva/utils';
import HttpService from '@/utils/services/http-service';
import { cloneDeep } from 'lodash';

export default class TableService extends BaseWidgetService {
  async fetchData(pageSettings, selectedTab) {
    const pageSettingsCopy = cloneDeep(pageSettings);
    try {
      this.loading = true;
      this.error = false;
      const body = {
        page: 'customer_value_assesment',
        widget: 'cva_content_score_sku',
        entityType: 'SKU',
        metricsList: rem1V2ConfigEnabled()
          ? [
              'cva_content_score_client_id_v2',
              'cva_content_score_overall_score_v2',
              'cva_content_score_Video_flag_string_v2',
              'cva_content_score_A_content_string_v2'
            ]
          : [
              'cva_content_score_client_id',
              'cva_content_score_overall_score',
              'cva_content_score_Video_flag_string',
              'cva_content_score_A_content_string'
            ],
        dimensionsList: [
          'Title_length',
          'Desc_length',
          'Bullet_length',
          'Images',
          'A_content',
          'Video_flag',
          'review',
          'rating',
          'SKU',
          'score',
          'brand',
          'client_id'
        ],
        eventsList: [],
        enablePaginationCount: true,
        operations: {
          system: 'cva',
          enableNewPVPFormulaForSOV: false,
          enableDedupBeforeRollup: false,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
          orderByList: getOrderByList(
            'cva_content_score_overall_score',
            'DESC'
          ),
          limit: 20,
          page: 1,
          outerWhereClause: {
            dimensionNameValueList: []
          }
        },
        where: {
          dimensionNameValueList: [
            {
              dimensionName: 'time_period',
              dimensionValue: 'L90D',
              operator: 'EQUAL_TO'
            },
            {
              dimensionName: 'module',
              dimensionValue: 'Content_score_sku',
              operator: 'EQUAL_TO'
            }
          ]
        }
      };
      if (rem1V2ConfigEnabled()) {
        body.where.dimensionNameValueList.push(this.getRem1RetailerPayload());
        body.operations.orderByList = getOrderByList(
          'cva_content_score_overall_score_v2',
          'DESC'
        );
      }
      body.where.dimensionNameValueList.push(
        ...(pageSettingsCopy?.where?.dimensionNameValueList || [])
      );
      if (selectedTab === 'Your SKUs') {
        body.where.dimensionNameValueList.push({
          dimensionName: 'client_flag',
          dimensionValue: 'client',
          operator: 'EQUAL_TO'
        });
      } else {
        body.where.dimensionNameValueList.push({
          dimensionName: 'client_flag',
          dimensionValue: 'comp',
          operator: 'EQUAL_TO'
        });
      }
      const data = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/cat_analytics/data'
      });
      this.data = data.data;
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  getColumnDefs() {
    let columns = [
      {
        headerName: 'SKU Details',
        field: 'SKU',
        type: 'string',
        minWidth: 300,
        headerComponentParams: {
          enableSorting: false
        },
        cellRendererFramework: 'DynamicCellComponent',
        cellRendererParams: {
          component: 'skuWithRating',
          paramsToProps: skuParamsToProps
        },
        pinned: 'left'
      },
      {
        headerName: 'Brand',
        field: 'brand',
        toolTipText: 'Brand of the SKU',
        cellClass: 'u-text-transform-capitalize',
        minWidth: 150
      },
      {
        headerName: 'Overall Content Score',
        field: 'overall_content_score',
        sort: 'desc',
        headerComponentParams: {
          keyType: 'number',
          toolTipText:
            'Content Score is an indicator of the quality of content on the Product display pages. The score is computed based on a predefined set of parameters and thresholds. The dashboard analyses each of the parameters and checks if the SKU content falls with respect to the threshold. Based on the number of parameters crossing the threshold, a content score is generated for each SKU. For example, If the SKU content is more than the threshold for 8 parameters out of a total of 10 parameters, the content score will be 80%.'
        },
        cellRendererParams: {
          cellAlignment: 'u-text-align-right'
        }
      },
      {
        headerName: 'Title Length',
        field: 'Title_length',
        headerComponentParams: {
          keyType: 'number',
          toolTipText:
            'The number of characters in the SKU title as per CommerceIQ’s Market Intelligence data. It should ideally be > 40 characters'
        },
        cellRendererParams: {
          keyType: 'numeric',
          cellAlignment: 'u-text-align-right'
        },
        cellClass: (params) => {
          return params?.value < CONSTANTS?.contentScore?.titleLn
            ? 'u-bg-color-purple-xx-light'
            : '';
        }
      },
      {
        headerName: 'Description Length (words)',
        field: 'Desc_length',
        headerComponentParams: {
          keyType: 'number',
          toolTipText:
            'The number of words in the SKU description as per CommerceIQ’s Market Intelligence data. It should ideally be > 60 words'
        },
        cellRendererParams: {
          keyType: 'numeric',
          cellAlignment: 'u-text-align-right'
        },
        cellClass: (params) => {
          return params?.value < CONSTANTS?.contentScore?.descriptionLn
            ? 'u-bg-color-purple-xx-light'
            : '';
        }
      },
      {
        headerName: 'Number of Bullet Points',
        field: 'Bullet_length',
        headerComponentParams: {
          keyType: 'number',
          toolTipText:
            'The number of bullet points as per CommerceIQ’s Market Intelligence data. It should ideally be > 4 bullets'
        },
        cellRendererParams: {
          keyType: 'numeric',
          cellAlignment: 'u-text-align-right'
        },
        cellClass: (params) => {
          return params?.value < CONSTANTS?.contentScore?.bulletPoints
            ? 'u-bg-color-purple-xx-light'
            : '';
        }
      },
      {
        headerName: 'Number of Images',
        field: 'Images',
        headerComponentParams: {
          keyType: 'number',
          toolTipText:
            'The number of images as per CommerceIQ’s Market Intelligence data. It should ideally be > 4 images'
        },
        cellRendererParams: {
          keyType: 'numeric',
          cellAlignment: 'u-text-align-right'
        },
        cellClass: (params) => {
          return params?.value < CONSTANTS?.contentScore?.images
            ? 'u-bg-color-purple-xx-light'
            : '';
        }
      },
      {
        headerName: 'Average Rating',
        field: 'rating',
        headerComponentParams: {
          keyType: 'number',
          toolTipText:
            'The rating of the product as per CommerceIQ’s Market Intelligence data'
        },
        cellRendererParams: {
          fillType: 'float',
          cellAlignment: 'u-text-align-right'
        },
        cellClass: (params) => {
          return params?.value < CONSTANTS?.contentScore?.averageRating
            ? 'u-bg-color-purple-xx-light'
            : '';
        }
      },
      {
        headerName: 'Video Content',
        field: 'Video_flag_string',
        headerComponentParams: {
          keyType: 'string',
          toolTipText: 'Checks if the ASIN has video content on the PDP'
        },
        minWidth: 150,
        cellClass: (params) => {
          return params?.value?.toLowerCase() ===
            CONSTANTS?.contentScore?.videoContent.toLowerCase()
            ? 'u-bg-color-purple-xx-light'
            : '';
        }
      },
      {
        headerName: 'A+ Content',
        field: 'A_content_string',
        headerComponentParams: {
          keyType: 'string',
          toolTipText: 'Checks if the ASIN has A+ content on the PDP'
        },
        minWidth: 150,
        cellClass: (params) => {
          return params?.value?.toLowerCase() ===
            CONSTANTS?.contentScore?.aContent.toLowerCase()
            ? 'u-bg-color-purple-xx-light'
            : '';
        }
      }
    ];
    columns = getActiveColumns(columns, 'content_score');
    return columns.map(getColumnDefinition);
  }

  getTableData() {
    const asinMetrics = ['SKU'];
    const tableData = (this.data?.entityData || []).map((entity) => {
      return entity.data.reduce((previousVal, currentVal) => {
        if (asinMetrics.includes(currentVal.name)) {
          previousVal[currentVal.name] = currentVal.RESULT;
        } else {
          previousVal[currentVal.name] = currentVal.RESULT?.[currentVal.name];
        }
        return previousVal;
      }, {});
    });
    return tableData;
  }
}
