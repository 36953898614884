<template>
  <div
    class="u-spacing-pv-xxxl u-spacing-pb-xxl u-spacing-ph-xxl campaign-creation-campaign-targeting"
  >
    <section class="u-spacing-pt-m">
      <createCampaignHeader :step="step" />
      <!-- Radio button selection -->
      <div class="u-spacing-mb-xl u-width-60">
        <span
          class="u-font-size-5 u-font-weight-600 u-color-grey-base"
          data-cy="selectTargetingTypeTxt"
          >Select a targeting type:</span
        >
        <rbRadioGroup
          :radio-options="radioOptions"
          :value="radioSelectedValue"
          :desc-class="'u-line-height-1_6'"
          :container-class="radioContainerClass"
          data-cy="radioGroupSublabel"
          @onRadioChanged="handleRadioSelection"
        />
      </div>

      <div v-if="radioSelectedValue.toLowerCase() === 'keyword targeting'">
        <!-- First custom action panel component -->
        <component
          :is="customActionPanelTargetingCustomState.component"
          v-if="customActionPanelTargetingCustomState"
          :key="addTargetingComponentKey"
          class="add-keyword-campaign-creation"
          v-bind="customActionPanelTargetingCustomState.props"
          v-on="customActionPanelTargetingCustomState.events"
        >
          <div
            v-if="targetMessages && !targetsLength"
            slot="right-table-footer"
            class="edit_suggested_campaign_keyword_targeting u-font-size-6 u-color-orange-base u-font-weight-600 u-position-absolute"
          >
            <rb-icon
              class="u-color-orange-base rb-icon--small"
              icon="info-circle-fill"
              data-cy="targetMsg"
            />
            {{ targetMessages }}
          </div>
        </component>

        <!-- Collapsible title for negative targeting -->
        <div class="u-flex-align-items-center u-display-flex">
          <CollapsibleTitle
            class="u-spacing-pt-xl u-spacing-pr-s u-spacing-pb-l"
            title="Negative Keyword Targeting"
            :is-expanded="negativeKeywordsExpand"
            title-font-class="u-font-size-5 u-font-weight-600"
            data-cy="clickNegativeKeywordsExpand"
            @click="() => (negativeKeywordsExpand = !negativeKeywordsExpand)"
          />
          <div class="u-spacing-pt-s">
            <div
              class="u-font-size-5 u-border-left u-font-weight-600 u-border-width-s u-border-color-grey-xxx-light u-color-grey-lighter u-spacing-pl-s u-spacing-pv-xxs"
              data-cy="optionalTxt"
            >
              Optional
            </div>
          </div>
        </div>

        <!-- Second custom action panel component -->
        <div v-if="negativeKeywordsExpand">
          <component
            :is="customActionPanelNegativeTargetingCustomState.component"
            v-if="customActionPanelNegativeTargetingCustomState"
            :key="addNegativeTargetingComponentKey"
            class="add-keyword-campaign-creation"
            v-bind="customActionPanelNegativeTargetingCustomState.props"
            v-on="customActionPanelNegativeTargetingCustomState.events"
          >
            <div
              v-if="targetMessages && !targetsLength"
              slot="right-table-footer"
              class="edit_suggested_campaign_keyword_targeting u-font-size-6 u-color-orange-base u-font-weight-600 u-position-absolute"
            >
              <rb-icon
                class="u-color-orange-base rb-icon--small u-display-block"
                icon="info-circle-fill"
                data-cy="targetMsg"
              />
              {{ targetMessages }}
            </div>
          </component>
        </div>
      </div>

      <!-- Product targeting -->
      <div v-else-if="radioSelectedValue.toLowerCase() === 'product targeting'">
        <component
          :is="customActionPanelProductAndCategoryCustomState.component"
          v-if="customActionPanelProductAndCategoryCustomState"
          :key="productTargetingComponentKey"
          class="add-keyword-campaign-creation"
          v-bind="customActionPanelProductAndCategoryCustomState.props"
          v-on="customActionPanelProductAndCategoryCustomState.events"
        >
          <div
            v-if="targetMessages && !targetsLength"
            slot="right-table-footer"
            class="edit_suggested_campaign_keyword_targeting u-font-size-6 u-color-orange-base u-font-weight-600 u-position-absolute"
          >
            <rb-icon
              class="u-color-orange-base rb-icon--small u-display-block"
              icon="info-circle-fill"
              data-cy="targetMsg"
            />
            {{ targetMessages }}
          </div>
          <div slot="leftTableHeader">
            <GenericTabsComponent
              :tabs-list="tabList"
              :default-tab-index="defaultTabIndex"
              @handleTabsClick="handleTabsClick"
            />
          </div>
        </component>

        <!-- Collapsible title for negative targeting -->
        <div
          v-if="step.showNegativeTargeting"
          class="u-flex-align-items-center u-display-flex"
        >
          <CollapsibleTitle
            class="u-spacing-pt-xl u-spacing-pr-s u-spacing-pb-l"
            title="Negative Product Targeting"
            :is-expanded="negativeTargetExpand"
            title-font-class="u-font-size-5 u-font-weight-600"
            @click="() => (negativeTargetExpand = !negativeTargetExpand)"
          />
          <div class="u-spacing-pt-s">
            <div
              data-cy="optionalTxt"
              class="u-font-size-5 u-border-left u-font-weight-600 u-border-width-s u-border-color-grey-xxx-light u-color-grey-lighter u-spacing-pl-s u-spacing-pv-xxs"
            >
              Optional
            </div>
          </div>
        </div>

        <!-- Second custom action panel component -->
        <div v-if="negativeTargetExpand">
          <component
            :is="customActionPanelNegativeTargetingCustomState.component"
            v-if="customActionPanelNegativeTargetingCustomState"
            :key="addNegativeTargetingComponentKey"
            class="add-keyword-campaign-creation"
            v-bind="customActionPanelNegativeTargetingCustomState.props"
            v-on="customActionPanelNegativeTargetingCustomState.events"
          >
            <div
              v-if="targetMessages && !targetsLength"
              slot="right-table-footer"
              class="edit_suggested_campaign_keyword_targeting u-font-size-6 u-color-orange-base u-font-weight-600 u-position-absolute"
            >
              <rb-icon
                class="u-color-orange-base rb-icon--small u-display-block"
                icon="info-circle-fill"
                data-cy="targetMsg"
              />
              {{ targetMessages }}
            </div>
          </component>
        </div>
      </div>

      <!-- Default Bid - Auto targeting -->
      <div
        v-if="autoTargetingOptions.includes(radioSelectedValue.toLowerCase())"
      >
        <div v-if="radioSelectedValue.toLowerCase() === 'default_bid'">
          <component
            :is="step.default_bid_component"
            v-if="step.default_bid_component"
            :key="autoTargetingComponentKey"
            :selected-values="selectedValues"
            :step="step"
            class="add-keyword-campaign-creation"
          />

          <!-- Collapsible title for negative targeting -->
          <div class="u-flex-align-items-center u-display-flex">
            <CollapsibleTitle
              class="u-spacing-pt-xl u-spacing-pr-s u-spacing-pb-l"
              title="Negative Keyword Targeting"
              :is-expanded="negativeKeywordsExpand"
              title-font-class="u-font-size-5 u-font-weight-600"
              @click="() => (negativeKeywordsExpand = !negativeKeywordsExpand)"
            />
            <div class="u-spacing-pt-s">
              <div
                class="u-font-size-5 u-border-left u-font-weight-600 u-border-width-s u-border-color-grey-xxx-light u-color-grey-lighter u-spacing-pl-s u-spacing-pv-xxs"
                data-cy="optionalTxt"
              >
                Optional
              </div>
            </div>
          </div>

          <!-- Second custom action panel component -->
        </div>

        <!-- Bids by targeting groups - Auto targeting -->
        <div
          v-else-if="
            radioSelectedValue.toLowerCase() === 'bids_by_targeting_group'
          "
        >
          <component
            :is="step.bids_by_targeting_group_component"
            v-if="step.bids_by_targeting_group_component"
            :key="autoTargetingComponentKey"
            :step="step"
            :selected-values="selectedValues"
            @stepDescription="autoTargetingStepDescription"
          />
          <div
            v-if="targetMessages"
            class="u-display-flex u-spacing-pt-s edit_suggested_campaign_keyword_targeting u-font-size-6 u-color-orange-base u-font-weight-600"
          >
            <rb-icon
              class="u-color-orange-base rb-icon--small u-display-block"
              icon="info-circle-fill"
            />
            <span
              class="u-spacing-pl-s"
              data-cy="targetMsg"
              >{{ targetMessages }}</span
            >
          </div>

          <!-- Collapsible title for negative targeting -->
          <div class="u-flex-align-items-center u-display-flex">
            <CollapsibleTitle
              class="u-spacing-pt-xl u-spacing-pr-s u-spacing-pb-l"
              title="Negative Keyword Targeting"
              :is-expanded="negativeKeywordsExpand"
              title-font-class="u-font-size-5 u-font-weight-600"
              @click="() => (negativeKeywordsExpand = !negativeKeywordsExpand)"
            />
            <div class="u-spacing-pt-s">
              <div
                class="u-font-size-5 u-border-left u-font-weight-600 u-border-width-s u-border-color-grey-xxx-light u-color-grey-lighter u-spacing-pl-s u-spacing-pv-xxs"
                data-cy="optionalTxt"
              >
                Optional
              </div>
            </div>
          </div>
        </div>
        <div v-if="negativeKeywordsExpand">
          <component
            :is="customActionPanelNegativeTargetingCustomState.component"
            v-if="customActionPanelNegativeTargetingCustomState"
            class="add-keyword-campaign-creation"
            v-bind="customActionPanelNegativeTargetingCustomState.props"
            v-on="customActionPanelNegativeTargetingCustomState.events"
          />
        </div>
      </div>

      <bidWarning
        :show="showTargetWarningMessage"
        :title="targetWarningTitle"
        :sub-text="targetWarningSubText"
      />

      <!-- Footer -->
      <createCampaignFooter
        :disable-right-btn="
          isCreateAdGroupFlow
            ? boolAutoTargeting && targetMessages && targetMessages.length
              ? true
              : disableRightButton
            : !targetsLength
        "
        :class="showTargetWarningMessage ? 'u-spacing-mt-s' : 'u-margin-top-40'"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
        @onRightBtnDisabled="handleRightBtnDisabled"
      />
    </section>
  </div>
</template>

<script>
import rbRadioGroup from '@/components/ams/campaign_creation/steps/common/rbRadioGroupSubLabel.vue';
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import CustomActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';
import CollapsibleTitle from '@/components/pages/strategy_builder/steps/collapsible-title.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import GenericTabsComponent from '@/components/basic/genericTabsComponent.vue';
import bidWarning from '@/components/ams/campaign_creation/steps/edit/campaignTargetting/bidWarning.vue';
export default {
  components: {
    bidWarning,
    rbRadioGroup,
    createCampaignHeader,
    createCampaignFooter,
    CollapsibleTitle,
    CustomActionPanel,
    GenericTabsComponent
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    autoTargetingOptions: ['default_bid', 'bids_by_targeting_group'],
    showTargetWarningMessage: false,
    targetWarningTitle: '',
    targetWarningSubText: '',
    campaignTypeMap: {
      sponsoredProducts: 'sp',
      createOwnCampaignSP: 'sp',
      createOwnCampaignSD: 'sp', // as of yet, no recommandation support for sd, so keeping it as SP
      sponsoredBrands: 'sb',
      createOwnCampaignSB: 'sb'
    },
    radioContainerClass:
      'global-radio-wrapper u-spacing-mr-l u-spacing-mt-m u-width-60',
    campaignType: 'sp',
    radioOptions: [
      {
        type: 'Keyword Targeting',
        label: 'Keyword Targeting',
        desc: 'Choose keywords to help your products appear in shopper searches'
      },
      {
        type: 'Product Targeting',
        label: 'Product Targeting',
        desc: 'Choose categories, products, brands related to your product'
      }
    ],
    radioSelectedValue: 'Keyword Targeting',
    negativeKeywordsExpand: false,
    negativeTargetExpand: false,
    customActionPanelTargetingCustomState: null,
    customActionPanelNegativeTargetingCustomState: null,
    customActionPanelProductAndCategoryCustomState: null,
    suggestedCampaignIndex: 0,
    defaultTabIndex: 0,
    tabList: ['Categories', 'Products'],
    addTargetingComponentKey: 0,
    addNegativeTargetingComponentKey: 0,
    productTargetingComponentKey: 0,
    autoTargetingComponentKey: 0
  }),
  computed: {
    isCreateAdGroupFlow() {
      if (this.$route.name === 'create_ad_group' || this.$route?.query?.own) {
        return true;
      } else {
        return false;
      }
    },
    disableRightButton() {
      const campaignToEdit =
        this.allStepsData?.suggestedCampaigns[this.suggestedCampaignIndex];
      if (this.boolAutoTargeting) {
        if (campaignToEdit.autoTargetingMetbod === 'default_bid') {
          return campaignToEdit?.budget <= 0;
        } else if (campaignToEdit?.targets?.length) {
          let checkedItems = [];
          let uncheckedItems = campaignToEdit?.targets.filter((item) => {
            if (item.checked) {
              checkedItems.push(item);
            }
            return !item.checked;
          });
          if (campaignToEdit?.targets?.length === uncheckedItems?.length) {
            return true;
          } else if (checkedItems?.length) {
            let emptyCheckedBids = [];
            checkedItems.map((item) => {
              if (item.value <= 0 || !item.value) {
                emptyCheckedBids.push(item);
              }
            });
            if (emptyCheckedBids?.length) {
              return true;
            }
          }
          return false;
          // return boolEmptyValues;
        }
      } else {
        const arrayToTraverse = campaignToEdit?.targets;
        let bidKeyToCheckWith = 'exact_bid';
        if (this.radioSelectedValue === 'Product Targeting') {
          bidKeyToCheckWith = 'new_bid';
        }
        for (const i in arrayToTraverse) {
          let exact_bid = arrayToTraverse[i]?.[bidKeyToCheckWith];
          exact_bid = parseFloat(exact_bid);
          if (!exact_bid) {
            return true;
          }
        }
        return !arrayToTraverse.length;
      }
    },
    targetMessages() {
      const campaignToEdit =
        this.allStepsData?.suggestedCampaigns[this.suggestedCampaignIndex];
      if (
        this.boolAutoTargeting &&
        campaignToEdit.autoTargetingMetbod !== 'default_bid'
      ) {
        return campaignToEdit.message;
      }
      return this.allStepsData?.suggestedCampaigns?.[
        this.suggestedCampaignIndex
      ]?.messages?.targets;
    },
    targetsLength() {
      if (this.boolAutoTargeting) {
        return false;
      } else {
        return this.allStepsData?.suggestedCampaigns[
          this.suggestedCampaignIndex
        ]?.targets?.length;
      }
    },
    allStepsData() {
      return this.$store?.getters?.[this.selectedValues?.getter];
    },
    boolAutoTargeting() {
      let value = false;
      const suggestedCampaign =
        this.allStepsData?.suggestedCampaigns?.[this.suggestedCampaignIndex];
      if (
        this.campaignType === 'sp' &&
        suggestedCampaign.spTargetingType === 'auto'
      ) {
        value = true;
      }
      return value;
    }
  },
  watch: {
    targetsLength: {
      handler(newVal) {
        if (this.boolAutoTargeting) {
          this.autoTargetingStepDescription();
        } else {
          const type =
            this.allStepsData?.suggestedCampaigns[this.suggestedCampaignIndex]
              .targetingType;
          const targetingTypeMap = {
            keyword: 'keyword',
            product: 'target'
          };
          this.step.description = `${newVal || 0} ${targetingTypeMap[type]}${
            newVal !== 1 ? "'s" : ''
          } added`;
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.suggestedCampaignIndex = this?.$route?.query?.key?.split('-')?.[1];
    this.suggestedCampaignIndex = parseInt(this.suggestedCampaignIndex);
    const { campaignType, targetingType } =
      this.allStepsData?.suggestedCampaigns[this.suggestedCampaignIndex];
    this.campaignType = this.campaignTypeMap[campaignType];
    if (this.step?.radioOptions) {
      this.radioOptions = this.step.radioOptions;
    }
    this.customActionPanelTargetingCustomState =
      this.step?.customActionPanelKeywordTargetingCustomState(this);
    this.customActionPanelNegativeTargetingCustomState =
      this.step?.customActionPanelNegativeKeywordTargetingCustomState(this);
    this.customActionPanelProductAndCategoryCustomState =
      this.step?.customActionPanelCategoriesTargetingCustomState(
        this,
        this.campaignType
      );

    this.checkAndLoadAutoTargetingConfigs();

    // Based on the previous targeting tab already selected by the user
    // Select that radio button automatically
    const previousTargetingType = targetingType;
    if (previousTargetingType === 'product') {
      this.radioSelectedValue = 'Product Targeting';
    }
    this.updateAllStepsData();
  },
  methods: {
    handleRightBtnDisabled(value) {
      this.$parent.$emit('onStepNextBtnDisabled', {
        disabled: value,
        index: this.step.index
      });
    },
    handleTabsClick(value) {
      if (value === 'Categories') {
        this.customActionPanelProductAndCategoryCustomState =
          this.step?.customActionPanelCategoriesTargetingCustomState(
            this,
            this.campaignType
          );
        this.defaultTabIndex = 0;
      } else {
        this.customActionPanelProductAndCategoryCustomState =
          this.step?.customActionPanelProductTargetingCustomState(this);
        this.defaultTabIndex = 1;
      }
      this.productTargetingComponentKey = Math.random();
    },
    checkAndLoadAutoTargetingConfigs() {
      if (!this.boolAutoTargeting) {
        return;
      }
      const suggestedCampaign =
        this.allStepsData?.suggestedCampaigns[this.suggestedCampaignIndex];
      this.radioOptions = this.step.radioOptions;
      this.radioSelectedValue =
        suggestedCampaign?.autoTargetingMetbod || 'default_bid';
      this.updateAllStepsData(false);
      this.autoTargetingStepDescription();
    },
    autoTargetingStepDescription() {
      const suggestedCampaign =
        this.allStepsData?.suggestedCampaigns[this.suggestedCampaignIndex];
      this.step.description = this.step.getDescription(suggestedCampaign);
    },
    nextStep() {
      this.$emit('nextStep', {});
    },
    prevStep() {
      this.$emit('prevStep', {});
    },
    handleRadioSelection(selectedType) {
      this.radioSelectedValue = selectedType;
      this.updateAllStepsData(true);
    },
    updateAllStepsData(clearTargets = false) {
      const allStepsData = this.allStepsData;
      const suggestedCampaigns = allStepsData.suggestedCampaigns;
      const suggestedCampaignToEdit =
        suggestedCampaigns[this.suggestedCampaignIndex];
      if (clearTargets === true) {
        this.addTargetingComponentKey = Math.random();
        this.addNegativeTargetingComponentKey = Math.random();
        this.productTargetingComponentKey = Math.random();
        this.autoTargetingComponentKey = Math.random(4);
        // Add random for productAndCategoryTargeting also
        if (!this.boolAutoTargeting) {
          suggestedCampaignToEdit.targets = [];
          suggestedCampaignToEdit.negativeTargets = [];
        }
      }
      if (this.radioSelectedValue.toLowerCase() === 'keyword targeting') {
        suggestedCampaignToEdit.targetingType = 'keyword';
      } else if (
        this.radioSelectedValue.toLowerCase() === 'product targeting'
      ) {
        suggestedCampaignToEdit.targetingType = 'product';
      } else if (this.boolAutoTargeting) {
        suggestedCampaignToEdit.autoTargetingMetbod = this.radioSelectedValue;
        if (this.radioSelectedValue.toLowerCase === 'default_bid') {
          suggestedCampaignToEdit.targets = this.step.rows;
        }
      }
      suggestedCampaigns[this.suggestedCampaignIndex] = suggestedCampaignToEdit;
      const newSuggestedCampaigns = [...suggestedCampaigns];
      this.$store.dispatch(this.selectedValues?.action, {
        ...allStepsData,
        suggestedCampaigns: newSuggestedCampaigns
      });
    }
  }
};
</script>

<style scoped>
.edit_suggested_campaign_keyword_targeting {
  bottom: -20px;
}
</style>
