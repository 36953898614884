var values = {
  unavailable_merged_rec: {
    availability_type: {
      width: '160px',
      key: 'availability_type',
      values: [
        {
          title: 'Unavailable'
        },
        {
          title: 'Out of Stock'
        },
        {
          title: 'Suppressed'
        }
      ]
    }
  },
  'Content change all': {
    content_change_type: {
      width: '320px',
      key: 'content_change_type',
      values: [
        {
          title: 'Change in title'
        },
        {
          title: 'Change in bullet points'
        },
        {
          title: 'Change in description'
        },
        {
          title: 'Change in important information'
        },
        {
          title: 'Change in number of images'
        },
        {
          title: 'Change in hero image'
        },
        {
          title: 'Change in Video Count'
        },
        {
          title: 'Video Drop'
        }
      ]
    },
    deviation: {
      width: '100px',
      key: 'deviation',
      values: [
        {
          title: 'Yes'
        },
        {
          title: 'No'
        },
        {
          title: 'NA'
        }
      ]
    }
  },
  'Change in Variants': {
    variant_deviation: {
      width: '100px',
      key: 'variant_deviation',
      values: [
        {
          title: 'Yes'
        },
        {
          title: 'No'
        },
        {
          title: 'NA'
        }
      ]
    }
  },
  po_discrepancy_rec: {
    discrepancy_type: {
      width: '160px',
      className: 'po-discrepancy-reccommendation',
      key: 'discrepancy_type',
      values: [
        {
          title: 'Case Size'
        },
        {
          title: 'Case Cost'
        },
        {
          title: 'SKU Id'
        },
        {
          title: 'Availability'
        }
      ]
    }
  }
};

export default {
  values
};
