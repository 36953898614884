<template>
  <section class="addSearchTermAsKeyword u-bg-color-grey-white u-width-100">
    <section>
      <loader
        v-if="loader"
        class="fill--parent u-bg-color-grey-white"
        :loading="loader"
        :color="'#007cf6'"
      />
      <div class="u-display-flex u-flex-direction-column">
        <header
          class="title u-font-size-5 u-font-weight-600 u-spacing-pv-l u-spacing-ph-l u-text-case-upper"
        >
          {{ widgetTitle }}
        </header>
        <div class="u-display-flex">
          <div class="u-display-flex u-width-50 u-flex-direction-column">
            <section
              v-if="showCampaignDetails"
              class="campaign-selection u-display-flex u-flex-direction-column u-spacing-ph-l"
            >
              <div
                class="u-font-size-6 u-font-weight-600 u-color-grey-mid-light u-spacing-mb-s"
              >
                Campaign
              </div>
              <div class="u-max-width-50 u-display-flex">
                <rb-select
                  class="u-spacing-mr-s valueDropdown u-max-width-100"
                  :send-details="true"
                  :on-close="campaignSelected"
                  :options="campaignDropdownOptions"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-spacing-p-s u-cursor-pointer"
                  >
                    <div
                      class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis"
                    >
                      {{ selectedCampaign && selectedCampaign.campaign_name }}
                    </div>
                    <rb-icon
                      class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base"
                      :icon="'caret-down'"
                    />
                  </div>
                  <template
                    slot="item"
                    slot-scope="option"
                  >
                    <div
                      class="u-display-flex u-flex-align-items-center"
                      :title="option.campaign_name"
                    >
                      {{ option.campaign_name }}
                    </div>
                  </template>
                </rb-select>
              </div>
            </section>

            <section
              v-if="showCampaignDetails"
              class="campaign-selection u-display-flex u-flex-direction-column u-spacing-ph-l u-spacing-pt-m"
            >
              <div
                class="u-font-size-6 u-font-weight-600 u-color-grey-mid-light u-spacing-mb-s"
              >
                Ad Group
              </div>
              <div class="u-display-flex">
                <rb-select
                  class="valueDropdown u-max-width-100"
                  class-name="bp-select"
                  :send-details="true"
                  :is-multi-select="true"
                  :on-close="onSelectAdGroup"
                  :options="adGroups"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-spacing-p-s u-cursor-pointer"
                  >
                    <div
                      class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis"
                    >
                      {{ adGroupTitle }}
                    </div>
                    <rb-icon
                      class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base"
                      :icon="'caret-down'"
                    />
                  </div>
                  <div
                    v-if="adGroups.length"
                    slot="footer"
                    class="u-display-flex u-spacing-ph-l u-spacing-pt-m u-spacing-pb-s u-border-top u-border-width-s u-border-color-grey-xxx-light"
                  >
                    <rb-button
                      :click-fn="clickOutside"
                      :type="'filled'"
                      text="Apply"
                      size="s"
                    />
                  </div>
                </rb-select>
                <div class="dummy-element">&nbsp;</div>
              </div>
            </section>

            <section
              class="match-type-selection u-display-flex u-flex-direction-column u-spacing-ph-l"
              :class="{
                'u-spacing-pt-0 u-spacing-pb-m': !showCampaignDetails,
                'u-spacing-pv-m': showCampaignDetails
              }"
            >
              <div
                class="u-font-size-6 u-font-weight-600 u-color-grey-mid-light u-spacing-mb-s"
              >
                Match Type
              </div>
              <div class="u-width-auto">
                <rb-select
                  class="u-spacing-mr-s valueDropdown u-width-150px"
                  :width="'150px'"
                  :send-details="true"
                  :on-close="matchTypeSelected"
                  :options="matchTypeOptions"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                  >
                    <div
                      class="u-flex-0 u-flex-shrink-past-min-size u-display-flex u-flex-direction-column u-spacing-p-s"
                    >
                      <span
                        class="u-font-size-5 u-text-case-title"
                        daat-cy="selectedMatchType"
                        >{{
                          selectedMatchType && selectedMatchType.title
                        }}</span
                      >
                    </div>
                    <rb-icon
                      class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                      :icon="'caret-down'"
                    />
                  </div>
                  <template
                    slot="item"
                    slot-scope="option"
                  >
                    <div
                      class="u-display-flex u-flex-align-items-center u-text-case-title"
                    >
                      {{ option.title }}
                    </div>
                  </template>
                </rb-select>
              </div>
            </section>

            <section
              v-if="!hideKeywordsBidSection"
              class="content u-display-flex u-flex-direction-column u-spacing-ph-l"
            >
              <div
                class="u-font-size-6 u-font-weight-600 u-color-grey-mid-light u-spacing-mb-s"
              >
                Keyword Bid
              </div>
              <div
                class="u-color-grey-light u-display-flex u-flex-direction-row u-spacing-pb-s u-spacing-pt-0"
              >
                <div
                  class="u-spacing-mb-s"
                  style="width: 200px"
                >
                  <rb-radio
                    v-model="customBids"
                    native-value="0"
                  >
                    Respective Keyword Bids
                  </rb-radio>
                </div>
                <div class="u-spacing-mb-s u-width-160px">
                  <rb-radio
                    v-model="customBids"
                    native-value="1"
                  >
                    Custom Bids
                  </rb-radio>
                </div>
              </div>
              <div
                v-if="customBids === '1'"
                class="radio-holder u-display-flex u-spacing-pb-l"
              >
                <div
                  class="left-panel u-font-size-5 u-color-grey-light u-width-260px u-spacing-pv-m"
                  @change="changeRadio"
                >
                  <div class="u-spacing-mb-m">
                    <rb-radio
                      v-model="bidsType"
                      native-value="0"
                    >
                      Set new bid
                    </rb-radio>
                  </div>
                  <div class="u-spacing-mb-m">
                    <rb-radio
                      v-model="bidsType"
                      native-value="1"
                    >
                      Increase bid
                    </rb-radio>
                  </div>
                  <div class="u-spacing-mb-xs">
                    <rb-radio
                      v-model="bidsType"
                      native-value="2"
                    >
                      Decrease bid
                    </rb-radio>
                  </div>
                </div>
                <div
                  class="u-spacing-pl-xxl u-display-flex u-width-100 u-flex-align-items-baseline u-flex-justify-content-center u-flex-direction-column"
                >
                  <div class="u-display-flex">
                    <rb-select
                      v-if="bidsType !== '0'"
                      class="u-spacing-mr-s valueDropdown"
                      :send-details="true"
                      :on-close="bidOptionSelected"
                      :options="dropdownOptions"
                    >
                      <div
                        slot="trigger"
                        class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                      >
                        <div
                          class="u-display-flex u-flex-direction-column u-spacing-p-s"
                        >
                          <span class="u-font-size-5">{{
                            selectedOption.title
                          }}</span>
                        </div>
                        <rb-icon
                          class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                          :icon="'caret-down'"
                        />
                      </div>
                      <template
                        slot="item"
                        slot-scope="option"
                      >
                        <div class="u-display-flex u-flex-align-items-center">
                          {{ option.title }}
                        </div>
                      </template>
                    </rb-select>
                    <div
                      class="u-display-flex u-flex-0 u-flex-align-items-center u-width-120px"
                      :class="{
                        percentBox:
                          selectedOption.value === 'percent' && bidsType !== '0'
                      }"
                    >
                      <span
                        v-if="
                          bidsType === '0' || selectedOption.value !== 'percent'
                        "
                        class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                      >
                        {{ currency }}
                      </span>
                      <input
                        ref="new_bid"
                        v-model="bidValue"
                        type="number"
                        class="rb-input currency-input u-display-flex u-flex-align-items-center"
                        :min="
                          validationObj[computedActionPanelType].absolute.min
                        "
                        step="0.01"
                        :max="
                          validationObj[computedActionPanelType].absolute.max
                        "
                        autofocus
                        @input="checkForMaxBid($event)"
                      />
                      <span
                        v-if="
                          bidsType !== '0' && selectedOption.value === 'percent'
                        "
                        class="appender u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pr-s u-flex-0"
                      >
                        %
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="bidsType !== '0'"
                    class="u-display-flex u-spacing-pt-m u-flex-direction-column"
                  >
                    <div
                      class="u-display-flex u-font-size-6 u-font-weight-600 u-color-grey-mid-light u-spacing-pb-xs"
                    >
                      {{ bidsType == 1 ? 'Ceiling Bid' : 'Floor Bid' }}
                    </div>
                    <div
                      class="u-display-flex u-flex-0 u-flex-align-items-center u-width-120px"
                      :class="{ percentBox: bidsType !== '0' }"
                    >
                      <span
                        v-if="
                          bidsType !== '0' || selectedOption.value === 'percent'
                        "
                        class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                      >
                        {{ currency }}
                      </span>
                      <input
                        v-model="floorCeilingValue"
                        type="number"
                        class="rb-input currency-input-right u-display-flex u-flex-align-items-center"
                        :min="
                          validationObj[computedActionPanelType].absolute.min
                        "
                        step="0.01"
                        :max="
                          validationObj[computedActionPanelType].absolute.max
                        "
                        autofocus
                        @input="checkForMaxBid($event)"
                      />
                    </div>
                  </div>
                  <div>
                    <span
                      v-if="showError"
                      class="u-flex-0 u-font-size-7 u-color-red-base"
                      >{{ errorMessage }}</span
                    >
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div
            v-if="duplicateRowsPresent"
            class="u-display-flex u-width-50 error-section u-flex-align-items-flex-start u-spacing-mb-l"
          >
            <div
              class="u-display-flex u-flex-align-items-flex-start u-flex-direction-row"
            >
              <div class="u-display-flex u-flex-align-items-flex-start">
                <rb-icon
                  class="u-flex-0 rb-icon--medium u-color-orange-base u-spacing-ml-s"
                  :icon="'caution'"
                />
              </div>
              <!-- eslint-disable -->
              <div
                class="u-flex-0 u-font-size-5 u-color-orange-base u-spacing-ph-s"
                v-html="duplicateErrorMessage"
              />
              <!-- eslint-enable -->
            </div>
          </div>
        </div>
        <footer class="addSearchTermAsKeywordFooter">
          <div
            class="u-display-flex u-flex-direction-column u-spacing-pv-m u-spacing-ph-l u-border-width-s u-border-top u-border-bottom u-border-color-grey-xxx-light"
          >
            <div
              v-if="valueConfirmationRequired && confirmCheckRequired"
              class="u-display-flex u-spacing-mb-m"
            >
              <rb-checkbox
                key="confirmHighValue"
                v-model="validationConfirmed"
                native-value="false"
                class="u-width-50"
              >
                {{ confirmMessage }}
              </rb-checkbox>
            </div>
            <div class="u-display-flex">
              <rb-button
                :id="applyActionID()"
                :click-fn="takeAction"
                :text="'Apply'"
                :size="'s'"
                :type="'filled'"
                class="u-spacing-mr-s"
                :disabled="applyButtonValidity"
                :class="{
                  disabled:
                    customBids === '1' &&
                    (bidValue.length === 0 || formValid === false)
                }"
              />
              <rb-button
                :click-fn="takeAction"
                :text="'Cancel'"
                :size="'s'"
                :type="'hollow'"
                class="u-spacing-mr-s"
              />
            </div>
          </div>
        </footer>
      </div>
    </section>
  </section>
</template>

<script>
import loader from '@/components/basic/loader';
import { commons } from '@/components/ams/campaigns/commons.js';
import { uniqBy, cloneDeep } from 'lodash';
export default {
  name: 'AddSearchTermAsKeyword',
  components: {
    loader
  },
  props: {
    selectedValues: {
      type: Array,
      default: function () {
        return [];
      }
    },
    profileNames: {
      type: Array,
      default: null
    },
    confirmCheckRequired: {
      type: Boolean,
      default: false
    },
    closePanelMethod: {
      type: Function,
      default: function () {}
    },
    afterAPIActionToCall: {
      type: Function,
      default: function () {}
    },
    actionPanelType: {
      type: String,
      default: 'searchTerms'
    },
    parentRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    campaignEditConfig: {
      type: Object,
      default: function () {
        return { boolCampaignEdit: false };
      }
    },
    showCampaignDetails: {
      type: Boolean,
      default: true
    },
    selectedActionType: {
      type: String,
      default: ''
    },
    isRetailerIndependent: {
      type: Boolean,
      default: false
    },
    retailerIndependentConfigs: {
      type: Object,
      default: function () {
        return {};
      }
    },
    matchTypeOptions: {
      type: Array,
      default: function () {
        return [{ title: 'exact' }, { title: 'broad' }, { title: 'phrase' }];
      }
    },
    hideKeywordsBidSection: {
      type: Boolean,
      default: false
    },
    isUsingForAddNegativeKeyword: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      floorCeilingValue: '',
      retailer: '',
      bidValue: '',
      bidsType: '0',
      customBids: '0',
      duplicateRowsPresent: false,
      duplicateErrorMessage: '',
      dropdownOptions: [
        {
          title: 'Change by percentage',
          value: 'percent'
        },
        {
          title: 'Change by absolute value',
          value: 'absolute'
        }
      ],
      campaignDropdownOptions: [],
      selectedAdGroup: [],
      adGroups: [],
      selectedCampaign: {},
      selectedMatchType: null,
      selectedOption: {},
      currency: this.$currency,
      formValid: true,
      errorMessage: '',
      showError: false,
      applyClicked: false,
      previouslySelectedAdGroupData: [],
      validationObj: {
        searchTerms: {
          absolute: {
            max: 1000,
            min: 0.02
          }
        },
        walmartsearchTermsSP: {
          absolute: {
            min: 0.3,
            max: 100
          }
        },
        walmartsearchTermsSBA: {
          absolute: {
            min: 1,
            max: 100
          }
        },
        samsclub_apisearchTermsSP: {
          absolute: {
            min: 0.3,
            max: 20
          }
        }
      },
      loader: false,
      bidsActions: {
        0: 'newBids',
        1: 'increaseBids',
        2: 'decreaseBids'
      },
      validationConfirmed: false,
      valueConfirmationRequired: false,
      allCampaingsData: []
    };
  },
  computed: {
    actionMap() {
      return {
        searchTerms: {
          actionName: 'addKeywordToCampaigns',
          bidKey: 'bid',
          previousBid: 'previousBid',
          newBid: 'newBid',
          matchTypeKey: 'match_type_keyword'
        },
        [`${this.retailer}searchTermsSP`]: {
          actionName: `${this.retailer}AddKeywordToCampaigns`,
          bidKey: 'bid',
          previousBid: 'previousBid',
          newBid: 'newBid',
          matchTypeKey: 'match_type'
        },
        [`${this.retailer}searchTermsSBA`]: {
          actionName: `${this.retailer}AddKeywordToCampaigns`,
          bidKey: 'bid',
          previousBid: 'previousBid',
          newBid: 'newBid',
          matchTypeKey: 'match_type'
        }
      };
    },
    adGroupTitle() {
      let text = '';
      if (this.selectedAdGroup.length === 0) {
        text = 'Respective ad groups of selected search terms';
      } else {
        this.selectedAdGroup.forEach((item) => {
          if (item) {
            text = text + item.title + ', ';
          }
        });
        text = text.slice(0, -2);
      }
      return text;
    },
    panelSelectionCount() {
      return this.selectedValues.length;
    },
    widgetTitle() {
      if (this.isUsingForAddNegativeKeyword) {
        return `add negative keywords (${this.panelSelectionCount} search terms selected)`;
      }
      return `add keywords (${this.panelSelectionCount} search terms selected)`;
    },
    applyButtonValidity() {
      var flag = false;
      if (this.customBids === '0') {
        return flag;
      }
      if (this.confirmCheckRequired === false) {
        flag =
          this.bidValue.length === 0 ||
          this.formValid === false ||
          this.selectedValues.length === 0;
      } else {
        if (this.valueConfirmationRequired === true) {
          flag =
            this.validationConfirmed === false ||
            this.bidValue.length === 0 ||
            this.formValid === false ||
            this.selectedValues.length === 0;
        } else {
          flag =
            this.bidValue.length === 0 ||
            this.formValid === false ||
            this.selectedValues.length === 0;
        }
      }
      return flag;
    },
    confirmMessage() {
      var type = this.computedActionPanelType.includes('searchTerms')
        ? 'keywords'
        : '';
      var message = `I understand and accept the big changes made to some of the ${type}.`;
      return message;
    },
    computedActionPanelType() {
      if (this.isRetailerIndependent) {
        return this.retailerIndependentConfigs.getActionPanelType(
          this.selectedCampaign
        );
      }
      return this.actionPanelType;
    }
  },
  watch: {
    selectedCampaign(newVal) {
      this.selectedAdGroup = [];
      this.adGroups = [];
      if (newVal?.adgroup_id) {
        this.adGroups = this.allCampaingsData.filter((item) => {
          if (item?.campaign_id === newVal?.campaign_id) {
            item.title = item.adgroup_name;
            item.selected = false;
            if (
              this.selectedValues.length === 1 &&
              item.adgroup_id === this.selectedValues[0]?.data?.adgroup_id
            ) {
              item.selected = true;
              this.selectedAdGroup = [item];
            }
            return item;
          }
        });
        if (this.selectedAdGroup.length === 0 && this.adGroups.length > 0) {
          this.adGroups[0].selected = true;
          this.selectedAdGroup = [this.adGroups[0]];
        }
      } else if (!newVal?.adgroup_id) {
        this.selectedAdGroup = [];
      }
      this.previouslySelectedAdGroupData = cloneDeep(this.adGroups);
    },
    bidsType: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.valueConfirmationRequired = false;
        this.validationConfirmed = false;
      }
      if (newVal === '0' && this.selectedValues.length === 1) {
        this.bidValue = this.selectedValues[0].data.bid;
      }
      if (newVal === '1') {
        this.dropdownOptions[0].title = 'Increase by percentage';
        this.dropdownOptions[1].title = 'Increase by absolute value';
      }
      if (newVal === '2') {
        this.dropdownOptions[0].title = 'Decrease by percentage';
        this.dropdownOptions[1].title = 'Decrease by absolute value';
      }
    },
    customBids(newVal) {
      if (newVal === '1') {
        if (this.selectedValues.length > 1) {
          this.changeRadio();
        } else {
          this.bidValue = this.selectedValues[0].data.bid || '';
        }
      } else {
        this.bidValue = '';
      }
    },
    selectedValues: function (newArr) {
      if (newArr.length) {
        this.selectDefaultCampaign(newArr);
        this.fetchCampaignList();
      }
    }
  },
  created() {
    this.fetchCampaignList();
    this.selectedOption = this.dropdownOptions[0];
    this.selectedMatchType = this.matchTypeOptions[0];
    this.bidValue = this.selectedValues[0].data.bid;
    this.retailer = this.$store.getters.getRetailer;
  },
  methods: {
    applyActionID() {
      return this.$route.path.indexOf('sp') > -1 ||
        this.$route.path.indexOf('sb') > -1
        ? `${this.$route.path.split('/')[7]}_${this.actionPanelType}_${
            this.selectedActionType
          }`
        : `${this.actionPanelType}_${this.selectedActionType}`;
    },
    clickOutside() {
      this.applyClicked = true;
      document.querySelector('.dummy-element').click();
    },
    onSelectAdGroup(context, data) {
      if (this.applyClicked) {
        this.selectedAdGroup = [];
        this.selectedAdGroup = data.filter((item) => item.selected);
        this.applyClicked = false;
        this.$nextTick(() => {
          this.previouslySelectedAdGroupData = cloneDeep(this.adGroups);
        });
      } else {
        this.$nextTick(() => {
          for (let i = 0; i < this.previouslySelectedAdGroupData.length; i++) {
            if (!this.previouslySelectedAdGroupData[i].selected) {
              delete this.adGroups[i].selected;
            } else {
              this.adGroups[i].selected = true;
            }
          }
        });
      }
    },
    selectionIsRemoved() {
      let removedSelection = false;
      this.selectedValues.forEach((value) => {
        if (!value.data.search_term) {
          value.setSelected(false);
          removedSelection = true;
        }
      });
      if (removedSelection) {
        this.$snackbar.open({
          message:
            'One or more search terms are null or blank and hence were deselected, please retry the action.',
          duration: 6000,
          buttonColor: '#f5d908',
          actionText: ''
        });
      }
      return removedSelection;
    },
    fetchCampaignList() {
      const that = this;
      this.loader = true;
      if (this.campaignEditConfig.boolCampaignEdit) {
        this.allCampaingsData = this.campaignEditConfig?.allCampaingsData;
        that.campaignDropdownOptions = uniqBy(
          this.allCampaingsData,
          'campaign_id'
        );
        this.selectedCampaign = this.allCampaingsData[0];
        this.loader = false;
      } else {
        let payload, action;
        if (this.isRetailerIndependent) {
          const APIData = cloneDeep(this.retailerIndependentConfigs.APIConfig);
          APIData.where.dimensionNameValueList =
            this.retailerIndependentConfigs.getWhereClause({
              selections: this.selectedValues
            });
          payload = APIData;
          action = 'getCampaignList';
        } else {
          const profiles = this.profileNames
            ? this.profileNames
            : this.selectedValues.map((item) => item.data.profile_id);
          payload = {
            profiles,
            dimensionKeyType: this.profileNames ? 'profile_name' : 'profile_id'
          };
          action = 'fetchCampaignList';
        }
        this.$store.dispatch(action, payload).then(
          (response) => {
            this.allCampaingsData = response;
            that.campaignDropdownOptions = uniqBy(response, 'campaign_id');
            that.selectDefaultCampaign(this.selectedValues);
            that.loader = false;
            that.$refs.new_bid && that.$refs.new_bid.focus();
          },
          () => {
            that.loader = false;
            that.$snackbar.open({
              message: 'Could not load campaign list. Please try again.',
              duration: 5000,
              actionText: ''
            });
          }
        );
      }
    },

    selectDefaultCampaign(selectionArray) {
      const elemIndex = this.campaignDropdownOptions.findIndex(
        (item) => item.campaign_id === 'SAME_AS_INPUT'
      );
      if (selectionArray.length > 1) {
        if (elemIndex === -1) {
          this.campaignDropdownOptions.unshift({
            campaign_name: 'Respective campaigns of selected search terms',
            campaign_id: 'SAME_AS_INPUT'
          });
        }
        this.selectedCampaign = this.campaignDropdownOptions[0];
      } else if (selectionArray.length === 1) {
        if (elemIndex > -1) {
          this.campaignDropdownOptions.splice(elemIndex, 1);
        }
        this.selectedCampaign = this.campaignDropdownOptions.filter((item) => {
          return (
            item.campaign_id.toString() === selectionArray[0].data.campaign_id
          );
        })[0] || { campaign_name: 'Select a campaign' }; // add a default placeholder when no campaign matches in the dropdown list
      }
    },

    takeAction(type) {
      var valObj = {
        type: this.bidsActions[this.bidsType],
        selectionType: this.selectedOption.value,
        value: this.bidValue
      };
      var objToPass = {
        actionType: type.textContent.trim().toLowerCase(),
        value: valObj
      };
      this.duplicateRowsPresent = false;
      this.duplicateErrorMessage = '';
      if (objToPass.actionType === 'apply') {
        this.callBidActionApi(objToPass);
      } else {
        this.$emit('closePanelMethod');
      }
    },
    changeRadio() {
      this.bidValue = '';
      this.$nextTick(() => {
        this.$refs.new_bid.focus();
      });
    },
    campaignSelected(context, selectedOption) {
      if (!selectedOption.length) return;
      this.selectedCampaign = selectedOption[0];
    },
    matchTypeSelected(context, selectedOption) {
      if (!selectedOption.length) return;
      this.selectedMatchType = selectedOption[0];
    },
    bidOptionSelected(context, selectedOption) {
      if (!selectedOption.length) return;
      this.selectedOption = selectedOption[0];
      this.bidValue = '';
    },

    checkForMaxBid(event, actionObject, key) {
      var inputVal = event.target.value;
      if (!inputVal) {
        return;
      }
      const value = Number(inputVal).toFixed(2);
      this.showError = false;

      var valObj = {
        type: this.bidsActions[this.bidsType],
        selectionType: this.selectedOption.value,
        value: this.bidValue
      };
      this.getPayload({ value: valObj });

      if (this.bidsType !== '0' && this.selectedOption.value === 'percent') {
        if (value > 0) {
          this.formValid = true;
        }
        return;
      }
      this.formValid = false;
      const maxBid =
        this.validationObj[this.computedActionPanelType].absolute.max;
      const minBid =
        this.validationObj[this.computedActionPanelType].absolute.min;
      if (
        minBid &&
        maxBid &&
        (value > maxBid || value < minBid) &&
        this.bidsType === '0'
      ) {
        this.errorMessage =
          'Absolute value should be between ' + minBid + ' to ' + maxBid;
        this.showError = true;
        return;
      } else if (minBid && value < minBid && this.bidsType === '0') {
        this.errorMessage = 'Absolute value should be greater than ' + minBid;
        this.showError = true;
        return;
      }
      this.errorMessage = '';
      this.formValid = true;
      valObj = {
        type: this.bidsActions[this.bidsType],
        selectionType: this.selectedOption.value,
        value: this.bidValue
      };
      this.getPayload({ value: valObj });
    },

    // initial payload object as per the panel type
    getInitialPayloadObject(data, actionType) {
      var obj = {
        actionPayload: {
          campaignId: data.campaign_id,
          profileId: data.profile_id,
          campaignType: data.campaign_type,
          adgroupId: data.adgroup_id,
          keywordText: data.search_term,
          matchType:
            data[this.actionMap[this.computedActionPanelType].matchTypeKey],
          ...(this.retailerIndependentConfigs?.addAdditionalFields?.(
            data,
            'actionPayload'
          ) || [])
        },
        viewPayload: {
          campaignName: null,
          campaignId: null,
          matchType: null,
          profileId: data.profile_id,
          campaignType: data.campaign_type,
          keywordText: data.search_term,
          ...(this.retailerIndependentConfigs?.addAdditionalFields?.(
            data,
            'viewPayload'
          ) || [])
        },
        actionType: this.actionMap[this.computedActionPanelType].actionName,
        actionSource: {
          pageUrl: window.location.href,
          primaryKey: `insights_searchTerm_${data.worklogger_primary_key}`
        }
      };
      var newBidKey = this.actionMap[this.computedActionPanelType].newBid;
      var prevBidKey = this.actionMap[this.computedActionPanelType].previousBid;
      var rowDataKey = this.actionMap[this.computedActionPanelType].bidKey;
      obj.actionPayload[prevBidKey] = data[rowDataKey];
      obj.actionPayload[newBidKey] = '';
      obj.viewPayload[prevBidKey] = data[rowDataKey];
      obj.viewPayload[newBidKey] = '';
      return obj;
    },
    checkForConfirmation(refObj, newBidKey, prevBidKey) {
      var obj = refObj.actionPayload;
      var prevVal = parseFloat(obj[prevBidKey]);
      var newVal = parseFloat(obj[newBidKey]);
      const currentBidAction = this.bidsActions[this.bidsType];
      if (this.floorCeilingValue.length > 0) {
        if (currentBidAction === 'increaseBids') {
          if (parseFloat(this.floorCeilingValue) < newVal) {
            refObj.actionPayload[newBidKey] = parseFloat(
              this.floorCeilingValue
            );
            refObj.viewPayload[newBidKey] = parseFloat(this.floorCeilingValue);
          }
        } else if (currentBidAction === 'decreaseBids') {
          if (parseFloat(this.floorCeilingValue) > newVal) {
            refObj.actionPayload[newBidKey] = parseFloat(
              this.floorCeilingValue
            );
            refObj.viewPayload[newBidKey] = parseFloat(this.floorCeilingValue);
          }
        }
      }
      if (newVal >= 2 * prevVal) {
        this.valueConfirmationRequired = true;
      } else {
        this.valueConfirmationRequired = false;
        this.validationConfirmed = false;
      }
      if (isNaN(newVal)) {
        refObj.actionPayload[newBidKey] = parseFloat(prevVal);
      }
    },

    // updates the payload with updated values as per required calculations for bid/budget change
    processPayloadForBidChange(params, payloadObjRef, rowData, adGroup) {
      // process campaign
      if (this.selectedCampaign.campaign_id === 'SAME_AS_INPUT') {
        payloadObjRef.actionPayload.campaignId = rowData.campaign_id.toString();
        payloadObjRef.viewPayload.campaignId = rowData.campaign_id.toString();
        payloadObjRef.viewPayload.campaignName = rowData.campaign_name;
        payloadObjRef.viewPayload.adgroupId =
          rowData.adgroup_id ?? rowData.ad_group_id;
        payloadObjRef.viewPayload.adgroupName = rowData.adgroup_name;
        payloadObjRef.actionPayload.adgroupId =
          rowData.adgroup_id ?? rowData.ad_group_id;
      } else {
        payloadObjRef.actionPayload.campaignId =
          this.selectedCampaign.campaign_id.toString();
        payloadObjRef.actionPayload.profileId =
          this.selectedCampaign.profile_id;
        payloadObjRef.viewPayload.campaignId =
          this.selectedCampaign.campaign_id.toString();
        payloadObjRef.viewPayload.profileId = this.selectedCampaign.profile_id;
        payloadObjRef.viewPayload.campaignName =
          this.selectedCampaign.campaign_name;
        payloadObjRef.viewPayload.adgroupId = adGroup
          ? adGroup.adgroup_id
          : this.selectedCampaign.adgroup_id;
        payloadObjRef.viewPayload.adgroupName = adGroup
          ? adGroup.adgroup_name
          : this.selectedCampaign.adgroup_name;
        payloadObjRef.actionPayload.adgroupId = adGroup
          ? adGroup.adgroup_id
          : this.selectedCampaign.adgroup_id;
      }

      // process match type
      payloadObjRef.actionPayload.matchType =
        this.selectedMatchType.title.toLowerCase();
      payloadObjRef.viewPayload.matchType =
        this.selectedMatchType.title.toLowerCase();

      // process bid value
      var newBidKey = this.actionMap[this.computedActionPanelType].newBid;
      var prevBidKey = this.actionMap[this.computedActionPanelType].previousBid;
      var rowDataKey = this.actionMap[this.computedActionPanelType].bidKey;
      if (
        params.value.type === 'increaseBids' ||
        params.value.type === 'decreaseBids'
      ) {
        var mathsFunc = params.value.type === 'increaseBids' ? 'min' : 'max';
        var limit =
          params.value.type === 'increaseBids'
            ? this.validationObj[this.computedActionPanelType].absolute.max ||
              1000
            : this.validationObj[this.computedActionPanelType].absolute.min;
        const newBidVal = Math[mathsFunc](
          commons.getBidValue(params, rowData[rowDataKey]),
          limit
        );
        payloadObjRef.actionPayload[newBidKey] = newBidVal;
        payloadObjRef.viewPayload[newBidKey] = newBidVal;
      } else {
        const value = parseFloat(params.value.value).toFixed(2);
        payloadObjRef.actionPayload[prevBidKey] = rowData[rowDataKey];
        payloadObjRef.actionPayload[newBidKey] = value;
        payloadObjRef.viewPayload[prevBidKey] = rowData[rowDataKey];
        payloadObjRef.viewPayload[newBidKey] = value;
      }
      if (this.customBids === '1') {
        this.checkForConfirmation(payloadObjRef, newBidKey, prevBidKey);
      } else if (this.customBids === '0') {
        payloadObjRef.actionPayload[prevBidKey] = rowData[rowDataKey];
        payloadObjRef.actionPayload[newBidKey] = rowData[rowDataKey];
        payloadObjRef.viewPayload[prevBidKey] = rowData[rowDataKey];
        payloadObjRef.viewPayload[newBidKey] = rowData[rowDataKey];
      }
    },

    // function that creates the payload which is used in API
    getPayload(params) {
      var paramsToReturn = [];
      for (let i = 0; i < this.selectedValues.length; i++) {
        var data = this.selectedValues[i].data;
        if (this.selectedAdGroup.length > 0) {
          for (const adGroup of this.selectedAdGroup) {
            var payloadObj = this.getInitialPayloadObject(data);
            this.processPayloadForBidChange(params, payloadObj, data, adGroup);
            paramsToReturn.push(payloadObj);
          }
        } else {
          var payloadObj = this.getInitialPayloadObject(data);
          this.processPayloadForBidChange(params, payloadObj, data);
          paramsToReturn.push(payloadObj);
        }
      }
      return paramsToReturn;
    },

    selectedCampaignIsValid() {
      let selectedCampaignIsValid = true;
      if (!this.selectedCampaign.campaign_id) {
        selectedCampaignIsValid = false;
      }
      if (!selectedCampaignIsValid) {
        this.$snackbar.open({
          message: 'Please select a valid campaign.',
          duration: 6000,
          buttonColor: '#f5d908',
          actionText: ''
        });
      }
      return selectedCampaignIsValid;
    },

    removeCampaignDuplicates() {
      const selectedCampaignId = this.selectedCampaign.campaign_id;
      const removedDuplicates = [];
      this.selectedValues.forEach((node) => {
        if (node.data) {
          if (selectedCampaignId !== 'SAME_AS_INPUT') {
            if (
              node.data.is_search_term_present_as_keyword_in_campaign &&
              node.data[
                this.actionMap[this.computedActionPanelType].matchTypeKey
              ] === 'EXACT' &&
              selectedCampaignId.toString() === node.data.campaign_id
            ) {
              removedDuplicates.push(node.data.keyword_text);
            }
          } else {
            if (
              node.data.is_search_term_present_as_keyword_in_campaign &&
              node.data[
                this.actionMap[this.computedActionPanelType].matchTypeKey
              ] === 'EXACT'
            ) {
              removedDuplicates.push(node.data.keyword_text);
            }
          }
        }
      });
      return removedDuplicates;
    },

    isSeachTermTargettedExpression() {
      let isSeachTermTargettedExpression = false;
      if (this.selectedValues.length) {
        this.selectedValues.forEach((node) => {
          if (
            node.data[
              this.actionMap[this.computedActionPanelType].matchTypeKey
            ] === 'TARGETING_EXPRESSION'
          ) {
            isSeachTermTargettedExpression = true;
          }
        });
      }
      return isSeachTermTargettedExpression;
    },

    // API call
    callBidActionApi(params) {
      if (!this.selectedCampaignIsValid()) {
        return;
      }
      if (this.selectionIsRemoved()) {
        return;
      }
      if (this.isSeachTermTargettedExpression()) {
        this.duplicateRowsPresent = true;
        this.duplicateErrorMessage =
          "Some of the search terms are of 'TARGETING_EXPRESSION' match type, please remove those search terms and try again.";
        return;
      }
      if (this.removeCampaignDuplicates().length > 0) {
        this.duplicateRowsPresent = true;
        const message = this.removeCampaignDuplicates().join(', ');
        this.duplicateErrorMessage =
          'Following search terms already exist as exact match, hence no action taken. <div style="color:#000;padding-top:16px;line-height:1.29">' +
          message +
          '</div>';
        return;
      }
      var that = this;
      var payload = this.getPayload(params);
      var othersObj = {
        loader: that.loader,
        successMessage:
          'Action has been submitted for the selected search term(s).',
        errorMessage:
          'Something went wrong while performing the action. Please try again!',
        successFn: () => {
          // that.parentRef.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.loader = false;
          this.$emit('afterAPIActionToCall');
        }
      };
      this.loader = true;
      if (
        this.campaignEditConfig.boolCampaignEdit ||
        this.isRetailerIndependent
      ) {
        this.$emit('callApiFromCampaignEdit', {
          selections: payload,
          action: 'addAsKeyword',
          vueRef: this
        });
      } else {
        commons.bidChangeApiAction.call(this, params, payload, othersObj);
      }
    }
  }
};
</script>

<style lang="css" scoped>
.addSearchTermAsKeyword {
  position: relative;
}
.addSearchTermAsKeyword .radio-holder .left-panel {
  border-right: 1px solid #e9eaeb;
}
.error-section {
  border-left: 1px solid #e9eaeb;
}

.error-border {
  border: 1px solid #d7263d !important;
}

.valueDropdown {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
}

.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  max-width: 90px;
  width: 100%;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input.currency-input-right {
  border-left: 0;
  padding-right: 4px;
  height: 32px;
}
.rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 32px;
}

.rb-input::-webkit-input-placeholder {
  color: #caccce;
}
.rb-input::-moz-placeholder {
  color: #caccce;
}
.rb-input:-ms-input-placeholder {
  color: #caccce;
}
.rb-input:-moz-placeholder {
  color: #caccce;
}

.percentBox .rb-input {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.percentBox .rb-input.currency-input-right {
  border-left: 0;
  padding-left: 5px;
}
.percentBox .rb-input.currency-input {
  border-right: 0;
}

.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.appender .currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
}

.u-width-140px {
  width: 140px !important;
}

.u-width-150px {
  width: 150px !important;
}
</style>
