import { state, mutations, getters } from './mutations';
import { actions } from './actions';

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
