<template>
  <div class="u-width-100">
    <p
      v-if="typeIndicator"
      class="u-font-size-7 u-spacing-mb-s u-text-case-upper u-color-grey-base u-font-weight-600"
    >
      {{ typeIndicator }}
    </p>
    <rb-select
      v-if="params.data.client_flag === 'client'"
      :send-details="true"
      :class-name="'tableSelect'"
      :on-close="clientOnClose"
      :options="clientOptions"
    >
      <div
        slot="trigger"
        class="u-display-flex u-flex-align-items-center u-cursor-pointer"
      >
        <div class="u-display-flex u-flex-align-items-center u-width-100">
          <span
            :title="params.data[params.colDef.field]"
            class="u-font-size-5 u-spacing-ml-s u-text-overflow-ellipsis u-color-grey-base"
            >{{ params.data[params.colDef.field] }}</span
          >
          <rb-icon
            class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
            :icon="'caret-down'"
          />
        </div>
      </div>
      <template
        slot="item"
        slot-scope="option"
      >
        <div>{{ option.brand }}</div>
      </template>
    </rb-select>

    <rb-select
      v-if="params.data.client_flag === 'comp' && compOptions.length > 5"
      :send-details="true"
      :class-name="'tableSelect'"
      :on-close="compOnClose"
      :options="compOptions"
    >
      <div
        slot="trigger"
        class="u-display-flex u-flex-align-items-center u-cursor-pointer"
      >
        <div class="u-display-flex u-flex-align-items-center u-width-100">
          <span
            :title="params.data[params.colDef.field]"
            class="u-font-size-5 u-spacing-ml-s u-text-overflow-ellipsis u-color-grey-base"
            >{{ params.data[params.colDef.field] }}</span
          >
          <rb-icon
            class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
            :icon="'caret-down'"
          />
        </div>
      </div>
      <template
        slot="item"
        slot-scope="option"
      >
        <div>{{ option.brand }}</div>
      </template>
    </rb-select>
    <p
      v-if="params.data.client_flag === 'comp' && compOptions.length <= 5"
      :title="params.data[params.colDef.field]"
      class="u-font-size-5 u-spacing-ml-s u-text-overflow-ellipsis u-color-grey-base"
    >
      {{ params.data[params.colDef.field] }}
    </p>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'tableSelect',
  data() {
    return {
      typeIndicator: null
    };
  },
  computed: {
    clientOptions() {
      return this.params.colDef.cellRendererParams.clientOptions;
    },
    compOptions() {
      return this.params.colDef.cellRendererParams.compOptions;
    }
  },
  created() {
    if (
      this.params.rowIndex === 0 &&
      this.params.data.client_flag === 'client'
    ) {
      this.typeIndicator = 'Your brands';
      this.params.node.setRowHeight(60);
    } else if (
      this.params.rowIndex === 1 &&
      this.params.data.client_flag === 'comp'
    ) {
      this.typeIndicator = 'Comp brands';
      this.params.node.setRowHeight(60);
      setTimeout(() => {
        this.params.api.onRowHeightChanged();
      }, 0);
    }
  },
  methods: {
    clientOnClose(context, selection) {
      if (this.params.colDef.cellRendererParams.clientOnClose) {
        this.params.colDef.cellRendererParams.clientOnClose(
          context,
          selection,
          this.params.rowIndex
        );
      }
    },
    compOnClose(context, selection) {
      if (this.params.colDef.cellRendererParams.compOnClose) {
        this.params.colDef.cellRendererParams.compOnClose(
          context,
          selection,
          this.params.rowIndex
        );
      }
    }
  }
};
</script>

<style lang="css">
.tableSelect .dropdown-content {
  max-height: 300px;
}
</style>
