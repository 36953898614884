<template>
  <section class="campaign-creation-setup u-position-fixed widget--full-screen">
    <h1>Manual campaigns</h1>
  </section>
</template>

<script>
import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';
import config from '@/components/ams/campaign_creation/setup_configs/index.js';

export default {
  computed: {
    retailer() {
      return this.$store.getters.getRetailer;
    }
  },
  created() {
    const suggestedCampaignId = Math.floor(Math.random() * 90000) + 10000;
    const suggestedCampaigns = [
      {
        skus: [],
        targets: [],
        own: true,
        spTargetingType: 'manual',
        negativeTargets: [],
        biddingStrategy: 'legacyForSales',
        startDate: moment().format('YYYYMMDD'),
        budget: 100,
        profile: null,
        portfolio: null,
        targetingType: 'keyword', // defaultRadioButtonSelection
        suggestedCampaignId: suggestedCampaignId,
        suggestedCampaignName: `Your campaign | ${this.campaignDate()}`,
        campaignType: 'createOwnCampaignSP',
        adgroupBid: 1,
        singleCampaignFlow: true
      }
    ];
    const stepsConfig = cloneDeep(
      config?.[`${this.retailer}_manual`]?.stepsConfig
    );
    this.$store.dispatch('campaignCreation/updateAllSetpsData', {
      suggestedCampaigns,
      stepsConfig
    });
    this.$router.push({
      name: 'edit_suggested_campaign',
      query: {
        key: `${suggestedCampaignId}-${0}`,
        own: true,
        manual: true
      }
    });
  },
  methods: {
    campaignDate() {
      const date = moment.utc().format('YYYY-MM-DD HH:mm:ss');
      const stillUtc = moment.utc(date).toDate();
      return moment(stillUtc).local().format('MMMM Do YY, HH:mm:ss');
    }
  }
};
</script>
