// @ts-ignore
import HttpService from '@/utils/services/http-service';
import { AxiosResponse } from 'axios';
import {
  asinLevelV2ConfigEnabled,
  isMarketShareLite,
  constants,
  categoryConstants,
  overviewConstants
} from '@/components/pages/insights/amazon/market-share/utils';

export interface MetaDataReqBody {
  page: string;
  widget: string;
  pageId: number;
  globalViewId: number;
}

export interface MetaDataReadApi {
  widgetId: number;
  name: string;
  label: string;
  description: any;
  metrics: Record<string, Metric>;
  events: Events;
  eventCalendar: any;
  calendar: Calendar;
  downloadApiTemplate: DownloadTemplate;
  cacheInfo: CacheInfo;
  customMetadata: any;
  metadata: Metadata5;
}

// export interface Metrics {
//   SALES_ESTIMATES: SalesEstimates;
//   UNIT_ESTIMATES: UnitEstimates;
//   SALES_ESTIMATES_SIZE: SalesEstimatesSize;
//   UNIT_ESTIMATES_SIZE: UnitEstimatesSize;
//   SALES_ESTIMATES_SHARE_PERCENT: SalesEstimatesSharePercent;
//   UNIT_ESTIMATES_SHARE_PERCENT: UnitEstimatesSharePercent;
// }

export interface UnitEstimatesSharePercent {
  name: string;
  label: string;
  tooltip: string;
  iqTooltip: {};
  enableIqTooltip: boolean;
  priority: number;
  dataGroup: string;
  srcType: string;
  keyName: string;
  source: Source6;
  views: View6[];
  api: Api6;
  registryId: number;
  metricGroup: string;
  alias: string;
  entityList: any;
  configurable: boolean;
  metadata: Metadata6;
}

export interface Source6 {
  cubeName: string;
  sourceKey: string;
}

export interface View6 {
  label: string;
  entityType: string;
  selected: boolean;
  view: string;
}

export interface Api6 {
  request: Request6;
  service: string;
  endPoint: string;
}

export interface Request6 {
  page: string;
  widget: string;
  entityType: string;
  metricsList: string[];
  dimensionsList: string[];
  eventsList: any[];
  enablePaginationCount: boolean;
  operations: Operations6;
  where: Where6;
}

export interface Operations6 {
  enableNewPVPFormulaForSOV: boolean;
  getSharePercentage: boolean;
  getSharePercentageV2: boolean;
  timeseriesEnabled: boolean;
  timeseriesDimension: string;
  pvpenabled: boolean;
  enableDedupBeforeRollup: boolean;
  timeseriesRollupBy: string;
  additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: string[];
  orderByList: OrderByList4[];
  limit: number;
}

export interface OrderByList4 {
  dimension: string;
  direction: string;
}

export interface Where6 {
  dimensionNameValueList: any[];
  date: Date6;
  pvpDate: PvpDate6;
  excludeDimensionsFromSharePercentage: any[];
}

export interface Date6 {
  from: string;
  to: string;
}

export interface PvpDate6 {
  from: string;
  to: string;
}

export interface Metadata6 {
  isInverted: boolean;
  type: string;
  unit: string;
}

export interface Metric {
  name: string;
  label: string;
  tooltip: string;
  iqTooltip: any;
  enableIqTooltip: boolean;
  priority: number;
  dataGroup: string;
  srcType: string;
  keyName: string;
  source: Source;
  views: View[];
  api: Api;
  registryId: number;
  metricGroup: string;
  alias: string;
  entityList: any;
  configurable: boolean;
  metadata: Metadata;
}

export interface Source {
  cubeName: string;
  sourceKey: string;
}

export interface View {
  label: string;
  entityType: string;
  selected: boolean;
  view: string;
}

export interface Api {
  request: Request;
  service: string;
  endPoint: string;
}

export interface Request {
  page: string;
  widget: string;
  entityType: string;
  metricsList: string[];
  dimensionsList: string[];
  eventsList: any[];
  enablePaginationCount: boolean;
  operations: Operations;
  where: Where;
}

export interface Operations {
  enableNewPVPFormulaForSOV: boolean;
  getSharePercentage: boolean;
  getSharePercentageV2: boolean;
  timeseriesEnabled: boolean;
  timeseriesDimension: string;
  pvpenabled: boolean;
  enableDedupBeforeRollup: boolean;
  timeseriesRollupBy: string;
  additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: string[];
  orderByList: OrderByList[];
  limit: number;
  max_date?: string;
  min_date?: string;
}

export interface OrderByList {
  dimension: string;
  direction: string;
}

export interface Where {
  dimensionNameValueList: any[];
  date: Date;
  pvpDate: PvpDate;
  excludeDimensionsFromSharePercentage?: any[];
}

export interface Date {
  from: string;
  to: string;
  name?: string;
  page_wise_min_max_key?: string;
}

export interface PvpDate {
  from: string;
  to: string;
}

export interface Metadata {
  isInverted: boolean;
  type: string;
  unit: string;
  visible: boolean;
}

export interface UnitEstimates {
  name: string;
  label: string;
  tooltip: string;
  iqTooltip: any;
  enableIqTooltip: boolean;
  priority: number;
  dataGroup: string;
  srcType: string;
  keyName: string;
  source: Source2;
  views: View2[];
  api: Api2;
  registryId: number;
  metricGroup: string;
  alias: string;
  entityList: any;
  configurable: boolean;
  metadata: Metadata2;
}

export interface Source2 {
  cubeName: string;
  sourceKey: string;
}

export interface View2 {
  label: string;
  entityType: string;
  selected: boolean;
  view: string;
}

export interface Api2 {
  request: Request2;
  service: string;
  endPoint: string;
}

export interface Request2 {
  page: string;
  widget: string;
  entityType: string;
  metricsList: string[];
  dimensionsList: string[];
  eventsList: any[];
  enablePaginationCount: boolean;
  operations: Operations2;
  where: Where2;
}

export interface Operations2 {
  enableNewPVPFormulaForSOV: boolean;
  getSharePercentage: boolean;
  getSharePercentageV2: boolean;
  timeseriesEnabled: boolean;
  timeseriesDimension: string;
  pvpenabled: boolean;
  enableDedupBeforeRollup: boolean;
  timeseriesRollupBy: string;
  additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: string[];
  orderByList: OrderByList2[];
  limit: number;
}

export interface OrderByList2 {
  dimension: string;
  direction: string;
}

export interface Where2 {
  dimensionNameValueList: any[];
  date: Date2;
  pvpDate: PvpDate2;
  excludeDimensionsFromSharePercentage: any[];
}

export interface Date2 {
  from: string;
  to: string;
}

export interface PvpDate2 {
  from: string;
  to: string;
}

export interface Metadata2 {
  isInverted: boolean;
  type: string;
  unit: string;
}

export interface SalesEstimatesSharePercent {
  name: string;
  label: string;
  tooltip: string;
  iqTooltip: {};
  enableIqTooltip: boolean;
  priority: number;
  dataGroup: string;
  srcType: string;
  keyName: string;
  source: Source5;
  views: View5[];
  api: Api5;
  registryId: number;
  metricGroup: string;
  alias: string;
  entityList: any;
  configurable: boolean;
  metadata: Metadata5;
}

export interface Source5 {
  cubeName: string;
  sourceKey: string;
}

export interface View5 {
  label: string;
  entityType: string;
  selected: boolean;
  view: string;
}

export interface Api5 {
  request: Request5;
  service: string;
  endPoint: string;
}

export interface Request5 {
  page: string;
  widget: string;
  entityType: string;
  metricsList: string[];
  dimensionsList: string[];
  eventsList: any[];
  enablePaginationCount: boolean;
  operations: Operations5;
  where: Where5;
}

export interface Operations5 {
  enableNewPVPFormulaForSOV: boolean;
  getSharePercentage: boolean;
  getSharePercentageV2: boolean;
  timeseriesEnabled: boolean;
  timeseriesDimension: string;
  pvpenabled: boolean;
  enableDedupBeforeRollup: boolean;
  timeseriesRollupBy: string;
  additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: string[];
  orderByList: OrderByList3[];
  limit: number;
}

export interface OrderByList3 {
  dimension: string;
  direction: string;
}

export interface Where5 {
  dimensionNameValueList: any[];
  date: Date5;
  pvpDate: PvpDate5;
  excludeDimensionsFromSharePercentage: any[];
}

export interface Date5 {
  from: string;
  to: string;
}

export interface PvpDate5 {
  from: string;
  to: string;
}

export interface Metadata5 {
  downloadMetricsList: any;
  isInverted: boolean;
  type: string;
  unit: string;
  visible: boolean;
  defaultMetrics: string[];
  defaultHiddenMetrics: string[];
}

export interface SalesEstimatesSize {
  name: string;
  label: string;
  tooltip: string;
  iqTooltip: any;
  enableIqTooltip: boolean;
  priority: number;
  dataGroup: string;
  srcType: string;
  keyName: string;
  source: Source3;
  views: View3[];
  api: Api3;
  registryId: number;
  metricGroup: string;
  alias: string;
  entityList: any;
  configurable: boolean;
  metadata: Metadata3;
}

export interface Source3 {
  cubeName: string;
  sourceKey: string;
}

export interface View3 {
  label: string;
  entityType: string;
  selected: boolean;
  view: string;
}

export interface Api3 {
  request: Request3;
  service: string;
  endPoint: string;
}

export interface Request3 {
  page: string;
  widget: string;
  entityType: string;
  metricsList: string[];
  dimensionsList: any[];
  eventsList: any[];
  enablePaginationCount: boolean;
  operations: Operations3;
  where: Where3;
}

export interface Operations3 {
  enableNewPVPFormulaForSOV: boolean;
  getSharePercentage: boolean;
  getSharePercentageV2: boolean;
  timeseriesEnabled: boolean;
  timeseriesDimension: string;
  pvpenabled: boolean;
  enableDedupBeforeRollup: boolean;
  timeseriesRollupBy: string;
  additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: string[];
  orderByList: any[];
}

export interface Where3 {
  dimensionNameValueList: any[];
  date: Date3;
  pvpDate: PvpDate3;
  excludeDimensionsFromSharePercentage: any[];
}

export interface Date3 {
  from: string;
  to: string;
}

export interface PvpDate3 {
  from: string;
  to: string;
}

export interface Metadata3 {
  isInverted: boolean;
  type: string;
  unit: string;
  visible: boolean;
}

export interface UnitEstimatesSize {
  name: string;
  label: string;
  tooltip: string;
  iqTooltip: any;
  enableIqTooltip: boolean;
  priority: number;
  dataGroup: string;
  srcType: string;
  keyName: string;
  source: Source4;
  views: View4[];
  api: Api4;
  registryId: number;
  metricGroup: string;
  alias: string;
  entityList: any;
  configurable: boolean;
  metadata: Metadata4;
}

export interface Source4 {
  cubeName: string;
  sourceKey: string;
}

export interface View4 {
  label: string;
  entityType: string;
  selected: boolean;
  view: string;
}

export interface Api4 {
  request: Request4;
  service: string;
  endPoint: string;
}

export interface Request4 {
  page: string;
  widget: string;
  entityType: string;
  metricsList: string[];
  dimensionsList: any[];
  eventsList: any[];
  enablePaginationCount: boolean;
  operations: Operations4;
  where: Where4;
}

export interface Operations4 {
  enableNewPVPFormulaForSOV: boolean;
  getSharePercentage: boolean;
  getSharePercentageV2: boolean;
  timeseriesEnabled: boolean;
  timeseriesDimension: string;
  pvpenabled: boolean;
  enableDedupBeforeRollup: boolean;
  timeseriesRollupBy: string;
  additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: string[];
  orderByList: any[];
}

export interface Where4 {
  dimensionNameValueList: any[];
  date: Date4;
  pvpDate: PvpDate4;
  excludeDimensionsFromSharePercentage: any[];
}

export interface Date4 {
  from: string;
  to: string;
}

export interface PvpDate4 {
  from: string;
  to: string;
}

export interface Metadata4 {
  isInverted: boolean;
  type: string;
  unit: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Events {}

export interface Calendar {
  min_date: string;
  max_date: string;
}

export interface CacheInfo {
  cacheStatus: string;
  hash: string;
}

export interface DownloadTemplate {
  page: string;
  widget: string;
  entityType: string;
  metricsList: [] | any[];
  dimensionsList: any[];
  eventsList: any[];
  enablePaginationCount: boolean;
  operations: Operations;
  where: Where;
  endPoint?: string;
  request?: any;
  service?: string;
}

export interface Metadata5 {
  downloadMetricsList: any;
  dataAPI: DataApi;
  marketShareMetric: Record<
    string,
    {
      include3p: string;
      exclude3p: string;
      exclude3pclient: string;
      only3p: string;
    }
  >;
  marketShareLiteMetric: Record<
    string,
    {
      include3p: string;
      exclude3p: string;
      exclude3pclient: string;
      only3p: string;
    }
  >;
  asp: {
    metricList: {
      include3p: 'marketshare_asp_v2';
      exclude3p: 'marketshare_asp_exclude_3P_v2';
      exclude3pclient: 'marketshare_asp_exclude_3P_v2';
      only3p: 'marketshare_asp_only_3p_v2';
    };
    dimensionMapping: {
      include3p: 'asp';
      exclude3p: 'asp_exclude_3P';
      exclude3pclient: 'asp_exclude_3P';
      only3p: 'asp_only_3p';
    };
  };
  sales_estimates: {
    metricList: {
      include3p: 'EV_SALES_ESTIMATES_V2';
      exclude3p: 'EV_SALES_ESTIMATES_EXCLUDE_3P_V2';
      exclude3pclient: 'EV_SALES_ESTIMATES_EXCLUDE_3P_V2';
      only3p: 'EV_SALES_ESTIMATES_ONLY_3P_V2';
    };
    dimensionMapping: {
      include3p: 'EV_SALES_ESTIMATES';
      exclude3p: 'EV_SALES_ESTIMATES_EXCLUDE_3P';
      exclude3pclient: 'EV_SALES_ESTIMATES_EXCLUDE_3P';
      only3p: 'EV_SALES_ESTIMATES_ONLY_3P_V2';
    };
  };
  total_sales_estimates: {
    metricList: {
      include3p: string;
      exclude3p: string;
      exclude3pclient: string;
      only3p: string;
    };
    dimensionMapping: {
      include3p: 'EV_TOTAL_SALES_ESTIMATES';
      exclude3p: 'EV_TOTAL_SALES_ESTIMATES_EXCLUDE_3P';
      exclude3pclient: 'EV_TOTAL_SALES_ESTIMATES_EXCLUDE_3P';
      only3p: 'EV_TOTAL_SALES_ESTIMATES_ONLY_3P_V2';
    };
  };
  unit_estimates: {
    metricList: {
      include3p: string;
      exclude3p: string;
      exclude3pclient: string;
      only3p: string;
    };
    dimensionMapping: {
      include3p: string;
      exclude3p: string;
      exclude3pclient: string;
      only3p: string;
    };
  };
  total_unit_estimates: {
    metricList: {
      include3p: string;
      exclude3p: string;
      exclude3pclient: string;
      only3p: string;
    };
    dimensionMapping: {
      include3p: string;
      exclude3p: string;
      exclude3pclient: string;
      only3p: string;
    };
  };
  msIndependentDataGroups: string[];
  salesMetrics: string[];
  unitMetrics: string[];
}

export interface DataApi {
  service: string;
  endPoint: string;
  request: Request5;
}
export abstract class MetaDataService {
  abstract widgetName: string;
  async getData(
    pageId: number,
    globalViewId: number
  ): Promise<AxiosResponse<MetaDataReadApi>> {
    const requestBody = await this.generateMetaDataReqBody(
      pageId,
      globalViewId
    );
    return await HttpService.post('DASHBOARD_SERVICE', requestBody, {
      append: '/widget/metadata/read'
    });
  }

  abstract generateMetaDataReqBody(
    pageId: number,
    globalViewId: number
  ): Promise<MetaDataReqBody>;
}

export class PieChartMarketShareCategoryPageMetaDataService extends MetaDataService {
  widgetName = isMarketShareLite()
    ? constants.mslPieCharWidgetName
    : constants.pieCharWidgetName;
  constructor() {
    super();
  }

  async generateMetaDataReqBody(
    pageId: number,
    globalViewId: number
  ): Promise<MetaDataReqBody> {
    return {
      page: isMarketShareLite()
        ? categoryConstants.mslPage
        : categoryConstants.page,
      widget:
        (isMarketShareLite()
          ? constants.mslPieCharWidgetName
          : constants.pieCharWidgetName) +
        (asinLevelV2ConfigEnabled() ? '_v2' : ''),
      globalViewId: globalViewId,
      pageId: pageId
    };
  }
}

export class BenchmarkMarketShareExecutivePageService extends MetaDataService {
  widgetName = isMarketShareLite()
    ? constants.mslBenchmarkingWidgetName
    : constants.benchmarkingWidgetName;
  constructor() {
    super();
  }

  async generateMetaDataReqBody(
    pageId: number,
    globalViewId: number
  ): Promise<MetaDataReqBody> {
    return {
      page: isMarketShareLite()
        ? overviewConstants.mslPage
        : overviewConstants.page,
      widget:
        (isMarketShareLite()
          ? constants.mslBenchmarkingWidgetName
          : constants.benchmarkingWidgetName) +
        (asinLevelV2ConfigEnabled() ? '_v2' : ''),
      globalViewId: globalViewId,
      pageId: pageId
    };
  }
}
