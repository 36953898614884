<template>
  <div class="u-display-flex u-height-100 u-width-100">
    <div
      v-for="(value, index) in values"
      :key="index"
      class="u-width-100 u-height-100 u-display-flex u-flex-align-items-center u-flex-justify-content-center"
      :class="
        index > 0
          ? 'u-border-color-grey-xxx-light u-border-left u-border-width-s'
          : ''
      "
    >
      <AdjustPercentage
        v-if="showEditIcon"
        :use-just-percentage="true"
        :row-index="rowIndex"
        table-id="additional-inputs"
        :col-id="value.id"
        :value="{ value: value.value }"
        :show-error="value.showError"
        :allow-negative="value.allowNegative"
        @updateRow="updateRow"
      />
      <span
        v-else
        v-tippy="tippy"
        :title="value.tooltip"
        :class="
          useAsHeaderLabels
            ? 'u-color-grey-lighter u-font-weight-600'
            : 'u-color-grey-base'
        "
      >
        {{ value.title }}
      </span>
    </div>
  </div>
</template>

<script>
import AdjustPercentage from '@/components/ams/media-planner/generate-plan/steps/goals/adjust-percentage.vue';
export default {
  components: {
    AdjustPercentage
  },
  props: {
    values: {
      type: Array,
      default: () => {
        return [];
      }
    },
    showEditIcon: {
      type: Boolean,
      default: false
    },
    rowIndex: {
      type: Number,
      default: 0
    },
    useAsHeaderLabels: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tippy: {
        placement: 'top',
        arrow: true,
        distance: -10,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      }
    };
  },
  methods: {
    updateRow(...args) {
      this.$emit('updateRow', ...args);
    }
  }
};
</script>
