<template>
  <div
    class="overview_content"
    data-cy="overview_content"
  >
    <widgetContainer
      :header-options="headerOptionsComp"
      :is-loading="isLoading"
      :footer-options="footerOptionsComp"
      :last-updated-date="lastUpdatedDate"
    >
      <div
        v-if="!isLoading"
        slot="body"
      >
        <div class="u-display-flex">
          <span
            class="u-font-size-3 u-font-weight-600 u-spacing-mb-l u-display-inline-block"
            >Overall content score and SKU's split</span
          >
          <rb-tooltip-header
            id="tooltipTemplate"
            :title="tooltipTitle"
          >
            <!-- eslint-disable -->
            <div
              slot="tooltip-message"
              class="u-spacing-mt-m tooltip-message"
              v-html="overallTooltipText"
            />
          </rb-tooltip-header>
          <rb-icon
            v-tippy="setTooltip.tippyOptions"
            class="u-color-grey-lighter u-display-inline-block u-spacing-mb-l u-spacing-ml-xs u-cursor-pointer rb-icon--medium"
            icon="help-fill"
          />
        </div>
        <BarChart
          data-cy="overall-content"
          :sub-label="overAllBarChartLabel"
          :data="barData"
          :show-grey-bar="false"
          :legends-label="`SKU's Split:`"
        />
        <span
          class="metrics-container-label u-font-size-3 u-font-weight-600 u-spacing-mt-xl u-spacing-mb-s u-display-inline-block"
          >Percentage of SKUs not meeting individual requirement</span
        >
        <div class="metrics_container u-spacing-mb-l u-width-100">
          <content-metric-card
            v-for="(card, index) in visibleCards"
            :key="index"
            :card-data="card"
            :sub-label="metricCardSubLabel(card)"
            :dropdown-options="dropdownOptions"
            @dropdownClose="dropdownClose"
          >
            <div
              slot="footer"
              class="view-sku u-display-flex u-spacing-mt-m u-cursor-pointer"
              @click="viewSku(card)"
            >
              <rb-icon
                class="icon-view-sku u-display-flex u-color-grey-lighter rb-icon--medium u-spacing-mr-s"
                :icon="'table-chart'"
              />
              <span
                class="view-sku-text u-font-weight-600 u-font-size-5 u-color-grey-lighter"
                >View SKUs</span
              >
            </div>
          </content-metric-card>
        </div>
      </div>
    </widgetContainer>
  </div>
</template>

<script>
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import rightPanel from '@/components/basic/customRightPanel.vue';
import BarChart from '@/components/basic/barChart.vue';
import ContentMetricCard from './contentMetricCard.vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import overviewConfig from './overviewConfig';
import Vue from 'vue';
import { formatter } from '@/utils/helpers/formatter.js';
import { isEqual } from 'lodash';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default {
  components: {
    BarChart,
    ContentMetricCard,
    widgetContainer,
    rightPanel
  },
  mixins: [cwMixin],
  data() {
    return {
      overviewConfig,
      isLoading: false,
      metricsDataResponse: {},
      metricsArray: [],
      widgetData: [],
      barData: [],
      dropdownOptions: [],
      visibleCards: [],
      tooltipTitle: 'Overall Content Score and SKU’s Split',
      overallTooltipText:
        'Content Score is an indicator of the quality of content on the Product display pages. The score is computed based on a predefined set of parameters and thresholds. The dashboard analyses each of the parameters and checks if the SKU content falls with respect to the threshold. Based on the number of parameters crossing the threshold, a content score is generated for each SKU. For example, If the SKU content is more than the threshold for 8 parameters out of a total of 10 parameters, the content score will be 80%.<br/><br/>The list of parameters and its thresholds is available on the “Content Score Configuration” option towards the top right of the dashboard.'
    };
  },
  computed: {
    getOverviewConfig() {
      const retailer = this.$store.getters.getRetailer;
      this.overviewConfig.cards = this.overviewConfig?.cards?.filter((e) =>
        e?.retailer.includes(retailer)
      );
      return this.overviewConfig;
    },
    overAllBarChartLabel() {
      return `<span class='u-font-size-4 u-color-grey-lighter u-font-weight-600 u-spacing-mt-xxs u-display-inline-block u-spacing-ml-xs'> ${this.getTotalSKUs} Total SKUs</span>`;
    },
    getTotalSKUs() {
      return formatter(this.barData?.totalCount, 'numeric');
    },
    setTooltip() {
      return {
        id: 'tooltipTemplate',
        tippyOptions: {
          html: '#' + 'tooltipTemplate',
          theme: 'rb-tooltip-header',
          placement: 'bottom',
          interactive: true,
          reactive: true,
          arrow: false
        }
      };
    },
    activeRetailer() {
      return this.$store.getters.getRetailer;
    },
    isAdverstisingSubscribed() {
      return this.$store.getters.getProductConfig.indexOf('MarketingIQ') > -1;
    }
  },
  watch: {
    widgetRequestParams(ov, nv) {
      if (!isEqual(ov, nv)) {
        this.fetchData();
      }
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      const metricsGrouping = dashUtils.groupApis(this.metadata.metrics);
      let response;
      for (const groupName of Object.keys(metricsGrouping)) {
        const api = metricsGrouping[groupName].api;
        const request = dashUtils.replacePlaceHolderWithData(
          api.request,
          this.widgetRequestParams
        );
        // request.operations = { ...request.operations, ...operations };
        request.where.dimensionNameValueList =
          this.widgetRequestParams[':dimensionNameValueList'];
        request.enablePaginationCount = true;
        await dashUtils.fetchDataAPI(request, api).then((args) => {
          response = args?.entityData[0]?.data;
        });
      }
      //  const metricName = this.widgetData.map(e=>e.name);
      const data = {};
      response.forEach((e) => {
        data[':' + e.name] = e.RESULT[e.name];
      });
      this.widgetData = dashUtils.replacePlaceHolderWithData(
        this.getOverviewConfig,
        data
      );

      // Update Overview Filters For Breakdown Table
      const tableParams = this.getWidgetRequestParams(
        'content_scorecard_dashboard_breakdown'
      );
      const keys = this.widgetData.cards.map((e) => e.key);
      tableParams[':dimensionNameValueList'] = tableParams[
        ':dimensionNameValueList'
      ].filter((e) => keys.includes(e.dimensionName));
      const global = this.getGlobalRequestParams();
      if (tableParams[':dimensionNameValueList']?.length) {
        tableParams[':dimensionNameValueList'][0].dimensionValue =
          global[':' + tableParams[':dimensionNameValueList'][0].dimensionName];
        this.updateWidgetRequestParams(
          tableParams,
          'content_scorecard_dashboard_breakdown'
        );
      }
      this.updateBarChart();
      this.updateCards();
      this.isLoading = false;
    },
    dropdownClose(data, key) {
      if (data.length) {
        let cards = this.widgetData.cards;
        const index = cards.findIndex((e) => e.key === key);
        cards[index].show = false;
        cards = cards.filter((e) => e.key !== data[0]?.key);
        data[0].show = true;
        cards.splice(index, 0, data[0]);
        Vue.set(this.widgetData, 'cards', cards);
        this.updateCards();
      }
    },
    updateBarChart() {
      let barData = dashUtils.replacePlaceHolderWithData(
        this.widgetData.overall.barData,
        this.getGlobalRequestParams()
      );
      barData = barData.map((e) => {
        e.label = `<span class="u-font-size-5 u-color-grey-mid-light u-display-inline-block u-spacing-pt-xxs u-spacing-pr-xxs">${
          e.label
        }</span><span class='u-display-inline-block u-font-size-7 u-spacing-mb-xxs u-color-grey-mid-light'>(${
          e.min
        }%-${
          e.max
        }%):&nbsp</span><span class="u-font-size-7 u-color-grey-base u-font-weight-600 u-spacing-pt-xxs">${formatter(
          e.totalCount,
          'numeric'
        )} SKUs</span>`;
        return e;
      });
      let totalBarPercentage = 0;
      barData.forEach((bar) => {
        totalBarPercentage += parseFloat(bar.percentage);
      });
      barData.forEach(
        (bar) =>
          (bar.percentage = (
            (bar.percentage / totalBarPercentage) *
            100
          ).toFixed(2))
      );
      Vue.set(this.widgetData.overall, 'barData', barData);
      this.barData = this.widgetData.overall;
    },
    updateCards() {
      const global = this.getGlobalRequestParams();
      let cards = dashUtils.replacePlaceHolderWithData(
        this.widgetData.cards,
        global
      );
      cards = cards.map((e) => {
        e.label = `${e.title} ${e.operator ?? ''} ${e.numeric ? e.value : ''} ${
          e.suffix ?? ''
        }`;
        return e;
      });
      Vue.set(this.widgetData, 'cards', cards);
      this.visibleCards = this.widgetData.cards.filter(
        (e) => this.handleIsMetricRequired(e) && e.show
      ); // && (e.numeric ? true : global[(':' + e.key)] === 'true')
      this.dropdownOptions = this.widgetData.cards.filter(
        (e) => this.handleIsMetricRequired(e) && !e.show
      );
    },
    handleIsMetricRequired(cardDetails) {
      // Function to handle custom conditions to display metric
      // #1 Showing few metric only when MarketingIQ is enabled for amazon client
      if (
        !this.isAdverstisingSubscribed &&
        'isSalesOnlyMetric' in cardDetails
      ) {
        return !cardDetails.isSalesOnlyMetric[this.activeRetailer];
      }
      return true;
    },
    metricCardSubLabel(card) {
      return `<span class='u-font-size-5 u-color-grey-base u-spacing-ml-xs u-font-weight-600'>${formatter(
        card.count,
        'numeric'
      )}</span><span class='u-font-size-5 u-color-grey-lighter u-font-weight-600 u-spacing-mt-xxs u-display-inline-block'>/${
        this.getTotalSKUs
      } SKUs</span>`;
    },
    viewSku(data) {
      const tableParams = this.getWidgetRequestParams(
        'content_scorecard_dashboard_breakdown'
      );
      const keys = this.widgetData.cards.map((e) => e.key);
      tableParams[':dimensionNameValueList'] = tableParams[
        ':dimensionNameValueList'
      ].filter((e) => !keys.includes(e.dimensionName));
      tableParams[':dimensionNameValueList'].push({
        dimensionName: data.key,
        dimensionValue: data.value
      });
      if (data.numeric) {
        const filter = tableParams[':dimensionNameValueList'].find(
          (e) => e.dimensionName === data.key
        );
        filter.operator = 'LESS_THAN';
      }
      this.updateWidgetRequestParams(
        tableParams,
        'content_scorecard_dashboard_breakdown'
      );
    }
  }
};
</script>

<style lang="css" scoped>
.overview_content .view-sku:hover .icon-view-sku,
.overview_content .view-sku:hover .view-sku-text {
  color: #2b333b;
}
.overview_content .metrics_container {
  grid-template-columns: repeat(5, minmax(0, 1fr));
  display: grid;
  column-gap: 40px;
}
.overview_content .metrics-container-label {
  margin-top: 40px;
}
.overview_content .tooltip-message {
  line-height: 1.38;
  color: #2a333c;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 400;
  font-size: 1.3rem;
}
</style>
