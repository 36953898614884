import HttpService from '@/utils/services/http-service';
import { cloneDeep, isEqual } from 'lodash';
const { filters } = require('@ciq-dev/ciq-ui');

const isUserPersistenceEnabled = () => {
  return filters.config_check('feature.user_selection_persistence.enable');
};

const state = {
  initUserPageMetadata: {},
  userPageMetadata: {},
  isLoading: false
};

const getters = {
  getInitUserPageMetadata: (state) => {
    return state.initUserPageMetadata;
  },
  getUserPageMetadata: (state) => {
    return state.userPageMetadata;
  },
  isUserConfigLoading: (state) => {
    return state.isLoading;
  }
};

const mutations = {
  SET_INIT_USER_PAGE_METADATA: (state, data) => {
    state.initUserPageMetadata = data;
  },
  SET_USER_PAGE_METADATA: (state, data) => {
    state.userPageMetadata = data;
  },
  SET_LOADING: (state, payload) => {
    state.isLoading = payload;
  }
};

const actions = {
  fetchUserPageMetadata: async (context, data) => {
    if (isUserPersistenceEnabled() === false) {
      return;
    }
    context.commit('SET_LOADING', true);
    try {
      const response = await HttpService.post('FETCH_USER_SELECTIONS', {});
      const transformedResponse = {};
      response?.data?.created?.forEach((item) => {
        if (!transformedResponse[item.type]) {
          transformedResponse[item.type] = {};
        }

        if (typeof item.metadata === 'object') {
          transformedResponse[item.type][item.type_id] = item.metadata;
        }
      });
      context.commit(
        'SET_INIT_USER_PAGE_METADATA',
        cloneDeep(transformedResponse)
      );
      context.commit('SET_USER_PAGE_METADATA', cloneDeep(transformedResponse));
    } catch (error) {
      context.commit('SET_LOADING', false);
    }
  },
  updateUserMetadata: (context, payload) => {
    if (isUserPersistenceEnabled() === false) {
      return;
    }
    const { type, typeId } = payload;
    const userMetadata = context.getters.getUserPageMetadata;
    if (!userMetadata[type]) {
      userMetadata[type] = {};
    }

    if (typeof payload === 'object') {
      userMetadata[type][typeId] = {
        ...userMetadata[type][typeId],
        ...payload
      };
    }
    delete userMetadata[type][typeId].typeId;
    delete userMetadata[type][typeId].type;

    context.commit('SET_USER_PAGE_METADATA', userMetadata);
    if (!isEqual(userMetadata, context.getters.getInitUserPageMetadata)) {
      context.dispatch('updateUserPageMetadata');
    }
    context.commit('SET_LOADING', true);
  },
  updateUserPageMetadata: async (context) => {
    if (isUserPersistenceEnabled() === false) {
      return;
    }
    context.commit('SET_LOADING', true);
    let finalTransformedList = [];
    const userPageMetadata = context.getters.getUserPageMetadata;
    Object.keys(userPageMetadata).forEach((type) => {
      Object.keys(userPageMetadata[type]).forEach((typeId) => {
        if (
          !isEqual(
            userPageMetadata[type][typeId],
            context.getters.getInitUserPageMetadata?.[type]?.[typeId]
          )
        ) {
          finalTransformedList.push({
            type: type,
            type_id: typeId,
            metadata: userPageMetadata[type][typeId]
          });
        }
      });
    });

    context.commit('SET_INIT_USER_PAGE_METADATA', cloneDeep(userPageMetadata));

    try {
      await HttpService.post('SET_USER_SELECTIONS', {
        userMetadataList: finalTransformedList
      });
    } catch (error) {
      console.error('Error:', error);
    } finally {
      context.commit('SET_LOADING', false);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
