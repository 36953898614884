import BusinessInsights from '@/components/pages/businessInsights/index.vue';
import HomeV2 from '@/components/homeV2.vue';
import ToolsAndSettings from '@/pages/toolsAndSettings/route.vue';
import toolsAndSettingsRoutes from '@/pages/toolsAndSettings/route.js';

import DigitalShelfLanding from '@/pages/digital_shelf/route_config/index.vue';
import DigitalShelfRoutes from '@/pages/digital_shelf/route_config/route.js';
import Invite from '@/components/pages/invite/invite.vue';
import DvtInternalTool from '@/components/pages/ciq-internal-tools/dvt-internal-tool.vue';
import MailValidator from '@/components/pages/ciq-internal-tools/mail-validator';
import productDiagnostics from '@/components/pages/ciq-internal-tools/product-diagnostics';
import productSLA from '@/components/pages/ciq-internal-tools/product-sla/productSLA.vue';
import productDiagnosticsRoutes from '@/components/pages/ciq-internal-tools/product-diagnostics/route.js';
import internalToolBox from '@/components/pages/ciq-internal-tools/internal-toolbox';
import { filters } from '@ciq-dev/ciq-ui';
import passwordManager from '@/components/pages/ciq-internal-tools/password-manager/passwordManager.vue';
import CiqEfundamentalsFAQ from '@/pages/ciq-efundamentals-announcement/index.vue';
import CONSTANTS from '@/utils/constants';
import reportedIssues from '@/components/widgets/reportedIssues.vue';
import { proxyLinksRoute } from '@/router/commonRoutePaths.js';

const freshRoutes = {
  path: '/us/amazon/fresh',
  name: 'Home',
  component: HomeV2,
  children: [
    {
      path: '',
      name: 'Home',
      redirect() {
        const freshLanding = filters.config_check('feature.fresh.home');
        if (freshLanding) {
          return freshLanding;
        } else {
          return 'business_overview';
        }
      }
    },
    {
      path: 'business_overview/:id?',
      name: 'businessInsights',
      title: 'Business Overview',
      component: BusinessInsights,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: 'reportedIssues',
      name: 'reportedIssues',
      component: reportedIssues
    },
    {
      path: 'tools_and_settings',
      name: 'ToolsAndSettings',
      component: ToolsAndSettings,
      children: toolsAndSettingsRoutes.routes
    },
    {
      path: 'digital_shelf',
      component: DigitalShelfLanding,
      children: DigitalShelfRoutes.routes
    },
    {
      path: 'invite',
      name: 'invite',
      component: Invite
    },
    {
      path: 'dvtInternalTool',
      name: 'dvtInternalTool',
      component: DvtInternalTool
    },
    {
      path: 'mailValidator',
      name: 'mailValidator',
      component: MailValidator
    },
    {
      path: 'product_diagnostics',
      name: 'productDiagnostics',
      component: productDiagnostics,
      children: productDiagnosticsRoutes.routes
    },
    {
      path: 'product_sla',
      name: 'productSLA',
      component: productSLA
    },
    {
      path: 'internalToolBox',
      name: 'internalToolBox',
      component: internalToolBox
    },
    {
      path: 'account_manager',
      name: 'passwordManager',
      component: passwordManager
    },
    {
      path: 'ciq-efundamentals-announcement',
      name: 'ciq-efundamentals-announcement',
      component: CiqEfundamentalsFAQ
    },
    proxyLinksRoute
  ]
};

export { freshRoutes };
