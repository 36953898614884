import StrategyList from '@/components/pages/strategy_list/strategyList.vue';
import Strategies from '@/components/pages/strategies/strategies.vue';
import CreateStrategy from '@/components/pages/strategies/create/createStrategy.vue';
import budgetPlanner from '@/components/ams/budgetPlanner/budgetPlanner.vue';
import budgetPlannerRoutes from '@/components/ams/budgetPlanner/route.js';
import campaignManagement from '@/components/pages/campaign-management/campaignManagement.vue';
import campaignManagementRoutes from '@/components/pages/campaign-management/route.js';
import MediaPlanner from '@/components/pages/media-planner/index.vue';
import mediaPlannerRoutes from '@/components/pages/media-planner/routes.js';
import dspCampaignManagementRoutes from '@/components/pages/dsp-campaign-management/route.js';
import StrategyBuilder from '@/components/pages/strategy_builder/strategyBuilder.vue';
import PassThroughRoute from '@/components/widgets/routerView.vue';
import CONSTANTS from '@/utils/constants';
import budgetPlannerRoutesV2 from '@/pages/budget-planner-v2/route.js';

const wrapper = {
  template: '<router-view :key="$route.fullPath" />'
};

export default {
  configCheckRequired: true,
  routes: [
    {
      path: 'campaign_optimizer',
      component: PassThroughRoute,
      children: [
        {
          path: '',
          name: 'StrategyList',
          title: 'Campaign Optimizer',
          component: StrategyList,
          meta: {
            productLine: CONSTANTS.PRODUCT_LINE.RMM,
            appendEsDataSetName: true
          }
        },
        {
          path: 'create',
          name: 'StrategyBuilder',
          component: StrategyBuilder,
          meta: {
            productLine: CONSTANTS.PRODUCT_LINE.RMM,
            appendEsDataSetName: true
          }
        },
        {
          path: 'edit',
          name: 'StrategyBuilderEdit',
          component: StrategyBuilder,
          meta: {
            productLine: CONSTANTS.PRODUCT_LINE.RMM,
            appendEsDataSetName: true
          }
        }
      ]
    },
    {
      path: 'hourly_bidder',
      component: PassThroughRoute,
      props: true,
      children: [
        {
          path: '',
          name: 'strategies',
          title: 'Hourly Bidder',
          component: Strategies,
          props: true,
          meta: {
            productLine: CONSTANTS.PRODUCT_LINE.RMM,
            appendEsDataSetName: true
          }
        },
        {
          path: 'create',
          name: 'create-strategy',
          component: CreateStrategy,
          meta: {
            productLine: CONSTANTS.PRODUCT_LINE.RMM,
            appendEsDataSetName: true
          }
        },
        {
          path: 'edit',
          name: 'edit-strategy',
          component: CreateStrategy,
          meta: {
            productLine: CONSTANTS.PRODUCT_LINE.RMM,
            appendEsDataSetName: true
          }
        }
      ]
    },
    {
      path: 'budget_optimizer',
      title: 'Budget Optimizer',
      component: budgetPlanner,
      children: budgetPlannerRoutes.routes
    },
    {
      path: 'budget_optimizer_v2',
      title: 'Budget Optimizer',
      component: budgetPlanner,
      children: budgetPlannerRoutesV2.routes
    },
    {
      path: 'campaign_management',
      name: 'cmDefaultRedirect',
      component: campaignManagement,
      children: campaignManagementRoutes.routes
    },
    {
      path: 'media_planner',
      name: 'Media Planner',
      component: MediaPlanner,
      children: mediaPlannerRoutes.routes
    },
    {
      path: 'dsp_campaign_management',
      name: 'DSP Campaign Management',
      component: wrapper,
      children: dspCampaignManagementRoutes.routes
    },
    {
      path: 'amc_insights',
      title: 'AMC Insights',
      component: wrapper,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: '',
      redirect: () => {
        return 'campaign_optimizer';
      }
    }
  ]
};
