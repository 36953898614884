import isEqual from 'lodash/isEqual';

export const flattenArrayOfArray = (arrayOfArray) => {
  const flatArray = [];
  for (const nestedArray of arrayOfArray) {
    flatArray.push(...nestedArray);
  }
  return flatArray;
};

export const convertArraysToMaps = (
  nestedArraysToBeConverted,
  keyToMapWith,
  internalProperty = 'data'
) => {
  return nestedArraysToBeConverted.map((arrayToBeConverted) => {
    //   const map = {};
    //   arrayToBeConverted[internalProperty].forEach(item => {
    //     map[item[keyToMapWith]] = item;
    //   });
    //   return map;
    return mapOfArray(arrayToBeConverted, keyToMapWith, internalProperty);
  });
  // return arrayOfLookupMaps;
};

export const mapOfArray = (
  arrayToBeConverted,
  keyToMapWith,
  internalProperty = 'data'
) => {
  const map = {};
  arrayToBeConverted[internalProperty].forEach((item) => {
    map[item[keyToMapWith]] = item;
  });
  return map;
};

/**
 * The function checks if a given string is a valid JSON string.
 * @param stringToCheck - The `stringToCheck` parameter is a string that you want to check if it is a
 * valid JSON string.
 * @returns a boolean value. It returns true if the stringToCheck is a valid JSON string, and false
 * otherwise.
 */
export const isValidJsonString = (stringToCheck) => {
  try {
    JSON.parse(stringToCheck);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * The function merges two arrays of objects based on a specified property.
 * @param target - The `target` parameter is an array that represents the target array where the
 * elements will be merged into.
 * @param source - The `source` parameter is an array of objects that you want to merge into the
 * `target` array. Each object in the `source` array represents a source element that you want to merge
 * into the `target` array.
 * @param prop - The "prop" parameter is a string that represents the property name that will be used
 * to match elements in the "target" and "source" arrays.
 */
export const mergeByProperty = (target, source, prop) => {
  source.forEach((sourceElement) => {
    let targetElement = target.find((targetElement) => {
      return sourceElement[prop] === targetElement[prop];
    });
    targetElement
      ? Object.assign(targetElement, sourceElement)
      : target.push(sourceElement);
  });
};

/**
 * The function generates a location string based on URL components, a selected view, and a selected
 * widget.
 * @param urlComponents - An array of objects representing the components of a URL. Each object has a
 * property called "display" which represents the display name of the component.
 * @param currentSelectedView - The `currentSelectedView` parameter is a string that represents the
 * currently selected view in the application.
 * @param currentSelectedWidget - The `currentSelectedWidget` parameter is a string that represents the
 * currently selected widget.
 * @returns the final location string.
 */
export const generateLocationFromUrlComponents = (
  urlComponents,
  currentSelectedView,
  currentSelectedWidget
) => {
  let finalLocation = '';
  urlComponents?.slice(1)?.forEach((urlComponent, index) => {
    const displayName = urlComponent?.display;
    finalLocation +=
      displayName?.charAt(0)?.toUpperCase() + displayName?.slice(1);
    if (index < urlComponents.length - 1) {
      finalLocation += ' > ';
    }
  });
  finalLocation += currentSelectedView;

  if (currentSelectedWidget) {
    finalLocation += ' > ';
    finalLocation += currentSelectedWidget;
  }
  return finalLocation;
};

/**
 * The function `formatOperatorWhereClause` formats the operator in the `whereClause` object by
 * extracting the operator from the `dimensionValue` object and assigning it to the `operator`
 * property.
 * @param whereClause - The `whereClause` parameter is an object that contains information about the
 * dimensions and values to filter on. It has the following structure:
 * @returns The updated `whereClause` object is being returned.
 */
export const formatOperatorWhereClause = (whereClause) => {
  let updatedWhereClause = whereClause;

  updatedWhereClause.dimensionNameValueList =
    updatedWhereClause?.dimensionNameValueList?.map((clause) => {
      if (clause?.dimensionValue?.operator) {
        return {
          dimensionName: clause.dimensionName,
          dimensionValue: clause.dimensionValue?.value,
          operator: clause.dimensionValue?.operator?.operator
        };
      }
      return clause;
    });

  return updatedWhereClause;
};

/**
 * The function generates a truncated string by removing characters from the input string and adding
 * ellipsis if necessary, and also adds a count of remaining selections.
 * @param concatStr - The `concatStr` parameter is a string that represents the concatenated
 * selections.
 * @param maxStrLen - The `maxStrLen` parameter represents the maximum length of the string that you
 * want to generate.
 * @param idx - The `idx` parameter represents the current index of the selection being processed in
 * the `selections` array.
 * @param selections - An array of strings representing the selections to be concatenated.
 * @returns the updated string after truncating it and adding ellipsis (...) if necessary.
 */
export const generateTruncatedStringHelper = (
  concatStr,
  maxStrLen,
  idx,
  selections
) => {
  let updatedStr = concatStr;
  updatedStr = updatedStr.slice(0, maxStrLen - 3);
  // add '...' if all the selections is not fit into updatedStr
  for (let k = 0; k < idx; k++) {
    if (!updatedStr.includes(selections[k])) {
      updatedStr = updatedStr.concat('...');
    }
  }
  // Handling edge case ',' in the end for unclipped selections.
  if (updatedStr.charAt(updatedStr.length - 1).includes(',')) {
    updatedStr = updatedStr.slice(0, updatedStr.length - 1);
  }
  const moreCount = selections.length - idx;
  updatedStr = updatedStr.concat(
    idx < selections.length ? ' & ' + moreCount + ' more' : ''
  );
  updatedStr = updatedStr.slice(0, maxStrLen - 3);
  updatedStr = generateTruncatedStringHelper2(updatedStr, selections, idx);
  return updatedStr;
};

/**
 * The function generates a truncated string by adding '...' if all the selections do not fit into the
 * original string.
 * @param concatStr - The `concatStr` parameter is a string that represents the concatenated string of
 * all the selections.
 * @param selections - The `selections` parameter is an array of strings that represents a list of
 * selections. Each selection is a string that you want to check if it is included in the `concatStr`
 * string.
 * @param idx - The `idx` parameter represents the index of the last selection in the `selections`
 * array that should be included in the `concatStr` string.
 * @returns the updated string after adding '...' if all the selections do not fit into the original
 * concatenated string.
 */
export const generateTruncatedStringHelper2 = (concatStr, selections, idx) => {
  let updatedStr = concatStr;
  // add '...' if all the selections is not fit into concatStr
  for (let k = 0; k < idx; k++) {
    if (!updatedStr.includes(selections[k])) {
      updatedStr = updatedStr.concat('...');
    }
  }
  return updatedStr;
};

/**
 * The function `generateTruncatedString` takes an array of selections and a maximum string length, and
 * returns a concatenated string of the selections, with a maximum length of `maxStrLen`, and appends
 * "X more" if there are additional selections beyond the truncated string.
 * @param selections - An array of strings representing the selections.
 * @param maxStrLen - The `maxStrLen` parameter is the maximum length of the resulting string.
 * @returns The function `generateTruncatedString` returns a concatenated string of the `selections`
 * array, with a maximum length of `maxStrLen`. If the concatenated string exceeds the maximum length,
 * it is truncated and appended with the number of remaining selections.
 */
export const generateTruncatedString = (selections, maxStrLen) => {
  let concatStr = '';
  for (let idx = 0; idx <= selections?.length; idx++) {
    const curSelected = selections[idx]?.replaceAll('----', ' > ');
    if (concatStr.length >= maxStrLen - 3) {
      concatStr = generateTruncatedStringHelper(
        concatStr,
        maxStrLen,
        idx,
        selections
      );
      // Handling edge case ',' in the end for unclipped selections.
      if (concatStr.charAt(concatStr.length - 1).includes(',')) {
        concatStr = concatStr.slice(0, concatStr.length - 1);
      }
      const moreCount = selections.length - idx;
      concatStr = concatStr.concat(
        idx < selections.length ? ' & ' + moreCount + ' more' : ''
      );
      break;
    } else if (curSelected && curSelected.length > 0) {
      concatStr = concatStr.concat(
        curSelected,
        idx < selections.length - 1 ? ', ' : ''
      );
    }
  }
  return concatStr;
};

export const areArraysEqual = (newValues, oldValues) => {
  const compareFunction = (a, b) => {
    return a.localeCompare(b);
  };
  const sortedArrayForNewValues = [...newValues.slice()].sort(compareFunction);
  const sortedArrayForOldValues = [...oldValues.slice()].sort(compareFunction);
  return isEqual(sortedArrayForNewValues, sortedArrayForOldValues);
};

export const isBOPage = (page) => {
  return page === 'BusinessInsights' || page === 'BusinessInsightsEU';
};

export const specialCharacterCheck = (input) => {
  const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  return format.test(input);
};

export function isElementOverflowing(el) {
  if (!el) {
    return false;
  }
  const curOverflow = el.style.overflow;
  if (!curOverflow || curOverflow === 'visible') {
    el.style.overflow = 'hidden';
  }
  const isOverflowing =
    el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
  el.style.overflow = curOverflow;
  return isOverflowing;
}

export const getProgressEstimatedTime = (progressEstimatedTime) => {
  if (progressEstimatedTime >= 60) {
    return `${Math.floor(progressEstimatedTime / 60)} hrs`;
  }
  return `${progressEstimatedTime} mins`;
};
