<template>
  <div class="u-width-100 u-overflow-auto u-display-flex u-spacing-p-xxl">
    <modal
      v-if="showRemoveFilePopUp"
      :modal-config="modalConfig"
      @clickedOutside="closeModal"
    />
    <section class="createBudgetPlan--steps u-width-100">
      <budgetPlannerHeader :step="step" />
      <section
        v-if="fileUploadStatus === 'not_started'"
        class="u-dispay-flex u-spacing-mb-l"
        style="max-width: 650px"
      >
        <div
          class="u-dispay-flex u-flex-justify-content-space-between u-flex-align-items-center"
        >
          <div
            class="u-color-blue-base u-spacing-pb-s u-display-flex u-flex-align-content-center u-flex-direction-row"
            :class="{ blockPointerEvents: downloadNewFileFromTemplateLoader }"
            @click="
              downloadBudgetPlannerFile(
                true,
                'downloadNewFileFromTemplateLoader'
              )
            "
          >
            <rb-icon
              class="fs-download-new-budget-plan rb-icon--medium u-cursor-pointer"
              :class="{ rotate: downloadNewFileFromTemplateLoader }"
              :icon="downloadNewFileFromTemplateLoader ? 'spinner' : 'download'"
            />
            <div
              class="u-spacing-pl-s link-custom-hover u-font-size-5 u-cursor-pointer u-font-weight-600"
            >
              Download budget plan template
            </div>
          </div>
        </div>
        <dropArea
          class="fs-upload-budget-plan"
          @onFileUpload="handleFileUpload"
        />
      </section>
      <div v-else>
        <div
          v-if="
            fileUploadStatus === 'complete' ||
            fileUploadStatus === 'incomplete' ||
            fileUploadStatus === 'icorrect_template'
          "
          class="u-display-flex u-flex-align-content-center"
        >
          <div
            v-if="fileUploadStatus !== 'icorrect_template'"
            class="u-color-grey-lighter u-spacing-pr-l upload-link-custom u-spacing-pb-s u-display-flex u-flex-align-content-center u-flex-direction-row"
            :class="{ blockPointerEvents: downloadNewFileFromTemplateLoader }"
            @click="
              downloadBudgetPlannerFile(
                true,
                'downloadNewFileFromTemplateLoader'
              )
            "
          >
            <rb-icon
              class="rb-icon--medium u-cursor-pointer"
              :class="{ rotate: downloadNewFileFromTemplateLoader }"
              :icon="downloadNewFileFromTemplateLoader ? 'spinner' : 'download'"
            />
            <div
              class="u-spacing-pl-s u-font-size-5 u-cursor-pointer fs-download-new-budget-plan u-font-weight-600"
            >
              Download budget plan template
            </div>
          </div>
          <div
            class="u-color-grey-lighter u-spacing-pb-s u-display-flex u-flex-align-content-center u-flex-direction-row upload-link-custom fs-reupload-budget-plan"
            @click="reUploadPlan"
          >
            <rb-icon
              class="rb-icon--medium u-cursor-pointer is-flipped"
              icon="download"
            />
            <div
              class="u-spacing-pl-s u-font-size-5 u-cursor-pointer u-font-weight-600"
            >
              Reupload budget plan
            </div>
            <dropArea
              ref="reuploadDropArea"
              style="display: none"
              @onFileUpload="onReupload"
            />
          </div>
        </div>
        <div
          style="max-width: 700px"
          class="u-spacing-p-m loaderCard"
          :style="{
            background: fileUploadStatus === 'complete' ? '#007cf6' : '#fff'
          }"
          :class="{
            errorBorder:
              fileUploadStatus === 'incomplete' ||
              fileUploadStatus === 'error' ||
              fileUploadStatus === 'icorrect_template'
          }"
        >
          <div
            v-if="
              fileUploadStatus === 'started' || fileUploadStatus === 'error'
            "
            class="u-width-100"
          >
            <div
              class="u-display-flex u-flex-direction-row u-flex-justify-content-space-between u-spacing-mt-m u-font-weight-600"
            >
              <div
                v-tippy="tippy"
                class="u-font-size-4 u-text-overflow-ellipsis"
                :title="fileDetails.name"
              >
                {{ fileDetails.name }}
              </div>
              <div
                class="u-color-blue-base u-cursor-pointer u-font-size-5 link-custom-hover"
                @click="resetFileUpload()"
              >
                Cancel
              </div>
            </div>
            <div
              class="u-spacing-mt-m u-spacing-mb-s"
              :class="fileUploadStatus === 'started' ? 'loader' : 'errorLoader'"
            />
            <div
              class="u-font-weight-400 u-font-size-5 u-spacing-mb-m"
              :class="
                fileUploadStatus === 'started'
                  ? 'u-color-blue-base'
                  : 'u-color-red-base'
              "
            >
              {{ uploadMessage }}
            </div>
          </div>
          <div
            v-else
            class="u-width-100 u-display-flex u-flex-align-items-center u-spacing-pv-m u-flex-justify-content-space-between"
          >
            <div
              class="u-font-size-4 u-display-flex"
              :class="{
                'u-color-grey-white': fileUploadStatus === 'complete',
                'u-color-red-base':
                  fileUploadStatus === 'incomplete' ||
                  fileUploadStatus === 'icorrect_template'
              }"
            >
              <div
                class="u-spacing-pl-m"
                style="padding-top: 2px"
              >
                <rb-icon
                  class="rb-icon--bp-custom"
                  :icon="
                    fileUploadStatus === 'complete'
                      ? 'check-fill-circle'
                      : 'info-circle-fill'
                  "
                  :style="
                    fileUploadStatus === 'complete'
                      ? ''
                      : 'transform: rotate(180deg)'
                  "
                />
              </div>
              <div
                class="u-spacing-ph-s"
                style="max-width: 300px"
              >
                <div
                  v-tippy="tippy"
                  :class="{
                    'u-color-grey-base':
                      fileUploadStatus === 'incomplete' ||
                      fileUploadStatus === 'icorrect_template'
                  }"
                  class="u-font-weight-600 u-font-size-2 u-text-overflow-ellipsis"
                  :title="fileDetails.name"
                >
                  {{ fileDetails.name }}
                </div>
                <div class="u-spacing-pt-xs u-font-size-5">
                  {{
                    fileUploadStatus === 'incomplete' ||
                    fileUploadStatus === 'icorrect_template'
                      ? uploadMessage
                      : fileDetails.time
                  }}
                </div>
              </div>
            </div>
            <div class="u-display-flex">
              <div class="defaultHollowButton">
                <rb-button
                  v-if="fileUploadStatus === 'complete'"
                  style="min-width: 105px"
                  text="Remove"
                  type="hollow"
                  :click-fn="onClickRemove"
                  class="u-spacing-mr-m fs-remove-budget-plan"
                />
                <div
                  v-else
                  class="u-height-100 u-display-flex u-flex-align-items-center u-spacing-ph-l"
                >
                  <span
                    class="link-custom-hover u-cursor-pointer u-font-size-6 u-color-blue-base fs-remove-budget-plan"
                    @click="onClickRemove"
                    >Remove</span
                  >
                </div>
              </div>
              <rb-button
                v-if="fileUploadStatus === 'complete'"
                style="min-width: 105px"
                :text="downloadFileLoader ? '' : 'Download'"
                type="filled"
                :loading="downloadFileLoader"
                :disabled="downloadFileLoader"
                :click-fn="downloadBudgetPlanFromFilePath"
                class="u-spacing-mr-m fs-download-existing-budget-plan"
              />
              <div class="hollow-button-download">
                <rb-button
                  v-if="fileUploadStatus === 'incomplete'"
                  style="min-width: 105px"
                  :text="downloadFileLoader ? '' : 'Download and fix'"
                  type="hollow"
                  :loading="downloadFileLoader"
                  :disabled="downloadFileLoader"
                  :click-fn="downloadBudgetPlanFromFilePath"
                  class="u-spacing-mr-m fs-budget-plan-download-and-fix"
                />
                <rb-button
                  v-if="fileUploadStatus === 'icorrect_template'"
                  style="min-width: 105px"
                  :text="
                    downloadNewFileFromButtonLoader
                      ? ''
                      : 'Download new template'
                  "
                  type="hollow"
                  :disabled="downloadNewFileFromButtonLoader"
                  :loading="downloadNewFileFromButtonLoader"
                  :click-fn="downloadNewTemplate"
                  class="u-spacing-mr-m fs-download-new-budget-plan"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <budgetPlannerFooter
        :show-left-btn="false"
        :disable-right-btn="disablePreviewButton"
        btn-right-text="Preview"
        class="u-spacing-pt-xxl"
        custom-class-right="fs-budget-plan-move-to-preview-step"
        @onClickBtnRight="nextStep"
      />
    </section>
  </div>
</template>

<script>
import stepsMixin from '../stepMixin';
import modal from '@/components/widgets/modal.vue';
import budgetPlannerHeader from '../common/header';
import budgetPlannerFooter from '../common/footer';
import dropArea from './dropArea';

export default {
  components: {
    dropArea,
    modal,
    budgetPlannerHeader,
    budgetPlannerFooter
  },
  mixins: [stepsMixin],
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    tippy: {
      placement: 'bottom',
      arrow: false,
      distance: 8,
      popperOptions: {
        modifiers: {
          preventOverflow: {
            enabled: false
          }
        }
      }
    },
    disablePreviewButton: true,
    downloadNewFileFromTemplateLoader: false,
    downloadNewFileFromButtonLoader: false,
    downloadFileLoader: false,
    uploadMessage: '',
    showRemoveFilePopUp: false,
    lastFileUploadStatus: 'complete',
    modalConfig: {}
  }),
  computed: {
    downloadFileText() {
      let text =
        this.fileUploadStatus === 'complete' ? 'Download' : 'Download and fix';
      if (this.downloadFileLoader) {
        text = '';
      }
      return text;
    },
    fileDetails() {
      return this.$store.getters.getBudgetPlanFileDetails;
    },
    fileUploadStatus() {
      return this.$store.getters.getBudgetPlanFileUploadStatus;
    },
    budgetPlanFileUploadError() {
      return this.$store.getters.getBudgetPlanFileUploadError;
    }
  },
  watch: {
    disablePreviewButton: {
      handler(newValue) {
        let indexesToEnable = [0];
        if (!newValue) {
          indexesToEnable = [0, 1, 2, 3];
        }
        this.$emit('breadcrumb-indexes-to-enable', indexesToEnable);
      },
      immediate: true
    },
    fileUploadStatus: {
      handler: function (newValue, oldValue) {
        if (oldValue) {
          this.lastFileUploadStatus = oldValue;
        }
        this.disablePreviewButton = newValue !== 'complete';
        let color = '';
        let message = '';
        if (
          (newValue === 'error' ||
            newValue === 'incomplete' ||
            newValue === 'icorrect_template') &&
          oldValue === 'started'
        ) {
          color = '#d7263d';
          this.uploadMessage = this.budgetPlanFileUploadError;
          message = `<div><span class="rb-icon is-flipped icon-info-circle-fill rb-icon--small"></span> ${this.budgetPlanFileUploadError}</div>`;
          this.snackbar(color, message);
        }
        if (newValue === 'complete' && oldValue === 'started') {
          color = '#3ea95e';
          message = `<div class="u-display-flex u-flex-align-items-center"><div class="rb-icon icon-check-fill-circle rb-icon--small u-spacing-mr-xs"></div> <div> <span class="u-font-weight-600">${this.fileDetails.name}</span> was uploaded successfully.</div></div>`;
          this.snackbar(color, message);
        }
      },
      immediate: true
    }
  },
  created() {
    this.$store.dispatch('setVisitedSteps', 'uploadStep');
  },
  methods: {
    closeModal(val) {
      if (val) {
        this.showRemoveFilePopUp = false;
      }
    },
    resetFileUpload() {
      if (this.showRemoveFilePopUp) {
        this.showRemoveFilePopUp = false;
      }
      this.$store.dispatch('setBudgetPlanFileUploadStatus', 'not_started');
    },
    reUploadPlan() {
      this.$refs.reuploadDropArea.dropArea.click();
    },
    onReupload(file) {
      this.resetFileUpload();
      this.handleFileUpload(file);
    },
    onClickRemove() {
      const that = this;
      this.modalConfig = {
        bodyTitle: {
          text: 'Remove budget plan',
          highlightText: '',
          icon: 'trash'
        },
        body: {
          text: 'Are you sure you want to remove the existing budget plan?'
        },
        footer: {
          leftButton: {
            text: 'Cancel',
            onClick: () => {
              that.showRemoveFilePopUp = false;
            },
            icon: '',
            show: true
          },
          rightButton: {
            text: 'Remove',
            onClick: () => {
              that.resetFileUpload();
            },
            icon: '',
            show: true
          }
        }
      };
      this.showRemoveFilePopUp = true;
    },
    nextStep() {
      this.$emit('nextStep', {});
    },
    handleFileUpload(file) {
      if (file) {
        this.$store.dispatch('setBudgetPlanFileDetails', {
          name: file.name,
          time: 'NA'
        });
        this.fileUploadApiCall(file);
        this.$store.dispatch('resetVisitedSteps', ['uploadStep']);
      }
    },
    fileUploadApiCall(file) {
      const formData = new FormData();
      formData.append('file', file);
      const year = new Date().getFullYear();
      this.uploadMessage = 'Uploading your budget plan..';
      this.$store.dispatch('uploadBudgetPlanFile', {
        formData,
        year: this.$route.query.nextYearPlan ? year + 1 : year
      });
    },
    downloadBudgetPlannerFile(downloadNewFile, downloadFrom) {
      this[downloadFrom] = true;
      let message = '';
      const year = new Date().getFullYear();
      this.$store
        .dispatch('downloadBudgetPlanExcel', {
          year: this.$route.query.nextYearPlan ? year + 1 : year,
          downloadNewFile
        })
        .then((response) => {
          if (response.status === 200) {
            message = 'Downloaded new budget plan successfully.';
          } else {
            message =
              'Downloading the new budget plan failed. Please try again.';
          }
        })
        .catch((error) => {
          if (error) {
            message =
              'Downloading the new budget plan failed. Please try again.';
          }
        })
        .finally(() => {
          this[downloadFrom] = false;
          this.snackbar('', message);
        });
    },
    downloadNewTemplate() {
      this.downloadBudgetPlannerFile(true, 'downloadNewFileFromButtonLoader');
    },
    downloadBudgetPlanFromFilePath() {
      this.downloadFileLoader = true;
      let message = '';
      this.$store
        .dispatch('downloadBudgetPlanFile')
        .then((response) => {
          if (response.status === 200) {
            message = 'Downloaded the existing budget plan successfully.';
          } else {
            message =
              'Downloading the existing budget plan failed. Please try again.';
          }
        })
        .catch((error) => {
          if (error) {
            message =
              'Downloading the existing budget plan failed. Please try again.';
          }
        })
        .finally(() => {
          this.downloadFileLoader = false;
          this.snackbar('', message);
        });
    }
  }
};
</script>

<style lang="css" scoped>
.blockPointerEvents {
  pointer-events: none;
}
.rb-button--filled {
  background: #fff !important;
  color: #007cf6 !important;
}
.rb-button--filled:hover {
  background: #e6e6e6 !important;
  color: #007cf6 !important;
}
.rb-button--filled[disabled] {
  background: #aaadb1;
}
.rb-button--filled:active {
  background: #0062c3;
}
.budget-plan-desc {
  font-style: italic;
}
.upload-link-custom:hover,
.upload-link-custom:focus {
  color: #4b5158;
}
.link-custom-hover:hover,
.link-custom-hover:focus {
  text-decoration: underline;
}
.create-plan--steps:not(:first-child) {
  margin-top: 100px;
}
.defaultHollowButton .rb-button--hollow {
  color: #fff !important;
  background: #007cf6 !important;
  border: 1px solid #fff !important;
}
.defaultHollowButton .rb-button--hollow:hover {
  background: #2a95ff !important;
  color: #fff !important;
  border-color: #fff !important;
}
.hollow-button-download .rb-button--hollow {
  padding: 0px 24px;
  color: #007cf6 !important;
  border-color: #007cf6 !important;
}
.hollow-button-download .rb-button--hollow:hover {
  color: #0062c3 !important;
  border-color: #0062c3 !important;
}
.step-workspace {
  padding-top: 40px;
}
.errorLoader {
  height: 8px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #d9263e33;
  border-radius: 100px;
}
.errorLoader:before {
  display: block;
  position: absolute;
  content: '';
  width: 80%;
  height: 8px;
  background-color: #d7263d;
  animation: loading 1s linear;
}
.loader {
  height: 8px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #007bf63b;
  border-radius: 100px;
}
.errorBorder {
  border: solid 1px #d7263d;
}
.loader:before {
  display: block;
  position: absolute;
  content: '';
  width: 100%;
  height: 8px;
  background-color: #007cf6;
  animation: loading 4s linear;
}
.loaderCard {
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(43, 51, 59, 0.15);
}
@keyframes loading {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.rb-icon--bp-custom {
  height: 19px;
  width: 19px;
}
.rb-icon--bp-custom:before {
  font-size: 19px;
}
</style>
