<template>
  <div
    class="u-display-flex u-flex-align-items-center"
    :class="[parentClass]"
  >
    <rb-icon
      v-tippy="realtimeMetricTippyConfig.tippyOptions"
      :class="[
        iconClass,
        metricLabel === 'NA' || streamMetricData === 'NA'
          ? 'u-color-grey-lighter'
          : 'u-color-blue-base'
      ]"
      class="rb-icon"
      icon="in-progress-dotted"
    />
    <rb-tooltip-header :id="realtimeMetricTippyConfig.id">
      <!-- eslint-disable -->
      <div
        slot="tooltip-message"
        class="tooltip-message stream-metric-card-tooltip u-display-flex u-flex-justify-content-flex-start"
      >
        <span>
          <rb-icon
            class="rb-icon rb-icon--medium"
            :class="{
              'u-color-blue-base':
                metricLabel !== 'NA' || streamMetricData !== 'NA',
              'u-color-grey-lighter':
                metricLabel === 'NA' || streamMetricData === 'NA'
            }"
            icon="in-progress-dotted"
          />
        </span>
        <html-component
          class="u-spacing-ml-xs"
          :html-string="
            metricLabel || streamMetricData
              ? getTippyTextForRealTimeData()
              : streamMetricTooltip
          "
          :wrapper-element="'span'"
        />
      </div>
    </rb-tooltip-header>
    <span
      v-if="showLabel"
      class="u-font-size-6 u-font-weight-600 u-spacing-ml-xs"
      :class="{
        'u-color-blue-base': metricLabel !== 'NA' || streamMetricData !== 'NA',
        'u-color-grey-lighter':
          metricLabel === 'NA' || streamMetricData === 'NA'
      }"
    >
      {{ metricLabel }}
    </span>
  </div>
</template>

<script>
import HtmlComponent from '@/components/basic/html-component.vue';
import {
  streamMetricTooltipWithData,
  streamMetricTooltipWithNoData
} from '../constants';
import moment from 'moment-timezone';

export default {
  components: {
    HtmlComponent
  },
  props: {
    iconClass: {
      type: String,
      default: 'rb-icon--medium'
    },
    parentClass: {
      type: String,
      default: 'u-spacing-mt-xs'
    },
    delay: {
      type: Array,
      default: () => [0, 0]
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    streamMetricData: {
      type: String,
      default: null
    },
    streamMetricTooltip: {
      type: String,
      default: null
    },
    metricLabel: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      realtimeMetricTippyConfig: {
        id: 'realtimeMetricTippy',
        tippyOptions: {
          html: '#' + 'realtimeMetricTippy',
          delay: this.delay,
          theme: 'rb-tooltip-header',
          placement: 'bottom',
          interactive: true,
          reactive: true,
          arrow: false
        }
      }
    };
  },
  methods: {
    getTippyTextForRealTimeData() {
      const timeZone = 'America/Los_Angeles';
      let tooltipStringText =
        this.metricLabel === 'NA' || this.streamMetricData === 'NA'
          ? streamMetricTooltipWithNoData
          : streamMetricTooltipWithData;
      const currentDate = moment().tz(timeZone).format('MMM DD,YYYY');
      tooltipStringText = tooltipStringText.replace('date', currentDate);
      if (this.metricLabel !== 'NA' || this.streamMetricData !== 'NA') {
        const value = this.metricLabel || this.streamMetricData;
        tooltipStringText = tooltipStringText.replace('value', value);
        return tooltipStringText;
      }
      return tooltipStringText;
    }
  }
};
</script>
