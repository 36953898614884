<template>
  <div>
    <div
      class="u-font-size-5 u-font-weight-600 u-color-grey-light u-display-inline-block"
      :class="groupLabelClass"
    >
      {{ groupLabel }}
    </div>

    <div
      class="u-display-flex"
      :class="{ 'u-flex-direction-column': isColumn }"
    >
      <div
        v-for="({ type, label, desc }, index) in radioOptions"
        :key="index"
        class="global-radio-wrapper"
        :class="{
          'u-spacing-mr-l': radioOptions.length !== index + 1,
          'u-spacing-mb-l': isColumn
        }"
      >
        <div>
          <rb-radio
            v-model="selectedRadio"
            :native-value="type"
            :data-cy="`targetType-${label}`"
          >
            <div class="u-display-flex u-flex-align-items-center">
              {{ label }}
              <slot :name="`right_radio_${type}`" />
            </div>
          </rb-radio>
        </div>
        <div
          class="u-font-size-6 u-font-weight-400 u-color-grey-lighter u-spacing-mt-s u-spacing-ml-l u-line-height-1"
          :data-cy="`targetTypeDescription-${label}`"
        >
          {{ desc }}
        </div>
        <slot :name="`below_radio_${type}`" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioGroup',
  props: {
    value: {
      type: String,
      required: true
    },
    radioOptions: {
      type: Array,
      required: true
    },
    groupLabel: {
      type: String,
      default: ''
    },
    isColumn: {
      type: Boolean,
      default: false
    },
    groupLabelClass: {
      type: String,
      default: 'u-spacing-mb-s'
    }
  },
  computed: {
    selectedRadio: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit('onRadioChanged', newValue);
      }
    }
  }
};
</script>
