var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bulk-search-overview-popup",class:{ 'u-display-none': !_vm.showSearchOverviewPopup }},[_c('bulk-search-overview-header',{attrs:{"bulk-search-popup-props":_vm.bulkSearchPopupProps},on:{"closeSearchOverviewPopup":_vm.closeSearchOverviewPopup}}),_vm._t("custom-bulk-type"),_c('bulk-search-entity-search',{ref:"bulkSearchEntitySearch",attrs:{"bulk-search-popup-props":_vm.bulkSearchPopupProps},on:{"highlightEntitiesOnSearch":_vm.highlightEntitiesOnSearch,"exitEditMode":_vm.exitEditMode}}),(_vm.isTextAreaEnabled)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.pastedText),expression:"pastedText"}],staticClass:"bulk-searched-textarea",attrs:{"id":"bulkSearchInput","placeholder":_vm.bulkSearchPopupProps.textAreaPlaceholder},domProps:{"value":(_vm.pastedText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.pastedText=$event.target.value}}}):_c('div',{staticClass:"bulk-searched-entity-list-preview",attrs:{"id":"bulkSearchTermsPreview"},on:{"click":_vm.enableTextArea}},[(_vm.entitiesToPreview.length)?_c('div',_vm._l((_vm.entitiesToPreview),function(entity){return _c('div',{key:entity.id},[(entity.isDuplicate)?_c('rb-icon',{staticClass:"u-color-orange-base rb-icon--small u-cursor-pointer",attrs:{"icon":"warning"}}):_vm._e(),_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            placement: 'top',
            arrow: false,
            interactive: true,
            distance: 5,
            size: 'regular',
            maxWidth: '300px'
          }),expression:"{\n            placement: 'top',\n            arrow: false,\n            interactive: true,\n            distance: 5,\n            size: 'regular',\n            maxWidth: '300px'\n          }"}],class:{ 'u-spacing-pl-xs': entity.isDuplicate },attrs:{"title":_vm.getTooltipTitle(entity.isDuplicate)}},[_c('span',{staticClass:"u-spacing-mr-s u-font-size-5",domProps:{"innerHTML":_vm._s(entity.entityValue)}})])],1)}),0):_c('div',[_c('div',{staticClass:"u-color-grey-lighter"},[_vm._v(" Type or Paste inputs, each of them seperated with a new line ")])])]),_c('bulk-search-overview-footer',{attrs:{"search-overview-entity-list":_vm.searchOverviewEntityList,"max-paste-limit":_vm.bulkSearchPopupProps.maxPasteLimit,"invalid-entity-count":_vm.invalidEntityCount,"is-text-area-enabled":_vm.isTextAreaEnabled},on:{"handleViewInvalidEntitiesClick":_vm.handleViewInvalidEntitiesClick,"handleDeleteInvalidEntitiesClick":_vm.handleDeleteInvalidEntitiesClick}}),_c('bulk-search-overview-actions',_vm._g({ref:"bulkSearchOverviewActions",attrs:{"pasted-text":_vm.pastedText,"pastedTextWhileSearchApplied":_vm.previouslySearchedText},on:{"handleClearAll":_vm.handleClearAll}},_vm.$listeners))],2)}
var staticRenderFns = []

export { render, staticRenderFns }