import { set, get, cloneDeep } from 'lodash';
const actionToRecMap = {
  keywordBidChange: {
    action_type: 'action_payload.actionType',
    campaignType: 'action_payload.actionPayload.campaignType',
    campaign_name: 'campaign_name',
    current_bid: 'action_payload.actionPayload.previousBid',
    keyword_id: 'action_payload.actionPayload.keywordId',
    new_bid: 'action_payload.actionPayload.newBid',
    new_keyword_text: 'keyword_text',
    new_match_type: 'match_type_keyword',
    profile_id: 'action_payload.actionPayload.profileId'
  },
  campaignBudgetChange: {
    action_type: 'action_payload.actionType',
    campaignType: 'action_payload.actionPayload.campaignType',
    campaign_id: 'action_payload.actionPayload.campaignId',
    campaign_name: 'action_payload.viewPayload.name',
    daily_budget: 'action_payload.actionPayload.previousBudget',
    new_daily_budget: 'action_payload.actionPayload.newBudget',
    profile_id: 'action_payload.actionPayload.profileId'
  }
};
const recToActionMap = {
  campaignBudgetChange: {
    'actionPayload.newBudget': 'new_daily_budget',
    'viewPayload.newBudget': 'new_daily_budget'
  },
  keywordBidChange: {
    'actionPayload.newBid': 'new_bid',
    'viewPayload.newBid': 'new_bid'
  }
};
export default {
  formatToRecommendationPayload: (selections, actionType) => {
    // transforming action_payload to that of recommendations inorder to use the same actions-workspace component.
    selections.map((row) => {
      row.action_payload_for_api = cloneDeep(row.action_payload);
      row.isLoading = false;
      row.action_payload = JSON.parse(row.action_payload);
      row.profile_id = get(row, 'action_payload.actionPayload.profileId');
      const action = {};
      for (const property in actionToRecMap[actionType]) {
        action[property] = get(row, actionToRecMap[actionType][property]);
      }
      row.action_payload = {};
      row.action_payload[actionType] = {
        actions: JSON.stringify([action])
      };
      row.action_payload = JSON.stringify(row.action_payload);
    });
  },
  generateEditActionPayload: (selection, action, actionType) => {
    const payload = JSON.parse(selection.action_payload_for_api);
    for (const property in recToActionMap[actionType]) {
      const newValue = action[recToActionMap[actionType][property]];
      if (newValue < 0) {
        return null;
      }
      set(payload, property, newValue);
    }
    return [payload];
  }
};
