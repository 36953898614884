<template>
  <div>
    <div class="u-spacing-mv-s u-width-100">
      <div class="u-display-flex u-flex-align-items-center u-width-100">
        <span class="u-font-size-6 u-font-weight-600">Time period:</span>
        <SelectMonth
          ref-id="start_month"
          :selected-month="startMonth"
          :month-options="startMonthOptions"
          @onMonthChange="(selection) => onMonthChange(selection, 'start')"
        />
        <div class="u-spacing-ml-s u-font-size-4">to</div>
        <SelectMonth
          ref-id="end_month"
          :selected-month="endMonth"
          :month-options="endMonthOptions"
          @onMonthChange="(selection) => onMonthChange(selection, 'end')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import SelectMonth from '@/components/ams/media-planner/generate-plan/steps/month-range/select-month.vue';
import moment from 'moment';
import {
  filteredHistoricalDataAPIConfig,
  getPreviousPeriodDates,
  FETCH_FILTERED_HISTORICAL_DATA_ACTION,
  FILTERED_HISTORICAL_DATA_GETTER,
  organizeOptions
} from '@/components/ams/media-planner/config.js';
export default {
  components: {
    SelectMonth
  },
  props: {
    storeData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    updateKeyInStore: {
      type: Function,
      default: () => {
        return () => null;
      }
    }
  },
  data() {
    return {
      startMonthOptions: [],
      endMonthOptions: [],
      startMonth: '',
      endMonth: ''
    };
  },
  computed: {
    filteredHistoricalDataLength() {
      const { data } = this.$store.getters[FILTERED_HISTORICAL_DATA_GETTER];
      return Object.keys(data || {})?.length;
    }
  },
  created() {
    this.setMonthSelectionOptions();
  },
  methods: {
    updateMonths(startMonth, endMonth) {
      const updatedMonths = {
        startMonth,
        endMonth
      };
      if (startMonth) {
        updatedMonths.startMonth = moment(startMonth)
          .startOf('month')
          .format('MMM DD, YYYY');
      }
      if (endMonth) {
        updatedMonths.endMonth = moment(endMonth)
          .endOf('month')
          .format('MMM DD, YYYY');
      }
      this.updateKeyInStore('months', updatedMonths);

      this.createPayloadAndFetch(updatedMonths);
      return updatedMonths;
    },
    setMonthSelectionOptions() {
      const {
        months: { startMonth, endMonth },
        monthOptions: { startMonthOptions, endMonthOptions }
      } = this.storeData;
      this.startMonth = moment(startMonth).format('MMM YYYY');
      this.endMonth = moment(endMonth).format('MMM YYYY');
      this.startMonthOptions = startMonthOptions;
      this.endMonthOptions = endMonthOptions;
    },
    snackbar(message) {
      this.$snackbar.open({
        message: message,
        duration: 5000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    createPayloadAndFetch({ startMonth, endMonth }) {
      const {
        businessUnit,
        filteredBusinessUnit,
        organizeType,
        filters,
        mediaPlanId,
        showPlansAtBULevel
      } = this.storeData;
      if (mediaPlanId || this.filteredHistoricalDataLength) {
        const isOverall = organizeType === organizeOptions.OVERALL.value;
        const bU = isOverall ? businessUnit : filteredBusinessUnit;
        const APIConfig = { ...filteredHistoricalDataAPIConfig };
        APIConfig.metricsList = [...APIConfig.metricsList];
        const { start, end } = getPreviousPeriodDates({
          startMonth,
          endMonth
        });
        APIConfig.where.date.from = start;
        APIConfig.where.date.to = end;
        if (!isOverall) {
          const dimensionNameValueList = [];
          Object.keys(filters).forEach((key) => {
            filters[key].forEach((j) => {
              dimensionNameValueList.push({
                dimensionName: key,
                dimensionValue: j
              });
            });
          });
          APIConfig.where.dimensionNameValueList = dimensionNameValueList;
        }
        if (!isOverall || showPlansAtBULevel) {
          APIConfig.entityType = bU?.dimensionName?.toLowerCase();
          APIConfig.metricsList = [
            ...APIConfig.metricsList,
            bU?.dimensionName?.toLowerCase()
          ];
        }
        this.$store.dispatch(FETCH_FILTERED_HISTORICAL_DATA_ACTION, {
          APIConfig,
          filtersUpdated: true,
          overall: isOverall && !showPlansAtBULevel,
          isIC: bU?.dimensionType === 'CLIENT_INTERNAL_CATALOGUE',
          applyFiltersForOverall: !isOverall,
          snackbar: this.snackbar
        });
      }
    },
    onMonthChange(selection, type) {
      const { value, title } = selection;
      if (type === 'start') {
        this.startMonth = title;
        let foundMonth = false;
        this.endMonthOptions = this.startMonthOptions.filter((month) => {
          if (!foundMonth) {
            foundMonth = month.title === title;
          }
          return foundMonth;
        });
        const { startMonth } = this.updateMonths(value, this.endMonth);
        const monthsDiff = moment(this.endMonth).diff(
          moment(startMonth),
          'months',
          true
        );
        if (monthsDiff < 0) {
          this.endMonth = this.startMonth;
          this.updateMonths(this.startMonth, value);
        }
      } else {
        this.endMonth = title;
        this.updateMonths(this.startMonth, value);
      }
    }
  }
};
</script>
