<template>
  <div
    class="u-display-flex u-flex-direction-column u-width-100 u-position-relative"
  >
    <filter-select-all
      :is-all-selected="isAllSelected"
      :options-after-search="optionsAfterSearch"
      :search-text="searchText"
      :is-all-disabled="false"
      :is-some-disabled="false"
      @handleFilterSelectAll="handleFilterSelectAll"
    />
    <div
      v-for="(item, index) in optionsAfterSearch"
      v-if="data && data.length > 0"
      class="u-cursor-pointer u-spacing-pv-s u-spacing-ph-m"
    >
      <div
        class="u-display-flex u-width-100 u-flex-justify-content-space-between"
      >
        <div
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-overflow-auto"
          @click="handleClick(item)"
        >
          <rb-icon
            class="u-flex-align-self-flex-start u-flex-0 rb-icon--medium"
            :class="{
              'u-color-blue-base': tempSelections[item.title],
              'u-color-grey-lighter': !tempSelections[item.title]
            }"
            :icon="
              'checkbox-' + (tempSelections[item.title] ? 'selected' : 'empty')
            "
          />
          <div
            :title="item.title"
            class="u-font-weight-normal u-spacing-pl-s u-line-height-1 u-font-size-5 u-color-grey-light"
            :class="{
              'u-text-overflow-ellipsis-3-line': checkForLongTitle(item.title),
              'u-text-overflow-ellipsis': !checkForLongTitle(item.title)
            }"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="data && data.length === 0"
      class="u-spacing-m-m u-font-size-7 u-text-align-center u-color-grey-light"
    >
      No Results
    </div>
    <loader
      v-if="!data"
      class="fill--parent"
      :loading="!data"
      :color="'#007cf6'"
    />
  </div>
</template>
<script>
import HttpService from '@/utils/services/http-service';
import loader from '@/components/basic/loader';
import filterSelectAll from '@/components/widgets/filter/filterSelectAll.vue';
import { isEmpty } from 'lodash';
export default {
  name: 'CustomShelf',
  components: {
    loader,
    filterSelectAll
  },
  props: {
    filterType: {
      type: String,
      default: 'DIGITAL_SHELF'
    },
    selections: {
      type: Array,
      default: () => []
    },
    availableOptionsCount: {
      type: Number,
      default: 0
    },
    selectedSelectionsCount: {
      type: Number,
      default: 0
    },
    searchText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      filterEntityTypeMap: {
        DIGITAL_SHELF: 'keyword',
        CUSTOM_LISTS: 'campaign'
      },
      data: null,
      tempSelections: {},
      isAllSelected: false
    };
  },
  computed: {
    optionsAfterSearch() {
      if (!this.data) {
        return;
      }
      var data = this.data;
      var _data = [];
      for (var i = 0; i < data.length; i++) {
        if (
          data[i].title
            .toString()
            .toLowerCase()
            .indexOf(this.searchText.toLowerCase()) !== -1
        ) {
          _data.push(data[i]);
        }
      }
      return _data;
    },
    optionsAfterSearchMinusDisabledDeselections() {
      // This is for getting the total number of options which are present after search and in disabled-not-selected state.
      return this.optionsAfterSearch.filter(
        (item) => item.enable || this.isItemSelected(item.title) === 'selected'
      );
    },
    isSelectionsAndDataLengthEqual() {
      return this.selectedSelectionsCount === this.availableOptionsCount;
    }
  },
  watch: {
    selections(val) {
      this.tempSelections = {};
      val.map((item) => {
        this.tempSelections[item] = true;
      });
    },
    searchText() {
      this.$emit('options-fetched', this.optionsAfterSearch);
    }
  },
  created() {
    this.isAllSelected = false;
    if (!this.data) {
      this.getCustomShelfData(this.filterEntityTypeMap[this.filterType]);
    }
    this.selections.map((item) => {
      this.tempSelections[item] = true;
    });
    !isEmpty(this.tempSelections) && this.isSelectionsAndDataLengthEqual
      ? (this.isAllSelected = true)
      : (this.isAllSelected = false);
    this.$parent.$on('clearAllSelection', this.clearAllSelection);
  },
  methods: {
    handleClick(item) {
      this.$set(
        this.tempSelections,
        item.title,
        !this.tempSelections[item.title]
      );
      this.$emit(
        'selection',
        Object.keys(this.tempSelections).filter((item) => {
          return this.tempSelections[item] === true;
        })
      );
      this.checkIfAllSelected();
    },
    async getCustomShelfData(type) {
      const config = {
        entityType: type || 'keyword',
        tagType: 'custom',
        customType: 'Custom'
      };
      const response = await HttpService.get('FETCH_SUGGESTED_ENTITY_TAG', {
        append: `?entityType=${config.entityType}&tagType=${config.tagType}&customType=${config.customType}`
      });
      const result = response.data
        .sort((a, b) => {
          if (a.level1 < b.level1) {
            return -1;
          }
          if (a.level1 > b.level1) {
            return 1;
          }
          return 0;
        })
        .map((item) => {
          return {
            title: item.level1
          };
        });
      this.data = result;
      this.$emit('options-fetched', result);
    },
    handleFilterSelectAll() {
      this.mutations = true;
      if (
        this.optionsAfterSearch.length ===
        Object.keys(this.tempSelections).length
      ) {
        this.tempSelections = {};
        this.$emit(
          'selection',
          Object.keys(this.tempSelections).filter((item) => {
            return this.tempSelections[item] === true;
          })
        );
        if (this.isAllSelected) this.isAllSelected = false;
      } else {
        this.optionsAfterSearch.forEach((item) => {
          if (!this.tempSelections[item.title]) {
            this.handleClick(item);
          }
        });
      }
      this.checkIfAllSelected();
    },
    checkIfAnyOptionUnselected() {
      for (let i = 0; i < this.optionsAfterSearch.length; i++) {
        if (!this.tempSelections[this.optionsAfterSearch[i].title]) {
          this.isAllSelected = false;
          break;
        }
        this.isAllSelected = true;
      }
    },
    checkIfAllSelected() {
      if (this.enableMinimumSelection) {
        if (this.tempSelections.length === 0) {
          this.mutations = false;
        }
      }
      if (this.searchText) {
        if (this.isAllSelected) {
          this.checkIfAnyOptionUnselected();
        } else {
          const optionsFilteredBySearchTerm = this.optionsAfterSearch.filter(
            (item) =>
              item?.title
                ?.toLowerCase()
                .indexOf(this.searchText.toLowerCase()) !== -1
          );
          this.isAllSelected =
            this.filterTempSelectionBySearchTerm().length ===
            optionsFilteredBySearchTerm.length;
        }
      } else {
        this.checkIfAnyOptionUnselected();
      }
    },
    filterTempSelectionBySearchTerm() {
      return Object.keys(this.tempSelections).filter(
        (title) =>
          title?.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1
      );
    },
    isItemSelected(title) {
      return this.tempSelections[title] ? 'selected' : 'empty';
    },
    clearAllSelection(data) {
      this.isAllSelected = false;
    },
    checkForLongTitle(title) {
      return title && title.length > 44;
    }
  }
};
</script>
