<template>
  <div
    class="u-display-flex u-margin-top-10 u-margin-bottom-10 u-flex-justify-content-center"
  >
    <span class="u-font-size-6 default-text">
      {{ nonClickableText }}
    </span>
    <div class="u-display-flex u-align-items-flex-center">
      <span
        v-if="isBulkSelectClicked"
        class="u-color-blue-base u-cursor-pointer u-font-size-6 clickable-text change-bg-color"
        @click="onClickClear"
      >
        {{ 'Clear Selection' }}
      </span>
      <span
        v-else
        class="u-color-blue-base u-cursor-pointer u-font-size-6 clickable-text"
        @click="onClickSelectAll"
      >
        {{ clickableText }}
      </span>
    </div>
  </div>
</template>

<script>
import { formatter } from '@/utils/helpers/formatter.js';

export default {
  components: {},
  props: {
    currentSelectedEntities: {
      type: Number,
      default: 0
    },
    totalEntities: {
      type: Number,
      default: 0
    },
    isBulkSelectClicked: {
      type: Boolean,
      default: false
    },
    pageKey: {
      type: String,
      default: ''
    },
    isBulkSelectedCleared: {
      type: Function,
      default: () => {}
    },
    pageLimit: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  computed: {
    clickableText() {
      return `Select all ${formatter(this.totalEntities, 'number', 'NA')} ${
        this.pageKey
      }`;
    },
    nonClickableText() {
      let text = `All ${formatter(this.currentSelectedEntities)} ${
        this.pageKey
      } are selected. `;
      if (this.isBulkSelectClicked) {
        text = `All ${formatter(this.totalEntities)} ${
          this.pageKey
        } are selected. `;
      }
      return text;
    }
  },
  methods: {
    onClickSelectAll() {
      this.$emit('bulkSelectClicked');
    },
    onClickClear() {
      this.$emit('isBulkSelectedCleared');
    }
  }
};
</script>

<style scoped>
.default-text {
  white-space: pre;
  padding: 5px 0 !important;
}
.clickable-text {
  height: fit-content;
  width: fit-content;
  padding: 5px 5px !important;
  background-color: #f8f8f8;
}
.change-bg-color {
  background-color: #e6f3ff !important;
}
.clickable-text:hover {
  border-radius: 3px;
  background-color: #e6f3ff;
}
</style>
