<template>
  <!-- Bid Change Panel -->
  <section class="bulk-actions">
    <header
      class="title u-font-size-5 u-font-weight-600 u-spacing-pt-l u-spacing-ph-l u-text-case-upper"
    >
      {{ heading }}
    </header>
    <div class="u-spacing-ph-l u-spacing-pb-l u-spacing-pt-m">
      <div
        v-if="radioOptions.length > 1"
        class="u-spacing-mb-m"
      >
        <p
          class="u-font-size-5 u-font-weight-600 u-color-grey-base"
          data-cy="radioHeading"
        >
          {{ radioHeading }}
        </p>
        <RadioGroup
          :radio-options="radioOptions"
          :value="actionType"
          :container-class="'global-radio-wrapper u-spacing-mr-l u-spacing-mt-m'"
          @onRadioChanged="handleRadioSelection"
        />
      </div>
      <p class="u-font-size-6 u-color-grey-lighter u-spacing-pb-m">
        <span class="u-color-black-base u-font-weight-600">{{
          secondaryText[0]
        }}</span>
        {{ secondaryText[1] }}
      </p>

      <div class="u-position-relative">
        <div
          v-show="groupsRows.length === 0"
          class="fill-message fill--parent u-font-size-3"
          style="z-index: 90"
        >
          No ad groups are available for selected targeting type
        </div>
        <component
          :is="customActionPanelCustomState.component"
          v-if="customActionPanelCustomState"
          :key="customActionPanelComponentKey"
          class="add-sku-campaign-creation"
          v-bind="customActionPanelCustomState.props"
          v-on="customActionPanelCustomState.events"
        >
          <div slot="aboveLeftCustomBid">
            <slot name="aboveLeftCustomBid" />
          </div>
          <div
            v-if="typeof customStateMap[actionType] === 'object'"
            slot="leftTableHeader"
          >
            <GenericTabsComponent
              :tabs-list="tabList"
              :default-tab-index="defaultTabIndex"
              @handleTabsClick="handleTabsClick"
            />
          </div>
          <div
            v-if="groupsRows.length > 0"
            slot="result-body-header"
            class="u-spacing-ph-l u-spacing-pv-m"
          >
            <p class="u-font-size-5 u-font-weight-600">Ad Groups:</p>
            <div class="u-display-flex u-flex-align-items-flex-end u-width-50">
              <div
                class="u-border-radius-s u-border-all u-border-width-xs u-border-color-grey-xxx-light u-font-size-6 u-spacing-p-xs u-display-inline-block u-spacing-mr-s u-text-overflow-ellipsis"
              >
                {{ firstCampaignName }}... + {{ groupsRows.length - 1 }} more
              </div>
              <TippyTableWithHeader
                header-text="Ad Groups"
                :column-definition="groupsTableColDefinition"
                :data-rows="groupsRows"
                @onSelectionChange="onSelectionChange"
              >
                <rb-button
                  slot="trigger-content"
                  class="rb-button"
                  :text="'Edit'"
                  :type="'filled'"
                  size="s"
                  icon-left="pencil"
                />
              </TippyTableWithHeader>
            </div>
          </div>
        </component>
      </div>
    </div>
  </section>
</template>

<script>
import loader from '@/components/basic/loader';
import messageBar from '@/components/ams/actionPanels/messageBar.vue';
import baseSplitPanelTemplate from './baseSplitPanelTemplate.vue';
import RadioGroup from '@/components/ams/campaign_creation/steps/common/rbRadioGroupSubLabel.vue';
import CustomActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';
import TippyTableWithHeader from '@/components/ams/actionPanels/table-tooltip.vue';
import GenericTabsComponent from '@/components/basic/genericTabsComponent.vue';

export default {
  name: 'BulkActions',
  components: {
    loader,
    messageBar,
    baseSplitPanelTemplate,
    RadioGroup,
    CustomActionPanel,
    TippyTableWithHeader,
    GenericTabsComponent
  },
  props: {
    radioOptions: {
      type: Array,
      default: () => []
    },
    heading: {
      type: String,
      default: ''
    },
    radioHeading: {
      type: String,
      default: ''
    },
    secondaryText: {
      type: Array,
      default: () => []
    },
    customStateMap: {
      type: Object,
      default: () => {}
    },
    groupsTableColDefinition: {
      type: Array,
      default: () => []
    },
    groupsRows: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      actionType: this.radioOptions?.[0].type,
      customActionPanelCustomState:
        this.customStateMap[this.radioOptions?.[0]?.type](this),
      customActionPanelComponentKey: 0,
      defaultTabIndex: 0
    };
  },
  computed: {
    firstCampaignName() {
      return this.groupsRows[0]?.campaign_name;
    },
    tabList() {
      return (
        this.customStateMap[this.actionType]?.tabList || [
          'Categories',
          'Products'
        ]
      );
    }
  },
  methods: {
    handleRadioSelection(newValue) {
      this.customActionPanelComponentKey += 1;
      this.$emit('handleRadioSelection', newValue);
      this.$emit('onSelectionChange', []);
      this.actionType = newValue;
      const typeOfActionTypeMap = typeof this.customStateMap[this.actionType];
      if (typeOfActionTypeMap === 'object') {
        const firstTab = this.tabList[0].toLowerCase();
        this.customActionPanelCustomState =
          this.customStateMap[this.actionType][firstTab](this);
      } else {
        this.customActionPanelCustomState =
          this.customStateMap[this.actionType](this);
      }
    },
    handleTabsClick(value) {
      if (value) {
        this.customActionPanelCustomState =
          this.customStateMap[this.actionType][value.toLowerCase()](this);
        const tabIndex = this.tabList.findIndex((tab) => value === tab);
        this.defaultTabIndex = tabIndex;
      }
      this.customActionPanelComponentKey =
        this.customActionPanelComponentKey += 1;
    },
    onSelectionChange(newValue) {
      this.$emit('onSelectionChange', newValue);
    }
  }
};
</script>

<style lang="css">
.bulk-actions .rb-button .icon-pencil:before {
  font-size: 13px;
}
.bulk-actions .fill-message {
  position: absolute;
  display: flex;
  text-align: center;
  opacity: 0.75;
  background: #fff;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}
</style>
