export const widgetsConfig = {
  config: {
    filters: {
      emit: 'businessInsightsFilterApplied',
      hasSearch: false,
      addToFilter: 'businessInsightsAddToFilter'
    }
  },
  filterMapping: {
    CLIENT_INTERNAL_CATALOGUE: 'Internal Categorization',
    AVC_CATALOGUE: 'AVC Categorization',
    AMAZON_CATEGORY: 'Retailer Categorization',
    RETAILER_CATEGORY: 'Retailer Categorization',
    OTHERS: undefined,
    SAVED: 'Saved Filters',
    CLIENT_SAVED_FILTERS: 'Saved Filters',
    MY_SAVED_FILTERS: 'My Saved Filters',
    STRATEGY: 'Impacted by',
    EXPRESSION: 'Expression (Column) Filters',
    COLUMN: 'Column Filters',
    SMART_FILTERS: 'CIQ Categorization'
  },
  pinnedChipCountryFilterMetadata: {
    primaryData: {
      dimensionColumn: 'region',
      dimensionLabel: 'Country',
      dimensionName: 'region',
      dimensionType: 'CLIENT_INTERNAL_CATALOGUE'
    },
    data: [],
    filterV2: false,
    showSelectAll: true
  }
};
