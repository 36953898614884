<template>
  <div
    v-tippy="{ placement: 'top', arrow: false }"
    class="config-icon-container u-display-flex u-flex-direction-column u-flex-align-items-items-center u-flex-align-justify-content-center"
    :title="(config.tooltip || {}).title"
    :class="[
      'u-color-' + (((config.icon || {}).class || {}).color || 'grey-lighter'),
      {
        'u-cursor-pointer': (config.click || {}).emit
      },
      config.extraClasses
    ]"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    @click="handleClick"
  >
    <rb-icon
      :icon="config.icon.icon"
      :style="config.icon.style"
      :class="[
        'rb-icon--' + (((config.icon || {}).class || {}).size || 'medium')
      ]"
    />
    <div
      v-if="config.subText"
      :class="[
        'u-font-size-' +
          ((((config.subText || {}).text || {}).class || {}).size || '7'),
        'u-spacing-mt-' + (((config.icon || {}).class || {}).marginTop || 'xs')
      ]"
    >
      {{ config.subText.text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: {}
    }
  },
  methods: {
    getPayload(evt) {
      return {
        context: this,
        event: evt
      };
    },
    handleMouseEnter(evt) {
      this.$emit('mouseEnter', this.getPayload(evt));
    },
    handleMouseLeave(evt) {
      this.$emit('mouseLeave', this.getPayload(evt));
    },
    handleClick(evt) {
      if (this.config?.click?.emit) {
        this.$emit(this.config?.click?.emit, this.getPayload(evt));
      }
    }
  }
};
</script>

<style lang="css" scoped>
.config-icon-container :hover {
  opacity: 0.8;
}
</style>
