<template>
  <!-- Header Start -->
  <section class="media-planner-setup--header u-display-flex u-spacing-p-l">
    <div class="u-flex-1">
      <div class="u-display-flex u-flex-align-items-flex-end">
        <span @click="handleGoBack()">
          <rb-icon
            icon="cross"
            class="u-color-grey-lighter u-cursor-pointer is-flipped u-spacing-mr-s"
            :class="'rb-icon--x-medium'"
          />
        </span>
        <div
          class="u-display-flex"
          :style="{ width: `calc(${headerStyle()} + 30px)` }"
        >
          <input
            ref="media-planner-input"
            :value="title"
            type="text"
            :class="{
              'u-pointer-events-none': isEditVisible,
              'u-width-100': title,
              'text-underline': !isEditVisible && title
            }"
            class="heading--input u-text-overflow-ellipsis u-font-size-2 u-font-weight-bold"
            :placeholder="placeholder"
            @click.self.stop=""
            @input="handleEditTitle"
            @keydown="onPressEnter"
          />
          <div
            v-if="isEditVisible && !savingName"
            @click.stop="handleEditBtnClick"
          >
            <rb-icon
              class="rb-icon--x-medium u-cursor-pointer u-color-grey-lighter"
              icon="pencil"
            />
          </div>
          <Loader
            :loading="savingName"
            :color="'#8b8f93'"
          />
        </div>
        <div
          v-if="showRightBtn"
          class="u-spacing-ml-auto u-display-flex"
        >
          <rb-button
            class="u-spacing-mr-l"
            :disabled="loading || disabledDownloadBtn"
            :click-fn="handleClickDownloadBtn"
            text="Budget Plan"
            type="filled"
            icon-left="download"
          />
          <rb-select
            :open-direction="'bottom-end'"
            :send-details="true"
            :options="actions"
            :on-close="actionSelected"
          >
            <div
              slot="trigger"
              class="u-more-action-color u-cursor-pointer u-more-action u-flex-1 u-border-all u-border-radius-s u-border-width-s u-color-grey-lighter u-border-color-grey-xxx-light u-display-flex u-max-width-100 u-flex-align-items-center u-spacing-p-s"
            >
              <rb-icon
                class="rb-icon--medium u-spacing-mh-s"
                :icon="'more-horizontal'"
                style="transform: rotate(270deg) scaleY(1.3)"
              />
              <div
                class="u-flex-1 u-font-size-6 u-font-weight-600 u-text-overflow-ellipsis u-spacing-mr-s"
              >
                {{ ' More Actions' }}
              </div>
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <div
                v-tippy="{
                  placement: 'bottom',
                  maxWidth: '200px',
                  distance: -30,
                  arrow: false
                }"
                class="u-flex-0 u-display-flex u-flex-align-items-center u-cursor-pointer u-action-padding"
                :class="{ 'disabled-btn': option.disabledBOSheet }"
                :title="
                  option.disabledBOSheet
                    ? option.disabledTippyTitle
                    : option.tippyTitle
                "
              >
                <rb-icon
                  v-if="option.icon"
                  class="u-color-grey-base"
                  :class="option.class"
                  :icon="option.icon"
                />
                <div
                  class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis u-color-grey-base"
                >
                  {{ option.name }}
                </div>
              </div>
            </template>
          </rb-select>
        </div>
      </div>
      <div
        class="u-font-size-6 u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-color-red-base u-spacing-mt-xs"
      >
        <div
          v-if="errorMessage"
          class="u-display-flex u-flex-align-items-center"
        >
          <rb-icon
            class="u-flex-0 rb-icon--small"
            icon="error-fill"
          />
          <div class="u-flex-0 u-spacing-ml-xxs">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Header ends -->
</template>

<script>
import Loader from '@/components/basic/loader';
export default {
  components: {
    Loader
  },
  model: {
    prop: 'title',
    event: 'handleEditTitle'
  },
  props: {
    title: {
      type: String,
      required: true
    },
    isEditVisible: {
      type: Boolean,
      default: true
    },
    showRightBtn: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabledBOSheet: {
      type: Boolean,
      default: false
    },
    disabledDownloadBtn: {
      type: Boolean,
      default: false
    },
    savingName: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      actions: [
        {
          icon: 'download',
          name: 'Download BO Sheet',
          class: 'rb-icon--small u-spacing-mr-s',
          tippyTitle: 'Download this plan with Budget Optimizer Template',
          disabledTippyTitle:
            'This sheet is only available for Profile and Portfolio Dimensions'
        }
      ]
    };
  },
  created() {
    this.$emit('setHeaderRef', this);
  },
  methods: {
    headerStyle() {
      if (this.isEditVisible) {
        if (this.title.length === 0) {
          return `${this.placeholder.length * 3}ch`;
        } else if (this.title.length * 11.5 < 800) {
          return `${this.title.length * 10.05}px`;
        }
        return '42%';
      }
      return '100%';
    },
    actionSelected(__, data) {
      if (data?.length) {
        const { name } = data[0];
        if (name === 'Download BO Sheet') {
          this.$emit('handleClickBOButton');
        }
      }
    },
    handleGoBack() {
      this.$emit('handleGoBack');
    },
    handleEditBtnClick() {
      this.$refs?.['media-planner-input']?.focus();
      this.$emit('handleChangeIsEditVisible', false);
    },
    handleEditTitle(event) {
      this.$emit('handleEditTitle', event.target.value);
    },
    handleClickDownloadBtn() {
      this.$emit('handleClickDownloadBtn');
    },
    onPressEnter(e) {
      if (e.key === 'Enter') {
        this.$emit('removeFocus');
      }
    }
  }
};
</script>

<style lang="css">
.u-spacing-ml-auto {
  margin-left: auto;
}

.text-underline {
  text-decoration: underline;
  text-decoration-color: #8b8f93;
  text-decoration-thickness: 2px;
  text-decoration-skip-ink: none;
}

.heading--input {
  border: 0;
  outline: none;
  font-family: 'ProximaNova', sans-serif;
}
.heading--input:focus {
  border: 0;
  outline: none;
  font-family: 'ProximaNova', sans-serif;
}
.heading--input:focus:focus {
  border: 0;
  outline: none;
}

.media-planner-setup--header {
  z-index: 300;
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  position: relative;
}

.u-more-action {
  height: 36px;
}
.u-more-action:hover {
  border-color: #007cf6;
  color: #007cf6;
}
.u-action-padding {
  padding: 0 !important;
}

.disabled-btn {
  opacity: 0.4;
  cursor: not-allowed !important;
}
</style>
