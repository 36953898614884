<template>
  <section>
    <component
      :is="customState.component"
      v-if="customState && isCustomActionStateVisible"
      :parent-ref="this"
      :selected-values="tableSelections"
      :selections="tableSelections"
      :fetched-adgroups="adgroups"
      :load="adgroupLoader"
      v-bind="customState.props"
      v-on="customState.events"
    />
    <manageColumns
      :key="manageColumnsKey"
      :selected-columns="selectedColumns"
      :listener="manageColumnsPanelListener"
      :unselected-columns="unSelectedColumns"
      :detailed-metrics="manageColumnsMasterMetrics"
      :non-negotiable-columns="nonNegotiableColumns"
      @manageColumnsApply="manageColumnsApply"
    />
    <!-- TODO refactor load, fetched adgroupd, selected values, parent ref, selections into the config file -->
    <CardContainer
      :last-updated-date="maxDate"
      :show-last-updated-date="showLastUpdatedDate"
      :get-realtime-metadata="getRealtimeMetadata"
      horizontal-padding="none"
      body-bottom-padding="none"
      :bottom-padding="computedIsActionBarShown ? 'none' : undefined"
      :top-padding="computedIsActionBarShown ? 'none' : undefined"
      :action-icons="computedIsActionBarShown ? [] : actionIcons"
      :custom-components="computedIsActionBarShown ? [] : customComponents"
      v-on="actionIconEvents"
      @email="handleEmail"
      @refreshTable="refreshTable"
    >
      <section slot="body">
        <div
          v-if="computedIsActionBarShown"
          class="actions-bar--wrapper actionspace-dummy u-position-sticky top-0 z-index-2"
        >
          <ActionsBar
            :close-fn="closeActionsWorkflow"
            :selections="tableSelections"
            :recommendation-type="''"
            :passed-actions="actionBarActions"
            @open-workspace="openActionsWorkspace"
          />
        </div>
        <TableContainer
          :key="tableRenderKey"
          ref="table-container"
          :load="(dataSource || {}).load"
          :error="(dataSource || {}).error"
          :no-data="(dataSource || {}).noData"
          :config="tableConfig"
          :table-row="((dataSource || {}).rows || {}).tableRow"
          :table-column="tableColumns"
          :row-height="70"
          :pagination-total-key="
            (((dataService || {}).data || {}).rows || {}).totalRows
          "
          :pagination-per-page-limit="
            (((tableConfig || {}).body || {}).APIConfig || {}).limit || 30
          "
          v-bind="config.table.props"
          :show-pagination-options="true"
          v-on="config.table.events"
          @rowSelect="handleRowSelect"
        />
      </section>
    </CardContainer>
  </section>
</template>

<script>
import chartWithLegends from '@/components/basic/chart-with-legends.vue';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import TableContainer from '@/components/pages/insights/amazon/share-of-voice/organisms/table-container.vue';
import CardContainer from '@/pages/campaign-management/components/card-conatainer-no-destroy.vue';
import { formatter } from '@/utils/helpers/formatter.js';
import cellRenderer from '@/components/globals/dataTable/tableCellComponent.js';
import { eventBus } from '@/utils/services/eventBus';
import ActionsBar from '@/components/widgets/actionsBar.vue';
import BidChangePanel from '@/components/ams/actionPanels/bidChangePanel.vue';
import HttpService from '@/utils/services/http-service';
import { commons } from '@/components/ams/campaigns/commons.js';
import {
  EMAILID,
  getManageColumnHeaderIcon
} from '@/pages/campaign-management/constants.js';
import { requestDateReplacement } from '@/components/pages/insights/amazon/share-of-voice/templates/dashboard-service-utils.js';
import AddKeywordsPanel from '@/components/ams/actionPanels/addKeywordsPanel.vue';
import AdjustDates from '@/pages/campaign-management/retailers/walmart/AdjustDates.vue';
import EditOptimizationStrategy from '@/pages/campaign-management/retailers/criteo/line_items/EditOptimizationStrategy/index.vue';
import EditBudgetAndPacing from '@/pages/campaign-management/retailers/criteo/line_items/EditBudgetAndPacing/index.vue';
import EditLineItemName from '@/pages/campaign-management/retailers/criteo/line_items/EditLineItemName/index.vue';
import EditLineItemDates from '@/pages/campaign-management/retailers/criteo/line_items/EditLineItemDates/index.vue';
import AddProducts from '@/pages/campaign-management/retailers/criteo/line_items/AddProducts/index.vue';
import AddSearchTermAsKeywordPanel from '@/components/ams/actionPanels/addSearchTermAsKeywordPanel.vue';
import AddNegativeKeywordsPanel from '@/components/ams/actionPanels/addNegativeKeywordsPanel.vue';
import EditAttributionSettings from '@/pages/campaign-management/retailers/criteo/campaigns/EditAttributionSettings/index.vue';
import {
  fetchRealtimeTableData,
  isRealTimeUpdateEnabled
} from '@/pages/campaign-management/store.js';
import transformer from '@/utils/services/data-transformer';
import HttpLayer from '@/utils/services/http-layer';
import manageColumns from '@/components/widgets/manageColumnsSidebar.vue';
import manageColumnMixin from '@/components/widgets/custom_widgets/manage_column_mixin.js';
import AddAsinsPanel from '@/components/ams/actionPanels/addAsinsPanel.vue';
import customActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';

export default {
  components: {
    ActionsBar,
    chartWithLegends,
    TableContainer,
    CardContainer,
    BidChangePanel,
    AddKeywordsPanel,
    AdjustDates,
    AddNegativeKeywordsPanel,
    customActionPanel,
    EditOptimizationStrategy,
    EditBudgetAndPacing,
    EditLineItemName,
    EditLineItemDates,
    AddProducts,
    EditAttributionSettings,
    AddSearchTermAsKeywordPanel,
    manageColumns,
    AddAsinsPanel
  },
  mixins: [manageColumnMixin],
  props: {
    config: {
      type: Object,
      default: null
    },
    dataService: {
      type: Object,
      default: null
    },
    globalWhereClause: {
      type: Object,
      default: () => ({})
    },
    maxDate: {
      type: String,
      default: null
    },
    showPlotRowsChip: {
      type: Boolean,
      default: false
    },
    filterInstance: {
      type: Object,
      default: null
    }
  },
  data() {
    const tableConfig = this.config?.table?.props?.config || {
      body: {
        APIConfig: {
          page: 1,
          limit: 30
        }
      }
    };
    const actionIcons = [
      getManageColumnHeaderIcon(),
      ...this.config.headerOptions
    ];
    const that = this;
    const actionIconEvents = {
      openManageColumnsAction() {
        that.openManageColumns();
      },
      ...(this.config?.headerOptionsEvents || {})
    };
    const manageColumnsPanelListener =
      this.$store.getters.getRetailer +
      '_' +
      this.config.widget +
      '_manageColumnsPanelListener';
    return {
      adgroupLoader: false,
      adgroups: [],
      tableRenderKey: 1,
      rowSelectionMap:
        {} /* not used internally as of now, look for mutations in the corresponding config file */,
      actionBarActions: this.config?.actionsBar?.actions || [],
      actionIcons,
      actionIconEvents,
      localTableColumns: [],
      inputParams: {
        orderByList: cloneDeep(this.config?.api?.request?.orderByList || [])
      },
      customState: {},
      tableSelections: [],
      isCustomActionStateVisible: false,
      isActionBarShown: false,
      tableConfig,
      currentActionProperties: null,
      enableManageColumns: true,
      manageColumnsPanelListener,
      getMetadataStore: {
        campaign: 'campaignManagement/getCampaignsRealTimeMetadata',
        keyword: 'campaignManagement/getKeywordsRealTimeMetadata',
        skus: 'campaignManagement/getSkusRealTimeMetadata'
      }
    };
  },
  computed: {
    dataSource() {
      let { data: dataSource } = this.dataService;
      if (this?.config?.table?.dataSource) {
        if (this?.config?.table?.dataSource?.store) {
          dataSource =
            this.$store.getters[this?.config?.table?.dataSource.getter];
        }
      }
      return dataSource;
    },
    showLastUpdatedDate() {
      return this.$store.getters.getRetailer !== 'walmart';
    },
    getRealtimeMetadata() {
      const getter = this.getMetadataStore[`${this.config.widget}`];
      return this.$store.getters[getter];
    },
    computedIsActionBarShown() {
      return this.config?.actionsBar?.actions && this.isActionBarShown;
    },
    customComponents() {
      const dynamicComponents = [];
      if (this.config?.header?.customComponents) {
        dynamicComponents.push(...this.config.header.customComponents);
      }
      return dynamicComponents;
    }
  },
  watch: {
    tableSelections: async function (newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        if (!newVal.length) {
          this.closeActionsWorkflow();
        } else {
          const {
            config,
            selectionLength,
            dropDownSelection,
            removeSelectionState
          } = this.currentActionProperties || {};
          const currentActionConfig = config;
          if (
            currentActionConfig?.handler &&
            currentActionConfig?.reRunHandlerOnSelectionChange &&
            this.customState.component
          ) {
            this.callActionConfigHandler(
              currentActionConfig,
              selectionLength,
              dropDownSelection,
              removeSelectionState,
              newVal
            );
          }
        }
      }
    }
  },
  beforeDestroy() {
    if (this.config?.table?.props) {
      if (this.config?.table.props.paginationChangeEvent) {
        eventBus.$off(this.config.table.props.paginationChangeEvent);
      }
      if (this.config?.table.props?.sortingChangeEvent) {
        eventBus.$off(this.config.table.props.sortingChangeEvent);
      }
    }
  },
  created() {
    if (this.config?.table?.props) {
      if (this.config.table.props.paginationChangeEvent) {
        eventBus.$on(this.config.table.props.paginationChangeEvent, (data) => {
          this.$emit('plotRowData', null);
          this.$nextTick(() => {
            // event is triggered before values of limit is updated in the rb-insighst comp, hence using next tick to introduce handler after limit updated
            this.tableConfig.body.APIConfig.page = data;
            this.getData();
          });
        });
      }
      if (this.config.table.props.sortingChangeEvent) {
        eventBus.$on(this.config.table.props.sortingChangeEvent, (data) => {
          this.$emit('plotRowData', null);
          const newSortPayload = [
            {
              dimension: data.colDef.field,
              direction: data.sort.toUpperCase()
            }
          ];
          this.inputParams.orderByList = newSortPayload;
          this.getData();
        });
      }
    }
    this.getData();
  },
  methods: {
    refreshTable() {
      this.getData();
    },
    async fetchAdgroupsData(adgroupPayload, selections) {
      try {
        adgroupPayload.where.dimensionNameValueList = selections.map(
          (selectedItem) => {
            return {
              dimensionName: 'campaign_id',
              dimensionValue: selectedItem.data.campaign_id
            };
          }
        );
        this.adgroupLoader = true;
        const response = await HttpLayer.post({
          cube: 'EXECUTE_CUBE_API',
          APIData: adgroupPayload
        });
        this.adgroups = [];
        if (response.success) {
          this.adgroups = transformer.mergeResultDimension(response.data, true);
          // this.adgroups = this.adgroups.map((item, index) => {
          //   item.ad_group_name = 'ads_' + index;
          //   return item;
          // });
          // TODO - get ad group name added in the cube
        }
      } catch (e) {
      } finally {
        this.adgroupLoader = false;
      }
    },
    switchBlueBar() {
      this.$emit('openActivityLog', {
        show: false
      });
      this.$emit('showBlueBar', {
        show: true,
        pageType: this.config.messageBar.pageType
      });
    },
    resetPagination() {
      this.tableConfig.body.APIConfig.page = 1;
    },
    openActivityLog(obj) {
      this.$emit('showBlueBar', { show: false });
      const payload = {
        show: true,
        activityLogPage: this.config.activityBar.pageType,
        widget: this.config.widget,
        primaryKey: obj.params.data[this.config.primaryKey]
      };
      this.$emit('openActivityLog', payload);
    },
    handleEmail() {
      this.$emit('showModal');
      const emailAction = this.actionIcons.find((item) => item.id === EMAILID);
      this.$emit(
        'updateModalConfig',
        emailAction?.metadata?.modalConfig || 'emailRequestConfig'
      );
    },
    sendEmailRequest() {
      const tableApi = this.getEmailRequestCopy();
      const tableConfig = this.tableColumns;
      const fileSuffix = this.config?.email?.fileSuffix || '';
      const downloadApi = commons.getDownloadApiRequest(
        tableConfig,
        tableApi,
        fileSuffix
      );
      HttpService.post('EXECUTE_CUBE_API_EMAIL', downloadApi)
        .then((response) => {
          this.$snackbar.open({
            message: 'Email sent successfully'
          });
        })
        .catch((error) => {
          console.error('error', error);
          this.$snackbar.open({
            message: 'Could not send Email'
          });
        });
    },
    getTableConfig() {
      const retailer = this.$store.getters.getRetailer;
      const colDefs = this.getColumnDefinition(this.config.metrics, retailer);
      return colDefs;
    },
    setTableColumns() {
      this.localTableColumns = this.getTableConfig();
    },
    showActionBar(payload) {
      this.isActionBarShown = payload;
    },
    closeActionsWorkflow() {
      this.removeCustomCustomComponents();
      this.$emit('plotRowData', null);
    },
    removeCustomCustomComponents() {
      this.showActionBar(false);
      this.showCustomActionWidget(false);
      this.deselectAllInTable();
    },
    deselectAllInTable() {
      this.config?.table?.props?.gridOptions?.api?.deselectAll();
    },
    showCustomActionWidget(payload) {
      this.isCustomActionStateVisible = payload;
    },
    getValidSelections(selections, currentActionConfig) {
      if (currentActionConfig.getInvalidSelections) {
        const invalidSelections =
          currentActionConfig.getInvalidSelections(selections);
        if (!isEmpty(invalidSelections)) {
          invalidSelections.forEach((node) => {
            node.setSelected(false);
          });
          this.$snackbar.open({
            message: currentActionConfig.invalidSelectionsMessage,
            duration: 6000,
            buttonColor: '#f5d908',
            actionText: ''
          });
          return selections.filter((item) => !invalidSelections.includes(item));
        }
      }
      return selections;
    },
    async openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection,
      selections
    ) {
      try {
        if (actionId !== 'show-chart') {
          this.isActionBarShown = false;
          this.showCustomActionWidget(true);
          this.$emit('plotRowData', null);
        }
        const currentActionConfig = this.config.actionsBar.actions.find(
          (item) => item.id === actionId
        );
        const validSelections = this.getValidSelections(
          selections,
          currentActionConfig
        );
        if (isEmpty(validSelections)) {
          return;
        }
        const validSelectionsLength = validSelections.length;
        this.currentActionProperties = {
          actionId,
          removeSelectionState,
          validSelectionsLength,
          dropDownSelection,
          validSelections,
          config: currentActionConfig
        };
        this.customState = currentActionConfig.customState;
        if (currentActionConfig.handler) {
          this.callActionConfigHandler(
            currentActionConfig,
            validSelectionsLength,
            dropDownSelection,
            removeSelectionState,
            validSelections
          );
        }
      } catch (e) {
        console.error('e', e);
      }
    },
    async callActionConfigHandler(
      currentActionConfig,
      selectionLength,
      dropDownSelection,
      removeSelectionState,
      selections
    ) {
      const response = await currentActionConfig.handler.call(
        this,
        selectionLength,
        dropDownSelection,
        selections
      );
      if (currentActionConfig.customRenderHandler) {
        currentActionConfig.customRenderHandler(this, response);
      } else {
        if (removeSelectionState) {
          this.removeCustomCustomComponents();
        }
      }
    },
    updateActions(selections) {
      let updatedBarActions = this.actionBarActions.map((item) => {
        let returnItem = item;
        if (item.actionUpdater) {
          returnItem = item.actionUpdater.call(this, item, selections);
        }
        return returnItem;
      });
      this.actionBarActions = updatedBarActions.filter((item) => {
        if (item.enable !== undefined) {
          return item.enable;
        }
        return true;
      });
    },
    handleRowSelect(payload) {
      const { selections } = payload;
      this.isActionBarShown = !this.isCustomActionStateVisible
        ? !!selections.length
        : this.isActionBarShown;
      if (this.isActionBarShown) {
        this.updateActions(selections);
      }
      this.tableSelections = selections;
      if (this.config?.table?.events?.updateRowSelectionMap) {
        this.config.table.events.updateRowSelectionMap.call(this, payload);
      }
    },
    removeActionsState() {
      this.closeActionsWorkflow();
      this.tableSelections = [];
    },
    mutateColoumn(dictionary, obj, widget, index, retailer) {
      if (isRealTimeUpdateEnabled.includes(retailer)) {
        this.setRealtimeFields(dictionary, obj, widget, index, retailer);
      }
    },
    setKeyType(dictionary, i) {
      let keyType = 'string';
      if (dictionary[i].type === 'NUMERIC') {
        keyType = dictionary[i].unit ? dictionary[i].unit : 'NUMERIC';
      }
      if (dictionary[i].type === 'date') {
        keyType = dictionary[i].type;
      }
      return keyType;
    },
    getColumnDefinition(dictionary, retailer = '') {
      let columnDefs = [];
      const _columnDefs = [];
      for (const i in dictionary) {
        const showOnUi = dictionary[i].showOnUI ?? true;
        if (!showOnUi) {
          continue;
        }
        let obj = {};
        obj.field = i;
        obj.headerComponentFramework = 'genericTableHeader';
        const keyType = this.setKeyType(dictionary, i);
        obj.cellRendererParams = {
          keyType: keyType,
          formatterFn: formatter
        };
        obj.cellRenderer = cellRenderer;
        obj.headerName = dictionary[i].title;
        obj.title = dictionary[i].title;
        obj.headerComponentParams = {
          enableSorting: true
        };
        if (dictionary[i].sort) {
          obj.headerComponentParams.sort = dictionary[i].sort;
        }
        obj.keyOrder = dictionary[i].keyOrder;
        dictionary[i].pinned && (obj.pinned = dictionary[i].pinned);
        if (dictionary[i].toolTipText) {
          obj.headerComponentParams.toolTipText = dictionary[i].toolTipText;
        }
        obj.headerComponentParams.keyType = keyType;
        if (dictionary[i].callback) {
          obj = dictionary[i].callback(obj, this, dictionary[i]);
        }
        if (dictionary[i].cellClass) {
          obj.cellClass = dictionary[i].cellClass;
        }
        this.mutateColoumn(dictionary, obj, this.config.widget, i, retailer);
        _columnDefs.push(obj);
      }
      columnDefs = _columnDefs.sort((a, b) => {
        return a.keyOrder - b.keyOrder;
      });
      return columnDefs;
    },
    filteredDimensionNameList(
      dimensionNameValueList,
      dataGroup,
      bundleCubeExecutionRequest
    ) {
      const dimensionNameClone = cloneDeep(dimensionNameValueList);
      const { measuresList, groupByDimensionsList } =
        bundleCubeExecutionRequest[dataGroup];
      const result = dimensionNameClone.filter((value) => {
        const isCampaignCatogiraztionFilter =
          value.dimensionName.includes('dimension') &&
          dataGroup !== 'adv_metadata';
        const isFilterPresent =
          measuresList.includes(value.dimensionName) ||
          groupByDimensionsList.includes(value.dimensionName) ||
          (value.dimensionName === 'search' && dataGroup !== 'adv_metadata') ||
          isCampaignCatogiraztionFilter;
        return isFilterPresent;
      });
      return result;
    },
    appendWhereClause(requestCopy) {
      if (!requestCopy.where) {
        requestCopy.where = {};
      }
      requestDateReplacement(requestCopy.where, this.globalWhereClause);
      if (!requestCopy.where.dimensionNameValueList) {
        requestCopy.where.dimensionNameValueList = [];
      }
      requestCopy.where.dimensionNameValueList.push(
        ...this.globalWhereClause.dimensionNameValueList
      );
      const that = this;
      const isWalmart = this.$store.getters.getRetailer === 'walmart';
      if (requestCopy.bundleCubeExecutionRequest) {
        // If the request has bundleCubeExecutionRequest, then add where clause inside as well
        Object.keys(requestCopy.bundleCubeExecutionRequest).forEach(
          (dataGroup) => {
            if (!requestCopy.bundleCubeExecutionRequest[dataGroup].where) {
              requestCopy.bundleCubeExecutionRequest[dataGroup].where = {};
            }
            requestDateReplacement(
              requestCopy.bundleCubeExecutionRequest[dataGroup].where,
              that.globalWhereClause
            );
            if (
              !requestCopy.bundleCubeExecutionRequest[dataGroup].where
                ?.dimensionNameValueList
            ) {
              requestCopy.bundleCubeExecutionRequest[
                dataGroup
              ].where.dimensionNameValueList = [];
            }
            let newDimensionNameList =
              that.globalWhereClause.dimensionNameValueList;
            if (isWalmart) {
              newDimensionNameList = this.filteredDimensionNameList(
                that.globalWhereClause.dimensionNameValueList,
                dataGroup,
                requestCopy.bundleCubeExecutionRequest
              );
              if (dataGroup === 'adv_metadata') {
                requestCopy.bundleCubeExecutionRequest[dataGroup].where.date =
                  {};
                requestCopy.bundleCubeExecutionRequest[
                  dataGroup
                ].where.pvpDate = {};
              }
            }
            requestCopy.bundleCubeExecutionRequest[
              dataGroup
            ].where.dimensionNameValueList.push(...newDimensionNameList);
          }
        );
      }
    },
    getRequestCopy() {
      const requestCopy = cloneDeep(this.config.api.request);
      this.appendWhereClause(requestCopy);
      requestCopy.page = this.tableConfig.body.APIConfig.page || 1;
      requestCopy.limit = this.tableConfig.body.APIConfig.limit || 30;
      requestCopy.orderByList = this.inputParams.orderByList;
      return requestCopy;
    },
    getEmailRequestCopy() {
      const requestCopy = this.getRequestCopy();
      const blacklistMetrics = this.config.api.blacklistMetricsForEmail;
      requestCopy.measuresList = requestCopy.measuresList.filter(
        (item) => !blacklistMetrics?.includes(item)
      );
      return requestCopy;
    },
    recreateTable() {
      this.tableRenderKey++;
    },
    metaDataApiCall() {
      if (
        isRealTimeUpdateEnabled.includes(this.$store.getters.getRetailer) &&
        ['keyword', 'campaign', 'skus'].includes(this.config.widget)
      ) {
        fetchRealtimeTableData(
          'campaignManagement',
          this.$store.getters.getRetailer,
          this.dataSource,
          'campaignManagement/',
          this.config.widget,
          this
        );
      }
    },
    async getData() {
      try {
        this.removeActionsState();
        const requestCopy = this.getRequestCopy();
        if (this.config?.table?.beforeFetch) {
          this.config?.table?.beforeFetch.call(this, requestCopy);
        }
        await this.dataService.getData(requestCopy, this.config);
        if (this.config?.table?.afterFetch) {
          this.config?.table?.afterFetch.call(this);
        }
        if (
          !this.dataService.data.error &&
          !this.dataService.data.noData &&
          this?.config?.table?.props?.levels
        ) {
          const { levels = [] } = this?.config?.table?.props || {};
          for (const level of levels) {
            const levelRequestCopy = cloneDeep(level.api.request);
            if (level.beforeFetch) {
              level.beforeFetch.call(this, levelRequestCopy);
            }
            await this.$store.dispatch(level.api.storeAction, {
              request: { APIData: levelRequestCopy }
            });
            if (level.afterFetch) {
              level.afterFetch.call(this);
            }
          }
        }
        this.metaDataApiCall();
      } catch (err) {
        console.error('err', err);
      }
    },
    getAllTableColumns() {
      return this.getTableConfig() || [];
    }
  }
};
</script>

<style scoped></style>
