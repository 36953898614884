import { cloneDeep } from 'lodash';
import {
  deepReplaceObjectValues,
  deepReplaceUnusedValuesWithNull
} from '@/pages/entity_details/common_components/helper.js';

// {
//   "product_title": "DreamBone Chicken Flavored Rawhide-Free Dog Chews, Small, 22 Oz. (12 Count)",
//   "final_auto_cubesdk_count": 872,
//   "auto_cubesdk_count": 872,
//   "buy_box_pct": null,
//   "availability_pct": null,
//   "availability_status": "In stock",
//   "brand_name": "DreamBone",
//   "offer_price": 23.87,
//   "image_url": "https://i5.walmartimages.com/asr/a019291a-bfb1-45c4-a636-eb959f8c4e29.a01531d5b006a43fddf3e8ba5f076733.jpeg",
//   "product_url": "https://www.walmart.com/ip/DreamBone-Chicken-Flavored-Rawhide-Free-Dog-Chews-Small-22-Oz-12-Count/45911858",
//   "sku": "45911858",
//   "isRowSelected": true
// }

export function formProductRequest(
  template,
  skus,
  campaignId,
  adGroupId,
  productState = 'enabled'
) {
  const productRequestArr = [];
  for (const item of skus) {
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':sku': item.sku,
      ':imageUrl': item.image_url,
      ':productTitle': item.product_title,
      ':productUrl': item.product_url,
      ':campaignId': campaignId,
      ':adGroupId': adGroupId,
      ':productBid': parseFloat(item.newBid) || null,
      ':productState': productState
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    deepReplaceUnusedValuesWithNull(requestObject);
    productRequestArr.push(requestObject);
  }
  return productRequestArr;
}
export function formUpdateProductRequest(
  template,
  skus,
  campaignId,
  adGroupId,
  isDelete
) {
  const productRequestArr = [];
  for (const item of skus) {
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':sku': item?.itemId,
      ':campaignId': campaignId,
      ':adGroupId': adGroupId,
      ':productBid': parseFloat(item.newBid) || null,
      ':productState': isDelete ? 'deleted' : null
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    deepReplaceUnusedValuesWithNull(requestObject);
    productRequestArr.push(requestObject);
  }
  return productRequestArr;
}

export function formKeywordRequest(template, keywords, campaignId, adGroupId) {
  const keywordRequestArr = [];
  for (const item of keywords) {
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':keyword': item.keyword,
      ':matchType': item.match_type,
      ':bid': parseFloat(item.newBid),
      ':campaignId': campaignId,
      ':adGroupId': adGroupId,
      ':keywordState': 'enabled'
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    deepReplaceUnusedValuesWithNull(requestObject);
    keywordRequestArr.push(requestObject);
  }
  return keywordRequestArr;
}

export function formUpdateKeywordRequest(
  template,
  keywords,
  campaignId,
  adGroupId,
  isDelete
) {
  const keywordRequestArr = [];
  for (const item of keywords) {
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':keywordId': item?.keywordId,
      ':bid': parseFloat(item.newBid) || null,
      ':campaignId': campaignId,
      ':adGroupId': adGroupId,
      ':keywordState': isDelete ? 'archived' : null
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    deepReplaceUnusedValuesWithNull(requestObject);
    keywordRequestArr.push(requestObject);
  }
  return keywordRequestArr;
}
export function formNegativeKeywordRequest(
  template,
  keywords,
  campaignId,
  adGroupId
) {
  const keywordRequestArr = [];
  for (const item of keywords) {
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':keyword': item.keyword,
      ':matchType': `negative${
        item.match_type.charAt(0).toUpperCase() + item?.match_type.slice(1)
      }`,
      ':campaignId': campaignId,
      ':adGroupId': adGroupId,
      ':keywordState': 'enabled'
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    deepReplaceUnusedValuesWithNull(requestObject);
    keywordRequestArr.push(requestObject);
  }
  return keywordRequestArr;
}

export function formUpdateNegativeKeywordRequest(
  template,
  keywords,
  campaignId,
  adGroupId,
  isDelete
) {
  const keywordRequestArr = [];
  for (const item of keywords) {
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':keywordId': item?.keywordId,
      ':campaignId': campaignId,
      ':adGroupId': adGroupId,
      ':keywordState': isDelete ? 'archived' : null
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    deepReplaceUnusedValuesWithNull(requestObject);
    keywordRequestArr.push(requestObject);
  }
  return keywordRequestArr;
}

export function formCreativeRequest(template, profiles, campaignId, adGroupId) {
  const keywordRequestArr = [];
  for (const item of profiles) {
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':brandName': item.brandName,
      ':headline': item.headline,
      ':clickUrl': item.url,
      ':campaignId': campaignId,
      ':adGroupId': adGroupId
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    deepReplaceUnusedValuesWithNull(requestObject);
    keywordRequestArr.push(requestObject);
  }
  return keywordRequestArr;
}

export function formUpdateCreativeRequest(
  template,
  profiles,
  campaignId,
  adGroupId
) {
  const keywordRequestArr = [];
  for (const item of profiles) {
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':brandName': item.brandName || null,
      ':headline': item.headline || null,
      ':clickUrl': item.url || null,
      ':creativeId': item?.sbaProfileId,
      ':campaignId': campaignId,
      ':adGroupId': adGroupId
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    deepReplaceUnusedValuesWithNull(requestObject);
    keywordRequestArr.push(requestObject);
  }
  return keywordRequestArr;
}

export function formPlacementInclusionRequest(
  template,
  placementInclusionObject,
  campaignId
) {
  const placementInclusionKeyMappings = {
    searchCarousel: 'Search Carousel',
    itemCarousel: 'Item Carousel',
    itemBuyBox: 'Item Buybox'
  };
  const placementInclusionArr = [];
  for (const key in placementInclusionObject) {
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':campaignId': campaignId,
      ':placementInclusionType': placementInclusionKeyMappings[key],
      ':placementInclusionTypeStatus': placementInclusionObject[key].value
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    deepReplaceUnusedValuesWithNull(requestObject);
    placementInclusionArr.push(requestObject);
  }
  return placementInclusionArr;
}

export function formPlacementBidMultiplierRequest(
  template,
  placementBidMultiplierObject,
  campaignId
) {
  const placementBidMultiplierKeyMappings = {
    buyBox: 'Buy-Box',
    searchIngrid: 'Search Ingrid',
    homePage: 'Home Page',
    stockUp: 'Stock Up'
  };
  const placementBidMultiplierArr = [];
  for (const key in placementBidMultiplierObject) {
    if (!placementBidMultiplierObject[key]) {
      continue;
    }
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':campaignId': campaignId,
      ':bidMultiplierPlacementType': placementBidMultiplierKeyMappings[key],
      ':bidMultiplierPlacementValue': parseFloat(
        placementBidMultiplierObject[key]
      )
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    deepReplaceUnusedValuesWithNull(requestObject);
    placementBidMultiplierArr.push(requestObject);
  }
  return placementBidMultiplierArr;
}

export function formPlatformBidMultiplierRequest(
  template,
  platformBidMultiplierObject,
  campaignId
) {
  const platformBidMultiplierKeyMappings = {
    desktop: 'Desktop',
    mobile: 'Mobile',
    app: 'App'
  };
  const platformBidMultiplierArr = [];
  for (const key in platformBidMultiplierObject) {
    if (!platformBidMultiplierObject[key]) {
      continue;
    }
    const requestObject = cloneDeep(template);
    const keyValuePairs = {
      ':campaignId': campaignId,
      ':bidMultiplierPlatformType': platformBidMultiplierKeyMappings[key],
      ':bidMultiplierPlatformValue': parseFloat(
        platformBidMultiplierObject[key]
      )
    };
    for (const key in keyValuePairs) {
      deepReplaceObjectValues(requestObject, key, keyValuePairs[key]);
    }
    deepReplaceUnusedValuesWithNull(requestObject);
    platformBidMultiplierArr.push(requestObject);
  }
  return platformBidMultiplierArr;
}
