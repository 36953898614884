<template>
  <div
    class="u-display-flex u-flex-direction-column"
    :style="{ height: dimensions.height, width: dimensions.width }"
  >
    <div
      class="u-flex-1 u-display-flex u-flex-direction-column card u-border-width-s u-border-color-grey-xxx-light"
    >
      <!-- Right Table header -->
      <div
        v-if="showHeader"
        class="tableHeading u-spacing-ph-m u-spacing-pv-s u-font-weight-600 u-font-size-5"
        :class="{
          'u-display-flex u-flex-align-items-center u-flex-justify-content-space-between':
            showDownloadButton
        }"
      >
        <div class="u-spacing-p-xs">
          {{ headerText }}
        </div>

        <div
          v-if="showDownloadButton"
          @click="downloadButtonClicked"
        >
          <IconText
            :icon="'download'"
            :is-loading="downloadButtonLoading"
            :icon-classes="'rb-icon u-color-grey-lighter icon-download u-cursor-pointer'"
            :is-metric-tool-tip-enabled="true"
            :tooltip-text="'Download'"
          />
        </div>
      </div>

      <div class="u-position-relative u-flex-1 u-width-100">
        <rb-insights-table
          class="u-display-flex left-table u-flex-direction-column"
          :grid-options="gridOptions"
          :config="tableConfig"
          :auto-configure="autoConfigureOption"
          :row-height="68"
          :enable-client-side-sorting="false"
          :enable-server-side-sorting="false"
          :pagination="true"
          :client-side-pagination="true"
          :pagination-total-key="autoConfigureOption.rows.length"
          :get-current-instance="getCurrentSourceInstance"
          :pagination-per-page-limit="paginationPerPageLimit"
          :download-this-table="false"
          :show-pagination-options="false"
          :row-no-hover-state="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IconText from '@/components/basic/iconText.vue';
export default {
  name: 'TippyTableWithHeader',
  components: {
    IconText
  },
  props: {
    columnDefinition: {
      type: Array,
      default: () => []
    },
    dataRows: {
      type: Array,
      default: () => []
    },
    paginationPerPageLimit: {
      type: Number,
      default: 2
    },
    headerText: {
      type: String,
      default: 'Header'
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showDownloadButton: {
      type: Boolean,
      default: false
    },
    downloadButtonLoading: {
      type: Boolean,
      default: false
    },
    dimensions: {
      type: Object,
      default: () => ({
        height: '287px',
        width: '540px'
      })
    }
  },
  data() {
    return {
      rbTableSourceInstance: null,
      tableConfig: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: {}
        }
      }
    };
  },
  computed: {
    autoConfigureOption() {
      return {
        rows: this.dataRows,
        columns: this.columnDefinition
      };
    }
  },
  methods: {
    getCurrentSourceInstance(context) {
      this.rbTableSourceInstance = context;
    },
    downloadButtonClicked() {
      this.$emit('downloadButtonClicked');
    }
  }
};
</script>
