<template>
  <div
    :class="`rra-card-widget ${defaultClass} u-spacing-mv-0`"
    expoter="display-none"
  >
    <widgetContainer
      :header-options="headerOptionsComp"
      :header-container-grid-styles="headerContainerGridStyles"
      title="Shortage Invoices"
      :is-loading="isLoading"
      :footer-options="footerOptions(true)"
    >
      <div
        id="cardContainer"
        slot="body"
      >
        <div
          class="u-display-flex u-flex-direction-column u-position-relative u-overflow-hidden"
        >
          <div v-if="showWidgetData">
            <div class="upper-metrics u-display-flex u-flex-direction-row">
              <div
                v-for="(metr, key) in getTopBodyMetricsList(0, metricOffset)"
                :key="key"
              >
                <div class="u-display-flex u-flex-direction-row">
                  <div
                    class="metric-component u-display-flex u-flex-direction-column"
                  >
                    <el-tooltip
                      placement="bottom"
                      effect="light"
                      popper-class="rra-popper-class"
                      :visible-arrow="false"
                    >
                      <span
                        class="upper-metric-label u-font-size-4 u-color-grey-mid-light u-display-flex u-flex-align-items-center u-spacing-pb-xs"
                      >
                        <rb-icon
                          class="dot-icon u-spacing-pr-xxs"
                          icon="dot"
                          :style="{
                            color: cardPrimaryMetricColorCode[metr.alias]
                          }"
                        />
                        {{ metr.label + `${isCardCollapsed ? ' : ' : ''}` }}
                      </span>
                      <div slot="content">
                        <div
                          class="u-display-flex u-flex-direction-row u-font-weight-600 u-font-size-5"
                        >
                          <span class="u-color-grey-base">
                            {{ metr.label }}</span
                          >
                        </div>
                        <div class="u-spacing-mv-m u-font-size-6">
                          <span class="u-color-grey-light">{{
                            metr.tooltip
                          }}</span>
                        </div>
                      </div>
                    </el-tooltip>
                    <metric
                      :size="metricSize1"
                      :label-color="getMetricLabelColor(metr.alias)"
                      class="u-display-inline-flex u-flex-1 u-width-100"
                      :split="isSplit"
                      :hide-zero="false"
                      :config="metr.metadata.tagConfig"
                      :data="cardMetricData(metr.keyName, 'primary')"
                    />
                  </div>
                  <div
                    v-if="
                      key < getTopBodyMetricsList(0, metricOffset).length - 1
                    "
                    class="vertical-line"
                  />
                </div>
              </div>
            </div>
            <chartWithGradient
              :key="myKey"
              :metric-data="getTopBodyMetricsData(0, metricOffset)"
              :metadata="metadata"
              :card-primary-metric-color-code="cardPrimaryMetricColorCode"
              :metrics-list="getTopBodyMetricsList(0, metricOffset)"
              :widget-request-params="widgetRequestParams"
              :chart-config="chartConfig"
            />
            <div class="lower-metrics u-display-flex u-flex-direction-row">
              <div
                v-for="(metr, key) in getTopBodyMetricsList(
                  metricOffset,
                  metricOffset + 2
                )"
                :key="key"
              >
                <div
                  class="metric-component-2 u-display-flex u-flex-direction-column"
                >
                  <el-tooltip
                    placement="bottom"
                    effect="light"
                    popper-class="rra-popper-class"
                    :visible-arrow="false"
                  >
                    <span
                      class="u-font-size-5 u-color-grey-mid-light u-display-flex u-flex-align-items-center u-spacing-pb-xs"
                    >
                      {{ metr.label }}
                    </span>
                    <div slot="content">
                      <div
                        class="u-display-flex u-flex-direction-row u-font-weight-600 u-font-size-5"
                      >
                        <span class="u-color-grey-base"> {{ metr.label }}</span>
                      </div>
                      <div class="u-spacing-mv-m u-font-size-6">
                        <span class="u-color-grey-light">{{
                          metr.tooltip
                        }}</span>
                      </div>
                    </div>
                  </el-tooltip>
                  <metric
                    :size="metricSize2"
                    :label-color="cardPrimaryMetricColorCode[metr.alias]"
                    class="u-display-inline-flex u-flex-1 u-width-100"
                    :split="isSplit"
                    :hide-zero="false"
                    :config="metr.metadata.tagConfig"
                    :data="cardMetricData(metr.keyName, 'primary')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <noDataState
              showable-text="Date Type is not compatible hence there is no data"
              date-select-trigger-text="Change Date Type"
              icon="calendar"
            />
          </div>
        </div>
      </div>
    </widgetContainer>
  </div>
</template>
<script>
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import metric from '@/components/basic/metric';
import { isEqual } from 'lodash';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import { getMetricData } from '@/components/widgets/custom_widgets/custom_widget_components/cards/utility.js';
import chartWithGradient from '@/components/basic/chart-with-gradient.vue';
import noDataState from './no-data-state.vue';

const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default {
  components: {
    widgetContainer,
    metric,
    chartWithGradient,
    noDataState
  },
  mixins: [cwMixin],
  props: {
    cardWidgetTitle: {
      type: String,
      default: ''
    },
    cardWidgetIcon: {
      type: String,
      default: ''
    },
    widgetRequestParams: {
      type: Object,
      default: () => {}
    },
    isCardCollapsed: {
      type: Boolean,
      default: true
    },
    defaultClass: {
      type: String,
      default: () => ''
    },
    metricSize1: {
      type: String,
      default: 'xxxl'
    },
    metricSize2: {
      type: String,
      default: 'xxl'
    },
    isInteractive: {
      type: Boolean,
      default: true
    },
    isSplit: {
      type: Boolean,
      default: true
    },
    onClickHeader: {
      type: Function,
      default: () => {}
    },
    metricOffset: {
      type: Number,
      default: 0
    },
    showWidgetData: {
      type: Boolean,
      default: true
    },
    footerOptions: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: true,
      metricsList: [],
      metricData: [],
      selectedMetric: '',
      footerValue: undefined,
      myKey: 0,
      chartConfig: {
        xFormat: '%m/%d/%Y',
        axis: {
          x: {
            show: false
          },
          y: {
            show: false
          }
        },
        legend: false,
        size: {
          height: 75
        },
        tooltip: false,
        tooltip_format: {},
        point: false,
        line: {
          connectNull: true
        },
        padding: {
          left: -8,
          right: -7,
          bottom: 5
        },
        regions: {},
        timeseries: this.widgetRequestParams[':operations'].daterangeDimension
      }
    };
  },
  computed: {
    cardFooterLabel() {
      return this.widgetRequestParams.metadata.cardFooterLabel;
    },
    cardPrimaryMetricList() {
      return this.widgetRequestParams.metadata.cardPrimaryMetricList;
    },
    cardPrimaryMetricColorCode() {
      return this.widgetRequestParams.metadata.cardPrimaryMetricColorCode;
    }
  },
  watch: {
    widgetRequestParams(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.fetchData();
        this.chartConfig.timeseries =
          this.widgetRequestParams[':operations'].daterangeDimension;
      }
    }
  },
  created() {
    this.getCardMetricLIst();
    this.fetchData();
  },
  methods: {
    getMetricLabelColor(metricAlias) {
      return metricAlias === 'shortages_pending_resolution'
        ? '#2b333b'
        : this.cardPrimaryMetricColorCode[metricAlias];
    },
    getTopBodyMetricsList(primaryOffset, secondaryOffset) {
      return this.metricsList.slice(primaryOffset, secondaryOffset);
    },
    getTopBodyMetricsData(primaryOffset, secondaryOffset) {
      return this.metricData.slice(primaryOffset, secondaryOffset);
    },
    getCardMetricLIst() {
      this.metricsList = [];
      Object.keys(this.metadata.metrics).forEach((metric) => {
        if (this.cardPrimaryMetricList.includes(metric)) {
          this.metricsList.push(this.metadata.metrics[metric]);
        }
      });
      this.generateToolTipFormatObject();
    },
    async fetchData() {
      this.isLoading = true;
      const metricsGrouping = dashUtils.groupApis(this.metadata.metrics);
      let response;
      for (const groupName of Object.keys(metricsGrouping)) {
        const api = metricsGrouping[groupName].api;
        const request = dashUtils.replacePlaceHolderWithData(
          api.request,
          this.widgetRequestParams
        );
        request.where.dimensionNameValueList =
          this.widgetRequestParams[':dimensionNameValueList'];
        request.enablePaginationCount = true;
        await dashUtils
          .fetchDataAPI(request, api)
          .then((args) => {
            response = args?.entityData[0]?.data;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log('An Error Occured', error?.message);
          });
      }
      const data = {};
      response.forEach((e) => {
        data[':' + e.name] = e.RESULT[e.name];
      });
      this.metricData = response;
      this.myKey += 1;
      this.isLoading = false;
    },
    cardMetricData(selectedCardLabel, type) {
      let finalObj = {};
      if (type === 'primary') {
        finalObj = getMetricData(selectedCardLabel, this.metricData);
      } else {
        finalObj = getMetricData(
          `${this.cardFooterLabel?.toLowerCase()}_${selectedCardLabel}`,
          this.metricData
        );
      }
      return finalObj;
    },
    generateToolTipFormatObject() {
      const tagConfigArray = this.metricsList.map((metric) => {
        return {
          label: metric.label,
          tagConfig: metric?.metadata?.tagConfig?.tag1Unit
        };
      });
      const formattedObject = tagConfigArray.reduce(
        (obj, item) => ((obj[item.label] = item.tagConfig), obj),
        {}
      );

      this.chartConfig.tooltip_format = formattedObject;
    }
  }
};
</script>
<style lang="css">
.rra-card-widget .vertical-line {
  width: 1px;
  height: 45px;
  flex-grow: 0;
  background-color: #e9eaeb;
}
.rra-card-widget .metric-component-2 {
  padding-left: 24px !important;
  padding-right: 3px !important;
  margin-bottom: 16px !important;
  margin-top: 16px !important;
}
.rra-card-widget .u-color-grey-x-light {
  color: #2b333b;
}
.rra-card-widget .custom_widget_body {
  padding: 0px !important;
}
.rra-card-widget .c3-tooltip-container .card > :first-child {
  padding: 0.8rem;
  margin: 0;
  border: none;
}
.rra-card-widget .c3-tooltip-container .card > :first-child span {
  font-size: 13px;
}
.rra-card-widget .c3-tooltip-container .card > :nth-child(2) {
  display: none;
}
.rra-card-widget .c3-tooltip-container .card > :not(:first-child) {
  padding: 0 0.8rem;
  font-size: 11px;
  min-width: 200px !important;
}
</style>
