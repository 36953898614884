import Vue from 'vue';
import chartSvg from '@/utils/helpers/chartSvg';
import { CHART_TOOLTIP_TEMPLATES } from '@/utils/helpers/chartUtils.js';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import { store } from '@/store/store';
// import * as d3 from 'd3';

function getSelectedCurrency() {
  return store.getters.getSelectedCurrency;
}

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
  );
}

export default {
  props: {
    config: Object,
    data: [Object, Array, String],
    chartChange: Number
  },
  created() {
    this.initConfig();
  },
  mounted() {
    this.chartConfig.data.columns = this.data || [];
    this.chartConfig.data.onclick = (data, element) => {
      this.$emit('pointClicked', { data, element });
    };
    this.chartConfig.bindto = this.$el;
    if (
      this.chartConfig.grid !== undefined &&
      (this.chartConfig.grid.x.show || this.chartConfig.grid.y.show)
    ) {
      this.$el.classList.add('graphWithGrids');
    }
  },
  beforeDestroy() {
    if (this.d3) {
      this.d3
        .selectAll('.legendText')
        .on('mouseover', null)
        .on('mouseout', null)
        .on('click', null);
    }
  },
  data() {
    var chartWidget = this;
    var chartOptions = this.config.chartOptions;
    const localChartSvg = chartSvg;
    let colorObj = chartOptions.color;
    if (!colorObj) {
      colorObj = {
        pattern: [
          '#ffa800',
          '#bd10e0',
          '#ff6072',
          '#97cc04',
          '#23b5d3',
          '#f5d908',
          '#ff909d',
          '#ffc24c',
          '#d158ea',
          '#f8e552',
          '#b6dc4f',
          '#65cce1'
        ]
      };
    }
    return {
      // chartId: null,
      scales: {},
      d3Instance: null,
      customLegends: null,
      chartInstance: null,
      chartConfig: {
        line: {
          connectNull: true
        },
        axis: {
          x: {
            show: false
          },
          y: {
            show: false
          },
          y2: {
            min: 0,
            show: false
          }
        },
        point: {
          r: function (data) {
            var pointFormat =
              ((this.config.chartOptions || {}).point_format || {})[data.id] ||
              {};
            if ((this.config.chartOptions.events || []).length > 0) {
              const eventsKey = this.config.chartOptions.events.findIndex(
                (item) => {
                  return item.key === data.id;
                }
              );
              if (eventsKey !== -1) {
                return 8;
              }
            }
            if (isFunction(pointFormat)) {
              return pointFormat(data);
            }
            if (
              (data.id.toLowerCase().indexOf('promo') > -1 ||
                data.id.toLowerCase().indexOf('timeline') > -1) &&
              data.id.toLowerCase().indexOf('promotions') === -1
            ) {
              return 5;
            }
            if (this.config.chartOptions.point === false) {
              return 0;
            }
            return 3;
          }.bind(this)
        },
        data: {
          columns: []
        },
        color: colorObj,
        oninit: function () {
          var legendItems = [];
          this.chartOptions = chartOptions;
          this.localChartSvg = localChartSvg;
          if (typeof chartOptions?.oninit === 'function') {
            chartOptions?.oninit();
          }
          // Icon changes for unavailable and 3p variants.
          if (
            Vue.options.filters.config_check(
              'feature.merge_unavailable_recommendation.enable'
            )
          ) {
            const base = '/chartIcons/';
            this.localChartSvg['p-variant'].src = base + '3p-variant-new.svg';
            this.localChartSvg['3P Variant'].src = base + '3p-variant-new.svg';
            this.localChartSvg.Unavailable.src = base + 'unavailable-new.svg';
            this.localChartSvg.availability.src = base + 'unavailable-new.svg';
            this.localChartSvg.Availability.src = base + 'unavailable-new.svg';
            this.localChartSvg.unavailable_merged = {
              src: base + 'unavailable-new.svg'
            };
          }
          chartWidget.d3Instance = this.d3;
          var element = this.config.bindto;
          if ((this.chartOptions.events || []).length > 0) {
            const svgElement = this.d3.select(element).select('svg');
            for (let i = 0; i < this.chartOptions.events.length; i++) {
              const event = this.chartOptions.events[i];
              let url =
                (this.localChartSvg[event.icon] &&
                  this.localChartSvg[event.icon].src) ||
                this.localChartSvg.dot.src;
              if (
                window.chrome &&
                window.chrome.extension &&
                window.chrome.extension.getURL
              ) {
                url = window.chrome.extension.getURL('/icons' + url);
              }
              svgElement
                .append('filter')
                .attr('id', event.key.replace(/ /g, ''))
                .attr('width', '100%')
                .attr('height', '100%')
                .append('feImage')
                .attr('xlink:href', url);
            }
          }
          if (
            this.data.targets &&
            this.data.targets.length > 0 &&
            chartOptions.legend !== false
          ) {
            for (var i = 0; i < this.data.targets.length; i++) {
              legendItems.push(this.data.targets[i].id);
            }
            var that = this;
            this.d3
              .select(element)
              .insert('div', '.chart')
              .attr(
                'class',
                'u-display-flex u-flex-justify-content-center u-spacing-mb-m legends-container'
              )
              .selectAll('span')
              .data(legendItems)
              .enter()
              .append('span')
              .attr('data-id', function (id) {
                return id;
              })
              .attr('class', 'u-display-flex u-flex-align-items-center')
              .html(function (id) {
                if (chartOptions.hideSecondaryLegend) {
                  return '';
                }
                const label = chartWidget?.customLegends?.[id]?.title ?? id;
                const url =
                  chartWidget?.customLegends?.[id]?.url ??
                  chartWidget.customLegends?.[id];
                return (
                  '<span class="legendText u-spacing-ml-m u-spacing-mr-xs u-font-size-6 u-cursor-pointer"><span class="u-spacing-mr-xs" style="border-radius:100%; display:inline-block; width: 8px; height:8px; background:' +
                  that.color(id) +
                  '"></span><span>' +
                  label +
                  '</span></span>' +
                  (url
                    ? '<a target="_blank" href="' +
                      url +
                      '"><span class="rb-icon icon-open-new-window rb-icon--x-small u-color-grey-x-light"></span></a>'
                    : '')
                );
              })
              .select('.legendText')
              .on('mouseover', function (id) {
                that.api.focus(id);
              })
              .on('mouseout', function (id) {
                that.api.revert();
              })
              .on('click', function (id) {
                var opacity = this.style.opacity;
                if (opacity === '1' || !opacity) {
                  this.style.opacity = '0.3';
                } else {
                  this.style.opacity = '1';
                }
                that.api.toggle(id);
              });

            this.d3
              .select(element)
              .selectAll('.legendText')
              .attr('data-id', function (id) {
                if (
                  that.chartOptions &&
                  that.chartOptions.disableLegends &&
                  that.chartOptions.disableLegends.length > 0
                ) {
                  if (that.chartOptions.disableLegends.indexOf(id) !== -1) {
                    setTimeout(
                      function () {
                        this.click();
                      }.bind(this),
                      100
                    );
                  }
                }
                return id;
              });
          }
        },
        onresize: debounce(function () {
          this.api.resize();
        }),
        onresized: function () {
          chartOptions?.onresized?.();
        },
        onrendered: function () {
          var $$ = this;
          if (
            chartWidget?.isPVPTimeseriesChart &&
            chartWidget?.chartConfig?.axis?.x?.type === 'category'
          ) {
            chartWidget.fixLabels?.();
          }
          if (
            this.data.targets &&
            this.data.targets.length > 1 &&
            chartOptions.show_axis_colors
          ) {
            var element = this.config.bindto;
            this.data?.targets?.forEach((item) => {
              if (
                this.config.data_types &&
                ['line', 'bar'].includes(this.config.data_types[item.id]) &&
                chartOptions.realtime === false
              ) {
                const axisToColor = $$.config.data_axes[item.id];
                const classToSelect =
                  axisToColor === 'y2' ? '.c3-axis-y2' : '.c3-axis-y';
                const color =
                  $$.config.data_axes[item.id + '_color'] ||
                  chartWidget.chartConfig.data.colors[item.id];
                if (
                  chartWidget.chartConfig.axis.y?.show &&
                  chartWidget.chartConfig.axis.y2?.show
                ) {
                  const height = this.d3
                    .select(element)
                    .select('.c3-event-rect')
                    .node()
                    .getAttribute('height');
                  const allTick = this.d3
                    .select(element)
                    .selectAll(`${classToSelect} .tick line`);
                  allTick.style('display', 'block');
                  allTick.style('stroke', color);
                  this.d3
                    .select(element)
                    .select('.side-border-' + axisToColor)
                    .remove();
                  const yAxis = this.d3
                    .select(element)
                    .select(classToSelect)
                    .append('line');
                  yAxis
                    .attr('y1', 0)
                    .attr('x1', 0)
                    .attr('x2', 0)
                    .attr('y2', height);
                  yAxis.attr('class', 'side-border-' + axisToColor);
                  yAxis.style('stroke', color);
                  yAxis.style('stroke-width', '1px');
                }
              }
            });
          }

          var circles = $$.getCircles();
          const singleCircleMap = {};
          if (
            ((this.chartOptions && this.chartOptions.events) || []).length > 0
          ) {
            for (let i = 0; i < circles._groups[0].length; i++) {
              const singleCircle = circles._groups[0][i];
              if (singleCircle.__data__.value == null) {
                continue;
              }
              const eventsKey = this.chartOptions.events.findIndex((item) => {
                return item.key === singleCircle.__data__.id;
              });
              if (eventsKey !== -1) {
                if (!singleCircleMap[singleCircle.__data__.x]) {
                  singleCircleMap[singleCircle.__data__.x] = [];
                }
                singleCircleMap[singleCircle.__data__.x].push(singleCircle);
                singleCircle.setAttribute(
                  'filter',
                  `url(#${this.chartOptions.events[eventsKey].key.replace(
                    / /g,
                    ''
                  )})`
                );
                const classString =
                  singleCircle.getAttribute('class') + ' u-opacity-1';
                singleCircle.setAttribute('class', classString);
                // singleCircle.addEventListener('click', function (d) {
                //   console.log('clicked', d);
                // });
              }
            }
          }
          const shiftBy = 8;
          for (const key in singleCircleMap) {
            const dayArray = singleCircleMap[key] || [];
            if (dayArray.length > 1) {
              for (let i = 0; i < dayArray.length; i++) {
                const currentDayPoint = dayArray[i];
                const currentCy = parseFloat(
                  currentDayPoint.getAttribute('cy')
                );
                const currentCx = parseFloat(
                  currentDayPoint.getAttribute('cx')
                );
                let newCx = currentCx;
                let newCy = currentCy;
                switch (i) {
                  case 0:
                    newCx = currentCx + shiftBy;
                    break;
                  case 1:
                    newCx = currentCx - shiftBy;
                    break;
                  case 2:
                    newCy = currentCy - shiftBy;
                }
                currentDayPoint.setAttribute('cy', newCy);
                currentDayPoint.setAttribute('cx', newCx);
              }
            }
          }
          for (var i = 0; i < circles._groups.length; i++) {
            for (var j = 0; j < circles._groups[i].length; j++) {
              $$.getCircles(j)
                .style('fill', '#FFF')
                .style('stroke', $$.color)
                .style('stroke-width', 0.75);
            }
          }
        },
        legend: {
          show: false
        },
        tooltip: {
          contents: function (
            d,
            defaultTitleFormat,
            defaultValueFormat,
            color
          ) {
            var $$ = this;
            var config = $$.config;
            let titleFormat =
              chartWidget.config.tooltip_format_title ||
              config.tooltip_format_title ||
              defaultTitleFormat;
            var nameFormat =
              config.tooltip_format_name ||
              function (name) {
                return name;
              };
            var valueFormat = config.tooltip_format_value || defaultValueFormat;
            var text = '';
            var i = 0;
            var title = '';
            var value = '';
            var name = '';
            var bgcolor = '';
            var meta = this.config.data_classes;
            let eventCounts = 0;
            let metricCounts = 0;
            const labelText =
              chartWidget.chartConfig.tooltip_label_text || 'Metrics';
            let eventText = `
              <div class="u-spacing-pt-s u-spacing-pb-m u-spacing-pl-m u-border-top u-border-width-s u-border-color-grey-xxx-light u-display-flex">
                <span class="u-font-size-5 u-color-grey-light u-font-weight-600">Events</span>
              </div>`;

            for (i = 0; i < d.length; i++) {
              if (
                chartWidget.chartConfig.dynamicRange &&
                chartWidget.scales &&
                chartWidget.scales[d[i].id]
              ) {
                // d[i].value = chartWidget.scales[d[i].id].invert(d[i].value).toFixed(5)
                d[i].value = chartWidget.originalValues[d[i].id][d[i].index];
              }
              if (!(d[i] && (d[i].value || d[i].value === 0))) {
                continue;
              }
              const eventIndex = (this.chartOptions.events || []).findIndex(
                (item) => {
                  return item.key === d[i].id;
                }
              );
              var nameFromData = d[i].name;
              if (nameFromData.startsWith('gradient')) {
                continue;
              }
              var indexFromData = d[i].index;
              if (!text) {
                title = titleFormat ? titleFormat(d[i].x) : d[i].x;
                if (d[i].x && d[i].x.constructor.name === 'Date') {
                  title = Vue.options.filters.printable_date(new Date(d[i].x));
                }
                if ($$.config.data_type === 'donut') {
                  title = title === undefined ? $$.config.donut_title : title;
                }
                title = this.chartOptions.tooltipTitle
                  ? this.chartOptions.tooltipTitle(d[i].x)
                  : title;
                text = `<div class="card u-spacing-pb-s" style="background:rgba(255, 255, 255, 0.98); box-shadow: 0 0 4px 0 #caccce !important;">
                          <div class="u-spacing-p-m u-border-bottom u-border-width-s u-border-color-grey-xxx-light u-display-flex u-spacing-mb-s">
                            <span class="u-font-size-4 u-color-grey-light u-font-weight-600">${title}</span>
                          </div>
                          <div class="u-spacing-pl-m u-spacing-pt-s u-spacing-pb-m u-spacing-pr-s u-display-flex">
                            <span class="u-font-size-5 u-color-grey-light u-font-weight-600">${labelText}</span>
                          </div>`;
                if (this.chartOptions?.realtime) {
                  text = CHART_TOOLTIP_TEMPLATES.STREAM_HOURLY_CHART(
                    chartOptions,
                    d[i].x,
                    labelText
                  );
                }
              }
              if (
                Object.keys(meta).length > 0 &&
                meta[d[i].name] &&
                this.chartOptions.tooltip_mapper[nameFromData] &&
                meta[nameFromData] &&
                meta[nameFromData][indexFromData] &&
                meta[nameFromData][indexFromData][
                  this.chartOptions.tooltip_mapper[nameFromData]
                ]
              ) {
                name =
                  meta[nameFromData][indexFromData][
                    this.chartOptions.tooltip_mapper[nameFromData]
                  ];
              } else {
                name = d[i].name;
              }
              name = nameFormat(name);
              if (isFunction(chartOptions?.tooltip_format?.All?.customNameFn)) {
                name = chartOptions.tooltip_format.All.customNameFn(d[i]);
              }
              value = valueFormat(d[i].value, d[i].ratio, d[i].id, d[i].index);
              bgcolor = $$.levelColor
                ? $$.levelColor(d[i].value)
                : color(d[i].id);
              if (eventIndex !== -1) {
                let url =
                  (this.localChartSvg[d[i].id] &&
                    this.localChartSvg[d[i].id].src) ||
                  this.localChartSvg.dot.src;
                if (
                  window.chrome &&
                  window.chrome.extension &&
                  window.chrome.extension.getURL
                ) {
                  url = window.chrome.extension.getURL('/icons' + url);
                }
                eventText += `<div style="min-width: 240px; max-width: 500px;" class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-center u-font-size-5 u-color-grey-light u-spacing-pb-s u-spacing-ph-m">
                            <span class="u-display-flex u-flex-align-items-center" style="min-width:136px;height:16px;">
                              <div class="u-spacing-mr-s" style="border-radius:100%; display:inline-block; width: 12px; height:12px;">
                                <img src="${url}" class="u-display-flex u-height-100 u-width-100" style="object-fit: fill;" />
                              </div>
                              <span class="u-spacing-mr-s">${name}</span>
                            </span>
                            <span class="u-font-weight-600 u-line-height-1-3 u-flex-align-items-center" style="overflow-wrap: break-word;word-break: break-all;">${value}</span>
                          </div>`;
                eventCounts++;
              } else {
                text += `<div style="min-width: 240px; max-width: 500px;" class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-flex-start u-font-size-5 u-color-grey-light u-spacing-pb-s u-spacing-ph-m">
                <span class="u-display-flex u-flex-align-items-center" style="min-width:136px">
                  <span class="u-spacing-mr-s" style="border-radius:100%; display:inline-block; width: 8px; height:8px; background:${bgcolor}"></span>
                  <span class="u-spacing-mr-s">${name}</span>
                </span>
                <span class="u-font-weight-600 u-line-height-1-3">${value}</span>
              </div>`;
                metricCounts++;
              }
            }
            if (eventCounts > 0) {
              if (metricCounts === 0) {
                text +=
                  '<div class="u-display-flex u-flex-justify-content-center u-flex-align-items-flex-start u-font-size-5 u-color-grey-light u-spacing-p-m u-spacing-pt-0 u-font-weight-600 u-spacing-mt-0">No Data Available</div>';
              } else {
                text += '<div class="u-spacing-pb-s"></div>';
              }
              return text + eventText + '</div>';
            } else {
              if (isFunction(chartOptions?.append_to_tooltip)) {
                text += chartOptions.append_to_tooltip(title);
              }
              return text + '</div>';
            }
          },
          format: {
            value: function (value, ratio, id, index) {
              var tooltipFormat =
                ((this.config.chartOptions || {}).tooltip_format || {})[id] ||
                {};
              if (isFunction(tooltipFormat)) {
                return tooltipFormat(value, ratio, id, index);
              }
              if (Object.keys(tooltipFormat).length === 0) {
                tooltipFormat =
                  ((this.config.chartOptions || {}).tooltip_format || {}).All ||
                  {};
              }
              if (tooltipFormat.format !== false) {
                return Vue.options.filters.num_format(
                  value,
                  tooltipFormat.pre,
                  tooltipFormat.suff,
                  tooltipFormat.min,
                  tooltipFormat.roundoff,
                  undefined,
                  getSelectedCurrency()
                );
              } else {
                return value;
              }
            }.bind(this)
          },
          position: this.config?.chartOptions?.tooltip_position
            ? (data, width, height, element) =>
                this.config.chartOptions.tooltip_position(
                  data,
                  width,
                  height,
                  element
                )
            : undefined
        }
      }
    };
  },
  watch: {
    'config.chartOptions': {
      deep: true,
      handler() {
        this.initConfig();
      }
    },
    'config.xAxisCategories': {
      deep: true,
      handler() {
        this.initConfig();
      }
    }
  },
  updated() {
    this.$emit('updateStroke');
  },
  methods: {
    chartInitialConfigGenerator() {
      this.config.xAxisType = this.config.xAxisType || 'category';
      this.config.stack = this.config.stack || [];
      this.config.chartGetter = this.config.chartGetter || '';
      this.chartConfig.data.type =
        this.config.chartOptions.type || this.config.type || 'line';
      if (this.config.chartOptions.types) {
        this.chartConfig.data.types = this.config.chartOptions.types;
      }
      this.chartConfig.interaction = this.config.interaction;
      this.chartConfig.axis.x.type = this.config.xAxisType;
      if (this.config.axis) {
        this.chartConfig.axis.rotated = this.config.axis.rotated || false;
      }

      if (this.chartConfig.data.type === 'donut') {
        this.chartConfig.donut = this.config.donut || undefined;
      }

      if (this.chartConfig.data.type === 'bar') {
        if (this.config?.groups) {
          this.chartConfig.data.groups = this.config.groups;
        }
        this.chartConfig.data.x = 'x';
        if (this.config.bar?.width?.ratio !== undefined) {
          this.chartConfig.bar = this.config.bar;
        }
        this.chartConfig.data.x = 'x';
        this.chartConfig.axis.x = {
          show: true,
          type: 'category'
        };
        if (this.chartConfig.xDataKey !== undefined) {
          this.chartConfig.data.x = this.chartConfig.xDataKey;
        }
      }
    },
    yAxisFormatter() {
      this.chartConfig.axis.y.show = true;
      this.chartConfig.axis.y.tick.format = (value) => {
        return Vue.options.filters.num_format(
          value,
          this.config.chartOptions.axis_format.y.pre,
          this.config.chartOptions.axis_format.y.suff,
          this.config.chartOptions.axis_format.y.min,
          this.config.chartOptions.axis_format.y.roundoff,
          undefined,
          getSelectedCurrency()
        );
      };
      if (this.config.chartOptions.axis_format.y.inverted) {
        this.chartConfig.axis.y.inverted = true;
      }
      if (this.config.chartOptions.axis_format.y.min) {
        this.chartConfig.axis.y.min =
          this.config.chartOptions.axis_format.y.min;
      }
      if (this.config.chartOptions.axis_format.y.tick?.count) {
        this.chartConfig.axis.y.tick.count =
          this.config.chartOptions.axis_format.y.tick?.count;
      }
    },
    y2AxisFormatter() {
      this.chartConfig.axis.y2.show = true;
      this.chartConfig.axis.y2.tick.format = (value) => {
        return Vue.options.filters.num_format(
          value,
          this.config.chartOptions.axis_format.y2.pre,
          this.config.chartOptions.axis_format.y2.suff,
          this.config.chartOptions.axis_format.y2.min,
          this.config.chartOptions.axis_format.y2.roundoff,
          undefined,
          getSelectedCurrency()
        );
      };
      if (this.config.chartOptions.axis_format.y2.inverted) {
        this.chartConfig.axis.y2.inverted = true;
      }
      if (this.config.chartOptions.axis_format.y2.min) {
        this.chartConfig.axis.y2.min =
          this.config.chartOptions.axis_format.y2.min;
      }
      if (this.config.chartOptions.axis_format.y2.tick?.count) {
        this.chartConfig.axis.y2.tick.count =
          this.config.chartOptions.axis_format.y2.tick?.count;
      }
    },
    timeSeriesChartOptionsGenerator() {
      if (typeof this.config.chartOptions.timeseries === 'object') {
        this.chartConfig.data.xs = this.config.chartOptions.timeseries;
      } else {
        this.chartConfig.data.x = this.config.chartOptions.timeseries;
      }
      this.chartConfig.axis.x = {
        show: this.config.chartOptions?.axis?.x?.show ?? true,
        type: 'timeseries',
        tick: {
          format: '%e %b',
          fit: true,
          outer: false,
          count: this.config.chartOptions.chartWidth === 's' ? 5 : undefined
        }
      };
      if (this.config.chartOptions.realtime) {
        this.chartConfig.axis.x.tick.format = (value) => {
          return moment(value).utc().format('h a');
        };
      }
      if (this.config.chartOptions.axis_format?.x) {
        this.chartConfig.axis.x.tick.format =
          this.config.chartOptions.axis_format.x;
      }
      this.chartConfig.axis.y = {
        show: false,
        outer: false,
        tick: {
          format: function (a) {
            return Vue.options.filters.num_format(
              a.toFixed(2),
              'currency',
              undefined,
              undefined,
              undefined,
              undefined,
              getSelectedCurrency()
            );
          }
        }
      };
      if (this.config.chartOptions.axis_format?.y) {
        this.yAxisFormatter();
      }
      this.chartConfig.axis.y2 = {
        show: false,
        tick: {
          outer: false,
          format: function (a) {
            return Vue.options.filters.num_format(
              a.toFixed(2),
              'currency',
              undefined,
              undefined,
              undefined,
              undefined,
              getSelectedCurrency()
            );
          }
        }
      };
      if (this.config.chartOptions.axis_format?.y2) {
        this.y2AxisFormatter();
      }
      this.timeseriesChartOptionsGridGenerator();
    },
    timeseriesChartOptionsGridGenerator() {
      this.chartConfig.grid = {
        y: {
          show: false
        },
        x: {
          show: false
        }
      };
      if (
        this.config.chartOptions.grid &&
        this.config.chartOptions.grid.constructor === String
      ) {
        if (this.config.chartOptions.grid.indexOf('x') !== -1) {
          this.chartConfig.grid.x.show = true;
        }
        if (this.config.chartOptions.grid.indexOf('y') !== -1) {
          this.chartConfig.grid.y.show = true;
        }
      } else if (
        this.config.chartOptions.grid &&
        this.config.chartOptions.grid.constructor === Object
      ) {
        this.chartConfig.grid = this.config.chartOptions.grid;
      }
    },
    categoryChartConfigGenerator() {
      this.chartConfig.grid = {
        y: {
          show: false
        },
        x: {
          show: false
        }
      };
      this.chartConfig.data.xs = {};
      if (
        this.config.chartOptions.grid &&
        this.config.chartOptions.grid.indexOf('x') !== -1
      ) {
        this.chartConfig.grid.x.show = true;
      }
      if (
        this.config.chartOptions.grid &&
        this.config.chartOptions.grid.indexOf('y') !== -1
      ) {
        this.chartConfig.grid.y.show = true;
      }
      this.chartConfig.axis.x.categories = this.config.xAxisCategories;
      this.chartConfig.axis.x.tick = {
        show: true,
        culling: this.config?.chartOptions?.disableCulling
          ? false
          : this.config?.chartOptions?.culling ?? {
              max: 5,
              multiline: true
            }
      };
      this.chartConfig.axis.y.show = true;
      this.chartConfig.axis.y.tick = {
        format: (value) => {
          const tooltipFormat = this.config.chartOptions.tooltip_format;
          if (!tooltipFormat) {
            return;
          }
          const format = tooltipFormat[Object.keys(tooltipFormat)[0]];
          return Vue.options.filters.num_format(
            value,
            format?.pre,
            format?.suff,
            format?.min,
            format?.roundoff,
            undefined,
            getSelectedCurrency()
          );
        }
      };
      if (this.config?.chartOptions?.axis?.y2?.tick) {
        this.chartConfig.axis.y2.tick = {
          format: this.config.chartOptions.axis.y2.tick.format
        };
      }
      this.chartConfig.axis.x.show = true;
      if (this.config?.chartOptions?.xAxisHeight) {
        this.chartConfig.axis.x.height = this.config.chartOptions.xAxisHeight;
      }
      this.chartConfig.data.xFormat = '';
      this.chartConfig.data.x = '';
    },
    chartPaddingGenerator() {
      if (!this.chartConfig.padding) {
        this.chartConfig.padding = {};
      }
      for (var i in this.config.chartOptions.padding) {
        this.chartConfig.padding[i] = this.config.chartOptions.padding[i];
      }
    },
    chartAxesGenerator() {
      this.chartConfig.data.axes = this.config.chartOptions.axes;
      if (this.config.chartOptions.hideY2 === true) {
        if (!this.chartConfig.axis.y2) {
          this.chartConfig.axis.y2 = {};
        }
        this.chartConfig.axis.y2.show = false;
      }
    },
    chartConditionalConfigGenerator() {
      if (this.config.chartOptions.padding) {
        this.chartPaddingGenerator();
      }

      if (this.config.chartOptions.axes) {
        this.chartAxesGenerator();
      }

      if (this.config.chartOptions.xFormat) {
        this.chartConfig.data.xFormat = this.config.chartOptions.xFormat;
      }
      if (this.config.chartOptions.tooltip) {
        this.chartConfig.tooltip = this.config.chartOptions.tooltip;
      }

      if (this.config.chartOptions.regions) {
        this.chartConfig.data.regions = this.config.chartOptions.regions;
      }
      if (this.config.chartOptions.events) {
        this.chartConfig.events = this.config.chartOptions.events;
      }
      if (this.config.regions) {
        this.chartConfig.regions = this.config.regions;
      }
      if (this.config.chartOptions.gauge) {
        this.chartConfig.gauge = this.config.chartOptions.gauge;
      }
      if (this?.config?.chartOptions?.data?.colors) {
        this.chartConfig.data.colors = this.config.chartOptions.data.colors;
      }
      if (this?.config?.chartOptions?.data?.color) {
        this.chartConfig.data.color = this.config.chartOptions.data.color;
      }
      if (this?.config?.chartOptions?.data?.classes) {
        this.chartConfig.data.classes = this.config.chartOptions.data.classes;
      }
      this.chartConfig.tooltip_label_text =
        this.config.chartOptions.tooltip_label_text;
      // Handling the Y and Y2 axis in case for events. Without this, Values are getting plotted at the middle of the chart. Use Case - SDP Page
      // if (this.config.chartOptions.hasEvents) {
      //   this.chartConfig.axis.y2.min = 0;
      //   this.chartConfig.axis.y.min = 0;
      // }

      if (this.config.chartOptions.dynamicRange) {
        this.chartConfig.dynamicRange = true;
        this.chartConfig.axis.y.min = 0;
      } else {
        this.chartConfig.dynamicRange = false;
      }

      if (this.config?.chartOptions?.dynamicRangeValues?.length === 2) {
        this.chartConfig.dynamicRangeValues =
          this.config?.chartOptions?.dynamicRangeValues;
      }

      if (this.config.chartOptions.size) {
        this.chartConfig.size = this.config.chartOptions.size;
      }
    },
    lineChartConfigGenerator() {
      if (!this.chartConfig.line) {
        this.chartConfig.line = {};
      }
      if (this.config.chartOptions.line.connectNull === false) {
        this.chartConfig.line.connectNull = false;
      } else {
        this.chartConfig.line.connectNull = true;
      }
    },
    donutChartConfigGenerator() {
      this.chartConfig.data.order = null;
    },
    initConfig() {
      this.chartInitialConfigGenerator();
      if (this.config.chartOptions?.timeseries !== undefined) {
        this.timeSeriesChartOptionsGenerator();
      } else if (this.config.xAxisType === 'category') {
        this.categoryChartConfigGenerator();
      }
      this.chartConditionalConfigGenerator();
      if (this.config.chartOptions?.type === 'donut') {
        this.donutChartConfigGenerator();
      }
      if (this.config.chartOptions.line) {
        this.lineChartConfigGenerator();
      }
      if (this.config?.chartOptions?.labels) {
        this.chartConfig.data.labels = this.config.chartOptions.labels;
      }
      if (this.config?.chartOptions?.names) {
        this.chartConfig.data.names = this.config.chartOptions.names;
      }
      if (
        this.config?.chartOptions?.order === null ||
        this.config?.chartOptions?.order
      ) {
        this.chartConfig.data.order = this.config.chartOptions.order;
      }
    }
  }
};
