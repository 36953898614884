import dictionary from '@/components/ams/budgetPlanner/actions/actionsDictionary.js';
import { formatter } from '@/utils/helpers/formatter.js';
import cellRenderer from '@/components/globals/dataTable/tableCellComponent.js';
import customToolTip from '@/components/widgets/customToolTip';
import Vue from 'vue';
import utils from '@/utils/helpers/';
import moment from 'moment-timezone';

const getMaxFeedData = (that) => {
  const maxDate =
    that.$store.getters.getMaxDate.budget_planner_manual_actions &&
    that.$store.getters.getMaxDate.budget_planner_manual_actions.max_feed_date;
  const formatedDate = moment(maxDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
  return formatedDate;
};

const actionMap = {
  keywordBidChange: {
    current: 'previous_bid',
    suggested: 'current_bid',
    title: {
      mid: "this keyword's bid from"
    }
  },
  campaignBudgetChange: {
    current: 'previous_budget',
    suggested: 'current_budget',
    title: {
      mid: "this campaigns's daily budget from"
    }
  }
};

const suggestedActionComponent = Vue.extend({
  components: {
    customToolTip
  },
  computed: {
    description() {
      const data =
        this.params.data?.tooltip_description &&
        JSON.parse(this.params.data?.tooltip_description);
      const enableExplainability =
        Vue.options.filters.config_check(
          'feature.budget_planner.explainability.enable_external'
        ) ||
        false ||
        utils.internalUserCheck(window.user);
      const show = enableExplainability && data;
      return { data, show };
    },
    title() {
      const rowData = this.params.data;
      const title = {
        pre: 'Actions with this icon are supported by CommerceIQ platform',
        suff: null,
        mid: null
      };
      if (this.description.show) {
        // generate custom tiltle;
        const current = rowData[actionMap[rowData.action_type].current];
        const suggested = rowData[actionMap[rowData.action_type].suggested];
        const status = current > suggested ? 'Decreasing' : 'Increasing';
        title.pre = 'We recommend';
        title.mid = `${status} ${
          actionMap[rowData.action_type].title.mid
        } ${Vue.options.filters.num_format(
          current,
          'currency'
        )} to ${Vue.options.filters.num_format(suggested, 'currency')}`;
        title.suff = 'because of the following factors:';
      }
      return title;
    },
    cellValue() {
      return this.params.value;
    }
  },
  template: `
  <customToolTip>
    <div slot="toolTipData">
      <div class="u-max-width-480px" v-if="description.show">
        <div class="u-spacing-pl-l u-spacing-pt-m u-spacing-pb-s u-font-weight-600 u-font-size-2"><span class="u-color-blue-base">IQ</span> Tip</div>
        <div
          :class="[description.show? 'u-spacing-ml-l u-font-weight-400 u-spacing-mr-m u-spacing-mb-m u-font-size-5':'u-font-size-7']"
          class="u-fon u-line-height-1-3 u-color-grey-light"
        >{{title.pre}} <span class="u-font-weight-600" v-if="title.mid">"{{title.mid}}"</span>
        <span v-if="title.suff">{{title.suff}}</span>
        </div>
        <div class="u-font-size-6 u-font-weight-normal u-color-grey-base" v-if="description.show">
          <div
            v-for="(item, key) in description.data"
            class="u-display-flex u-spacing-ml-xs"
            :key="key"
            :class="{'u-spacing-mb-m': item.length > 0}"
          >
            <div v-if="item.length > 0" class="u-spacing-mr-m u-display-flex u-flex-align-items-flex-start u-width-100">
              <div
                class="u-bullet-points u-flex-none u-border-radius-l u-bg-color-blue-base u-spacing-ml-s u-spacing-mt-xs"
              ></div>
              <span class="u-spacing-pl-s u-line-height-1-3">{{item}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div slot="body" slot-scope="{tippyConfig}">
      <div
        class="u-width-100 u-color-grey-base u-text-case-title u-text-align-left u-flex-align-items-center u-flex-justify-content-space-between u-display-flex u-text-overflow-ellipsis"
      >
        <div class=" u-display-flex">
        <rb-icon
          v-tippy="{ placement: 'bottom', distance: -15, maxWidth: '500px', delay: [500,0] }"
          title="Actions with this icon are supported by CommerceIQ platform"
          class="u-cursor-pointer rb-icon--small u-color-blue-base"
          icon="logo"
        ></rb-icon>
        <div class="u-spacing-p-xs">{{cellValue}}</div>
        </div>
        <div v-if="description.show" class="actionsIQTipRowHover u-flex-justify-content-flex-end u-width-25 u-height-auto">
          <rb-icon
            v-tippy="tippyConfig"
            class="u-cursor-pointer rb-icon--xx-medium u-color-blue-base"
            icon="light-bulb"
          ></rb-icon>
        </div>
      </div>
    </div>
  </customToolTip>`
});

var map = {};
var units = {};
var axes = {};

const approveAction = (row) => {
  row.node.setSelected(true);
  row.context.componentParent.openActionsWorkspace('edit-actions');
};

for (var i in dictionary.map) {
  var configuredUnit = dictionary.map[i].unit;
  var unit = null;
  map[i] = dictionary.map[i].title;
  if (configuredUnit === 'CURRENCY') {
    unit = {
      pre: 'currency'
    };
  } else if (configuredUnit === 'PERCENTAGE') {
    unit = {
      suff: '%'
    };
  } else {
    unit = {
      pre: '',
      suff: ''
    };
  }
  units[map[i]] = unit;
  if (configuredUnit === 'CURRENCY') {
    axes[map[i]] = 'y';
  } else {
    axes[map[i]] = 'y2';
  }
}
/**
 * generate table column from the dictonary.js - Hardcoded column defn for Campaign page. Same Column definition will be used for CSV download(field and title)
 * To Disable Sorting for specific column - Disable enableSorting key in headerComponentParams
 */
var columnDefs = [];
function getColumnDefinition(vueRef, currentPage) {
  var _columnDefs = [];
  for (const i in dictionary.map) {
    if (
      Array.isArray(dictionary.map[i].page) &&
      dictionary.map[i].page.indexOf(currentPage) !== -1
    ) {
      const obj = {};
      obj.field = i;
      // obj.suppressSizeToFit = true;
      obj.headerComponentFramework = 'genericTableHeader';
      // obj.enableSorting = true;
      let keyType = 'string';
      if (dictionary.map[i].type === 'NUMERIC') {
        keyType = dictionary.map[i].unit ? dictionary.map[i].unit : 'NUMERIC';
      }
      if (dictionary.map[i].type === 'date') {
        keyType = dictionary.map[i].type;
      }
      obj.cellRendererParams = {
        keyType: keyType,
        formatterFn: formatter
      };
      obj.cellRenderer = cellRenderer;
      obj.headerName = dictionary.map[i].title;
      obj.title = dictionary.map[i].title;
      obj.headerComponentParams = {
        enableSorting: true
      };
      if (dictionary.map[i].type === 'icon') {
        delete obj.cellRenderer;
        obj.cellRendererFramework = 'iconTableCell';
        obj.headerComponentFramework = 'iconTableHeader';
        obj.cellRendererParams.iconClickEvent = vueRef.openSidePanel;
        obj.cellRendererParams.displayIcon = 'timeline';
        obj.headerComponentParams.displayName = 'Activity log';
        obj.cellRendererParams.toolTipText = dictionary.map[i].toolTipText;
        obj.notDownloadable = true;
        obj.headerComponentParams.displayIcon = 'timeline';
        obj.minWidth = 60;
      }
      if (dictionary.map[i].type === 'status') {
        delete obj.cellRenderer;
        obj.cellRendererFramework = 'alertsTableStatus';
        obj.headerComponentFramework = 'genericTableHeader';
        obj.cellRendererParams.approveAction = approveAction;
        obj.minWidth = 140;
      }
      if (dictionary.map[i].title === 'Suggested Action') {
        delete obj.cellRenderer;
        obj.cellRendererFramework = suggestedActionComponent;
        obj.minWidth = 270;
      }

      if (dictionary.map[i].title === 'Campaign Name') {
        obj.minWidth = 270;
      }

      const findKeyOrder = currentPage + '_keyOrder';
      const pageTooltip = currentPage + '_toolTipText';
      obj.keyOrder = dictionary.map[i][findKeyOrder];
      dictionary.map[i].pinned && (obj.pinned = dictionary.map[i].pinned);
      // dictionary.map[i].pinned && (delete obj.cellRendererFramework);
      if (dictionary.map[i][pageTooltip] || dictionary.map[i].toolTipText) {
        obj.headerComponentParams.toolTipText =
          dictionary.map[i][pageTooltip] || dictionary.map[i].toolTipText;
      }
      obj.headerComponentParams.keyType = keyType;
      _columnDefs.push(obj);
      // _columnDefs[0].minWidth = 350;
      // _columnDefs[0].pinned = 'left';
    }
  }
  /**
   * Sort based on key order
   */
  columnDefs = _columnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });
  return columnDefs;
}

export default {
  config: (that) => {
    return {
      filters: {
        emit: 'budgetPlannerActionsFiltersApplied',
        hasSearch: false
      },
      widgets: {
        widget1: {
          meta: {
            type: 'table',
            key: 'budgetPlannerManualActionTableCampaign',
            primaryKey: 'campaign_id',
            paginationAction: 'budgetPlannerPaginationTriggered',
            action: 'budgetPlannerManualActionTable'
          },
          header: {
            show: false,
            search: true,
            tooltip: ''
          },
          footer: {
            date: 'budget_planner_manual_actions',
            infoText:
              '| Metrics shown are for the 7-day period from 9 days ago to 2 days ago'
          },
          body: {
            actionType: 'campaignBudgetChange',
            APIConfig: {
              cubeName: 'ams_manual_actions_campaign_budget_change',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              measuresList: [
                'status',
                'action_payload',
                'campaign_type',
                'action_type',
                'previous_budget',
                'current_budget',
                'suggested_action',
                'tooltip_description',
                'campaign_name',
                'state',
                'start_date',
                'end_date',
                'paid_sales_14d',
                'roi',
                'iroas',
                'avg_cost',
                'halo_sales_14_day',
                'impressions',
                'clicks',
                'ctr',
                'orders_14d',
                'units_14d',
                'conversions_14d',
                'cpc'
              ],
              groupByDimensionsList: [
                'campaign_id',
                'profile_id',
                'primary_key'
              ],
              customAPIDecisionVars: { system: 'budget_optimizer' },
              orderByList: [
                {
                  dimension: 'paid_sales_14d',
                  direction: 'DESC'
                }
              ],
              where: {
                dimensionNameValueList: []
              },
              limit: 30,
              page: 1
            },
            getMaxFeedData: getMaxFeedData(that),
            download: {
              fileName: 'ManualActionsCampaignTableData',
              columnMap: getColumnDefinition(that, 'campaign'),
              action: 'downloadManualActionsTableData'
            },
            columnDefs: getColumnDefinition(that, 'campaign')
          }
        },
        widget2: {
          meta: {
            type: 'table',
            key: 'budgetPlannerManualActionTableKeyword',
            primaryKey: 'keyword_id',
            paginationAction: 'budgetPlannerPaginationTriggered',
            action: 'budgetPlannerManualActionTable'
          },
          header: {
            show: false,
            search: true,
            tooltip: ''
          },
          footer: {
            date: 'budget_planner_manual_actions',
            infoText:
              '| Metrics shown are for the 14-day period from 16 days ago to 2 days ago'
          },
          body: {
            actionType: 'keywordBidChange',
            APIConfig: {
              cubeName: 'ams_manual_actions_keywords_bid_change',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              measuresList: [
                'status',
                'action_payload',
                'suggested_action',
                'campaign_type',
                'action_type',
                'previous_bid',
                'current_bid',
                'keyword_text',
                'campaign_name',
                'cpc',
                'tooltip_description',
                'match_type_keyword',
                'roi',
                'iroas',
                'impressions',
                'clicks',
                'paid_sales_14d',
                'spend',
                'last_bid_change_date',
                'ctr',
                'orders_14d',
                'units_14d',
                'conversions_14d'
              ],
              groupByDimensionsList: [
                'keyword_id',
                'campaign_id',
                'profile_id',
                'primary_key'
              ],
              customAPIDecisionVars: { system: 'budget_optimizer' },
              orderByList: [
                {
                  dimension: 'paid_sales_14d',
                  direction: 'DESC'
                }
              ],
              where: {
                dimensionNameValueList: []
              },
              limit: 30,
              page: 1
            },
            getMaxFeedData: getMaxFeedData(that),
            download: {
              fileName: 'ManualActionsKeywordTableData',
              columnMap: getColumnDefinition(that, 'keyword'),
              action: 'downloadManualActionsTableData'
            },
            columnDefs: getColumnDefinition(that, 'keyword')
          }
        }
      }
    };
  }
};
