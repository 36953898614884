import Vue from 'vue';
import HttpService from '@/utils/services/http-service';
import { cloneDeep } from 'lodash';
import utils from '@/utils/helpers/';
import HttpLayer from '@/utils/services/http-layer';
import { widgetsConfig } from '@/components/pages/businessInsights/widgets.js';
// import moment from 'moment';

const benchmarkingConstants = {
  widget: 'benchMarking'
};

const defaults = {
  selectedRollUp: {
    name: 'Days',
    value: 'DAY',
    diff: 'day'
  },
  isPlotted: false
};
const state = {
  benchmarkingSelectedColumn: {},
  benchmarkingDropdownList: {},
  benchmarkingFields: {},
  benchmarkingMetaData: {},
  benchmarkingMainDropdownData: {},
  benchmarkingMainDropdownSelection: {},
  benchmarkingTableMetricList: {},
  benchmarkingPlottedMetric: null,
  benchmarkingMetadata: {},
  benchmarkingMetricTypes: [],
  benchMarkingDropdownWhere: {
    dimensionNameValueList: [],
    date: {},
    pvpDate: {}
  },
  benchMarkingWhere: {
    dimensionNameValueList: [],
    date: {},
    pvpDate: {}
  },
  benchMarkingTableSelectedMetricList: {},
  benchMarkingOverallState: {},
  benchMarkingTimeseries: {},
  benchMarkingRollUpBy: {},
  benchMarkingChartState: {},
  bechmarkingIsPlotted: {},
  benchmarkingIsGroupingRequired: {},
  benchmarkingEntityDataWarehouse: {},
  bechmarkingTempPinColumns: {},
  benchmarkingRollUpOptions: {},
  benchmarkingAliasToMetricMapping: {},
  benchmarkingNameToMetricMapping: {}
};

const initStore = (state, key) => {
  Vue.set(state.benchmarkingAliasToMetricMapping, key, {});

  Vue.set(state.benchmarkingNameToMetricMapping, key, {});

  Vue.set(state.benchmarkingRollUpOptions, key, []);

  Vue.set(state.benchmarkingSelectedColumn, key, {});
  Vue.set(state.benchmarkingDropdownList);

  Vue.set(state.benchmarkingSelectedColumn, key, {});

  Vue.set(state.benchmarkingDropdownList, key, {});

  Vue.set(state.benchmarkingFields, key, {});

  Vue.set(state.benchmarkingMetaData, key, {});

  Vue.set(state.benchmarkingMainDropdownData, key, {});

  Vue.set(state.benchmarkingMainDropdownSelection, key, {});

  Vue.set(state.benchmarkingTableMetricList, key, {});

  Vue.set(state, 'benchmarkingPlottedMetric', {});

  Vue.set(state.benchmarkingPlottedMetric, key, null);

  Vue.set(state.benchmarkingMetadata, key, {});

  Vue.set(state.benchmarkingMetricTypes, key, []);

  Vue.set(state.bechmarkingTempPinColumns, key, false);

  Vue.set(state.benchMarkingDropdownWhere, key, {
    dimensionNameValueList: [],
    date: {},
    pvpDate: {}
  });

  Vue.set(state.benchMarkingWhere, key, {
    dimensionNameValueList: [],
    date: {},
    pvpDate: {}
  });

  Vue.set(state.benchMarkingTableSelectedMetricList, key, {});

  Vue.set(state.benchMarkingOverallState, key, {});

  Vue.set(state.benchMarkingTimeseries, key, {});

  Vue.set(state.benchMarkingRollUpBy, key, {});

  Vue.set(state.benchMarkingChartState, key, {});

  Vue.set(state.bechmarkingIsPlotted, key, defaults.isPlotted);

  Vue.set(state.benchmarkingIsGroupingRequired, key, false);

  Vue.set(state.benchmarkingEntityDataWarehouse, key, {});
};

const getters = {
  getFullBenchmarkingTableMetricList: (state) => {
    return state.benchmarkingTableMetricList;
  },
  getBenchmarkingRollUpBy: (state) => {
    return state.benchMarkingRollUpBy;
  },
  getBenchmarkingAliasToMetricMapping: (state) => {
    return state.benchmarkingAliasToMetricMapping;
  },
  getBenchmarkingNameToMetricMapping: (state) => {
    return state.benchmarkingNameToMetricMapping;
  },
  getbenchmarkingDataWarehouse: (state) => {
    return state.benchmarkingEntityDataWarehouse;
  },
  getbenchMarkingSaveViewData: (state) => {
    const result = {};
    for (const storeKey in state.benchmarkingMetaData) {
      const selectedMap = {};
      for (const rowCategory in state.benchMarkingTableSelectedMetricList[
        storeKey
      ]) {
        for (const key in state.benchMarkingTableSelectedMetricList[storeKey][
          rowCategory
        ]) {
          if (!selectedMap[rowCategory]) {
            selectedMap[rowCategory] = {};
          }
          selectedMap[rowCategory][key] = {
            key: state.benchMarkingTableSelectedMetricList[storeKey][
              rowCategory
            ][key].key
          };
        }
      }
      result[storeKey] = {
        // benchmarkingSelectedColumn: state.benchmarkingSelectedColumn[storeKey],
        benchmarkingMainDropdownSelectionKey:
          state.benchmarkingMainDropdownSelection[storeKey]?.data
            ?.dimensionName,
        // benchmarkingPlottedMetric: state.benchmarkingPlottedMetric[storeKey]?.key,
        benchMarkingRollUpBy: state.benchMarkingRollUpBy[storeKey]?.value,
        benchMarkingTableSelectedMetricList: selectedMap
      };
    }
    return result;
  },
  getBechmarkingColumnSortedOrder: (state) => {
    const domList = document.querySelectorAll('.custom-table-header');
    const list = [];
    for (let i = 0; i < domList.length; i++) {
      list.push(domList[i].getAttribute('custom-table-header-column-id'));
    }
    return list;
  },
  isBenchmarkingDropdownEnabled: (state) => {
    const result = {};
    for (const storeKey in state.benchmarkingSelectedColumn) {
      // const maxCount = 7; // TODO 5
      // const count = Object.keys(state.benchmarkingSelectedColumn[storeKey]).length;
      result[storeKey] = true; // count < maxCount;
    }
    return result;
  },
  getBenchmarkingSelectedColumn: (state) => {
    return state.benchmarkingSelectedColumn;
  },
  getBenchmarkingDropdownList: (state) => {
    return state.benchmarkingDropdownList;
  },
  getbenchmarkingTableFields: (state) => {
    return state.benchmarkingFields;
  },
  getBenchmarkingMainDropdownData: (state) => {
    const data = cloneDeep(state.benchmarkingMainDropdownData);
    const results = {};
    for (const storeKey in data) {
      results[storeKey] = {
        error: state.benchmarkingMainDropdownData[storeKey].error,
        load: state.benchmarkingMainDropdownData[storeKey].load,
        nodata: state.benchmarkingMainDropdownData[storeKey].nodata,
        rows: []
      };
      const rows = data[storeKey].rows;
      for (let i = 0; i < rows.length; i++) {
        if (!rows[i].filterGroup.allFiltersApplicable) {
          for (
            let j = 0;
            j < state.benchMarkingWhere[storeKey].dimensionNameValueList.length;
            j++
          ) {
            // state.benchMarkingWhere.dimensionNameValueList.length
            const index = rows[i].filterGroup.filters.findIndex((filterKey) => {
              return (
                state.benchMarkingWhere[storeKey].dimensionNameValueList[j]
                  .dimensionName === filterKey
              );
              // return filterKey === 'profile_name';
            });
            if (index > -1) {
              rows[i].disable = false;
            } else {
              rows[i].disable = true;
              break;
            }
          }
        }
        results[storeKey].rows.push(rows[i]);
      }
    }
    return results;
  },
  getBenchmarkingMainDropdownSelection: (state) => {
    return state.benchmarkingMainDropdownSelection;
  },
  getBenchmarkingTableMetricsList: (state) => {
    const results = {};
    const findTreeNodeAndMarkDisable = (list, disableObj) => {
      for (let i = 0; i < list.length; i++) {
        if (list[i].children?.length > 0) {
          const disableList = [];
          for (const key in disableObj) {
            disableList.push(disableObj[key]);
          }
          utils.disableTreeNodes(list[i], disableList);
        }
      }
    };
    for (const storeKey in state.benchmarkingTableMetricList) {
      results[storeKey] = {};
      const allTableMetricList = cloneDeep(
        state.benchmarkingTableMetricList[storeKey]
      );
      const selectedMetric = cloneDeep(
        state.benchMarkingTableSelectedMetricList[storeKey]
      );
      for (const groupKey in allTableMetricList) {
        const groupedAllList = allTableMetricList[groupKey];
        results[storeKey][groupKey] = {};
        const groupedSelectedMetric = selectedMetric[groupKey];
        findTreeNodeAndMarkDisable(groupedAllList, groupedSelectedMetric);
        for (const metricKey in groupedSelectedMetric) {
          const index = groupedAllList.findIndex((item) => {
            return item.key === metricKey;
          });
          if (index > -1) {
            groupedAllList.splice(index, 1);
          }
        }
        results[storeKey][groupKey] = groupedAllList;
      }
    }
    // return state.benchmarkingTableMetricList;
    return results;
  },
  getBenchmarkingPlottedMetric: (state) => {
    return state.benchmarkingPlottedMetric;
  },
  getBenchmarkingMetadata: (state) => {
    return state.benchmarkingMetadata;
  },
  getBenchmarkingMetricTypes: (state) => {
    return state.benchmarkingMetricTypes;
  },
  getBenchMarkingOverallState: (state) => {
    return state.benchMarkingOverallState;
  },
  getBenchMarkingTimeseries: (state) => {
    return state.benchMarkingTimeseries;
  },
  getBenchmarkingChartState: (state) => {
    return state.benchMarkingChartState;
  },
  getBechmarkingIsPlotted: (state) => {
    return state.bechmarkingIsPlotted;
  },
  getBenchmarkingIsGroupingRequired: (state) => {
    return state.benchmarkingIsGroupingRequired;
  }
};

const mutations = {
  BENCHMARKING_CLEAR_SELECTED_LIST: (state, { storeKey }) => {
    Vue.set(state.benchmarkingSelectedColumn, storeKey, {});
  },
  BENCHMARKING_SELECTED_COLUMN_LIST_SUCCESS: (state, { data, storeKey }) => {
    Vue.set(state.benchmarkingSelectedColumn[storeKey], data.entityId, {});
    Vue.set(
      state.benchmarkingSelectedColumn[storeKey][data.entityId],
      'data',
      data
    );
    Vue.set(
      state.benchmarkingSelectedColumn[storeKey][data.entityId],
      'load',
      false
    );
    Vue.set(
      state.benchmarkingSelectedColumn[storeKey][data.entityId],
      'error',
      false
    );
  },
  BENCHMARKING_SELECTED_COLUMN_LIST_RESET: (state, { data, storeKey }) => {
    Vue.set(state.benchmarkingSelectedColumn[storeKey], data.entityId, {});
    Vue.set(
      state.benchmarkingSelectedColumn[storeKey][data.entityId],
      'data',
      data
    );
    Vue.set(
      state.benchmarkingSelectedColumn[storeKey][data.entityId],
      'load',
      true
    );
    Vue.set(
      state.benchmarkingSelectedColumn[storeKey][data.entityId],
      'error',
      false
    );
  },
  BENCHMARKING_SELECTED_COLUMN_LIST_ERROR: (state, { data, storeKey }) => {
    Vue.set(state.benchmarkingSelectedColumn[storeKey], data.entityId, {});
    Vue.set(
      state.benchmarkingSelectedColumn[storeKey][data.entityId],
      'data',
      data
    );
    Vue.set(
      state.benchmarkingSelectedColumn[storeKey][data.entityId],
      'load',
      false
    );
    Vue.set(
      state.benchmarkingSelectedColumn[storeKey][data.entityId],
      'error',
      true
    );
  },
  BENCHMARKING_DROPDOWN_LIST: (state, { data, storeKey, searchText }) => {
    // For ordering purpose. Short term fix for demo
    // let allItems = data.filter(item => { return item.dimensionType !== 'Amazon Categorization'; });
    // const amazonCategoryItems = data.filter(item => { return item.dimensionType === 'Amazon Categorization'; });
    // allItems = [...allItems, ...amazonCategoryItems];
    // let searchTextKey = searchText;
    // if (!searchTextKey) {
    //   searchTextKey = 'default';
    // }
    // let keyData = {
    //   []
    // }
    Vue.set(state.benchmarkingDropdownList[storeKey], 'data', data);
    Vue.set(state.benchmarkingDropdownList[storeKey], 'load', false);
    Vue.set(state.benchmarkingDropdownList[storeKey], 'error', false);
    Vue.set(state.benchmarkingDropdownList[storeKey], 'nodata', false);
  },
  BENCHMARKING_DROPDOWN_LIST_NO_DATA: (
    state,
    { data, storeKey, searchText }
  ) => {
    Vue.set(state.benchmarkingDropdownList[storeKey], 'data', []);
    Vue.set(state.benchmarkingDropdownList[storeKey], 'load', false);
    Vue.set(state.benchmarkingDropdownList[storeKey], 'error', false);
    Vue.set(state.benchmarkingDropdownList[storeKey], 'nodata', true);
  },
  BENCHMARKING_DELETE_SELECTED_COLUMN_ENTITY: (state, { data, storeKey }) => {
    Vue.delete(state.benchmarkingSelectedColumn[storeKey], data.entityId);
    for (const key in state.benchMarkingTimeseries[storeKey]) {
      if (
        state.benchMarkingTimeseries[storeKey][key].chartLegends[data.entityId]
      ) {
        Vue.delete(
          state.benchMarkingTimeseries[storeKey][key].chartLegends,
          data.entityId
        );
      }
    }
  },
  BENCHMARKING_TABLE_FIELDS: (state, { data, storeKey }) => {
    const levelName = 'benchmarkingFields';
    if (!state[levelName]) {
      Vue.set(state, levelName, {});
    }
    if (!state[levelName][storeKey]) {
      Vue.set(state[levelName], [storeKey], {});
    }
    if (!state[levelName][storeKey][data.id]) {
      Vue.set(state[levelName][storeKey], data.id, {});
    }
    Vue.set(state[levelName][storeKey][data.id], 'rows', data.rows);
    Vue.set(state[levelName][storeKey][data.id], 'load', false);
    Vue.set(state[levelName][storeKey][data.id], 'error', false);
    Vue.set(state[levelName][storeKey][data.id], 'id', data.id);
    Vue.set(state[levelName][storeKey][data.id], 'index', data.index);
    Vue.set(state[levelName][storeKey][data.id], 'level', data.level);
  },
  BENCHMARKING_WIDGET_METADATE: (state, { data, storeKey }) => {
    Vue.set(state.benchmarkingMetaData[storeKey], 'data', data);
    Vue.set(state.benchmarkingMetaData[storeKey], 'load', false);
    Vue.set(state.benchmarkingMetaData[storeKey], 'error', false);
  },
  BENCHMARKING_MAIN_DROPDOWN: (state, { data, storeKey }) => {
    data = data.map((item) => {
      item.label = item.dimensionLabel;
      item.dimensionType = widgetsConfig.filterMapping[item.dimensionType];
      return item;
    });
    // For ordering purpose. Short term fix for demo
    let allItems = data.filter((item) => {
      return item.dimensionType !== 'Amazon Categorization';
    });
    const amazonCategoryItems = data.filter((item) => {
      return item.dimensionType === 'Amazon Categorization';
    });
    allItems = [...allItems, ...amazonCategoryItems];
    Vue.set(state.benchmarkingMainDropdownData[storeKey], 'rows', allItems);
    Vue.set(state.benchmarkingMainDropdownData[storeKey], 'load', false);
    Vue.set(state.benchmarkingMainDropdownData[storeKey], 'error', false);
  },
  BENCHMARKING_MAIN_DROPDOWN_SELECTED: (state, { data, storeKey }) => {
    Vue.set(state.benchmarkingMainDropdownSelection[storeKey], 'data', data);
    Vue.set(state.benchmarkingMainDropdownSelection[storeKey], 'load', false);
    Vue.set(state.benchmarkingMainDropdownSelection[storeKey], 'error', false);
    // unset data
    Vue.set(state.benchmarkingSelectedColumn, storeKey, {});
    Vue.set(state.benchmarkingDropdownList, storeKey, {});
    Vue.set(state.benchmarkingFields, storeKey, {});
    Vue.set(state.benchmarkingTableMetricList, storeKey, {});
    Vue.set(state.benchmarkingPlottedMetric, storeKey, null);
    Vue.set(state.benchmarkingMetricTypes, storeKey, []);
    Vue.set(state.benchMarkingTableSelectedMetricList, storeKey, {});
    Vue.set(state.benchMarkingTimeseries, storeKey, {});
  },
  BENCHMARKING_TABLE_METRIC_LIST: (state, { data, storeKey }) => {
    for (const key in data) {
      Vue.set(state.benchmarkingTableMetricList[storeKey], key, data[key]);
    }
  },
  BENCHMARKING_SELECTED_COLUMN_SHIFT_AND_ADD_NEW: (
    state,
    { data, storeKey }
  ) => {
    for (const i in state.benchmarkingSelectedColumn[storeKey]) {
      state.benchmarkingSelectedColumn[storeKey][i].data.order++;
    }
    data.order = 0;
    Vue.set(state.benchmarkingSelectedColumn[storeKey], data.entityId, {});
    Vue.set(
      state.benchmarkingSelectedColumn[storeKey][data.entityId],
      'data',
      data
    );
    Vue.set(
      state.benchmarkingSelectedColumn[storeKey][data.entityId],
      'load',
      false
    );
    Vue.set(
      state.benchmarkingSelectedColumn[storeKey][data.entityId],
      'error',
      false
    );
    for (const key in state.benchmarkingFields[storeKey]) {
      for (
        let i = 0;
        i < state.benchmarkingFields[storeKey][key].rows.length;
        i++
      ) {
        Vue.set(
          state.benchmarkingFields[storeKey][key].rows[i],
          data.entityId,
          {
            load: true,
            data: {},
            error: false,
            rowCategory: state.benchmarkingFields[storeKey][key].rowCategory
          }
        );
      }
    }
  },
  BENCHMARKING_PLOTTED_METRIC: (state, { data, storeKey }) => {
    Vue.set(state.benchmarkingPlottedMetric, storeKey, data);
  },
  BENCHMARKING_PLOTTED_METRIC_NAME_ADDER: (state, { data, storeKey }) => {
    Vue.set(state.benchmarkingPlottedMetric[storeKey], 'keyLookUp', data);
  },
  BENCHMARKING_METADATA_SUCCESS: (state, { data, storeKey }) => {
    Vue.set(state.benchmarkingMetadata[storeKey], 'data', data);
    Vue.set(state.benchmarkingMetadata[storeKey], 'load', false);
    Vue.set(state.benchmarkingMetadata[storeKey], 'error', false);
  },
  BENCHMARKING_METADATA_ERROR: (state, { data, storeKey }) => {
    Vue.set(state.benchmarkingMetadata[storeKey], 'data', null);
    Vue.set(state.benchmarkingMetadata[storeKey], 'load', false);
    Vue.set(state.benchmarkingMetadata[storeKey], 'error', true);
  },
  BENCHMARKING_METADATA_RESET: (state, { data, storeKey }) => {
    Vue.set(state.benchmarkingMetadata[storeKey], 'data', null);
    Vue.set(state.benchmarkingMetadata[storeKey], 'load', true);
    Vue.set(state.benchmarkingMetadata[storeKey], 'error', false);
  },
  BENCHMARKING_METRIC_TYPE_LIST_SET: (state, { data, storeKey }) => {
    Vue.set(state.benchmarkingMetricTypes, storeKey, data);
  },
  BENCHMARKING_OVERALL_STATE_RESET: (state, { data, storeKey }) => {
    Vue.set(state.benchMarkingOverallState[storeKey], 'load', true);
    Vue.set(state.benchMarkingOverallState[storeKey], 'error', false);
  },
  BENCHMARKING_OVERALL_STATE_SUCCESS: (state, { data, storeKey }) => {
    Vue.set(state.benchMarkingOverallState[storeKey], 'load', false);
    Vue.set(state.benchMarkingOverallState[storeKey], 'error', false);
  },
  BENCHMARKING_OVERALL_STATE_ERROR: (state, { data, storeKey }) => {
    Vue.set(state.benchMarkingOverallState[storeKey], 'load', false);
    Vue.set(state.benchMarkingOverallState[storeKey], 'error', true);
  },
  BENCHMARKING_ROW_DATA_SETTER_SUCCESS: (state, { data, storeKey }) => {
    const levelName = 'benchmarkingFields';
    for (
      let i = 0;
      i < state[levelName][storeKey][data.id]?.rows?.length;
      i++
    ) {
      if (
        state[levelName][storeKey][data.id].rows[i].selectedMetric.key ===
          data.key &&
        state[levelName][storeKey][data.id].rows[i][data.entityId]
      ) {
        Vue.set(
          state[levelName][storeKey][data.id].rows[i][data.entityId],
          'load',
          false
        );
        Vue.set(
          state[levelName][storeKey][data.id].rows[i][data.entityId],
          'error',
          false
        );
        Vue.set(
          state[levelName][storeKey][data.id].rows[i][data.entityId],
          'data',
          data.data
        );
        Vue.set(
          state[levelName][storeKey][data.id].rows[i][data.entityId],
          'noData',
          false
        );
      }
    }
  },
  BENCHMARKING_ROW_DATA_SETTER_ERROR: (state, { data, storeKey }) => {
    const levelName = 'benchmarkingFields';
    for (
      let i = 0;
      i < state[levelName][storeKey][data.id]?.rows?.length;
      i++
    ) {
      if (
        state[levelName][storeKey][data.id].rows[i].selectedMetric.key ===
          data.key &&
        state[levelName][storeKey][data.id].rows[i][data.entityId]
      ) {
        Vue.set(
          state[levelName][storeKey][data.id].rows[i][data.entityId],
          'load',
          false
        );
        Vue.set(
          state[levelName][storeKey][data.id].rows[i][data.entityId],
          'error',
          true
        );
        Vue.set(
          state[levelName][storeKey][data.id].rows[i][data.entityId],
          'data',
          null
        );
        Vue.set(
          state[levelName][storeKey][data.id].rows[i][data.entityId],
          'noData',
          false
        );
      }
    }
  },
  BENCHMARKING_ROW_DATA_SETTER_LOADER: (state, { data, storeKey }) => {
    const levelName = 'benchmarkingFields';
    for (let i = 0; i < state[levelName][storeKey][data.id].rows.length; i++) {
      if (
        state[levelName][storeKey][data.id].rows[i].selectedMetric.key ===
          data.key &&
        state[levelName][storeKey][data.id].rows[i][data.entityId]
      ) {
        Vue.set(
          state[levelName][storeKey][data.id].rows[i][data.entityId],
          'load',
          true
        );
        Vue.set(
          state[levelName][storeKey][data.id].rows[i][data.entityId],
          'error',
          false
        );
        Vue.set(
          state[levelName][storeKey][data.id].rows[i][data.entityId],
          'data',
          {}
        );
        Vue.set(
          state[levelName][storeKey][data.id].rows[i][data.entityId],
          'noData',
          false
        );
      }
    }
  },
  BENCHMARKING_ROW_DATA_SETTER_NO_DATA_SUCCESS: (state, { data, storeKey }) => {
    const levelName = 'benchmarkingFields';
    for (let i = 0; i < state[levelName][storeKey][data.id].rows.length; i++) {
      if (
        state[levelName][storeKey][data.id].rows[i].selectedMetric.key ===
        data.key
      ) {
        Vue.set(
          state[levelName][storeKey][data.id].rows[i][data.entityId],
          'load',
          false
        );
        Vue.set(
          state[levelName][storeKey][data.id].rows[i][data.entityId],
          'error',
          false
        );
        Vue.set(
          state[levelName][storeKey][data.id].rows[i][data.entityId],
          'data',
          null
        );
        Vue.set(
          state[levelName][storeKey][data.id].rows[i][data.entityId],
          'noData',
          true
        );
      }
    }
  },
  BENCHMARKING_TABLE_METRIC_CHANGE: (state, { data, storeKey }) => {
    for (const key in state.benchmarkingSelectedColumn[storeKey]) {
      const levelName = 'benchmarkingFields';
      for (
        let i = 0;
        i < state[levelName][storeKey][data['ag-grid-parent-id']].rows.length;
        i++
      ) {
        if (
          state[levelName][storeKey][data['ag-grid-parent-id']].rows[i]
            .selectedMetric.key === data.selectedMetric.key
        ) {
          Vue.set(
            state[levelName][storeKey][data['ag-grid-parent-id']].rows[i][key],
            'load',
            true
          );
          Vue.set(
            state[levelName][storeKey][data['ag-grid-parent-id']].rows[i][key],
            'error',
            false
          );
          Vue.set(
            state[levelName][storeKey][data['ag-grid-parent-id']].rows[i][key],
            'data',
            null
          );
          Vue.set(
            state[levelName][storeKey][data['ag-grid-parent-id']].rows[i][key],
            'noData',
            false
          );
        }
      }
    }
    Vue.delete(
      state.benchMarkingTableSelectedMetricList[storeKey][data.rowCategory],
      [data.oldSelectedMetric.key]
    );
    Vue.set(
      state.benchMarkingTableSelectedMetricList[storeKey][data.rowCategory],
      data.selectedMetric.key,
      data.selectedMetric
    );
  },
  BENCHMARKING_SELECTED_TABLE_METRIC: (state, { data, storeKey }) => {
    if (
      !state.benchMarkingTableSelectedMetricList[storeKey][data.row.rowCategory]
    ) {
      Vue.set(
        state.benchMarkingTableSelectedMetricList[storeKey],
        data.row.rowCategory,
        {}
      );
    }
    for (let i = 0; i < data.rows.length; i++) {
      Vue.set(
        state.benchMarkingTableSelectedMetricList[storeKey][
          data.row.rowCategory
        ],
        data.rows[i].selectedMetric.key,
        data.rows[i].selectedMetric
      );
    }
  },
  BENCHMARKING_CHART_GRAPH_RESET: (state, { data, entityId, storeKey }) => {
    Vue.set(state.benchMarkingTimeseries, storeKey, {});
    Vue.set(state.benchMarkingChartState, storeKey, {});
    Vue.set(state.benchMarkingChartState[storeKey], 'load', true);
    Vue.set(state.benchMarkingChartState[storeKey], 'nodata', false);
    Vue.set(state.benchMarkingChartState[storeKey], 'error', false);
  },
  BENCHMARKING_CHART_GRAPH_NO_DATA: (state, { data, entityId, storeKey }) => {
    Vue.set(state.benchMarkingChartState, storeKey, {});
    Vue.set(state.benchMarkingChartState[storeKey], 'load', false);
    Vue.set(state.benchMarkingChartState[storeKey], 'nodata', true);
    Vue.set(state.benchMarkingChartState[storeKey], 'error', false);
  },
  BENCHMARKING_CHART_GRAPH_ERROR: (state, { data, entityId, storeKey }) => {
    Vue.set(state.benchMarkingChartState, storeKey, {});
    Vue.set(state.benchMarkingChartState[storeKey], 'load', false);
    Vue.set(state.benchMarkingChartState[storeKey], 'nodata', false);
    Vue.set(state.benchMarkingChartState[storeKey], 'error', true);
  },
  BENCHMARKING_CHART_GRAPH_SUCCESS: (state, { data, entityId, storeKey }) => {
    Vue.set(state.benchMarkingChartState, storeKey, {});
    Vue.set(state.benchMarkingChartState[storeKey], 'load', false);
    Vue.set(state.benchMarkingChartState[storeKey], 'nodata', false);
    Vue.set(state.benchMarkingChartState[storeKey], 'error', false);
  },
  BENCHMARKING_CHART_GRAPH_DATA: (state, { data, entityId, storeKey }) => {
    let timeseriesNotPresent = false;
    if (!state.benchMarkingTimeseries[storeKey][data.name]) {
      Vue.set(state.benchMarkingTimeseries[storeKey], data.name, {});
      Vue.set(
        state.benchMarkingTimeseries[storeKey][data.name],
        'timeseries',
        []
      );
      Vue.set(
        state.benchMarkingTimeseries[storeKey][data.name],
        'chartLegends',
        {}
      );
      timeseriesNotPresent = true;
    }
    Vue.set(
      state.benchMarkingTimeseries[storeKey][data.name].chartLegends,
      entityId,
      {
        config: data.config,
        displayData: data.displayData,
        additionalData: data.additionalData
      }
    );
    for (let i = 0; i < data.timeseries.length; i++) {
      if (!state.benchMarkingTimeseries[storeKey][data.name].timeseries[i]) {
        Vue.set(
          state.benchMarkingTimeseries[storeKey][data.name].timeseries,
          i,
          {}
        );
      }
      Vue.set(
        state.benchMarkingTimeseries[storeKey][data.name].timeseries[i],
        entityId,
        data.timeseries[i][data.name]
      );
      if (timeseriesNotPresent) {
        Vue.set(
          state.benchMarkingTimeseries[storeKey][data.name].timeseries[i],
          'report_date',
          data.timeseries[i].feed_date
        );
      }
    }
  },
  BENCHMARKING_DATE_CHANGE: (state, { storeKey }) => {
    Vue.set(state.benchMarkingWhere[storeKey].date, 'from', '2020-07-19');
  },
  BENCHMARKING_STORE_INIT: (state, storeKey) => {
    initStore(state, storeKey);
  },
  BENCHMARKING_WHERE_UPDATE: (state, { data, storeKey }) => {
    Vue.set(state.benchMarkingWhere, storeKey, data);
    Vue.set(state.benchMarkingTimeseries, storeKey, {});
  },
  BENCHMARKING_SET_ROLLUP_BY: (state, { data, storeKey }) => {
    Vue.set(state.benchMarkingRollUpBy, storeKey, data);
  },
  BENCHMARKING_SET_IS_PLOTTED: (state, { storeKey, data }) => {
    if (!data) {
      Vue.set(state.benchmarkingPlottedMetric, storeKey, null);
    }
    Vue.set(state.bechmarkingIsPlotted, storeKey, data);
  },
  BENCHMARKING_IS_GROUPING_REQUIRED: (state, { storeKey, data }) => {
    Vue.set(state.benchmarkingIsGroupingRequired, storeKey, data);
  },
  BENCHMARKING_SET_NEW_STATE: (state, { storeKey, data }) => {
    const keysToExclude = {
      benchmarkingMainDropdownSelectionKey:
        'benchmarkingMainDropdownSelectionKey',
      benchMarkingRollUpBy: 'benchMarkingRollUpBy',
      benchMarkingTableSelectedMetricList:
        'benchMarkingTableSelectedMetricList',
      benchmarkingSelectedColumn: 'benchmarkingSelectedColumn'
    };
    Vue.set(state.benchmarkingSelectedColumn, storeKey, {});
    if (data.benchmarkingSelectedColumn) {
      Vue.set(state.bechmarkingTempPinColumns, storeKey, true);
      Vue.set(
        state.benchmarkingSelectedColumn,
        storeKey,
        data.benchmarkingSelectedColumn
      );
      for (const key in data.benchmarkingSelectedColumn) {
        Vue.set(
          state.benchmarkingEntityDataWarehouse[storeKey],
          data.benchmarkingSelectedColumn[key].data.entityId,
          data.benchmarkingSelectedColumn[key].data
        );
      }
    }
    if (data.benchMarkingRollUpBy) {
      const index = state.benchmarkingRollUpOptions[storeKey].findIndex(
        (item) => {
          return item.value === data.benchMarkingRollUpBy;
        }
      );
      if (index > -1) {
        Vue.set(
          state.benchMarkingRollUpBy,
          storeKey,
          state.benchmarkingRollUpOptions[storeKey][index]
        );
      }
    }
    if (data.benchMarkingTableSelectedMetricList) {
      const selectedMap = {};
      for (const rowCategory in data.benchMarkingTableSelectedMetricList) {
        for (const key in data.benchMarkingTableSelectedMetricList[
          rowCategory
        ]) {
          if (state.benchmarkingMetadata[storeKey]?.data?.metrics?.[key]) {
            if (!selectedMap[rowCategory]) {
              selectedMap[rowCategory] = {};
            }
            selectedMap[rowCategory][key] = {
              key: key,
              name: state.benchmarkingMetadata[storeKey].data.metrics[key].label
            };
          }
        }
      }
      Vue.set(state.benchMarkingTableSelectedMetricList, storeKey, selectedMap);
    }
    if (data.benchmarkingMainDropdownSelectionKey) {
      const dropdownOptions = state.benchmarkingMainDropdownData[storeKey].rows;
      const index = dropdownOptions.findIndex((item) => {
        return item.dimensionName === data.benchmarkingMainDropdownSelectionKey;
      });
      if (index > -1) {
        Vue.set(
          state.benchmarkingMainDropdownSelection[storeKey],
          'data',
          dropdownOptions[index]
        );
      }
    }
    for (const key in data) {
      if (keysToExclude[key]) {
        continue;
      }
      if (!state[key]) {
        Vue.set(state, key, {});
      }
      Vue.set(state[key], storeKey, data[key]);
    }
  },
  BENCHMARKING_UPDATE_ORDERING: (state, { storeKey }) => {
    const domList = document.querySelectorAll('.custom-table-header');
    const list = [];
    for (let i = 0; i < domList.length; i++) {
      list.push(domList[i].getAttribute('custom-table-header-column-id'));
      const entityId = domList[i].getAttribute('custom-table-header-column-id');
      if (state.benchmarkingSelectedColumn[storeKey][entityId]) {
        Vue.set(
          state.benchmarkingSelectedColumn[storeKey][entityId].data,
          'order',
          i
        );
        Vue.set(
          state.benchmarkingSelectedColumn[storeKey][entityId].data,
          'main_order',
          null
        );
        Vue.set(
          state.benchmarkingSelectedColumn[storeKey][entityId].data,
          'replace',
          false
        );
      }
    }
  },
  BENCHMARKING_ADD_NEW_ENTITY_TO_WAREHOUSE: (state, { storeKey, data }) => {
    Vue.set(
      state.benchmarkingEntityDataWarehouse[storeKey],
      data.entityId,
      data
    );
  },
  BENCHMARKING_CLEAR_SELECTED_METRIC: (state, { storeKey }) => {
    Vue.set(state.benchMarkingTableSelectedMetricList, storeKey, {});
  },
  BENCHMARKING_OFF_TEMP_PIN_COLUMNS: (state, { storeKey }) => {
    Vue.set(state.bechmarkingTempPinColumns, storeKey, false);
  },
  BENCHMARKING_UPDATE_ROLL_UP_OPTIONS: (state, { storeKey, data }) => {
    Vue.set(state.benchmarkingRollUpOptions, storeKey, data);
  },
  BENCHMARKING_METADATA_MAPPING: (state, { storeKey, data }) => {
    for (const key in data.metrics) {
      const alias = data.metrics[key].alias;
      const name = data.metrics[key].name;
      Vue.set(
        state.benchmarkingAliasToMetricMapping[storeKey],
        alias,
        data.metrics[key]
      );
      Vue.set(
        state.benchmarkingNameToMetricMapping[storeKey],
        name,
        data.metrics[key]
      );
    }
  }
};

const actions = {
  bechmarkingUpdateRollupOpions: (context, { storeKey, data }) => {
    context.commit('BENCHMARKING_UPDATE_ROLL_UP_OPTIONS', { storeKey, data });
  },
  benchmarkingChatOrdering: (context, { storeKey }) => {
    context.commit('BENCHMARKING_UPDATE_ORDERING', { storeKey });
  },
  benchmarkingSetNewState: (context, { data, storeKey }) => {
    context.commit('BENCHMARKING_CLEAR_SELECTED_METRIC', { storeKey });
    context.commit('BENCHMARKING_SET_NEW_STATE', { data, storeKey });
    context.dispatch('bechmarkingRestart', storeKey);
  },
  initBenchmarkingStore: (context, storeKey) => {
    context.commit('BENCHMARKING_STORE_INIT', storeKey);
  },
  bechmarkingRemoveChart: (context, { storeKey }) => {
    context.commit('BENCHMARKING_SET_IS_PLOTTED', { storeKey, data: false });
  },
  fetchBenchmarkingAllSelectedColumnData: async (
    context,
    { data, storeKey }
  ) => {
    for (let i = 0; i < data.length; i++) {
      data[i].order = i;
      context.dispatch('fetchBenchmarkingColumnData', {
        data: data[i],
        storeKey
      });
    }
  },
  fetchBenchmarkingColumnData(context, { data, storeKey }) {
    context.commit('BENCHMARKING_SELECTED_COLUMN_LIST_RESET', {
      data,
      storeKey
    });
    context.commit('BENCHMARKING_SELECTED_COLUMN_LIST_SUCCESS', {
      data,
      storeKey
    });
    // setTimeout(() => {
    // }, 10);
  },
  bechmarkingAddNewSelectedColumn(context, { data, storeKey }) {
    context.commit('BENCHMARKING_SELECTED_COLUMN_SHIFT_AND_ADD_NEW', {
      data,
      storeKey
    });
    context.commit('BENCHMARKING_ADD_NEW_ENTITY_TO_WAREHOUSE', {
      data,
      storeKey
    });
    // const grouper = {};
    // for (let k = 0; k < rows.length; k++) {
    //   const dataGroup = context.state.benchmarkingMetadata[storeKey].data.metrics[rows[k].selectedMetric.key].dataGroup;
    //   if (!grouper[dataGroup]) {
    //     grouper[dataGroup] = [];
    //   }
    //   grouper[dataGroup].push(context.state.benchmarkingMetadata[storeKey].data.metrics[rows[k].selectedMetric.key]);
    // }
    // for (const key in grouper) {
    //   context.dispatch('getBechmarkingData', {
    //     data: {
    //       metrics: grouper[key],
    //       rowCategory: data.row.rowCategory,
    //       id: data.id,
    //       // key: rows[k].selectedMetric.key,
    //       selectedColumn: context.state.benchmarkingSelectedColumn[storeKey]
    //     },
    //     storeKey
    //   });
    // }
    const rowCategoryGrouper = {};
    for (const key in context.state.benchmarkingFields[storeKey]) {
      for (
        let i = 0;
        i < context.state.benchmarkingFields[storeKey][key].rows.length;
        i++
      ) {
        if (
          !rowCategoryGrouper[
            context.state.benchmarkingFields[storeKey][key].rows[i].rowCategory
          ]
        ) {
          rowCategoryGrouper[
            context.state.benchmarkingFields[storeKey][key].rows[i].rowCategory
          ] = {};
        }
        rowCategoryGrouper[
          context.state.benchmarkingFields[storeKey][key].rows[i].rowCategory
        ].id = key;
        if (
          !rowCategoryGrouper[
            context.state.benchmarkingFields[storeKey][key].rows[i].rowCategory
          ].grouper
        ) {
          rowCategoryGrouper[
            context.state.benchmarkingFields[storeKey][key].rows[i].rowCategory
          ].grouper = {};
        }
        const currentMetric =
          context.state.benchmarkingMetadata[storeKey].data.metrics[
            context.state.benchmarkingFields[storeKey][key].rows[i]
              .selectedMetric.key
          ];
        const dataGroupKey = currentMetric.dataGroup;
        if (
          !rowCategoryGrouper[
            context.state.benchmarkingFields[storeKey][key].rows[i].rowCategory
          ].grouper[dataGroupKey]
        ) {
          rowCategoryGrouper[
            context.state.benchmarkingFields[storeKey][key].rows[i].rowCategory
          ].grouper[dataGroupKey] = [];
        }
        rowCategoryGrouper[
          context.state.benchmarkingFields[storeKey][key].rows[i].rowCategory
        ].grouper[dataGroupKey].push(currentMetric);
      }
    }
    const selectedMetricList = cloneDeep(
      context.state.benchMarkingTableSelectedMetricList[storeKey]
    );
    for (const rowCategoryKey in rowCategoryGrouper) {
      const rows = [];
      const grouper = {};
      for (const key in selectedMetricList?.[rowCategoryKey]) {
        rows.push(selectedMetricList[rowCategoryKey][key]);
      }
      for (let k = 0; k < rows.length; k++) {
        const dataGroup =
          context.state.benchmarkingMetadata[storeKey].data.metrics[rows[k].key]
            .dataGroup;
        if (!grouper[dataGroup]) {
          grouper[dataGroup] = [];
        }
        grouper[dataGroup].push(
          context.state.benchmarkingMetadata[storeKey].data.metrics[rows[k].key]
        );
      }
      if (rowCategoryGrouper[rowCategoryKey]) {
        for (const key in grouper) {
          context.dispatch('getBechmarkingData', {
            data: {
              metrics: grouper[key],
              rowCategory: rowCategoryKey,
              id: rowCategoryGrouper[rowCategoryKey].id,
              // key: rows[k].selectedMetric.key,
              selectedColumn: {
                [data.entityId]: data
              }
            },
            storeKey
          });
        }
      }
    }
    // for (const rowCategoryKey in rowCategoryGrouper) {
    //   for (const groupKey in rowCategoryGrouper[rowCategoryKey].grouper) {
    //     context.dispatch('getBechmarkingData', {
    //       data:
    //       {
    //         metrics: rowCategoryGrouper[rowCategoryKey].grouper[groupKey],
    //         rowCategory: rowCategoryKey,
    //         id: rowCategoryGrouper[rowCategoryKey].id,
    //         selectedColumn: {
    //           [data.entityId]: data
    //         }
    //       },
    //       storeKey
    //     });
    //   }
    // }
    if (
      context.state.benchmarkingPlottedMetric[storeKey] &&
      context.state.benchmarkingPlottedMetric[storeKey].key
    ) {
      context.dispatch('getBenchmarkingChartData', {
        data: {
          metric:
            context.state.benchmarkingMetadata[storeKey].data.metrics[
              context.state.benchmarkingPlottedMetric[storeKey].key
            ],
          selectedColumn: context.state.benchmarkingSelectedColumn[storeKey]
        },
        storeKey
      });
    }
  },
  benchmarkingDeleteSelectedColumn(context, { data, storeKey }) {
    context.commit('BENCHMARKING_DELETE_SELECTED_COLUMN_ENTITY', {
      data,
      storeKey
    });
  },
  benchmarkingTableFields: (context, { data, storeKey }) => {
    const rows = [];
    const obj = {
      rowCategory: data.row.rowCategory,
      selectedMetric: null
    };
    for (const key in context.state.benchmarkingSelectedColumn[storeKey]) {
      obj[key] = {
        load: true,
        data: {},
        error: false,
        rowCategory: data.row.rowCategory
      };
    }
    if (
      context.state.benchMarkingTableSelectedMetricList[storeKey] &&
      context.state.benchMarkingTableSelectedMetricList[storeKey][
        data.row.rowCategory
      ] &&
      Object.keys(
        context.state.benchMarkingTableSelectedMetricList[storeKey][
          data.row.rowCategory
        ]
      ).length > 0
    ) {
      for (const key in context.state.benchMarkingTableSelectedMetricList[
        storeKey
      ][data.row.rowCategory]) {
        const _obj = cloneDeep(obj);
        _obj.selectedMetric =
          context.state.benchMarkingTableSelectedMetricList[storeKey][
            data.row.rowCategory
          ][key];
        rows.push(_obj);
      }
    } else {
      const count = Object.keys(
        context.state.benchmarkingTableMetricList[storeKey][
          data.row.rowCategory
        ]
      ).length;
      const numberOfEntities = count >= 4 ? 4 : count;
      const defaultList =
        (context.state.benchmarkingMainDropdownSelection[storeKey].data
          .defaultMetrics || {})[data.row.rowCategory] || [];
      // let defaultListLength = defaultList.length;
      let skippedCount = 0;
      const maxSearch = 20;
      if (defaultList?.length > 0) {
        for (let i = 0; i < defaultList.length; i++) {
          const _obj = cloneDeep(obj);
          const metric =
            context.state.benchmarkingMetadata[storeKey].data.metrics[
              defaultList[i]
            ];
          const index = context.state.benchmarkingTableMetricList[storeKey][
            data.row.rowCategory
          ].findIndex((item) => {
            return item.key === defaultList[i];
          });
          if (index > -1) {
            const selectedMetric =
              context.state.benchmarkingTableMetricList[storeKey][
                data.row.rowCategory
              ][index];
            if (selectedMetric.disable) {
              skippedCount++;
              continue;
            }
          }
          if (metric) {
            _obj.selectedMetric = {
              key: metric.source.sourceKey,
              name: metric.label,
              tooltip: metric.tooltip
            };
            rows.push(_obj);
          }
        }
      } else {
        for (let i = 0; i < numberOfEntities; i++) {
          const _obj = cloneDeep(obj);
          const selectedMetric =
            context.state.benchmarkingTableMetricList[storeKey][
              data.row.rowCategory
            ][i];
          if (selectedMetric.disable) {
            skippedCount++;
            continue;
          }
          _obj.selectedMetric = selectedMetric;
          rows.push(_obj);
        }
        for (let i = 0; i < skippedCount; i++) {
          const selectedMetric =
            context.state.benchmarkingTableMetricList[storeKey][
              data.row.rowCategory
            ][i];
          if (!selectedMetric) {
            break;
          }
          if (skippedCount > maxSearch) {
            break;
          }
          if (selectedMetric.disable) {
            skippedCount++;
            continue;
          }
          const _obj = cloneDeep(obj);
          _obj.selectedMetric = selectedMetric;
          rows.push(_obj);
        }
      }
      // const metricsWithWaterfall = context.state.benchmarkingMetadata[storeKey].data.metadata.groupInfo.metricsWithWaterfall;
      for (let i = 0; i < skippedCount; i++) {
        const selectedMetric =
          context.state.benchmarkingTableMetricList[storeKey][
            data.row.rowCategory
          ][i];
        if (!selectedMetric) {
          break;
        }
        let index = null;
        if (selectedMetric.children) {
          index = rows.findIndex((item) => {
            return utils.searchTree(selectedMetric, item?.selectedMetric?.key);
          });
        } else {
          index = rows.findIndex((item) => {
            return item?.selectedMetric?.key === selectedMetric.key;
          });
        }
        if (index > -1) {
          skippedCount++;
          continue;
        }
        // if (skippedCount > maxSearch) {
        //   break;
        // }
        if (selectedMetric.disable) {
          skippedCount++;
          continue;
        }
        const _obj = cloneDeep(obj);
        _obj.selectedMetric = selectedMetric;
        rows.push(_obj);
      }
    }
    data.rows = rows;
    context.commit('BENCHMARKING_TABLE_FIELDS', { data, storeKey });
    context.commit('BENCHMARKING_SELECTED_TABLE_METRIC', { data, storeKey });
    const grouper = {};
    for (let k = 0; k < rows.length; k++) {
      const dataGroup =
        context.state.benchmarkingMetadata[storeKey].data.metrics[
          rows[k].selectedMetric.key
        ].dataGroup;
      if (!grouper[dataGroup]) {
        grouper[dataGroup] = [];
      }
      grouper[dataGroup].push(
        context.state.benchmarkingMetadata[storeKey].data.metrics[
          rows[k].selectedMetric.key
        ]
      );
    }
    for (const key in grouper) {
      context.dispatch('getBechmarkingData', {
        data: {
          metrics: grouper[key],
          rowCategory: data.row.rowCategory,
          id: data.id,
          // key: rows[k].selectedMetric.key,
          selectedColumn: context.state.benchmarkingSelectedColumn[storeKey]
        },
        storeKey
      });
    }
  },
  async downloadBenchmarking(context, storeKey) {
    const grouper = {};
    const selectedMetricList = cloneDeep(
      context.state.benchMarkingTableSelectedMetricList[storeKey]
    );
    const metrics = cloneDeep(
      context.state.benchmarkingMetadata[storeKey].data.metrics
    );
    const selectedColumn = cloneDeep(
      context.state.benchmarkingSelectedColumn[storeKey]
    );
    const pageWhere = cloneDeep(context.state.benchMarkingWhere[storeKey]);
    const flatSelectMetricList = {};
    const metricsListArray = [];
    const downloadApiTemplate = cloneDeep(
      context.state.benchmarkingMetadata[storeKey].data.downloadApiTemplate
    );
    for (const key in selectedMetricList) {
      for (const metricKey in selectedMetricList[key]) {
        flatSelectMetricList[metricKey] = selectedMetricList[key][metricKey];
      }
    }
    for (const key in flatSelectMetricList) {
      const dataGroup = metrics[key].dataGroup;
      if (!grouper[dataGroup]) {
        grouper[dataGroup] = [];
      }
      grouper[dataGroup].push(metrics[key]);
      metricsListArray.push(metrics[key].name);
    }
    const overridenDataGroupsRequest = {};
    for (const key in grouper) {
      const metricData = {
        metrics: grouper[key],
        selectedColumn: selectedColumn
      };
      const api = metricData.metrics[0].api;
      api.request.metricsList = [];
      api.request.entityType =
        context.state.benchmarkingMainDropdownSelection[
          storeKey
        ].data.dimensionColumn;
      api.request.page = storeKey;
      api.request.operations.timeseriesRollupBy = null;
      api.request.operations.timeseriesEnabled = false;
      const entites = [];
      for (const key in metricData.selectedColumn) {
        entites.push({
          dimensionName: api.request.entityType,
          dimensionValue: key
        });
      }
      for (let i = 0; i < metricData.metrics.length; i++) {
        if (!api.request.metricsList) {
          api.request.metricsList = [];
        }
        api.request.metricsList.push(metricData.metrics[i].name);
        if (!api.request.where.dimensionNameValueList?.length === 0) {
          api.request.where.dimensionNameValueList = [];
        }
        api.request.where.dimensionNameValueList = [
          ...metricData.metrics[i].api.request.where.dimensionNameValueList
        ];
      }
      api.request.where.dimensionNameValueList = [
        ...new Set(api.request.where.dimensionNameValueList)
      ];
      api.request.where.date = pageWhere.date;
      api.request.where.pvpDate = pageWhere.pvpDate;
      api.request.where.dimensionNameValueList = [
        ...api.request.where.dimensionNameValueList,
        ...entites,
        ...pageWhere.dimensionNameValueList
      ];
      api.request.widget = benchmarkingConstants.widget;
      api.request.page = storeKey;
      overridenDataGroupsRequest[key] = api.request;
    }
    const request = downloadApiTemplate.request;
    request.entityValue = null;
    request.entityType =
      context.state.benchmarkingMainDropdownSelection[
        storeKey
      ].data.dimensionColumn;
    request.eventsList = [];
    request.metricsList = metricsListArray;
    request.overridenDataGroupsRequest = overridenDataGroupsRequest;
    request.where.date = pageWhere.date;
    request.where.pvpDate = pageWhere.pvpDate;
    request.where.dimensionNameValueList = pageWhere.dimensionNameValueList;
    request.widget = benchmarkingConstants.widget;
    request.page = storeKey;
    const res = await HttpService.post('DASHBOARD_SERVICE', request, {
      append: downloadApiTemplate.endPoint
    });
    const resData = res.data;
    return resData;
  },
  async fetchBenchmarkingMetadata(context, metaData) {
    // Changes for views
    const { storeKey } = metaData;
    try {
      context.commit('BENCHMARKING_OVERALL_STATE_RESET', { storeKey });
      context.commit('BENCHMARKING_METADATA_RESET', { data: null, storeKey });
      context.commit('BENCHMARKING_OVERALL_STATE_RESET', {
        data: null,
        storeKey
      });
      const body = {};
      body.widget = benchmarkingConstants.widget;
      body.page = storeKey;
      const { data } = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/widget/metadata/read'
      });
      context.commit('BENCHMARKING_METADATA_MAPPING', { data, storeKey });
      context.commit('BENCHMARKING_MAIN_DROPDOWN', {
        data: data?.customMetadata?.dropdownOptions,
        storeKey
      });
      context.commit('BENCHMARKING_METADATA_SUCCESS', { data, storeKey });
      const userPageMeta = context.getters.getUserPageMetadata;

      if (metaData?.config?.metadata?.[storeKey]) {
        context.dispatch('benchmarkingSetNewState', {
          data: metaData?.config?.metadata?.[storeKey],
          storeKey: storeKey
        });
        const viewState = {};
        viewState.type = metaData.config?.widgetName;
        viewState[metaData.config?.widgetName] = metaData.config?.metadata;
        context.dispatch('setGlobalViewAction', { ...viewState });
      } else if (userPageMeta?.page?.businessInsights?.benchmarking) {
        context.dispatch('benchmarkingSetNewState', {
          data: userPageMeta?.page?.businessInsights?.benchmarking?.[storeKey],
          storeKey: storeKey
        });
        const viewState = {};
        viewState.type = 'benchMarking';
        viewState.benchMarking =
          userPageMeta?.page?.businessInsights?.benchmarking;
        context.dispatch('setGlobalViewAction', { ...viewState });
      } else if (data?.metadata?.defaultConfig?.[storeKey]) {
        const viewState = {};
        viewState.type = data.name;
        viewState[data.name] = data?.metadata?.defaultConfig;
        context.dispatch('benchmarkingSetNewState', {
          data: data?.metadata?.defaultConfig?.[storeKey],
          storeKey: storeKey
        });
        context.dispatch('setGlobalViewAction', { ...viewState });
      }
      // await context.dispatch('bechmarkingRestart', storeKey).finally(() => {
      //   // Reading View from metadata
      // });
    } catch (err) {
      console.log(err);
      context.commit('BENCHMARKING_METADATA_ERROR', { storeKey });
    }
  },
  async bechmarkingRestart(context, storeKey) {
    context.commit('BENCHMARKING_OVERALL_STATE_RESET', { storeKey });
    const data = context.state.benchmarkingMetadata[storeKey].data;
    if (!data) {
      return;
    }
    // context.state.benchMarkingTableSelectedMetricList[storeKey]
    if (!context.state.benchmarkingMainDropdownSelection[storeKey].data) {
      context.commit('BENCHMARKING_MAIN_DROPDOWN_SELECTED', {
        data: data?.customMetadata?.dropdownOptions[0],
        storeKey
      });
    }
    if (!context.state.benchMarkingRollUpBy[storeKey].value) {
      context.commit('BENCHMARKING_SET_ROLLUP_BY', {
        data: defaults.selectedRollUp,
        storeKey
      });
    }
    context.dispatch('benchmarkingTableMetrics', { data: data, storeKey });
    await context.dispatch('fetchMainDropDownList', {
      searchText: null,
      storeKey
    });
    if (context.state.benchmarkingPlottedMetric[storeKey]) {
      context.commit('BENCHMARKING_SET_IS_PLOTTED', { data: true, storeKey });
      context.dispatch('getBenchmarkingChartData', {
        data: {
          metric:
            context.state.benchmarkingMetadata[storeKey].data.metrics[
              context.state.benchmarkingPlottedMetric[storeKey].key
            ],
          selectedColumn: context.state.benchmarkingSelectedColumn[storeKey]
        },
        storeKey
      });
    }
    context.commit('BENCHMARKING_OVERALL_STATE_SUCCESS', { storeKey });
  },
  benchmarkingMainDropdownChange(context, { data, storeKey }) {
    context.commit('BENCHMARKING_MAIN_DROPDOWN_SELECTED', { data, storeKey });
    context.commit('BENCHMARKING_SET_IS_PLOTTED', { data: false, storeKey });
    context.commit('BENCHMARKING_CLEAR_SELECTED_METRIC', { storeKey });
    context.dispatch('bechmarkingRestart', storeKey);
  },
  benchmarkingTableMetrics(context, { data, storeKey }) {
    const obj = {};
    const metricTypeList = [];
    // let metrics = {};
    // let metricGiven = [];
    // let showAllMetrics = false;
    const entityGroup = cloneDeep(
      context.state.benchmarkingMainDropdownSelection[storeKey].data.entityGroup
    );
    const allMetrics = cloneDeep(data.metrics);
    // for (let i = 0; i < entityGroup.length; i++) {
    //   if (entityGroup[i].showAllMetrics) {
    //     showAllMetrics = true;
    //   } else {
    //     metricGiven = [...metricGiven, ...entityGroup[i].metrics];
    //   }
    // }
    // if (showAllMetrics) {
    //   metrics = data.metrics;
    // } else {
    //   const deduplicateMetrics = [...new Set(metricGiven)];
    //   for (let i = 0; i < deduplicateMetrics.length; i++) {
    //     metrics[deduplicateMetrics[i]] = allMetrics[deduplicateMetrics[i]];
    //   }
    // }
    const rowCategoryCounter = {};
    for (const key in allMetrics) {
      allMetrics[key].disable = true;
      allMetrics[key].showIcon = true;
      allMetrics[
        key
      ].disableTooltip = `This metric is unavailable at a ${context.state.benchmarkingMainDropdownSelection[storeKey]?.data.label} level`;
      allMetrics[key].notAvailableMetricTooltip = {};
      context.state.benchmarkingMainDropdownSelection[
        storeKey
      ]?.data?.entityGroup.forEach((group) => {
        allMetrics[key].notAvailableMetricTooltip[
          `notAvailable${group.view}`
        ] = `${allMetrics[key].label} is currently unavailable for ${group.label}`;
      });
    }
    // let isGroupingRequired = false;
    // if (entityGroup.length > 1) {
    //   isGroupingRequired = true;
    // }
    for (let i = 0; i < entityGroup.length; i++) {
      for (let j = 0; j < entityGroup[i].metrics.length; j++) {
        if (allMetrics[entityGroup[i].metrics[j]]) {
          allMetrics[entityGroup[i].metrics[j]].disable = false;
          allMetrics[entityGroup[i].metrics[j]].showIcon = false;
          if (entityGroup[i].markingEnable) {
            allMetrics[entityGroup[i].metrics[j]].isAvailable = true;
            allMetrics[
              entityGroup[i].metrics[j]
            ].availableLabel = `Available for ${entityGroup[i].label}`;
          }
        } else {
          allMetrics[entityGroup[i].metrics[j]].showIcon = true;
          allMetrics[entityGroup[i].metrics[j]].disableTooltip = null;
          // allMetrics[entityGroup[i].metrics[j]].disableTooltip = `${allMetrics[entityGroup[i].metrics[j]].label} is currently unavailable for ${entityGroup[i].metrics[j].label}`;
        }
      }
      for (let k = 0; k < entityGroup[i].hiddenMetrics.length; k++) {
        if (allMetrics[entityGroup[i].hiddenMetrics[k]]) {
          delete allMetrics[entityGroup[i].hiddenMetrics[k]];
        }
      }
    }
    // entity: 'SALES',
    // rowCategory: 'sales'
    for (const key in allMetrics) {
      if (!allMetrics[key].disable) {
        for (let i = 0; i < allMetrics[key]?.views?.length; i++) {
          const { view } = allMetrics[key].views[i];
          if (!rowCategoryCounter[view]) {
            rowCategoryCounter[view] = 0;
          }
          rowCategoryCounter[view]++;
        }
      }
    }
    for (const key in allMetrics) {
      for (let i = 0; i < allMetrics[key]?.views?.length; i++) {
        const { label, view } = allMetrics[key].views[i];
        if (!rowCategoryCounter[view]) {
          continue;
        }
        if (!obj[view]) {
          obj[view] = [];
          metricTypeList.push({
            entity: label,
            rowCategory: view
          });
        }
        obj[view].push({
          key: allMetrics[key].source.sourceKey,
          name: allMetrics[key].label,
          disable: allMetrics[key].disable,
          tooltip: allMetrics[key].tooltip,
          disableTooltip: allMetrics[key].disableTooltip,
          showIcon: allMetrics[key].showIcon,
          notAvailableMetricTooltip: allMetrics[key].notAvailableMetricTooltip,
          isAvailable: allMetrics[key].isAvailable,
          availableLabel: allMetrics[key].availableLabel
        });
      }
    }
    // if (!context.state.benchmarkingPlottedMetric[storeKey]) {
    //   context.commit('BENCHMARKING_PLOTTED_METRIC', { data: obj.sales[0], storeKey });
    // }
    const order = ['sales', 'advertising', 'operations'];
    const orderedMetricTypeList = [];
    for (let i = 0; i < order.length; i++) {
      const index = metricTypeList.findIndex((value) => {
        return value.rowCategory === order[i];
      });
      if (index > -1) {
        orderedMetricTypeList.push(metricTypeList[index]);
      }
    }
    const metricsWithWaterfall =
      context.state.benchmarkingMetadata[storeKey].data.metadata.groupInfo
        .metricsWithWaterfall;
    const waterfall =
      context.state.benchmarkingMetadata[storeKey].data.metadata.groupInfo
        .waterfall;
    const addedWaterfalls = {};
    const _obj = {};
    for (const rowCategory in obj) {
      _obj[rowCategory] = [];
      for (let i = 0; i < obj[rowCategory].length; i++) {
        if (metricsWithWaterfall[obj[rowCategory][i].key]) {
          if (!addedWaterfalls[metricsWithWaterfall[obj[rowCategory][i].key]]) {
            addedWaterfalls[
              metricsWithWaterfall[obj[rowCategory][i].key]
            ] = true;
            _obj[rowCategory].push(
              waterfall[metricsWithWaterfall[obj[rowCategory][i].key]]
            );
          }
        } else {
          _obj[rowCategory].push(obj[rowCategory][i]);
        }
      }
    }
    context.commit('BENCHMARKING_TABLE_METRIC_LIST', { data: _obj, storeKey });
    context.commit('BENCHMARKING_METRIC_TYPE_LIST_SET', {
      data: orderedMetricTypeList,
      storeKey
    });
  },
  benchmarkingTableSelection(context, { data, storeKey }) {
    context.commit('BENCHMARKING_TABLE_METRIC_CHANGE', { data, storeKey });
    context.dispatch('getBechmarkingData', {
      data: {
        metrics: [
          context.state.benchmarkingMetadata[storeKey].data.metrics[
            data.selectedMetric.key
          ]
        ],
        rowCategory: data.rowCategory,
        id: data['ag-grid-parent-id'],
        key: data.selectedMetric.key,
        selectedColumn: context.state.benchmarkingSelectedColumn[storeKey]
      },
      storeKey
    });
  },
  benchmarkingStoreLogger(context, { storeKey, data }) {
    HttpLayer.post({
      urlId: 'EVENT_LOGS',
      APIData: {
        ...data,
        page: storeKey,
        widget: 'benchmarking'
      }
    }).then((data) => {
      console.log(data);
    });
  },
  benchMarkingChangePlottedMetric(context, { data, storeKey }) {
    var elem = document.querySelector('.benchmarkingHeader');
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    }
    context.dispatch('benchmarkingStoreLogger', {
      storeKey,
      data: {
        eventType: 'plotted_metric_change',
        type: 'Click',
        from: state.benchmarkingPlottedMetric[storeKey]?.name,
        to: data.name
      }
    });
    context.commit('BENCHMARKING_PLOTTED_METRIC', { data, storeKey });
    context.dispatch('getBenchmarkingChartData', {
      data: {
        metric:
          context.state.benchmarkingMetadata[storeKey].data.metrics[data.key],
        selectedColumn: context.state.benchmarkingSelectedColumn[storeKey]
      },
      storeKey
    });
    context.commit('BENCHMARKING_SET_IS_PLOTTED', { data: true, storeKey });
  },
  async benchmarkingEntitySearch(
    context,
    { searchText, storeKey, mutationDisable }
  ) {
    const options = await context.dispatch('fetchMainDropDownList', {
      searchText,
      storeKey,
      mutationDisable
    });
    return options;
  },
  async fetchMainDropDownList(
    context,
    { searchText, storeKey, mutationDisable }
  ) {
    try {
      const body = cloneDeep(
        context.state.benchmarkingMetadata[storeKey].data.customMetadata
          .commonApis.topEntityValues
      );
      body.entityType =
        context.state.benchmarkingMainDropdownSelection[
          storeKey
        ].data.dimensionColumn;
      const pageWhere = cloneDeep(context.state.benchMarkingWhere[storeKey]);
      body.where.date = pageWhere.date;
      body.where.pvpDate = pageWhere.pvpDate;
      body.operations.orderByList =
        context.state.benchmarkingMainDropdownSelection[
          storeKey
        ].data.additionalData.orderByList;
      // body.operations.limit = 20;
      // delete body.operations.limit;
      body.metricsList = [];
      for (let i = 0; i < body.operations.orderByList.length; i++) {
        body.metricsList.push(body.operations.orderByList[i].dimension);
      }
      body.dimensionsList =
        context.state.benchmarkingMainDropdownSelection[
          storeKey
        ].data.additionalData.dimensionsList;
      body.widget = benchmarkingConstants.widget;
      body.where.dimensionNameValueList = [];
      body.page = storeKey;
      if (searchText) {
        body.where.dimensionNameValueList.push({
          dimensionName:
            context.state.benchmarkingMainDropdownSelection[storeKey].data
              .additionalData.searchByKey,
          dimensionValue: searchText,
          operator: 'ILIKE'
        });
      }
      body.where.dimensionNameValueList = [
        ...pageWhere.dimensionNameValueList,
        ...body.where.dimensionNameValueList
      ];
      body.where.dimensionNameValueList = [
        ...new Set(body.where.dimensionNameValueList)
      ];
      const { data } = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/entity/metrics/data'
      });
      const entityData = data?.entityData;
      let columns = [];
      const entityGroup =
        context.state.benchmarkingMainDropdownSelection[storeKey].data
          .entityGroup;
      const dropdownGroupBy =
        context.state.benchmarkingMainDropdownSelection[storeKey].data
          .additionalData.dropdownGroupBy;
      let isGroupingRequired = false;
      if (entityGroup.length > 1) {
        isGroupingRequired = true;
      }
      for (let i = 0; i < entityData.length; i++) {
        let results = {};
        for (let j = 0; j < entityData[i].data.length; j++) {
          results = {
            ...entityData[i].data[j].RESULT,
            ...results
          };
        }
        if (isGroupingRequired) {
          const index = entityGroup.findIndex((groupObj) => {
            return groupObj.view === results[dropdownGroupBy];
          });
          const groupByUIKey = `label_${dropdownGroupBy}`;
          results.groupByUIKey = groupByUIKey;
          results.isGroupingRequired = isGroupingRequired;
          results[groupByUIKey] = entityGroup[index].label;
        }
        context.commit('BENCHMARKING_IS_GROUPING_REQUIRED', {
          data: isGroupingRequired,
          storeKey
        });
        columns.push({
          entityId: entityData[i].entityValue,
          entityName: entityData[i].entityValue,
          ...results
        });
      }
      columns = columns.map((item) => {
        item.label = item.entityName;
        return item;
      });
      if (!mutationDisable) {
        if (columns.length === 0) {
          context.commit('BENCHMARKING_DROPDOWN_LIST_NO_DATA', {
            data: [],
            storeKey,
            searchText: searchText
          });
        } else {
          context.commit('BENCHMARKING_DROPDOWN_LIST', {
            data: columns,
            storeKey,
            searchText: searchText
          });
        }
      }
      // if (Object.keys(context.state.benchmarkingSelectedColumn[storeKey]).length === 0) {
      //   const _columns = cloneDeep(columns)
      //   context.dispatch('fetchBenchmarkingAllSelectedColumnData', { data: _columns.splice(0, 4), storeKey });
      // }
      const _columns = cloneDeep(columns);
      if (
        !context.state.bechmarkingTempPinColumns[storeKey] &&
        !mutationDisable
      ) {
        context.dispatch('fetchBenchmarkingAllSelectedColumnData', {
          data: _columns.splice(0, 4),
          storeKey
        });
      }
      context.commit('BENCHMARKING_OFF_TEMP_PIN_COLUMNS', { storeKey });
      context.commit('BENCHMARKING_OVERALL_STATE_SUCCESS', {
        data: null,
        storeKey
      });
      return columns;
    } catch (err) {
      context.commit('BENCHMARKING_OVERALL_STATE_ERROR', {
        data: null,
        storeKey
      });
    }
  },
  benchMarkingWhereUpdate(context, { data, storeKey }) {
    context.commit('BENCHMARKING_WHERE_UPDATE', { data, storeKey });
    context.commit('BENCHMARKING_CLEAR_SELECTED_LIST', { storeKey });
    const rollUps = utils.getRollUps(data.date);
    context.dispatch('bechmarkingUpdateRollupOpions', {
      storeKey: storeKey,
      data: rollUps
    });
    if (context.state.bechmarkingIsPlotted[storeKey]) {
      const rollUpIndex = context.state.benchmarkingRollUpOptions[
        storeKey
      ].findIndex((item) => {
        return (
          item.value === context.state.benchMarkingRollUpBy[storeKey].value
        );
      });
      if (rollUpIndex === -1) {
        context.commit('BENCHMARKING_SET_ROLLUP_BY', {
          data: context.state.benchmarkingRollUpOptions[storeKey][0],
          storeKey
        });
      }
    }
    // const dimensionLabel = context.state.benchmarkingMainDropdownSelection[storeKey].data.dimensionLabel;
    if (
      !context.state.benchmarkingMainDropdownSelection[storeKey]?.data
        ?.filterGroup?.allFiltersApplicable
    ) {
      for (let i = 0; i < data.dimensionNameValueList.length; i++) {
        const index = context.state.benchmarkingMainDropdownSelection[
          storeKey
        ]?.data?.filterGroup?.filters?.findIndex((filterKey) => {
          return filterKey === data.dimensionNameValueList[i].dimensionName;
        });
        if (index === -1) {
          const asinIndex = context.state.benchmarkingMainDropdownData[
            storeKey
          ].rows.findIndex((dropdownOption) => {
            return dropdownOption.dimensionName === 'asin';
          });
          context.dispatch('benchmarkingMainDropdownChange', {
            data: context.state.benchmarkingMainDropdownData[storeKey].rows[
              asinIndex
            ],
            storeKey
          });
          context.dispatch('showGlobalSnack', {
            text: 'The widgets are populated with compatible data based on the current dashboard filter.'
          });
          return;
          // All back to asin
        }
      }
    }
    context.commit('BENCHMARKING_CLEAR_SELECTED_METRIC', { storeKey });
    context.dispatch('bechmarkingRestart', storeKey);
  },
  benchMarkingDateChange(context, { data, storeKey }) {
    context.commit('BENCHMARKING_DATE_CHANGE', { data, storeKey });
    context.commit('BENCHMARKING_CLEAR_SELECTED_METRIC', { storeKey });
    context.dispatch('bechmarkingRestart', storeKey);
  },
  benchmarkingSetRollUpBy(context, { data, storeKey }) {
    context.commit('BENCHMARKING_SET_ROLLUP_BY', { data, storeKey });
    context.dispatch('getBenchmarkingChartData', {
      data: {
        metric:
          context.state.benchmarkingMetadata[storeKey].data.metrics[
            context.state.benchmarkingPlottedMetric[storeKey].key
          ],
        selectedColumn: context.state.benchmarkingSelectedColumn[storeKey]
      },
      storeKey
    });
  },
  async getBenchmarkingChartData(context, { data, storeKey }) {
    context.commit('BENCHMARKING_CHART_GRAPH_RESET', { data, storeKey });
    try {
      const metricData = cloneDeep(data);
      metricData.metric.api.request.entityType =
        context.state.benchmarkingMainDropdownSelection[
          storeKey
        ].data.dimensionColumn;
      metricData.metric.api.request.metricsList = [metricData.metric.name]; // keyName
      metricData.metric.api.request.operations.timeseriesRollupBy =
        context.state.benchMarkingRollUpBy[storeKey].value;
      metricData.metric.api.request.operations.timeseriesEnabled = true;
      const entites = [];
      const checkList = {};
      for (const key in metricData.selectedColumn) {
        entites.push({
          dimensionName: metricData.metric.api.request.entityType,
          dimensionValue: key
        });
        checkList[key] = false;
        // context.commit('BENCHMARKING_ROW_DATA_SETTER_LOADER', {
        //   entityId: key,
        //   key: metricData.key,
        //   data: {},
        //   id: metricData.id
        // });
      }
      if (
        !metricData.metric.api.request.where.dimensionNameValueList?.length ===
        0
      ) {
        metricData.metric.api.request.where.dimensionNameValueList = [];
      }
      const pageWhere = cloneDeep(context.state.benchMarkingWhere[storeKey]);
      metricData.metric.api.request.where.date = pageWhere.date;
      metricData.metric.api.request.where.pvpDate = pageWhere.pvpDate;
      metricData.metric.api.request.where.dimensionNameValueList = [
        ...metricData.metric.api.request.where.dimensionNameValueList,
        ...entites
      ];
      metricData.metric.api.request.where.dimensionNameValueList = [
        ...new Set(metricData.metric.api.request.where.dimensionNameValueList),
        ...pageWhere.dimensionNameValueList
      ];

      metricData.metric.api.request.widget = benchmarkingConstants.widget;
      metricData.metric.api.request.page = storeKey;
      const res = await HttpService.post(
        'DASHBOARD_SERVICE',
        metricData.metric.api.request,
        { append: metricData.metric.api.endPoint }
      );

      let apiResponse = [];
      let tempTimeseriesAxesData = {};
      var modifiedTimeseriesAPIResponse = (apiResponse = res.data.entityData);

      apiResponse.map((item, index) => {
        item.data.map((itemData, childIndex) => {
          !!itemData.TIMESERIES &&
            itemData.TIMESERIES.length &&
            itemData.TIMESERIES.map((timeseriesData) => {
              tempTimeseriesAxesData[timeseriesData.feed_date] = null;
            });
          !!itemData.TIMESERIES &&
            itemData.TIMESERIES.length &&
            Object.keys(tempTimeseriesAxesData).map((item) => {
              const found = itemData.TIMESERIES.some(
                (el) => el.feed_date === item
              );
              if (!found)
                modifiedTimeseriesAPIResponse[index].data[
                  childIndex
                ].TIMESERIES.push({
                  feed_date: item,
                  [context.state.benchmarkingPlottedMetric[storeKey].key]: null
                });
              modifiedTimeseriesAPIResponse[index].data[childIndex].TIMESERIES
                .length &&
                modifiedTimeseriesAPIResponse[index].data[
                  childIndex
                ].TIMESERIES.sort((a, b) => {
                  return new Date(a.feed_date) - new Date(b.feed_date);
                });
            });
        });
      });

      const resData = [];
      resData.entityData = modifiedTimeseriesAPIResponse;
      for (let i = 0; i < resData.entityData.length; i++) {
        const index = resData.entityData[i].data?.findIndex((item) => {
          return item.alias === data.metric.alias;
        });
        if (index < 0) {
          continue;
        }
        context.commit('BENCHMARKING_PLOTTED_METRIC_NAME_ADDER', {
          storeKey,
          data: resData.entityData[i].data[index].name
        });
        if (!metricData.selectedColumn[resData.entityData[i].entityValue]) {
          continue;
        }
        const config = {};
        config.invertTag2 = data.metric.metadata.isInverted || false;
        if (data.metric.metadata.unit === 'CURRENCY') {
          config.tag1Unit = { pre: 'currency' };
        } else if (data.metric.metadata.unit === 'PERCENTAGE') {
          config.tag1Unit = { suff: '%' };
        } else {
          config.tag1Unit = null;
        }
        config.tag2Unit = { suff: '%' };
        if (
          data.metric.metadata.type &&
          data.metric.metadata.type.toLowerCase() === 'number'
        ) {
          config.tag1Unit = { roundoff: 0 };
        }
        const displayData = {
          tag1: resData?.entityData?.[i]?.data?.[index]?.RESULT?.[
            resData?.entityData?.[i]?.data?.[index]?.name
          ],
          tag2: resData?.entityData?.[i]?.data?.[index]?.PVP?.[
            `PVP_${resData?.entityData?.[i]?.data?.[index]?.name}`
          ]
        };
        if (data.metric.metadata.unit === 'PERCENTAGE') {
          displayData.tag2 =
            resData.entityData[i].data[index].PVP[
              `PVP_DIFF_${resData.entityData[i].data[index].name}`
            ];
        }
        checkList[resData.entityData[i].entityValue] = true;
        context.commit('BENCHMARKING_CHART_GRAPH_DATA', {
          data: {
            config,
            displayData: displayData,
            timeseries: resData.entityData[i].data[index].TIMESERIES || [],
            name: resData.entityData[i].data[index].name,
            additionalData:
              metricData.selectedColumn[resData.entityData[i].entityValue].data
          },
          entityId: resData.entityData[i].entityValue,
          storeKey
        });
      }
      for (const key in checkList) {
        if (!checkList[key]) {
          context.commit('BENCHMARKING_CHART_GRAPH_DATA', {
            data: {
              config: {},
              displayData: {},
              timeseries: [],
              name: data.metric.keyName,
              additionalData: metricData.selectedColumn[key].data
            },
            entityId: key,
            storeKey
          });
        }
      }
      context.commit('BENCHMARKING_CHART_GRAPH_SUCCESS', { data, storeKey });
    } catch (err) {
      console.log(err);
      context.commit('BENCHMARKING_CHART_GRAPH_ERROR', { data, storeKey });
    }
  },
  async getBechmarkingData(context, { data, storeKey }) {
    const metricData = cloneDeep(data);
    const api = metricData.metrics[0].api;
    api.request.metricsList = [];
    api.request.entityType =
      context.state.benchmarkingMainDropdownSelection[
        storeKey
      ].data.dimensionColumn;
    api.request.page = storeKey;
    api.request.operations.timeseriesRollupBy = null;
    api.request.operations.timeseriesEnabled = false;
    const entites = [];
    const checkList = {};
    for (const key in metricData.selectedColumn) {
      entites.push({
        dimensionName: api.request.entityType,
        dimensionValue: key
      });
      for (let i = 0; i < metricData.metrics.length; i++) {
        if (!checkList[metricData.metrics[i].source.sourceKey]) {
          checkList[metricData.metrics[i].source.sourceKey] = {};
        }
        checkList[metricData.metrics[i].source.sourceKey][key] = false;
      }
    }
    for (let i = 0; i < metricData.metrics.length; i++) {
      if (!api.request.metricsList) {
        api.request.metricsList = [];
      }
      api.request.metricsList.push(metricData.metrics[i].name);
      if (!api.request.where.dimensionNameValueList?.length === 0) {
        api.request.where.dimensionNameValueList = [];
      }
      api.request.where.dimensionNameValueList = [
        ...metricData.metrics[i].api.request.where.dimensionNameValueList
      ];
    }
    api.request.where.dimensionNameValueList = [
      ...new Set(api.request.where.dimensionNameValueList)
    ];
    try {
      const pageWhere = cloneDeep(context.state.benchMarkingWhere[storeKey]);
      api.request.where.date = pageWhere.date;
      api.request.where.pvpDate = pageWhere.pvpDate;
      api.request.where.dimensionNameValueList = [
        ...api.request.where.dimensionNameValueList,
        ...entites,
        ...pageWhere.dimensionNameValueList
      ];
      api.request.widget = benchmarkingConstants.widget;
      api.request.page = storeKey;
      const res = await HttpService.post('DASHBOARD_SERVICE', api.request, {
        append: api.endPoint
      });
      const resData = res.data;
      metricData.metrics.forEach((metric) => {
        let i = 0;
        for (i = 0; i < resData.entityData.length; i++) {
          const config = {};
          const index = resData.entityData[i].data?.findIndex((item) => {
            return item.alias === metric.alias;
          });
          if (index < 0) {
            continue;
          }
          if (!metricData.selectedColumn[resData.entityData[i].entityValue]) {
            continue;
          }
          config.invertTag2 = metric.metadata.isInverted || false;
          if (metric.metadata.unit === 'CURRENCY') {
            config.tag1Unit = { pre: 'currency' };
          } else if (metric.metadata.unit === 'PERCENTAGE') {
            config.tag1Unit = { suff: '%' };
          } else {
            config.tag1Unit = null;
          }
          config.tag2Unit = { suff: '%' };
          if (
            metric.metadata.type &&
            metric.metadata.type.toLowerCase() === 'number'
          ) {
            config.tag1Unit = { roundoff: 0 };
          }
          let tag2 = null;
          if (resData.entityData[i].data[index]?.PVP) {
            if (metric.metadata.unit === 'PERCENTAGE') {
              tag2 =
                resData.entityData[i].data[index].PVP[
                  `PVP_DIFF_${resData.entityData[i].data[index].name}`
                ];
            } else {
              tag2 =
                resData.entityData[i].data[index].PVP[
                  `PVP_${resData.entityData[i].data[index].name}`
                ];
            }
          }
          const displayData = {
            tag1: resData?.entityData?.[i]?.data?.[index]?.RESULT?.[
              resData?.entityData?.[i]?.data?.[index]?.name
            ],
            tag2: tag2
          };
          checkList[metric.source.sourceKey][
            resData.entityData[i].entityValue
          ] = true;
          context.commit('BENCHMARKING_ROW_DATA_SETTER_SUCCESS', {
            data: {
              entityId: resData.entityData[i].entityValue,
              key: metric.source.sourceKey,
              data: {
                config,
                data: displayData,
                timeseries: []
              },
              id: metricData.id
            },
            storeKey
          });
        }
      });
      metricData.metrics.forEach((metric) => {
        for (const key in checkList[metric.source.sourceKey]) {
          if (!checkList[metric.source.sourceKey][key]) {
            context.commit('BENCHMARKING_ROW_DATA_SETTER_NO_DATA_SUCCESS', {
              data: {
                entityId: key,
                key: metric.source.sourceKey,
                data: null,
                id: metricData.id
              },
              storeKey
            });
          }
        }
      });
    } catch (err) {
      console.log(err);
      metricData.metrics.forEach((metric) => {
        for (const key in checkList[metric.source.sourceKey]) {
          context.commit('BENCHMARKING_ROW_DATA_SETTER_ERROR', {
            data: {
              entityId: key,
              key: metric.source.sourceKey,
              data: null,
              id: metricData.id
            },
            storeKey
          });
        }
      });
    }
  }
};
export default {
  state,
  getters,
  mutations,
  actions
};

// class SQS {
//   // Add a message to the SQS
//   enqueue (...) {

//   }

//   // Get message from the SQS to process
//   dequeue (...) {

//   }

//   // Ack to SQS, consumer is done processing the message
//   delete (...) {

//   }

//   // This method will watch
//   watcher () {

//   }
// }

// Example

// timeout = 3

// t + 0->
// C1 msg1 = SQS.dequeue();
// C2 msg2 = SQS.dequeue();

// t + 1 ->
// C3 msg3 = SQS.dequeue();
// C4 msg4 = SQS.dequeue();

// t + 2 ->
// C1 SQS.delete(msg1);
// C3 SQS.delete(msg3);

// t + 3 ->
// C4 msg4 = SQS.delete(msg4);
// SQS enqueue back the msg2

// t + 4 ->
// C2 SQS.delete(msg2); //Ignore this
