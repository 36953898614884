import transformer from '@/utils/services/data-transformer';
import { isEmpty, cloneDeep, mapValues, get, merge, uniqBy } from 'lodash';
import HttpService from '@/utils/services/http-service';
import { downloadLinkAsFile } from '@/utils/helpers/downloader.js';
import utils from '@/utils/helpers/';
import moment from 'moment-timezone';
// import { formatter } from '@/utils/helpers/formatter.js';

export const groupApis = (metrics) => {
  const grouping = {};
  const metricKeys = Object.keys(metrics);
  metricKeys.forEach((metricKey) => {
    const metric = metrics[metricKey];
    if (!grouping[metric.dataGroup]) {
      if (metric?.metadata?.tableConfig?.columnHeader?.props?.params) {
        metric.metadata.tableConfig.columnHeader.props.params = null;
      }
      grouping[metric.dataGroup] = cloneDeep(metric);
      grouping[metric.dataGroup].api.request.metricsList = [metric.name];
    } else {
      grouping[metric.dataGroup].api.request.metricsList.push(metric.name);
    }
  });
  return grouping;
};

export const createDictionary = (selectedMetrics, selectableMetrics) => {
  try {
    const dictionary = {};
    const metricOrderList = [];
    selectedMetrics.forEach((metric) => {
      if (selectableMetrics[metric]) {
        const { label, tooltip, metadata, priority, name } =
          selectableMetrics[metric];
        metricOrderList.push(metric);
        dictionary[metric] = {
          title: label,
          name: name,
          toolTipText: tooltip,
          keyOrder: priority,
          enableSorting: true,
          ...metadata
        };
      }
    });
    metricOrderList.sort((a, b) => {
      return dictionary[a].keyOrder - dictionary[b].keyOrder;
    });
    metricOrderList.forEach((metricName, index) => {
      dictionary[metricName].keyOrder = index + 1;
    });
    return dictionary;
  } catch (error) {
    console.error(error);
  }
};

export const mergeResponseToSingleRowObjArray = (res) => {
  const metricKeys = Object.keys(res);
  // if (metricKeys?.length === 1) {
  const rowData = res[metricKeys[0]]?.entityData;
  // }
  const totalEntityCount = rowData[0]?.totalEntityCount;
  const rowsFormatted = [];
  rowData.forEach((entityUnit) => {
    const dataMap = {};
    entityUnit.data.forEach((item) => {
      dataMap[item.name] = item.RESULT[item.name];
    });
    rowsFormatted.push(dataMap);
  });
  return { rows: rowsFormatted, totalRowsCount: totalEntityCount };
};

const mapPropsToValues = (
  mapMetricNameToKey,
  tableProps,
  params,
  parserObj
) => {
  const props = {};
  Object.keys(tableProps).forEach((elem) => {
    const metric = mapMetricNameToKey[tableProps[elem]];
    props[elem] = tableProps[elem];
    if (metric && params?.data) {
      props[elem] = params.data[metric];
    }
    // parse the prop before passing to the component
    if (parserObj) {
      if (parserObj[elem]) {
        props[elem] = utils[parserObj[elem]](props[elem]);
      }
    }
  });
  props.params = params;
  return props;
};

export const getDynamicColumnDefs = (
  columnList,
  defaultOperations,
  columnKeyMapping
) => {
  let columnDefs = [];
  const metricKeys = Object.keys(columnList);
  const mapMetricNameToKey = {};

  metricKeys.forEach((e) => (mapMetricNameToKey[columnList[e].name] = e));
  metricKeys.forEach((key, index) => {
    const selectedColumn = merge(columnList[key], columnKeyMapping?.[key]);
    const tableConfig = selectedColumn?.tableConfig;
    let columnMetaObject = {};
    const columnDefinition = {
      name: key,
      uiField: {
        uiLabel: selectedColumn.title,
        uiTooltip: selectedColumn.toolTipText,
        uiType: 'custom',
        uiOrder: selectedColumn.keyOrder,
        metadata: {
          tableColumnName: key,
          showOnUi: selectedColumn?.visible,
          sortOnColumn: selectedColumn?.sortable,
          unselected: selectedColumn?.unselected,
          isFixed: selectedColumn.isFixed ?? false,
          checkboxSelection: selectedColumn?.checkboxSelection,
          alignHeader: ['number', 'numeric'].includes(
            selectedColumn?.type?.toLowerCase()
          )
            ? 'right'
            : 'left',
          headerComponent: {
            bDynamicHeaderComponent: true,
            componentName: 'dataHeader',
            props: {},
            columnDefsToProps: (params) => {
              const defaultSort = defaultOperations?.orderByList?.find(
                (e) => e.dimension === key
              );
              params.column.sort = defaultSort?.direction?.toLowerCase();
              return {
                displayName: params.displayName,
                params: params,
                toolTipText: params.toolTipText,
                alignHeader: ['number', 'numeric'].includes(
                  selectedColumn.type?.toLowerCase()
                )
                  ? 'right'
                  : 'left',
                enableSorting: selectedColumn.sortable
              };
            }
          }
        }
      }
    };
    columnMetaObject = {
      widget: 'dynamicCellComponent',
      dynamicCellComponentParams: {
        componentName: 'dataCell',
        paramsToProps: (params) => {
          return {
            params,
            type: selectedColumn.type,
            value:
              typeof params.value === 'string' && selectedColumn.parser
                ? utils[selectedColumn.parser](params.value)
                : params.value,
            unit: selectedColumn.unit
          };
        }
      },
      width: selectedColumn.type?.toLowerCase() === 'string' ? 180 : 120
    };
    if (tableConfig) {
      if (tableConfig.ui_component) {
        columnMetaObject = {
          widget: 'dynamicCellComponent',
          dynamicCellComponentParams: {
            componentName: tableConfig.ui_component,
            paramsToProps: (params) => {
              return mapPropsToValues(
                mapMetricNameToKey,
                tableConfig.props,
                params,
                tableConfig.parser
              );
            },
            eventMap: tableConfig.eventMap || {}
          },
          isDownloadable: true,
          width: key === 'sku' || key === 'asin' ? 250 : 150
        };
      }
      if (tableConfig.eventMap) {
        columnMetaObject.dynamicCellComponentParams.eventMap =
          tableConfig.eventMap;
      }
      // ability to pass custom param to prop mapping for needed usecases.
      if (tableConfig.paramsToProps) {
        columnMetaObject.dynamicCellComponentParams.paramsToProps =
          tableConfig.paramsToProps;
      }
      if (tableConfig.width) {
        columnMetaObject.width = tableConfig.width;
      }
      if (tableConfig.columnHeader) {
        columnDefinition.uiField.metadata.headerComponent.componentName =
          tableConfig.columnHeader.ui_component;
        columnDefinition.uiField.metadata.headerComponent.props =
          tableConfig.columnHeader.props;
        columnDefinition.uiField.metadata.headerComponent.columnDefsToProps = (
          params
        ) => {
          return (
            tableConfig.columnHeader.columnDefsToProps ??
            (!tableConfig.columnHeader.props
              ? null
              : mapPropsToValues(
                  mapMetricNameToKey,
                  tableConfig.columnHeader.props,
                  params
                ))
          );
        };
      }
    }
    columnDefinition.uiField.metadata = {
      ...columnDefinition.uiField.metadata,
      ...columnMetaObject
    };
    columnDefs.push(columnDefinition);
  });
  columnDefs = transformer.getColumnDefinition(columnDefs);
  return columnDefs.displayColConfigs;
};

export const getDimensionValuesAndList = (data) => {
  const dimensionValueList = [];
  Object.keys(data).forEach((dimensionName) => {
    const dimensionValues = data[dimensionName];
    if (dimensionName !== 'date_range') {
      dimensionValues?.forEach &&
        dimensionValues?.forEach((d) => {
          dimensionValueList.push({
            dimensionName: dimensionName,
            dimensionValue: d
          });
        });
    }
  });
  return dimensionValueList;
};
export const getPageWidgets = (pageId, page, globalViewId = 0) => {
  try {
    return HttpService.post(
      'DASHBOARD_SERVICE',
      { page, pageId, globalViewId },
      { append: '/page/widgets/list' }
    );
  } catch (error) {
    console.error(error);
  }
};

export const getWidgetMetadata = (widgetListItem, pageId, globalViewId = 0) => {
  const metaRequestPromises = [];
  // to be checked with veeresh
  widgetListItem.API.forEach((api) => {
    const wRequestData = api.request;
    metaRequestPromises.push(
      HttpService.post(
        api.service,
        {
          widget: wRequestData.widget,
          id: parseInt(pageId),
          page: wRequestData.page,
          globalViewId: globalViewId
        },
        { append: api.endPoint }
      )
    );
  });
  return Promise.all(metaRequestPromises).then((args) => {
    return args;
  });
};

export const createDataGroups = (metricsMetadata) => {
  const metricsApiGrouping = {};
  const metricsKeys = Object.keys(metricsMetadata);
  metricsKeys.forEach((metricKey) => {
    const group = metricsMetadata[metricKey].dataGroup;
    if (!metricsApiGrouping[group]) {
      metricsApiGrouping[group] = {};
    }
    metricsApiGrouping[group][metricKey] = metricsMetadata[metricKey];
  });
  return metricsApiGrouping;
};

export const replacePlaceHolderWithData = (
  placeHolderObjectRef,
  placeholderValueMap
) => {
  let placeHolderObject = cloneDeep(placeHolderObjectRef);
  Object.keys(placeholderValueMap).forEach((inputKey) => {
    if (inputKey.includes(':')) {
      if (typeof placeholderValueMap[inputKey] !== 'object') {
        placeHolderObject = JSON.parse(
          JSON.stringify(placeHolderObject).replaceAll(
            inputKey,
            placeholderValueMap[inputKey]
          )
        );
      } else {
        const jsonStringOfValue = JSON.stringify(placeholderValueMap[inputKey]);
        const finalStringObject = JSON.stringify(placeHolderObject).replaceAll(
          `"${inputKey}"`,
          jsonStringOfValue
        );
        placeHolderObject = JSON.parse(finalStringObject);
      }
    }
  });
  return placeHolderObject;
};

export const fetchDataAPI = (request, api) => {
  return HttpService.post(api.service, request, { append: api.endPoint }).then(
    (res) => {
      return res.data;
    }
  );
};

export const download = async (
  currentWidgetMetaData,
  requestParams,
  applyFormattingDownload = false,
  downloadFileCustomName = ''
) => {
  try {
    const api = currentWidgetMetaData.downloadApiTemplate;
    const request = replacePlaceHolderWithData(api.request, requestParams);
    // request.where.dimensionNameValueList = requestParams[':dimensionNameValueList'];
    request.operations.orderByList = requestParams[':orderByList'] || [];
    // will remove this line when this is fixed from BE
    if (!requestParams?.keepExistingDimensionNameValueList) {
      request.where.dimensionNameValueList =
        requestParams[':dimensionNameValueList'];
    } else if (!isEmpty(requestParams.replaceEntityInDimensionNameValueList)) {
      for (const item of request.where.dimensionNameValueList) {
        item.dimensionValue =
          requestParams?.replaceEntityInDimensionNameValueList[
            item.dimensionValue
          ] || item.dimensionValue;
      }
    }
    request.metricsList = requestParams[':metricsList'];

    const response = await HttpService.post(api.service, request, {
      append: api.endPoint
    });
    const { url } = response.data;

    if (url) {
      downloadLinkAsFile(url);
    } else {
      let dimensionColumn = [];
      Object.keys(currentWidgetMetaData.metrics).forEach((metric) => {
        if (
          currentWidgetMetaData.metrics[metric]?.metadata?.downloadable ||
          currentWidgetMetaData.metrics[metric]?.metadata?.visible
        ) {
          dimensionColumn.push({
            field: metric,
            title: currentWidgetMetaData.metrics[metric].label,
            priority: currentWidgetMetaData.metrics[metric].priority,
            type: currentWidgetMetaData?.metrics[metric]?.metadata?.type || ''
          });
        }
      });
      dimensionColumn.sort((item1, item2) => item1.priority - item2.priority);

      let mergedData = mergeEntityDataResult(response.data.entityData);

      if (applyFormattingDownload) {
        const formattedData = formatDownloadData(
          cloneDeep(mergedData),
          cloneDeep(dimensionColumn)
        );
        mergedData = formattedData.rows;
        dimensionColumn = formattedData.columns;
      }
      utils.performDownload(
        mergedData,
        dimensionColumn,
        downloadFileCustomName !== ''
          ? downloadFileCustomName
          : currentWidgetMetaData.label || currentWidgetMetaData.name
      );
    }
  } catch (error) {
    console.log(error);
    throw Error(error);
  }
};
const formatDownloadData = (rowData, columnsMap) => {
  columnsMap.forEach((column) => {
    // If column type is date, format the data
    if (column.type.toLowerCase() === 'date') {
      rowData.forEach((row) => {
        const formattedDate = moment(row[column.field]).format('MM/DD/YYYY');
        row[column.field] = moment(formattedDate).isValid()
          ? formattedDate
          : 'NA';
      });
    }
    // Add more formatting below this line
  });

  // Return the formatted rows and clumns
  return {
    rows: rowData,
    columns: columnsMap
  };
};
export const mergeEntityDataResult = (entityData) => {
  const data = entityData?.map((entity) => {
    let result = {};
    entity.data.forEach((e) => {
      result = { ...result, ...e.RESULT };
    });
    return result;
  });
  return data;
};
export const mapPropToData = (propDataKeyMap, data, staticProps) => {
  if (!data) {
    return staticProps;
  }
  const dynamicProps = mapValues(propDataKeyMap, (columnKey) => {
    return get(data, columnKey, columnKey);
  });
  return merge(staticProps, dynamicProps);
};
export const getFilterBarData = (filterBarConfig, filterData) => {
  const data = {};
  const filterComponents = filterBarConfig.map((filterBar) => {
    if (filterBar.isUiUpdateOnly) return filterBar;
    data[filterBar.key] = uniqBy(filterData, filterBar.key).map((e) => {
      return {
        [filterBar.selectType === 'dropdown' ? 'title' : 'label']:
          e[filterBar.key],
        value: e[filterBar.key]
      };
    });
    return { ...filterBar, ...{ options: data[filterBar.key] } };
  });
  return filterComponents;
};
export const addMetricListToRequest = (request, metricsList) => {
  let modifiedRequest = cloneDeep(request);
  modifiedRequest.metricsList = ':metricsList';
  const tempParams = {
    ':metricsList': metricsList
  };
  modifiedRequest = replacePlaceHolderWithData(modifiedRequest, tempParams);
  return modifiedRequest;
};

export const generateBundledDataPayloadSection = (metricsMap) => {
  const groupedMetricsMap = groupApis(metricsMap);
  const bundleDataGroupsRequest = mapValues(groupedMetricsMap, (groupValue) => {
    return groupValue.api.request;
  });
  return bundleDataGroupsRequest;
};
