import amazon from '@/pages/entity_details/configs/amazon/index.js';
import walmart from '@/pages/entity_details/configs/walmart/index.js';
import samsclub_api from '@/pages/entity_details/configs/samsclub_api/index.js';
import instacart from '@/pages/entity_details/configs/instacart/index.js';

export default {
  amazon,
  walmart,
  samsclub_api,
  instacart
};
