<template>
  <div>
    <div
      @mouseover="setShowDetails(false)"
      @mouseleave="setShowDetails(false)"
    >
      <donutChart :percentage="scoreDetails.overall" />
    </div>
    <div
      v-if="showDetails"
      class="u-bg-color-grey-white shadows u-border-radius-s details-container"
    >
      <donutChart :percentage="scoreDetails.title" />
      <donutChart :percentage="scoreDetails.description" />
      <donutChart :percentage="scoreDetails.image" />
    </div>
  </div>
</template>

<script>
import donutChart from '@/pages/all_retailers/dso/content-automation/donutChart.vue';
export default {
  name: 'ProductScore',
  components: {
    donutChart
  },
  props: {
    scoreDetails: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showDetails: false
    };
  },
  methods: {
    setShowDetails(value) {
      this.showDetails = value;
    }
  }
};
</script>
