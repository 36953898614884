<template>
  <section>
    <component
      :is="customState.component"
      v-if="customState && isCustomActionStateVisible"
      :parent-ref="parentRef"
      :selections="selections"
      :selected-values="selections"
      v-bind="customState.props"
      v-on="customState.events"
    >
      <div
        v-for="(item, index) in customState.slots"
        :slot="item.slot"
        :key="index"
      >
        <component
          :is="item.component"
          :parent-ref="parentRef"
          :selections="selections"
          v-bind="item.props"
          v-on="item.events"
        ></component>
      </div>
    </component>
    <div
      v-if="isActionBarShown && !isCustomActionStateVisible"
      class="actions-bar--wrapper actionspace-dummy u-position-sticky top-0 z-index-2"
    >
      <ActionsBar
        :close-fn="closeActionsWorkflow"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="passedActions"
        @open-workspace="openActionsWorkspace"
      />
    </div>
  </section>
</template>

<script>
import ActionsBar from '@/components/widgets/actionsBar.vue';
import { isEmpty, isEqual } from 'lodash';

export default {
  components: {
    ActionsBar
  },
  props: {
    actionBarActions: {
      type: Array,
      default() {
        return [];
      }
    },
    selections: {
      type: Array,
      default() {
        return [];
      }
    },
    blacklistedActionsForMultiSelections: {
      type: Array,
      default() {
        return [];
      }
    },
    parentRef: {
      type: Object,
      default() {
        return this;
      }
    },
    isCustomActionStateVisible: {
      type: Boolean,
      default: false
    },
    showPlotRowsChip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      customState: {}
    };
  },
  computed: {
    isActionBarShown() {
      return this.selections.length > 0;
    },
    passedActions() {
      return this.selections.length > 1
        ? this.actionBarActions.map((item) =>
            this.blacklistedActionsForMultiSelections.includes(item.title)
              ? { ...item, disabled: true }
              : item
          )
        : this.actionBarActions.map((item) => {
            let returnItem = item;
            if (item.actionUpdater) {
              returnItem = item.actionUpdater.call(this, item, this.selections);
            }
            return returnItem;
          });
    }
  },
  watch: {
    selections: function (newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        if (!newVal.length) {
          this.closeActionsWorkflow();
        } else {
          if (this.showPlotRowsChip) {
            this.$emit('handlePlotRows', newVal || []);
          }
        }
      }
    }
  },
  methods: {
    deselectAllInTable() {
      this.$emit('deselectSelections', []);
    },
    closeActionsWorkflow() {
      this.removeCustomCustomComponents();
      this.$emit('handlePlotRows', null);
    },
    removeCustomCustomComponents() {
      this.showCustomActionWidget(false);
      this.deselectAllInTable();
    },
    showCustomActionWidget(payload) {
      this.$emit('toggleCustomActionState', payload);
    },
    getValidSelections(selections, currentActionConfig) {
      if (currentActionConfig.getValidSelections) {
        const validSelections =
          currentActionConfig.getValidSelections(selections);
        if (validSelections.length !== selections.length) {
          this.$emit('deselectSelections', {
            selections: validSelections
          });
          this.$snackbar.open({
            message: currentActionConfig.invalidSelectionsMessage,
            duration: 6000,
            buttonColor: '#f5d908',
            actionText: ''
          });
        }
        return validSelections;
      }
      return selections;
    },
    async openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection,
      selections
    ) {
      try {
        const currentActionConfig = this.actionBarActions.find(
          (item) => item.id === actionId
        );
        const validSelections = this.getValidSelections(
          selections,
          currentActionConfig
        );
        if (isEmpty(validSelections)) {
          return;
        }
        const validSelectionsLength = validSelections.length;
        this.customState = currentActionConfig.customState;
        if (currentActionConfig.customState) {
          this.showCustomActionWidget(true);
        }
        const context = this;
        if (this.customState?.events) {
          Object.keys(this.customState.events).forEach((event) => {
            context.customState.events[event] =
              context.customState.events[event].bind(context);
          });
        }
        if (currentActionConfig.handler) {
          const response = await currentActionConfig.handler.call(
            this,
            validSelectionsLength,
            dropDownSelection,
            validSelections
          );
          if (currentActionConfig.customRenderHandler) {
            currentActionConfig.customRenderHandler(this, response);
          } else {
            if (removeSelectionState) {
              this.removeCustomCustomComponents();
            }
          }
        }
      } catch (e) {
        console.log('e', e);
      }
    },
    switchBlueBar(name) {
      this.$emit('switchBlueBar', name);
    }
  }
};
</script>
