<template>
  <section class="u-position-fixed widget--full-screen">
    <loader
      v-if="launchedCampaign.loading"
      class="fill--parent u-bg-color-grey-white"
      :loading="launchedCampaign.loading"
      :color="'#007cf6'"
    />
    <div
      v-else
      class="u-display-flex u-flex-direction-column u-flex-align-items-center"
    >
      <img
        class="launchCampaignImg"
        src="/images/launchCampaign.png"
      />
      <div
        v-if="launchedCampaign.status === 'SUCCESS'"
        class="u-display-flex u-font-size-3 u-spacing-pt-l u-flex-align-items-center"
      >
        <rb-icon
          class="u-color-green-base"
          icon="check-fill-circle"
        />
        <p class="u-spacing-ml-s">
          <span
            data-cy="launchedSuccessfully"
            class="u-font-weight-600"
            >"{{ launchedCampaign.name }}"</span
          >
          is Successfully Created.
        </p>
      </div>
      <div
        v-if="failedAdGroups.length > 0"
        class="u-display-flex u-font-size-3 u-spacing-pt-l u-flex-align-items-center"
      >
        <rb-icon
          class="u-color-red-base"
          icon="cross-fill-circle"
        />
        <p
          data-cy="failedToLaunch"
          class="u-spacing-ml-s"
        >
          Creation of {{ failedAdGroups.length }} ad groups failed. Please try
          again from Campaign Management after some time.
        </p>
        <tippy
          to="infoTooltip"
          trigger="click"
          :distance="0"
          interactive="true"
          reactive="true"
          hide-on-click="true"
          :duration="0"
          placement="top-start"
        >
          <AdGroupsStatusTable
            header-text="Failed Items"
            :column-definition="failedAdGroupsColumnDefinition"
            :data-rows="failedAdGroups"
            :pagination-per-page-limit="4"
            :show-download-button="true"
            :dimensions="{
              height: '417px',
              width: '800px'
            }"
            @downloadButtonClicked="downloadSkusFailedReasonCsv"
          />
        </tippy>
        <p
          name="infoTooltip"
          class="u-cursor-pointer u-font-size-4 u-spacing-ml-s text-underline"
          style="text-decoration-style: dotted"
        >
          View
        </p>
      </div>
      <div class="u-display-flex u-spacing-pt-l">
        <div class="u-spacing-pr-m">
          <rb-button
            text="Create another Campaign"
            data-cy="createAnotherCampaign"
            class="createBtn"
            type="hollow"
            size="l"
            :click-fn="createCampaign"
          />
        </div>
        <rb-button
          text="View in Campaign Management"
          data-cy="viewInCampaignManagement"
          type="filled"
          size="l"
          :click-fn="routeToCampaigns"
        />
      </div>

      <div
        v-if="launchedCampaign.status === 'SUCCESS'"
        class="u-position-absolute u-cursor-default u-border-radius-xs u-spacing-p-l u-border-width-s u-border-color-grey-xxx-light u-border-all iq-tip-wrapper"
      >
        <div
          style="font-size: 24px"
          class="u-font-weight-600"
        >
          <rb-icon
            class="iqIconSize u-color-blue-base u-spacing-mr-s"
            icon="light-bulb"
          /><span class="u-color-blue-base">IQ </span>Tip
        </div>
        <div
          class="u-font-size-7 u-spacing-pt-m u-color-grey-lighter u-line-height-1-3"
        >
          <span class="u-font-weight-bold">Activity Log</span> <br />
          Addition of SKUs and keywords is in progress. Please check Activity
          Log in Campaign Management in case of any failures.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import AdGroupsStatusTable from '@/components/ams/campaign_creation/walmart_steps/adGroup/TippyTableWithHeader.vue';
import FailedErrorTextTableCell from '@/components/ams/campaign_creation/walmart_steps/adGroup/FailedErrorTextTableCell.vue';
import { initialStepsDataState } from '@/components/pages/instacart/campaign-creation/store/mutations.js';
import utilityHelpers from '@/utils/helpers';
import {
  LAUNCHED_CAMPAIGN_GETTER,
  STEPS_DATA_GETTER,
  FAILED_ADGROUPS_COLUMN_DEFINITION
} from '../campaign-creation/config';

export default {
  name: 'CampaignLaunched',
  components: {
    AdGroupsStatusTable
  },
  computed: {
    stepsData() {
      return this.$store.getters[STEPS_DATA_GETTER];
    },
    launchedCampaign() {
      return this.$store.getters[LAUNCHED_CAMPAIGN_GETTER];
    },
    failedAdGroups() {
      const { adGroups = [] } = this.launchedCampaign;
      return adGroups.reduce((acc, { name, code, description }) => {
        if (code === 'FAILURE') {
          acc.push({
            name: name,
            failure_reason: description
          });
        }

        return acc;
      }, []);
    }
  },
  created() {
    Vue.component('FailedErrorTextTableCell', FailedErrorTextTableCell);
    this.failedAdGroupsColumnDefinition = FAILED_ADGROUPS_COLUMN_DEFINITION;
  },
  methods: {
    createCampaign() {
      this.$store.dispatch(
        'instacartCampaignCreation/updateStepsData',
        initialStepsDataState
      );
      this.$store.dispatch(
        'instacartCampaignCreation/updateLaunchedCampaign',
        {}
      );
      this.$router.push({ name: 'Create Campaign' });
    },
    routeToCampaigns() {
      this.$router.push({ name: 'Campaigns' });
    },
    downloadSkusFailedReasonCsv() {
      const downloadDataArray = this.failedAdGroups.map((item) => ({
        name: item?.name,
        failure_reason: item?.failure_reason
      }));
      const downloadDataColumnMaps = [
        {
          field: 'name',
          title: 'Ad Group'
        },
        {
          field: 'failure_reason',
          title: 'Failure Reason'
        }
      ];
      const { name } = this.launchedCampaign;
      const fileName = `${name}_errorfile`;
      utilityHelpers.performDownload(
        downloadDataArray,
        downloadDataColumnMaps,
        fileName
      );
    }
  }
};
</script>

<style lang="css">
.launchCampaignImg {
  display: block;
  padding-top: 4%;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  height: 60%;
}
.createBtn {
  padding: 14px 40px !important;
}
.iq-tip-wrapper {
  right: 56px;
  bottom: 56px;
  z-index: 100;
  width: 232px;
}
.iqIconSize {
  height: 24px;
  width: 24px;
}
.iqIconSize:before {
  font-size: 24px;
}
</style>
