<template>
  <!-- eslint-disable -->
  <div
    :class="{
      'u-pointer-events-none': this.disabled,
      'disable-state': this.disabled
    }"
  >
    <!-- eslint-enable -->
    <div
      v-if="mode === 'Multi'"
      class="u-display-flex u-flex-wrap-yes u-cursor-pointer"
    >
      <date-range
        :max-date="getMaxDate"
        :min-date="!enableMinDate ? undefined : getMinDate"
        :show-reset="false"
        :show-compared-to-text="showComparedToText"
        :trigger-style="triggerStyle"
        :default-range-select="defaultRangeSelect"
        :allow-compare="showComparedToText"
        :date-mode="dateMode"
        :additional-ranges="additionalRanges"
        @submit="updatedateSelection"
        @create="onDateChange"
      />
    </div>
    <div
      v-if="mode === 'Single'"
      class="u-display-flex u-flex-wrap-yes u-cursor-pointer"
    >
      <div
        ref="singleModeCalendarTrigger"
        class="u-display-flex u-flex-align-items-center"
        @click="openSingleMode"
      >
        <div
          class="local-filter--token u-display-inline-flex u-flex-align-items-center u-spacing-mr-xs"
        >
          <rb-icon
            slot="trigger"
            class="rb-icon--small u-color-grey-lighter"
            :icon="'calendar'"
            data-cy="calendarIcon"
          />
          <span
            class="u-spacing-ml-xs u-spacing-mr-s u-line-height-1-1"
            data-cy="widget_level_single_calendar_text"
            >{{ singleDateString }}</span
          >
          <rb-icon
            slot="trigger"
            class="rb-icon--xx-small u-color-grey-lighter u-cursor-pointer"
            :icon="'arrow-down'"
          />
        </div>
      </div>
      <datepicker
        ref="singleModeCalendar"
        :disabled-dates="singleSelectDisabledDates"
        :value="getMaxDate"
        @input="onSingleModeCalendarInput"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import datepicker from 'vuejs-datepicker';
import dateRange from '@/components/widgets/datePicker/DateRangePicker.vue';

export default {
  name: 'LocalDateFilter',
  components: {
    datepicker,
    dateRange
  },
  props: {
    triggerStyle: {
      type: String,
      default: 'circular'
    },
    mode: {
      type: String,
      default: 'Multi'
    },
    value: {
      type: [String, Number, Boolean, Object, Array, Symbol, Function],
      default: null
    },
    maxDate: {
      type: String,
      default: ''
    },
    context: {
      type: Array,
      default: () => {
        return [];
      }
    },
    defaultRangeSelect: {
      type: String,
      default: 'last90Days'
    },
    customDate: {
      type: Object,
      default: () => {
        return {};
      }
    },
    disabled: Boolean,
    enableMinDate: {
      type: Boolean,
      default: true
    },
    showComparedToText: {
      type: Boolean,
      default: false
    },
    dateMode: {
      type: Object,
      default: function () {
        return {
          mode: 'day'
        };
      }
    },
    additionalRanges: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      singleSelectDisabledDates: {},
      singleDateString: '',
      dateString: '',
      dateRange: ''
    };
  },
  computed: {
    getMaxDate() {
      const maxDate = this.maxDate || 'catalog';

      let date =
        this.$route.query.to_date ||
        this.$store.getters?.getMaxDate?.[maxDate]?.max_feed_date ||
        {};

      if (!this.isEmptyObj(this.customDate)) {
        date = this.customDate.defaultDate;
      }

      this.singleDateString = Vue.options.filters.printable_date(
        new Date(date)
      );

      return date;
    },
    getMinDate() {
      const minDate = this.maxDate || 'catalog';

      const minFeedDate =
        this.$store.getters?.getMaxDate?.[minDate]?.min_feed_date;

      return minFeedDate;
    },
    anchorClasses() {
      return {
        'is-disabled': this.disabled,
        'is-paddingless': this.paddingless,
        'is-active': this.value !== null && this.value === this.$parent.selected
      };
    },
    itemClasses() {
      return {
        'dropdown-item': !this.hasLink,
        'is-disabled': this.disabled,
        'is-paddingless': this.paddingless,
        'is-active':
          this.value !== null && this.value === this.$parent.selected,
        'has-link': this.hasLink
      };
    },
    /**
     * Check if item can be clickable.
     */
    isClickable() {
      return !this.separator && !this.disabled;
    }
  },
  watch: {
    dateRange: function (val) {
      this.$emit('selected-value', val, this.context);
    },
    customDate: function () {
      this.rangeSelect();
    }
  },
  created() {
    this.rangeSelect();
    if (typeof window !== 'undefined' && this.mode === 'Single') {
      document.addEventListener('click', this.clickedOutside);
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('click', this.clickedOutside);
    }
  },
  methods: {
    onDateChange(range, onCreated) {
      if (onCreated) {
        this.dateRange = range;
      }
    },
    isInWhiteList(el) {
      if (el === this.$refs.singleModeCalendar) return true;
      if (el === this.$refs.singleModeCalendarTrigger) return true;
      if (this.$refs.singleModeCalendar !== undefined) {
        const children =
          this.$refs.singleModeCalendar.$el.querySelectorAll('*');
        for (const child of children) {
          if (el === child) {
            return true;
          }
        }
      }

      if (this.$refs.singleModeCalendarTrigger !== undefined) {
        const children =
          this.$refs.singleModeCalendarTrigger.querySelectorAll('*');
        for (const child of children) {
          if (el === child) {
            return true;
          }
        }
      }

      return false;
    },
    clickedOutside(event) {
      if (!this.isInWhiteList(event.target)) {
        this.$refs?.singleModeCalendar?.close();
      }
    },
    onSingleModeCalendarInput(date) {
      if (date) {
        this.singleDateString = Vue.options.filters.printable_date(
          new Date(date)
        );
        this.$emit(
          'selected-value',
          {
            from: new Date(date),
            to: new Date(date)
          },
          this.context
        );
        this.rangeSelect();
      }
    },
    openSingleMode() {
      this.$refs.singleModeCalendar.showCalendar();
    },
    updatedateSelection(range) {
      this.dateRange = range;
    },
    rangeSelect() {
      const toDate = !this.isEmptyObj(this.customDate)
        ? new Date(this.customDate.toDate)
        : new Date(this.getMaxDate);
      const fromDate = !this.isEmptyObj(this.customDate)
        ? new Date(this.customDate.fromDate)
        : null;
      this.singleSelectDisabledDates = {
        to: fromDate,
        from: toDate
      };
    }
  }
};
</script>

<style lang="css">
.vdp-datepicker input {
  display: none;
}
.vdp-datepicker__calendar {
  right: -20px;
  top: 25px;
  font-size: 1.3rem;
  font-weight: 400;
  padding: 1.6rem;
  color: #2b333b;
  border-radius: 4px;
  border: 1px #ccc solid;
  box-shadow: 0 0 10px #ccc;
  width: 320px !important;
}
.vdp-datepicker__calendar header {
  padding: 0;
  font-size: 13px !important;
  font-weight: 600;
}
.vdp-datepicker__calendar .cell.day-header {
  font-size: 11px !important;
  font-weight: 600;
}
.vdp-datepicker__calendar .cell.selected {
  border-radius: 8px;
  color: #fff;
  background: #007cf6 !important;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover {
  border-radius: 8px;
  color: #fff;
  background: #caccce;
  border: 0 !important;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover {
  border-radius: 10px;
  border-color: #007cf6 !important;
}
.vdp-datepicker__calendar .disabled {
  color: #4b5158 !important;
  opacity: 0.3 !important;
}
.local-filter--token:hover {
  border-color: #caccce;
}
.local-filter--token:hover .rb-icon {
  color: #6a7075;
}
.disable-state {
  opacity: 0.55;
}
</style>
