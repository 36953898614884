export const EMAILID = 'EMAIL';
export const MAXIMIZE_ID = 'MAXIMIZE';
export const MINIMIZE_ID = 'MINIMIZE';
export const FAILED_ITEMS_TOGGLE_ID = 'FAILED_ITEMS_TOGGLE';
export const MANAGE_COLUMN_ID = 'MANAGE_COLUMN';

export const maximizeConfig = () => ({
  id: MAXIMIZE_ID,
  emit: 'maximize',
  icon: 'enlarge',
  subText: 'MAXIMIZE',
  loading: false,
  error: false
});

export const minimizeConfig = (context) => ({
  id: MINIMIZE_ID,
  emit: 'minimize',
  icon: 'collapse',
  subText: 'MINIMIZE',
  loading: false,
  error: false
});

export const emailConfig = () => ({
  id: EMAILID,
  emit: 'email',
  subText: 'EMAIL',
  icon: 'email-subscription',
  metadata: {
    modalConfig: 'emailRequestConfig'
  },
  loading: false,
  error: false
});

export const getManageColumnHeaderIcon = () => ({
  id: MANAGE_COLUMN_ID,
  emit: 'openManageColumnsAction',
  subText: 'MANAGE COLUMNS',
  icon: 'manage-coloumns',
  loading: false,
  error: false
});

export const skusEditStatusActionPayloadKeys = () => ({
  // keys based on the data to pick from table, alias is the key required is the action payload
  advertiser_id: {},
  campaign_id: {
    alias: 'campaignId'
  },
  campaign_type: {
    alias: 'campaignType'
  },
  sku: {
    alias: 'item_id'
  },
  ad_group_id: {},
  ad_group_content_id: {},
  status: {
    alias: 'current_status'
  }
});

export const criteoskusEditStatusActionPayloadKeys = () => ({
  // keys based on the data to pick from table, alias is the key required is the action payload
  promoted_product_status: {
    alias: 'previousStatus'
  },
  line_item_id: {
    alias: 'line_item_id'
  },
  product_id: {
    alias: 'promoted_product_id'
  }
});

export const campaignsEditStatusActionPayloadKeys = () => ({
  advertiser_id: {},
  campaign_id: {
    alias: 'campaignId'
  },
  campaign_type: {
    alias: 'campaignType'
  },
  status: {
    alias: 'current_status'
  }
});

export const lineItemsEditStatusActionPayloadKeys = () => ({
  line_item_id: {},
  status: {}
});

export const keywordsEditStatusActionPayloadKey = () => ({
  advertiser_id: {},
  campaign_id: {
    alias: 'campaignId'
  },
  campaign_type: {
    alias: 'campaignType'
  },
  keyword_id: {
    alias: 'keywordId'
  },
  keyword_text: {},
  status: {
    alias: 'current_state'
  }
});

export const baseChartConfig = () => ({
  chartOptions: {
    legend: false,
    type: 'line',
    xFormat: '%Y-%m-%d',
    timeseries: 'report_date',
    tooltip_format: {},
    axes: {},
    show_axis_colors: true,
    axis_format: {
      y: {},
      y2: {}
    },
    xDataKey: 'x',
    grid: 'xy'
  }
});

export const additionalDateRanges = () => {
  return {
    add: ['lastMonth', 'lastWeek'],
    order: {
      lastWeek: 3,
      lastMonth: 3
    }
  };
};

export const graphsPlottedTextConfig = (length) => ({
  chipIcons: [
    {
      icon: 'cross',
      emit: 'removePlottedEntity'
    }
  ],
  textArray: [
    {
      text: 'Graphs plotted for',
      class: {
        color: ''
      }
    },
    {
      text: length
    },
    {
      text: 'rows'
    }
  ]
});

export const gridOptions = () => ({
  suppressColumnVirtualisation: false,
  defaultColDef: {
    resizable: false
  },
  domLayout: 'normal'
});

export const failedItemsToggleIconConfig = (
  subText = 'VIEW FAILED CAMPAIGNS',
  icon = 'campaign'
) => ({
  id: FAILED_ITEMS_TOGGLE_ID,
  emit: 'failedItemsToggle',
  subText: subText,
  icon: icon,
  loading: false,
  error: false
});

export const commonChartActionIcons = () => [minimizeConfig()];

export const commonTableActionIcons = () => [emailConfig()];

export const levelName = 'filteredCampaignsSubgroupData';
