import { SelectAllSelector } from './select-all-selector';
import { constructIdentifier, Selections, Selector, UIState } from './selector';

export abstract class SelectorGroup<SelectorType extends Selector> {
  abstract selectAllSelector: SelectAllSelector | null;
  abstract multi: boolean;
  public selectors: { [key: string]: SelectorType } = {};
  // eslint-disable-next-line no-useless-constructor
  constructor(public parent: Selector | null) {}

  get selections() {
    let selections: { [key: string]: boolean | null } = {};
    for (const selector of Object.values(this.selectors)) {
      selections = {
        ...selections,
        ...selector.selections
      };
    }
    return selections;
  }

  get selectorsArray() {
    return Object.values(this.selectors);
  }

  addSelector(child: Selector) {
    this.selectors[child.id] = child as SelectorType;
  }

  applySelections(selection: Selections) {
    console.debug('Selection being applied', selection);
    for (let selector of this.selectorsArray) {
      if (selector.childrenGroup === null) {
        const selectionVal =
          selection[constructIdentifier(selector.group.parent, selector)];
        if (selectionVal) {
          selector.select(true);
        } else if (selectionVal === false) {
          selector.deselect();
        }
      } else {
        selector?.childrenGroup.applySelections(selection);
      }
    }
  }

  selectAll(): void {
    this.selectorsArray.forEach((selector) => {
      selector.select(true);
    });
  }

  deselectAll(): void {
    this.selectorsArray.forEach((selector) => {
      selector.deselect();
    });
  }

  uiState(): UIState[] {
    const data: UIState[] = [];
    for (let key of this.selectorsArray) {
      data.push({
        icon: key.icon,
        title: key.id,
        text: key.id,
        children: this.selectors[key.id].childrenGroup?.uiState(),
        iconColorClass: key.iconColor,
        disabled: key.disabled,
        selected: key.isSelected
      });
    }
    return data;
  }
}
