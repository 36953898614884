<template>
  <div class="fallback-ui">
    <div
      class="fallback-wrapper u-spacing-mb-0 u-spacing-pt-0 u-spacing-mt-0 u-bg-color-grey-white sticky u-header-box-shadow negative_margin_class"
      style="z-index: 12"
    >
      <div
        class="u-display-flex u-flex-justify-content-space-between title-bar u-spacing-pb-m"
      >
        <div class="u-display-flex u-flex-align-items-center">
          <PageTitle :title="title" />
          <span
            ><rb-icon
              class="u-spacing-ml-s u-color-base-lighter"
              icon="lock"
          /></span>
        </div>
        <div
          v-tippy="{ placement: 'top', arrow: false }"
          title="Remove Page Permanently"
        >
          <span
            class="icon u-flex-align-self-center u-color-grey-lighter"
            @click="onDeleteHandler"
            ><rb-icon :icon="icon"
          /></span>
        </div>
      </div>
    </div>
    <modal
      v-if="showModal"
      :modal-config="modalConfig"
      @clickedOutside="closeModal"
    >
      <div slot="body">
        <div
          class="u-display-flex u-flex-justify-content-center u-flex-align-items-center"
        >
          <slot name="modal-body" />
        </div>
      </div>
    </modal>
    <slot name="fallback-body" />
  </div>
</template>
<script>
import modal from '@/components/widgets/modal.vue';
export default {
  name: 'FallbackUI',
  components: {
    modal
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    shouldRecieveNotification: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: 'delete'
    }
  },

  data() {
    return {
      showModal: false,
      modalConfig: {
        bodyTitle: {
          text: null,
          highlightText: null,
          icon: null
        },
        body: {
          text: null
        },
        footer: {
          leftButton: {
            text: null,
            onClick: null,
            icon: null,
            show: false
          },
          rightButton: {
            text: null,
            onClick: null,
            icon: null,
            show: false,
            class: 'cancel-button'
          }
        }
      }
    };
  },
  methods: {
    onDeleteHandler() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    }
  }
};
</script>
<style lang="css" scoped>
.u-color-base-lighter {
  color: #8b8f93;
}
.icon:hover {
  cursor: pointer;
}
.fallback-wrapper {
  position: sticky;
  top: 0;
  margin-bottom: 0px !important;
}
.fallback-wrapper .icon-delete:before {
  font-size: 24px !important;
}
.fallback-wrapper .icon-lock:before {
  font-size: 24px !important;
}
</style>
