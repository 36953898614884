// import HttpService from '@/utils/services/http-service';
import Vue from 'vue';
import { eventBus } from '@/utils/services/eventBus';
import HttpService from '@/utils/services/http-service';
import {
  getObjectKey,
  asinLevelV2ConfigEnabled,
  isMarketShareLite,
  categoryConstants
} from '@/components/pages/insights/amazon/market-share/utils';

const state = {
  selections: {},
  parentSelections: {},
  filterData: null,
  topLevelMulti: false,
  allCategoryList: {}
};

const getters = {
  getSelections: (state, context) => {
    const retailer = context.getRootPath.split('/')[2];
    return state.selections[retailer] || {};
  },
  getHeirarchyFilterData: (state, context) => {
    const retailer = context.getRootPath.split('/')[2];
    return state.filterData ? state.filterData[retailer] : null;
  },
  getTopLevelMulti: (state, context) => {
    return state.topLevelMulti;
  },
  getAllCategoryList: (state, context) => {
    return state.allCategoryList;
  }
};

const setDigitalShelfData = (data, identifier) => {
  for (const i in data) {
    data[i].identifier = identifier.length === 0 ? i : identifier + '----' + i;
    if (Object.keys(data[i].children).length > 0) {
      setDigitalShelfData(data[i].children, data[i].identifier);
    }
  }
};

const addAllCombos = (selections, data, keys, index) => {
  const path = keys.split('----');
  if (path.length === index) {
    return selections;
  }
  if (selections[data[path[index]]?.identifier]) {
    delete selections[data[path[index]].identifier];
    if (index !== path.length - 1) {
      if (data[path[index]].children) {
        for (const i in data[path[index]].children) {
          selections[data[path[index]].children[i].identifier] = true;
        }
      }
    }
  }
  return addAllCombos(selections, data[path[index]].children, keys, index + 1);
};

const mutations = {
  SET_SELECTIONS: (state, data) => {
    const retailer = data.retailer;
    data = data.data;
    const stateSelections = { [retailer]: data };
    Vue.set(state, 'selections', stateSelections);
    eventBus.$emit('digitalShelfSelection', state.selections[retailer]);
  },
  resetHierarchyFilterData: (state) => {
    state.filterData = null;
    state.selections = {};
    state.parentSelections = {};
    state.filterData = null;
    state.topLevelMulti = false;
    state.allCategoryList = {};
  },
  SET_SHELF_DATA: (state, data) => {
    Vue.set(state, 'topLevelMulti', data.multi);
    const retailer = data.retailer;
    data = data.response;
    if (!state.selections[retailer]) {
      state.selections[retailer] = {};
    }
    if (!state.filterData) {
      state.filterData = {};
    }
    setDigitalShelfData(data, '');
    state.parentSelections[retailer] = data;
    const stateFilters = { ...state.filterData };
    stateFilters[retailer] = { ...data };
    Vue.set(state, 'filterData', stateFilters);
    // state.filterData[retailer] = { ...data };
  },
  SET_INITIAL_SELECTIONS: (state, data) => {
    const retailer = data.retailer;
    const stateSelections = { ...state.selections };
    stateSelections[retailer] = { ...data.data };
    Vue.set(state, 'selections', stateSelections);
    eventBus.$emit('digitalShelfSelection', state.selections[retailer]);
  },
  SET_ALL_CATEGORY_LIST: (state, data) => {
    setDigitalShelfData(data, '');
    Vue.set(state, 'allCategoryList', data);
  }
};

const actions = {
  setSelections: (context, data) => {
    const retailer = context.getters.getRootPath.split('/')[2];
    context.commit('SET_SELECTIONS', {
      data,
      retailer
    });
  },
  getFilterData: async (context, data, root) => {
    const retailer = context.getters.getRootPath.split('/')[2];
    const api = data.api;
    const method = data.method || 'get';
    const requestBody = data.body || {
      headers: {
        retailerId: retailer
      }
    };
    const requestConfig = data.requestConfig || {};
    const filterDataKey = data.filterDataKey || undefined;

    HttpService?.[method](api, requestBody, requestConfig)
      .then((response) => {
        if (response?.status === 200) {
          response = response?.data?.response || {};
          context.commit('SET_SHELF_DATA', {
            response: response?.[filterDataKey] || response || {},
            retailer,
            multi: response?.multi
          });
        }
      })
      .catch((error) => {
        console.error(error);
        context.commit('SET_SHELF_DATA', {
          response: {},
          retailer
        });
      });
  },
  getFilterDataV2: async (context, data, root) => {
    const retailer = context.getters.getRootPath.split('/')[2];
    HttpService.post(
      isMarketShareLite() ? 'MARKETSHARELITE_FILTER' : 'MARKETSHARE_FILTER',
      {
        page: isMarketShareLite()
          ? categoryConstants.mslPage
          : categoryConstants.page
      },
      {
        params: { v2: asinLevelV2ConfigEnabled() }
      }
    )
      .then((response) => {
        if (response?.status === 200) {
          response = response?.data?.response.category || {};
          let selectedCategory = data.toLowerCase();
          selectedCategory = getObjectKey(response, data);
          context.commit('SET_SHELF_DATA', {
            response: response[selectedCategory].children || {},
            retailer
          });
          context.commit('SET_ALL_CATEGORY_LIST', response);
        }
      })
      .catch((error) => {
        console.error(error);
        context.commit('SET_SHELF_DATA', {
          response: {},
          retailer
        });
      });
  },
  resetHierarchyFilterData: (context) => {
    context.commit('resetHierarchyFilterData');
  },
  setInitialSelections: (context, data) => {
    const retailer = context.getters.getRootPath.split('/')[2];
    context.commit('SET_INITIAL_SELECTIONS', {
      data,
      retailer
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
