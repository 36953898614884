<template>
  <div class="u-dispaly-flex u-width-100">
    <div
      class="progress-bar u-dispaly-flex u-width-100 u-flex-direction-column u-border-radius-s u-bg-grey-white u-spacing-p-m"
    >
      <div
        class="u-display-flex u-width-100 u-flex-justify-content-space-between u-spacing-mb-s"
      >
        <span
          class="u-display-inline-flex u-color-grey-base u-font-size-4"
          v-html="getFileName"
        />
        <span
          class="button u-display-inline-flex u-cursor-pointer u-color-blue-base u-font-size-6 u-font-weight-600 u-spacing-mr-s"
          @click="cancel"
          >Cancel</span
        >
      </div>
      <div
        class="loader"
        :class="active ? 'active' : ''"
      />
      <div class="u-display-inline-flex u-spacing-mt-xs">
        <span class="u-font-size-6 u-line-height-1 u-color-blue-base"
          >{{ loadingText }}<span
        /></span>
      </div>
    </div>
  </div>
</template>
<script>
const oObject = {
  name: 'ProgressBar',
  owner: 'Ramkumar M',
  props: {
    type: {
      type: String,
      default: 'static'
    },
    fileName: {
      type: String,
      default: undefined
    },
    loadingText: {
      type: String,
      default: 'Uploading the file…'
    },
    cancelFn: Function
  },
  data() {
    return {
      active: true
    };
  },
  computed: {
    getFileName() {
      return this.fileName;
    }
  },
  watch: {},
  methods: {
    cancel() {
      if (this.cancelFn) {
        this.cancelFn();
      }
    }
  }
};
export default oObject;
</script>
<style lang="css" scoped>
.loader {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: rgb(0, 124, 246, 0.2);
  border-radius: 4px;
}

.active:before {
  display: block;
  position: absolute;
  content: '';
  width: 100%;
  height: 8px;
  background-color: #007cf6;
  animation: loading 60s linear;
  animation-iteration-count: infinite;
}

@keyframes loading {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}
</style>

<style>
.progress-bar {
  box-shadow: 0 0 6px 0 rgba(43, 51, 59, 0.15);
}
</style>
