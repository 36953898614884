<template>
  <div class="u-display-flex u-flex-align-items-center">
    <div
      v-for="(item, index) in buttonOptions"
      :key="index"
    >
      <button
        class="u-border-radius u-border-width-s u-border-all u-spacing-ph-s u-spacing-pv-xs u-font-size-5 u-font-weight-400 u-spacing-mr-s u-spacing-mt-s u-cursor-pointer"
        :class="
          item.value === selectedMetric
            ? 'background-color u-border-color-blue-base u-color-grey-base'
            : 'u-border-color-grey-xxx-light u-bg-color-grey-white u-color-grey-mid-light'
        "
        @click.stop="setSelected(item.value)"
      >
        {{ item.label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttonOptions: {
      type: Array,
      required: true
    },
    selectedMetric: {
      type: String,
      required: true
    }
  },
  methods: {
    setSelected(tabSelected) {
      this.$emit('tabSelected', tabSelected);
    }
  }
};
</script>
<style lang="css" scoped>
.background-color {
  background-color: #d9ebfe;
}
.u-border-radius {
  border-radius: 26px;
}
</style>
