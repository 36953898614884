<template>
  <div
    class="u-width-100"
    :class="{ 'u-margin-bottom-40': defaultMarginBottom }"
  >
    <div
      class="u-font-size-3 u-font-weight-600 u-spacing-pb-s u-text-align-left"
      data-cy="ccStepHeaderTitle"
    >
      {{ (step.header && step.header.title) || '' }}
    </div>
    <div
      v-if="step.header.desc"
      class="u-font-size-5 u-color-grey-lighter u-line-height-1-5"
    >
      <p
        v-for="(desc, $index) in step.header.desc"
        :key="$index"
        data-cy="ccStepHeaderDescription"
      >
        {{ desc }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BudgetPlannerHeader',
  props: {
    defaultMarginBottom: {
      type: Boolean,
      default: true
    },
    step: {
      type: Object,
      default: () => {}
    }
  },
  methods: {}
};
</script>
