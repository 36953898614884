<template>
  <div class="dashboard-service-table-wrapper">
    <widgetContainer
      :header-options="uiHeaderOptions"
      :context="context"
      :last-updated-date="lastUpdatedDate"
      :title="(metadata || {}).label"
      :description="(metadata || {}).description"
      :is-loading="isLoaded"
      :header-container-grid-styles="headerContainerGridStyles"
      :footer-options="footerOptionsComp"
    >
      <div
        slot="body"
        class="container"
      >
        <div
          class="u-display-flex u-flex-align-items-center u-height-100 u-width-100 u-spacing-pl-m u-spacing-pr-m u-spacing-pb-m"
        >
          <topContributorCard
            v-for="(card, index) in cards"
            :key="index"
            :header-options="Icons[index]"
            :title="card.header.title"
            :rows="card.body.row"
            :card-level-title="card.cardLevelTitle"
            :is-empty="card.isEmpty"
            @download="onClickHandler($event, index)"
          />
        </div>
      </div>
    </widgetContainer>
  </div>
</template>
<script>
import Vue from 'vue';
import iconWithText from '@/components/globals/dataTable/tableComponentsWrapper/iconWithText.vue';
import { eventBus } from '@/utils/services/eventBus';
import Loader from '@/components/basic/loader.vue';
import DashboardDataService from '@/utils/common/dashboardDataService.js';
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import skuWithRating from '@//components/basic/skuWithRating.vue';
import widgetsUtil from '@/components/widgetMixin';
import statusTextWithIconAndBg from '@/components/globals/dataTable/tableComponentsWrapper/statusTextWithIconAndBg.vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import HttpService from '@/utils/services/http-service';
import {
  groupApis,
  replacePlaceHolderWithData
} from '@/utils/common/dashboard-service-utils.js';
import { isEqual, cloneDeep } from 'lodash';
import { formatter, formatSelectedButton } from '@/utils/helpers/formatter.js';
import cardWidget from '@/components/widgets/cardWidget.vue';
import TopContributorCard from './TopContributorCard.vue';
import { downloadLinkAsFile } from '@/utils/helpers/downloader.js';
import TopContributorsTab from './TopContributorsTab.vue';
export default {
  components: {
    Loader,
    widgetContainer,
    TopContributorCard
  },
  mixins: [widgetsUtil, cwMixin],
  props: {
    headerHeight: {
      type: Number,
      default: 52
    },
    tableClass: {
      type: String,
      default:
        'u-display-flex u-flex-direction-column u-height-100 dashboard-service-table'
    },
    gridOptions: {
      type: Object,
      default() {
        return {
          domLayout: 'normal'
        };
      }
    },
    rowHeight: {
      type: Number,
      default: 80
    },
    context: {
      type: Object,
      default: () => {
        return {};
      }
    },
    bypassFilters: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      PoData: [],
      FcData: [],
      SKUData: [],
      isShippedCogsComparisonEnabled:
        this.metadata.metadata.defaultConfig?.availableTabs?.['Impact']
          ?.isShippedCogsComparisonEnabled,
      isload: false,
      fc: this.metadata.metadata.defaultConfig?.entityValue?.fc,
      asin: this.metadata.metadata.defaultConfig?.entityValue?.asin,
      po: this.metadata.metadata.defaultConfig?.entityValue?.po,
      selectedButton: '',
      selectedButtonDisplayLabel: '',
      ShippedCogsMetricsName:
        this.metadata.metadata?.defaultConfig?.shippedCogsMetricName,
      downloadMetricList:
        this.metadata.metadata?.defaultConfig?.downloadMetricList,
      metricList: this.metadata.metadata?.defaultConfig?.metricList,
      asinObjectList: this.metadata.metadata?.defaultConfig?.asinObjectList,
      poObjectList: this.metadata.metadata?.defaultConfig?.poObjectList,
      Icons: [
        [
          {
            grid_column: '1fr 1fr 1fr 1fr 1fr',
            ui_component: 'icon-text',
            icon: 'share',
            isLoading: false,
            tooltipText: 'Export',
            isMetricToolTipEnabled: true,
            icon_classes: 'u-color-grey-lighter u-cursor-pointer '
          }
        ],
        [
          {
            grid_column: '1fr 1fr 1fr 1fr 1fr',
            ui_component: 'icon-text',
            icon: 'share',
            isLoading: false,
            tooltipText: 'Export',
            isMetricToolTipEnabled: true,
            icon_classes: 'u-color-grey-lighter u-cursor-pointer '
          }
        ],
        [
          {
            grid_column: '1fr 1fr 1fr 1fr 1fr',
            ui_component: 'icon-text',
            icon: 'share',
            isLoading: false,
            tooltipText: 'Export',
            isMetricToolTipEnabled: true,
            icon_classes: 'u-color-grey-lighter u-cursor-pointer '
          }
        ]
      ],
      buttonOptions: [],
      cards: [
        {
          header: {
            title: `Top ${this.metadata.metadata.defaultOperations.limit} SKUs`
          },
          body: { row: this.SKUData },
          type: this.metadata.metadata.defaultConfig.entityValue?.asin,
          isEmpty: false,
          cardLevelTitle: 'SKU Details'
        },
        {
          header: {
            title: `Top ${this.metadata.metadata.defaultOperations.limit} FCs`
          },
          body: { row: this.FcData },
          type: this.metadata.metadata.defaultConfig.entityValue.fc,
          isEmpty: false,
          cardLevelTitle: 'FC Details'
        },
        {
          header: {
            title: `Top ${this.metadata.metadata.defaultOperations.limit} POs`
          },
          body: { row: this.PoData },
          type: this.metadata.metadata.defaultConfig.entityValue.po,
          isEmpty: false,
          cardLevelTitle: 'Carrier Details'
        }
      ],
      isLoaded: true,
      operations: {
        page: 1,
        limit: 10
      },
      dataService: {},
      where: {
        dimensionNameValueList: [],
        date: {
          from: '2021-08-30',
          to: '2021-10-11'
        }
      }
    };
  },
  computed: {
    uiHeaderOptions() {
      const tempHeaderOptions = cloneDeep(this.headerOptionsComp) || [];
      const availableTabs = this.metadata.metadata.defaultConfig.availableTabs;
      const buttonList = [];
      for (const key in availableTabs) {
        if (
          availableTabs[key].displayLabel ===
          this.metadata.metadata.defaultConfig.selectedTab
        ) {
          this.isShippedCogsComparisonEnabled =
            availableTabs[key].isShippedCogsComparisonEnabled;
          buttonList.push({
            selected: true,
            label: availableTabs[key].displayLabel,
            value: key,
            isShippedCogsComparisonEnabled:
              availableTabs[key].isShippedCogsComparisonEnabled
          });
        } else {
          buttonList.push({
            selected: false,
            label: availableTabs[key].displayLabel,
            value: key,
            isShippedCogsComparisonEnabled:
              availableTabs[key].isShippedCogsComparisonEnabled
          });
        }
      }
      this.buttonOptions = buttonList;
      const obj = {
        ui_component: 'top-contributor-tab',
        props: {
          buttonOptions: this.buttonOptions,
          value: 'asin',
          order: 300
        },
        events: {
          buttonChanged: this.filterChanged
        }
      };
      tempHeaderOptions.push(obj);
      return tempHeaderOptions;
    },
    bypassMetricGroups() {
      return this?.metadata?.metadata?.selectedMetricsListUnaffected;
    }
  },
  watch: {
    widgetRequestParams(newVal, oldVal) {
      if (isEqual(newVal, oldVal)) {
        console.log('refresh skipped since widgetRequestParams are equal ');
        this.getTopContributors();
        return;
      }
      this.init();
      Vue.set(this.operations, 'page', 1);
      this.getTopContributors();
    }
  },
  created() {
    this.init();
    Vue.component('iconWithText', iconWithText);
    Vue.component('topContributorCard', TopContributorCard);
    Vue.component('skuWithRating', skuWithRating);
    Vue.component('statusTextWithIconAndBg', statusTextWithIconAndBg);
    Vue.component('card-widget', cardWidget);
    Vue.component('top-contributor-tab', TopContributorsTab);
    // to be improved/ made more configurable for data service plug and play.
    this.dataService = new DashboardDataService(
      this.metadata,
      this.widgetRequestParams[':widget'],
      this.widgetRequestParams.page,
      this.widgetRequestParams.pageId
    );
    const defaultOperations = this.metadata?.metadata?.defaultOperations;
    if (defaultOperations) {
      this.operations = { ...this.operations, ...defaultOperations };
    }
    this.hoistWidgetParamsToParent(this.operations);
    eventBus.$on(this.sortChangeEvent, (data) => {
      const operation = [
        { dimension: data.colDef.field, direction: data.sort.toUpperCase() }
      ];
      Vue.set(this.operations, 'page', 1);
      Vue.set(this.operations, 'orderByList', operation);
      this.hoistWidgetParamsToParent(this.operations);
    });

    this.getTopContributors();
  },
  methods: {
    init() {
      this.selectedButton = this.metadata.metadata?.defaultConfig?.selectedTab;
      const availableTabs = this.metadata.metadata.defaultConfig.availableTabs;
      for (const key in availableTabs) {
        if (this.selectedButton === availableTabs[key].displayLabel) {
          this.selectedButtonDisplayLabel = availableTabs[key].displayLabel;
          this.selectedButton = key;
          this.isShippedCogsComparisonEnabled =
            availableTabs[key].isShippedCogsComparisonEnabled;
        }
      }
    },
    async onClickHandler(title, cardKey) {
      this.Icons[cardKey][0].isLoading = true;
      this.Icons = cloneDeep(this.Icons);
      const entityType =
        title === `Top ${this.metadata.metadata.defaultOperations.limit} SKUs`
          ? 'asin'
          : title ===
            `Top ${this.metadata.metadata.defaultOperations.limit} POs`
          ? this.po
          : this.fc;
      const button = formatSelectedButton(this.selectedButton);
      await this.downloadTopContributorsData(
        this.widgetRequestParams,
        entityType,
        button
      );
      this.Icons[cardKey][0].isLoading = false;
      this.Icons = cloneDeep(this.Icons);
    },
    getCardData(enityType, data) {
      try {
        const res = data[0]?.filter((sku) => sku[0]?.entityType === enityType);
        if (res) return res[0];
      } catch (err) {
        console.log(err);
      }
    },
    structureData(data) {
      try {
        const tab = this.isShippedCogsComparisonEnabled
          ? formatSelectedButton(this.selectedButtonDisplayLabel)
          : formatSelectedButton(this.selectedButton);
        const unit = this.getUnit(tab);
        const updatedRes = data?.map((row) => {
          const finalValue = row.data?.find((item) => item.name === tab);
          const shipped_cogs = row.data.find(
            (item) => item.name === this.ShippedCogsMetricsName
          );
          const link = row.data?.find(
            (item) => item.name === this.poObjectList[tab]
          );
          const tooltipText = row.data.find((item) => item.name === 'impact');
          return {
            type: row.entityType,
            payload: {
              value: formatter(finalValue.RESULT[tab], unit, undefined),
              url: link?.RESULT[this.poObjectList[tab]],
              shippedCogsValue: formatter(
                shipped_cogs?.RESULT?.shipped_cogs,
                unit,
                undefined
              ),
              data: row.entityValue,
              tooltipText: `Impact Percentage ${tooltipText?.RESULT['impact']}%`
            }
          };
        });
        return updatedRes;
      } catch (err) {
        console.log(err);
      }
    },
    getUnit(tab) {
      return this.metadata.metrics[tab].metadata.unit;
    },
    getSkuData(entityType, asinData) {
      try {
        const selectedButton = formatSelectedButton(this.selectedButton);
        let finalFormattedData;
        const unit = this.isShippedCogsComparisonEnabled
          ? this.getUnit(formatSelectedButton(this.selectedButtonDisplayLabel))
          : this.getUnit(selectedButton);
        if (asinData.length) {
          asinData = this.getCardData('asin', asinData);
          const formatedData = [];
          for (let i = 0; i < asinData?.length; i++) {
            let finalObject = {};
            finalObject.entityValue = asinData[i]?.entityValue;
            for (let j = 0; j < asinData[i].data?.length; j++) {
              finalObject = { ...finalObject, ...asinData[i]?.data[j].RESULT };
            }
            formatedData.push(finalObject);
            finalFormattedData = formatedData.map((dataItem) => {
              const formattedObj = {};
              formattedObj.entityValue = dataItem.entityValue;
              formattedObj.title =
                dataItem[`${this.asinObjectList[selectedButton].title}`];
              formattedObj.imageUrl =
                dataItem[`${this.asinObjectList[selectedButton].imageUrl}`];
              formattedObj.productPageUrl =
                dataItem[
                  `${this.asinObjectList[selectedButton].productPageUrl}`
                ];
              formattedObj.asin = dataItem.asin;
              return {
                type: entityType,
                payload: {
                  data: formattedObj,
                  value: formatter(
                    dataItem[
                      formatSelectedButton(this.selectedButtonDisplayLabel)
                    ],
                    unit,
                    undefined
                  ),
                  shippedCogsValue: formatter(
                    dataItem.shipped_cogs,
                    unit,
                    undefined
                  ),
                  tooltipText: `Impact Percentage ${dataItem.impact}%`
                }
              };
            });
          }
        }
        return finalFormattedData;
      } catch (err) {
        console.log(err);
      }
    },
    hoistWidgetParamsToParent(operations) {
      const orderByList = operations?.orderByList || [];
      const widgetRequestParams = this.getCurrentWidgetRequestParams();
      widgetRequestParams[':orderByList'] = orderByList;
      this.updateWidgetRequestParams(widgetRequestParams, this.widgetName);
    },
    async getTopContributorData(
      operations,
      widgetRequestParams,
      selectedButton
    ) {
      try {
        const dimension = formatSelectedButton(selectedButton);
        this.isload = true;
        const entityTypes = [this.fc, this.po, this.asin];
        this.tableGrouping = groupApis(this.metadata.metrics);
        const grouping = Object.keys(this.tableGrouping)[0];
        const api = this.tableGrouping[grouping].api;
        const promiseArray = [];
        await Promise.all(
          entityTypes.map(async (entity) => {
            let updatedDimensionValueList = [];
            const request = replacePlaceHolderWithData(
              api.request,
              widgetRequestParams
            );
            request.operations = { ...request.operations, ...operations };
            if (this.bypassMetricGroups?.includes(dimension)) {
              updatedDimensionValueList = widgetRequestParams[
                ':dimensionNameValueList'
              ]?.filter(
                (item) => !this.bypassFilters.includes(item.dimensionName)
              );
            } else {
              updatedDimensionValueList =
                widgetRequestParams[':dimensionNameValueList'];
            }
            request.where.dimensionNameValueList = updatedDimensionValueList;
            request.enablePaginationCount = true;
            request.metricsList = this.metricList[entity][dimension];
            request.entityType = entity;
            if (!this.isShippedCogsComparisonEnabled) {
              request.operations.orderByList = [
                {
                  dimension: dimension,
                  direction: 'DESC'
                }
              ];
            } else {
              request.operations.orderByList = [
                {
                  dimension: dimension,
                  direction: 'DESC'
                },
                {
                  dimension: this.ShippedCogsMetricsName,
                  direction: 'DESC'
                }
              ];
            }
            request.dimensionsList = [];
            return await HttpService.post(api.service, request, {
              append: api.endPoint
            });
          })
        ).then((values) => {
          const entityData = values.map((value) => value.data.entityData);
          promiseArray.push(entityData);
        });
        this.isload = false;
        return promiseArray;
      } catch (error) {
        this.isload = false;
        throw Error(error);
      }
    },
    filterChanged(selectedButton) {
      if (this.selectedButton === selectedButton) return;
      this.selectedButton = selectedButton;
      this.selectedButtonDisplayLabel =
        this.metadata.metadata.defaultConfig.availableTabs[
          selectedButton
        ].displayLabel;
      this.buttonOptions.map((button) => {
        if (button.selected) button.selected = false;
        if (button.value === selectedButton) {
          button.selected = true;
          this.isShippedCogsComparisonEnabled =
            button.isShippedCogsComparisonEnabled;
        }
      });
      this.getTopContributors();
    },
    async downloadTopContributorsData(
      widgetRequestParams,
      entityType,
      selectedButton
    ) {
      const api = this.metadata.downloadApiTemplate;
      this.tableGrouping = groupApis(this.metadata.metrics);
      const request = replacePlaceHolderWithData(
        api.request,
        widgetRequestParams
      );
      if (entityType === this.fc) {
        request.metricsList = this.metricList[entityType][selectedButton];
      } else {
        request.metricsList =
          this.downloadMetricList[entityType][selectedButton];
      }
      request.entityType = entityType;
      if (!this.isShippedCogsComparisonEnabled) {
        request.operations.orderByList = [
          {
            dimension: selectedButton,
            direction: 'DESC'
          }
        ];
      } else {
        request.operations.orderByList = [
          {
            dimension: selectedButton,
            direction: 'DESC'
          },
          {
            dimension: this.ShippedCogsMetricsName,
            direction: 'DESC'
          }
        ];
      }
      let updatedDimensionValueList = [];
      const dimension = formatSelectedButton(selectedButton);
      if (this.bypassMetricGroups?.includes(dimension)) {
        updatedDimensionValueList = widgetRequestParams[
          ':dimensionNameValueList'
        ]?.filter((item) => !this.bypassFilters.includes(item.dimensionName));
      } else {
        updatedDimensionValueList =
          widgetRequestParams[':dimensionNameValueList'];
      }
      request.where.dimensionNameValueList = updatedDimensionValueList;
      request.dimensionsList = [];
      const response = await HttpService.post(api.service, request, {
        append: api.endPoint
      });
      downloadLinkAsFile(response?.data?.url);
    },
    formatCardData(entity, formattedRes) {
      const index = this.cards.findIndex((item) => item.type === entity);
      if (formattedRes.length) {
        const data = this.getCardData(entity, formattedRes);
        if (data) {
          this.cards[index].body.row = this.structureData(data);
          this.cards[index].isEmpty = false;
        } else this.cards[index].isEmpty = true;
      } else this.cards[index].isEmpty = true;
    },
    async getTopContributors() {
      try {
        this.isLoaded = true;
        const formattedRes = await this.getTopContributorData(
          this.operations,
          this.widgetRequestParams,
          this.selectedButton
        );
        const entityType = [this.fc, this.po];
        entityType.map((entity) => this.formatCardData(entity, formattedRes));
        const sku = this.cards.find((item) => item.type === 'asin');
        const skuData = this.getSkuData('asin', formattedRes);
        if (skuData) {
          sku.body.row = skuData;
          sku.isEmpty = false;
        } else {
          sku.isEmpty = true;
        }
        this.isload = false;
      } catch (error) {
        console.error(error);
        this.$snackbar.open({
          message: 'Something Went Wrong',
          duration: 6000,
          actionText: ''
        });
      }
      this.isLoaded = false;
    }
  }
};
</script>
<style lang="css">
.table-loader {
  z-index: 10;
}
.container {
  display: flex;
  flex-direction: row;
}
.dashboard-service-table-wrapper .rb-insights-table-container {
  height: 65vh;
}
.dashboard-service-table-wrapper .icon-download:hover {
  opacity: 0.7;
}
</style>
