import {
  digitalShelfBase,
  marketInsightsTabBase,
  advertisingTabBase,
  campaignManagementChildBase,
  campaignManagementTabBase,
  campaignOptimizerTab,
  reportsTabBase,
  campaignOptimizerTabBase,
  marketShareLiteTabBase,
  getCriteoRetailerRoutes,
  citrusRetailRoutes,
  getPromoteIqRetailerMenuArray
} from '@/router/primaryTabsV2ConfigUtils.js';

const retailConfig = {
  amazonretail: [
    {
      title: 'Search for SKUs',
      icon: 'search',
      path: 'search',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      hideInMenu: true,
      config: 'businessInsights',
      configCheckRequired: false,
      order: 1,
      page: 'pages'
    },
    {
      title: 'Business Overview',
      icon: 'business_overview',
      path: 'business_overview',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      redirect: '',
      config: 'businessInsights',
      configCheckRequired: true,
      order: 1,
      page: 'pages'
    },
    {
      title: 'Recommendations',
      icon: 'alerts',
      path: 'recommendations',
      redirect: '',
      config: 'recommendations',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      page: 'pages'
    },
    {
      title: 'Reports',
      icon: 'reports',
      path: 'reports',
      redirect: '',
      newSection: true,
      productRoles: ['SalesIQ', 'MarketingIQ'],
      config: 'amazonReports',
      configCheckRequired: true,
      page: 'pages'
    },
    {
      title: 'Strategy & Planning',
      icon: '',
      redirect: '',
      defaultRoute: 'strategy_and_planning/category_leaderboard',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Category Leaderboard',
          icon: 'category_landscape',
          path: 'strategy_and_planning/category_leaderboard',
          redirect: '',
          config: 'cva',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Ecommerce Command Centre',
          icon: 'category_landscape',
          path: 'strategy_and_planning/ecommerce_command_centre',
          redirect: '',
          config: 'remcc',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'insights',
          newSection: true
        }
      ]
    },
    {
      title: 'Digital Shelf',
      icon: '',
      redirect: '',
      defaultRoute: 'digital_shelf/market_insights',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Share of Voice',
          icon: 'market_insights',
          path: 'digital_shelf/share_of_voice',
          redirect: '',
          config: 'search',
          productRoles: ['SalesIQ'],
          strictProductRoleCheck: true,
          // StrictProductRoleCheck config check for strict equality for the product roles.
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Market Insights',
          icon: 'market_insights',
          path: 'digital_shelf/market_insights',
          redirect: '',
          config: 'sov_v2',
          overrideConfig: 'amazon_super_sov_v2',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Market Share',
          icon: 'market_share',
          path: 'digital_shelf/market_share',
          redirect: '',
          config: 'market_share',
          productRoles: ['SalesIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Market Share',
          icon: 'market_share',
          path: 'digital_shelf/market_share_lite',
          redirect: '',
          config: 'amazon_market_share_lite',
          productRoles: ['SalesIQ'],
          configCheckRequired: true,
          page: 'insights',
          newSection: true
        },
        {
          title: 'Content Audit',
          icon: 'content_audit',
          path: 'digital_shelf/content_audit',
          redirect: '',
          config: 'content',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Reviews & Rating',
          icon: 'star',
          path: 'digital_shelf/reviews_and_ratings',
          redirect: '',
          config: 'reviews',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Price War',
          icon: 'price_war',
          path: 'digital_shelf/price_war',
          redirect: '',
          config: 'pricewar',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Content Scorecard',
          icon: 'content_audit',
          path: 'digital_shelf/content_scorecard',
          redirect: '',
          config: 'amazonContentScorecard',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        }
      ]
    },
    {
      title: 'Supply Chain',
      icon: '',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      redirect: '',
      defaultRoute: '',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Profit Recovery Overview',
          icon: 'shortage-invoices',
          path: 'supply_chain/praoverview',
          redirect: '',
          config: 'revenue_recovery',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Deductions Overview',
          icon: 'dollar',
          path: 'supply_chain/deduction_management',
          redirect: '',
          config: 'deduction_management',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Chargeback Analytics',
          icon: 'chargeback',
          path: 'supply_chain/chargeback_analytics',
          redirect: '',
          config: 'chargeback_analytics',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Co-Op Deductions',
          icon: 'dollar',
          path: 'supply_chain/coopDeduction',
          redirect: '',
          config: 'coopDeduction',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Shortages',
          icon: 'shortage-invoices',
          path: 'supply_chain/shortages',
          redirect: '',
          config: 'shortage_invoice_report',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Shortage Invoice Report',
          icon: 'shortage-invoices',
          path: 'supply_chain/shortages_fallback_ui',
          redirect: '',
          config: 'shortage_fallback_invoice_report',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          isFallbackDashboard: true,
          configCheckRequired: true,
          localConfig: 'shortages-fallback-notification',
          page: 'insights'
        },
        {
          title: 'Price Variance',
          icon: 'price-variance',
          path: 'supply_chain/price_variance',
          redirect: '',
          config: 'price_variance_shortage_invoice_report',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Chargebacks',
          icon: 'chargeback',
          path: 'supply_chain/chargebacks',
          redirect: '',
          config: 'chargeback_claims',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Shipment Tracker',
          icon: 'shipment_tracker',
          path: 'supply_chain/shipment_tracker',
          redirect: '',
          configCheckRequired: true,
          beta: true,
          config: 'shipmenttracker',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          page: 'insights'
        },
        {
          title: 'Sales Forecast',
          icon: 'sales_forecast',
          path: 'supply_chain/sales_forecast',
          redirect: '',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          config: 'forecasting',
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'PO Fill Rate',
          icon: 'po_fill_rate',
          path: 'supply_chain/po_fill_rate',
          redirect: '',
          config: 'pofillrate',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        }
      ]
    },
    {
      title: 'Advertising',
      icon: 'topMovers',
      redirect: '',
      defaultRoute: 'campaign_optimizer',
      isCollapsed: false,
      productRoles: ['MarketingIQ'],
      configCheckRequired: true,
      children: [
        {
          title: 'AMC Insights',
          icon: 'market_insights',
          path: 'advertising/amc_insights',
          config: 'amc_insights',
          overrideConfig: 'amc_insights',
          page: 'pages',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true
        },
        {
          title: 'Campaign Optimizer',
          icon: 'campaign_optimizer',
          redirect: '',
          path: 'advertising/campaign_optimizer',
          config: 'campaign_optimizer',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages'
        },
        {
          title: 'Hourly Bidder',
          icon: 'hourly_bidder',
          path: 'advertising/hourly_bidder',
          config: 'strategies',
          page: 'pages',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true
        },
        {
          title: 'Budget Optimizer',
          icon: 'budget_optimizer',
          path: 'advertising/budget_optimizer',
          config: 'budget_planner',
          page: 'pages',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true
        },
        {
          title: 'Budget Optimizer',
          icon: 'budget_optimizer',
          path: 'advertising/budget_optimizer_v2',
          config: 'amazon_budget_planner_v2',
          page: 'pages',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true
        },
        {
          title: 'Media Planner',
          icon: 'dollar',
          path: 'advertising/media_planner',
          config: 'media_planner',
          page: 'pages',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          newSection: true
        },
        {
          title: 'Campaign Management',
          icon: 'campaign_management',
          path: 'advertising/campaign_management',
          redirect: '',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages',
          config: 'campaign_management',
          isCollapsed: true,
          children: [
            {
              title: 'Campaigns',
              path: 'advertising/campaign_management/campaigns',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'campaigns',
              page: 'pages',
              children: [
                {
                  path: 'advertising/campaign_management/campaings/:entityType/:entityId',
                  title: 'Entity Details',
                  icon: '',
                  redirect: '',
                  configCheckRequired: true,
                  page: 'pages'
                },
                {
                  path: 'advertising/campaign_management/campaigns/:entityType/:entityId/:subEntityType/:subEntityId',
                  title: 'Sub Entity Details',
                  icon: '',
                  redirect: '',
                  configCheckRequired: true,
                  page: 'pages'
                }
              ]
            },
            {
              title: 'Keywords',
              path: 'advertising/campaign_management/keywords',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'keywords',
              page: 'pages'
            },
            {
              title: 'Search Terms',
              path: 'advertising/campaign_management/search_terms',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'search_terms',
              page: 'pages'
            },
            {
              title: 'Ad SKUs',
              path: 'advertising/campaign_management/ad_skus',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'ams_skus',
              page: 'pages'
            }
          ]
        },
        {
          title: 'DSP Campaign Management',
          icon: 'campaign_management',
          path: 'advertising/dsp_campaign_management',
          redirect: '',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages',
          config: 'dsp_campaign_management',
          overrideConfig: 'dsp_campaign_management',
          isCollapsed: true,
          children: [
            {
              title: 'Orders',
              path: 'advertising/dsp_campaign_management/orders',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'dsp_orders',
              page: 'pages',
              children: [
                {
                  path: 'advertising/dsp_campaign_management/orders/:entityType/:entityId',
                  title: 'Entity Details',
                  icon: '',
                  redirect: '',
                  configCheckRequired: true,
                  page: 'pages'
                }
              ]
            },
            {
              title: 'Line Items',
              path: 'advertising/dsp_campaign_management/line_items',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'dsp_line_items',
              page: 'pages',
              children: [
                {
                  path: 'advertising/dsp_campaign_management/line_items/:entityType/:entityId',
                  title: 'Entity Details',
                  icon: '',
                  redirect: '',
                  configCheckRequired: true,
                  page: 'pages'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      title: 'Assortment',
      icon: '',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      redirect: '',
      defaultRoute: '',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'SKUs',
          icon: 'skus',
          path: 'assortment/skus',
          config: 'dw',
          page: 'pages',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true
        }
      ]
    },
    {
      title: 'Actions Log',
      icon: 'action_log',
      path: 'actions_log',
      config: 'actions_log',
      page: 'pages',
      productRoles: ['MarketingIQ'],
      configCheckRequired: true
    }
  ],
  walmartretail: [
    {
      title: 'Business Overview',
      icon: 'business_overview',
      path: 'business_overview',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      redirect: '',
      config: 'walmartBusinessInsights',
      configCheckRequired: true,
      order: 1,
      page: 'pages'
    },
    {
      title: 'Recommendations',
      icon: 'alerts',
      path: 'recommendations',
      redirect: '',
      config: 'walmart_recommendations',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      page: 'pages'
    },
    {
      title: 'Reports',
      icon: 'reports',
      path: 'reports',
      redirect: '',
      config: 'walmartReports',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      page: 'pages'
    },
    {
      title: 'Strategy & Planning',
      icon: '',
      redirect: '',
      defaultRoute: 'strategy_and_planning/category_leaderboard',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Ecommerce Command Centre',
          icon: 'category_landscape',
          path: 'strategy_and_planning/ecommerce_command_centre',
          redirect: '',
          config: 'remcc',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'insights',
          newSection: true
        }
      ]
    },
    {
      title: 'digital shelf',
      icon: '',
      redirect: '',
      defaultRoute: '/insights/share-of-voice',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Market Insights',
          icon: 'market_insights',
          path: 'digital_shelf/market_insights',
          redirect: '',
          config: 'sov_v2',
          overrideConfig: 'walmart_super_sov_v2',
          productRoles: ['SalesIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Market Share',
          icon: 'market_share',
          path: 'digital_shelf/market_share_lite',
          redirect: '',
          config: 'market_share_lite',
          productRoles: ['SalesIQ'],
          configCheckRequired: true,
          page: 'insights',
          newSection: true
        },
        {
          title: 'Inventory Availability',
          icon: 'inventory',
          path: 'digital_shelf/inventory',
          redirect: '',
          config: 'walmartInventoryAvailability',
          productRoles: ['MarketingIQ', 'SalesIQ'],
          configCheckRequired: true,
          page: 'pages'
        },
        {
          title: 'Pricing',
          icon: 'dollar',
          path: 'digital_shelf/pricing',
          redirect: '',
          config: 'walmartPricingPromotions',
          productRoles: ['MarketingIQ', 'SalesIQ'],
          configCheckRequired: true,
          page: 'pages'
        },
        {
          title: 'Seller Insights',
          icon: 'p-variant',
          path: 'digital_shelf/seller_insights',
          redirect: '',
          config: 'walmartSellerDashboard',
          configCheckRequired: true,
          page: 'pages',
          productRoles: ['MarketingIQ', 'SalesIQ']
        },
        {
          title: 'Content Scorecard',
          icon: 'content_audit',
          path: 'digital_shelf/content',
          redirect: '',
          config: 'walmartContentScorecard',
          productRoles: ['MarketingIQ', 'SalesIQ'],
          configCheckRequired: true,
          page: 'pages'
        }
      ]
    },
    {
      title: 'Advertising',
      icon: 'topMovers',
      productRoles: ['MarketingIQ'],
      redirect: '',
      defaultRoute: '/strategyList',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Campaign Optimizer',
          icon: 'campaign_optimizer',
          redirect: '',
          path: 'advertising/campaign_optimizer',
          config: 'campaign_optimizer_walmart',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages'
        },
        {
          title: 'Budget Optimizer',
          icon: 'budget_optimizer',
          path: 'advertising/budget_optimizer',
          config: 'walmart_budget_planner',
          page: 'pages',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true
        },
        {
          title: 'Hourly Bidder',
          icon: 'hourly_bidder',
          path: 'advertising/hourly_bidder',
          config: 'walmart_strategies',
          page: 'pages',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true
        },
        {
          title: 'Campaign Management',
          icon: 'campaign_management',
          path: 'advertising/campaign_management',
          redirect: '',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages',
          config: 'walmart_campaign_management',
          isCollapsed: true,
          children: [
            {
              title: 'Campaigns',
              path: 'advertising/campaign_management/campaigns',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'walmart_campaigns',
              page: 'pages'
            },
            {
              title: 'Keywords',
              path: 'advertising/campaign_management/keywords',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'walmart_keywords',
              page: 'pages'
            },
            {
              title: 'Ad SKUs',
              path: 'advertising/campaign_management/ad_skus',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'walmart_ams_skus',
              page: 'pages'
            },
            {
              title: 'Search Terms',
              path: 'advertising/campaign_management/search_terms',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'walmart_search_terms',
              page: 'pages'
            }
          ]
        }
      ]
    }
  ],
  samsclub_apiretail: [
    {
      ...reportsTabBase,
      config: 'samsclub_apiReports'
    },
    {
      ...digitalShelfBase,
      children: [
        {
          ...marketInsightsTabBase,
          overrideConfig: 'samsclub_api_super_sov_v2'
        }
      ]
    },
    {
      title: 'Advertising',
      icon: 'topMovers',
      productRoles: ['MarketingIQ'],
      redirect: '',
      defaultRoute: '/strategyList',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Campaign Optimizer',
          icon: 'campaign_optimizer',
          redirect: '',
          path: 'advertising/campaign_optimizer',
          config: 'campaign_optimizer_samsclub_api',
          productRoles: ['MarketingIQ'],
          // configCheckRequired: true,
          page: 'pages'
        },
        {
          title: 'Hourly Bidder',
          icon: 'hourly_bidder',
          path: 'advertising/hourly_bidder',
          config: 'samsclub_api_strategies',
          page: 'pages',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true
        },
        {
          title: 'Campaign Management',
          icon: 'campaign_management',
          path: 'advertising/campaign_management',
          redirect: '',
          productRoles: ['MarketingIQ'],
          // configCheckRequired: true,
          page: 'pages',
          config: 'samsclub_api_campaign_management',
          isCollapsed: true,
          children: [
            {
              title: 'Campaigns',
              path: 'advertising/campaign_management/campaigns',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'samsclub_api_campaigns',
              page: 'pages'
            },
            {
              title: 'Keywords',
              path: 'advertising/campaign_management/keywords',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'samsclub_api_keywords',
              page: 'pages'
            },
            {
              title: 'Ad SKUs',
              path: 'advertising/campaign_management/ad_skus',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'samsclub_api_ams_skus',
              page: 'pages'
            },
            {
              title: 'Search Terms',
              path: 'advertising/campaign_management/search_terms',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'samsclub_api_search_terms',
              page: 'pages'
            }
          ]
        }
      ]
    }
  ],
  amazonfresh: [
    {
      title: 'Business Overview',
      icon: 'business_overview',
      path: 'business_overview',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      redirect: '',
      config: 'freshBusinessInsights',
      configCheckRequired: true,
      order: 1,
      page: 'pages'
    },
    {
      title: 'digital shelf',
      icon: '',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Regional Availability',
          icon: 'market_insights',
          path: 'digital_shelf/regional_availability',
          redirect: '',
          config: 'regional_availability',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        }
      ]
    }
  ],
  instacartretail: [
    {
      title: 'Reports',
      icon: 'reports',
      path: 'reports',
      redirect: '',
      config: 'instacartReports',
      productRoles: ['MarketingIQ'],
      configCheckRequired: true,
      page: 'pages'
    },
    {
      title: 'Advertising',
      icon: 'topMovers',
      productRoles: ['MarketingIQ'],
      redirect: '',
      defaultRoute: '',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Campaign Optimizer',
          icon: 'campaign_optimizer',
          redirect: '',
          path: 'advertising/campaign_optimizer',
          config: 'campaign_optimizer_instacart',
          productRoles: ['MarketingIQ'],
          configCheckRequired: false,
          page: 'instacart'
        },
        {
          title: 'Hourly Bidder',
          icon: 'hourly_bidder',
          path: 'advertising/hourly_bidder',
          config: 'instacart_strategies',
          page: 'pages',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true
        },
        {
          title: 'Budget Optimizer',
          icon: 'budget_optimizer',
          path: 'advertising/budget_optimizer',
          config: 'instacart_budget_planner',
          page: 'pages',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true
        },
        {
          title: 'Campaign Management',
          icon: 'campaign_management',
          path: 'advertising/campaign_management',
          redirect: '',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages',
          config: 'campaign_management',
          isCollapsed: false,
          children: [
            {
              title: 'Campaigns',
              path: 'advertising/campaign_management/campaigns',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'campaigns',
              page: 'pages'
            },
            {
              title: 'Keywords',
              path: 'advertising/campaign_management/keywords',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'keywords',
              page: 'pages'
            },
            {
              title: 'Ad SKUs',
              path: 'advertising/campaign_management/ad_skus',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'ams_skus',
              page: 'pages'
            },
            {
              title: 'Ad Groups',
              path: 'advertising/campaign_management/ad_groups',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'ad_groups',
              page: 'pages'
            }
          ]
        }
      ]
    },
    {
      title: 'Actions Log',
      icon: 'action_log',
      path: 'actions_log',
      internalRelease: true,
      config: 'actions_log',
      page: 'instacart',
      productRoles: ['MarketingIQ'],
      configCheckRequired: false
    }
  ],
  homedepotretail: [
    {
      title: 'digital shelf',
      icon: '',
      redirect: '',
      defaultRoute: '/insights/share-of-voice',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Market Insights',
          icon: 'market_insights',
          path: 'digital_shelf/market_insights',
          redirect: '',
          config: 'sov_v2',
          overrideConfig:
            'homedepot_super_sov_v2' /* not being used, for future usecases */,
          productRoles: ['SalesIQ'],
          configCheckRequired: true,
          page: 'insights'
        }
      ]
    }
  ],
  wayfairretail: [
    {
      title: 'digital shelf',
      icon: '',
      redirect: '',
      defaultRoute: '/insights/share-of-voice',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Market Insights',
          icon: 'market_insights',
          path: 'digital_shelf/market_insights',
          redirect: '',
          config: 'sov_v2',
          overrideConfig:
            'wayfair_super_sov_v2' /* not being used, for future usecases */,
          productRoles: ['SalesIQ'],
          configCheckRequired: true,
          page: 'insights'
        }
      ]
    }
  ],
  overstockretail: [
    {
      title: 'digital shelf',
      icon: '',
      redirect: '',
      defaultRoute: '/insights/share-of-voice',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Market Insights',
          icon: 'market_insights',
          path: 'digital_shelf/market_insights',
          redirect: '',
          productRoles: ['SalesIQ'],
          config: 'sov_v2',
          overrideConfig: 'overstock_super_sov_v2',
          configCheckRequired: true,
          page: 'insights'
        }
      ]
    }
  ],
  lowesretail: [
    {
      title: 'digital shelf',
      icon: '',
      redirect: '',
      defaultRoute: '/insights/share-of-voice',
      isCollapsed: false,
      configCheckRequired: false,
      children: [
        {
          title: 'Market Insights',
          icon: 'market_insights',
          path: 'digital_shelf/market_insights',
          redirect: '',
          config: 'sov_v2',
          productRoles: ['SalesIQ'],
          configCheckRequired: true,
          page: 'insights'
        }
      ]
    }
  ],
  allretail: [
    {
      title: 'Cross Retailer Overview',
      icon: 'business_overview',
      path: 'cross-retailer-overview',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      hideInMenu: false,
      configCheckRequired: true,
      config: 'cross_retailer_overview',
      order: 1,
      page: 'pages'
    },
    {
      title: 'Omni Retailer Overview',
      icon: 'business_overview',
      path: 'cross-retailer-dashboard',
      config: 'product_demo',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      page: 'pages'
    },
    {
      title: 'Digital Shelf Optimisation',
      icon: '',
      redirect: '',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Content Automation',
          icon: 'content_audit',
          path: 'content-automation',
          redirect: '',
          config: 'product_demo',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'pages'
        },
        {
          title: 'IMAP',
          icon: 'content_audit',
          path: 'imap',
          redirect: '',
          config: 'product_demo',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'pages'
        },
        {
          title: 'Optimise Your Content',
          icon: 'content_audit',
          path: 'generate-title',
          redirect: '',
          config: 'product_demo',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true,
          page: 'pages'
        }
      ]
    }
  ],
  chewyretail: [
    {
      title: 'digital shelf',
      icon: '',
      redirect: '',
      defaultRoute: '/insights/share-of-voice',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Market Insights',
          icon: 'market_insights',
          path: 'digital_shelf/market_insights',
          redirect: '',
          config: 'sov_v2',
          overrideConfig:
            'chewy_super_sov_v2' /* not being used, for future usecases */,
          productRoles: ['SalesIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          ...marketShareLiteTabBase,
          config: 'market_share_lite'
        }
      ]
    }
  ],
  krogerretail: [
    {
      title: 'Reports',
      icon: 'reports',
      path: 'reports',
      redirect: '',
      config: 'krogerReports',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      page: 'pages'
    },
    {
      title: 'digital shelf',
      icon: '',
      redirect: '',
      defaultRoute: '/insights/share-of-voice',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Market Insights',
          icon: 'market_insights',
          path: 'digital_shelf/market_insights',
          redirect: '',
          config: 'sov_v2',
          overrideConfig:
            'kroger_super_sov_v2' /* not being used, for future usecases */,
          productRoles: ['SalesIQ'],
          configCheckRequired: true,
          page: 'insights'
        }
      ]
    },
    {
      title: 'Advertising',
      icon: 'topMovers',
      productRoles: ['MarketingIQ'],
      redirect: '',
      defaultRoute: '/advertising',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          ...campaignOptimizerTabBase,
          config: 'campaign_optimizer_kroger',
          configCheckRequired: false
        },
        {
          title: 'Campaign Management',
          icon: 'campaign_management',
          path: 'advertising/campaign_management',
          redirect: '',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages',
          config: 'campaign_management',
          isCollapsed: true,
          children: [
            {
              title: 'Campaigns',
              path: 'advertising/campaign_management/campaigns',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'kroger_campaigns',
              page: 'pages'
            },
            {
              title: 'Ad Groups',
              path: 'advertising/campaign_management/ad_groups',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'kroger_ad_groups',
              page: 'pages'
            },
            {
              title: 'Ad SKUs',
              path: 'advertising/campaign_management/ad_skus',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'kroger_ad_skus',
              page: 'pages'
            }
          ]
        }
      ]
    }
  ],
  albertsons_citrusretail: [
    {
      title: 'Reports',
      icon: 'reports',
      path: 'reports',
      redirect: '',
      config: 'albertsons_citrusReports',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      page: 'pages'
    },
    {
      title: 'digital shelf',
      icon: '',
      redirect: '',
      defaultRoute: '/insights/share-of-voice',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Market Insights',
          icon: 'market_insights',
          path: 'digital_shelf/market_insights',
          redirect: '',
          config: 'sov_v2',
          overrideConfig: 'albertsons_citrus_super_sov_v2',
          productRoles: ['SalesIQ'],
          configCheckRequired: true,
          page: 'insights'
        }
      ]
    },
    {
      title: 'Advertising',
      icon: 'topMovers',
      productRoles: ['MarketingIQ'],
      redirect: '',
      defaultRoute: '/advertising',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Campaign Optimizer',
          icon: 'campaign_optimizer',
          redirect: '',
          path: 'advertising/campaign_optimizer',
          config: 'campaign_optimizer_albertsons_citrus',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages'
        },
        {
          title: 'Campaign Management',
          icon: 'campaign_management',
          path: 'advertising/campaign_management',
          redirect: '',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages',
          config: 'campaign_management',
          isCollapsed: true,
          children: [
            {
              title: 'Campaigns',
              path: 'advertising/campaign_management/campaigns',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'albertsons_citrus_campaigns',
              page: 'pages'
            },
            {
              title: 'Ad SKUs',
              path: 'advertising/campaign_management/ad_skus',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'albertsons_citrus_ad_skus',
              page: 'pages'
            },
            {
              title: 'Keywords',
              path: 'advertising/campaign_management/keywords',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'albertsons_citrus_keywords',
              page: 'pages'
            }
          ]
        }
      ]
    }
  ],
  shipt_citrusretail: [
    {
      title: 'Reports',
      icon: 'reports',
      path: 'reports',
      redirect: '',
      config: 'shipt_citrusReports',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      page: 'pages'
    },
    {
      title: 'Advertising',
      icon: 'topMovers',
      productRoles: ['MarketingIQ'],
      redirect: '',
      defaultRoute: '/advertising',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Campaign Optimizer',
          icon: 'campaign_optimizer',
          redirect: '',
          path: 'advertising/campaign_optimizer',
          config: 'campaign_optimizer_shipt_citrus',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages'
        },
        {
          title: 'Campaign Management',
          icon: 'campaign_management',
          path: 'advertising/campaign_management',
          redirect: '',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages',
          config: 'campaign_management',
          isCollapsed: true,
          children: [
            {
              title: 'Campaigns',
              path: 'advertising/campaign_management/campaigns',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'shipt_citrus_campaigns',
              page: 'pages'
            },
            {
              title: 'Ad SKUs',
              path: 'advertising/campaign_management/ad_skus',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'shipt_citrus_ad_skus',
              page: 'pages'
            },
            {
              title: 'Keywords',
              path: 'advertising/campaign_management/keywords',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'shipt_citrus_keywords',
              page: 'pages'
            }
          ]
        }
      ]
    }
  ],
  hyvee_citrusretail: [
    {
      title: 'Reports',
      icon: 'reports',
      path: 'reports',
      redirect: '',
      config: 'hyvee_citrusReports',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      page: 'pages'
    },
    {
      ...digitalShelfBase,
      children: [
        {
          ...marketInsightsTabBase,
          overrideConfig: 'hyvee_citrus_super_sov_v2'
        }
      ]
    },
    {
      title: 'Advertising',
      icon: 'topMovers',
      productRoles: ['MarketingIQ'],
      redirect: '',
      defaultRoute: '/advertising',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Campaign Optimizer',
          icon: 'campaign_optimizer',
          redirect: '',
          path: 'advertising/campaign_optimizer',
          config: 'campaign_optimizer_hyvee_citrus',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages'
        },
        {
          title: 'Campaign Management',
          icon: 'campaign_management',
          path: 'advertising/campaign_management',
          redirect: '',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages',
          config: 'campaign_management',
          isCollapsed: true,
          children: [
            {
              title: 'Campaigns',
              path: 'advertising/campaign_management/campaigns',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'hyvee_citrus_campaigns',
              page: 'pages'
            },
            {
              title: 'Ad SKUs',
              path: 'advertising/campaign_management/ad_skus',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'hyvee_citrus_ad_skus',
              page: 'pages'
            },
            {
              title: 'Keywords',
              path: 'advertising/campaign_management/keywords',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'hyvee_citrus_keywords',
              page: 'pages'
            }
          ]
        }
      ]
    }
  ],
  shoprite_citrusretail: [
    {
      title: 'Reports',
      icon: 'reports',
      path: 'reports',
      redirect: '',
      config: 'shoprite_citrusReports',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      page: 'pages'
    },
    {
      ...digitalShelfBase,
      children: [
        {
          ...marketInsightsTabBase,
          overrideConfig: 'shoprite_citrus_super_sov_v2'
        }
      ]
    },
    {
      title: 'Advertising',
      icon: 'topMovers',
      productRoles: ['MarketingIQ'],
      redirect: '',
      defaultRoute: '/advertising',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Campaign Optimizer',
          icon: 'campaign_optimizer',
          redirect: '',
          path: 'advertising/campaign_optimizer',
          config: 'campaign_optimizer_shoprite_citrus',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages'
        },
        {
          title: 'Campaign Management',
          icon: 'campaign_management',
          path: 'advertising/campaign_management',
          redirect: '',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages',
          config: 'campaign_management',
          isCollapsed: true,
          children: [
            {
              title: 'Campaigns',
              path: 'advertising/campaign_management/campaigns',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'shoprite_citrus_campaigns',
              page: 'pages'
            },
            {
              title: 'Ad SKUs',
              path: 'advertising/campaign_management/ad_skus',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'shoprite_citrus_ad_skus',
              page: 'pages'
            },
            {
              title: 'Keywords',
              path: 'advertising/campaign_management/keywords',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'shoprite_citrus_keywords',
              page: 'pages'
            }
          ]
        }
      ]
    }
  ],
  target_citrusretail: [
    {
      title: 'Reports',
      icon: 'reports',
      path: 'reports',
      redirect: '',
      config: 'target_citrusReports',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      configCheckRequired: true,
      page: 'pages'
    },
    {
      ...digitalShelfBase,
      children: [
        {
          ...marketInsightsTabBase,
          overrideConfig: 'target_citrus_super_sov_v2'
        },
        {
          ...marketShareLiteTabBase,
          config: 'market_share_lite'
        }
      ]
    },
    {
      title: 'Advertising',
      icon: 'topMovers',
      productRoles: ['MarketingIQ'],
      redirect: '',
      defaultRoute: '/advertising',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Campaign Optimizer',
          icon: 'campaign_optimizer',
          redirect: '',
          path: 'advertising/campaign_optimizer',
          config: 'campaign_optimizer_target_citrus',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages'
        },
        {
          title: 'Campaign Management',
          icon: 'campaign_management',
          path: 'advertising/campaign_management',
          redirect: '',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages',
          config: 'campaign_management',
          isCollapsed: true,
          children: [
            {
              title: 'Campaigns',
              path: 'advertising/campaign_management/campaigns',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'target_citrus_campaigns',
              page: 'pages'
            },
            {
              title: 'Ad SKUs',
              path: 'advertising/campaign_management/ad_skus',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'target_citrus_ad_skus',
              page: 'pages'
            },
            {
              title: 'Keywords',
              path: 'advertising/campaign_management/keywords',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'target_citrus_keywords',
              page: 'pages'
            }
          ]
        }
      ]
    }
  ],
  kroger_promoteiqretail: getPromoteIqRetailerMenuArray('kroger_promoteiq'),
  chewy_promoteiqretail: getPromoteIqRetailerMenuArray('chewy_promoteiq'),
  ahold_citrusretail: [
    {
      ...reportsTabBase,
      config: 'ahold_citrusReports'
    },
    {
      ...digitalShelfBase,
      children: [
        {
          ...marketInsightsTabBase,
          overrideConfig: 'ahold_citrus_super_sov_v2'
        }
      ]
    },
    {
      ...advertisingTabBase,
      children: [
        {
          ...campaignOptimizerTab,
          config: 'campaign_optimizer_ahold_citrus'
        },
        {
          ...campaignManagementTabBase,
          children: [
            {
              ...campaignManagementChildBase,
              title: 'Campaigns',
              path: 'advertising/campaign_management/campaigns',
              config: 'ahold_citrus_campaigns'
            },
            {
              ...campaignManagementChildBase,
              title: 'Ad SKUs',
              path: 'advertising/campaign_management/ad_skus',
              config: 'ahold_citrus_ad_skus'
            },
            {
              ...campaignManagementChildBase,
              title: 'Keywords',
              path: 'advertising/campaign_management/keywords',
              config: 'ahold_citrus_keywords'
            }
          ]
        }
      ]
    }
  ],
  bestbuyretail: [
    {
      title: 'digital shelf',
      icon: '',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          ...marketShareLiteTabBase,
          config: 'market_share_lite'
        },
        {
          ...marketInsightsTabBase,
          overrideConfig: 'bestbuy_super_sov_v2'
        }
      ]
    }
  ],
  amazon_3pretail: [
    {
      title: 'Business Overview',
      icon: 'business_overview',
      path: 'business_overview',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      redirect: '',
      config: 'amazon3PBusinessInsights',
      configCheckRequired: true,
      order: 1,
      page: 'pages'
    },
    {
      title: 'Reports',
      icon: 'reports',
      path: 'reports',
      redirect: '',
      newSection: true,
      productRoles: ['SalesIQ', 'MarketingIQ'],
      config: 'amazon3PReports',
      configCheckRequired: true,
      page: 'pages'
    },
    {
      title: 'Digital Shelf',
      icon: '',
      redirect: '',
      defaultRoute: 'digital_shelf/market_insights',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Market Insights',
          icon: 'market_insights',
          path: 'digital_shelf/market_insights',
          redirect: '',
          config: 'amazon3PSov_v2',
          overrideConfig: 'amazon_super_sov_v2',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Market Share',
          icon: 'market_share',
          path: 'digital_shelf/market_share',
          redirect: '',
          config: 'amazon3PMarket_share',
          productRoles: ['SalesIQ'],
          configCheckRequired: true,
          page: 'insights'
        }
      ]
    },
    {
      title: 'Advertising',
      icon: 'topMovers',
      redirect: '',
      defaultRoute: 'campaign_optimizer',
      isCollapsed: false,
      productRoles: ['MarketingIQ'],
      configCheckRequired: true,
      children: [
        {
          title: 'Campaign Optimizer',
          icon: 'campaign_optimizer',
          redirect: '',
          path: 'advertising/campaign_optimizer',
          config: 'campaign_optimizer_amazon_3p',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages'
        },
        {
          title: 'Hourly Bidder',
          icon: 'hourly_bidder',
          path: 'advertising/hourly_bidder',
          config: 'amazon_3p_strategies',
          page: 'pages',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true
        },
        {
          title: 'Media Planner',
          icon: 'dollar',
          path: 'advertising/media_planner',
          config: 'media_planner_amazon_3p',
          page: 'pages',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          newSection: true
        },
        {
          title: 'Campaign Management',
          icon: 'campaign_management',
          path: 'advertising/campaign_management',
          redirect: '',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'pages',
          config: 'amazon_3p_campaign_management',
          isCollapsed: true,
          children: [
            {
              title: 'Campaigns',
              path: 'advertising/campaign_management/campaigns',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'amazon_3p_campaigns',
              page: 'pages',
              children: [
                {
                  path: 'advertising/campaign_management/campaings/:entityType/:entityId',
                  title: 'Entity Details',
                  icon: '',
                  redirect: '',
                  configCheckRequired: true,
                  page: 'pages'
                },
                {
                  path: 'advertising/campaign_management/campaigns/:entityType/:entityId/:subEntityType/:subEntityId',
                  title: 'Sub Entity Details',
                  icon: '',
                  redirect: '',
                  configCheckRequired: true,
                  page: 'pages'
                }
              ]
            },
            {
              title: 'Keywords',
              path: 'advertising/campaign_management/keywords',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'amazon_3p_keywords',
              page: 'pages'
            },
            {
              title: 'Search Terms',
              path: 'advertising/campaign_management/search_terms',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'amazon_3p_search_terms',
              page: 'pages'
            },
            {
              title: 'Ad SKUs',
              path: 'advertising/campaign_management/ad_skus',
              icon: '',
              redirect: '',
              productRoles: ['MarketingIQ'],
              configCheckRequired: true,
              config: 'amazon_3p_ams_skus',
              page: 'pages'
            }
          ]
        }
      ]
    },
    {
      title: 'Assortment',
      icon: '',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      redirect: '',
      defaultRoute: '',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'SKUs',
          icon: 'skus',
          path: 'assortment/skus',
          config: 'amazon3Pdw',
          page: 'pages',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true
        }
      ]
    },
    {
      title: 'Actions Log',
      icon: 'action_log',
      path: 'actions_log',
      config: 'actions_log',
      page: 'pages',
      productRoles: ['MarketingIQ'],
      configCheckRequired: true
    }
  ],
  amazon_b2bretail: [
    {
      title: 'Business Overview',
      icon: 'business_overview',
      path: 'business_overview',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      redirect: '',
      config: 'amazonB2BBusinessInsights',
      configCheckRequired: true,
      order: 1,
      page: 'pages'
    },
    {
      title: 'Assortment',
      icon: '',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      redirect: '',
      defaultRoute: '',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'SKUs',
          icon: 'skus',
          path: 'assortment/skus',
          config: 'amazonB2Bdw',
          page: 'pages',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true
        }
      ]
    }
  ],
  amazon_hybridretail: [
    {
      title: 'Business Overview',
      icon: 'business_overview',
      path: 'business_overview',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      redirect: '',
      config: 'amazonB2BBusinessInsights',
      configCheckRequired: true,
      order: 1,
      page: 'pages'
    },
    {
      title: 'Listings Health',
      icon: 'listing-health',
      path: 'listings_health',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      redirect: '',
      config: 'listingsHealth',
      configCheckRequired: false,
      page: 'pages'
    },
    {
      title: 'Digital Shelf',
      icon: '',
      redirect: '',
      defaultRoute: 'digital_shelf/market_insights',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'Market Insights',
          icon: 'market_insights',
          path: 'digital_shelf/market_insights',
          redirect: '/us/amazon/retail/',
          config: 'amazonHybridSov_v2',
          overrideConfig: 'amazon_super_sov_v2',
          productRoles: ['MarketingIQ'],
          configCheckRequired: true,
          page: 'insights'
        },
        {
          title: 'Market Share',
          icon: 'market_share',
          path: 'digital_shelf/market_share',
          redirect: '/us/amazon/retail/',
          config: 'amazonHybridMarket_share',
          productRoles: ['SalesIQ'],
          configCheckRequired: true,
          page: 'insights'
        }
      ]
    },
    {
      title: 'Assortment',
      icon: '',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      redirect: '',
      defaultRoute: '',
      isCollapsed: false,
      configCheckRequired: true,
      children: [
        {
          title: 'SKUs',
          icon: 'skus',
          path: 'assortment/skus',
          config: 'amazonHybridDw',
          page: 'pages',
          redirect: '/us/amazon_3p/retail/',
          productRoles: ['SalesIQ', 'MarketingIQ'],
          configCheckRequired: true
        }
      ]
    }
  ],

  sainsburys_citrusretail: citrusRetailRoutes('sainsburys'),
  waitrose_citrusretail: citrusRetailRoutes('waitrose'),
  tesco_citrusretail: citrusRetailRoutes('tesco'),
  gopuff_citrusretail: citrusRetailRoutes('gopuff'),

  targetretail: getCriteoRetailerRoutes('target'),
  samsclubretail: getCriteoRetailerRoutes('samsclub'),
  meijerretail: getCriteoRetailerRoutes('meijer'),
  costcoretail: getCriteoRetailerRoutes('costco'),
  freshdirect_criteoretail: getCriteoRetailerRoutes('freshdirect_criteo'),
  walmart_criteoretail: getCriteoRetailerRoutes('walmart_criteo'),
  shipt_criteoretail: getCriteoRetailerRoutes('shipt_criteo'),
  walgreen_criteoretail: getCriteoRetailerRoutes('walgreen_criteo'),
  cvs_criteoretail: getCriteoRetailerRoutes('cvs_criteo'),
  asda_criteoretail: getCriteoRetailerRoutes('asda_criteo'),
  ulta_criteoretail: getCriteoRetailerRoutes('ulta_criteo'),
  albertsons_criteoretail: getCriteoRetailerRoutes('albertsons_criteo'),
  ubereats_criteoretail: getCriteoRetailerRoutes('ubereats_criteo'),
  target_rmsretail: getCriteoRetailerRoutes('target_rms')
};

const retailersMenuConfig = {
  sainsburys: {
    retailerConfig: 'feature.sainsburys_citrus',
    internalUserConfig: 'feature.is_sainsburys_citrus_for_internal_users',
    id: 'Sainsburys',
    key: 'sainsburys_citrusretail'
  },
  waitrose: {
    retailerConfig: 'feature.waitrose_citrus',
    internalUserConfig: 'feature.is_waitrose_citrus_for_internal_users',
    id: 'Waitrose',
    key: 'waitrose_citrusretail'
  },
  tesco: {
    retailerConfig: 'feature.tesco_citrus',
    internalUserConfig: 'feature.is_tesco_citrus_for_internal_users',
    id: 'Tesco',
    key: 'tesco_citrusretail'
  },
  gopuff: {
    retailerConfig: 'feature.gopuff_citrus',
    internalUserConfig: 'feature.is_gopuff_citrus_for_internal_users',
    id: 'Gopuff',
    key: 'gopuff_citrusretail'
  },
  walgreen: {
    retailerConfig: 'feature.walgreen_criteo',
    internalUserConfig: 'feature.is_walgreen_criteo_for_internal_users',
    id: 'Walgreen',
    key: `walgreen_criteoretail`
  },
  cvs: {
    retailerConfig: 'feature.cvs_criteo',
    internalUserConfig: 'feature.is_cvs_criteo_for_internal_users',
    id: 'CVS',
    key: `cvs_criteoretail`
  },
  asda: {
    retailerConfig: 'feature.asda_criteo',
    internalUserConfig: 'feature.is_asda_criteo_for_internal_users',
    id: 'ASDA',
    key: `asda_criteoretail`
  },
  ulta: {
    retailerConfig: 'feature.ulta_criteo',
    internalUserConfig: 'feature.is_ulta_criteo_for_internal_users',
    id: 'Ulta',
    key: 'ulta_criteoretail'
  },
  albertsons: {
    retailerConfig: 'feature.albertsons_criteo',
    internalUserConfig: 'feature.is_albertsons_criteo_for_internal_users',
    id: 'Albertsons (Criteo)',
    key: 'albertsons_criteoretail'
  },
  target_rms: {
    retailerConfig: 'feature.target_rms',
    internalUserConfig: 'feature.is_target_rms_for_internal_users',
    id: 'Target RMS',
    key: 'target_rmsretail'
  },
  costco: {
    retailerConfig: 'feature.costco',
    internalUserConfig: 'feature.is_costco_for_internal_users',
    id: 'Costco',
    key: 'costcoretail'
  },
  freshdirect_criteo: {
    retailerConfig: 'feature.freshdirect_criteo',
    internalUserConfig: 'feature.is_freshdirect_criteo_for_internal_users',
    id: 'Fresh Direct',
    key: 'freshdirect_criteoretail'
  }
};

export { retailConfig, retailersMenuConfig };
