<template>
  <div class="u-display-flex u-flex-align-items-center">
    <div
      v-if="text"
      class="u-color-grey-white u-text-case-title u-display-flex u-flex-align-items-center u-flex-justify-content-center"
      :class="[avatarFontSize, iconTextSpacing]"
      :style="{
        'background-color': getAvatarColor,
        'border-radius': '50%',
        'max-height': diameter,
        'min-height': diameter,
        'min-width': diameter,
        'max-width': diameter
      }"
    >
      {{ text[0] }}
    </div>
    <div
      v-if="hasText && text"
      v-tippy="{
        placement: tippyProps.placement,
        offset: tippyProps.offset,
        arrow: tippyProps.arrow,
        interactive: tippyProps.interactive,
        distance: tippyProps.distance
      }"
      class="u-color-grey-mid-light"
      :class="[textFontSize, extraTextClass]"
      :title="tippyProps.title"
    >
      {{ text }}
    </div>
    <div
      v-if="!text"
      class="u-color-grey-mid-light"
    >
      NA
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: null
    },
    diameter: {
      type: String,
      default: '24px'
    },
    tippyProps: {
      type: Object,
      default: () => {
        return {
          title: '',
          placement: 'bottom-end',
          offset: '10, 0',
          arrow: false,
          interactive: false,
          distance: 10
        };
      }
    },
    avatarFontSize: {
      type: String,
      default: 'u-font-size-6'
    },
    textFontSize: {
      type: String,
      default: 'u-font-size-6'
    },
    hasText: {
      type: Boolean,
      default: true
    },
    extraTextClass: {
      type: String,
      default: null
    },
    iconTextSpacing: {
      type: String,
      default: null
    }
  },
  computed: {
    getAvatarColor() {
      return '#' + this.intToRGB(this.hashCode(this.text));
    }
  },
  methods: {
    hashCode(str) {
      let hash = 0;
      if (str) {
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
      }
      return hash;
    },
    intToRGB(i) {
      const color = (i & 0x00ffffff).toString(16).toUpperCase();
      return '00000'.substring(0, 6 - color.length) + color;
    }
  }
};
</script>

<style lang="css">
.hover-on-element:hover {
  color: #007cf6;
}
</style>
