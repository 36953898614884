<template>
  <form
    data-cy="form"
    class="u-position-relative inputClassForEntityTaggingSearch"
    @submit.prevent="onSearchSubmit"
  >
    <rb-input
      :value="searchTerm"
      :placeholder="placeholder"
      :class="inputClasses"
      :rounded="false"
      data-cy="searchInputField"
      @input="handleInput"
    />
    <span
      class="u-position-absolute u-display-flex"
      style="top: 0px; left: 10px; bottom: 0; margin: auto"
      @click.stop.prevent="onSearchSubmit"
    >
      <rb-icon
        icon="search"
        class="search__input-icon rb-icon--small"
        data-cy="searchIcon"
      />
    </span>
  </form>
</template>

<script>
import { debounce } from 'lodash';
export default {
  name: 'RbSearch',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    inputClasses: {
      type: String,
      default: 'u-width-480px'
    }
  },
  data() {
    return {
      searchTerm: '',
      debounce: debounce
    };
  },
  created() {
    this.searchTerm = this.value || '';
  },
  methods: {
    handleInput(text) {
      this.searchTerm = text;
      this.$emit('input', text);
    },
    onSearchSubmit() {
      this.$emit('onSearchTrigger', this.searchTerm);
    }
  }
};
</script>

<style lang="css">
.inputClassForEntityTaggingSearch input {
  border-radius: 4px !important;
  border: none !important;
  background-color: #f5f7fa !important;
  padding-left: 3rem !important;
  width: 360px !important;
  height: 29px !important;
  font-size: 11px !important;
}
</style>
