<template>
  <card-widget
    class="u-flex-1 budget-panner-dashboard"
    :config="config.widgets['widget1']"
    :data="chartData"
  >
    <div
      slot="body"
      class="u-display-flex u-flex-direction-column u-width-100 u-spacing-ph-l u-spacing-pb-l"
    >
      <div
        class="u-spacing-pt-l u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
      >
        <div class="u-font-weight-600 u-font-size-3 u-color-grey-lighter">
          BUDGET PLAN OVERVIEW
        </div>
        <div>
          <div
            :style="'pointer-events:' + chartData.error ? 'none' : ''"
            class="u-display-flex u-width-100 u-flex-direction-row-reverse u-flex-wrap-yes"
          />
        </div>
      </div>
      <div
        class="u-spacing-pt-l u-font-weight-600 u-font-size-5 u-color-grey-lighter"
      >
        METRICS
      </div>
      <chart-with-legends
        :metrics-list="budgetMetricsList"
        :custom-chart-class="['u-spacing-mt-s']"
        :color-object="budgetUnitsColors"
        :chart-config="config.widgets['widget1'].body.chart"
        :chart-data="widget1Data"
        :default-legends="false"
        :metrics-shown="getBudgetMetricsShown"
        :default-select-metric="budgetDefaultSelectMetric"
        :metric-config="tagConfig"
        :disable-add-new-metric="true"
        :metric-data="dashboardLegendsData"
        :minimum-metric="getBudgetMetricsShown.length"
        :remove-dropdown="true"
        :selected-metric-limit="getBudgetMetricsShown.length"
        :show-padding-between="false"
        :margin-top-large="false"
        :margin-top-small="true"
        @selectedMetricList="selectedMetricList"
      >
        <!-- custom chart legends ui -->
        <div
          slot="customLegendForChart"
          slot-scope="{ val }"
        >
          <div
            class="u-font-size-6 u-spacing-pb-xs u-color-grey-lighter u-spacing-ph-m u-spacing-mt-xs"
            :class="{
              'u-spacing-pt-l': !(
                val.key === 'Planned Budget' || val.key === 'Ad Spend'
              )
            }"
          >
            <div
              v-show="val.key === 'Planned Budget' || val.key === 'Ad Spend'"
              class="u-font-size-6 u-color-grey-lighter u-spacing-pt-m u-spacing-pb-xs"
            >
              Cumulative
            </div>
            <div
              class="u-font-size-4 u-spacing-pb-m"
              style="font-color: #627178"
            >
              {{ val.key }}
            </div>
            <div>
              <metric
                v-if="val.metricData"
                :size="'l'"
                class="u-display-inline-flex"
                :class="[
                  val.key === 'Planned Budget' || val.key === 'Ad Spend'
                    ? 'u-spacing-pb-m'
                    : 'u-spacing-pb-l'
                ]"
                :config="val.metricConfig || {}"
                :data="val.metricData || {}"
              />
            </div>
          </div>
        </div>
        <div slot="post-legends">
          <div
            class="u-width-100 u-display-flex u-flex-direction-row u-flex-justify-content-space-between u-spacing-pt-s"
          >
            <!-- cumulativ difference legend -->
            <div
              class="u-display-flex"
              style="min-width: 180px"
            >
              <div
                class="custom-chart-legend u-cursor-default workbench-hover-card"
                :class="[
                  dashboardLegendsData.cumulativeDifference.spendStatus ===
                  'Underspending'
                    ? 'u-bg-color-green-base'
                    : 'u-bg-color-red-base'
                ]"
              >
                <div
                  class="u-font-size-6 u-color-grey-white u-spacing-pt-m u-spacing-ph-m"
                  style="margin-top: 6px"
                >
                  Cumulative difference
                </div>
                <div
                  class="u-font-size-4 u-color-grey-white u-spacing-ph-m u-spacing-pt-xs"
                >
                  {{ dashboardLegendsData.cumulativeDifference.spendStatus }}
                </div>
                <div
                  class="u-font-size-3 u-color-grey-white u-font-weight-600 u-spacing-mb-xs u-spacing-ph-m u-spacing-pt-m u-spacing-pb-m"
                >
                  {{ dashboardLegendsData.cumulativeDifference.difference }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          slot="space-between-legend-and-chart"
          class="u-display-flex u-flex-justify-content-flex-start"
        >
          <div class="u-spacing-mt-l u-spacing-mb-xs">
            <rb-select
              class="fs-budget-plan-dashboard-roll-up u-spacing-mr-m valueDropdown u-max-width-100"
              :send-details="true"
              :options="rollUps"
              :on-close="onRollupChange"
            >
              <div
                slot="trigger"
                class="u-display-flex u-flex-align-items-center u-spacing-p-xs u-cursor-pointer"
              >
                <rb-icon
                  class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                  icon="calendar"
                />
                <span
                  class="u-spacing-mr-xs u-flex-0 u-color-grey-lighter u-font-size-7"
                  >Roll up by:&nbsp;</span
                >
                <div
                  class="u-flex-1 u-font-size-7 u-text-overflow-ellipsis u-color-grey-light"
                >
                  {{ selectedRollUp.name }}
                </div>
                <rb-icon
                  class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                  icon="caret-down"
                />
              </div>
              <template
                slot="item"
                slot-scope="option"
              >
                <div class="u-display-flex u-flex-align-items-center">
                  {{ option.name }}
                </div>
              </template>
            </rb-select>
          </div>
          <div class="u-spacing-mt-l u-spacing-mb-xs u-max-height-100">
            <splitTabs
              tab1="Cumulative"
              tab2="Absolute"
              class="split-tabs-container"
              @tabClicked="onTabClick"
            />
          </div>
        </div>
      </chart-with-legends>
    </div>
    <div
      slot="footer"
      class="u-color-grey-x-light u-font-size-7"
    >
      <div class="u-flex-align-items-center u-display-flex">
        <div class="u-spacing-mr-xs">
          Last Updated :
          {{
            ((((config || {}).widgets || {})['widget1'] || {})['footer'] || {})[
              'getMaxDateDashboard'
            ]
          }}
        </div>
        <rb-icon
          v-tippy="tippy"
          :title="getRetailerMessage"
          class="rb-icon--small tool-tip-hover-budget-planner u-cursor-pointer"
          icon="info-circle-fill"
        />
      </div>
    </div>
  </card-widget>
</template>

<script>
import Vue from 'vue';
import metric from '@/components/basic/metric';
import widgetsConfig from './widget.js';
import widgetsUtil from '@/components/widgetMixin';
import cardWidget from '@/components/widgets/cardWidget';
import splitTabs from '@/components/widgets/splitTabs';
import { cloneDeep } from 'lodash';
import chartWithLegends from '@/components/basic/chart-with-legends.vue';
import { eventBus } from '@/utils/services/eventBus';
import moment from 'moment-timezone';
import {
  budgetMetricsShown,
  retailerMessage
} from '@/components/ams/budgetPlanner/retailerConfig';

export default {
  components: {
    chartWithLegends,
    cardWidget,
    metric,
    splitTabs
  },
  mixins: [widgetsUtil],
  data: () => ({
    // point_format is a c3 config used to determine the size of the point on a graph. We are dynamically generating it here.
    pointFormatValue: 3,
    pointFormatDefault: {
      numberOfMonthsToShowFor: 6,
      showPointValue: 3,
      hidePointValue: 0,
      dateFormat: 'YYYY-MM-DD'
    },
    tippy: {
      placement: 'right',
      arrow: false,
      distance: 8,
      popperOptions: {
        modifiers: {
          preventOverflow: {
            enabled: false
          }
        }
      }
    },
    message: 'Amazon data is typically delayed by upto 24 hours.',
    showY2: false,
    widgetsConfig: {},
    budgetType: 'Cumulative',
    config: {},
    rollUps: [
      { name: 'Days', key: 'rollup_days' },
      { name: 'Weeks', key: 'rollup_weeks' },
      { name: 'Months', key: 'rollup_months' }
    ],
    selectedRollUp: { name: 'Days', key: 'rollup_days' },
    budgetMetricsList: [
      { key: 'Planned Budget' },
      { key: 'Ad Spend' },
      { key: 'Spend / Budget' },
      { key: 'Spend / PCOGS' }
    ],
    budgetDefaultSelectMetric: [{ key: 'Planned Budget' }, { key: 'Ad Spend' }],
    budgetMetricsShown: [],
    tagConfig: {
      'Planned Budget': { tag1Unit: { pre: 'currency' } },
      'Ad Spend': { tag1Unit: { pre: 'currency' } },
      'Spend / PCOGS': { tag1Unit: { pre: '', suff: '%' } },
      'Spend / Budget': { tag1Unit: { pre: '', suff: '%' } }
    },
    budgetUnitsColors: {
      'Planned Budget': '#bd10e0',
      'Ad Spend': '#ffa800',
      'Spend / PCOGS': 'brown',
      'Spend / Budget': 'green'
    }
  }),
  computed: {
    getRetailerMessage() {
      const retailer = this.$store.getters.getRetailer;
      return retailerMessage[retailer];
    },
    getBudgetMetricsShown() {
      const reatiler = this.$store.getters.getRetailer;
      return budgetMetricsShown[reatiler];
    },
    chartData() {
      return this.$store.getters.getDashboardChartDataBP;
    },
    dashboardLegendsData() {
      const data = this.chartData.legendData || {};
      const plannedBudgetTag = data.planned_budget_sum || null;
      const adSpendTag = data.actual_spend_sum || null;
      let spendStatus = 'N/A';
      const spendVsPcogs = data.spend_vs_pcogs
        ? data.spend_vs_pcogs.toFixed(2)
        : 'NA';
      const spendVsBudget = data.spend_vs_budget
        ? data.spend_vs_budget.toFixed(2)
        : 'NA';
      const plannedBudgetMaxFeedDate =
        data.planned_budget_till_max_report_date || null;
      let difference = 'N/A';
      if (adSpendTag && plannedBudgetMaxFeedDate) {
        spendStatus =
          adSpendTag > plannedBudgetMaxFeedDate
            ? 'Overspending'
            : 'Underspending';
        difference = Vue.options.filters.num_format(
          Math.abs(adSpendTag - plannedBudgetMaxFeedDate),
          'currency'
        );
      }
      return {
        'Planned Budget': {
          tag1: plannedBudgetTag,
          tooltip: 'PD tooltip'
        },
        'Ad Spend': {
          tag1: adSpendTag,
          tooltip: 'Ad Tooltip'
        },
        'Spend / PCOGS': {
          tag1: spendVsPcogs
        },
        'Spend / Budget': {
          tag1: spendVsBudget
        },
        cumulativeDifference: {
          difference,
          spendStatus
        },
        spendVsBudget,
        spendVsPcogs
      };
    },
    widget1Data() {
      const data = this.chartData.timeSeriesData || [];
      let absolute = cloneDeep(data);
      const cumulative = cloneDeep(data);
      if (this.budgetType === 'Absolute') {
        absolute = absolute.filter((row) => {
          if (row[0] !== 'Planned Budget' || row[0] !== 'Ad Spend') {
            if (row[0] === 'Cumulative Planned Budget') {
              row[0] = 'Planned Budget';
            }
            if (row[0] === 'Cumulative Ad Spend') {
              row[0] = 'Ad Spend';
            }
            return row;
          }
        });
      }
      const colors = { 'Planned Budget': '#bd10e0', 'Ad Spend': '#ffa800' };
      const axes = {
        'Planned Budget': 'y',
        'Planned Budget_color': '#bd10e0',
        'Ad Spend': 'y',
        'Ad Spend_color': '#ffa800',
        'Spend / Budget': 'y2',
        'Spend / Budget_color': 'green',
        'Spend / PCOGS': 'y2',
        'Spend / PCOGS_color': 'brown'
      };
      const xs = {
        'Planned Budget': 'feed_date',
        'Ad Spend': 'feed_date',
        'Spend / PCOGS': 'feed_date',
        'Spend / Budget': 'feed_date'
      };
      const axisFormat = {
        y: {
          pre: 'currency',
          show: true
        },
        y2: {
          pre: '',
          suff: '%',
          show: this.showY2
        }
      };
      return {
        data: this.budgetType === 'Absolute' ? absolute : cumulative,
        colors,
        axes,
        xs,
        timeseries: 'feed_date',
        axis_format: axisFormat,
        show_axis_colors: true,
        xDataKey: 'x',
        grid: 'xy',
        axis: { y2: { show: true } }
      };
    }
  },
  created() {
    // widget config
    this.widgetsConfig = widgetsConfig;
    this.parentRef = this;
    this.config = widgetsConfig.config(this);
    this.checkDateRageForPoints();
    eventBus.$on(this.config.filters.emit, () => {
      this.checkDateRageForPoints();
    });
  },
  methods: {
    checkDateRageForPoints() {
      // this function is used to dynamically adjust the point size on the c3 line graph based on date range and rollup selection
      const dateRange =
        JSON.parse(localStorage.getItem('budgetPlanner-filters'))?.date_range ||
        {};
      const from = moment(dateRange.from, this.pointFormatDefault.dateFormat);
      const to = moment(dateRange.to, this.pointFormatDefault.dateFormat);
      const diff = to.diff(from, 'months');
      this.pointFormatValue =
        this.selectedRollUp?.key === 'rollup_days' &&
        diff > this.pointFormatDefault.numberOfMonthsToShowFor
          ? this.pointFormatDefault.hidePointValue
          : this.pointFormatDefault.showPointValue;
    },
    selectedMetricList(metricList) {
      this.showY2 = false;
      metricList.selectedMetric.forEach((item) => {
        if (!(item.key === 'Planned Budget' || item.key === 'Ad Spend')) {
          this.showY2 = true;
        }
      });
    },
    onTabClick(tab) {
      this.budgetType = tab;
    },
    openSeasonality() {
      this.$store.dispatch('resetVisitedSteps', ['uploadStep', 'previewStep']);
      this.$store.dispatch('setCreatePlanActiveSelection', 2);
      this.$router.push({ name: 'create-budget-plan' });
    },
    onRollupChange(context, rollUp) {
      if (rollUp.length > 0) {
        this.selectedRollUp = rollUp[0];
        this.$store.dispatch('setDashboardChartRollup', rollUp[0].key);
        this.$store.dispatch(
          'dashboardChartDataBP',
          this.config.widgets.widget1
        );
        this.checkDateRageForPoints();
      }
    },
    openSidePanel() {
      this.$emit('openSidePanel');
    }
  }
};
</script>

<style lang="css">
.budget-panner-dashboard .split-tabs-container .split-tabs {
  line-height: 1.3 !important;
}
.budget-panner-dashboard .split-tabs-container .split-tabs div {
  border-radius: 24px !important;
}
</style>

<style lang="css" scoped>
.valueDropdown {
  border-radius: 13.5px;
  height: 26px;
  border: solid 1px #e9eaeb;
}
.valueDropdown &:hover,
&:focus {
  color: #6a7075;
  fill: #6a7075;
  border: 1px solid #caccce;
}
</style>
