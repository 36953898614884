<template>
  <div
    class="u-font-size-5 u-display-flex u-flex-justify-content-flex-start u-flex-align-items-center"
  >
    <div
      class="dot u-spacing-mr-s"
      :style="{
        'background-color': dotColor
      }"
    />
    <div
      class="u-spacing-pr-xs u-font-weight-600"
      :class="['u-color-' + label1.color]"
    >
      {{ computedLabel1 }}
    </div>
    <div class="u-color-grey-lighter u-spacing-pr-xs">&</div>
    <div
      class="u-spacing-pr-xs u-font-weight-600"
      :class="['u-color-' + label2.color]"
    >
      {{ computedLabel2 }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label1: {
      type: Object,
      default: () => ({})
    },
    label2: {
      type: Object,
      default: () => ({})
    },
    dotColor: {
      type: String,
      default: null
    }
  },
  computed: {
    computedLabel1() {
      return this.label1.direction + ' ' + this.label1.text;
    },
    computedLabel2() {
      return this.label2.direction + ' ' + this.label2.text;
    }
  }
};
</script>

<style lang="css" scoped>
.dot {
  min-height: 14px;
  min-width: 14px;
  max-height: 14px;
  max-width: 14px;
  border-radius: 100%;
}
</style>
