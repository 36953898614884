<template>
  <div
    class="u-border-width-s u-border-left u-border-right u-border-color-grey-xxx-light u-spacing-mh-m u-height-100 u-width-100 u-display-flex u-flex-align-items-center u-flex-justify-content-center card-column-cell"
    :class="params.cardClass"
  >
    <div
      class="u-display-inline-flex u-width-100 u-height-100 u-flex-align-items-center u-cursor-default u-flex-justify-content-center"
    >
      <div
        class="u-height-100 u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center"
      >
        <div v-if="!isLoading && isHavingError">
          <span>Error</span>
        </div>
        <div
          v-else-if="isNoData && !isHavingError && !isLoading"
          v-tippy="tippy"
          :title="naToolTip"
        >
          <span :class="{ 'underline-text': naToolTip !== 'NA' }">NA</span>
        </div>
        <div
          v-else
          class="u-height-100 u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center"
        >
          <div
            v-if="isLoading && toShowMetric"
            class="u-width-100 u-height-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center"
          >
            <!-- <span>Loading...</span> -->
            <!-- <div
              class="skeleton-loader"
            >
              <skeleton-loader class="u-border-radius-s" />
            </div> -->
            <loader
              class="u-bg-color-grey-white"
              :loading="isLoading"
              :color="'#007cf6'"
            />
          </div>
          <div
            v-if="!isLoading && !isHavingError && toShowMetric && config"
            class="metric-holder"
            :class="{ centered: params.data[params.colDef.field].centered }"
            :style="{ width: params.data[params.colDef.field].metricWidth }"
          >
            <metric
              :config="config"
              :hide-zero="false"
              :size="metricClass"
              class="u-display-inline-flex u-spacing-mt-s"
              :data="getMetricData(metricData)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import metric from '@/components/basic/metric';
import loader from '@/components/basic/loader';
import { cloneDeep } from 'lodash';
// import skeletionLoader from '../../../../globals/skeletionLoader';

export default Vue.extend({
  components: {
    metric,
    loader
    // skeletionLoader
  },
  data() {
    return {
      tippy: {
        placement: 'right',
        arrow: false,
        distance: 8,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    metricClass() {
      if (this.params.metricClass !== undefined) {
        return this.params.metricClass;
      } else {
        return 'xs';
      }
    },
    naToolTip() {
      const groupByKey = this.params.colDef.headerComponentParams.groupByKey;
      if (groupByKey) {
        const allOptions =
          this.$store.getters.getBenchmarkingMainDropdownSelection[
            this.params.storeKey
          ].data.entityGroup;
        const groupByIndex = allOptions.findIndex((item) => {
          return item.view === this.params.clientFlag;
        });
        const options = allOptions[groupByIndex].metrics;
        const index = options.findIndex((item) => {
          return item === this.params.data.selectedMetric.key;
        });
        if (index === -1) {
          return `This metric is currently unavailable for ${allOptions[groupByIndex].label}`;
        }
      }
      return 'NA';
    },
    isLoading() {
      if (this.toShowMetric) {
        return this.params.data[this.params.colDef.field]?.load;
      }
    },
    toShowMetric() {
      return this.params.data['ag-grid-level'] > 0;
    },
    isNoData() {
      if (this.toShowMetric) {
        let noData = this.params.data[this.params.colDef.field]?.noData;
        if (noData) {
          return noData;
        }
        if (
          !this.params.data[this.params.colDef.field]?.data?.data?.tag1 &&
          this.params.data[this.params.colDef.field]?.data?.data?.tag1 !== 0 &&
          !this.params.data[this.params.colDef.field]?.data?.data?.tag2 &&
          this.params.data[this.params.colDef.field]?.data?.data?.tag2 !== 0
        ) {
          noData = true;
        }
        return noData;
      }
    },
    isHavingError() {
      if (this.toShowMetric) {
        return this.params.data[this.params.colDef.field]?.error;
      }
    },
    config() {
      const config = this.params.data[this.params.colDef.field]?.data?.config;
      if (!config) {
        return;
      }
      return {
        tag1Unit: config.tag1Unit,
        tag2Unit: config.tag2Unit,
        invertTag2: config.invertTag2,
        tableCell: true
      };
    },
    metricData() {
      const data = this.params.data[this.params.colDef.field]?.data?.data;
      return {
        tag1: data?.tag1,
        tag2: data?.tag2
      };
    }
  },
  created() {},
  methods: {
    getMetricData(data) {
      const metricData = cloneDeep(data);
      const excludePvpMetrics =
        this.params.colDef.headerComponentParams?.metadata?.metadata
          ?.excludePvpDisplay;
      const name = this.params.data?.selectedMetric?.key;
      if (excludePvpMetrics?.includes(name) && metricData?.tag2) {
        metricData.tag2 = 0;
      }
      return metricData;
    },
    onMetricSelect(context, selection) {
      console.log(selection);
      if (selection?.length > 0) {
        this.params.data.selectedMetric = selection[0];
        // debugger;
        // this.$store.dispatch()
      }
    },
    plotMetric() {
      console.log(this.params.data.selectedMetric.name);
      this.$store.dispatch(
        'benchmarkingChangePlottedMetric',
        this.params.data.selectedMetric
      );
    }
  }
});
</script>

<style scoped lang="css">
.fit-content {
  width: fit-content;
}
.show-chart-holder {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007cf6;
  visibility: hidden;
}
.ag-row-hover .show-chart-holder {
  visibility: visible;
}
.metric-holder {
  width: 115px;
}
.skeleton-loader {
  height: 16px;
  width: 32px;
}
.underline-text {
  text-decoration: underline;
}
.centered {
  display: flex;
  justify-content: center;
}
.highlighted-card {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(43, 51, 59, 0.15);
}
</style>
