import { AxiosResponse } from 'axios';
import { SelectedMetric } from '../../types/benchmark-table';
import { DropdownElement } from '../../types/rb-select-dropdown';
import {
  constants,
  getGlobalSettingsForMS,
  setGlobalSettingsForMS,
  asinLevelV2ConfigEnabled,
  isMarketShareLite
} from '@/components/pages/insights/amazon/market-share/utils';
import {
  ApiData,
  BenchmarkWidget,
  DataApi,
  DataApiResponse,
  EntityData
} from '@/components/pages/insights/amazon/market-share/service/data-api';
import { MetaDataReadApi } from '@/components/pages/insights/amazon/market-share/service/meta-data';
import { SeggregatedMap } from '@/components/pages/insights/amazon/market-share/service/benchmarking-table/types';
import {
  DimensionNameValueList,
  ThreePSelection
} from '@/components/pages/insights/amazon/market-share/types/filters';

/**
 * MetricGroup, represents the group of metrics that have to be shown in a dropdown
 */
export class MetricGroup {
  private displayedMetricsNames: string[];
  public totalMetrics: string[] = [];
  private metricMap: {
    [id: string]: Metric;
  };

  /**
   *
   * @param metrics
   * @param dataApi
   * @param globalViewId
   * @param pageId
   * @param showableMetrics
   */
  constructor(
    public metrics: Metric[],
    private dataApi: BenchmarkWidget,
    private globalViewId: number,
    private pageId: number,
    private showableMetrics: Metric[],
    public isThisMetricPlotted: boolean = false,
    public dataApiResponse?: DataApiResponse
  ) {
    this.metricMap = {};
    for (const metric of this.metrics) {
      this.metricMap[metric.id] = metric;
    }
    const savedMetricNames = getGlobalSettingsForMS(
      constants.DISPLAYED_METRICS
    ) as string[];
    this.displayedMetricsNames = savedMetricNames?.length
      ? savedMetricNames
      : this.showableMetrics.slice(0, 5).map((x) => x.id);
  }

  findMetric(metricName: string): Metric | undefined {
    return this.metrics.find((x) => x.id === metricName);
  }

  /**
   * Switches the metric selection in the dropdown
   * @param id The id of the metric you want to switch
   * @param metricId The metric id you want to switch to
   */
  switchMetric(
    selectedMetric: SelectedMetric,
    newSelection: { selectedOptions: DropdownElement[] }
  ) {
    this.displayedMetricsNames[
      this.displayedMetricsNames.findIndex((x) => x === selectedMetric.id)
    ] = newSelection.selectedOptions[0].key;
    setGlobalSettingsForMS(
      constants.DISPLAYED_METRICS,
      this.displayedMetricsNames
    );
  }

  /**
   * Returns the options that needs to be shown in the metric selection dropdown
   * @returns The options that needs to be shown in the metric selection dropdown
   */
  get options(): Metric[] {
    return this.showableMetrics.filter(
      (x) => !this.displayedMetricsNames.includes(x.id)
    );
  }

  /**
   * Returns the raw data for all showable metrics in a metric group
   * @param dataApi Data API response from the backend
   * @returns Data passed to benchmarkTable vue component
   */
  get data() {
    const metricData: any[] = [];
    this.displayedMetricsNames.forEach((x) => {
      try {
        let m = this.metricMap?.[x];
        if (x === 'asp') {
          m = this.metricMap?.asp || this.metricMap?.asp_exclude_3P;
        }
        m.data = this.dataApiResponse;
        metricData.push(m.tableMetricData);
      } catch (err) {
        console.error(err);
        setGlobalSettingsForMS(constants.DISPLAYED_METRICS, [], true);
      }
    });
    return [
      {
        entity: 'METRICS',
        rowCategory: 'metrics',
        'ag-grid-level': 0,
        'ag-grid-total-levels': 1,
        isExpanded: true
      },
      ...metricData
    ];
  }

  get displayedMetrics() {
    return this.displayedMetricsNames.map(
      (metricName) => this.metricMap[metricName]
    );
  }

  get seggregatedMetericList(): SeggregatedMap {
    const seggregatedMetricMap: SeggregatedMap = {};
    for (const metric of this.metrics) {
      if (metric.dataGroup in seggregatedMetricMap) {
        seggregatedMetricMap[metric.dataGroup].push(metric);
      } else {
        seggregatedMetricMap[metric.dataGroup] = [metric];
      }
    }
    return seggregatedMetricMap;
  }

  /**
   *
   * @param whereClause The where clause to be attached to the request, appends the incoming dimensionNameValueList to the dimensionNameValueList from the read API
   * @param displayShareIn Unit or dollar share what needs to be shown
   * @returns Returns the data by the bundled request
   */
  async makeBundledRequest(
    whereClause: any,
    displayShareIn: string,
    metadataResponse: AxiosResponse<MetaDataReadApi>,
    threepSelection: ThreePSelection
  ): Promise<AxiosResponse<DataApiResponse>> {
    // TODO: @rhitik.b@commerceiq.ai : Remove the logic for displayShareIn from this method
    const datasPromise: Promise<AxiosResponse<DataApiResponse>>[] = [];
    // Making seggregated calls to the data APIs to get
    // the data for each of the seperate data group
    // eslint-disable-next-line prefer-const
    const uiMetadata = metadataResponse?.data?.metadata;
    /** Array containing all the metric names that are applicable to unit estimate */
    const unitsMetrics = uiMetadata?.unitMetrics;
    /** Array containing all the metric names that are applicable to sales estimate */
    const salesMetrics = uiMetadata?.salesMetrics;
    /** name of marketshare metric w.r.t Estimate type and 3p selection */
    const marketShareMetric =
      uiMetadata?.marketShareMetric?.[displayShareIn]?.[threepSelection];
    for (let [dataGroup, metrics] of Object.entries(
      this.seggregatedMetericList
    )) {
      if (!asinLevelV2ConfigEnabled()) {
        if (
          (threepSelection !== ThreePSelection.EXCLUDE_3P_CLIENT &&
            dataGroup ===
              (isMarketShareLite()
                ? constants.mslActualExclude3P
                : constants.actualExclude3P)) ||
          (threepSelection === ThreePSelection.EXCLUDE_3P_CLIENT &&
            dataGroup ===
              (isMarketShareLite() ? constants.mslActual : constants.msActual))
        ) {
          continue;
        }
      }
      if (displayShareIn.includes('SALES_ESTIMATES')) {
        metrics = metrics.filter(
          (metric) =>
            !unitsMetrics.some((unitsMetric) =>
              unitsMetric?.includes(metric.id)
            ) || marketShareMetric?.includes(metric.id)
        );
      } else if (displayShareIn.includes('UNIT_ESTIMATES')) {
        metrics = metrics.filter(
          (metric) =>
            !salesMetrics.some((salesMetric) =>
              salesMetric?.includes(metric.id)
            ) || marketShareMetric?.includes(metric.id)
        );
      }
      let dimensionNameValueList =
        metrics?.[0].metricData?.['api']?.['request']?.['where']
          ?.dimensionNameValueList;
      const msIndependentDataGroups = uiMetadata?.msIndependentDataGroups;
      if (!msIndependentDataGroups.includes(metrics?.[0].dataGroup)) {
        dimensionNameValueList = metrics?.[0].metricData?.['api']?.[
          'request'
        ]?.['where']?.dimensionNameValueList.concat(
          whereClause?.dimensionNameValueList?.length > 0
            ? whereClause.dimensionNameValueList
            : []
        );
        if (metrics[0]?.dimensionValueList?.length) {
          dimensionNameValueList.push(...metrics[0].dimensionValueList);
        }
      }
      const metricList = metrics.map(
        (metric) => {
          this.totalMetrics.push(metric.metricData.name);
          return metric.metricData.name;
        } // TODO: This should be strongly typed
      );
      let v2 = asinLevelV2ConfigEnabled();
      // TODO: Make sure we make the request to the data api using the common data Apias
      const response = this.dataApi.getData(
        metrics?.[0].metricData?.['api']?.['request'],
        {
          substitute: {
            'operations.orderByList': [],
            metricsList: metricList,
            'where.dimensionNameValueList': dimensionNameValueList
          },
          variableReplace: {
            ':from': whereClause.date.from,
            ':to': whereClause.date.to,
            ':pvpFrom': whereClause.pvpDate.from,
            ':pvpTo': whereClause.pvpDate.to,
            ':widget':
              (isMarketShareLite()
                ? constants.mslBenchmarkingWidgetName
                : constants.benchmarkingWidgetName) + (v2 ? '_v2' : '')
          }
        },
        metadataResponse.data.metadata.dataAPI.endPoint,
        metrics?.[0].metricData?.['api']?.['service']
      );
      datasPromise.push(response);
    }
    const datas = await Promise.all(datasPromise);
    const categoryDataMapping: { [key: string]: EntityData } = {};
    for (const entityData of datas[0].data.entityData) {
      categoryDataMapping[entityData.entityValue] = entityData;
    }
    for (const data of datas.slice(1)) {
      for (const entity of data.data.entityData) {
        if (entity.entityValue === undefined) {
          continue;
        }
        if (!categoryDataMapping[entity.entityValue]) {
          categoryDataMapping[entity.entityValue] = entity;
        }
        categoryDataMapping[entity.entityValue].data = categoryDataMapping[
          entity.entityValue
        ].data.concat(entity.data);
      }
    }
    const combinedResponse = {
      ...datas[0],
      data: {
        cacheInfo: datas[0].data.cacheInfo,
        entityData: Object.keys(categoryDataMapping).map(
          (entity) => categoryDataMapping[entity]
        )
      }
    };
    return combinedResponse;
  }

  addMetric(metric: Metric) {
    this.metrics.push(metric);
  }

  replaceMetric(oldMetric: Metric, newMetric: Metric) {
    const index = this.metrics.indexOf(oldMetric);
    this.metrics.splice(index, 1, newMetric);
  }
}

export class Metric {
  id!: string;
  metricName!: string;
  metricData!: Record<string, any>;
  dimensionValueList!: DimensionNameValueList[];
  data?: DataApiResponse;
  constructor(
    id: string,
    metricName: string,
    metricData: Record<string, any>,
    dimensionValueList: DimensionNameValueList[],
    data?: DataApiResponse
  ) {
    this.id = id;
    this.metricName = metricName;
    this.metricData = metricData;
    this.dimensionValueList = dimensionValueList;
    this.data = data;
  }

  get tooltip() {
    return this.metricData?.['tooltip'];
  }

  get dataGroup() {
    return this.metricData?.['dataGroup'];
  }

  /**
   * Data that needs to be plotted on the table.
   */
  get tableMetricData() {
    const categories: {
      [key: string]: {
        load: boolean;
        metricWidth: string;
        centered: boolean;
        data: {
          config: {
            invertTag2: boolean;
            tag1Unit: {
              pre: string;
              suff: string;
            };
            tag2Unit: {
              suff: string;
            };
          };
          data: {
            tag1: number;
            tag2: number | undefined;
          };
          timeseries: never[];
        };
        error: boolean;
        rowCategory: string;
        noData: boolean;
      };
    } = {};
    if (this.data) {
      for (const d of this.data.entityData) {
        const metadataUnit = this.metricData.metadata.unit.toLowerCase();
        const metricData = this.findMetricData(d.data);
        const pvpKey =
          metadataUnit === 'percentage'
            ? `PVP_DIFF_${metricData?.name}`
            : `PVP_${metricData?.name}`;
        categories[d.entityValue] = {
          centered: true,
          load: false,
          metricWidth: '200px',
          data: {
            config: {
              invertTag2: this.metricData.metadata.isInverted,
              tag1Unit: {
                pre: metadataUnit !== 'percentage' ? metadataUnit : '',
                suff: metadataUnit === 'percentage' ? '%' : ''
              },
              tag2Unit: { suff: '%' }
            },
            data: {
              tag1: metricData?.RESULT[metricData.name] as number,
              tag2: this.findMetricData(d.data)?.PVP
                ? this.findMetricData(d.data)?.PVP[pvpKey]
                : undefined
            },
            timeseries: []
          },
          error: false,
          rowCategory: 'sales',
          noData: false
        };
      }
    }
    return {
      rowCategory: 'sales',
      selectedMetric: {
        name: this.metricName,
        tooltip: this.tooltip,
        id: this.id
      },
      'ag-grid-level': 1,
      'ag-grid-total-levels': 1,
      ...categories
    };
  }

  findMetricData(data: ApiData[]): ApiData | undefined {
    if (this.id === 'asp') {
      return (
        data.find((metricData) => metricData.name === 'asp') ||
        data.find((metricData) => metricData.name === 'asp_exclude_3P') ||
        data.find((metricData) => metricData.name === 'asp_3P_only')
      );
    }
    return data.find((metricData) => metricData.name === this.id);
  }
}
