<template>
  <div
    v-tableCardCell="params.tableCardCellConfig"
    class="u-display-inline-flex u-width-100 u-height-100 u-flex-align-items-center u-cursor-default u-flex-justify-content-flex-end"
  >
    <div v-if="!metricData.tag1 && !metricData.tag2">
      <div
        class="cell--content u-white-space-normal u-flex-shrink-past-min-size u-color-grey-base"
        data-cy="metricValue"
      >
        NA
      </div>
    </div>
    <metric
      v-else
      :config="config"
      :hide-zero="false"
      :size="'xs'"
      class="u-display-inline-flex u-spacing-mt-s"
      :data="metricData"
    />
  </div>
</template>

<script>
import metric from '@/components/basic/metric';
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'metricDisplay',
  components: {
    metric
  },
  data() {},
  computed: {
    config() {
      return {
        tag1Unit: this.params?.tag1Unit,
        tag2Unit: this.params?.tag2Unit,
        tableCell: true
      };
    },
    metricData() {
      return {
        tag1: this.params.data[this.params?.tag1Key],
        tag2: this.params.data[this.params?.tag2Key]
      };
    }
  },
  created() {},
  methods: {}
};
</script>
