src/pages/toolsandsettings/entitytagging/index.vue
<template>
  <section class="reports_container u-bg-color-grey-white u-height-100">
    <section
      class="page_header u-spacing-ph-l u-spacing-pt-0 u-spacing-pb-m u-bg-color-grey-white sticky u-z-index-1 u-header-box-shadow"
    >
      <div class="u-display-flex">
        <h2 class="u-font-size-2 u-color-grey-base u-font-weight-bold">
          Reports
        </h2>
      </div>
    </section>
    <section
      class="dashboardSection u-spacing-ph-m u-spacing-mt-l u-envelop-width u-width-100"
    >
      <div class="u-display-flex u-flex-direction-column">
        <section
          v-for="(item, elem, index) of allTabs"
          :key="index"
          class="u-width-100 u-flex-wrap-yes"
          :class="{ 'u-spacing-mt-m': index !== 0 }"
        >
          <p
            v-if="Object.keys(allTabs).length > 1"
            class="u-font-size-3 u-text-align-left u-spacing-pb-m u-color-blue-base u-font-weight-600 u-text-transform-uppercase"
          >
            {{ elem }}
          </p>
          <div
            class="u-width-100 u-display-flex u-flex-align-items-strech u-flex-wrap-yes u-flex-justify-content-start"
          >
            <div
              v-for="(report, reportIndex) in item"
              :key="report.name"
              :class="{ 'u-spacing-ml-m': reportIndex % 4 !== 0 }"
              class="cardSection u-display-flex u-flex-direction-column u-flex-align-items-flex-start u-spacing-mb-m"
              style="width: calc(25% - 1.2rem)"
              @click="handleNavigation(report)"
            >
              <div
                class="u-spacing-p-m u-width-100 u-spacing-pr-0 u-display-flex u-flex-align-item-flex-end u-flex-align-items-center"
              >
                <Bookmark
                  :path="report.fullPath"
                  :title="report.name"
                  :icon-class="'rb-icon--xx-medium u-height-100'"
                  :wrapper-class="'u-spacing-mr-xs'"
                />
                <span
                  class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-mt-xxs u-line-height-1-3"
                  data-cy="report-name"
                  >{{ report.name }}</span
                >
              </div>
              <!-- <div class="u-display-flex u-flex-justify-content-space-between u-spacing-pb-m u-spacing-ph-m ">
                <p class="chipRound u-font-size-6 u-color-grey-base u-display-flex">
                  <rb-icon icon="bot rb-icon--small u-spacing-mr-xs u-color-blue-base" />
                  <span class="u-spacing-mt-xxs">CIQ</span> <rb-icon icon="calendar rb-icon--small u-spacing-mr-xs u-spacing-ml-m u-color-grey-lighter" /> <span class="u-spacing-mt-xxs">1 Jan, 2020</span>
                </p>
              </div> -->
            </div>
          </div>
        </section>
      </div>
    </section>
  </section>
</template>

<script>
import Vue from 'vue';
import utils from '@/utils/helpers/';
import reportsConfig from '@/pages/reports/route_config/route.js';
import { filters } from '@ciq-dev/ciq-ui';

export default {
  name: 'Report',
  author: 'Rajan V',
  data() {
    const dashBoardList = {};
    if (reportsConfig.routes) {
      reportsConfig.routes.forEach((element) => {
        if (element.path && element.path !== ':id') {
          if (utils.tabsConfigCheckPassV2.call(this, element)) {
            element.fullPath =
              this.$store.getters.getRootPath + 'reports/' + element.path;
            if (dashBoardList.Sales) {
              dashBoardList.Sales.push(element);
            } else {
              dashBoardList.Sales = [];
              dashBoardList.Sales.push(element);
            }
          }
        }
      });
    }

    return {
      dashBoardList: dashBoardList
    };
  },
  computed: {
    allTabs() {
      let customDashboard =
        this.$store.getters.getCustomReportsClientDashboards || [];
      if (customDashboard.length > 0) {
        customDashboard = customDashboard.map((element) => {
          if (element.path.indexOf('reports/') !== -1) {
            element.fullPath = this.$store.getters.getRootPath + element.path;
          } else {
            element.fullPath =
              this.$store.getters.getRootPath + 'reports/' + element.path;
          }
          return element;
        });
        Vue.set(this.dashBoardList, 'Advertising', customDashboard);
      }
      return this.dashBoardList;
    }
  },
  created() {
    const version = filters.config_check(
      'feature.custom_reports_v2.default_version'
    );
    this.$store.dispatch('set_default_version', version);
    this.$store.dispatch('check_and_fetch_clients_dashboard');
  },
  methods: {
    handleNavigation(element) {
      this.$logger.logEvent({
        eventType: 'reports-card-click',
        type: 'Click',
        page: `${element.path}`,
        eventContext: 'Reports Card Click'
      });
      if (element.path.indexOf('reports/') !== -1) {
        this.$router.push(`${element.path}`);
      } else {
        this.$router.push(`reports/${element.path}`);
      }
    }
  }
};
</script>
<style lang="css" scoped>
.cardSection {
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  box-shadow: 0 1px 4px 0 #e9eaeb;
}

.cardSection:hover {
  box-shadow: 0 1px 8px 0 rgba(43, 51, 59, 0.15) !important;
}

.u-z-index-1 {
  z-index: 1;
}

.reports_container {
  background-color: #f5f7fa;
}
</style>
