const base = '/chartIcons/';
// Mapping is hacky way to solve the pbm in chartting wb.
export default {
  'p-variant': {
    src: base + '3p-variant.svg'
  },
  '3P Variant': {
    src: base + '3p-variant.svg'
  },
  'add-on': {
    src: base + 'add-on.svg'
  },
  Addon: {
    src: base + 'add-on.svg'
  },
  amazon_choice: {
    src: base + 'amazon.svg'
  },
  'Prime Exclusive': {
    src: base + 'prime_exclusive.svg'
  },
  'Amazon Choice': {
    src: base + 'amazon.svg'
  },
  'best-seller': {
    src: base + 'best-seller.svg'
  },
  'Best Seller': {
    src: base + 'best-seller.svg'
  },
  Campaigns: {
    src: base + 'campaign.svg'
  },
  Promotions: {
    src: base + 'promotions.svg'
  },
  promotions: {
    src: base + 'promotions.svg'
  },
  'prime-exclusive': {
    src: base + 'prime-exclusive.svg'
  },
  'content-change': {
    src: base + 'content-change.svg'
  },
  'Content Change': {
    src: base + 'content-change.svg'
  },
  'out-of-stock': {
    src: base + 'oos.svg'
  },
  'Out of Stock': {
    src: base + 'oos.svg'
  },
  'search-entry': {
    src: base + 'search_entry.svg'
  },
  'search-exit': {
    src: base + 'search_exit.svg'
  },
  'Search entry to Page 1': {
    src: base + 'search_entry.svg'
  },
  'Search drop from Page 1': {
    src: base + 'search-fill.svg'
  },
  'subscribe-save': {
    src: base + 'sns.svg'
  },
  'Subscribe & Save': {
    src: base + 'sns.svg'
  },
  suppressed: {
    src: base + 'suppressed.svg'
  },
  Suppressed: {
    src: base + 'suppressed.svg'
  },
  availability: {
    src: base + 'unavailable.svg'
  },
  Availability: {
    src: base + 'unavailable.svg'
  },
  Unavailable: {
    src: base + 'unavailable.svg'
  },
  dot: {
    src: base + 'dot.svg'
  },
  campaign: {
    src: base + 'campaign.svg'
  },
  'lost-buybox': {
    src: base + 'lost-buybox.svg'
  },
  'Lost Buybox': {
    src: base + 'lost-buybox.svg'
  },
  'Change in variants': {
    src: base + 'change_in_variants.svg'
  },
  change_in_variants: {
    src: base + 'change_in_variants.svg'
  },
  'Search exit from Page 1': {
    src: base + 'search_exit.svg'
  }
};
