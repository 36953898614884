import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';

const state = {
  reviewCountData: null,
  assortmentAverageData: null,
  localFilterSelectedValue: {},
  reviewsLocalFilters: {
    starRatings: [
      {
        title: 'Rating: All',
        value: 'total_new_reviews'
      },
      {
        title: 'Rating: 1 Star',
        value: 'star1_new_reviews'
      },
      {
        title: 'Rating: 2 Star',
        value: 'star2_new_reviews'
      },
      {
        title: 'Rating: 3 Star',
        value: 'star3_new_reviews'
      },
      {
        title: 'Rating: 4 Star',
        value: 'star4_new_reviews'
      },
      {
        title: 'Rating: 5 Star',
        value: 'star5_new_reviews'
      }
    ]
  },
  insightsReviewsFilterValues: {},
  reviewsFilters: [],
  reviewCountDate: null,
  insightsReviewsData: {},
  insightsReviewsSelectedFilter: {
    date_range: {
      name: 'Last 90 days'
    }
  },
  insightsReviewsWhereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  filtersMapping: {
    reviews_category: 'category',
    reviews_subcategory: 'subcategory',
    reviews_product_group: 'product_group',
    reviews_brand: 'brand'
  },
  averageResponse: {},
  reviewCountLegendReset: true,
  reviewCountLegendDateApplied: false,
  assortmentAverageDate: null,
  assortmentLegendReset: true,
  assortmentLegendDateApplied: false,
  reviewsTablePage: 1,
  page_wise_min_max_key: 'review_ratings'
};

const getters = {
  getInsightsReviewsFilterValues: (state) => {
    return state.insightsReviewsFilterValues;
  },
  getInsightsReviewsSelectedFilter: (state) => {
    return state.insightsReviewsSelectedFilter;
  },
  getInsightsReviewsData: (state) => {
    return state.insightsReviewsData;
  },
  getReviewCountData: (state) => {
    return state.reviewCountData;
  },
  getAssortmentAverageData: (state) => {
    return state.assortmentAverageData;
  },
  getReviewsLocalFilters: (state) => {
    return state.reviewsLocalFilters;
  },
  getSelectedReviewsLocalFilters: (state) => {
    return state.localFilterSelectedValue;
  },
  getReviewCountLegendReset: (state) => {
    return state.reviewCountLegendReset;
  },
  getReviewCountLegendLegendDateApplied: (state) => {
    return state.reviewCountLegendDateApplied;
  },
  getAssortmentLegendReset: (state) => {
    return state.assortmentLegendReset;
  },
  getAssortmentLegendDateApplied: (state) => {
    return state.assortmentLegendDateApplied;
  },
  getReviewsTablePage: (state) => {
    return state.reviewsTablePage;
  }
};

const mutations = {
  IREVIEWS_SUCCESS: (state, data) => {
    Vue.set(state.insightsReviewsData, data.key, {});
    if (data.xs || data.legends) {
      Vue.set(state.insightsReviewsData[data.key], 'data', {
        data: data.columns,
        xs: data.xs,
        legends: data.legends
      });
    } else {
      Vue.set(state.insightsReviewsData[data.key], 'data', data.columns);
    }
    Vue.set(state.insightsReviewsData[data.key], 'rows', data.rows);
    Vue.set(state.insightsReviewsData[data.key], 'totalRows', data.totalRows);
    Vue.set(state.insightsReviewsData[data.key], 'metrics', data.metrics);
    Vue.set(state.insightsReviewsData[data.key], 'load', false);
    Vue.set(state.insightsReviewsData[data.key], 'page', data.page);
    Vue.set(state.insightsReviewsData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.insightsReviewsData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.insightsReviewsData[data.key], 'noData', true);
    }
  },
  IREVIEWS_RESET: (state, data) => {
    Vue.set(state.insightsReviewsData, data.key, {});
    Vue.set(state.insightsReviewsData[data.key], 'columns', []);
    Vue.set(state.insightsReviewsData[data.key], 'rows', []);
    Vue.set(state.insightsReviewsData[data.key], 'load', true);
    Vue.set(state.insightsReviewsData[data.key], 'error', false);
    Vue.set(state.insightsReviewsData[data.key], 'noData', false);
  },
  IREVIEWS_ERROR: (state, data) => {
    Vue.set(state.insightsReviewsData, data.key, {});
    Vue.set(state.insightsReviewsData[data.key], 'columns', []);
    Vue.set(state.insightsReviewsData[data.key], 'rows', []);
    Vue.set(state.insightsReviewsData[data.key], 'load', false);
    Vue.set(state.insightsReviewsData[data.key], 'error', true);
    Vue.set(state.insightsReviewsData[data.key], 'noData', false);
  },
  IREVIEWS_SET_SELECTED_FILTER: (state, data) => {
    Vue.set(
      state.insightsReviewsSelectedFilter,
      data.filterValueKey,
      data.values
    );
    getFiltersInFormat6(state.insightsReviewsSelectedFilter, state);
  },
  SET_REVIEWS_FILTER_VALUES: (state, data) => {
    Vue.set(
      state.insightsReviewsFilterValues,
      data.filterValueKey,
      data.values
    );
  },
  STORE_REVIEWCOUNTDATA: (state, data) => {
    state.reviewCountData = data;
  },
  STORE_AVERAGECOUNTDATA: (state, data) => {
    state.assortmentAverageData = data;
  },
  STORE_UPDATE_RATINGS_BAR_DATA: (state, data) => {
    for (var key in state.assortmentAverageData) {
      state.assortmentAverageData[key].avg_review_rating =
        data[key].avg_review_rating;
    }
  },
  SET_LOCAL_FILTER_DATA: (state, data) => {
    state.reviewsLocalFilters[data.key] = data.data;
  },
  UPDATE_LOCAL_FILTER_SELECTED_VALUE: (state, data) => {
    Vue.set(state.localFilterSelectedValue, data.key, data.value);
    // state.localFilterSelectedValue[data.key] = data.value;
  },
  SET_REVIEWS_DATE: (state, data) => {
    state.reviewCountDate = data;
  },
  REVIEWCOUNT_LEGEND_RESET: (state, val) => {
    state.reviewCountLegendReset = val;
  },
  REVIEWCOUNT_LEGEND_DATE_APPLIED: (state, val) => {
    state.reviewCountLegendDateApplied = val;
  },
  SET_ASSORTMENT_AVERAGE_DATE: (state, data) => {
    state.assortmentAverageDate = data;
  },
  ASSORTMENT_LEGEND_RESET: (state, val) => {
    state.assortmentLegendReset = val;
  },
  ASSORTMENT_LEGEND_DATE_APPLIED: (state, val) => {
    state.assortmentLegendDateApplied = val;
  },
  UPDATE_TABLE_PAGE: (state, val) => {
    state.reviewsTablePage = val;
  }
};

function getFiltersInFormat6(oFilter, state) {
  var where = state.insightsReviewsSelectedFilter;
  where.dimensionNameValueList = [];

  for (var i in oFilter) {
    if (i !== 'date_range') {
      for (var j in oFilter[i]) {
        if (typeof oFilter[i][j] === typeof true) {
          where.dimensionNameValueList.push({
            dimensionName: state.filtersMapping[i] || i,
            dimensionValue: j
          });
        } else {
          if (oFilter[i][j].items && oFilter[i][j].name) {
            for (var k in oFilter[i][j].items) {
              where.dimensionNameValueList.push({
                dimensionName:
                  state.filtersMapping[oFilter[i][j].name] ||
                  oFilter[i][j].name,
                dimensionValue: k
              });
            }
          }
        }
      }
    }
  }
  state.insightsReviewsSelectedFilter = where;
}

function getChartDataInFormat0(_aArray, data, response, replace) {
  var _oObj = {};
  if (response === null) {
    return [];
  }
  for (var i = 0; i < response.length; i++) {
    for (var j in response[i]) {
      if (replace[j] !== undefined) {
        if (!_oObj[j]) {
          _oObj[j] = [j];
        }
        _oObj[j].push(response[i][j]);
      }
    }
  }
  for (i in replace) {
    if (_oObj[i] !== undefined) {
      _oObj[i][0] = replace[i];
    }
  }
  return _oObj;
}

function getReviewsWhereClauseDate(context) {
  var returnDate = {
    to: context.getters.getDateRangeValues.review_ratings[
      context.getters.getInsightsReviewsSelectedFilter.date_range.name
    ].to,
    from: context.getters.getDateRangeValues.review_ratings[
      context.getters.getInsightsReviewsSelectedFilter.date_range.name
    ].from,
    name: context.getters.getDateRangeValues.review_ratings[
      context.getters.getInsightsReviewsSelectedFilter.date_range.name
    ].name,
    page_wise_min_max_key: state.page_wise_min_max_key
  };
  return returnDate;
}

const resetReviews = (context, key) => {
  context.commit('IREVIEWS_RESET', { key });
};

const setWhereClause = (data, context) => {
  const where = transformer.getCompleteWhereClause(
    data.meta.localFilters,
    context.state.insightsReviewsSelectedFilter
  );

  const date = context.state.reviewCountDate
    ? context.state.reviewCountDate.date
    : getReviewsWhereClauseDate(context);

  data.body.APIConfig.where = { ...where, date };

  if (context.state.reviewCountDate?.pvpDate) {
    data.body.APIConfig.where.pvpDate = context.state.reviewCountDate.pvpDate;
  }
};

const updateBundleCubeRequest = (data) => {
  const { bundleCubeExecutionRequest, where } = data.body.APIConfig || {};

  if (bundleCubeExecutionRequest) {
    for (const request of Object.values(bundleCubeExecutionRequest)) {
      if (request.where) {
        if (request.getPointInTimeMetrics === true) {
          request.where.dimensionNameValueList = where.dimensionNameValueList;
        } else {
          request.where = where;
        }
      }
    }
  }
};

const postRequest = (context, data) => {
  HttpLayer.post({ APIData: data.body.APIConfig })
    .then((response) => {
      if (response.success) {
        const count =
          response.data.length > 0 ? response.data[0].RESULT.count : 1;
        context.commit('IREVIEWS_SUCCESS', {
          key: data.meta.key,
          rows: transformer.mergeResultDimension(response.data, true),
          page: data.body.APIConfig.page,
          totalRows: count
        });
      } else {
        // Handle error here if needed
      }
    })
    .catch((error) => {
      context.commit('IREVIEWS_ERROR', { key: data.meta.key });
      console.log(error);
    });
};

const actions = {
  setAssortmentAverageDate: (context, data) => {
    context.commit('SET_ASSORTMENT_AVERAGE_DATE', data);
  },
  updateLocalFilterSelectedValue: (context, data) => {
    context.commit('UPDATE_LOCAL_FILTER_SELECTED_VALUE', data);
  },
  setInsightsReviewsSelectedFilter: (context, data) => {
    context.commit('IREVIEWS_SET_SELECTED_FILTER', data);
  },
  setReviewsAndRatingsCustomDate: (context, data) => {
    context.commit('SET_REVIEWS_DATE', data);
  },
  updateReviewsSelectedFilter: (context, data) => {
    setTimeout(function () {
      var filters = transformer.getUniqueFilters(
        context.state.searchFilters || [],
        'reviews_',
        data.mapping
      );
      context.commit('SET_FILTER_DATA', filters);
      context.commit('REVIEWCOUNT_LEGEND_RESET', true);
      context.commit('REVIEWCOUNT_LEGEND_DATE_APPLIED', false);
      context.commit('ASSORTMENT_LEGEND_RESET', true);
      context.commit('ASSORTMENT_LEGEND_DATE_APPLIED', false);
      context.commit('UPDATE_TABLE_PAGE', 1);
    }, 1500);
  },

  getSkuLevelBreakdownData: (context, data) => {
    resetReviews(context, data.meta.key);
    setWhereClause(data, context);
    updateBundleCubeRequest(data);
    postRequest(context, data);
  },

  downloadSkuLevelBreakdownTableData: (context, data) => {
    var apiConfigToSend = JSON.parse(JSON.stringify(data.body.APIConfig));
    apiConfigToSend.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.insightsReviewsSelectedFilter
    );
    apiConfigToSend.where.date = getReviewsWhereClauseDate(context);
    if (!context.state.reviewCountDate) {
      apiConfigToSend.where.date = getReviewsWhereClauseDate(context);
    } else {
      apiConfigToSend.where.date = context.state.reviewCountDate.date;
      if (context.state.reviewCountDate.pvpDate) {
        data.body.APIConfig.where.pvpDate =
          context.state.reviewCountDate.pvpDate;
      }
    }

    return HttpLayer.post({
      APIData: apiConfigToSend
    }).then((response) => {
      return transformer.mergeResultDimension(response.data);
    });
  },

  getReviewCount: (context, data) => {
    context.commit('IREVIEWS_RESET', {
      key: data.meta.key
    });
    var tagKey = 'total_new_reviews';
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      [],
      context.state.insightsReviewsSelectedFilter
    );
    data.body.APIConfig.where.date = getReviewsWhereClauseDate(context);
    if (!context.state.reviewCountDate) {
      data.body.APIConfig.where.date = getReviewsWhereClauseDate(context);
    } else {
      data.body.APIConfig.where.date = context.state.reviewCountDate.date;
      if (context.state.reviewCountDate.pvpDate) {
        data.body.APIConfig.where.pvpDate =
          context.state.reviewCountDate.pvpDate;
      }
    }
    if (data.meta.localFilters.length > 0) {
      tagKey = data.meta.localFilters[0].dimensionValue;
      data.body.APIConfig.measuresList = [
        data.meta.localFilters[0].dimensionValue
      ];
      data.body.APIConfig.orderByList = [
        {
          dimension: 'client_flag',
          direction: 'ASC'
        },
        {
          dimension: tagKey,
          direction: 'DESC'
        }
      ];
    }
    HttpLayer.post({
      APIData: data.body.APIConfig
    })
      .then((response) => {
        var xs = {};
        var _aArray = [];
        if (!response.success) {
        } else {
          var oReturn = {};
          if (response.data.length > 0) {
            for (var i = 0; i < response.data.length; i++) {
              var oTemp = {};
              var cKey = response.data[i].DIMENSION.brand;
              var regEx = /^-?\d+\.?\d*$/;
              if (regEx.test(cKey) === true) {
                cKey = response.data[i].DIMENSION.brand + ' ';
              }
              if (cKey) {
                if (cKey && cKey === 'client_brands') {
                  // response.data[i]['DIMENSION']['brand'] = 'My Brands';
                }

                var chartDataObj = {
                  feed_date: 'feed_date_' + cKey
                };
                chartDataObj[tagKey] = cKey;
                oTemp = getChartDataInFormat0(
                  _aArray,
                  data,
                  response.data[i].TIMESERIES,
                  chartDataObj
                );
                oTemp.data = response.data[i].DIMENSION;
                oReturn[cKey] = oTemp;

                xs[cKey] = 'feed_date_' + cKey;
              }
            }
            context.commit('STORE_REVIEWCOUNTDATA', oReturn);
            context.commit('IREVIEWS_SUCCESS', {
              key: data.meta.key,
              columns: oReturn,
              metrics: data.body.getTagData
                ? data.body.getTagData(response.data, tagKey)
                : undefined,
              page: data.body.APIConfig.page,
              xs
            });
          } else {
            context.commit('IREVIEWS_SUCCESS', {
              key: data.meta.key,
              columns: [],
              page: data.body.APIConfig.page,
              metrics: undefined
            });
          }

          if (context.state.reviewCountLegendDateApplied === false) {
            context.commit('REVIEWCOUNT_LEGEND_RESET', true);
          }
          context.commit('REVIEWCOUNT_LEGEND_DATE_APPLIED', false);
        }
      })
      .catch((error) => {
        context.commit('IREVIEWS_ERROR', { key: data.meta.key });
        console.log(error);
      });
  },

  getAssortmentAverageData: (context, data) => {
    context.commit('IREVIEWS_RESET', {
      key: data.meta.key
    });
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.insightsReviewsSelectedFilter
    );
    data.body.APIConfig.where.date = getReviewsWhereClauseDate(context);
    var date = '';
    if (!context.state.assortmentAverageDate) {
      date = getReviewsWhereClauseDate(context);
    } else {
      date = context.state.assortmentAverageDate;
    }
    data.body.APIConfig.where.date.from = date.to; // '2018-03-23';
    data.body.APIConfig.where.date.to = date.to;
    HttpLayer.post({
      APIData: data.body.APIConfig
    })
      .then((response) => {
        if (!response.success) {
        } else {
          if (response.data.length > 0) {
            context.state.averageResponse = response;
            var oTemp = {};
            for (var i = 0; i < response.data.length; i++) {
              var key = response.data[i].DIMENSION.brand; // response.data[i]['DIMENSION']['brand'] === 'client_brands' ? 'My Brands' : response.data[i]['DIMENSION']['brand'];
              var regEx = /^-?\d+\.?\d*$/;
              if (regEx.test(key) === true) {
                key = response.data[i].DIMENSION.brand + ' ';
              }
              if (key.toUpperCase() !== 'UNKNOWN') {
                oTemp[key] = {
                  data: {
                    brand: key, // response.data[i]['DIMENSION']['brand'] === 'client_brands' ? 'My Brands' : response.data[i]['DIMENSION']['brand'],
                    client_flag: response.data[i].DIMENSION.client_flag
                  }
                };
              }
            }
            context.commit('STORE_AVERAGECOUNTDATA', oTemp);
          }
          context.dispatch('getAssortmentAverageGraphData', data);
        }
      })
      .catch((error) => {
        context.commit('IREVIEWS_ERROR', { key: data.meta.key });
        console.log(error);
      });
  },

  getAssortmentAverageGraphData: (context, data) => {
    var apiConfig = {
      cubeName: 'review_ratings_widget',
      measuresList: ['count'],
      groupByDimensionsList: ['brand', 'client_flag', 'avg_review_rating_bin'],
      timeseriesDimension: 'feed_date',
      where: {
        date: {
          from: '2018-03-20',
          to: '2018-03-20'
        },
        dimensionNameValueList: [
          {
            dimensionName: 'product_group',
            dimensionValue: 'Pantry'
          }
        ]
      },
      orderByList: [
        {
          dimension: 'client_flag',
          direction: 'ASC'
        },
        {
          dimension: 'avg_review_rating_bin',
          direction: 'DESC'
        }
      ],
      getLatestAvailableInsteadOfRollup: false,
      timeseriesEnabled: false,
      pvpenabled: false,
      yoyenabled: false,
      getSharePercentage: false
    };
    apiConfig.where = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      context.state.insightsReviewsSelectedFilter
    );
    apiConfig.where.date = getReviewsWhereClauseDate(context);

    var date = '';
    if (!context.state.assortmentAverageDate) {
      date = getReviewsWhereClauseDate(context);
    } else {
      date = context.state.assortmentAverageDate;
    }
    apiConfig.where.date.from = date.to; // '2018-03-23';
    apiConfig.where.date.to = date.to;
    HttpLayer.post({
      APIData: apiConfig
    })
      .then((response) => {
        if (!response.success) {
        } else {
          if (response.data.length > 0) {
            var oTemp = {};
            for (var i = 0; i < response.data.length; i++) {
              var key = response.data[i].DIMENSION.brand;
              var regEx = /^-?\d+\.?\d*$/;
              if (regEx.test(key) === true) {
                key = response.data[i].DIMENSION.brand + ' ';
              }
              if (oTemp.hasOwnProperty(key)) {
                oTemp[key].avg_review_rating[
                  response.data[i].DIMENSION.avg_review_rating_bin + 1
                ] = response.data[i].RESULT.count;
              } else {
                oTemp[key] = {
                  data: {
                    brand: key,
                    client_flag: response.data[i].DIMENSION.client_flag
                  }
                };
                oTemp[key].avg_review_rating = [key];
                oTemp[key].avg_review_rating[
                  response.data[i].DIMENSION.avg_review_rating_bin + 1
                ] = response.data[i].RESULT.count;
              }
            }
            for (var kkey in oTemp) {
              for (var ij = 1; ij < 6; ij++) {
                oTemp[kkey].avg_review_rating[ij] =
                  oTemp[kkey].avg_review_rating[ij] === undefined ||
                  oTemp[kkey].avg_review_rating[ij] === null
                    ? 0
                    : oTemp[kkey].avg_review_rating[ij];
              }
              var firstElement = [oTemp[kkey].avg_review_rating[0]];
              var values = oTemp[kkey].avg_review_rating.slice(1);
              values = values.reverse();
              // console.log(firstElement.concat(values));
              oTemp[kkey].avg_review_rating = firstElement.concat(values);
            }
            context.commit('STORE_UPDATE_RATINGS_BAR_DATA', oTemp);
            // context.commit('STORE_AVERAGECOUNTDATA', oTemp);
            context.commit('IREVIEWS_SUCCESS', {
              key: data.meta.key,
              columns: oTemp,
              metrics: data.body.getTagData
                ? data.body.getTagData(
                    context.state.averageResponse.data,
                    'avg_review_rating'
                  )
                : undefined,
              page: data.body.APIConfig.page
            });
          } else {
            context.commit('IREVIEWS_SUCCESS', {
              key: data.meta.key,
              columns: [],
              page: data.body.APIConfig.page,
              metrics: undefined
            });
          }
          if (context.state.assortmentLegendDateApplied === false) {
            context.commit('ASSORTMENT_LEGEND_RESET', true);
          }
          context.commit('ASSORTMENT_LEGEND_DATE_APPLIED', false);
        }
      })
      .catch((error) => {
        context.commit('IREVIEWS_ERROR', { key: data.meta.key });
        console.log(error);
      });
  },

  setReviewCountLegendReset: (context, data) => {
    context.commit('REVIEWCOUNT_LEGEND_RESET', data);
  },

  setReviewCountLegendDateApplied: (context, data) => {
    context.commit('REVIEWCOUNT_LEGEND_DATE_APPLIED', data);
    context.commit('REVIEWCOUNT_LEGEND_RESET', false);
  },

  setAssortmentLegendReset: (context, data) => {
    context.commit('ASSORTMENT_LEGEND_RESET', data);
  },

  setAssortmentLegendDateApplied: (context, data) => {
    context.commit('ASSORTMENT_LEGEND_DATE_APPLIED', data);
    context.commit('ASSORTMENT_LEGEND_RESET', false);
  },
  setReviewsTablePage: (context, data) => {
    context.commit('UPDATE_TABLE_PAGE', data);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
