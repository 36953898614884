import {
  getRetailerNameInSentenceCase,
  getRetailer
} from '@/components/ams/campaign_creation/setup_configs/walmart/common/adGroupConfig.js';

import {
  callNegativeApplyButtonDisabled,
  callNegativeApplyRows,
  callupdatedRightTableRowsForNegative,
  getUpdatedTextInput,
  bidValues
} from '@/components/ams/ad_group_creation/setup_configs/walmart/adgroupKeywordTargeting.js';
export const fetchSavedState = (context, getter) => {
  const state = context.$store.getters[getter];
  return state;
};

export const generateMatchTypeRows = (row, matchType, showKeyword) => {
  const tempRow = JSON.parse(JSON.stringify(row));
  const tempKeyword = tempRow.keyword ? tempRow.keyword.toLowerCase() : null;
  tempRow.key = tempKeyword + '&' + matchType;
  tempRow.keywordToShow = showKeyword ? tempRow.keyword : ' ';
  tempRow.match_type = matchType;
  tempRow.newBid = row.suggestedBid;
  return tempRow;
};

export const updateSavedState = (context, action, data) => {
  context.$store.dispatch(action, data);
};

export const keywordLeftTable = (vueRef) => {
  return [
    {
      name: 'keywordToShow',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keywordToShow',
          showOnUi: true,
          isFixed: true,
          width: 170
        }
      }
    },
    {
      name: 'match_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Match Type',
        uiType: 'string',
        uiOrder: '3',
        uiTooltip: 'Type of keyword matches suggested.',
        metadata: {
          toolTipText: '',
          tableColumnName: 'match_type',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'suggestedBid',
      type: 'currency',
      uiField: {
        uiLabel: 'Suggested Bid',
        uiType: 'currency',
        uiOrder: '4',
        uiTooltip: `Suggested Bid as given by ${getRetailerNameInSentenceCase(
          vueRef
        )}.`,
        metadata: {
          toolTipText: '',
          tableColumnName: 'suggestedBid',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'score',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword score',
        uiType: 'string',
        uiOrder: '5',
        uiTooltip: `Score of the keyword according to relevancy to the ad group as given by ${getRetailerNameInSentenceCase(
          vueRef
        )}. Higher score means higher relevancy.`,
        metadata: {
          toolTipText: '',
          tableColumnName: 'score',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: vueRef.addAll,
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all Keywords'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add the keyword with the respective match type.',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: vueRef.addEntity,
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    }
  ];
};

export const keywordRightTable = (vueRef) => {
  return [
    {
      name: 'keyword',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keyword',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'match_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Match Type',
        uiType: 'string',
        uiOrder: '2',
        uiTooltip: 'Type of keyword matches suggested.',
        metadata: {
          toolTipText: '',
          tableColumnName: 'match_type',
          showOnUi: true,
          isFixed: false,
          width: 90
        }
      }
    },
    {
      name: 'Suggested bid',
      type: 'custom',
      uiField: {
        widget: 'icon',
        uiLabel: 'Suggested bid',
        uiType: 'custom',
        uiOrder: '3',
        uiTooltip: 'Suggested bid for the specific keyword.',
        metadata: {
          toolTipText: 'Set current keyword bid to its suggested bid.',
          widget: 'icon',
          type: 'iconText',
          tableColumnName: 'suggestedBid',
          formatType: 'currency',
          displayIcon: 'trending-neutral',
          showOnUi: true,
          isFixed: false,
          width: 100,
          iconClickEvent: (context) => {
            const rowNodes = {
              rowNodes: [context.params.node]
            };
            if (!context.params.data.newBid) {
              context.params.data.newBid = context.params.data.suggestedBid;
            }
            vueRef.rightTableInstance.redrawTableRows(rowNodes);
          }
        }
      }
    },
    {
      name: 'newBid',
      type: 'custom',
      uiField: {
        uiLabel: 'Bid',
        uiOrder: '4',
        uiType: 'custom',
        uiTooltip: `Enter the CPC bids for the selected keywords. The bids should be between $${
          getRetailer(vueRef) === 'walmart' ? 1 : 0.3
        } and $20 both inclusive.`,
        metadata: {
          toolTipText: '',
          widget: 'input',
          type: 'number',
          tableColumnName: 'newBid',
          defaultValueColumnName: 'newBid',
          showOnUi: true,
          isFixed: false,
          formatType: 'currency',
          width: 140,
          keyupEvent: '',
          onchangeEvent: (context, event) => {
            // Validation should happen here
            context.params.data.newBid = parseFloat(context.bidValue);
            vueRef.checkValidityForApply();
          }
        }
      }
    },
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '5',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove the keyword from the Ad Group.',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ];
};

export const negativeKeywordLeftTable = (vueRef) => {
  return [
    {
      name: 'keywordToShow',
      type: 'STRING',
      uiField: {
        uiLabel: 'Keyword',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keywordToShow',
          showOnUi: true,
          isFixed: true,
          width: 170
        }
      }
    },
    {
      name: 'match_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Match Type',
        uiType: 'string',
        uiOrder: '3',
        uiTooltip: 'Type of keyword matches suggested.',
        metadata: {
          toolTipText: '',
          tableColumnName: 'match_type',
          showOnUi: true,
          isFixed: false,
          width: 170
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: vueRef.addAll,
        customStyles:
          'cursor:pointer; color:#037ef6 !important; padding-left:15px',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all Keywords'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add the keyword with the respective match type.',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: vueRef.addEntity,
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 110
        }
      }
    }
  ];
};
export const negativeKeywordRightTable = (vueRef) => {
  return [
    {
      name: 'keyword',
      type: 'STRING',
      uiField: {
        uiLabel: 'Negative Keyword',
        uiType: 'string',
        uiOrder: '1',
        metadata: {
          toolTipText: '',
          tableColumnName: 'keyword',
          showOnUi: true,
          isFixed: false,
          width: 270
        }
      }
    },
    {
      name: 'match_type',
      type: 'STRING',
      uiField: {
        uiLabel: 'Match Type',
        uiType: 'string',
        uiOrder: '2',
        uiTooltip: 'Type of keyword matches suggested.',
        metadata: {
          toolTipText: '',
          tableColumnName: 'match_type',
          showOnUi: true,
          isFixed: false,
          width: 190
        }
      }
    },
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '5',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove the keyword from the Ad Group.',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 170
        }
      }
    }
  ];
};

export function keywordTargetingState(
  context,
  adGroupId,
  campaignType = 'sponsoredBrands'
) {
  return {
    component: 'customActionPanel',
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          let entityCountForEnterListPanel = 1000;
          const retailer = getRetailer(vueRef);
          if (retailer === 'walmart') {
            if (campaignType !== 'sponsoredProducts') {
              entityCountForEnterListPanel = 200;
            }
          }
          return {
            primaryKey: 'key',
            isMultipleAllowed: true,
            default: {
              hideHeader: true,
              hideFooter: true,
              noRowsRightTable: 'Add Keywords to this list',
              selections: '',
              noRowsLeftTable: 'No Keywords sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple keywords are not allowed'
            },
            left: {
              altStore: `${retailer}CampaignCreation/getKeywords`,
              tabs: {
                Suggested: {
                  search: {
                    enableSearch: true,
                    placeholder: 'Search',
                    searchKey: 'keyword',
                    isClientSide: true
                  },
                  columnDefs: keywordLeftTable(vueRef),
                  showFilterCheckBox: true,
                  filterCheckBoxSource: {
                    header: 'Select Match Type:',
                    defaultSelected: ['Broad', 'Phrase', 'Exact'],
                    items: ['Broad', 'Phrase', 'Exact']
                  }
                },
                'Enter List': {
                  bidValues: bidValues(retailer),
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'keyword',
                      buttonLabel: 'Add keywords',
                      emitTextInput: true,
                      entityCount: entityCountForEnterListPanel,
                      emitAtEnd: true
                    },
                    events: {
                      updateTextInput(data) {
                        const keywords = data?.keywordsArray?.filter(
                          (item) => item.keyword.length <= 80
                        );
                        const customBid = data?.ref?.suggestedCustomBidInput;

                        // Assign minBid and maxBid based on retailers here
                        let maxBid = 100;
                        let minBid = 1;
                        if (
                          retailer === 'walmart' &&
                          campaignType === 'sponsoredProducts'
                        ) {
                          minBid = 0.3;
                          maxBid = null;
                        }
                        if (retailer === 'samsclub_api') {
                          minBid = 0.3;
                        }

                        // Logic to show snackbar message starts here
                        if (
                          maxBid &&
                          minBid &&
                          (customBid < minBid || customBid > maxBid)
                        ) {
                          data?.ref?.$snackbar.open({
                            message: `Please add a bid between ${minBid} and ${maxBid}.`,
                            duration: 6000,
                            buttonColor: '#f5d908',
                            actionText: ''
                          });
                          return;
                        }
                        if (customBid < minBid) {
                          data?.ref?.$snackbar.open({
                            message: `Please add a bid greater than ${minBid}`,
                            duration: 6000,
                            buttonColor: '#f5d908',
                            actionText: ''
                          });
                          return;
                        }
                        const addToRightKeywordsTable = [];
                        for (const item of keywords) {
                          addToRightKeywordsTable.push({
                            suggestedBid: '-',
                            newBid: customBid,
                            keyword: item.keyword,
                            keywordToShow: item.keyword,
                            key: item.key,
                            match_type: item.match_type
                          });
                        }
                        const invalidKeywords = new Set();
                        const filteredInvalidKeywords = data?.keywordsArray
                          .filter(({ keyword }) => keyword.length > 80)
                          ?.map?.((item) => item.keyword);
                        filteredInvalidKeywords.forEach((keyword) => {
                          invalidKeywords.add(keyword);
                        });
                        if (invalidKeywords.size > 0) {
                          data?.ref?.$snackbar.open({
                            message:
                              'The length of the keyword(s) should be within 80 characters.',
                            duration: 6000,
                            buttonColor: '#f5d908',
                            actionText: ''
                          });
                        }
                        data.ref.addEntityFromRawObjectArray(
                          addToRightKeywordsTable
                        );
                        data.enterListRef.keywordTextInput =
                          invalidKeywords.size
                            ? Array.from(invalidKeywords)?.join?.('\n')
                            : '';
                      }
                    }
                  },
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  showLeftCustomBid: true,
                  showFilterCheckBox: true,
                  filterCheckBoxSource: {
                    header: 'Select Match Type:',
                    defaultSelected: ['Broad', 'Phrase', 'Exact'],
                    items: ['Broad', 'Phrase', 'Exact']
                  }
                }
              },
              isColumnDefAutoConfigure: true,
              isClientSidePagination: true,
              table: 'left',
              meta: {
                type: 'table',
                action: `${retailer}CampaignCreation/fetchKeywords`,
                adGroupId: adGroupId,
                updateRows: (vueRef, searchKey, searchText) => {
                  const newRows = [];
                  let fliterBroad = false;
                  let filterPhrase = false;
                  let filterExact = false;
                  const checkBoxValues = vueRef.leftFilterCheckBoxArray
                    .toString()
                    .toLowerCase();
                  fliterBroad = checkBoxValues.includes('broad');
                  filterPhrase = checkBoxValues.includes('phrase');
                  filterExact = checkBoxValues.includes('exact');
                  if (
                    (!filterPhrase && !filterExact && !fliterBroad) ||
                    (filterPhrase && filterExact && fliterBroad)
                  ) {
                    filterPhrase = true;
                    filterExact = true;
                    fliterBroad = true;
                  }
                  vueRef.leftTableData.rows.forEach((row) => {
                    if (fliterBroad) {
                      const broad = generateMatchTypeRows(
                        row,
                        'broad',
                        fliterBroad
                      );
                      newRows.push(broad);
                    }
                    if (filterPhrase) {
                      const phrase = generateMatchTypeRows(
                        row,
                        'phrase',
                        !fliterBroad
                      );
                      newRows.push(phrase);
                    }
                    if (filterExact) {
                      const exact = generateMatchTypeRows(
                        row,
                        'exact',
                        !fliterBroad && !filterPhrase && !fliterBroad
                      );
                      newRows.push(exact);
                    }
                  });
                  newRows.forEach((row) => {
                    const limit = campaignType === 'sponsoredBrands' ? 1 : 0.3;
                    row.newBid =
                      !Number.isNaN(parseFloat(row.suggestedBid)) &&
                      parseFloat(row.suggestedBid) < limit
                        ? limit
                        : row.suggestedBid;
                  });
                  vueRef.leftTableRows = newRows;
                  if (searchKey && searchText) {
                    vueRef.leftTableRows = vueRef.leftTableRows.filter((row) =>
                      row[searchKey]
                        .toLowerCase()
                        .includes(searchText?.toLowerCase())
                    );
                  }
                },
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                useExistingWhereClause: true,
                APIConfig: {
                  page: 1,
                  limit: 10
                },
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: keywordLeftTable(vueRef),
                rowClassRules: {
                  ' hideBorderAddKeywordsToCampaigns': function (params) {
                    if (params.data.keywordToShow === ' ') {
                      return true;
                    }
                    return false;
                  }
                }
              },
              headerText: 'Add Keywords'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: true,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: keywordRightTable(vueRef)
              },
              updateRightTableRows(vueRef) {
                const allStepsData = fetchSavedState(
                  context,
                  context?.selectedValues?.getter
                );
                const newRightTableData = [];
                const { fetchedKeywordsResponse } = allStepsData;
                if (
                  fetchedKeywordsResponse &&
                  fetchedKeywordsResponse.length > 0
                ) {
                  fetchedKeywordsResponse.forEach((item) => {
                    const newKeyword = {
                      suggestedBid: '-',
                      newBid: item?.bid,
                      keyword: item?.keywordText,
                      keywordToShow: item?.keywordText,
                      key: item?.keywordText + '&' + item?.matchType,
                      match_type: item?.matchType,
                      keywordId: item?.keywordId
                    };
                    newRightTableData.push(newKeyword);
                  });
                }
                vueRef.rightTableRows = newRightTableData;
                vueRef.refreshLeftTableIsRowSelected();
              },
              headerText: () =>
                `Added Keywords (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {}
          };
        }
      }
    },
    events: {
      rightTableRows(rows) {
        let allStepsData =
          fetchSavedState(context, context?.selectedValues?.getter) || {};
        allStepsData.targets = rows;
        allStepsData = { ...allStepsData };
        updateSavedState(
          context,
          `${getRetailer(context)}CampaignCreation/updateAllStepsData`,
          allStepsData
        );
      },
      afterAPIActionToCall(payload) {
        this.$emit('openSnackbar', 'Action has been submitted');
      },
      closePanelMethod() {}
    }
  };
}

export function negativeKeywordTargetingState(context, adGroupId) {
  return {
    component: 'customActionPanel',
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'key',
            isMultipleAllowed: true,
            default: {
              hideHeader: true,
              hideFooter: true,
              noRowsRightTable: 'Add Keywords to this list',
              selections: '',
              noRowsLeftTable: 'No Keywords sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Multiple keywords are not allowed'
            },
            left: {
              tabs: {
                'Enter List': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'keyword',
                      buttonLabel: 'Add keywords',
                      emitTextInput: true,
                      entityCount: 1000,
                      emitAtEnd: true
                    },
                    events: {
                      updateTextInput(data) {
                        getUpdatedTextInput(data);
                      }
                    }
                  },
                  bidValues: bidValues('samsclub_api'),
                  showFilterCheckBox: true,
                  filterCheckBoxSource: {
                    header: 'Select Match Type:',
                    defaultSelected: ['Exact'],
                    items: ['Exact']
                  }
                }
              },
              isColumnDefAutoConfigure: true,
              isClientSidePagination: true,
              table: 'left',
              meta: {
                type: 'table',
                adGroupId: adGroupId,
                localFilters: []
              },
              footer: {
                show: false
              },
              body: {
                useExistingWhereClause: true,
                APIConfig: {
                  page: 1,
                  limit: 10
                },
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: negativeKeywordLeftTable(vueRef),
                rowClassRules: {
                  ' hideBorderAddKeywordsToCampaigns': function (params) {
                    return params.data.keywordToShow === ' ';
                  }
                }
              },
              headerText: 'Add Negative Keywords'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: true,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 50,
                  page: 1
                },
                columnDefs: negativeKeywordRightTable(vueRef)
              },
              updateRightTableRows(vueRef) {
                callupdatedRightTableRowsForNegative(context, vueRef);
              },
              headerText: () =>
                `Added Negative Keywords (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {
              callNegativeApplyRows(rows, context, vueRef, 'samsclub_api');
            },
            applyButtonDisabled() {
              callNegativeApplyButtonDisabled(vueRef, context);
            }
          };
        }
      }
    },
    events: {
      rightTableRows(rows) {
        let allStepsData =
          fetchSavedState(context, context?.selectedValues?.getter) || {};
        allStepsData.negativeTargets = rows;
        allStepsData = { ...allStepsData };
        updateSavedState(
          context,
          `${getRetailer(context)}CampaignCreation/updateAllStepsData`,
          allStepsData
        );
      },
      afterAPIActionToCall() {
        this.$emit('openSnackbar', 'Action has been submitted');
      }
    }
  };
}
