import { BaseConfig } from '@/pages/dashboard_service_campaign_management/types/index';
import { UtilServiceForConfig } from '@/pages/dashboard_service_campaign_management/utils/utilServiceForConfig';

const chartingWb = () =>
  import(
    // @ts-ignore
    '@/components/widgets/custom_widgets/custom_widget_components/charts/charting-wb.vue'
  );
const dashboardServiceTable = () =>
  import(
    // @ts-ignore
    '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue'
  );
const plotRowsChip = () =>
  import(
    // @ts-ignore
    '@/pages/dashboard_service_campaign_management/common_components/plotRowsChip.vue'
  );
const tableSwitchComponent = () =>
  import(
    // @ts-ignore
    '@/pages/dashboard_service_campaign_management/common_components/tableSwitchComponent.vue'
  );
const iconCell = () =>
  import(
    // @ts-ignore
    '@/pages/dashboard_service_campaign_management/common_components/iconCell.vue'
  );
const iconTextActions = () =>
  // @ts-ignore
  import('@/components/widgets/iconTextActions.vue');
const actionsBar = () =>
  // @ts-ignore
  import('@/components/widgets/custom_widgets/cw_actionsBar');
const activityLog = () =>
  // @ts-ignore
  import('@/components/widgets/collaboration/collaboration.vue');
const EditBudgetAndPacing = () =>
  import(
    // @ts-ignore
    '@/pages/campaign-management/retailers/criteo/line_items/EditBudgetAndPacing/index.vue'
  );
const iconHeader = () =>
  import(
    // @ts-ignore
    '@/pages/dashboard_service_campaign_management/common_components/iconHeader.vue'
  );

const PRIMARY_KEY = 'category_id';

export function categoriesConfig(retailer: string): any {
  const listApiPageName = `${retailer}_cm_categories`;
  const chartWidgetName = `${retailer}_cm_categories_charting_wb`;
  const tableWidgetName = `${retailer}_cm_categories_table`;
  const localStorageFilterKey = `${retailer}-categories-filter`;
  const localStorageFilterOrderKey = `${retailer}-categories-filter-order`;
  const tabName = 'Categories';

  const utilServiceForConfig = new UtilServiceForConfig({
    retailer,
    listApiPageName,
    chartWidgetName,
    tableWidgetName,
    localStorageFilterKey,
    localStorageFilterOrderKey,
    tabName
  });
  const configToReturn: BaseConfig = {
    PRIMARY_KEY,
    entity: 'categories',
    styleClass: 'orders generic-campaign-management',
    pageInfo: utilServiceForConfig.getPageInfo(),
    initRequestParams: utilServiceForConfig.getInitRequestParams(),
    filterSettings: utilServiceForConfig.getFilterSettings({
      backendCube: 'promoteiq_categories_workbench',
      searchDimension: 'search',
      searchInputPlaceHolder: 'Search for a Category',
      bulkSearchStringConstants: {
        dimensionNameMapping: 'category_taxonomy',
        findEntityInputPlaceholder: 'Find a category',
        subTitle: 'categories'
      },
      enableTaxonomyFilters: true
    }),
    // components for dynamic registration in DashboardWrapper
    components: {
      [chartWidgetName]: chartingWb,
      [tableWidgetName]: dashboardServiceTable,
      plotRowsChip: plotRowsChip,
      tableSwitchComponent: tableSwitchComponent,
      iconCell: iconCell,
      iconTextActions: iconTextActions,
      actionsBar: actionsBar,
      activityLog: activityLog,
      iconHeader: iconHeader
    },
    widgetNames: utilServiceForConfig.getWidgetNames(),
    headerOptions: function (context: any) {
      return {
        // context is bound to the DashboardWrapper component
        [chartWidgetName]: {
          props: utilServiceForConfig.getChartWidgetProps(context)
        },
        [tableWidgetName]: {
          props: {
            ...utilServiceForConfig.getTableWidgetProps(context),
            headerOptions: [
              {
                ui_component: 'actionsBar',
                props: {
                  // contains actions and their handlers similar to that of walmart campaign management
                  actionBarActions: [
                    utilServiceForConfig.getPlotRowsActionObject()
                  ],
                  isCustomActionStateVisible:
                    context.isCustomActionStateVisible,
                  selections: context.rowSelections,
                  showPlotRowsChip: context.showPlotRowsChip
                },
                events: {
                  handlePlotRows: context.handlePlotRows,
                  deselectSelections: (selections: any) => {
                    // this is on x click in actions bar;
                    context.handleSelectionChange(selections, true);
                  },
                  openSnackbar: context.openSnackbar,
                  toggleCustomActionState: context.toggleCustomActionState,
                  switchBlueBar: context.switchBlueBar
                }
              },
              ...utilServiceForConfig.getEmailActionComponentRenderObjectArray(
                context
              )
            ],
            enableManageColumns: !context.rowSelections.length
          }
        }
      };
    },
    getActivityData: utilServiceForConfig.getGetActivityDataMethod('category'),
    modal: utilServiceForConfig.getModalBaseConfigForEmail()
  };
  return configToReturn;
}
