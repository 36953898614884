var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rb-category"},[_c('p',{staticClass:"u-spacing-pt-l u-spacing-pl-l u-font-size-5 u-font-weight-600 u-color-grey-lighter u-spacing-pb-l u-text-case-upper u-border-bottom u-border-width-s u-border-color-grey-xxx-light"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"widget-wrp"},[(_vm.data.length > 0)?_c('div',{staticClass:"header u-display-flex u-flex-justify-content-space-between"},[_c('div',{staticClass:"border--line u-spacing-pt-m u-spacing-pb-m u-text-align-right u-spacing-pr-m u-font-size-7 u-flex-1"},[_vm._v(" Share in last 7 days ")]),_c('div',{staticClass:"u-text-align-right u-spacing-pl-m u-spacing-pt-m u-spacing-pb-m u-display-flex u-flex-1 u-font-size-7"},[_vm._v(" Share in prior week ")])]):_vm._e(),_vm._l((_vm.data),function(item,index){return _c('section',{key:index,staticClass:"body u-display-flex u-flex-justify-content-space-between",style:([
        item.colors
          ? { color: item.colors }
          : { color: ("" + (_vm.colorArray[index % _vm.colorArray.length])) }
      ])},[_c('div',{staticClass:"u-display-flex border--line u-spacing-pt-m u-flex-1",class:{
          'u-spacing-pb-xl': index === _vm.data.length - 1,
          'u-spacing-pb-m': index !== _vm.data.length - 1
        }},[_c('div',{staticClass:"u-spacing-pl-l u-flex-1 u-display-flex u-flex-align-items-center",class:{ 'u-font-weight-bold': index === 0 }},[_vm._v(" "+_vm._s(item.BRAND_NAME)+" ")]),(index === 0)?_c('div',{staticClass:"u-text-align-right u-flex-2 u-spacing-pr-m u-font-weight-bold"},[_vm._v(" "+_vm._s(item.LW_VALUE_FORMAT)+" ")]):_vm._e(),(index !== 0)?_c('div',{staticClass:"u-flex-2"},[_c('div',[_c('div',{staticClass:"u-spacing-pr-m u-text-align-right u-spacing-pb-xs u-font-size-6"},[_vm._v(" "+_vm._s(item.LW_VALUE_FORMAT)+" ")]),_c('div',{staticClass:"rb-progress"},[_c('div',{staticClass:"progress--bar",style:([
                  item.colors
                    ? { backgroundColor: item.colors }
                    : {
                        backgroundColor: ("" + (_vm.colorArray[index % _vm.colorArray.length]))
                      },
                  item.lastWeekChange <= 1
                    ? { width: '2%' }
                    : { width: ((item.lastWeekChange) + "%") }
                ])})])])]):_vm._e()]),_c('div',{staticClass:"u-display-flex u-flex-1 u-spacing-pt-m",class:{
          'u-spacing-pb-xl': index === _vm.data.length - 1,
          'u-spacing-pb-m': index !== _vm.data.length - 1
        }},[(index === 0)?_c('div',{staticClass:"u-flex-2 u-spacing-pl-m u-text-align-left u-font-weight-bold"},[_vm._v(" "+_vm._s(item.PW_VALUE_FORMAT)+" ")]):_vm._e(),(index !== 0)?_c('div',{staticClass:"u-flex-2"},[_c('div',[_c('div',{staticClass:"u-spacing-pl-m u-text-align-left u-spacing-pb-xs u-font-size-6"},[_vm._v(" "+_vm._s(item.PW_VALUE_FORMAT)+" ")]),_c('div',{staticClass:"rb-progress progress--right"},[_c('div',{staticClass:"progress--bar--right",style:([
                  item.colors
                    ? { backgroundColor: item.colors }
                    : {
                        backgroundColor: ("" + (_vm.colorArray[index % _vm.colorArray.length]))
                      },
                  item.priorWeekChange <= 1
                    ? { width: '2%' }
                    : { width: ((item.priorWeekChange) + "%") }
                ])})])])]):_vm._e(),_c('div',{staticClass:"u-spacing-ph-xl u-display-flex u-flex-align-items-center u-color-grey-base",class:{
            'u-font-weight-bold': index === 0,
            'u-spacing-pt-xs': index !== 0
          }},[(item.trendIncrese)?_c('rb-icon',{staticClass:"icon--small rb-icon--small u-spacing-mr-xs u-color-blue-base u-color-green-base u-flex-align-items-center",attrs:{"icon":'arrow-up'}}):_vm._e(),(!item.trendIncrese)?_c('rb-icon',{staticClass:"icon--small rb-icon--small u-spacing-mr-xs u-color-red-base u-flex-align-items-center",attrs:{"icon":'arrow-down'}}):_vm._e(),_vm._v(" "+_vm._s(item.CHANGE_VALUE_FORMAT)+" ")],1)])])}),(_vm.data.length === 0)?_c('section',{staticClass:"u-display-flex u-flex-justify-content-center u-spacing-mb-xl"},[_vm._v(" No Data Avaialable !!! ")]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }