<template>
  <!-- Bid Change Panel -->
  <section class="add-negative-keywords u-width-100 u-bg-color-grey-white">
    <loader
      class="fill--parent u-bg-color-grey-white"
      :loading="loader"
      :color="'#007cf6'"
    />

    <section v-if="!loader">
      <header
        class="title u-font-size-5 u-font-weight-600 u-spacing-pv-l u-spacing-ph-l"
      >
        <div class="u-text-case-upper">{{ title }} {{ selectionCount }}</div>
      </header>

      <section
        v-if="showCampaignDetails"
        class="campaign-selection u-display-flex u-flex-direction-column u-spacing-ph-l"
      >
        <div
          class="u-font-size-6 u-font-weight-600 u-color-grey-mid-light u-spacing-mb-s"
        >
          Campaign
        </div>
        <div class="u-max-width-50 u-display-flex">
          <rb-select
            class="u-spacing-mr-s valueDropdown u-max-width-100"
            :send-details="true"
            :on-close="campaignSelected"
            :options="campaignDropdownOptions"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-spacing-p-s u-cursor-pointer"
            >
              <div class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis">
                {{ selectedCampaign && selectedCampaign.campaign_name }}
              </div>
              <rb-icon
                class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base"
                :icon="'caret-down'"
              />
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <div
                class="u-display-flex u-flex-align-items-center"
                :title="option.campaign_name"
              >
                {{ option.campaign_name }}
              </div>
            </template>
          </rb-select>
        </div>
      </section>

      <section
        v-if="showCampaignDetails"
        class="campaign-selection u-display-flex u-flex-direction-column u-spacing-ph-l u-spacing-pt-m"
      >
        <div
          class="u-font-size-6 u-font-weight-600 u-color-grey-mid-light u-spacing-mb-s"
        >
          Ad Group
        </div>
        <div class="u-display-flex">
          <rb-select
            class="valueDropdown u-max-width-100"
            class-name="bp-select"
            :send-details="true"
            :is-multi-select="true"
            :on-close="onSelectAdGroup"
            :options="adGroups"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-spacing-p-s u-cursor-pointer"
            >
              <div class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis">
                {{ adGroupTitle }}
              </div>
              <rb-icon
                class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base"
                :icon="'caret-down'"
              />
            </div>
            <div
              v-if="adGroups.length"
              slot="footer"
              class="u-display-flex u-spacing-ph-l u-spacing-pt-m u-spacing-pb-s u-border-top u-border-width-s u-border-color-grey-xxx-light"
            >
              <rb-button
                :click-fn="clickOutside"
                :type="'filled'"
                text="Apply"
                size="s"
              />
            </div>
          </rb-select>
          <div class="dummy-element">&nbsp;</div>
        </div>
      </section>

      <section
        class="content u-spacing-ph-l"
        :class="{
          'u-spacing-pt-0 u-spacing-pb-m': !showCampaignDetails,
          'u-spacing-pv-m': showCampaignDetails
        }"
      >
        <div
          class="u-font-size-6 u-spacing-mb-s u-color-grey-mid-light u-font-weight-600"
        >
          Match type
        </div>
        <div
          class="u-font-size-5 u-color-grey-light u-display-flex u-spacing-mb-m"
        >
          <div class="radio-wrapper">
            <rb-checkbox v-model="negativePhrase">
              Negative phrase
            </rb-checkbox>
          </div>
          <div class="radio-wrapper u-spacing-ml-s">
            <rb-checkbox v-model="negativeExact"> Negative exact </rb-checkbox>
          </div>
        </div>
      </section>

      <footer class="bidChangePanelFooter">
        <div
          class="u-display-flex u-spacing-pv-m u-spacing-ph-l u-border-width-s u-border-top u-border-bottom u-border-color-grey-xxx-light"
        >
          <rb-button
            :id="applyActionID()"
            :click-fn="takeAction"
            :text="'Apply'"
            :size="'s'"
            :type="'filled'"
            class="u-spacing-mr-s u-cursor-pointer"
            :disabled="!applyButtonValidity"
          />
          <rb-button
            :click-fn="takeAction"
            :text="'Cancel'"
            :size="'s'"
            :type="'hollow'"
            class="u-spacing-mr-s u-cursor-pointer"
          />
        </div>
      </footer>
    </section>
  </section>
</template>

<style lang="css" scoped>
.add-negative-keywords {
  position: relative;
  min-height: 230px;
}

.radio-wrapper {
  min-width: 135px;
}

.valueDropdown {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
}
</style>

<script>
import loader from '@/components/basic/loader';
import { commons } from '@/components/ams/campaigns/commons.js';
import { uniqBy, cloneDeep } from 'lodash';

export default {
  name: 'NegativeSearchTermsPanel',
  components: {
    loader
  },
  props: {
    selectedValues: {
      type: Array,
      default: function () {
        return [];
      }
    },
    title: {
      type: String,
      default: ''
    },
    closePanelMethod: {
      type: Function,
      default: function () {}
    },
    afterAPIActionToCall: {
      type: Function,
      default: function () {}
    },
    actionPanelType: {
      type: String,
      default: 'keywords'
    },
    parentRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    campaignEditConfig: {
      type: Object,
      default: function () {
        return { boolCampaignEdit: false };
      }
    },
    showCampaignDetails: {
      type: Boolean,
      default: true
    },
    selectedActionType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedAdGroup: [],
      applyClicked: false,
      adGroups: [],
      errorMessage: '',
      loader: false,
      selectedCampaign: null,
      actionType: 'addNegativeKeyword',
      campaignDropdownOptions: [],
      negativeExact: false,
      negativePhrase: false,
      allCampaingsData: [],
      previouslySelectedAdGroupData: []
    };
  },
  computed: {
    adGroupTitle() {
      let text = '';
      if (this.selectedAdGroup.length === 0) {
        text = 'Respective ad groups of selected search terms';
      } else {
        this.selectedAdGroup.forEach((item) => {
          if (item) {
            text = text + item.title + ', ';
          }
        });
        text = text.slice(0, -2);
      }
      return text;
    },
    applyButtonValidity() {
      let checkIfCampaignExists = false;
      if (this.selectedCampaign?.campaign_name) {
        checkIfCampaignExists =
          this.selectedCampaign.campaign_name !== 'Select a campaign';
      }
      return (
        (this.negativePhrase || this.negativeExact) &&
        this.campaignDropdownOptions.length > 0 &&
        checkIfCampaignExists
      );
    },
    selectionCount() {
      var length = this.selectedValues.length;
      return `(${length} search term${length > 1 ? 's' : ''} selected)`;
    },
    confirmMessage() {
      var message =
        'I understand and accept the big changes made to some of the keyword bids.';
      return message;
    }
  },
  watch: {
    selectedCampaign(newVal) {
      this.selectedAdGroup = [];
      this.adGroups = [];
      if (newVal?.adgroup_id) {
        this.adGroups = this.allCampaingsData.filter((item) => {
          if (item?.campaign_id === newVal?.campaign_id) {
            item.title = item.adgroup_name;
            item.selected = false;
            if (
              this.selectedValues.length === 1 &&
              item.adgroup_id === this.selectedValues[0]?.data?.adgroup_id
            ) {
              item.selected = true;
              this.selectedAdGroup = [item];
            }
            return item;
          }
        });
        if (this.selectedAdGroup.length === 0 && this.adGroups.length > 0) {
          this.adGroups[0].selected = true;
          this.selectedAdGroup = [this.adGroups[0]];
        }
      } else if (!newVal?.adgroup_id) {
        this.selectedAdGroup = [];
      }
      this.previouslySelectedAdGroupData = cloneDeep(this.adGroups);
    },
    selectedValues(newValue) {
      this.fetchCampaignList();
      if (newValue.length === 0) {
        this.$emit('closePanelMethod');
      }
    }
  },
  created() {
    this.fetchCampaignList();
  },
  methods: {
    applyActionID() {
      return this.$route.path.indexOf('sp') > -1 ||
        this.$route.path.indexOf('sb') > -1
        ? `${this.$route.path.split('/')[7]}_${this.actionPanelType}_${
            this.selectedActionType
          }`
        : `${this.actionPanelType}_${this.selectedActionType}`;
    },
    clickOutside() {
      this.applyClicked = true;
      document.querySelector('.dummy-element').click();
    },
    onSelectAdGroup(context, data) {
      if (this.applyClicked) {
        this.selectedAdGroup = [];
        this.selectedAdGroup = data.filter((item) => item.selected);
        this.applyClicked = false;
        this.$nextTick(() => {
          this.previouslySelectedAdGroupData = cloneDeep(this.adGroups);
        });
      } else {
        this.$nextTick(() => {
          for (let i = 0; i < this.previouslySelectedAdGroupData.length; i++) {
            if (!this.previouslySelectedAdGroupData[i].selected) {
              delete this.adGroups[i].selected;
            } else {
              this.adGroups[i].selected = true;
            }
          }
        });
      }
    },
    takeAction(type) {
      if (type.innerText === 'Cancel') {
        this.$emit('closePanelMethod');
        return;
      }
      var objToPass = {};
      this.callBidActionApi(objToPass);
    },

    campaignSelected(context, selectedOption) {
      if (!selectedOption.length) return;
      this.selectedCampaign = selectedOption[0];
    },

    fetchCampaignList() {
      const that = this;
      this.loader = true;
      if (this.campaignEditConfig.boolCampaignEdit) {
        this.allCampaingsData = this.campaignEditConfig?.allCampaingsData;
        that.campaignDropdownOptions = uniqBy(
          this.allCampaingsData,
          'campaign_id'
        );
        this.selectedCampaign = this.allCampaingsData[0];
        this.loader = false;
      } else {
        const profiles = this.profileNames
          ? this.profileNames
          : this.selectedValues.map((item) => item.data.profile_id);
        this.$store
          .dispatch('fetchCampaignList', {
            profiles,
            dimensionKeyType: this.profileNames ? 'profile_name' : 'profile_id'
          })
          .then(
            (response) => {
              this.allCampaingsData = response;
              that.campaignDropdownOptions = uniqBy(response, 'campaign_id');
              that.selectDefaultCampaign(this.selectedValues);
              that.loader = false;
            },
            () => {
              that.loader = false;
              that.$snackbar.open({
                message: 'Could not load campaign list. Please try again.',
                duration: 5000,
                actionText: ''
              });
            }
          );
      }
    },

    selectDefaultCampaign(selectionArray) {
      const elemIndex = this.campaignDropdownOptions.findIndex(
        (item) => item.campaign_id === 'SAME_AS_INPUT'
      );
      if (selectionArray.length > 1) {
        if (elemIndex === -1) {
          this.campaignDropdownOptions.unshift({
            campaign_name: 'Respective campaigns of selected search terms',
            campaign_id: 'SAME_AS_INPUT'
          });
        }
        this.selectedCampaign = this.campaignDropdownOptions[0];
      } else if (selectionArray.length === 1) {
        if (elemIndex > -1) {
          this.campaignDropdownOptions.splice(elemIndex, 1);
        }
        this.selectedCampaign = this.campaignDropdownOptions.filter((item) => {
          return (
            item.campaign_id.toString() === selectionArray[0].data.campaign_id
          );
        })[0] || { campaign_name: 'Select a campaign' }; // add a default placeholder when no campaign matches in the dropdown list
      }
    },

    // initial payload object as per the panel type
    getInitialPayloadObject(data, adGroup) {
      const arrToRet = [];
      const obj = {
        actionType: this.actionType,
        actionSource: {
          pageUrl: window.location.href,
          primaryKey: `insights_searchTerm_${data.worklogger_primary_key}`
        }
      };
      obj.actionPayload = {
        campaignId: data.campaign_id,
        profileId: data.profile_id,
        campaignType: data.campaign_type,
        keywordText: data.search_term,
        adgroupId: adGroup ? adGroup.adgroup_id : data.adgroup_id,
        matchType: this.negativeExact ? 'negativeExact' : 'negativePhrase'
      };
      obj.viewPayload = { ...obj.actionPayload };
      obj.viewPayload.campaign_name = data.campaign_name;
      obj.viewPayload.adgroupName = adGroup
        ? adGroup.adgroup_name
        : data.adgroup_name;
      arrToRet.push(obj);
      if (this.negativeExact && this.negativePhrase) {
        const objCopy = JSON.parse(JSON.stringify(obj));
        const otherMatchType =
          obj.actionPayload.matchType === 'negativeExact'
            ? 'negativePhrase'
            : 'negativeExact';
        objCopy.actionPayload.matchType = otherMatchType;
        objCopy.viewPayload.matchType = otherMatchType;
        arrToRet.push(objCopy);
      }
      return arrToRet;
    },

    // function that creates the payload which is used in API
    getPayload(params) {
      var paramsToReturn = [];
      for (let i = 0; i < this.selectedValues.length; i++) {
        if (this.selectedAdGroup.length > 0) {
          for (const adGroup of this.selectedAdGroup) {
            var data = this.selectedValues[i].data;
            var arr = this.getInitialPayloadObject(data, adGroup);
            paramsToReturn.push(...arr);
          }
        } else {
          var data = this.selectedValues[i].data;
          var arr = this.getInitialPayloadObject(data);
          paramsToReturn.push(...arr);
        }
      }
      return paramsToReturn;
    },

    // API call
    callBidActionApi(params) {
      var that = this;
      var payload = this.getPayload(params);
      var othersObj = {
        loader: that.loader,
        successMessage:
          'Action has been submitted for the selected search term(s)',
        errorMessage: 'Something went wrong with the action, please try again.',
        successFn: () => {
          that.parentRef.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.loader = false;
          this.$emit('afterAPIActionToCall');
        }
      };
      this.loader = true;
      if (this.campaignEditConfig.boolCampaignEdit) {
        this.$emit('callApiFromCampaignEdit', {
          selections: payload,
          action: 'addAsNegativeKeyword'
        });
      } else {
        commons.bidChangeApiAction.call(this, params, payload, othersObj);
      }
    }
  }
};
</script>
