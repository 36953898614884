import BaseWidgetService from '@/components/pages/insights/amazon/cva/services/BaseWidgetService.js';
import { rem1V2ConfigEnabled } from '@/components/pages/insights/amazon/cva/utils';
import HttpService from '@/utils/services/http-service';
import { cloneDeep } from 'lodash';

export default class CompetitorLandscapeService extends BaseWidgetService {
  topBrandsData = null;
  async fetchData(pageSettings) {
    const pageSettingsCopy = cloneDeep(pageSettings);
    try {
      this.loading = true;
      this.error = false;
      const body = {
        page: 'customer_value_assesment',
        widget: 'cva_comp_landscape',
        entityType: 'brand',
        metricsList: rem1V2ConfigEnabled()
          ? ['cva_comp_landscape_client_id_v2']
          : ['cva_comp_landscape_client_id'],
        dimensionsList: [
          'cohort_rank',
          'attributes',
          'time_period',
          'brand',
          'client_flag',
          'score',
          'BROWSE_NODE',
          'level',
          'client_id'
        ],
        eventsList: [],
        enablePaginationCount: true,
        operations: {
          system: 'cva',
          enableNewPVPFormulaForSOV: false,
          enableDedupBeforeRollup: false,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
          orderByList: [],
          limit: 17,
          page: 1,
          outerWhereClause: {
            dimensionNameValueList: []
          }
        },
        where: {
          dimensionNameValueList: [
            {
              dimensionName: 'time_period',
              dimensionValue: 'L90D',
              operator: 'EQUAL_TO'
            },
            {
              dimensionName: 'cohort_rank',
              dimensionValue: '3',
              operator: 'LESS_THAN_OR_EQUAL_TO'
            },
            {
              dimensionName: 'module',
              dimensionValue: 'Competitor Landscape Top 3',
              operator: 'EQUAL_TO'
            }
          ]
        },
        globalViewId: 481,
        pageId: 9001
      };
      if (rem1V2ConfigEnabled()) {
        body.where.dimensionNameValueList.push(this.getRem1RetailerPayload());
      }
      body.where.dimensionNameValueList.push(
        ...(pageSettingsCopy?.where?.dimensionNameValueList || [])
      );
      body.operations.orderByList.push({
        dimension: 'score',
        direction: 'DESC'
      });
      const data = await HttpService.post('DASHBOARD_SERVICE', body, {
        append: '/cat_analytics/data'
      });
      this.data = data.data;
      this.computeTopBrandsData();
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  computeTopBrandsData() {
    this.topBrandsData = this.data.entityData.map((entity) => {
      return entity.data.reduce((previousVal, currentVal) => {
        previousVal[currentVal.name] = currentVal.RESULT?.[currentVal.name];
        return previousVal;
      }, {});
    });
  }
}
