<template>
  <section
    class="u-display-flex campaign-summary u-border-radius-s u-spacing-mb-l"
  >
    <div
      class="u-display-flex u-width-100 u-flex-justify-content-space-between u-spacing-p-l"
    >
      <div class="u-display-flex u-flex-direction-column">
        <div
          class="u-font-size-3 u-spacing-pb-m u-font-weight-600 u-color-grey-lighter"
          data-cy="campaignSummary"
        >
          Campaign Summary
        </div>
        <div>
          <budgetCards
            class="budget-card-width"
            :load-cards="loadCards"
            :cards-list="cardsList"
            data-cy="budgetCard"
          />
        </div>
      </div>

      <rb-button
        :click-fn="launchCampaigns"
        :text="'Launch Campaigns'"
        type="filled"
        :disabled="disableLaunchCampaign"
        data-cy="launchCampaigns"
      />
    </div>
  </section>
</template>

<script>
import budgetCards from './budgetCards';
export default {
  components: {
    budgetCards
  },
  props: {
    disableLaunchCampaign: {
      type: Boolean,
      default: false
    },
    loadCards: {
      default: true,
      type: Boolean
    },
    cardsList: {
      type: Array,
      default: () => [
        { label: 'Spend / Budget', value: '103.92', unit: 'percentage' },
        { label: 'Budget Impact', value: '100', unit: 'currency' },
        { label: 'Estimated Sales', value: '100', unit: 'currency' }
      ]
    }
  },
  data: () => ({}),
  methods: {
    launchCampaigns() {
      this.$emit('launchCampaign');
    }
  }
};
</script>

<style lang="css" scoped>
.campaign-summary {
  box-shadow: 0px 0px 4px #caccce;
}
.budget-card-width {
  width: fit-content;
}
.rb-button--hollow {
  border-color: #007cf6;
  color: #007cf6;
}
.rb-button--hollow:hover {
  border-color: #007cf6;
  color: #007cf6;
}
</style>
