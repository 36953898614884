<template>
  <section
    :class="{
      'rule-builder-chip': chipSize === 'xs'
    }"
    class="u-spacing-ph-xs u-display-flex u-display-flex u-flex-align-items-center u-flex-align-items-center u-font-size-7 u-color-grey-lighter u-cursor-pointer"
  >
    <div
      v-for="(item, index) in multiDropdownConfig"
      :key="index"
      class="u-display-flex u-flex-align-items-center"
      @click="handleClick(item, index)"
    >
      <div v-if="item.preText">{{ item.preText }}:</div>
      <div class="u-display-flex u-flex-align-items-center">
        <div
          v-for="(stepSelections, stepIndex) in item.selected"
          :key="stepIndex"
        >
          <div
            v-for="(stepSelection, itemIndex) in stepSelections"
            :key="itemIndex"
            class="u-spacing-ml-xs overflow-styles"
          >
            <div v-if="((stepSelection || {}).prefix || {}).text">
              {{ ((stepSelection || {}).prefix || {}).text }}
            </div>
            <div v-if="stepSelection.load">
              <loader
                size="12px"
                class="u-bg-color-grey-white u-spacing-pl-s"
                :loading="true"
                :color="'#007cf6'"
              />
            </div>
            <div
              v-else
              class="u-display-flex"
            >
              <div
                v-if="((stepSelection || {}).infix || {}).text !== null"
                class="u-color-grey-base"
              >
                {{ ((stepSelection || {}).infix || {}).text }}
              </div>
              <div v-if="((stepSelection || {}).suffix || {}).text">
                {{ ((stepSelection || {}).suffix || {}).text }}
              </div>
            </div>
          </div>
        </div>
        <rb-icon
          v-if="(item.iconConfig || {}).name"
          class="u-spacing-ml-xs"
          :class="['rb-icon--' + item.iconConfig.size]"
          :icon="item.iconConfig.name"
        />
      </div>
      <rb-icon
        v-if="(item.seperator || {}).name"
        class="u-spacing-ml-s u-spacing-mr-xs"
        :class="[
          'u-color-' + item.seperator.color,
          'rb-icon--' + item.seperator.size
        ]"
        :icon="item.seperator.name"
      />
    </div>
  </section>
</template>

<script>
import loader from '@/components/basic/loader';
export default {
  components: {
    loader
  },
  props: {
    chipSize: {
      type: String,
      default: 'xs'
    },
    multiDropdownConfig: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleClick(item, index) {
      const payload = { item, index };
      this.$emit('click', payload);
    }
  }
};
</script>

<style lang="css" scoped>
.chip-style {
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 13.5px;
  border: solid 1px #e9eaeb;
}
.chip-style:hover,
.chip-style:focus {
  color: #6a7075;
  fill: #6a7075;
  border: 1px solid #caccce;
}
.overflow-styles {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.shadows {
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
</style>
