<template>
  <div>
    <div
      class="u-font-size-5 u-color-grey-lighter u-font-weight-600 u-text-case-upper u-spacing-mb-s"
    >
      {{ cardWidgetTitle }} ({{ cards.length }})
    </div>
    <div
      id="cardContainer"
      class="card-container u-display-flex"
      :class="{ 'overflow-auto': isHorizontallyScrollable }"
      style="gap: 1.6rem !important"
    >
      <div
        v-for="(card, key) in cards"
        :key="key"
        :style="cardMinWidth"
        class="u-flex-1 u-cursor-pointer"
        @click="clickMetric(card.keyName)"
      >
        <CardWidgetWithImpact
          v-show="true"
          :config="card"
          :default-class="defaultClass"
          :isbgcolor="card.keyName === selectedMetric"
          :constant-footer-bg="constantFooterBg"
          :is-card-collapsed="isCardCollapsed"
          :footer-value="cardMetricData(card.keyName, 'secondary')"
          :is-tooltip-enabled="isTooltipEnabled"
          :card-tooltip-text="cardTooltipText"
          class="u-flex-wrap-wrap u-flex-1"
          data-cy="card-widget-with-impact"
        >
          <div
            slot="body"
            data-cy="card-widget-with-impact-body"
          >
            <loader
              class="fill--parent"
              :loading="isLoading"
              :color="'#007CF6'"
            />
            <metric
              :size="'xxxl'"
              :label-color="card.keyName === selectedMetric ? 'white' : ''"
              class="metric-component u-display-inline-flex u-flex-1 u-width-100 u-flex-justify-content-center u-spacing-mb-l"
              :split="true"
              :hide-zero="false"
              :config="card.metadata.tagConfig"
              :data="cardMetricData(card.keyName, 'primary')"
              :selected="card.keyName === selectedMetric"
            />
          </div>
          <div
            slot="footer-left"
            class="footer-left u-spacing-mr-s impact-label"
          >
            Impact
          </div>
          <div
            slot="footer"
            class="metric-label"
          >
            <metric
              :size="'xss'"
              class="u-display-inline-flex u-flex-1 u-width-100 u-flex-justify-content-center"
              :split="false"
              :config="tagFooterConfig"
              :data="
                isMultiDashboard
                  ? cardMetricFooterData(card.keyName, metricData)
                  : cardMetricData(card.keyName, 'secondary')
              "
              :hide-zero="true"
            />
          </div>
        </CardWidgetWithImpact>
      </div>
    </div>
    <HorizontalScroller
      v-if="isHorizontallyScrollable"
      class="card-widget-scroller"
      :target-element="'cardContainer'"
      :offset-to-scroll="500"
    />
  </div>
</template>
<script>
import CardWidgetWithImpact from '@/components/basic/CardWidgetWithImpact';
import HorizontalScroller from '@/components/widgets/custom_widgets/custom_widget_components/scroller/HorizontalScroller.vue';
import metric from '@/components/basic/metric';
import { isEqual } from 'lodash';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import loader from '@/components/basic/loader';
import {
  getMetricData,
  sortMetric
} from '@/components/widgets/custom_widgets/custom_widget_components/cards/utility.js';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default {
  components: {
    CardWidgetWithImpact,
    HorizontalScroller,
    metric,
    loader
  },
  mixins: [cwMixin],
  props: {
    cardWidgetTitle: {
      type: String,
      default: ''
    },
    initialViewCardCount: {
      type: Number,
      default: 4
    },
    isHorizontallyScrollable: {
      type: Boolean,
      default: false
    },
    cardClickFunction: {
      type: Function,
      default: function () {}
    },
    tagFooterConfig: {
      type: Object,
      default: function () {
        return {
          tag1Unit: {
            pre: 'currency',
            roundoff: '',
            suff: ''
          },
          tag2Unit: {
            suff: '%'
          },
          invertTag2: false
        };
      }
    },
    widgetRequestParams: {
      type: Object,
      default: () => {}
    },
    widgetsArrayToReload: {
      type: Array,
      default: function () {
        return [];
      }
    },
    isCardCollapsed: {
      type: Boolean,
      default: true
    },
    isMultiDashboard: {
      type: Boolean,
      default: () => false
    },
    cardMetricFooterData: {
      type: Function,
      default: function () {}
    },
    defaultClass: {
      type: String,
      default: () => ''
    },
    isTooltipEnabled: {
      type: Boolean,
      default: true
    },
    constantFooterBg: {
      type: String,
      default: ''
    },
    handleMultiTableViews: {
      type: Boolean,
      default: false
    }, // temporory prop to handle roi table views in roi page
    isMetricSorted: {
      type: Boolean,
      default: false
    },
    cardTooltipText: {
      type: String,
      default: ''
    },
    shouldSortMetric: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      cards: [],
      metricData: [],
      selectedMetric: '',
      footerValue: undefined
    };
  },
  computed: {
    cardMinWidth() {
      return `min-width: calc(90%/${this.initialViewCardCount});`;
    },
    dimensionValueMapping() {
      return this.widgetRequestParams.metadata.dimensionValueMapping;
    },
    cardFooterLabel() {
      return this.widgetRequestParams.metadata.cardFooterLabel;
    },
    cardPrimaryMetricList() {
      return this.widgetRequestParams.metadata.cardPrimaryMetricList;
    },
    cardSecondaryMetrcList() {
      return this.widgetRequestParams.metadata.cardSecondaryMetrcList;
    }
  },
  watch: {
    widgetRequestParams(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.fetchData();
      }
    }
  },
  created() {
    this.getCardMetricLIst();
    this.selectedMetric = this.cards[0].keyName;
    this.fetchData();
  },
  methods: {
    getCardMetricLIst() {
      this.cards = [];
      Object.keys(this.metadata.metrics).forEach((metric, index) => {
        if (this.cardPrimaryMetricList.includes(metric)) {
          this.cards.push(this.metadata.metrics[metric]);
        }
      });
    },
    async fetchData() {
      this.isLoading = true;
      const metricsGrouping = dashUtils.groupApis(this.metadata.metrics);
      let response;
      for (const groupName of Object.keys(metricsGrouping)) {
        const api = metricsGrouping[groupName].api;
        const request = dashUtils.replacePlaceHolderWithData(
          api.request,
          this.widgetRequestParams
        );
        request.where.dimensionNameValueList =
          this.widgetRequestParams[':dimensionNameValueList'];
        request.enablePaginationCount = true;
        await dashUtils.fetchDataAPI(request, api).then((args) => {
          response = args?.entityData[0]?.data;
        });
      }
      const data = {};
      response.forEach((e) => {
        data[':' + e.name] = e.RESULT[e.name];
      });
      this.metricData = response;
      if (this.shouldSortMetric) {
        this.cards = sortMetric(this.metricData, this.cards);
        this.selectedMetric = this.cards[0].keyName;
        this.clickMetric(this.selectedMetric);
        this.$emit('dataApiResolved', true);
      }
      this.isLoading = false;
    },
    cardMetricData(selectedCardLabel, type) {
      let finalObj = {};
      if (type === 'primary') {
        finalObj = getMetricData(selectedCardLabel, this.metricData);
      } else {
        finalObj = getMetricData(
          `${this.cardFooterLabel.toLowerCase()}_${selectedCardLabel}`,
          this.metricData
        );
      }
      return finalObj;
    },
    getTagDataSet1(data, mapping) {
      var oReturn = {};
      let tag2Data = 0;
      if ((data.PVP || {})[`PVP_${mapping}`]) {
        tag2Data = (data.PVP || {})[`PVP_${mapping}`];
      } else if ((data.PVP || {})['PVP_' + mapping] === 0) {
        tag2Data = 0;
      } else {
        tag2Data = undefined;
      }
      oReturn = {
        tag1: data.RESULT[mapping],
        tag2: tag2Data
      };
      return oReturn;
    },
    clickMetric(selectedMetric) {
      this.selectedMetric = selectedMetric;
      this.widgetsArrayToReload.forEach((widget) => {
        const tempWidgetParams = this.getWidgetRequestParams(widget);
        tempWidgetParams[':dimensionNameValueList'] = [
          {
            dimensionName: 'filter_issue_type',
            dimensionValue: '',
            operator: 'ILIKE'
          }
        ];
        if (Array.isArray(this.dimensionValueMapping[selectedMetric])) {
          const updatedDimensionValue = this.dimensionValueMapping[
            selectedMetric
          ].map((item) => {
            return {
              dimensionName:
                tempWidgetParams[':dimensionNameValueList'][0].dimensionName,
              dimensionValue: item,
              operator: tempWidgetParams[':dimensionNameValueList'][0].operator
            };
          });
          tempWidgetParams[':dimensionNameValueList'] = updatedDimensionValue;
        } else
          tempWidgetParams[':dimensionNameValueList'][0].dimensionValue =
            this.dimensionValueMapping[selectedMetric];
        this.updateWidgetRequestParams(tempWidgetParams, widget);
      });
      // temporory fix to handle roi table views, will remove once new DI is pulled
      if (this.handleMultiTableViews) {
        this.$emit('handleMultiTableViews', selectedMetric);
      }
    }
  }
};
</script>
<style lang="css">
.selected-card-bg {
  background: #007cf6 !important;
}
.selected-metric {
  font-size: 2rem;
}
.footer-right {
  font-size: 1.5rem;
}
.impact-label {
  font-size: 1.4rem;
  font-weight: 600;
  color: #8b8f93 !important;
  font-family: ProximaNova;
}
.overflow-auto {
  overflow: auto;
}
.card-container::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}
</style>
