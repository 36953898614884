<template>
  <div
    class="badge"
    :class="customClass"
  >
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'BadgeWithLabel',
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    customClass() {
      return `${this.title.toLowerCase().split(' ').join('-')}`;
    }
  }
};
</script>

<style scoped>
.badge {
  padding: 5px 10px;
  background-color: #8b8f93;
  font-weight: 600;
  color: #ffffff;
  border-radius: 4px;
  font-size: 11px;
}
</style>
