export const componentBelowFilterConfigItems = {
  componentBelowFilterVHtmlText:
    "<div class='u-font-size-5'>We have observed the placement metrics are not in sync with Sams Club Console. We are currently working with Sams club team to fix this.</div>",
  componentBelowFilter: function (vueRef) {
    return {
      show: vueRef.showComponentBelowFilter,
      ui_component: 'failedCampaignsBanner',
      props: {
        showCta: false,
        showCloseIcon: true,
        closeIconCustomClass: 'u-margin-left-auto',
        iconCustomClass: 'u-color-orange-base',
        vHtmlText: this.componentBelowFilterVHtmlText
      },
      events: {
        closeIconClicked: function () {
          vueRef.showComponentBelowFilter = false;
        }
      },
      customClass: 'background-yellow-warning'
    };
  }
};

export const systemTransparencyBannerConfig = {
  systemTransparencyBanner: function (dates) {
    return {
      show: !!dates,
      ui_component: 'SystemTransparencyBanner',
      props: {
        dates: dates
      }
    };
  }
};
