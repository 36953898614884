import HttpService from '@/utils/services/http-service';
import Vue from 'vue';
import axios from 'axios';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import utils from '@/utils/helpers/';

// helper functions
const commonStateStructure = () => {
  // created default structure as it is used for most states.
  return { load: false, rows: [], error: false, totalRows: 0 };
};

const createCommitObject = (status, data) => {
  // respononse status helper as it is same for most states
  return {
    load: status === 'loading',
    rows: data?.rows || [],
    error: status === 'error' ? data?.error || false : false,
    totalRows: data?.totalRows ? data.totalRows : 0
  };
};

const commonMutationSetter = (state, stateName, data) => {
  Vue.set(state[stateName], 'rows', data?.rows);
  Vue.set(state[stateName], 'load', data.load);
  Vue.set(state[stateName], 'error', data.error);
  Vue.set(state[stateName], 'totalRows', data.totalRows);
};

const formatAllEntitiesForAllTagsResponse = (response, entityType) => {
  return (
    response?.entityTagging.map((item) => {
      const tags = item.tags.map((tag) => {
        return { title: tag.level1 };
      });
      let transformedObject = {};
      if (entityType === 'sku') {
        transformedObject = {
          asin: item.entityDetail.value,
          asin_name: item.entityDetail.description,
          image_url: item.entityDetail.image,
          product_page_url: item.productPageUrl,
          tags: tags
        };
      } else if (entityType === 'keyword') {
        transformedObject = {
          keyword: item.entityDetail.value,
          tags: tags
        };
      } else if (entityType === 'campaign') {
        transformedObject = {
          campaign_name: item.entityDetail.description,
          campaign_id: item.entityDetail.value,
          tags: tags
        };
      }
      return transformedObject;
    }) || []
  );
};

const formatAllEntitiesForATagResponse = (response, entityType) => {
  return (
    response?.entityDetailList?.map((item) => {
      let transformedObject = {};
      if (entityType === 'sku') {
        transformedObject = {
          asin: item?.value,
          asin_name: item?.description,
          latest_image_url: item?.image,
          latest_product_page_url: item?.productPageUrl
        };
      } else if (entityType === 'keyword') {
        transformedObject = { keyword_text: item?.value };
      } else if (entityType === 'campaign') {
        transformedObject = {
          campaign_name: item?.description,
          campaign_id: item?.value
        };
      }
      return transformedObject;
    }) || []
  );
};

const getFiltersEntitiesAndTags = (oFilter, state) => {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (oFilter.values[i].length > 0 && oFilter.values[i][0].operator) {
      where.dimensionNameValueList.push({
        dimensionName: i,
        dimensionValue: oFilter.values[i][0].value,
        operator: oFilter.values[i][0].operator.operator
      });
    } else {
      for (var j = 0; j < oFilter.values[i].length; j++) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][j]
        });
      }
    }
  }
  state.whereClause = where;
};

// STATE, GETTER, MUTATION, ACTION -->
const state = {
  tagDescription: '',
  allTags: commonStateStructure(),
  allEntitiesForATag: commonStateStructure(),
  addTagToEntity: commonStateStructure(),
  allEntititesTagsMap: commonStateStructure(),
  allEntities: commonStateStructure(),
  removeTag: commonStateStructure(),
  skusForCatalogValue: commonStateStructure(),
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  executeApiSelectedFitlers: {}
};
const getters = {
  getAllTags: (state) => state.allTags,
  getAllEntitiesForATag: (state) => state.allEntitiesForATag,
  getAllEntititesTagsMap: (state) => state.allEntititesTagsMap,
  getAllEntities: (state) => state.allEntities,
  getExecuteApiSelectedFitlers: (state) => state.executeApiSelectedFitlers,
  getRemovedTag: (state) => state.removeTag,
  getTagDescription: (state) => state.tagDescription,
  getSkusForCatalogValues: (state) => state.skusForCatalogValue
};

const mutations = {
  SET_TAG_DESC: (state, data) => {
    state.tagDescription = data;
  },
  REMOVE_TAG: (state, data) => {
    commonMutationSetter(state, 'removeTag', data);
  },
  ALL_ENTITIES: (state, data) => {
    commonMutationSetter(state, 'allEntities', data);
  },
  ALL_ENTITIES_TAGS_MAP: (state, data) => {
    commonMutationSetter(state, 'allEntititesTagsMap', data);
  },

  ALL_ENTITIES_FOR_A_TAG: (state, data) => {
    commonMutationSetter(state, 'allEntitiesForATag', data);
  },

  ENTITIES_EXECUTE_API_FILTERS: (state, data) => {
    Vue.set(state, 'executeApiSelectedFitlers', data.values);
    getFiltersEntitiesAndTags(data, state);
  },

  FETCH_ALL_TAGS: (state, data) => {
    commonMutationSetter(state, 'allTags', data);
  },
  SET_SKUS_IN_CATALOG: (state, data) => {
    commonMutationSetter(state, 'skusForCatalogValue', data);
  }
};

const actions = {
  performEntityTagsDownlaod: async (
    context,
    { APIConfig, localFilters, columnMap, entity, fileName }
  ) => {
    const apiConfig = { ...APIConfig };
    delete apiConfig.paginationRequest;
    const finalWhereClause = transformer.getCompleteWhereClause(
      localFilters || [],
      context.state.whereClause
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    apiConfig.brandsRequest.where = transformedWhereClause;
    apiConfig.brandsRequest.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    delete apiConfig.brandsRequest.where.tagWhereClause;
    try {
      const response = await HttpService.post(
        'ALL_ENTITIES_WITH_ALL_TAGS',
        apiConfig
      );
      const formattedResponse = formatAllEntitiesForAllTagsResponse(
        response.data,
        entity
      );
      for (const item of formattedResponse) {
        let tagString = '';
        for (const [index, tag] of item?.tags?.entries() || []) {
          tagString += tag.title;
          if (index + 1 !== item?.tags?.length) {
            tagString = tagString + ',';
          }
        }
        item.tags = tagString;
      }
      utils.performDownload(formattedResponse, columnMap, fileName);
      return true;
    } catch (err) {
      return false;
    }
  },
  bulkAddTag: async (context, data) => {
    return await HttpService.post('BULK_ADD_TAG', data);
  },
  removeTag: async (context, data) => {
    try {
      context.commit('REMOVE_TAG', 'loading');
      const response = await HttpService.post('DELETE_TAG', data);
      if (response?.data === 'Successfully Added!') {
        context.commit('REMOVE_TAG');
      } else {
        context.commit('REMOVE_TAG', { error: true, load: false });
      }
      // todo
    } catch (e) {
      context.commit('REMOVE_TAG', { error: true, load: false });
    }
  },
  addTagToEntity: async (context, data) => {
    // used in Workbench > SKUS and Campaign Management > Keywords
    return await HttpService.post('ADD_ENTITY_TAG', data);
  },

  fetchAllEntitiesWithAllTags: async (
    context,
    { APIConfig, localFilters, useExistingWhereClause }
  ) => {
    // todo, api contract pending
    context.commit('ALL_ENTITIES_TAGS_MAP', createCommitObject('loading'));
    const { entityType } = APIConfig;
    if (!useExistingWhereClause) {
      const finalWhereClause = transformer.getCompleteWhereClause(
        localFilters || [],
        context.state.whereClause
      );
      const transformedWhereClause =
        transformer.transformWhereClauseWithTag(finalWhereClause);
      APIConfig.brandsRequest.where = transformedWhereClause;
      APIConfig.brandsRequest.tagWhereClause =
        transformedWhereClause.tagWhereClause || [];
      delete APIConfig.brandsRequest.where.tagWhereClause;
    }
    try {
      const response = await HttpService.post(
        'ALL_ENTITIES_WITH_ALL_TAGS',
        APIConfig,
        {
          apiCancelId: 'dw/allEntitiesForATag'
        }
      );
      const result = formatAllEntitiesForAllTagsResponse(
        response.data,
        entityType
      );
      context.commit(
        'ALL_ENTITIES_TAGS_MAP',
        createCommitObject('success', {
          rows: result,
          totalRows: parseInt(response?.data?.count || '0')
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        context.commit(
          'ALL_ENTITIES_TAGS_MAP',
          createCommitObject('error', { error: true })
        );
      }
    }
  },

  removeEntityFromATag: async (context, data) => {
    return await HttpService.post('REMOVE_ENTITY_FROM_A_TAG', data);
  },

  fetchAllEntitiesForAtag: async (context, data) => {
    // get all entities that belong to a tag
    const { entityType, tagType, customType, tagLevel, tag } = data;
    context.commit('SET_TAG_DESC', '');
    context.commit('ALL_ENTITIES_FOR_A_TAG', createCommitObject('loading'));
    try {
      const response = await HttpService.get('ALL_ENTITIES_FOR_A_TAG', {
        append: `?entityType=${entityType}&tagType=${tagType}&customType=${customType}&tagLevel=${tagLevel}&tag=${encodeURIComponent(
          tag
        )}`,
        apiCancelId: 'dw/allEntitiesForATag'
      });
      const result = formatAllEntitiesForATagResponse(
        response.data,
        entityType,
        context
      );
      context.commit(
        'ALL_ENTITIES_FOR_A_TAG',
        createCommitObject('success', {
          rows: result,
          totalRows: result.length
        })
      );
      context.commit('SET_TAG_DESC', response?.data?.tagDescription || '');
    } catch (error) {
      if (!axios.isCancel(error)) {
        context.commit(
          'ALL_ENTITIES_FOR_A_TAG',
          createCommitObject('error', { error: true })
        );
      }
    }
  },

  fetchEntitiesExecuteApiFilters: (context, data) => {
    context.commit('ENTITIES_EXECUTE_API_FILTERS', data);
  },

  fetchEntitiesExecuteApi: (context, data) => {
    data.body.APIConfig.where = transformer.getCompleteWhereClause(
      data?.meta?.localFilters || [],
      context.state.whereClause
    );
    context.commit('ALL_ENTITIES', createCommitObject('loading'));
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit(
          'ALL_ENTITIES',
          createCommitObject('error', { error: true })
        );
      } else {
        let resultArray = [];
        resultArray = transformer.mergeResultDimension(response.data, true);
        context.commit(
          'ALL_ENTITIES',
          createCommitObject('success', {
            rows: resultArray,
            totalRows:
              resultArray[0]?.auto_cubesdk_count || resultArray[0]?.count || 0
          })
        );
      }
    });
  },

  fetchAllTags: async (context, data) => {
    // used in Workbench > SKUS and Campaign Management > Keywords.
    // data.prefix == null > fetch all tags or else we filter by prefix.
    try {
      context.commit('FETCH_ALL_TAGS', createCommitObject('loading'));
      const response = await HttpService.get('FETCH_SUGGESTED_ENTITY_TAG', {
        append: `?entityType=${data.entityType}&tagType=${
          data.tagType
        }&customType=${data.customType}${
          data.prefix ? '&prefix=' + data.prefix : ''
        }`,
        apiCancelId: 'dataworkbench/tagSuggestions'
      });
      const result = response.data.map((item) => {
        return {
          title: item.level1
        };
      });
      context.commit(
        'FETCH_ALL_TAGS',
        createCommitObject('success', {
          rows: result,
          totalRows: result.length
        })
      );
    } catch (error) {
      if (!axios.isCancel(error)) {
        context.commit(
          'FETCH_ALL_TAGS',
          createCommitObject('error', { error: true })
        );
      }
    }
  },
  fetchSkusForCatalogValues(context, data) {
    context.commit('SET_SKUS_IN_CATALOG', createCommitObject('loading'));
    const APIConfig = { ...data };
    const params = data.params;
    APIConfig.where = {
      dimensionNameValueList: [
        {
          dimensionName: params.dimension,
          dimensionValue: params.tag,
          ...(params?.esDataSetName
            ? { esDataSetName: params.esDataSetName }
            : {})
        }
      ]
    };
    delete APIConfig.params;
    HttpLayer.post({
      APIData: APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit(
          'SET_SKUS_IN_CATALOG',
          createCommitObject('error', { error: true })
        );
      } else {
        let skus = [];
        skus = transformer.mergeResultDimension(response.data, true);

        context.commit(
          'SET_SKUS_IN_CATALOG',
          createCommitObject('success', { rows: skus, totalRows: skus.length })
        );
      }
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
