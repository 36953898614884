<template>
  <div class="u-spacing-ph-s u-width-100 brand-icon-cell">
    <div
      v-if="params.tooltipText"
      :id="tooltipId"
      class="u-display-none"
    >
      <div class="u-max-width-350 u-spacing-p-s">
        <div class="u-display-flex u-flex-align-items-center">
          <div class="image-wrapper-header-s">
            <img :src="params.imageUrl" />
          </div>
          <!-- eslint-disable -->
          <span
            class="u-font-weight-normal u-spacing-ml-s u-font-size-5"
            v-html="params.tooltipHeader"
          />
        </div>
        <p
          class="u-line-height-1-3 u-font-size-6 u-color-grey-light u-font-weight-normal u-spacing-mt-s"
        >
          {{ params.tooltipText }}
        </p>
      </div>
    </div>
    <div
      class="u-spacing-ph-m u-spacing-pt-m u-spacing-mb-m u-height-100 u-display-flex u-flex-align-items-center root"
      :class="params.customClasses"
      v-tippy="tippyOptions"
    >
      <div class="image-wrapper-header">
        <img :src="params.imageUrl" />
      </div>
      <span
        class="u-font-size-3 u-font-weight-600 u-spacing-ml-s"
        :class="{ 'u-font-size-4': params.headerText === 'Dimensions' }"
        >{{ params.headerText }}</span
      >
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  computed: {
    tooltipId() {
      return `${this.params.column.colId}_header_tooltip`;
    },
    tippyOptions() {
      return {
        html: '#' + this.tooltipId,
        theme: 'light light-shadow',
        placement: 'top-start',
        distance: -8
      };
    }
  }
});
</script>
<style lang="css" scoped>
.root {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.u-max-width-350 {
  max-width: 350px;
}
.image-wrapper-header {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 25px;
}
.image-wrapper-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.image-wrapper-header-s {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
}
.image-wrapper-header-s img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
</style>
