<template>
  <div
    class="u-bg-color-grey-white u-border-radius-s history-card u-spacing-p-m u-spacing-mv-s u-cursor-default"
  >
    <div class="u-display-flex u-flex-justify-content-space-between">
      <div
        class="u-spacing-pb-xxs u-flex-4 u-font-size-6 u-text-overflow-ellipsis"
      >
        <span class="u-color-grey-lighter">To&#32;</span>
        <span
          v-for="(receipient, index) in to"
          :key="index"
          class="u-color-grey-light u-font-weight-600 u-spacing-pr-xs"
          >{{ receipient }}</span
        >
      </div>
      <span
        class="u-font-weight-600 u-flex-3 u-display-flex u-flex-justify-content-flex-end u-font-size-7 u-color-grey-mid-light"
      >
        <span class="u-spacing-ph-xs">{{ formatDate }}</span>
        <span>{{ formatTime }}</span>
      </span>
    </div>
    <div class="u-flex-4 u-font-size-7 u-text-overflow-ellipsis">
      <span class="u-color-grey-lighter">From&#32;</span>
      <span class="u-color-grey-light u-font-weight-600">{{ from }}</span>
    </div>
    <div
      class="u-font-size-6 u-font-weight-600 u-color-grey-base u-spacing-mt-m"
    >
      {{ subject }}
    </div>
  </div>
</template>

<script>
import { utcToLocalDate, utcToLocalTime } from '@/utils/helpers/formatter';
export default {
  components: {},
  props: {
    to: {
      type: Array,
      default: []
    },
    time: {
      type: String,
      default: ''
    },
    subject: {
      type: String,
      default: ''
    },
    from: {
      type: String,
      default: ''
    }
  },
  computed: {
    formatTime() {
      return utcToLocalTime(this.time);
    },
    formatDate() {
      return utcToLocalDate(this.time);
    }
  },
  watch: {},
  methods: {}
};
</script>

<style lang="css" scoped>
.history-card {
  box-shadow: 0 2px 4px 0 rgba(43, 51, 59, 0.15);
}
</style>
