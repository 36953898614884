<template>
  <div
    class="u-position-relative u-font-size-5 u-spacing-pv-m u-spacing-pr-xxl"
  >
    <p class="u-font-size-3 u-font-weight-600">
      {{ $t('lost_buy_box') }}
    </p>
    <p class="u-font-size-4 u-font-weight-600 u-spacing-pt-l">
      {{ $t('lost_buy_box') }}
      {{ alert.attributes.lost_buybox_to_seller_days }}
      {{ $t('days_ago_to_seller') }} "{{
        alert.attributes.lost_buybox_to_sellername
      }}"
    </p>
    <p class="u-font-size-6 u-spacing-pt-s">
      {{ $t('appeared_on_')
      }}{{ formatter(alert.attributes.last_appeared_on, 'date') }} &bull;
      {{ $t('appeared_') }}{{ alert.attributes.recency
      }}{{ $t('_out_of_last_30_days') }}
    </p>
    <div class="u-display-flex u-spacing-pt-xl">
      <div
        class="u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('units_at_amazon')"
          :item-value="alert.attributes.current_units_at_amazon"
        />
      </div>
      <div
        class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('open_po')"
          :item-value="alert.attributes.open_po_code"
        />
      </div>
      <div
        class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('offer_price')"
          :item-value="formatter(alert.attributes.offer_price, 'currency')"
        />
      </div>
      <div class="u-spacing-pl-s">
        <key-value-pair
          :item-key="$t('revenue')"
          :item-value="
            formatter(alert.attributes.last_4weeks_revenue, 'currency')
          "
        />
        <span class="u-font-size-7 u-color-grey-lighter"
          >({{ $t('last_4_weeks') }})</span
        >
      </div>
    </div>
    <div class="u-display-flex u-spacing-pt-m u-spacing-pb-xl">
      <div
        class="u-display-flex u-flex-align-items-center u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <span>{{ $t('rating') }}: </span>
        <star-rating
          :active-color="'#f3b000'"
          :inactive-color="'#f3b000'"
          :max="5"
          :value="Math.round(alert.attributes.rating)"
          :readonly="true"
        >
          <span
            slot="activeLabel"
            slot-scope="props"
            ><rb-icon :icon="'star'"
          /></span>
          <span
            slot="inactiveLabel"
            slot-scope="props"
            ><rb-icon :icon="'star-empty'"
          /></span>
        </star-rating>
        <span
          >({{ alert.attributes.rating || 'NA' }} {{ $t('out_of_5') }})</span
        >
      </div>
      <div class="u-spacing-pl-s u-display-flex u-flex-align-items-center">
        <key-value-pair
          :item-key="$t('reviews')"
          :item-value="alert.attributes.reviews || 'NA'"
        />
      </div>
    </div>
    <div class="detail-view--card detail--chart">
      <chart
        v-if="showChart"
        style="height: 270px"
        class="u-spacing-mh-l u-spacing-pv-l"
        :config="barChartConfig"
        :data="barChartData"
      />
    </div>
    <p
      class="u-spacing-pt-xl u-font-size-5 u-font-weight-600 u-color-grey-lighter"
    >
      {{ $t('recommended_action') }}
    </p>
    <div
      v-if="alert.attributes.mean_estimate_value && showPerformanceOverview"
      class="u-display-flex u-flex-align-items-center u-spacing-pv-xs u-color-blue-base"
    >
      <p>
        Estimated incremental margin value (min and max):
        {{ alert.attributes.min_estimate_value | num_format('currency') }} -
        {{ alert.attributes.max_estimate_value | num_format('currency') }}
      </p>
      <el-tooltip
        effect="dark"
        :content="$t('price_compression_help_text')"
        placement="top"
      >
        <rb-icon
          class="rb-icon--medium u-color-blue-base help-fill-size"
          :icon="'help-fill'"
        />
      </el-tooltip>
    </div>
    <p class="u-font-size-4 u-spacing-pt-s u-spacing-pb-s">
      {{ $t('lost_buy_box_rec_text') }}
    </p>
  </div>
</template>

<script>
import Vue from 'vue';
import chart from '@/components/basic/chart';
import starRating from '@/components/widgets/starRatings';
import keyValuePair from '@/components/basic/keyValuePair';

export default {
  components: {
    chart,
    starRating,
    keyValuePair
  },
  props: {
    alert: {
      type: Object,
      default: null
    },
    // eslint-disable-next-line vue/require-default-prop
    formatter: {
      type: Function
    }
  },
  data() {
    return {
      showChart: false,
      lostBuyBoxDesc: [],
      barChartConfig: {
        xAxisType: 'timeseries',
        load: false,
        chartOptions: {
          timeseries: {},
          axes: {
            // 'Price on amazon.com': 'y2',
            'Price on walmart.com': 'y2',
            'Buy Box Price': 'y2'
            // 'Price on amazon.co.uk': 'y2'
          },
          tooltip_format: {
            'Price on walmart.com': {
              roundoff: 2,
              pre: 'currency'
            },
            'Inventory on-hand': {
              roundoff: 0
            },
            'Lost buy box timeline': function (value, ratio, id, index) {
              return this.lostBuyBoxDesc[index];
            }.bind(this),
            'Buy Box Price': function (value, ratio, id, index) {
              return this.buyBoxPriceTrendDec[index];
            }.bind(this)
          },
          point_format: {
            'Lost buy box timeline': function (data) {
              if (this.lostBuyBoxDesc[data.index] !== 'No') {
                return 5;
              } else {
                return 2;
              }
            }.bind(this)
          }
        }
      },
      barChartData: [],
      showPerformanceOverview: Vue.options.filters.config_check(
        'feature.recommendations.performanceOverview.enable'
      ),
      buyBoxPriceTrendDec: []
    };
  },
  mounted() {
    var xs = {
      // 'Price on amazon.com': 'Price on amazon.com_x',
      'Price on walmart.com': 'Price on walmart.com_x',
      'Inventory on-hand': 'Inventory on-hand_x',
      'Lost buy box timeline': 'Lost buy box timeline_x'
    };
    var dataArray = [];
    var dateArray = [];
    var chartData = [];
    var entry;

    var offerPriceTrend = [];
    if (this.alert.attributes.offer_price_trend) {
      offerPriceTrend = JSON.parse(this.alert.attributes.offer_price_trend);
      if (offerPriceTrend.length) {
        offerPriceTrend = offerPriceTrend.filter((item) => {
          return item.retailer_site !== undefined;
        });
        dataArray = ['Price on ' + offerPriceTrend[0].retailer_site];
        dateArray = ['Price on ' + offerPriceTrend[0].retailer_site + '_x'];
        xs['Price on ' + offerPriceTrend[0].retailer_site] = [
          'Price on ' + offerPriceTrend[0].retailer_site + '_x'
        ];
        this.barChartConfig.chartOptions.axes[
          'Price on ' + offerPriceTrend[0].retailer_site
        ] = 'y2';
        this.barChartConfig.chartOptions.tooltip_format[
          'Price on ' + offerPriceTrend[0].retailer_site
        ] = {
          roundoff: 2,
          pre: 'currency'
        };
        for (entry of offerPriceTrend) {
          if (entry.offer_price === undefined) continue;
          dataArray.push(entry.offer_price);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
        xs[dataArray[0]] = dateArray[0];
      }
    }

    var offerPriceTrendWalmart = [];
    if (this.alert.attributes.walmart_price_trend) {
      offerPriceTrendWalmart = JSON.parse(
        this.alert.attributes.walmart_price_trend
      );
      if (offerPriceTrendWalmart.length) {
        offerPriceTrendWalmart = offerPriceTrendWalmart.filter((item) => {
          return item.retailer_site !== undefined;
        });
        if (offerPriceTrendWalmart.length > 0) {
          dataArray = ['Price on ' + offerPriceTrendWalmart[0].retailer_site];
          dateArray = [
            'Price on ' + offerPriceTrendWalmart[0].retailer_site + '_x'
          ];
          for (entry of offerPriceTrendWalmart) {
            if (entry.comp_offer_price === undefined) continue;
            dataArray.push(entry.comp_offer_price);
            dateArray.push(entry.Date);
          }
          chartData.push(dataArray, dateArray);
          xs[dataArray[0]] = dateArray[0];
        }
      }
    }

    var inventoryOnHandTrend = [];
    if (this.alert.attributes.inventory_on_hand_trend) {
      inventoryOnHandTrend = JSON.parse(
        this.alert.attributes.inventory_on_hand_trend
      );
      if (inventoryOnHandTrend.length) {
        dataArray = ['Inventory on-hand'];
        dateArray = ['Inventory on-hand_x'];
        for (entry of inventoryOnHandTrend) {
          if (entry.Inventory_on_hand === undefined) continue;
          dataArray.push(entry.Inventory_on_hand);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
        xs[dataArray[0]] = dateArray[0];
      }
    }

    var lostBuyBoxTrend = [];
    if (this.alert.attributes.lost_buybox_timeline) {
      lostBuyBoxTrend = JSON.parse(this.alert.attributes.lost_buybox_timeline);
      if (lostBuyBoxTrend.length) {
        lostBuyBoxTrend = lostBuyBoxTrend.sort((a, b) => {
          if (a.Date < b.Date) return -1;
          else if (a.Date > b.Date) return 1;
          return 0;
        });
        dataArray = ['Lost buy box timeline'];
        dateArray = ['Lost buy box timeline_x'];
        for (entry of lostBuyBoxTrend) {
          var flag = entry.Buybox_loss_flag;
          dataArray.push(1);
          dateArray.push(entry.Date);
          this.lostBuyBoxDesc.push(flag === 1 ? 'Yes' : 'No');
        }
        chartData.push(dataArray, dateArray);
        xs[dataArray[0]] = dateArray[0];
      }
    }

    var buyBoxPriceTrend = [];
    if (this.alert.attributes.buybox_price_trend) {
      buyBoxPriceTrend = JSON.parse(this.alert.attributes.buybox_price_trend);
      if (buyBoxPriceTrend.length) {
        buyBoxPriceTrend = buyBoxPriceTrend.sort((a, b) => {
          if (a.Date < b.Date) return -1;
          else if (a.Date > b.Date) return 1;
          return 0;
        });
        buyBoxPriceTrend = buyBoxPriceTrend.filter((item) => {
          return item.retailer_site !== undefined;
        });
        if (buyBoxPriceTrend.length > 0) {
          dataArray = ['Buy Box Price'];
          dateArray = ['Buy Box Price_x'];
          for (entry of buyBoxPriceTrend) {
            if (entry.buybox_price === undefined) continue;
            dataArray.push(entry.buybox_price);
            dateArray.push(entry.Date);
            const trendValue = `${this.formatter(
              entry.buybox_price,
              'currency'
            )}${
              entry.buybox_seller_name
                ? `, seller: ${entry.buybox_seller_name}`
                : ''
            } `;
            this.buyBoxPriceTrendDec.push(trendValue);
          }
          chartData.push(dataArray, dateArray);
          xs[dataArray[0]] = dateArray[0];
        }
      }
    }

    this.barChartConfig.chartOptions.timeseries = xs;
    this.barChartData = chartData;
    this.showChart = true;
  }
};
</script>

<style scoped lang="css">
.icon--task .rb-icon {
  height: 16px;
  width: 16px;
}
.icon--task .rb-icon::before {
  font-size: 16px;
}
</style>
