<template>
  <div
    class="u-display-flex u-flex-direction-column edit-date-cm-action u-spacing-p-l u-bg-color-white-base"
  >
    <div
      class="u-width-100 title u-font-size-5 u-spacing-pb-l u-font-weight-600"
    >
      ADJUST START/END DATE ({{ selections.length }} campaign{{
        selections.length > 1 ? 's' : ''
      }}
      selected)
      <div class="u-font-weight-normal u-spacing-pt-s">
        Extending the dates cannot unpause a campaign that is already paused.
      </div>
    </div>

    <div class="u-display-flex">
      <div
        v-click-outside="hideStartCalendar"
        class="u-spacing-pr-l u-display-flex u-flex-align-items-center"
      >
        <div class="u-font-size-5 u-spacing-pr-s u-font-weight-600">
          Start date:
        </div>
        <div>
          <span
            class="filter--token u-font-size-6 u-border-radius-xs u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
            @click="openCalendar('startDate')"
          >
            <rb-icon
              :icon="'calendar'"
              class="rb-icon--small u-spacing-mr-xs"
            />
            <span class="u-line-height-1_2">
              {{ startDate | dateFormatFilter }}</span
            >
          </span>
          <datepicker
            ref="startDate"
            v-model="startDate"
            style="z-index: 104"
            input-class="hide_calendar_strategy"
            :disabled-dates="disableStartDates"
          />
        </div>
      </div>
      <div
        v-click-outside="hideEndCalendar"
        class="u-display-flex u-flex-align-items-center"
      >
        <div class="u-font-size-5 u-font-weight-600 u-spacing-pr-s">
          End date:
        </div>
        <div>
          <span
            class="filter--token u-font-size-6 u-border-radius-xs u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
            @click="openCalendar('endDate')"
          >
            <rb-icon
              :icon="'calendar'"
              class="rb-icon--small u-spacing-mr-xs"
            />
            <span class="u-line-height-1_2">
              {{ endDate | dateFormatFilter }}</span
            >
          </span>
          <datepicker
            ref="endDate"
            v-model="endDate"
            input-class="hide_calendar_strategy"
            :disabled-dates="disableEndDates"
          />
        </div>
      </div>
    </div>
    <div class="u-spacing-pt-xl">
      <rb-button
        text="Cancel"
        :click-fn="onClose"
        size="s"
        class="u-spacing-mr-12"
      />
      <rb-button
        text="Apply"
        type="filled"
        :click-fn="onApply"
        size="s"
        :loading="loader"
        :disabled="false"
      />
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import moment from 'moment-timezone';
import { commons } from '@/components/ams/campaigns/commons.js';

export default {
  components: {
    Datepicker
  },
  filters: {
    dateFormatFilter: function (value) {
      if (!value) return 'No Date';
      return moment(value).format('MMM DD, YYYY');
    }
  },
  props: {
    selections: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      dateFormat: 'MM/DD/YYYY',
      loader: false,
      startDate: null,
      localDate: new Date(),
      endDate: null
    };
  },
  computed: {
    disableStartDates() {
      let result = {};
      const allDates = this.selections.map((item) =>
        moment(item?.data?.scheduled_start_at_date, this.dateFormat)
      );
      const maxDate = moment.max(allDates);
      const isDateInPast = maxDate && moment(maxDate).diff(moment());
      if (!maxDate || (isDateInPast && isDateInPast < 0)) {
        result = {
          from: moment().toDate(),
          to: moment().toDate()
        };
      } else if (maxDate && isDateInPast > 0) {
        result = {
          from: moment().toDate(),
          to: moment().toDate()
        };
      }
      return result;
    },
    disableEndDates() {
      return {
        to: moment().toDate()
      };
    }
  },
  watch: {
    selections: {
      immediate: true,
      handler: function (newVal) {
        if (newVal?.length === 1) {
          this.startDate = newVal[0]?.data?.scheduled_start_at_date
            ? moment(
                newVal[0]?.data?.scheduled_start_at_date,
                this.dateFormat
              ).toDate()
            : null;
          this.endDate = newVal[0]?.data?.scheduled_end_at_date
            ? moment(
                newVal[0]?.data?.scheduled_end_at_date,
                this.dateFormat
              ).toDate()
            : null;
        } else {
          this.startDate = null;
          this.endDate = null;
        }
      }
    }
  },
  methods: {
    generateAndValidatePayload(row) {
      let message = '';
      let invalid = false;
      const startDate = this.startDate
        ? moment(this.startDate).format(this.dateFormat)
        : null;
      const endDate = this.endDate
        ? moment(this.endDate).format(this.dateFormat)
        : null;
      if (
        startDate === row.scheduled_start_at_date &&
        endDate === row.scheduled_end_at_date
      ) {
        message = 'Please update either start date or end date';
        invalid = true;
      }
      if (moment(this.endDate).diff(this.startDate) < 0) {
        message = 'Start date cannot be ahead of end date.';
        invalid = true;
      }
      return {
        payload: {
          actionPayload: {
            profileId: 0,
            campaignId: row.campaign_id,
            campaignType: row.campaign_type,
            newStartDate:
              startDate === row.scheduled_start_at_date ? null : startDate,
            newEndDate: endDate === row.scheduled_end_at_date ? null : endDate,
            previousStartDate: row.scheduled_start_at_date,
            previousEndDate: row.scheduled_end_at_date
          },
          viewPayload: {
            name: row.campaign_name,
            newStartDate: startDate
              ? moment(startDate, this.dateFormat).format('ll')
              : null || row?.scheduled_start_at_date
              ? moment(row?.scheduled_start_at_date, this.dateFormat).format(
                  'll'
                )
              : null || 'NA',
            newEndDate: endDate
              ? moment(endDate, this.dateFormat).format('ll')
              : null || row?.scheduled_end_at_date
              ? moment(row?.scheduled_end_at_date, this.dateFormat).format('ll')
              : null || 'NA',
            previousStartDate: row?.scheduled_start_at_date
              ? moment(row?.scheduled_start_at_date, this.dateFormat).format(
                  'll'
                )
              : null || 'NA',
            previousEndDate: row?.scheduled_end_at_date
              ? moment(row?.scheduled_end_at_date, this.dateFormat).format('ll')
              : null || 'NA',
            campaignId: row.campaign_id,
            campaignType: row.campaign_type
          },
          actionType: 'instacartCampaignStartEndDateChange',
          actionSource: {
            pageUrl: window.location.href
          }
        },
        validation: {
          invalid,
          message
        }
      };
    },
    onApply() {
      const requestPayload = [];
      let invalid = false;
      for (const item of this.selections) {
        const { payload, validation } = this.generateAndValidatePayload(
          item.data
        );
        if (validation.invalid) {
          invalid = true;
          this.$snackbar.open({
            message: validation.message,
            duration: 6000,
            buttonColor: '#f5d908',
            actionText: ''
          });
          return;
        }
        requestPayload.push(payload);
      }
      if (invalid) return;
      var that = this;
      that.loader = true;
      const othersObj = {
        loader: that.loader,
        successMessage: 'Action has been submitted',
        errorMessage: 'Something went wrong.',
        successFn: () => {},
        errorFn: () => {},
        finallyFn: () => {
          that.loader = false;
          this.$emit('afterAPIActionToCall');
        }
      };
      commons.bidChangeApiAction.call(
        this,
        that.selections,
        requestPayload,
        othersObj
      );
    },
    onClose() {
      this.$emit('close');
    },
    hideStartCalendar() {
      this.$refs.startDate.close();
    },
    hideEndCalendar() {
      this.$refs.endDate.close();
    },
    openCalendar(param) {
      this.$refs[param] && this.$refs[param].showCalendar();
    }
  }
};
</script>

<style lang="css">
.edit-date-cm-action .vdp-datepicker__calendar {
  top: 1px;
  right: -195px;
}
</style>
