<template>
  <!-- Container -->
  <div
    class="u-color-grey-base u-position-relative u-bg-color-white-base u-width-100 u-display-flex u-flex-direction-column"
  >
    <!-- Loader -->
    <div
      v-if="isLoading"
      class="loaderContainer"
    >
      <loader
        class="fill--parent"
        :loading="isLoading"
        :color="'#007cf6'"
      />
    </div>

    <div class="u-spacing-pb-l u-spacing-ph-l u-spacing-pt-l">
      <!-- Heading -->
      <div
        v-if="config.headerText"
        class="title u-font-size-5 u-spacing-pb-l u-font-weight-600 u-text-case-upper"
      >
        {{ config.headerText ? config.headerText() : 'Date Management' }}
      </div>

      <!-- start/end date -->

      <div class="u-display-flex">
        <div class="u-spacing-pr-l u-display-flex u-flex-align-items-center">
          <div class="u-spacing-mr-s u-font-size-5 u-font-weight-600">
            {{ config.leftCalendarHeading || 'Start Date:' }}
          </div>
          <div v-click-outside="hideStartCalendar">
            <span
              v-tippy="{ placement: 'top', arrow: true }"
              class="filter--token u-font-size-6 u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
              :class="{
                'removePointerEvents disabled-grey-background-date':
                  config.leftCalendarDisabled ? true : false
              }"
              @click="openCalendar('startDate')"
            >
              <rb-icon
                icon="calendar"
                class="rb-icon--small u-spacing-mr-xs"
              />
              <span class="u-spacing-ph-xs u-line-height-1_2">
                {{
                  disableMultipleStartDates
                    ? 'Select'
                    : dateFormatFilter(entityDates.start, 'start')
                }}</span
              >
              <span
                v-if="this.timezone && !disableMultipleStartDates"
                class="u-line-height-1_2"
              >
                {{ this.timezone }}
              </span>
              <rb-icon
                v-if="!config.leftCalendarDisabled"
                class="rb-icon--small"
                icon="caret-down"
              />
            </span>
            <datepicker
              ref="startDate"
              v-model="entityDates.start"
              style="right: -200px"
              name="startDate"
              :disabled-dates="disabledStartDates"
            />
          </div>
        </div>

        <div class="u-display-flex u-flex-align-items-center">
          <div class="u-spacing-pr-s u-font-size-5 u-font-weight-600">
            {{ config.rightCalendarHeading || 'End Date:' }}
          </div>
          <div
            v-click-outside="hideEndCalendar"
            class="entity-details-settings-calendar"
          >
            <span
              class="filter--token u-font-size-6 u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
              @click="openCalendar('endDate')"
            >
              <rb-icon
                icon="calendar"
                class="rb-icon--small u-spacing-mr-xs"
              />
              <span class="u-spacing-ph-xs u-line-height-1_2">
                {{
                  multipleEndDates
                    ? 'Select'
                    : dateFormatFilter(entityDates.end, 'end')
                }}</span
              >
              <span
                v-if="this.timezone && !noEndDate"
                class="u-line-height-1_2"
              >
                {{ this.timezone }}
              </span>
              <rb-icon
                class="rb-icon--small"
                icon="caret-down"
              />
            </span>
            <datepicker
              ref="endDate"
              v-model="entityDates.end"
              style="right: -100px"
              name="endDate"
              :disabled-dates="disabledEndDates"
            >
              <div
                slot="beforeCalendarHeader"
                class="no-end-date u-border-bottom u-border-color-grey-xx-light u-border-width-s u-spacing-p-s u-cursor-pointer u-spacing-mb-s"
                :class="{
                  'u-color-white-base u-bg-color-orange-base': noEndDate
                }"
                data-cy="noEndDate"
                @click="selectNoEndDate"
              >
                No end date
              </div>
            </datepicker>
          </div>
        </div>
      </div>
    </div>

    <!-- Apply and Cancel buttons -->
    <div
      class="u-width-100 u-border-top u-border-width-s u-border-bottom u-border-color-grey-xxx-light u-spacing-pv-m u-spacing-ph-l u-display-flex"
    >
      <div
        v-if="isApplyButtonDisabled() || !selections.length"
        v-tippy="{ placement: 'top', arrow: true }"
        :title="isApplyButtonDisabled() ? tooltipErrorMessage : ''"
      >
        <rb-button
          :text="config.leftBtnLabel || 'Apply'"
          type="filled"
          :click-fn="onLeftBtnClick"
          :disabled="true"
          class="u-spacing-mr-12"
          size="s"
        />
      </div>
      <rb-button
        v-else
        :text="config.leftBtnLabel || 'Apply'"
        type="filled"
        :click-fn="onLeftBtnClick"
        :disabled="false"
        class="u-spacing-mr-12"
        size="s"
      />
      <rb-button
        :text="config.rightBtnLabel || 'Cancel'"
        :click-fn="onRightBtnClick"
        size="s"
      />
    </div>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
import moment from 'moment-timezone';
import Datepicker from 'vuejs-datepicker';
export default {
  components: {
    loader,
    Datepicker
  },
  props: {
    timezone: {
      type: String,
      default: null
    },
    widgetsConfig: {
      type: Function,
      default: () => {
        return () => ({});
      }
    },
    selections: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      isLoading: false,
      config: null,
      errorMessage: null,
      tooltipErrorMessage: '',
      noEndDate: true,
      disableMultipleStartDates: false,
      multipleEndDates: false,
      entityDates: {
        start: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        end: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      },
      disabledStartDates: {
        from: null,
        to: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      },
      disabledEndDates: {
        from: null,
        to: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      }
    };
  },
  computed: {
    defaultStartEndDate() {
      if (this.config?.defaultStartEndDate) {
        if (typeof this.config?.defaultStartEndDate === 'function') {
          return this.config?.defaultStartEndDate(this);
        }
        return this.config?.defaultStartEndDate;
      }
      return {
        start: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        end: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      };
    }
  },
  watch: {
    selections(newVal) {
      if (!newVal?.length) {
        this.$emit('closePanelMethod');
        return;
      }
      if (newVal.length > 1) {
        this.disableMultipleStartDates = true;
        this.multipleEndDates = true;
      } else {
        this.multipleEndDates = false;
        this.disableMultipleStartDates = false;
      }
      const defaultStartEndDate = this.defaultStartEndDate;
      this.entityDates = {
        start: defaultStartEndDate.start,
        end: defaultStartEndDate.end
      };
    },
    'entityDates.start'(newStartDate) {
      if (this.config?.onStartDateUpdated) {
        const { endDateToBeDisabledTill, endDateToBeDisabledFrom } =
          this.config?.onStartDateUpdated(newStartDate);
        if (endDateToBeDisabledTill) {
          this.disabledEndDates.to = endDateToBeDisabledTill;
        }
        if (endDateToBeDisabledFrom) {
          this.disabledEndDates.from = endDateToBeDisabledFrom;
        }
        return;
      }
      this.disabledEndDates.to = this.entityDates.start;
    },
    'entityDates.end'(newVal) {
      this.multipleEndDates = false;
      this.noEndDate = !newVal;
    }
  },
  async created() {
    this.initializeConfig();
    const defaultStartEndDate = this.defaultStartEndDate;
    this.entityDates = {
      start: defaultStartEndDate.start,
      end: defaultStartEndDate.end
    };
  },
  mounted() {
    if (this.selections.length > 1) {
      this.disableMultipleStartDates = true;
      this.multipleEndDates = true;
    }
  },
  methods: {
    initializeConfig() {
      this.config = this.widgetsConfig(this);
    },
    isApplyButtonDisabled() {
      if (this.config?.isApplyButtonDisabled) {
        return this.config?.isApplyButtonDisabled(this);
      }
      return false;
    },
    openCalendar(param) {
      if (param === 'startDate' && this.selections.length > 1) return;
      this.$refs[param] && this.$refs[param].showCalendar();
    },
    hideStartCalendar() {
      this.$refs?.startDate?.close();
    },
    hideEndCalendar() {
      this.noEndDate = !this.entityDates.end;
      this.$refs?.endDate?.close();
    },
    selectNoEndDate() {
      this.entityDates.end = null;
      this.hideEndCalendar();
    },
    dateFormatFilter: function (value, type) {
      if (
        new Date(this.entityDates.end) < new Date(this.entityDates.start) &&
        this.entityDates.end !== null
      ) {
        this.entityDates.end = this.entityDates.start;
      }
      if (value === null) return `No ${type} date`;
      return moment(value).format('MMM DD, YYYY');
    },
    onClose() {
      this.$emit('closePanelMethod');
    },
    async onLeftBtnClick() {
      try {
        this.isLoading = true;
        await this.config?.onLeftBtnClick?.(this.entityDates);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async onRightBtnClick() {
      try {
        this.isLoading = true;
        this.config?.onRightBtnClick?.();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
      this.$emit('closePanelMethod');
    },
    dateFormatter(val) {
      if (val === null) return null;
      return moment(val).format('MM/DD/YYYY');
    }
  }
};
</script>

<style lang="css">
.entity-details-settings-calendar .vdp-datepicker__calendar {
  padding: 0px;
}
.entity-details-settings-calendar .vdp-datepicker__calendar div {
  padding: 16px;
}
</style>
<style scoped lang="css">
.filter--token {
  border-radius: 2px;
}
.min-height-card {
  min-height: 630px;
}

.no-end-date:hover,
.no-end-date:focus {
  background: #ffa800;
}

.disabled-grey-background-date {
  background: #e9eaeb;
}
</style>
