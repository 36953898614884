<template>
  <form
    slot="trigger"
    class="standard-search-wrapper u-height-100"
    @submit.prevent="searchSubmit"
  >
    <div
      class="u-font-size-2"
      @click="searchSubmit"
    >
      <rb-icon
        icon="search"
        class="rb-icon--medium u-color-grey-lighter u-cursor-pointer"
      />
    </div>
    <rb-input
      v-model="searchText"
      :placeholder="searchConfig.placeholder || 'Search'"
      class="standard-search-input"
    />
  </form>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';

export default {
  props: {
    searchConfig: {
      type: Object,
      default: () => ({
        emit: '',
        placeholder: ''
      })
    }
  },
  data() {
    return {
      searchText: ''
    };
  },
  methods: {
    searchSubmit() {
      if (this.searchConfig?.emit) {
        eventBus.$emit(this.searchConfig.emit, this.searchText);
      }
    }
  }
};
</script>
<style lang="css">
.bulk-search-input .standard-search-wrapper {
  display: flex;
}
.bulk-search-input .standard-search-wrapper .standard-search-input .rb-input {
  background-color: transparent;
  border: none;
  height: 100%;
  padding: 0px 0px 0px 10px;
}
.bulk-search-input
  .standard-search-wrapper
  .standard-search-input
  .rb-input:focus {
  box-shadow: none;
}
.bulk-search-input
  .standard-search-wrapper
  .standard-search-input
  .rb-input::placeholder {
  color: #8b8f93;
}
</style>
