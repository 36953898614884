<template>
  <div
    class="u-display-inline-flex u-height-100 u-flex-justify-content-flex-end u-width-100 u-flex-align-items-center reverse-content"
  >
    <div
      class="budget-planner-custom-header-text u-flex-shrink-past-min-size u-flex-1 u-font-weight-600"
    >
      <div
        style="font-size: 14px"
        class="budget-planner-custom-text-label u-color-grey-lighter reverse-content"
        :class="name !== `Scope` ? `u-text-align-center` : ``"
      >
        {{ name
        }}<span
          v-if="name !== `Scope`"
          style="font-size: 11px"
          ><br />Spend / Budget</span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    params: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    name() {
      console.log('this.params', this.params);
      return this.params?.displayName || 'NA';
    }
  }
};
</script>
