import Vue from 'vue';
// import HttpLayer from '@/utils/services/http-layer'
// import transformer from '@/utils/services/data-transformer'

// const tempRes = [
//   {
//     'primaryKey': 'clientId_301|profId_234|campId_1234',

//     'actionType': 'campaignBudgetChange',
//     'actionStatus': 'successful',
//     'timeStamp': '2019-01-15T10:52:59Z',
//     'username': 'nikhil@boomerangcommerce.com',
//     'viewPayload': {
//       'name': 'abcd',
//       'currentValue': 230.0,
//       'newValue': 245.0,
//       'actionString': 'adjusted the bid from '
//     },
//     'actionId': 12345,
//     'actionSource': {
//       'pageUrl': 'URL value',
//       'page': 'insights',
//       'widget': 'campaigns',
//       'section': null,
//       'subSection': null
//     }
//   },
//   {
//     'primaryKey': 'ins_camapaign_136486780641305',
//     'actionType': 'campaignBudgetChange',
//     'actionStatus': 'successful',
//     'timeStamp': '2019-02-09T10:52:59Z',
//     'username': 'nikhil@boomerangcommerce.com',
//     'viewPayload': {
//       'name': 'abcd',
//       'currentBudget': 230.0,
//       'newBudget': 256.9,
//       'actionString': 'adjusted the bid from '
//     },
//     'actionId': 12344,
//     'actionSource': {
//       'pageUrl': 'URL value',
//       'page': 'insights',
//       'widget': 'campaigns',
//       'section': null,
//       'subSection': null
//     }
//   },
//   {
//     'primaryKey': 'ins_camapaign_136486780641305',
//     'actionType': 'campaignBudgetChange',
//     'actionStatus': 'successful',
//     'timeStamp': '2019-04-10T10:52:59Z',
//     'username': 'nikhil@boomerangcommerce.com',
//     'viewPayload': {
//       'name': 'abcd',
//       'currentBudget': 230.0,
//       'newBudget': 256.9,
//       'actionString': 'adjusted the bid from '
//     },
//     'actionId': 12344,
//     'actionSource': {
//       'pageUrl': 'URL value',
//       'page': 'insights',
//       'widget': 'campaigns',
//       'section': null,
//       'subSection': null
//     }
//   }
// ]
const state = {
  activitylogData: {}
};

const getters = {
  getActivityLog: (state) => {
    return state.activitylogData;
  }
};

const mutations = {
  ACTIVITY_LOG_SUCCESS: (state, data) => {
    Vue.set(state.activitylogData, data.key, {});
    if (data.xs || data.legends) {
      Vue.set(state.activitylogData[data.key], 'data', {
        data: data.columns,
        xs: data.xs,
        legends: data.legends
      });
    } else {
      Vue.set(state.activitylogData[data.key], 'data', data.columns);
    }
    Vue.set(state.activitylogData[data.key], 'rows', data.rows);
    Vue.set(state.activitylogData[data.key], 'totalRows', data.totalRows);
    Vue.set(state.activitylogData[data.key], 'metrics', data.metrics);
    Vue.set(state.activitylogData[data.key], 'page', data.page);
    Vue.set(state.activitylogData[data.key], 'load', false);
    Vue.set(state.activitylogData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.activitylogData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.activitylogData[data.key], 'noData', true);
    }
  },
  ACTIVITY_LOG_RESET: (state, data) => {
    Vue.set(state.activitylogData, data.key, {});
    Vue.set(state.activitylogData[data.key], 'columns', []);
    Vue.set(state.activitylogData[data.key], 'rows', []);
    Vue.set(state.activitylogData[data.key], 'load', true);
    Vue.set(state.activitylogData[data.key], 'error', false);
    Vue.set(state.activitylogData[data.key], 'noData', false);
  },
  ACTIVITY_LOG_ERROR: (state, data) => {
    Vue.set(state.activitylogData, data.key, {});
    Vue.set(state.activitylogData[data.key], 'columns', []);
    Vue.set(state.activitylogData[data.key], 'rows', []);
    Vue.set(state.activitylogData[data.key], 'load', false);
    Vue.set(state.activitylogData[data.key], 'error', true);
    Vue.set(state.activitylogData[data.key], 'noData', false);
  }
};

export default {
  state,
  getters,
  mutations
};
