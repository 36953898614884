<template>
  <div>
    <div class="u-display-flex u-flex-align-items-center">
      <rb-icon
        :icon="'error-fill'"
        class="u-color-red-base rb-icon--small u-cursor-pointer u-spacing-mr-xs"
      />
      <span class="u-font-size-6 u-color-red-base">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    errorMessage: {
      type: String
    }
  }
};
</script>
