<template>
  <div
    class="u-bg-color-grey-white shadows u-border-radius-s widget-1 u-spacing-mt-m u-spacing-p-m"
  >
    <div
      class="u-display-flex u-flex-justify-content-space-between u-spacing-mt-m u-spacing-mb-m"
    >
      <div class="u-display-flex">
        <rb-icon
          icon="spotlight"
          class="rb-icon--large"
        />
        <p
          class="u-font-size-2 u-font-weight-600 u-color-grey-lighter u-text-case-upper u-spacing-pt-s u-spacing-pl-s"
        >
          SKU Breakdown
        </p>
        <div class="u-spacing-ml-xxl u-spacing-mt-xs">
          <rb-search
            v-model="skuSearchText"
            placeholder="Search for SKUs"
            input-classes="search-input"
            @onSearchTrigger="onSkuSearch"
          />
        </div>
      </div>
      <div class="u-display-flex">
        <div
          class="u-display-flex u-flex-justify-content-center u-flex-align-items-center"
          @click="sendEmail"
        >
          <rb-icon
            icon="email"
            class="u-color-blue-base"
          />
          <p class="link u-font-size-7 u-spacing-pl-xs u-spacing-pt-xxs">
            Email
          </p>
        </div>
        <div
          class="u-display-flex u-flex-justify-content-center u-flex-align-items-center u-spacing-ml-m"
        >
          <rb-icon
            icon="download"
            class="u-color-blue-base"
          />
          <p class="link u-font-size-7 u-spacing-pl-xs u-spacing-pt-xxs">
            Download
          </p>
        </div>
      </div>
    </div>
    <div class="u-display-flex u-flex-align-items-center">
      <label class="u-font-size-4 u-font-weight-600">Select a Retailer:</label>
      <rb-select
        :on-close="onRetailerSelect"
        :options="retailerOptions"
        :send-details="true"
        class="retailer-dropdown"
      >
        <div
          slot="trigger"
          class="u-display-flex"
        >
          <div
            v-tippy="{ placement: 'top' }"
            :title="metricTitleTooltip"
            class="u-font-size-4 u-text-overflow-ellipsis u-color-grey-mid-light u-font-weight-600 u-cursor-pointer"
            @mouseenter="metricLabelHover"
          >
            {{ selectedRetailer && selectedRetailer.label }}
          </div>
          <rb-icon
            class="u-display-flex u-flex-justify-content-flex-end u-color-grey-lighter rb-icon--small u-spacing-ml-xs u-color-grey-base"
            icon="caret-down"
          />
        </div>
        <div
          slot="item"
          slot-scope="option"
        >
          <span>{{ option.label }}</span>
        </div>
      </rb-select>
    </div>
    <rb-insights-table
      id="imap-retailer-breakdown"
      :table-column="skuBreakdownColDef"
      :table-row="filteredSkuBreakdownTableData"
      :grid-options="skuBreakdownGridOptions"
      :enable-client-side-sorting="true"
      :pagination="false"
      sorting-change-event="categoryPerformanceSortChangeEvent"
      class="rb-insights-table-scrollable"
      :row-selection="rowSelection"
      :has-overflow="true"
      :row-height="90"
    />
    <email
      :show-email-modal="showEmail"
      :email-details="emailDetails"
      @onSendEamil="raiseTicket"
      @onCancelEmail="cancelRaiseTicket"
    >
      <template slot="body">
        <div class="u-font-size-4 u-spacing-mt-l">
          <p>
            We identified the following SKUs under IMAP Violation. Please see
            the details below and address the IMAP violations
          </p>
          <rb-insights-table
            id="imap-send-email"
            :table-column="skuBreakdownColDef"
            :table-row="selectedSkus"
            :grid-options="skuBreakdownGridOptions"
            :pagination="false"
            class="rb-insights-table-scrollable u-spacing-mt-l"
            :has-overflow="true"
            :row-height="90"
          />
        </div>
        <p class="u-font-size-4 u-spacing-mt-l">
          Kindly provide the updated status of the open items/ in-progress
          items.
        </p>
      </template>
    </email>
  </div>
</template>

<script>
import Vue from 'vue';
import imapStatus from '@/pages/all_retailers/dso/imap/imapStatus.vue';
import { IMAP_SKU_DATA } from '@/pages/all_retailers/dso/constant';
import rbSearch from '@/components/widgets/search.vue';
import email from '@/pages/all_retailers/dso/molecules/email.vue';

export default {
  name: 'SkuBreakdown',
  components: {
    rbSearch,
    email
  },
  data() {
    return {
      showEmail: false,
      skuBreakdownTableData: IMAP_SKU_DATA,
      filteredSkuBreakdownTableData: IMAP_SKU_DATA,
      skuBreakdownColDef: [
        {
          headerName: 'SKUs',
          key: 'sku',
          type: 'string',
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            keyType: 'string',
            toolTipText: ''
          },
          cellRendererFramework: 'DynamicCellComponent',
          cellRendererParams: {
            eventMap: {},
            cellAlignment: 'u-text-align-center',
            component: 'RbSku',
            paramsToProps: (params) => {
              return {
                ...params.data.skuDetails
              };
            }
          },
          pinned: 'left'
        },
        {
          headerName: 'Map Price',
          field: 'mapPrice',
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            keyType: 'string',
            toolTipText: ''
          }
        },
        {
          headerName: 'Current selling price',
          field: 'currentSellingPrice',
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            keyType: 'string',
            toolTipText: ''
          }
        },
        {
          headerName: 'First Mover',
          field: 'firstMover',
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            keyType: 'string',
            toolTipText: ''
          }
        },
        {
          headerName: 'Followed by',
          field: 'followedBy',
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            keyType: 'string',
            toolTipText: ''
          }
        },
        {
          headerName: 'Date of violation',
          field: 'dateOfViolation',
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            keyType: 'string',
            toolTipText: ''
          }
        },
        {
          headerName: 'Days of violation',
          field: 'daysOfViolation',
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            keyType: 'string',
            toolTipText: ''
          }
        },
        {
          headerName: 'Status',
          field: 'status',
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            keyType: 'string',
            toolTipText: ''
          },
          cellRendererFramework: 'DynamicCellComponent',
          cellRendererParams: {
            eventMap: {},
            cellAlignment: 'u-text-align-center',
            component: 'ImapStatus',
            paramsToProps: (params) => {
              return {
                status: params.data.status
              };
            }
          }
        }
      ],
      skuBreakdownGridOptions: {
        suppressColumnVirtualisation: true,
        context: {
          componentParent: this
        }
      },
      skuSearchText: '',
      rowSelection: {
        mode: 'multiple',
        onSelectionChanged: this.onSkuSelection
      },
      retailerOptions: [
        { label: 'Amazon', value: 'amazon' },
        { label: 'Walmart', value: 'walmart' },
        { label: 'Target', value: 'target' }
      ],
      selectedRetailer: { label: 'Amazon', value: 'amazon' },
      metricTitleTooltip: '',
      selectedSkus: [],
      emailDetails: {}
    };
  },
  created() {
    Vue.component('ImapStatus', imapStatus);
  },
  methods: {
    onSkuSearch() {
      console.log('On sku search');
    },
    onRetailerSelect(context, selectedOption) {
      this.selectedRetailer = selectedOption[0];
      const retailer = this.selectedRetailer.value;
      this.filteredSkuBreakdownTableData = this.skuBreakdownTableData.filter(
        (sku) => sku.retailer === retailer
      );
    },
    metricLabelHover(e) {
      const metricEl = e?.target;
      if (metricEl?.offsetWidth < metricEl?.scrollWidth) {
        this.metricTitleTooltip = metricEl.innerText;
      } else {
        this.metricTitleTooltip = null;
      }
    },
    onSkuSelection(selectedRows) {
      this.selectedSkus = selectedRows.map((row) => row.data);
    },
    raiseTicket() {
      this.showEmail = false;
      this.notify();
    },
    cancelRaiseTicket() {
      this.showEmail = false;
    },
    notify() {
      this.$snackbar.open({
        message: 'Ticket Raised',
        duration: 5000,
        buttonColor: '#007CF6',
        actionText: '',
        customStyles: 'background: #007cf6'
      });
    },
    sendEmail() {
      this.emailDetails = {
        to: 'vendor.manager@amazon.co',
        from: 'client@commerceiq.ai',
        body: ''
      };
      this.showEmail = true;
    }
  }
};
</script>

<style scoped lang="css">
.retailer-dropdown {
  background: #ffffff;
  border: 1px solid #e9eaeb;
  border-radius: 4px;
  padding: 0.8rem;
  margin: 1rem;
}
th {
  font-size: 1.2rem;
}
</style>
