<template>
  <tippy
    :to="toElementName"
    v-bind="tippyProps"
  >
    <div class="u-font-weight-400 u-font-size-5">
      <div class="u-spacing-mb-s u-font-weight-600">Guidelines:</div>
      <div>1. Format: png</div>
      <div>2. Size less than 200kb</div>
      <div>3. Dimensions equal to 300px * 180px</div>
    </div>
  </tippy>
</template>

<script>
export default {
  props: {
    toElementName: {
      type: String,
      default: 'infoTooltip'
    },
    tippyProps: {
      type: Object,
      default: () => {
        return {
          trigger: 'mouseenter',
          distance: 0,
          interactive: true,
          reactive: true,
          'hide-on-click': true,
          duration: 0,
          placement: 'top-start'
        };
      }
    }
  }
};
</script>
