import { metricCellDisplay } from '@/components/pages/insights/amazon/market-share/molecules/customCell.js';
import { overviewSortMetrics } from '@/components/pages/insights/amazon/market-share/utils';

export default class CategoryBreakdown {
  getColumnDefs() {
    let columns = [
      {
        headerName: `<span class='u-color-grey-lighter u-font-size-5 u-font-weight-600'>Category > Sub-category</span>`,
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'string'
        },
        cellRendererFramework: 'DynamicCellComponent',
        cellRendererParams: {
          component: 'parentChild',
          paramsToProps: (params) => {
            return {
              params,
              expandButtonClickFunction: (isExpanded) => {
                params.context?.componentParent?.handleCategoryExpandButton(
                  params,
                  isExpanded
                );
              }
            };
          }
        },
        pinned: 'left',
        minWidth: 300
      },
      {
        headerName: 'Market Share',
        field: 'marketShareMetric',
        headerComponentFramework: 'genericTableHeader',
        headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'number',
          toolTipText: `Market Share of your brands in the selected category in the selected time period`,
          tooltipCustomTitle: 'Market Share'
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'marketShareMetric',
            data2: 'PVP_DIFF_marketShareMetric',
            data1_type: 'marketShareMetric_absoluteType',
            invertTag: 'marketShareMetric_invertTag',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data2_type: 'PERCENTAGE'
        },
        minWidth: 150
      },
      {
        headerName: 'Category Size',
        field: 'categorySize',
        headerComponentFramework: 'genericTableHeader',
        headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'number',
          toolTipText:
            'The sum of estimated sales of all SKUs of all brands in the selected category in the selected time period',
          tooltipCustomTitle: 'Category Size'
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'categorySize',
            data2: 'PVP_categorySize',
            data1_type: 'categorySize_absoluteType',
            invertTag: 'categorySize_invertTag',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data2_type: 'PERCENTAGE'
        },
        minWidth: 150
      },
      {
        field: 'sales',
        headerComponentFramework: 'genericTableHeader',
        headerName: 'Sales',
        title: 'Sales',
        headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'number',
          toolTipText:
            'The sum of estimated sales of all SKUs of your brand in the selected category in the selected time period',
          tooltipCustomTitle: 'Sales'
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'sales',
            data2: 'PVP_sales',
            data1_type: 'sales_absoluteType',
            invertTag: 'sales_invertTag',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data2_type: 'PERCENTAGE'
        },
        minWidth: 150
      },
      {
        headerName: 'Your SKU count',
        field: 'your_skus',
        headerComponentFramework: 'genericTableHeader',
        headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'number',
          toolTipText: 'Total number of your SKUs that are part of the category'
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'your_skus',
            data2: 'PVP_your_skus',
            data1_type: 'your_skus_absoluteType',
            invertTag: 'your_skus_invertTag',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data2_type: 'PERCENTAGE'
        },
        minWidth: 150
      },
      {
        headerName: 'Total SKUs',
        field: 'total_skus',
        headerComponentFramework: 'genericTableHeader',
        headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'number',
          toolTipText: 'Total number of SKUs that are part of the category'
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'total_skus',
            data2: 'PVP_total_skus',
            data1_type: 'total_skus_absoluteType',
            invertTag: 'total_skus_invertTag',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data2_type: 'PERCENTAGE'
        },
        minWidth: 150
      },
      {
        headerName: 'Glance Views',
        field: 'glance_views',
        headerComponentFramework: 'genericTableHeader',
        headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'number',
          toolTipText:
            'Sum total of glance views received in the selected time period'
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'glance_views',
            data2: 'PVP_glance_views',
            data1_type: 'glance_views_absoluteType',
            invertTag: 'glance_views_invertTag',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data2_type: 'PERCENTAGE'
        },
        minWidth: 150
      },
      {
        headerName: 'Unit Conversion',
        field: 'unit_conversion',
        headerComponentFramework: 'genericTableHeader',
        headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'number',
          toolTipText:
            'The ratio of units sold and glance views expressed as a percentage'
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'unit_conversion',
            data2: 'PVP_DIFF_unit_conversion',
            data1_type: 'unit_conversion_absoluteType',
            invertTag: 'unit_conversion_invertTag',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data2_type: 'PERCENTAGE'
        },
        minWidth: 150
      },
      {
        headerName: 'Unavailability %',
        field: 'unavilability',
        headerComponentFramework: 'genericTableHeader',
        headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'number',
          toolTipText: `Percentage of glance views when your ASIN(s) were not available for sale. Unavailability % uses Rep OOS values from AVC and data from CommerceIQ's Market Intelligence to correctly predict the percentage of glance views when an ASIN(s) was not available for sale`
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'unavilability',
            data2: 'PVP_DIFF_unavilability',
            data1_type: 'unavilability_absoluteType',
            invertTag: 'unavilability_invertTag',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data2_type: 'PERCENTAGE'
        },
        minWidth: 150
      },
      {
        headerName: 'Lost Buy Box %',
        field: 'lbb',
        headerComponentFramework: 'genericTableHeader',
        headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'number',
          toolTipText:
            'Lost buy box for all your SKUs that are part of the category for the selected period. LBB% is the percentage glance views where the ASIN had lost the buy box. LBB across the category is calculated as the percentage of total LBB glance views across all ASINs in the category out of the total glance views in the category'
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'lbb',
            data2: 'PVP_DIFF_lbb',
            data1_type: 'lbb_absoluteType',
            invertTag: 'lbb_invertTag',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data2_type: 'PERCENTAGE'
        },
        minWidth: 150
      },
      {
        headerName: 'PO Fill Rate',
        field: 'po_fill_rate',
        headerComponentFramework: 'genericTableHeader',
        headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'number',
          toolTipText:
            'The ratio of units confirmed and units ordered for your SKUs in the selected date range'
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'po_fill_rate',
            data2: 'PVP_DIFF_po_fill_rate',
            data1_type: 'po_fill_rate_absoluteType',
            invertTag: 'po_fill_rate_invertTag',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data2_type: 'PERCENTAGE'
        },
        minWidth: 150
      },
      {
        headerName: 'Revenue Lost due to Unavailability',
        field: 'revenue_lost_unavailability',
        headerComponentFramework: 'genericTableHeader',
        headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'number',
          toolTipText:
            'Ordered Product Sales ($) lost in the selected time period due to ASINs not being available for sale. Revenue loss is determined by triangulating Rep OOS, Glance view, Conversion rate and Average Selling Price from AVC.'
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'revenue_lost_unavailability',
            data2: 'PVP_revenue_lost_unavailability',
            data1_type: 'revenue_lost_unavailability_absoluteType',
            invertTag: 'revenue_lost_unavailability_invertTag',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data2_type: 'PERCENTAGE'
        },
        minWidth: 150
      },
      {
        headerName: 'Revenue Lost due to LBB',
        field: 'revenue_lost_lbb',
        headerComponentFramework: 'genericTableHeader',
        headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'number',
          toolTipText:
            'Ordered Product Sales ($) lost in the selected time period due to ASINs having lost buy box. Revenue loss is determined by triangulating LBB%, Glance view, Conversion rate and Average Selling Price from AVC.'
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'revenue_lost_lbb',
            data2: 'PVP_revenue_lost_lbb',
            data1_type: 'revenue_lost_lbb_absoluteType',
            invertTag: 'revenue_lost_lbb_invertTag',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data2_type: 'PERCENTAGE'
        },
        minWidth: 150
      },
      {
        headerName: 'Ad Spend',
        field: 'ad_spend',
        headerComponentFramework: 'genericTableHeader',
        headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
        headerComponentParams: {
          enableSorting: false,
          keyType: 'number',
          toolTipText: 'Amount Spent on Ads'
        },
        cellRendererFramework: metricCellDisplay,
        cellRendererParams: {
          cellAlignment: 'u-text-align-right',
          renderParams: {
            data1: 'ad_spend',
            data2: 'PVP_ad_spend',
            data1_type: 'ad_spend_absoluteType',
            invertTag: 'ad_spend_invertTag',
            comparisonTooltip:
              '"Change value" is unavailable for this time period'
          },
          data2_type: 'PERCENTAGE'
        },
        minWidth: 150
      }
    ];
    return [...columns];
  }

  getTopBrandsColumnDef() {
    let topBrandsColumnDef = {
      headerName: 'My Top Brands',
      field: 'topBrands',
      key: 'topBrands',
      type: 'string',
      minWidth: 250,
      headerComponentFramework: 'genericTableHeader',
      headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
      headerComponentParams: {
        toolTipText: 'Your top brands present in the category'
      },
      cellRendererFramework: 'DynamicCellComponent',
      cellRendererParams: {
        eventMap: {},
        cellAlignment: 'u-text-align-right',
        component: 'BrandRankCell',
        paramsToProps: (params) => {
          if (params.data.myBrandsData) {
            let brandLogo = [2, 1, 3];
            let brandImageSizes = [5.5, 4.5, 4.5];
            let baseCDNUrl = 'https://cdn.rboomerang.com/images/brands/';
            let imgData = brandLogo
              .filter((img) => {
                return params.data.myBrandsData[img] !== undefined;
              })
              .map((imgEle) => {
                let brandName = params.data.myBrandsData[imgEle].name
                  .toLowerCase()
                  ?.trim('')
                  .replaceAll(' ', '_');
                let clientFlag =
                  brandName + '_' + params.data.myBrandsData[imgEle].clientFlag;
                let brandUrl = baseCDNUrl + brandName + '.jpeg';
                return {
                  imgUrl: brandUrl,
                  brandRank: imgEle,
                  brandName: brandName,
                  tag1: params.data.myBrandsData[imgEle].marketShare,
                  tag2: params.data.myBrandsData[imgEle].pvpMarketShare,
                  clientFlag
                };
              });
            return {
              data: imgData,
              loading: params.data.myBrandsLoader,
              apiFailed: params.data.myBrandsApiFailed,
              metricName: 'Market Share',
              brandImageSizes: brandImageSizes,
              showTooltipWithBrandData: true,
              showCompetitors: false,
              showCrownForTopBrand: true
            };
          } else {
            return {
              data: [],
              loading: params.data.myBrandsLoader,
              apiFailed: params.data.myBrandsApiFailed,
              metricName: 'Market Share',
              showTooltipWithBrandData: true,
              showCompetitors: false,
              showCrownForTopBrand: true
            };
          }
        }
      }
    };
    return topBrandsColumnDef;
  }

  getTopCompetitorBrandsColumnDef() {
    let topBrandsColumnDef = {
      headerName: 'Top Competitor Brands',
      field: 'competitorBrands',
      key: 'competitorBrands',
      type: 'string',
      minWidth: 250,
      headerComponentFramework: 'genericTableHeader',
      headerClass: 'u-color-grey-lighter u-font-size-5 u-font-weight-600',
      headerComponentParams: {
        toolTipText: 'Top competitor brands present in the category'
      },
      cellRendererFramework: 'DynamicCellComponent',
      cellRendererParams: {
        eventMap: {},
        cellAlignment: 'u-text-align-right',
        component: 'BrandRankCell',
        paramsToProps: (params) => {
          if (params.data.compBrandsData) {
            let brandLogo = [2, 1, 3];
            let brandImageSizes = [5.5, 4.5, 4.5];
            let baseCDNUrl = 'https://cdn.rboomerang.com/images/brands/';
            let imgData = brandLogo
              .filter((img) => {
                return params.data.compBrandsData[img] !== undefined;
              })
              .map((imgEle) => {
                let brandName = params.data.compBrandsData[imgEle].name
                  .toLowerCase()
                  ?.trim('')
                  .replaceAll(' ', '_');
                let clientFlag =
                  brandName +
                  '_' +
                  params.data.compBrandsData[imgEle].clientFlag;
                let brandUrl = baseCDNUrl + brandName + '.jpeg';
                return {
                  imgUrl: brandUrl,
                  brandRank: imgEle,
                  brandName: brandName,
                  tag1: params.data.compBrandsData[imgEle].marketShare,
                  tag2: params.data.compBrandsData[imgEle].pvpMarketShare,
                  clientFlag
                };
              });
            return {
              data: imgData,
              loading: params.data.competitorBrandsLoader,
              apiFailed: params.data.competitorBrandsApiFailed,
              metricName: 'Market Share',
              brandImageSizes: brandImageSizes,
              showTooltipWithBrandData: true,
              showCompetitors: false,
              showCrownForTopBrand: true
            };
          } else {
            return {
              data: [],
              loading: params.data.competitorBrandsLoader,
              apiFailed: params.data.competitorBrandsApiFailed,
              metricName: 'Market Share',
              showTooltipWithBrandData: true,
              showCompetitors: false,
              showCrownForTopBrand: true
            };
          }
        }
      }
    };
    return topBrandsColumnDef;
  }

  updateColDefs(metricDataForPayload, sortConfiguration) {
    let columnDefs = this.getColumnDefs();

    for (let columnId = columnDefs.length - 1; columnId >= 1; columnId--) {
      const metricName = columnDefs[columnId].field;
      if (!metricDataForPayload.hasOwnProperty(metricName)) {
        columnDefs.splice(columnId, 1);
      }
      if (overviewSortMetrics[sortConfiguration.metric] === metricName) {
        const columnName = columnDefs[columnId].headerName;
        columnDefs[columnId].headerName = this.generateCustomHeader(
          sortConfiguration.sortingOptions.icons,
          columnName
        );
        columnDefs[columnId].minWidth = 164;
      }
    }
    columnDefs = columnDefs.concat(
      this.getTopBrandsColumnDef(),
      this.getTopCompetitorBrandsColumnDef()
    );
    return columnDefs;
  }

  generateCustomHeader(icons, columnName) {
    let spacing = 'u-spacing-mr-m';
    if (columnName === 'Sales') {
      spacing = 'u-spacing-mr-xxxl';
    }
    return `<div class="u-flex-direction-row u-display-flex u-flex-justify-content-space-between">
    <span class="u-display-flex u-flex-direction-row ${spacing}"> 
            ${icons
              .map(
                (icon) =>
                  `<span class="icon-${icon} u-color-blue-base rb-icon--small"></span>`
              )
              .join('')}
          </span>
          <span class="u-font-size-5">${columnName}</span></div>`;
  }
}
