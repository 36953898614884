<template>
  <div>
    <div
      class="u-display-flex u-flex-direction-column u-position-relative u-flex-justify-content-flex-start u-spacing-mt-m u-spacing-p-l u-width-100 card"
    >
      <div class="u-display-flex u-flex-direction-column">
        <span class="u-font-size-3 u-font-weight-bold u-color-blue-base">{{
          sectionTitle
        }}</span>
        <span class="u-font-size-6 u-color-grey-lighter u-spacing-mt-s"
          >Finalise your {{ sectionTitle.toLowerCase() }} list.</span
        >
      </div>
      <!-- <div class="u-display-flex u-flex-direction-column u-spacing-mt-l">
            <span class="u-font-size-5 u-font-weight-600">Type</span>
            <span class="u-font-size-6 u-color-grey-lighter u-spacing-mt-s">Choose the type of target list.</span>
            <div class="u-display-flex u-spacing-mt-m">
                <boomerang-single-select :selects="(step || {}).typeSelect" :selectWidth="50" v-on:change="typeSelectChange">
                </boomerang-single-select>
            </div>
        </div> -->
      <div class="u-display-flex u-flex-direction-column u-spacing-mt-l">
        <!-- <span class="u-font-size-5 u-font-weight-600">Target list and black list</span>
            <span class="u-font-size-6 u-color-grey-lighter u-spacing-mt-s">Choose the final target and black lists.</span> -->
        <div class="u-display-flex">
          <short-list-table
            :emit="'change'"
            :display_key_main="shortListTableData.displayKeyMain"
            :display_key_filter="shortListTableData.displayKeyFilter"
            :stage="stage"
            :main-table="shortListTableData.mainTable"
            :filter-table="shortListTableData.filterTable"
            :primary_key="shortListTableData.primaryKey"
            @change="targetListUpdated"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import boomerangSingleSelect from '@/components/widgets/select/singleSelect.vue/';
import shortListTable from '@/components/widgets/shortListTable.vue/';
import { isLineItemRetailer } from '@/components/pages/strategies/create/utils.js';
export default {
  components: {
    boomerangSingleSelect,
    shortListTable
  },
  props: {
    step: {
      type: Object,
      default: () => {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    },
    allStepsData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      entitySelects: [],
      entitySelected: {},
      filtersResultArray: [],
      targetList: [],
      stage: {
        load: true
      }
    };
  },
  computed: {
    shortListTableData() {
      const isLineItem = isLineItemRetailer(this?.$store?.getters?.getRetailer);
      const filtersResult =
        this.allStepsData[this.step.indexMapping.rule].data.filtersResult || [];
      const targetList =
        this.allStepsData[this.step.indexMapping.rule].data.targetList || [];
      return {
        mainTable: filtersResult,
        filterTable: targetList,
        displayKeyMain: isLineItem ? 'line_item_name' : 'campaign_name',
        displayKeyFilter: isLineItem ? 'line_item_name' : 'campaign_name',
        primaryKey: isLineItem ? 'line_item_id' : 'campaign_id'
      };
    },

    sectionTitle() {
      return isLineItemRetailer(this?.$store?.getters?.getRetailer)
        ? 'Line Items'
        : 'Target';
    }
  },
  watch: {
    allStepsData(val) {
      this.targetList = val[this.step.indexMapping.rule].data.targetList;
      this.stage.load = val[this.step.indexMapping.rule].data.load;
      if (this.stage.load === undefined) {
        this.stage.load = true;
      }
      if (this.targetList.length > 0) {
        this.nextStepStatus(false);
      } else {
        this.nextStepStatus(true);
      }
    }
  },
  created() {
    this.stage.load = this.allStepsData[this.step.indexMapping.rule].data.load;
    if (this.stage.load === undefined) {
      this.stage.load = true;
    }
    this.stepValidate();
  },
  mounted() {
    this.targetList =
      this.allStepsData[this.step.indexMapping.rule].data.targetList || [];
    this.stepValidate();
  },
  methods: {
    typeSelectChange(val) {
      this.typeSelected = val;
      //   this.sendRequestFilterResult();
    },
    nextStepStatus(value) {
      this.$emit('nextStep', value, this.index);
    },
    formatStep1Data() {
      const obj = {
        targetList: this.targetList,
        type: this.typeSelected
      };
      return obj;
    },
    stepDataOut() {
      const obj = this.formatStep1Data();
      obj.index = this.index;
      this.$emit('stepDataOut', obj);
    },
    targetListUpdated(data) {
      this.targetList = data.filterTable;
      this.stepValidate();
    },
    stepValidate() {
      this.stepValid = false;
      if (this.targetList.length > 0) {
        this.stepValid = true;
      }
      if (this.stepValid) {
        this.nextStepStatus(false);
        this.stepDataOut();
      } else {
        this.nextStepStatus(true);
      }
    }
  }
};
</script>

<style scoped lang="css">
.createStratgey {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 999999;
  background: #f5f7fa;
}
.createStratgey .createStratgey--header {
  background-color: #ffffff;
  height: 104px;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
}
.createStratgey .createStratgey--filter {
  background: #f5f7fa;
  height: 109px;
}

.heading--input {
  border: 0;
  outline: none;
  font-family: 'ProximaNova', sans-serif;
}
.heading--input:focus {
  border: 0;
  outline: none;
}
.custom--select {
  width: 33%;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 #e9eaeb;
  border: solid 1px #e9eaeb;
  background-color: #ffffff;
}
</style>
