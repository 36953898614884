<template>
  <span>
    <div
      :id="id"
      class="IQtooltip-content u-display-flex u-spacing-ph-m u-spacing-mt-m u-spacing-mb-m"
    >
      <div class="u-display-flex u-flex-direction-column">
        <div class="u-font-weight-600 u-font-size-2">
          <span class="u-color-blue-base">IQ</span> Tip
        </div>
        <div class="u-line-height-1-3 u-display-flex u-flex-direction-column">
          <div
            v-for="(para, $index) of data && data.content.para"
            :key="$index"
            class="u-display-inline-flex u-font-weight-normal u-spacing-mt-m"
          >
            {{ para }}
          </div>
        </div>
      </div>
    </div>
    <span v-tippy="tippyOptions">
      <rb-icon
        class="u-color-blue-base u-cursor-pointer IQTooltip"
        icon="light-bulb"
      />
    </span>
  </span>
</template>
<script>
export default {
  name: 'IQTooltip',
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      id: ''
    };
  },
  computed: {
    tippyOptions() {
      return {
        html: '#' + this.id,
        reactive: true,
        distance: 4,
        placement: 'bottom-end',
        interactive: true,
        theme: 'dropdown',
        trigger: 'mouseenter',
        // trigger: 'click',
        duration: [0, 0],
        boundary: 'viewport'
      };
    }
  },
  created() {
    this.id = 'IQTooltip' + this._uid;
  }
};
</script>

<style lang="css" scoped>
.IQtooltip-content {
  max-width: 500px;
  word-break: break-word;
}
</style>
