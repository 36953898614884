import { cloneDeep, flatten } from 'lodash';
import Vue from 'vue';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default {
  props: {
    enableRollup: {
      type: Boolean,
      default: false
    },
    selectedMetricLimit: {
      type: Number,
      default: 1
    },
    minimumMetric: {
      type: Number,
      default: 2
    },
    removeDropdown: {
      type: Boolean,
      default: true
    },
    disableAddNewMetricButton: {
      type: Boolean,
      default: true
    },
    chartConfig: {
      type: Object,
      default: {}
    },
    isMetricToolTipEnabled: {
      type: Boolean,
      default: false
    },
    enableDisplayIn: {
      type: Boolean,
      default: true
    },
    modifyTooltipMessage: {
      type: Boolean,
      default: false
    },
    hideZero: {
      type: Boolean,
      default: true
    },
    bypassFilters: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    dataTransformer(metricsDataResponse) {
      this.isChartLoading = true;
      metricsDataResponse.forEach((metricGroup) => {
        metricGroup.forEach((metric) => {
          const obj = {};
          // metric RESULT is the tag1 or the primary value, while the PVP one is for tag2
          obj.tag1 = metric.RESULT[metric.name];
          obj.tag2 = metric.PVP['PVP_' + metric.name];
          // If metric meta unit is percentage then we need the PVP_DIFF
          if (
            this.metadata.metrics[metric.name].metadata.unit === 'PERCENTAGE'
          ) {
            obj.tag2 = metric.PVP['PVP_DIFF_' + metric.name];
          }
          // Assign the created object to the metric data
          this.metricData[this.metadata.metrics[metric.name].label] = obj;
        });
      });
      this.myKey = Math.random();
      const timeSeriesMetricsDataResponse = flatten(metricsDataResponse).reduce(
        (finalObj, eachMetric) => {
          return eachMetric
            ? {
                ...finalObj,
                [eachMetric.name]: eachMetric.TIMESERIES
              }
            : finalObj;
        },
        {}
      );

      this.chartData.data = [];
      const meta = this.metadata.metrics;
      const metricsSeriesList = [];
      const chartXS = {};

      Object.keys(timeSeriesMetricsDataResponse).forEach((metricKey) => {
        const label = meta[metricKey]?.label;
        const timeSeriesLabel =
          this.chartConfig?.chartOptions?.timeseries + '_' + label;
        chartXS[label] = timeSeriesLabel;
        const metricValues = timeSeriesMetricsDataResponse[metricKey]
          ? timeSeriesMetricsDataResponse[metricKey].map(
              (metricEntry) => metricEntry[metricKey]
            )
          : [];
        const metricFeedDateValues = timeSeriesMetricsDataResponse[metricKey]
          ? timeSeriesMetricsDataResponse[metricKey].map(
              (metricEntry) => metricEntry.feed_date
            )
          : [];
        metricValues.unshift(label);
        metricFeedDateValues.unshift(timeSeriesLabel);
        metricsSeriesList.push(metricValues, metricFeedDateValues);
      });

      this.chartData.xs = chartXS;

      Vue.set(this.chartData, 'data', metricsSeriesList);
      this.isChartLoading = false;
    },
    tempTransformer(request, metricsList) {
      let modifiedRequest = cloneDeep(request);
      modifiedRequest.metricsList = ':metricsList';
      const tempParams = {
        ':metricsList': metricsList
      };
      modifiedRequest = dashUtils.replacePlaceHolderWithData(
        modifiedRequest,
        tempParams
      );
      return modifiedRequest;
    },
    generateMetricDisplayConfig(metricData) {
      const metricConfig = {};
      // Construct the required configs
      metricData.forEach((metric) => {
        const metricMeta = metric.metadata;

        // Percentage, currency and metric suffix/prefix in chart tooltip
        if (metricMeta.unit === 'PERCENTAGE') {
          this.chartConfig.chartOptions.tooltip_format[metric.label] = {
            suff: '%'
          };
        } else if (metricMeta.unit === 'CURRENCY') {
          this.chartConfig.chartOptions.tooltip_format[metric.label] = {
            pre: 'currency'
          };
        } else if (metricMeta.unit === 'NUMBER') {
          this.chartConfig.chartOptions.tooltip_format[metric.label] = {
            roundoff: 0
          };
        }

        metricConfig[metric.label] = {
          invertTag2: metricMeta.isInverted,
          tag1Unit: {
            pre: metricMeta.type === 'CURRENCY' ? 'currency' : '',
            suff:
              metricMeta.unit in this.suffMap
                ? this.suffMap[metricMeta.unit]
                : '',
            roundoff:
              metricMeta.type === 'NUMBER' && metricMeta.unit === ''
                ? 0
                : undefined
          },
          tag2Unit: {
            suff: '%'
          }
        };
      });
      return metricConfig;
    }
  }
};
