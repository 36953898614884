var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sku-info-holder u-display-flex"},[_c('div',{staticClass:"u-spacing-mr-m u-width-100",class:{ 'u-height-100': _vm.isLoading }},[_c('div',{staticClass:"card u-display-flex u-width-100",class:{ 'u-height-100': _vm.showError }},[(!_vm.isLoading && !_vm.showError)?_c('section',{staticClass:"u-width-100"},[_c('section',{staticClass:"u-spacing-m-l u-spacing-mb-0"},[_c('div',{staticClass:"sku-image-holder u-flex-complete-center"},[(_vm.getDataValue('image_url'))?_c('img',{staticClass:"u-spacing-p-s u-width-100",attrs:{"src":_vm.getDataValue('image_url')}}):_vm._e()]),_c('div',{staticClass:"u-spacing-mt-s"},[_c('span',{staticClass:"u-font-size-4 u-font-weight-600 u-color-grey-base u-line-height-1-3"},[_vm._v(_vm._s(_vm.getDataValue('title')))])]),_c('div',{staticClass:"u-spacing-mt-s"},[_c('a',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippyOptions),expression:"tippyOptions"}],staticClass:"u-font-size-4 u-color-grey-lighter u-cursor-pointer asin_url",attrs:{"href":_vm.getDataValue('product_url'),"target":"_blank","title":"View Amazon detail page","rel":"noopener"}},[_vm._v(_vm._s(_vm.data.asin))])]),(_vm.getDataValue('variant_info'))?_c('div',{staticClass:"u-spacing-mt-m"},[_c('div',[_c('div',{staticClass:"u-spacing-mb-s"},_vm._l((_vm.getDataValue('variant_info')),function(value,key){return _c('span',{key:value,staticClass:"u-font-size-4 u-spacing-mr-s u-spacing-mb-xs u-display-block"},[_c('span',{staticClass:"u-color-grey-lighter u-text-transform u-font-size-5"},[_vm._v(_vm._s(key)+":")]),_c('span',{staticClass:"u-color-grey-base u-text-transform u-font-size-5 u-font-weight-600"},[_vm._v(" "+_vm._s(value)+" ")])])}),0),(
                  _vm.getDataValue('other_variants') &&
                  _vm.getDataValue('other_variants').length > 0
                )?_c('rb-select',{staticClass:"u-spacing-mr-m",attrs:{"width":'260px',"send-details":true,"on-close":_vm.onVariantClose,"options":_vm.getDataValue('other_variants'),"class-name":'u-spacing-pt-0',"search-enable-threshold":200},scopedSlots:_vm._u([{key:"item",fn:function(option){return [_c('rb-sku',{attrs:{"image-url":option.image_url,"title":option.title,"asin":option.child_sku,"product-page-url":option.product_url,"enable-s-d-p":false},on:{"navigateToSDP":_vm.navigateToSDP}},[_c('template',{slot:"sku-context"},_vm._l((option.variant_json),function(value,key){return _c('span',{key:value,staticClass:"u-spacing-mr-s"},[_c('span',{staticClass:"u-color-grey-lighter u-text-transform"},[_vm._v(_vm._s(key)+":")]),_c('span',{staticClass:"u-color-grey-base"},[_vm._v(" "+_vm._s(value)+" "),_c('span',[_vm._v("•")])])])}),0)],2)]}}],null,false,1997444825)},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-cursor-pointer",attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_c('span',{staticClass:"u-font-size-6 asin_url",staticStyle:{"font-size":"11px","font-weight":"600","color":"#007cf6"}},[_vm._v("View "+_vm._s(_vm.getDataValue('other_variants').length)+" other variants")])])]),_c('template',{slot:"header"},[_c('div',{staticClass:"u-bg-color-grey-light u-color-grey-white u-spacing-p-m"},[_c('span',{staticClass:"u-color-white u-font-size-5"},[_vm._v("Other Variants")])])])],2):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[(
                _vm.getDataValue('events') && _vm.getDataValue('events').length > 0
              )?_c('div',{staticClass:"u-spacing-mt-l"},[_c('chips',{attrs:{"chips":_vm.getDataValue('events')}})],1):_vm._e(),(_vm.distributionType && _vm.distributionType.length > 0)?_c('div',{staticClass:"u-spacing-mt-l"},[_c('chips',{attrs:{"chips":_vm.distributionType}})],1):_vm._e()]),_c('div',{staticClass:"u-display-flex u-flex-complete-center",class:{
              'u-spacing-mt-l':
                _vm.getDataValue('offer_price') ||
                _vm.getDataValue('avg_product_rating') ||
                _vm.getDataValue('review_count')
            }},[(_vm.getDataValue('offer_price'))?_c('span',{staticClass:"u-font-size-4 u-color-red-base"},[_vm._v(" "+_vm._s(_vm._f("num_format")(_vm.getDataValue('offer_price'), 'currency', undefined, undefined, undefined, undefined, _vm.selectedCurrency ))+" ")]):_vm._e(),(_vm.getDataValue('offer_price'))?_c('span',{staticClass:"dot"},[_vm._v("•")]):_vm._e(),(
                _vm.getDataValue('avg_product_rating') ||
                _vm.getDataValue('review_count')
              )?_c('rating-stars',{attrs:{"star":_vm.getDataValue('avg_product_rating'),"count":_vm.getDataValue('review_count')}}):_vm._e()],1),(
              _vm.getDataValue('badges') && _vm.getDataValue('badges').length !== 0
            )?_c('div',{staticClass:"u-display-flex u-spacing-mt-m u-flex-wrap-yes"},_vm._l((_vm.getDataValue('badges')),function(val,index){return _c('div',{key:index,staticClass:"u-display-flex u-flex-align-items-center u-spacing-mr-s u-spacing-mt-s",staticStyle:{"width":"102px","height":"18px"}},[_c('img',{staticClass:"u-width-100 u-height-100",staticStyle:{"object-fit":"contain"},attrs:{"src":'/sku-images/' + val.name + '.png'}})])}),0):_vm._e(),_c('div',{staticClass:"u-spacing-mt-s u-display-flex"},[_c('list-view',{attrs:{"list":_vm.getDataValue('infoList')}})],1)]),_c('div',{staticClass:"u-spacing-mt-m u-flex-justify-content-flex-start u-display-flex u-border-top u-border-color-grey-xxx-light u-color-grey-x-light u-border-width-s u-font-size-6 card-footer u-spacing-p-m u-spacing-ml-s"},[_c('span',[_vm._v("Last Updated : "),_c('span',{staticClass:"u-color-grey-dark"},[_vm._v(_vm._s(_vm.formatter(_vm.metaDataConfig.calendar.max_date, 'date')))])])])]):_vm._e(),(_vm.isLoading)?_c('section',{staticClass:"u-spacing-m-l u-width-100 u-display-flex isLoadingWidget",staticStyle:{"display":"flex"}},[_c('loader',{staticClass:"fill--parent u-bg-color-grey-white",attrs:{"color":'#007cf6'}})],1):_vm._e(),(!_vm.isLoading && _vm.showError)?_c('section',{staticClass:"u-spacing-m-l u-width-100 u-display-flex u-flex-align-items-center u-flex-justify-content-center"},[_c('div',{staticClass:"u-font-size-4"},[_vm._v("No Data.")])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }