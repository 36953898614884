<template>
  <div class="bg">
    <!-- account name -->
    <div
      class="u-bg-color-white-base u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-pb-l u-spacing-pl-l u-spacing-pr-l u-width-100 account-manager-header"
    >
      <h2 class="u-font-size-2 u-color-grey-base u-font-weight-bold">
        {{ mainAccountName }}
      </h2>
      <div class="u-display-flex u-flex-justify-content-flex-end">
        <splitTabs
          v-show="accounts.length > 0"
          class="account-manager-split-tabs"
          tab1="Login Details"
          tab2="API Details"
          @tabClicked="onSectionTabClick"
        />
      </div>
    </div>

    <!-- tabs -->
    <div
      class="u-display-flex u-flex-align-items-center account-manager-tabs u-spacing-pl-l u-spacing-pr-l"
    >
      <div
        v-for="tab in tabs"
        :key="tab.id"
        v-tippy="tippy"
        class="account-manager-tab"
        :class="{
          'account-manager-tab-active':
            selectedTab && selectedTab.id === tab.id,
          'account-manager-tab-disabled': tab.disabled
        }"
        :title="tab.tooltip"
        @click="selectTab(tab)"
      >
        {{ tab.name }}
      </div>
    </div>

    <div class="u-spacing-p-l">
      <!-- retailer header -->
      <div
        v-if="accountsHeader"
        class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-flex-1 u-spacing-pb-l"
      >
        <span class="u-font-size-3 card__title u-font-weight-600">
          <span
            class="u-color-grey-lighter"
            style="margin-right: 10px"
            >ACCOUNTS RELATED TO</span
          >
          <span class="u-color-black-lighter">{{ accountsHeader }}</span>
        </span>
      </div>

      <!-- no data found -->
      <div
        v-show="!loading && accounts.length === 0"
        class="account-manager-no-data"
      >
        <img
          src="/images/no_sku_found.png"
          alt="no account data found"
        />
        <div class="account-manager-no-data-txt">
          There are no accounts under {{ selectedTab.name }}!
        </div>
      </div>

      <!-- retailer accounts card -->
      <loader
        :loading="loading"
        class="u-display-flex u-flex-justify-content-center u-width-100"
        style="height: 100px"
        :color="'#007cf6'"
      />

      <div
        v-if="accounts.length > 0"
        class="u-display-flex-column account-manager"
      >
        <div
          v-for="(val, index) in accounts"
          :key="index"
          :val="val"
          class="account-manager-row u-spacing-mb-l u-spacing-pl-l u-spacing-pr-l u-spacing-pt-l u-border-width-s u-border-color-grey-xxx-light"
        >
          <!-- key /value iterator -->
          <div
            v-for="item in accountsSectionFilter(val)"
            :key="item.key"
            class="account-manager-column"
            :style="{ width: item.width }"
          >
            <div class="account-manager-column-key">
              {{ item.key }}
            </div>
            <div
              class="account-manager-column-value"
              :class="item.class"
            >
              <span
                v-if="item.type === 'text'"
                v-tippy="tippy"
                class="account-manager-column-value-text u-cursor-pointer"
                :title="item.value"
                >{{ item.value }}</span
              >
              <span
                v-if="item.type === 'masked'"
                class="u-color-grey-base"
                >&lt;&gt;</span
              >
              <span
                v-if="item.type === 'password'"
                class="account-manager-column-value-password"
                >***</span
              >
              <a
                v-if="item.type === 'link'"
                :href="item.value"
                target="_blank"
                >Open Link</a
              >
              <span
                v-show="!item.disableCopy"
                v-tippy="tippy"
                :title="`Copy ${item.key}`"
                class="u-spacing-ml-xs"
                @click="copyToClipboard(item.value, item.key)"
              >
                <rb-icon
                  class="rb-icon--medium u-cursor-pointer account-manager-icon"
                  :icon="'copy-to-clipboard'"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import loader from '@/components/basic/loader';
import CONSTANTS from '@/utils/constants';
import splitTabs from '@/components/widgets/splitTabs';
import {
  copyToClipboard,
  retailerAPIMap,
  fetchRetailerDetails,
  updateRetailers,
  getAvailableTabs,
  getRetailerName
} from './utils';
import urlHelper from '@/utils/helpers/url';

export default {
  components: { loader, splitTabs },
  data() {
    return {
      system: '',
      tabs: [],
      selectedTab: null,
      sectionTab: 'login',
      accounts: [],
      loading: true,
      tippy: {
        placement: 'top',
        distance: 8
      }
    };
  },
  computed: {
    retailer() {
      return this.$store.getters.getRetailer;
    },
    clientName() {
      return urlHelper.getClientInfoFromUrl().client;
    },
    mainAccountName() {
      return this.$store.getters.getConfigs?.client?.vendor?.name;
    },
    region() {
      return window.location.href.split('/')[3];
    },
    accountsHeader() {
      return getRetailerName({
        retailer: this.retailer,
        system: this.system,
        accounts: this.accounts
      });
    }
  },
  created() {
    let programs = this.$store.getters.getProductConfig;
    this.tabs = getAvailableTabs(programs);
    this.selectTab(this.tabs[0]);
  },
  methods: {
    generateRegionClientName() {
      let regions = Object.values(CONSTANTS.regions);

      const splitClientName = this.clientName?.split('-');
      if (regions?.includes(splitClientName?.[splitClientName?.length - 1])) {
        if (this.$store.getters.getLocation !== CONSTANTS.regions.US) {
          return `${splitClientName?.slice(0, -1)?.join('-')}-${
            this.$store.getters.getLocation
          }`;
        } else {
          return `${splitClientName?.slice(0, -1)?.join('-')}`;
        }
      } else if (this.$store.getters.getLocation !== CONSTANTS.regions.US) {
        return `${this.clientName}-${this.$store.getters.getLocation}`;
      }
      return this.clientName;
    },
    async selectTab(tab) {
      if (
        tab.disabled ||
        (this.selectedTab && this.selectedTab.id === tab.id)
      ) {
        return;
      }

      this.loading = true;

      this.selectedTab = tab;
      this.system =
        this.retailer === 'amazon_3p'
          ? this.selectedTab.idFor3p
          : this.selectedTab.id;

      this.accounts = [];
      try {
        const response = await fetchRetailerDetails({
          clientName: this.generateRegionClientName(),
          region: this.region.toUpperCase(),
          system: this.system,
          retailerAPI: retailerAPIMap[this.retailer]
        });
        updateRetailers(response, this.accounts);
      } catch (e) {
        this.$snackbar.open({
          message: `Fetching retailer ${this.selectedTab.name} details`,
          duration: 2000,
          actionText: 'Failed'
        });
      } finally {
        this.loading = false;
      }
    },
    accountsSectionFilter(account) {
      return account.keyValuePairs.filter(
        (item) => item.section === 'common' || item.section === this.sectionTab
      );
    },
    onSectionTabClick(section) {
      const nameToId = {
        'Login Details': 'login',
        'API Details': 'api'
      };
      this.sectionTab = nameToId[section];
    },
    copyToClipboard(txt, field) {
      copyToClipboard(txt).then(() => {
        this.$snackbar.open({
          message: field,
          duration: 2000,
          actionText: 'Copied'
        });
      });
    }
  }
};
</script>
<style lang="css" scoped>
.account-manager-row {
  display: flex;
  min-height: 100px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 1px 6px rgba(43, 51, 59, 0.15);
  border-radius: 4px;
  flex-wrap: wrap;
}
.account-manager-column {
  word-break: break-all;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 14px;
  margin-bottom: 30px;
  margin-right: 32px;
}
.account-manager-column .account-manager-column-key {
  color: #8b8f93;
  margin-bottom: 16px;
}
.account-manager-column .account-manager-column-value {
  color: #6a7075;
  display: flex;
  align-items: center;
}
.account-manager-column
  .account-manager-column-value
  .account-manager-column-value-text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.account-manager-column .account-manager-column-value a {
  color: #6a7075;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 0.5px;
}
.account-manager-column
  .account-manager-column-value.account-manager-column-value-dark {
  color: #2b333b;
}
.account-manager-column:last-child {
  margin-right: unset;
}
.account-manager {
  height: calc(100vh - 255px);
  overflow: auto;
}
.account-manager-tabs {
  font-size: 1.4rem;
  color: #4b5158;
  background-color: #fff;
  height: 38px;
  box-shadow: 0px 1px 4px #e9eaeb;
}
.account-manager-tab {
  font-size: 1.4rem;
  cursor: pointer;
}
.account-manager-tab + .account-manager-tab {
  margin-left: 40px;
}
.account-manager-tab-active {
  color: #2690f8;
  font-weight: 600;
}
.account-manager-tab-disabled {
  opacity: 0.3;
}
.account-manager-no-data {
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.account-manager-no-data > img {
  width: 403px;
  height: 273px;
  margin-bottom: 30px;
}
.account-manager-no-data-txt {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 18px;
  color: #2b333b;
}
.account-manager-icon {
  color: #8b8f93;
  transition: color 0.3s;
}
.account-manager-icon:hover {
  color: #2b333b;
}
.account-manager-header {
  filter: drop-shadow(2px 1px 8px #e9eaeb);
}
.account-manager-column-value-password {
  color: #2b333b;
  font-weight: 800;
  position: relative;
}
.account-manager-column-value-password:before {
  position: absolute;
  content: ' ';
  width: 100%;
  height: 1px;
  bottom: 4px;
  background-color: #2b333b;
}
</style>

<style lang="css">
.account-manager-split-tabs .split-tabs {
  line-height: 1.2;
}
.account-manager-split-tabs .split-tabs .u-border-radius-xl {
  border-radius: 24px;
}
</style>
