<template>
  <div
    class="action-bar--workspace u-display-flex u-border-color-grey-white u-flex-direction-column"
  >
    <Loader
      v-if="!isWorkspaceLoaded"
      class="fill--parent"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
    />
    <div
      v-if="!isboomerangAction"
      class="u-spacing-ph-l"
    >
      <div
        class="action--title u-font-weight-600 u-font-size-5 u-spacing-pt-l u-text-case-upper"
      >
        <span v-if="requestedAction.id === 'nonBoomOpen'">Open</span>
        <span v-if="requestedAction.id === 'nonBoomApprove'">Approve</span>
        <span v-if="requestedAction.id === 'nonBoomActionTaken'">Resolve</span>
        Recommendations
      </div>
      <div
        class="u-font-size-7 validation--msg u-spacing-pt-xs u-color-grey-lighter"
      >
        <div>{{ totalSelections }} recommendation(s) selected.</div>
      </div>
      <div class="u-font-size-5 u-spacing-pv-m">
        These actions are
        <strong>not</strong> powered by boomerang. You will have to work on them
        yourself but you can mark the status here for collaborating with your
        team.
      </div>
    </div>
    <div
      v-if="!isEditable && isboomerangAction"
      class="u-spacing-ph-l"
    >
      <div
        class="action--title u-font-weight-600 u-font-size-5 u-spacing-pt-l u-text-case-upper"
      >
        Approve Recommendations
      </div>
      <div
        class="u-font-size-7 validation--msg u-spacing-pt-xs u-color-grey-lighter"
      >
        <div
          v-if="totalSelections === validSelections && validSelections !== 0"
        >
          {{ validSelections }} recommendation(s) selected.
        </div>
        <div v-if="totalSelections > validSelections && validSelections !== 0">
          Only
          <span class="u-color-blue-base u-font-weight-600">{{
            validSelections
          }}</span>
          out of the {{ totalSelections }} recommendations selected can be
          approved.
        </div>
        <div v-if="validSelections === 0">
          None of the recommendations selected can be approved.
        </div>
      </div>
      <div class="u-font-size-5 u-spacing-pv-m">
        On approval, recommended actions with
        <rb-icon
          class="rb-icon--small u-color-blue-base"
          :icon="'logo'"
        />(Supported by CommerceIQ) will be initiated.
      </div>

      <div
        v-if="isDemoClient"
        class="confirm_automation_container bg-blue-base-gradient"
      >
        <div
          class="u-display-flex u-flex-direction-column u-height-100 u-flex-justify-content-center"
        >
          <div
            :style="{ 'justify-content': 'start' }"
            class="u-flex-complete-center"
          >
            Would you like to Aut
            <span>
              <rb-icon
                class="rb-icon--medium"
                icon="bot"
              />
            </span>
            mate this process ?
          </div>
        </div>
        <two-tab-view
          :initial-state="false"
          class="automate_toggle"
          tab1="Yes"
          background="white"
          tab2="No"
        />
      </div>
    </div>
    <div
      v-if="isEditable"
      class="u-spacing-ph-l"
    >
      <div
        class="action--title u-font-weight-600 u-font-size-5 u-spacing-pt-l u-text-case-upper"
      >
        Edit recommended action
      </div>
      <div
        class="u-font-size-7 validation--msg u-spacing-pt-xs u-color-grey-lighter"
      >
        <div
          v-if="totalSelections === validSelections && validSelections !== 0"
        >
          {{ validSelections }} recommendation selected.
        </div>
      </div>
      <div class="action--title u-font-weight-600 u-font-size-6 u-spacing-pt-l">
        {{ actionConfig.edit_title }}
      </div>
      <div
        class="u-font-size-7 validation--msg u-spacing-pt-xs u-color-grey-lighter"
      >
        <div>
          {{ validSelections }}
          {{ currentPrimaryKeyToTextMap[currentPrimaryKey] }}(s) selected.
        </div>
      </div>
      <div class="edit--workspace u-spacing-pv-m">
        <div
          v-for="(action, index) in actionsArrayForEdit"
          :key="index"
          class="action--row u-display-flex u-spacing-mb-s"
        >
          <div
            v-if="actionConfig.campaign_name && actionConfig.campaign_name.show"
            class="column--wide u-flex-shrink-past-min-size u-flex-0 u-spacing-pr-xl"
          >
            <div class="title--bar u-font-size-6 u-spacing-mb-m">Campaign:</div>
            <div
              v-if="actionConfig.campaign_name.editable"
              class="rb-control"
            >
              <rb-select
                v-tippy="{ placement: 'top' }"
                :send-details="true"
                :context="[index]"
                :on-close="campaignSelected"
                :options="campaignList"
                :title="action.campaign_name || 'Please select a campaign'"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-cursor-pointer u-width-100"
                >
                  <span
                    class="u-flex-1 u-font-size-6 u-text-overflow-ellipsis u-spacing-p-s"
                  >
                    {{ action.campaign_name || 'Please select a campaign' }}
                  </span>
                  <rb-icon
                    class="rb-icon--medium u-spacing-mr-s u-color-grey-mid-light"
                    :icon="'caret-down'"
                  />
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div
                    class="u-font-size-6 dropdown-selection-width u-text-overflow-ellipsis"
                    :title="option.campaign_name"
                  >
                    {{ option.campaign_name }}
                  </div>
                </template>
              </rb-select>
            </div>
            <div
              v-else
              class="read-only--box u-font-size-6 u-spacing-p-s u-text-overflow-ellipsis"
            >
              {{ action.campaign_name }}
            </div>
          </div>

          <div
            v-if="
              actionConfig.campaign_name &&
              actionConfig.campaign_name.show &&
              actionConfig.campaign_name.editable
            "
            class="column--wide u-flex-shrink-past-min-size u-flex-0 u-spacing-pr-xl"
          >
            <div class="title--bar u-font-size-6 u-spacing-mb-m">Ad group:</div>
            <div
              v-if="actionConfig.campaign_name.editable"
              class="rb-control"
            >
              <rb-select
                v-tippy="{ placement: 'top' }"
                :send-details="true"
                :context="[index]"
                :on-close="adgroupSelected"
                :options="(action && action.adGroupsForSelectedCampaign) || []"
                :title="
                  action.adgroup_name ||
                  (!action.campaign_name
                    ? 'Will be available post campaign selection'
                    : 'Select Ad group')
                "
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-cursor-pointer u-width-100"
                >
                  <span
                    class="u-flex-1 u-font-size-6 u-spacing-p-s u-text-overflow-ellipsis"
                  >
                    {{
                      action.adgroup_name ||
                      (!action.campaign_name
                        ? 'Will be available post campaign selection'
                        : 'Select Ad group')
                    }}
                  </span>
                  <rb-icon
                    class="rb-icon--medium u-spacing-mr-s u-color-grey-mid-light"
                    :icon="'caret-down'"
                  />
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div
                    class="u-font-size-6 dropdown-selection-width u-text-overflow-ellipsis"
                    :title="option.adgroup_name"
                  >
                    {{ option.adgroup_name }}
                  </div>
                </template>
              </rb-select>
            </div>
            <div
              v-else
              class="read-only--box u-font-size-6 u-spacing-p-s u-text-overflow-ellipsis"
            >
              {{ action.campaign_name }}
            </div>
          </div>

          <div
            v-if="
              actionConfig.new_match_type && actionConfig.new_match_type.show
            "
            class="column--thin u-flex-shrink-past-min-size u-flex-0 u-spacing-pr-xl"
          >
            <div class="title--bar u-font-size-6 u-spacing-mb-m">
              Keyword match type:
            </div>
            <div
              v-if="actionConfig.new_match_type.editable"
              class="rb-control"
            >
              <rb-select
                :send-details="true"
                :on-close="matchTypeSelected"
                :context="[index]"
                :options="matchTypes"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-cursor-pointer u-width-100"
                >
                  <div
                    class="u-flex-1 u-font-size-6 u-spacing-p-s u-text-case-title"
                  >
                    {{ action.new_match_type }}
                  </div>
                  <rb-icon
                    class="rb-icon--medium u-spacing-mr-s u-color-grey-mid-light"
                    :icon="'caret-down'"
                  />
                </div>
                <template
                  slot="item"
                  slot-scope="option"
                >
                  <div
                    style="width: 95px"
                    class="u-font-size-6 u-text-case-title"
                  >
                    {{ option.title }}
                  </div>
                </template>
              </rb-select>
            </div>
            <div
              v-else
              class="read-only--box u-font-size-6 u-spacing-p-s u-text-case-title"
            >
              {{ action.new_match_type.toLowerCase() }}
            </div>
          </div>
          <div
            v-if="actionConfig.new_bid && actionConfig.new_bid.show"
            class="column--thin u-flex-0 u-flex-shrink-past-min-size"
          >
            <div class="title--bar u-font-size-6 u-spacing-mb-m u-display-flex">
              <div class="u-flex-0">Keyword bid:</div>
              <div
                v-tippy="tippyOptions"
                class="u-flex-0 u-cursor-pointer"
                :title="`Upper limit of the bid is set as ${formatterFn(
                  actionConfig.max_bid,
                  'currency'
                )} for this strategy`"
              >
                <rb-icon
                  class="u-flex-0 rb-icon--small u-color-grey-lighter"
                  :icon="'info-circle-fill'"
                />
              </div>
            </div>
            <div
              v-if="actionConfig.new_bid.editable"
              class="u-display-flex u-flex-0 u-flex-align-items-center u-flex-shrink-past-min-size"
            >
              <span
                class="currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                >{{ currency }}</span
              >
              <input
                ref="new_bid"
                type="number"
                data-cy="bidInput"
                class="rb-input currency-input u-display-flex u-flex-align-items-center"
                min="0.02"
                step="0.01"
                :max="actionConfig.max_bid"
                placeholder="Bid amount"
                :value="action.new_bid"
                @blur="checkForMaxBid($event, action, 'new_bid')"
              />
            </div>
            <div
              v-else
              class="read-only--box u-font-size-6 u-spacing-p-s"
            >
              {{ action.new_bid }}
            </div>
          </div>
          <div
            v-if="
              actionConfig.new_daily_budget &&
              actionConfig.new_daily_budget.show
            "
            class="column--thin u-flex-0 u-flex-shrink-past-min-size"
          >
            <div class="title--bar u-font-size-6 u-spacing-mb-m u-display-flex">
              <span class="u-flex-0">Campaign budget:</span>
              <span
                v-tippy="tippyOptions"
                class="u-flex-0 u-cursor-pointer"
                :title="`Upper limit of the budget is set as ${formatterFn(
                  actionConfig.max_bid,
                  'currency'
                )} for this strategy`"
              >
                <rb-icon
                  class="u-flex-0 rb-icon--small u-color-grey-lighter"
                  :icon="'info-circle-fill'"
                />
              </span>
            </div>
            <div
              v-if="actionConfig.new_daily_budget.editable"
              class="u-display-flex u-flex-0 u-flex-align-items-center u-flex-shrink-past-min-size"
            >
              <span
                class="currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                >{{ currency }}</span
              >
              <input
                ref="new_bid"
                type="number"
                data-cy="bidInput"
                class="rb-input currency-input u-display-flex u-flex-align-items-center"
                min="0.02"
                step="0.01"
                :max="actionConfig.max_bid"
                placeholder="Bid amount"
                :value="action.new_daily_budget"
                @blur="checkForMaxBid($event, action, 'new_daily_budget')"
              />
            </div>
            <div
              v-else
              class="read-only--box u-font-size-6 u-spacing-p-s"
            >
              {{ action.new_daily_budget }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="errorMsg"
      class="error-message u-color-red-base u-spacing-ph-l u-spacing-pb-s u-font-size-7"
    >
      {{ errorMsg }}
    </div>
    <div
      class="u-display-flex u-flex-align-items-center u-spacing-pv-m u-spacing-ph-l u-border-width-s u-border-top u-border-color-grey-xxx-light"
    >
      <rb-button
        v-if="!isboomerangAction"
        :click-fn="takeAction"
        :text="nonBoomerangActionButtonText"
        :size="'s'"
        :type="'filled'"
        class="u-spacing-mr-s"
      />
      <rb-button
        v-if="validSelections !== 0 && isboomerangAction"
        :click-fn="takeAction"
        :text="isEditable ? 'Confirm and approve' : 'Approve'"
        :size="'s'"
        :type="'filled'"
        class="u-spacing-mr-s fs-action-workspace-confirm-and-approve"
      />
      <rb-button
        :click-fn="closeWorkspace"
        :text="'Cancel'"
        :size="'s'"
        :type="'hollow'"
        class="u-spacing-mr-s"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import actionsConfiguration from '@/components/pages/recommendations/actions.js';
import { uniqBy, groupBy, cloneDeep } from 'lodash';
import Loader from '@/components/basic/loader.vue';
import { formatter } from '@/utils/helpers/formatter.js';
import twoTabView from '@/demo/twoTabView.vue';
/* eslint-disable vue/require-default-prop */

export default {
  components: {
    Loader,
    twoTabView
  },
  props: {
    loadedWorkspace: {
      default: true,
      type: Boolean
    },
    selections: Array,
    totalSelections: Number,
    validSelections: Number,
    submitAction: Function,
    closeFn: Function,
    currentPrimaryKey: String,
    requestedAction: Object
  },
  data() {
    return {
      actionsConfiguration: actionsConfiguration,
      formatterFn: formatter,
      currency: this.$currency,
      matchTypes: [{ title: 'broad' }, { title: 'phrase' }, { title: 'exact' }],
      currentPrimaryKeyToTextMap: {
        keyword_text: 'keyword',
        campaign: 'campaign',
        search_term: 'keyword'
      },
      actionsArrayForEdit: [],
      campaignList: [],
      errorMsg: '',
      isPayloadValid: true,
      tippyOptions: {
        placement: 'right',
        maxWidth: '400px',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      isDemoClient: Vue.options.filters.config_check(
        'feature.demo.product_demo.enable'
      )
    };
  },
  computed: {
    nonBoomerangActionButtonText() {
      let text = '';
      if (this.requestedAction.id === 'nonBoomApprove') {
        text = 'Approve';
      } else if (this.requestedAction.id === 'nonBoomOpen') {
        text = 'Mark as Open';
      } else if (this.requestedAction.id === 'nonBoomActionTaken') {
        text = 'Mark as Action Taken';
      }
      return text;
    },
    isWorkspaceLoaded() {
      return (
        this.$store.getters.isActionWorkspaceLoaded && this.loadedWorkspace
      );
    },
    isEditable() {
      return (
        this.actionConfig &&
        this.validSelections === 1 &&
        this.actionConfig.edit
      );
    },
    actionConfig() {
      return this.actionsConfiguration.allActions[this.currentAction];
    },
    currentAction() {
      // assuming only 1 action type is supported per recommendation row
      return this.actionPayload
        ? Object.keys(this.actionPayload)[0]
        : undefined;
    },
    actionPayload() {
      let payload = {};
      if (this.selections.length) {
        const selection = this.selections[0];
        if (selection.data && selection.data.action_payload) {
          payload = JSON.parse(selection.data.action_payload);
        }
      }
      return payload;
    },
    isboomerangAction() {
      return !this.requestedAction.id.startsWith('nonBoom');
    },
    profileId() {
      let profileId = '';
      if (this.selections.length) {
        const selection = this.selections[0];
        if (selection.data && selection.data.profile_id) {
          profileId = selection.data.profile_id;
        }
      }
      return profileId;
    }
  },
  watch: {
    selections: {
      immediate: true,
      handler: function (newVal, oldVal) {
        this.actionsArrayForEdit = this.currentAction
          ? JSON.parse(this.actionPayload[this.currentAction].actions).slice(0)
          : [];
        if (newVal && newVal !== oldVal) {
          if (
            this.actionConfig &&
            this.actionConfig.campaign_name &&
            this.actionConfig.campaign_name.show &&
            this.actionConfig.campaign_name.editable &&
            this.selections.length === 1
          ) {
            this.$store
              .dispatch('fetchCampaignList', {
                profiles: [this.profileId],
                dimensionKeyType: 'profile_id'
              })
              .then(
                (response) => {
                  this.setCampaingsList(response);
                },
                () => {
                  this.$snackbar.open({
                    message: 'Could not load campaign list. Please try again.',
                    duration: 5000,
                    actionText: ''
                  });
                }
              );
          }
        }
      }
    }
  },
  methods: {
    setCampaingsList(data) {
      const groupAdgroupsByCampaingId = groupBy(data, 'campaign_id');
      for (const campaignData of data) {
        campaignData.adGroupsForSelectedCampaign =
          (groupAdgroupsByCampaingId &&
            campaignData.campaign_id &&
            groupAdgroupsByCampaingId[campaignData.campaign_id]) ||
          [];
      }
      for (const actionData of this.actionsArrayForEdit) {
        const groupedAdGroups =
          (groupAdgroupsByCampaingId &&
            actionData.campaign_id &&
            groupAdgroupsByCampaingId[actionData.campaign_id]) ||
          [];
        if (actionData.campaign_id) {
          actionData.adGroupsForSelectedCampaign = groupedAdGroups;
          // select 1st adgroup by default
          if (groupedAdGroups.length > 0) {
            actionData.adgroup_name = groupedAdGroups[0]?.adgroup_name;
            actionData.adgroup_id = groupedAdGroups[0]?.adgroup_id;
          }
        }
      }
      this.campaignList = uniqBy(data, 'campaign_id');
    },
    closeWorkspace() {
      if (this.closeFn) {
        this.closeFn();
      }
    },
    takeAction() {
      if (this.submitAction) {
        if (this.isEditable) {
          this.validateAndModifyPayload();
          if (!this.isPayloadValid) {
            return false;
          }
          const newActionPayload = {
            actions: this.actionsArrayForEdit
          };
          this.submitAction(
            this.isEditable,
            this.currentAction,
            newActionPayload
          );
        } else {
          this.submitAction();
        }
      }
    },
    validateAndModifyPayload() {
      this.isPayloadValid = true;
      this.errorMsg = '';
      const isCampaignRequired =
        this.actionConfig &&
        this.actionConfig.campaign_name &&
        this.actionConfig.campaign_name.editable;
      for (var i = 0; i < this.actionsArrayForEdit.length; i++) {
        if (isCampaignRequired && !this.actionsArrayForEdit[i].campaign_name) {
          this.isPayloadValid = false;
          this.errorMsg = 'Please select a campaign from the campaigns list.';
          break;
        }
        if (isCampaignRequired && !this.actionsArrayForEdit[i].adgroup_name) {
          this.isPayloadValid = false;
          this.errorMsg = 'Please select an Ad group from the Ad group list.';
          break;
        }
        for (var j = 0; j < i; j++) {
          if (
            this.actionsArrayForEdit[i].campaign_name ===
              this.actionsArrayForEdit[j].campaign_name &&
            this.actionsArrayForEdit[i].adgroup_id ===
              this.actionsArrayForEdit[j].adgroup_id &&
            this.actionsArrayForEdit[i].new_match_type ===
              this.actionsArrayForEdit[j].new_match_type
          ) {
            this.isPayloadValid = false;
            this.errorMsg =
              "'Campaign name', 'Ad group' and 'match type' combination is not unique.";
            break;
          }
        }
      }
      if (this.isPayloadValid && isCampaignRequired) {
        // delete non required keys from payload for editible campaigns.
        for (const item of this.actionsArrayForEdit) {
          if (item.adgroup_name) delete item.adgroup_name;
          if (item.adGroupsForSelectedCampaign)
            delete item.adGroupsForSelectedCampaign;
        }
      }
    },
    checkForMaxBid(event, actionObject, key) {
      const value = Number(event.target.value).toFixed(2);
      const maxBid = this.actionConfig.max_bid;
      const minBid = this.actionConfig.min_bid;
      actionObject[key] = Number(value);
      if (value > maxBid) {
        actionObject[key] = maxBid;
        event.target.value = maxBid;
      }
      if (value < minBid) {
        actionObject[key] = minBid;
        event.target.value = minBid;
      }
    },
    matchTypeSelected(context, val) {
      if (val[0]) {
        Vue.set(
          this.actionsArrayForEdit[context[0]],
          'new_match_type',
          val[0].title
        );
      }
    },
    adgroupSelected(context, val) {
      if (val[0]) {
        this.actionsArrayForEdit[context[0]].adgroup_name = val[0].adgroup_name;
        this.actionsArrayForEdit[context[0]].adgroup_id = val[0].adgroup_id;
        this.actionsArrayForEdit = cloneDeep(this.actionsArrayForEdit);
      }
    },
    campaignSelected(context, val) {
      if (val[0]) {
        Vue.set(
          this.actionsArrayForEdit[context[0]],
          'campaign_name',
          val[0].campaign_name
        );
        Vue.set(
          this.actionsArrayForEdit[context[0]],
          'campaign_id',
          val[0].campaign_id
        );
        Vue.set(
          this.actionsArrayForEdit[context[0]],
          'profile_id',
          val[0].profile_id
        );
        Vue.set(
          this.actionsArrayForEdit[context[0]],
          'client_id',
          val[0].client_id
        );
        Vue.set(
          this.actionsArrayForEdit[context[0]],
          'campaignType',
          val[0].campaignType
        );
        Vue.set(
          this.actionsArrayForEdit[context[0]],
          'adGroupsForSelectedCampaign',
          val[0].adGroupsForSelectedCampaign
        );
        Vue.set(
          this.actionsArrayForEdit[context[0]],
          'adgroup_id',
          val[0].adgroup_id
        );
        Vue.set(
          this.actionsArrayForEdit[context[0]],
          'adgroup_name',
          val[0].adgroup_name
        );
      }
    }
  }
};
</script>

<style lang="css" scoped>
.dropdown-selection-width {
  min-width: 320px;
}
.action-bar--workspace {
  background: white;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 9;
}
.action-bar--workspace input[type='number']::-webkit-inner-spin-button,
.action-bar--workspace input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}
.action-bar--workspace .read-only--box {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  cursor: default;
  color: #6a7075;
  height: 32px;
}
.action-bar--workspace .rb-control {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.action-bar--workspace .rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  max-width: 80px;
  width: 100%;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 1px solid #e9eaeb;
}
.action-bar--workspace .rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 32px;
}
.action-bar--workspace .currency-symbol {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-right: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.action-bar--workspace .column--wide {
  width: 400px;
}
.action-bar--workspace .column--thin {
  width: 160px;
}
.action-bar--workspace .edit--workspace .action--row .title--bar {
  display: none;
  height: 14px;
}
.action-bar--workspace .edit--workspace .action--row:first-of-type .title--bar {
  display: flex;
}
</style>

<style lang="css">
.confirm_automation_container {
  width: 628px;
  height: 61px;
  align-items: center;
  border-radius: 4px;
  color: #ffffff;
  justify-content: space-between;
  display: flex;
  font-weight: 600;
  font-size: 1.4rem;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  margin-bottom: 1.6rem;
}
.confirm_automation_container .automate_toggle {
  color: #8b8f93;
}
.confirm_automation_container .automate_toggle .isHighlighted {
  background-color: #23b5d3;
}
</style>
