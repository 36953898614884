import Objective from '@/components/pages/instacart/campaign-creation/components/steps/campaign-objective.vue';
import AdGroup from '@/components/pages/instacart/campaign-creation/components/steps/campaign-ad-groups.vue';
import Settings from '@/components/pages/instacart/campaign-creation/components/steps/campaign-settings';
import Targeting from '@/components/pages/instacart/campaign-creation/components/steps/ad-group-targeting.vue';
import CreateAdGroup from '@/components/pages/instacart/campaign-creation/components/steps/ad-group-creation.vue';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';

export const MODULE_NAME = 'instacartCampaignCreation';
const ENTITY_DETAILS = 'entityDetails';

export const STEPS_DATA_GETTER = `${MODULE_NAME}/stepsData`;
export const STEPS_DATA_SETTER = `${MODULE_NAME}/updateStepsData`;
export const LAUNCHED_CAMPAIGN_GETTER = `${MODULE_NAME}/launchedCampaign`;
export const LAUNCHED_CAMPAIGN_SETTER = `${MODULE_NAME}/updateLaunchedCampaign`;
export const CAMPAIGN_NAME_EXISTS_CHECK = `${MODULE_NAME}/checkIfCampaignNameExists`;
export const CREATE_ADGROUP = `${MODULE_NAME}/createAdgroup`;

export const CAMPAIGN_DETAILS_GETTER = `${ENTITY_DETAILS}/getCampaignDetails`;

export const STEPS_CONFIG = [
  {
    name: 'Objective',
    header: {
      title: 'What is the campaign Objective?',
      desc: ['Select the objective of the campaign.']
    },
    textMapping: 'objective',
    component: Objective
  },
  {
    name: 'Settings',
    header: {
      title: 'Campaign setting details',
      desc: [
        'Start date is the date when your campaign will start and end date is the date when your campaign will end and the budget is the amount you’re willing to spend on your campaign.'
      ]
    },
    textMapping: 'settings',
    component: Settings
  },
  {
    name: 'Ad Groups',
    header: {
      title: 'All Ad Groups',
      desc: ['This space includes all the ad group(s) within the campaigns.']
    },
    textMapping: 'adGroups',
    component: AdGroup
  }
];

const selectSkuLeftTable = (vueRef) => [
  {
    // currDefinition
    name: 'sku_details',
    type: 'sku',
    uiField: {
      uiLabel: 'SKU Details',
      uiType: 'custom',
      widget: 'sku',
      uiOrder: '1',
      metadata: {
        widget: 'sku',
        tippyText: '',
        toolTipText: '',
        tableColumnName: 'SKU Details',
        columnTitleKey: 'product_title',
        columnAsinKey: 'upc',
        columnImageUrlKey: 'image_url',
        columnAsinHoverText: '',
        columnEnableProductLink: true,
        coloumnProductUrlKey: 'product_url',
        showOnUi: true,
        isFixed: false,
        getQuickFilterText: function (params) {
          return params.data.product_title + '_' + params.data.upc;
        }
      }
    }
  },
  {
    name: 'brand',
    type: 'STRING',
    uiField: {
      uiLabel: 'Brand',
      uiType: 'string',
      uiOrder: '2',
      metadata: {
        toolTipText: '',
        tableColumnName: 'brand_name',
        showOnUi: true,
        isFixed: false,
        width: 90
      }
    }
  },
  {
    name: 'size',
    type: 'STRING',
    uiField: {
      uiLabel: 'Size',
      uiType: 'string',
      uiOrder: '3',
      metadata: {
        toolTipText: '',
        tableColumnName: 'product_size',
        showOnUi: true,
        isFixed: false,
        width: 90
      }
    }
  },
  {
    name: 'Add all',
    type: 'custom',
    uiField: {
      uiLabel: 'Add all',
      uiType: 'custom',
      clickHeader: vueRef.addAll,
      customStyles:
        'cursor:pointer; color:#037ef6 !important; padding-left:15px',
      headerIcon: {
        icon: 'info-circle-fill',
        toolTip: 'Add all SKUs on this page'
      },
      uiOrder: '4',
      metadata: {
        toolTipText: 'Add this SKU',
        toolTipPosition: 'left',
        widget: 'icon',
        tableColumnName: 'na',
        showOnUi: true,
        suppressSizeToFit: true,
        isFixed: false,
        iconClickEvent: vueRef.addEntity,
        displayIcon: 'plus',
        alternateIcon: 'check-fill-circle',
        alternateIconClass: 'u-color-green-base',
        width: 110
      }
    }
  }
];

const selectSkuRightTable = (vueRef) => [
  {
    name: 'sku_details',
    type: 'sku',
    uiField: {
      uiLabel: 'SKU Details',
      uiType: 'custom',
      uiOrder: '1',
      metadata: {
        widget: 'sku',
        toolTipText: '',
        tableColumnName: 'SKU Details',
        columnTitleKey: 'product_title',
        columnAsinKey: 'upc',
        columnImageUrlKey: 'image_url',
        columnAsinHoverText: '',
        columnEnableProductLink: true,
        coloumnProductUrlKey: 'product_url',
        showOnUi: true,
        isFixed: false
      }
    }
  },
  {
    name: 'Remove all',
    type: 'custom',
    uiField: {
      uiLabel: 'Remove all',
      uiType: 'custom',
      uiOrder: '2',
      customStyles:
        'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
      clickHeader: vueRef.removeAll,
      metadata: {
        toolTipText: 'Remove Sku',
        toolTipPosition: 'left',
        widget: 'icon',
        tableColumnName: 'na',
        showOnUi: true,
        isFixed: false,
        iconClickEvent: vueRef.removeEntity,
        displayIcon: 'cross',
        iconSize: 'small',
        suppressSizeToFit: true,
        width: 110
      }
    }
  }
];

const APIConfig = {
  cubeName: 'instacart_sku_catalog',
  getLatestAvailableInsteadOfRollup: false,
  timeseriesEnabled: false,
  pvpenabled: false,
  yoyenabled: false,
  measuresList: [
    'product_title',
    'product_url',
    'image_url',
    'brand_name',
    'product_size'
  ],
  groupByDimensionsList: ['upc'],
  orderByList: [{ dimension: 'product_title', direction: 'ASC' }],
  limit: 10,
  page: 1
};

const fetchSavedState = (context, getter) => {
  const state = context.$store.getters[getter];
  return state;
};

const updateSavedState = (context, action, data) => {
  context.$store.dispatch(action, data);
};

const formSKUList = (skus, rightTableRows) => {
  const rightTableSKUs = rightTableRows.map(
    (rightTableSKU) => rightTableSKU.upc
  );
  const validSKUs = skus.filter((sku) => !rightTableSKUs?.includes(sku.trim()));
  let skuList = '';
  for (const sku of validSKUs) {
    skuList += `'${sku.trim()}',`;
  }
  skuList = skuList.slice(0, -1);
  return skuList;
};

const addSkuOnInput = (data) => {
  const skuList = formSKUList(data.keywords, data?.ref?.rightTableRows);
  const APIConfigDeepClone = cloneDeep(APIConfig);
  let result = [];
  if (skuList === '') {
    data.enterListRef.keywordTextInput = '';
    data.ref.$snackbar.open({
      message: 'SKU(s) in the list are already added',
      duration: 6000,
      buttonColor: '#f5d908',
      actionText: ''
    });
    return;
  }
  delete APIConfigDeepClone.limit;
  delete APIConfigDeepClone.page;
  APIConfigDeepClone.where = {
    dimensionNameValueList: [
      {
        dimensionName: 'upc',
        dimensionValue: skuList,
        operator: 'IN'
      }
    ]
  };
  data.ref.rightTableLoad = true;
  HttpLayer.post({
    APIData: APIConfigDeepClone
  })
    .then((response) => {
      result = transformer.mergeResultDimension(response.data, true);
      let keywordTextLeft = skuList.replace(/'/g, '');
      result.forEach(({ upc }) => {
        keywordTextLeft = keywordTextLeft.replace(upc, '');
      });
      // removing comma from end and start and then replacing all adjacent commas in between with link break.
      data.enterListRef.keywordTextInput = keywordTextLeft
        .replace(/^,+|,+$/g, '')
        .replace(/(,)\1*/g, '\n');
    })
    .finally(() => {
      data.ref.$snackbar.open({
        message: result?.length
          ? 'Added SKU(s) from the list'
          : 'Ineligible/Invalid SKU(s) were not added',
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ''
      });
      data.ref.addEntityFromRawObjectArray(result);
      data.ref.rightTableLoad = false;
    });
};

const customActionPanelCustomState = (context) => ({
  component: 'customActionPanel',
  props: {
    widgetsConfig: {
      config: (vueRef) => {
        return {
          primaryKey: 'upc',
          customActionPanelContainerClass:
            'u-height-100 campaign-creation-add-section-min-height',
          isMultipleAllowed: true,
          default: {
            hideHeader: true,
            hideFooter: true,
            noRowsRightTable: "Add SKU's to this list",
            selections: '',
            noRowsLeftTable: "No SKU's sources found",
            leftBtnLabel: 'Apply',
            rightBtnLabel: 'Cancel',
            warningMessage: 'Multiple line items are not allowed'
          },
          left: {
            rowHeight: 68,
            tabs: {
              "All SKU's": {
                columnDefs: selectSkuLeftTable(vueRef),
                search: {
                  enableSearch: true,
                  searchKey: 'search_text'
                },
                showFilterCheckBox: false
              },
              'Enter List': {
                disableFilter: true,
                ui_component: {
                  name: 'enterListPanel',
                  props: {
                    actionName: 'SKU',
                    buttonLabel: 'Add SKU',
                    emitTextInput: true
                  },
                  events: {
                    updateTextInput(data) {
                      addSkuOnInput(data);
                    }
                  }
                }
              }
            },
            isColumnDefAutoConfigure: true,
            table: 'left',
            meta: {
              localFilters: [],
              type: 'table',
              action: 'fetchTableDataExecuteApi',
              updateRows: (vueRef) => {
                const stepsData =
                  { ...fetchSavedState(context, STEPS_DATA_GETTER) } || {};
                const addedSkus = stepsData.tempAdGroup?.products || [];
                if (addedSkus.length) {
                  vueRef.rightTableRows = addedSkus;
                }
              },
              paginationAction: 'fetchTableDataExecuteApi'
            },
            footer: {
              show: false
            },
            body: {
              APIConfig: cloneDeep(APIConfig),
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              columnDefs: selectSkuLeftTable(vueRef)
            },
            headerText: "Add SKU's"
          },
          right: {
            rowHeight: 68,
            table: 'right',
            searchKey: 'search',
            footer: {
              show: false
            },
            isColumnDefAutoConfigure: true,
            body: {
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              APIConfig: {
                limit: 10,
                page: 1
              },
              columnDefs: selectSkuRightTable(vueRef)
            },
            headerText: () => `Added SKU's (${vueRef.rightTableRows.length})`
          }
        };
      }
    }
  },
  events: {
    rightTableRows(rows) {
      const stepsData =
        { ...fetchSavedState(context, STEPS_DATA_GETTER) } || {};
      stepsData.tempAdGroup.products = rows;
      updateSavedState(context, STEPS_DATA_SETTER, stepsData);
    }
  }
});

export const AD_GROUP_STEP = {
  name: 'Ad Group',
  header: {
    title: 'Ad Group',
    desc: [
      'Ad groups are a way to organize, manage, and track performance of the products within your campaign. Give your ad group a name that is descriptive and meaningful to you, based on the products you want to advertise. For example, by category, like “Barbeque grills.”        '
    ]
  },
  textMapping: 'adGroup',
  component: CreateAdGroup,
  moduleClass: 'campaign-creation-component-height-class',
  customActionPanelContainerClass:
    'u-height-100 campaign-creation-add-section-min-height',
  customActionPanelCustomState: customActionPanelCustomState
};

export const MANUAL_AD_GROUPS_STEPS = [
  AD_GROUP_STEP,
  {
    name: 'Targeting',
    header: {
      title: 'What are the keywords you would like to target?',
      desc: [
        'Your keywords (word combinations and phrases) are used to match your ads with search terms shoppers are using to find products.'
      ]
    },
    textMapping: 'targeting',
    component: Targeting
  }
];

export const OPTIMIZED_AD_GROUPS_STEPS = [AD_GROUP_STEP];

export const OBJECTIVE_TYPES = {
  acquire: {
    label: 'Acquire',
    type: 'acquire'
  },
  maximize_sales: {
    label: 'Maximize Sales',
    type: 'maximize_sales'
  },
  other: {
    label: 'Build your own',
    type: 'other'
  }
};

export const CAMPAIGN_OBJECTIVES = [
  {
    type: OBJECTIVE_TYPES.acquire.type,
    label: OBJECTIVE_TYPES.acquire.label,
    desc: 'Convert new to brand customers'
  },
  {
    type: OBJECTIVE_TYPES.maximize_sales.type,
    label: OBJECTIVE_TYPES.maximize_sales.label,
    desc: 'Get customers to purchase your products'
  },
  {
    type: OBJECTIVE_TYPES.other.type,
    label: OBJECTIVE_TYPES.other.label,
    desc: 'Mix and match between “Acquire”, “Maximize Sales” or “Manual” bidding'
  }
];

export const BUDGET_TYPES = {
  dailyBudget: {
    label: 'Daily Budget',
    type: 'dailyBudget'
  }
};

export const CAMPAIGN_BUDGET_TYPES = [
  {
    type: BUDGET_TYPES.dailyBudget.type,
    label: BUDGET_TYPES.dailyBudget.label,
    desc: ''
  }
];

export const BID_TYPES = {
  acquire_ntb: {
    label: 'Optimize, Boost New to Brand',
    type: 'acquire_ntb'
  },
  max_sales: {
    label: 'Optimize, Maximize Sales',
    type: 'max_sales'
  },
  manual: {
    label: 'Manual',
    type: 'manual'
  }
};

export const CAMPAIGN_BID_TYPES = (value = 'default') => {
  const bidTypesConfig = {
    acquire: [
      {
        type: BID_TYPES.acquire_ntb.type,
        label: BID_TYPES.acquire_ntb.label,
        desc: 'Boost sales from new-to-brand customers while continuing to maximize sales at a low cost per click'
      }
    ],
    maximize_sales: [
      {
        type: BID_TYPES.max_sales.type,
        label: BID_TYPES.max_sales.label,
        desc: 'Maximize sales with continual bid optimization'
      },
      {
        type: BID_TYPES.manual.type,
        label: BID_TYPES.manual.label,
        desc: 'Set your own CPC bids and optional override bids for specific keywords.'
      }
    ],
    other: [
      {
        type: BID_TYPES.acquire_ntb.type,
        label: BID_TYPES.acquire_ntb.label,
        desc: 'Boost sales from new-to-brand customers while continuing to maximize sales at a low cost per click.'
      },
      {
        type: BID_TYPES.max_sales.type,
        label: BID_TYPES.max_sales.label,
        desc: 'Maximize sales with continual bid optimization'
      },
      {
        type: BID_TYPES.manual.type,
        label: BID_TYPES.manual.label,
        desc: 'Set your own CPC bids and optional override bids for specific keywords.'
      }
    ],
    default: [
      {
        type: BID_TYPES.acquire_ntb.type,
        label: BID_TYPES.acquire_ntb.label,
        desc: 'Boost sales from new-to-brand customers while continuing to maximize sales at a low cost per click.'
      }
    ]
  };

  return bidTypesConfig[value] || bidTypesConfig.default;
};

export const SETTINGS_SIDEBAR_TEXT_MAP = [
  {
    key: 'campaignDates.startDate',
    text: (value) => `Start date: ${moment(value).format('MMM DD, YYYY')}`
  },
  {
    key: 'campaignDates.endDate',
    text: (value) => `End date: ${moment(value).format('MMM DD, YYYY')}`
  },
  {
    key: 'budgetType',
    text: (value) => `Budget Type: ${BUDGET_TYPES[value].label}`
  },
  {
    key: 'dailyBudget',
    text: (value) => `$${value} daily budget`
  },
  {
    key: 'lifetimeBudget',
    text: (value) => `$${value} lifetime budget`
  },
  {
    key: 'bidType',
    text: (value) => `${BID_TYPES[value].label}`
  },
  {
    key: 'minRoas',
    text: (value) => `ROAS: $${value}`
  }
];

export const FAILED_ADGROUPS_COLUMN_DEFINITION = [
  {
    name: 'ad_group_name',
    type: 'STRING',
    uiField: {
      uiLabel: 'Ad Group',
      uiType: 'string',
      uiOrder: '1',
      metadata: {
        toolTipText: '',
        tableColumnName: 'name',
        showOnUi: true,
        isFixed: false,
        width: 200
      }
    }
  },
  {
    name: 'failure_reason',
    type: 'custom',
    uiField: {
      uiLabel: 'Failure Reason',
      uiType: 'custom',
      uiOrder: '2',
      metadata: {
        widget: 'dynamiccellcomponent',
        dynamicCellComponentParams: {
          componentName: 'FailedErrorTextTableCell',
          paramsToProps: (params) => {
            return {
              params,
              errorText: params.data?.failure_reason
            };
          }
        },
        toolTipText: '',
        tableColumnName: 'failure_reason',
        showOnUi: true,
        isFixed: false,
        width: 600
      }
    }
  }
];
