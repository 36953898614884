import Vue from 'vue';
import HttpService from '@/utils/services/http-service';
import transformer from '@/utils/services/data-transformer';

const state = {
  salesFilters: [],
  insightsSalesData: {},
  insightsSalesSelectedFilter: {
    date_range: {
      name: 'Last 90 days'
    },
    sales_group_by: {
      Brand: true
    }
  },
  insightsSalesWhereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  sales_group_by_map: {
    Brand: 'brand',
    Manufacturer: 'manufacturername'
  },
  filtersMapping: {
    sales_manufacturername: 'manufacturername',
    sales_l1: 'l1',
    sales_l2: 'l2',
    sales_l3: 'l3',
    sales_brand: 'brand'
  }
};

const getters = {
  getInsightsSalesSelectedFilter: (state) => {
    return state.insightsSalesSelectedFilter;
  },
  getInsightsSalesData: (state) => {
    return state.insightsSalesData;
  },
  getInsightsSalesSelectedGroupBy: (state) => {
    return Object.keys(state.insightsSalesSelectedFilter.sales_group_by)
      .length > 0
      ? Object.keys(state.insightsSalesSelectedFilter.sales_group_by)[0]
      : 'Brand';
  }
};

const mutations = {
  ISALE_SUCCESS: (state, data) => {
    Vue.set(state.insightsSalesData, data.chartDataKey, {});
    Vue.set(state.insightsSalesData[data.chartDataKey], 'data', {
      data: data.columns,
      xs: data.xs
    });
    Vue.set(
      state.insightsSalesData[data.chartDataKey],
      'columns',
      data.columns
    );
    Vue.set(state.insightsSalesData[data.chartDataKey], 'rows', data.rows);
    Vue.set(state.insightsSalesData[data.chartDataKey], 'load', false);
    if (data.columns.length === 0) {
      Vue.set(state.insightsSalesData[data.chartDataKey], 'noData', true);
    }
  },
  ISALE_RESET: (state, data) => {
    Vue.set(state.insightsSalesData, data.chartDataKey, {});
    Vue.set(state.insightsSalesData[data.chartDataKey], 'data', []);
    Vue.set(state.insightsSalesData[data.chartDataKey], 'columns', []);
    Vue.set(state.insightsSalesData[data.chartDataKey], 'rows', []);
    Vue.set(state.insightsSalesData[data.chartDataKey], 'load', true);
    Vue.set(state.insightsSalesData[data.chartDataKey], 'error', false);
    Vue.set(state.insightsSalesData[data.chartDataKey], 'noData', false);
  },
  ISALE_ERROR: (state, data) => {
    Vue.set(state.insightsSalesData, data.chartDataKey, {});
    Vue.set(state.insightsSalesData[data.chartDataKey], 'columns', []);
    Vue.set(state.insightsSalesData[data.chartDataKey], 'rows', []);
    Vue.set(state.insightsSalesData[data.chartDataKey], 'load', false);
    Vue.set(state.insightsSalesData[data.chartDataKey], 'error', true);
    Vue.set(state.insightsSalesData[data.chartDataKey], 'noData', false);
  },
  ISALE_SET_SELECTED_FILTER: (state, data) => {
    Vue.set(
      state.insightsSalesSelectedFilter,
      data.filterValueKey,
      data.values
    );
    getFiltersInFormat3(state.insightsSalesSelectedFilter, state);
    localStorage.setItem(
      'insights_sales_selected_filters',
      JSON.stringify(state.insightsSalesSelectedFilter)
    );
  }
};

function getFiltersInFormat3(oFilter, state) {
  var where = state.insightsSalesWhereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter) {
    if (i !== 'date_range' && i !== 'sales_group_by') {
      for (var j in oFilter[i]) {
        if (
          typeof oFilter[i][j] === typeof true &&
          state.filtersMapping[i] !== undefined
        ) {
          where.dimensionNameValueList.push({
            dimensionName: state.filtersMapping[i] || i,
            dimensionValue: j
          });
        } else {
          if (
            oFilter[i][j].items &&
            oFilter[i][j].name &&
            state.filtersMapping[i] !== undefined
          ) {
            for (var k in oFilter[i][j].items) {
              where.dimensionNameValueList.push({
                dimensionName:
                  state.filtersMapping[oFilter[i][j].name] ||
                  oFilter[i][j].name,
                dimensionValue: k
              });
            }
          }
        }
      }
    }
  }

  console.log(where);
  state.insightsSalesWhereClause = where;
}

function getTableColumns(context) {
  var selectedGroupBy =
    context.getters.getInsightsSalesSelectedFilter.sales_group_by;

  var _aArray = [];
  var columns = [selectedGroupBy[0], 'Week', 'Revenue', 'Share'];
  var columnNames = ['group_by', 'week', 'effective_revenue', 'revenue_share'];
  var columnWidths = ['200px', '250px', '200px', '200px'];
  for (var i = 0; i < columns.length; i++) {
    _aArray.push({
      prop: columnNames[i],
      sortable: false,
      fixed: false,
      label: columns[i],
      width: columnWidths[i]
    });
  }
  return _aArray;
}

function getChartDataInFormat(data, response) {
  var _aArray = [];
  var _oObj = {};
  if (response === null) {
    return [];
  }
  for (var i = 0; i < response.length; i++) {
    for (var j in response[i]) {
      if (!_oObj[j]) {
        _oObj[j] = [j];
      }
      _oObj[j].push(response[i][j]);
    }
  }

  for (var k in _oObj) {
    _aArray.push(_oObj[k]);
  }

  return _aArray;
}

function getRowSpanNumbers(aArray) {
  var oObj = {};
  var _aReturn = [];

  for (var i = 0; i < aArray.length; i++) {
    if (!oObj[aArray[i].week]) {
      oObj[aArray[i].week] = {};
    }
    if (!oObj[aArray[i].week][aArray[i].group_by]) {
      oObj[aArray[i].week][aArray[i].group_by] = null;
    }

    oObj[aArray[i].week][aArray[i].group_by] = aArray[i];
  }

  for (var j in oObj) {
    var addedCount = false;
    for (var k in oObj[j]) {
      if (addedCount === false) {
        oObj[j][k].count = Object.keys(oObj[j]).length;
        addedCount = true;
      }

      _aReturn.push(oObj[j][k]);
    }
  }

  return _aReturn;
}

const actions = {
  getSalesEstData: (context, data) => {
    context.commit('ISALE_RESET', { chartDataKey: data.chartDataKey });
    data.APIConfig.where = context.state.insightsSalesWhereClause;
    if (!context.state.insightsSalesSelectedFilter.date_range.to) {
      data.APIConfig.where.date = {
        to: context.getters.getDateRangeValues.predictions.to,
        from: context.getters.getDateRangeValues.predictions.from
      };
    } else {
      data.APIConfig.where.date =
        context.state.insightsSalesSelectedFilter.date_range;
    }

    var selectedGroupBy =
      Object.keys(state.insightsSalesSelectedFilter.sales_group_by).length > 0
        ? Object.keys(state.insightsSalesSelectedFilter.sales_group_by)[0]
        : 'Brand';
    data.APIConfig.groupByDimensionsList = [
      context.state.sales_group_by_map[selectedGroupBy]
    ];

    HttpService.post('EXECUTE_CUBE_API', data.APIConfig)
      .then((response) => {
        var _aArray = [];
        var _oObj = {};
        for (var i = 0; i < response.data.response.data.length; i++) {
          var brandLevelData = getChartDataInFormat(
            data,
            response.data.response.data[i].TIMESERIES
          );
          for (var j = 0; j < brandLevelData.length; j++) {
            if (brandLevelData[j][0] === 'effective_revenue') {
              brandLevelData[j][0] =
                response.data.response.data[i].DIMENSION[
                  context.state.sales_group_by_map[selectedGroupBy]
                ];
            } else if (brandLevelData[j][0] === 'week_end_date') {
              brandLevelData[j][0] =
                'week_end_date_' +
                response.data.response.data[i].DIMENSION[
                  context.state.sales_group_by_map[selectedGroupBy]
                ];
            }
            _oObj[brandLevelData[j][0]] = brandLevelData[j];
          }
        }

        for (i in _oObj) {
          _aArray.push(_oObj[i]);
        }

        if (response.data.response.data.length === 0) {
          context.commit('ISALE_SUCCESS', {
            chartDataKey: data.chartDataKey,
            columns: []
          });
        } else {
          var _columns = [];
          var _xs = {};
          for (var k = 0; k < _aArray.length; k++) {
            _columns.push(_aArray[k]);
            if ((_aArray[k][0] || '').indexOf('week_end_date') !== -1) {
              _xs[_aArray[k][0].replace('week_end_date_', '')] = _aArray[k][0];
            }
          }

          var total = _columns.length > 8 ? 8 : _columns.length;

          context.commit('ISALE_SUCCESS', {
            chartDataKey: data.chartDataKey,
            columns: _columns.splice(0, total),
            tag1: response.data.response.data[0].RESULT[data.tag1],
            tag2: response.data.response.data[0].RESULT[data.tag2],
            xs: _xs
          });
        }
      })
      .catch((error) => {
        console.log(error);
        context.commit('ISALE_ERROR', { chartDataKey: data.chartDataKey });
      });
  },
  getShareEstData: (context, data) => {
    context.commit('ISALE_RESET', { chartDataKey: data.chartDataKey });
    data.APIConfig.where = context.state.insightsSalesWhereClause;
    if (!context.state.insightsSalesSelectedFilter.date_range.to) {
      data.APIConfig.where.date = {
        to: context.getters.getDateRangeValues.predictions.to,
        from: context.getters.getDateRangeValues.predictions.from
      };
    } else {
      data.APIConfig.where.date =
        context.state.insightsSalesSelectedFilter.date_range;
    }

    var selectedGroupBy =
      Object.keys(state.insightsSalesSelectedFilter.sales_group_by).length > 0
        ? Object.keys(state.insightsSalesSelectedFilter.sales_group_by)[0]
        : 'Brand';
    data.APIConfig.groupByDimensionsList = [
      context.state.sales_group_by_map[selectedGroupBy]
    ];

    HttpService.post('EXECUTE_CUBE_API', data.APIConfig)
      .then((response) => {
        var _aArray = [];
        var _oObj = {};
        for (var i = 0; i < response.data.response.data.length; i++) {
          var brandLevelData = getChartDataInFormat(
            data,
            response.data.response.data[i].TIMESERIES
          );
          for (var j = 0; j < brandLevelData.length; j++) {
            if (brandLevelData[j][0] === 'effective_revenue_share_percentage') {
              brandLevelData[j][0] =
                response.data.response.data[i].DIMENSION[
                  context.state.sales_group_by_map[selectedGroupBy]
                ];
            } else if (brandLevelData[j][0] === 'week_end_date') {
              brandLevelData[j][0] =
                'week_end_date_' +
                response.data.response.data[i].DIMENSION[
                  context.state.sales_group_by_map[selectedGroupBy]
                ];
            }
            if (brandLevelData[j][0] !== 'effective_revenue') {
              _oObj[brandLevelData[j][0]] = brandLevelData[j];
            }
          }
        }

        for (i in _oObj) {
          _aArray.push(_oObj[i]);
        }

        if (response.data.response.data.length === 0) {
          context.commit('ISALE_SUCCESS', {
            chartDataKey: data.chartDataKey,
            columns: []
          });
        } else {
          var _columns = [];
          var _xs = {};
          for (var k = 0; k < _aArray.length; k++) {
            _columns.push(_aArray[k]);
            if ((_aArray[k][0] || '').indexOf('week_end_date') !== -1) {
              _xs[_aArray[k][0].replace('week_end_date_', '')] = _aArray[k][0];
            }
          }

          var total = _columns.length > 8 ? 8 : _columns.length;
          context.commit('ISALE_SUCCESS', {
            chartDataKey: data.chartDataKey,
            columns: _columns.splice(0, total),
            tag1: response.data.response.data[0].RESULT[data.tag1],
            tag2: response.data.response.data[0].RESULT[data.tag2],
            xs: _xs
          });
        }
      })
      .catch((error) => {
        console.log(error);
        context.commit('ISALE_ERROR', { chartDataKey: data.chartDataKey });
      });
  },
  getSalesShareSummaryData: (context, data) => {
    context.commit('ISALE_RESET', { chartDataKey: data.chartDataKey });
    data.APIConfig.where = context.state.insightsSalesWhereClause;
    if (!context.state.insightsSalesSelectedFilter.date_range.to) {
      data.APIConfig.where.date = {
        to: context.getters.getDateRangeValues.predictions.to,
        from: context.getters.getDateRangeValues.predictions.from
      };
    } else {
      data.APIConfig.where.date =
        context.state.insightsSalesSelectedFilter.date_range;
    }

    var selectedGroupBy =
      Object.keys(state.insightsSalesSelectedFilter.sales_group_by).length > 0
        ? Object.keys(state.insightsSalesSelectedFilter.sales_group_by)[0]
        : 'Brand';
    // var selectedGroupBy = context.getters.getInsightsSalesSelectedFilter['sales_group_by'];
    data.APIConfig.groupByDimensionsList = [
      context.state.sales_group_by_map[selectedGroupBy]
    ];

    HttpService.post('EXECUTE_CUBE_API', data.APIConfig)
      .then((response) => {
        var _aArray = [];
        if (response.data.response.data.length === 0) {
          context.commit('ISALE_SUCCESS', {
            chartDataKey: data.chartDataKey,
            columns: getTableColumns(context),
            rows: _aArray
          });
        } else {
          for (var i = 0; i < response.data.response.data.length; i++) {
            for (
              var j = 0;
              j < response.data.response.data[i].TIMESERIES.length;
              j++
            ) {
              var _aTimeseries = response.data.response.data[i].TIMESERIES[j];
              _aTimeseries.week =
                _aTimeseries.week_start + ' - ' + _aTimeseries.week_end;
              _aTimeseries.share =
                _aTimeseries.effective_revenue_share_percentage;
              _aTimeseries.group_by =
                response.data.response.data[i].DIMENSION[
                  context.state.sales_group_by_map[selectedGroupBy]
                ];
              _aArray.push(_aTimeseries);
            }
          }

          _aArray = getRowSpanNumbers(_aArray);

          context.commit('ISALE_SUCCESS', {
            chartDataKey: data.chartDataKey,
            columns: getTableColumns(context),
            rows: _aArray
          });
        }
      })
      .catch((error) => {
        console.log(error);
        context.commit('ISALE_ERROR', { chartDataKey: data.chartDataKey });
      });
  },
  setInsightsSalesSelectedFilter: (context, data) => {
    context.commit('ISALE_SET_SELECTED_FILTER', data);
  },
  updateSalesFilters: (context, data) => {
    setTimeout(function () {
      var filters = transformer.getUniqueFilters(
        context.state.salesFilters || [],
        'sales_',
        data.mapping
      );
      context.commit('SET_FILTER_DATA', filters);
    }, 1500);
  },
  getSalesFilters: (context, data) => {
    var postObj = {
      cubeName: 'brands_daily_sales_estimations',
      measuresList: [],
      groupByDimensionsList: ['l1', 'brand', 'l2', 'l3', 'manufacturername'],
      timeseriesDimension: 'FEED_DATE',
      where: {
        date: {
          // 'from': '2017-01-01',
          // 'to': context.getters.getTodaysDate
        },
        dimensionNameValueList: []
      },
      timeseriesEnabled: false,
      pvpenabled: false,
      yoyenabled: false
    };
    // postObj.where = context.state.insightsSalesWhereClause;

    HttpService.post('EXECUTE_CUBE_API', postObj)
      .then((response) => {
        context.state.salesFilters = response.data.response.data;
        context.commit('SET_FILTER_DATA', {
          sales_group_by: [
            {
              title: 'Brand'
            },
            {
              title: 'Manufacturer'
            }
          ]
        });
        context.commit(
          'SET_FILTER_DATA',
          transformer.getUniqueFilters(response.data.response.data, 'sales_')
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
