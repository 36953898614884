var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-flex-justify-content-flex-start u-spacing-p-s"},[_c('div',[(
          [_vm.SKU_STATE.IMPROVED, _vm.SKU_STATE.NEGATIVE].includes(
            _vm.productDetails.state
          )
        )?_c('rb-icon',{class:{
          'u-color-orange-base': _vm.SKU_STATE.IMPROVED === _vm.productDetails.state,
          'u-color-red-base': _vm.SKU_STATE.NEGATIVE === _vm.productDetails.state
        },attrs:{"icon":"warning"}}):_vm._e()],1),_c('div',{staticClass:"name-description-container"},[_c('p',{staticClass:"u-spacing-p-s"},[_vm._v(" "+_vm._s(_vm.productDetails.currentContent)+" ")]),_c('span',{staticClass:"u-spacing-p-xs u-spacing-ml-s u-font-size-7 u-font-weight-600",class:{
          'u-bg-color-yellow-base u-color-grey-base u-border-radius-m':
            _vm.productDetails.status === 'pending',
          'u-bg-color-blue-base u-color-white-base u-border-radius-m':
            _vm.productDetails.status === 'ticketRaised'
        }},[_vm._v(" "+_vm._s(_vm.SKU_STATUS[_vm.productDetails.status])+" ")])]),_c('div',{on:{"click":function($event){return _vm.onProductNameDescriptionEdit(_vm.productDetails)}}},[(
          [_vm.SKU_STATE.IMPROVED, _vm.SKU_STATE.NEGATIVE].includes(
            _vm.productDetails.state
          )
        )?_c('rb-icon',{staticClass:"u-color-blue-base rb-icon--medium",attrs:{"icon":"pencil"}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }