<template>
  <!-- Add Negative Keywords Panel -->
  <section class="u-bg-color-grey-white u-position-relative">
    <div
      class="u-display-flex u-width-100 u-spacing-ph-m u-flex-justify-content-space-between u-flex-direction-column"
    >
      <div
        v-if="heading"
        class="u-spacing-pb-s u-font-weight-600 u-font-size-5"
      >
        {{ heading }}
      </div>
      <div v-if="isMatchTypeEnabled">
        <rbRadioGroup
          :value="matchType"
          :radio-options="matchTypeOptions"
          group-label="Match Type:"
          @onRadioChanged="handleRadioChange"
        />
      </div>
      <rb-input
        ref="searchBox"
        v-model="keywordTextInput"
        rows="2"
        :placeholder="
          inputPlaceHolder || `Enter ${actionName}s seperated by new line`
        "
        type="textarea"
        class="u-flex-1 search--input-s input-text-height"
        :rounded="false"
      />
      <div
        class="u-width-100 u-display-flex u-flex-justify-content-space-between u-flex-direction-row u-spacing-pv-s"
      >
        <div class="u-font-size-7">
          <span v-if="inputInfo">
            {{ inputInfo }}
          </span>
          <span v-else>
            You may add upto {{ entityCount }} {{ actionName }}s.
          </span>
        </div>
        <div class="u-spacing-pt-m">
          <rb-button
            :click-fn="addKeywordsFn"
            :disabled="isAddKeywordsDisabled"
            :text="buttonLabel"
            size="s"
            icon-left="right-arrow"
            type="filled"
            class="u-color-grey-white"
            style="border-left: none"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import rbRadioGroup from '@/components/ams/campaign_creation/steps/common/rbRadioGroupSubLabel.vue';
import { eventBus } from '@/utils/services/eventBus';
import { specialCharacterCheck } from '@/utils/helpers/utils.js';
export default {
  name: 'EnterListPanel',
  components: {
    rbRadioGroup
  },
  props: {
    emitTextInput: {
      type: Boolean,
      default: false
    },
    emitAtEnd: {
      type: Boolean,
      default: false
    },
    parentRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    inputInfo: {
      type: String,
      default: null
    },
    inputPlaceHolder: {
      type: String,
      default: null
    },
    actionName: {
      type: String,
      default: 'keyword'
    },
    buttonLabel: {
      type: String,
      default: 'Add keywords'
    },
    filterCheckBoxArray: {
      type: Array,
      default: function () {
        return [];
      }
    },
    isTarget: {
      // For add custom negative targets in campaign edit
      type: Boolean,
      default: false
    },
    entityCount: {
      type: Number,
      default: 1000
    },
    heading: {
      type: String,
      default: ''
    },
    isMatchTypeEnabled: {
      type: Boolean,
      default: false
    },
    allowSpecialCharacters: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      keywordTextInput: '',
      matchType: 'exact'
    };
  },
  computed: {
    isAddKeywordsDisabled() {
      if (
        this.parentRef.showLeftFilterCheckBox &&
        this.parentRef.leftConfigFilterCheckBoxSource.items &&
        this.filterCheckBoxArray.length === 0
      ) {
        return true;
      }

      return (
        this.parentRef.suggestedCustomBidError ||
        this.keywordTextInput.length === 0
      );
    }
  },
  created() {
    this.matchTypeOptions = [
      { label: 'Exact', value: 'exact', type: 'exact' },
      { label: 'Phrase', value: 'phrase', type: 'phrase' }
    ];
    this.matchType = this.matchTypeOptions[0].type;
  },

  methods: {
    handleRadioChange(newVal) {
      this.matchType = newVal;
      eventBus.$emit('matchTypeChange', this.matchType);
    },
    getMatchType(filterValue) {
      switch (filterValue.toLowerCase()) {
        case 'exact':
          return 'exact';
        case 'phrase':
          return 'phrase';
        case 'broad':
          return 'broad';
        case 'negative exact':
          return 'negativeExact';
        case 'negative phrase':
          return 'negativePhrase';
        case 'negative broad':
          return 'negativeBroad';
        case 'targeting expression':
          return 'targetingExpression';
        default:
          return 'exact';
      }
    },
    getMatchTypeToShow(selectedMatchType) {
      switch (selectedMatchType.toLowerCase()) {
        case 'targeting expression':
          return 'TARGETING_EXPRESSION';
        case 'negative phrase':
          return 'Negative Phrase';
        case 'negative exact':
          return 'Negative Exact';
      }
    },
    rowWithSameKeyAlreadyPresent(key) {
      for (const item of this.parentRef.rightTableRows) {
        if (
          (item?.key && item.key.toLowerCase() === key.toLowerCase()) ||
          (item?.key === key &&
            this.parentRef?.config?.left?.tabs?.['Enter List']?.onSkipFilter)
        ) {
          return true;
        }
      }
      return false;
    },
    addKeywordsFn() {
      let keywords = this.keywordTextInput.split(/\r?\n/);
      if (
        !this.allowSpecialCharacters &&
        specialCharacterCheck(this.keywordTextInput)
      ) {
        this.snackbar('Special characters are not allowed', 6000);
        return;
      }
      keywords = keywords.filter(Boolean);
      const uniqueKeywords = [];
      let keywordsExist = false;
      let duplicatePresent = false;
      let newDataArray = [];
      for (const value of keywords) {
        if (uniqueKeywords.indexOf(value) === -1) {
          uniqueKeywords.push(value?.trim());
        } else {
          duplicatePresent = true;
        }
      }
      if (this.emitTextInput && !this.emitAtEnd) {
        this.$emit('updateTextInput', {
          keywords: uniqueKeywords,
          ref: this.parentRef,
          filterCheckBoxArray: this.filterCheckBoxArray,
          enterListRef: this
        });
        return;
      }

      if (
        this.parentRef?.showLeftFilterCheckBox &&
        this.parentRef?.leftConfigFilterCheckBoxSource?.items &&
        !this.parentRef?.config?.left?.tabs?.['Enter List'].onSkipFilter
      ) {
        const selectedFilterCheckBox = this.filterCheckBoxArray;
        for (const uniqueWord of uniqueKeywords) {
          // const uniqueWord = uniqueKeywords[i];
          for (const selectedMatchType of selectedFilterCheckBox) {
            const key = uniqueWord + '&' + this.getMatchType(selectedMatchType);
            if (!this.rowWithSameKeyAlreadyPresent(key)) {
              // the creation of this logic can be moved outside to the config somehow. As the structure of the table
              // can differ from scenarios
              const data = {
                key,
                broad_bid: null,
                exact_bid: null,
                keyword_type: 'GENERIC',
                phrase_bid: null,
                recommended_keyword: uniqueWord,
                keyword: uniqueWord,
                match_type: this.getMatchType(selectedMatchType),
                matchTypeToShow: this.getMatchTypeToShow(selectedMatchType),
                bid: '-',
                newBid: null
              };
              if (this.parentRef?.leftCustomBid?.show) {
                data.newBid = this.parentRef?.suggestedCustomBidInput;
              }
              if (this.isTarget) {
                data.recommended_target = `<b>Product:</b> ${uniqueWord}`;
              }
              newDataArray.push(data);
            } else {
              keywordsExist = true;
            }
          }
        }
      }

      if (this.parentRef?.config?.left?.tabs?.['Enter List']?.onSkipFilter) {
        const returnedObj = this.parentRef.config.left.tabs?.[
          'Enter List'
        ].onSkipFilter(this, uniqueKeywords, newDataArray, keywordsExist);
        newDataArray = returnedObj.newDataArray;
        keywordsExist = returnedObj.keywordsExist;
      }

      if (newDataArray.length >= this.entityCount) {
        const remainingKeywords = newDataArray.length - this.entityCount;
        newDataArray = newDataArray.slice(0, this.entityCount);
        this.snackbar(
          'Unable to add the last ' +
            remainingKeywords +
            ` keywords (You may add upto ${this.entityCount} ` +
            this.actionName +
            's' +
            ')',
          6000
        );
      }
      const keywordOrTarget = this.isTarget ? 'target' : 'keyword';
      if (keywordsExist && duplicatePresent) {
        this.snackbar(
          `Duplicate ${keywordOrTarget}(s) removed, keyword(s) & match-type(s) combination already exists.`,
          6000
        );
      } else if (keywordsExist) {
        this.snackbar(
          `${keywordOrTarget}(s) & match-type(s) combination already exists.`,
          6000
        );
      } else if (duplicatePresent) {
        this.snackbar(`Duplicate ${keywordOrTarget}(s) removed.`, 6000);
      }
      if (this.emitTextInput && this.emitAtEnd) {
        this.$emit('updateTextInput', {
          keywordsArray: newDataArray,
          ref: this.parentRef,
          filterCheckBoxArray: this.filterCheckBoxArray,
          enterListRef: this
        });
      } else {
        this.parentRef.addEntityFromRawObjectArray(newDataArray);
        this.keywordTextInput = '';
      }
    },
    snackbar(message, timer) {
      this.$snackbar.open({
        message: message,
        duration: timer,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    }
  }
};
</script>
