export default {
  chartConfig: {
    chartOptions: {
      legend: false,
      xFormat: '%m/%d/%Y',
      timeseries: 'report_date',
      tooltip_format: {},
      axes: {},
      // 'axis_format': {
      //   y: {},
      //   y2: {}
      // },
      grid: 'xy',
      hasEvents: false,
      type: 'line',
      types: {},
      xAxisType: 'category',
      stack: [],
      chartGetter: '',
      show_axis_colors: true,
      events: [],
      dynamicRange: true,
      size: {
        height: 450
      }
    },
    stack: [],
    chartGetter: ''
  },
  eventsIconMap: {
    out_of_stock: {
      icon: 'out-of-stock',
      color: 'u-color-yellow-base'
    },
    has_3p_variant: {
      icon: 'p-variant',
      color: 'u-color-red-base'
    },
    is_addon: {
      icon: 'add-on',
      color: 'u-color-blue-base'
    },
    is_prime_exclusive: {
      icon: 'prime-exclusive',
      color: 'u-color-cyan-dark'
    },
    client_variant_change: {
      icon: 'change_in_variants',
      color: 'u-color-indigo-base'
    },
    availability: {
      icon: 'availability',
      color: 'u-color-turquoise-base'
    },
    unavailable: {
      icon: 'availability',
      color: 'u-color-turquoise-base'
    },
    search_entry: {
      icon: 'search-entry',
      color: 'u-color-pink-base'
    },
    content_change: {
      icon: 'content-change',
      color: 'u-color-green-light'
    },
    lostbuybox: {
      icon: 'lost-buybox',
      color: 'u-color-blue-dark'
    },
    promotions: {
      icon: 'promotions',
      color: 'u-color-orange-dark'
    },
    search_drop: {
      icon: 'search-exit',
      color: 'u-color-indigo-xxx-light'
    },
    bestseller: {
      icon: 'best-seller',
      color: 'u-color-orange-base'
    },
    amazon_choice_badge: {
      icon: 'amazon_choice',
      color: 'u-color-grey-base'
    },
    subscribe_save: {
      icon: 'subscribe-save',
      color: 'u-color-purple-base'
    },
    campaign_start_end: {
      icon: 'campaign',
      color: 'u-color-green-dark'
    },
    suppressed: {
      icon: 'suppressed',
      color: 'u-color-green-base'
    }
  }
};
