import Vue from 'vue';

export const state = {
  healthStatusSummarized: {
    data: {},
    loading: false,
    error: null
  },
  healthStatusDetailed: {
    data: {},
    loading: false,
    error: null
  },
  isDrawerOpen: false,
  expandedHealthGroupIds: [],
  dates: {
    from: null,
    to: null
  }
};

export const getters = {
  healthStatusSummarized: ({ healthStatusSummarized }) =>
    healthStatusSummarized,
  healthStatusDetailed: ({ healthStatusDetailed }) => healthStatusDetailed,
  isDrawerOpen: ({ isDrawerOpen }) => isDrawerOpen,
  expandedHealthGroupIds: ({ expandedHealthGroupIds }) =>
    expandedHealthGroupIds,
  dates: ({ dates }) => dates
};

export const mutations = {
  updateSummarizedHealthStatus(state, data) {
    Vue.set(state, 'healthStatusSummarized', data);
  },
  updateDetailedHealthStatus(state, data) {
    Vue.set(state, 'healthStatusDetailed', data);
  },
  updateIsDrawerOpen(state, data) {
    state.isDrawerOpen = data;
  },
  updateExpandedHealthGroupIds(state, data) {
    Vue.set(state, 'expandedHealthGroupIds', data);
  },
  updateDates(state, data) {
    Vue.set(state, 'dates', data);
  }
};
