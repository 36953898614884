const ciqlocalStorage = {};

ciqlocalStorage.setItem = (key, string) => {
  localStorage.setItem(key, string);
};
ciqlocalStorage.getItem = (key) => {
  return localStorage.getItem(key);
};
ciqlocalStorage.removeItem = (key) => {
  localStorage.removeItem(key);
};
export default {
  install(Vue, options) {
    Vue.prototype.$ciqlocalStorage = ciqlocalStorage;
  }
};
