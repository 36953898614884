import entityDic from '@/pages/entity_details/configs/amazon/dictionary.js';
import { commons } from '../commons';
import utilConfig from '@/components/ams/campaigns/utils.js';
import utils from '@/utils/helpers/';
import { streamMetricTooltip } from '@/components/ams/campaigns/constants.js';

import {
  updateTextInputKeyword,
  keywordRightTable,
  negativeKeywordRightTable,
  keywordTargetingState,
  negativeKeywordTargetingState
} from '@/components/ams/campaign_creation/edit_configs/amazon/common/keywordTargeting.js';
import {
  categoryColumnDefWithoutAutoConfigure,
  getColumnDefinition as getProductAndCategoryColumnDefinition,
  productActionState,
  categoriesActionState
} from '@/components/ams/campaign_creation/edit_configs/amazon/common/productTargeting.js';
import {
  addAudiencesToCampaignActionPanel,
  addCategoriesToAudienceCampaigns
} from '@/pages/entity_details/configs/amazon/actionConfigs.js';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import { adgroupsTargetingProductConfig as adGroupsKeywordTargetingProductSD } from '@/pages/entity_details/configs/amazon/sd.js';
import { cloneDeep } from 'lodash';
import { CubeConfig } from '@/components/ams/campaigns/campaigns/cubeConfig.js';
const {
  map,
  units,
  axes,
  baseMeasuresList,
  getTagData,
  getColumnDefinition,
  createBulkSearchObjectForWidget,
  bulkSearchProps,
  openBulkSearchHelperPopup,
  createSelectedMetricsList,
  getMetricsListForChartingWb
} = utilConfig('amazon', 'campaign');

const ActionType = {
  AUDIENCE: 'audience',
  KEYWORD: 'keyword',
  PRODUCT: 'product'
};

function updateTextInput(data) {
  const customBid = data?.ref?.suggestedCustomBidInput;
  if (customBid <= 0 || customBid > 1000) {
    data?.ref?.$snackbar.open({
      message: 'Please add a vaild custom bid',
      duration: 6000,
      buttonColor: '#f5d908',
      actionText: ''
    });
    return;
  }
  const keywords = data?.keywords;
  const addToRightKeywordsTable = [];
  for (const item of keywords) {
    const target = `ASIN : ${item}`;
    let newKeyword = true;
    for (const rightTableData of data.ref.rightTableRows) {
      if (rightTableData?.entity_ui_label === target) {
        newKeyword = false;
      }
    }
    if (newKeyword) {
      addToRightKeywordsTable.push({
        suggested_bid: '-',
        new_bid: customBid,
        entity_ui_label: target,
        competitor_asin: item
      });
    } else {
      data?.ref?.$snackbar.open({
        message: 'One or more Products already exists in Added Targets table',
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ''
      });
    }
  }
  data.ref.addEntityFromRawObjectArray(addToRightKeywordsTable);
}

const getNegativeKeywordTargeting = (context) => {
  const negativeKeywordTargeting = {
    ...negativeKeywordTargetingState(context)
  };
  const getConfig = (arg) =>
    negativeKeywordTargetingState(context).props.widgetsConfig.config(arg);
  negativeKeywordTargeting.props.widgetsConfig.config = (arg) => {
    const config = getConfig(arg);
    delete config.left.tabs.Suggested;
    config.left.tabs['Enter List'].filterCheckBoxSource.items = [
      'Negative Exact',
      'Negative Phrase'
    ];
    delete config.left.meta.updateRows;
    delete config.left.body.APIConfig;
    config.right.body.columnDefs = negativeKeywordRightTable(arg);
    return config;
  };
  negativeKeywordTargeting.events.rightTableRows = (rows) => {
    context.$parent.resultTableData = rows;
  };
  return negativeKeywordTargeting;
};

const workbenchCampaignsPlotSelectedRows = {
  action: 'getWorkbenchCampaignsChartData',
  transform: function (config, data, that) {
    config.meta.plotSelections = [];
    if (data && data.length > 0) {
      for (const element of data) {
        config.meta.plotSelections.push({
          dimensionName: 'campaign_id',
          operator: 'ILIKE',
          dimensionValue: element.campaign_id
        });
      }
    }
    return config;
  }
};
const workbenchCampaignsSearchTriggered = {
  action: 'getWorkbenchCampaignsChartData',
  transform: function (config, data, that) {
    config.meta.plotSelections = [];
    if (data !== undefined) {
      config.meta.localFilters = [];
      if (data.length > 0) {
        config.meta.localFilters.push({
          dimensionName: 'search',
          operator: 'ILIKE',
          dimensionValue: data
        });
      }
    }
    return config;
  }
};

const negativeProductTargeting = (context) => ({
  component: 'customActionPanel',
  props: {
    widgetsConfig: {
      config: (vueRef) => {
        return {
          primaryKey: 'entity_ui_label',
          isMultipleAllowed: true,
          default: {
            hideHeader: true,
            hideFooter: true,
            noRowsRightTable: 'Add Targets to this list',
            selections: '',
            noRowsLeftTable: 'No Target sources found',
            leftBtnLabel: 'Apply',
            rightBtnLabel: 'Cancel',
            warningMessage: 'Multiple Target are not allowed'
          },
          left: {
            rowHeight: 68,
            tabs: {
              'Enter List': {
                ui_component: {
                  name: 'enterListPanel',
                  props: {
                    actionName: 'target',
                    inputPlaceHolder: 'Enter ASINs separated by new line',
                    buttonLabel: 'Add ASINs',
                    inputInfo: 'You may add upto 1000 ASINs',
                    emitTextInput: true
                  },
                  events: {
                    updateTextInput
                  }
                },
                showFilterCheckBox: false,
                filterCheckBoxSource: {
                  header: 'Filter by:',
                  items: ['Broad', 'Phrase', 'Exact']
                }
              }
            },
            isColumnDefAutoConfigure: true,
            table: 'left',
            meta: {
              type: 'table',
              action: 'fetchTableDataExecuteApi',
              localFilters: [],
              paginationAction: 'fetchTableDataExecuteApi'
            },
            footer: {
              show: false
            },
            body: {
              useExistingWhereClause: true,
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              }
            },
            headerText: 'Add Negative Products'
          },
          right: {
            table: 'right',
            searchKey: 'search',
            footer: {
              show: false
            },
            isColumnDefAutoConfigure: false,
            body: {
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              APIConfig: {
                limit: 50,
                page: 1
              },
              columnDefs: getProductAndCategoryColumnDefinition(
                vueRef,
                entityDic.add_negative_targets_sp_and_sb.right(vueRef)
              )
            },
            headerText: () => `Added (${vueRef.rightTableRows.length})`
          },
          apply(rows = []) {}
        };
      }
    }
  },
  events: {
    rightTableRows(rows) {
      context.$parent.resultTableData = rows;
    },
    afterAPIActionToCall(payload) {
      this.$emit('open.open', 'Action has been submitted');
    },
    closePanelMethod() {}
  }
});

const getKeywordTargeting = (context) => {
  const keywordTargeting = { ...keywordTargetingState(context) };
  const getConfig = (arg) =>
    keywordTargetingState(context).props.widgetsConfig.config(arg);
  keywordTargeting.props.widgetsConfig.config = (arg) => {
    const config = getConfig(arg);
    delete config.left.tabs.Suggested;
    config.left.tabs['Enter List'].ui_component.events.updateTextInput = (
      data
    ) => {
      updateTextInputKeyword(data, 0.02, 1000);
    };
    delete config.left.meta.updateRows;
    delete config.left.body.APIConfig;
    config.right.body.columnDefs = keywordRightTable(arg);
    return config;
  };
  keywordTargeting.events.rightTableRows = (rows) => {
    context.$parent.resultTableData = rows;
    context.$parent.checkTableValidity();
  };
  return keywordTargeting;
};

const getProductTargeting = (context) => {
  const productTargeting = { ...productActionState(context) };
  const getConfig = (arg) =>
    productActionState(context).props.widgetsConfig.config(arg);
  productTargeting.props.widgetsConfig.config = (arg) => {
    const config = getConfig(arg);
    delete config.left.tabs.Suggested;
    config.left.meta.updateRows = (vueRef) => {
      vueRef.rightTableRows = context.$parent.resultTableData;
    };
    delete config.left.body.APIConfig;
    delete config.left.body.columnDefs;
    return config;
  };
  productTargeting.events.rightTableRows = (rows) => {
    context.$parent.resultTableData = rows;
  };
  return productTargeting;
};

const getCategoriesTargeting = (context) => {
  const categoriesTargeting = { ...categoriesActionState(context) };
  const getConfig = (arg) =>
    categoriesActionState(context).props.widgetsConfig.config(arg);
  categoriesTargeting.props.widgetsConfig.config = (arg) => {
    const config = getConfig(arg);
    delete config.left.tabs.Suggested;
    delete config.left.tabs.Search.columnDefs;
    delete config.left.meta.action.Suggested;
    config.left.meta.updateRows = (vueRef) => {
      vueRef.rightTableRows = context.$parent.resultTableData;
    };
    delete config.left.meta.allSkus;
    delete config.left.body.APIConfig;
    config.left.body.columnDefs = categoryColumnDefWithoutAutoConfigure(arg);
    return config;
  };
  categoriesTargeting.events.rightTableRows = (rows) => {
    context.$parent.resultTableData = rows;
  };
  return categoriesTargeting;
};

const getAddAudiencesToCampaignTargeting = (context) => {
  const audienceTargeting = {
    ...addAudiencesToCampaignActionPanel(context, 'sd')
  };
  const getConfig = (arg) =>
    addAudiencesToCampaignActionPanel(context, 'sd').props.widgetsConfig.config(
      arg
    );
  audienceTargeting.props.widgetsConfig.config = (arg) => {
    const config = getConfig(arg);
    config.default.hideFooter = true;
    return config;
  };
  audienceTargeting.events.rightTableRows = (rows) => {
    context.$parent.resultTableData = rows;
  };
  return audienceTargeting;
};

const getAddCategoriesToAudienceCampaignsTargeting = (context, tabName) => {
  const audienceCategoriesTargeting = {
    ...addCategoriesToAudienceCampaigns(
      context,
      'sd',
      tabName,
      context.$parent.selectedLookBack
    )
  };
  const getConfig = (arg) =>
    addCategoriesToAudienceCampaigns(
      context,
      'sd',
      tabName,
      context.$parent.selectedLookBack
    ).props.widgetsConfig.config(arg);
  audienceCategoriesTargeting.props.widgetsConfig.config = (arg) => {
    const config = getConfig(arg);
    config.default.hideFooter = true;
    delete config.left.tabs.Suggested;
    return config;
  };
  audienceCategoriesTargeting.events.rightTableRows = (rows) => {
    context.$parent.resultTableData = rows;
  };
  return audienceCategoriesTargeting;
};

const APIConfigForCampaignsGroups = {
  cubeName: 'adgroup_campaign_profile_data',
  measuresList: [
    'campaign_name',
    'profile_name',
    'adgroup_name',
    'campaign_type',
    'targeting_type',
    'entity_count',
    'adgroup_status',
    'spend',
    'roas',
    'campaign_targeting_type'
  ],
  groupByDimensionsList: ['campaign_id', 'profile_id', 'adgroup_id'],
  where: {
    dimensionNameValueList: []
  }
};

export const groupsTableColDefinition = [
  {
    // currDefinition
    name: 'campaignName',
    type: 'STRING',
    uiField: {
      uiLabel: 'Campaign name',
      uiType: 'string',
      uiOrder: 1,
      metadata: {
        toolTipText: '',
        tableColumnName: 'campaignName',
        showOnUi: true,
        isFixed: false,
        width: 286,
        cellClass: 'hide-header-checkbox'
      }
    }
  },
  {
    // currDefinition
    name: 'select',
    type: 'STRING',
    uiField: {
      uiLabel: 'Select',
      uiType: 'string',
      uiOrder: 2,
      metadata: {
        toolTipText: '',
        tableColumnName: 'select',
        cellClass: 'checkbox-only',
        showOnUi: true,
        isFixed: false,
        width: 71,
        checkboxSelection: true
      }
    }
  },
  {
    // currDefinition
    name: 'ad_group_name',
    type: 'STRING',
    uiField: {
      uiLabel: 'Ad Group Name',
      uiType: 'string',
      uiOrder: 3,
      metadata: {
        toolTipText: '',
        tableColumnName: 'adgroup_name',
        showOnUi: true,
        isFixed: false,
        width: 230
      }
    }
  },
  {
    // currDefinition
    name: 'status',
    type: 'STRING',
    uiField: {
      uiLabel: 'Status',
      uiType: 'string',
      uiOrder: 4,
      metadata: {
        toolTipText: '',
        tableColumnName: 'adgroup_status',
        showOnUi: true,
        isFixed: false,
        width: 76
      }
    }
  },
  {
    // currDefinition
    name: 'total_targets',
    type: 'STRING',
    uiField: {
      uiLabel: 'Total Targets',
      uiType: 'string',
      uiOrder: 5,
      metadata: {
        toolTipText: '',
        tableColumnName: 'entity_count',
        showOnUi: true,
        isFixed: false,
        width: 79
      }
    }
  },
  {
    // currDefinition
    name: 'spend',
    type: 'currency',
    uiField: {
      uiLabel: 'Spend',
      uiType: 'currency',
      uiOrder: 6,
      metadata: {
        toolTipText: '',
        tableColumnName: 'spend',
        showOnUi: true,
        isFixed: false,
        width: 113
      }
    }
  },
  {
    // currDefinition
    name: 'roas',
    type: 'currency',
    uiField: {
      uiLabel: 'ROAS',
      uiType: 'currency',
      uiOrder: 7,
      metadata: {
        toolTipText: '',
        tableColumnName: 'roas',
        showOnUi: true,
        isFixed: false,
        width: 69
      }
    }
  }
];

const audienceTargetingUtilObject = {
  getExpressionType: (dynamicSegment, isAudience, keyword) => {
    return (
      dynamicSegment ||
      (isAudience && 'audienceSameAs') ||
      (keyword?.categoryId ? 'asinCategorySameAs' : 'asinSameAs')
    );
  },
  getEntityType: (dynamicSegment, entityType, keyword) => {
    return (
      dynamicSegment ||
      entityType ||
      (keyword?.categoryId ? 'Category' : 'Product')
    );
  },
  getAsinTitle: (audienceId, categoryUI, keyword) => {
    return audienceId || keyword?.categoryId
      ? categoryUI
      : keyword.competitor_asin_title;
  },
  getValue: (dynamicSegment, audienceId, keyword) => {
    return (
      dynamicSegment ||
      audienceId ||
      keyword?.categoryId ||
      keyword?.competitor_asin ||
      'NA'
    );
  },
  getAsinId: (newKeywordText, keyword) => {
    return (
      newKeywordText || keyword.categoryId || keyword.competitor_asin || 'NA'
    );
  },
  getEntityText: (dynamicSegment, audienceId, keyword, categoryUI) => {
    return dynamicSegment || audienceId || keyword?.categoryId
      ? categoryUI
      : keyword.competitor_asin || 'NA';
  },
  getAdGroupName: (adGroup) => {
    return adGroup.adgroup_name || 'NA';
  },
  loopReqValuesAndInsertInTemplate: (reqValues, payloadTemplate) => {
    for (const item in reqValues) {
      deepReplaceObjectValues(payloadTemplate, item, reqValues[item]);
    }
  }
};

const getTagUnitData = (data) => {
  const result = data[0]?.RESULT || {};
  const oReturn = {};

  for (const i in result) {
    const translatedI = map?.[i] || i;
    oReturn[translatedI] = {
      invertTag2: commons.isInverted(i),
      tag1Unit: units[translatedI],
      tag2Unit: { suff: '%' }
    };
  }

  return oReturn;
};

const getNewKeywordText = (dynamicSegment, marketing, keyword) => {
  const dynamicSegmentText = dynamicSegment && `${keyword?.dynamicSegment}`;
  return `${marketing}(${
    dynamicSegmentText || (keyword?.categoryId && 'asinCategorySameAs=')
  }${dynamicSegmentText ? '' : keyword?.categoryId} ${
    keyword.lookback && `lookback=${keyword.lookback}`
  })`;
};

const getMarketingAndEntityType = (
  marketingType,
  isAudience,
  audienceId,
  keyword
) => {
  const entityTypeAndMarketing = {
    views: {
      entityType: 'View Remarketing',
      marketing: 'views'
    },
    purchase: {
      entityType: 'Purchase Remarketing',
      marketing: 'purchases'
    }
  };

  let { entityType, marketing } = entityTypeAndMarketing[marketingType];
  let newKeywordText = null;
  const dynamicSegment = keyword?.isDynamicSegment && keyword?.dynamicSegment;
  if (isAudience) {
    entityType = 'Audience';
    marketing = 'audience';
    newKeywordText = `audienceSameAs=${audienceId}`;
  }

  if (marketing && marketing !== ActionType.AUDIENCE) {
    newKeywordText = getNewKeywordText(dynamicSegment, marketing, keyword);
  }

  return {
    entityType,
    marketing,
    newKeywordText,
    dynamicSegment
  };
};

export default {
  config: (that) => {
    return {
      bulkSearchProps,
      openBulkSearchHelperPopup,
      createSelectedMetricsList,
      getMetricsListForChartingWb,
      getColumnDefinition: (...args) =>
        getColumnDefinition(...args, 'campaign'),
      filters: {
        emit: 'workbenchCampaignsFilterApplied',
        hasSearch: true,
        search: {
          width: '240px',
          enable: true,
          emit: 'workbenchCampaignsSearchTriggered',
          placeholder: 'Search for a Campaign'
        }
      },
      widgets: {
        widget1: {
          map: map,
          meta: {
            type: 'chart',
            key: 'campaignsChart',
            axisFormatMap: units,
            action: 'getWorkbenchCampaignsChartData',
            streamMetricTooltip: streamMetricTooltip,
            listen: {
              workbenchCampaignsFilterApplied: {
                action: 'getWorkbenchCampaignsChartData',
                transform: function (config, data, that) {
                  config.meta.plotSelections = [];
                  return config;
                }
              },
              workbenchCampaignsPlotSelectedRows,
              workbenchCampaignsSearchTriggered,
              ...createBulkSearchObjectForWidget(
                'getWorkbenchCampaignsChartData',
                'chart',
                'campaign'
              ),
              workbenchCampaignsPaginationTriggered: {
                action: 'getWorkbenchCampaignsChartData',
                transform: function (config) {
                  config.meta.plotSelections = [];
                  return config;
                }
              }
            }
          },
          header: {
            show: false
          },
          footer: {
            date: 'ams_campaign'
          },
          body: {
            APIConfig: {
              cubeName: 'ams_campaigns_campaign_workbench',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: true,
              timeseriesDimension: 'report_date',
              timeseriesRollupBy: 'WEEK',
              pvpenabled: true,
              yoyenabled: false,
              pvptimeseriesEnabled: false,
              sharePercentageV2: false,
              splyenabled: false,
              getPointInTimeMetrics: false,
              getSharePercentage: false,
              pointInTimeMetrics: false,
              daterangeDimension: 'report_date',
              measuresList: [
                ...baseMeasuresList,
                'total_sales',
                'pcogs',
                'inventory_latest',
                'avg_selling_price',
                'customer_orders',
                'attributedSalesNewToBrand14d',
                'PercentageSalesNewToBrand14d',
                'attributedOrdersNewToBrand14d',
                'attributedUnitsOrderedNewToBrand14d',
                'estimated_missed_impressions_upper7d',
                'estimated_missed_clicks_upper7d',
                'estimated_missed_sales_upper7d',
                'percent_time_in_budget',
                'acogs',
                'incremental_fraction',
                'iroas',
                'incremental_sales',
                'incrementality_spend',
                'INCREMENTALITY_PAID_SALES_14D',
                'weighted_percent_time_in_budget',
                'cost_denominator'
              ],
              groupByDimensionsList: ['campaign_name', 'campaign_id'],
              customAPIDecisionVars: {
                system: 'amazon_campaign_management'
              },
              where: {},
              orderByList: [
                {
                  dimension: 'spend',
                  direction: 'DESC'
                }
              ]
            },
            SreamAPIConfig: {
              cubeName: 'amazon_hourly_campaigns_stream_workbench',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: true,
              timeseriesDimension: 'hour',
              timeseriesRollupBy: 'WEEK',
              pvpenabled: true,
              yoyenabled: false,
              pvptimeseriesEnabled: false,
              sharePercentageV2: false,
              splyenabled: false,
              getPointInTimeMetrics: false,
              getSharePercentage: false,
              pointInTimeMetrics: false,
              daterangeDimension: 'report_date',
              measuresList: [
                'spend',
                'impressions',
                'clicks',
                'units_1d',
                'units_7d',
                'units_14d',
                'orders_1d',
                'orders_7d',
                'orders_14d',
                'paid_sales_1d',
                'paid_sales_7d',
                'paid_sales_14d',
                'paid_sales',
                'ctr',
                'conversions_1d',
                'conversions_7d',
                'conversions_14d',
                'acos_1d',
                'acos_7d',
                'acos_14d',
                'cpc',
                'halo_sales_1_day',
                'halo_sales_7_day',
                'halo_sales_14_day',
                'roi',
                'average_order_value'
              ],
              groupByDimensionsList: ['campaign_name', 'campaign_id'],
              customAPIDecisionVars: {
                system: 'amazon_campaign_management'
              },
              where: {},
              orderByList: [
                // {
                //   dimension: 'spend',
                //   direction: 'DESC'
                // }
              ]
            },
            getTagUnitData,
            getTagData,
            chart: {
              chartOptions: {
                legend: false,
                type: 'line',
                xFormat: '%m/%d/%Y',
                timeseries: 'report_date',
                tooltip_format: units,
                axes: axes,
                line: {
                  connectNull: true
                },
                show_axis_colors: true,
                axis_format: {
                  y: {},
                  y2: {}
                },
                xDataKey: 'x',
                grid: 'xy',
                realtime: false
              }
            }
          }
        },
        widget2: {
          meta: {
            type: 'table',
            key: 'campaignsTable',
            primaryKey: 'campaign_id',
            action: 'getWorkbenchCampaignsTableData',
            listen: {
              workbenchCampaignsFilterApplied: {
                action: 'getWorkbenchCampaignsTableData',
                transform: function (config) {
                  config.body.APIConfig.page = 1;
                  return config;
                }
              },
              workbenchCampaignsSearchTriggered: {
                action: 'getWorkbenchCampaignsTableData',
                transform: function (config, data, that) {
                  config.body.APIConfig.page = 1;
                  if (data !== undefined) {
                    config.meta.localFilters = [];
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    }
                  }
                  return config;
                }
              },
              ...createBulkSearchObjectForWidget(
                'getWorkbenchCampaignsTableData',
                'table',
                'campaign'
              )
            },
            paginationAction: 'workbenchCampaignsPaginationTriggered'
          },
          header: {
            show: false
          },
          footer: {
            date: 'ams_campaign'
          },
          body: {
            metadata: utils.internalUserCheck(window.user) && {
              primaryKey: 'campaign_id',
              stateKey: 'campaignsRealTimeMetadata',
              apiConfig: {
                cubeName: 'ams_campaigns_campaign_workbench_metadata',
                measuresList: ['campaign_id', 'base_budget', 'campaign_status'],
                groupByDimensionsList: ['campaign_id'],
                orderByList: [
                  {
                    dimension: 'campaign_status',
                    direction: 'DESC'
                  }
                ],
                where: {},
                tagWhereClause: []
              }
            },
            stream: {
              cubeName: 'amazon_hourly_campaigns_stream_workbench',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: false,
              timeseriesDimension: 'hour',
              timeseriesRollupBy: 'DAY',
              pvpenabled: false,
              yoyenabled: false,
              pvptimeseriesEnabled: false,
              sharePercentageV2: false,
              splyenabled: false,
              getPointInTimeMetrics: false,
              getSharePercentage: false,
              pointInTimeMetrics: false,
              daterangeDimension: 'report_date',
              measuresList: [
                'spend',
                'impressions',
                'clicks',
                'units_1d',
                'units_7d',
                'units_14d',
                'orders_1d',
                'orders_7d',
                'orders_14d',
                'paid_sales_1d',
                'paid_sales_7d',
                'paid_sales_14d',
                'paid_sales',
                'ctr',
                'conversions_1d',
                'conversions_7d',
                'conversions_14d',
                'acos_1d',
                'acos_7d',
                'acos_14d',
                'cpc',
                'halo_sales_1_day',
                'halo_sales_7_day',
                'halo_sales_14_day',
                'roi',
                'average_order_value'
              ],
              groupByDimensionsList: ['campaign_name', 'campaign_id'],
              customAPIDecisionVars: {
                system: 'amazon_campaign_management'
              },
              where: {},
              orderByList: [],
              tagWhereClause: []
            },
            APIConfig: CubeConfig.request,
            download: {
              fileName: 'CampaignTableData',
              columnMap: getColumnDefinition(that, 'campaign'),
              action: 'downloadCampaignsTableData'
            },
            gridOptions: {
              suppressColumnVirtualisation: true,
              context: {
                componentParent: this
              },
              domLayout: 'normal'
            },
            columnDefs: getColumnDefinition(that, 'campaign'),
            rowHeight: 70
          }
        },
        addKeywordsPanel: {
          body: {
            APIConfig: {
              cubeName: 'ams_cm_keyword_recommendations_for_skus',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              measuresList: [
                'recommended_keyword',
                'keyword_type',
                'localized_ear',
                'broad_bid',
                'exact_bid',
                'phrase_bid'
              ],
              groupByDimensionsList: ['recommended_keyword'],
              orderByList: [
                {
                  dimension: 'localized_ear',
                  direction: 'DESC'
                }
              ],
              limit: 10,
              page: 1,
              where: {
                dimensionNameValueList: [{}]
              }
            }
          },
          tableConfigs: {
            metadata: {
              sourceTable: [
                {
                  name: 'keyword',
                  type: 'STRING',
                  uiField: {
                    uiLabel: 'Keyword',
                    uiType: 'string',
                    uiOrder: '1',
                    metadata: {
                      toolTipText: '',
                      tableColumnName: 'keyword',
                      showOnUi: true,
                      isFixed: false,
                      width: 170
                    }
                  }
                },
                {
                  name: 'match_type',
                  type: 'STRING',
                  uiField: {
                    uiLabel: 'Match type',
                    uiType: 'string',
                    uiOrder: '2',
                    metadata: {
                      toolTipText: '',
                      tableColumnName: 'match_type',
                      showOnUi: true,
                      isFixed: false,
                      width: 90
                    }
                  }
                },
                {
                  name: 'newBid',
                  type: 'STRING',
                  uiField: {
                    uiLabel: 'Sugg Bid',
                    uiType: 'string',
                    uiOrder: '3',
                    metadata: {
                      toolTipText: '',
                      tableColumnName: 'newBid',
                      showOnUi: true,
                      isFixed: false,
                      width: 90
                    }
                  }
                },
                {
                  name: 'Add all',
                  type: 'custom',
                  uiField: {
                    uiLabel: 'Add all',
                    uiType: 'custom',
                    clickHeader: that.addAllKeywordsToTable,
                    customStyles:
                      'cursor:pointer; color:#037ef6 !important; padding-left:15px',
                    headerIcon: {
                      icon: 'info-circle-fill',
                      toolTip: 'Add all keywords on this page'
                    },
                    uiOrder: '4',
                    metadata: {
                      toolTipText: 'Add keyword',
                      toolTipPosition: 'left',
                      widget: 'icon',
                      tableColumnName: 'na',
                      showOnUi: true,
                      suppressSizeToFit: true,
                      isFixed: false,
                      iconClickEvent: that.addToSelection,
                      displayIcon: 'plus',
                      alternateIcon: 'check-fill-circle',
                      alternateIconClass: 'u-color-green-base',
                      width: 110
                    }
                  }
                }
              ],
              resultTable: [
                {
                  name: 'keyword',
                  type: 'STRING',
                  uiField: {
                    uiLabel: 'Keyword',
                    uiType: 'string',
                    uiOrder: '1',
                    metadata: {
                      toolTipText: '',
                      tableColumnName: 'keyword',
                      showOnUi: true,
                      isFixed: false,
                      width: 170
                    }
                  }
                },
                {
                  name: 'match_type',
                  type: 'STRING',
                  uiField: {
                    uiLabel: 'Match type',
                    uiType: 'string',
                    uiOrder: '2',
                    metadata: {
                      toolTipText: '',
                      tableColumnName: 'match_type',
                      showOnUi: true,
                      isFixed: false,
                      width: 90
                    }
                  }
                },
                {
                  name: 'suggeted bid',
                  type: 'custom',
                  uiField: {
                    widget: 'icon',
                    uiLabel: 'Sugg bid',
                    uiType: 'custom',
                    uiOrder: '3',
                    metadata: {
                      toolTipText: 'Set suggested bid',
                      widget: 'icon',
                      type: 'iconText',
                      tableColumnName: 'bid',
                      formatType: 'currency',
                      displayIcon: 'trending-neutral',
                      showOnUi: true,
                      isFixed: false,
                      width: 110,
                      iconClickEvent: that.setDefaultValue
                      // contextReturnEvent: this.getArrowButtonContext
                    }
                  }
                },
                {
                  name: 'Bid',
                  type: 'custom',
                  uiField: {
                    uiLabel: 'Bid',
                    uiOrder: '4',
                    uiType: 'custom',
                    metadata: {
                      toolTipText: '',
                      widget: 'input',
                      type: 'number',
                      tableColumnName: 'bid',
                      defaultValueColumnName: 'newBid',
                      showOnUi: true,
                      isFixed: false,
                      formatType: 'currency',
                      width: 100,
                      keyupEvent: '',
                      // blurEvent: that.validateTableData
                      onchangeEvent: that.validateTableData
                      // contextReturnEvent: this.getInputFieldContext
                    }
                  }
                },
                {
                  name: 'Remove all',
                  type: 'custom',
                  uiField: {
                    uiLabel: 'Remove all',
                    uiType: 'custom',
                    uiOrder: '5',
                    customStyles:
                      'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
                    clickHeader: that.deleteAllKeywordsFromTable,
                    metadata: {
                      toolTipText: 'Remove keyword',
                      toolTipPosition: 'left',
                      widget: 'icon',
                      tableColumnName: 'na',
                      showOnUi: true,
                      isFixed: false,
                      iconClickEvent: that.removeFromSelection,
                      displayIcon: 'cross',
                      iconSize: 'small',
                      suppressSizeToFit: true,
                      width: 110
                    }
                  }
                }
              ]
            }
          },
          bulkActionConfigs: {
            radioOptions: [
              {
                type: 'keyword',
                label: 'Keyword Targeting'
              },
              {
                type: 'product',
                label: 'Product Targeting'
              },
              {
                type: 'audience',
                label: 'Audience Targeting'
              }
            ],
            radioHeading: 'Select a targeting type:',
            customStateMap: {
              keyword: getKeywordTargeting,
              product: {
                categories: getCategoriesTargeting,
                products: getProductTargeting
              },
              audience: {
                'amazon audiences': getAddAudiencesToCampaignTargeting,
                'view remarketing': (context) =>
                  getAddCategoriesToAudienceCampaignsTargeting(
                    context,
                    'View Remarketing'
                  ),
                'purchase remarketing': (context) =>
                  getAddCategoriesToAudienceCampaignsTargeting(
                    context,
                    'Purchase Remarketing'
                  ),
                tabList: [
                  'Amazon Audiences',
                  'View Remarketing',
                  'Purchase Remarketing'
                ],
                checkTableValidity(rows) {
                  return {
                    tableValid: rows.length,
                    showValueError: false
                  };
                }
              }
            },
            showAboveLeftCustomBid: (bulkActionsRef) => {
              return (
                bulkActionsRef &&
                bulkActionsRef.actionType === 'audience' &&
                bulkActionsRef.defaultTabIndex !== 0
              );
            },
            getAudienceTargetingObject(adGroup, keyword) {
              const payloadTemplate = cloneDeep(
                adGroupsKeywordTargetingProductSD.addAudiencesToCampaigns
              );
              const marketingType = keyword?.marketingType;
              const audienceId = keyword?.audienceId;
              const categoryUI = keyword?.categoryUI || keyword.category;
              const isAudience = keyword?.isAudience;

              const { marketing, entityType, newKeywordText, dynamicSegment } =
                getMarketingAndEntityType(
                  marketingType,
                  isAudience,
                  audienceId,
                  keyword
                );

              const reqValues = {
                ':expressionType':
                  audienceTargetingUtilObject.getExpressionType(
                    dynamicSegment,
                    isAudience,
                    keyword
                  ),
                ':entityType': audienceTargetingUtilObject.getEntityType(
                  dynamicSegment,
                  entityType,
                  keyword
                ),
                ':campaignId': adGroup.campaign_id,
                ':marketingType': marketing,
                ':adgroupId': adGroup.adgroup_id,
                ':asin_title': audienceTargetingUtilObject.getAsinTitle,
                ':pageUrl': window.location.href,
                ':image_url': keyword.competitor_asin_image_url,
                ':value': audienceTargetingUtilObject.getValue(
                  dynamicSegment,
                  audienceId,
                  keyword
                ),
                ':asin_id': audienceTargetingUtilObject.getAsinId(
                  newKeywordText,
                  keyword
                ),
                ':entity_text': audienceTargetingUtilObject.getEntityText(
                  dynamicSegment,
                  audienceId,
                  keyword,
                  categoryUI
                ),
                ':adgroupName':
                  audienceTargetingUtilObject.getAdGroupName(adGroup),
                ':bid': keyword.new_bid,
                ':product_url': keyword.competitor_asin_product_url
              };
              audienceTargetingUtilObject.loopReqValuesAndInsertInTemplate(
                reqValues,
                payloadTemplate
              );
              if (keyword.lookback && marketingType) {
                payloadTemplate.actionPayload.new_expression?.[0]?.value?.push({
                  type: 'lookback',
                  value: keyword.lookback
                });
              }
              return payloadTemplate;
            },
            APIConfig: APIConfigForCampaignsGroups,
            groupsTableColDefinition: groupsTableColDefinition
          }
        },
        addAsinsPanel: {
          body: {
            APIConfig: {
              cubeName: 'ams_campaign_asin_relationship',
              measuresList: [],
              groupByDimensionsList: [
                'asin',
                'product_title',
                'product_url',
                'image_url'
              ],
              orderByList: [
                {
                  dimension: 'product_title',
                  direction: 'ASC'
                }
              ],
              where: {
                dimensionNameValueList: []
              }
            }
          },
          tableConfigs: {
            metadata: {
              sourceTable: [
                {
                  // currDefinition
                  name: 'sku_details',
                  type: 'sku',
                  uiField: {
                    uiLabel: 'SKU Details',
                    uiType: 'custom',
                    widget: 'sku',
                    uiOrder: '1',
                    metadata: {
                      widget: 'sku',
                      toolTipText: '',
                      tableColumnName: 'SKU Details',
                      columnTitleKey: 'product_title',
                      columnAsinKey: 'asin',
                      columnImageUrlKey: 'image_url',
                      coloumnProductUrlKey: 'product_url',
                      showOnUi: true,
                      isFixed: false,
                      getQuickFilterText: function (params) {
                        return (
                          params.data.product_title + '_' + params.data.asin
                        );
                      }
                    }
                  }
                },
                {
                  name: 'Add all',
                  type: 'custom',
                  uiField: {
                    uiLabel: 'Add all',
                    uiType: 'custom',
                    clickHeader: that.addAllAsinsToTable,
                    customStyles:
                      'cursor:pointer; color:#037ef6 !important; padding-left:15px',
                    headerIcon: {
                      icon: 'info-circle-fill',
                      toolTip: 'Add all SKUs on this page'
                    },
                    uiOrder: '2',
                    metadata: {
                      toolTipText: 'Add this SKU',
                      toolTipPosition: 'left',
                      widget: 'icon',
                      tableColumnName: 'na',
                      showOnUi: true,
                      suppressSizeToFit: true,
                      isFixed: false,
                      iconClickEvent: that.addToSelection,
                      displayIcon: 'plus',
                      alternateIcon: 'check-fill-circle',
                      alternateIconClass: 'u-color-green-base',
                      width: 110
                    }
                  }
                }
              ],
              resultTable: [
                {
                  name: 'sku_details',
                  type: 'sku',
                  uiField: {
                    uiLabel: 'SKU Details',
                    uiType: 'custom',
                    uiOrder: '1',
                    metadata: {
                      widget: 'sku',
                      toolTipText: '',
                      tableColumnName: 'SKU Details',
                      columnTitleKey: 'product_title',
                      columnAsinKey: 'asin',
                      columnImageUrlKey: 'image_url',
                      coloumnProductUrlKey: 'product_url',
                      showOnUi: true,
                      isFixed: false
                    }
                  }
                },
                {
                  name: 'Remove all',
                  type: 'custom',
                  uiField: {
                    uiLabel: 'Remove all',
                    uiType: 'custom',
                    uiOrder: '2',
                    customStyles:
                      'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
                    clickHeader: that.deleteAllAsinsFromTable,
                    metadata: {
                      toolTipText: 'Remove Sku',
                      toolTipPosition: 'left',
                      widget: 'icon',
                      tableColumnName: 'na',
                      showOnUi: true,
                      isFixed: false,
                      iconClickEvent: that.removeFromSelection,
                      displayIcon: 'cross',
                      iconSize: 'small',
                      suppressSizeToFit: true,
                      width: 110
                    }
                  }
                }
              ]
            }
          },
          bulkActionConfigs: {
            APIConfig: APIConfigForCampaignsGroups,
            groupsTableColDefinition: groupsTableColDefinition
          }
        },
        fetchAddGroups: {
          body: {
            APIConfig: {
              cubeName: 'campaign_adgroups_mapping',
              measuresList: [],
              groupByDimensionsList: ['adgroup_name', 'adgroup_id'],
              where: {
                dimensionNameValueList: []
              }
            }
          }
        },
        addNegativeKeywords: {
          body: {
            APIConfig: {
              cubeName: 'ams_campaign_asin_relationship',
              measuresList: [],
              groupByDimensionsList: [
                'asin',
                'product_title',
                'product_url',
                'image_url'
              ],
              orderByList: [
                {
                  dimension: 'product_title',
                  direction: 'ASC'
                }
              ],
              where: {
                dimensionNameValueList: []
              }
            }
          },
          tableConfigs: {
            metadata: {
              resultTable: [
                {
                  name: 'keyword',
                  type: 'STRING',
                  uiField: {
                    uiLabel: 'Keyword',
                    uiType: 'string',
                    uiOrder: '1',
                    metadata: {
                      toolTipText: '',
                      tableColumnName: 'keyword',
                      showOnUi: true,
                      isFixed: false
                    }
                  }
                },
                {
                  name: 'match_type',
                  type: 'STRING',
                  uiField: {
                    uiLabel: 'Match type',
                    uiType: 'string',
                    uiOrder: '2',
                    metadata: {
                      toolTipText: '',
                      tableColumnName: 'match_type',
                      showOnUi: true,
                      isFixed: false
                    }
                  }
                },
                {
                  name: 'Remove all',
                  type: 'custom',
                  uiField: {
                    uiLabel: 'Remove all',
                    uiType: 'custom',
                    uiOrder: '3',
                    customStyles:
                      'cursor:pointer; color:#037ef6 !important;padding-left:10px;',
                    clickHeader: that.removeAllKeywords,
                    metadata: {
                      toolTipText: 'Remove keyword',
                      toolTipPosition: 'left',
                      widget: 'icon',
                      tableColumnName: 'na',
                      showOnUi: true,
                      isFixed: false,
                      iconClickEvent: that.removeKeyword,
                      displayIcon: 'cross',
                      iconSize: 'small',
                      suppressSizeToFit: true,
                      width: 125
                    }
                  }
                }
              ]
            }
          },
          bulkActionConfigs: {
            radioOptions: [
              {
                type: 'keyword',
                label: 'Keyword Targeting'
              },
              {
                type: 'product',
                label: 'Product Targeting'
              }
            ],
            radioHeading: 'Select a targeting type:',
            customStateMap: {
              keyword: getNegativeKeywordTargeting,
              product: negativeProductTargeting
            },
            APIConfig: APIConfigForCampaignsGroups,
            groupsTableColDefinition: groupsTableColDefinition
          }
        }
      }
    };
  }
};
