<template>
  <div class="query-builder u-width-100">
    <slot name="queryBuilder u-width-100">
      <div class="query-fields u-flex-direction-column u-width-100">
        <div
          v-for="(selectedEntry, index) in selectedEntriesArr"
          :key="index"
          class="query-fields"
        >
          <div
            data-cy="expressionFilterDropdown"
            class="operations"
          >
            <rb-select
              :on-close="onOperationsSelected"
              :context="selectedEntry"
              :options="availableOperator"
              :send-details="true"
              class="u-white-space-no-wrap query-builder-select"
            >
              <div
                slot="item"
                ref="query-builder"
                slot-scope="dropdownItem"
                class="query-builder-dropdown-item"
              >
                <div
                  v-tippy="tippyOptions"
                  class="query-builder-item"
                  :title="operatorNames[dropdownItem.title]"
                >
                  {{ dropdownItem.title }}
                </div>
              </div>
              <rb-button
                slot="trigger"
                :text="selectedEntry.operator.title"
                :type="'dropdown'"
                :icon-right="'arrow-down'"
              />
            </rb-select>
          </div>
          <div class="u-display-flex value">
            <div
              v-if="selectedUnit && showOperatorValue(selectedEntry)"
              class="u-font-size-3 u-spacing-mh-s"
            >
              {{ selectedUnit }}
            </div>
            <div
              v-if="selectedEntry.operator.operator === 'BETWEEN'"
              class="u-display-flex u-width-100"
            >
              <rb-input
                v-model="selectedEntry.value1"
                class="u-spacing-mr-xs"
                :class-list="['padding-right-none']"
                :type="inputType"
                @input="textFocusOut()"
              />
              <rb-input
                v-model="selectedEntry.value2"
                :class-list="['padding-right-none']"
                :type="inputType"
                @input="textFocusOut()"
              />
            </div>
            <div
              v-else-if="showOperatorValue(selectedEntry)"
              class="u-width-100"
            >
              <rb-input
                v-model="selectedEntry.value"
                :type="inputType"
                @input="textFocusOut()"
              />
            </div>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import Vue from 'vue';
import { operator, operatorTitle } from '@/utils/helpers/operator.js';

export default {
  name: 'QueryBuilder',
  props: {
    disableBetweenOperator: {
      type: Boolean,
      default: false
    },
    hideMetrics: {
      type: Boolean,
      default: true
    },
    filterForOperators: {
      type: Boolean,
      default: false
    },
    metrics: {
      type: Object,
      default: () => {}
    },
    addRules: {
      type: Boolean,
      default: false
    },
    applyChanges: Function,
    selectedRules: {
      type: Array,
      default: () => []
    },
    operatorsToFilterOut: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedUnit: null,
      removeInputBoxForOperators: ['IS_NOT_NULL', 'IS_NULL'],
      selectedEntriesArr: [],
      operatorsList: [],
      inputValue: '',
      availableOperator: [],
      inputType: 'text',
      operatorNames: {},
      tippyOptions: {
        placement: 'right',
        popperOptions: {
          modifiers: {
            offset: {
              offset: '0px, 20px'
            },
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {},
  watch: {},
  created() {
    if (
      this.metrics &&
      this.metrics.config &&
      this.metrics.config.addtionalMetadata &&
      this.metrics.config.addtionalMetadata.expressionField &&
      this.metrics.config.addtionalMetadata.expressionField.expressionType ===
        'NUMERIC' &&
      !this.filterForOperators
    ) {
      this.inputType = 'number';
      this.availableOperator =
        this.metrics.config.addtionalMetadata.expressionField.operators
          .map((item) => {
            return {
              title: operator[item],
              operator: item
            };
          })
          .filter((item) => {
            // don't show filter operators which don't have explcit title mapping in UI
            const bShow = !!item.title;
            return bShow;
          });
      // add a custom BETWEEN operator (since backend doesn't supports it through cube)
      if (!this.disableBetweenOperator) {
        this.availableOperator.push({
          title: operator.BETWEEN,
          operator: 'BETWEEN'
        });
      }
      if (this.metrics.config.addtionalMetadata.unit) {
        this.selectedUnit =
          this.metrics.config.addtionalMetadata.unit === 'PERCENTAGE'
            ? '%'
            : Vue.prototype.$currency;
      }
    } else {
      this.availableOperator =
        this.metrics.config.addtionalMetadata.expressionField.operators
          .map((item) => {
            return {
              title: operator[item],
              operator: item
            };
          })
          .filter((availableOperator) => {
            // New types of operants like is NULL and NOT NULL started coming from backend.
            // current UI does not support having IS NULL and NOT NULL selection since we show a text box next to Title.
            // So currently commenting these
            // don't show filter operators which don't have explcit title mapping in UI
            const bShow = this.filterForOperators
              ? !(
                  availableOperator.operator === 'IS_NULL' ||
                  availableOperator.operator === 'IS_NOT_NULL' ||
                  availableOperator.operator === 'NOT_EQUAL_TO'
                )
              : !!availableOperator.title;
            return bShow;
          });
    }
    this.availableOperator = this.availableOperator.filter(
      (availableOperatorItem) =>
        !this.operatorsToFilterOut.includes(availableOperatorItem?.operator)
    );
    if (this.selectedRules && this.selectedRules.length !== 0) {
      this.selectedEntriesArr = this.selectedRules[0];
    }
    if (this.selectedRules && this.selectedRules.length === 0) {
      this.addQueries();
    }
    this.operatorNames = operatorTitle;
  },
  methods: {
    showOperatorValue(selectedEntry) {
      // show input box based on operators
      if (
        this.removeInputBoxForOperators?.includes(
          selectedEntry?.operator?.operator
        )
      ) {
        return false;
      }
      return true;
    },
    clearInputValues() {
      // method called from the parent, fixes https://boomerang.atlassian.net/browse/PROD-111799. Input values persisting with screen toggled, desired behaviour is to clear them out
      this.selectedEntriesArr = this.selectedEntriesArr.map((selectedEntry) => {
        if (selectedEntry.operator.operator === 'BETWEEN') {
          selectedEntry.value1 = '';
          selectedEntry.value2 = '';
        } else {
          selectedEntry.value = '';
        }
      });
    },
    onMetricsSelected(context, selected) {
      if (selected && selected.length > 0) {
        // context.selectedMetric = selected[0].title;
        this.isComplete(context);
      }
    },
    onOperationsSelected(context, selected) {
      if (selected && selected.length > 0) {
        context.operator = selected[0];
        this.isComplete();
      }
    },
    textFocusOut() {
      this.isComplete();
    },
    isComplete(context) {
      if (this.applyChanges) {
        if (this.selectedEntriesArr.length > 0) {
          const validate = this.selectedEntriesArr.every((item) => {
            if (item.operator && item.operator.title) {
              if (
                this.removeInputBoxForOperators.includes(
                  item.operator?.operator
                )
              ) {
                item.value = null;
                return true;
              }
              if (
                item.operator.operator === 'BETWEEN' &&
                item.value1 &&
                item.value2 &&
                Number(item.value1) <= Number(item.value2)
              ) {
                item.value = `${item.value1}, ${item.value2}`;
                return true;
              } else if (item.operator.operator !== 'BETWEEN' && item.value) {
                return true;
              }
            }
            return false;
          });
          if (validate) {
            this.formatQuerySelection(
              this.inputType === 'number',
              this.selectedEntriesArr
            );
            this.applyChanges(this.selectedEntriesArr);
          } else {
            this.applyChanges({ mutations: false });
          }
        } else {
          this.applyChanges({ mutations: false });
        }
      }
    },
    formatQuerySelection(isNumber, selectedEntries) {
      // Ensuring float converions for numeric expression values. ex .5 => 0.5
      if (isNumber && selectedEntries && selectedEntries[0]) {
        const selectedOperator = selectedEntries[0]?.operator?.operator;
        if (selectedOperator !== 'BETWEEN') {
          selectedEntries[0].value =
            selectedEntries[0]?.value?.charAt(0) === '.'
              ? `0${selectedEntries[0]?.value}`
              : selectedEntries[0]?.value;
        } else {
          selectedEntries[0].value1 =
            selectedEntries[0].value1.charAt(0) === '.'
              ? `0${selectedEntries[0].value1}`
              : selectedEntries[0].value1;
          selectedEntries[0].value2 =
            selectedEntries[0].value2.charAt(0) === '.'
              ? `0${selectedEntries[0].value2}`
              : selectedEntries[0].value2;
        }
      }
    },
    addQueries() {
      const _array = {
        operator: this.availableOperator[0],
        value: null,
        unit: this.selectedUnit
      };
      this.selectedEntriesArr.push(_array);
    },
    deleteQueries(id) {
      if (this.selectedEntriesArr.length > 0) {
        for (var i in this.selectedEntriesArr) {
          if (id === this.selectedEntriesArr[i].id) {
            this.selectedEntriesArr.splice(i, 1);
          }
        }
      }
    }
  }
};
</script>

<style lang="css" scoped>
.query-builder {
  display: inline-flex;
  flex-direction: column;
  align-items: baseline;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  padding-top: 1.6rem;
  overflow: auto;
  max-height: 294px;
  display: inline-block;
}
.query-builder .query-fields {
  display: inline-flex;
}
.query-builder .query-fields .operations {
  padding-right: 0.8rem;
}
.query-builder .query-fields .value {
  display: inline-flex;
  align-items: center;
}
</style>
