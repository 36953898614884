<template>
  <div
    class="u-spacing-pv-xxxl u-spacing-ph-xxl campaign-creation-campaign-targeting"
  >
    <section class="u-spacing-pt-m">
      <createCampaignHeader :step="step" />
      <component
        :is="customActionPanelCustomState.component"
        v-if="customActionPanelCustomState"
        :key="addTargetKey"
        class="add-keyword-campaign-creation"
        v-bind="customActionPanelCustomState.props"
        v-on="customActionPanelCustomState.events"
      >
        <div
          v-if="targetMessages && !targetsLength"
          slot="right-table-footer"
          class="edit_suggested_campaign_keyword_targeting u-font-size-6 u-color-orange-base u-font-weight-600 u-position-absolute"
        >
          <rb-icon
            class="u-color-orange-base rb-icon--small"
            icon="info-circle-fill"
            data-cy="targetMsg"
          />
          {{ targetMessages }}
        </div>
        <div slot="leftTableHeader">
          <GenericTabsComponent
            :tabs-list="tabList"
            :default-tab-index="defaultTabIndex"
            @handleTabsClick="handleTabsClick"
          />
        </div>
      </component>

      <bidWarning
        :show="showTargetWarningMessage"
        :title="targetWarningTitle"
        :sub-text="targetWarningSubText"
      />
      <createCampaignFooter
        :disable-right-btn="!targetsLength"
        :class="showTargetWarningMessage ? 'u-spacing-mt-s' : 'u-margin-top-40'"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
        @onRightBtnDisabled="handleRightBtnDisabled"
      />
    </section>
  </div>
</template>

<script>
import bidWarning from '@/components/ams/campaign_creation/steps/edit/campaignTargetting/bidWarning.vue';
import CustomActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';
import CollapsibleTitle from '@/components/pages/strategy_builder/steps/collapsible-title.vue';
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import GenericTabsComponent from '@/components/basic/genericTabsComponent.vue';
// import campaignTargetting from './campaignTargetting';
export default {
  components: {
    createCampaignHeader,
    createCampaignFooter,
    // campaignTargetting
    CollapsibleTitle,
    CustomActionPanel,
    GenericTabsComponent,
    bidWarning
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    campaignTypeMap: {
      sponsoredProducts: 'sp',
      createOwnCampaignSP: 'sp',
      sponsoredBrands: 'sb',
      createOwnCampaignSB: 'sb'
    },
    showTargetWarningMessage: false,
    targetWarningTitle: '',
    targetWarningSubText: '',
    negativeKeywordsExpand: false,
    addTargetKey: 0,
    defaultTabIndex: 0,
    tabList: ['Categories', 'Products'],
    customActionPanelCustomState: null,
    customActionPanelNegativeTargetingCustomState: null,
    suggestedCampaignIndex: 0,
    categoriesActionState: null,
    campaignType: 'sp'
  }),
  computed: {
    targetMessages() {
      return this.allStepsData?.suggestedCampaigns?.[
        this.suggestedCampaignIndex
      ]?.messages?.targets;
    },
    targetsLength() {
      return this.allStepsData?.suggestedCampaigns[this.suggestedCampaignIndex]
        ?.targets?.length;
    },
    allStepsData() {
      return this.$store?.getters?.[this.selectedValues?.getter];
    }
  },
  watch: {
    targetsLength: {
      handler(newVal) {
        this.step.description = `${newVal || 0} target${
          newVal !== 1 ? "'s" : ''
        } added`;
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.suggestedCampaignIndex = this?.$route?.query?.key?.split('-')?.[1];
    this.suggestedCampaignIndex = parseInt(this.suggestedCampaignIndex);
    const { campaignType } =
      this.allStepsData?.suggestedCampaigns[this.suggestedCampaignIndex];
    this.campaignType = this.campaignTypeMap[campaignType];
    this.customActionPanelCustomState = this.step?.categoriesActionPanelState(
      this,
      this.campaignType
    );
    // this.customActionPanelNegativeTargetingCustomState = this.step?.customActionPanelNegativeTargetingCustomState(this);
  },
  methods: {
    handleRightBtnDisabled(value) {
      this.$parent.$emit('onStepNextBtnDisabled', {
        disabled: value,
        index: this.step.index
      });
    },
    handleTabsClick(value) {
      if (value === 'Categories') {
        this.customActionPanelCustomState =
          this.step?.categoriesActionPanelState(this, this.campaignType);
        this.defaultTabIndex = 0;
      } else {
        this.customActionPanelCustomState =
          this.step?.customActionPanelCustomState(this);
        this.defaultTabIndex = 1;
      }
      this.addTargetKey++;
    },
    nextStep() {
      this.$emit('nextStep', {});
    },
    prevStep() {
      this.$emit('prevStep', {});
    }
  }
};
</script>

<style lang="css">
.add-keyword-campaign-creation .table-container-entity-asin-tagging {
  padding-right: 0.2rem;
  padding-left: 0.2rem;
}
</style>

<style scoped>
.edit_suggested_campaign_keyword_targeting {
  bottom: -20px;
}
</style>
