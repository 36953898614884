<template>
  <div class="u-display-flex u-text-overflow-ellipsis sku-with-rating">
    <rb-sku
      class="u-text-overflow-ellipsis"
      :show-image="showImage"
      :title="title"
      :image-url="imageUrl"
      :asin="asin"
      :product-page-url="productPageUrl"
      :enable-s-d-p="enableSDP"
      :rb-asin-tippy-text="pdpTooltip"
    >
      <div
        slot="sku-context"
        class="u-spacing-p-0 u-spacing-mt-0"
      >
        <sku-retailers-icon-list
          :text-class="'u-font-size-7 u-spacing-pt-xxs'"
          :product-urls-list="productUrlsComp"
          :retailers-logo-map="retailersLogoMap"
        />
      </div>
    </rb-sku>
  </div>
</template>

<script>
import skuRetailersIconList from '@/components/basic/skuRetailersIconList.vue';
import { difference, uniq } from 'lodash';
export default {
  name: 'SkuMultipleRetailers',
  components: {
    skuRetailersIconList
  },
  props: {
    title: {
      type: String,
      default: null
    },
    showImage: {
      type: Boolean,
      default: true
    },
    asin: {
      type: String,
      default: null
    },
    imageUrl: {
      type: String,
      default: null
    },
    productPageUrl: {
      type: String,
      default: null
    },
    enableSDP: {
      type: Boolean,
      default: true
    },
    productUrlsList: {
      type: [String, Array],
      default: ''
    },
    retailersLogoMap: {
      type: Object,
      default() {
        return {
          amazon: 'https://cdn.rboomerang.com/images/amazon.png',
          walmart: 'https://cdn.rboomerang.com/images/walmart.png',
          target: 'https://cdn.rboomerang.com/images/target.png',
          instacart: 'https://cdn.rboomerang.com/images/instacart.png',
          wayfair: 'https://cdn.rboomerang.com/images/wayfair.png',
          staples: 'https://cdn.rboomerang.com/images/staples.png'
        };
      }
    },
    pdpTooltip: {
      type: String,
      default: 'View Walmart detail page'
    }
  },
  computed: {
    productUrlsComp() {
      let allRetailerLinks;
      if (Array.isArray(this.productUrlsList)) {
        allRetailerLinks = this.productUrlsList;
      } else if (typeof this.productUrlsList === 'string') {
        // a work around to handle the product Url list coming as string array from backend.
        // Its a specical case for pivoted tables. need to improvise on this soln.
        allRetailerLinks = JSON.parse(this.productUrlsList);
      }
      // there are existing cases when duplicate product urls. we will show only one retailer link even if duplicate product urls come.
      // also the primary retail link (productPageUrl) is already present in the component. so we filter that out.
      return uniq(difference(allRetailerLinks, [this.productPageUrl]));
    }
  },
  methods: {
    navigateToSDP(retailer) {
      console.log(retailer);
    }
  }
};
</script>
