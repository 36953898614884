import {
  getAPIConfigForFetchingAdGroups,
  groupsTableColDefinition
} from '@/pages/campaign-management/retailers/walmart/campaigns/addKeywordsPanelWidget.js';
import {
  selectSkuLeftTableForAutoSP,
  selectSkuRightTableForAutoSP
} from '@/components/ams/campaign_creation/setup_configs/walmart/common/adGroupConfig.js';
import {
  deepReplaceObjectValues,
  deepReplaceUnusedValuesWithNull
} from '@/pages/entity_details/common_components/helper.js';
import { cloneDeep } from 'lodash';
import HttpService from '@/utils/services/http-service';
import { getAddCustomProductsRequestPayloadTemplate as getAddCustomProductsRequestPayloadTemplateForSPManual } from '@/pages/entity_details/configs/walmart/sp_manual.js';
import { getAddCustomProductsRequestPayloadTemplate as getAddCustomProductsRequestPayloadTemplateForSPAuto } from '@/pages/entity_details/configs/walmart/sp_auto.js';
import { getAddCustomProductsRequestPayloadTemplate as getAddCustomProductsRequestPayloadTemplateForSBManual } from '@/pages/entity_details/configs/walmart/sb_manual.js';

const addProductsRequestTemplateMapping = {
  sp: {
    auto: getAddCustomProductsRequestPayloadTemplateForSPAuto,
    manual: getAddCustomProductsRequestPayloadTemplateForSPManual
  },
  sb: {
    manual: getAddCustomProductsRequestPayloadTemplateForSBManual
  }
};

function transformAllSkusResponse(dataArray) {
  dataArray.forEach((rowItem) => {
    if (!rowItem?.suggested_bid) {
      rowItem.newBid = 0.2;
      rowItem.suggestedBidToShow = '-';
    } else {
      rowItem.suggestedBidToShow = rowItem?.suggested_bid;
      rowItem.newBid = rowItem?.suggested_bid;
    }
  });
}

const getIsBidDisabled = (selections) => {
  let containsAutoTargetingType = false;
  selections.forEach((item) => {
    if (item.data?.targeting_type === 'auto') {
      containsAutoTargetingType = true;
    }
  });
  return !containsAutoTargetingType;
};

const getTableValidityFunction = (that) => {
  return function () {
    const isBidDisabled = getIsBidDisabled(that.selections);
    that.resultTableData.forEach((item) => {
      item.disabled = isBidDisabled;
      item.toolTipText = isBidDisabled
        ? 'Bids are not applicable for manual campaigns'
        : '';
    });
    const addAsinsPanelVueRef = that;
    if (addAsinsPanelVueRef.resultTableData.length === 0) {
      addAsinsPanelVueRef.tableValid = false;
      return;
    }
    if (addAsinsPanelVueRef.selectedAdGroups.length === 0) {
      addAsinsPanelVueRef.tableValid = false;
      return;
    }
    const arrayToTraverse = addAsinsPanelVueRef.resultTableData;
    for (const i in arrayToTraverse) {
      const newBid = arrayToTraverse[i]?.newBid;
      if (!newBid || parseFloat(newBid) < 0.2 || parseFloat(newBid) > 20) {
        if (!isBidDisabled) {
          addAsinsPanelVueRef.tableValid = false;
          addAsinsPanelVueRef.showValueError = true;
          addAsinsPanelVueRef.errorMessage =
            'Bid should be between $0.2 and $20, both inclusive.';
          return;
        }
      }
    }
    addAsinsPanelVueRef.showValueError = false;
    addAsinsPanelVueRef.tableValid = true;
  };
};

const getRunMethodOnSelectionChange = (that) => {
  return function () {
    that.checkTableValidity();
    that.resultTableData = [...that.resultTableData];
  };
};

const getApplyFunction = (that) => {
  const retailer = that.$store.getters.getRetailer;
  return async function () {
    that.asinsLoading = true;
    const campaignList = that.selections;
    const selectedAdGroups = that.selectedAdGroups;
    const payload = [];
    selectedAdGroups.forEach((adGroupItem) => {
      const campaignId = adGroupItem?.campaign_id;
      const campaignItem = campaignList.find(
        (item) => item.data?.campaign_id === campaignId
      );
      const campaignType = campaignItem?.data?.campaign_type?.toLowerCase();
      const targetingType = campaignItem.data?.targeting_type?.toLowerCase();
      const payloadRequestTemplate =
        addProductsRequestTemplateMapping[campaignType][targetingType](
          retailer
        );
      that.resultTableData.forEach((skuItem) => {
        const payloadObject = cloneDeep(payloadRequestTemplate);
        const keyValuePairs = {
          ':campaignId': campaignId,
          ':adgroupId': adGroupItem?.ad_group_id,
          ':item_id': skuItem?.sku,
          ':advertiserId': campaignItem?.data?.advertiser_id,
          ':sku_title': skuItem?.product_title,
          ':image_url': skuItem?.image_url,
          ':product_url': skuItem?.product_url,
          ':search_text': skuItem?.search_text,
          ':adgroupName': adGroupItem?.ad_group_name,
          ':bid': skuItem?.newBid,
          ':pageUrl': window.location.href
        };
        for (const key in keyValuePairs) {
          deepReplaceObjectValues(payloadObject, key, keyValuePairs[key]);
        }
        deepReplaceUnusedValuesWithNull(payloadObject);
        payload.push(payloadObject);
      });
    });
    try {
      await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
      that.parentRef.switchBlueBar();
      that.closePanel();
    } catch (err) {
      that.openSnackbar('Something went wrong.');
      that.asinsLoading = false;
    }
  };
};

export const getWidgetConfigForAddSkus = (retailer) => {
  const newGroupsTableColDefinition = groupsTableColDefinition.map((item) => {
    if (item.name === 'ad_group_name') {
      item.uiField.metadata.tableColumnName = 'ad_group_name';
    }
    return item;
  });
  return {
    config: (that) => {
      // Source table array logic changes
      const sourceTableColumnDefinitionArray =
        selectSkuLeftTableForAutoSP(that);
      sourceTableColumnDefinitionArray.forEach((item) => {
        if (item.name === 'Add all') {
          item.uiField.clickHeader = that.addAllAsinsToTable;
          item.uiField.metadata.iconClickEvent = that.addToSelection;
        }
      });

      // result table array logic changes here
      const resultTableColumnDefinitionArray =
        selectSkuRightTableForAutoSP(that);
      resultTableColumnDefinitionArray.forEach((item) => {
        switch (item.name) {
          case 'Remove all':
            item.uiField.clickHeader = that.deleteAllAsinsFromTable;
            item.uiField.metadata.iconClickEvent = that.removeFromSelection;
            break;
          case 'newBid':
            item.uiField.metadata.onchangeEvent = (context) => {
              const rowIndex = context.params.node.rowIndex;
              context.params.data.newBid = parseFloat(context.bidValue);
              that.resultTableData[rowIndex].newBid =
                context.params.data.newBid;
              that.checkTableValidity();
            };
            break;
          case 'Suggested bid':
            item.uiField.metadata.iconClickEvent = (context) => {
              const rowNodes = {
                rowNodes: [context.params.node]
              };
              if (!context.params.data.newBid) {
                context.params.data.newBid = context.params.data.suggested_bid;
                const rowIndex = context.params.node.rowIndex;
                that.resultTableData[rowIndex].newBid =
                  context.params.data.newBid;
              }
              that.rbTableResultInstance.redrawTableRows(rowNodes);
              that.checkTableValidity();
            };
            break;
          default:
            break;
        }
      });
      return {
        widgets: {
          addAsinsPanel: {
            body: {
              APIConfig: {
                cubeName: `${retailer}_sku_catalog`,
                getLatestAvailableInsteadOfRollup: false,
                timeseriesEnabled: false,
                pvpenabled: false,
                yoyenabled: false,
                measuresList: [
                  'product_title',
                  'buy_box_pct',
                  'availability_pct',
                  'availability_status',
                  'brand_name',
                  'offer_price',
                  'image_url',
                  'product_url',
                  'suggested_bid',
                  'search_text'
                ],
                groupByDimensionsList: ['sku'],
                orderByList: [],
                where: {
                  dimensionNameValueList: []
                }
              }
            },
            tableConfigs: {
              metadata: {
                sourceTable: sourceTableColumnDefinitionArray,
                resultTable: resultTableColumnDefinitionArray
              }
            },
            bulkActionConfigs: {
              APIConfig: getAPIConfigForFetchingAdGroups(retailer),
              groupsTableColDefinition: newGroupsTableColDefinition
            },
            transformAllSkusResponse,
            checkTableValidity: getTableValidityFunction(that),
            apply: getApplyFunction(that),
            runMethodOnSelectionChange: getRunMethodOnSelectionChange(that),
            useCurrentCampaignIdVariable: true
          }
        }
      };
    }
  };
};
