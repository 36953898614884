export const LOW_INVENTORY_SUMMARY_METRICS_VS_RETAILER_MAP = {
  '3P': {
    overview_sku_count: 'count_3p_skus',
    overview_revenue_impact: 'revenue_missed_3P'
  },
  retail: {
    overview_sku_count: 'count_retail_skus',
    overview_revenue_impact: 'revenue_missed_retail'
  },
  retail_and_3p: {
    overview_sku_count: 'count_all_skus_pit',
    overview_revenue_impact: 'revenue_missed_pit'
  }
};

export const LOW_INVENTORY_RETAILER_VS_CHART_METRICS_MAP = {
  retail_and_3p: ['revenue_missed', 'count_all_skus'],
  retail: ['revenue_missed_retail', 'count_retail_skus'],
  '3P': ['revenue_missed_3P', 'count_3p_skus']
};

export const AUTOMATION_TYPE_VS_CHART_METRICS_MAP = {
  unavailable: [
    'unavailable',
    'unavailable_skus__retail',
    'unavailable_skus__3p',
    'impact_unavailable',
    'impact_unavailable__retail',
    'impact_unavailable__3p'
  ],
  '3pVariant': [
    'three_p_variants',
    'three_p_variants__3p',
    'three_p_variants__retail',
    'impact_three_p_variants',
    'impact_three_p_variants__retail',
    'impact_three_p_variants__3p'
  ],
  contentAuthority: [
    'content_authority',
    'content_authority__retail',
    'content_authority__3p',
    'impact_content_authority',
    'impact_content_authority__retail',
    'impact_content_authority__3p'
  ],
  variationAuthority: [
    'variation_authority',
    'variation_authority__retail',
    'variation_authority__3p',
    'impact_variation_authority',
    'impact_variation_authority__retail',
    'impact_variation_authority__3p'
  ]
};

export const AUTOMATION_TYPE_AUTOMATION_VS_RETAILER_VS_METRICS = {
  unavailable: {
    retail_and_3p: ['unavailable', 'impact_unavailable'],
    retail: ['unavailable_skus__retail', 'impact_unavailable__retail'],
    '3p': ['unavailable_skus__3p', 'impact_unavailable__3p']
  },
  '3pVariant': {
    retail_and_3p: ['three_p_variants', 'impact_three_p_variants'],
    retail: ['three_p_variants__retail', 'impact_three_p_variants__retail'],
    '3p': ['three_p_variants__3p', 'impact_three_p_variants__3p']
  },
  contentAuthority: {
    retail_and_3p: ['content_authority', 'impact_content_authority'],
    retail: ['content_authority__retail', 'impact_content_authority__retail'],
    '3p': ['content_authority__3p', 'impact_content_authority__3p']
  },
  variationAuthority: {
    retail_and_3p: ['variation_authority', 'impact_variation_authority'],
    retail: [
      'variation_authority__retail',
      'impact_variation_authority__retail'
    ],
    '3p': ['variation_authority__3p', 'impact_variation_authority__3p']
  }
};

export const AUTOMATION_TYPE_CHART_COLOR_CONFIG = {
  unavailable: {
    retail_and_3p: [],
    retail: [],
    '3p': []
  },
  '3pVariant': {
    retail_and_3p: [],
    retail: [],
    '3p': []
  },
  contentAuthority: {
    retail_and_3p: [],
    retail: [],
    '3p': []
  },
  variationAuthority: {
    retail_and_3p: [],
    retail: [],
    '3p': []
  }
};
export const AUTOMATION_TYPE_CHART_METRIC_LIST = {
  unavailable: {
    retail_and_3p: [
      {
        label: 'SKUs',
        alias: 'unavailable'
      },
      {
        label: 'Revenue Impact',
        alias: 'impact_unavailable'
      }
    ],
    retail: [
      {
        label: 'Revenue Impact',
        alias: 'impact_unavailable__retail'
      },
      {
        label: 'SKUs',
        alias: 'unavailable_skus__retail'
      }
    ],
    '3p': [
      {
        label: 'Revenue Impact',
        alias: 'impact_unavailable__3p'
      },
      {
        label: 'SKUs',
        alias: 'unavailable_skus__3p'
      }
    ]
  },
  '3pVariant': {
    retail_and_3p: [
      {
        label: 'Revenue Impact',
        alias: 'impact_three_p_variants'
      },
      {
        label: 'SKUs',
        alias: 'three_p_variants'
      }
    ],
    retail: [
      {
        label: 'Revenue Impact',
        alias: 'impact_three_p_variants__retail'
      },
      {
        label: 'SKUs',
        alias: 'three_p_variants__retail'
      }
    ],
    '3p': [
      {
        label: 'Revenue Impact',
        alias: 'impact_three_p_variants__3p'
      },
      {
        label: 'SKUs',
        alias: 'three_p_variants__3p'
      }
    ]
  },
  contentAuthority: {
    retail_and_3p: [
      {
        label: 'Revenue Impact',
        alias: 'impact_content_authority'
      },
      {
        label: 'SKUs',
        alias: 'content_authority'
      }
    ],
    retail: [
      {
        label: 'Revenue Impact',
        alias: 'impact_content_authority__retail'
      },
      {
        label: 'SKUs',
        alias: 'content_authority__retail'
      }
    ],
    '3p': [
      {
        label: 'Revenue Impact',
        alias: 'impact_content_authority__3p'
      },
      {
        label: 'SKUs',
        alias: 'content_authority__3p'
      }
    ]
  },
  variationAuthority: {
    retail_and_3p: [
      {
        label: 'Revenue Impact',
        alias: 'impact_variation_authority'
      },
      {
        label: 'SKUs',
        alias: 'variation_authority'
      }
    ],
    retail: [
      {
        label: 'Revenue Impact',
        alias: 'impact_variation_authority__retail'
      },
      {
        label: 'SKUs',
        alias: 'variation_authority__retail'
      }
    ],
    '3p': [
      {
        label: 'Revenue Impact',
        alias: 'impact_variation_authority__3p'
      },
      {
        label: 'SKUs',
        alias: 'impact_variation_authority__3p'
      }
    ]
  }
};
