<template>
  <div
    class="timePeriod_wrp u-overflow-auto u-height-100 u-position-absolute u-width-100"
  >
    <Strategy-Header :step="step" />
    <div
      v-if="step.name === 'Time Period'"
      class="u-display-flex u-flex-direction-column u-width-100 u-spacing-mt-xxl"
    >
      <section class="u-display-flex">
        <div
          class="u-display-flex u-spacing-mr-xxl u-spacing-pr-xl u-border-right u-border-width-s u-border-color-grey-xxx-light"
        >
          <div class="u-display-flex u-flex-align-items-center u-spacing-mr-m">
            <p class="u-font-size-5 u-spacing-mr-s">Start Date:</p>
            <div v-click-outside="hideStartCalendar">
              <span
                class="filter--token u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
                @click="openCalendar('startDate')"
              >
                <rb-icon
                  :icon="'calendar'"
                  class="rb-icon--small u-spacing-mr-xs"
                />
                <span class="u-spacing-ph-xs u-line-height-1_2">
                  {{
                    selectedValues.strategyStartDate | dateFormatFilter
                  }}</span
                >
              </span>
              <datepicker
                ref="startDate"
                v-model="selectedValues.strategyStartDate"
                name="startDate"
                input-class="hide_calendar_strategy"
                :disabled-dates="disabledDates"
              />
            </div>
          </div>
          <div class="u-display-flex u-flex-align-items-center">
            <p class="u-font-size-5 u-spacing-mr-s">End Date:</p>
            <div v-click-outside="hideEndCalendar">
              <span
                class="filter--token u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
                @click="openCalendar('endDate')"
              >
                <rb-icon
                  :icon="'calendar'"
                  class="rb-icon--small u-spacing-mr-xs"
                />
                <span class="u-spacing-ph-xs u-line-height-1_2">
                  {{ selectedValues.strategyEndDate | dateFormatFilter }}</span
                >
              </span>
              <datepicker
                ref="endDate"
                v-model="selectedValues.strategyEndDate"
                name="endDate"
                input-class="hide_calendar_strategy"
                :disabled-dates="disabledDates"
              />
            </div>
          </div>
        </div>
        <div class="u-display-flex u-flex-align-items-center">
          <p
            v-if="!selectedValues.strategyEndDate"
            class="u-font-size-5"
          >
            <rb-icon
              :icon="'info-circle-fill'"
              class="rb-icon--small u-spacing-mr-xs"
            />
            Not setting an end date is equivalent to this strategy running
            forever.
          </p>
        </div>
      </section>
      <div
        v-if="!startDateValidation"
        class="u-font-size-7 u-spacing-mt-m"
      >
        <rb-icon
          :icon="'error-fill'"
          class="u-color-red-base rb-icon--small u-cursor-pointer u-spacing-mr-xs"
        />
        <span class="u-font-size-6 u-color-red-base"
          >Start date cannot be earlier than today's date. Please choose an
          appropriate start date.</span
        >
      </div>
      <div
        v-if="!endDateValidation"
        class="u-font-size-7 u-spacing-mt-m"
      >
        <rb-icon
          :icon="'error-fill'"
          class="u-color-red-base rb-icon--small u-cursor-pointer u-spacing-mr-xs"
        />
        <span class="u-font-size-6 u-color-red-base"
          >End date is in the past. Please choose an appropriate end date.</span
        >
      </div>
      <div
        v-if="!dateValidation"
        class="u-font-size-7 u-spacing-mt-m"
      >
        <rb-icon
          :icon="'error-fill'"
          class="u-color-red-base rb-icon--small u-cursor-pointer u-spacing-mr-xs"
        />
        <span class="u-font-size-6 u-color-red-base"
          >Start date needs to be before the end date. Please choose an
          appropriate start date.</span
        >
      </div>
    </div>
    <section class="u-margin-top-80 u-display-flex u-margin-bottom-80">
      <rb-button
        id="confirmButton"
        :text="'Back'"
        :type="'hollow'"
        :click-fn="movePrev.bind(this)"
        class="u-spacing-mr-m u-spacing-ph-xl u-spacing-pv-s"
      />
      <rb-button
        id="confirmButton"
        :text="'Next'"
        :disabled="disabledButton"
        :type="'filled'"
        :click-fn="moveNext.bind(this)"
      />
    </section>
  </div>
</template>

<script>
import localDateFilter from '@/components/basic/localDateFilter.vue';
import StrategyHeader from './header';
import moment from 'moment-timezone';
import Datepicker from 'vuejs-datepicker';
import stepsMixin from '@/components/pages/strategy_builder/steps/stepsMixin.js';

export default {
  name: 'TimeperiodStep',
  components: {
    StrategyHeader,
    localDateFilter,
    Datepicker
  },
  filters: {
    dateFormatFilter: function (value) {
      if (!value) return 'No Date';
      return moment(value).format('MMM DD, YYYY');
    }
  },
  mixins: [stepsMixin],
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    // Date minus one day
    let date = new Date();
    date = date.setDate(date.getDate() - 1);
    return {
      dateValidation: true,
      startDateValidation: true,
      endDateValidation: true,
      disabledDates: {
        to: new Date(date)
      }
    };
  },
  computed: {
    disabledButton() {
      let isDisable = true;
      if (
        this.selectedValues.strategyStartDate &&
        this.dateValidation &&
        this.startDateValidation &&
        this.endDateValidation
      ) {
        isDisable = false;
      } else {
        isDisable = true;
      }
      this.$parent.$emit('stepsGlobalEvent', {
        type: 'ValidationBtn',
        values: { step: this.step.textMapping, valid: !isDisable }
      });
      return isDisable;
    }
  },
  watch: {
    'selectedValues.strategyEndDate': {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.actionDateValidation();
        }
      },
      deep: true
    },
    'selectedValues.strategyStartDate': {
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.actionDateValidation();
        }
      },
      deep: true
    }
  },
  methods: {
    hideStartCalendar() {
      this.$refs.startDate && this.$refs.startDate.close();
    },
    hideEndCalendar() {
      this.$refs.endDate && this.$refs.endDate.close();
    },
    openCalendar(param) {
      this.$refs[param] && this.$refs[param].showCalendar();
    },
    actionDateValidation() {
      const startDate = moment(this.selectedValues.strategyStartDate);
      if (
        this.selectedValues.mode !== 'edit' ||
        this.selectedValues.strategyStatus === 'DRAFT'
      ) {
        this.startDateValidation = moment().diff(startDate, 'days') <= 0;
      }
      if (this.selectedValues.strategyEndDate) {
        const endDate = moment(this.selectedValues.strategyEndDate);
        this.endDateValidation = endDate.diff(moment(), 'days') >= 0;
        this.dateValidation = startDate.diff(endDate, 'days') <= 0;
      }
    },
    moveNext() {
      this.formatDisplayText(this.step.textMapping);
      this.$emit('nextStep', {
        allStep: this.selectedValues,
        currentStep: this.step
      });
    },
    movePrev() {
      this.$emit('prevStep', {
        allStep: this.selectedValues,
        currentStep: this.step
      });
    }
  }
};
</script>
