import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';
import { inject } from '@vue/composition-api';
import Vue from 'vue';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default {
  data() {
    return {
      lastUpdatedDate: null,
      isDownloadLoading: false
    };
  },
  setup() {
    const requestParams = inject('requestParams');
    const updateEntireRequestParams = inject('updateEntireRequestParams');
    const updateWidgetRequestParams = inject('updateWidgetRequestParams');
    const updateGlobalRequestParams = inject('updateGlobalRequestParams');
    const getWidgetRequestParams = inject('getWidgetRequestParams');
    const getGlobalRequestParams = inject('getGlobalRequestParams');
    const getWidgetMetaForKey = inject('getWidgetMetaForKey');
    return {
      requestParams,
      updateEntireRequestParams,
      updateWidgetRequestParams,
      updateGlobalRequestParams,
      getWidgetRequestParams,
      getGlobalRequestParams,
      getWidgetMetaForKey
    };
  },
  computed: {
    headerOptionsComp() {
      // append based on download and search flags
      let headerOptionsCopy = cloneDeep(this.headerOptions);
      if (this.searchParams?.show) {
        headerOptionsCopy?.push({
          ui_component: 'RbSearch',
          props: {
            value: this.searchParams?.value || '',
            inputClasses: 'search__input u-spacing-mr-m',
            placeholder: this.searchParams.placeholder || 'Search by SKU'
          },
          events: {
            onSearchTrigger: this.onSearchSubmit
          },
          order: 200
        });
      }
      if (
        !this.hideTitle &&
        (this.metadata?.label || this.metadata?.description)
      ) {
        headerOptionsCopy.unshift({
          ui_component: 'textContainer',
          props: {
            text: this.metadata.label || this.metadata.description,
            isDescription: !!this.metadata?.description
          },
          order: 100
        });
      }
      if (this.enableManageColumns) {
        headerOptionsCopy?.push({
          ui_component: 'iconTextActions',
          props: {
            action: { title: 'MANAGE COLUMNS', icon: 'manage-coloumns' },
            mode: 'default',
            applyPadding: false
          },
          grid_column: this.manageColumnGridPosition,
          events: {
            clicked: this.openManageColumns
          },
          order: this.manageColumnFlexOrder
        });
      }
      if (this.enableDownload) {
        headerOptionsCopy?.push({
          ui_component: 'iconText',
          props: {
            icon: this.iconForDownload,
            alignDirection: 'vertical',
            iconClasses:
              'rb-icon u-color-grey-lighter icon-download u-cursor-pointer',
            tooltipText: this.tooltipText,
            isMetricToolTipEnabled: this.isMetricToolTipEnabled,
            textClasses: this.textClasses,
            text: this.textForIcon,
            loaderClass: this.loaderClass,
            isLoading: this.isDownloadLoading,
            expoter: this.expoterClass
          },
          events: {
            onClick: this.download
          },
          order: 300
        });
      }
      if (this.enableDownloadWithText) {
        headerOptionsCopy?.push({
          ui_component: 'iconTextActions',
          props: {
            action: { title: 'DOWNLOAD', icon: 'download' },
            mode: 'default',
            applyPadding: false
          },
          events: {
            clicked: this.download
          },
          order: 300
        });
      }
      headerOptionsCopy = headerOptionsCopy.sort((a, b) => a.order - b.order);
      return headerOptionsCopy;
    },
    footerOptionsComp() {
      const footerOptionsCopy = cloneDeep(this.footerOptions || []);
      if (this.showLastUpdatedDate) {
        footerOptionsCopy.unshift({
          ui_component: 'iconText',
          props: {
            text: `Last Updated: ${this.lastUpdatedDate}`,
            textClasses: 'u-color-grey-x-light u-font-size-7'
          }
        });
      }
      return footerOptionsCopy;
    }
  },
  props: {
    widgetName: {
      type: String,
      default: ''
    },
    metadata: {
      type: Object,
      default() {
        return {};
      }
    },
    widgetRequestParams: {
      type: Object,
      default() {
        return {};
      }
    },
    enableDownload: {
      type: Boolean,
      default: false
    },
    enableDownloadWithText: {
      type: Boolean,
      default: false
    },
    enableManageColumns: {
      type: Boolean,
      default: false
    },
    customDownloadEnable: {
      type: Boolean,
      default: false
    },
    customDownloadFunction: {
      type: Function,
      default: () => {}
    },
    applyFormattingDownload: {
      type: Boolean,
      default: false
    },
    downloadFileCustomName: {
      type: String,
      default: ''
    },
    minMaxDate: {
      type: Object,
      default: null
    },
    searchParams: {
      type: Object,
      default: () => {}
    },
    headerOptions: {
      type: Array,
      default: () => []
    },
    footerOptions: {
      type: Array,
      default: () => []
    },
    context: {
      type: Object,
      default: () => {}
    },
    headerContainerGridStyles: {
      type: Object,
      default: null
    },
    footerContainerGridStyles: {
      type: Object,
      default: null
    },
    showLastUpdatedDate: {
      type: Boolean,
      default: true
    },
    isMetricToolTipEnabled: {
      type: Boolean,
      default: () => false
    },
    tooltipText: {
      type: String,
      default: () => null
    },
    textForIcon: {
      type: String,
      default: () => ''
    },
    iconForDownload: {
      type: String,
      default: 'download'
    },
    textClasses: {
      type: String,
      default: 'u-spacing-ph-s u-font-size-6 u-color-grey-base'
    },
    loaderClass: {
      type: String,
      default: ''
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    expoterClass: {
      type: String,
      default: ''
    },
    manageColumnGridPosition: {
      type: Number,
      default: undefined
    },
    manageColumnFlexOrder: {
      type: Number,
      default: 300
    }
  },
  watch: {
    widgetRequestParams(newValue) {
      // console.log('widgetRequestParams of ', this.widgetName, ' changed', newValue);
    }
  },
  created() {
    this.lastUpdatedDate = moment(this.minMaxDate?.maxDate).format('LL');
  },
  methods: {
    async download() {
      try {
        this.isDownloadLoading = true;
        const metrics = this.dualDataSources
          ? this.metricsFromMetadata
          : this.metadata.metrics;
        const metricsArrayListForDownload = Object.values(metrics).map(
          (item) => ({ name: item.name, alias: item.alias })
        );
        const widgetReqParams = this.getCurrentWidgetRequestParams();
        widgetReqParams[':metricsList']?.length > 0
          ? (this.widgetRequestParams[':metricsList'] =
              widgetReqParams[':metricsList'])
          : (this.widgetRequestParams[':metricsList'] =
              metricsArrayListForDownload.map((item) => item.name));

        /**
         * if manageColumn is enabled we will sent only columns,
         * which are visible on UI to user while downloading report
         */
        if (this.enableManageColumns) {
          const displayedMetricsList = this.tableColumns.map(
            (column) => column.field
          );
          const whiteListedWidgetsForDownloadFix =
            Vue.options.filters.config_check(
              'feature.dashboardServiceTable.download.whiteListedWidgets'
            ) || '';
          this.widgetRequestParams[':metricsList'] = metricsArrayListForDownload
            .filter((value) => {
              let toSearch = value.name;
              if (whiteListedWidgetsForDownloadFix.includes(this.widgetName)) {
                if (value.name !== value.alias) {
                  toSearch = value.alias;
                }
              }
              return displayedMetricsList.includes(toSearch);
            })
            .map((metric) => metric.name);
        }

        // if any widget has custom download enabled
        if (this.customDownloadEnable) {
          await this.customDownloadFunction(
            this.metadata,
            this.widgetRequestParams
          );
        } else {
          await dashUtils.download(
            this.metadata,
            this.widgetRequestParams,
            this.applyFormattingDownload,
            this.downloadFileCustomName
          );
        }
      } catch (error) {
        this.$snackbar.open({
          message: 'Something Went Wrong',
          duration: 6000,
          actionText: ''
        });
      }
      this.isDownloadLoading = false;
    },
    onSearchSubmit(data) {
      const requestParamCopy = cloneDeep(this.requestParams[this.widgetName]);
      const searchDimension = this.searchParams.searchDimension || 'search';
      requestParamCopy[':dimensionNameValueList'] =
        requestParamCopy[':dimensionNameValueList']?.filter(
          (e) =>
            e.dimensionName !== (this.searchParams.searchDimension || 'search')
        ) ?? [];
      if (data) {
        requestParamCopy[':dimensionNameValueList'].push({
          dimensionName: searchDimension,
          dimensionValue: data,
          operator: 'ILIKE'
        });
      }
      this.updateWidgetRequestParams(requestParamCopy, this.widgetName);
    },
    getCurrentWidgetRequestParams() {
      return this.getWidgetRequestParams(this.widgetName);
    },
    getCurrentWidgetMeta(widgetId) {
      return this.getWidgetMetaForKey(widgetId);
    }
  }
};
