<template>
  <div
    class="u-color-grey-x-light u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-l"
  >
    <div>Last updated: {{ formattedDate() }}</div>
    <div
      class="u-display-flex u-cursor-pointer u-color-blue-base u-flex-align-items-center u-spacing-pl-m"
      @click="$emit('onRefresh')"
    >
      <rb-icon
        icon="sync"
        class="u-color-blue-base rb-icon--medium u-spacing-mr-xs"
      />
      <div>Refresh</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
export default {
  props: {
    lastUpdated: {
      type: Date,
      default: undefined
    }
  },
  methods: {
    formattedDate() {
      return moment(this.lastUpdated).format('hh:mm a');
    }
  }
};
</script>
