<template>
  <span
    class="recommendations--actions u-display-flex u-flex-align-items-center u-height-100"
    @click.stop
  >
    <div
      class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-center"
    >
      <div
        v-if="mounted && !isActionInProgress"
        class="recommendations--status u-display-flex u-flex-align-items-center u-cursor-pointer u-flex-justify-content-center"
      >
        <span
          v-if="
            isActionApplicable && !isNonBoomAction && hasBoomerangActionsAccess
          "
          class="approve--btn u-spacing-p-xs u-color-orange-base u-border-all u-border-color-orange-base u-border-width-s u-font-size-7 u-font-weight-bold u-border-radius-xs"
          @click.stop="selectRow()"
          >Approve</span
        >
        <!-- <span v-if="isNonBoomAction && isActionApproved" @click.stop="selectRow(actions['action_taken'])" class="action-taken--btn u-spacing-p-xs u-color-cyan-base u-border-all u-border-color-cyan-base u-border-width-s u-font-size-7 u-font-weight-bold u-border-radius-xs">Action taken</span> -->
        <span
          :ref="'trigger'"
          v-tippy="isNonBoomAction && statusTippyOptions"
          :class="statusColors[selectedStatus]"
          class="status--btn u-spacing-p-xs u-font-size-7 u-font-weight-bold u-border-radius-xs"
          >{{ selectedStatus }}</span
        >
        <div
          v-if="isNonBoomAction"
          :id="'statusHTML' + _uid"
          class="u-spacing-pv-s"
          style="width: 105px"
        >
          <div
            v-for="action in availableActions"
            :key="action.key"
            class="list--blue-hover list--hover"
          >
            <li
              class="u-spacing-ph-m u-spacing-pv-s"
              @click.stop="selectRow(action)"
            >
              <span
                class="status--btn u-spacing-p-xs u-font-size-7 u-font-weight-bold u-border-radius-xs"
                :class="statusColorsForDropDown[action.key]"
                >{{ action.title }}</span
              >
            </li>
          </div>
        </div>
      </div>
      <div v-if="isActionInProgress">
        <div class="mini-spinner">
          <div class="bounce1" />
          <div class="bounce2" />
          <div class="bounce3" />
        </div>
      </div>
      <div
        v-if="isFailedState"
        ref="failStateTippy"
        v-tippy="tippyOptions"
        class="u-spacing-pl-xs"
        :title="'Sorry, the action you took failed due to a system issue. Please try again.'"
      >
        <rb-icon
          class="rb-icon--medium u-color-red-base"
          :icon="'info-circle-fill'"
        />
      </div>
    </div>
  </span>
</template>

<script>
import actionsMixin from '@/utils/mixins/actionsMixin';
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'alertsTableStatus',
  mixins: [actionsMixin],
  data() {
    return {
      tippyOptions: {
        placement: 'bottom',
        maxWidth: '200px',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      mounted: false,
      statusColors: {
        Open: 'u-bg-color-grey-white u-color-grey-x-light u-border-all u-border-color-xx-light u-border-width-s',
        Approved: 'u-color-grey-white u-bg-color-orange-base',
        'Action taken': 'u-color-grey-white u-bg-color-cyan-base',
        Failed: 'u-color-grey-white u-bg-color-grey-xx-light'
      },
      statusColorsForDropDown: {
        Open: 'u-bg-color-grey-white u-color-grey-x-light u-border-all u-border-color-xx-light u-border-width-s',
        Approved:
          'u-bg-color-grey-white u-color-orange-base u-border-all u-border-color-orange-base u-border-width-s',
        'Action taken':
          'u-bg-color-grey-white u-color-cyan-base u-border-all u-border-color-cyan-base u-border-width-s'
      },
      statusTextMap: {
        OPEN: 'Open',
        IN_PROGRESS: 'Approved',
        APPROVED: 'Approved',
        ACTION_TAKEN: 'Action taken',
        FAILED: 'Open'
      },
      actions: [
        {
          key: 'Open',
          title: 'Open',
          id: 'nonBoomOpen',
          value: 'OPEN',
          successMsg: 'marked as Open.'
        },
        {
          key: 'Approved',
          title: 'Approve',
          id: 'nonBoomApprove',
          value: 'APPROVED',
          successMsg: 'marked as Approved.'
        },
        {
          key: 'Action taken',
          title: 'Action taken',
          id: 'nonBoomActionTaken',
          value: 'ACTION_TAKEN',
          successMsg: 'marked as Action taken.'
        }
      ]
    };
  },
  computed: {
    statusTippyOptions() {
      if (this.mounted) {
        return {
          html: '#statusHTML' + this._uid,
          interactive: true,
          theme: 'dropdown',
          trigger: 'mouseenter',
          placement: 'bottom-start',
          reactive: true,
          distance: 5,
          popperOptions: {
            modifiers: {
              preventOverflow: {
                enabled: false
              },
              hide: {
                enabled: false
              }
            }
          }
        };
      } else {
        return null;
      }
    },
    selectedStatus() {
      let state = 'OPEN';
      state = this.params.data.action_state || this.params.data.status;
      return this.statusTextMap[state];
    },
    isActionApproved() {
      const state = this.params.data.action_state || this.params.data.status;
      return state === 'APPROVED';
    },
    isNonBoomAction() {
      return !this.currentAction;
    },
    isActionInProgress() {
      return this.params.node.data.isLoading;
    },
    availableActions() {
      return this.actions.filter((item) => {
        return (
          item.value !== this.params.data.action_state ||
          this.params.data.status
        );
      });
    },
    hasBoomerangActionsAccess() {
      if (this.isBudgetPlanManualAction) {
        return true;
      }
      const roles = this.$store.getters.getSessionUser.roles;
      const hasRequiredRole =
        roles.indexOf(this.$store.state.alerts.actionRoleRequired) !== -1;
      return hasRequiredRole;
    }
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    selectRow(actionObj) {
      if (this.isBudgetPlanManualAction) {
        this.params.approveAction(this.params);
        return;
      }
      let action = {
        id: 'edit',
        successMsg: 'approved.'
      };
      if (!this.currentAction) {
        // i.e. if its a nonBoom action
        action = actionObj;
        this.params.node.data.isLoading = true;
        this.params.context.componentParent.requestedWorkspaceAction = action;
        this.params.context.componentParent.selections = [this.params.node];
        this.params.context.componentParent.totalSelections =
          this.params.context.componentParent.selections.length;
        this.params.context.componentParent.validSelections =
          this.params.context.componentParent.selections.length;
        this.params.context.componentParent.submitAction(false, action);
        return;
      }
      this.params.node.setSelected(true);
      this.params.context.componentParent.openActionsWorkspace(
        action,
        false,
        1
      );
    }
  }
};
</script>

<style lang="css" scoped>
.approve--btn {
  display: none;
}
.approve--btn:hover {
  background-color: #ffa800;
  color: #fff;
}
</style>
