<template>
  <section class="shadows">
    <div class="u-display-flex u-spacing-ph-l u-flex-1 u-bg-color-grey-white">
      <rb-filter-panel
        id="filterID"
        :key="filterKey"
        :data="filterData"
        :primary="primaryFilterData"
        :secondary-data="secondaryFilterData"
        :listener-event="filterLocalStorageKey"
        :emit="filterEmit"
        :loader-status="loaderStatus"
        :has-search="true"
        :new-date="enableDate"
        :enable-time-select="enableTimeSelect"
        :allow-date-compare="allowDateCompare"
        :default-date-post-range="defaultDatePostRange"
        :on-create="onCreate"
        :enable-save="enableSave"
        :page="page"
        :hide-filter="true"
        :min-max-date="minMaxDate"
        :enable-remove-all="false"
        :show-notification="false"
        :hide-remove-btn="hideRemoveBtn"
        :additional-ranges="additionalRanges"
        :date-mode="dateMode"
        :enable-open-filter="enableOpenFilter"
        :state="filterState"
        :external-filter-selection-listener="externalFilterSelectionListener"
        :selection-mode="selectionMode"
        :hide-clear-all-btn="hideClearAllBtn"
        :enable-minimum-selection="enableMinimumSelection"
        :config="config"
        :disable-date-range-selection="disableDateRangeSelection"
        :css-class="cssClass"
        :hide-add-filter="hideAddFilter"
        @savedFilterApplied="handleSaveFilterApplied"
        @filterMounted="handleFilterMount"
        @filterBeforeDestroy="filterBeforeDestroy"
      >
        <template slot="below-date-range">
          <slot name="below-date-range" />
        </template>
        <div
          id="filter-slot"
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-justify-content-flex-start u-flex-align-items-center"
        >
          <slot name="before-filter" />
          <vr-select
            v-if="viewSelectionEnabled"
            :value="selectedOption"
            :options="optionsList"
            :group-by-key="'groupName'"
            @input="handleVrSelectInput"
            @onHide="onHide"
            @onShow="onShow"
          >
            <div
              id="trigger"
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-color-grey-lighter u-cursor-pointer optionsDropdown u-flex-justify-content-flex-end"
            >
              <Bookmark :title="(selectedOption || {}).label" />
              <div
                class="u-display-flex u-flex-justify-content-space-between u-color-grey-base"
              >
                <span class="u-font-size-2 u-font-weight-bold">{{
                  (selectedOption || {}).label
                }}</span>
              </div>
              <rb-icon
                class="rb-icon--x-medium u-color-grey-lighter"
                :icon="'caret-down'"
                expoter="display-none"
              />
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <div
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
                :title="option.label"
              >
                <p v-if="!option.edit">
                  {{ option.label }}
                </p>
                <input
                  v-if="option.edit"
                  :id="'input' + option.globalViewId"
                  v-model="option.label"
                  class="input_value"
                  :disabled="!option.edit"
                  @click.stop="option.edit ? '' : true"
                />
                <section
                  v-if="option.groupName !== 'Default Views' && option.edit"
                >
                  <span @click.stop="toggleEdit(option)">
                    <rb-icon
                      class="rb-icon--medium u-color-grey-lighter"
                      :icon="'cross-fill-circle'"
                    />
                  </span>
                  <span @click.stop="updateViewName(option)">
                    <rb-icon
                      class="rb-icon--medium u-spacing-ml-s u-color-grey-lighter"
                      :icon="'check-fill-circle'"
                    />
                  </span>
                </section>
                <section
                  v-if="option.groupName !== 'Default Views' && !option.edit"
                  class="u-display-flex"
                >
                  <div
                    v-if="option.currentUser"
                    class="viewActionIcons u-flex-align-items-center"
                  >
                    <span @click.stop="toggleEdit(option)">
                      <rb-icon
                        class="rb-icon--medium u-spacing-ml-s u-color-grey-lighter"
                        :icon="'pencil'"
                      />
                    </span>
                    <span @click.stop="onShowConfirmDialogFn('delete', option)">
                      <rb-icon
                        class="rb-icon--medium u-spacing-ml-s u-color-grey-lighter"
                        :icon="'delete'"
                      />
                    </span>
                  </div>
                  <div :name="'infoTooltip' + option.globalViewId">
                    <rb-icon
                      v-if="option.currentUser"
                      class="rb-icon--medium u-spacing-ml-s u-color-grey-lighter"
                      :icon="'admin'"
                    />
                    <rb-icon
                      v-if="!option.currentUser"
                      class="rb-icon--medium u-spacing-ml-s u-color-grey-lighter"
                      :icon="'person'"
                    />
                  </div>
                  <tippy
                    v-if="!hideInsideTippy"
                    :to="'infoTooltip' + option.globalViewId"
                    interactive="true"
                    reactive="true"
                    trigger="mouseenter"
                    distant="0"
                    :duration="0"
                    theme="dropdown"
                    placement="bottom-end"
                  >
                    <div style="background: #333333; padding: 8px">
                      <p style="color: white; padding-bottom: 8px">
                        Created by: {{ option.createdBy }}
                      </p>
                      <p style="color: white; padding-bottom: 4px">
                        Created at: {{ option.formattedAt }}
                      </p>
                    </div>
                  </tippy>
                </section>
              </div>
            </template>
          </vr-select>
          <div
            v-if="!disableView"
            v-show="!hideViewBtn"
            class="u-display-flex"
          >
            <rb-button
              v-show="!hideUpdateView"
              id="updateView"
              text="Update view"
              :loading="isUpdateViewLoading"
              :type="'filled'"
              :click-fn="onShowConfirmDialogFn.bind(this, 'update')"
              class="u-spacing-ml-m"
              expoter="display-none"
            />
            <GenericInputTippy
              :title="'Save as new view'"
              :is-loading="isSaveViewLoading"
              expoter="display-none"
              @onUpdateView="onUpdateView"
            />
            <rb-button
              v-tippy="{ placement: 'top', arrow: false }"
              :title="'Reset the filters and dates to the last saved view.'"
              icon-left="revert"
              :type="'hollow'"
              :click-fn="onResetView"
              class="u-spacing-ml-s hollow-outlined-btn u-bg-color-transparent-base"
              expoter="visibility-hidden"
            />
          </div>
        </div>
        <template slot="preDatePicker">
          <slot name="preDatePicker" />
        </template>
        <template slot="download">
          <slot name="download" />
        </template>
        <div
          v-if="!hideExport"
          slot="download"
        >
          <rb-button
            v-tippy="{ placement: 'top', arrow: false }"
            :title="'Click here to export the dashboard as a PDF. The current selections and data present on the dashboard will be exported.'"
            text=""
            icon-left="share"
            :type="'hollow'"
            :click-fn="exportWidgets"
            class="u-spacing-ml-s hollow-outlined-btn u-bg-color-transparent-base export-pdf"
            expoter="display-none"
          />
        </div>
        <div
          v-if="invalidFilters.length > 0"
          slot="before-filter"
          class="rb-filter"
          expoter="display-none"
        >
          <div
            class="filter--token u-spacing-mr-s u-display-inline-flex u-flex-align-items-center"
          >
            <p
              class="u-display-flex u-flex-align-items-center u-color-grey-base"
            >
              <rb-icon
                class="rb-icon--small u-color-orange-base u-spacing-mr-xs"
                :icon="'warning'"
              />
              One or more saved filters are invalidated.
              <span
                class="u-spacing-ml-xs u-color-grey-lighter u-cursor-pointer u-text-decoration-underline"
                @click="onShowConfirmDialogFn('invalidFilter')"
                >view details</span
              >
            </p>
          </div>
        </div>
        <template slot="after-filter">
          <slot name="after-filter" />
        </template>
      </rb-filter-panel>
    </div>
    <slot name="footer" />
  </section>
</template>

<script>
import GenericInputTippy from '@/components/basic/GenericInputTippy';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';

export default {
  components: {
    GenericInputTippy,
    rbFilterPanel
  },
  props: {
    allowDateCompare: {
      type: Boolean,
      default: true
    },
    hideAddFilter: {
      type: Boolean,
      default: false
    },
    cssClass: {
      type: String,
      default: 'u-flex-justify-content-space-between'
    },
    disableDateRangeSelection: {
      type: Boolean,
      default: false
    },
    viewSelectionEnabled: {
      type: Boolean,
      default: true
    },
    enableMinimumSelection: {
      type: Boolean,
      default: false
    },
    hideClearAllBtn: {
      type: Boolean,
      default: false
    },
    selectionMode: {
      type: String,
      default: null
    },
    disableView: {
      type: Boolean,
      default: false
    },
    hideExport: {
      type: Boolean,
      default: false
    },
    filterEmit: {
      type: String,
      default: null
    },
    externalFilterSelectionListener: {
      type: String,
      default: null
    },
    showFilter: {
      type: Boolean,
      default: false
    },
    filterKey: {
      type: Number,
      default: 0
    },
    invalidFilters: {
      type: Array,
      default: () => []
    },
    isUpdateViewLoading: {
      type: Boolean,
      default: false
    },
    isSaveViewLoading: {
      type: Boolean,
      default: false
    },
    page: {
      type: String,
      default: null
    },
    additionalRanges: {
      type: Object,
      default: () => ({})
    },
    hideViewBtn: {
      type: Boolean,
      default: true
    },
    hideRemoveBtn: {
      type: Boolean,
      default: false
    },
    hideUpdateView: {
      type: Boolean,
      default: true
    },
    enableOpenFilter: {
      type: Boolean,
      default: true
    },
    minMaxDate: {
      type: Object,
      default: () => ({})
    },
    defaultDatePostRange: {
      type: String,
      default: null
    },
    enableDate: {
      type: Boolean,
      default: true
    },
    dateMode: {
      type: Object,
      default: function () {
        return {
          mode: 'day'
        };
      }
    },
    enableTimeSelect: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    filterLocalStorageKey: {
      type: String,
      default: null
    },
    selectedOption: {
      type: Object,
      default: () => ({
        label: 'Overview Business'
      })
    },
    optionsList: {
      type: Array,
      default: () => []
    },
    filterState: {
      type: Object,
      default: () => ({})
    },
    filterData: {
      type: Array,
      default: () => []
    },
    primaryFilterData: {
      type: Array,
      default: () => []
    },
    secondaryFilterData: {
      type: Object,
      default: () => ({})
    },
    enableSave: {
      type: Boolean,
      default: false
    },
    loaderStatus: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: function () {
        return {
          search: {}
        };
      }
    }
  },
  data() {
    return {
      hideInsideTippy: false
    };
  },
  methods: {
    handleFilterMount(instance) {
      this.$emit('filterMounted', instance);
    },
    filterBeforeDestroy() {
      this.$emit('filterBeforeDestroy');
    },
    handleSaveFilterApplied() {
      this.$emit('savedFilterApplied');
    },
    toggleEdit(option) {
      this.$emit('toggleEdit', option);
    },
    hideTippy() {
      const instance = document.querySelector('.tippy-popper')?._tippy;
      instance?.hide();
    },
    updateViewName(view) {
      this.$emit('toggleEdit', view);
      this.hideTippy();
      this.onUpdateView('update', view, 'name');
    },
    handleVrSelectInput(selectedOption) {
      this.$emit('input', selectedOption);
    },
    onUpdateView(mode, data, type) {
      const payload = { mode, data, type };
      this.hideTippy();
      this.$emit('updateView', payload);
    },
    exportWidgets() {
      this.$emit('exportWidgets');
    },
    onResetView() {
      this.$emit('resetView');
    },
    onCreate(instance) {
      this.$emit('onCreate', instance);
    },
    onHide(tippyInstance) {
      this.$emit('onHide');
      this.hideInsideTippy = true;
    },
    onShow(tippyInstance) {
      this.hideInsideTippy = false;
    },
    onShowConfirmDialogFn(type, view) {
      this.onUpdateView(type, view);
    }
  }
};
</script>

<style lang="css" scoped>
.shadows {
  box-shadow: 2px 1px 8px 0 #e9eaeb;
}
.u-sticky-on-scroll {
  position: sticky;
  z-index: 2;
}

.u-maxWidth {
  max-width: 1440px;
  margin: auto;
}

.u-border-none {
  border: none;
}

.input_value {
  border: none !important;
  outline: none;
  background: none;
  padding-left: 0px;
  color: #2b333b !important;
}

.input_value:hover {
  color: #fff !important;
}

.viewActionIcons {
  visibility: hidden;
}

.overrideHover {
  background: #f5f7fa;
}
.overrideHover .input_value {
  color: #2b333b !important;
  width: 100%;
}
.overrideHover .input_value::selection {
  background-color: #bfdffd;
}
.overrideHover .icon-cross-fill-circle {
  color: #8b8f93 !important;
  fill: #8b8f93 !important;
}
.overrideHover .icon-save {
  color: #007cf6 !important;
  fill: #007cf6 !important;
}

.select__dropdown-list-item:hover .viewActionIcons {
  visibility: visible;
  display: inline-flex;
}

.nav-transparent {
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}

.deleteBtn:hover {
  background-color: #d7263d;
}
</style>
