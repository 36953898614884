<template>
  <div class="u-spacing-mv-s">
    <div
      v-tippy="{
        placement: 'bottom-start',
        interactive: true,
        reactive: true,
        arrow: false
      }"
      :title="inputDescription"
      class="u-spacing-mb-s u-font-size-5 u-color-grey-base"
    >
      {{ inputLabel }}
      <span v-if="isRequired">*</span>
    </div>

    <rb-input
      v-if="inputType === 'NUMBER'"
      v-model="model"
      type="number"
      :class="{ error: hasError }"
      class="u-display-flex u-flex-align-items-center u-font-size-7"
      placeholder="15"
    />
    <rb-switch
      v-else-if="inputType === 'SWITCH'"
      v-model="model"
    ></rb-switch>
    <rb-input
      v-else-if="inputType === 'LIST'"
      v-model="arrayModel"
      :class="{ error: hasError }"
      type="textarea"
      class="u-width-100"
    />
    <rb-input
      v-else-if="inputType === 'QUOTED_LIST'"
      v-model="quotedArrayModel"
      :class="{ error: hasError }"
      type="textarea"
      class="u-width-100"
      @blur="validateAndResetQuotedListData"
    />
    <rb-input
      v-else-if="inputType === 'STRING'"
      v-model="model"
      :class="{ error: hasError }"
    ></rb-input>
    <rb-filter-panel
      v-else-if="inputType === 'FILTERS'"
      :key="filterKey"
      :style="{
        'z-index': isFilterActive ? 2 : 0
      }"
      :data="filterData"
      :primary="primaryFilterData"
      :hide-filter="true"
      :new-date="false"
      :enable-remove-all="false"
      :filter-v2="true"
      :show-ingestion-progress="true"
      :default-filters="appliedFilters"
      :use-local-storage="false"
      @filterMounted="handleFilterMount"
      @allFilter="appliedFilters = $event"
    />
    <div
      v-if="hasError"
      class="u-font-size-5 u-spacing-mt-s u-color-red-base"
    >
      * {{ hasError }}
    </div>
  </div>
</template>

<script>
import rbFilterPanel from '@/components/widgets/filter-panel';

export default {
  components: { rbFilterPanel },
  props: {
    value: {
      type: [String, Boolean, Array, Object, Number],
      default: null
    },
    inputLabel: {
      type: String,
      default: ''
    },
    inputType: {
      type: String,
      default: 'STRING'
    },
    inputDescription: {
      type: String,
      default: ''
    },
    filterData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    primaryFilterData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    hasError: {
      type: String,
      default: ''
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filterInstance: null,
      filterKey: 1
    };
  },
  computed: {
    arrayModel: {
      get() {
        return this.value?.join?.(',');
      },
      set(val) {
        if (val.length) this.$emit('input', val?.split?.(','));
        else {
          this.$emit('input', []);
        }
      }
    },
    quotedArrayModel: {
      get() {
        return this.value?.map((val) => `"${val}"`).join?.(', ');
      },
      set(val) {
        let quotedValues = val.match(/"([^"]*)"/g) || [];
        quotedValues = quotedValues
          .map((match) => match.slice(1, -1))
          .filter((val) => val.trim().length !== 0 && val.trim() !== ',');
        this.$emit('input', quotedValues);
      }
    },
    model: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.inputType === 'SWITCH') console.log(val);
        this.$emit('input', val);
      }
    },
    isFilterActive() {
      return (
        this.filterInstance?.isFilterDropdownActive ||
        this.filterInstance?.instances?.some((instance) => instance.isActive)
      );
    },
    appliedFilters: {
      get() {
        if (this.inputType !== 'FILTERS') return null;
        let filtersArray = JSON.parse(this.value);
        const filters =
          filtersArray?.reduce((acc, el) => {
            Object.entries(el).forEach(([k, v]) => {
              acc[k] = v;
            });
            return acc;
          }, {}) ?? {};
        return {
          values: filters,
          order: Object.keys(filters)
        };
      },
      set(val) {
        if (val) {
          let filtersArray = Object.entries(val).reduce((acc, [k, v]) => {
            acc.push({ [k]: v });
            return acc;
          }, []);
          this.$emit('input', JSON.stringify(filtersArray));
        } else {
          this.$emit('input', null);
        }
      }
    }
  },
  watch: {
    primaryFilterData() {
      this.filterKey++;
    }
  },
  methods: {
    handleFilterMount(instance) {
      this.filterInstance = instance;
    },
    validateAndResetQuotedListData() {
      /**
       * rb-input in CIQ-UI stores the passed value in the newValue and uses it for the input.
       * In the setter, we have handling to fallback to empty array if there is no valid match.
       * When there is an invalid input, the input-component fallbacks to the empty array but the invalid value is still displayed on the UI.
       * This is because we are not resetting the value passed to the rb-input.
       * When we have an invalid input, the quotedArrayModel will be an empty string.
       * By resetting the input to a valid one and then setting it to empty string, we are force resetting the value displayed in rb-input
       * Likewise, by setting the quotedArrayModel to quotedArrayModel again, we are resetting the displayed value if there is mismatch for non-empty case.
       */
      let initialValue = this.quotedArrayModel;
      this.quotedArrayModel = '"reset"';
      this.$nextTick(() => {
        this.quotedArrayModel = initialValue;
      });
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .error input {
  border-color: #d7263d;
}
::v-deep .error textarea {
  border-color: #d7263d;
}
</style>
