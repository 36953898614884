var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"u-font-size-5 u-position-relative u-width-100"},[_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTooltip),expression:"showTooltip"}],ref:"bubble-chart-tooltip",staticClass:"bubble-chart-tooltip",style:({
      transform: ("translate(" + _vm.tooltipLeft + "px, " + _vm.tooltipTop + "px)")
    })},[_vm._t("tooltip",null,{"popupData":_vm.popupData})],2),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMidpointPopup),expression:"showMidpointPopup"}],ref:"bubble-chart-midpoint-tooltip",staticClass:"u-position-absolute bubble-chart-midpoint-tooltip",style:({
      'z-index': 9999,
      transform: ("translate(" + _vm.midPointLeft + "px, " + _vm.midPointTop + "px)")
    })},[_c('section',{staticClass:"u-bg-color-grey-white box-shadows u-spacing-p-m"},[_c('div',{staticClass:"u-spacing-pb-m u-display-flex u-flex-align-items-center"},[_c('rb-icon',{staticClass:"u-cursor-pointer u-color-orange-base",attrs:{"icon":'mid_point_inverted'}}),_c('div',{staticClass:"u-spacing-ml-s u-color-grey-base u-font-size-3 u-font-weight-600"},[_vm._v(" Midpoint ")])],1),_c('BubbleChartPopupRow',{attrs:{"axis-label":_vm.xAxisLabel,"coordinate":_vm.getCoordinate(
            _vm.xAxisOffset - _vm.xTranslationOffset,
            _vm.xAxisUnit,
            undefined,
            _vm.coordinatePrecision
          ),"axis-heading":"Horizontal Axis:"}}),_c('BubbleChartPopupRow',{staticClass:"u-spacing-mt-l",attrs:{"axis-label":_vm.yAxisLabel,"coordinate":_vm.getCoordinate(
            _vm.yAxisOffset - _vm.yTranslationOffset,
            _vm.yAxisUnit,
            undefined,
            _vm.coordinatePrecision
          ),"axis-heading":"Vertical Axis:"}})],1)]),_c('section',{staticClass:"u-width-100",attrs:{"id":_vm.chartId}})])}
var staticRenderFns = []

export { render, staticRenderFns }