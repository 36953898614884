<template>
  <div
    class="u-display-flex"
    :class="params.containerStyles"
  >
    <span :class="params.textStyles">
      {{ params.headerText }}
    </span>
    <div
      :class="params.ctaStyles"
      @click="handleCtaClick"
    >
      {{ params.ctaText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderWithCta',
  methods: {
    handleCtaClick() {
      this.params.onCtaClick?.(this.params);
    }
  }
};
</script>
