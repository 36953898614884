<template>
  <div
    class="u-display-flex u-bg-color-blue-xxx-light u-flex-direction-column u-font-size-6 u-spacing-pt-s u-spacing-pb-s u-spacing-pl-m u-spacing-pr-m u-height-100 u-border-radius-s u-color-grey-mid-light"
  >
    <div
      v-for="(observation, index) in observations"
      :key="index"
      class="u-display-flex u-flex-direction-row u-spacing-mv-s"
    >
      <div
        class="show-chart-holder u-display-flex u-flex-align-items-center u-flex-justify-content-center u-border-radius-xs u-spacing-p-xxs u-spacing-mr-m"
      >
        <rb-icon
          class="rb-icon--small u-color-grey-white"
          :icon="'show-chart'"
        />
      </div>
      <!-- eslint-disable -->
      <span
        class="u-line-height-14"
        v-html="observation"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    observations: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="css" scoped>
.show-chart-holder {
  width: 16px;
  height: 16px;
  background-color: #007cf6;
}
.u-line-height-14 {
  line-height: 14px;
}
.u-bg-color-blue-xxx-light {
  background-color: #f2f8fe;
}
</style>
