import Vue from 'vue';
import HttpService from '@/utils/services/http-service';
import { widgetsConfig } from '@/components/pages/businessInsights/widgets.js';
import { cloneDeep } from 'lodash';
import CONSTANTS from '@/utils/constants.js';

const state = {
  widgetConfig: {},
  metaDataConfig: {},
  measureList: [],
  selectedMeasure: {},
  entityList: [],
  selectedEntity: {},
  topGainersData: {
    rows: [],
    totalRows: 0
  },
  topLosersData: {
    rows: [],
    totalRows: 0
  },
  recommendation_count: {},
  metricOrderList: [],
  rowPerPage: 5,
  recommendationDataFetched: false
};

const initStore = (state, storeKey, retailer) => {
  Vue.set(state, 'widgetConfig', {});
  Vue.set(state, 'metaDataConfig', {});
  Vue.set(state, 'measureList', []);
  Vue.set(state, 'selectedMeasure', {});
  Vue.set(state, 'entityList', []);
  Vue.set(state, 'selectedEntity', {});
  Vue.set(state, 'topGainersData', {
    rows: [],
    totalRows: 0
  });
  Vue.set(state, 'topLosersData', {
    rows: [],
    totalRows: 0
  });
  Vue.set(state, 'recommendation_count', {});
};

const getters = {
  getTopMoverFilterEnabled: (state) => {
    return state?.selectedEntity?.additionalData?.filterEntity;
  },
  getTopMoverDisabled: (state) => {
    return state?.selectedEntity?.additionalData?.disableButton;
  },
  getTopMoversMeasureList: (state) => {
    return state.measureList;
  },
  getTopMoversSelectedMeasure: (state) => {
    return state.selectedMeasure;
  },
  getTopMoversEntityList: (state, getters, rootState) => {
    const entityList = cloneDeep(state.entityList) || [];
    const whereClause = cloneDeep(getters.getWhereClause);
    const finalList = [];
    if (
      CONSTANTS.v2TopMoversSupportedRetailers.includes(
        rootState?.app?.retailerConfig?.retailer
      ) ||
      CONSTANTS.v2TopMoversSupportedRegions.includes(
        rootState?.app?.retailerConfig?.location
      )
    ) {
      return entityList || [];
    }
    for (let i = 0; i < entityList.length; i++) {
      if (!entityList[i]?.filterGroup?.allFiltersApplicable) {
        for (let j = 0; j < whereClause.dimensionNameValueList.length; j++) {
          const index = entityList[i].filterGroup.filters.findIndex(
            (filterKey) => {
              return (
                whereClause.dimensionNameValueList[j].dimensionName ===
                filterKey
              );
            }
          );
          if (index > -1) {
            entityList[i].disable = false;
          } else {
            entityList[i].disable = true;
            break;
          }
        }
      }
      finalList.push(entityList[i]);
    }
    return [...finalList];
  },
  getTopMoversSelectedEntity: (state) => {
    return state.selectedEntity;
  },
  getTopGainersData: (state) => {
    return state.topGainersData;
  },
  getTopLosersData: (state) => {
    return state.topLosersData;
  },
  getTopMoversMetaData: (state) => {
    return state.metaDataConfig;
  },
  getTopMoversRowPerPage: (state) => {
    return state.rowPerPage;
  },
  gettopMoversSaveViewData: (state) => {
    return {
      selectedEntity: state.selectedEntity.dimensionColumn,
      selectedMeasure: state.selectedMeasure?.alias,
      selectedMetricsList:
        state?.metaDataConfig?.metadata?.metricOrderList || []
    };
  },
  getIsRecommendationDataFetched: (state) => {
    return state.recommendationDataFetched;
  }
};

const mutations = {
  TOPMOVERS_STORE_INIT: (state, data) => {
    initStore(state, data?.storeKey, data?.retailer);
  },
  SET_TOPMOVERS_WIDGETCONFIG: (state, data) => {
    Vue.set(state, 'widgetConfig', data.data);
  },
  SET_TOPMOVERS_METADATACONFIG: (state, data) => {
    Vue.set(state, 'metaDataConfig', data.data);
  },
  SET_TOPMOVERS_METRICLIST: (state, data) => {
    Vue.set(state, data.storeKey, data?.data?.metadata?.metricOrderList);
  },
  SET_TOPMOVERS_MEASURELIST: (state, data) => {
    Vue.set(state, data.storeKey, data.data);
  },
  SET_TOPMOVERS_ENTITYLIST: (state, data) => {
    Vue.set(state, data.storeKey, data.data);
  },
  SET_TOPMOVERS_SELECTEDMEASURE: (state, data) => {
    Vue.set(state, data.storeKey, data.data);
  },
  SET_TOPMOVERS_SELECTEDMEASUREFROMPERFORMANCEOVERVIEW: (state, data) => {
    Vue.set(state, data.storeKey, data.data);
  },
  SET_TOPMOVERS_SELECTEDENTITY: (state, data) => {
    Vue.set(state, data.storeKey, data.data);
  },
  SET_TOPMOVERS_TOGGLEVALUE: (state, data) => {
    Vue.set(state, data.storeKey, data.data);
  },
  SET_TOPMOVERS_ROWPERPAGE: (state, data) => {
    Vue.set(state, data.storeKey, data.data);
  },
  SET_TOPMOVERS_DATA: (state, data) => {
    Vue.set(state, data.storeKey, data.data);
  },
  SET_RECOMMENDATION_DATA: (state, data) => {
    Vue.set(state, 'recommendation_count', {});
    Vue.set(state, 'recommendation_count', data.data);
  },
  PaginationEvent: (state, data) => {
    Vue.set(state, data.storeKey, data.currentPage);
  },
  RowPageEvent: (state, data) => {
    Vue.set(state, data.storeKey, data.data);
  },
  SET_RECOMMENDATION_DATA_FETCHED: (state, data) => {
    Vue.set(state, data.storeKey, data.data);
  }
};

const actions = {
  TOPMOVERS_STORE_INIT_ACTION: (context, data) => {
    context.commit('TOPMOVERS_STORE_INIT', {
      storeKey: data.storeKey,
      retailer: data.retailer
    });
    context.commit('SET_TOPMOVERS_WIDGETCONFIG', {
      storeKey: data.storeKey,
      data: data.config
    });
    return HttpService.post(
      data.config?.APIs?.service,
      {
        widget: data.config?.widgetName,
        page: data.config?.page,
        globalViewId: data.config?.globalViewId
      },
      { append: data.config?.APIs?.endPoint }
    ).then(
      (response) => {
        const responseData = response.data;
        if (data.isDefaultView) {
          const userPageMeta = context.getters.getUserPageMetadata;
          if (
            userPageMeta?.page?.businessInsights?.topMovers?.metricsList?.length
          ) {
            responseData.metadata.metricOrderList =
              userPageMeta?.page?.businessInsights?.topMovers?.metricsList;
          }
        }
        context.commit('SET_TOPMOVERS_METADATACONFIG', {
          storeKey: data.storeKey,
          data: responseData
        });
        // setup the drop down
        const metrticList = Object.keys(response.data?.metrics).reduce(
          (result, key) => {
            const metric = response.data?.metrics[key];
            metric.title = metric.label;
            metric.groupBy = metric.views?.[0]?.label;
            if (
              !(
                (CONSTANTS.v2TopMoversSupportedRetailers.includes(
                  data.retailer
                ) ||
                  CONSTANTS.v2TopMoversSupportedRegions.includes(
                    context.getters.getLocation
                  )) &&
                (metric?.metadata?.visible === false ||
                  response?.data?.metadata?.hiddenMetrics?.includes(
                    metric?.alias
                  ))
              ) &&
              !(
                data.retailer === 'amazon_hybrid' &&
                (metric?.alias?.includes('sku_3p_all__') ||
                  metric?.metadata?.visible === false)
              )
            ) {
              result.push(metric);
            }
            return result;
          },
          []
        );
        context.commit('SET_TOPMOVERS_MEASURELIST', {
          storeKey: 'measureList',
          data: metrticList
        });
        // Setup the show by
        let entityList;
        if (
          CONSTANTS.v2TopMoversSupportedRetailers.includes(data.retailer) ||
          CONSTANTS.v2TopMoversSupportedRegions.includes(
            context.getters.getLocation
          )
        ) {
          entityList = response.data.metadata.availableGroupBy?.map((key) => {
            return {
              dimensionLabel: key.dimensionLabel,
              dimensionColumn: key.dimensionColumn,
              dimensionType: key.dimensionType,
              label: key.dimensionLabel,
              dimensionName: key.dimensionColumn
            };
          });
        } else {
          entityList = response.data?.customMetadata?.dropdownOptions.map(
            (key) => {
              key.label = key.dimensionLabel || key.label;
              key.dimensionType =
                widgetsConfig.filterMapping[key.dimensionType];
              return key;
            }
          );
        }

        // For ordering purpose
        let allItems = entityList.filter((item) => {
          return (
            item.dimensionType &&
            item.dimensionType !== 'Internal Categorization'
          );
        });
        const amazonCategoryItems = entityList.filter((item) => {
          return (
            !item.dimensionType ||
            item.dimensionType === 'Internal Categorization'
          );
        });
        allItems = [...amazonCategoryItems, ...allItems];

        context.commit('SET_TOPMOVERS_ENTITYLIST', {
          storeKey: 'entityList',
          data: allItems
        });
        let selectedEntity = null;
        let selectedMeasure = null;
        if (
          data.config?.metadata &&
          Object.keys(data.config?.metadata || {}).length > 0
        ) {
          selectedEntity =
            entityList.filter((item) => {
              return (
                item.dimensionColumn === data.config?.metadata.selectedEntity
              );
            })[0] || null;
          selectedMeasure =
            metrticList.filter((item) => {
              return item.alias === data.config?.metadata.selectedMeasure;
            })[0] || null;

          const viewState = {};
          viewState.type = data.config?.widgetName;
          viewState[data.config?.widgetName] = data.config?.metadata;
          if (data.config?.metadata?.selectedMetricsList) {
            const widgetMeta = response.data;
            widgetMeta.metadata.metricOrderList =
              data.config?.metadata?.selectedMetricsList;
            context.commit('SET_TOPMOVERS_METADATACONFIG', {
              storeKey: data.storeKey,
              data: widgetMeta
            });
          }

          context.dispatch('setGlobalViewAction', { ...viewState });
        } else if (response.data?.metadata?.defaultConfig) {
          selectedEntity =
            entityList.filter((item) => {
              return (
                item.dimensionColumn ===
                response.data?.metadata?.defaultConfig.selectedEntity
              );
            })[0] || null;
          selectedMeasure =
            metrticList.filter((item) => {
              return (
                item.alias ===
                response.data?.metadata?.defaultConfig.selectedMeasure
              );
            })[0] || null;
          const viewState = {};
          viewState.type = data.config?.widgetName;
          viewState[data.config?.widgetName] =
            response.data?.metadata?.defaultConfig;
          context.dispatch('setGlobalViewAction', { ...viewState });
        }

        if (selectedMeasure) {
          context.commit('SET_TOPMOVERS_SELECTEDMEASURE', {
            storeKey: 'selectedMeasure',
            data: selectedMeasure
          });
        } else {
          context.commit('SET_TOPMOVERS_SELECTEDMEASURE', {
            storeKey: 'selectedMeasure',
            data: metrticList[0]
          });
        }

        if (selectedEntity && selectedEntity.dimensionLabel === 'SKU') {
          return context
            .dispatch('ENTITY_CHANGE_ACTION', selectedEntity)
            .finally(() => {
              return 'success';
            });
        } else if (selectedEntity && selectedEntity.dimensionLabel !== 'SKU') {
          context.dispatch('ENTITY_CHANGE_ACTION', selectedEntity);
          return 'success';
        } else if (entityList && entityList[0]?.dimensionLabel === 'SKU') {
          return context
            .dispatch('ENTITY_CHANGE_ACTION', entityList[0])
            .finally(() => {
              return 'success';
            });
        } else {
          context.dispatch('ENTITY_CHANGE_ACTION', entityList[0]);
          return 'success';
        }
      },
      (error) => {
        return error;
      }
    );
  },
  MEASURE_CHANGE_ACTION: (context, data) => {
    context.commit('SET_TOPMOVERS_SELECTEDMEASURE', {
      storeKey: 'selectedMeasure',
      data: data
    });
  },
  ENTITY_CHANGE_ACTION: (context, data) => {
    if (
      data.dimensionLabel === 'SKU' &&
      data?.additionalData?.recommendationsCount?.api
    ) {
      context.dispatch('fetchRecommendationData', {
        storeKey: data.storeKey,
        entity: data
      });
    } else {
      context.commit('SET_RECOMMENDATION_DATA_FETCHED', {
        storeKey: 'recommendationDataFetched',
        data: true
      });
    }
    context.commit('SET_TOPMOVERS_SELECTEDENTITY', {
      storeKey: 'selectedEntity',
      data: data
    });

    const promise = new Promise((resolve) => {
      resolve('success');
    });
    return promise;
  },
  TOGGLE_CHANGE_ACTION: (context, data) => {
    context.commit('SET_TOPMOVERS_TOGGLEVALUE', {
      storeKey: 'isSplitByBusinessModelEnabled',
      data: data
    });
  },
  topMoversMeasureChange: (context, data) => {
    if (context.state?.metaDataConfig?.metrics?.[data.alias]) {
      context.dispatch('globalStoreLogger', {
        eventType: 'performance_widget_top_movers_metric_change',
        type: 'Click',
        widget: 'topMovers',
        metric: context.state?.metaDataConfig?.metrics[data.alias].label
      });
      context.commit('SET_TOPMOVERS_SELECTEDMEASUREFROMPERFORMANCEOVERVIEW', {
        storeKey: 'selectedMeasure',
        data: context.state?.metaDataConfig?.metrics[data.alias]
      });
    }
  },
  fetchTopMoversData: (context, data) => {
    context.commit('SET_TOPMOVERS_DATA', {
      storeKey: data.storeKey,
      data: {
        rows: [],
        totalRows: 0
      }
    });
    return HttpService.post('DASHBOARD_SERVICE', data.body, {
      append: data.endPoint
    }).then((response) => {
      const tableRowData = response.data.entityData.map((items) => {
        const entity = {};
        entity.entityType = items.entityType;
        entity.entityValue = items.entityValue;
        entity.totalEntityCount = items.totalEntityCount;
        items.data.forEach((item) => {
          if (context.state.selectedEntity.dimensionLabel === 'SKU') {
            entity.count =
              context.state?.recommendation_count?.[entity.entityValue];
          }
          if (
            CONSTANTS.v2TopMoversSupportedRetailers.includes(
              context.getters.getRetailer
            ) ||
            CONSTANTS.v2TopMoversSupportedRegions.includes(
              context.getters.getLocation
            )
          ) {
            const metricName = item.name;
            entity[metricName] = {};
            entity[metricName].metricName = item.name;
            entity[metricName].metricValue = item.RESULT?.[item?.name];
            // Doing Math.abs() for sorting
            entity[metricName].currentPeriod = Math.abs(
              item.PVP?.['PVP_DIFF_' + item.name]
            );
            entity[metricName].currentPeriod_data1 =
              item.PVP?.['PVP_DIFF_' + item.name];
            entity[metricName].metricType =
              context.state.metaDataConfig?.metrics?.[
                item.name
              ]?.metadata?.type;
            entity[metricName].metricUnit =
              context.state.metaDataConfig?.metrics?.[
                item.name
              ]?.metadata?.unit;

            entity[metricName].currentPeriod_data2 =
              item.PVP?.['PVP_' + item.name];

            entity[metricName].previousPeriod =
              item.SPLY?.['SPLY_DIFF_' + item.name];
            entity[metricName].previousPeriod_data1 =
              item.SPLY?.['SPLY_DIFF_' + item.name];

            entity[metricName].previousPeriod_data2 =
              item.SPLY?.['SPLY_' + item.name];

            if (context.state.selectedMeasure?.metadata?.isInverted) {
              entity[metricName].currentPeriod_invertTag = true;
              entity[metricName].previousPeriod_invertTag = true;
            }
            if (
              context.state.selectedMeasure?.metadata?.unit === 'PERCENTAGE'
            ) {
              entity[metricName].currentPeriod =
                item.PVP?.['PVP_DIFF_' + item.name];
              entity[metricName].currentPeriod_data1 =
                item.PVP?.['PVP_DIFF_' + item.name];
              entity[metricName].currentPeriod_data2 =
                entity[metricName].currentPeriod_data1;
              entity[metricName].previousPeriod =
                item.SPLY?.['SPLY_DIFF_' + item.name];
              entity[metricName].previousPeriod_data1 =
                item.SPLY?.['SPLY_DIFF_' + item.name];
              entity[metricName].previousPeriod_data2 =
                entity[metricName].previousPeriod_data1;
            }
          } else if (item.name === context.state.selectedMeasure?.keyName) {
            entity.metricName = item.name;
            entity.metricValue = item.RESULT[item.name];
            // Doing Math.abs() for sorting
            entity.currentPeriod = Math.abs(
              item.PVP?.['PVP_DIFF_' + item.name]
            );
            entity.currentPeriod_data1 = item.PVP?.['PVP_DIFF_' + item.name];
            entity.currentPeriod_data1_type =
              context.state.selectedMeasure?.metadata?.unit ??
              context.state.selectedMeasure?.metadata?.type;
            entity.currentPeriod_data2 = item.PVP?.['PVP_' + item.name];
            entity.currentPeriod_data2_type = 'PERCENTAGE';
            entity.previousPeriod = item.SPLY?.['SPLY_DIFF_' + item.name];
            entity.previousPeriod_data1 = item.SPLY?.['SPLY_DIFF_' + item.name];
            entity.previousPeriod_data1_type =
              context.state.selectedMeasure?.metadata?.unit ??
              context.state.selectedMeasure?.metadata?.type;
            entity.previousPeriod_data2 = item.SPLY?.['SPLY_' + item.name];
            entity.previousPeriod_data2_type = 'PERCENTAGE';
            if (context.state.selectedMeasure?.metadata?.isInverted) {
              entity.currentPeriod_invertTag = true;
              entity.previousPeriod_invertTag = true;
            }
            if (
              context.state.selectedMeasure?.metadata?.unit === 'PERCENTAGE'
            ) {
              entity.currentPeriod = item.PVP?.['PVP_DIFF_' + item.name];
              entity.currentPeriod_data1 = item.PVP?.['PVP_DIFF_' + item.name];
              entity.currentPeriod_data2 = entity.currentPeriod_data1;
              entity.previousPeriod = item.SPLY?.['SPLY_DIFF_' + item.name];
              entity.previousPeriod_data1 =
                item.SPLY?.['SPLY_DIFF_' + item.name];
              entity.previousPeriod_data2 = entity.previousPeriod_data1;
            }
            entity[item.alias] = item.RESULT?.[item.alias];
          } else {
            entity[item.alias] = item.RESULT?.[item.alias];
          }
        });
        entity[items.entityType] = items.entityValue;
        return entity;
      });

      // // Normalize the width
      // const currentPeriod_data2Min = minBy(tableRowData, 'currentPeriod_data2').currentPeriod_data2;
      // const currentPeriod_data2Max = maxBy(tableRowData, 'currentPeriod_data2').currentPeriod_data2;
      // const previousPeriod_data2Min = minBy(tableRowData, 'previousPeriod_data2').previousPeriod_data2;
      // const previousPeriod_data2Max = maxBy(tableRowData, 'previousPeriod_data2').previousPeriod_data2;
      // tableRowData = tableRowData.map(item => {
      //   item.normalizedWidthCurrent = (item.currentPeriod_data2 - currentPeriod_data2Min) / (currentPeriod_data2Max - currentPeriod_data2Min);
      //   item.normalizedWidthPrevious = (item.previousPeriod_data2 - previousPeriod_data2Min) / (previousPeriod_data2Max - previousPeriod_data2Min);
      //   return item;
      // });
      context.commit('SET_TOPMOVERS_DATA', {
        storeKey: data.storeKey,
        data: {
          rows: tableRowData,
          totalRows: tableRowData[0]?.totalEntityCount
        }
      });
      return response;
    });
  },
  fetchRecommendationData: (context, data) => {
    context.commit('SET_RECOMMENDATION_DATA_FETCHED', {
      storeKey: 'recommendationDataFetched',
      data: false
    });
    const request = data.entity.additionalData.recommendationsCount.api.request;
    const whereClause = context.getters.getWhereClause.dimensionNameValueList;
    request.filters = [];
    request.filters[0] = whereClause.reduce((acc, item) => {
      if (!acc[item.dimensionName]) {
        acc[item.dimensionName] = [];
        acc[item.dimensionName].push(item.dimensionValue);
      } else {
        acc[item.dimensionName].push(item.dimensionValue);
      }
      return acc;
    }, {});
    return HttpService.post('RECOMMENDATION_SERVICE', request, {
      append: '/recommendation',
      headers: { account: 'deprecated' }
    }).then((response) => {
      let recommendationsCount = {};
      recommendationsCount =
        response.data.recommendationMetaData?.recommendationDetails?.reduce(
          (acc, item) => {
            acc[item.asin] = item.recommendationNameType.length;
            return acc;
          },
          {}
        );
      context.commit('SET_RECOMMENDATION_DATA', {
        storeKey: 'recommendation',
        data: recommendationsCount
      });
      context.commit('SET_RECOMMENDATION_DATA_FETCHED', {
        storeKey: 'recommendationDataFetched',
        data: true
      });
      return response;
    });
  },
  setRecommendationData: (context, data) => {
    const currentTopMoversData =
      data.storeKey === 'topLosersData'
        ? context.getters.getTopLosersData
        : context.getters.getTopGainersData;

    currentTopMoversData.rows = currentTopMoversData.rows.map((item) => {
      item.count = context.state?.recommendation_count?.[item.entityValue];
      return item;
    });

    context.commit('SET_TOPMOVERS_DATA', {
      storeKey: data.storeKey,
      data: currentTopMoversData
    });
  },
  paginationEvent: (context, data) => {
    const limit = data.body.APIConfig.limit;
    if (limit !== context.getters.getTopMoversRowPerPage) {
      context.commit('RowPageEvent', { storeKey: 'rowPerPage', data: limit });
    } else {
      context.commit('PaginationEvent', {
        storeKey: `${data.meta.widgetId}`,
        data: data.body.APIConfig.page
      });
    }
  },
  destroyTopMovers: (context, data) => {
    context.commit('TOPMOVERS_STORE_INIT', data);
  },
  diableAddToFilter: (context) => {
    context.commit('SET_TOPMOVERS_SELECTEDENTITY', {
      storeKey: 'selectedEntity',
      data: {
        ...context.getters.getTopMoversSelectedEntity,
        additionalData: {
          ...context.getters.getTopMoversSelectedEntity?.additionalData,
          disableButton: true
        }
      }
    });
  },
  enableAddToFilter: (context) => {
    context.commit('SET_TOPMOVERS_SELECTEDENTITY', {
      storeKey: 'selectedEntity',
      data: {
        ...context.getters.getTopMoversSelectedEntity,
        additionalData: {
          ...context.getters.getTopMoversSelectedEntity?.additionalData,
          disableButton: false
        }
      }
    });
  },
  setTopMoversMetadataConfig: (context, data) => {
    context.commit('SET_TOPMOVERS_METADATACONFIG', {
      storeKey: data.storeKey,
      data: data.data
    });
    context.commit('SET_TOPMOVERS_METRICLIST', {
      storeKey: 'metricOrderList',
      data: data.data
    });
  },
  setTopMoversDataFromPageConfig: (context) => {
    const userPageMeta = context.getters.getUserPageMetadata;

    if (userPageMeta.page.businessInsights.topMovers) {
      context.commit('SET_TOPMOVERS_SELECTEDMEASURE', {
        storeKey: 'selectedMeasure',
        data: userPageMeta?.page?.businessInsights?.topMovers?.selectedMeasure
      });
      context.commit('SET_TOPMOVERS_SELECTEDENTITY', {
        storeKey: 'selectedEntity',
        data: userPageMeta?.page?.businessInsights?.topMovers?.selectedEntity
      });
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
