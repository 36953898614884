var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-display-flex u-flex-align-items-center"},_vm._l((_vm.checkboxOptions),function(ref,index){
var type = ref.type;
var label = ref.label;
var disabled = ref.disabled;
return _c('div',{key:index,staticClass:"u-border-radius-xxxl u-spacing-mr-m",class:{
      'u-border-color-blue-x-light': _vm.sourceCheckBox[index],
      'u-color-grey-x-lighter': !_vm.sourceCheckBox[index]
    }},[_c('label',{staticClass:"check-container"},[_c('rb-checkbox',{staticClass:"u-spacing-ph-s u-spacing-pv-xs u-border-color-blue-base",attrs:{"true-value":type,"false-value":false,"disabled":disabled},on:{"input":function($event){return _vm.selectedCheckbox(index, type)}},model:{value:(_vm.sourceCheckBox[index]),callback:function ($$v) {_vm.$set(_vm.sourceCheckBox, index, $$v)},expression:"sourceCheckBox[index]"}},[_c('div',{staticClass:"u-spacing-mr-s u-font-size-5"},[_vm._v(_vm._s(label))])])],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }