<template>
  <div class="u-position-relative u-spacing-ph-m u-spacing-pt-m">
    <div
      class="u-display-flex u-flex-align-items-flex-end u-flex-justify-content-space-between"
    >
      <div
        class="u-font-size-4 u-font-weight-600 u-spacing-pb-s u-color-grey-lighter"
      >
        REPORTS SENT
      </div>
      <div class="u-display-flex u-flex-align-items-center u-spacing-pb-s">
        <rb-icon
          :icon="'calendar'"
          class="rb-icon--mv-custom u-spacing-mr-xs u-color-grey-lighter"
        />
        <div class="u-font-size-6 u-color-grey-lighter">Last 30 Days</div>
      </div>
    </div>
    <div v-if="!mailHistoryLoad && mailHistoryLength">
      <div
        v-for="(item, index) in mailHistory"
        :key="index"
      >
        <historyCard
          :from="item.fromUser"
          :to="item.to"
          :time="item.createdAt"
          :subject="item.subject"
        />
      </div>
      <div
        class="fixed-container u-font-size-6 u-bg-color-grey-white u-spacing-p-m"
      >
        <div class="u-display-flex u-flex-justify-content-flex-end">
          {{ bottomText }}
        </div>
      </div>
    </div>
    <div
      v-else-if="!mailHistoryLoad && !mailHistoryLength"
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-height-100 u-font-size-4"
    >
      No reports sent yet!
    </div>
    <div v-else>
      <div class="u-display-flex u-flex-justify-content-center">
        <loader
          class="u-bg-color-grey-white u-spacing-mv-s"
          :loading="mailHistoryLoad"
          :color="'#007cf6'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
import historyCard from './components/history-card';
export default {
  components: {
    historyCard,
    loader
  },
  computed: {
    mailHistory() {
      return this.$store.getters.mailHistory.rows;
    },
    mailHistoryLength() {
      return this.mailHistory.length ? this.mailHistory.length : '';
    },
    mailHistoryLoad() {
      return this.$store.getters.mailHistory.load;
    },
    bottomText() {
      return this.mailHistoryLength
        ? `${this.mailHistoryLength} reports sent`
        : 'No reports sent yet';
    }
  },
  watch: {},
  created() {
    this.$store.dispatch('fetchMailHistory');
  },
  destroyed() {},
  methods: {}
};
</script>

<style lang="css" scoped>
.fixed-container {
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 0 0 4px 0 rgba(43, 51, 59, 0.15);
}

.rb-icon--mv-custom {
  height: 14px;
  width: 14px;
}
.rb-icon--mv-custom:before {
  font-size: 14px;
}
</style>
