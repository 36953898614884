var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippyOptions),expression:"tippyOptions"}],staticClass:"tag_token tag_token_container u-spacing-mv-xs u-spacing-pv-xs u-spacing-mr-s u-cursor-default u-display-flex u-flex-align-items-center u-flex-justify-content-space-between",class:[
    'u-font-size-' + _vm.tagFontSize,
    'u-color-' + _vm.tagFontColor,
    _vm.isTagClickable && 'u-cursor-pointer',
    _vm.extraWrpClass
  ],style:(_vm.enableStyleApi && { 'background-color': _vm.tagColor }),attrs:{"title":_vm.tippyTitle},on:{"click":function($event){$event.stopPropagation();return _vm.handleTagClick($event)},"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave}},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-flex-justify-content-center u-white-space-no-wrap tag-text"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._l((_vm.icons),function(iconData,index){return _c('div',{key:index,staticClass:"u-display-flex u-flex-align-items-center u-flex-justify-content-center u-cursor-pointer u-spacing-ml-s tag-icon",class:[
      'rb-icon--' + _vm.tagIconSize,
      'icon-' + iconData.icon,
      'u-color-' + iconData.color
    ],on:{"click":function($event){$event.stopPropagation();return _vm.onIconClick(iconData)}}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }