import MonthRange from '@/components/ams/media-planner/generate-plan/steps/month-range/index.vue';
import Goal from '@/components/ams/media-planner/generate-plan/steps/goal.vue';
import BusinessUnits from '@/components/ams/media-planner/generate-plan/steps/business-units/index.vue';
import Goals from '@/components/ams/media-planner/generate-plan/steps/goals/index.vue';
import GeneratedOutput from '@/components/ams/media-planner/generate-plan/output/index.vue';
import moment from 'moment';
import { formatter } from '@/utils/helpers/formatter.js';
import { cloneDeep, sortBy, set, get } from 'lodash';
import {
  baseConfigForBULevelData,
  ams_campaigns_asin_workbench_backfilled
} from '@/components/ams/media-planner/constants.js';

export const MODULE_NAME = 'mediaPlanner';
export const MEDIA_PLANNER_CREATION_DATA_GETTER = `${MODULE_NAME}/mediaPlannerConfig`;
export const MEDIA_PLANNER_CREATION_DATA_SETTER = `${MODULE_NAME}/updateMediaPlannerConfig`;
export const FETCH_FILTERED_HISTORICAL_DATA_ACTION = `${MODULE_NAME}/fetchFilteredHistoricalData`;
export const FILTERED_HISTORICAL_DATA_GETTER = `${MODULE_NAME}/filteredHistoricalData`;
export const FILTERED_HISTORICAL_DATA_SETTER = `${MODULE_NAME}/updateFilteredHistoricalData`;
export const OVERALL_HISTORICAL_DATA_GETTER = `${MODULE_NAME}/overallHistoricalData`;
export const OVERALL_HISTORICAL_DATA_SETTER = `${MODULE_NAME}/updateOverallHistoricalData`;
export const INTERNAL_CATEGORIZATIONS_DATA_GETTER = `${MODULE_NAME}/internalCategorizations`;
export const INTERNAL_CATEGORIZATIONS_DATA_SETTER = `${MODULE_NAME}/updateInternalCategorizations`;
export const RETAILER_CATEGORIZATIONS_DATA_GETTER = `${MODULE_NAME}/retailerCategorizations`;
export const RETAILER_CATEGORIZATIONS_DATA_SETTER = `${MODULE_NAME}/updateRetailerCategorizations`;
export const SAVE_OR_GENERATE_MEDIA_PLAN_ACTION = `${MODULE_NAME}/saveOrGenerateMediaPlan`;
export const UPDATE_MEDIA_PLAN_ACTION = `${MODULE_NAME}/updateMediaPlan`;
export const FETCH_LIST_OF_MEDIA_PLANS_ACTION = `${MODULE_NAME}/fetchListOfMediaPlans`;
export const DELETE_MEDIA_PLAN_ACTION = `${MODULE_NAME}/deleteMediaPlan`;
export const GENERATED_MEDIA_PLANS_GETTER = `${MODULE_NAME}/generatePlans`;
export const DRAFTED_MEDIA_PLANS_GETTER = `${MODULE_NAME}/draftedPlans`;
export const SAVING_OR_UPDATING_PLAN_STATUS_GETTER = `${MODULE_NAME}/savingOrUpdatingPlanStatus`;
export const SAVING_OR_UPDATING_PLAN_STATUS_SETTER = `${MODULE_NAME}/updateSavingOrUpdatingPlan`;
export const OUTPUT_GETTER = `${MODULE_NAME}/output`;
export const OUTPUT_SETTER = `${MODULE_NAME}/updateOutput`;
export const CREATE_OUTPUT_TABLE_ACTION = `${MODULE_NAME}/createOutputTable`;
export const FETCH_OUTPUT_ACTION = `${MODULE_NAME}/fetchOutput`;
export const FETCH_STATUS_ACTION = `${MODULE_NAME}/getMediaPlanStatus`;
export const RETRY_GENERATING_PLAN_ACTION = `${MODULE_NAME}/retryPlan`;
export const FETCH_MEDIA_PLAN_ACTION = `${MODULE_NAME}/getMediaPlan`;
export const FETCH_ADDITIONAL_INPUTS = `${MODULE_NAME}/fetchAdditionalInputs`;
export const EXPECTED_GROWTH_GETTER = `${MODULE_NAME}/expectedGrowth`;
export const EXPECTED_GROWTH_SETTER = `${MODULE_NAME}/updateExpectedGrowth`;
export const GET_OVERALL_ERRORS = `${MODULE_NAME}/errors`;

export const PROFILE_NAME = 'profile_name';
export const PORTFOLIO_NAME = 'portfolio_name';
export const ADDITIONAL_INPUT_DESCRIPTION =
  'Provide inputs for Expected Organic Sales growth and Expected ROAS growth here if you feel historical growth for these metrics may not represent future growth numbers. Provide percentages for Budget distribution across keyword types, if you want to fix the budget allocation at the keyword type level. The sum of percentages across all keyword types should be 100%.';

export const historicalDataKeyMap = {
  paid_sales_14d: { key: 'paid_sales_14d', text: 'Ad Sales' },
  roi: { key: 'roi', text: 'ROAS' },
  spend: { key: 'spend', text: 'Ad Spend' },
  total_sales: { key: 'total_sales', text: 'OPS' },
  shipped_cogs: { key: 'shipped_cogs', text: 'SCOGS' }
};

export const historicalDataKeys = Object.keys(historicalDataKeyMap);

export const makeTrueIfZero = (condition) => !!condition || condition === 0;

export const checkIfNegative = (currentValue, finalValue) => {
  if (!currentValue) {
    return finalValue;
  }

  return currentValue > 0 ? finalValue : -finalValue;
};

export const getCompetitivePlans = (
  goal,
  moderatelyAggressivePlanPercent = 25,
  highlyAggressivePlanPercent = 30
) => {
  const goalLabel = goalOptions?.[goal]?.label || '';
  return {
    base_plan: {
      id: 'base_plan',
      label: 'Plan A',
      defaultOPSGoal: 15,
      scenario: 'scenario_1'
    },
    moderately_aggressive_plan: {
      id: 'moderately_aggressive_plan',
      label: 'Plan B',
      description: `Create Plan B: ${goalLabel} Goal by ${checkIfNegative(
        moderatelyAggressivePlanPercent,
        Math.abs(moderatelyAggressivePlanPercent)
      )}%`,
      defaultOPSGoal: 25,
      scenario: 'scenario_2'
    },
    highly_aggressive_plan: {
      id: 'highly_aggressive_plan',
      label: 'Plan C',
      description: `Create Plan C: ${goalLabel.replace(
        'Increase',
        'Change'
      )} Goal by ${checkIfNegative(
        highlyAggressivePlanPercent,
        Math.abs(highlyAggressivePlanPercent)
      )}%`,
      defaultOPSGoal: 35,
      scenario: 'scenario_3'
    }
  };
};

export const defaultCompetitivePlans = getCompetitivePlans();

export const defaultPlan = {
  description: [
    'For the year',
    '2023',
    ', I want to',
    'Change OPS',
    'by',
    '15%',
    'for Plan A,',
    '15%',
    'for Plan B, and',
    '15%',
    'for Plan C',
    'for my',
    'Overall Business',
    ', considering',
    'ROAS Focused Strategy',
    'for Budget Allocation.'
  ],
  valueIndexes: {
    1: { index: 1, stepIndex: 1, text: '2023' },
    3: { index: 3, stepIndex: 2, text: 'Change OPS' },
    5: {
      index: 5,
      stepIndex: 4,
      text: `${defaultCompetitivePlans.base_plan.defaultOPSGoal}%`
    },
    7: {
      index: 7,
      stepIndex: 4,
      text: `${defaultCompetitivePlans.moderately_aggressive_plan.defaultOPSGoal}%`
    },
    9: {
      index: 9,
      stepIndex: 4,
      text: `${defaultCompetitivePlans.highly_aggressive_plan.defaultOPSGoal}%`
    },
    12: { index: 12, stepIndex: 3, text: 'Overall Business' },
    14: { index: 14, stepIndex: 2, text: 'ROAS Focused Strategy' }
  }
};

const getStrategy = (priority) => {
  const maxPriority = Math.max(...Object.values(priority));
  let tooltip = '';
  const maxPriorityStrategies = Object.keys(priority).reduce((list, key) => {
    if (priority[key] > 0) {
      tooltip =
        tooltip +
        `${tooltip ? ' & ' : ''}${strategyPlanDescriptionMap[key]} ${
          priority[key]
        } chip(s)`;
    }

    if (priority[key] === maxPriority) {
      list.push(strategyPlanDescriptionMap[key]);
    }
    return list;
  }, []);

  return { tooltip, text: maxPriorityStrategies.join(' & ') };
};

const numberOfFiltersText = (filters, filtersMap) => {
  return Object.keys(filters).reduce((acc, dimension) => {
    const firstFilter = filters?.[dimension]?.[0];
    const dimensionFilters = filters?.[dimension];
    const numberOfFilters = dimensionFilters?.length - 1;
    const dimensionFilterNames = dimensionFilters?.join(', ');
    const dimensionLabel = filtersMap?.[dimension] || dimension;
    return {
      text:
        (acc.text ? `${acc.text} & ` : '') +
        `${dimensionLabel}(s) : ${firstFilter}${
          numberOfFilters > 0 ? ` ... & ${numberOfFilters} more` : ''
        }`,
      tooltip:
        (acc.tooltip ? `${acc.tooltip}</br></br>` : '') +
        `${dimensionLabel}(s) : ${dimensionFilterNames}`
    };
  }, {});
};

const getPlanText = (condition) =>
  condition ? 'for Plan A, and' : 'for Plan A';

export const createPlanDescription = (planConfig) => {
  const {
    months: { endMonth, startMonth },
    goal,
    organizeType,
    strategy,
    priority,
    filteredBusinessUnit,
    filters,
    plans,
    filtersMap
  } = planConfig;
  const valueIndexes = { ...defaultPlan.valueIndexes };
  const description = [...defaultPlan.description];
  const basePlanOPS =
    plans?.[defaultCompetitivePlans.base_plan.id]?.overall?.opsGoal;
  const moderatelyAggressivePlanOPS =
    plans?.[defaultCompetitivePlans.moderately_aggressive_plan.id]?.overall
      ?.opsGoal;
  const highlyAggressivePlanOPS =
    plans?.[defaultCompetitivePlans.highly_aggressive_plan.id]?.overall
      ?.opsGoal;
  const moderatelyAggressivePlanEnabled =
    plans?.[defaultCompetitivePlans.moderately_aggressive_plan.id]?.enabled;
  const highlyAggressivePlanEnabled =
    plans?.[defaultCompetitivePlans.highly_aggressive_plan.id]?.enabled;
  if (startMonth && endMonth) {
    valueIndexes[1].text = `${moment(startMonth).format(
      'MMM, YYYY'
    )} to ${moment(endMonth).format('MMM, YYYY')}`;
    description[0] = 'For the time period';
  }
  if (goal) {
    valueIndexes[3].text = goalOptions[goal]?.label;
  }
  if (organizeType) {
    if (
      organizeType === organizeOptions.CUSTOMIZE.value &&
      filteredBusinessUnit?.dimensionName
    ) {
      description[11] = 'for';
      const { text, tooltip } = numberOfFiltersText(filters, filtersMap);
      valueIndexes[12].text = text;
      valueIndexes[12].tooltip = tooltip;
    } else {
      valueIndexes[12].text = organizeOptions[organizeType]?.label;
      description[11] = 'for my';
      valueIndexes[12].tooltip = '';
    }
  }
  valueIndexes[5].text = `${checkIfNegative(
    basePlanOPS,
    Math.abs(basePlanOPS)
  )}%`;
  if (moderatelyAggressivePlanEnabled) {
    valueIndexes[7].text = `${checkIfNegative(
      moderatelyAggressivePlanOPS,
      Math.abs(moderatelyAggressivePlanOPS)
    )}%`;
    description[8] = getIfElse(
      highlyAggressivePlanEnabled,
      description[8],
      description[8].replace(', and', '')
    );
  } else {
    description[6] = getPlanText(highlyAggressivePlanEnabled);
    description[8] = '';
    valueIndexes[7].text = '';
  }
  if (highlyAggressivePlanEnabled) {
    valueIndexes[9].text = `${checkIfNegative(
      highlyAggressivePlanOPS,
      Math.abs(highlyAggressivePlanOPS)
    )}%`;
  } else {
    description[6] = getPlanText(moderatelyAggressivePlanEnabled);
    description[10] = '';
    valueIndexes[9].text = '';
  }
  if (strategy) {
    const { text, tooltip } = getStrategy(priority);
    valueIndexes[14].text = `${text} Focused Strategy`;
    valueIndexes[14].tooltip = tooltip;
  }
  return {
    description,
    valueIndexes
  };
};

export const organizeOptions = {
  OVERALL: {
    isSelected: true,
    label: 'Overall Business',
    value: 'OVERALL',
    index: 0
  },
  CUSTOMIZE: {
    isSelected: false,
    label: 'Customize',
    value: 'CUSTOMIZE',
    index: 1
  }
};

const MONTH_RANGE = 11;
const getMonthOptions = () => {
  const startMonthOptions = [
    {
      title: moment().format('MMM YYYY'),
      value: moment().startOf('month').format('MMM DD, YYYY')
    }
  ];
  const endMonthOptions = [
    {
      title: moment().format('MMM YYYY'),
      value: moment().endOf('month').format('MMM DD, YYYY')
    }
  ];

  new Array(MONTH_RANGE).fill(0).forEach((__, index) => {
    const nextMonth = moment(
      index === 0 ? startMonthOptions[0].value : startMonthOptions[index].value
    ).add(1, 'months');

    startMonthOptions.push({
      title: moment(nextMonth).format('MMM YYYY'),
      value: moment(nextMonth).startOf('month').format('MMM DD, YYYY')
    });

    endMonthOptions.push({
      title: moment(nextMonth).format('MMM YYYY'),
      value: moment(nextMonth).endOf('month').format('MMM DD, YYYY')
    });
  });

  return {
    startMonthOptions,
    endMonthOptions,
    startMonth: startMonthOptions[0],
    endMonth: endMonthOptions[11]
  };
};

export const goalOptions = {
  OPS: {
    isSelected: true,
    label: 'Change OPS',
    secondaryLabel: 'OPS',
    value: 'OPS',
    index: 0,
    dataKey: 'total_sales'
  },
  SCOGS: {
    isSelected: false,
    label: 'Change Shipped COGS',
    secondaryLabel: 'Shipped COGS',
    value: 'SCOGS',
    index: 1,
    dataKey: 'shipped_cogs'
  },
  ADSALES: {
    isSelected: false,
    label: 'Change Ad Sales',
    secondaryLabel: 'Ad Sales',
    value: 'ADSALES',
    index: 2,
    dataKey: 'spend'
  }
};

export const strategyMap = {
  roas_focused: {
    title: 'ROAS Focused',
    value: 'roas_focused',
    tooltip: 'Assigning majority chips to ROAS growth',
    planDescriptionValue: 'ROAS',
    chipsAllocations: {
      adSales: 1,
      roas: 3,
      yoyGrowth: 1,
      ntb: 0
    }
  },
  growth_focused: {
    title: 'Growth Focused',
    value: 'growth_focused',
    tooltip: 'Assigning majority chips to YoY growth and NTB% growth',
    planDescriptionValue: 'Growth',
    chipsAllocations: {
      adSales: 1,
      roas: 0,
      yoyGrowth: 2,
      ntb: 2
    }
  },
  based_on_last_year: {
    title: 'Based on Last Year',
    value: 'based_on_last_year',
    tooltip: 'Assigning all chips to Ad Sales ',
    planDescriptionValue: 'Ad Sales',
    chipsAllocations: {
      adSales: 5,
      roas: 0,
      yoyGrowth: 0,
      ntb: 0
    }
  },
  custom: {
    title: 'Custom',
    value: 'custom',
    tooltip: 'You can move chips and assign them as you like',
    chipsAllocations: {
      adSales: 0,
      roas: 0,
      yoyGrowth: 0,
      ntb: 0
    }
  }
};

const DEFAULT_BUSINESS_UNIT = {
  dimensionLabel: 'Profile',
  dimensionName: 'profile_name',
  dimensionColumn: 'PROFILE_NAME',
  dimensionType: 'AMAZON_CATEGORY',
  entityType: null,
  tagType: null,
  esDataSetName: 'keyword',
  enableClickOnly: false
};

export const defaultMediaPlannerConfig = {
  name: '',
  months: {
    startMonth: getMonthOptions().startMonth.value,
    endMonth: getMonthOptions().endMonth.value
  },
  monthOptions: {
    startMonthOptions: getMonthOptions().startMonthOptions,
    endMonthOptions: getMonthOptions().endMonthOptions
  },
  goal: goalOptions.OPS.value,
  strategy: strategyMap.roas_focused.value,
  minRoas: 5,
  priority: strategyMap.roas_focused.chipsAllocations,
  roasChecked: false,
  organizeType: organizeOptions.OVERALL.value,
  businessUnit: DEFAULT_BUSINESS_UNIT,
  filteredBusinessUnit: {},
  filters: {},
  aggressivePlansModified: false,
  mediaPlanId: null,
  plans: {
    [defaultCompetitivePlans.base_plan.id]: {
      overall: {
        opsGoal: defaultCompetitivePlans.base_plan.defaultOPSGoal,
        additionalInputs: {}
      },
      businessUnitLevel: [],
      scenario: defaultCompetitivePlans.base_plan.scenario
    },
    [defaultCompetitivePlans.moderately_aggressive_plan.id]: {
      overall: {
        opsGoal:
          defaultCompetitivePlans.moderately_aggressive_plan.defaultOPSGoal,
        additionalInputs: {}
      },
      businessUnitLevel: [],
      isModified: {
        inputs: false,
        ops: false
      },
      enabled: true,
      scenario: defaultCompetitivePlans.moderately_aggressive_plan.scenario
    },
    [defaultCompetitivePlans.highly_aggressive_plan.id]: {
      overall: {
        opsGoal: defaultCompetitivePlans.highly_aggressive_plan.defaultOPSGoal,
        additionalInputs: {}
      },
      businessUnitLevel: [],
      isModified: {
        inputs: false,
        ops: false
      },
      enabled: true,
      scenario: defaultCompetitivePlans.highly_aggressive_plan.scenario
    }
  },
  showPlansAtBULevel: false,
  showAdditionalInputsAtBULevel: false,
  additionalInputsTableError: {},
  filtersMap: {}
};

export const strategyPlanDescriptionMap = {
  adSales: 'Ad Sales',
  roas: 'ROAS',
  yoyGrowth: 'Growth',
  ntb: 'NTB'
};

export const categoriesMap = {
  adSales: ['Customer Traffic', 'Ad Sales'],
  roas: ['Performance', 'ROAS'],
  yoyGrowth: ['Opportunity', 'YOY Growth'],
  ntb: ['Reach', 'NTB%']
};

export const ADV_METRICS_BACKFILLED = [
  'paid_sales_14d_backfilled',
  'spend_backfilled',
  'roi_backfilled'
];

export const ADV_METRICS = ['paid_sales_14d', 'spend', 'roi'];

export const OPS_AND_SCOGS_METRICS = [
  'sales_dashboard__ordered_product_sales',
  'dw__shipped_cogs'
];

export const getAPIConfigForBULevel = (
  toDate,
  fromDate,
  entityType,
  dimensionNameValueList,
  isBackfilled = false
) => {
  const config = cloneDeep(baseConfigForBULevelData);

  if (isBackfilled) {
    delete config.bundleDataGroupsRequest.ams_campaigns_asin_workbench;
    config.bundleDataGroupsRequest.ams_campaigns_asin_workbench_backfilled =
      ams_campaigns_asin_workbench_backfilled;
    config.bundleDataGroupsRequest.ams_campaigns_asin_workbench_backfilled.metricsList =
      ADV_METRICS_BACKFILLED;
  }

  const amsWorkbench = isBackfilled
    ? 'ams_campaigns_asin_workbench_backfilled'
    : 'ams_campaigns_asin_workbench';

  config.where.date.to = toDate;
  config.where.date.from = fromDate;
  config.entityType = entityType;
  ['dw', amsWorkbench, 'sales_dashboard'].forEach((key) => {
    config.bundleDataGroupsRequest[key].entityType = entityType;
    config.bundleDataGroupsRequest[key].where.dimensionNameValueList =
      dimensionNameValueList;
    config.bundleDataGroupsRequest[key].where.date.from = fromDate;
    config.bundleDataGroupsRequest[key].where.date.to = toDate;
    if (key !== amsWorkbench) {
      config.bundleDataGroupsRequest[key].operations.showByEntities = [
        entityType
      ];
    }
  });
  config.bundleDataGroupsRequest.dw.metricsList = [
    OPS_AND_SCOGS_METRICS[1],
    entityType
  ];
  config.bundleDataGroupsRequest[amsWorkbench].metricsList = [
    ...(isBackfilled ? ADV_METRICS_BACKFILLED : ADV_METRICS),
    entityType
  ];
  config.bundleDataGroupsRequest.sales_dashboard.metricsList = [
    OPS_AND_SCOGS_METRICS[0],
    entityType
  ];
  return config;
};

export const filteredHistoricalDataAPIConfig = {
  page: 'BusinessInsights',
  widget: 'chartingWb',
  entityType: '#ALLOVER_AGGREGATE',
  metricsList: [...ADV_METRICS, ...OPS_AND_SCOGS_METRICS],
  eventsList: [],
  operations: {
    timeseriesEnabled: false,
    timeseriesDimension: 'feed_date',
    daterangeDimension: 'feed_date',
    commonFilterEnabled: true,
    pvpenabled: false,
    samePeriodLastYearTimeseriesEnabled: false,
    enableDedupBeforeRollup: true,
    timeseriesRollupBy: 'DAY',
    timeseriesWeekStartDay: 'SUNDAY',
    additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
      'asin',
      'feed_date'
    ]
  },
  where: {
    dimensionNameValueList: [],
    date: {
      from: '2022-02-06',
      to: '2023-02-12',
      name: 'custom',
      page_wise_min_max_key: 'BusinessInsights'
    },
    pvpDate: {
      from: '2021-01-30',
      to: '2022-02-05',
      compare_name: null
    }
  }
};

export const chartsCube = (fromDate, toDate) => ({
  cubeName: 'ams_campaigns_asin_workbench_backfilled',
  getLatestAvailableInsteadOfRollup: false,
  timeseriesEnabled: true,
  timeseriesDimension: 'report_date',
  pvpenabled: true,
  yoyenabled: false,
  customAPIDecisionVars: {
    system: 'amazon_campaign_management'
  },
  measuresList: ['average_order_value', 'PercentageSalesNewToBrand14d'],
  groupByDimensionsList: ['asin', 'campaign_name'],
  orderByList: [
    {
      dimension: 'average_order_value',
      direction: 'DESC'
    }
  ],
  dedupBeforeRollup: {
    enableDedupBeforeRollup: true,
    additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: ['asin']
  },
  where: {
    dimensionNameValueList: [],
    date: {
      from: fromDate,
      to: toDate,
      name: 'custom',
      page_wise_min_max_key: 'ams_asin'
    },
    pvpDate: {
      from: moment(fromDate).subtract(1, 'year').format('YYYY-MM-DD'),
      to: moment(toDate).subtract(1, 'year').format('YYYY-MM-DD'),
      compare_name: null
    }
  },
  tagWhereClause: []
});

export const filteredHistoricalTableColDef = [
  {
    name: 'first_col',
    type: 'STRING',
    uiField: {
      uiLabel: 'Profile',
      uiType: 'string',
      uiOrder: 1,
      metadata: {
        toolTipText: '',
        tableColumnName: 'first_col',
        showOnUi: true,
        isFixed: false,
        width: 174
      }
    }
  },
  {
    name: 'total_sales',
    type: 'currency',
    uiField: {
      uiLabel: 'OPS',
      uiType: 'currency',
      uiOrder: 2,
      customStyles: 'justify-content: center;',
      metadata: {
        toolTipText: '',
        tableColumnName: 'total_sales',
        showOnUi: true,
        isFixed: false,
        width: 121,
        cellClass: 'u-text-align-center'
      }
    }
  },
  {
    name: 'paid_sales_14d',
    type: 'currency',
    uiField: {
      uiLabel: 'Ad Sales',
      uiType: 'currency',
      uiOrder: 3,
      customStyles: 'justify-content: center;',
      metadata: {
        toolTipText: '',
        tableColumnName: 'paid_sales_14d',
        showOnUi: true,
        isFixed: false,
        width: 145,
        cellClass: 'u-text-align-center'
      }
    }
  },
  {
    name: 'roi',
    type: 'currency',
    uiField: {
      uiLabel: 'ROAS',
      uiType: 'currency',
      uiOrder: 4,
      customStyles: 'justify-content: center;',
      metadata: {
        toolTipText: '',
        tableColumnName: 'roi',
        showOnUi: true,
        isFixed: false,
        width: 145,
        cellClass: 'u-text-align-center'
      }
    }
  },
  {
    name: 'spend',
    type: 'currency',
    uiField: {
      uiLabel: 'Ad Spend',
      uiType: 'currency',
      uiOrder: 5,
      customStyles: 'justify-content: center;',
      metadata: {
        toolTipText: '',
        tableColumnName: 'spend',
        showOnUi: true,
        isFixed: false,
        width: 138,
        cellClass: 'u-text-align-center'
      }
    }
  },
  {
    name: 'shipped_cogs',
    type: 'currency',
    uiField: {
      uiLabel: 'SCOGS',
      uiType: 'currency',
      uiOrder: 6,
      customStyles: 'justify-content: center;',
      metadata: {
        toolTipText: '',
        tableColumnName: 'shipped_cogs',
        showOnUi: true,
        isFixed: false,
        width: 138,
        cellClass: 'u-text-align-center'
      }
    }
  }
];

export const getColDef = (
  key,
  name,
  order,
  pinned,
  type,
  headerProps,
  cellRendererParams
) => ({
  field: key,
  headerName: name,
  title: name,
  order: order,
  pinned: pinned,
  suppressSizeToFit: true,
  minWidth: 100,
  cellRendererParams:
    type === 'custom'
      ? cellRendererParams
      : {
          keyType: type || 'string',
          formatterFn: (...args) => {
            if (args[0] === 'NA' && key !== 'distributionLevel') return '';
            return formatter(...args);
          }
        },
  cellRendererFramework:
    type === 'custom' ? 'DynamicCellComponent' : 'genericTableCell',
  ...(headerProps || {})
});

const getForecastedMetricHeaderProps = (text) => ({
  headerComponentParams: {
    component: 'iconWithText',
    columnDefsToProps: () => ({
      tooltip: 'Forecasted value',
      icon: 'info-circle-fill',
      textClasses: 'u-font-size-7 u-font-weight-600',
      wrapperClass: 'output-table-headers',
      iconSize: 'medium',
      tippyOptions: { placement: 'top-start', arrow: false, distance: -10 },
      text
    })
  },
  headerComponentFramework: 'DynamicHeaderComponent'
});

export const getOutputColumns = (hasBUDimensions, hideEdit, goal) => {
  const opsColumnHeader = `% ${
    goalOptions[goal].value === goalOptions.SCOGS.value
      ? goalOptions[goal].secondaryLabel
      : goalOptions.OPS.secondaryLabel
  } goal`;
  return [
    {
      label: 'Budget Required',
      key: 'BUDGET_REQUIRED',
      type: 'CURRENCY',
      headerProps: {
        ...getForecastedMetricHeaderProps('Budget Required')
      }
    },
    {
      label: '+/-',
      key: 'PERCENTAGE_CHANGE',
      type: 'PERCENTAGE',
      headerProps: {
        ...getForecastedMetricHeaderProps('+/-')
      }
    },
    {
      label: opsColumnHeader,
      key: 'PERCENTAGE_OPS_GOAL',
      type:
        goalOptions[goal].value !== goalOptions.ADSALES.value
          ? 'custom'
          : 'PERCENTAGE',
      ...(goalOptions[goal].value !== goalOptions.ADSALES.value
        ? {
            cellRendererParams: {
              component: 'AdjustPercentage',
              paramsToProps: (params) => {
                const value = {
                  achieveOPSGoalsBy: 'percentage',
                  value:
                    params.value > 0
                      ? Math.abs(params.value.toFixed(2))
                      : -Math.abs(params.value.toFixed(2))
                };
                return {
                  value,
                  rowIndex: params.rowIndex,
                  tableId: params.data.planId,
                  hideEditIcon:
                    hideEdit ||
                    (hasBUDimensions
                      ? params.data['ag-grid-level'] > 1
                      : params.data['ag-grid-level'] > 0),
                  useForOutputScreen: true,
                  setValue: params.setValue,
                  colId:
                    hasBUDimensions && params.data.PORTFOLIO_NAME
                      ? params.data.PORTFOLIO_NAME
                      : params.data.distributionLevel,
                  valuesUpdated:
                    makeTrueIfZero(params.data.PERCENTAGE_OPS_GOAL_ORIGINAL) &&
                    params.data.PERCENTAGE_OPS_GOAL_ORIGINAL !== params.value
                };
              },
              eventMap: {
                updateRow: 'updateRow'
              }
            }
          }
        : {
            headerProps: {
              ...getForecastedMetricHeaderProps(opsColumnHeader)
            }
          })
    },
    {
      label: 'OPS',
      key: 'OPS',
      type: 'CURRENCY',
      headerProps: {
        ...getForecastedMetricHeaderProps('OPS')
      }
    },
    {
      label: 'SCOGS',
      key: 'SHIPPED_COGS',
      type: 'CURRENCY',
      headerProps: {
        ...getForecastedMetricHeaderProps('SCOGS')
      }
    },
    {
      label: 'YoY Organic Sales Growth',
      key: 'PERCENTAGE_YOY_ORGANIC_SALES_GROWTH',
      type: 'PERCENTAGE',
      headerProps: {
        ...getForecastedMetricHeaderProps('YoY Organic Sales Growth')
      }
    },
    {
      label: 'Organic Sales',
      key: 'ORGANIC_SALES',
      type: 'CURRENCY',
      headerProps: {
        ...getForecastedMetricHeaderProps('Organic Sales')
      }
    },
    {
      label: 'Ad Sales',
      key: 'AD_SALES',
      type: 'CURRENCY',
      headerProps: {
        ...getForecastedMetricHeaderProps('Ad Sales')
      }
    },
    {
      label: 'YOY ROAS Growth',
      key: 'PERCENTAGE_YOY_ROAS_GROWTH',
      type: 'PERCENTAGE',
      headerProps: {
        ...getForecastedMetricHeaderProps('YOY ROAS Growth')
      }
    },
    {
      label: 'ROAS',
      key: 'ROAS',
      type: 'CURRENCY',
      headerProps: {
        ...getForecastedMetricHeaderProps('ROAS')
      }
    }
  ];
};

export const planMetricOptions = [
  { key: { tag1: 'OPS' }, title: 'Forecasted Ops' },
  { key: { tag1: 'BUDGET_REQUIRED' }, title: 'Required Budget' },
  { key: { tag1: 'PERCENTAGE_CHANGE' }, title: 'Change +/-' },
  { key: { tag1: 'SHIPPED_COGS' }, title: 'SCOGS' },
  { key: { tag1: 'ORGANIC_SALES' }, title: 'Organic Sales' },
  { key: { tag1: 'AD_SALES' }, title: 'Ad Sales' },
  { key: { tag1: 'ROAS' }, title: 'ROAS' }
];

export const getPreviousPeriodDates = (
  months,
  format = 'YYYY-MM-DD',
  modulusTriggerTime = new Date()
) => {
  const previousPeriodDates = {};
  const diffInMonths = moment(months.endMonth).diff(
    months.startMonth,
    'months'
  );
  const dateToConsider = moment.utc(modulusTriggerTime).local();
  if (diffInMonths < 11) {
    const lastYear = moment(months.endMonth).subtract(1, 'year').year();
    if (
      moment(months.endMonth).month() === moment(dateToConsider).month() &&
      lastYear === moment(dateToConsider).year() &&
      moment(months.endMonth).month() !== moment(months.startMonth).month()
    ) {
      previousPeriodDates.end = moment(months.endMonth)
        .subtract(1, 'year')
        .subtract(1, 'month')
        .endOf('month')
        .format(format);
    } else {
      previousPeriodDates.end = moment(months.endMonth)
        .subtract(1, 'year')
        .endOf('month')
        .format(format);
    }

    previousPeriodDates.start = moment(months.startMonth)
      .subtract(1, 'year')
      .startOf('month')
      .format(format);
  } else {
    previousPeriodDates.end = moment(dateToConsider)
      .subtract(1, 'month')
      .endOf('month')
      .format(format);
    previousPeriodDates.start = moment(dateToConsider)
      .subtract(1, 'year')
      .startOf('month')
      .format(format);
  }

  return previousPeriodDates;
};

export const YOY_GROWTH_PERCENTAGE = 'YoY growth % (Backfilled)';
export const NTB_SALES_PERCENTAGE = 'NTB% Sales (Backfilled)';
export const getOutputMetrics = (
  months,
  moderatelyAggressivePlanEnabled,
  highlyAggressivePlanEnabled,
  modulusTriggerTime
) => {
  const { start, end } = getPreviousPeriodDates(
    months,
    'MMM YYYY',
    modulusTriggerTime
  );
  return {
    metric1: {
      title: `Summary of ${start} - ${end}`,
      metricsShown: [
        'OPS Previous Period',
        'Ad Spend (Backfilled)',
        'ROAS (Backfilled)'
      ],
      metricsConfig: {
        'OPS Previous Period': { tag1Unit: { pre: 'currency' } },
        'Ad Spend (Backfilled)': { tag1Unit: { pre: 'currency' } },
        'ROAS (Backfilled)': { tag1Unit: { pre: 'currency' } },
        'Organic Sales (Backfilled)': { tag1Unit: { pre: 'currency' } },
        'Ad Sales (Backfilled)': { tag1Unit: { pre: 'currency' } },
        [NTB_SALES_PERCENTAGE]: { tag1Unit: { suff: '%' } },
        [YOY_GROWTH_PERCENTAGE]: { tag1Unit: { suff: '%' } }
      },
      metricData: {
        'OPS Previous Period': { tag1: 0 },
        'Ad Spend (Backfilled)': { tag1: 0 },
        'ROAS (Backfilled)': { tag1: 0 },
        'Organic Sales (Backfilled)': { tag1: 0 },
        'Ad Sales (Backfilled)': { tag1: 0 },
        [NTB_SALES_PERCENTAGE]: { tag1: 0 },
        [YOY_GROWTH_PERCENTAGE]: { tag1: 0 }
      },
      metricsList: [
        { title: 'OPS Previous Period' },
        { title: 'Organic Sales (Backfilled)' },
        { title: 'Ad Spend (Backfilled)' },
        { title: 'Ad Sales (Backfilled)' },
        { title: 'ROAS (Backfilled)' },
        { title: NTB_SALES_PERCENTAGE },
        { title: YOY_GROWTH_PERCENTAGE }
      ]
    },
    ...[
      defaultCompetitivePlans.base_plan.id,
      ...(moderatelyAggressivePlanEnabled
        ? [defaultCompetitivePlans.moderately_aggressive_plan.id]
        : []),
      ...(highlyAggressivePlanEnabled
        ? [defaultCompetitivePlans.highly_aggressive_plan.id]
        : [])
    ].reduce((objMap, id) => {
      objMap[id] = {
        title: defaultCompetitivePlans[id].label,
        metricsShown: ['Forecasted Ops', 'Required Budget'],
        metricsConfig: planMetricOptions.reduce((data, { title, key }) => {
          data[title] = {
            tag1Unit: key.tag1.includes('PERCENTAGE')
              ? { suff: '%' }
              : { pre: 'currency' }
          };
          return data;
        }, {}),
        metricData: planMetricOptions.reduce((data, { title }) => {
          data[title] = { tag1: 0 };
          return data;
        }, {}),
        metricsList: planMetricOptions.map(({ title }) => ({ title }))
      };

      return objMap;
    }, {})
  };
};

export const getColumnDef = (isIC, hasBUDimensions, hideEdit, goal) => [
  getColDef('plan', 'Plan', 1, 'left', null),
  getColDef(
    'distributionLevel',
    `Overall > Business Unit >  Campaign Type ${
      isIC ? '' : '> Keyword Type '
    }> Month`,
    2,
    'left',
    null,
    {
      headerComponentParams: {
        customStyles: {
          'margin-left': '-117px',
          'border-left': '1px solid #e9eaeb',
          'padding-left': '20px'
        }
      },
      headerComponentFramework: 'genericTableHeader'
    }
  ),
  ...getOutputColumns(hasBUDimensions, hideEdit, goal).map(
    ({ label, key, type, cellRendererParams, headerProps }, index) =>
      getColDef(
        key,
        label,
        index + 3,
        false,
        type,
        headerProps,
        cellRendererParams
      )
  )
];

export const getAdditionalInputsColDef = (businessUnit) => [
  {
    name: 'plan',
    type: 'STRING',
    uiField: {
      uiLabel: 'Plan',
      uiType: 'string',
      uiOrder: 1,
      customStyles: 'border-right: 1px solid #e9eaeb; margin-left: 24px;',
      metadata: {
        toolTipText: '',
        tableColumnName: 'plan',
        showOnUi: true,
        isFixed: true,
        width: 180
      }
    }
  },
  {
    name: businessUnit.dimensionName,
    type: 'STRING',
    uiField: {
      uiLabel: businessUnit.dimensionLabel,
      uiType: 'string',
      uiOrder: 2,
      customStyles: 'margin-left: 24px;',
      metadata: {
        toolTipText: '',
        tableColumnName: businessUnit.dimensionName,
        showOnUi: true,
        isFixed: true,
        width: 180
      }
    }
  },
  {
    name: 'plan',
    type: 'custom',
    uiField: {
      uiLabel: 'Expected Growth',
      uiType: 'custom',
      uiOrder: 3,
      metadata: {
        widget: 'dynamiccellcomponent',
        dynamicCellComponentParams: {
          componentName: 'SplitCell',
          paramsToProps: (params) => {
            return {
              values: [
                {
                  value: params.data.expected_organic_growth,
                  id: 'expected_organic_growth'
                },
                {
                  value: params.data.expected_roas_growth,
                  id: 'expected_roas_growth'
                }
              ],
              showEditIcon: true,
              rowIndex: params.rowIndex
            };
          },
          eventMap: {
            updateRow: 'updateRow'
          }
        },
        headerComponent: {
          bDynamicHeaderComponent: true,
          componentName: 'SplitCellHeader',
          columnDefsToProps: () => {
            return {
              mainHeaderLabel: 'Expected Growth',
              splitHeaderLabels: [
                {
                  title: 'Expected Organic Growth',
                  tooltip:
                    'Expected % growth in organic sales for the period selected.'
                },
                {
                  title: 'Expected ROAS Growth',
                  tooltip: 'Expected % ROAS growth for the period selected'
                }
              ]
            };
          }
        },
        showOnUi: true,
        isFixed: false,
        width: 400
      }
    }
  },
  {
    name: 'plan',
    type: 'custom',
    uiField: {
      uiLabel: 'Budget Distribution',
      uiType: 'custom',
      uiOrder: 4,
      metadata: {
        widget: 'dynamiccellcomponent',
        dynamicCellComponentParams: {
          componentName: 'SplitCell',
          paramsToProps: (params) => {
            const showError =
              Object.keys(budgetDistributionMap).reduce(
                (list, col) => list + (params.data[col] || 0),
                0
              ) !== 100;
            return {
              values: [
                {
                  showError,
                  value: params.data.keyword_generic,
                  id: 'keyword_generic',
                  allowNegative: false
                },
                {
                  showError,
                  value: params.data.keyword_client_branded,
                  id: 'keyword_client_branded',
                  allowNegative: false
                },
                {
                  showError,
                  value: params.data.keyword_competitor_branded,
                  id: 'keyword_competitor_branded',
                  allowNegative: false
                },
                {
                  showError,
                  value: params.data.others,
                  id: 'others',
                  allowNegative: false
                }
              ],
              showEditIcon: true,
              rowIndex: params.rowIndex
            };
          },
          eventMap: {
            updateRow: 'updateRow'
          }
        },
        headerComponent: {
          bDynamicHeaderComponent: true,
          componentName: 'SplitCellHeader',
          columnDefsToProps: () => {
            return {
              mainHeaderLabel: 'Budget Distribution',
              splitHeaderLabels: [
                {
                  title: 'Keyword Generic',
                  tooltip: 'Provide % distribution for keyword type Generic'
                },
                {
                  title: 'Keyword Client Branded',
                  tooltip:
                    'Provide % distribution for keyword type Client Branded'
                },
                {
                  title: 'Keyword Competitor Branded',
                  tooltip:
                    'Provide % distribution for keyword type Competitor Branded '
                },
                {
                  title: 'Others',
                  tooltip: 'Provide % distribution for other targeting types'
                }
              ]
            };
          }
        },
        showOnUi: true,
        isFixed: false,
        width: 800
      }
    }
  }
];

export const getPlanColDef = (tableId) => [
  {
    name: 'first_col',
    type: 'STRING',
    uiField: {
      uiLabel: 'first_col',
      uiType: 'string',
      uiOrder: 1,
      metadata: {
        toolTipText: '',
        tableColumnName: 'first_col',
        showOnUi: true,
        isFixed: false,
        width: 115
      }
    }
  },
  {
    name: 'plan',
    type: 'custom',
    uiField: {
      uiLabel: 'Previous Period Data',
      uiType: 'custom',
      uiOrder: 2,
      metadata: {
        widget: 'dynamiccellcomponent',
        dynamicCellComponentParams: {
          componentName: 'SplitCell',
          paramsToProps: (params) => {
            const previousPeriodData = params?.data?.data || {};
            return {
              values: [
                {
                  title: formatter(
                    previousPeriodData.paid_sales_14d,
                    'currency',
                    'NA'
                  )
                },
                {
                  title: formatter(previousPeriodData.roi, 'currency', 'NA')
                },
                {
                  title: formatter(
                    previousPeriodData.total_sales,
                    'currency',
                    'NA'
                  )
                },
                {
                  title: formatter(previousPeriodData.spend, 'currency', 'NA')
                },
                {
                  title: formatter(
                    previousPeriodData.shipped_cogs,
                    'currency',
                    'NA'
                  )
                }
              ]
            };
          }
        },
        headerComponent: {
          bDynamicHeaderComponent: true,
          componentName: 'SplitCellHeader',
          columnDefsToProps: ({ context }) => {
            const { start, end } =
              context?.componentParent?.previousPeriodDates || {};
            return {
              mainHeaderLabel: `Previous Period Data (${start} - ${end})`,
              splitHeaderLabels: [
                {
                  title: 'Ad Sales'
                },
                {
                  title: 'ROAS'
                },
                {
                  title: 'OPS'
                },
                {
                  title: 'Ad Spend'
                },
                {
                  title: 'SCOGS'
                }
              ]
            };
          }
        },
        showOnUi: true,
        isFixed: false,
        width: 320
      }
    }
  },
  {
    name: 'ops',
    type: 'custom',
    uiField: {
      uiLabel: '% OPS Goal',
      uiType: 'custom',
      uiOrder: 3,
      customStyles: 'justify-content: center;',
      metadata: {
        widget: 'dynamiccellcomponent',
        dynamicCellComponentParams: {
          componentName: 'AdjustPercentage',
          paramsToProps: ({ context, data, value, rowIndex }) => {
            const dimension =
              context?.componentParent?.businessUnit?.dimensionName || {};
            return {
              value: value,
              rowIndex: rowIndex,
              tableId,
              isOverall: data[dimension] === 'Overall',
              showEditByDefault: true
            };
          },
          eventMap: {
            updateRow: 'updateRow'
          }
        },
        tableColumnName: 'ops',
        showOnUi: true,
        isFixed: false,
        width: 115
      }
    }
  }
];
export const getStepConfig = (storeData, updateKeyInStore, disabledSteps) => [
  {
    title: 'Create a Media Plan',
    description:
      'Create a media plan to estimate the budget requirement by each month, quarter, and year given a certain sales/OPS Goal.'
  },
  {
    title: 'What is the Time Period for Budget Plan ?',
    description:
      'Add Start date and end date for the budget plan you want to create.',
    nextStepTitle: 'What is the Goal for Budget Plan ?',
    nextStepDescription: 'Select a Goal.',
    component: MonthRange,
    componentProps: { storeData, updateKeyInStore }
  },
  {
    title: 'What is your Goal ?',
    description: 'Select a Goal.',
    nextStepTitle: 'For what business do you want to create the plan ?',
    nextStepDescription:
      'Add business units in the filter box and select business unit you want to organize the budget plan.',
    component: Goal,
    componentProps: { storeData, updateKeyInStore }
  },
  {
    title: 'For what business do you want to create the plan ?',
    description:
      'Add business units in the filter box and select business unit you want to organize the budget plan.',
    nextStepTitle: 'Enter Your Goals',
    nextStepDescription:
      'Select how you want to achieve your goal. Enter metric goals and also select the checkbox to create two extra competitive plans as compared to the plan A which helps in better comparisons of the budget plan to achieve different goals.',
    component: BusinessUnits,
    componentProps: {
      storeData,
      updateKeyInStore
    }
  },
  {
    title: 'Enter Your Goals',
    description:
      'Select how you want to achieve your goal. Enter metric goals and also select the checkbox to create two extra competitive plans as compared to the plan A which helps in better comparisons of the budget plan to achieve different goals.',
    component: Goals,
    componentProps: { storeData, updateKeyInStore }
  },
  {
    component: GeneratedOutput,
    componentProps: { storeData, updateKeyInStore, disabledSteps }
  }
];

export const achieveOPSGoalByOptions = {
  percentage: { type: 'percentage', label: 'Percentage' },
  absolute: { type: 'absolute', label: 'Absolute Value' }
};

export const convertToAbsoluteValue = (total, percentage) =>
  +Math.abs((total * percentage) / 100).toFixed(2);
export const convertToPercentageValue = (absolute, total) =>
  +Math.abs((absolute / total) * 100).toFixed(2);
export const convertToIntegerPercentageValue = (
  value,
  total,
  isAlreadyInPercentage
) =>
  isAlreadyInPercentage
    ? value
    : checkIfNegative(value, convertToPercentageValue(value, total));

export const convertToIntegerAbsoluteValue = (
  value,
  total,
  isAlreadyInAbsolute
) =>
  isAlreadyInAbsolute
    ? value
    : checkIfNegative(value, convertToAbsoluteValue(total, value));

const getAvgValue = (updatedPlans, planId) => {
  const sumOfAdditionalInputs = {};
  updatedPlans[planId].businessUnitLevel.forEach(({ additionalInputs }) => {
    Object.keys(checkUndefined(additionalInputs, {})).forEach((colId) => {
      if (
        additionalInputs &&
        (additionalInputs[colId] || additionalInputs[colId] === 0)
      ) {
        sumOfAdditionalInputs[colId] = {
          ...sumOfAdditionalInputs[colId],
          sum:
            checkNullish(sumOfAdditionalInputs[colId]?.sum, 0) +
            additionalInputs[colId],
          length: checkNullish(sumOfAdditionalInputs[colId]?.length, 0) + 1
        };
      }
    });
  });
  Object.keys(sumOfAdditionalInputs).forEach((key) => {
    sumOfAdditionalInputs[key] =
      (
        sumOfAdditionalInputs[key].sum / sumOfAdditionalInputs[key].length
      ).toFixed(2) * 1;
  });
  return sumOfAdditionalInputs;
};

export const createPayloadToSaveOrUpdateMediaPlan = (
  mediaPlannerConfig,
  isDrafted
) => {
  const {
    months,
    goal,
    organizeType,
    businessUnit,
    filteredBusinessUnit,
    strategy,
    priority,
    plans,
    minRoas,
    roasChecked,
    filters,
    name,
    showPlansAtBULevel,
    showAdditionalInputsAtBULevel,
    backfilledData,
    filtersMap
  } = mediaPlannerConfig;
  const isOverall = organizeType === organizeOptions.OVERALL.value;
  const reporting_level = isOverall ? businessUnit : filteredBusinessUnit;

  let invisibleFilters = [];
  const dimension = reporting_level?.dimensionName?.toLowerCase();

  if (!(isOverall && !showPlansAtBULevel)) {
    const filtersHasDimension = filters[dimension];
    if (!filtersHasDimension) {
      const bUs = plans[
        defaultCompetitivePlans.base_plan.id
      ].businessUnitLevel.map(({ name }) => name);
      invisibleFilters = {
        ...invisibleFilters,
        [dimension]: bUs
      };
    }
  }

  if (showAdditionalInputsAtBULevel) {
    const updatedPlans = { ...plans };
    Object.keys(plans).forEach((planId) => {
      const avgOfAdditionalInputs = getAvgValue(updatedPlans, planId);
      updatedPlans[planId].overall.additionalInputs = avgOfAdditionalInputs;
    });
  }

  const configs = {
    showPlansAtBULevel,
    showAdditionalInputsAtBULevel,
    workflowName:
      reporting_level.dimensionType === 'AMAZON_CATEGORY'
        ? 'media_planner_simulation_wf_rc'
        : 'media_planner_simulation_wf_ic',
    reporting_level,
    optional: {
      roas_guardrail: roasChecked ? minRoas : 0,
      filters: isOverall && !showPlansAtBULevel ? {} : filters,
      invisibleFilters
    },
    backfilledData,
    filtersMap
  };

  return {
    output_start_date: moment(months.startMonth).format(),
    output_end_date: moment(months.endMonth).format(),
    sales_goal: goal,
    sales_goal_level:
      showPlansAtBULevel && isOverall
        ? organizeOptions.CUSTOMIZE.value
        : organizeType,
    adsales_percentage: priority.adSales * 10,
    roas_percentage: priority.roas * 10,
    ntb_sales_percentage: priority.ntb * 10,
    yoy_growth_percentage: priority.yoyGrowth * 10,
    strategy,
    plans: JSON.stringify(plans),
    ...(isDrafted !== undefined ? { is_drafted: isDrafted } : {}),
    configs: JSON.stringify(configs),
    name
  };
};

export const getConfigFromPlan = (planData) => {
  const {
    OUTPUT_START_DATE,
    OUTPUT_END_DATE,
    SALES_GOAL,
    SALES_GOAL_LEVEL,
    ADSALES_PERCENTAGE,
    ROAS_PERCENTAGE,
    NTB_SALES_PERCENTAGE,
    YOY_GROWTH_PERCENTAGE,
    PLANS,
    STRATEGY,
    CONFIGS,
    NAME,
    TOTAL_MODULUS_TRIGGERS,
    MODULUS_TRIGGER_TIME
  } = planData;
  const parsedConfigs = JSON.parse(CONFIGS);
  const filters = CONFIGS ? parsedConfigs.optional.filters : {};
  if (SALES_GOAL_LEVEL === organizeOptions.OVERALL.value) {
    const { dimensionName } = parsedConfigs.reporting_level || {};
    filters[dimensionName] = (filters[dimensionName] || []).filter(
      (f) => f !== null
    );
  }
  return {
    months: {
      startMonth: OUTPUT_START_DATE,
      endMonth: OUTPUT_END_DATE
    },
    goal: SALES_GOAL,
    organizeType: SALES_GOAL_LEVEL,
    priority: {
      adSales: ADSALES_PERCENTAGE / 10,
      roas: ROAS_PERCENTAGE / 10,
      ntb: NTB_SALES_PERCENTAGE / 10,
      yoyGrowth: YOY_GROWTH_PERCENTAGE / 10
    },
    plans: PLANS ? JSON.parse(PLANS) : {},
    strategy: STRATEGY,
    filters,
    businessUnit:
      CONFIGS && SALES_GOAL_LEVEL === organizeOptions.OVERALL.value
        ? parsedConfigs.reporting_level
        : {},
    filteredBusinessUnit:
      CONFIGS && SALES_GOAL_LEVEL === organizeOptions.CUSTOMIZE.value
        ? parsedConfigs.reporting_level
        : {},
    name: NAME,
    monthOptions: defaultMediaPlannerConfig.monthOptions,
    totalModulusTriggers: TOTAL_MODULUS_TRIGGERS,
    modulusTriggerTime: MODULUS_TRIGGER_TIME,
    showPlansAtBULevel: parsedConfigs.showPlansAtBULevel,
    showAdditionalInputsAtBULevel: parsedConfigs.showAdditionalInputsAtBULevel,
    backfilledData: parsedConfigs.backfilledData || {},
    filtersMap: parsedConfigs.filtersMap || {}
  };
};

export const MEDIA_PLAN_STATUS = {
  INPUT_SAVED: {
    index: 0,
    id: 'INPUT_SAVED',
    message: 'Saving inputs',
    percent: 14.28
  },
  POPULATING_FILTERS: {
    index: 1,
    id: 'POPULATING_FILTERS',
    message: 'Populating the selected filters',
    percent: 14.28 * 2
  },
  TRIGGERED_MODULUS_WF: {
    index: 2,
    id: 'TRIGGERED_MODULUS_WF',
    message: 'Calculating budget',
    percent: 14.28 * 3
  },
  BUDGET_CALCULATED: {
    index: 3,
    id: 'BUDGET_CALCULATED',
    message: 'Budget calculated',
    percent: 14.28 * 4
  },
  JSON_FILE_UPLOADED: {
    index: 4,
    id: 'JSON_FILE_UPLOADED',
    message: 'Generating data',
    percent: 14.28 * 5
  },
  EXCEL_GENERATED: {
    index: 5,
    id: 'EXCEL_GENERATED',
    message: 'Generated excel',
    percent: 14.28 * 6
  },
  MEDIA_PLAN_CREATED: {
    index: 6,
    id: 'MEDIA_PLAN_CREATED',
    message: 'Media plan created',
    percent: 100
  }
};

const getActualData = (data, objToUse, entityType) => {
  data.forEach(({ RESULT }) => {
    const firstKey = Object.keys(checkUndefined(RESULT))[0];
    if (RESULT && firstKey) {
      const firstValue = Object.values(RESULT)[0];
      const finalValue =
        entityType && firstKey === entityType && !firstValue
          ? 'NA'
          : firstValue;
      objToUse[
        firstKey === 'ordered_product_sales' ? 'total_sales' : firstKey
      ] = finalValue;
    }
  });
};

export const computedFilteredData = (
  res,
  state,
  entityType,
  data,
  ntbSales,
  yoyGrowthPercentage
) => {
  const isOverall = data.overall;
  const result = isOverall ? { ntbSales, yoyGrowthPercentage } : {};
  if (isOverall) {
    res.forEach(({ data = [] }) => {
      getActualData(data, result);
    });
  } else {
    const filteredRes = res.filter(({ data }) => data.length);
    filteredRes.forEach(({ data = [] }) => {
      const historicalData = {};
      getActualData(data, historicalData, entityType);
      result[entityType] = [...(result[entityType] || []), historicalData];
    });
  }

  const finalData = {
    ...state.filteredHistoricalData?.data,
    [entityType]: isOverall ? result : result[entityType]
  };

  return isOverall ? finalData[entityType] : finalData;
};

const getDistributionLevelMap = (distributions, isIC, lastLevelIndex) => ({
  0: { distribution: distributions.retailer_level, level: 'RETAILER' },
  1: { distribution: distributions.dimension_level, level: 'DIMENSION' },
  2: { distribution: distributions.campaign_level, level: 'CAMPAIGN_TYPE' },
  ...(isIC
    ? {}
    : {
        3: { distribution: distributions.keyword_level, level: 'KEYWORD_TYPE' }
      }),
  [lastLevelIndex]: { distribution: distributions.month_level, level: 'MONTH' }
});

const DIMENSIONS = [
  'BUDGET_REQUIRED',
  'PERCENTAGE_CHANGE',
  'PERCENTAGE_OPS_GOAL',
  'OPS',
  'SCOGS',
  'PERCENTAGE_YOY_ORGANIC_SALES_GROWTH',
  'ORGANIC_SALES',
  'AD_SALES',
  'PERCENTAGE_YOY_ROAS_GROWTH',
  'ROAS',
  'PORTFOLIO_NAME'
];

const getProperData = (yearAndMonth) => {
  if (typeof yearAndMonth === 'string') {
    const splitYearAndMonth = yearAndMonth?.split('_');
    if (splitYearAndMonth[1] < 10) {
      return `${splitYearAndMonth[0]}0${splitYearAndMonth[1]}`;
    }
    return `${splitYearAndMonth[0]}${splitYearAndMonth[1]}`;
  }

  return yearAndMonth;
};

const getMonthLevelDimensionName = (
  item,
  dimension,
  distributionLevelMap,
  lastLevelIndex
) => {
  let dimensionName = item[dimension];
  if (dimension === distributionLevelMap[lastLevelIndex].level) {
    dimensionName =
      typeof item[dimension] === 'string'
        ? item[dimension]?.split('_')[1]
        : item[dimension];
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    dimensionName =
      typeof item[dimension] === 'string'
        ? `${months[dimensionName - 1]} ${+item[dimension]?.split('_')?.[0]}`
        : months[dimensionName - 1];
  }

  return dimensionName;
};

const getSortedRows = (rows, dimension, distributionLevelMap, lastLevelIndex) =>
  dimension === distributionLevelMap[lastLevelIndex].level
    ? sortBy(rows, (ob) => getProperData(ob[dimension]))
    : sortBy(rows, (ob) => ob[dimension]);
const getCondition = (
  parentsAtEachLevel,
  level,
  item,
  distributionLevelMap
) => {
  return parentsAtEachLevel.every(
    (parent, index) =>
      level === 1 || item[distributionLevelMap[index + 1].level] === parent
  );
};

export const getOutputTableRows = (
  distributions,
  planId,
  parentsAtEachLevel,
  level,
  distributionLevelToShow,
  actualPlan,
  isIC
) => {
  const obj = {};
  const rows = [];
  const lastLevelIndex = isIC ? 3 : 4;
  const distributionLevelMap = getDistributionLevelMap(
    distributions,
    isIC,
    lastLevelIndex
  );
  const dimension = distributionLevelMap[level].level;
  const isNotLastLevel = level < lastLevelIndex;
  const nextDimension =
    distributionLevelMap[isNotLastLevel ? level + 1 : level].level;
  const distribution = distributionLevelMap[level]?.distribution;
  const planData = cloneDeep(
    distribution?.[defaultCompetitivePlans[planId].scenario]
  );
  planData.forEach((item) => {
    const condition = getCondition(
      parentsAtEachLevel,
      level,
      item,
      distributionLevelMap
    );
    if (item[dimension] && condition) {
      if (obj[item[dimension]]) {
        obj[item[dimension]].count += 1;
        DIMENSIONS.forEach((key) => {
          obj[item[dimension]].item[key] = item[key];
        });
        rows[obj[item[dimension]].index] = {
          ...rows[obj[item[dimension]].index],
          ...obj[item[dimension]].item
        };
      } else {
        const distributionLevel = getMonthLevelDimensionName(
          item,
          dimension,
          distributionLevelMap,
          lastLevelIndex
        );
        const index = rows.length;
        obj[item[dimension]] = { item, index, count: 1 };
        rows[index] = {
          ...item,
          'ag-grid-has-expand':
            isNotLastLevel &&
            item[dimension] &&
            distributionLevelToShow[nextDimension],
          distributionLevel,
          plan: ` ${actualPlan}`,
          planId
        };
      }
    }
  });

  return getSortedRows(rows, dimension, distributionLevelMap, lastLevelIndex);
};

export const getBUFiltersPayload = (dimension, isIC) => ({
  esDataSetName: isIC ? 'ams_sku' : 'common_filter',
  entityType: null,
  tagType: null,
  enableGreyout: true,
  enableNextHierarchyIndicator: false,
  dimensionsList: [dimension],
  whereClause: []
});

export const budgetDistributionMap = {
  keyword_generic: 'keyword_generic',
  keyword_client_branded: 'keyword_client_branded',
  keyword_competitor_branded: 'keyword_competitor_branded',
  others: 'others'
};

export const buildProfilePortfolioMaps = (res, portfolioProfileMap) => {
  const mapping = res.data.data || [];
  mapping.forEach(({ PORTFOLIO_NAME, PROFILE_NAME }) => {
    if (PORTFOLIO_NAME && PROFILE_NAME) {
      if (!portfolioProfileMap[PORTFOLIO_NAME]) {
        portfolioProfileMap[PORTFOLIO_NAME] = PROFILE_NAME;
      }
    }
  });
};

export const buildRestOfTheData = (res, currentRes) => {
  const finalRes = [];
  const entityData = checkUndefined(res?.data?.entityData);
  const entityDataMap = {};
  entityData.forEach(({ data, entityValue }) => {
    entityDataMap[entityValue] = data;
  });
  currentRes.forEach(({ data, entityValue }) => {
    entityDataMap[entityValue] = [
      ...checkUndefined(entityDataMap[entityValue]),
      ...data
    ];
  });
  Object.keys(entityDataMap).forEach((entityValue) =>
    finalRes.push({ data: entityDataMap[entityValue], entityValue })
  );
  return finalRes;
};

export const getFinalDataForAdditionalInputs = (
  finalRes,
  showAdditionalInputsAtBULevel,
  result
) => {
  const overallData = {
    opsTY: undefined,
    opsLY: undefined,
    adSalesTY: undefined,
    adSalesLY: undefined
  };
  finalRes.forEach(({ data, entityValue }) => {
    const bUData = {
      opsTY: undefined,
      opsLY: undefined,
      adSalesTY: undefined,
      adSalesLY: undefined
    };
    data.forEach(({ name, PVP, RESULT }) => {
      let finalValue = 0;
      const isROAS = name === 'roi';
      const key = getIfElse(
        isROAS,
        'expectedRoasGrowth',
        'expectedOrganicGrowth'
      );
      if (isROAS) {
        finalValue = checkNullish(PVP[`PVP_${name}`], 0);
      } else {
        const data = getIfElse(
          showAdditionalInputsAtBULevel,
          bUData,
          overallData
        );
        if (name === 'ordered_product_sales') {
          data.opsTY = checkNullish(RESULT[name], 0);
          data.opsLY = checkNullish(PVP[name], 0);
        }

        if (name === 'paid_sales_14d') {
          data.adSalesTY = checkNullish(RESULT[name], 0);
          data.adSalesLY = checkNullish(PVP[name], 0);
        }

        if (!isNaN(data.opsTY + data.adSalesTY)) {
          const { opsTY, adSalesTY, opsLY, adSalesLY } = data;
          const oSTY = opsTY - adSalesTY;
          const oSLY = opsLY - adSalesLY;
          const organicSales = getIfElse(
            oSLY === 0,
            0,
            ((oSTY - oSLY) / oSLY) * 100
          );
          finalValue = parseFloat(formatter(organicSales, 'PERCENTAGE', 0), 10);
        }
      }

      if (showAdditionalInputsAtBULevel) {
        result.bULevel[entityValue] = {
          ...checkUndefined(result.bULevel[entityValue], {}),
          [key]: finalValue
        };
      } else {
        result.overall[key] = finalValue;
      }
    });
  });
  return result;
};

export const getPayloadForFetchingAdditionalInputs = (
  showAdditionalInputsAtBULevel,
  filters,
  dimensionName,
  isIC,
  isOverall
) => {
  const dimensionNameValueList = [];
  if (!isOverall) {
    Object.keys(filters).forEach((key) => {
      filters[key].forEach((j) => {
        dimensionNameValueList.push({
          dimensionName: key,
          dimensionValue: j
        });
      });
    });
  }

  const fromDate = moment()
    .subtract(3, 'months')
    .startOf('month')
    .format('YYYY-MM-DD');
  const toDate = moment()
    .subtract(1, 'months')
    .endOf('month')
    .format('YYYY-MM-DD');
  const pvpFromDate = moment(fromDate)
    .subtract(1, 'years')
    .format('YYYY-MM-DD');
  const APIConfig = cloneDeep(
    showAdditionalInputsAtBULevel && !isIC
      ? baseConfigForBULevelData
      : filteredHistoricalDataAPIConfig
  );
  const pvpToDate = moment(toDate).subtract(1, 'years').format('YYYY-MM-DD');
  if (!showAdditionalInputsAtBULevel) {
    APIConfig.operations.pvpenabled = true;
    APIConfig.where.dimensionNameValueList = dimensionNameValueList;
    APIConfig.metricsList = [
      OPS_AND_SCOGS_METRICS[0],
      ADV_METRICS_BACKFILLED[0],
      ADV_METRICS_BACKFILLED[2]
    ];
    APIConfig.where.date.from = fromDate;
    APIConfig.where.date.to = toDate;
    APIConfig.where.pvpDate.from = pvpFromDate;
    APIConfig.where.pvpDate.to = pvpToDate;
  } else if (isIC) {
    APIConfig.entityType = dimensionName;
    APIConfig.operations.pvpenabled = true;
    APIConfig.where.dimensionNameValueList = dimensionNameValueList;
    APIConfig.metricsList = [OPS_AND_SCOGS_METRICS[0]];
    APIConfig.where.date.from = fromDate;
    APIConfig.where.date.to = toDate;
    APIConfig.where.pvpDate.from = pvpFromDate;
    APIConfig.where.pvpDate.to = pvpToDate;
  } else {
    delete APIConfig.bundleDataGroupsRequest.dw;
    delete APIConfig.bundleDataGroupsRequest.ams_campaigns_asin_workbench;
    APIConfig.bundleDataGroupsRequest.ams_campaigns_asin_workbench_backfilled =
      ams_campaigns_asin_workbench_backfilled;
    APIConfig.where.date.to = toDate;
    APIConfig.where.date.from = fromDate;
    APIConfig.entityType = dimensionName;
    ['ams_campaigns_asin_workbench_backfilled', 'sales_dashboard'].forEach(
      (key) => {
        APIConfig.bundleDataGroupsRequest[key].entityType = dimensionName;
        APIConfig.bundleDataGroupsRequest[key].where.dimensionNameValueList =
          dimensionNameValueList;
        APIConfig.bundleDataGroupsRequest[key].operations.pvpenabled = true;
        APIConfig.bundleDataGroupsRequest[key].where.date.from = fromDate;
        APIConfig.bundleDataGroupsRequest[key].where.date.to = toDate;
        APIConfig.bundleDataGroupsRequest[key].where.pvpDate.from = pvpFromDate;
        APIConfig.bundleDataGroupsRequest[key].where.pvpDate.to = pvpToDate;
        if (key !== 'ams_campaigns_asin_workbench_backfilled') {
          APIConfig.bundleDataGroupsRequest[key].operations.showByEntities = [
            dimensionName
          ];
        }
      }
    );
    APIConfig.bundleDataGroupsRequest.ams_campaigns_asin_workbench_backfilled.metricsList =
      [ADV_METRICS_BACKFILLED[2], ADV_METRICS_BACKFILLED[0]];
    APIConfig.bundleDataGroupsRequest.sales_dashboard.metricsList = [
      OPS_AND_SCOGS_METRICS[0]
    ];
  }

  return APIConfig;
};

export const getConditionToFetchExpectedGrowth = (
  showAdditionalInputsAtBULevel,
  expectedGrowth,
  isEditing,
  forceFetch
) => {
  let fetchNumber = 0;

  if (!isEditing) {
    if (showAdditionalInputsAtBULevel) {
      const firstBU = Object.keys(expectedGrowth.data.bULevel)[0];
      fetchNumber = checkNullish(
        expectedGrowth?.data?.bULevel?.[firstBU]?.expectedOrganicGrowth,
        false
      );
    } else {
      fetchNumber = checkNullish(
        expectedGrowth?.data?.overall?.expectedOrganicGrowth,
        false
      );
    }
  }
  return !Number.isFinite(fetchNumber) || forceFetch;
};

export const getUpdatedPlansWithAdditionalInputs = (
  updatedPlans,
  expectedGrowth,
  showAdditionalInputsAtBULevel
) => {
  const { overall, bULevel } = expectedGrowth.data;
  const defaultBudgetDistributionConfigs = {
    keyword_generic: 53,
    keyword_client_branded: 19,
    keyword_competitor_branded: 7,
    others: 21
  };
  Object.keys(defaultCompetitivePlans).forEach((planId) => {
    if (showAdditionalInputsAtBULevel) {
      get(updatedPlans[planId], 'businessUnitLevel', []).forEach(
        (bU, index) => {
          set(
            updatedPlans[planId],
            `businessUnitLevel[${index}].additionalInputs`,
            {
              expected_organic_growth: checkNullish(
                bULevel?.[bU?.name]?.expectedOrganicGrowth,
                0
              ),
              expected_roas_growth: checkNullish(
                bULevel?.[bU?.name]?.expectedRoasGrowth,
                0
              ),
              keyword_generic:
                bULevel?.[bU?.name]?.keyword_generic ??
                defaultBudgetDistributionConfigs.keyword_generic,
              keyword_client_branded:
                bULevel?.[bU?.name]?.keyword_client_branded ??
                defaultBudgetDistributionConfigs.keyword_client_branded,
              keyword_competitor_branded:
                bULevel?.[bU?.name]?.keyword_competitor_branded ??
                defaultBudgetDistributionConfigs.keyword_competitor_branded,
              others:
                bULevel?.[bU?.name]?.others ??
                defaultBudgetDistributionConfigs.others
            }
          );
        }
      );
    } else {
      set(updatedPlans[planId], 'overall.additionalInputs', {
        expected_organic_growth: checkNullish(overall.expectedOrganicGrowth, 0),
        expected_roas_growth: checkNullish(overall.expectedRoasGrowth, 0),
        keyword_client_branded:
          defaultBudgetDistributionConfigs.keyword_client_branded,
        keyword_competitor_branded:
          defaultBudgetDistributionConfigs.keyword_competitor_branded,
        keyword_generic: defaultBudgetDistributionConfigs.keyword_generic,
        others: defaultBudgetDistributionConfigs.others
      });
    }
  });
  return updatedPlans;
};

// UTILS
export const checkUndefined = (condition, defaultValue = []) =>
  condition || defaultValue;
export const checkNullish = (condition, defaultValue) =>
  condition ?? defaultValue;

export const getIfElse = (condition, ifCondition, elseCondition) =>
  condition ? ifCondition : elseCondition;
