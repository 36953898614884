<template>
  <div
    class="u-bg-color-grey-white shadows u-border-radius-s widget-1 u-spacing-mt-m u-spacing-p-m"
  >
    <div class="u-display-flex u-spacing-mt-m u-spacing-mb-m">
      <rb-icon
        icon="spotlight"
        class="rb-icon--large u-color-grey-lighter"
      />
      <p
        class="u-font-size-2 u-font-weight-600 u-color-grey-lighter u-text-case-upper u-spacing-pt-s u-spacing-pl-s"
      >
        SKU Breakdown
      </p>
    </div>
    <rb-insights-table
      id="content-score-retailer-breakdown"
      :table-column="skuBreakdownColDef"
      :table-row="skuBreakdownTableData"
      :grid-options="skuBreakdownGridOptions"
      :enable-client-side-sorting="true"
      :pagination="false"
      sorting-change-event="categoryPerformanceSortChangeEvent"
      class="rb-insights-table-scrollable"
      :row-selection="rowSelection"
      :has-overflow="true"
      :row-height="90"
    />
    <edit-product-name-description
      :show-edit="showEdit"
      :content-details="contentDetails"
      @onNameDescriptionEdit="onNameDescriptionEditConfirm"
      @onEditNameDescriptionCancel="onNameDescriptionEditCancel"
    />
    <edit-sku-image
      :show-edit="showImageEdit"
      :image-content-details="imageContentDetails"
      @onMatchImageSOTContent="onImageEditConfirm"
    />
  </div>
</template>

<script>
import { SKU_DATA } from '@/pages/all_retailers/dso/constant';
import EditProductNameDescription from '@/pages/all_retailers/dso/content-automation/editProductNameDescription.vue';
import EditSkuImage from '@/pages/all_retailers/dso/content-automation/editSKUImage.vue';
import { cloneDeep } from 'lodash';

export default {
  components: {
    EditProductNameDescription,
    EditSkuImage
  },
  data() {
    return {
      showEdit: false,
      showImageEdit: false,
      skuBreakdownColDef: [
        {
          headerName: 'Score',
          key: 'scoreDetails',
          type: 'string',
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            keyType: 'string',
            toolTipText: ''
          },
          cellRendererFramework: 'DynamicCellComponent',
          cellRendererParams: {
            eventMap: {},
            cellAlignment: 'u-text-align-right',
            component: 'ProductScore',
            paramsToProps: (params) => {
              return {
                scoreDetails: params.data.scoreDetails
              };
            }
          },
          tooltipField: 'scoreDetails',
          tooltipComponentParams: {
            scoreDetails: {
              title: 80,
              description: 49,
              image: 70,
              overall: 90
            }
          },
          tooltipComponent: 'ProductScore',
          pinned: 'left'
        },
        {
          headerName: 'Retailer',
          key: 'retailer',
          type: 'string',
          headerComponentFramework: 'genericTableHeader',
          headerComponentParams: {
            keyType: 'string',
            toolTipText: ''
          },
          cellRendererFramework: 'DynamicCellComponent',
          cellRendererParams: {
            eventMap: {},
            cellAlignment: 'u-text-align-right',
            component: 'RetailerImage',
            paramsToProps: (params) => {
              return {
                retailerDetails: {
                  name: params.data.retailer
                }
              };
            }
          }
        },
        {
          headerName: 'SKU Image',
          key: 'skuDetails',
          headerComponentFramework: 'genericTableHeader',
          type: 'string',
          headerComponentParams: {
            keyType: 'string',
            toolTipText: ''
          },
          cellRendererFramework: 'DynamicCellComponent',
          cellRendererParams: {
            eventMap: {},
            cellAlignment: 'u-text-align-center',
            component: 'SkuImage',
            paramsToProps: (params) => {
              return {
                skuDetails: params.data.skuDetails,
                onImageEdit: params.context.componentParent?.onImageEdit?.bind()
              };
            }
          }
        },
        {
          headerName: 'Product Name',
          key: 'productDetails',
          headerComponentFramework: 'genericTableHeader',
          type: 'string',
          headerComponentParams: {
            keyType: 'string',
            toolTipText: ''
          },
          cellRendererFramework: 'DynamicCellComponent',
          cellRendererParams: {
            eventMap: {},
            cellAlignment: 'u-text-align-center',
            component: 'ProductNameDescription',
            paramsToProps: (params) => {
              return {
                productDetails: {
                  ...params.data.productNameDetails,
                  retailer: params.data.retailer
                },
                onProductNameDescriptionEdit:
                  params.context.componentParent?.onNameDescriptionEdit?.bind()
              };
            }
          },
          minWidth: 450
        },
        {
          headerName: 'Net quantity',
          field: 'netQuantity',
          headerComponentFramework: 'genericTableHeader',
          minWidth: 100
        },
        {
          headerName: 'Description',
          key: 'descriptionDetails',
          headerComponentFramework: 'genericTableHeader',
          type: 'string',
          headerComponentParams: {
            keyType: 'string',
            toolTipText: ''
          },
          cellRendererFramework: 'DynamicCellComponent',
          cellRendererParams: {
            eventMap: {},
            cellAlignment: 'u-text-align-center',
            component: 'ProductNameDescription',
            paramsToProps: (params) => {
              return {
                productDetails: {
                  ...params.data.descriptionDetails,
                  retailer: params.data.retailer
                },
                onProductNameDescriptionEdit:
                  params.context.componentParent?.onNameDescriptionEdit?.bind()
              };
            }
          },
          minWidth: 420
        },
        {
          headerName: 'Buy Box',
          key: 'buyBoxType',
          headerComponentFramework: 'genericTableHeader',
          type: 'string',
          headerComponentParams: {
            keyType: 'string',
            toolTipText: ''
          },
          cellRendererFramework: 'DynamicCellComponent',
          cellRendererParams: {
            eventMap: {},
            cellAlignment: 'u-text-align-center',
            component: 'BuyBox',
            paramsToProps: (params) => {
              return {
                buyBoxType: params.data.buyBoxType
              };
            }
          },
          minWidth: 100
        }
      ],
      skuBreakdownTableData: cloneDeep(SKU_DATA),
      rowSelection: {
        mode: 'multiple'
      },
      skuBreakdownGridOptions: {
        suppressColumnVirtualisation: true,
        context: {
          componentParent: this
        }
      },
      contentDetails: {},
      imageContentDetails: {}
    };
  },
  methods: {
    onNameDescriptionEdit(productDetails) {
      this.contentDetails = { ...productDetails };
      this.showEdit = true;
    },
    onNameDescriptionEditConfirm(productDetails) {
      this.skuBreakdownTableData = this.skuBreakdownTableData.map(
        (skuDetails) => {
          if (
            productDetails.id === skuDetails.id &&
            productDetails.contentType === 'name'
          ) {
            return {
              ...skuDetails,
              productNameDetails: {
                ...skuDetails.productNameDetails,
                currentContent: productDetails.suggestedContent,
                status: 'ticketRaised'
              }
            };
          } else if (
            productDetails.id === skuDetails.id &&
            productDetails.contentType === 'description'
          ) {
            return {
              ...skuDetails,
              descriptionDetails: {
                ...skuDetails.descriptionDetails,
                currentContent: productDetails.suggestedContent,
                status: 'ticketRaised'
              }
            };
          } else {
            return skuDetails;
          }
        }
      );
      this.notify();
      this.showEdit = false;
    },
    onImageEdit(skuImageDetails) {
      this.imageContentDetails = { ...skuImageDetails };
      this.showImageEdit = true;
    },
    onImageEditConfirm(skuDetails) {
      this.skuBreakdownTableData = this.skuBreakdownTableData.map(
        (prodDetails) => {
          if (
            prodDetails.id === skuDetails.id &&
            skuDetails.contentType === 'image'
          ) {
            return {
              ...prodDetails,
              skuDetails: {
                ...skuDetails,
                currentImageUrl: skuDetails.suggestedImageUrl,
                state: 'positive'
              }
            };
          } else {
            return prodDetails;
          }
        }
      );
      this.notify();
      this.showImageEdit = false;
    },
    notify() {
      this.$snackbar.open({
        message: 'Ticket Raised for content change',
        duration: 5000,
        buttonColor: '#007CF6',
        actionText: '',
        customStyles: 'background: #007cf6'
      });
    },
    onNameDescriptionEditCancel() {
      this.showEdit = false;
    }
  }
};
</script>
