import urlHelper from '@/utils/helpers/url';
import logger from '@/components/pages/skuDetails/logger.js';
import router from '@/router';

window.getFinalTimingObject = function (r) {
  r.loadEventEnd = window.totalTime;
  return r;
};

router.beforeEach((to, from, next) => {
  if (window.performance && window.performance.getEntries) {
    if (from.fullPath !== '/') {
      var _considerArray = [];
      var index = 0;
      var endTime = 0;
      if (window.performance.getEntries().length > 4) {
        index = 4;
      }

      var startTime = window.performance.getEntries()[index].startTime;

      var x = window.performance.getEntries().filter(function (a) {
        return (
          a.entryType === 'resource' &&
          a.initiatorType !== 'img' &&
          a.name.indexOf('fullstory') === -1 &&
          a.name.indexOf('/i?e=') === -1 &&
          a.name.indexOf('/fonts/') === -1 &&
          a.name.indexOf('keepalive') === -1
        );
      });

      for (var i = 1; i < x.length; i++) {
        if (i === 1) {
          _considerArray.push(x[0]);
        }
        if ((x[i].connectStart - x[i - 1].connectStart) / 1000 < 4) {
          _considerArray.push(x[i]);
        } else {
          break;
        }
      }

      if (x.length === 1) {
        _considerArray = x;
      }

      _considerArray = _considerArray.sort(function (a, b) {
        return b.responseEnd - a.responseEnd;
      });

      if (_considerArray.length === 0) {
        endTime = startTime;
      } else {
        endTime = _considerArray[0].responseEnd;
      }

      window.totalTime = parseInt(Math.abs(endTime - startTime));
      console.log(from.fullPath + '-----' + window.totalTime);
      // window.snowplow('trackPageView', from.fullPath, null, undefined, window.totalTime);
      let fromRoute = from.fullPath;
      fromRoute = decodeURIComponent(fromRoute);
      const pagesData = urlHelper.getPageHierarchy(fromRoute);
      logger.log({
        eventType: 'page_load_time',
        type: 'route_change',
        page: `${pagesData.page}`,
        pageIdentifier: `${fromRoute}`,
        eventContext: 'page_load_time',
        from: fromRoute,
        to: '',
        ...pagesData,
        timeToLoad: window.totalTime
      });
      window.performance.clearResourceTimings();
    }
  }
  next();
});
