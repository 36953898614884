const tableColumns = [
  {
    title: 'SKU Details',
    key: 'sku_details',
    pinned: 'left',
    showToolTip: true,
    toolTipText: '',
    type: 'asin',
    hoverText: 'Add to Search',
    keys: {
      imageUrl: 'image_url',
      product_url: 'asin_url',
      title: 'asin_title',
      asin: 'asin'
    },
    keyOrder: 1
  },
  {
    title: 'In-Stock Percentage',
    key: 'in_stock_percentage',
    showToolTip: true,
    toolTipText: `Instock % represents the average percentage of the Zipcodes where ASIN has been available to be sold in the selected dates`,
    type: 'numeric',
    component: 'histogram',
    unit: 'percentage',
    formatReq: true,
    keyOrder: 2
  }
];

export default {
  columns: tableColumns
};
