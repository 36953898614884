import Vue from 'vue';
import HttpService from '@/utils/services/http-service';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import {
  filterBudgetPlannerCollectiveSummary,
  getFiltersInFormatWKBudgetPlanner,
  getEntireBusinessData,
  filterTableLevel1Data,
  filterTableLevel2Data,
  getSortedEntities
} from '@/pages/budget-planner-v2/dashboard/helpers/table.js';
import {
  populateChartMetricsData,
  getChartAPIDataPayload,
  populateChartLegendsData
} from '@/pages/budget-planner-v2/dashboard/helpers/chart.js';

const state = {
  showDashboardLoader: false,
  dashboardError: {},
  dashboardChartRollup: 'rollup_days', // ['rollup_days', 'rollup_months', 'rollup_weeks']
  dashboardChartDataBP: {
    error: false,
    load: false,
    timeSeriesData: [],
    legendData: [],
    noData: false
  },
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  BPDashboardActionStatus: {
    load: false,
    error: false,
    noData: false
  },
  workbenchBudgetPlannerSelectedFilters: {
    date_range: {
      value: 'Last 30 days'
    }
  },
  budgetPlannerDashboardTable: [],
  budgetPlannerDashboardEntireBusiness: {
    rows: [],
    error: false,
    noData: false,
    load: false
  },
  budgetPlannerDashboardTableLevel1: {},
  budgetPlannerDashboardLevel2: {},
  budgetPlanMTDProfilesTooltipContent: [],
  createPlanActiveSelection: 0,
  page_wise_min_max_key: 'budget_optimizer_dashboard',
  strategies: null
};

const getters = {
  isDashboardLoading: (state) => state.showDashboardLoader,
  getBudgetPlanMTDProfilesTooltipContent: (state) =>
    state.budgetPlanMTDProfilesTooltipContent,
  getCreatePlanActiveSelection: (state) => state.createPlanActiveSelection,
  getDashboardChartDataBP: (state) => state.dashboardChartDataBP,
  getWorkbenchBudgetPlannerFilters: (state) =>
    state.workbenchBudgetPlannerSelectedFilters,
  getBPDashboardActionStatus: (state) => state.BPDashboardActionStatus,
  getBudgetPlannerDashboardTable: (state) => state.budgetPlannerDashboardTable,
  getBudgetPlannerDashboardEntireBusiness: (state) =>
    state.budgetPlannerDashboardEntireBusiness,
  getBudgetPlannerDashboardLevel1: (state) =>
    state.budgetPlannerDashboardTableLevel1,
  getBudgetPlannerDashboardLevel2: (state) =>
    state.budgetPlannerDashboardLevel2,
  getBudgetPlannerDateWiseMinMaxKey: (state) => state.page_wise_min_max_key,
  getStrategies: (state) => state.strategies
};

const mutations = {
  MUTATE_STRATEGIES: (state, strategies) => {
    state.strategies = strategies;
  },
  BUDGET_PLAN_MTD_PROFILES_TOOLTIP_CONTENT: (state, data) => {
    state.budgetPlanMTDProfilesTooltipContent = data;
  },
  DASHBOARD_CHART_DATA: (state, data) => {
    state.dashboardChartDataBP = data;
  },
  CREATE_PLAN_ACTIVE_SELECTION: (state, data) => {
    state.createPlanActiveSelection = data;
  },
  DASHBOARD_CHART_ROLLUP: (state, rollup) => {
    state.dashboardChartRollup = rollup;
  },
  IWKBUDGETPLANNER_SET_SELECTED_FILTER: (state, data) => {
    const dateRange = state.workbenchBudgetPlannerSelectedFilters.date_range;
    Vue.set(state, 'workbenchBudgetPlannerSelectedFilters', data.values);
    if (!state.workbenchBudgetPlannerSelectedFilters.date_range) {
      Vue.set(
        state.workbenchBudgetPlannerSelectedFilters,
        'date_range',
        dateRange
      );
    }
    getFiltersInFormatWKBudgetPlanner(data, state);
  },
  BP_DASHBOARD_ACTIONS_STATUS: (state, data) => {
    state.BPDashboardActionStatus = data;
  },
  BP_DASHBOARD_LOADER: (state, data) => {
    state.showDashboardLoader = data;
  },
  BUDGET_PLANNER_DASHBOARD_TABLE: (state, budgetPlannerDashboardTable) => {
    state.budgetPlannerDashboardTable = budgetPlannerDashboardTable;
  },
  BUDGET_PLANNER_DASHBOARD_ENTIRE_BUSINESS: (
    state,
    budgetPlannerDashboardEntireBusiness
  ) => {
    state.budgetPlannerDashboardEntireBusiness =
      budgetPlannerDashboardEntireBusiness;
  },
  BUDGET_PLANNER_DASHBOARD_TABLE_LEVEL1: (state, data) => {
    const levelName = 'budgetPlannerDashboardTableLevel1';
    if (!state[levelName]) {
      Vue.set(state, levelName, {});
    }
    if (!state[levelName][data.id]) {
      Vue.set(state[levelName], data.id, {});
    }
    const levelData = {
      rows: data.rows,
      load: false,
      error: false,
      id: data.id,
      index: data.index,
      level: data.level
    };

    Vue.set(state, levelName, {});
    Vue.set(state[levelName], data.id, levelData);
  },
  BUDGET_PLANNER_DASHBOARD_TABLE_LEVEL2: (state, data) => {
    const levelName = 'budgetPlannerDashboardLevel2';
    if (!state[levelName]) {
      Vue.set(state, levelName, {});
    }
    if (!state[levelName][data.id]) {
      Vue.set(state[levelName], data.id, {});
    }
    const levelData = {
      rows: data.rows,
      load: false,
      error: false,
      id: data.id,
      index: data.index,
      level: data.level
    };

    Vue.set(state, levelName, {});
    Vue.set(state[levelName], data.id, levelData);
  }
};

const actions = {
  fetchLatestStrategy: async (context) => {
    context.commit('BP_DASHBOARD_LOADER', true);
    context.commit('MUTATE_STRATEGIES', null);
    const response = await HttpService.get(
      'GET_BUDGET_PLANNER_OMNI_STRATEGIES'
    );
    const payload = response?.data;
    context.commit('MUTATE_STRATEGIES', payload || null);
    context.commit('BP_DASHBOARD_LOADER', false);
  },
  setCreatePlanActiveSelection: (context, selection) => {
    context.commit('CREATE_PLAN_ACTIVE_SELECTION', selection);
  },
  setDashboardChartRollup: (context, rollup) => {
    context.commit('DASHBOARD_CHART_ROLLUP', rollup);
  },
  setWorkbenchBudgetPlannerFilters: (context, data) => {
    context.commit('IWKBUDGETPLANNER_SET_SELECTED_FILTER', data);
  },
  downloadDashboardTable: async (context, configData) => {
    const where = configData.where;
    // resetting where clause due to client side filtering
    configData.where = {
      dimensionNameValueList: []
    };
    configData.where.date = context.state.whereClause.date;
    const response = await HttpLayer.post({
      APIData: configData
    });
    let data = transformer.mergeResultDimension(response.data, true);
    const entireBusiness = data.filter(
      (row) => row.level_type === 'ENTIRE_BUSINESS'
    );
    if (
      where.dimensionNameValueList &&
      where.dimensionNameValueList.length > 0
    ) {
      data = data.map((row, index) => {
        row._key = `key_${index}`;
        return row;
      });
      data = filterBudgetPlannerCollectiveSummary(data, where);
    }
    return getSortedEntities(data, entireBusiness);
  },
  dashboardChartDataBP: async (context, data) => {
    // resetting data
    const dataObj = {
      error: false,
      load: true,
      timeSeriesData: [],
      legendData: [],
      noData: false
    };
    context.commit('DASHBOARD_CHART_DATA', dataObj);
    const { apiData, legendData } = getChartAPIDataPayload(context, data);
    try {
      await populateChartMetricsData(apiData, dataObj);
      await populateChartLegendsData(dataObj, legendData, data);
    } catch (err) {
      console.error(err);
    } finally {
      context.commit('DASHBOARD_CHART_DATA', dataObj);
    }
  },
  budgetPlannerDashboardActions: async (context, data) => {
    const obj = {
      load: true,
      error: false,
      noData: false
    };
    context.commit('BP_DASHBOARD_ACTIONS_STATUS', obj);
    try {
      for (let cubesToCall of data.body.cubesToCall) {
        data.body.APIConfig.cubeName = cubesToCall;
        const response = await HttpLayer.post({
          APIData: data.body.APIConfig,
          urlId: 'BUDGET_OPTIMIZER_NO_CACHE_CUBE'
        });
        if (!response.success) {
          // error if any success is false
          obj.error = true;
        } else if (response.data && response.data.length > 0) {
          const innerData = transformer.mergeResultDimension(response.data);
          obj[data.body.APIConfig.cubeName] = innerData;
        }
      }
    } catch (error) {
      obj.error = true;
    } finally {
      obj.load = false;
      context.commit('BP_DASHBOARD_ACTIONS_STATUS', obj);
    }
  },
  budgetPlanMTDProfilesTooltipContent: (context, data) => {
    // fetching dashoborad profile description for explainability tooltips.
    let mtdProfilesTooltipContent = [];
    HttpLayer.post({
      APIData: data.body.profileDescriptionAPIConfig
    })
      .then((response) => {
        mtdProfilesTooltipContent =
          response.success && response?.data.length > 0
            ? transformer.mergeResultDimension(response.data)
            : [];
      })
      .catch(() => {
        // ignoring the error, if this api fails it should not block the ui as its only tooltip data.
        mtdProfilesTooltipContent = [];
      })
      .finally(() => {
        context.commit(
          'BUDGET_PLAN_MTD_PROFILES_TOOLTIP_CONTENT',
          mtdProfilesTooltipContent
        );
      });
  },
  budgetPlannerSummaryColletiveData: async (context, data) => {
    const retailer = context?.rootGetters?.getRetailer;
    data.body.APIConfig = data.body.retailer[retailer].APIConfig;
    const dataObj = {
      error: false,
      load: true,
      rows: [],
      noData: false
    };

    context.commit('BUDGET_PLANNER_DASHBOARD_ENTIRE_BUSINESS', dataObj);
    // resetting widget where clause for download api as filtering is done on client.
    try {
      const { innerData, entireBusiness } = await getEntireBusinessData(
        retailer,
        data,
        dataObj,
        context.state.whereClause
      );
      context.commit('BUDGET_PLANNER_DASHBOARD_TABLE', innerData);
      dataObj.rows = entireBusiness;
    } catch (error) {
      console.error(error);
      dataObj.error = true;
    } finally {
      dataObj.load = false;
      context.commit('BUDGET_PLANNER_DASHBOARD_ENTIRE_BUSINESS', dataObj);
    }
    // setting where clause for download widget post the api call as filtering is happening on ui
    data.body.retailer[retailer].APIConfig.where =
      transformer.getCompleteWhereClause(
        data.meta.localFilters || [],
        context.state.whereClause
      );
  },
  createBudgetPlannerDashboardTableLevel1: (context, data) => {
    const dashboardTable = context.state.budgetPlannerDashboardTable;
    context.commit(
      'BUDGET_PLANNER_DASHBOARD_TABLE_LEVEL1',
      filterTableLevel1Data(data, dashboardTable) || []
    );
  },
  createBudgetPlannerDashboardTableLevel2: (context, data) => {
    const retailer = context?.rootGetters?.getRetailer;
    const dashboardTable = context.state.budgetPlannerDashboardTable;

    context.commit(
      'BUDGET_PLANNER_DASHBOARD_TABLE_LEVEL2',
      filterTableLevel2Data(retailer, data, dashboardTable) || []
    );
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
