<template>
  <div
    class="u-display-flex u-flex-wrap-yes u-height-100 u-width-100 u-flex-align-items-center"
  >
    <div
      v-for="(recType, index) in params.data.recommendationNameType"
      :key="index"
      class="u-flex-none u-flex-shrink-past-min-size rec-chip u-cursor-pointer u-font-size-6 u-spacing-p-xs u-spacing-m-xs"
      :title="'View Details'"
      @click.stop="chipClicked(recType)"
    >
      {{ recType.recommendationLabel }}
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'alertsTableChips',
  methods: {
    chipClicked(recType) {
      this.params.context.componentParent.selectAlertFromAll(
        this.params.data,
        recType,
        this
      );
    }
  }
};
</script>

<style lang="css" scoped>
.rec-chip {
  border: 1px solid #6a7075;
  line-height: 13px;
  border-radius: 2px;
  color: #6a7075;
}
.rec-chip:hover {
  color: white;
  background-color: #6a7075;
  border: 1px solid #6a7075;
}
.rec-chip:active {
  background-color: #4b5158;
}
</style>
