// import { isEqual } from 'lodash';
const hash = require('object-hash');
export const setInitialUnsavedState = (context) => {
  const localUnsavedState = {};
  const localDefaultState = setInitialSavedState(context);
  const unsavedState = getWidgetUnsavedState(context) || {};
  const defaultStateKeys = Object.keys(localDefaultState);
  defaultStateKeys.forEach((key) => {
    localUnsavedState[key] = unsavedState[key] || localDefaultState[key];
  });
  pushToStateStore(context, localUnsavedState);
};
export const setInitialSavedState = (context) => {
  const localDefaultState = {};
  const initialState = context.initialState?.[context.widgetName] || {};
  const defaultState = context.getDefaultState();
  const defaultStateKeys = Object.keys(defaultState);
  defaultStateKeys.forEach((key) => {
    localDefaultState[key] = initialState[key] || defaultState[key];
  });
  pushToStateStore(context, localDefaultState, 'pushToInitialState');
  return localDefaultState;
};

export const getUnsavedState = (
  context,
  storeKey = 'getUnsavedWidgetStates'
) => {
  return context.$store.getters[context.namespace + storeKey];
};

export const getWidgetUnsavedState = (context, storeKey) => {
  return getUnsavedState(context, storeKey)[context.widgetName];
};

export const pushToStateStore = (
  context,
  data,
  emit = 'pushToUnsavedState'
) => {
  context.$emit(emit, { [context.widgetName]: data });
};

export const setInitialState = (
  context,
  key,
  value,
  storeKey = 'getInitialWidgetStates',
  emit = 'pushToInitialState'
) => {
  const initialStateCopy = JSON.parse(
    JSON.stringify(getWidgetUnsavedState(context, storeKey))
  );
  setState(context, initialStateCopy, key, value, emit);
};

export const getStateCopy = (context) => {
  return JSON.parse(JSON.stringify(getWidgetUnsavedState(context)));
};

export const setUnsavedState = (context, key, value, storeKey) => {
  const unsavedStateCopy = JSON.parse(
    JSON.stringify(getWidgetUnsavedState(context))
  );
  setState(context, unsavedStateCopy, key, value, storeKey);
};

export const setState = (context, state, key, value, storeKey) => {
  state = {
    ...state,
    [key]: value
  };
  pushToStateStore(context, state, storeKey);
};
export const getUnsavedSettings = (
  context,
  storeKey = 'getUnsavedWidgetStates'
) => {
  return context.$store.getters[context.namespace + storeKey].settings;
};

export const compareObjects = (obj1, obj2) => {
  // console.log('initial', obj1);
  // console.log('unsaved', obj2);
  // // console.log(JSON.stringify(obj1));
  // Object.keys(obj1).forEach(key => {
  //   console.log(key, isEqual(obj1?.[key], obj2?.[key]));
  //   if (!isEqual(obj1?.[key], obj2?.[key])) {
  //     Object.keys(obj1?.[key]).forEach(widgetKey => {
  //       if (!isEqual(obj1?.[key]?.[widgetKey], obj2?.[key]?.[widgetKey])) {
  //         // console.log('obj1[key]', key, widgetKey);
  //         console.log('initial', key, widgetKey, obj1?.[key]?.[widgetKey]);
  //         console.log('unsaved', key, widgetKey, obj2?.[key]?.[widgetKey]);
  //       }
  //     });
  //   }
  // });
  const initialStateHash = hash(obj1);
  const unsavedStateHash = hash(obj2);
  return initialStateHash === unsavedStateHash;
};
