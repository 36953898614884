<template>
  <div class="u-display-flex u-flex-align-items-center u-spacing-mr-m">
    <span class="u-font-size-4 u-font-weight-600 u-color-grey-x-light">{{
      groupLabel
    }}</span>
    <div
      v-for="({ type, label, disabled = false }, index) in radioOptions"
      :key="index"
      class="global-radio-wrapper"
    >
      <div class="u-spacing-ml-s">
        <rb-radio
          v-model="currentRadio"
          :disabled="disabled"
          :native-value="type"
        >
          <div
            :class="{
              'u-font-weight-600': customLabel && type === selectedRadio,
              'u-font-weight-600 u-color-grey-mid-light':
                customLabel && type !== selectedRadio
            }"
          >
            {{ label }}
          </div>
        </rb-radio>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    radioOptions: {
      type: Array,
      required: true
    },
    groupLabel: {
      type: String,
      default: ''
    },
    customLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedRadio: this.value
    };
  },
  computed: {
    currentRadio: {
      get() {
        return this.value;
      },
      set(val) {
        this.selectedRadio = val;
      }
    }
  },
  watch: {
    selectedRadio(newVal) {
      this.$emit('onRadioChanged', newVal);
    }
  }
};
</script>
