var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"tippy",rawName:"v-tippy",value:({
    html: '#' + _vm.id,
    reactive: true,
    distance: 4,
    placement: 'bottom',
    interactive: true,
    theme: 'dropdown',
    trigger: 'click',
    duration: [0, 0],
    onShow: _vm.onShow,
    onHide: _vm.onHide
  }),expression:"{\n    html: '#' + id,\n    reactive: true,\n    distance: 4,\n    placement: 'bottom',\n    interactive: true,\n    theme: 'dropdown',\n    trigger: 'click',\n    duration: [0, 0],\n    onShow: onShow,\n    onHide: onHide\n  }"}],ref:"trigger"},[_vm._t("trigger",[(!_vm.showChip)?_c('div',{staticClass:"u-display-flex u-cursor-pointer"},[_c('rb-icon',{staticClass:"u-font-size-5 rb-icon--small u-spacing-mr-xs u-color-grey-lighter",attrs:{"icon":"add-circle-fill"}}),_c('div',{staticClass:"u-font-size-5 u-color-grey-lighter"},[_vm._v(" "+_vm._s(_vm.emptyStateTriggerText)+" ")])],1):_c('div',[_vm._t("rule-builder-chip",[_c('ruleBuilderChip',{attrs:{"pre-text-icon":_vm.preTextIcon,"chip-selections":_vm.chipSelections,"has-cross":_vm.chipHasCross,"pre-text":_vm.chipPreText,"chip-size":_vm.chipSize},on:{"crossClick":_vm.handleRuleBuilderChipCrossClick}},[_c('section',{attrs:{"slot":"before-cross"},slot:"before-cross"},[_vm._t("before-cross")],2)])])],2)]),_c('section',{staticClass:"u-border-radius-s",attrs:{"id":_vm.id}},[_c('ruleBuilderV2',{ref:"rule-builder",attrs:{"root":_vm.root,"load":_vm.load,"error":_vm.error,"no-data":_vm.noData},on:{"reset":_vm.handleRuleBuilderReset,"ruleApply":_vm.handleRuleApply}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }