import HttpService from '@/utils/services/http-service';
import moment from 'moment';

export default class SovCrawlTimes {
  widgetId;
  widget;
  page;
  pageId;
  globalViewId;

  data = {
    load: false,
    error: false,
    rows: [],
    noData: false
  };

  constructor(page, widget, pageId, globalViewId) {
    this.widget = widget;
    this.page = page;
    this.pageId = pageId;
    this.globalViewId = globalViewId;
  }

  async getData(request) {
    try {
      this.data = {
        ...this.data,
        load: true,
        error: false
      };
      request.widget = this.widget;
      request.page = this.page;
      request.globalViewId = this.globalViewId;
      request.pageId = this.pageId;
      const { data } = await HttpService.post(
        'DASHBOARD_SERVICE',
        request,
        { append: '/entity/metrics/data' },
        { widgetId: this.widgetId, hashingKeysToIgnore: ['where'] }
      );
      const { entityData } = data;
      if (entityData.length === 0) {
        this.data = {
          rows: [],
          error: false,
          load: false,
          noData: true
        };
      } else {
        let data = {};
        entityData.forEach((v) => {
          const crawlHourSliced = v.entityValue.slice(0, 5);
          if (!(crawlHourSliced in data)) {
            data[crawlHourSliced] = v;
          }
        });
        data = Object.values(data);
        const parsedData = data.map((v) => {
          return {
            ...v,
            title: moment(v.entityValue, 'HH:mm:ss').format('LT')
          };
        });
        this.data = {
          rows: parsedData,
          error: false,
          load: false,
          noData: false
        };
      }
    } catch (e) {
      if (HttpService.isCancelRequestError(e)) {
        return e;
      }
      this.data = {
        ...this.data,
        load: false,
        error: true
      };
    }
  }
}
