import Vue from 'vue';
import { formatter } from '@/utils/helpers/formatter.js';
import helper from '@/utils/helpers/index.js';

const skuLevelBreakDownColumns = [
  {
    title: 'SKU Details',
    key: 'sku_details',
    pinned: 'left',
    showToolTip: true,
    toolTipText: '',
    type: 'asin',
    hoverText: 'Add to Search',
    keys: {
      imageUrl: 'image_url',
      productUrl: 'asin_url',
      title: 'asin_title',
      asin: 'asin'
    },
    keyOrder: 1
  },
  {
    title: 'SKU Info',
    key: 'common_columns',
    keyOrder: 2,
    children: [
      {
        title: 'SKU number',
        key: 'sku_number',
        showToolTip: true,
        toolTipText: 'SKU Number of the ASIN',
        type: 'string',
        keyOrder: 1
      },
      {
        title: 'Weeks of Cover(CIQ)',
        key: 'current_weeks_of_coverage',
        showToolTip: true,
        toolTipText:
          'Improvised metric to calculate the number of weeks in which the inventory with Amazon will need replenishment. It is calculated by taking into account the Sellable on Hand Inventory, Direct Fulfillment Inventory (if present) and Open Purchase Orders which are either completed today or are completing in next two weeks as per AVC and dividing this with average sales velocity of shipped units in the last 4 weeks.',
        type: 'numeric',
        formatReq: true,
        keyOrder: 2
      }
    ]
  },
  {
    title: 'Aggregate Forecast',
    key: 'agregate_forecast',
    keyOrder: 2,
    children: [
      {
        title: 'Units',
        reactToKey: 'cases_or_units',
        showOn: 'Units',
        key: 'future_forecasted_units',
        showToolTip: true,
        toolTipText: 'Aggregate forecast listed in units',
        type: 'numeric',
        formatReq: true,
        keyOrder: 1
      },
      {
        title: 'Cases',
        reactToKey: 'cases_or_units',
        showOn: 'Cases',
        key: 'future_forecasted_cases',
        showToolTip: true,
        toolTipText: 'Aggregate forecast listed in cases',
        type: 'numeric',
        formatReq: true,
        keyOrder: 2
      },
      {
        title: 'Value',
        key: 'future_forecasted_value',
        showToolTip: true,
        toolTipText: '',
        type: 'numeric',
        unit: 'currency',
        formatReq: true,
        keyOrder: 3
      }
    ]
  },
  {
    title: 'Aggregate Past Performance',
    key: 'agregate_past_performance',
    group: 'past',
    keyOrder: 3,
    children: [
      {
        title: 'Forecast',
        reactToKey: 'cases_or_units',
        keys: {
          Units: 'PVP_past_forecasted_units',
          Cases: 'PVP_past_forecasted_cases'
        },
        key: '',
        showToolTip: true,
        toolTipText: '',
        type: 'numeric',
        formatReq: true,
        keyOrder: 1
      },
      {
        title: 'Consumption',
        reactToKey: 'cases_or_units',
        keys: {
          Units: 'PVP_past_consumption_units',
          Cases: 'PVP_past_consumption_cases'
        },
        key: '',
        showToolTip: true,
        toolTipText: '',
        type: 'numeric',
        formatReq: true,
        keyOrder: 2
      },
      {
        title: 'Purchase orders',
        reactToKey: 'cases_or_units',
        keys: {
          Units: 'PVP_past_purchase_order_units',
          Cases: 'PVP_past_purchase_order_cases'
        },
        key: '',
        showToolTip: true,
        toolTipText: '',
        type: 'numeric',
        formatReq: true,
        keyOrder: 3
      },
      {
        title: 'No of days OOS',
        key: 'PVP_past_oos_days',
        showToolTip: true,
        toolTipText: 'Number of days the SKU was OOS in the chosen time period',
        type: 'numeric',
        formatReq: true,
        keyOrder: 3
      }
    ]
  },
  {
    title: 'Accuracy Metrics (Forecast vs Consumption)',
    key: 'accuracy_metrics_forecast_consumption',
    group: 'past',
    keyOrder: 4,
    children: [
      {
        title: 'Wmape',
        // reactToKey: 'forecast_vs',
        // keys: {
        //   'Consumption': 'PVP_wape_forecast_vs_consumption',
        //   'Purchase': 'PVP_wape_forecast_vs_purchase_order'
        // },
        key: 'PVP_wape_forecast_vs_consumption',
        showToolTip: true,
        toolTipText:
          '<p>Weighted Mean Absolute Percentage Error:</p><p>Percentage error in forecast.</p><br/><p><span class="u-font-weight-600">Formula</span>: [Sum of (Actual-Forecast) * 100) ]  / ( Sum of Actuals ) for the chosen time period</p>',
        type: 'numeric',
        unit: 'percentage',
        formatReq: true,
        keyOrder: 1
      },
      {
        title: 'Mean absolute error',
        reactToKey: 'cases_or_units',
        reactToMultiKeys: true,
        keys: {
          Units: 'PVP_forecast_vs_consumption_mean_absolute_error_units',
          Cases: 'PVP_forecast_vs_consumption_mean_absolute_error_cases'
          // 'Purchase&&Units': 'PVP_forecast_vs_purchase_order_mean_absolute_error_units',
          // 'Purchase&&Cases': 'PVP_forecast_vs_purchase_order_mean_absolute_error_cases'
        },
        key: '',
        showToolTip: true,
        toolTipText:
          '<p>Average error in forecast.</p><br/><p><span class="u-font-weight-600">Formula</span>: Average of (Actual-Forecast) for the chosen time period</p>',
        type: 'numeric',
        formatReq: true,
        keyOrder: 2
      },
      {
        title: 'Tracking signal',
        // reactToKey: 'forecast_vs',
        // keys: {
        //   'Consumption': 'PVP_tracking_signal_forecast_vs_consumption',
        //   'Purchase': 'PVP_tracking_signal_forecast_vs_purchase_order'
        // },
        key: 'PVP_tracking_signal_forecast_vs_consumption',
        showToolTip: true,
        toolTipText:
          '<p>Indicates if there is repeated error in forecast over multiple periods.</p><br/><p>Value < -4 : repeated over-forecasting</p><p>Value > +4 : repeated under-forecasting</p><br/><p><span class="u-font-weight-600">Formula</span>: Sum of (Actual - Forecast)  / Average of (Forecast - Mean forecast)</p>',
        type: 'numeric',
        formatReq: true,
        keyOrder: 3
      },
      {
        title: 'Durbin watson metric',
        // reactToKey: 'forecast_vs',
        // keys: {
        //   'Consumption': 'PVP_durbin_watson_forecast_vs_consumption',
        //   'Purchase': 'PVP_durbin_watson_forecast_vs_purchase_order'
        // },
        key: 'PVP_durbin_watson_forecast_vs_consumption',
        showToolTip: true,
        toolTipText:
          '<p>Indicates if a forecast error will influence the next forecast.</p><br/><p>Value = 2 :  No influence</p><p>0 <= Value < 2 : Next forecast will have a similar error</p><p>2 < Value <= 4 : Next forecast will have a reverse error</p><br/><p><span class="u-font-weight-600">Formula</span>:  (Sum of difference in forecast error between two periods)<sup>2</sup> / (Sum of forecast errors)<sup>2</sup></p>',
        type: 'numeric',
        formatReq: true,
        keyOrder: 4
      }
    ]
  },
  {
    title: 'Accuracy Metrics (Forecast vs Purchase Orders)',
    key: 'accuracy_metrics_forecast_purchase',
    keyOrder: 4,
    group: 'past',
    children: [
      {
        title: 'Wmape',
        // reactToKey: 'forecast_vs',
        // keys: {
        //   'Consumption': 'PVP_wape_forecast_vs_consumption',
        //   'Purchase': 'PVP_wape_forecast_vs_purchase_order'
        // },
        key: 'PVP_wape_forecast_vs_purchase_order',
        showToolTip: true,
        toolTipText:
          '<p>Weighted Mean Absolute Percentage Error:</p><p>Percentage error in forecast.</p><br/><p><span class="u-font-weight-600">Formula</span>: [Sum of (Actual-Forecast) * 100) ]  / ( Sum of Actuals ) for the chosen time period</p>',
        type: 'numeric',
        unit: 'percentage',
        formatReq: true,
        keyOrder: 1
      },
      {
        title: 'Mean absolute error',
        reactToKey: 'cases_or_units',
        reactToMultiKeys: true,
        keys: {
          // 'Consumption&&Units': 'PVP_forecast_vs_consumption_mean_absolute_error_units',
          // 'Consumption&&Cases': 'PVP_forecast_vs_consumption_mean_absolute_error_cases',
          Units: 'PVP_forecast_vs_purchase_order_mean_absolute_error_units',
          Cases: 'PVP_forecast_vs_purchase_order_mean_absolute_error_cases'
        },
        key: '',
        showToolTip: true,
        toolTipText:
          '<p>Average error in forecast.</p><br/><p><span class="u-font-weight-600">Formula</span>: Average of (Actual-Forecast) for the chosen time period</p>',
        type: 'numeric',
        formatReq: true,
        keyOrder: 2
      },
      {
        title: 'Tracking signal',
        // reactToKey: 'forecast_vs',
        // keys: {
        //   'Consumption': 'PVP_tracking_signal_forecast_vs_consumption',
        //   'Purchase': 'PVP_tracking_signal_forecast_vs_purchase_order'
        // },
        key: 'PVP_tracking_signal_forecast_vs_purchase_order',
        showToolTip: true,
        toolTipText:
          '<p>Indicates if there is repeated error in forecast over multiple periods.</p><br/><p>Value < -4 : repeated over-forecasting</p><p>Value > +4 : repeated under-forecasting</p><br/><p><span class="u-font-weight-600">Formula</span>: Sum of (Actual - Forecast)  / Average of (Forecast - Mean forecast)</p>',
        type: 'numeric',
        formatReq: true,
        keyOrder: 3
      },
      {
        title: 'Durbin watson metric',
        // reactToKey: 'forecast_vs',
        // keys: {
        //   'Consumption': 'PVP_durbin_watson_forecast_vs_consumption',
        //   'Purchase': 'PVP_durbin_watson_forecast_vs_purchase_order'
        // },
        key: 'PVP_durbin_watson_forecast_vs_purchase_order',
        showToolTip: true,
        toolTipText:
          '<p>Indicates if a forecast error will influence the next forecast.</p><br/><p>Value = 2 :  No influence</p><p>0 <= Value < 2 : Next forecast will have a similar error</p><p>2 < Value <= 4 : Next forecast will have a reverse error</p><br/><p><span class="u-font-weight-600">Formula</span>:  (Sum of difference in forecast error between two periods)<sup>2</sup> / (Sum of forecast errors)<sup>2</sup></p>',
        type: 'numeric',
        formatReq: true,
        keyOrder: 4
      }
    ]
  }
];

// Generate Ag-grid Column Format for a single column
function generateColumnDef(column, vueRef) {
  const obj = {};
  obj.headerComponentFramework = 'genericTableHeader';
  let keyType = 'string';
  if (column.type === 'numeric') {
    keyType = column.unit ? column.unit : 'numeric';
  }
  if (column.type === 'date') {
    keyType = column.type;
  }
  obj.field = column.key;
  obj.cellRendererParams = {
    keyType: keyType,
    formatterFn: column.formatReq === false ? undefined : formatter
  };
  obj.cellRendererFramework = 'genericTableCell';
  obj.headerName = column.title;
  obj.title = column.title;
  obj.headerComponentParams = {
    enableSorting: true
  };
  if (column.hasOwnProperty('hide')) {
    obj.hide = column.hide;
  }
  if (column.type === 'asin') {
    obj.cellRendererFramework = 'skuTableComponentWithButton';
    obj.headerComponentParams.enableSorting = false;
    obj.cellRendererParams = column;
    obj.cellRendererParams.hoverTextClickEvent = vueRef.addToSearch;
    obj.minWidth = 300;
  }
  if (column.type !== 'asin') {
    obj.minWidth = helper.setMinWidth({ keyType: keyType });
  }
  obj.keyOrder = column.keyOrder;
  column.pinned && (obj.pinned = column.pinned);
  if (column.showToolTip) {
    obj.headerComponentParams.toolTipText = column.toolTipText;
    obj.headerComponentParams.removeSplit = true;
  }
  obj.headerComponentParams.keyType = keyType;
  return obj;
}

var columnDefs = [];
function getColumnDefinition(vueRef, tableKey, columns) {
  var _columnDefs = [];
  for (const i in columns) {
    let obj = {};
    if (columns[i].children && columns[i].children.length > 0) {
      obj.headerName = columns[i].title;
      obj.field = columns[i].key;
      obj.keyOrder = columns[i].keyOrder;
      obj.marryChildren = true;
      obj.children = [];
      for (const j in columns[i].children) {
        obj.children.push(generateColumnDef(columns[i].children[j], vueRef));
      }
    } else {
      obj = generateColumnDef(columns[i], vueRef);
    }
    _columnDefs.push(obj);
  }

  /**
   * Sort based on key order
   */
  columnDefs = _columnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });
  return columnDefs;
}

function getSearchDimension(config, data, that) {
  var _config = { ...config }; // new reference of properties and functions in config
  if (data !== undefined) {
    _config.meta.localFilters = [];
    if (data.length > 0) {
      _config.meta.localFilters.push({
        dimensionName: 'search',
        operator: 'ILIKE',
        dimensionValue: data
      });
    }
  }
  return _config;
}

export default {
  config: (that) => {
    return {
      filters: {
        emit: 'forecastFilterApplied',
        listen: {
          forecastFilterApplied: {
            action: 'updateForecastSelectedFilter'
          }
        },
        hasSearch: true,
        hideFilter: true,
        search: {
          enable: true,
          emit: 'forecastSearchTriggered',
          width: '240px',
          placeholder: 'Search ASIN or ASIN Name'
        }
      },
      widgets: {
        widget1: {
          meta: {
            widgetId: 'widget1',
            type: 'chart',
            hideOnNoData: false,
            width: 1,
            action: 'getForecastedUnits',
            key: 'ForecastedUnits',
            listen: {
              forecastFilterApplied: {
                action: 'getForecastedUnits'
              },
              forecastSearchTriggered: {
                action: 'getForecastedUnits',
                transform: function (config, data, that) {
                  return getSearchDimension(config, data, that);
                }
              },
              dropdownOptionsChanged: {
                action: 'getForecastedUnits'
              },
              calendarChanged: {
                action: 'getForecastedUnits',
                transform: function (config, options, that) {
                  var _config = { ...config };
                  if (options && options.calender_granuality) {
                    if (options.calender_granuality === 'Week') {
                      _config.body.APIConfig.timeseriesDimension =
                        'week_start_date';
                    } else if (options.calender_granuality === 'Month') {
                      _config.body.APIConfig.timeseriesDimension =
                        'month_start_date';
                    }
                  }
                  return _config;
                }
              }
            },
            localFilters: []
          },
          header: {
            show: true,
            title: 'FORECAST',
            tooltip: '',
            actions: {
              download: {
                includeDate: true,
                fileName: 'Forecast',
                allMap: [
                  {
                    displayName: 'Forecasted units',
                    dbKey: 'forecasted_units'
                  },
                  {
                    displayName: 'Forecasted cases',
                    dbKey: 'forecasted_cases'
                  },
                  {
                    displayName: 'Forecasted value',
                    dbKey: 'forecasted_value'
                  }
                ],
                colMap: [],
                action: 'downloadForecastedUnits',
                transform: function (config, data, that) {
                  var _config = { ...config };
                  return _config;
                }
              }
            }
          },
          footer: {
            date: 'v2_forecast'
          },
          body: {
            APIConfig: {
              cubeName: 'v2_forecast_widget',
              measuresList: [
                'forecasted_units',
                'forecasted_cases',
                'forecasted_value'
              ],
              groupByDimensionsList: [],
              timeseriesDimension: 'week_start_date',
              timeseriesEnabled: true,
              pvpenabled: false,
              yoyenabled: false,
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  [
                    'asin',
                    'forecast_date',
                    'feed_date',
                    'price_type',
                    'forecast_type'
                  ]
              },
              orderByList: [],
              where: {
                dimensionNameValueList: [],
                date: {}
              }
            },
            getTagData(data, mapObj) {
              var oReturn = {};
              for (var obj of data) {
                if (obj.RESULT && Object.keys(obj.RESULT).length > 0) {
                  for (var key in obj.RESULT) {
                    if (Object.keys(mapObj).indexOf(key) > -1) {
                      oReturn[key] = {
                        title: mapObj[key],
                        tag: {
                          tag1: obj.RESULT[key]
                        }
                      };
                    }
                  }
                }
              }
              return oReturn;
            },
            tagConfig: {
              Forecast: {
                tag1Unit: {
                  pre: ''
                }
              },
              Value: {
                tag1Unit: {
                  pre: 'currency'
                }
              }
            },
            chart: {
              chartOptions: {
                legend: false,
                type: 'line',
                xFormat: '%m/%d/%Y',
                timeseries: 'feed_date',
                tooltip_format: {
                  Value: {
                    pre: 'currency',
                    roundoff: 2
                  }
                },
                axis_format: {
                  y: {
                    show: true
                  }
                  // y2: {
                  //   show: true
                  // }
                },
                xDataKey: 'x',
                grid: 'xy'
              }
            },
            map: {
              forecasted_units: 'Forecasted Units',
              forecasted_cases: 'Forecasted Cases',
              forecasted_value: 'Value'
            },
            mapArr: [
              {
                title: 'Forecasted Units',
                reactToKey: 'cases_or_units',
                showOn: 'Units',
                key: 'forecasted_units'
              },
              {
                title: 'Forecasted Cases',
                reactToKey: 'cases_or_units',
                showOn: 'Cases',
                key: 'forecasted_cases'
              },
              {
                title: 'Value',
                reactToKey: null,
                showOn: null
              }
            ]
          }
        },
        widget2: {
          meta: {
            widgetId: 'widget2',
            type: 'chart',
            hideOnNoData: false,
            width: 1,
            action: 'getPastPerformance',
            key: 'PastPerformance',
            listen: {
              forecastFilterApplied: {
                action: 'getPastPerformance'
              },
              forecastSearchTriggered: {
                action: 'getPastPerformance',
                transform: function (config, data, that) {
                  return getSearchDimension(config, data, that);
                }
              },
              dropdownOptionsChanged: {
                action: 'getPastPerformance'
              },
              calendarChanged: {
                action: 'getPastPerformance',
                transform: function (config, options, that) {
                  // var _config = {...config};
                  var _config = Vue.util.extend({}, config);
                  if (options && options.calender_granuality) {
                    if (options.calender_granuality === 'Week') {
                      _config.body.APIConfig.timeseriesDimension =
                        'week_start_date';
                    } else if (options.calender_granuality === 'Month') {
                      _config.body.APIConfig.timeseriesDimension =
                        'month_start_date';
                    }
                  }
                  return _config;
                }
              }
            },
            localFilters: []
          },
          header: {
            show: true,
            title: 'PAST PERFORMANCE',
            tooltip: '',
            actions: {
              download: {
                includeDate: true,
                fileName: 'PastForecastPerformance',
                colMap: [
                  {
                    displayName: 'Start date',
                    dbKey: 'week_start_date'
                  }
                ],
                action: 'downloadPastPerformance',
                transform: function (config, data, that) {
                  var _config = { ...config };
                  return _config;
                }
              }
            }
          },
          footer: {
            date: 'v2_forecast'
          },
          body: {
            APIConfig: {
              cubeName: 'v2_forecast_widget',
              measuresList: [
                'forecasted_units',
                'consumption_units',
                'diff_forecasted_consumption_units',
                'purchase_order_units',
                'diff_forecasted_purchase_order_units',
                'forecasted_cases',
                'consumption_cases',
                'diff_forecasted_consumption_cases',
                'purchase_order_cases',
                'diff_forecasted_purchase_order_cases',
                'forecasted_value'
              ],
              groupByDimensionsList: [],
              timeseriesDimension: 'week_start_date',
              timeseriesEnabled: true,
              pvpenabled: false,
              yoyenabled: false,
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  [
                    'asin',
                    'forecast_date',
                    'feed_date',
                    'price_type',
                    'forecast_type'
                  ]
              },
              orderByList: [],
              where: {
                dimensionNameValueList: [],
                date: {}
              }
            },
            getTagData(data, mapObj) {
              var oReturn = {};
              for (var obj of data) {
                if (obj.RESULT && Object.keys(obj.RESULT).length > 0) {
                  for (var key in obj.RESULT) {
                    if (Object.keys(mapObj).indexOf(key) > -1) {
                      oReturn[key] = {
                        title: mapObj[key],
                        tag: {
                          tag1: obj.RESULT[key]
                        }
                      };
                    }
                  }
                }
              }
              return oReturn;
            },
            tagConfig: {
              Forecast: {
                tag1Unit: {
                  pre: ''
                }
              },
              Consumption: {
                tag1Unit: {
                  pre: ''
                }
              },
              'On Hand Inventory': {
                tag1Unit: {
                  pre: ''
                }
              },
              'Purchase Order': {
                tag1Unit: {
                  pre: ''
                }
              },
              'Difference [Forecast-Consumption]': {
                tag1Unit: {
                  pre: ''
                }
              },
              'Difference [Forecast-Purchase Orders]': {
                tag1Unit: {
                  pre: ''
                }
              }
            },
            chart: {
              chartOptions: {
                legend: false,
                type: 'line',
                xFormat: '%m/%d/%Y',
                timeseries: 'feed_date',
                tooltip_format: {},
                axis_format: {
                  y: {
                    show: true
                  },
                  y2: {
                    show: true,
                    roundoff: 0
                  }
                },
                xDataKey: 'x',
                grid: {
                  y: {
                    show: true,
                    lines: [
                      {
                        value: 0,
                        text: '',
                        axis: 'y2',
                        class: 'forecast-c3-ygrid-color'
                      }
                    ]
                  },
                  x: {
                    show: true
                  }
                }
              }
            },
            // Used for charts and metrics
            map: {
              forecasted_units: 'Forecast Units',
              forecasted_cases: 'Forecast Cases',
              consumption_units: 'Consumption Units',
              consumption_cases: 'Consumption Cases',
              purchase_order_units: 'Purchase Units',
              purchase_order_cases: 'Purchase Cases',
              units_at_amazon_weekly: 'On Hand Inventory Units',
              units_at_amazon_monthly: 'On Hand Inventory Units',
              cases_at_amazon_weekly: 'On Hand Inventory Cases',
              cases_at_amazon_monthly: 'On Hand Inventory Cases',
              diff_forecasted_consumption_units:
                'Diff[Forecast Units - Consumption Units]',
              diff_forecasted_consumption_cases:
                'Diff[Forecast Cases - Consumption Cases]',
              diff_forecasted_purchase_order_units:
                'Diff[Forecast Units - Purchase Units]',
              diff_forecasted_purchase_order_cases:
                'Diff[Forecast Cases - Purchase Cases]'
            },
            mapArr: [
              {
                title: 'Forecast Units',
                reactToKey: 'cases_or_units',
                key: 'forecasted_units',
                showOn: 'Units'
              },
              {
                title: 'Forecast Cases',
                reactToKey: 'cases_or_units',
                key: 'forecasted_cases',
                showOn: 'Cases'
              },
              {
                title: 'Consumption Units',
                // reactToKey: ['cases_or_units', 'forecast_vs'],
                reactToKey: 'cases_or_units',
                key: 'consumption_units',
                showOn: 'Units'
                // showOn: [
                //   {
                //     value: 'Units'
                //   },
                //   {
                //     value: 'Consumption'
                //   }
                // ]
              },
              {
                title: 'Consumption Cases',
                // reactToKey: ['cases_or_units', 'forecast_vs'],
                reactToKey: 'cases_or_units',
                key: 'consumption_cases',
                showOn: 'Cases'
                // showOn: [
                //   {
                //     value: 'Cases'
                //   },
                //   {
                //     value: 'Consumption'
                //   }
                // ]
              },
              {
                title: 'Purchase Units',
                // reactToKey: ['cases_or_units', 'forecast_vs'],
                reactToKey: 'cases_or_units',
                key: 'purchase_order_units',
                showOn: 'Units'
                // showOn: [
                //   {
                //     value: 'Units'
                //   },
                //   {
                //     value: 'Purchase'
                //   }
                // ]
              },
              {
                title: 'Purchase Cases',
                // reactToKey: ['cases_or_units', 'forecast_vs'],
                reactToKey: 'cases_or_units',
                key: 'purchase_order_cases',
                showOn: 'Cases'
                // showOn: [
                //   {
                //     value: 'Cases'
                //   },
                //   {
                //     value: 'Purchase'
                //   }
                // ]
              },
              {
                title: 'Difference [Forecast - Consumption]',
                // reactToKey: ['cases_or_units', 'forecast_vs'],
                reactToKey: 'cases_or_units',
                key: 'diff_forecasted_consumption_units',
                showOn: 'Units',
                // showOn: [
                //   {
                //     value: 'Units'
                //   },
                //   {
                //     value: 'Consumption'
                //   }
                // ],
                type: 'bar',
                axes: 'y2'
              },
              {
                title: 'Difference [Forecast - Consumption]',
                // reactToKey: ['cases_or_units', 'forecast_vs'],
                reactToKey: 'cases_or_units',
                key: 'diff_forecasted_consumption_cases',
                showOn: 'Cases',
                // showOn: [
                //   {
                //     value: 'Cases'
                //   },
                //   {
                //     value: 'Consumption'
                //   }
                // ],
                type: 'bar',
                axes: 'y2'
              },
              {
                title: 'Difference [Forecast - Purchase]',
                // reactToKey: ['cases_or_units', 'forecast_vs'],
                reactToKey: 'cases_or_units',
                key: 'diff_forecasted_purchase_order_units',
                showOn: 'Units',
                // showOn: [
                //   {
                //     value: 'Units'
                //   },
                //   {
                //     value: 'Purchase'
                //   }
                // ],
                type: 'bar',
                axes: 'y2'
              },
              {
                title: 'Difference [Forecast - Purchase]',
                // reactToKey: ['cases_or_units', 'forecast_vs'],
                reactToKey: 'cases_or_units',
                key: 'diff_forecasted_purchase_order_cases',
                showOn: 'Cases',
                // showOn: [
                //   {
                //     value: 'Cases'
                //   },
                //   {
                //     value: 'Purchase'
                //   }
                // ],
                type: 'bar',
                axes: 'y2'
              }
            ]
          }
        },
        widget3: {
          meta: {
            widgetId: 'widget3',
            type: 'metric',
            hideOnNoData: false,
            width: 1,
            action: 'getAccuracyMetricsData',
            key: 'accuracyMetrics',
            listen: {
              forecastFilterApplied: {
                action: 'getAccuracyMetricsData'
              },
              forecastSearchTriggered: {
                action: 'getAccuracyMetricsData',
                transform: function (config, data, that) {
                  return getSearchDimension(config, data, that);
                }
              },
              dropdownOptionsChanged: {
                action: 'getAccuracyMetricsData'
              },
              calendarChanged: {
                action: 'getAccuracyMetricsData',
                transform: function (config, options, that) {
                  var _config = { ...config };
                  // if (options && options.calender_granuality) {
                  //   if (options.calender_granuality === 'Week') {
                  //     _config.body.APIConfig.cubeName = 'v2_forecast_widget_week';
                  //   } else if (options.calender_granuality === 'Month') {
                  //     _config.body.APIConfig.cubeName = 'v2_forecast_widget_month';
                  //   }
                  // }
                  return _config;
                }
              }
            },
            localFilters: []
          },
          header: {
            title: '',
            actions: {
              download: {
                includeDate: true,
                fileName: 'PastForecastErrors',
                allMap: [
                  {
                    displayName: 'Wmape - consumption',
                    dbKey: 'wape_forecast_vs_consumption'
                  },
                  {
                    displayName: 'Wmape - purchase',
                    dbKey: 'wape_forecast_vs_purchase_order'
                  },
                  {
                    displayName: 'Mean revenue impact - consumption',
                    dbKey: 'forecast_vs_consumption_mean_revenue_impact'
                  },
                  {
                    displayName: 'Mean revenue impact - purchase',
                    dbKey: 'forecast_vs_purchase_order_mean_revenue_impact'
                  },
                  {
                    displayName: 'Tracking signal - consumption',
                    dbKey: 'tracking_signal_forecast_vs_consumption'
                  },
                  {
                    displayName: 'Tracking signal - purchase',
                    dbKey: 'tracking_signal_forecast_vs_purchase_order'
                  },
                  {
                    displayName: 'Durbin watson metric - consumption',
                    dbKey: 'durbin_watson_forecast_vs_consumption'
                  },
                  {
                    displayName: 'Durbin watson metric - purchase',
                    dbKey: 'durbin_watson_forecast_vs_purchase_order'
                  }
                ],
                colMap: [],
                action: 'downloadAccuracyMetrics',
                transform: function (config, data, that) {
                  var _config = { ...config };
                  return _config;
                }
              }
            }
          },
          footer: {
            date: 'v2_forecast'
          },
          body: {
            APIConfig: {
              // 'cubeName': 'v2_forecast_widget_week',
              cubeName: 'v2_forecast_widget',
              measuresList: [
                'tracking_signal_forecast_vs_consumption',
                'wape_forecast_vs_consumption',
                'forecast_vs_consumption_mean_revenue_impact',
                'durbin_watson_forecast_vs_consumption',
                'tracking_signal_forecast_vs_purchase_order',
                'wape_forecast_vs_purchase_order',
                'forecast_vs_purchase_order_mean_revenue_impact',
                'durbin_watson_forecast_vs_purchase_order'
              ],
              groupByDimensionsList: [],
              timeseriesEnabled: false,
              timeseriesDimension: 'forecast_date',
              pvpenabled: false,
              yoyenabled: false,
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  [
                    'asin',
                    'forecast_date',
                    'feed_date',
                    'price_type',
                    'forecast_type'
                  ]
              },
              orderByList: [],
              where: {
                dimensionNameValueList: [],
                date: {}
              }
            },
            getTagData(data, mapping) {
              var metrics = {};
              for (var i in data.RESULT) {
                metrics[i] = {
                  tag1: data.RESULT[i] || 0
                };
              }
              return metrics;
            },
            tagConfigsArr: [
              // wmape
              {
                tag1Unit: {
                  suff: '%',
                  roundoff: 0
                }
              },
              // mean error
              {
                tag1Unit: {
                  pre: 'currency'
                }
              },
              // tracking signal
              {
                tag1Unit: {
                  pre: '',
                  roundoff: 0
                }
              },
              // durbin watson metric
              {
                tag1Unit: {
                  pre: '',
                  suff: '',
                  roundoff: 1
                }
              }
            ]
          }
        },
        widget4: {
          meta: {
            widgetId: 'widget4',
            type: 'table',
            hideOnNoData: false,
            width: 1,
            action: 'getSKULevelBreakDownTableData',
            key: 'SKULevelBreakDown',
            listen: {
              forecastFilterApplied: {
                action: 'getSKULevelBreakDownTableData',
                transform: function (config, data, that) {
                  var _config = { ...config };
                  _config.body.APIConfig.page = 1;
                  return _config;
                }
              },
              forecastSearchTriggered: {
                action: 'getSKULevelBreakDownTableData',
                transform: function (config, data, that) {
                  var _config = { ...config };
                  _config.body.APIConfig.page = 1;
                  return getSearchDimension(_config, data, that);
                }
              },
              dropdownOptionsChanged: {
                action: 'getSKULevelBreakDownTableData',
                transform: function (config, data, that) {
                  var _config = { ...config };
                  _config.body.APIConfig.page = 1;
                  return _config;
                }
              },
              calendarChanged: {
                action: 'getSKULevelBreakDownTableData',
                transform: function (config, options, that) {
                  var _config = { ...config };
                  if (options && options.calender_granuality) {
                    if (options.calender_granuality === 'Week') {
                      _config.body.APIConfig.cubeName =
                        'v2_forecast_widget_week';
                    } else if (options.calender_granuality === 'Month') {
                      _config.body.APIConfig.cubeName =
                        'v2_forecast_widget_month';
                    }
                  }
                  _config.body.APIConfig.page = 1;
                  return _config;
                }
              }
            },
            localFilters: []
          },
          header: {
            search: true,
            searchPlaceholder: 'Search',
            searchParams: ['search'],
            title: 'SKU LEVEL BREAKDOWN',
            actions: {
              download: {
                includeDate: true,
                fileName: 'ForecastForSKUs',
                allMap: [
                  {
                    displayName: 'SKU',
                    dbKey: 'asin'
                  },
                  {
                    displayName: 'SKU Title',
                    dbKey: 'asin_title'
                  },
                  {
                    displayName: 'Product Page URL',
                    dbKey: 'asin_url'
                  },
                  {
                    displayName: 'SKU Number',
                    dbKey: 'sku_number'
                  },
                  {
                    displayName: 'Weeks of coverage',
                    dbKey: 'current_weeks_of_coverage'
                  },
                  {
                    displayName: 'Aggregate forecasted units',
                    dbKey: 'future_forecasted_units'
                  },
                  {
                    displayName: 'Aggregate forecasted cases',
                    dbKey: 'future_forecasted_cases'
                  },
                  {
                    displayName: 'Aggregate forecast value',
                    dbKey: 'future_forecasted_value'
                  },
                  {
                    displayName: 'Past forecast in units',
                    dbKey: 'PVP_past_forecasted_units'
                  },
                  {
                    displayName: 'Past forecast in cases',
                    dbKey: 'PVP_past_forecasted_cases'
                  },
                  {
                    displayName: 'Past consumption in units',
                    dbKey: 'PVP_past_consumption_units'
                  },
                  {
                    displayName: 'Past consumption in cases',
                    dbKey: 'PVP_past_consumption_cases'
                  },
                  {
                    displayName: 'Past purchase in units',
                    dbKey: 'PVP_past_purchase_order_units'
                  },
                  {
                    displayName: 'Past purchase in cases',
                    dbKey: 'PVP_past_purchase_order_cases'
                  },
                  {
                    displayName: 'Days OOS',
                    dbKey: 'PVP_past_oos_days'
                  },
                  {
                    displayName: 'Wmape - consumption',
                    dbKey: 'PVP_wape_forecast_vs_consumption'
                  },
                  {
                    displayName: 'Wmape - purchase',
                    dbKey: 'PVP_wape_forecast_vs_purchase_order'
                  },
                  {
                    displayName: 'Mean absolute error in units- purchase',
                    dbKey:
                      'PVP_forecast_vs_purchase_order_mean_absolute_error_units'
                  },
                  {
                    displayName: 'Mean absolute error in cases - purchase',
                    dbKey:
                      'PVP_forecast_vs_purchase_order_mean_absolute_error_cases'
                  },
                  {
                    displayName: 'Mean absolute error in units - consumption',
                    dbKey:
                      'PVP_forecast_vs_consumption_mean_absolute_error_units'
                  },
                  {
                    displayName: 'Mean absolute error in cases- consumption',
                    dbKey:
                      'PVP_forecast_vs_consumption_mean_absolute_error_cases'
                  },
                  {
                    displayName: 'Tracking signal - consumption',
                    dbKey: 'PVP_tracking_signal_forecast_vs_consumption'
                  },
                  {
                    displayName: 'Tracking signal - purchase',
                    dbKey: 'PVP_tracking_signal_forecast_vs_purchase_order'
                  },
                  {
                    displayName: 'Durbin watson metric - consumption',
                    dbKey: 'PVP_durbin_watson_forecast_vs_consumption'
                  },
                  {
                    displayName: 'Durbin watson metric - purchase',
                    dbKey: 'PVP_durbin_watson_forecast_vs_purchase_order'
                  }
                ],
                colMap: [],
                action: 'downloadSKULevelBreakDownTableData',
                transform: function (config, data, that) {
                  var _config = Object.assign({}, config);
                  _config = JSON.parse(JSON.stringify(_config));
                  _config.body.APIConfig.page = undefined;
                  _config.body.APIConfig.limit = undefined;
                  return _config;
                }
              }
            }
          },
          footer: {
            date: 'v2_forecast'
          },
          body: {
            defaultOrderBy: [
              // {
              //   'dimension': 'asin',
              //   'direction': 'ASC'
              // }
              {
                type: 'DIMENSION',
                measureType: 'DIMENSION',
                measureName: 'asin',
                direction: 'DESC'
              }
            ],
            APIConfig: {
              cubeName: 'v2_forecast_widget_week',
              limit: 10,
              page: 1,
              customAPIDecisionVars: {
                additionalWhereClause: false,
                skipLimitAndOffset: false,
                enableForecastSkuLevelWidget: true
              },
              measuresList: [
                'wape_forecast_vs_consumption',
                'forecast_vs_consumption_mean_revenue_impact',

                'forecast_vs_consumption_mean_absolute_error_units',
                'forecast_vs_consumption_mean_absolute_error_cases',
                'forecast_vs_purchase_order_mean_absolute_error_units',
                'forecast_vs_purchase_order_mean_absolute_error_cases',

                'durbin_watson_forecast_vs_consumption',
                'tracking_signal_forecast_vs_purchase_order',
                'tracking_signal_forecast_vs_consumption',
                'wape_forecast_vs_purchase_order',
                'forecast_vs_purchase_order_mean_revenue_impact',
                'durbin_watson_forecast_vs_purchase_order',
                'past_forecasted_units',
                'past_consumption_units',
                'past_purchase_order_units',
                'past_forecasted_cases',
                'past_consumption_cases',
                'past_purchase_order_cases',
                'past_forecasted_value',
                'past_oos_days',
                'current_weeks_of_coverage',
                'past_units_in_hand',
                'future_forecasted_units',
                'future_forecasted_cases',
                'future_forecasted_value',
                'asin_url',
                'image_url',
                'asin_title',
                'count'
              ],
              groupByDimensionsList: ['asin', 'sku_number'],
              timeseriesEnabled: false,
              timeseriesDimension: 'forecast_date',
              pvpenabled: false,
              yoyenabled: false,
              dedupBeforeRollup: {
                enableDedupBeforeRollup: true,
                additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions:
                  [
                    'asin',
                    'forecast_date',
                    'feed_date',
                    'price_type',
                    'forecast_type'
                  ]
              },
              // 'orderByList': [
              //   {
              //     'dimension': 'future_forecasted_value',
              //     'direction': 'DESC'
              //   },
              //   {
              //     'dimension': 'asin',
              //     'direction': 'ASC'
              //   }
              // ],
              customOrderAndSortList: [
                {
                  type: 'RESULT',
                  measureType: 'RESULT',
                  measureName: 'future_forecasted_value',
                  direction: 'DESC'
                },
                {
                  type: 'DIMENSION',
                  measureType: 'DIMENSION',
                  measureName: 'asin',
                  direction: 'DESC'
                }
              ],
              where: {
                dimensionNameValueList: [],
                pvpDate: {},
                date: {}
              }
            }
          }
        }
      }
    };
  },
  tableColumns: skuLevelBreakDownColumns,
  getColumnDefs: getColumnDefinition
};
