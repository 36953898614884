import { VueConstructor } from 'vue';
// @ts-ignore
import HttpService from '@/utils/services/http-service';
import moment from 'moment-timezone';
// @ts-ignore
import { store } from '@/store/store';
import { cloneDeep } from 'lodash';
import {
  Where,
  CustomerFilter,
  FilterData,
  DimensionNameValueList,
  CategorySelectionList,
  ConstructedPaths,
  AttributeType,
  AttributeData,
  SelectionData,
  AttributeOption,
  AttributeDropdownData,
  SelectedCategoryLevelList,
  ThreePSelection,
  FilterStatusData,
  GlobalSettings,
  V2Metrics,
  DisplayShareTypes,
  CategoryList,
  CategoryChildList,
  MetaData,
  BreakdownTableItem
} from '@/components/pages/insights/amazon/market-share/types/filters';
import { CustomColDef } from '@/components/pages/insights/amazon/market-share/types/column';
import {
  DataApiRequestFormat,
  Operations,
  OrderByList,
  SkuCheckBox
} from '@/components/pages/insights/amazon/market-share/service/data-api';
import {
  Metric as TMetric,
  MetaDataReadApi
} from '@/components/pages/insights/amazon/market-share/service/meta-data';
import { AxiosResponse } from 'axios';

/** @constant
    @type {object}
*/
export const constants = {
  CATEGORY: 'category',
  SUBCATEGORY: 'subcategory',
  NESTED_SETTINGS: 'nestedSettings',
  NAVIGATE_FROM: 'navigateFrom',
  CATEGORY_HIERARCHY: 'category_hierarchy',
  DATE_RANGE: 'date_range',
  FILTER_SELECTION: 'filterSelection',
  ATTRIBUTES: 'attributesv1',
  OVERVIEW_CATEGORY_HIERARCHY: 'overview_category_hierarchy',
  SHOWED_BENCHMARKING_COLUMNS: 'showedBenchmarkingColumns',
  BENCHMARK_BRANDS_LIST: 'benchmarkBrandsList',
  DISPLAYED_METRICS: 'displayedMetrics',
  SKU_CHECKBOX: 'skuCheckBox',
  SORTING_OBJECT: 'sortingObject',
  THREE_P_GROUP_FLAG: 'three_p_group_flag',
  V2_INIT: 'v2_init',
  mslPieCharWidgetName: 'msl_pieChart',
  pieCharWidgetName: 'pieChart',
  mslBenchmarkingWidgetName: 'msl_benchMarking',
  benchmarkingWidgetName: 'ms_benchMarking',
  mslActualExclude3P: 'msl_actual_exclude_3p',
  actualExclude3P: 'ms_actual_exclude_3p',
  mslActual: 'msl_actual',
  msActual: 'ms-actual',
  marketSharePages: ['market share', 'market share lite'],
  benchmarkingDownloadTippyTitle:
    'Download Excel with total Market Share by category share.',
  shareSummaryDownloadTippyTitle:
    'Download Excel with week level Market Share for the selected Manufacturer and time period.',
  dateMode: { mode: 'week' } // This is added for lastWeek to work with "same period last year" selection as per week by week.
};

export const overviewConstants = {
  mslNamespace: 'marketShareOverview-lite/',
  mslPage: 'MARKETSHARE-LITE-EXECUTIVE',
  mslFilterLocalStorageKey: 'marketsharelite-overview-filters',
  mslDiffDate: 'MARKETSHARELITE_DIFF_DATE',
  mslShareSummaryWidget: 'msl_categoryShareSummary_executive',
  mslPageName: 'marketShareOverview_lite',
  mslMetricList: {
    MSL_UNIT_ESTIMATES: 'actual_unit_marketsharelite',
    MSL_SALES_ESTIMATES: 'actual_sales_marketsharelite'
  },
  namespace: 'marketShareOverview/',
  page: 'MARKETSHARE-EXECUTIVE',
  filterLocalStorageKey: 'marketshare-overview-filters',
  diffDate: 'MARKETSHARE_DIFF_DATE',
  shareSummaryWidget: 'categoryShareSummary_executive',
  pageName: 'marketShareOverview',
  filterEmit: 'marketshare-overview-filter-apply',
  metricList: {
    UNIT_ESTIMATES: 'actual_unit_marketshare',
    SALES_ESTIMATES: 'actual_sales_marketshare'
  }
};

export const categoryConstants = {
  mslShareSummaryWidget: 'msl_categoryShareSummary_executive',
  mslNamespace: 'marketshare-category-lite/',
  mslPage: 'MARKETSHARE-LITE-CATEGORY',
  mslFilterLocalStorageKey: 'marketsharelite-category-filters',
  mslPageName: 'category-page-lite',
  mslDiffDate: 'MARKETSHARELITE_DIFF_DATE',
  mslExecPage: 'marketShareOverview_lite',
  mslCategoryShareSummary: 'msl_categoryShareSummary_category',
  mslBenchmarkWidget: 'msl_benchmarkBrands',
  mslMetricList: {
    actual_unit_marketsharelite: 'MSL_UNIT_ESTIMATES',
    actual_sales_marketsharelite: 'MSL_SALES_ESTIMATES'
  },
  benchmarkWidget: 'ms_benchmarkBrands',
  categoryShareSummary: 'categoryShareSummary_category',
  shareSummaryWidget: 'categoryShareSummary_executive',
  namespace: 'marketshare-category/',
  page: 'MARKETSHARE-CATEGORY',
  filterLocalStorageKey: 'marketshare-category-filters',
  pageName: 'category-page',
  diffDate: 'MARKETSHARE_DIFF_DATE',
  execPage: 'marketShareOverview',
  metricList: {
    actual_unit_marketshare: 'UNIT_ESTIMATES',
    actual_sales_marketshare: 'SALES_ESTIMATES'
  }
};

export function getCurrencyName(Vue: VueConstructor): string | undefined {
  const locale = 'en';
  const currencyCode = (Vue as any).options.filters.config_check(
    'feature.i18n.currency'
  );
  let currencyDisplayName: string | undefined = currencyCode
    ? new Intl.DisplayNames(locale, { type: 'currency' }).of(currencyCode)
    : undefined;
  if (currencyDisplayName && !currencyDisplayName.endsWith('s')) {
    currencyDisplayName = currencyDisplayName + 's';
  }
  return currencyDisplayName ?? 'Dollars';
}

export function isMarketShareLite() {
  return window.location.pathname.includes('market_share_lite');
}

export function updatePayloadWithNodeId(
  dimensionNameValueList: DimensionNameValueList[],
  categoryNodeMap: AttributeData
) {
  return dimensionNameValueList.map((dimension: any) => {
    if (categoryNodeMap[dimension.dimensionValue]) {
      dimension.dimensionValue = categoryNodeMap[dimension.dimensionValue];
    }
    return dimension;
  });
}

export function getShareSummaryWidget(page: string) {
  const pageConstant =
    page === 'categoryPage' ? categoryConstants : overviewConstants;
  const widget = isMarketShareLite()
    ? pageConstant.mslShareSummaryWidget
    : pageConstant.shareSummaryWidget;
  return function (widgetName: string) {
    return (
      widgetName === widget ||
      widgetName === widget + (asinLevelV2ConfigEnabled() ? '_v2' : '')
    );
  };
}

export function getActionIcons(downloadTooltip: string) {
  return isMarketShareLite()
    ? []
    : [
        {
          emit: 'download',
          icon: 'download',
          loading: false,
          error: false,
          tippyTitle: downloadTooltip || 'Download report'
        }
      ];
}

export function getCategoryPageWidget() {
  const widget = isMarketShareLite()
    ? categoryConstants.mslCategoryShareSummary
    : categoryConstants.categoryShareSummary;
  return function (widgetName: string) {
    return (
      widgetName === widget ||
      widgetName === widget + (asinLevelV2ConfigEnabled() ? '_v2' : '')
    );
  };
}

export function getMetric(page: string) {
  const getIsLite = isMarketShareLite();
  if (page === 'category') {
    return getIsLite
      ? categoryConstants.mslMetricList
      : categoryConstants.metricList;
  } else {
    return getIsLite
      ? overviewConstants.mslMetricList
      : overviewConstants.metricList;
  }
}

export function getMetricName() {
  const getIsLite = isMarketShareLite();
  return {
    salesMetricNameComp: getIsLite
      ? 'actual_sales_exclude_3P_all_sales_marketsharelite'
      : 'actual_sales_exclude_3P_all_sales_marketshare',
    salesMetricName: getIsLite
      ? 'msl_actual_sales_exclude_3P_from_my_share_and_market'
      : 'actual_sales_exclude_3P_from_my_share_and_market',
    unitMetricNameComp: getIsLite
      ? 'actual_unit_exclude_3P_all_sales_marketsharelite'
      : 'actual_unit_exclude_3P_all_sales_marketshare',
    unitMetricName: getIsLite
      ? 'msl_actual_unit_exclude_3P_from_my_share_and_market'
      : 'actual_unit_exclude_3P_from_my_share_and_market'
  };
}

function hashCode(str: string) {
  let hash = 0;
  if (str) {
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }
  }
  return hash;
}

function intToHex(i: number) {
  const color = (i & 0x00ffffff).toString(16).toUpperCase();
  return '00000'.substring(0, 6 - color.length) + color;
}

function getColorHex(text: string) {
  const generatedHashCode = hashCode(text);
  return intToHex(generatedHashCode);
}

export function getLocalStorageKey() {
  return isMarketShareLite() ? 'globalSettingForMSL' : 'globalSettingForMS';
}

export function computedStyles(size: string) {
  if (size === 'm') {
    return {
      legendsMinHeight: '166px',
      legendSize: 'm',
      legendDonutSpacing: 's',
      donutDimensions: {
        height: 132,
        width: 132
      }
    };
  } else if (size === 'xs') {
    return {
      width: 107,
      donutDimensions: {
        height: 107,
        width: 107
      }
    };
  }
  return {
    legendsMinHeight: '176px',
    legendSize: 'l',
    legendDonutSpacing: 'l',
    donutDimensions: {
      height: 158,
      width: 152
    }
  };
}

const colorPallete = [
  { hex: '#007cf6' },
  { hex: '#3ea95e' },
  { hex: '#ffd500' },
  { hex: '#23b5d3' },
  { hex: '#97cc04' },
  { hex: '#f7981c' },
  { hex: '#ff6072' },
  { hex: '#bd10e0' },
  { hex: '#d7263d' },
  { hex: '#4f14e3' },
  { hex: '#73ff61' },
  { hex: '#eefa0a' },
  { hex: '#61ffed' },
  { hex: '#c2ff17' },
  { hex: '#f73edf' },
  { hex: '#8a0cff' },
  { hex: '#ff0000' },
  { hex: '#9e9cff' },
  { hex: '#a7dfb8' },
  { hex: '#fffda4' },
  { hex: '#b6f1e9' },
  { hex: '#c4fff7' },
  { hex: '#02e0a9' },
  { hex: '#ff74ab' },
  { hex: '#bc73ff' },
  { hex: '#83b7eb' },
  { hex: '#008b8b' },
  { hex: '#05d3fc' },
  { hex: '#c4a837' },
  { hex: '#6b5941' },
  { hex: '#a81890' },
  { hex: '#8c7382' },
  { hex: '#7c1f1a' }
];

export const colorPalleteIndexed = [
  ...colorPallete.map((x) => x.hex),
  ...[...Array(1000).keys()]
    .map((x) => x.toString())
    .map(getColorHex)
    .map((x) => '#' + x)
];

export const getAdditionalDateRanges = (
  startDate: moment.Moment,
  endDate: moment.Moment
) => {
  const additionalRanges: {
    add: string[];
    overrideRange: string[];
    disabledRange: string[];
    order: any;
  } = {
    add: [],
    overrideRange: [],
    disabledRange: [],
    order: {}
  };
  /** Array containing the thresholds for date range selection */
  const weeksArray = [0, 3, 12, 25, 51];
  weeksArray.forEach((week, index) => {
    if (Math.abs(startDate.diff(endDate, 'week')) >= week) {
      if (week === 0) {
        additionalRanges.add.push('lastWeek');
        additionalRanges.overrideRange.push('lastWeek');
        additionalRanges.order.lastWeek = 3;
      } else {
        const keyText = `last${week + 1}Week`;
        additionalRanges.add.push(keyText);
        additionalRanges.overrideRange.push(keyText);
        additionalRanges.order[keyText] = index + 3;
      }
    } else {
      const keyText = `last${week + 1}Week`;
      additionalRanges.add.push(keyText);
      additionalRanges.disabledRange.push(keyText);
      additionalRanges.order[keyText] = index + 3;
    }
  });
  return additionalRanges;
};

export const getObjectKey = (obj: any, searchText: string) => {
  let foundKey = '';
  Object.keys(obj || {}).forEach((key) => {
    if (searchText.toLowerCase() === key.toLowerCase()) {
      foundKey = key;
    }
  });
  return foundKey;
};
// https://boomerang.atlassian.net/wiki/spaces/~887506052/pages/2025553961/Approach+Docs
export const getFormattedSubCategory = (
  subCategory: any,
  categoryObject: any
) => {
  const returnArr = Object.keys(subCategory).map((key) => {
    const words = key.split('----');
    // category name which is lower-case
    let categoryName = words[0];
    words.shift();
    // getting the actual category name which is in the category object
    categoryName = getObjectKey(categoryObject, categoryName);
    return `${categoryName}----${words.join('----')}`;
  });
  return returnArr;
};

const restrictedRanges = ['last13Week', 'last26Week', 'last52Week'];
export const getHighestSelectableRange = (
  calenarRangeOrder: any,
  availableDateRange: string[]
) => {
  // availableDateRange is list of ranges for which are selectable
  // while calculating highestSelectableRange we will also check if that range is selectable also
  let highestSelectableRange = 'lastWeek';
  const ranges = Object.keys(calenarRangeOrder);
  for (let range of ranges) {
    // Select the highest possible range
    if (restrictedRanges.includes(range)) {
      continue;
    } else if (
      calenarRangeOrder[range] > calenarRangeOrder[highestSelectableRange] &&
      availableDateRange.includes(range)
    ) {
      highestSelectableRange = range;
    }
  }
  return highestSelectableRange;
};

// returns the entire globalSettingForMS or specific settings as requested over parameter
export const getGlobalSettingsForMS = (
  requestedFilter?: string,
  categorySelectionList?: CategorySelectionList,
  v2?: boolean
): any => {
  let globalSettingForMS: GlobalSettings = JSON.parse(
    localStorage.getItem(getLocalStorageKey()) || '{}'
  );
  const asinV2 = v2 !== undefined ? v2 : asinLevelV2ConfigEnabled(); // this check is required to override config value
  // If we are using v2 APIs in that case we have to convert the params stored in local storage to the ones that are acceptable by v2 version
  if (asinV2) {
    globalSettingForMS = convertV1ParamsToV2(globalSettingForMS);
    if (globalSettingForMS?.where?.dimensionNameValueList) {
      globalSettingForMS.where.dimensionNameValueList =
        globalSettingForMS?.where?.dimensionNameValueList.filter(
          (dimension) => {
            return dimension.dimensionName !== 'three_p_group_flag';
          }
        );
    }
  }
  // to check whether subcategory exists in our backend
  if (globalSettingForMS?.subcategory && categorySelectionList) {
    const getGlobalFilter = globalSettingForMS.subcategory;
    for (const selectedCategory in getGlobalFilter) {
      if (!categorySelectionList[selectedCategory.toLowerCase()]) {
        delete getGlobalFilter?.subcategory?.[selectedCategory];
      }
    }
  }
  // end of business logic
  if (requestedFilter) {
    return globalSettingForMS[requestedFilter];
  } else {
    return globalSettingForMS;
  }
};

// sets the key and value in localStorage key as passed
// key and value - are used in order to assign some new setting or update the setting of MS in localStorage
// key and reset - is used to delete any settings of MS from localStorage
// updateData - is used to add entire object to the MS global settings
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setGlobalSettingsForMS = (
  key: string,
  value: any,
  reset = false
) => {
  const globalSettingForMS = getGlobalSettingsForMS(
    undefined,
    undefined,
    false
  );
  // if key is null (add the value parameter as object to the existing settings)
  // else assign key and value to the localStorage
  if (key === null) {
    localStorage.setItem(
      getLocalStorageKey(),
      JSON.stringify({ ...globalSettingForMS, ...value })
    );
  } else {
    if (reset) {
      if (globalSettingForMS?.[key]) {
        delete globalSettingForMS[key];
      }
    } else {
      globalSettingForMS[key] = value;
    }
    localStorage.setItem(
      getLocalStorageKey(),
      JSON.stringify(globalSettingForMS)
    );
  }
};

// removes globalSettingForMS from localStorage
export const removeGlobalSettingsForMS = () => {
  localStorage.removeItem(getLocalStorageKey());
};

// returns the transformed list of subcategory for default selection in the subcategory dropdown
export const getTransformedSubcategory = () => {
  const globalSettingForMS = getGlobalSettingsForMS();
  if (globalSettingForMS?.subcategory) {
    let selectedSubCategory = Object.keys(globalSettingForMS.subcategory);
    // loop over list of subcategory
    selectedSubCategory = selectedSubCategory.map((item) => {
      // removal of parent category from each subcategory
      // eg: removal of "beverages----" from the hierarchy (beverages----Ready-to-Drinky)
      const [, ...subcagtegory] = item.split('----');
      item = subcagtegory.join('----');
      return item;
    });
    return selectedSubCategory;
  }
};

// mapping of global settings (keys and values) to local settings (keys and values)
// settingsData - receives the object over which data mapping has to be done
// configuration - receives the configuration object which is referred for mapping data
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const configurationSetting = (
  settingsData: any,
  configuration: any
): any => {
  for (const configItem in configuration) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const configItemData: any = configuration?.[configItem];
    if (settingsData?.[configItem]) {
      for (const replaceIdentifier in configItemData) {
        if (
          configItemData?.[replaceIdentifier]?.[
            settingsData?.[configItem]?.[replaceIdentifier]
          ]
        ) {
          settingsData[configItem][replaceIdentifier] =
            configItemData[replaceIdentifier][
              settingsData[configItem][replaceIdentifier]
            ];
        }
      }
    }
  }
  return settingsData;
};

// returns all the selections paths that can be possible
export const filterHierarchyPaths = (
  filterList: FilterData['category'],
  pathList: CategorySelectionList,
  key: string
) => {
  for (const filter in filterList) {
    const _key = key ? key + '----' + filter : filter;
    // adding of the path key in all the attributes as it is required at the time of forming the payload
    if (filterList?.[filter]?.attributes?.length) {
      filterList[filter].attributes.map((item: AttributeType) => {
        item.path = [_key.toLowerCase()];
      });
    }
    // assigning the selection combination with filter attributes
    pathList[_key.toLowerCase()] = filterList[filter].attributes;
    // check if we have children for the filter
    if (Object.keys(filterList[filter].children)) {
      filterHierarchyPaths(filterList[filter].children, pathList, _key);
    }
  }
  return pathList;
};

/**
 * @description returns the map of all possible category selections with their nodeIds
 * @param {FilterData} filterList - list of category and subcategory
 * @param {CategoryList} pathList - takes empty object
 * @return {object} pathList - map of all possible selection paths of category/subcategory with nodeId
 */
export const NodeIdMap = (
  filterList: FilterData['category'],
  pathList: CategoryList,
  key: string
) => {
  for (const filter in filterList) {
    const _key = key ? key + '~' + filter : filter;
    pathList[_key.toLowerCase() + '~'] = filterList[filter].id as string;
    if (Object.keys(filterList[filter].children)) {
      NodeIdMap(filterList[filter].children, pathList, _key);
    }
  }
  return pathList;
};

/**
 * @description construct multiple paths from selected hierarchy path
 * @param {object} categoryHierarchyPath - have the category and subcategory hierarchy selection
 * @param {object} constructedPaths - new paths will be formed and added to this object
 * @return {object} constructedPaths
 */
export const constructPaths = (
  categoryHierarchyPath: string,
  constructedPaths: ConstructedPaths
) => {
  const selectedNodes = categoryHierarchyPath.split('----');
  let previousPath = '';
  selectedNodes.map((selections) => {
    previousPath =
      previousPath +
      (previousPath !== ''
        ? '----' + selections.toLowerCase()
        : selections.toLowerCase());
    constructedPaths[previousPath] = true;
  });
  return constructedPaths;
};

/**
 * @description returns the array of attributes based on filter selection
 * @param {object} selectedPath - have the category and subcategory selection
 * @param {object} categorySelectionList - have list of all the happy path for selections
 * @return {array} totalAttributes
 */
export const getAttributeList = (
  selectedPath: object,
  categorySelectionList: CategorySelectionList
) => {
  let constructedPaths = {};
  let attributesSelection = getGlobalSettingsForMS()?.[constants.ATTRIBUTES];
  for (const categoryHierarchyPath in selectedPath) {
    constructedPaths = constructPaths(categoryHierarchyPath, constructedPaths);
  }
  const totalAttributes: any[] = [];
  // loop around the path contructed as per the category and subcategory selection so that we can loop around only the attributes of the contructed path
  for (const path in constructedPaths) {
    if (categorySelectionList[path]) {
      const attributes = categorySelectionList[path];
      attributes.map((item) => {
        if (totalAttributes?.length) {
          // loop around the totalAttributes to check whether category level attribute is already exists in the totalAttributes
          for (const [index, attribute] of totalAttributes.entries()) {
            // checks if we have added category level attributes in totalAttributes
            if (
              attribute?.dimensionName === item.dimensionName &&
              attribute.dimensionLabel === item.dimensionLabel
            ) {
              // if attribute already exists then add the path of attribute to the category level attributes of totalAttributes
              item?.path?.[0] ? attribute.path.push(item?.path?.[0]) : '';
              break;
            }
            // if we have dont have category level attributes in totalAttributes then add the category level attributes
            if (index === totalAttributes.length - 1) {
              totalAttributes.push(item);
              break;
            }
          }
        } else {
          totalAttributes.push(item);
        }
      });
    }
  }
  // updates the attributes selection in local Storage
  attributesSelection &&
    Object.keys(attributesSelection).length &&
    Object.keys(attributesSelection).map((selection) => {
      // loop over the category level attribute selections
      let removeAttribute = true;
      // loop over the category level attribute formed as per the selection
      for (const [index, attribute] of totalAttributes.entries()) {
        // if we find the selection in totalAttributes list then break the loop
        if (selection === attribute?.dimensionLabel) {
          removeAttribute = false;
          break;
        }
        // if we dont find the selection in totalAttributes then remove the selection from local Storage
        if (index === totalAttributes.length - 1 && removeAttribute) {
          delete attributesSelection?.[selection];
          setGlobalSettingsForMS(constants.ATTRIBUTES, attributesSelection);
        }
      }
    });
  return totalAttributes;
};

/**
 * @description returns the data to add applied attributes in API payload
 * @return {array} payloadimensionData
 */
export const getAttributesData = (attributeList: AttributeType[]) => {
  const payloadimensionData: object[] = [];
  const getAttributes = getGlobalSettingsForMS()?.[constants.ATTRIBUTES] || {};
  Object.keys(getAttributes)?.map((item) => {
    attributeList.find((attribute: AttributeType) => {
      if (attribute?.dimensionLabel === item) {
        attribute?.path?.map((selectedPath: string) => {
          let value = selectedPath.replace(/----/g, '~');
          const attributeData: AttributeData = {};
          attributeData.dimensionName = attribute?.dimensionName;
          attributeData.dimensionValue = (
            getAttributes[item] +
            '~' +
            value +
            '~'
          )?.toLowerCase();
          attributeData.operator = 'STARTSWITH';
          payloadimensionData.push(attributeData);
        });
      }
    });
  });
  return payloadimensionData;
};

/**
 * @description stores applied attributes data in localStorage
 * @param {object} selectionData - have selection data from dropdown
 */
export const onFilterApply = (selectionData: SelectionData) => {
  const getAttributes = getGlobalSettingsForMS()?.[constants.ATTRIBUTES] || {};
  if (selectionData?.selections.length) {
    getAttributes[selectionData.title] = selectionData?.selections?.[0];
    setGlobalSettingsForMS(constants.ATTRIBUTES, getAttributes);
  } else {
    delete getAttributes[selectionData.title];
    if (Object.keys(getAttributes).length) {
      setGlobalSettingsForMS(constants.ATTRIBUTES, getAttributes);
    } else {
      setGlobalSettingsForMS(constants.ATTRIBUTES, {}, true);
    }
  }
};

/**
 * @description returns the attributes dropdown option list
 * @param {object} attributeData - contains attributes data with dropdown options
 */
export const getAttributeDropdownOptions = (attributeData: AttributeType) => {
  const attributeOption: AttributeDropdownData = {};
  attributeOption[attributeData.dimensionLabel] = {
    multi: false,
    v1: true,
    key: attributeData.dimensionName,
    label: attributeData.dimensionName,
    values: []
  };
  attributeData?.possibleValues?.forEach((item) => {
    const options: AttributeOption = {
      title: '',
      enable: false
    };
    options.title = item;
    options.enable = true;
    attributeOption[attributeData.dimensionLabel].values.push(options);
  });
  return attributeOption;
};

/**
 * @description returns the updated attributes list
 * @param {object} subcategory - list of selected subcategory
 * @param {object} categorySelectionList - list of all the possible paths
 * @param {object} currentAttributeList - contains attributes data with dropdown options
 * @return {array} attibutes list
 */
export const getAttributes = (
  subcategory: ConstructedPaths | {},
  categorySelectionList: CategorySelectionList,
  currentAttributeList: AttributeType[]
) => {
  let selectedPath: ConstructedPaths = {};
  if (Object.keys(subcategory).length) {
    selectedPath = subcategory;
    return getAttributeList(selectedPath, categorySelectionList);
  } else {
    if (currentAttributeList.length) {
      let selectedPath: ConstructedPaths = {};
      // as there is no subcategory selected so we are sending L1 category as selection to get updated category level attribute list
      selectedPath[getGlobalSettingsForMS()?.category_hierarchy?.[0]] = true;
      return getAttributeList(selectedPath, categorySelectionList);
    }
  }
};

/**
 * @description returns all the selections paths that can be possible with its enable status
 * @param {FilterStatusData} filterList - data of all category/subcateogry enable status
 * @param {ConstructedPaths} pathList - all possible selection paths of category/subcategory with its enable status
 * @return {object} pathList - object of all possible selections with its enable status as per the attributes selected
 */
export const enableFilter = (
  filterList: FilterStatusData['category'],
  pathList: ConstructedPaths,
  key?: string
) => {
  for (const filter in filterList) {
    const _key = key ? key + '----' + filter : filter;
    // assigning the selection combination with enable status
    pathList[_key.toLowerCase()] = filterList[filter].isEnabled;
    // check if we have children for the filter
    if (Object.keys(filterList[filter].children)) {
      enableFilter(filterList[filter].children, pathList, _key);
    }
  }
  return pathList;
};

/**
 * @description construct payload as per the attributes selected and make the API call to get the category status data
 * @param {array} dynamicAttributes - array of available category level filters
 * @return {object} - object of all possible selections with its enable status
 */
export const getCategoryDisableStatus = async (
  dynamicAttributes: AttributeType[]
) => {
  const globalFilter = getGlobalSettingsForMS();
  const attributeSelections: any = globalFilter[constants.ATTRIBUTES]
    ? Object.keys(globalFilter[constants.ATTRIBUTES])
    : [];
  const body: SelectedCategoryLevelList = {
    attributes: []
  };
  dynamicAttributes?.forEach((attribute) => {
    if (attributeSelections.includes(attribute?.dimensionLabel)) {
      body.attributes.push(attribute?.dimensionLabel);
    }
  });
  if (body?.attributes?.length) {
    const categoryDisableData = await HttpService.post(
      'MARKETSHARE_CATEGORY_DISABLE',
      body,
      {
        params: {
          v2: asinLevelV2ConfigEnabled()
        }
      }
    );
    return enableFilter(categoryDisableData?.data?.response?.category, {});
  } else {
    return {};
  }
};

/**
 * @description to disable the category level filters
 * @param {array} selectionList - hierarichal path of the subcategory on which the category level filter is applicable
 * @param {object} categoryStatusList - all possible selection paths of category/subcategory with its enable status
 * @return {boolean}
 */
export const getDisableAttribute = (
  selectionList: string[],
  categoryStatusList: ConstructedPaths
) => {
  let disable = false;
  if (!Object.keys(categoryStatusList).length) {
    return true;
  } else {
    // looping through the multiple selection paths on which the category level filter is applicable
    for (const item of selectionList) {
      // breaking the loop as soon as we find any selection path status needs to active
      if (categoryStatusList?.[item]) {
        return (disable = categoryStatusList?.[item]);
      }
    }
  }
};

export const getDimensionValueList = (subCategory: any[] = []) => {
  return subCategory?.map((item) => {
    let value = item.replace(/----/g, '~');
    value = value.toLowerCase() + '~';
    return {
      dimensionName: 'category_hierarchy',
      dimensionValue: value,
      operator: 'STARTSWITH'
    };
  });
};

export const computeFinalWhereClause = (
  initialWhereClause: Where,
  subCategories: DimensionNameValueList[],
  selectedCustomerFilters: Record<string, CustomerFilter[]>,
  selectedAttributes: DimensionNameValueList[]
) => {
  const whereClause = cloneDeep(initialWhereClause);
  if (subCategories.length > 0) {
    if (initialWhereClause?.dimensionNameValueList?.length > 0) {
      whereClause.dimensionNameValueList =
        whereClause?.dimensionNameValueList.filter((element) => {
          if (element.dimensionName === 'category_hierarchy') {
            return false;
          } else {
            return element;
          }
        });
      const newdimensionNameValueList = subCategories;
      whereClause.dimensionNameValueList.push(...newdimensionNameValueList);
    }
  }
  const customerFilters = Object.keys(selectedCustomerFilters);
  if (customerFilters.length > 0) {
    const newdimensionNameValueList: DimensionNameValueList[] = [];
    customerFilters.forEach((key) => {
      const selectedValues = selectedCustomerFilters[key];
      selectedValues.forEach((value) => {
        newdimensionNameValueList.push({
          dimensionName: value.dimensionName,
          dimensionValue: value.label
        });
      });
    });
    whereClause.dimensionNameValueList.push(...newdimensionNameValueList);
  }
  if (selectedAttributes?.length) {
    selectedAttributes.map((item) =>
      whereClause.dimensionNameValueList.push(item)
    );
  }
  return whereClause;
};
export const getFormattedCustomerLevelFilters = (
  filterData: FilterData,
  selectedCustomerFilters: Record<string, CustomerFilter[]> = {}
) => {
  return filterData?.customer?.attributes?.map((attribute) => {
    const options = attribute.possibleValues?.map((option) => ({
      label: option,
      dimensionName: attribute.dimensionName
    }));
    return {
      label: attribute.dimensionLabel,
      defaultSelections:
        selectedCustomerFilters?.[attribute.dimensionLabel] || options,
      options
    };
  });
};
export const getSavedCustomerFilters = (filterData: FilterData) => {
  const savedCustomerFilters = getGlobalSettingsForMS('customerLevelFilters');
  const customerFilterKeysFromAPI = filterData?.customer?.attributes?.map(
    (attribute) => attribute.dimensionLabel
  );
  Object.keys(savedCustomerFilters || {}).forEach((filter) => {
    if (!customerFilterKeysFromAPI.includes(filter)) {
      delete savedCustomerFilters[filter];
    }
  });
  return savedCustomerFilters;
};
/** Method to transform the API request based on the 3p selection that the user made */
export const modifyPayloadFor3p = (
  payload: DataApiRequestFormat<Operations>
) => {
  if (payload.where?.dimensionNameValueList) {
    // Check whether the selection is 'exclude 3p from my sales' i.e if the dimensionValueList contains 3p flag set to 'comp'
    const foundDimensionNameValueIndex =
      payload.where.dimensionNameValueList.findIndex(
        (el) =>
          el.dimensionName === constants.THREE_P_GROUP_FLAG &&
          el.dimensionValue === 'comp'
      );
    if (foundDimensionNameValueIndex > -1) {
      // If yes, remove that element from dimensionValueList
      payload.where?.dimensionNameValueList.splice(
        foundDimensionNameValueIndex,
        1
      );
      if (payload.operations) {
        // Populate the dimensionValueList of outerWhereClause
        payload.operations.outerWhereClause = {
          ...(payload.operations.outerWhereClause || {}),
          dimensionNameValueList: [
            ...(payload.operations.outerWhereClause?.dimensionNameValueList ||
              []),
            {
              dimensionName: constants.THREE_P_GROUP_FLAG,
              dimensionValue: false
            }
          ]
        };
      }
      // Add 3p flag to dimensionsList
      if (!payload.dimensionsList) {
        payload.dimensionsList = [constants.THREE_P_GROUP_FLAG];
      } else {
        payload.dimensionsList.push(constants.THREE_P_GROUP_FLAG);
      }
    }
  }
};

export enum ThreePOptions {
  Include3p = 'include3p',
  Exclude3pClient = 'exclude3pclient',
  Exclude3P = 'exclude3p',
  Only3P = 'only3p'
}
export function get3pOptions(shareType: DisplayShareTypes = 'Marketshare') {
  if (shareType === 'Sales') {
    return [
      {
        label: 'My 1P + 3P sales',
        value: ThreePOptions.Include3p.valueOf(),
        tooltip:
          'Shows the 1P + 3P sales of all the brands. The Market Share against this option is computed as 1P + 3P Sales of a brand out of 1P + 3P Sales of the Market.'
      },
      {
        label: 'My 1P Sales',
        value: ThreePOptions.Exclude3P.valueOf(),
        tooltip:
          'Shows the 1P sales of all the brands. The Market Share against this option is computed as 1P sales of a brands out of 1P Sales of the Market.'
      },
      ...(asinLevelV2ConfigEnabled()
        ? [
            {
              label: 'My 3P Sales',
              value: ThreePOptions.Only3P.valueOf(),
              tooltip:
                'Shows the 3P sales of all the brands. This includes the sales due to Lost Buy Box and indepedent 3P listings. The Market Share against this option is computed as 3P sales of a brands out of 3P Sales of the Market.'
            }
          ]
        : [])
    ];
  }
  return [
    {
      label: 'My 1P + 3P sales out of 1P + 3P of the market',
      value: ThreePOptions.Include3p.valueOf(),
      tooltip:
        'Calculates Market Share taking into consideration sales of your brands by 1P and 3P sellers out of the 1P + 3P sales of the entire market. The inclusion of 3P sales helps in understanding the total share of your brands (1P + 3P sales) in the Entire Market (1P + 3P sales).'
    },
    {
      label: 'My 1P sales out of 1P + 3P of the market',
      value: ThreePOptions.Exclude3pClient.valueOf(),
      tooltip:
        'Calculates Market Share taking into consideration sales of your brands by 1P seller out of the 1P + 3P sales of the entire market. The exclusion of 3P sales from your sales helps in understanding the total 1P share of your brands in the Entire Market (1P + 3P sales)'
    },
    {
      label: 'My 1P Sales out of 1P Sales of Market',
      value: ThreePOptions.Exclude3P.valueOf(),
      tooltip:
        'Calculates Market Share % taking into consideration only the sales by 1P sellers. Exclusion of 3P sales from your sales and the market helps in understanding the total 1P share of your brands in the entire 1P market by excluding sales made by 3P ASINs.'
    },
    ...(asinLevelV2ConfigEnabled()
      ? [
          {
            label: 'My 3P Sales out of 3P Sales of Market',
            value: ThreePOptions.Only3P.valueOf(),
            tooltip:
              'Calculates Market Share % taking into consideration only the sales by 3P sellers. Exclusion of 1P sales from your sales and the market helps in understanding the total 3P share of your brands in the entire 3P market by excluding sales made by 1P ASINs.'
          }
        ]
      : [])
  ];
}

export function transformNestedSettingsInDropDown(
  nestedSettings: any,
  Vue: VueConstructor
) {
  const currencyName = getCurrencyName(Vue);
  for (let index in nestedSettings?.displayShareIn) {
    nestedSettings.displayShareIn[index].children[0].label = currencyName;
  }
  nestedSettings.displayShareIn[0].shareType = DisplayByOptions[0].label;
  return nestedSettings;
}

export function transformNestedSettingsValueForGlobalSettings(payload: any) {
  let nestedSettings = cloneDeep(payload);
  const labelValue = nestedSettings?.displayShareIn?.label;
  if (labelValue !== 'Units') {
    nestedSettings.displayShareIn.label = 'Dollars';
  }
  const shareType = nestedSettings?.displayShareIn?.shareType;
  if (shareType === DisplayByOptions[0].label) {
    nestedSettings.displayShareIn.shareType = DisplayByOptions[0].shareType;
  }
  return nestedSettings;
}

export function transformNestedSettingsInUI(
  nestedSettings: any,
  Vue: VueConstructor
) {
  const labelValue = nestedSettings.displayShareIn.label;
  if (labelValue === 'Dollars') {
    nestedSettings.displayShareIn.label = getCurrencyName(Vue);
  }
  const shareType = nestedSettings.displayShareIn.shareType;
  if (shareType === DisplayByOptions[0].shareType) {
    nestedSettings.displayShareIn.shareType = DisplayByOptions[0].label;
  }
  return nestedSettings;
}

function metricCal() {
  return {
    metricsListDollars: isMarketShareLite()
      ? 'actual_sales_marketsharelite'
      : 'actual_sales_marketshare',
    metricsListUnits: isMarketShareLite()
      ? 'actual_unit_marketsharelite'
      : 'actual_unit_marketshare'
  };
}

export const DisplayByOptions = [
  {
    label: 'Market Share',
    shareType: 'Marketshare',
    children: [
      {
        metricsList: metricCal().metricsListDollars,
        label: 'Dollars'
      },
      {
        metricsList: metricCal().metricsListUnits,
        label: 'Units'
      }
    ]
  },
  {
    label: 'Sales',
    shareType: 'Sales',
    children: [
      {
        metricsList: metricCal().metricsListDollars,
        label: 'Dollars'
      },
      {
        metricsList: metricCal().metricsListUnits,
        label: 'Units'
      }
    ]
  }
];

export function DefaultDisplayByOptions() {
  return {
    shareType: 'Marketshare',
    metricsList: isMarketShareLite()
      ? 'MSL_SALES_ESTIMATES'
      : 'SALES_ESTIMATES',
    label: 'Dollars'
  };
}

/** Method to transform the old format of 3p settings into acceptable dropdown format and store in local storage  */
export const transform3pInNestedSettings = () => {
  // Get the settings from local storage
  let nestedSettings = getGlobalSettingsForMS(
    constants.NESTED_SETTINGS,
    undefined,
    false
  );
  if (nestedSettings) {
    let include3p = nestedSettings.include3p;
    // Check if exclude 3p was selected
    if (include3p?.length === 0) {
      // Update the include3p to new format
      include3p = get3pOptions()[2];
    } else if (include3p?.[0]?.label === get3pOptions()[0].label) {
      include3p = get3pOptions()[0];
    }
    nestedSettings.include3p = include3p;
    // update the local storage with new settings
    setGlobalSettingsForMS(constants.NESTED_SETTINGS, nestedSettings);
  }
};
/** Method to save the sorting order/state of All the tables in a common object
 * @param tableName - Name of the table whose sorting state needs to be saved
 * @param orderByList - sorting Data for the mentioned table
 */
export const saveSortingState = (
  tableName: string,
  orderByList: OrderByList[]
) => {
  const sortingObj: { [key: string]: OrderByList[] } =
    getGlobalSettingsForMS(constants.SORTING_OBJECT) || {};
  sortingObj[tableName] = orderByList;
  setGlobalSettingsForMS(constants.SORTING_OBJECT, sortingObj);
};
/** Method that returns a map function to compute sort logic
 * @param fieldKey - name of object key that contains the column name
 * @param orderByList - array containing the sorting data
 * @returns A map function to compute the sort logic
 */
export const getSortMapFunc = (
  fieldKey: 'dimensionColumn' | 'field',
  orderByList: OrderByList[]
) => {
  return (column: CustomColDef) => {
    if (orderByList?.length) {
      if (column[fieldKey] === orderByList[0].dimension) {
        column.sort = orderByList[0].direction.toLowerCase();
      } else {
        column.sort = undefined;
      }
    }
    return column;
  };
};
export const customDimensionValueListForMetric = (
  metric: TMetric,
  metadataResponse: AxiosResponse<MetaDataReadApi>,
  threepSelection: ThreePSelection
) => {
  const dimensionNameValueList = [];
  const uiMetadata = metadataResponse?.data?.metadata;
  /** Datagroups which is not dependent on 3p selection */
  const msIndependentDataGroups = uiMetadata?.msIndependentDataGroups;
  /** Data groups for which the whereClause is same for include 3p and exclude from client */
  const dataGroups2 = isMarketShareLite()
    ? [
        'ev_estimate_total_marketshareslite_dg',
        'ev_total_sku_count_marketshareslite_dg',
        'msl_actual_exclude_3p'
      ]
    : [
        'ev_estimate_total_marketshares_dg',
        'ev_total_sku_count_marketshares_dg',
        'ms_actual_exclude_3p'
      ];
  const { EXCLUDE_3P, INCLUDE_3P } = ThreePSelection;
  if (threepSelection !== INCLUDE_3P) {
    // Not modifying the where clause for include3p
    if (!msIndependentDataGroups.includes(metric.dataGroup)) {
      // Filtering the dataGroups for which there is no change required in the whereClause
      if (
        threepSelection === EXCLUDE_3P ||
        !dataGroups2.includes(metric.dataGroup)
      ) {
        dimensionNameValueList.push({
          dimensionName: constants.THREE_P_GROUP_FLAG,
          dimensionValue: false
        });
      }
    }
  }
  return dimensionNameValueList;
};
export const getSettingsValue = (
  brandEntityType: string,
  page: string,
  isMspro = false
) => {
  const metricsListDollars = isMarketShareLite()
    ? 'MSL_SALES_ESTIMATES'
    : 'SALES_ESTIMATES';
  const metricsListUnits = isMarketShareLite()
    ? 'MSL_UNIT_ESTIMATES'
    : 'UNIT_ESTIMATES';
  return {
    viewBy: [
      { entityType: brandEntityType, label: 'Brands' },
      { entityType: 'manufacturer', label: 'Manufacturers' }
    ],
    displayShareIn: [
      {
        label: 'Market Share',
        shareType: 'Marketshare',
        children: [
          {
            metricsList: metricsListDollars,
            label: 'Dollars'
          },
          {
            metricsList: metricsListUnits,
            label: 'Units'
          }
        ]
      },
      ...(isMspro
        ? []
        : [
            {
              label: 'Sales',
              shareType: 'Sales',
              children: [
                {
                  metricsList: metricsListDollars,
                  label: 'Dollars'
                },
                {
                  metricsList: metricsListUnits,
                  label: 'Units'
                }
              ]
            }
          ])
    ],
    include3p:
      page === 'MARKETSHARE-SKU'
        ? get3pOptions()
        : get3pOptions(getDisplayByShareType())
  };
};

export const generateOuterWhereClauseDimensionNameValueList = (
  payload: any,
  checkBox: SkuCheckBox,
  searchedText: string,
  type: string
) => {
  /**
   * utility function to generate outerWhereCluase dimensionsList while calling API
   */
  const outerWhereClauseDimensionNameValueList = [];
  if (type === 'data-api' && checkBox.your && checkBox.comp) {
    payload.where.dimensionNameValueList =
      payload.where.dimensionNameValueList.filter(
        (item: { dimensionName: string }) =>
          item.dimensionName !== 'client_flag'
      );
  } else if (!checkBox.your && checkBox.comp) {
    outerWhereClauseDimensionNameValueList.push({
      dimensionName: 'client_flag',
      dimensionValue: 'comp'
    });
  } else if (checkBox.your && !checkBox.comp) {
    outerWhereClauseDimensionNameValueList.push({
      dimensionName: 'client_flag',
      dimensionValue: 'client'
    });
  }
  if (searchedText.trim() !== '') {
    payload.dimensionsList.push('search');
    outerWhereClauseDimensionNameValueList.push({
      dimensionName: 'search',
      dimensionValue: searchedText,
      operator: 'ILIKE'
    });
  }
  payload.operations.outerWhereClause = {};
  payload.operations.outerWhereClause.dimensionNameValueList =
    outerWhereClauseDimensionNameValueList;
  return payload;
};
function convertV1ParamsToV2(
  globalSettingForMS: GlobalSettings
): GlobalSettings {
  if (globalSettingForMS?.nestedSettings?.displayShareIn !== undefined) {
    let v2MetricName = (globalSettingForMS?.nestedSettings?.displayShareIn
      .metricsList +
      (getDisplayByShareType() === 'Sales'
        ? ''
        : '_SHARE_PERCENT')) as V2Metrics;
    v2MetricName = add3pSuffixToV1MetricName(globalSettingForMS, v2MetricName);
    return {
      ...globalSettingForMS,
      nestedSettings: {
        ...globalSettingForMS?.nestedSettings,
        displayShareIn: {
          ...globalSettingForMS?.nestedSettings.displayShareIn,
          metricsList: v2MetricName as V2Metrics
        }
      }
    };
  } else {
    return globalSettingForMS;
  }
}

export function asinLevelV2ConfigEnabled() {
  const asinConfig =
    store?.getters?.getFeatureEnableConfig?.insights?.asin_v2?.enable || false;
  if (!localStorage.getItem(constants.V2_INIT) && asinConfig) {
    removeGlobalSettingsForMS();
    localStorage.setItem(constants.V2_INIT, 'true');
  }
  return asinConfig;
}

export function add3pSuffixToV1MetricName(
  globalSettingForMS: GlobalSettings,
  metricName: string
): V2Metrics {
  if (
    globalSettingForMS?.nestedSettings?.include3p?.value ===
      ThreePSelection.EXCLUDE_3P ||
    globalSettingForMS?.settingValue?.include3p?.value ===
      ThreePSelection.EXCLUDE_3P
  ) {
    metricName += '_EXCLUDE_3P';
  } else if (
    globalSettingForMS?.nestedSettings?.include3p?.value ===
      ThreePSelection.EXCLUDE_3P_CLIENT ||
    globalSettingForMS?.settingValue?.include3p?.value ===
      ThreePSelection.EXCLUDE_3P_CLIENT
  ) {
    metricName += '_EXCLUDE_MY_3P';
  } else if (
    globalSettingForMS?.nestedSettings?.include3p?.value ===
    ThreePSelection.ONLY_3P
  ) {
    metricName += '_ONLY_3P';
  }
  return metricName as V2Metrics;
}

export function checkForDisplayShareInOptions() {
  const globalNestedSettings =
    getGlobalSettingsForMS(constants.NESTED_SETTINGS, undefined, false) || {};
  if (!globalNestedSettings?.displayShareIn) {
    globalNestedSettings.displayShareIn = {};
  }
  Object.keys(DefaultDisplayByOptions).map((option) => {
    if (!globalNestedSettings.displayShareIn[option]) {
      // todo - adding ts-ignore below to avoid build failure and unblock QA. Will fix it asap
      globalNestedSettings.displayShareIn[option] =
        // @ts-ignore
        DefaultDisplayByOptions[option];
    }
  });
  setGlobalSettingsForMS(constants.NESTED_SETTINGS, globalNestedSettings);
}

export function getDisplayByShareType(): DisplayShareTypes {
  // taking it from local storage to avoid maximum call stack
  const globalSettingForMS: GlobalSettings = JSON.parse(
    localStorage.getItem(getLocalStorageKey()) || '{}'
  );
  // todo - adding ts-ignore below to avoid build failure and unblock QA. Will fix it asap
  const displayShareIn =
    // @ts-ignore
    globalSettingForMS[constants.NESTED_SETTINGS]?.displayShareIn || {};
  return displayShareIn?.shareType || 'Marketshare';
}

export function getDisplayByLabel(): string {
  // taking it from local storage to avoid maximum call stack
  const globalSettingForMS: GlobalSettings = JSON.parse(
    localStorage.getItem(getLocalStorageKey()) || '{}'
  );
  // todo - adding ts-ignore below to avoid build failure and unblock QA. Will fix it asap
  const displayShareIn =
    // @ts-ignore
    globalSettingForMS[constants.NESTED_SETTINGS]?.displayShareIn || {};
  return displayShareIn?.label || 'Dollar';
}

export function getGlobalToLocalInclude3p() {
  return {
    ...(getDisplayByShareType() === 'Sales'
      ? {
          label: {
            'My 1P + 3P sales out of 1P + 3P of the market': 'My 1P + 3P sales',
            'My 1P sales out of 1P + 3P of the market': 'My 1P Sales',
            'My 1P Sales out of 1P Sales of Market': 'My 1P Sales',
            'My 3P Sales out of 3P Sales of Market': 'My 3P Sales'
          }
        }
      : {
          label: {
            'My 1P + 3P sales': 'My 1P + 3P sales out of 1P + 3P of the market',
            'My 1P Sales': 'My 1P Sales out of 1P Sales of Market',
            'My 3P Sales': 'My 3P Sales out of 3P Sales of Market'
          }
        })
  };
}

// merging of category list
export function formCategoryList(
  categoryList1: string[],
  categoryList2: string[]
) {
  return [...(categoryList1 ?? []), ...(categoryList2 ?? [])];
}

// clears localstorage if the category form local storage doesnt match with the latest category list
export function shouldClearLocalStorage(
  categoryList: string[],
  allPossibleSelectionList: CategorySelectionList
) {
  for (let category of categoryList) {
    if (!allPossibleSelectionList[category.toLowerCase()]) {
      removeGlobalSettingsForMS();
      break;
    }
  }
}

// calls the function to merge two lists of categories and return the list
export function getCategoryList(pageName: string) {
  let globalFilter = getGlobalSettingsForMS();
  let categoryList = [];
  if (pageName === 'categoryPage' || pageName === 'skuPage') {
    let categoryPageList = globalFilter?.category_hierarchy;
    let subcategory = globalFilter?.subcategory
      ? Object.keys(globalFilter?.subcategory)
      : [];
    categoryList = formCategoryList(categoryPageList, subcategory);
  } else {
    let overviewCategory = globalFilter?.overview_category_hierarchy;
    let benchmarkingColumn = globalFilter?.showedBenchmarkingColumns;
    categoryList = formCategoryList(overviewCategory, benchmarkingColumn);
  }
  return categoryList;
}

// checks that the local storage has updated data
export function localStorageFallback(
  categoryFromAPI: FilterData['category'],
  pageName: string
) {
  let globalFilter = getGlobalSettingsForMS();
  const allPossibleSelectionList = filterHierarchyPaths(
    categoryFromAPI,
    {},
    ''
  );
  let categoryList = getCategoryList(pageName);
  if (Object.keys(globalFilter).length) {
    shouldClearLocalStorage(categoryList, allPossibleSelectionList);
    if (globalFilter[constants.ATTRIBUTES]) {
      let flag = false;
      for (let attribute of categoryFromAPI[
        globalFilter?.category_hierarchy?.[0]
      ].attributes) {
        if (
          Object.keys(globalFilter[constants.ATTRIBUTES]).includes(
            attribute.dimensionLabel
          )
        ) {
          break;
        } else {
          flag = true;
        }
      }
      if (flag) {
        setGlobalSettingsForMS(constants.ATTRIBUTES, {}, true);
      }
    }
  }
}

// -------- MS 2.0 -----------

export const msProConstants = {
  errorMessage: {
    message: 'Something Went Wrong!',
    duration: 5000,
    buttonColor: '#f5d908',
    actionText: ' '
  },
  chartColors: {
    red: '#D7263D',
    green: '#3EA95E',
    gray: '#808080'
  },
  defaultSortingMetric: 'ms_pro_v2_ev_marketshares_dg',
  oldVersion: 'Version 1.0',
  newVersion: 'Version 2.0',
  versionList: ['Version 1.0', 'Version 2.0'],
  nestedSettings: {
    viewBy: { entityType: 'category', label: 'Category' },
    displayShareIn: DefaultDisplayByOptions(),
    include3p: get3pOptions()[0]
  }
};

export const categoryInCardConstants = {
  // Here values are same as keys in metadata object of read API response
  metrics: {
    marketshare: 'marketShareMetric',
    categorySize: 'categorySize',
    sales: 'sales'
  },
  msPro_prefix: 'ms_pro_2.0_'
};

export function getOptionsListForCompareDropdown(viewBy: string) {
  const options = [
    viewBy === 'brand'
      ? { name: 'Brand vs Brand', key: 'brand' }
      : { name: 'Manufacturer vs Manufacturer', key: 'manufacturer' },
    { name: 'Metric vs Metric', key: 'metric' }
  ];
  return options;
}
export function getDefaultCompareConfiguration(viewBy: string) {
  const options = getOptionsListForCompareDropdown(viewBy);
  const selectedComparision = options[0].key;
  return selectedComparision;
}

export function getCompareConfiguration(viewBy: string) {
  const comaprisonKey = 'selectedComparison';
  const storedComaprison = localStorage.getItem(comaprisonKey);
  if (storedComaprison === null) {
    localStorage.setItem(
      comaprisonKey,
      JSON.stringify(getDefaultCompareConfiguration(viewBy))
    );
    return getDefaultCompareConfiguration(viewBy);
  }
  return JSON.parse(storedComaprison);
}

// Updates Comparison Widget Configuration when viewBy changes
export function updateComparisionConfiguration(viewBy: string) {
  const comaprisonKey = 'selectedComparison';
  const existingSelectedComparision = JSON.parse(
    localStorage.getItem(comaprisonKey) ?? '{}'
  );
  let selectedComparision = getDefaultCompareConfiguration(viewBy);
  if (existingSelectedComparision === 'metric') {
    selectedComparision = 'metric';
  }
  localStorage.setItem(comaprisonKey, JSON.stringify(selectedComparision));
  return selectedComparision;
}

export function getMetricSelectedForTabView() {
  const metricKey = 'selectedMetricForTabView';
  const storedMetric = localStorage.getItem(metricKey);
  if (storedMetric === null) {
    const defaultMetric =
      msProCategoryConstants.defaultMetricSelectedForTabView;
    localStorage.setItem(metricKey, JSON.stringify(defaultMetric));
    return defaultMetric;
  }
  return JSON.parse(storedMetric);
}

export const defaultSortConfiguration = {
  metric: 'Sales',
  sortingOptions: {
    label: 'Absolute Descending',
    icons: ['sort-desc'],
    direction: 'DESC'
  }
};

export const overviewSortMetrics = {
  'Market Share': 'marketShareMetric',
  Sales: 'sales',
  'Category Size': 'categorySize'
};

export function getOverviewSortConfiguration() {
  if (localStorage.getItem('overviewSortConfiguration') === null) {
    localStorage.setItem(
      'overviewSortConfiguration',
      JSON.stringify(defaultSortConfiguration)
    );
    return defaultSortConfiguration;
  } else
    return JSON.parse(
      localStorage.getItem('overviewSortConfiguration') ?? '{}'
    );
}

export const performanceBreakDownSortMetrics = {
  'Market Share': 'marketShareMetric',
  Sales: 'sales'
};

export function getPerformanceBreakDownSortConfiguration() {
  if (localStorage.getItem('performanceBreakDownSortConfiguration') === null) {
    localStorage.setItem(
      'performanceBreakDownSortConfiguration',
      JSON.stringify(defaultSortConfiguration)
    );
    return defaultSortConfiguration;
  } else
    return JSON.parse(
      localStorage.getItem('performanceBreakDownSortConfiguration') ?? '{}'
    );
}

export const msProOverviewConstants = {
  page: 'MS_PRO_2.0-EXECUTIVE',
  cardWidget: 'ms_pro_2.0_overview_w1_card',
  breakdownTableMetrics: {
    marketshare: 'marketShareMetric',
    categorySize: 'categorySize',
    sales: 'sales',
    lbb: 'lbb',
    glanceViews: 'glance_views',
    poFillRate: 'po_fill_rate',
    unavailability: 'unavilability',
    unitConversion: 'unit_conversion',
    yourSku: 'your_skus',
    totalSku: 'total_skus',
    revenueLostUnavailability: 'revenue_lost_unavailability',
    revenueLostLbb: 'revenue_lost_lbb',
    adSpend: 'ad_spend'
  },
  shareSummaryWidget: 'ms_pro_2.0_overview_categoryShareSummary'
};

export const msProCategoryConstants = {
  page: 'MS_PRO_2.0-CATEGORY',
  metricsCardWidget: 'ms_pro_2.0_category_w1_metrics_card',
  shareSummaryWidget: 'ms_pro_2.0_overview_categoryShareSummary',
  categoryShareSummary: 'ms_pro_2.0_categoryShareSummary_category_v2',
  breakdownWidget: 'ms_pro_2.0_category_w1_breakdown',
  performanceTrendsWidget: 'ms_pro_2.0_category_w2_trends',
  breakdownTableMetrics: {
    marketshare: 'marketShareMetric',
    sales: 'sales'
  },
  performanceTrendMetrics: {
    marketshare: 'marketShareMetric',
    sales: 'sales',
    categorySize: 'categorySize'
  },
  metricVsMetric: {
    marketshare: {
      key: 'marketShareMetric',
      displayName: 'Market Share',
      tooltip:
        'Market Share of the brand in the selected category in the selected time period'
    },
    sales: {
      key: 'sales',
      displayName: 'Sales',
      tooltip:
        'The sum of estimated sales of all SKUs of the brand in the selected category in the selected time period'
    }
  },
  defaultMetricSelectedForTabView: 'marketshare',
  trendsMetricListForTabView: [
    {
      label: 'Market Share',
      value: 'marketshare'
    },
    {
      label: 'Sales',
      value: 'sales'
    }
  ]
};

export function getMsProShareSummaryWidget(page: string) {
  const pageConstant =
    page === 'categoryPage' ? msProCategoryConstants : msProOverviewConstants;
  const widget = pageConstant.shareSummaryWidget;
  return function (widgetName: string) {
    return widgetName === widget;
  };
}

export function setCategoryFilterInCategoryPage(category: string) {
  setGlobalSettingsForMS(constants.CATEGORY, category);
  // resets the category selection of other pages
  setGlobalSettingsForMS(constants.SUBCATEGORY, {}, true);
  setGlobalSettingsForMS(constants.FILTER_SELECTION, {}, true);
  setGlobalSettingsForMS(constants.CATEGORY_HIERARCHY, {}, true);
  setGlobalSettingsForMS(constants.ATTRIBUTES, {}, true);
}

export function toggleVersion(updateVal: boolean) {
  localStorage.setItem('enableMsPro', JSON.stringify(updateVal));
}

export function isMsproEnabled() {
  return (
    store?.getters?.getFeatureEnableConfig?.insights?.market_share_v2?.enable ||
    false
  );
}

export function msVersion() {
  const featureConfig = isMsproEnabled();
  let enableMsPro = 'false';
  if (featureConfig) {
    enableMsPro = localStorage.getItem('enableMsPro') ?? 'true';
  }
  return featureConfig && JSON.parse(enableMsPro);
}

export const msProDisplayByOptions = [
  {
    label: 'Market Share',
    shareType: 'Marketshare',
    children: [
      {
        metricsList: metricCal().metricsListDollars,
        label: 'Dollars'
      },
      {
        metricsList: metricCal().metricsListUnits,
        label: 'Units'
      }
    ]
  }
];

export function updateTimeSelectionForChart(
  request: object,
  noOfWeeksInChart: number
) {
  let nWeeksRequest = JSON.parse(JSON.stringify(request));
  const dateObject = nWeeksRequest.where.date;
  const toDate = new Date(dateObject.to);
  const updatedName = 'last' + noOfWeeksInChart + 'Week';

  const updatedFromDate = moment(toDate).subtract(
    6 + (noOfWeeksInChart - 1) * 7,
    'days'
  );

  dateObject.from = updatedFromDate.toISOString().split('T')[0];
  dateObject.name = updatedName;
  nWeeksRequest.where.date = dateObject;

  const pvpDateObject = nWeeksRequest.where.pvpDate;
  const updatedPvpToDate = moment(updatedFromDate).subtract(1, 'day');
  const updatedPvpFromDate = moment(updatedFromDate).subtract(
    noOfWeeksInChart * 7,
    'days'
  );

  pvpDateObject.from = updatedPvpFromDate.toISOString().split('T')[0];
  pvpDateObject.to = updatedPvpToDate.toISOString().split('T')[0];
  nWeeksRequest.where.pvpDate = pvpDateObject;

  return nWeeksRequest;
}

export const categoryMapping = (
  filterList: FilterData['category'],
  pathList: CategoryChildList,
  key: string
) => {
  for (const filter in filterList) {
    const _key = key ? key + '~' + filter : filter;
    const categoryChild = Object.keys(filterList[filter].children);
    pathList[_key.toLowerCase() + '~'] = categoryChild.length > 0;
    if (categoryChild) {
      categoryMapping(filterList[filter].children, pathList, _key);
    }
  }
  return pathList;
};

export const getMetricDetails = (
  metadata: MetaData,
  globalSettings: any,
  metricMapping: any
) => {
  const threepSelection = globalSettings?.settingValue?.include3p?.value;
  const displayType = globalSettings?.settingValue?.displayShareIn?.label;
  const constMetricList: BreakdownTableItem =
    msProOverviewConstants.breakdownTableMetrics;
  const metricDataGroupMapping: any = {};
  for (const metricKey in constMetricList) {
    const metricName =
      metadata[constMetricList[metricKey]][displayType][threepSelection];
    if (!metricMapping.hasOwnProperty(metricName)) {
      continue;
    }
    const metricObject = metricMapping[metricName];
    metricDataGroupMapping[constMetricList[metricKey]] = metricObject;
  }
  return metricDataGroupMapping;
};

export const buildUIMetricMapping = (metricDataGroupMapping: any) => {
  const uiMetricMapping: any = {};
  for (const metricKey in metricDataGroupMapping) {
    const metricName = metricDataGroupMapping[metricKey].name;
    uiMetricMapping[metricName] = metricKey;
  }
  return uiMetricMapping;
};

export const getMetricsMapping = (readApiResponse: any, metricMapping: any) => {
  const metricList = readApiResponse.metrics;
  for (let metricKey in metricList) {
    if (metricList.hasOwnProperty(metricKey)) {
      // Ensure to check for own property
      const metrics = metricList[metricKey];
      metricMapping[metrics.name] = {
        request: metrics?.api.request,
        name: metrics.keyName,
        dataGroup: metrics.dataGroup,
        metricUnit: metrics.metadata.unit,
        metricList: metrics.name
      };
    }
  }
  return metricMapping;
};

export function updateRequestFormat(request: object, globalSettings: any) {
  let updatedRequest = JSON.parse(JSON.stringify(request));
  updatedRequest.where.date = globalSettings?.where?.date;
  updatedRequest.where.pvpDate = globalSettings?.where?.pvpDate;
  updatedRequest.where.dimensionNameValueList = [
    ...globalSettings.where.dimensionNameValueList,
    ...updatedRequest.where.dimensionNameValueList
  ];
  updatedRequest.where.excludeDimensionsFromSharePercentage = [];
  updatedRequest.metricsList = [];
  return updatedRequest;
}

export function getBasicRequestFormat(
  request: object,
  globalSettings: any,
  entityType: string
) {
  let updatedRequest = JSON.parse(JSON.stringify(request));
  const { date, pvpDate } = globalSettings.where;
  updatedRequest.where.date = date;
  updatedRequest.where.pvpDate = pvpDate;
  updatedRequest.metricsList = [];
  updatedRequest.entityType = entityType;
  return updatedRequest;
}

export function makeBundledRequest(
  parentRequestFormat: object,
  metricDataGroupMapping: any,
  globalSettings: any,
  entityType: string,
  isSortEnabled: boolean,
  sortConfiguration: any
) {
  const parentRequest = getBasicRequestFormat(
    parentRequestFormat,
    globalSettings,
    entityType
  );
  parentRequest.bundleDataGroupsRequest = {};
  parentRequest.operations.orderByList = [];
  parentRequest.operations.bundleRequestJoinType = 'FULL_OUTER_JOIN';
  parentRequest.operations.isChainingJoinEnabled = true;
  for (const metricKey in metricDataGroupMapping) {
    let metricRequest = metricDataGroupMapping[metricKey].request;
    metricRequest = updateRequestFormat(metricRequest, globalSettings);
    metricRequest.metricsList[0] =
      metricDataGroupMapping[metricKey].dashboardServiceKey;
    metricRequest.operations.orderByList = [];
    metricRequest.operations.orderByList.push({
      dimension: metricDataGroupMapping[metricKey].dashboardServiceKey,
      direction: 'DESC'
    });
    if (isSortEnabled) {
      const sortByMetric = sortConfiguration.metric;
      if (
        overviewSortMetrics[
          sortByMetric as keyof typeof overviewSortMetrics
        ] === metricKey
      ) {
        const metricUnit = metricDataGroupMapping[metricKey].metricUnit;
        const PVP_DIFF_TYPE =
          metricUnit === 'PERCENTAGE' ? 'pvp_diff_' : 'pvp_';
        let metricName = metricDataGroupMapping[metricKey].dashboardServiceKey;
        if (sortConfiguration.sortingOptions.sortByChange) {
          metricName = `${PVP_DIFF_TYPE}${metricDataGroupMapping[metricKey].dashboardServiceKey}`;
        }
        parentRequest.operations.orderByList.push({
          dimension: metricName,
          direction: sortConfiguration.sortingOptions.direction
        });
      }
    }
    parentRequest.bundleDataGroupsRequest[
      metricDataGroupMapping[metricKey].dataGroup
    ] = metricRequest;
  }
  return parentRequest;
}

export function getCategoryLevel(categoryName: string) {
  let childCategoryLevel = '';
  let level = 0;
  for (let i = 0; i < categoryName.length; i++) {
    const char = categoryName.charAt(i);
    if (!isNaN(Number(char))) {
      childCategoryLevel += char;
    } else if (childCategoryLevel !== '') {
      // Break loop if a non-digit character is encountered after the number
      break;
    }
  }
  if (childCategoryLevel !== '') {
    level = Number.parseInt(childCategoryLevel, 10);
    if (!isNaN(level)) {
      return level;
    }
  }
}

export const getMetricData = (
  readApiResponse: any,
  globalSettings: any,
  metricList: any
) => {
  const threepSelection = globalSettings?.settingValue?.include3p?.value;
  const displayType = globalSettings?.settingValue?.displayShareIn?.label;
  let metricMapping: any = {};
  for (let metricKey in metricList) {
    const metric =
      readApiResponse.metadata[metricList[metricKey]][displayType][
        threepSelection
      ];
    metricMapping[metricList[metricKey]] = {
      request: readApiResponse.metrics[metric].api.request,
      dataGroup: readApiResponse.metrics[metric].dataGroup,
      metricList: readApiResponse.metrics[metric].name,
      metricUnit: readApiResponse.metrics[metric].metadata.unit,
      name: metric
    };
  }
  return metricMapping;
};
