<template>
  <section class="campaign-creation-setup u-position-fixed widget--full-screen">
    <div class="u-position-fixed model u-width-100 campaign-creation-setup">
      <!-- Header Start -->
      <div class="">
        <section
          class="campaign-creation-setup--header u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-p-l"
        >
          <div
            class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-flex-1"
          >
            <div class="u-display-flex u-flex-align-items-center">
              <span @click="closeWithoutSave()">
                <rb-icon
                  :icon="'cross'"
                  class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-mr-s"
                />
              </span>
              <span
                data-cy="campaignCreateHeader"
                class="u-font-size-1 u-font-weight-600"
                >Campaign Creator
              </span>
            </div>
            <span
              class="u-font-size-4 u-color-grey-mid-light u-spacing-pt-s u-spacing-ml-l"
              >Create multiple types of campaigns
            </span>
          </div>
        </section>
      </div>
      <!-- Header ends -->
      <!-- Scroll section -->
      <div class="u-display-flex u-spacing-mt-xl">
        <Upload />
      </div>
      <!-- Scroll section -->
      <loader
        v-show="false"
        class="fill--parent"
        :color="'#3fadf7'"
        :size="'4rem'"
        :thickness="'.2rem'"
      />
    </div>
  </section>
</template>

<script>
import config from '@/components/ams/campaign_creation/setup_configs/index.js';
import loader from '@/components/basic/loader';
import Upload from '@/components/ams/budgetPlanner/steps/uploadStep/index.vue';
export default {
  components: {
    loader,
    Upload
  },
  data() {
    return {
      pageData: null,
      createOptions: [
        {
          icon: '',
          title: 'Assisted Campaigns',
          description:
            'Provides creation of bulk campaign with just 3 steps and you are ready to launch to bulk campaigns all at once.',
          route: 'create_assisted_campaigns'
        },
        {
          icon: '',
          title: 'Manual Campaigns',
          description:
            'Provides creation of bulk campaign with just 3 steps and you are ready to launch to bulk campaigns all at once.',
          route: 'setup_suggested_campaign'
        },
        {
          icon: '',
          title: 'Create With Bulk Sheet ',
          description:
            'Provides creation of bulk campaign with just 3 steps and you are ready to launch to bulk campaigns all at once.',
          route: 'setup_suggested_campaign'
        }
      ]
    };
  },
  computed: {
    retailer() {
      return this.$store.getters.getRetailer;
    }
  },
  created() {
    this.pageData = config?.[this.retailer];
  },
  methods: {
    closeWithoutSave() {
      this.$store.dispatch(this.pageData.action, {});
      this.$router.push({
        name: this.pageData?.closeCreationRouteName
      });
    },
    goToRoute(routeName) {
      this.$router.push({
        name: routeName
      });
    }
  }
};
</script>

<style lang="css">
.campaign-creation-setup {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 200;
  left: 0px;
  background-color: #ffffff;
}
.campaign-creation-setup .step-component-v2 .scrollable {
  padding-top: 0px;
}
.campaign-creation-setup
  .step-component-v2
  .marign-top-campaign-creation-bullets {
  margin-top: 160px;
  padding-left: 8px;
}
.campaign-creation-setup .campaign-creation-setup--header {
  z-index: 300;
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  position: relative;
}
</style>
