<template>
  <div>
    <div class="u-flex-direction-row u-display-flex full-viewport">
      <receipientContainer class="u-flex-3 u-overflow-y-auto" />
      <reportsSent class="u-flex-5 u-overflow-y-auto u-spacing-mb-s" />
      <emailForm class="u-flex-10 u-overflow-y-auto u-spacing-mb-s" />
    </div>
  </div>
</template>

<script>
import receipientContainer from './receipients-container';
import emailForm from './emailForm';
import reportsSent from './reports-sent';
export default {
  components: {
    receipientContainer,
    emailForm,
    reportsSent
  },
  computed: {
    notification() {
      return this.$store.getters.notification.message;
    },
    notificationType() {
      return this.$store.getters.notification.type;
    },
    notificationShow() {
      return this.$store.getters.notification.show;
    }
  },
  watch: {},
  methods: {}
};
</script>

<style lang="css" scoped>
.full-viewport {
  max-height: 87vh;
  min-height: 87vh;
}
</style>
