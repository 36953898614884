<template>
  <div
    class="u-display-inline-flex u-height-100 u-width-100 u-flex-align-items-center"
    :class="[
      params.keyType && isKeyRightAligned ? 'reverse-content' : '',
      params.enableSorting ? 'u-cursor-pointer' : ''
    ]"
    @click="customSort()"
  >
    <div
      class="header-text u-flex-shrink-past-min-size u-flex-1 u-font-weight-600"
      :style="customStyles"
      :class="[
        sortState ? 'u-color-grey-light' : 'u-color-grey-lighter',
        params.keyType && isKeyRightAligned
          ? 'reverse-content u-text-align-right'
          : ''
      ]"
      @click="clickHeader($event)"
    >
      <rb-tooltip-header
        v-if="params.toolTipText"
        :id="setTooltip.id"
        :title="getTooltipHeader"
        :message="tooltipMessage"
      >
        <!-- eslint-disable -->
        <div
          slot="tooltip-message"
          class="tooltip-message"
          v-html="tooltipMessage"
        />
        <!-- eslint-enable -->
      </rb-tooltip-header>
      <!-- eslint-disable -->
      <span
        v-tippy="setTooltip.tippyOptions"
        class="text-label"
        v-html="formattedDisaplyName(params.displayName)"
        :data-cy="`TableHeader-${params.displayName}`"
      />
      <!-- eslint-enable -->
      <parent-asin-tooltip v-if="params.showParentAsinTooltip" />
      <div
        v-if="params.headerIcon"
        class="u-display-flex"
        @click="onIconClick"
      >
        <rb-icon
          v-tippy="iconTippy"
          :title="
            params.headerIcon.toolTip ? params.headerIcon.toolTip : 'ToolTip'
          "
          style="cursor: pointer"
          class="sort-icon u-color-grey-lighter u-spacing-ml-xs"
          :icon="params.headerIcon.icon"
          :class="params.headerIcon.class || 'rb-icon--small'"
        />
      </div>
      <div
        v-if="params.filter && params.filter.enable"
        class="u-display-flex"
      >
        <rb-select
          :is-multi-select="true"
          :context="params.filter.context"
          :send-details="true"
          :on-close="onFilterSelect"
          :options="params.filter.values"
          :class-name="params.filter.className"
          :width="params.filter.width"
        >
          <div
            slot="trigger"
            class="u-display-flex u-spacing-p-s u-flex-align-items-center u-cursor-pointer"
          >
            <rb-icon
              v-tippy="iconTippy"
              :title="selectedFiltersTitle"
              class="rb-icon--small u-spacing-ml-xs"
              :class="{
                'u-color-grey-lighter': selectedFiltersFromState.length === 0,
                'u-color-blue-base': selectedFiltersFromState.length > 0
              }"
              :icon="'filter'"
            />
          </div>
        </rb-select>
      </div>
    </div>

    <div
      class="icons-wrap u-flex-none u-display-flex u-flex-align-items-center"
      :class="[
        sortState
          ? isKeyRightAligned
            ? 'u-spacing-pr-s'
            : 'u-spacing-pl-s'
          : ''
      ]"
    >
      <rb-icon
        v-if="sortState === 'asc'"
        class="sort-icon rb-icon--x-small"
        :icon="'sort-asc'"
      />
      <rb-icon
        v-if="sortState === 'desc'"
        class="sort-icon rb-icon--x-small"
        :icon="'sort-desc'"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import ParentAsinTooltip from '@/components/pages/insights/amazon/market-share/molecules/parent-asin-tooltip.vue';
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'genericTableHeader',
  components: {
    ParentAsinTooltip
  },
  data() {
    return {
      selectedFiltersTitle: 'Add filter',
      iconTippy: {
        placement: 'top',
        arrow: false,
        distance: 8,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      keysToLeftAlign: [
        'string',
        'bsr_link',
        'recommended_action_type',
        'link'
      ],
      sortState: null
    };
  },
  computed: {
    selectedFiltersFromState() {
      const key = this.params?.filter?.context[0];
      let returnValue = [];
      if (key) {
        returnValue =
          this.$store.getters[this.params?.filter?.stateGetter]?.filter[key];
      }
      if (returnValue) {
        if (returnValue.length === 0) {
          this.selectedFiltersTitle = 'Add filter';
        } else {
          this.selectedFiltersTitle = returnValue.join(', ');
        }
      }
      return returnValue || [];
    },
    setTooltip() {
      return {
        id: this.params.column.colId,
        tippyOptions: {
          html: '#' + this.params.column.colId,
          theme: 'rb-tooltip-header',
          placement: 'bottom',
          interactive: true,
          reactive: true,
          arrow: false
        }
      };
    },
    customStyles() {
      return this.params.customStyles || '';
    },
    isKeyRightAligned() {
      if (this.params.alignHeader) {
        return this.params.alignHeader !== 'left';
      }
      const keyType = this.params.keyType;
      return this.keysToLeftAlign.indexOf(keyType) === -1;
    },
    tooltipMessage() {
      if (this.params.toolTipText) {
        if (
          this.params.displayName &&
          this.params.toolTipText.toLowerCase() ===
            this.params.displayName.toLowerCase()
        ) {
          return '';
        }
        // By default split using ':'
        if (!this.params.removeSplit) {
          return this.params.toolTipText.includes(':')
            ? this.params.toolTipText.split(':')[1].trim()
            : this.params.toolTipText;
        } else if (this.params.removeSplit === true) {
          return this.params.toolTipText;
        }
      }
      return '';
    },
    getTooltipHeader() {
      if (this.params.tooltipCustomTitle) {
        return this.params.tooltipCustomTitle;
      } else if (this.params.column.colDef.tooltipHeader) {
        return this.params.column.colDef.tooltipHeader;
      }
      return this.params.displayName.capitalize();
    }
  },
  mounted() {
    this.sortState = this.params.column.getSort() || null;
    this.params.column.addEventListener('sortChanged', this.onSortChanged);
    this.$nextTick(() => {
      let toExpand = false;
      const el =
        this.$el.querySelector && this.$el.querySelector('.text-label');
      if (!el) {
        return;
      }
      if (el.scrollHeight > 35) {
        toExpand = true;
      }
      if (!toExpand) {
        el.classList.add('u-flex-align-items-center');
      } else {
        el.classList.add('u-flex-align-items-flex-start');
      }
    });
  },
  beforeDestroy() {
    this.params.column.removeEventListener('sortChanged');
  },
  methods: {
    onIconClick(event) {
      this.params.defaultIconClickHandler(this, event);
    },
    clickHeader(event) {
      if (this.params.clickHeader) {
        this.params.clickHeader(this, event);
      }
    },
    onSortChanged() {
      this.sortState = this.params.column.getSort();
    },
    customSort() {
      this.$nextTick(() => {
        this.params.enableSorting &&
          this.params.onColumnSort &&
          this.params.onColumnSort(this.params.column);
      });
      if (!this.params.enableSorting) {
        return;
      }
      this.params.progressSort();
    },
    onFilterSelect(context, values) {
      values = values.map((item) => {
        return item.title;
      });
      if (
        values.sort().toString() !==
        this.selectedFiltersFromState.sort().toString()
      ) {
        this.params.filter.onSelect(context, values);
      }
    },
    formattedDisaplyName(displayName) {
      return Vue.options.filters.first_caps(displayName);
    }
  }
};
</script>

<style lang="css" scoped>
.reverse-content {
  flex-direction: row-reverse;
}

.header-text {
  height: 100%;
  padding: 8px 0;
  word-break: break-word;
  overflow: hidden;
  vertical-align: middle;
  white-space: normal;
  display: flex;
  align-items: center;
  line-height: 1.1;
}
.header-text .text-label {
  max-height: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.tooltip-message {
  line-height: 1.38;
  color: #2a333c;
  word-break: break-word !important;
  word-wrap: break-word !important;
  display: flex;
  flex-direction: column;
  width: 100;
  font-weight: 400;
  font-size: 1.3rem;
}
</style>
