<template>
  <div class="u-display-flex u-flex-direction-column">
    <span
      class="u-font-size-3 u-color-grey-lighter u-font-weight-600 u-text-transform-uppercase u-display-flex u-flex-align-items-center"
      :class="{ 'u-spacing-mb-s': description }"
    >
      {{ title }}
      (<span class="u-color-grey-base">{{ count }}</span
      >)
      <rb-icon
        v-if="infoTooltip"
        v-tippy="{ placement: 'bottom' }"
        class="rb-icon--medium u-spacing-ml-xs u-color-blue-base"
        icon="info-circle-outline"
        :title="infoTooltip"
      />
    </span>
    <span class="u-color-grey-light u-font-size-5">{{ description }}</span>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: null
    },
    infoTooltip: {
      type: String,
      default: ''
    }
  }
};
</script>
