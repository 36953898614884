<template>
  <div
    class="u-position-relative u-font-size-5 u-spacing-pv-m u-spacing-pr-xxl"
  >
    <p class="u-font-size-3 u-font-weight-600">
      {{ $t('predictive_oos_with_upcoming_po') }}
    </p>
    <p class="u-font-size-4 u-font-weight-600 u-spacing-pt-l">
      {{ $t('expected_to_go_oos_in_') }}{{ alert.attributes.daysofsupply
      }}{{ $t('_days') }}.
    </p>
    <p class="u-font-size-6 u-spacing-pt-s">
      {{ $t('last_appeared_on_')
      }}{{ formatter(alert.attributes.feed_date, 'date') }} &bull;
      {{ $t('appeared_') }}{{ alert.attributes.recency
      }}{{ $t('_out_of_last_30_days') }}
    </p>
    <div class="u-display-flex u-spacing-pt-xl u-spacing-pb-m">
      <div
        class="u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('units_velocity')"
          :item-value="
            formatter(alert.attributes.unit_velocity, 'number') + ' SKUs/day'
          "
        />
      </div>
      <div
        class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('current_units_on_hand')"
          :item-value="
            formatter(alert.attributes.current_units_on_hand, 'number')
          "
        />
      </div>
      <div
        class="u-spacing-ph-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <key-value-pair
          :item-key="$t('price_elasticity')"
          :item-value="formatter(alert.attributes.elasticity_bucket, 'string')"
        />
      </div>
      <div class="u-spacing-pl-s">
        <key-value-pair
          :item-key="$t('replenishment_code')"
          :item-value="alert.attributes.replenishment_code"
        />
      </div>
    </div>
    <div class="u-display-flex u-spacing-pb-xl">
      <div
        class="u-display-flex u-flex-align-items-center u-spacing-pr-s u-border-right u-border-width-s u-border-color-grey-xx-light"
      >
        <span class="u-font-weight-600">{{ $t('rating') }}: </span>
        <star-rating
          :active-color="'#f3b000'"
          :inactive-color="'#f3b000'"
          :max="5"
          :value="Math.round(alert.attributes.rating)"
          :readonly="true"
        >
          <span
            slot="activeLabel"
            slot-scope="props"
            ><rb-icon :icon="'star'"
          /></span>
          <span
            slot="inactiveLabel"
            slot-scope="props"
            ><rb-icon :icon="'star-empty'"
          /></span>
        </star-rating>
        <span
          >({{ alert.attributes.rating || 'NA' }} {{ $t('out_of_5') }})</span
        >
      </div>
      <div class="u-spacing-pl-s u-display-flex u-flex-align-items-center">
        <key-value-pair
          :item-key="$t('reviews')"
          :item-value="alert.attributes.reviews || 'NA'"
        />
      </div>
    </div>
    <div class="detail-view--card detail--chart">
      <chart
        v-if="showChart"
        style="height: 270px"
        class="u-spacing-mh-l u-spacing-pv-l"
        :config="barChartConfig"
        :data="barChartData"
      />
    </div>
    <p class="u-spacing-pt-xl u-font-size-6 u-font-weight-600">
      {{ $t('recommended_action') }}
    </p>
    <div
      v-if="alert.attributes.mean_estimate_value && showPerformanceOverview"
      class="u-display-flex u-flex-align-items-center u-spacing-pv-xs u-color-blue-base"
    >
      <p>
        Estimated incremental margin value (min and max):
        {{ alert.attributes.min_estimate_value | num_format('currency') }} -
        {{ alert.attributes.max_estimate_value | num_format('currency') }}
      </p>
      <el-tooltip
        effect="dark"
        placement="top"
      >
        <div slot="content">
          {{ $t('predictive_oos_help_text') }} <br />
          {{ $t('predictive_oos_min_help_text') }} <br />
          {{ $t('predictive_oos_max_help_text') }}
        </div>
        <rb-icon
          class="rb-icon--medium u-color-blue-base help-fill-size"
          :icon="'help-fill'"
        />
      </el-tooltip>
    </div>
    <p class="u-font-size-4 u-spacing-pt-s u-spacing-pb-s">
      {{ $t('predictive_oos_with_po_rec_text') }}
    </p>
    <div class="detail-view--card">
      <el-table :data="[{}]">
        <el-table-column
          :label="$t('SKU_details')"
          :width="300"
        >
          <div slot-scope="scope">
            <div>
              <p
                :title="alert.attributes.title"
                class="u-text-overflow-ellipsis"
              >
                {{ alert.attributes.title }}
              </p>
              <p>{{ alert.attributes.asin }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('po_code')">
          <div slot-scope="scope">
            <div>
              <p>{{ alert.attributes.po_code }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('vendor_code')">
          <div slot-scope="scope">
            <div>
              <p>{{ alert.attributes.vendor_code }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('ordered_on')">
          <div slot-scope="scope">
            <div>
              <p>{{ formatter(alert.attributes.ordered_on, 'date') }}</p>
            </div>
          </div>
        </el-table-column>
        <el-table-column
          :label="$t('delivery_window')"
          :width="200"
        >
          <div slot-scope="scope">
            <div>
              <p>{{ alert.attributes.delivery_window }}</p>
            </div>
          </div>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import chart from '@/components/basic/chart';
import starRating from '@/components/widgets/starRatings';
import keyValuePair from '@/components/basic/keyValuePair';

export default {
  components: {
    chart,
    starRating,
    keyValuePair
  },
  props: {
    alert: {
      type: Object,
      default: null
    },
    // eslint-disable-next-line vue/require-default-prop
    formatter: {
      type: Function
    }
  },
  data() {
    return {
      showChart: false,
      promoDesc: [],
      barChartConfig: {
        xAxisType: 'timeseries',
        load: false,
        chartOptions: {
          timeseries: {},
          axes: {
            // 'Price on amazon.com': 'y2'
          },
          tooltip_format: {
            'Unit Sales': {
              roundoff: 0
            },
            'Promo Timeline': function (value, ratio, id, index) {
              return this.promoDesc[index];
            }.bind(this)
          },
          point_format: {
            'Promo Timeline': function (data) {
              if (this.promoDesc[data.index] !== '') {
                return 5;
              } else {
                return 2;
              }
            }.bind(this)
          }
        }
      },
      barChartData: [],
      showPerformanceOverview: Vue.options.filters.config_check(
        'feature.recommendations.performanceOverview.enable'
      )
    };
  },
  mounted() {
    var xs = {
      'Unit Sales': 'Unit Sales_x',
      // 'Price on amazon.com': 'Price on amazon.com_x',
      'Promo Timeline': 'Promo Timeline_x'
    };
    var dataArray = [];
    var dateArray = [];
    var chartData = [];
    var entry;

    var offerPriceTrend = [];
    if (this.alert.attributes.offer_price_trend) {
      offerPriceTrend = JSON.parse(this.alert.attributes.offer_price_trend);
      if (offerPriceTrend.length) {
        offerPriceTrend = offerPriceTrend.filter((item) => {
          return item.retailer_site !== undefined;
        });
        dataArray = ['Price on ' + offerPriceTrend[0].retailer_site];
        dateArray = ['Price on ' + offerPriceTrend[0].retailer_site + '_x'];
        xs['Price on ' + offerPriceTrend[0].retailer_site] = [
          'Price on ' + offerPriceTrend[0].retailer_site + '_x'
        ];
        this.barChartConfig.chartOptions.axes[
          'Price on ' + offerPriceTrend[0].retailer_site
        ] = 'y2';
        this.barChartConfig.chartOptions.tooltip_format[
          'Price on ' + offerPriceTrend[0].retailer_site
        ] = {
          roundoff: 2,
          pre: 'currency'
        };
        for (entry of offerPriceTrend) {
          if (entry.offer_price === undefined) continue;
          dataArray.push(entry.offer_price);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
        xs[dataArray[0]] = dateArray[0];
      }
    }

    var promoTrend = [];
    if (this.alert.attributes.promo_timeline_trend) {
      promoTrend = JSON.parse(this.alert.attributes.promo_timeline_trend);
      if (promoTrend.length) {
        promoTrend = promoTrend.sort((a, b) => {
          if (a.Date < b.Date) return -1;
          else if (a.Date > b.Date) return 1;
          return 0;
        });
        dataArray = ['Promo Timeline'];
        dateArray = ['Promo Timeline_x'];
        for (entry of promoTrend) {
          if (entry.promo_desc === undefined || entry.promo_desc === 'NA')
            continue;
          var desc = JSON.parse(entry.promo_desc);
          if (desc.length === 0) continue;
          dataArray.push(1);
          dateArray.push(entry.Date);
          this.promoDesc.push(desc.join(', '));
        }
        chartData.push(dataArray, dateArray);
      }
    }

    var salesTrend = [];
    if (this.alert.attributes.unit_sales_trend) {
      salesTrend = JSON.parse(this.alert.attributes.unit_sales_trend);
      if (salesTrend.length) {
        dataArray = ['Unit Sales'];
        dateArray = ['Unit Sales_x'];
        for (entry of salesTrend) {
          if (entry.unit_sales === undefined) continue;
          dataArray.push(entry.unit_sales);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
      }
    }

    this.barChartConfig.chartOptions.timeseries = xs;
    this.barChartData = chartData;
    this.showChart = true;
  }
};
</script>

<style scoped lang="css">
.icon--task .rb-icon {
  height: 16px;
  width: 16px;
}
.icon--task .rb-icon::before {
  font-size: 16px;
}
</style>
