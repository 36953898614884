<template>
  <div>
    <div class="u-font-size-6 u-spacing-p-l">
      <div class="u-spacing-pb-xs u-text-case-upper u-font-weight-600">
        {{ heading }}
      </div>
      <radioSelectionWithInput
        class="u-spacing-pt-m"
        :radio-config="radioConfig"
        default-radio-selected="set_new"
        :error-message="bidErrorMessage"
        :warning-message="bidErrorMessage"
        :show-input-title="showInputTitle"
        :show-tooltip="selections.length > 1"
        :bid-suggestion="bidSuggestions"
        @onUpdate="onBidUpdates"
        @radioBidSuggestionData="handleRadioBidSuggestionData"
      />
    </div>
    <div
      class="u-spacing-pv-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
    >
      <footerButtons
        :disable-left-btn="
          !(
            bidValue &&
            bidValue.inputValue &&
            (bidValue.inputValue > 0 ||
              bidValue.inputValue === 'ZZ' ||
              bidValue.inputValue === 'XX') &&
            bidValue.inputValue.length
          ) || bidErrorMessage.length > 0
        "
        class="u-spacing-ph-l"
        :button-size="'s'"
        :hollow-hover-button-left="false"
        left-button-type="filled"
        btn-left-text="Apply"
        @onClickBtnRight="closeActionBar"
        @onClickBtnLeft="onSaveBid"
      />
    </div>
  </div>
</template>

<script>
import radioSelectionWithInput from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/radioSelectionWithInput.vue';

const footerButtons = () =>
  import('@/pages/entity_details/common_components/footerButtons.vue');

export default {
  components: {
    radioSelectionWithInput,
    footerButtons
  },
  props: {
    title: {
      type: String,
      required: true
    },
    selectedEntity: {
      type: String,
      required: true
    },
    customCreatePayload: {
      type: Function,
      default: null
    },
    handleApply: {
      type: Function,
      default: () => {
        return () => ({});
      }
    },
    actionType: {
      type: String,
      default: 'kroger_promoteiqProductBidChange'
    },
    radioConfig: {
      default: () => {
        return [];
      },
      type: Array
    },
    getSingleSelectBidSuggestions: {
      type: Function,
      default: () => {}
    },
    getMultiSelectBidSuggestions: {
      type: Function,
      default: () => {}
    },
    showInputTitle: {
      type: Boolean
    },
    selections: {
      type: Array,
      default: () => {
        return [];
      }
    },
    currentBidKey: {
      type: String,
      default: null
    },
    defaultMinBid: {
      type: Number,
      default: null
    },
    minBidKey: {
      type: String,
      default: null
    },
    defaultMaxBid: {
      type: Number,
      default: null
    },
    maxBidKey: {
      type: String,
      default: null
    },
    aggregator: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      bidValue: null,
      bidSuggestionRadioValue: null,
      suggestedBidCheck: {}
    };
  },
  computed: {
    heading() {
      return `${this.title} (${this.selections.length} ${this.selectedEntity}${
        this.selections.length > 1 ? `s` : ''
      } selected)`;
    },
    bidErrorMessage() {
      if (!this.bidValue?.inputValue) {
        return '';
      }
      const singleSelection = this.selections.length === 1;
      const item = this.selections[0];
      let updatedCurrentBid = this.transformBid(
        item?.data?.[this.currentBidKey]
      );
      const minimumValue =
        (singleSelection && item?.data?.[this.minBidKey]) || this.defaultMinBid;
      const maximumValue =
        (singleSelection && item?.data?.[this.maxBidKey]) || this.defaultMaxBid;
      if (minimumValue && +updatedCurrentBid < minimumValue) {
        return singleSelection
          ? `Custom bid should be greater or equal to $${minimumValue}`
          : `Custom bid should be greater or equal to ${this.defaultMinBid}`;
      }
      if (maximumValue && +updatedCurrentBid > maximumValue) {
        return singleSelection
          ? `Custom bid should be lesser or equal to $${maximumValue}`
          : `Custom bid should be lesser or equal to ${this.defaultMaxBid}`;
      }
      return '';
    },
    bidSuggestions() {
      this.bidValue = null;
      this.getBidSuggestions();
      return this.selections.length > 1
        ? this.multiSelectBidSuggestions
        : this.singleSelectBidSuggestions;
    }
  },
  created() {
    this.getBidSuggestions();
  },
  methods: {
    getBidSuggestions() {
      this.singleSelectBidSuggestions =
        this.getSingleSelectBidSuggestions(this);
      this.multiSelectBidSuggestions = this.getMultiSelectBidSuggestions(this);
    },
    getUpdatedBidValue(item) {
      const oldBid = item?.data?.[this.currentBidKey] || 0;
      let suggestBidParam = null;
      if (this.bidSuggestionRadioValue === 'currentBid') {
        suggestBidParam = this.currentBidKey;
      } else if (this.bidSuggestionRadioValue === 'suggestedBid') {
        suggestBidParam = this.multiSelectBidSuggestions.valueKey;
      } else if (this.bidSuggestionRadioValue === 'suggestedMinBid') {
        suggestBidParam = this.multiSelectBidSuggestions.secondValueKey;
      }
      return this.transformBid(oldBid, item, suggestBidParam);
    },
    handleRadioBidSuggestionData(data) {
      this.bidSuggestionRadioValue = data;
    },
    createActionPayload(item) {
      let updatedBid = this.getUpdatedBidValue(item);
      return {
        campaignId: item?.data?.[`${this.aggregator}_cm_sku_campaign_id`],
        content_id: item?.data?.[`${this.aggregator}_cm_sku_content_id`],
        sku_bid: updatedBid
      };
    },
    createPayload() {
      const payload = [];
      this.selections.forEach((item) => {
        const actionPayload = this.createActionPayload(item);
        const viewPayload = {
          new_sku_bid: actionPayload.sku_bid?.toString(),
          old_sku_bid: item?.data?.[this.currentBidKey]?.toString()
        };
        const payloadObject = {
          widget: 'amsSkus',
          actionType: this.actionType,
          actionSource: {
            pageUrl: window.location.href
          },
          primaryKey: item?.data?.[`${this.aggregator}_cm_sku_sku_id`],
          actionPayload,
          viewPayload
        };
        payload.push(payloadObject);
      });
      return payload;
    },
    async onSaveBid() {
      const payload = this.customCreatePayload?.(this) ?? this.createPayload();
      this.handleApply(this, payload);
    },
    transformBid(oldBid, selection, key) {
      let delta = parseFloat(this.bidValue?.inputValue);
      if (this.bidValue?.radioValue === 'set_new') {
        if (
          this.bidValue?.inputValue === 'ZZ' ||
          this.bidValue?.inputValue === 'XX'
        ) {
          return parseFloat(selection?.data?.[key]);
        }
        return delta;
      }
      if (this.bidValue?.dropdownValue === 'percentage') {
        delta = oldBid * (delta / 100);
      }
      if (this.bidValue?.radioValue === 'increase') {
        return parseFloat((oldBid + delta).toFixed(2));
      } else {
        return parseFloat((oldBid - delta).toFixed(2));
      }
    },
    closeActionBar() {
      this.$emit('closePanelMethod');
    },
    onBidUpdates(updatedValues) {
      this.bidValue = updatedValues;
    }
  }
};
</script>
