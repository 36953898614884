<template>
  <div
    class="pvpTimeseriesSwitchWrapper u-display-flex u-flex-align-items-center u-font-size-7 u-spacing-mr-s"
    data-cy="pvpTimeseriesSwitchStrip"
  >
    <rb-switch
      :id="pvpId"
      :true-value="true"
      :false-value="false"
      :value="pvpSwitchValue"
      :native-value="pvpSwitchValue"
      :disabled="disabled"
      class="u-width-auto"
      size="small"
      @input="switchToggled"
    />
    <slot name="label">
      <label :for="pvpId"
        ><span
          class="u-color-grey-lighter u-spacing-mr-xs u-cursor-pointer"
          data-cy="plotTrendlineTxt"
          >{{ label.text }}</span
        >
        <span
          class="u-color-grey-base u-cursor-pointer"
          data-cy="plotTrendlineDate"
          >{{ label.dateText }}</span
        ></label
      >
    </slot>
  </div>
</template>

<script>
export default {
  name: 'PvpTimeseriesSwitch',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    label: {
      type: Object,
      default: () => ({
        text: '',
        dateText: ''
      })
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pvpSwitchValue: false,
      pvpId: 'pvpSwitchId'
    };
  },
  created() {
    if (this.label.text === 'Compare to Yesterday') {
      this.pvpSwitchValue = false;
      return;
    }
    this.pvpSwitchValue = this.value;
  },
  methods: {
    switchToggled() {
      this.pvpSwitchValue = !this.pvpSwitchValue;
      this.$emit('onSwitchChange', this.pvpSwitchValue);
    }
  }
};
</script>

<style lang="css">
.pvpTimeseriesSwitchWrapper {
  height: 24px;
  padding: 6px 8px;
  border-radius: 13.5px;
  border: solid 1px #e9eaeb;
}
.pvpTimeseriesSwitchWrapper:hover {
  cursor: pointer;
}
.pvpTimeseriesSwitchWrapper:hover,
.pvpTimeseriesSwitchWrapper:focus {
  border: 1px solid #caccce;
}

.rb-checkbox {
  width: auto;
}

.rb-switch {
  input[type='checkbox'] + .small {
    width: 15px;
    height: 4px;
    padding: 3px;
    &:before {
      width: 8px;
      height: 8px;
    }
  }
}
</style>
