<template>
  <div :class="['u-width-' + componentWidth]">
    <div
      class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-flex-start u-flex-wrap-yes metricListClass"
    >
      <div
        class="u-display-flex u-flex-wrap-yes u-flex-align-items-stretch"
        :class="['u-width-' + componentWidth]"
      >
        <slot name="pre-metric" />
        <div
          v-for="(val, index) in localMetricDisplayList"
          :key="index"
          :data-cy="`metricName-${val.keyName}`"
          :val="val"
          :class="[
            `${val.keyName}-metric`,
            { 'disabled u-pointer-events-none': val.disabled },
            { 'metric-card-hover-trigger': true },
            { 'u-spacing-mt-l': marginTopLarge },
            { 'u-spacing-mt-s': marginTopSmall }
          ]"
          @click.stop="selectNewMetric(index, val)"
        >
          <div
            v-if="!showLegendsAsChips || showChart"
            data-cy="metricCards"
            class="custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative u-cursor-pointer workbench-hover-card"
            :style="getChartLegendComputedStyle(val.key, index)"
          >
            <div
              v-if="computedSelectedMetric[val.key] && !fillMetric"
              class="active-metric-card"
              data-cy="activeMetricCard"
              :style="{
                'background-color':
                  metricColors[val.key] || computedColorPattern[index]
              }"
            />
            <div
              v-else
              class="metric-card-hover"
              data-cy="metricCardHover"
              :style="
                metricColors[val.key]
                  ? { 'background-color': 'transparent' }
                  : { 'background-color': computedColorPattern[index] }
              "
            />
            <slot
              name="customLegend"
              :val="{
                additionalData: val.additionalData,
                key: val.key,
                metricData: (metricData || {})[val.key],
                metricConfig: (metricConfig || {})[val.key]
              }"
            />
            <div v-if="defaultLegends">
              <div
                v-if="allowMetricRemoval(index, val)"
                class="cross-button-holder u-spacing-mb-xxs"
              >
                <div
                  class="cross-icon"
                  data-cy="removemetric"
                  @click.stop="deleteMetric(index)"
                >
                  <rb-icon
                    class="rb-icon--xx-small u-cursor-pointer u-color-grey-lighter"
                    :icon="'cross'"
                  />
                </div>
              </div>
              <div
                class="u-spacing-ph-m"
                :class="[
                  allowMetricRemoval(index, val)
                    ? ''
                    : 'u-spacing-pt-s u-spacing-mt-s override-padding',
                  showLegendsAsChips ? 'u-spacing-pb-s u-spacing-mt-s' : '',
                  metricBottomPadding
                ]"
              >
                <div>
                  <RbSelectV2
                    v-if="val && !removeDropdown && enableWatefall"
                    width="300px"
                    :search-enable-threshold="10"
                    class="u-spacing-mr-m"
                    :context="[index]"
                    :send-details="true"
                    :on-close="metricSelected"
                    :options="computedMetricsList"
                    :class-name="'tableSelect'"
                    :group-by-key="'metricGroup'"
                    :search-enabled="true"
                    :is-waterfall-dropdown="true"
                    :note-generator-metric-tooltip="noteGeneratorMetricTooltip"
                    data-cy="metricDropDown"
                  >
                    <div
                      slot="trigger"
                      :class="[
                        !ellipsisOnOverflow &&
                          'u-display-flex u-flex-align-items-center u-cursor-pointer'
                      ]"
                    >
                      <div
                        v-tippy="tippy"
                        class="u-display-flex u-flex-align-items-center"
                        data-cy="MetricTooltip"
                        :title="
                          !showCustomTooltip
                            ? val.tooltip
                              ? val.tooltip
                              : 'NA'
                            : ''
                        "
                      >
                        <span
                          :class="[
                            metricFontSize,
                            ellipsisOnOverflow &&
                              'overflow-styles u-display-block'
                          ]"
                          :data-cy="`metricValueKey-${index}-${val.key}`"
                          >{{ val.key }}</span
                        >
                        <rb-icon
                          class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                          :icon="'caret-down'"
                          data-cy="metricDropdownIcon"
                        />
                      </div>
                    </div>
                    <template
                      slot="item"
                      slot-scope="option"
                    >
                      <div class="u-display-flex u-flex-direction-column">
                        <div class="u-display-flex u-flex-align-items-center">
                          <div class="u-width-100">
                            <div
                              class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
                            >
                              <div class="u-spacing-pr-m u-width-100">
                                {{ option.label || option.name }}
                              </div>
                            </div>
                            <div
                              v-if="filterNotApplied(option.filterRequired)"
                              class="u-font-size-6 u-font-weight-600 u-spacing-mt-xs"
                              data-cy="filterNotAppliedNote"
                            >
                              {{ option.filterNotAppliedNote }}
                            </div>
                          </div>
                          <span
                            v-if="option.children"
                            class="rb-icon u-display-flex u-flex-align-items-center u-flex-justify-content-center rb-icon--small icon-arrow-up"
                            style="transform: rotate(90deg)"
                          />
                        </div>
                      </div>
                    </template>
                  </RbSelectV2>
                  <rb-select
                    v-if="val && !removeDropdown && !enableWatefall"
                    width="300px"
                    :search-enable-threshold="searchEnableThreshold"
                    class="u-spacing-mr-m"
                    :context="[index]"
                    :send-details="true"
                    :on-close="metricSelected"
                    :options="computedMetricsList"
                    :class-name="'campaigns-select'"
                    :search-enabled="true"
                  >
                    <div
                      slot="trigger"
                      :class="[
                        !ellipsisOnOverflow &&
                          'u-display-flex u-flex-align-items-center u-cursor-pointer'
                      ]"
                    >
                      <div
                        v-if="!isMetricToolTipEnabled"
                        v-tippy="tippy"
                        class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
                        :title="val.tooltip ? val.tooltip : 'NA'"
                      >
                        <template>
                          <div v-if="showStar">
                            <rb-icon
                              :icon="iconType"
                              class="u-flex-0 rb-icon--medium u-spacing-mr-xxs"
                              :style="{
                                color:
                                  metricColors[val.key] ||
                                  computedColorPattern[index]
                              }"
                            />
                          </div>
                        </template>
                        <span
                          :class="[
                            metricFontSize,
                            ellipsisOnOverflow &&
                              'overflow-styles u-display-block'
                          ]"
                          data-cy="metricTitle"
                          >{{ val.key }}</span
                        >
                        <rb-icon
                          class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                          :icon="'caret-down'"
                          data-cy="metricDropdownIcon"
                        />
                      </div>
                      <div
                        v-else
                        class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
                      >
                        <rb-tooltip-header
                          v-if="!showCustomTooltip"
                          id="tooltipTemplate"
                          :title="val.key ? val.key : 'NA'"
                          :message="
                            val.tooltip ? modifyMessage(val.tooltip) : 'NA'
                          "
                        />
                        <div
                          v-tippy="setTooltip.tippyOptions"
                          class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger"
                          :title="val.tooltip ? val.tooltip : 'NA'"
                        >
                          <span
                            :class="[
                              metricFontSize,
                              ellipsisOnOverflow &&
                                'overflow-styles u-display-block'
                            ]"
                            data-cy="metricTitle"
                            >{{ val.key }}</span
                          >
                          <rb-icon
                            class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                            :icon="'caret-down'"
                            data-cy="metricDropdownIcon"
                          />
                        </div>
                      </div>
                    </div>
                    <template
                      slot="item"
                      slot-scope="option"
                    >
                      <div class="u-display-flex u-flex-align-items-center">
                        <div
                          class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
                        >
                          <p
                            class="u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                            data-cy="metric-dropdown-option"
                          >
                            {{ option.title }}
                          </p>
                        </div>
                      </div>
                    </template>
                  </rb-select>
                  <div
                    v-if="removeDropdown && !isMetricToolTipEnabled"
                    v-tippy="tippy"
                    class="u-display-flex u-flex-align-items-center"
                    :title="val.tooltip ? val.tooltip : 'NA'"
                  >
                    <rb-icon
                      v-if="showIconsBeforeMetric"
                      :style="{
                        color:
                          metricColors[val.key] || computedColorPattern[index]
                      }"
                      class="rb-icon--medium u-spacing-mr-xs"
                      :icon="metricIcons[val.keyName] || ''"
                    />
                    <div class="u-display-flex">
                      <span class="u-font-size-5 u-line-height-1">{{
                        val.key
                      }}</span>
                    </div>
                  </div>
                  <div
                    v-else-if="removeDropdown && isMetricToolTipEnabled"
                    class="u-display-flex u-flex-align-items-center"
                  >
                    <rb-tooltip-header
                      id="tooltipTemplate"
                      :title="val.key ? val.key : 'NA'"
                      :message="val.tooltip ? modifyMessage(val.tooltip) : 'NA'"
                    />
                    <div
                      v-tippy="setTooltip.tippyOptions"
                      class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger4"
                      :title="val.tooltip ? val.tooltip : 'NA'"
                    >
                      <span
                        :class="[
                          metricFontSize,
                          ellipsisOnOverflow &&
                            'overflow-styles u-display-block'
                        ]"
                        data-cy="metricTitle"
                        >{{ val.key }}</span
                      >
                    </div>
                  </div>
                </div>
                <section class="u-width-100">
                  <slot
                    v-if="hasPostCustomMetric"
                    name="customMetric"
                    :value="{
                      metric: val,
                      metricData: (metricData || {})[val.key],
                      metricConfig: (metricConfig || {})[val.key]
                    }"
                  />
                  <metric
                    v-if="
                      hasPVP &&
                      (metricData || {})[val.key] &&
                      !hasPostCustomMetric
                    "
                    :hide-zero="hideZero"
                    :size="metricSize"
                    class="u-display-inline-flex u-spacing-mt-s"
                    :config="(metricConfig || {})[val.key]"
                    :data="(metricData || {})[val.key]"
                    :use-column-flex-direction="showMetricValInMultipleLine"
                  >
                    <template v-slot:postTag1Metric>
                      <slot :name="chartLegendPostTagSlotNames[index]"></slot>
                    </template>
                  </metric>
                </section>
                <span
                  v-if="!val && !(metricData || {})[val.key]"
                  class="u-color-grey-light u-font-size-5"
                  >No Data</span
                >
              </div>
            </div>
          </div>
          <customTippy
            v-if="showCustomTooltip"
            id="customTooltip"
            style="max-width: '10px'"
            :icon="val.icon"
            :pre-text="val.preText"
            :title="val.label"
            :text="val.tooltip"
            :note="
              val.metadata && val.metadata.tooltipNote
                ? val.metadata.tooltipNote
                : undefined
            "
            :one-liner="metricTooltipOneLiner"
          />
          <ChartChipLegends
            v-else-if="showLegendsAsChips && !showChart"
            :val="val"
            :hide-zero="hideZero"
            :show-chart="showChart"
            :remove-dropdown="removeDropdown"
            :metric-data="metricData"
            :index="index"
            :ellipsis-on-overflow="ellipsisOnOverflow"
            :chart-chip-options="computedMetricsList"
            :is-metric-tool-tip-enabled="isMetricToolTipEnabled"
            :metric-font-size="metricFontSize"
            :enable-watefall="enableWatefall"
            :metric-config="metricConfig"
            :modify-tooltip-message="modifyTooltipMessage"
            :has-post-custom-metric="hasPostCustomMetric"
            :computed-selected-metric="computedSelectedMetric"
            :bg-color="metricColors[val.key] || computedColorPattern[index]"
          />
          <div
            v-if="val.seprator"
            class="seprator"
          />
        </div>
        <div
          v-if="
            totalEntityCount - localMetricDisplayList.length > 0 &&
            showMetricCount
          "
          class="u-font-size-4 u-spacing-ph-s u-spacing-pv-xs u-spacing-mr-m u-spacing-mt-l u-flex-align-self-center u-color-grey-mid-light"
        >
          & {{ totalEntityCount - localMetricDisplayList.length }}
          {{ metricType }} (s)
        </div>
        <div
          v-if="
            !disableAddNewMetric &&
            localMetricDisplayList &&
            localMetricDisplayList.length < computedAddMetricLimit
          "
          class="u-spacing-mt-l u-display-flex"
        >
          <RbSelectV2
            v-if="enableWatefall"
            width="300px"
            :search-enable-threshold="10"
            class="u-spacing-mr-m"
            :send-details="true"
            :on-close="addNewMetric"
            :options="computedMetricsList"
            :class-name="'tableSelect'"
            :group-by-key="'metricGroup'"
            :search-enabled="enableLegendSearch"
            :is-waterfall-dropdown="true"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-cursor-pointer u-height-100"
            >
              <AddMetricTile />
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <WaterfallDropdownOption
                :show-big-tooltip="showBigTooltip"
                :option="option"
              />
            </template>
          </RbSelectV2>
          <rb-select
            v-else
            width="300px"
            :search-enable-threshold="200"
            class="u-spacing-mr-m"
            :send-details="true"
            :on-close="addNewMetric"
            :options="computedMetricsList"
            :search-enabled="enableLegendSearch"
            :class-name="'campaigns-select u-height-100 select-trigger-height-100'"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-cursor-pointer u-height-100"
            >
              <div
                class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger u-height-100"
              >
                <div
                  data-cy="addMetricTile"
                  class="metric-card-hover-trigger u-height-100"
                >
                  <div
                    data-cy="metricCard"
                    class="custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative u-cursor-pointer workbench-hover-card u-height-100"
                  >
                    <div
                      class="u-spacing-p-m u-display-flex u-flex-align-items-center u-flex-justify-content-center u-height-100"
                      style="min-height: 75px; min-width: 150px"
                    >
                      <span
                        class="u-display-flex u-font-size-5 u-color-grey-lighter"
                      >
                        <rb-icon
                          class="rb-icon--small u-cursor-pointer u-color-grey-lighter u-spacing-mr-xs"
                          :icon="'add-circle-fill'"
                        />Add metric
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <div class="u-display-flex u-flex-align-items-center">
                <div
                  class="u-overflow-hidden u-flex-direction-row u-display-flex u-flex-align-items-center u-width-100 u-spacing-pt-s u-spacing-pb-s u-spacing-pr-m u-spacing-pl-m u-flex-justify-content-space-between"
                >
                  <p
                    class="u-color-grey-base u-text-overflow-ellipsis u-font-size-5 u-text-case-title"
                    data-cy="metric-dropdown-option"
                  >
                    {{ option.title }}
                  </p>
                </div>
              </div>
            </template>
          </rb-select>
        </div>
        <slot name="post-legends" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="css">
.metric-card-hover-trigger {
  display: flex;
  align-items: center;
}
.metric-card-hover-trigger:hover .cross-icon {
  visibility: visible !important;
}
.cross-button-holder {
  display: flex;
  justify-content: flex-end;
}

.cross-button-holder .cross-icon {
  visibility: hidden;
  padding-right: 0.8rem;
  padding-top: 0.8rem;
}

.seprator {
  width: 3px;
  height: 40px;
  border-radius: 1.5px;
  margin-left: 1.6rem;
  margin-right: 2.4rem;
  background-color: #e9eaeb;
}
.u-flex-align-item-flex-end {
  align-items: flex-end;
}
</style>

<script>
import chart from './chart.vue';
import widgetsUtil from '@/components/widgetMixin';
import metric from '@/components/basic/metric';
import { differenceBy } from 'lodash';
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
import AddMetricTile from '@/components/basic/atoms/chart-legends/add-metric-tile.vue';
import WaterfallDropdownOption from '@/components/basic/atoms/chart-legends/waterfall-dropdown-option.vue';
import customTippy from '@/components/pages/insights/amazon/share-of-voice/atoms/customTippy.vue';
import ChartChipLegends from '@/components/basic/ChartChipLegends.vue';
import { eventBus } from '@/utils/services/eventBus';
export default {
  components: {
    chart,
    metric,
    RbSelectV2,
    AddMetricTile,
    WaterfallDropdownOption,
    customTippy,
    ChartChipLegends
  },
  mixins: [widgetsUtil],
  props: {
    showMetricCount: {
      type: Boolean,
      default: false
    },
    metricType: {
      type: String,
      default: ''
    },
    totalEntityCount: {
      type: Number,
      default: 0
    },
    noteGeneratorMetricTooltip: {
      type: Function,
      default: () => {}
    },
    showBigTooltip: {
      type: Boolean,
      default: false
    },
    searchEnableThreshold: {
      type: Number,
      default: 200
    },
    hideZero: {
      type: Boolean,
      default: true
    },
    metricsMaxWidth: {
      type: String,
      default: 'auto'
    },
    ellipsisOnOverflow: {
      type: Boolean,
      default: true
    },
    customStyleObject: {
      type: Object,
      default: () => ({})
    },
    componentWidth: {
      type: Number,
      default: 100
    },
    defaultLegends: {
      type: Boolean,
      default: true
    },
    metricDisplayList: {
      type: Array,
      default: () => []
    },
    metricsList: {
      type: Array,
      default: () => []
    },
    defaultSelectMetric: {
      type: Array,
      default: () => []
    },
    selectedMetricLimit: {
      type: Number,
      default: 2
    },
    metricConfig: {
      type: Object,
      default: () => {}
    },
    metricData: {
      type: Object,
      default: () => {}
    },
    minimumMetric: {
      type: Number,
      default: 2
    },
    hasPVP: {
      type: Boolean,
      default: true
    },
    colorPattern: {
      type: Array,
      default: () => []
    },
    colorObject: {
      type: Object,
      default: null
    },
    disableAddNewMetric: {
      type: Boolean,
      default: false
    },
    removeDropdown: {
      type: Boolean,
      default: false
    },
    emitOnMetricDefaultChange: {
      type: Boolean,
      default: false
    },
    plotOnYAxisOnly: {
      type: Boolean,
      default: false
    },
    enableWatefall: {
      type: Boolean,
      default: false
    },
    additionalData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    hasPostCustomMetric: {
      type: Boolean,
      defalut: false
    },
    metricFontSize: {
      type: String,
      default: 'u-font-size-5'
    },
    metricSize: {
      type: String,
      default: 'l'
    },
    isMetricToolTipEnabled: {
      type: Boolean,
      default: false
    },
    showStar: {
      type: Boolean,
      default: false
    },
    modifyTooltipMessage: {
      type: Boolean,
      default: false
    },
    iconType: {
      type: String,
      default: null
    },
    showIconsBeforeMetric: {
      type: Boolean,
      default: false
    },
    metricIcons: {
      type: Object,
      default: () => {}
    },
    showMetricValInMultipleLine: {
      type: Boolean,
      default: false
    },
    chartLegendPostTagSlotNames: {
      type: Array,
      default: () => []
    },
    showCustomTooltip: {
      type: Boolean,
      default: false
    },
    enableLegendSearch: {
      type: Boolean,
      default: false
    },
    showLegendsAsChips: {
      type: Boolean,
      default: false
    },
    showChart: {
      type: Boolean,
      default: true
    },
    marginTopLarge: {
      type: Boolean,
      default: true
    },
    marginTopSmall: {
      type: Boolean,
      default: false
    },
    metricTopPaddingType: {
      type: String,
      default: 'l'
    },
    metricBottomPaddingType: {
      type: String,
      default: 'm'
    },
    metricTooltipOneLiner: {
      type: Boolean,
      default: true
    },
    metricTooltipTheme: {
      type: String,
      default: 'dark'
    },
    fillMetric: {
      type: Boolean,
      default: false
    },
    restrictSingleMetricDeselection: {
      type: Boolean,
      default: false
    }
  },
  data() {
    if (this.showCustomTooltip) {
      var tippy = {
        html: '#customTooltip',
        placement: 'top',
        interactive: true,
        reactive: true,
        arrow: false,
        theme: this.metricTooltipTheme
      };
    } else {
      var tippy = {
        placement: 'left',
        arrow: false,
        distance: 8,
        boundary: 'viewport'
      };
    }
    return {
      selectedOptionValue: {},
      metricsSelectedIndex: [0, 1],
      chartWkbenchAxes: {},
      selectedMetric: [],
      localMetricDisplayList: [],
      tippy: tippy
    };
  },
  computed: {
    allowMetricRemoval() {
      return (index, val) => {
        if (
          this.restrictSingleMetricDeselection &&
          this.computedSelectedMetric[val.key]
        ) {
          return false;
        }
        return index > this.minimumMetric - 1;
      };
    },
    metricTopPadding() {
      const topPaddingTypeMap = {
        s: 'u-spacing-pt-s',
        m: 'u-spacing-pt-m',
        l: 'u-spacing-pt-l'
      };
      return topPaddingTypeMap[this.metricTopPaddingType] || 'u-spacing-pt-l';
    },
    metricBottomPadding() {
      const bottomPaddingTypeMap = {
        s: 'u-spacing-pb-s',
        m: 'u-spacing-pb-m',
        l: 'u-spacing-pb-l'
      };
      return (
        bottomPaddingTypeMap[this.metricBottomPaddingType] || 'u-spacing-pb-m'
      );
    },
    setTooltip() {
      return {
        id: 'tooltipTemplate',
        tippyOptions: this.showCustomTooltip
          ? {
              html: '#customTooltip',
              placement: 'top',
              interactive: true,
              reactive: true,
              arrow: false,
              maxWidth: 550
            }
          : {
              html: '#' + 'tooltipTemplate',
              theme: 'rb-tooltip-header',
              placement: 'bottom',
              interactive: true,
              reactive: true,
              arrow: false
            }
      };
    },
    computedSelectedMetric() {
      const returnData = {};
      for (let i = 0; i < this.selectedMetric.length; i++) {
        returnData[this.selectedMetric[i].key] = this.selectedMetric[i];
      }
      return returnData;
    },
    computedColorPattern() {
      return this.colorPattern.length > 0
        ? this.colorPattern
        : this.getColorPattern;
    },
    metricColors() {
      if (this.colorObject) {
        return this.colorObject;
      }
      const _colors = {};
      const colors = this.computedColorPattern;
      for (let i = 0; i < this.localMetricDisplayList.length; i++) {
        _colors[this.localMetricDisplayList[i].key] = colors[i];
      }
      return _colors;
    },
    computedAddMetricLimit() {
      // fixes https://boomerang.atlassian.net/browse/PROD-111684,
      // when using patterArray, the colors are mapped to same element in the metrics card, this causes trendlines to dissapear from the chart
      if (this.colorObject) {
        return Object.keys(this.colorObject).length;
      }
      return this.computedColorPattern.length;
    },
    computedMetricsList() {
      const differenceByList = this.selectedMetric.concat(
        this.localMetricDisplayList
      );
      return differenceBy(this.metricsList, differenceByList, (a, b) => {
        return a.key;
      });
    }
  },
  watch: {
    defaultSelectMetric(newValue) {
      this.selectedMetric = [...newValue];
      if (this.emitOnMetricDefaultChange) {
        // When default metric is changed, need to emit the event out.
        this.emitEventOut();
      }
    },
    metricDisplayList(newValue) {
      this.localMetricDisplayList = this.genratingFormatedData(newValue);
      if (this.emitOnMetricDefaultChange) {
        // When default metric is changed, need to emit the event out.
        this.emitEventOut();
      }
    }
  },
  created() {
    this.selectedMetric = [...this.defaultSelectMetric];
    this.localMetricDisplayList = this.genratingFormatedData(
      JSON.parse(JSON.stringify(this.metricDisplayList))
    );
    this.emitEventOut();
  },
  methods: {
    filterNotApplied(filter) {
      // Iff the where clause does not contain the filter required this method returns false
      let dimensionNameValueList =
        this.$store.getters?.getWhereClause?.['dimensionNameValueList'];
      return !(
        dimensionNameValueList &&
        dimensionNameValueList[0]?.dimensionName === filter
      );
    },
    modifyMessage(message) {
      if (!this.modifyTooltipMessage) return message;
      let messageResult = '';
      const updatedMessage = message.split('|');
      updatedMessage.forEach((item) => {
        messageResult = messageResult + item + '\n';
      });
      return messageResult;
    },
    genratingFormatedData(newValue) {
      const returnArr = [];
      for (let i = 0; i < newValue.length; i++) {
        const searchKey = newValue[i].key;
        const indexValue = this.metricsList.findIndex((item) => {
          return item.key === searchKey;
        });
        if (indexValue > -1) {
          returnArr.push({
            ...this.metricsList[indexValue],
            additionalData: this.additionalData[searchKey]
          });
        }
      }
      return returnArr;
    },
    // On Select of New Metric
    selectNewMetric(context, val) {
      if (!this.showChart && this.showLegendsAsChips) {
        return;
      }
      const valueIndex = this.selectedMetric.findIndex((item) => {
        return item.key === val.key;
      });
      if (valueIndex === -1) {
        this.smartPushQueue(val);
        if (this.plotOnYAxisOnly) {
          this.chartWkbenchAxes[this.localMetricDisplayList[context].key] =
            context % 2 === 0 ? 'y' : 'y';
        } else {
          this.chartWkbenchAxes[this.localMetricDisplayList[context].key] =
            context % 2 === 0 ? 'y' : 'y2';
        }
      } else {
        if (this.restrictSingleMetricDeselection) {
          return;
        }
        this.queueDelete(val.key);
      }
    },
    // On Select of new Metric of a card through drop down
    metricSelected(context, val) {
      let optionSelection = val;
      if (val?.treePath?.length > 0) {
        const metricKey = val.selectedOptions[0].metricKey;
        optionSelection = [];
        optionSelection = this.computedMetricsList.filter((item) => {
          return item.alias === metricKey;
        });
      } else if (val.selectedOptions) {
        optionSelection = val.selectedOptions;
      }
      val = optionSelection;
      if (val && val.length > 0) {
        // let selectedIndex = val[0].selectedIndex;
        // Find out the index based on computedMetricsList (Error Due to Search)
        const selectedIndex = this.computedMetricsList.findIndex((item) => {
          return item.key === val[0].key;
        });
        const tempOldMetric = this.localMetricDisplayList[context[0]];
        if (
          this.selectedMetric.findIndex((item) => {
            return item.key === this.computedMetricsList[selectedIndex].key;
          }) === -1
        ) {
          this.localMetricDisplayList[context[0]] =
            this.computedMetricsList[selectedIndex];
          if (this.plotOnYAxisOnly) {
            this.chartWkbenchAxes[this.computedMetricsList[selectedIndex].key] =
              context % 2 === 0 ? 'y' : 'y';
          } else {
            this.chartWkbenchAxes[this.computedMetricsList[selectedIndex].key] =
              context % 2 === 0 ? 'y' : 'y2';
          }
          this.localMetricDisplayList = [...this.localMetricDisplayList];
        }
        this.queueReplace(
          this.localMetricDisplayList[context[0]],
          tempOldMetric
        );
      }
    },
    metricSelectedV2(context, val) {
      if (val && context) {
        [context, val] = [val, context];
        // let selectedIndex = val[0].selectedIndex;
        // Find out the index based on computedMetricsList (Error Due to Search)
        const selectedIndex = this.computedMetricsList.findIndex((item) => {
          return item.key === val.key;
        });
        const tempOldMetric = this.localMetricDisplayList[context];
        if (
          this.selectedMetric.findIndex((item) => {
            return item.key === this.computedMetricsList[selectedIndex].key;
          }) === -1
        ) {
          this.localMetricDisplayList[context] =
            this.computedMetricsList[selectedIndex];
          if (this.plotOnYAxisOnly) {
            this.chartWkbenchAxes[this.computedMetricsList[selectedIndex].key] =
              context % 2 === 0 ? 'y' : 'y';
          } else {
            this.chartWkbenchAxes[this.computedMetricsList[selectedIndex].key] =
              context % 2 === 0 ? 'y' : 'y2';
          }
          this.localMetricDisplayList = [...this.localMetricDisplayList];
        }
        this.queueReplace(this.localMetricDisplayList[context], tempOldMetric);
      }
    },
    // Pushing into the queue and queue is full we removed the last element (First in last out idea)
    smartPushQueue(value) {
      if (this.selectedMetric.length >= this.selectedMetricLimit) {
        const removedValue = this.selectedMetric.shift();
        this.$emit('removedMetric', removedValue);
      }
      this.$emit('addMetric', value);
      this.selectedMetric.push(value);
      this.emitEventOut();
    },
    // Searching for the item in the queue and replacing it with newValue
    queueReplace(newValue, oldValue) {
      const indexLocation = this.selectedMetric.findIndex((item) => {
        return item.key === oldValue.key;
      });
      if (indexLocation === -1) {
        this.smartPushQueue(newValue);
      } else {
        this.$emit('removedMetric', this.selectedMetric[indexLocation]);
        this.selectedMetric[indexLocation] = newValue;
        this.selectedMetric = [...this.selectedMetric];
        this.$emit('addMetric', this.selectedMetric[indexLocation]);
        this.emitEventOut();
      }
    },
    // Removing the element from the queue if found in the array
    queueDelete(value) {
      const valueIndex = this.selectedMetric.findIndex((item) => {
        // console.log(item.key, value)
        return item.key === value;
      });
      if (valueIndex !== -1) {
        this.$emit('removedMetric', this.selectedMetric[valueIndex]);
        this.selectedMetric.splice(valueIndex, 1);
      }
      this.emitEventOut();
    },
    // Just emitting the event
    emitEventOut() {
      const obj = {
        selectedMetric: this.selectedMetric,
        metricColors: this.metricColors,
        metricDisplayList: this.localMetricDisplayList
      };
      this.$emit('selectedList', obj);
    },
    // Adding new metric and pushing into the queue
    addNewMetric(context, _val) {
      let val = _val;
      if (val?.selectedOptions) {
        val = _val.selectedOptions;
        // if waterfall selected _val is an object, and selections array is saved under selectedOptions, updating this way to retain functionalut
      }
      if (val && val.length > 0) {
        // let selectedIndex = val[0].selectedIndex;
        // find out the index in the metricslist
        const index = this.metricsList.findIndex(
          (item) => item.key === val[0].key
        );
        this.localMetricDisplayList.push(this.metricsList[index]);
        this.smartPushQueue(this.metricsList[index]);
      }
    },
    // Deleting the metric and deleting from the queue
    deleteMetric(index) {
      const key = this.localMetricDisplayList[index].key;
      this.localMetricDisplayList.splice(index, 1);
      this.queueDelete(key);
    },
    getChartLegendComputedStyle(key, index) {
      return {
        'border-radius': '4px',
        'max-width': this.metricsMaxWidth,
        border:
          this.fillMetric && this.computedSelectedMetric[key]
            ? '1.5px solid ' +
              (this.metricColors[key] || this.computedColorPattern[index]) +
              ' !important'
            : 'none',
        'background-color':
          this.fillMetric && this.computedSelectedMetric[key]
            ? (this.metricColors[key] || this.computedColorPattern[index]) +
              '10'
            : 'transparent'
      };
    }
  }
};
</script>
<style scoped lang="css">
.overflow-styles {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
