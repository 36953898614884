<template>
  <div>
    <span class="u-font-size-5 u-color-grey-lighter u-font-weight-600"
      >Campaign Types</span
    >
    <section class="u-display-flex u-spacing-mt-s">
      <div
        v-for="(item, index) in campaignTypes"
        :key="index"
        class="u-flex-1 u-max-width-400px u-cursor-pointer container"
        data-cy="selectCampaignType"
        @click="selectedCampaignType(index)"
      >
        {{ item.selected }}
        <div class="u-display-flex u-flex-direction-column">
          <div
            :class="[
              index === selectedIndex
                ? 'u-border-color-blue-base'
                : 'u-border-color-grey-xxx-light'
            ]"
            class="u-border-all u-border-radius-s u-spacing-p-l u-display-flex"
          >
            <span class="u-wdith-20 u-spacing-mr-l u-flex-align-self-center">
              <img
                class="campaign-types-img"
                :src="item.image"
                alt="no budget image"
              />
            </span>
            <div class="u-display-flex u-flex-direction-column">
              <span
                class="u-font-size-5 u-color-grey-base u-font-weight-600"
                :data-cy="`selectCampaignType-${item.title}`"
                >{{ item.title }}</span
              >
              <span
                class="u-font-size-7 u-color-grey-lighter u-spacing-mt-s"
                :data-cy="`campaignTypeDescription-${item.title}`"
                >{{ item.desc }}</span
              >
            </div>
          </div>
          <div
            v-if="index === selectedIndex && item.info"
            class="u-display-flex u-spacing-mt-m u-flex-align-items-center"
          >
            <rb-icon
              class="u-flex-0 rb-icon--medium u-color-grey-lighter"
              :icon="'info-circle-fill'"
            />
            <div
              class="u-font-size-5 u-color-grey-light u-spacing-ml-s"
              data-cy="CampaignTypeInfo"
            >
              {{ item.info }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="(stepConfigs || {}).radioGroupConfigs"
      class="u-display-flex u-spacing-mt-l"
    >
      <div class="u-spacing-mt-m">
        <span
          class="u-font-size-5 u-font-weight-600 u-color-grey-base"
          data-cy="selectTargetingType"
        >
          Select Targeting Type:
        </span>
        <div class="u-width-40">
          <rbRadioGroup
            :radio-options="
              ((stepConfigs || {}).radioGroupConfigs || {}).radioOptions
            "
            :value="stepConfigs.spTargetingType"
            :desc-class="'u-line-height-1_6'"
            :is-column="true"
            data-cy="radioGroupSublabel"
            :container-class="radioContainerClass"
            @onRadioChanged="handleRadioSelection"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import rbRadioGroup from '@/components/ams/campaign_creation/steps/common/rbRadioGroupSubLabel.vue';

export default {
  components: {
    rbRadioGroup
  },
  props: {
    campaignTypes: {
      type: Array,
      default: () => {}
    },
    defaultSelectedIndex: {
      type: Number,
      default: 0
    },
    stepConfigs: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    selectedIndex: 0,
    radioContainerClass: 'global-radio-wrapper u-spacing-mr-l u-spacing-mv-m'
  }),
  created() {
    this.selectedIndex = this.defaultSelectedIndex;
  },
  methods: {
    selectedCampaignType(index) {
      this.selectedIndex = index;
      this.$emit('selectedIndex', index);
    },
    handleRadioSelection(selection) {
      this.$emit('radioSelctionChanged', selection);
    }
  }
};
</script>

<style lang="css" scoped>
.container {
  margin-right: 2.4rem;
}
.container:last-child {
  margin-right: 0;
}
.cursor-block {
  cursor: crosshair;
}
.campaign-types-img {
  height: 55px;
  width: 85px;
}
</style>
