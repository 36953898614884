import HomeV2 from '@/components/homeV2.vue';
import DigitalShelfLanding from '@/pages/digital_shelf/route_config/index.vue';
import DigitalShelfRoutes from '@/pages/digital_shelf/route_config/route.js';
import ToolsAndSettings from '@/pages/toolsAndSettings/route.vue';
import toolsAndSettingsRoutes from '@/pages/toolsAndSettings/route.js';
import campaignManagementRoutes from '@/pages/campaign-management/route.js';
import Invite from '@/components/pages/invite/invite.vue';
import DvtInternalTool from '@/components/pages/ciq-internal-tools/dvt-internal-tool.vue';
import MailValidator from '@/components/pages/ciq-internal-tools/mail-validator';
import productDiagnostics from '@/components/pages/ciq-internal-tools/product-diagnostics';
import productSLA from '@/components/pages/ciq-internal-tools/product-sla/productSLA.vue';
import productDiagnosticsRoutes from '@/components/pages/ciq-internal-tools/product-diagnostics/route.js';
import internalToolBox from '@/components/pages/ciq-internal-tools/internal-toolbox';
import RecommendationsRoute from '@/pages/recommendations/recommendationsV2/route.js';
import PassThroughRoute from '@/components/widgets/routerView.vue';
import passwordManager from '@/components/pages/ciq-internal-tools/password-manager/passwordManager.vue';
import fileDownload from '@/pages/fileDownload/fileDownload.vue';
import walmartBo from '@/components/pages/businessInsights/index.vue';
import budgetPlanner from '@/components/ams/budgetPlanner/budgetPlanner.vue';
import budgetPlannerRoutes from '@/components/ams/budgetPlanner/route.js';
import RetailLinkConnect from '@/components/pages/ciq-internal-tools/retail-link-connect.vue';
import ReportsLanding from '@/pages/reports/route_config/index.vue';
import ReportsRoutes from '@/pages/reports/route_config/route.js';
import CreateCampaignWalmart from '@/components/ams/campaign_creation/createCampaignWalmart.vue';
import CreateAdgroup from '@/components/ams/ad_group_creation/setup_configs/createAdgroup.vue';
import CampaignCreated from '@/components/ams/campaign_creation/walmart_steps/campaignCreated/index.vue';
import CiqEfundamentalsFAQ from '@/pages/ciq-efundamentals-announcement/index.vue';
import Strategies from '@/components/pages/strategies/strategies.vue';
import CreateStrategy from '@/components/pages/strategies/create/createStrategy.vue';
import reportedIssues from '@/components/widgets/reportedIssues.vue';
import {
  proxyLinksRoute,
  campaignOptimizerChildrenRoutes
} from '@/router/commonRoutePaths.js';
import StrategyPlanningLanding from '@/pages/strategy_and_planning/route_config/index.vue';
import StrategyPlanningRoute from '@/pages/strategy_and_planning/route_config/route.js';
import urlHelper from '@/utils/helpers/url';
import CONSTANTS from '@/utils/constants';

const wrapper = {
  template: '<router-view :key="$route.fullPath" />'
};

const walmartRoutes = {
  path: '/us/walmart/retail',
  name: 'Home',
  component: HomeV2,
  children: [
    {
      path: '',
      name: 'Home',
      redirect() {
        return urlHelper.getDefaultHomeRoute(
          'feature.walmart.home',
          'digital_shelf/market_insights'
        );
      }
    },
    {
      path: 'business_overview/:id?',
      name: 'businessInsights',
      title: 'Business Overview',
      component: walmartBo,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: 'recommendations',
      name: 'Recommendations',
      children: RecommendationsRoute.routes,
      component: PassThroughRoute,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    },
    {
      path: 'strategy_and_planning',
      component: StrategyPlanningLanding,
      children: StrategyPlanningRoute.routes
    },
    {
      path: 'strategy_and_planning',
      component: StrategyPlanningLanding,
      children: StrategyPlanningRoute.routes
    },
    {
      path: 'reports',
      component: ReportsLanding,
      children: ReportsRoutes.routes
    },
    {
      path: 'strategy_and_planning',
      component: StrategyPlanningLanding,
      children: StrategyPlanningRoute.routes
    },
    {
      path: 'digital_shelf',
      name: 'digital_shelf',
      redirect() {
        return 'digital_shelf/market_insights';
      },
      component: DigitalShelfLanding,
      children: DigitalShelfRoutes.routes
    },
    {
      path: 'advertising',
      name: 'advertising',
      redirect() {
        return 'advertising/campaign_optimizer';
      },
      component: PassThroughRoute,
      children: [
        {
          path: 'hourly_bidder',
          component: PassThroughRoute,
          props: true,
          children: [
            {
              path: '',
              name: 'strategies',
              title: 'Hourly Bidder',
              component: Strategies,
              props: true
            },
            {
              path: 'create',
              name: 'create-strategy',
              component: CreateStrategy
            },
            {
              path: 'edit',
              name: 'edit-strategy',
              component: CreateStrategy
            }
          ].map((route) => ({
            ...route,
            meta: {
              productLine: CONSTANTS.PRODUCT_LINE.RMM,
              appendEsDataSetName: true
            }
          }))
        },
        {
          path: 'campaign_management',
          name: 'cmDefaultRedirect',
          component: wrapper,
          children: campaignManagementRoutes.routes
        },
        {
          path: 'budget_optimizer',
          title: 'Budget Optimizer',
          component: budgetPlanner,
          children: budgetPlannerRoutes.routes
        },
        {
          path: 'campaign_optimizer',
          component: PassThroughRoute,
          productRoles: ['MarketingIQ'],
          children: campaignOptimizerChildrenRoutes.map((route) => ({
            ...route,
            meta: { ...route.meta, appendEsDataSetName: true }
          }))
        },
        {
          path: 'create_campaign',
          name: 'create_campaign',
          title: 'Create Campaign',
          component: CreateCampaignWalmart,
          productRoles: ['MarketingIQ'],
          page: 'campaignManagement',
          config: 'campaigns'
        },
        {
          path: 'create_ad_group',
          name: 'create_ad_group',
          title: 'Create Ad Group',
          component: CreateAdgroup,
          productRoles: ['MarketingIQ'],
          page: 'campaignManagement',
          config: 'campaigns'
        },
        {
          path: 'campaign_created',
          name: 'campaign_created',
          title: 'Campaign Creator',
          component: CampaignCreated,
          productRoles: ['MarketingIQ'],
          page: 'campaignManagement',
          config: 'campaigns'
        }
      ]
    },
    {
      path: 'invite',
      name: 'invite',
      component: Invite
    },
    {
      path: 'strategy_and_planning',
      component: StrategyPlanningLanding,
      children: StrategyPlanningRoute.routes
    },
    {
      path: 'retail_link_connect',
      name: 'retail_link_connect',
      component: RetailLinkConnect
    },
    {
      path: 'retail-link-connect',
      name: 'retail-link-connect',
      redirect() {
        return 'retail_link_connect';
      }
    },
    {
      path: 'dvtInternalTool',
      name: 'dvtInternalTool',
      component: DvtInternalTool
    },
    {
      path: 'mailValidator',
      name: 'mailValidator',
      component: MailValidator
    },
    {
      path: 'product_diagnostics',
      name: 'productDiagnostics',
      component: productDiagnostics,
      children: productDiagnosticsRoutes.routes
    },
    {
      path: 'product_sla',
      name: 'productSLA',
      component: productSLA
    },
    {
      path: 'account_manager',
      name: 'passwordManager',
      component: passwordManager
    },
    {
      path: 'reportedIssues',
      name: 'reportedIssues',
      component: reportedIssues
    },
    {
      path: 'internalToolBox',
      name: 'internalToolBox',
      component: internalToolBox
    },
    {
      path: 'tools_and_settings',
      name: 'ToolsAndSettings',
      component: ToolsAndSettings,
      children: toolsAndSettingsRoutes.routes
    },
    {
      path: 'file-download',
      component: fileDownload
    },
    {
      path: 'ciq-efundamentals-announcement',
      name: 'ciq-efundamentals-announcement',
      component: CiqEfundamentalsFAQ
    },
    proxyLinksRoute
  ]
};

export { walmartRoutes };
