var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"u-font-size-6 u-spacing-p-l"},[_c('div',{staticClass:"u-spacing-pb-xs u-text-case-upper u-font-weight-600"},[_vm._v(" "+_vm._s(_vm.heading)+" ")]),_c('radioSelectionWithInput',{staticClass:"u-spacing-pt-m",attrs:{"radio-config":_vm.radioConfig,"default-radio-selected":"set_new","error-message":_vm.bidErrorMessage,"warning-message":_vm.bidErrorMessage,"show-input-title":_vm.showInputTitle,"show-tooltip":_vm.selections.length > 1,"bid-suggestion":_vm.bidSuggestions},on:{"onUpdate":_vm.onBidUpdates,"radioBidSuggestionData":_vm.handleRadioBidSuggestionData}})],1),_c('div',{staticClass:"u-spacing-pv-m u-border-top u-border-color-grey-xxx-light u-border-width-s"},[_c('footerButtons',{staticClass:"u-spacing-ph-l",attrs:{"disable-left-btn":!(
          _vm.bidValue &&
          _vm.bidValue.inputValue &&
          (_vm.bidValue.inputValue > 0 ||
            _vm.bidValue.inputValue === 'ZZ' ||
            _vm.bidValue.inputValue === 'XX') &&
          _vm.bidValue.inputValue.length
        ) || _vm.bidErrorMessage.length > 0,"button-size":'s',"hollow-hover-button-left":false,"left-button-type":"filled","btn-left-text":"Apply"},on:{"onClickBtnRight":_vm.closeActionBar,"onClickBtnLeft":_vm.onSaveBid}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }