const criteoConfigs = {
  postClickWindowOptions: [
    {
      title: '7 Days',
      value: 0
    },
    {
      title: '14 Days (CIQ Default)',
      value: 1
    },
    {
      title: '30 Days (Criteo Default)',
      value: 2
    }
  ],
  postClickProductOptions: [
    {
      title: 'Same product',
      value: 0
    },
    {
      title: 'Same product, same category (CIQ/Criteo Default)',
      value: 1
    },
    {
      title: 'Same product, same category, same brand',
      value: 2
    }
  ],
  postViewWindowOptions: [
    {
      title: 'No Post View (CIQ Default)',
      value: 0
    },
    {
      title: '1 Day (Criteo Default)',
      value: 1
    },
    {
      title: '7 Days',
      value: 2
    },
    {
      title: '14 Days',
      value: 3
    },
    {
      title: '30 Days',
      value: 4
    }
  ],
  tempPostViewProductOptions: {
    0: [
      {
        title: 'Same product(Criteo Default)',
        value: 0
      }
    ],
    1: [
      {
        title: 'Same product (Criteo Default)',
        value: 0
      },
      {
        title: 'Same product, same category',
        value: 1
      }
    ],
    2: [
      {
        title: 'Same product(Criteo Default)',
        value: 0
      },
      {
        title: 'Same product, same category',
        value: 1
      },
      {
        title: 'Same product, same category, same brand',
        value: 2
      }
    ]
  },
  tempPostViewWindowOptions: {
    0: [
      {
        title: 'No Post View (CIQ Default)',
        value: 0
      },
      {
        title: '1 Day (Criteo Default)',
        value: 1
      },
      {
        title: '7 Days',
        value: 2
      }
    ],
    1: [
      {
        title: 'No Post View (CIQ Default)',
        value: 0
      },
      {
        title: '1 Day (Criteo Default)',
        value: 1
      },
      {
        title: '7 Days',
        value: 2
      },
      {
        title: '14 Days',
        value: 3
      }
    ],
    2: [
      {
        title: 'No Post View (CIQ Default)',
        value: 0
      },
      {
        title: '1 Day (Criteo Default)',
        value: 1
      },
      {
        title: '7 Days',
        value: 2
      },
      {
        title: '14 Days',
        value: 3
      },
      {
        title: '30 Days',
        value: 4
      }
    ]
  },

  convertClickWindow: function (val) {
    switch (val) {
      case '7D':
        return '7 Days';
      case '14D':
        return '14 Days (CIQ Default)';
      case '30D':
        return '30 Days (Criteo Default)';
      case '7 Days':
        return '7D';
      case '14 Days (CIQ Default)':
        return '14D';
      case '30 Days (Criteo Default)':
        return '30D';
    }
  },
  convertViewWindow: function (val) {
    switch (val) {
      case 'none':
        return 'No Post View (CIQ Default)';
      case '1D':
        return '1 Day (Criteo Default)';
      case '7D':
        return '7 Days';
      case '14D':
        return '14 Days';
      case '30D':
        return '30 Days';
      case 'No Post View (CIQ Default)':
        return 'none';
      case '1 Day (Criteo Default)':
        return '1D';
      case '7 Days':
        return '7D';
      case '14 Days':
        return '14D';
      case '30 Days':
        return '30D';
    }
  },
  convertProductClickMatch: function (val) {
    switch (val) {
      case 'sameSku':
        return 'Same product';
      case 'sameSkuCategory':
        return 'Same product, same category (CIQ/Criteo Default)';
      case 'sameSkuCategoryBrand':
        return 'Same product, same category, same brand';
      case 'Same product':
        return 'sameSku';
      case 'Same product, same category (CIQ/Criteo Default)':
        return 'sameSkuCategory';
      case 'Same product, same category, same brand':
        return 'sameSkuCategoryBrand';
    }
  },
  convertProductViewMatch: function (val) {
    if (val === undefined) return null;
    switch (val) {
      case 'sameSku':
        return 'Same product (Criteo Default)';
      case 'sameSkuCategory':
        return 'Same product, same category';
      case 'sameSkuCategoryBrand':
        return 'Same product, same category, same brand';
      case 'Same product (Criteo Default)':
        return 'sameSku';
      case 'Same product, same category':
        return 'sameSkuCategory';
      case 'Same product, same category, same brand':
        return 'sameSkuCategoryBrand';
    }
  }
};

export default {
  target: criteoConfigs,
  costco: criteoConfigs,
  freshdirect_criteo: criteoConfigs,
  meijer: criteoConfigs,
  shipt_criteo: criteoConfigs,
  walmart_criteo: criteoConfigs,
  walgreen_criteo: criteoConfigs,
  cvs_criteo: criteoConfigs,
  asda_criteo: criteoConfigs,
  ulta_criteo: criteoConfigs,
  albertsons_criteo: criteoConfigs,
  ubereats_criteo: criteoConfigs,
  target_rms: criteoConfigs
};
