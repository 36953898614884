<template>
  <li
    class="node-tree u-text-align-center u-position-relative pt-40 u-spacing-pb-s pl-5 pr-5"
  >
    <div
      v-if="node.percentShare && node.percentShare !== '100'"
      class="u-position-absolute u-percent-label u-spacing-pt-xs"
      :class="findImpact"
    >
      <img
        alt="correlation icon"
        :src="findImpactImage"
      />
      <div class="u-font-size-5 u-color-grey-dark u-font-weight-600">
        {{ toPercentValue(node.percentShare) }}
      </div>
    </div>
    <div
      class="label u-font-size-5 u-display-inline-block u-border-radius-xl u-border-all u-border-width-s u-border-color-blue-xx-light u-spacing-pt-m u-spacing-pb-m u-spacing-pl-xl u-spacing-pr-xl"
    >
      <div class="u-font-weight-600 u-font-size-4 u-color-grey-light">
        {{ node.nodeLabel }}
      </div>
      <div
        class="u-color-red-base u-font-weight-600 u-spacing-pt-s u-spacing-pb-s"
      >
        <metric
          size="l"
          class="u-display-flex u-flex-justify-content-center"
          :label-color="rcaLabelColor.labelColor"
          :config="metricData.config"
          :data="metricData.data"
        />
      </div>
      <!-- <a class="u-fill-color-blue-base u-font-size-7">
        <img
          class="u-spacing-mr-xs"
          alt="top movers"
          src="https://cdn.rboomerang.com/remcc/top-movers.svg"
        />
        View Top Movers
      </a> -->
    </div>

    <ul
      v-if="node.children && node.children.length"
      class="pt-40 u-position-relative pl-5 pr-5"
      :class="{ 'only-child-node': node.children.length === 1 }"
    >
      <node
        v-for="(child, index) in node.children"
        :key="index"
        :node="child"
      />
    </ul>
  </li>
</template>

<script>
import metric from '@/components/basic/metric.vue';
export default {
  name: 'Node',
  components: {
    metric
  },
  props: {
    node: {
      type: Object,
      default: {}
    }
  },
  computed: {
    metricData() {
      return {
        config: {
          tag1Unit: {
            pre: this.node.unit === 'currency' ? 'currency' : undefined,
            suff: this.node.unit === 'percent' ? '%' : undefined
          },
          tag2Unit: {
            suff: '%'
          }
        },
        data: {
          tag1: this.node.currentVal,
          tag2: this.node.pvpVal
        }
      };
    },
    rcaLabelColor() {
      const labelColor = this.node.isInverted ? '#d7263d' : '#3ea95e';
      return {
        labelColor: labelColor
      };
    },
    findImpact() {
      let impactClass =
        this.node.children && this.node.children.length > 0
          ? 'u-has-children '
          : '';
      if (this.node.percentShare >= 50) impactClass += 'u-high-impact';
      else if (this.node.percentShare >= 30) impactClass += 'u-medium-impact';
      else impactClass += 'u-low-impact';
      return impactClass;
    },
    findImpactImage() {
      let impactImg = 'https://cdn.rboomerang.com/remcc/correlation-';
      if (this.node.percentShare >= 50) impactImg += 'purple.svg';
      else if (this.node.percentShare >= 30) impactImg += 'yellow.svg';
      else impactImg += 'blue.svg';
      return impactImg;
    }
  },
  methods: {
    toPercentValue(val) {
      return val + '%';
    }
  }
};
</script>

<style lang="css">
.tree li {
  float: left;
  list-style-type: none;
}
.tree li:not(:only-child) {
  transform: translateX(-15px);
}
.tree-list > li {
  padding-top: 0;
}
.tree ul.only-child-node::before {
  height: 80px;
}

.tree li::before,
.tree li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 2px dashed #98ccff;
  width: 50%;
  height: 40px;
}
.tree li::after {
  right: auto;
  left: 50%;
  border-left: 2px dashed #98ccff;
}

.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}

.tree li:last-child::before {
  border-right: 2px dashed #98ccff;
  border-radius: 0 5px 0 0;
}
.tree li:first-child::after {
  border-radius: 5px 0 0 0;
}

.tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px dashed #98ccff;
  width: 0;
  height: 40px;
}

.tree li .label {
  text-decoration: none;
  border: 1px solid #ddeeff;
  box-shadow: 0px 4px 16px #0000000f;
}
.tree li .u-percent-label {
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: 1;
  top: -20px;
  width: 4rem;
  height: 4rem;
  box-shadow: 0px 4px 16px #0000000f;
  &.u-has-children {
    right: 45%;
  }
  &.u-medium-impact {
    background-color: #fff4de;
    border: 1px solid #fea800;
    color: #fea800;
  }
  &.u-low-impact {
    background-color: #eefcfe;
    border: 1px solid #26b5d4;
    color: #26b5d4;
  }
  &.u-high-impact {
    background-color: #fcebff;
    border: 1px solid #be0ee0;
    color: #be0ee0;
  }
}
</style>
