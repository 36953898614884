var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"automation-card u-bg-color-grey-white shadows u-border-radius-s u-spacing-p-m"},[_c('div',{staticClass:"card-header u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"},[_c('span',{staticClass:"u-font-size-6 u-color-grey-light u-font-weight-600"},[_vm._v(_vm._s(_vm.cardTitle))]),(
        _vm.isAutomationSupported ||
        _vm.selectedRetailer === 'retail' ||
        _vm.selectedRetailer === 'retail_and_3p'
      )?_c('icon-text',{attrs:{"icon":"bot","icon-classes":"rb-icon--small u-color-grey-light u-spacing-ml-s","text":_vm.isAutomated ? 'Automated by CIQ' : 'Not Automated',"text-classes":"badge-text u-font-size-7 u-color-grey-light u-font-weight-400 u-spacing-mv-xxs u-spacing-mr-s","container-class":_vm.isAutomated
          ? 'u-border-radius-l automation-badge badge-automated'
          : 'u-border-radius-l automation-badge badge-not-automated'}}):_vm._e()],1),(_vm.isAutomationSupported)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"u-display-flex u-flex-direction-column"},[_c('div',{staticClass:"card-summary-data u-display-flex"},[_c('div',{staticClass:"u-display-flex u-spacing-pt-l u-width-100 u-flex-justify-content-space-between"},[_c('div',[_c('metric',{class:['u-font-weight-600', 'u-font-size-3'],attrs:{"size":"l","config":_vm.cardOverviewData['skus_hybrid'].config,"data":_vm.isMetricLoading
                  ? _vm.loadingMetricData
                  : _vm.cardOverviewData['skus_hybrid'].value,"hide-zero":false}}),_c('div',{staticClass:"u-display-flex u-spacing-pt-s"},[_c('span',{on:{"click":function($event){return _vm.scrollToTable()}}},[_c('metric',{class:[
                    'u-font-weight-600',
                    'u-font-size-3',
                    'u-display-inline',
                    'u-text-decoration-underline',
                    'u-cursor-pointer'
                  ],attrs:{"size":"13-12","config":_vm.cardOverviewData['tickets_logged'].config,"data":_vm.isMetricLoading
                      ? _vm.loadingMetricData
                      : _vm.cardOverviewData['tickets_logged'].value,"hide-zero":false}})],1),_c('span',{staticClass:"u-font-size-6 u-spacing-pl-xs u-font-weight-400 u-color-grey-lighter"},[_vm._v("Tickets logged")])])],1),_c('span',{staticClass:"vertical-divider u-spacing-mh-m u-spacing-mv-xxs"}),_c('div',{staticClass:"u-display-flex u-flex-direction-column u-flex-align-items-flex-end"},[_c('metric',{class:['u-font-weight-600', 'u-font-size-3'],attrs:{"size":"l","label-color":_vm.isAutomated ? '#3EA95E' : '#D7263D',"config":_vm.cardOverviewData['revenue_impact_hybrid'].config,"data":_vm.isMetricLoading
                  ? _vm.loadingMetricData
                  : _vm.cardOverviewData['revenue_impact_hybrid'].value,"hide-zero":false}}),_c('div',{staticClass:"u-font-size-6 u-font-weight-400 u-color-grey-lighter u-spacing-pt-s"},[_vm._v(" Revenue impact ")])],1)])]),_c('span',{staticClass:"u-font-size-6 u-font-weight-400 u-color-grey-lighter u-spacing-pt-l"},[_vm._v("SKUs impact vs Revenue Impact")]),_c('div',{staticClass:"trend-chart u-width-100 u-spacing-pt-s",class:_vm.isChartLoading ? 'u-flex-justify-content-center u-display-flex' : ''},[(_vm.isChartLoading)?_c('Loader',{staticClass:"u-bg-color-grey-white",attrs:{"loading":_vm.isChartLoading,"color":'#3fadf7',"size":"6rem","thickness":'.2rem'}}):_c('chartWithGradient',{attrs:{"metric-data":_vm.retailerSpecificChartData,"card-primary-metric-color-code":_vm.chartColor,"metadata":{ name: 'automation_type' },"metrics-list":_vm.chartMetricsList[_vm.selectedRetailer],"chart-config":_vm.chartConfig}})],1),(_vm.selectedRetailer === 'retail_and_3p')?_c('div',{staticClass:"retailer-split u-display-flex u-spacing-pt-m u-flex-justify-content-space-between"},[_c('div',{staticClass:"u-display-flex u-flex-direction-column"},[_c('span',{staticClass:"u-font-size-6 u-font-weight-400 u-color-grey-lighter"},[_vm._v("Retail")]),_c('div',{staticClass:"u-display-flex u-flex-direction-row u-spacing-pt-s"},[_c('metric',{class:[
                'u-font-weight-600',
                'u-font-size-3',
                'u-display-inline'
              ],attrs:{"size":"14-12","config":_vm.cardOverviewData['skus_retail'].config,"data":_vm.isMetricLoading
                  ? _vm.loadingMetricData
                  : _vm.cardOverviewData['skus_retail'].value,"hide-zero":false}}),_c('rb-icon',{staticClass:"rb-icon u-color-grey-xx-light u-spacing-mh-s u-spacing-pb-xxs rb-icon--x-small",attrs:{"icon":"dot"}}),_c('metric',{class:[
                'u-font-weight-600',
                'u-font-size-3',
                'u-display-inline'
              ],attrs:{"size":"14-12","label-color":_vm.isAutomated ? '#3EA95E' : '#D7263D',"config":_vm.cardOverviewData['revenue_impact_retail'].config,"data":_vm.isMetricLoading
                  ? _vm.loadingMetricData
                  : _vm.cardOverviewData['revenue_impact_retail'].value,"hide-zero":false}})],1)]),(_vm.isThreePSupported)?_c('div',{staticClass:"u-display-flex u-flex-direction-column"},[_c('span',{staticClass:"u-font-size-6 u-font-weight-400 u-color-grey-lighter"},[_vm._v("3P")]),_c('div',{staticClass:"u-display-flex u-flex-direction-row u-spacing-pt-s"},[_c('metric',{class:[
                'u-font-weight-600',
                'u-font-size-3',
                'u-display-inline'
              ],attrs:{"size":"14-12","config":_vm.cardOverviewData['skus_3p'].config,"data":_vm.isMetricLoading
                  ? _vm.loadingMetricData
                  : _vm.cardOverviewData['skus_3p'].value,"hide-zero":false}}),_c('rb-icon',{staticClass:"rb-icon u-color-grey-xx-light u-spacing-mh-s u-spacing-pb-xxs rb-icon--x-small",attrs:{"icon":"dot"}}),_c('metric',{class:[
                'u-font-weight-600',
                'u-font-size-3',
                'u-display-inline'
              ],attrs:{"size":"14-12","label-color":_vm.isAutomated ? '#3EA95E' : '#D7263D',"config":_vm.cardOverviewData['revenue_impact_3p'].config,"data":_vm.isMetricLoading
                  ? _vm.loadingMetricData
                  : _vm.cardOverviewData['revenue_impact_3p'].value,"hide-zero":false}})],1)]):_vm._e()]):_vm._e()]),(_vm.selectedRetailer !== '3p')?_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-spacing-pt-m"},[_c('rb-icon',{staticClass:"u-color-pink-base",attrs:{"icon":"ticket"}}),_c('metric',{staticClass:"u-font-size-6 u-spacing-pl-xs u-font-weight-600 u-color-grey-lighter u-cursor-default",attrs:{"size":"13-12","config":_vm.cardOverviewData['attention_tickets'].config,"data":_vm.isMetricLoading
            ? _vm.loadingMetricData
            : _vm.cardOverviewData['attention_tickets'].value,"hide-zero":false}}),_c('span',{staticClass:"u-spacing-pl-xs u-color-grey-lighter u-font-size-6"},[_vm._v("need your attention")])],1):_vm._e()]):_c('div',{staticClass:"u-display-flex u-flex-direction-column u-flex-align-items-center"},[_c('img',{staticClass:"u-spacing-mt-l u-spacing-mb-m",attrs:{"height":"96px","src":"/images/unavailable_report.png","alt":""}}),_c('span',{staticClass:"u-font-size-6 u-color-grey-light u-font-weight-600 u-spacing-mb-s"},[_vm._v("Not Applicable")]),_c('span',{staticClass:"u-font-size-7 u-color-grey-light u-font-weight-400 u-text-align-center"},[_vm._v("The automation type chosen is incompatible with the selected business model filter.")])]),_c('div',{staticClass:"u-display-inline-block"},[(_vm.isAutomationSupported)?_c('div',{staticClass:"u-font-size-7 u-color-blue-base u-font-weight-600 u-cursor-pointer u-display-flex u-flex-align-items-center u-spacing-pt-m",on:{"click":_vm.toggleCard}},[_c('rb-icon',{staticClass:"rb-icon--small u-spacing-mr-s",attrs:{"icon":_vm.isCardExpanded ? 'collapse' : 'full-screen'}}),_c('span',{staticClass:"u-font-size-6"},[_vm._v(" "+_vm._s(_vm.isCardExpanded ? 'Hide details' : 'View more details')+" ")])],1):_c('div',{staticClass:"u-font-size-7 u-color-grey-lighter u-font-weight-600 u-cursor-default u-display-flex u-flex-align-items-center u-spacing-pt-m"},[_c('rb-icon',{staticClass:"rb-icon--small u-spacing-mr-s",attrs:{"icon":"full-screen"}}),_c('span',{staticClass:"u-spacing-mt-xxs u-font-size-6"},[_vm._v(" View more details ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }