import HttpService from '@/utils/services/http-service';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';

export const BudgetPacingAPI = (payload) => {
  const modifiedPayload = {
    cubeName: 'budget_planner_dashboard_v2',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: false,
    pvpenabled: false,
    yoyenabled: false,
    measuresList: ['spend_vs_budget'],
    groupByDimensionsList: [],
    orderByList: [],
    where: {
      dimensionNameValueList: [],
      date: {
        from: payload.from,
        to: payload.to
      }
    },
    customAPIDecisionVars: {
      skipTimeSeriesMetric: true,
      system: 'budget_optimizer_omni'
    }
  };
  return HttpService.post('EXECUTE_CUBE_API', modifiedPayload);
};

export const WastedAdSpendAPI = (payload) => {
  const modifiedPayload = {
    cubeName: 'ams_search_term_campaign_workbench',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesDimension: 'report_date',
    timeseriesRollupBy: 'DAY',
    daterangeDimension: 'report_date',
    timeseriesEnabled: true,
    pvpenabled: true,
    yoyenabled: false,
    customAPIDecisionVars: {
      enableShareOfVoice: false,
      skipLimitAndOffset: false,
      additionalWhereClause: false,
      enableForecastSkuLevelWidget: false,
      enableFetchFromShards: false,
      skipExternalCount: false,
      skipTimeSeriesMetric: false,
      enableDigitalShelf: false,
      totalCompBrandsForDigitalShelf: 0,
      totalOthersBrandsForDigitalShelf: 0,
      totalClientBrandsForDigitalShelf: 0,
      anchoredBrand: null,
      preQueriesMap: {},
      ignoreMeasuresWhileDeduping: {},
      type: null,
      downloadRequest: null,
      contentScorecardRequest: null,
      queryReplacementRequest: null,
      system: null,
      enableChartsSku: false,
      timeSeriesDimensionAggregateRequest: null,
      jspEnabled: false,
      sovDataGroup: false,
      timeSeriesDimensionEnabled: false,
      chainingJoinEnabled: false
    },
    measuresList: ['spend'],
    groupByDimensionsList: [
      'search_term',
      'keyword_text',
      'campaign_name',
      'match_type_keyword',
      'keyword_id'
    ],
    orderByList: [
      {
        dimension: 'spend',
        direction: 'DESC'
      }
    ],
    where: {
      dimensionNameValueList: [],
      date: {
        from: payload.from,
        to: payload.to
      },
      pvpDate: {
        from: '2023-10-20',
        to: '2023-11-18',
        compare_name: null
      }
    },
    tagWhereClause: []
  };
  const zeroAdSalesPayload = cloneDeep(modifiedPayload);
  zeroAdSalesPayload.where.dimensionNameValueList = [
    {
      dimensionName: 'paid_sales_14d',
      dimensionValue: '0',
      operator: 'EQUAL_TO'
    }
  ];
  return HttpService.all([
    HttpService.post('EXECUTE_CUBE_CHART_API', modifiedPayload),
    HttpService.post('EXECUTE_CUBE_CHART_API', zeroAdSalesPayload)
  ]);
};

const generateAdSpendPayload = (payload, additionalFilters = []) => {
  const basePayload = {
    getPointInTimeMetrics: false,
    yoyenabled: false,
    measuresList: [],
    cubeName: 'ams_keyword_campaign_workbench',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesDimension: 'report_date',
    daterangeDimension: 'report_date',
    disableShard: false,
    timeseriesEnabled: false,
    pvpenabled: true,
    pvptimeseriesEnabled: false,
    sharePercentageV2: false,
    getSharePercentage: false,
    adjustPvpDateByMaxDate: false,
    commonFilterEnabled: false,
    bundleCubeExecutionRequest: {
      adv_metrics: {
        timeseriesDimension: 'report_date',
        unmodifiedTimeseriesDimension: 'feed_date',
        cubeName: 'ams_keyword_campaign_workbench',
        getLatestAvailableInsteadOfRollup: false,
        timeseriesEnabled: false,
        pvpenabled: true,
        pvptimeseriesEnabled: false,
        sharePercentageV2: false,
        splyenabled: false,
        pointInTimeMetrics: false,
        daterangeDimension: 'report_date',
        disableShard: false,
        enableNewPVPFormulaForSOV: false,
        flowResidueEntity: false,
        getPointInTimeMetrics: false,
        getSharePercentage: false,
        adjustPvpDateByMaxDate: false,
        commonFilterEnabled: false,
        customAPIDecisionVars: {
          enableForecastSkuLevelWidget: false,
          enableShareOfVoice: false,
          ignoreMeasuresWhileDeduping: {},
          jspEnabled: false,
          preQueriesMap: {},
          system: 'amazon_campaign_management',
          additionalWhereClause: false,
          anchoredBrand: null,
          chainingJoinEnabled: false,
          sovDataGroup: false,
          timeSeriesDimensionAggregateRequest: null,
          timeSeriesDimensionEnabled: false,
          totalClientBrandsForDigitalShelf: 0,
          totalCompBrandsForDigitalShelf: 0,
          downloadRequest: null,
          enableChartsSku: false,
          enableDigitalShelf: false,
          enableFetchFromShards: false,
          queryReplacementRequest: null,
          skipExternalCount: false,
          skipLimitAndOffset: false,
          skipTimeSeriesMetric: false,
          type: null,
          totalOthersBrandsForDigitalShelf: 0,
          contentScorecardRequest: null
        },
        yoyenabled: false,
        measuresList: ['spend'],
        groupByDimensionsList: [
          'keyword_text',
          'campaign_name',
          'match_type_keyword',
          'keyword_id'
        ],
        orderByList: [],
        tagWhereClause: [],
        where: {
          dimensionNameValueList: [],
          date: {
            from: payload.from,
            to: payload.to
          },
          pvpDate: {
            from: '2023-12-05',
            to: '2023-12-11',
            compare_name: null
          }
        },
        timeseriesRollUpBy: 'DAY'
      },
      sov_metrics: {
        timeseriesDimension: 'report_date',
        unmodifiedTimeseriesDimension: 'feed_date',
        cubeName: 'ams_keyword_campaign_workbench',
        getLatestAvailableInsteadOfRollup: false,
        pointInTimeMetrics: false,
        daterangeDimension: 'report_date',
        disableShard: false,
        enableNewPVPFormulaForSOV: false,
        flowResidueEntity: false,
        commonFilterEnabled: false,
        customAPIDecisionVars: {
          enableChartsSku: false,
          enableDigitalShelf: false,
          enableFetchFromShards: false,
          enableForecastSkuLevelWidget: false,
          enableShareOfVoice: false,
          ignoreMeasuresWhileDeduping: {},
          jspEnabled: false,
          preQueriesMap: {},
          timeSeriesDimensionAggregateRequest: null,
          timeSeriesDimensionEnabled: false,
          totalClientBrandsForDigitalShelf: 0,
          skipExternalCount: false,
          skipLimitAndOffset: false,
          skipTimeSeriesMetric: false,
          sovDataGroup: false,
          totalCompBrandsForDigitalShelf: 0,
          totalOthersBrandsForDigitalShelf: 0,
          type: null,
          system: 'amazon_campaign_management',
          additionalWhereClause: false,
          anchoredBrand: null,
          chainingJoinEnabled: false,
          contentScorecardRequest: null,
          downloadRequest: null,
          queryReplacementRequest: null
        },
        getPointInTimeMetrics: false,
        getSharePercentage: false,
        adjustPvpDateByMaxDate: false,
        yoyenabled: false,
        timeseriesEnabled: true,
        pvpenabled: false,
        pvptimeseriesEnabled: false,
        sharePercentageV2: false,
        splyenabled: false,
        measuresList: [
          'share_of_organic_search',
          'share_of_sponsered_search',
          'organic_search_count',
          'total_organic_search_count',
          'total_sponsored_search_count',
          'sponsored_search_count',
          'share_of_sponsered_brand_search',
          'header_search_count',
          'total_header_search_count'
        ],
        groupByDimensionsList: ['keyword_text'],
        orderByList: [],
        dedupBeforeRollup: {
          enableDedupBeforeRollup: true,
          additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
            'report_date'
          ]
        },
        tagWhereClause: [],
        where: {
          dimensionNameValueList: [],
          date: {
            from: payload.from,
            to: payload.to
          },
          pvpDate: {
            from: '2023-12-05',
            to: '2023-12-11',
            compare_name: null
          }
        },
        timeseriesRollUpBy: 'DAY'
      }
    },
    customAPIDecisionVars: {
      ignoreMeasuresWhileDeduping: {},
      jspEnabled: false,
      preQueriesMap: {},
      system: 'amazon_campaign_management',
      contentScorecardRequest: null,
      timeSeriesDimensionEnabled: false,
      totalClientBrandsForDigitalShelf: 0,
      totalCompBrandsForDigitalShelf: 0,
      totalOthersBrandsForDigitalShelf: 0,
      additionalWhereClause: false,
      anchoredBrand: null,
      chainingJoinEnabled: false,
      downloadRequest: null,
      enableChartsSku: false,
      enableDigitalShelf: false,
      queryReplacementRequest: null,
      skipExternalCount: false,
      skipLimitAndOffset: false,
      skipTimeSeriesMetric: false,
      sovDataGroup: false,
      timeSeriesDimensionAggregateRequest: null,
      enableFetchFromShards: false,
      enableForecastSkuLevelWidget: false,
      enableShareOfVoice: false,
      type: null
    },
    splyenabled: false,
    pointInTimeMetrics: false,
    enableNewPVPFormulaForSOV: false,
    flowResidueEntity: false,
    groupByDimensionsList: [],
    orderByList: [
      {
        dimension: 'spend',
        direction: 'DESC'
      }
    ],
    tagWhereClause: [],
    where: {
      dimensionNameValueList: additionalFilters,
      date: {
        from: payload.from,
        to: payload.to
      },
      pvpDate: {
        from: '2023-12-05',
        to: '2023-12-11',
        compare_name: null
      }
    },
    timeseriesRollUpBy: 'DAY'
  };

  const clonedPayload = cloneDeep(basePayload);
  clonedPayload.bundleCubeExecutionRequest.adv_metrics.where.dimensionNameValueList =
    additionalFilters;
  clonedPayload.where.dimensionNameValueList = additionalFilters;

  return clonedPayload;
};

const adSpendPayloadWhere = [
  {
    dimensionName: 'keyword_type',
    dimensionValue: 'CATEGORY',
    esDataSetName: 'campaigns_keywords_filter'
  },
  {
    dimensionName: 'keyword_type',
    dimensionValue: 'COMPETITOR_BRANDED',
    esDataSetName: 'campaigns_keywords_filter'
  },
  {
    dimensionName: 'keyword_type',
    dimensionValue: 'GENERIC',
    esDataSetName: 'campaigns_keywords_filter'
  }
];
export const GenericAdSpendAPI = (payload) => {
  const totalValuePayload = generateAdSpendPayload(payload);
  const zeroValuePayload = generateAdSpendPayload(payload, [
    ...adSpendPayloadWhere
  ]);
  return HttpService.all([
    HttpService.post('EXECUTE_CUBE_CHART_API', totalValuePayload),
    HttpService.post('EXECUTE_CUBE_CHART_API', zeroValuePayload)
  ]);
};

export const ExactAdSpendAPI = (payload) => {
  const totalValuePayload = generateAdSpendPayload(payload, [
    ...adSpendPayloadWhere
  ]);
  const zeroValuePayload = generateAdSpendPayload(payload, [
    ...adSpendPayloadWhere,
    {
      dimensionName: 'match_type_keyword',
      dimensionValue: 'exact',
      esDataSetName: 'campaigns_keywords_filter'
    }
  ]);
  return HttpService.all([
    HttpService.post('EXECUTE_CUBE_CHART_API', totalValuePayload),
    HttpService.post('EXECUTE_CUBE_CHART_API', zeroValuePayload)
  ]);
};

export const PercentTimeInBudgetAPI = (payload) => {
  const modifiedPayload = {
    cubeName: 'ams_campaigns_campaign_workbench',
    getLatestAvailableInsteadOfRollup: false,
    timeseriesEnabled: true,
    timeseriesDimension: 'report_date',
    timeseriesRollupBy: 'DAY',
    pvpenabled: true,
    yoyenabled: false,
    pvptimeseriesEnabled: false,
    sharePercentageV2: false,
    splyenabled: false,
    getPointInTimeMetrics: false,
    getSharePercentage: false,
    pointInTimeMetrics: false,
    daterangeDimension: 'report_date',
    measuresList: [
      'spend',
      'WEIGHTED_PERCENT_TIME_IN_BUDGET',
      'COST_DENOMINATOR',
      'percent_time_in_budget'
    ],
    groupByDimensionsList: ['campaign_name', 'campaign_id'],
    customAPIDecisionVars: {
      system: 'amazon_campaign_management'
    },
    where: {
      dimensionNameValueList: [],
      date: {
        from: payload.from,
        to: payload.to
      },
      pvpDate: {
        from: '2023-12-08',
        to: '2023-12-09',
        compare_name: null
      }
    },
    orderByList: [
      {
        dimension: 'spend',
        direction: 'DESC'
      }
    ],
    tagWhereClause: []
  };
  return HttpService.post('EXECUTE_CUBE_CHART_API', modifiedPayload);
};

export const PerformanceAPI = (payload) => {
  const modifiedPayload = {
    page: 'BusinessInsights',
    widget: 'chartingWb',
    entityType: '#ALLOVER_AGGREGATE',
    metricsList: ['sales_dashboard__ordered_product_sales'],
    eventsList: [],
    operations: {
      timeseriesEnabled: false,
      timeseriesDimension: 'feed_date',
      daterangeDimension: 'feed_date',
      commonFilterEnabled: true,
      pvpenabled: true,
      samePeriodLastYearTimeseriesEnabled: true,
      enableDedupBeforeRollup: true,
      timeseriesRollupBy: 'DAY',
      timeseriesWeekStartDay: 'SUNDAY',
      additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [
        'asin',
        'feed_date'
      ],
      pvptimeseriesEnabled: false
    },
    where: {
      dimensionNameValueList: [],
      date: {
        from: payload.from,
        to: payload.to
      },
      pvpDate: {
        from: moment(payload.from).subtract(1, 'years').format('YYYY-MM-DD'),
        to: moment(payload.to).subtract(1, 'years').format('YYYY-MM-DD'),
        compare_name: null
      }
    }
  };
  return HttpService.post('DASHBOARD_SERVICE', modifiedPayload, {
    append: '/entity/metrics/data'
  });
};

export const TaxomonyAPI = () => {
  const campaignTaxonomy = {
    entityType: 'campaignTaxonomy',
    componentType: 'Taxonomy'
  };
  const catalogTaxonomy = {
    entityType: 'catalogTaxonomy',
    componentType: 'Taxonomy'
  };
  const standardReports = {
    tagList: [{ tagGroup: 'recurring' }, { tagGroup: 'adhoc' }],
    pattern: ''
  };
  return HttpService.all([
    HttpService.post('ENTITY_EXISTS_V2', catalogTaxonomy),
    HttpService.post('ENTITY_EXISTS_V2', campaignTaxonomy),
    HttpService.post('DASHBOARD_TOOLING_SERVICE', standardReports, {
      append: '/page/tags/read'
    })
  ]);
};
