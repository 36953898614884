<template>
  <div
    class="u-bg-color-grey-white shadows u-border-radius-s widget-1 u-spacing-mt-m u-spacing-pl-s u-spacing-p-m"
  >
    <p
      class="u-font-size-2 u-font-weight-600 u-color-grey-lighter u-text-case-upper u-spacing-pt-s"
    >
      Source of MAP Violation
    </p>
    <div class="u-display-flex u-spacing-mt-m">
      <donut-chart-withLegend
        :chart-data="sourceData"
        :legends="sourceLegends"
        :donut-colors="sourceColors"
        :show-percentage="true"
      />
    </div>
    <section>
      <retailer-breakdown
        id="sourceOfViolation"
        :bar-data="retailerBreakDownData"
        :group-colors="retailerBreakDownColors"
        :categories="retailerBreakDownCategories"
        :groups="retailerBreakDownGroups"
      />
    </section>
  </div>
</template>

<script>
import retailerBreakdown from '@/pages/all_retailers/dso/imap/retailerBreakdown.vue';
import donutChartWithLegend from '@/pages/all_retailers/dso/molecules/donutChartWithLegend.vue';

export default {
  name: 'SourceOfMapViolation',
  components: {
    donutChartWithLegend,
    retailerBreakdown
  },
  data() {
    return {
      sourceMetric: {
        total: 20,
        retailer: 12,
        sellers3p: 8
      },
      sourceColors: {
        retailer: '#538FF5',
        sellers3p: '#FF6072'
      },
      retailerBreakDownData: [
        ['retailer', 12, 5, 10, 15],
        ['sellers3p', 8, 10, 0, 0]
      ],
      retailerBreakDownColors: {
        retailer: '#538FF5',
        sellers3p: '#FF6072'
      },
      retailerBreakDownCategories: ['Amazon', 'Walmart', 'Target', 'Best Buy'],
      retailerBreakDownGroups: [['retailer', 'sellers3p']]
    };
  },
  computed: {
    sourceData() {
      return [
        ['retailer', this.sourceMetric.retailer],
        ['sellers3p', this.sourceMetric.sellers3p]
      ];
    },
    sourceLegends() {
      return [
        {
          label: 'Retailer',
          value: this.sourceMetric.retailer,
          percentage: Math.round(
            (this.sourceMetric.retailer / this.sourceMetric.total) * 100
          ),
          color: '#538FF5'
        },
        {
          label: '3P Sellers',
          value: this.sourceMetric.sellers3p,
          percentage: Math.round(
            (this.sourceMetric.sellers3p / this.sourceMetric.total) * 100
          ),
          color: '#FF6072'
        }
      ];
    }
  }
};
</script>
