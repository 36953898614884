































































































import Vue, { PropType } from 'vue';
// @ts-ignore
import chart from '@/components/basic/chart';
// @ts-ignore
import metric from '@/components/basic/metric';
// @ts-ignore
import iconTextActions from '@/components/widgets/iconTextActions.vue';
// @ts-ignore
import cardContainer from '@/components/pages/insights/amazon/share-of-voice/organisms/card-container.vue';
// @ts-ignore
import chartWithLegends from '@/components/basic/chart-with-legends.vue';
// @ts-ignore
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
// @ts-ignore
import loader from '@/components/basic/loader';
// @ts-ignore
import rbSelectV2 from '@/components/pages/businessInsights/rbSelectV2';
import { MarketShareBenchmarkTable } from '@/components/pages/insights/amazon/market-share/service/benchmarking-table';
import {
  GlobalSettings,
  DimensionNameValueList
} from '@/components/pages/insights/amazon/market-share/types/filters';
import {
  setGlobalSettingsForMS,
  constants,
  isMarketShareLite
} from '@/components/pages/insights/amazon/market-share/utils';
import { DragStoppedEvent } from 'ag-grid-community';
import { SelectedMetric } from '../types/benchmark-table';
import { DropdownElement } from '../types/rb-select-dropdown';
import { cloneDeep } from 'lodash';

export default Vue.extend({
  components: {
    chart,
    cardContainer,
    metric,
    iconTextActions,
    rbDownloadButton,
    loader,
    chartWithLegends,
    rbSelectV2
  },
  props: {
    service: {
      type: Object as PropType<MarketShareBenchmarkTable>,
      required: true
    },
    globalViewId: {
      type: Number,
      required: true
    },
    pageId: {
      type: Number,
      required: true
    },
    page: {
      type: String,
      default: ''
    },
    // eslint-disable-next-line vue/require-default-prop
    globalSettings: Object as PropType<GlobalSettings>,
    reversedObject: {
      type: Object,
      default: () => {}
    },
    actionIcons: {
      type: Array,
      default: () => [
        {
          emit: 'download',
          icon: 'download',
          loading: false,
          error: false,
          tippyTitle: 'Download Excel with total Market Share by category share'
        }
      ]
    }
  },
  data() {
    return {
      actionIconsList: this.actionIcons,
      refreshKey: false,
      selectedOptionValue: {},
      tagConfig: {},
      isLoading: true,
      downloadApi: {
        error: false,
        loading: false
      },
      gridOptions: {
        onDragStopped: null as unknown,
        api: {
          refreshCells: () => {}
        },
        suppressColumnVirtualisation: true,
        context: {
          componentParent: this
        }
      },
      chartLoading: false,
      selectedRollUp: {
        name: 'Days',
        value: 'DAY',
        diff: 'day'
      },
      testSaveState: {},
      downloadLoading: false,
      downloadError: false,
      widget: 'benchmarking',
      isGroupingRequired: false
    };
  },
  computed: {
    tableRows():
      | {
          [columnName: string]: unknown;
        }[]
      | undefined {
      this.refreshKey;
      return this.service.data ? this.service.data.rows : [];
    },
    tableHeaderHeight(): number {
      return 248;
    },
    isPlotted(): boolean {
      return false;
    },
    chartDefaultMetricType(): boolean {
      return true;
    },
    chartState(): {} {
      return {};
    },
    isMetadataLoading(): boolean {
      return false;
    },
    isMetadataErrored(): boolean {
      return false;
    },
    isBenchMarkingOverallLoading(): boolean {
      return false;
    },
    isBenchMarkingOverallErrored(): boolean {
      return false;
    },
    displayMetric(): {} {
      return {};
    },
    mainDropDownOptionsNoData(): boolean {
      return false;
    },
    getTableColumns(): unknown {
      this.refreshKey;
      return this.service.columns;
    }
  },
  watch: {
    globalSettings: {
      immediate: true,
      handler(newVal: GlobalSettings, oldVal: unknown) {
        if (newVal.where && this.service) {
          let dimensionValueList: DimensionNameValueList[] = JSON.parse(
            JSON.stringify(newVal.where.dimensionNameValueList)
          );
          if (isMarketShareLite()) {
            dimensionValueList = dimensionValueList.map(
              (dimension: DimensionNameValueList) => {
                dimension.dimensionValue =
                  this.reversedObject[dimension.dimensionValue as string];
                return dimension;
              }
            );
          }

          const selectedCategories = dimensionValueList
            .filter((x) => x.dimensionName === 'category_hierarchy')
            .map((x) => (x.dimensionValue as string).toUpperCase());
          const displayShareIn = this.findDisplayShareIn(newVal);
          this.service.updateData(
            {
              date: newVal.where.date,
              pvpDate: newVal.where.pvpDate,
              dimensionNameValueList: Object.entries(dimensionValueList)
                .filter((x) => x[1].dimensionName !== 'category_hierarchy')
                .map((x) => x[1])
            },
            selectedCategories,
            displayShareIn,
            this.switchMetric.bind(this),
            newVal.settingValue.include3p.value
          );
        }
      }
    }
  },
  created() {
    this.gridOptions.onDragStopped = this.onDragStopped;
  },
  methods: {
    findDisplayShareIn(val: any) {
      let displayShareIn: 'SALES_ESTIMATES' | 'UNIT_ESTIMATES' =
        'UNIT_ESTIMATES';
      if (
        val.settingValue.displayShareIn.metricsList
          ?.toLowerCase()
          .includes('sales')
      ) {
        displayShareIn = 'SALES_ESTIMATES';
      } else {
        displayShareIn = 'UNIT_ESTIMATES';
      }
      return displayShareIn;
    },
    switchMetric(
      x: SelectedMetric,
      y: { selectedOptions: DropdownElement[] }
    ): void {
      this.service.metrics?.switchMetric(x, y);
      this.$forceUpdate();
      this.gridOptions.api.refreshCells();
      this.refreshKey = !this.refreshKey;
    },
    onDragStopped(event: DragStoppedEvent): void {
      const columnOrder = event.columnApi
        ?.getAllDisplayedColumns()
        .filter((_, index) => index !== 0) // Remove the Entity column
        .map((column) => column.getColDef().field);
      if (columnOrder?.length) {
        setGlobalSettingsForMS(
          constants.SHOWED_BENCHMARKING_COLUMNS,
          columnOrder
        );
      }
    },
    async downloadHandler(index: number) {
      try {
        this.downloadApi.loading = true;
        this.downloadApi.error = false;
        this.updateActionsIconDownload(index);
        await this.downloadReport();
      } catch (e) {
        console.error('e', e);
        this.downloadApi.loading = false;
        this.downloadApi.error = true;
        this.updateActionsIconDownload(index);
      } finally {
        this.downloadApi.loading = false;
        this.updateActionsIconDownload(index);
        setTimeout(() => {
          this.downloadApi.error = false;
          this.updateActionsIconDownload(index);
        }, 1000);
      }
    },

    updateActionsIconDownload(index: number) {
      const actionIconsCopy = JSON.parse(JSON.stringify(this.actionIcons));
      actionIconsCopy[index] = {
        ...actionIconsCopy[index],
        ...this.downloadApi
      };
      this.actionIconsList = actionIconsCopy;
    },

    async downloadReport() {
      const whereClause = cloneDeep(this.globalSettings?.where);
      const threepSelection =
        this.globalSettings?.settingValue?.include3p?.value;
      const estimateType =
        this.globalSettings.settingValue.displayShareIn.label;
      await this.service.download(whereClause, threepSelection, estimateType);
    }
  }
});
