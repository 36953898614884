import HttpService from '@/utils/services/http-service';

const state = {
  isSearchResultsOpen: false,
  isFetchingResults: false,
  searchResults: []
};

const getters = {
  isSearchResultsOpen: (state) => {
    return (
      state.isSearchResultsOpen &&
      (state.isFetchingResults ||
        (state.searchResults &&
          state.searchResults.length &&
          state.searchResults.length))
    );
  },
  isFetchingResults: (state) => {
    return state.isFetchingResults;
  },
  getSearchResults: (state) => {
    return state.searchResults;
  }
};

const mutations = {
  OPEN_SEARCH_RESULTS: (state) => {
    state.isSearchResultsOpen = true;
  },
  CLOSE_SEARCH_RESULTS: (state) => {
    state.isSearchResultsOpen = false;
  },
  SET_SEARCH_RESULTS: (state, data) => {
    state.searchResults = data;
  },
  START_FETCHING_RESULTS: (state, data) => {
    state.isFetchingResults = true;
  },
  FINISH_FETCHING_RESULTS: (state, data) => {
    state.isFetchingResults = false;
  },
  CLEAR_SEARCH_RESULTS: (state) => {
    state.searchResults = [];
  }
};

const actions = {
  fetchSearchResults: (context, searchText) => {
    context.commit('START_FETCHING_RESULTS');
    context.commit('SET_SEARCH_RESULTS', []);
    var filterPayload = context.getters.getRecommendationFiltersPayload;
    HttpService.post(
      'SEARCH_RECOMMENDATIONS',
      {
        filters: filterPayload.filters,
        recency: filterPayload.recency,
        state: filterPayload.state,
        recommendationGroup: 'All'
      },
      {
        pathParams: {
          searchString: searchText
        }
      }
    )
      .then((response) => {
        context.commit('SET_SEARCH_RESULTS', response.data);
        context.commit('FINISH_FETCHING_RESULTS');
      })
      .catch((error) => {
        if (error.response) {
          context.commit('SEARCH_ERROR', error.response);
          context.commit('FINISH_FETCHING_RESULTS');
        }
      });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
