<template>
  <div
    class="u-width-100 u-display-flex u-spacing-p-xxl budget-panner-dashboard"
  >
    <section class="create-plan--steps u-width-100 u-position-relative">
      <budgetPlannerHeader
        :default-margin-bottom="false"
        :step="graphStep"
      />
      <div
        class="u-width-100"
        :class="[$route.query.nextYearPlan && 'u-spacing-pt-xl']"
      >
        <div
          v-if="!$route.query.nextYearPlan"
          class="u-width-100 u-spacing-p-l customBorder u-spacing-mt-m u-margin-bottom-40"
        >
          <div class="u-display-flex u-flex-justify-content-space-between">
            <div class="u-display-flex">
              <rb-button
                class="u-spacing-mr-m valueDropdown"
                size="s"
                text="Entire Business"
                :class="{
                  activeSelection: selectionType === 'entire_business'
                }"
                :click-fn="selectEntireBusinessScope"
              />
              <!-- Profile Selection -->
              <rb-select
                v-tippy="{ placement: 'top', arrow: true }"
                class-name="bp-select"
                :custom-trigger="true"
                :title="
                  selectionType === 'profile'
                    ? tippyText
                    : `Select ${profileTitle}`
                "
                :is-multi-select="true"
                :class="{ activeSelection: selectionType === 'profile' }"
                class="u-spacing-mr-m valueDropdown u-max-width-100"
                :send-details="true"
                :on-close="setProfileInFilters"
                :options="localProfileDropdownOptions"
                @change="(data) => handleChange(data, 'brand')"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-spacing-p-xs u-cursor-pointer"
                >
                  <div
                    class="u-flex-1 u-font-size-7 u-text-overflow-ellipsis u-spacing-pl-xs"
                    style="max-width: 200px"
                    :class="
                      selectionType === 'profile'
                        ? 'u-color-blue-base'
                        : 'u-color-grey-lighter'
                    "
                  >
                    <span v-if="selectionType === 'profile'">
                      <span class="u-text-case-title">{{ profileTitle }}s</span
                      >: {{ tippyText }}
                    </span>
                    <span
                      v-else
                      class="u-text-case-title"
                    >
                      {{ profileTitle }}
                    </span>
                  </div>
                  <rb-icon
                    class="u-flex-0 rb-icon--medium u-spacing-mh-xs"
                    :class="
                      selectionType === 'profile'
                        ? 'u-color-blue-base'
                        : 'u-color-grey-lighter'
                    "
                    icon="caret-down"
                  />
                </div>
                <div
                  v-if="localProfileDropdownOptions.length"
                  slot="footer"
                  class="u-display-flex u-spacing-ph-l u-spacing-pt-m u-spacing-pb-s u-border-top u-border-width-s u-border-color-grey-xxx-light"
                >
                  <rb-button
                    :click-fn="clickOutside.bind(this)"
                    :type="'filled'"
                    text="Apply"
                    size="s"
                    :disabled="disableApplyActionBrand"
                  />
                </div>
              </rb-select>

              <!-- Portfolio Selection -->
              <rb-select
                v-if="checkRetailer"
                v-tippy="{ placement: 'top', arrow: true }"
                class-name="bp-select"
                :custom-trigger="true"
                :title="
                  selectionType === 'portfolio' ? tippyText : 'Select portfolio'
                "
                :is-multi-select="true"
                :class="{ activeSelection: selectionType === 'portfolio' }"
                class="u-spacing-mr-m valueDropdown u-max-width-100"
                :send-details="true"
                :on-close="setPortfolioInFilters"
                :options="localPortfolioDropdownOptions"
                @change="(data) => handleChange(data, 'portfolio')"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-spacing-p-xs u-cursor-pointer"
                >
                  <div
                    class="u-flex-1 u-font-size-7 u-text-overflow-ellipsis u-spacing-pl-xs"
                    style="max-width: 200px"
                    :class="
                      selectionType === 'portfolio'
                        ? 'u-color-blue-base'
                        : 'u-color-grey-lighter'
                    "
                  >
                    <span v-if="selectionType === 'portfolio'">
                      Portfolios: {{ tippyText }}
                    </span>
                    <span v-else> Portfolio </span>
                  </div>
                  <rb-icon
                    class="u-flex-0 rb-icon--medium u-spacing-mh-xs"
                    :class="
                      selectionType === 'portfolio'
                        ? 'u-color-blue-base'
                        : 'u-color-grey-lighter'
                    "
                    icon="caret-down"
                  />
                </div>
                <div
                  v-if="localPortfolioDropdownOptions.length"
                  slot="footer"
                  class="u-display-flex u-spacing-ph-l u-spacing-pt-m u-spacing-pb-s u-border-top u-border-width-s u-border-color-grey-xxx-light"
                >
                  <rb-button
                    :click-fn="clickOutside.bind(this)"
                    :type="'filled'"
                    text="Apply"
                    size="s"
                    :disabled="disableApplyActionPortfolio"
                  />
                </div>
              </rb-select>
              <div class="dummy-element">&nbsp;</div>
            </div>
            <div class="u-display-flex u-flex-align-items-center">
              <splitTabs
                tab1="Cumulative"
                tab2="Absolute"
                class="split-tabs-container"
                @tabClicked="onTabClick"
              />
              <div class="u-spacing-ml-m">
                <rb-select
                  class="fs-budget-plan-dashboard-roll-up u-spacing-mr-m valueDropdown u-max-width-100"
                  :on-close="getChartDataWithRollUp"
                  :send-details="true"
                  :options="rollUps"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-spacing-p-xs u-cursor-pointer"
                  >
                    <rb-icon
                      class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                      icon="calendar"
                    />
                    <span
                      class="u-spacing-mr-xs u-flex-0 u-color-grey-lighter u-font-size-7"
                      >Roll up by:&nbsp;</span
                    >
                    <div
                      class="u-flex-1 u-font-size-7 u-text-overflow-ellipsis u-color-grey-light"
                    >
                      {{ selectedRollUp.name }}
                    </div>
                    <rb-icon
                      class="u-flex-0 rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
                      icon="caret-down"
                    />
                  </div>
                  <template
                    slot="item"
                    slot-scope="option"
                  >
                    <div class="u-display-flex u-flex-align-items-center">
                      {{ option.name }}
                    </div>
                  </template>
                </rb-select>
              </div>
            </div>
          </div>
          <div
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-flex-direction-column"
            style="min-height: 332px"
          >
            <div
              v-if="isChartLoading"
              class="u-font-size-4 u-font-style-italic"
            >
              Looking at your historical data...
            </div>
            <div
              class="u-spacing-mt-m u-spacing-mb-s u-width-40"
              :class="isChartLoading ? 'loader' : ''"
            />
            <div v-if="!isChartLoading && chartData && chartData.length">
              <chart
                :config="config.widgets['widget1'].body.chart"
                :data="chartData"
              />
            </div>
            <div
              v-if="!isChartLoading && !chartData"
              class="u-font-size-4 u-font-style-italic"
            >
              No data available.
            </div>
          </div>
        </div>
        <budgetPlannerHeader :step="getSubStep" />
        <div
          v-for="(eventItem, i) in seasonalityEvents"
          :key="eventItem.eventId"
          class="u-width-100 u-display-flex"
        >
          <div
            class="u-width-100 u-display-flex u-spacing-pb-xl seasonality-event-row"
            :class="{
              'is-inactive':
                hasUnsavedEvent && isCurrentEventSaved(eventItem.eventId)
            }"
          >
            <add-seasonality-event
              :is-chart-loading="isChartLoading"
              :current-index="i"
              :show-header="i === 0"
              :remaining-budget-hash-map="remainingBudgetHashMap"
              :event-obj="eventItem"
              :effect-by-parent-map="newEffectByParentMap"
              @onUpdateEvent="eventDataUpdated"
              @onSaveEvent="saveSeasonalityEvent"
              @updateHashMap="updateRemainingBudgetHashMap"
            />
          </div>
        </div>
        <div>
          <span
            class="u-cursor-pointer u-color-blue-base u-font-size-5 u-display-inline-flex u-flex-align-items-center fs-budget-plan-add-event"
            :class="{ 'is-disabled': hasUnsavedEvent }"
            @click.stop="addEvent"
          >
            <rb-icon
              class="rb-icon--small u-spacing-mr-xs"
              icon="add-circle-fill"
            />
            <span>Add event</span>
          </span>
        </div>
        <div
          class="u-display-flex u-flex-direction-column u-flex-align-items-flex-start u-spacing-mt-xl"
        >
          <div v-if="displayErrorMessages.length">
            <div
              v-for="msg in displayErrorMessages"
              :key="msg"
              class="u-display-flex u-color-red-base u-spacing-mb-s u-font-size-5 u-flex-align-items-center"
            >
              <rb-icon
                class="rb-icon--small u-color-red-base u-spacing-mr-xs"
                icon="error-fill"
              />
              <span class="u-font-size-5 u-color-red-base">{{ msg }}</span>
            </div>
          </div>
          <div v-if="unsavedEventPresent">
            <div
              class="u-display-flex u-color-red-base u-spacing-mb-s u-font-size-5 u-flex-align-items-center"
            >
              <rb-icon
                class="rb-icon--small u-color-red-base u-spacing-mr-xs"
                icon="error-fill"
              />
              <span class="u-font-size-5 u-color-red-base"
                >Save or remove event to move forward.</span
              >
            </div>
          </div>
        </div>
      </div>
      <budgetPlannerFooter
        :disable-right-btn="!isBudgetPlanModified"
        :btn-right-text="budgetPlanExists ? 'Update plan' : 'Create plan'"
        class="u-spacing-pt-xxl u-spacing-pb-xl"
        custom-class-left="fs-budget-plan-seasonality-back"
        custom-class-right="fs-budget-plan-seasonality-next"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="goBack"
      />
    </section>
  </div>
</template>

<script>
import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import chart from '@/components/basic/chart';
import budgetPlannerHeader from '@/components/ams/budgetPlanner/steps/common/header';
import budgetPlannerFooter from '@/components/ams/budgetPlanner/steps/common/footer';
import addSeasonalityEvent from './addEvent';
import widgetsConfig from './widgets';
import stepsMixin from '@/components/ams/budgetPlanner/steps/stepMixin.js';
import splitTabs from '@/components/widgets/splitTabs';
import { cloneDeep, isEqual } from 'lodash';
import { eventBus } from '@/utils/services/eventBus';
import HttpService from '@/utils/services/http-service';
import {
  cumulativeStepretailerMap,
  retailerLevelSubStep
} from '@/components/ams/budgetPlanner/retailerConfig';
const moment = extendMoment(Moment);

export default {
  components: {
    budgetPlannerHeader,
    budgetPlannerFooter,
    addSeasonalityEvent,
    chart,
    splitTabs
  },
  mixins: [stepsMixin],
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    unsavedEventPresent: false,
    hasUnsavedEvent: false,
    displayErrorMessages: [],
    currentMonthAmsSpend: {},
    remainingBudgetHashMap: {},
    config: {},
    rollUps: [
      { name: 'Days', value: '' },
      { name: 'Weeks', value: 'week' },
      { name: 'Months', value: 'month' }
    ],
    graphData: {
      rollUp: '',
      dimensionNameValueList: []
    },
    selectedRollUp: { name: 'Days', value: '' },
    selectionType: 'entire_business', // ['entire_business', 'profile', 'portfoilo']
    tippyText: '',
    seasonalityEvents: [],
    tempEventsArr: [],
    pacingSelectionType: 'Cumulative',
    isBudgetHashValid: true,
    applyClicked: false,
    appliedProfileList: [],
    appliedPortfolioList: [],
    distributionMap: null,
    entityParentChildMap: {},
    effectByParentMap: {},
    newEffectByParentMap: {},
    selectedDetails: [],
    disableApplyActionBrand: true,
    disableApplyActionPortfolio: true,
    type: ''
  }),
  computed: {
    budgetPlanExists() {
      return !!this.$store.getters.getBudgetPlannerStrategies[0];
    },
    checkRetailer() {
      const retailer = this.$store.getters.getRetailer;
      if (retailer === 'walmart' || retailer === 'target') {
        return false;
      }

      return true;
    },
    getSubStep() {
      const retailer = this.$store.getters.getRetailer;
      return retailerLevelSubStep[retailer];
    },
    profileTitle() {
      const retailer = this.$store.getters.getRetailer;
      return cumulativeStepretailerMap[retailer];
    },
    graphStep() {
      const thisYear = new Date().getFullYear();
      const year = this.$route?.query?.nextYearPlan ? thisYear + 1 : thisYear;
      this.step.header.title =
        this.pacingSelectionType + ' budget plan for the year ' + year;
      this.step.breadCrumbTitle =
        this.pacingSelectionType + ' budget plan for the year ' + year + '.';
      return this.step;
    },
    chartData() {
      if (this.pacingSelectionType === 'Cumulative') {
        return this.$store.getters.getCumulativePacingChartData;
      } else if (this.pacingSelectionType === 'Absolute') {
        return this.$store.getters.getAbsolutePacingChartData;
      }
    },
    isChartLoading() {
      return this.$store.getters.getChartDataLoader;
    },
    disableNextButton() {
      return !!this.displayErrorMessages.length;
    },
    budgetPlanLastUpdated() {
      return this.$store.getters.getLastUploadedBudgetPlan;
    }
  },
  watch: {
    disableNextButton: {
      handler(newVal) {
        let indexesToEnable = [0, 1, 2];
        if (!newVal) {
          indexesToEnable = [0, 1, 2, 3];
        }
        this.$emit('breadcrumb-indexes-to-enable', indexesToEnable);
      },
      immediate: true
    },
    tempEventsArr: {
      handler(newVal) {
        this.hasUnsavedEvent = false;
        const allEvents = newVal;
        for (let i = 0; i < allEvents.length; i++) {
          if (!allEvents[i].isSaved) {
            this.hasUnsavedEvent = true;
          }
        }
      },
      deep: true
    },
    selectedDetails: {
      handler(newVal, oldVal) {
        !isEqual(newVal, oldVal) && this.type === 'brand' && newVal.length
          ? (this.disableApplyActionBrand = false)
          : (this.disableApplyActionBrand = true);
        !isEqual(newVal, oldVal) && this.type === 'portfolio' && newVal.length
          ? (this.disableApplyActionPortfolio = false)
          : (this.disableApplyActionPortfolio = true);
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.$store.dispatch('setVisitedSteps', 'cumulativeStep');
    this.config = widgetsConfig.config(this);
    this.localProfileDropdownOptions = JSON.parse(
      JSON.stringify(this.profileDropdownOptions)
    );
    this.localPortfolioDropdownOptions = JSON.parse(
      JSON.stringify(this.portfolioDropdownOptions)
    );
    this.appliedProfileList = cloneDeep(this.localProfileDropdownOptions);
    this.appliedPortfolioList = cloneDeep(this.localPortfolioDropdownOptions);
    this.monthlyPercentageDistrubtionToParentSetter();
  },
  mounted() {
    this.selectEntireBusinessScope();
    this.seasonalityEvents = [
      ...this.$store.getters.getSeasonalityEventsEntities
    ];
    this.seasonalityEvents.map((item) => {
      this.newCalculateChildValues(item);
    });
    this.tempEventsArr.push(...this.seasonalityEvents);
    this.currentMonthAmsSpend = this.$store.getters.getCurrentMonthAmsSpend;
    this.seasonalityEvents.forEach((evt) => {
      this.updateRemainingBudgetHashMap(this.remainingBudgetHashMap, evt);
    });
  },
  methods: {
    handleChange(data, type) {
      this.type = type;
      this.selectedDetails = cloneDeep(data);
    },
    reset() {
      this.newEffectByParentMap = {};
      this.seasonalityEvents.map((item) => {
        this.newCalculateChildValues(item);
      });
    },
    monthlyPercentageDistrubtionToParentSetter() {
      const memorizeIndex = {};
      const dataMap = {};
      const budgetPlannerEntities = this.budgetPlannerEntities;
      for (let i = 0; i < this.budgetPlannerEntities.length; i++) {
        const { executionScope, executionStrategies } =
          this.budgetPlannerEntities[i];
        const relatedBusinessScopeId = executionScope.relatedBusinessScopeId;
        if (executionScope.businessScope === 'ENTIRE_BUSINESS') {
          this.entityParentChildMap.ENTIRE_BUSINESS =
            this.entityParentChildMap[executionScope.businessScopeId];
          if (
            this.entityParentChildMap &&
            this.entityParentChildMap[executionScope.businessScopeId]
          ) {
            delete this.entityParentChildMap[executionScope.businessScopeId];
          }
          if (
            this.entityParentChildMap &&
            this.entityParentChildMap.ENTIRE_BUSINESS &&
            this.entityParentChildMap.ENTIRE_BUSINESS[
              executionScope.businessScopeId
            ]
          ) {
            delete this.entityParentChildMap.ENTIRE_BUSINESS[
              executionScope.businessScopeId
            ];
          }
          executionStrategies.map((item) => {
            if (item.type === 'PACING') {
              const monthKey = moment(item.start).format('MMM').toUpperCase();
              if (!dataMap.ENTIRE_BUSINESS) {
                dataMap.ENTIRE_BUSINESS = {};
              }
              dataMap.ENTIRE_BUSINESS[monthKey] = {
                percentage: null,
                absoluteAmount: item.value
              };
            }
          });
          continue;
        }
        if (!relatedBusinessScopeId) {
          continue;
        }
        if (!this.entityParentChildMap[relatedBusinessScopeId]) {
          this.entityParentChildMap[relatedBusinessScopeId] = {};
        }
        this.entityParentChildMap[relatedBusinessScopeId][
          executionScope.businessScopeId
        ] = null;
        if (!dataMap[executionScope.businessScopeId]) {
          dataMap[executionScope.businessScopeId] = {};
        }
        if (executionScope.businessScope === 'PORTFOLIO') {
          executionStrategies.map((item) => {
            if (item.type === 'PACING') {
              const monthKey = moment(item.start).format('MMM').toUpperCase();
              dataMap[executionScope.businessScopeId][monthKey] = {
                percentage: item.value,
                absoluteAmount: item.absoluteMonthlyBudget
              };
            }
          });
          continue;
        }
        let index = -1;
        if (!memorizeIndex[relatedBusinessScopeId]) {
          index = this.budgetPlannerEntities.findIndex(
            ({ executionScope, executionStrategies }) => {
              return executionScope.businessScopeId === relatedBusinessScopeId;
            }
          );
          memorizeIndex[relatedBusinessScopeId] = index;
        } else {
          index = memorizeIndex[relatedBusinessScopeId];
        }
        if (index > -1) {
          executionStrategies.map((item) => {
            if (item.type === 'PACING') {
              const monthKey = moment(item.start).format('MMM').toUpperCase();
              if (!item.value) {
                dataMap[executionScope.businessScopeId][monthKey] = {
                  percentage: item.value,
                  absoluteAmount: item.value
                };
              } else {
                const monthIndex = budgetPlannerEntities[
                  index
                ].executionStrategies.findIndex((parentItem) => {
                  if (
                    item.label.toUpperCase() === parentItem.label.toUpperCase()
                  ) {
                    return item.type === 'PACING';
                  } else {
                    return false;
                  }
                });
                if (monthIndex > -1) {
                  const percentage =
                    (item.value /
                      budgetPlannerEntities[index].executionStrategies[
                        monthIndex
                      ].value) *
                    100;
                  dataMap[executionScope.businessScopeId][monthKey] = {
                    percentage,
                    absoluteAmount: item.value
                  };
                }
              }
            }
          });
        }
      }
      this.distributionMap = dataMap;
    },
    clickOutside() {
      this.applyClicked = true;
      document.querySelector('.dummy-element').click();
    },
    onTabClick(selection) {
      this.pacingSelectionType = selection;
    },
    selectEntireBusinessScope() {
      if (this.entireBusinessObj) {
        this.localProfileDropdownOptions.forEach(
          (profile) => delete profile.selected
        );
        this.localPortfolioDropdownOptions.forEach(
          (portfolio) => delete portfolio.selected
        );
        this.generateDimensionNameValueList(
          this.entireBusinessObj,
          'entire_business'
        );
      }
    },
    // Applying profile filter
    // TODO - Nikhil: This function is hack to make it work for the release, we need to revisit it
    // Hack here is on the apply button fix and need to move the apply button to boomerang-ui
    setProfileInFilters(context, data) {
      if (this.applyClicked) {
        this.localPortfolioDropdownOptions.forEach(
          (portfolio) => delete portfolio.selected
        );
        this.generateDimensionNameValueList(data, 'profile');
        this.applyClicked = false;
        this.$nextTick(() => {
          this.appliedProfileList = cloneDeep(this.localProfileDropdownOptions);
        });
      } else {
        this.$nextTick(() => {
          for (let i = 0; i < this.appliedProfileList.length; i++) {
            if (!this.appliedProfileList[i].selected) {
              delete this.localProfileDropdownOptions[i].selected;
            }
          }
        });
      }
    },
    // Applying portfolio filter
    // TODO - Nikhil: This function is hack to make it work for the release, we need to revisit it
    // Hack here is on the apply button fix and need to move the apply button to boomerang-ui
    setPortfolioInFilters(context, data) {
      if (this.applyClicked) {
        this.localProfileDropdownOptions.forEach(
          (profile) => delete profile.selected
        );
        this.generateDimensionNameValueList(data, 'portfolio');
        this.applyClicked = false;
        this.$nextTick(() => {
          this.appliedPortfolioList = cloneDeep(
            this.localPortfolioDropdownOptions
          );
        });
      } else {
        this.$nextTick(() => {
          for (let i = 0; i < this.appliedPortfolioList.length; i++) {
            if (!this.appliedPortfolioList[i].selected) {
              delete this.localPortfolioDropdownOptions[i].selected;
            }
          }
        });
      }
    },
    // generates the dimension name value list for the api
    generateDimensionNameValueList(entityArray, name) {
      const dimensionNameValueList = [];
      this.tippyText = '';
      entityArray.forEach((item) => {
        // computing tippyText in the same loop
        this.tippyText += ' ' + item.title + ',';
        dimensionNameValueList.push({
          dimensionName:
            item.dimensionName === 'ENTIRE_BUSINESS'
              ? item.dimensionName
              : item.dimensionName + '_ID', // since pacing curve API accepts dimensionName differently than the entity payload key
          dimensionValue: item.dimensionValue
        });
      });
      this.selectionType = name;
      this.tippyText =
        this.tippyText.length > 0
          ? this.tippyText.slice(0, -1)
          : this.tippyText;
      if (dimensionNameValueList.length === 0) {
        this.selectEntireBusinessScope();
        return;
      }
      this.graphData.dimensionNameValueList = dimensionNameValueList;
      this.fetchBudgetPacingGraphData();
    },
    getChartDataWithRollUp(context, selection) {
      if (selection.length === 0) {
        return;
      }
      this.selectedRollUp = {
        name: selection[0].name,
        value: selection[0].value
      };
      this.graphData.rollUp = selection[0].value;
      this.fetchBudgetPacingGraphData();
    },
    // calls the pacing graph api
    fetchBudgetPacingGraphData() {
      if (!this.$route?.query?.nextYearPlan) {
        this.$store.dispatch('getBudgetPacingGraphData', this.graphData);
      } else {
        this.$store.dispatch('setSeasonalityToSavePayload');
      }
    },
    addEvent() {
      const validDate = this?.$route?.query.nextYearPlan
        ? moment().startOf('year').add(1, 'years').format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');
      const newEvent = {
        type: 'SEASONALITY',
        label: null,
        eventInfo: {
          description: ''
        },
        start: validDate,
        end: validDate,
        value: null,
        valueType: 'PERCENTAGE',
        eventId: Math.floor(Math.random() * 1000000) + new Date().getTime(),
        selectedScope: {
          dimensionName: 'ENTIRE_BUSINESS',
          dimensionValue: 'ENTIRE_BUSINESS'
        },
        calculatedAbsAmount: 0,
        isSaved: false,
        isValid: false,
        newEvent: true
      };
      this.seasonalityEvents.push(newEvent);
      this.tempEventsArr.push(newEvent);
      this.updateRemainingBudgetHashMap(this.remainingBudgetHashMap, newEvent);
    },
    updateRemainingBudgetHashMap(hashMap, eventObj) {
      const monthKey = moment(eventObj.start).format('MMM').toUpperCase();
      const scopeId = eventObj.selectedScope.dimensionValue;
      const parentScope = eventObj.selectedScope.relatedEntityScope;
      const parentScopeId = eventObj.selectedScope.relatedEntityId;

      if (!hashMap[monthKey]) {
        hashMap[monthKey] = {};
      }

      if (!hashMap[monthKey].ENTIRE_BUSINESS) {
        let initialBudgetAmount = this.budgetPlannerEntities
          .filter(
            (entity) =>
              entity.executionScope.businessScope === 'ENTIRE_BUSINESS'
          )[0]
          .executionStrategies.filter(
            (item) =>
              item.type === 'PACING' && item.label.toUpperCase() === monthKey
          )[0].value;
        if (
          monthKey === moment().format('MMM').toUpperCase() &&
          this.currentMonthAmsSpend.ENTIRE_BUSINESS >= 0
        ) {
          initialBudgetAmount =
            initialBudgetAmount - this.currentMonthAmsSpend.ENTIRE_BUSINESS;
        }
        hashMap[monthKey].ENTIRE_BUSINESS = {
          type: 'ENTIRE_BUSINESS',
          remainingBudget: initialBudgetAmount
        };
      }

      if (!parentScope) {
        // if entity is ENTIRE_BUSINESS
        hashMap[monthKey].ENTIRE_BUSINESS.remainingBudget -=
          eventObj.calculatedAbsAmount;
        if (hashMap[monthKey].ENTIRE_BUSINESS.remainingBudget < 0) {
          this.isBudgetHashValid = false;
        }
      } else if (parentScope === 'ENTIRE_BUSINESS') {
        // if entity is a PROFILE
        if (!hashMap[monthKey].ENTIRE_BUSINESS[scopeId]) {
          let initialBudgetAmount = this.budgetPlannerEntities
            .filter(
              (entity) => entity.executionScope.businessScopeId === scopeId
            )[0]
            .executionStrategies.filter(
              (item) =>
                item.type === 'PACING' && item.label.toUpperCase() === monthKey
            )[0].value;
          if (
            monthKey === moment().format('MMM').toUpperCase() &&
            this.currentMonthAmsSpend[scopeId] >= 0
          ) {
            initialBudgetAmount =
              initialBudgetAmount - this.currentMonthAmsSpend[scopeId];
          }
          hashMap[monthKey].ENTIRE_BUSINESS[scopeId] = {
            type: 'PROFILE',
            remainingBudget: initialBudgetAmount
          };
        }
        hashMap[monthKey].ENTIRE_BUSINESS[scopeId].remainingBudget -=
          eventObj.calculatedAbsAmount;
        // hashMap[monthKey]['ENTIRE_BUSINESS']['remainingBudget'] -= eventObj.calculatedAbsAmount
        if (
          hashMap[monthKey].ENTIRE_BUSINESS[scopeId].remainingBudget < 0 ||
          hashMap[monthKey].ENTIRE_BUSINESS.remainingBudget < 0
        ) {
          this.isBudgetHashValid = false;
        }
      } else if (parentScope === 'PROFILE') {
        // if entity is PORTFOLIO
        if (!hashMap[monthKey].ENTIRE_BUSINESS[parentScopeId]) {
          let initialBudgetAmount = this.budgetPlannerEntities
            .filter(
              (entity) =>
                entity.executionScope.businessScopeId === parentScopeId
            )[0]
            .executionStrategies.filter(
              (item) =>
                item.type === 'PACING' && item.label.toUpperCase() === monthKey
            )[0].value;
          if (
            monthKey === moment().format('MMM').toUpperCase() &&
            this.currentMonthAmsSpend[parentScopeId] >= 0
          ) {
            initialBudgetAmount =
              initialBudgetAmount - this.currentMonthAmsSpend[parentScopeId];
          }
          hashMap[monthKey].ENTIRE_BUSINESS[parentScopeId] = {
            type: 'PROFILE',
            remainingBudget: initialBudgetAmount
          };
        }
        if (!hashMap[monthKey].ENTIRE_BUSINESS[parentScopeId][scopeId]) {
          let initialBudgetAmount = this.budgetPlannerEntities
            .filter(
              (entity) => entity.executionScope.businessScopeId === scopeId
            )[0]
            .executionStrategies.filter(
              (item) =>
                item.type === 'PACING' && item.label.toUpperCase() === monthKey
            )[0].absoluteMonthlyBudget;
          if (
            monthKey === moment().format('MMM').toUpperCase() &&
            this.currentMonthAmsSpend[scopeId] >= 0
          ) {
            initialBudgetAmount =
              initialBudgetAmount - this.currentMonthAmsSpend[scopeId];
          }
          hashMap[monthKey].ENTIRE_BUSINESS[parentScopeId][scopeId] = {
            type: 'PORTFOLIO',
            remainingBudget: initialBudgetAmount
          };
        }
        hashMap[monthKey].ENTIRE_BUSINESS[parentScopeId][
          scopeId
        ].remainingBudget -= eventObj.calculatedAbsAmount;
        // hashMap[monthKey]['ENTIRE_BUSINESS'][parentScopeId]['remainingBudget'] -= eventObj.calculatedAbsAmount
        // hashMap[monthKey]['ENTIRE_BUSINESS']['remainingBudget'] -= eventObj.calculatedAbsAmount
        if (
          hashMap[monthKey].ENTIRE_BUSINESS[parentScopeId][scopeId]
            .remainingBudget < 0 ||
          hashMap[monthKey].ENTIRE_BUSINESS[parentScopeId].remainingBudget <
            0 ||
          hashMap[monthKey].ENTIRE_BUSINESS.remainingBudget < 0
        ) {
          this.isBudgetHashValid = false;
        }
      }
      return hashMap;
    },
    addMissingStructure(effectByParentMap, scopeId, monthKey, eventId) {
      if (!effectByParentMap[scopeId]) {
        effectByParentMap[scopeId] = {};
      }
      if (!effectByParentMap[scopeId][monthKey]) {
        effectByParentMap[scopeId][monthKey] = {
          events: {},
          tracker: {},
          totalEffect: 0,
          subtractValue: 0
        };
      }
    },
    newCalculateChildValues(eventObj) {
      const effectByParentMap = this.newEffectByParentMap;
      const monthKey = moment(eventObj.start).format('MMM').toUpperCase();
      const scopeId = eventObj.selectedScope.dimensionValue;
      let absoluteAmountWithRespectToMain = eventObj.value;
      if (eventObj.valueType === 'PERCENTAGE') {
        absoluteAmountWithRespectToMain =
          (this.distributionMap[scopeId][monthKey].absoluteAmount *
            eventObj.value) /
          100;
      }
      if (!effectByParentMap[scopeId]) {
        effectByParentMap[scopeId] = {};
      }
      if (!effectByParentMap[scopeId][monthKey]) {
        effectByParentMap[scopeId][monthKey] = {
          events: {},
          tracker: {},
          totalEffect: 0,
          subtractValue: 0
        };
      }
      if (!effectByParentMap[scopeId][monthKey].tracker[eventObj.eventId]) {
        effectByParentMap[scopeId][monthKey].tracker[eventObj.eventId] = {
          absoluteAmountWithRespectToMain: absoluteAmountWithRespectToMain,
          value: eventObj.value,
          valueType: eventObj.valueType,
          childTracker: eventObj.eventId,
          trackerBy: scopeId
        };
      }
      for (const childId in this.entityParentChildMap[scopeId]) {
        this.addMissingStructure(
          effectByParentMap,
          childId,
          monthKey,
          eventObj.eventId
        );
        effectByParentMap[childId][monthKey].events[eventObj.eventId] = {
          absoluteAmountWithRespectToMain: absoluteAmountWithRespectToMain,
          value: eventObj.value,
          valueType: eventObj.valueType,
          parentTracker: eventObj.eventId,
          eventBy: scopeId
        };
      }
      const sumMap = {};
      for (const childId in effectByParentMap) {
        if (!effectByParentMap[childId][monthKey]) {
          continue;
        }
        for (const event in effectByParentMap[childId][monthKey].events) {
          if (!sumMap[childId]) {
            sumMap[childId] = 0;
          }
          sumMap[childId] +=
            effectByParentMap[childId][monthKey].events[
              event
            ].absoluteAmountWithRespectToMain;
        }
      }
      let index = 0;
      for (const childId in this.entityParentChildMap[scopeId]) {
        const subtractValue =
          (sumMap[childId] *
            this.distributionMap[childId][monthKey].percentage) /
          100;
        effectByParentMap[childId][monthKey].subtractValue = subtractValue;
        effectByParentMap[childId][monthKey].totalEffect = sumMap[childId];
        const newEventObj = {
          type: 'SEASONALITY',
          label: null,
          eventInfo: {
            description: ''
          },
          start: eventObj.start,
          end: eventObj.end,
          value: subtractValue,
          valueType: 'ABSOLUTE',
          selectedScope: {
            dimensionValue: childId
          },
          eventId: 'custom' + index + childId
        };
        index++;
        this.newCalculateChildValues(newEventObj);
      }
      this.newEffectByParentMap = effectByParentMap;
    },
    clearRemainingBudgetHashMap(hashMap, eventObj) {
      const monthKey = moment(eventObj.start).format('MMM').toUpperCase();
      const scopeId = eventObj.selectedScope.dimensionValue;
      const parentScope = eventObj.selectedScope.relatedEntityScope;
      const parentScopeId = eventObj.selectedScope.relatedEntityId;

      if (
        !parentScope &&
        hashMap &&
        hashMap[monthKey] &&
        hashMap[monthKey].ENTIRE_BUSINESS
      ) {
        // if entity is ENTIRE_BUSINESS
        hashMap[monthKey].ENTIRE_BUSINESS.remainingBudget +=
          eventObj.calculatedAbsAmount;
      } else if (
        parentScope === 'ENTIRE_BUSINESS' &&
        hashMap[monthKey] &&
        hashMap[monthKey].ENTIRE_BUSINESS &&
        hashMap[monthKey].ENTIRE_BUSINESS[scopeId]
      ) {
        // if entity is a PROFILE
        hashMap[monthKey].ENTIRE_BUSINESS[scopeId].remainingBudget +=
          eventObj.calculatedAbsAmount;
        // hashMap[monthKey]['ENTIRE_BUSINESS']['remainingBudget'] += eventObj.calculatedAbsAmount
      } else if (
        parentScope === 'PROFILE' &&
        hashMap[monthKey] &&
        hashMap[monthKey].ENTIRE_BUSINESS &&
        hashMap[monthKey].ENTIRE_BUSINESS[parentScopeId] &&
        hashMap[monthKey].ENTIRE_BUSINESS[parentScopeId][scopeId]
      ) {
        // if entity is PORTFOLIO
        hashMap[monthKey].ENTIRE_BUSINESS[parentScopeId][
          scopeId
        ].remainingBudget += eventObj.calculatedAbsAmount;
        // hashMap[monthKey]['ENTIRE_BUSINESS'][parentScopeId]['remainingBudget'] += eventObj.calculatedAbsAmount
        // hashMap[monthKey]['ENTIRE_BUSINESS']['remainingBudget'] += eventObj.calculatedAbsAmount
      }
      return hashMap;
    },
    saveSeasonalityEvent(eventObj) {
      const eventId = eventObj.eventId;
      const index = this.seasonalityEvents.findIndex(
        (eventObj) => eventObj.eventId === eventId
      );
      const oldEventObj = this.seasonalityEvents[index];
      this.seasonalityEvents[index] = eventObj;
      this.seasonalityEvents[index].newEvent = false;
      this.hasUnsavedEvent = false;
      this.unsavedEventPresent = false;
      this.reset();
      setTimeout(() => {
        this.$set(this.seasonalityEvents, 0, this.seasonalityEvents[0]);
        this.$forceUpdate();
      });
      this.tempEventsArr.forEach((evtObj) => {
        if (!evtObj.isSaved) {
          this.hasUnsavedEvent = true;
        }
      });
      this.remainingBudgetHashMap = this.clearRemainingBudgetHashMap(
        this.remainingBudgetHashMap,
        oldEventObj
      );
      this.remainingBudgetHashMap = this.updateRemainingBudgetHashMap(
        this.remainingBudgetHashMap,
        eventObj
      );
      eventBus.$emit(
        'remainingBudgetHashMapUpdated',
        this.remainingBudgetHashMap
      );
      const events = this.seasonalityEvents.filter((evt) => evt.isSaved);
      this.$store.commit('UPDATE_SEASONALITY_EVENTS_IN_STORE', events);
      this.fetchBudgetPacingGraphData();
    },
    validateRemainingBudgetHash(eventObj) {
      const eventId = eventObj.eventId;
      const index = this.seasonalityEvents.findIndex(
        (eventObj) => eventObj.eventId === eventId
      );
      const oldEventObj = this.seasonalityEvents[index];
      let hashToTest = cloneDeep(this.remainingBudgetHashMap);
      hashToTest = this.clearRemainingBudgetHashMap(hashToTest, oldEventObj);
      this.isBudgetHashValid = true;
      this.updateRemainingBudgetHashMap(hashToTest, eventObj);
      return this.isBudgetHashValid;
    },
    deleteEvent(eventId) {
      const eventObj = this.tempEventsArr.filter(
        (eventObj) => eventObj.eventId === eventId
      )[0];
      this.seasonalityEvents = this.seasonalityEvents.filter(
        (eventObj) => eventObj.eventId !== eventId
      );
      const events = this.seasonalityEvents.filter((evt) => evt.isSaved);
      this.$store.commit('UPDATE_SEASONALITY_EVENTS_IN_STORE', events);
      this.unsavedEventPresent = false;
      this.tempEventsArr = this.tempEventsArr.filter(
        (eventObj) => eventObj.eventId !== eventId
      );
      this.validateEvents();
      if (eventObj.isSaved) {
        this.clearRemainingBudgetHashMap(this.remainingBudgetHashMap, eventObj);
        eventBus.$emit(
          'remainingBudgetHashMapUpdated',
          this.remainingBudgetHashMap
        );
        this.fetchBudgetPacingGraphData();
      }
      this.reset();
    },
    isCurrentEventSaved(eventId) {
      const currTempEvent = this.tempEventsArr.filter(
        (evt) => evt.eventId === eventId
      )[0];
      return currTempEvent ? currTempEvent.isSaved : true;
    },
    setSaveStatus(eventObj) {
      const allEvents = this.tempEventsArr;
      for (let i = 0; i < allEvents.length; i++) {
        if (eventObj.eventId === allEvents[i].eventId) {
          allEvents[i].isSaved = eventObj.isSaved;
        }
      }
    },
    eventDataUpdated(eventObj, comingFromSave) {
      const currentIndex = this.tempEventsArr.findIndex(
        (eObj) => eObj.eventId.toString() === eventObj.eventId.toString()
      );
      this.tempEventsArr[currentIndex] = eventObj;
      if (comingFromSave) {
        this.validateEvents();
      }
    },
    validateCurrentEvent(eventId) {
      const allEvents = this.tempEventsArr.filter((eObj) => eObj.isSaved);
      const currentEvent = this.tempEventsArr.filter(
        (eObj) => eObj.eventId === eventId
      )[0];

      let duplicateTitles = false;
      let overlapSameScope = false;
      let overlapParentChildScope = false;
      this.displayErrorMessages = [];
      for (let i = 0; i < allEvents.length; i++) {
        if (allEvents[i].label === currentEvent.label) {
          duplicateTitles = true;
        }
        const rangeI = moment.range(allEvents[i].start, allEvents[i].end);
        const rangeJ = moment.range(currentEvent.start, currentEvent.end);
        if (rangeI.overlaps(rangeJ, { adjacent: true })) {
          if (
            allEvents[i].selectedScope.dimensionValue ===
            currentEvent.selectedScope.dimensionValue
          ) {
            overlapSameScope = true;
          } else if (
            allEvents[i].selectedScope.dimensionValue ===
              currentEvent.selectedScope.relatedEntityId ||
            currentEvent.selectedScope.dimensionValue ===
              allEvents[i].selectedScope.relatedEntityId
          ) {
            overlapParentChildScope = true;
          }
        }
      }
      if (duplicateTitles) {
        this.displayErrorMessages.push(
          'Event name cannot be same for 2 or more events.'
        );
      }
      if (overlapSameScope) {
        this.displayErrorMessages.push(
          'Date range cannot overlap for 2 or more events with the same scope.'
        );
      }
      if (overlapParentChildScope) {
        this.displayErrorMessages.push(
          "Date range cannot overlap for a scope which is a parent/child of another event's scope."
        );
      }
      if (this.displayErrorMessages.length) {
        return false;
      } else {
        return true;
      }
    },
    validateEvents() {
      const allEvents = this.tempEventsArr;
      let invalidEventDataPresent = false;
      let duplicateTitles = false;
      let overlapSameScope = false;
      let overlapParentChildScope = false;
      let invalidRemainingBudgetPresent = false;
      this.displayErrorMessages = [];

      for (let i = 0; i < allEvents.length; i++) {
        if (!allEvents[i].isValid) {
          invalidEventDataPresent = true;
        }
        if (allEvents[i].remainingBudgetInvalid) {
          invalidRemainingBudgetPresent = true;
        }
        for (let j = 0; j < allEvents.length; j++) {
          if (i !== j) {
            if (allEvents[i].label === allEvents[j].label) {
              duplicateTitles = true;
            }
            const rangeI = moment.range(allEvents[i].start, allEvents[i].end);
            const rangeJ = moment.range(allEvents[j].start, allEvents[j].end);
            if (rangeI.overlaps(rangeJ, { adjacent: true })) {
              if (
                allEvents[i].selectedScope.dimensionValue ===
                allEvents[j].selectedScope.dimensionValue
              ) {
                overlapSameScope = true;
              } else if (
                allEvents[i].selectedScope.dimensionValue ===
                  allEvents[j].selectedScope.relatedEntityId ||
                allEvents[j].selectedScope.dimensionValue ===
                  allEvents[i].selectedScope.relatedEntityId
              ) {
                overlapParentChildScope = true;
              }
            }
          }
        }
      }
      if (invalidEventDataPresent) {
        this.displayErrorMessages.push(
          'Event name or budget value is invalid for one or more events.'
        );
      }
      if (invalidRemainingBudgetPresent) {
        this.displayErrorMessages.push(
          'Event budget is aggregating more than remaining amount for parent scope, please decrease your budget.'
        );
      }
      if (duplicateTitles) {
        this.displayErrorMessages.push(
          'Event name cannot be same for 2 or more events.'
        );
      }
      if (overlapSameScope) {
        this.displayErrorMessages.push(
          'Date range cannot overlap for 2 or more events with the same scope.'
        );
      }
      if (overlapParentChildScope) {
        this.displayErrorMessages.push(
          "Date range cannot overlap for a scope which is a parent/child of another event's scope."
        );
      }
      if (this.displayErrorMessages.length) {
        return false;
      } else {
        return true;
      }
    },
    async checkIfBudgetPlanWasUpdated() {
      try {
        const response = await HttpService.get('GET_BUDGET_PLANNER_STRATEGIES');
        return (
          response?.data?.[0]?.actions?.[0]?.executionTemplate
            ?.lastUploadTime || null
        );
      } catch {
        this.openSnackbar('Cannot fetch latest budget plan information');
        return null;
      }
    },
    openSnackbar(message) {
      this.snackbar('', message);
    },
    async isBudgetPlanUpdated() {
      if (this.$route.query?.nextYearPlan) return;
      const lastUpdatedDate = this.budgetPlanLastUpdated;
      const momentLastUpdatedDate = lastUpdatedDate && moment(lastUpdatedDate);
      if (momentLastUpdatedDate) {
        this.openSnackbar('Checking if budget plan was updated recently');
        const latestUpdatedDate = await this.checkIfBudgetPlanWasUpdated();
        if (!latestUpdatedDate) {
          return false;
        }
        const momentLatestUpdatedDate =
          latestUpdatedDate && moment(latestUpdatedDate);
        if (momentLatestUpdatedDate.diff(momentLastUpdatedDate) !== 0) {
          return true;
        }
      }
      return false;
    },
    async nextStep() {
      const isBudgetPlanUpdated = await this.isBudgetPlanUpdated();
      if (isBudgetPlanUpdated) {
        this.openSnackbar(
          'Budget plan was updated recently, please refresh the page to view the latest changes'
        );
        return;
      }
      this.saveBudgetPlannerStrategy();
    },
    goBack() {
      this.$emit('prevStep', {});
    }
  }
};
</script>

<style lang="css">
.budget-panner-dashboard .split-tabs-container .split-tabs {
  line-height: 1.3 !important;
}
.budget-panner-dashboard .split-tabs-container .split-tabs div {
  border-radius: 24px !important;
}
</style>

<style lang="css" scoped>
.create-plan--steps:not(:first-child) {
  margin-top: 100px;
}
.step-workspace {
  padding-top: 40px;
}
.valueDropdown {
  border-radius: 39px !important;
  border: solid 1px #e9eaeb;
}
.valueDropdown:hover,
.valueDropdown:focus {
  border: 1px solid #007cf6 !important;
}
.valueDropdown:hover *,
.valueDropdown:focus * {
  fill: #007cf6 !important;
  color: #007cf6 !important;
}
.customBorder {
  border-radius: 4px;
  border: solid 1px #e9eaeb;
}
.is-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.activeSelection {
  border: 1px solid #007cf6 !important;
  color: #007cf6 !important;
}
.loader {
  height: 8px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #007bf63b;
  border-radius: 100px;
}
.loader:before {
  display: block;
  position: absolute;
  content: '';
  width: 100%;
  height: 8px;
  background-color: #007cf6;
  animation: loading 12s linear;
}
@keyframes loading {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
</style>
