/* eslint-disable @typescript-eslint/camelcase */
import Vue from 'vue';
import { formatter } from '@/utils/helpers/formatter.js';
import customTippy from '@/components/pages/insights/amazon/share-of-voice/atoms/customTippy.vue';
import {
  setGlobalSettingsForMS,
  constants,
  isMarketShareLite,
  categoryConstants
} from '@/components/pages/insights/amazon/market-share/utils';
import expandableTooltip from '@/components/pages/insights/amazon/market-share/molecules/expandable-tooltip.vue';

const metricCellDisplay = Vue.extend({
  data() {
    return {
      renderParams: {
        invertTag: 'invertTag',
        data1: 'data1',
        data1_type: 'data1_type',
        data2: 'data2',
        data2_type: 'data2_type',
        comparisonTooltip: 'comparisonTooltip'
      }
    };
  },
  created() {
    if (this.params?.renderParams !== undefined) {
      this.renderParams = this.params?.renderParams;
    }
  },
  methods: {
    formatter: formatter
  },
  template: `<div class="u-width-100 u-display-flex u-height-100 u-flex-justify-content-flex-end">
  <div v-tippy="{ placement : 'top', arrow: false }" title="Data is unavailable for this time period" class="u-display-flex u-flex-align-items-center u-spacing-mr-m" v-if="(!params.data[renderParams.data1] && params.data[renderParams.data1] !== 0) && (!params.data[renderParams.data2] && params.data[renderParams.data2] !== 0)">
    <span>NA</span>
  </div>
  <div v-else class="u-display-flex u-flex-align-items-center u-flex-direction-row u-flex-justify-content-center">
    <p :value="params.data[renderParams.data1]"
      class="primary_metric u-spacing-ml-m u-spacing-mt-xxs u-spacing-mr-s u-color-grey-base">
      {{formatter(params.data[renderParams.data1], params.data[renderParams.data1_type] || params.data1_type, undefined, 2, 2)}}
    </p>
    <span
      v-if="params.data[renderParams.invertTag] === true"
      v-trend-indicator="{value: params.data[renderParams.data2]}"
      :value="params.data[renderParams.data2]"
      class="secondary_metric"
      :class="{'u-color-red-base': params.data[renderParams.data2] > 0, 'u-color-green-base': params.data[renderParams.data2] < 0}"
    >
      <span v-if="params.data[renderParams.data2]">{{formatter(params.data[renderParams.data2], params.data[renderParams.data2_type] || params.data2_type, undefined, 2, 2)}}</span>
      <span v-else class="u-color-grey-xx-lighter">- vs </span>
    </span>
    <span
      v-else
      v-trend-indicator="{value: params.data[renderParams.data2]}"
      :value="params.data[renderParams.data2]"
      class="secondary_metric"
      :class="{'u-color-red-base': params.data[renderParams.data2] < 0, 'u-color-green-base': params.data[renderParams.data2] > 0}"
    >
    <span v-if="params.data[renderParams.data2] === 0 || params.data[renderParams.data2]"> {{formatter(params.data[renderParams.data2], params.data[renderParams.data2_type] || params.data2_type, undefined, 2, 2)}}</span>
      <span v-else v-tippy="{ placement: 'top' , arrow: false }" :title="params.renderParams.comparisonTooltip" class="u-color-grey-xx-lighter"> vs - </span>
    </span>
  </div>
</div>`
});

const textCellDisplay = Vue.extend({
  components: {
    customTippy,
    expandableTooltip
  },
  data() {
    return {
      renderParams: {
        invertTag: 'invertTag',
        text1: 'text1',
        text2: 'text2',
        showIcon: 'showIcon'
      }
    };
  },
  computed: {
    icon() {
      return this.params.data[this.renderParams.showIcon] ? 'star' : '';
    },
    tooltipPreText() {
      return this.params.data[this.renderParams.showIcon]
        ? 'Manufacturer: '
        : 'Manufacturer: ';
    },
    tippyOptions() {
      return {
        placement: 'bottom-start',
        arrow: false,
        html: '#tooltip-' + this.params?.node.rowIndex,
        maxWidth: '440px',
        interactive: true,
        reactive: true,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      };
    }
  },
  created() {
    if (this.params?.renderParams !== undefined) {
      this.renderParams = this.params?.renderParams;
    }
  },
  methods: {},
  template: `
  <div class="u-width-100 u-display-flex u-height-100 u-flex-justify-content-flex-start u-color-grey-base u-border-left u-border-width-s u-border-color-grey-xxx-light">
  <div v-if="renderParams.enableTooltip">
  <expandableTooltip
    v-if="renderParams.showBrandsTooltip"
    :target-id="'tooltip-' + params.node.rowIndex"
    :primary-text="params.data.manufacturer"
    :primary-pre-text="'manufacturer: '"
    :secondary-pre-text="'associated brands: '"
    :items-list="params.data.brands_list?.split(', ') || []"
  />
  <customTippy
    v-else
    :id="'tooltip-' + params.node.rowIndex"
    :show-icon="params.data[renderParams.showIcon]"
    :icon="params.data[renderParams.showIcon] ? 'star' : ''"
    :pre-text="tooltipPreText"
    :title="params.data[renderParams.text2]"
  />
  </div>
  <div class="u-display-flex u-flex-align-items-center u-spacing-mr-m" v-if="!params.data[renderParams.text1] && !params.data[renderParams.text2]">
      <span>NA</span>
    </div>
    <div v-else class="u-display-flex u-flex-align-items-center u-flex-direction-row u-flex-justify-content-center">
      <rb-icon icon="star" class="u-flex-0 rb-icon--small u-color-grey-lighter u-spacing-mr-xs"   v-bind:class="{ 'u-visibility-hidden': !params.data[renderParams.showIcon] }"/>
      <div>
      <p
      :value="params.data[renderParams.text1]"
      class="u-spacing-mt-xxs u-spacing-mr-s" v-tippy="tippyOptions">
          {{params.data[renderParams.text1]}}
        </p>
        <p :value="params.data[renderParams.text2]" v-if="renderParams.showText2 && !params.data[renderParams.text1]"
          class="u-spacing-mt-xxs u-spacing-mr-s" :class="{'u-font-size-7 u-color-grey-light': params.data[renderParams.text1]}">
          {{params.data[renderParams.text2]}}
        </p>
      </div>
    </div>
  </div>`
});

const textLinkCellDisplay = Vue.extend({
  data() {
    return {
      renderParams: {
        invertTag: 'invertTag',
        text1: 'text1',
        text2: 'text2',
        showIcon: 'showIcon'
      },
      id: null
    };
  },
  computed: {
    tippyOptions() {
      return {
        html: '#comp' + this.id,
        placement: 'bottom',
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      };
    },
    compId() {
      return 'comp' + this.id;
    }
  },
  created() {
    if (this.params?.renderParams !== undefined) {
      this.renderParams = this.params?.renderParams;
    }
    this.id = this._uid;
  },
  methods: {
    navigateToPage(data) {
      const category = data.category
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      setGlobalSettingsForMS(constants.CATEGORY, category);
      // resets the category selection of other pages
      setGlobalSettingsForMS(constants.SUBCATEGORY, {}, true);
      setGlobalSettingsForMS(constants.FILTER_SELECTION, {}, true);
      setGlobalSettingsForMS(constants.CATEGORY_HIERARCHY, {}, true);
      setGlobalSettingsForMS(constants.ATTRIBUTES, {}, true);
      this.$router.push({
        name: isMarketShareLite()
          ? categoryConstants.mslPageName
          : categoryConstants.pageName
      });
    }
  },
  template: `<div class="u-width-100 u-display-flex u-height-100 u-flex-justify-content-flex-start u-color-grey-base u-border-left u-border-width-s u-border-color-grey-xxx-light u-spacing-pl-l">
    <div class="u-display-flex u-flex-align-items-center u-spacing-mr-m" v-if="!params.data[renderParams.text1]">
      <span>NA</span>
    </div>
    <div v-else class="u-display-flex u-flex-align-items-center u-flex-direction-row u-flex-justify-content-center">
    <div
      v-tippy="tippyOptions"
    >
      <div :id="compId" class="u-display-none">
        <div class="max-w-md w-full u-text-case-title">
          {{params.data[renderParams.text1]}}
        </div>
        <div class="u-spacing-mt-s u-color-grey-xx-light">
          Click for "Category View"
        </div>
      </div>
      <p :value="params.data[renderParams.text1]"
        class="u-spacing-mt-xxs u-spacing-mr-s u-border-bottom-dotted u-link-primary" @click="navigateToPage(params.data)">
        {{params.data[renderParams.text1]}}
      </p>
    </div>
    </div>
  </div>`
});

export { metricCellDisplay, textCellDisplay, textLinkCellDisplay };
