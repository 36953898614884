<template>
  <div class="u-spacing-pv-xxxl u-spacing-ph-xxl">
    <section class="u-spacing-pv-m">
      <Header :step="step" />
      <div class="u-spacing-m-negative-l">
        <BaseSplitPanelTemplate
          :panel-config="panelConfig"
          @updatedPanelData="updatePanelData"
        >
          <div
            slot="source-body"
            class="u-spacing-p-m"
          >
            <div class="u-spacing-mb-l u-spacing-mt-s">
              <div class="u-display-flex u-flex-align-items-center">
                <div
                  data-cy="defaultCpcBidText"
                  class="u-font-size-5 u-color-grey-base u-font-weight-600 u-spacing-mr-s"
                >
                  Default CPC Bid:
                </div>
                <span
                  data-cy="currency"
                  class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                >
                  {{ $currency }}
                </span>
                <input
                  ref="defaultBidInput"
                  v-model.number="defaultBid"
                  data-cy="inputValue"
                  type="number"
                  min="0"
                  class="rb-input currency-input"
                  step="0.01"
                  @click="$refs.defaultBidInput.select()"
                />
              </div>
            </div>

            <div
              class="u-display-flex u-width-100 u-flex-justify-content-space-between u-flex-direction-column"
            >
              <rb-input
                ref="searchBox"
                v-model="keywordTextInput"
                rows="2"
                placeholder="Enter keywords separated by new line"
                type="textarea"
                class="u-flex-1 search--input-s input-text-height"
                :rounded="false"
              />
              <div
                class="u-width-100 u-display-flex u-flex-justify-content-space-between u-flex-direction-row u-spacing-pv-s"
              >
                <div
                  class="u-font-size-7"
                  data-cy="additionOfSkuLimitTxt"
                >
                  You may add upto 1000 keywords.
                </div>
                <div class="u-spacing-pt-s">
                  <rb-button
                    :click-fn="addKeywordsFn"
                    :disabled="keywordTextInput.length === 0 || !!errorMessage"
                    text="Add keywords"
                    size="s"
                    icon-left="right-arrow"
                    type="filled"
                    class="u-color-grey-white"
                    style="border-left: none"
                    data-cy="addBtn"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            slot="result-body"
            class="u-display-flex u-flex-direction-column u-height-100"
          >
            <div class="tableBodyLeftNegativeKeywords">
              <div
                class="u-position-relative table-left"
                :style="{ height: tableConfig.resultTableHeight }"
              >
                <div
                  v-if="keywordsData.rows.length === 0"
                  class="fill--parent message"
                >
                  No keywords added.
                </div>
                <rb-insights-table
                  v-else
                  :config="tableConfigs"
                  :grid-options="gridOptions"
                  :auto-configure="keywordsData"
                  style="width: 100%"
                  :row-height="48"
                  :client-side-pagination="true"
                  :enable-client-side-sorting="false"
                  :enable-server-side-sorting="false"
                  :pagination="true"
                  :pagination-total-key="keywordsData.rows.length"
                  :pagination-per-page-limit="25"
                  :download-this-table="false"
                  :row-no-hover-state="true"
                />
              </div>
            </div>
          </div>
        </BaseSplitPanelTemplate>
      </div>
      <div
        v-if="errorMessage || addedKeywordsErrorMessage"
        class="u-spacing-mt-s u-font-size-6 u-display-flex u-flex-align-items-center"
        :class="showWarning ? 'u-color-orange-base' : 'u-color-red-base'"
      >
        <rb-icon
          class="u-flex-0 rb-icon--small"
          icon="error-fill"
        />
        <div class="u-flex-0 u-spacing-ml-xxs">
          {{ errorMessage || addedKeywordsErrorMessage }}
        </div>
      </div>
      <Footer
        class="u-spacing-pt-l"
        :btn-right-text="rightButtonText"
        :disable-right-btn="disableNextButton"
        @onClickBtnRight="nextStep"
        @onClickBtnLeft="prevStep"
      />
    </section>
  </div>
</template>

<script>
import Header from '@/components/ams/campaign_creation/steps/common/header.vue';
import Footer from '@/components/ams/campaign_creation/steps/common/footer.vue';
import AddKeywords from '@/components/ams/actionPanels/addNegativeKeywordsPanel.vue';
import BaseSplitPanelTemplate from '@/components/ams/actionPanels/baseSplitPanelTemplate.vue';
import InputComponent from '@/components/pages/strategy_builder/steps/input-component.vue';
import widgetsConfigRef from '@/components/pages/instacart/campaigns/campaigns/widgets.js';
import {
  STEPS_DATA_GETTER,
  STEPS_DATA_SETTER,
  BUDGET_TYPES,
  CREATE_ADGROUP,
  CAMPAIGN_DETAILS_GETTER
} from '../../config';
export default {
  name: 'Targeting',
  components: {
    Header,
    Footer,
    AddKeywords,
    BaseSplitPanelTemplate,
    InputComponent
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      rightButtonText: 'Create Ad Group',
      tableConfig: {
        sourceTableLabel: 'Add Keywords',
        resultTableLabel: 'Added Keywords',
        sourceTableWidth: 'calc((100% - 2 * 2.4rem) / 2)',
        resultTableWidth: 'calc((100% + 2 * 2.4rem) / 2)',
        resultTableHeight: '295px !important'
      },
      resultTableMetadata: [],
      resultTableData: [],
      defaultBid: 0.3,
      keywordTextInput: '',
      addedKeywordsErrorMessage: '',
      showWarning: false,
      panelData: {
        sourceCheckBox: ['Exact'],
        resultCheckBox: []
      },
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: {}
        }
      },
      tableConfigs: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      editingIndex: -1,
      actionName: 'keywords'
    };
  },
  computed: {
    campaignInfo() {
      return this.$store.getters[CAMPAIGN_DETAILS_GETTER] || {};
    },
    stepsData() {
      return this.$store.getters[STEPS_DATA_GETTER];
    },
    disableNextButton() {
      const { tempAdGroup, headerError } = this.stepsData;
      return !(
        tempAdGroup?.adGroupName &&
        this.defaultBid &&
        !this.addedKeywordsErrorMessage &&
        !this.errorMessage &&
        !headerError
      );
    },
    dailyBudget() {
      const { campaignEditFlow, steps } = this.stepsData;
      return campaignEditFlow
        ? this.campaignInfo?.data?.target_daily_budget
        : steps?.settings?.dailyBudget;
    },
    lifetimeBudget() {
      const { campaignEditFlow, steps } = this.stepsData;
      return campaignEditFlow
        ? this.$store.getters['entityDetails/getCampaignDetails']?.data?.budget
        : steps?.settings?.lifetimeBudget;
    },
    isDailyBudgetType() {
      const { campaignEditFlow, steps } = this.stepsData;
      return campaignEditFlow
        ? this.campaignInfo?.data?.budget_type === 'daily_budget'
        : steps?.settings?.budgetType === BUDGET_TYPES.dailyBudget.type;
    },
    panelConfig() {
      return {
        showBaseTemplate: true,
        header: '',
        source: {
          width: this.tableConfig.sourceTableWidth,
          header: this.tableConfig.sourceTableLabel
            ? this.tableConfig.sourceTableLabel
            : 'Add Keywords'
        },
        result: {
          width: this.tableConfig.resultTableWidth,
          header: this.tableConfig.resultTableLabel
            ? `${this.tableConfig.resultTableLabel} (${this.resultTableData.length})`
            : 'Added Keywords (' + this.resultTableData.length + ')'
        }
      };
    },
    keywordsData() {
      return {
        columns: this.resultTableMetadata,
        rows: this.resultTableData
      };
    },
    errorMessage() {
      const { budgetType } = this.stepsData.steps.settings;
      if (!this.defaultBid) {
        return 'Default CPC Bid cannot be empty';
      }

      if (this.defaultBid < 0.3) {
        return 'Default CPC bid should be greater than $0.3';
      }

      if (this.isDailyBudgetType && this.defaultBid > 85) {
        return 'Default CPC bid should be less than $85';
      }

      if (this.isDailyBudgetType && this.defaultBid >= this.dailyBudget) {
        return `Default CPC bid should be less than $${this.dailyBudget} (Daily Budget)`;
      }

      if (!this.isDailyBudgetType && this.defaultBid >= this.lifetimeBudget) {
        return `Default CPC bid should be less than $${this.lifetimeBudget} (Lifetime Budget)`;
      }

      return '';
    }
  },
  watch: {
    defaultBid(newVal) {
      const stepsData = { ...this.stepsData };
      if (
        newVal &&
        stepsData.steps.settings.budgetType === BUDGET_TYPES.dailyBudget.type
          ? newVal < stepsData.steps.settings.dailyBudget
          : newVal < stepsData.steps.settings.lifetimeBudget
      ) {
        this.showWarning = false;
      }
      stepsData.tempAdGroup.defaultBid = newVal;
      this.$store.dispatch(STEPS_DATA_SETTER, stepsData);
    },
    resultTableData(newVal) {
      this.updateGroupKeywords(newVal);
    }
  },
  created() {
    const stepsData = { ...this.$store.getters[STEPS_DATA_GETTER] };
    const {
      tempAdGroup,
      steps: { adGroups, settings }
    } = this.stepsData;
    const editingIndex = adGroups.findIndex(({ id }) => tempAdGroup.id === id);
    this.rightButtonText = editingIndex > -1 ? 'Save' : 'Create Ad Group';
    this.editingIndex = editingIndex;
    if (!tempAdGroup?.defaultBid) {
      stepsData.tempAdGroup.defaultBid = 0.3;
      this.$store.dispatch(STEPS_DATA_SETTER, stepsData);
    }
    if (editingIndex > -1) {
      if (tempAdGroup?.keywords) {
        this.resultTableData = tempAdGroup?.keywords;
      }
      this.defaultBid = tempAdGroup?.defaultBid || 0.3;
    }
    if (
      editingIndex > -1 &&
      (!this.defaultBid ||
        (this.isDailyBudgetType
          ? this.defaultBid >= settings.dailyBudget
          : this.defaultBid >= settings.lifetimeBudget))
    ) {
      this.showWarning = true;
    }
    this.widgetsConfig = widgetsConfigRef.config(this);
    this.resultTableMetadata =
      this.widgetsConfig.widgets.addNegativeKeywords.tableConfigs.metadata.resultTable;
  },
  methods: {
    updateGroupKeywords(value) {
      const stepsData = { ...this.stepsData };
      stepsData.tempAdGroup.keywords = value;
      this.$store.dispatch(STEPS_DATA_SETTER, stepsData);
    },
    setDefaultBid() {
      this.resultTableData = this.resultTableData.map((row) => {
        if (row.newBid === undefined) {
          row.newBid = this.defaultBid;
        }
        return row;
      });
    },
    updatePanelData(data) {
      this.panelData = data;
    },
    nextStep() {
      const stepsData = { ...this.stepsData };
      if (this.editingIndex > -1) {
        stepsData.steps.adGroups[this.editingIndex] = stepsData.tempAdGroup;
      } else {
        stepsData.steps.adGroups.push(stepsData.tempAdGroup);
      }
      if (stepsData.campaignEditFlow) {
        this.$store.dispatch(CREATE_ADGROUP, this).finally(() => {
          setTimeout(() => {
            this.$router.push({ name: 'InstacartEntityDetails' });
          }, 1000);
        });
      } else {
        stepsData.tempAdGroup = {};
        this.$store
          .dispatch(STEPS_DATA_SETTER, stepsData)
          .then(() => this.$parent.$emit('createAdGroup', 'submitting', 2));
      }
    },
    prevStep() {
      this.$emit('prevStep', {});
    },
    addKeywordsFn() {
      const keywords = this.keywordTextInput.split(/\r?\n/);
      const uniqueKeywords = [];
      let keywordNotPresent = true;
      let keywordsExist = false;
      let duplicatePresent = false;
      for (const value of keywords) {
        if (uniqueKeywords.indexOf(value) === -1) {
          uniqueKeywords.push(value);
        } else {
          duplicatePresent = true;
        }
      }
      uniqueKeywords.forEach((keyword) => {
        const checkBoxSelectionsToLoopThrough = this.panelData.sourceCheckBox;

        checkBoxSelectionsToLoopThrough.forEach((matchType) => {
          if (keyword.trim().length > 0) {
            keywordNotPresent = true;
            for (const value of this.resultTableData) {
              if (
                value.keyword === keyword.trim() &&
                value.match_type === matchType
              ) {
                keywordNotPresent = false;
                keywordsExist = true;
              }
            }
            if (keywordNotPresent) {
              this.resultTableData.push({
                keyword: keyword.trim(),
                match_type: matchType
              });
            }
          }
        });
      });
      if (this.defaultBid !== null && this.defaultBid !== undefined) {
        this.setDefaultBid();
      }

      if (this.resultTableData.length >= 1000) {
        const remainingKeywords = this.resultTableData.length - 1000;
        this.resultTableData = this.resultTableData.slice(0, 1000);
        this.snackbar(
          'Unable to add the last ' +
            remainingKeywords +
            ' keywords (You may add upto 1000 ' +
            this.actionName +
            ')',
          6000
        );
      }
      if (keywordsExist && duplicatePresent) {
        this.snackbar(
          'Duplicate keyword(s) removed, keyword(s) & match-type(s) combination already exists.',
          6000
        );
      } else if (keywordsExist) {
        this.snackbar(
          'Keyword(s) & match-type(s) combination already exists.',
          6000
        );
      } else if (duplicatePresent) {
        this.snackbar('Duplicate keyword(s) removed.', 6000);
      }
      this.loopThroughResultTableDataAndValidate();
      this.keywordTextInput = '';
    },
    snackbar(message, timer) {
      this.$snackbar.open({
        message: message,
        duration: timer,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    removeAllKeywords() {
      this.resultTableData = [];
      this.tableConfigs.body.APIConfig.page = 1;
    },
    removeKeyword(context) {
      this.resultTableData = this.resultTableData.filter((item) => {
        if (JSON.stringify(item) !== JSON.stringify(context.params.data)) {
          return item;
        }
      });
    },
    loopThroughResultTableDataAndValidate() {
      for (const item of this.resultTableData) {
        if (item.newBid < 0.3) {
          this.addedKeywordsErrorMessage = 'Bid should be greater than $0.3';
          return;
        }

        if (item.newBid > 85) {
          this.addedKeywordsErrorMessage = 'Bid should be less than $85';
          return;
        }
      }
      this.addedKeywordsErrorMessage = '';
    },
    validateTableData(context) {
      context.params.data.newBid = context.bidValue;
      const currentItemIndex = this.resultTableData.findIndex(
        ({ keyword }) => keyword === context.params.data.keyword
      );
      this.resultTableData[currentItemIndex].newBid = parseFloat(
        context.bidValue
      );
      this.updateGroupKeywords(this.resultTableData);
      this.loopThroughResultTableDataAndValidate();
    }
  }
};
</script>

<style lang="css" scoped>
.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 0;
}
.appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
  height: 20px;
}
.rb-input {
  width: 80px;
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  border: 1px solid #e9eaeb;
  border-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 20px;
}

.u-spacing-m-negative-l {
  margin: -2.4rem;
}
</style>
