<template>
  <!-- to do -->
  <div
    class="u-spacing-pv-xxxl u-spacing-ph-xxl u-position-relative adgroupKeywordTargeting"
  >
    <loader
      v-show="rightButtonLoading"
      slot="loader"
      class="fill--parent"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
      :style="{ 'z-index': '105' }"
    />
    <section class="u-spacing-pt-m">
      <createCampaignHeader :step="step" />
      <div>
        <component
          :is="customActionPanelCustomState.component"
          v-if="
            customActionPanelCustomState &&
            (manualCampaignOrNot || retailer === 'walmart')
          "
          ref="targetingTable"
          class="add-keyword-campaign-creation"
          v-bind="customActionPanelCustomState.props"
          v-on="customActionPanelCustomState.events"
        >
          <div
            v-if="showMessage"
            slot="right-table-footer"
            class="edit_suggested_campaign_keyword_targeting u-font-size-5 u-color-grey-lighter u-font-weight-600 u-position-absolute"
          >
            <rb-icon
              class="u-color-grey-lighter rb-icon--small"
              icon="info-circle-fill"
            />
            <span
              data-cy="errorMessage"
              class="u-spacing-ml-xs"
            >
              {{ showMessage }}
            </span>
          </div>
        </component>

        <!-- Negative keywords comes here -->
        <div
          v-if="showNegativeKeywordsPanel"
          class="u-flex-align-items-center u-display-flex"
        >
          <CollapsibleTitle
            class="u-spacing-pt-xl u-spacing-pr-s u-spacing-pb-l"
            title="Negative Keyword Targeting"
            :is-expanded="negativeKeywordsExpand"
            title-font-class="u-font-size-5 u-font-weight-600"
            @click="() => (negativeKeywordsExpand = !negativeKeywordsExpand)"
          />
          <div class="u-spacing-pt-s">
            <div
              data-cy="optionalTxt"
              class="u-font-size-5 u-border-left u-font-weight-600 u-border-width-s u-border-color-grey-xxx-light u-color-grey-lighter u-spacing-pl-s u-spacing-pv-xxs"
            >
              Optional
            </div>
          </div>
        </div>

        <createCampaignHeader
          v-if="negativeKeywordsExpand && showNegativeKeywordsPanel"
          :step="step.negativeStep"
        />
        <div v-show="negativeKeywordsExpand && showNegativeKeywordsPanel">
          <component
            :is="customActionPanelNegativeKeywordTargetingCustomState.component"
            v-if="customActionPanelNegativeKeywordTargetingCustomState"
            ref="negativeTargetingTable"
            class="add-keyword-campaign-creation"
            v-bind="customActionPanelNegativeKeywordTargetingCustomState.props"
            v-on="customActionPanelNegativeKeywordTargetingCustomState.events"
          />
        </div>
      </div>

      <!-- CampaignTargetting -->
      <createCampaignFooter
        :disable-right-btn="disableRightButton"
        :right-button-loading="rightButtonLoading"
        class="u-spacing-pt-l"
        :btn-right-text="rightButtonText"
        :error-message="errorMessage"
        @onClickBtnRight="onClickBtnRight"
        @onClickBtnLeft="prevStep"
      />
    </section>
  </div>
</template>

<script>
import CollapsibleTitle from '@/components/pages/strategy_builder/steps/collapsible-title.vue';
import CustomActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import loader from '@/components/basic/loader.vue';
import { WalmartCampaignCreateDataService } from '@/components/ams/campaign_creation/walmart_steps/walmartCampaignCreateDataService';
import {
  getKeywordsToAdd,
  getKeywordsToDelete,
  getKeywordsToUpdate
} from '@/components/ams/campaign_creation/walmart_steps/campaignTargeting/util.js';
import {
  ErrorCodes,
  WalmartCreateCustomException
} from '@/components/ams/campaign_creation/walmart_steps/errorUtility';
export default {
  name: 'KeywordTargeting',
  components: {
    createCampaignHeader,
    createCampaignFooter,
    CustomActionPanel,
    loader,
    CollapsibleTitle
  },
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: function () {
    return {
      customActionPanelCustomState: null,
      negativeKeywordsExpand: false,
      customActionPanelNegativeKeywordTargetingCustomState: null,
      campaignId: null,
      adGroupId: null,
      advertiserId: null,
      rightButtonLoading: false,
      initialFetchLoading: false,
      walmartCampaignCreateDataService: {},
      errorMessage: '',
      prefilledAndSavedNegativeKeywords: false
    };
  },
  computed: {
    retailer() {
      return this.$store.getters?.getRetailer || 'walmart';
    },
    showNegativeKeywordsPanel() {
      return this.retailer === 'samsclub_api';
    },
    rightButtonText() {
      if (this.retailer === 'samsclub_api') {
        if (this.campaignEditFLow) {
          return 'Create Ad Group';
        }
        if (this.manualCampaignOrNot) {
          return 'Launch Campaign';
        } else return 'Next';
      }
      return this.step.btnRightText || 'Next';
    },
    campaignEditFLow() {
      return window.location.href
        .toLowerCase()
        .includes('create_ad_group'.toLowerCase());
    },
    manualCampaignOrNot() {
      return (
        this.allStepsData?.targetingType === 'manual' ||
        window.location.href.toLowerCase().includes('sp_manual'.toLowerCase())
      );
    },
    targetsLength() {
      if (this.allStepsData?.targets) {
        return this.allStepsData?.targets.length;
      } else return 0;
    },
    negativeTargetsLength() {
      if (this.allStepsData?.negativeTargets) {
        return this.allStepsData?.negativeTargets.length;
      } else return 0;
    },
    allStepsData() {
      return this.$store?.getters?.[this.selectedValues?.getter];
    },
    disableRightButton() {
      const commonCondition =
        Boolean(this.showMessage) ||
        this.allStepsData?.campaignNameAlreadyExists === true ||
        this.rightButtonLoading;
      if (this.retailer === 'samsclub_api') {
        return this.manualCampaignOrNot && commonCondition;
      }
      return commonCondition;
    },
    showMessage() {
      let returnValue = '';
      if (this.targetsLength === 0) {
        returnValue = this.step.noKeywordsErrorMessage;
      }
      if (this.targetsLength > 0) {
        let minBid = this.step?.keywordBidValidationObject?.min;
        let maxBid = this.step?.keywordBidValidationObject?.max;
        for (const item of this.allStepsData?.targets) {
          if (
            minBid &&
            maxBid &&
            (!item.newBid ||
              parseFloat(item.newBid) < minBid ||
              parseFloat(item.newBid) > maxBid)
          ) {
            returnValue = `Bid should be between $${minBid} and $${maxBid} both inclusive.`;
          } else if (
            !maxBid &&
            minBid &&
            (!item.newBid || parseFloat(item.newBid) < minBid)
          ) {
            returnValue = `Bid should be greater than $${minBid}`;
          }
        }
        const limit =
          this.allStepsData?.campaignType === 'sponsoredBrands' ? 200 : 1000;
        const keywords = this.allStepsData?.targets;
        const addedKeywords = keywords.map((item) => item?.keyword);
        const distinctKeywords = new Set();
        addedKeywords.forEach((keyword) => distinctKeywords.add(keyword));
        if (
          this.allStepsData?.campaignType === 'sponsoredBrands' &&
          distinctKeywords.size > limit
        ) {
          returnValue = `An Ad Group can have a maximum of ${limit} distinct keywords with 1-3 match types.`;
        } else if (
          this.allStepsData?.campaignType !== 'sponsoredBrands' &&
          keywords?.length > limit
        ) {
          returnValue = `An Ad Group can have a maximum of ${limit} keywords with 1-3 match types.`;
        }
      }

      return returnValue;
    }
  },
  watch: {
    negativeKeywordsExpand(expanded) {
      if (expanded) {
        setTimeout(() => {
          if (!this.prefilledAndSavedNegativeKeywords) {
            this?.$refs?.negativeTargetingTable?.triggerUpdateRightTableRows?.();
            this.prefilledAndSavedNegativeKeywords = true;
          }
        }, 0);
      }
    },
    targetsLength: {
      handler(newVal) {
        if (this.retailer === 'walmart') {
          this.step.description = `${newVal} targeting keyword${
            newVal !== 1 ? 's' : ''
          } added`;
        }
        if (this.retailer === 'samsclub_api') {
          this.updateDescription(newVal, this.negativeTargetsLength);
        }
      },
      deep: true,
      immediate: true
    },
    negativeTargetsLength: {
      handler(newVal) {
        if (this.retailer === 'samsclub_api') {
          this.updateDescription(this.targetsLength, newVal);
        }
      },
      deep: true,
      immediate: true
    }
  },
  async created() {
    this.initializeDataService();
    this.customActionPanelCustomState = this.step?.customActionPanelCustomState(
      this,
      this.adGroupId,
      this.allStepsData?.campaignType
    );
    if (this.retailer === 'samsclub_api') {
      this.customActionPanelNegativeKeywordTargetingCustomState =
        this.step?.customActionPanelNegativeKeywordTargetingCustomState(this);
    }
    await this.fetchSaveAndPreFillValues();
  },
  methods: {
    async checkAndUpdateForNegativeKeywordsSamsClubApi() {
      const [
        wasNegativeKeywordsUpdated,
        negativekeywordsToAdd,
        negativekeywordsToDelete
      ] = this.checkIfNegativeKeywordsWasUpdated();

      if (wasNegativeKeywordsUpdated && negativekeywordsToAdd.length > 0) {
        await this.addNegativeKeywords(negativekeywordsToAdd);
      }
      if (wasNegativeKeywordsUpdated && negativekeywordsToDelete.length > 0) {
        await this.deleteNegativeKeywords(negativekeywordsToDelete);
      }

      if (this.manualCampaignOrNot && !this.campaignEditFLow) {
        await this.launchCampaign();
        this.$router.push({ name: 'campaign_created' });
      } else {
        this.goToNextStep();
      }
    },
    keywordLengthCheck(keywordLength) {
      return keywordLength !== 1 ? 's' : '';
    },
    updateDescriptionForManualSamsclubApi(keywordLength) {
      return this.manualCampaignOrNot
        ? `${keywordLength} targeting keyword${this.keywordLengthCheck(
            keywordLength
          )} added`
        : '';
    },
    updateDescription(keywordLength, negativeKeywordLength) {
      this.step.description = `<span>${this.updateDescriptionForManualSamsclubApi(
        keywordLength
      )}</span> </br> <span>
       ${negativeKeywordLength} negative targeting keyword${
        negativeKeywordLength !== 1 ? 's' : ''
      } added</span>`;
    },
    async fetchSaveAndPreFillValues() {
      try {
        if (this.retailer === 'samsclub_api') {
          await this.fetchSaveAndPreFillValuesForSamsClubApi();
          return;
        }
        const fetchedKeywordsResponse = await this.fetchKeywordsDetails();
        const payload = {
          ...this.allStepsData,
          ...{
            fetchedKeywordsResponse:
              fetchedKeywordsResponse?.data?.response?.filter(
                (item) => item?.adGroupId === parseInt(this.adGroupId)
              ) || []
          }
        };
        this.$store.dispatch(this.selectedValues.action, payload);
        if (this.adGroupId) {
          this.preFillFieldsFromFetchedData();
        }
      } catch (err) {
        this.handleCaughtError(err);
        console.log(err);
      }
    },
    async fetchSaveAndPreFillValuesForSamsClubApi() {
      const fetchedNegativeKeywordsResponse =
        await this.fetchNegativeKeywordsDetails();
      if (this.manualCampaignOrNot) {
        const fetchedKeywordsResponse = await this.fetchKeywordsDetails();
        const payload = {
          ...this.allStepsData,
          ...{
            fetchedKeywordsResponse:
              fetchedKeywordsResponse?.data?.response?.filter(
                (item) => item?.adGroupId === parseInt(this.adGroupId)
              ) || [],
            fetchedNegativeKeywordsResponse:
              fetchedNegativeKeywordsResponse?.data?.response?.filter(
                (item) => item?.adGroupId === parseInt(this.adGroupId)
              ) || []
          }
        };
        this.$store.dispatch(this.selectedValues.action, payload);
      }
      const negativePayload = {
        ...this.allStepsData,
        ...{
          fetchedNegativeKeywordsResponse:
            fetchedNegativeKeywordsResponse?.data?.response?.filter(
              (item) => item?.adGroupId === parseInt(this.adGroupId)
            ) || []
        }
      };
      this.$store.dispatch(this.selectedValues.action, negativePayload);
      if (this.adGroupId) {
        this.preFillFieldsFromFetchedData();
      }
    },
    async fetchKeywordsDetails() {
      this.rightButtonLoading = true;
      try {
        const response =
          await this.walmartCampaignCreateDataService.fetchKeywordsDetails();
        if (response?.data?.status === 'INTERNAL_SERVER_ERROR') {
          throw new WalmartCreateCustomException(
            ErrorCodes.FETCHING_KEYWORDS_DETAILS_FAILED,
            response
          );
        }
        return response;
      } catch (err) {
        console.log(err);
        throw new WalmartCreateCustomException(
          ErrorCodes.FETCHING_KEYWORDS_DETAILS_FAILED,
          err
        );
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async fetchNegativeKeywordsDetails() {
      this.rightButtonLoading = true;
      try {
        const response =
          await this.walmartCampaignCreateDataService.fetchNegativeKeywordsDetails();
        if (response?.data?.status === 'INTERNAL_SERVER_ERROR') {
          throw new WalmartCreateCustomException(
            ErrorCodes.FETCHING_NEGATIVE_KEYWORDS_DETAILS_FAILED,
            response
          );
        }
        return response;
      } catch (err) {
        console.log(err);
        throw new WalmartCreateCustomException(
          ErrorCodes.FETCHING_NEGATIVE_KEYWORDS_DETAILS_FAILED,
          err
        );
      } finally {
        this.rightButtonLoading = false;
      }
    },
    preFillFieldsFromFetchedData() {
      // This triggers the updateRows() method in customActionPanel which has the
      // logic for updating the right table from the fetched data
      this?.$refs?.targetingTable?.triggerUpdateRightTableRows?.();
    },
    initializeDataService() {
      const {
        campaignId,
        adGroupId,
        advertiserId,
        campaignType: campaignTypeFromQuery
      } = this.$route.query;
      this.campaignId = parseInt(campaignId) || undefined;
      const retailer = this.$store.getters.getRetailer;
      this.adGroupId = parseInt(adGroupId) || undefined;
      this.advertiserId = parseInt(advertiserId) || undefined;
      const campaignType =
        this.allStepsData?.campaignType || campaignTypeFromQuery || undefined;
      const dataService =
        this.step?.dataService || WalmartCampaignCreateDataService;
      this.walmartCampaignCreateDataService = new dataService(
        campaignId,
        adGroupId,
        advertiserId,
        campaignType,
        retailer
      );
    },
    async addKeywords(keywords) {
      this.rightButtonLoading = true;
      const allStepsData = this.allStepsData;
      try {
        const addKeywordsResponse =
          await this.walmartCampaignCreateDataService.addKeywords(
            keywords,
            allStepsData?.adGroupName
          );
        if (addKeywordsResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.ADD_KEYWORDS_FAILED,
            addKeywordsResponse
          );
        }
        return addKeywordsResponse;
      } catch (err) {
        if (err.message === ErrorCodes.ADD_KEYWORDS_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async updateKeywords(keywords) {
      this.rightButtonLoading = true;
      const allStepsData = this.allStepsData;
      try {
        const updateKeywordsResponse =
          await this.walmartCampaignCreateDataService.updateOrDeleteKeywords(
            keywords,
            allStepsData?.adGroupName
          );
        if (updateKeywordsResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.UPDATE_KEYWORDS_FAILED,
            updateKeywordsResponse
          );
        }
        return updateKeywordsResponse;
      } catch (err) {
        console.log(err);
        if (err.message === ErrorCodes.UPDATE_KEYWORDS_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async deleteKeywords(keywords) {
      this.rightButtonLoading = true;
      const allStepsData = this.allStepsData;
      try {
        const deleteKeywordsResponse =
          await this.walmartCampaignCreateDataService.updateOrDeleteKeywords(
            keywords,
            allStepsData?.adGroupName,
            true
          );
        if (deleteKeywordsResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.DELETE_KEYWORDS_FAILED,
            deleteKeywordsResponse
          );
        }
        return deleteKeywordsResponse;
      } catch (err) {
        console.log(err);
        if (err.message === ErrorCodes.DELETE_KEYWORDS_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    checkIfKeywordsWasUpdated() {
      const allStepsData = this.allStepsData;
      const tableKeywords = allStepsData?.targets;
      const fetchedKeywords = allStepsData?.fetchedKeywordsResponse;
      const keywordsToAdd = getKeywordsToAdd(tableKeywords, fetchedKeywords);
      const keywordsToUpdate = getKeywordsToUpdate(
        tableKeywords,
        fetchedKeywords
      );
      const keywordsToDelete = getKeywordsToDelete(
        tableKeywords,
        fetchedKeywords
      );
      const wasKeywordsUpdated =
        keywordsToAdd.length > 0 ||
        keywordsToUpdate.length > 0 ||
        keywordsToDelete.length > 0 ||
        false;
      return [
        wasKeywordsUpdated,
        keywordsToAdd,
        keywordsToUpdate,
        keywordsToDelete
      ];
    },
    async addNegativeKeywords(negativeKeywords) {
      this.rightButtonLoading = true;
      const allStepsData = this.allStepsData;
      try {
        const addNegativeKeywordsResponse =
          await this.walmartCampaignCreateDataService.addNegativeKeywords(
            negativeKeywords,
            allStepsData?.adGroupName
          );
        if (addNegativeKeywordsResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.ADD_NEGATIVE_KEYWORDS_FAILED,
            addNegativeKeywordsResponse
          );
        }
        return addNegativeKeywordsResponse;
      } catch (err) {
        if (err.message === ErrorCodes.ADD_NEGATIVE_KEYWORDS_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    async deleteNegativeKeywords(negativeKeywords) {
      this.rightButtonLoading = true;
      const allStepsData = this.allStepsData;
      try {
        const deleteNegativeKeywordsResponse =
          await this.walmartCampaignCreateDataService.updateOrDeleteNegativeKeywords(
            negativeKeywords,
            allStepsData?.adGroupName,
            true
          );
        if (deleteNegativeKeywordsResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.DELETE_KEYWORDS_FAILED,
            deleteNegativeKeywordsResponse
          );
        }
        return deleteNegativeKeywordsResponse;
      } catch (err) {
        console.log(err);
        if (err.message === ErrorCodes.DELETE_NEGATIVE_KEYWORDS_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    checkIfNegativeKeywordsWasUpdated() {
      const allStepsData = this.allStepsData;
      const tableNegativeKeywords = allStepsData?.negativeTargets;
      const fetchedNegativeKeywords =
        allStepsData?.fetchedNegativeKeywordsResponse;
      const negativekeywordsToAdd = getKeywordsToAdd(
        tableNegativeKeywords,
        fetchedNegativeKeywords
      );
      const negativekeywordsToDelete = getKeywordsToDelete(
        tableNegativeKeywords,
        fetchedNegativeKeywords
      );
      const wasNegativeKeywordsUpdated =
        negativekeywordsToAdd.length > 0 ||
        negativekeywordsToDelete.length > 0 ||
        false;
      return [
        wasNegativeKeywordsUpdated,
        negativekeywordsToAdd,
        negativekeywordsToDelete
      ];
    },
    async onClickBtnRight() {
      try {
        if (this.step?.isCreateAdGroup) {
          const [wasAdGroupUpdated, adGroupUpdateObject] =
            this.checkIfAdGroupWasUpdated();
          if (wasAdGroupUpdated) {
            await this.updateAdGroupDetails(adGroupUpdateObject);
          }
        }
        const [
          wasKeywordsUpdated,
          keywordsToAdd,
          keywordsToUpdate,
          keywordsToDelete
        ] = this.checkIfKeywordsWasUpdated();

        if (wasKeywordsUpdated && keywordsToAdd.length > 0) {
          await this.addKeywords(keywordsToAdd);
        }
        if (wasKeywordsUpdated && keywordsToUpdate.length > 0) {
          await this.updateKeywords(keywordsToUpdate);
        }
        if (wasKeywordsUpdated && keywordsToDelete.length > 0) {
          await this.deleteKeywords(keywordsToDelete);
        }

        if (this.retailer === 'samsclub_api') {
          await this.checkAndUpdateForNegativeKeywordsSamsClubApi();
          return;
        }
        this.goToNextStep();
      } catch (err) {
        console.log(err);
        this.handleCaughtError(err);
      } finally {
        await this.fetchSaveAndPreFillValues();
      }
    },
    async launchCampaign() {
      this.rightButtonLoading = true;
      try {
        const launchCampaignResponse =
          await this.walmartCampaignCreateDataService.launchCampaign();
        if (launchCampaignResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.LAUNCH_CAMPAIGN_FAILED,
            launchCampaignResponse
          );
        }
      } catch (err) {
        if (err.message === ErrorCodes.LAUNCH_CAMPAIGN_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    },
    handleCaughtError(err) {
      if (err.message === ErrorCodes.SOMETHING_WENT_WRONG) {
        this.errorMessage =
          'Something went wrong. Please try again after some time.';
      }
      if (err.message === ErrorCodes.FETCHING_KEYWORDS_DETAILS_FAILED) {
        this.errorMessage =
          'Something went wrong while fetching keywords details. Please close the page and try again after some time.';
      }
      if (
        err.message === ErrorCodes.FETCHING_NEGATIVE_KEYWORDS_DETAILS_FAILED
      ) {
        this.errorMessage =
          'Something went wrong while fetching negative keywords details. Please close the page and try again after some time.';
      }
      if (err.message === ErrorCodes.ADD_NEGATIVE_KEYWORDS_FAILED) {
        this.errorMessage =
          'Something went wrong while adding a negative keyword. Please try again after some time.';
      }
      if (err.message === ErrorCodes.DELETE_NEGATIVE_KEYWORDS_FAILED) {
        this.errorMessage =
          'Something went wrong while deleting a negative keyword. Please try again after some time.';
      }
      if (err.message === ErrorCodes.ADD_KEYWORDS_FAILED) {
        this.errorMessage =
          'Something went wrong while adding a keyword. Please try again after some time.';
      }
      if (err.message === ErrorCodes.UPDATE_KEYWORDS_FAILED) {
        this.errorMessage =
          'Something went wrong while updating a keyword. Please try again after some time.';
      }
      if (err.message === ErrorCodes.DELETE_KEYWORDS_FAILED) {
        this.errorMessage =
          'Something went wrong while deleting a keyword. Please try again after some time.';
      }
      if (err.message === ErrorCodes.UPDATE_AD_GROUP_FAILED) {
        this.errorMessage =
          'Something went wrong while updating the ad group. Please try again after some time.';
      }
    },
    goToNextStep() {
      if (this.step?.isCreateAdGroup) {
        return this.step?.redirectTo?.(this);
      }
      this.$emit('nextStep', {});
    },
    prevStep() {
      this.$emit('prevStep', {});
    },
    checkIfAdGroupWasUpdated() {
      const allStepsData = this.allStepsData;
      const fetchedAdGroupResponse = allStepsData?.fetchedAdGroupResponse;
      let wasAdGroupUpdated = false;
      const adGroupUpdateObject = {};
      if (fetchedAdGroupResponse.name !== allStepsData?.adGroupName) {
        adGroupUpdateObject.adGroupName = allStepsData?.adGroupName;
        wasAdGroupUpdated = true;
      }
      return [wasAdGroupUpdated, adGroupUpdateObject];
    },
    setAdGroupName(newVal) {
      let allStepsData = null;
      if (this.allStepsData?.adGroupName === undefined) {
        // If adGroupName is not present in all Steps data
        allStepsData = { ...this.allStepsData };
        this.adGroupName = newVal;
        allStepsData.adGroupName = this.adGroupName;
      } else if (this.allStepsData?.adGroupName && newVal === undefined) {
        this.adGroupName = this.allStepsData?.adGroupName;
        allStepsData = { ...this.allStepsData };
      } else {
        this.adGroupName = newVal;
        allStepsData = { ...this.allStepsData };
        allStepsData.adGroupName = this.adGroupName;
      }
      this.$store.dispatch(this.selectedValues.action, allStepsData);
    },
    async updateAdGroupDetails(adGroupUpdateObject) {
      this.rightButtonLoading = true;
      const changeToExistingAdGroupName = () => {
        this.setAdGroupName(
          this.allStepsData?.fetchedAdGroupResponse?.name || null
        );
        // Logic todo
      };
      try {
        const updateAdGroupDetailsResponse =
          await this.walmartCampaignCreateDataService.updateAdGroupDetails(
            adGroupUpdateObject
          );
        if (updateAdGroupDetailsResponse.data?.failure > 0) {
          throw new WalmartCreateCustomException(
            ErrorCodes.UPDATE_AD_GROUP_FAILED,
            updateAdGroupDetailsResponse
          );
        }
      } catch (err) {
        changeToExistingAdGroupName();
        if (err.message === ErrorCodes.UPDATE_AD_GROUP_FAILED) {
          throw err;
        }
        throw new WalmartCreateCustomException(ErrorCodes.SOMETHING_WENT_WRONG);
      } finally {
        this.rightButtonLoading = false;
      }
    }
  }
};
</script>

<style lang="css">
.add-keyword-campaign-creation .table-container-entity-asin-tagging {
  padding-right: 0.2rem;
  padding-left: 0.2rem;
}
</style>

<style scoped>
.edit_suggested_campaign_keyword_targeting {
  bottom: -20px;
}

.rb-icon--small:before {
  font-size: 14px;
}
</style>
