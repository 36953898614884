import tableDictionary from '@/pages/toolsAndSettings/entityTagging/common/createCustomEntity/dictionary.js';
import { formatter } from '@/utils/helpers/formatter.js';
import cellRenderer from '@/components/globals/dataTable/tableCellComponent.js';
import { Entities } from '@/pages/toolsAndSettings/const.js';
import campaign from '@/pages/toolsAndSettings/campaign-taxonomy.js';
import { cloneDeep } from 'lodash';

function addOptionalColumns({ retailer, dictionary, pageFilterKey, table }) {
  if (campaign?.config?.readOnlyColumns?.[retailer]) {
    campaign.config.readOnlyColumns[retailer].forEach((column, index) => {
      dictionary[table][column.altKey] = {
        title: column.value,
        toolTipText: column.value,
        type: 'String',
        keyOrder: index + 1,
        page: [Entities?.[pageFilterKey] || 'sku'],
        showOnUi: true,
        downloadable: true,
        maxWidth: 50,
        pinned: 'left'
      };
    });
  }
}

function addSKUConfigs({ dictionary, table, i, obj }) {
  if (dictionary[table][i].type === 'sku') {
    delete obj.cellRenderer;
    obj.cellRendererFramework = 'skuTableComponentWithButton';
    obj.cellRendererParams.showButton = false;
    obj.cellRendererParams.keys = {
      title: 'asin_name',
      imageUrl: 'latest_image_url',
      asin: 'asin',
      productUrl: 'asin_hyperlink'
    };
    obj.width = 440;
  }
}

function addIconConfigs({ dictionary, table, i, obj, label, vueRef }) {
  if (dictionary[table][i].type === 'icon') {
    delete obj.cellRenderer;
    obj.headerComponentParams.enableSorting = false;
    obj.cellRendererFramework = 'iconTableCell';
    obj.cellRendererParams.showToolTip = true;
    obj.cellRendererParams.toolTipText =
      dictionary[table][i].icon === 'plus' ? `Add ${label}` : `Remove ${label}`;
    obj.cellRendererParams.displayIcon = dictionary[table][i].icon;
    obj.cellRendererParams.alternateIcon = dictionary[table][i]?.alternateIcon;
    obj.cellRendererParams.onHoverClass = 'iconColorBlueBase';
    obj.cellRendererParams.iconSize = 'small';
    obj.cellRendererParams.iconClickEvent =
      vueRef[dictionary[table][i].iconClickEvent];
    obj.headerComponentParams.clickHeader =
      vueRef[dictionary[table][i].clickHeader];
    obj.headerComponentParams.customStyles = {
      color: '#007cf6',
      display: 'flex',
      width: '100%',
      'justify-content': 'center'
    };
    if (dictionary[table][i]?.toolTipText) {
      obj.headerComponentParams.headerIcon = {
        icon: 'info-circle-fill',
        toolTip: dictionary[table][i]?.toolTipText
      };
    }
    obj.width = 120;
  }
}

function addAdditionalConfigs({
  dictionary,
  table,
  i,
  keyType,
  columnDefs,
  obj
}) {
  if (dictionary[table][i].toolTipText) {
    obj.headerComponentParams.toolTipText = dictionary[table][i].toolTipText;
  }
  obj.headerComponentParams.keyType = keyType;
  if (dictionary[table][i].showOnUi) {
    columnDefs.push(obj);
  }
}

function getColumnDefinition(vueRef, pageFilterKey, table) {
  const dictionary = cloneDeep(tableDictionary);
  const retailer = vueRef.$store.getters.getRetailer;
  const label = pageFilterKey === 'sku' ? 'SKU' : pageFilterKey;
  if (pageFilterKey !== 'sku') {
    addOptionalColumns({ retailer, dictionary, pageFilterKey, table });
  }
  let tempColumnDefs = [];
  for (const i in dictionary[table]) {
    if (dictionary[table][i].page.includes(pageFilterKey)) {
      const obj = {};
      obj.headerComponentParams = {};
      obj.field = i;
      obj.title = dictionary[table][i].title;
      obj.suppressSizeToFit = false;
      obj.resizable = true;
      obj.minWidth = 50;
      obj.headerComponentFramework = 'genericTableHeader';
      const keyType = 'string';
      obj.cellRendererParams = {
        keyType: keyType,
        formatterFn: formatter
      };
      obj.enableSorting = true;
      obj.cellRenderer = cellRenderer;
      obj.headerComponentParams.enableSorting = true;
      addSKUConfigs({ dictionary, table, i, obj });
      obj.headerName = dictionary[table][i].title;
      addIconConfigs({ dictionary, table, i, obj, label, vueRef });
      obj.keyOrder = dictionary[table][i].keyOrder;
      dictionary[table][i].pinned && (obj.pinned = dictionary[table][i].pinned);
      addAdditionalConfigs({
        dictionary,
        table,
        i,
        keyType,
        columnDefs: tempColumnDefs,
        obj
      });
    }
  }
  return tempColumnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });
}

export default {
  [Entities.catalog]: {
    config: (that) => {
      return {
        primaryKey: 'asin',
        updateKeyMapper: {
          value: 'asin',
          type: 'sku',
          description: '',
          image: '',
          tagType: 'custom',
          tagCustomType: 'Custom'
        },
        default: {
          headerTitle: 'Untitled',
          headerTitlePlaceholder: '',
          headerDescription: '',
          headerDescriptionPlaceholder: '',
          pageTitle: 'Choose SKUs that should be associated with the Brand XYZ',
          pageDescription:
            'You may also narrow your search by adding filters or conditions.'
        },
        redirectToParentRoute: {
          path: '/tools_and_settings/catalog',
          query: {
            type: 'standard'
          }
        },
        left: {
          title: 'SKUs',
          searchKey: 'asin',
          meta: {
            type: 'table',
            key: 'skus_entities_source_table',
            action: 'fetchEntitiesExecuteApi',
            filters: {
              emit: 'fetchAllEntitiesFiltersTriggered',
              hasSearch: false
            },
            filterState: {
              getter: 'getExecuteApiSelectedFitlers',
              setter: 'fetchEntitiesExecuteApiFilters'
            },
            localFilters: [],
            paginationAction: 'fetchEntitiesExecuteApi'
          },
          footer: {
            show: false
          },
          body: {
            APIConfig: {
              cubeName: 'client_workbench_full_catalogue_latest_comp_data',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              measuresList: ['asin_name', 'scrape_date', 'latest_image_url'],
              groupByDimensionsList: ['asin', 'asin_hyperlink', 'cache_link'],
              orderByList: [
                {
                  dimension: 'asin',
                  direction: 'DESC'
                }
              ],
              limit: 20,
              page: 1
            },
            gridOptions: {
              context: {
                componentParent: that
              },
              domLayout: 'normal'
            },
            columnDefs: getColumnDefinition(that, 'sku', 'left')
          }
        },
        right: {
          title: 'SKUs with Brand XYZ',
          meta: {
            type: 'table',
            key: 'add_skus_to_tag',
            action: 'fetchSkusForCatalogValues',
            listen: {}
          },
          footer: {
            show: false
          },
          body: {
            APIConfig: {
              cubeName: 'client_workbench_full_catalogue_latest_comp_data',
              getLatestAvailableInsteadOfRollup: false,
              timeseriesEnabled: false,
              pvpenabled: false,
              yoyenabled: false,
              measuresList: ['asin_name', 'scrape_date', 'latest_image_url'],
              groupByDimensionsList: ['asin', 'asin_hyperlink', 'cache_link'],
              orderByList: [
                {
                  dimension: 'asin',
                  direction: 'DESC'
                }
              ],
              limit: 20,
              page: 1
            },
            gridOptions: {
              suppressColumnVirtualisation: true,
              context: {
                componentParent: this
              },
              defaultColDef: {
                resizable: false
              },
              domLayout: 'normal'
            },
            columnDefs: getColumnDefinition(that, 'sku', 'right')
          }
        }
      };
    }
  },
  [Entities.campaign]: {
    config: (that) => {
      const retailer = that.$store.getters.getRetailer;
      return {
        primaryKey: 'entity_id',
        updateKeyMapper: {
          value: 'entity_id',
          type: 'campaign',
          description: '',
          image: '',
          tagType: 'custom',
          tagCustomType: 'Custom'
        },
        default: {
          headerTitle: 'Untitled',
          headerTitlePlaceholder: '',
          headerDescription: '',
          headerDescriptionPlaceholder: '',
          pageTitle:
            'Choose campaigns that should be associated with the Brand XYZ',
          pageDescription:
            'You may also narrow your search by adding filters or conditions.'
        },
        redirectToParentRoute: {
          path: `/tools_and_settings/${Entities.campaign}`
        },
        left: {
          title: 'Campaigns',
          searchKey: 'search',
          class: ['u-flex-1'],
          table: 'left',
          customUrl: 'ENTITY_READ',
          responseTransformer: (response) =>
            response.data?.entityData?.response[0]['entityDataAndValuesList'],
          meta: {
            type: 'table',
            key: 'campaigns_entities_source_table',
            action: 'fetchTableDataExecuteApi',
            filters: {
              emit: 'fetchAllEntitiesFiltersTriggered',
              hasSearch: false
            },
            filterState: {
              getter: 'getExecuteApiSelectedFitlers',
              setter: 'fetchEntitiesExecuteApiFilters'
            },
            localFilters: [],
            paginationAction: 'fetchTableDataExecuteApi'
          },
          footer: {
            show: false
          },
          body: {
            APIConfig: {
              page: 1,
              limit: 20000,
              ...campaign.retailerSpecificEntityCubeDetails(retailer),
              entity: Entities.campaign,
              cubeName: campaign.editableTableConfig[retailer].filterCubeName
            },
            gridOptions: {
              context: {
                componentParent: that
              },
              domLayout: 'normal'
            },
            columnDefs: getColumnDefinition(that, 'campaign', 'left')
          }
        },
        right: {
          table: 'right',
          customUrl: 'ENTITY_READ',
          customPayload: true,
          title: 'Campaigns with Brand XYZ',
          class: ['u-flex-1'],
          responseTransformer: (response) =>
            response.data?.entityData?.response[0]['entityDataAndValuesList'],
          meta: {
            type: 'table',
            key: 'add_skus_to_tag',
            action: 'fetchTableDataExecuteApi',
            listen: {}
          },
          footer: {
            show: false
          },
          body: {
            APIConfig: {
              ...campaign.retailerSpecificEntityCubeDetails(retailer),
              entity: Entities.campaign,
              page: 1,
              limit: 20000,
              cubeName: campaign.editableTableConfig[retailer].filterCubeName
            },
            gridOptions: {
              suppressColumnVirtualisation: true,
              context: {
                componentParent: this
              },
              defaultColDef: {
                resizable: false
              },
              domLayout: 'normal'
            },
            columnDefs: getColumnDefinition(that, 'campaign', 'right')
          }
        }
      };
    }
  }
};
