<template>
  <div
    :key="panelKey"
    class="rightPanel"
    :class="panelState"
  >
    <div class="header u-spacing-mb-l u-position-sticky">
      <div
        class="u-bg-color-grey-white u-spacing-ph-m u-spacing-pt-xxl u-spacing-pb-l u-width-100 u-border-bottom u-border-width-s u-border-color-grey-xxx-light u-display-flex u-flex-justify-content-space-between"
      >
        <span class="u-font-size-5 u-color-grey-base u-font-weight-600">{{
          title
        }}</span>
        <span
          class="u-cursor-pointer"
          @click="closePanel"
        >
          <rb-icon
            icon="cross"
            class="rb-icon--small u-color-grey-base"
          />
        </span>
      </div>
    </div>
    <slot name="body" />
  </div>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';

export default {
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    listener: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      panelState: 'close',
      panelKey: 0
    };
  },
  created() {
    eventBus.$on(this.listener, (data) => {
      this.panelKey += 1;
      this.panelState = data;
    });
  },
  methods: {
    closePanel() {
      this.panelState = 'close';
      this.panelKey += 1;
      this.$emit('panelClose');
    }
  }
};
</script>

<style lang="css">
.rightPanel {
  z-index: 105;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  height: 100%;
  background: #fff;
  display: flex;
  width: 0px;
  box-shadow: -2px 0 8px 0 rgba(43, 51, 59, 0.25);
  transition: width 0s;
  flex-direction: column;
  overflow-y: auto;
}
.rightPanel .header {
  width: 100%;
  height: 46px;
  top: 0;
  z-index: 999;
  border-bottom: solid 1px #e9eaeb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rightPanel .body {
  flex-grow: 1;
  overflow: auto;
}

.rightPanel.close {
  width: 0%;
}
.rightPanel.close .body {
  display: none;
}
.rightPanel.open {
  width: 25%;
}
.rightPanel.open .body {
  display: block;
}
</style>
