<template>
  <div class="dashboard-service-table-wrapper">
    <manageColumns
      v-if="enableManageColumns"
      :key="manageColumnsKey"
      :selected-columns="selectedColumns"
      :listener="manageColumnsPanelListener"
      :unselected-columns="unSelectedColumns"
      :detailed-metrics="manageColumnsMasterMetrics"
      :non-negotiable-columns="nonNegotiableColumns"
      @manageColumnsApply="manageColumnsApply"
    />
    <widgetContainer
      :header-options="headerOptionsComp"
      :footer-options="footerOptionsComp"
      :context="context"
      :last-updated-date="lastUpdatedDate"
      :title="(metadata || {}).label"
      :description="(metadata || {}).description"
      :is-loading="isLoaded || load"
      :header-container-grid-styles="headerContainerGridStyles"
      :data-cy="`widgetContainer - ${(metadata || {}).label}`"
    >
      <div
        slot="body"
        class=""
      >
        <rb-insights-table
          :class="tableClass"
          :custom-height="false"
          :config="tableConfig"
          :grid-options="gridOptions"
          :table-row="tableRows"
          :table-column="enableManageColumns ? tableColumns : columnDefs"
          :row-height="rowHeight"
          :row-selection="rowSelection"
          :header-height="headerHeightData"
          :enable-client-side-sorting="false"
          :enable-server-side-sorting="true"
          :suppress-column-virtualisation="true"
          :sorting-change-event="sortChangeEvent"
          :show-pagination-options="true"
          :pagination="enablePagination"
          :pagination-total-key="totalEntityCount"
          :pagination-per-page-limit="tableConfig.body.APIConfig.limit"
          :download-this-table="true"
          :no-rows-message="'No Data.'"
          :is-row-selectable="isRowSelectable"
        />
      </div>
    </widgetContainer>
  </div>
</template>

<script>
import Vue from 'vue';
import badgeWithLabel from '@/components/globals/dataTable/tableComponentsWrapper/badgeWithLabel.vue';
import iconWithText from '@/components/globals/dataTable/tableComponentsWrapper/iconWithText.vue';
import textWithLink from '@/components/basic/textWithLink.vue';
import { eventBus } from '@/utils/services/eventBus';
import Loader from '@/components/basic/loader.vue';
import DashboardDataService from '@/utils/common/dashboardDataService.js';
import DashboardPivotedDataService from '@/utils/common/dashboardPivotedTableDataService.js';
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import moment from 'moment-timezone';
import skuWithRating from '@//components/basic/skuWithRating.vue';
import widgetsUtil from '@/components/widgetMixin';
import statusTextWithIconAndBg from '@/components/globals/dataTable/tableComponentsWrapper/statusTextWithIconAndBg.vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import manageColumnMixin from '@/components/widgets/custom_widgets/manage_column_mixin.js';
import dataCell from '@/components/widgets/tableComponents/cells/dataCell.vue';
import dataHeader from '@/components/globals/dataTable/tableHeaderComponents/dataHeader.vue';
import timezoneConversion from '@/components/globals/dataTable/tableComponentsWrapper/timezoneConversion.vue';
import manageColumns from '@/components/widgets/manageColumnsSidebar.vue';
import { isEqual } from 'lodash';
export default {
  components: {
    Loader,
    widgetContainer,
    manageColumns
  },
  mixins: [widgetsUtil, cwMixin, manageColumnMixin],
  props: {
    headerHeight: {
      type: Number,
      default: 52
    },
    tableClass: {
      type: String,
      default:
        'u-display-flex u-flex-direction-column u-height-100 dashboard-service-table'
    },
    gridOptions: {
      type: Object,
      default() {
        return {
          domLayout: 'normal'
        };
      }
    },
    rowHeight: {
      type: Number,
      default: 80
    },
    rowSelection: {
      type: Object,
      default() {
        return {};
      }
    },
    context: {
      type: Object,
      default: () => {
        return {};
      }
    },
    columnKeyMapping: {
      type: Object,
      default: null
    },
    enablePagination: {
      type: Boolean,
      default: true
    },
    isRowSelectable: {
      type: Function,
      default: null
    },
    bypassFilters: {
      type: Array,
      default: () => {
        return [];
      }
    },
    widgetDataService: {
      type: [Object, Function],
      default() {
        return DashboardDataService;
      }
    },
    watchForMetadataChanges: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      config: {},
      isLoaded: true,
      columnDefs: [],
      tableRows: [],
      headerHeightData: {},
      sortChangeEvent: 'sort-change',
      tableConfig: {
        body: {
          APIConfig: {
            page: 1,
            limit: 10
          }
        }
      },
      operations: {
        page: 1,
        limit: 10
      },
      dataService: {},
      totalEntityCount: 0,
      where: {
        dimensionNameValueList: [],
        date: {
          from: '2021-08-30',
          to: '2021-10-11'
        }
      },
      /**
       * below variable needs to be in sync with getMeasuresListArrayForEmail
       * in commonUtils file to support manage columns in email download
       */
      manageColumnsPanelListener: `${this.metadata.widgetId}_${this.metadata.name}ManageColumnsPanel`
    };
  },
  computed: {
    load() {
      return this?.dataService?.load;
    },
    error() {
      return this?.dataService?.error;
    },
    noData() {
      return this?.dataService?.noData;
    },
    getData() {
      return this.$store.getters['inventoryAvailability/getData'];
    },
    getMaxDateFormatted() {
      // const { max_feed_date } = this.$store.getters.getMaxDate.inventory_availability;
      // return moment(max_feed_date).format('MMM DD, YYYY');
      return moment(new Date()).format('MMM DD, YYYY');
    }
  },

  watch: {
    headerHeight(newVal) {
      this.headerHeightData = newVal;
    },
    widgetRequestParams(newVal, oldVal) {
      if (isEqual(newVal, oldVal)) {
        console.log('refresh skipped since widgetRequestParams are equal ');
        return;
      }
      this.tableConfig.body.APIConfig.page = 1;
      Vue.set(this.operations, 'page', 1);
      this.getTableData();
    },
    metadata(newVal, oldVal) {
      if (!this.watchForMetadataChanges) {
        return;
      }
      if (isEqual(newVal, oldVal)) {
        return;
      }
      this.dataService.updateMetadata(newVal);
      this.getTableColumns();
    },
    columnKeyMapping(nv, ov) {
      if (!isEqual(nv, ov)) {
        this.getTableColumns();
      }
    }
  },
  destroyed() {
    eventBus.$off(this.tableConfig.meta.paginationChange);
    eventBus.$off(this.sortChangeEvent);
  },
  created() {
    Vue.component('badgeWithLabel', badgeWithLabel);
    Vue.component('timezoneConversion', timezoneConversion);
    Vue.component('iconWithText', iconWithText);
    Vue.component('textWithLink', textWithLink);
    Vue.component('skuWithRating', skuWithRating);
    Vue.component('statusTextWithIconAndBg', statusTextWithIconAndBg);
    Vue.component('dataCell', dataCell);
    Vue.component('dataHeader', dataHeader);
    this.headerHeightData = this.headerHeight;
    Vue.set(this.tableConfig, 'meta', {
      paginationChange: this.widgetName.concat('-pagination')
    });
    // to be improved/ made more configurable for data service plug and play.
    if (this.metadata?.metadata?.pivot) {
      this.dataService = new DashboardPivotedDataService(
        this.metadata,
        this.widgetRequestParams[':widget'],
        this.widgetRequestParams.page,
        this.widgetRequestParams.pageId,
        this.metadata.metadata.pivot
      );
    } else {
      this.dataService = new this.widgetDataService(
        this.metadata,
        this.widgetRequestParams[':widget'],
        this.widgetRequestParams.page,
        this.widgetRequestParams.pageId
      );
    }
    this.headerHeightData =
      this.metadata?.metadata?.tableConfig?.columnHeader?.height ??
      this.headerHeightData;
    this.sortChangeEvent = this.widgetName.concat('-sort');
    const defaultOperations = this.metadata?.metadata?.defaultOperations;
    if (defaultOperations) {
      this.operations = { ...this.operations, ...defaultOperations };
      this.tableConfig.body.APIConfig.limit = defaultOperations.limit ?? 10;
    }
    this.hoistWidgetParamsToParent(this.operations);
    this.getTableColumns();
    this.getTableData();
    eventBus.$on(this.tableConfig.meta.paginationChange, (data) => {
      this.tableConfig.body.APIConfig = data.body.APIConfig;
      this.operations.page = data.body.APIConfig.page;
      this.operations.limit = data.body.APIConfig.limit;
      this.getTableData();
    });
    eventBus.$on(this.sortChangeEvent, (data) => {
      const operation = [
        { dimension: data.colDef.field, direction: data.sort.toUpperCase() }
      ];
      this.tableConfig.body.APIConfig.page = 1;
      Vue.set(this.operations, 'page', 1);
      Vue.set(this.operations, 'orderByList', operation);
      this.hoistWidgetParamsToParent(this.operations);
    });
  },
  methods: {
    hoistWidgetParamsToParent(operations) {
      const orderByList =
        operations?.orderByList ||
        this.widgetRequestParams?.[':orderByList'] ||
        [];
      const widgetRequestParams = this.getCurrentWidgetRequestParams();
      widgetRequestParams[':orderByList'] = orderByList;
      this.updateWidgetRequestParams(widgetRequestParams, this.widgetName);
    },
    async getTableData() {
      try {
        // added null check for pivot
        if (this.metadata?.metadata?.pivot) {
          // for pivot tables columns should also be refreshed when refreshing the data.
          this.getTableColumns();
        }
        this.isLoaded = true;
        const widgetRequestParams = this.widgetRequestParams;
        if (this.bypassFilters.length) {
          let updatedDimensionValueList =
            widgetRequestParams[':dimensionNameValueList'];
          updatedDimensionValueList = widgetRequestParams[
            ':dimensionNameValueList'
          ]?.filter((item) => !this.bypassFilters.includes(item.dimensionName));
          widgetRequestParams[':dimensionNameValueList'] =
            updatedDimensionValueList;
        }
        const formattedRes = await this.dataService.getTableRows(
          this.operations,
          widgetRequestParams
        );
        this.tableRows = formattedRes.rows;
        this.totalEntityCount = formattedRes.totalRowsCount;
      } catch (error) {
        console.error(error);
        this.tableRows = [];
        this.$snackbar.open({
          message: 'Something Went Wrong',
          duration: 6000,
          actionText: ''
        });
      }
      this.isLoaded = false;
    },
    async getTableColumns() {
      try {
        this.columnDefs = await this.dataService.getTableColumns(
          this.operations,
          this.widgetRequestParams,
          this.columnKeyMapping
        );
      } catch (error) {
        console.error(error);
        this.$snackbar.open({
          message: 'Something Went Wrong',
          duration: 6000,
          actionText: ''
        });
      }
    },
    getAllTableColumns() {
      return this.dataService.getTableColumns(
        this.operations,
        this.widgetRequestParams,
        this.columnKeyMapping
      );
    }
  }
};
</script>

<style lang="css">
.table-loader {
  z-index: 10;
}

.dashboard-service-table-wrapper .header-container-cwc {
  padding: 2.4rem;
}

.dashboard-service-table-wrapper .rb-insights-table-container {
  height: 65vh;
}

.dashboard-service-table-wrapper .custom_widget_container,
.dashboard-service-table-wrapper .custom_widget_body {
  padding-left: 0;
  padding-right: 0;
}

.dashboard-service-table-wrapper .icon-download:hover {
  color: #2b333b;
}
</style>
