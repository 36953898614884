<template>
  <div class="listings_health">
    <dashboard-inflator
      ref="dashboard_inflator"
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :filter="filterSettings"
      :min-max-date-range="minMaxDateRange"
      :lazy-loading="false"
      :widget-level-settings="headerOptions"
      :dashboard-help-overview="dashboardHelpOverview"
      :show-active-filter-banner="false"
      @requestParamsChange="requestParamsChange"
      @metadataUpdated="metadataUpdated"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import lowInventory from '@/components/pages/listingsHealth/LowInventory.vue';
import AutomationTypes from '@/components/pages/listingsHealth/AutomationTypes.vue';
import ListingsHealthChart from './ListingsHealthChart.vue';
import ListingsHealthTable from './ListingsHealthTable.vue';
import {
  groupApis,
  replacePlaceHolderWithData,
  mergeEntityDataResult
} from '@/utils/common/dashboard-service-utils';
import HttpService from '@/utils/services/http-service';
import { downloadLinkAsFile } from '@/utils/helpers/downloader.js';
import utils from '@/utils/helpers/';

export default {
  name: 'ListingsHealth',
  mixins: [dashboardMixin],
  provide() {
    return {
      setExpandedWidgetVisibility: this.setExpandedWidgetVisibility
    };
  },
  data() {
    const context = this;
    const pageInfo = {
      globalViewId: 0,
      id: 112,
      name: 'ListingsHealth',
      title: 'Listings Health'
    };
    return {
      pageInfo,
      context,
      minMaxDateRange: null,
      widgetMetadata: [],
      dashboardHelpOverview: {
        isDashboardHelpOverviewPresent: false, // To be updated after getting the PDF link
        documentPath:
          'https://cdn.rboomerang.com/helpDoc/Automations%20Impact%20Logic.pdf'
      },
      isAutomationCardExpanded: false,
      initRequestParams: {
        global: {
          ...pageInfo,
          ':from': '2021-10-21',
          ':to': '2021-10-27',
          ':pvpFrom': '2021-10-14',
          ':pvpTo': '2021-10-20',
          ':page': pageInfo.name,
          ':dimensionNameValueList': []
        },
        Unavailable_SKUs_recommendation_consolidated: {
          ':widget': 'Unavailable_SKUs_recommendation_consolidated',
          ':entityType': 'asin',
          ':dimensionNameValueList': [],
          ':orderByList': []
        },
        low_inventory: {
          ':widget': 'low_inventory',
          ':entityType': 'asin',
          ':dimensionNameValueList': [],
          ':orderByList': []
        },
        listings_health_automation_types: {
          ':widget': 'listings_health_automation_types',
          ':entityType': 'asin',
          ':dimensionNameValueList': [],
          ':orderByList': []
        },
        listings_health_benchmarking_metrics: {
          ':widget': 'listings_health_benchmarking_metrics',
          ':entityType': '#ALLOVER_AGGREGATE',
          ':dimensionNameValueList': [],
          ':orderByList': [],
          ':operations': {
            timeseriesEnabled: true,
            timeseriesDimension: 'feed_date',
            daterangeDimension: 'feed_date',
            commonFilterEnabled: true,
            pvpenabled: false,
            enableDedupBeforeRollup: true,
            timeseriesRollupBy: ':timeseriesRollupBy',
            getLatestAvailableInsteadOfRollup: false,
            anchoredBrand: null
          }
        }
      },
      tableGrouping: {}
    };
  },
  computed: {
    filterSettings: () => {
      return {
        emitEvent: 'automationsFilterApply',
        uiKey: 0,
        filterBarLSKey: 'automations-filter-bar',
        show: true,
        backendCube: 'automations_dashboard',
        defaultDatePostRange: 'last30Days',
        enableSave: false,
        listenerEvent: 'automations-filter',
        allowCompare: false,
        externalFilters: {},
        filterV2: true
      };
    },
    headerOptions() {
      return {
        low_inventory: {
          props: {
            enableDownload: true,
            customDownloadFunction: this.lowInventoryTableDownload,
            customDownloadEnable: true,
            primaryFilterData: [],
            iconForDownload: 'download',
            tooltipText: 'Download',
            isMetricToolTipEnabled: true,
            headerContainerGridStyles: {
              'grid-template-columns': '1fr auto auto'
            },
            downloadFileCustomName: 'LowInventoryReport',
            searchParams: {
              show: true,
              placeholder: 'Search',
              searchDimension: 'local_search'
            },
            containerClasses: 'u-spacing-pt-s',
            searchIconClasses: 'search__input-icon rb-icon--medium',
            widgetRequestParams: this.getWidgetRequestParams('low_inventory')
          }
        },
        listings_health_automation_types: {
          props: {
            headerContainerGridStyles: {
              'grid-template-columns': '1fr auto auto'
            },
            widgetRequestParams: this.getWidgetRequestParams(
              'listings_health_automation_types'
            ),
            headerOptions: []
          }
        },
        Unavailable_SKUs_recommendation_consolidated: {
          props: {
            showWidget: this.isAutomationCardExpanded,
            enableDownload: true,
            hideTitle: true,
            primaryFilterData: [],
            iconForDownload: 'download',
            tooltipText: 'Download',
            isMetricToolTipEnabled: true,
            headerContainerGridStyles: {
              'grid-template-columns': '1fr auto auto'
            },
            downloadFileCustomName: 'automationDetails',
            searchParams: {
              show: true,
              placeholder: 'Search',
              searchDimension: 'local_search'
            },
            containerClasses: 'u-spacing-pt-s',
            searchIconClasses: 'search__input-icon rb-icon--medium',
            widgetRequestParams: this.getWidgetRequestParams(
              'Unavailable_SKUs_recommendation_consolidated'
            )
          }
        },
        listings_health_benchmarking_metrics: {
          props: {
            showWidget: this.isAutomationCardExpanded,
            headerOptions: [],
            enableFooter: false,
            enableRollup: true,
            enableDownload: false,
            enableDisplayIn: false,
            selectedMetricLimit: 3,
            minimumMetric: 4,
            removeDropdown: false,
            disableAddNewMetricButton: true,
            isMetricToolTipEnabled: true,
            modifyTooltipMessage: true,
            headerContainerGridStyles: {
              'grid-template-columns': '1fr auto auto',
              'grid-column-gap': '1.5rem'
            },
            chartConfig: {
              chartOptions: {
                legend: false,
                xFormat: '%m/%d/%Y',
                timeseries: 'feed_date',
                tooltip_format: {},
                axes: {},
                grid: 'xy',
                hasEvents: false,
                type: 'line',
                types: {},
                xAxisType: 'category',
                stack: [],
                chartGetter: '',
                show_axis_colors: true,
                events: [],
                dynamicRange: true,
                size: { height: 300 },
                axis_format: {}
              },
              stack: [],
              chartGetter: '',
              xAxisType: 'category',
              lastUpdatedDate: false
            },
            colorObject: {
              'Total Cases': '#ffa800',
              'Successful Resolutions': '#bd10e0',
              Impact: '#97cc04',
              'Closed Cases': '#ff6072',
              'Open Cases': '#23b5d3',
              'Success Rate': '#f5d908',
              'Vendor Action Pending': '#6886C5'
            }
          }
        }
      };
    }
  },
  created() {
    this.updateEntireRequestParams(this.initRequestParams);
    Vue.component('low_inventory', lowInventory);
    Vue.component(
      'Unavailable_SKUs_recommendation_consolidated',
      ListingsHealthTable
    );
    Vue.component('listings_health_automation_types', AutomationTypes);
    Vue.component('listings_health_benchmarking_metrics', ListingsHealthChart);
  },
  methods: {
    metadataUpdated(data) {
      this.widgetMetadata = data;
    },
    setExpandedWidgetVisibility(showWidget) {
      this.isAutomationCardExpanded = showWidget;
    },
    getWidgetMetaByWidgetName(widgetName) {
      return this.widgetMetadata?.find((widget) => widget?.name === widgetName);
    },
    async lowInventoryTableDownload(widgetMeta, requestParams) {
      try {
        this.load = true;
        const api = widgetMeta.downloadApiTemplate;
        const metricsForTable =
          widgetMeta.metadata.metricsForExpandedTable || [];
        const filteredMetrics = {};
        metricsForTable.forEach((metric) => {
          filteredMetrics[metric] = widgetMeta.metrics[metric];
        });
        this.tableGrouping = groupApis(filteredMetrics);
        const keys = Object.keys(this.tableGrouping);
        if (keys.length === 1) {
          api.request.metricsList =
            this.tableGrouping[keys[0]]?.api?.request?.metricsList;
        }
        const request = replacePlaceHolderWithData(api.request, requestParams);
        request.where.dimensionNameValueList =
          requestParams[':dimensionNameValueList'];
        const response = await HttpService.post(api.service, request, {
          append: api.endPoint
        });
        const { url } = response.data;

        if (url) {
          downloadLinkAsFile(url);
        } else {
          let dimensionColumn = [];
          Object.keys(filteredMetrics).forEach((metric) => {
            if (
              widgetMeta.metrics[metric]?.metadata?.downloadable ||
              widgetMeta.metrics[metric]?.metadata?.visible
            ) {
              dimensionColumn.push({
                field: metric,
                title: widgetMeta.metrics[metric].label,
                priority: widgetMeta.metrics[metric].priority,
                type: widgetMeta?.metrics[metric]?.metadata?.type || ''
              });
            }
          });
          dimensionColumn.sort(
            (item1, item2) => item1.priority - item2.priority
          );

          let mergedData = mergeEntityDataResult(response.data.entityData);

          utils.performDownload(
            mergedData,
            dimensionColumn,
            'LowInventoryReport'
          );
        }
      } catch (error) {
        throw Error(error);
      }
      this.load = false;
    }
  }
};
</script>
