<template>
  <rb-toaster
    v-if="browserIncompatibility"
    :title="title"
    :message="message"
    :images="images"
    :close-fn="closeFn"
  />
</template>

<script>
import recentBrowserInfo from '@/assets/js/recentBrowserInfo.js';
export default {
  name: 'BrowserIncompatibility',
  data() {
    return {
      title: '',
      message: '',
      images: [],
      browserIncompatibilityStatus: false,
      browserList: recentBrowserInfo.browserList,
      browserThresholdConfig: recentBrowserInfo.browserThresholdConfig,
      browserDetailsJson: recentBrowserInfo.browserDetailsJson
    };
  },
  computed: {
    browserIncompatibility() {
      if (this.images.length && this.browserIncompatibilityStatus) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    if (localStorage.getItem('browserIncompatibilityWidgetClosed') !== 'true') {
      this.execFunc();
    }
  },
  methods: {
    getSeletedBrowserDetails(platform, browserNameArr) {
      var resultArr = [];
      if (browserNameArr && browserNameArr.length > 0) {
        for (var i = 0; i < browserNameArr.length; i++) {
          for (var pf in this.browserDetailsJson.BrowserList) {
            if (platform === pf) {
              if (
                Object.keys(this.browserDetailsJson.BrowserList[pf]).indexOf(
                  browserNameArr[i]
                ) > -1
              ) {
                resultArr.push(
                  this.browserDetailsJson.BrowserList[platform][
                    browserNameArr[i]
                  ]
                );
              }
              break;
            }
          }
        }
      }
      return resultArr;
    },
    getPlatform() {
      var platformList, platform;
      platformList = {
        Mac: { name: 'Osx' },
        Win: { name: 'Windows' },
        Linux: { name: 'Linux' }
      };
      var matchedPlatform = Object.keys(platformList).filter(function (key) {
        return (
          window.navigator.platform.match(key) &&
          window.navigator.platform.match(key).indexOf(key) > -1
        );
      });
      platform = platformList[matchedPlatform].name;
      return platform;
    },
    get_browser_info() {
      var ua = navigator.userAgent;
      var tem;
      var M;
      if (ua.match('Edge') && ua.match('Edge').indexOf('Edge') > -1) {
        M = ua.match(/(edge(?=\/))\/?\s*(\d+)/i) || [];
      } else {
        M =
          ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || [];
      }
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: 'IE ', version: tem[1] || '' };
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR\/(\d+)/);
        if (tem != null) {
          return { name: 'Opera', version: tem[1] };
        }
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
      }
      return {
        name: M[0].toLowerCase(),
        version: M[1]
      };
    },
    execFunc() {
      var browserInfo = this.get_browser_info();
      var platform = this.getPlatform();

      if (
        browserInfo &&
        platform &&
        this.browserThresholdConfig &&
        Object.keys(this.browserThresholdConfig).indexOf(browserInfo.name) > -1
      ) {
        var result = recentBrowserInfo;
        if (
          parseInt(browserInfo.version) >=
            this.browserThresholdConfig[browserInfo.name].version &&
          parseInt(browserInfo.version) <
            result.browserList[platform][
              this.capitalizeFirstLetter(browserInfo.name)
            ].LatestVersion
        ) {
          this.browserIncompatibilityStatus = true;
          localStorage.setItem('browserIncompatibilityWidgetClosed', false);
          this.title =
            'For the best user experience, please upgrade your browser.';
          this.message =
            'You might experience some issues on the version you are using. Please download the latest version. For further assistance, contact your IT support team.';
          this.images = this.getSeletedBrowserDetails(platform, [
            this.capitalizeFirstLetter(browserInfo.name)
          ]);
        }
      } else {
        this.browserIncompatibilityStatus = true;
        localStorage.setItem('browserIncompatibilityWidgetClosed', false);
        this.title =
          'For the best user experience, please use a compatible browser.';
        this.message =
          'Unfortunately, our application is not best viewed on the browser you are using. Please use one of the given browsers. For further assistance, contact your IT support team.';
        this.images = this.getSeletedBrowserDetails(platform, [
          'Chrome',
          'Firefox',
          'Safari'
        ]);
      }
    },
    closeFn() {
      this.browserIncompatibilityStatus = false;
      localStorage.setItem('browserIncompatibilityWidgetClosed', true);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
};
</script>
