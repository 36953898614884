<template>
  <div
    v-if="metadataLoad"
    class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
  >
    <loader
      class="u-bg-color-grey-white"
      :loading="true"
      :color="'#007cf6'"
    />
  </div>
  <div
    v-else-if="metdataError"
    class="intermediate-dimensions u-bg-color-grey-white container u-width-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-text-align-center u-color-grey-lighter"
  >
    <p>Error Occurred. Please try again later</p>
  </div>
  <cardContainer
    v-else
    class="margins-bottom"
    :title="(metadata || {}).label"
    :action-icons="actionIcons"
    :filters="[]"
    :has-hierarchy="hasHierarchy"
    :no-data="noData"
    :last-updated-date="lastUpdatedDate"
    :load="load || externalWidgetLoad"
    :error="error || externalWidgetError"
    @download="handleDownload"
  >
    <section slot="no-hierarchy">
      <p class="u-line-height-1-1">
        No Shelves configured! Please switch from
        <span class="u-font-weight-600 u-color-grey-base">{{
          (titleOptionSelected || {}).title
        }}</span>
        to
        <span class="u-font-weight-600 u-color-grey-base">KEYWORDS</span> above.
      </p>
      <p class="u-line-height-1-1">
        Please reach out to your Engagement Manager to set up Shelves on Market
        Insights
      </p>
    </section>
    <section slot="post-title">
      <titleSelect
        :options="digitalShelfLevels"
        :selected-option="titleOptionSelected"
        @optionSelect="handleOptionSelect"
      />
    </section>
    <section
      slot="pre-filters"
      class="u-display-flex u-flex-align-items-center"
    >
      <ruleBuilderTippyWrapperV2
        class="u-spacing-mh-m"
        :root="filterConfigV2.root"
        :chip-selections="ruleBuilderData"
        :step-config="filterConfigV2.stepConfig"
        :load="filterConfigV2.load"
        :error="filterConfigV2.error"
        :no-data="filterConfigV2.noData"
        pre-text-icon="filter"
        chip-size="m"
        @ruleApply="handleRuleApply"
      />
      <ruleBuilderTippyWrapperV2
        class="u-spacing-pl-m u-spacing-pr-s u-border-left u-border-color-grey-xxx-light u-border-width-s"
        :root="sortConfigV2.root"
        :chip-selections="sortRuleBuilderData"
        :step-config="sortConfigV2.stepConfig"
        :load="sortConfigV2.load"
        :error="sortConfigV2.error"
        :no-data="sortConfigV2.noData"
        :chip-has-cross="false"
        pre-text-icon="sort"
        chip-pre-text="Sort by:"
        chip-size="m"
        @ruleApply="handleSortChipRuleApply"
      >
        <section slot="before-cross">
          <div
            class="u-display-flex u-flex-justify-content-center u-flex-align-items-center"
            @click.stop="handleSortFilterInvert"
          >
            <div
              v-tippy="{ placement: 'top', arrow: false }"
              :title="'Reverse Sort Order'"
              class="u-display-flex u-flex-justify-content-center u-flex-align-items-center"
            >
              <rb-icon
                :icon="'sort_inverted'"
                :class="sortDirection === 'ASC' && 'sort-inversion'"
                class="rb-icon rb-icon--medium u-cursor-pointer u-color-blue-base"
              />
            </div>
          </div>
        </section>
      </ruleBuilderTippyWrapperV2>
    </section>
    <section slot="body">
      <section class="u-display-flex u-flex-wrap-yes u-spacing-pt-s">
        <div
          v-for="(donutConfig, index) in computedDonutConfig"
          :key="index"
          class="u-display-flex u-spacing-mr-xl child margins-right"
          :class="computedDonutConfig.length - index > 3 && 'u-spacing-mb-xl'"
        >
          <donutWithLegends
            :chart-data="donutConfig.chartData"
            :external-color-maps="(externalColorMaps || {}).brandToColor || {}"
            :size="'m'"
            :title="(donutConfig.additionalData || {}).title"
            :icon="(donutConfig.additionalData || {}).icon"
            :popup-metadata="donutConfig.popupData"
            :popup-title="(donutConfig.additionalData || {}).title"
            :font-size="5"
            :pvp-font-size="6"
            :custom-title-tooltip="customTitleTooltip"
            :is-title-clickable="computedIsTitleClickable"
            @titleClick="handleTitleClick($event, donutConfig)"
          >
            <section
              slot="title-right"
              class="u-display-flex u-flex-align-items-center"
            >
              <OptionsConfigHandler
                v-for="(config, optionIndex) in computedHeaderOptions"
                :key="optionIndex"
                class="u-spacing-ml-m u-spacing-mb-s"
                :config="config"
                :shelf-hierarchy="
                  (donutConfig.additionalData || {}).shelfHierarchy
                "
                @showOverlay="handleShowOverlay($event, donutConfig)"
                @optionSelect="handleDonutOptionSelect(donutConfig, $event)"
              />
            </section>
            <section
              slot="between-title-donut"
              class="u-width-100 u-border-bottom u-border-width-s u-border-color-grey-xxx-light u-spacing-pb-xs u-display-flex u-flex-justify-content-flex-start"
            >
              <div
                v-if="(donutConfig.additionalData || {}).totalKeywords"
                class="u-width-100 u-display-flex u-flex-align-items-center"
              >
                <rb-icon
                  class="u-display-flex u-flex-0 rb-icon--medium u-spacing-mr-xs u-color-grey-lighter"
                  :icon="'powered_by'"
                />
                <absoluteChange
                  v-if="intradayCrawlingAvailable"
                  metric="Multi Crawl Keywords:"
                  :result="`${
                    (donutConfig.additionalData || {}).totalMultiCrawlKeywords
                      .result
                  } / ${
                    (donutConfig.additionalData || {}).totalKeywords.result
                  }`"
                />
                <absoluteChange
                  v-else
                  metric="Keywords:"
                  :result="
                    (donutConfig.additionalData || {}).totalKeywords.result
                  "
                />
              </div>
            </section>
            <section
              slot="footer"
              class="u-border-bottom u-spacing-pb-xs u-border-width-s u-border-color-grey-xxx-light"
            >
              <section
                v-if="(donutConfig.additionalData || {} || {}).sortMetricData"
                class="u-display-flex u-flex-align-items-center"
              >
                <rb-icon
                  class="u-display-flex u-flex-0 rb-icon--medium u-spacing-mr-s u-color-grey-lighter"
                  :icon="'sort'"
                />
                <absoluteChange
                  :metric="formLabel(sortLabel, donutConfig)"
                  :is-inverted="
                    ((donutConfig.additionalData || {}).sortMetricData || {})
                      .isInverted
                  "
                  :result="
                    ((donutConfig.additionalData || {}).sortMetricData || {})
                      .result
                  "
                  :pvp-value="
                    (
                      ((donutConfig.additionalData || {}).sortMetricData || {})
                        .pvp || {}
                    ).pvp
                  "
                  :pvp-direction="
                    (
                      (donutConfig.additionalData || {}).sortMetricData.pvp ||
                      {}
                    ).pvpDirection
                  "
                />
              </section>
            </section>
          </donutWithLegends>
        </div>
      </section>
    </section>
    <section
      slot="footer-right"
      class="u-flex-grow-1 u-display-flex u-flex-justify-content-space-between u-flex-align-items-center"
    >
      <div>
        <FooterIcon
          v-show="isAnchored"
          icon="star"
          text="Client brand"
        />
      </div>
      <b-pagination
        :total="maxPages"
        :current.sync="currentUiPage"
        :size="'is-small'"
        :simple="false"
        :rounded="true"
        :per-page="uiPageLimit"
        @change="handlePagination"
      />
    </section>
  </cardContainer>
</template>

<script>
import cardContainer from '../organisms/card-container';
import loader from '@/components/basic/loader';
import ruleBuilderTippyWrapperV2 from '../molecules/rule-builder-tippy-wrapper-v2.vue';
import ruleBuilderV2 from '../molecules/rule-builder-v2.vue';
import titleSelect from '../molecules/title-select';
import donutWithLegends from '../molecules/donut-with-legends';
import { downloadFile } from '@/components/pages/skuDetails/helper.js';
import HttpService from '@/utils/services/http-service';
import absoluteChange from '../atoms/absolute-change.vue';
import { cloneDeep } from 'lodash';
import OptionsConfigHandler from '../organisms/options-config-handler.vue';
import moment from 'moment';
import { setUnsavedState, getWidgetUnsavedState } from './views-tracking';
import FooterIcon from '../atoms/footer-icons.vue';
import { KEYWORDS_PATH } from '@/components/pages/insights/amazon/share-of-voice/templates/constants.js';
import {
  groupApis,
  requestDateReplacement,
  requestTimeReplacement,
  appendPartialArrays
} from './dashboard-service-utils';
import {
  digitalShelfLevels,
  accessKeys,
  setShelfLevel,
  setLeafLevel,
  titleOptionSelect,
  selectedTitleOptions,
  appendIsNotNull,
  getFilteredShelfHierarchy,
  disableInHierarchy,
  hasHierarchy,
  appendAnchorBrandCondition,
  setRuleBuilderBrand,
  setDonutLegendMetadata,
  appendAnchorFilter
} from './common';

export default {
  components: {
    FooterIcon,
    cardContainer,
    loader,
    titleSelect,
    donutWithLegends,
    absoluteChange,
    ruleBuilderV2,
    ruleBuilderTippyWrapperV2,
    OptionsConfigHandler
  },
  props: {
    sovDataGroups: {
      type: Object,
      default: () => ({})
    },
    brandsList: {
      type: Object,
      default: {}
    },
    standardHierarchy: {
      type: Object,
      default: {}
    },
    clientBrands: {
      type: Object,
      default: {}
    },
    previousAnchorBrand: {
      type: Object,
      default: null
    },
    keywordType: {
      type: Array,
      default: () => []
    },
    types: {
      type: Array,
      default: () => []
    },
    placements: {
      type: Array,
      default: () => []
    },
    page: {
      type: String,
      default: null
    },
    initialState: {
      type: Object,
      default: () => ({})
    },
    unsavedState: {
      type: Object,
      default: () => ({})
    },
    widgetName: {
      type: String,
      default: null
    },
    externalColorMaps: {
      type: Object,
      default: () => ({})
    },
    sortConfigV2: {
      type: Object,
      default: () => ({})
    },
    filterConfigV2: {
      type: Object,
      default: () => ({})
    },
    dataService: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: null
    },
    namespace: {
      type: String,
      default: null
    },
    pageWiseMinMaxKey: {
      type: String,
      default: ''
    },
    multiCrawl: {
      type: Boolean,
      default: false
    },
    intradayCrawlingAvailable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      initialised: false,
      headerOptions: [
        {
          custom: {
            show: {
              entityType: [
                'digital_shelf_l1',
                'digital_shelf_l2',
                'digital_shelf_l3',
                'digital_shelf_l4',
                'digital_shelf_l5',
                'search_term'
              ]
            }
          },
          type: 'hierarchyPopup',
          icon: {
            icon: 'hierarchy',
            class: {}
          },
          tooltip: {
            tippyOptions: {
              reactive: true,
              distance: 4,
              placement: 'bottom',
              interactive: true,
              theme: 'dropdown',
              trigger: 'mouseenter',
              duration: [0, 0]
            }
          }
        },
        {
          custom: {
            show: {
              entityType: [
                'digital_shelf_l1',
                'digital_shelf_l2',
                'digital_shelf_l3',
                'digital_shelf_l4',
                'digital_shelf_l5',
                'search_term'
              ]
            }
          },
          tooltip: {
            title: 'Snapshots of Page 1'
          },
          icon: {
            icon: 'skus',
            class: {}
          },
          click: {
            emit: 'showOverlay'
          }
        },
        {
          custom: {
            show: {
              entityType: [
                'digital_shelf_l1',
                'digital_shelf_l2',
                'digital_shelf_l3',
                'digital_shelf_l4',
                'digital_shelf_l5'
              ]
            }
          },
          type: 'iconDropdown',
          tooltip: {
            title: 'View more options'
          },
          icon: {
            icon: 'more-horizontal',
            class: {},
            style: {
              transform: 'rotate(90deg)'
            }
          },
          dropdown: {
            options: [{ title: 'View Keyword Drivers', id: 'KEYWORD_DRIVERS' }]
          }
        }
      ],
      dataGrouping: {},
      lastUpdatedDate: '',
      actionIcons: [
        {
          emit: 'download',
          icon: 'download',
          loading: false,
          error: false,
          tippyTitle:
            'Download Excel with shelf-level Share of Voice by brand for current level'
        }
      ],
      downloadApi: {
        error: false,
        loading: false
      },
      currentApiPage: 1,
      currentUiPage: 1,
      uiPageLimit: 6,
      maxPages: null,
      apiLimit: null,
      digitalShelfLevels: [],
      metadata: {},
      data: {},
      apiDataTransformed: [],
      filterWhereClause: {
        dimensionNameValueList: []
      },
      devMetadata: {
        metadata: {
          sharesKey: {
            suffix: 'share_percentage'
          },
          digitalShelfTitleKey: 'entityValue',
          shelHierarchyKey: this.multiCrawl
            ? 'shelf_hierarchy_intraday'
            : 'shelf_hierarchy',
          footerMetricData: '',
          footerMetricKey: '',
          originalSortMericKey: '',
          sovSortMetrxicKey: ''
        },
        accessKeys: accessKeys()
      }
    };
  },
  computed: {
    isAnchored() {
      const { anchoredBrand } = this.getUnsavedSettings();
      return appendAnchorBrandCondition(anchoredBrand);
    },
    externalWidgetLoad() {
      return (
        this.brandsList.load ||
        this.standardHierarchy.load ||
        this.clientBrands.load
      );
    },
    initialisedAndExternalLoaded() {
      return !this.externalWidgetLoad && this.initialised;
    },
    externalWidgetError() {
      return (
        this.brandsList.error ||
        this.standardHierarchy.error ||
        this.clientBrands.error ||
        this.metadataError
      );
    },
    customTitleTooltip() {
      const { value } = this.titleOptionSelected;
      if (value === 'search_term') {
        return null;
      }
      return 'Analyze shelf';
    },
    computedIsTitleClickable() {
      const { value } = this.titleOptionSelected;
      if (value === 'search_term') {
        return false;
      }
      return true;
    },
    computedHeaderOptions() {
      const { value } = this.titleOptionSelected;
      return this.headerOptions.filter((option) => {
        if (option?.custom?.show?.entityType?.includes(value)) {
          return option;
        }
      });
    },
    hasHierarchy() {
      return hasHierarchy(this);
    },
    getSelectedCustomShelves() {
      return this.$store.getters[this.namespace + 'getSelectedCustomShelves'];
    },
    getSelectedStandardShelves() {
      return this.$store.getters[this.namespace + 'getSelectedStandardShelves'];
    },
    getCustomHierarchy() {
      return this.$store.getters[this.namespace + 'getCustomHierarchy'];
    },
    getStandardHierarchy() {
      return this.$store.getters[this.namespace + 'getStandardHierarchy'];
    },
    sortLabel() {
      return this.sortRuleBuilderData?.[0]?.[0]?.label || '';
    },
    ruleBuilderData() {
      return getWidgetUnsavedState(this)?.ruleBuilderData || [];
    },
    sortDirection() {
      return getWidgetUnsavedState(this)?.sortDirection || 'ASC';
    },
    sortRuleBuilderData() {
      return getWidgetUnsavedState(this)?.sortRuleBuilderData || [];
    },
    titleOptionSelected() {
      return selectedTitleOptions(this);
    },
    metadataLoad() {
      return this?.dataService?.metadataLoad;
    },
    metdataError() {
      return this?.dataService?.metdataError;
    },
    getGlobalWhereClause() {
      return this.$store.getters[this.namespace + 'getGlobalFilters'];
    },
    load() {
      return this?.dataService?.load;
    },
    error() {
      return this?.dataService?.error;
    },
    noData() {
      return this?.dataService?.noData;
    },
    computedDonutConfig() {
      const { anchoredBrand } = this.getUnsavedSettings();
      const { min, count } = this.range(
        this.currentUiPage - 1,
        this.uiPageLimit
      );
      const { min: apiMin } = this.range(
        this.currentApiPage - 1,
        this.apiLimit
      );
      const currentActivePageChartData = [
        ...this.dataService.donutChartData
      ].splice(min - apiMin, count);

      const currentActivePopupData = [
        ...this.dataService.donutPopupData
      ].splice(min - apiMin, count);
      const currentAdditionalData = [
        ...this.dataService.additionalDigitalShelfData
      ].splice(min - apiMin, count);
      const unformattedChartData = currentActivePageChartData.map(
        (unitDigitalShelfArray) => {
          return unitDigitalShelfArray.map((item) => {
            const returnItem = setDonutLegendMetadata(
              item,
              this.externalColorMaps?.brandToColor,
              anchoredBrand
            );
            if (appendAnchorBrandCondition(anchoredBrand)) {
              returnItem.iconCustomClass =
                item.clientFlag === 'comp' ? null : 'rb-icon-14';
            } else {
              returnItem.iconCustomClass = null;
            }
            return returnItem;
          });
        }
      );
      const configArray = [];
      for (let i = 0; i < unformattedChartData.length; i++) {
        const chartData = unformattedChartData[i] || {};
        const popupData = currentActivePopupData[i] || {};
        const additionalData = currentAdditionalData[i] || {};
        configArray.push({
          chartData,
          popupData,
          additionalData
        });
      }
      return configArray;
    },
    totalKeywordsMetric() {
      return this.multiCrawl ? 'total_keywords_intraday' : 'total_keywords';
    },
    shelfHierarchyKey() {
      return this.multiCrawl ? 'shelf_hierarchy_intraday' : 'shelf_hierarchy';
    }
  },
  watch: {
    initialisedAndExternalLoaded: {
      immediate: true,
      handler(initialisedAndExternalLoaded) {
        if (initialisedAndExternalLoaded) {
          this.transformAndFetch();
        }
      }
    }
  },
  created() {
    this.initialised = false;
    this.setTitleDropdownContents();
    this.setInitialUnsavedState();
    disableInHierarchy(this);
    this.init();
    this.$emit('created', this);
  },
  methods: {
    setTitleDropdownContents() {
      this.digitalShelfLevels = digitalShelfLevels(this.multiCrawl);
    },
    formLabel(sortLabel, donutConfig) {
      if (donutConfig?.additionalData?.sovBrand?.result) {
        return `${sortLabel} of ${donutConfig?.additionalData?.sovBrand?.result} `;
      } else {
        return `${sortLabel}:`;
      }
    },
    appendAnchorBrand(requestCopy) {
      const { anchoredBrand } = this.getUnsavedSettings();
      if (appendAnchorBrandCondition(anchoredBrand)) {
        requestCopy.operations.totalClientBrandsForDigitalShelf = 0;
        requestCopy.operations.anchoredBrand = anchoredBrand.title;
      }
    },
    createAddToFilterPayload(donutConfig) {
      const { additionalData } = donutConfig;
      const { title } = additionalData;
      const { type, level } = selectedTitleOptions(this);
      return { title, type, level };
    },
    handleDonutOptionSelect(context, payload) {
      if (payload?.details?.[0]) {
        const obj = this.createAddToFilterPayload(context);
        if (payload.details[0].id === 'KEYWORD_DRIVERS') {
          this.$emit('keywordsDriver', obj);
        }
      }
    },
    handleTitleClick(payload, context) {
      let obj = this.createAddToFilterPayload(context);
      const title = obj.title.toLowerCase();
      obj = { ...obj, title };
      this.$emit('addToFilter', obj);
    },
    handleShowOverlay(payload, context) {
      const { title: selectedDonutTitle } = context.additionalData;
      const selectedTitle = selectedTitleOptions(this);
      const { api, value } = selectedTitle;
      if (value === 'search_term') {
        this.$emit('setAccessedFrom', {
          hierarchy: 'search_term',
          text: selectedDonutTitle
        });
        this.$emit('setKeywordSelection', selectedDonutTitle);
      } else {
        const { prefix } = api;
        const prefixSplit = prefix.split('~');
        const externalDimensionNameValueList = [];
        externalDimensionNameValueList.push(
          {
            dimensionName: value,
            dimensionValue: selectedDonutTitle
          },
          { dimensionName: 'tag_type', dimensionValue: prefixSplit[0] }
        );
        this.$emit('setAccessedFrom', {
          hierarchy: 'shelf',
          text: selectedDonutTitle
        });
        this.$emit('setWhereClause', externalDimensionNameValueList);
      }
    },
    getUnsavedSettings() {
      return (
        this.$store.getters[this.namespace + 'getUnsavedWidgetStates']
          ?.settings || {}
      );
    },
    createBundleRequest(metricsList, filters) {
      const bundle = {};
      for (const metric of metricsList) {
        if (this.metadata.metrics[metric]) {
          const { dataGroup } = this.metadata.metrics[metric];
          const { api } = this.dataGrouping[dataGroup];
          const requestCopy = this.createDataRequestPayload(
            api.request,
            metric,
            dataGroup,
            filters
          );
          this.appendAnchorBrand(requestCopy);
          requestCopy.page = this.page;
          requestCopy.widget = this.widgetName;
          bundle[dataGroup] = requestCopy;
        }
      }
      return bundle;
    },
    firstStepSovCondition(ruleBuidlerState) {
      return ruleBuidlerState?.[0]?.[0]?.dimensionName === 'SOV';
    },
    addSortMetricsToMetadata(metric, sortRuleBuilderData) {
      if (sortRuleBuilderData?.[0]?.[0]?.dimensionName !== 'SOV') {
        const dimension = sortRuleBuilderData?.[0]?.[0]?.dimensionName;
        this.devMetadata.metadata.footerMetricData = dimension;
        this.devMetadata.metadata.originalSortMericKey = dimension;
        this.devMetadata.metadata.footerMetricKey = '';
        return dimension;
      } else {
        this.devMetadata.metadata.footerMetricData = '';
        this.devMetadata.metadata.footerMetricKey = `sorted_brand_${metric}_share_percentage`;
        this.devMetadata.metadata.originalSortMericKey = metric;
        this.devMetadata.metadata.sovfooterMetricData = 'sorted_brand';
      }
    },
    addOrderByPayload(
      bundleRequestTemplate,
      sortRuleBuilderData,
      sortDirection
    ) {
      if (!this.firstStepSovCondition(sortRuleBuilderData)) {
        const { dimensionName } = sortRuleBuilderData?.[0]?.[0] || {};
        const { value: metricType } = sortRuleBuilderData?.[1]?.[0] || {};
        const dimension = this.createMetricAbsoluteChangePayload(
          dimensionName,
          metricType,
          'NUMERIC'
        );
        bundleRequestTemplate.operations.orderByList = [
          {
            dimension,
            direction: sortDirection
          }
        ];
      }
    },
    downloadReport() {
      return new Promise(async (resolve, reject) => {
        try {
          const unsavedSettings = this.getUnsavedSettings();
          const { downloadApiTemplate } = this.metadata;
          const { endPoint, request, service } = downloadApiTemplate;
          const bundleRequestTemplate = cloneDeep(request);
          const metric = unsavedSettings.selectedPlacement;
          this.formulateDataRequest(bundleRequestTemplate, metric, {
            metrics: [this.shelfHierarchyKey]
          });
          this.appendNonSovWhereClause(bundleRequestTemplate);
          bundleRequestTemplate.widget = this.widgetName;
          bundleRequestTemplate.page = this.page;
          const { data } = await HttpService.post(
            service,
            bundleRequestTemplate,
            {
              append: endPoint
            }
          );
          downloadFile(data.url);
          resolve();
        } catch (e) {
          console.error('error', e);
          reject(e);
        }
      });
    },
    addFilterMetric(ruleBuilderData) {
      if (
        ruleBuilderData?.[0]?.[0]?.dimensionName &&
        ruleBuilderData?.[0]?.[0]?.dimensionName !== 'SOV'
      ) {
        return ruleBuilderData?.[0]?.[0]?.dimensionName;
      }
    },
    formulateDataRequest(bundleRequestTemplate, metric, filters = {}) {
      const unsavedState = getWidgetUnsavedState(this) || {};
      const addtionalMetrics = [];
      const addtionalSortMetric = this.addSortMetricsToMetadata(
        metric,
        unsavedState.sortRuleBuilderData
      );
      if (addtionalSortMetric) {
        addtionalMetrics.push(addtionalSortMetric);
      }
      const filterMetric = this.addFilterMetric(unsavedState.ruleBuilderData);
      if (filterMetric && filterMetric !== addtionalSortMetric) {
        addtionalMetrics.push(filterMetric);
      }
      bundleRequestTemplate.bundleDataGroupsRequest = this.createBundleRequest(
        [
          metric,
          this.totalKeywordsMetric,
          ...(this.intradayCrawlingAvailable && !this.multiCrawl
            ? [this.totalMultiCrawlKeywordsMetric]
            : []),
          ...addtionalMetrics
        ],
        filters
      );
      const titleOptionSelected = selectedTitleOptions(this);
      const entityType = titleOptionSelected.value;
      bundleRequestTemplate.entityType = entityType;
      this.addOrderByPayload(
        bundleRequestTemplate,
        unsavedState.sortRuleBuilderData,
        unsavedState.sortDirection
      );
      return bundleRequestTemplate;
    },
    appendNonSovWhereClause(bundleRequestTemplate) {
      const dataGroups = Object.keys(
        bundleRequestTemplate.bundleDataGroupsRequest || {}
      );
      const unsavedState = getWidgetUnsavedState(this);
      dataGroups.forEach((dataGroup) => {
        if (
          !this.sovDataGroups[dataGroup] &&
          this.applyForDataGroup(unsavedState.ruleBuilderData, dataGroup)
        ) {
          bundleRequestTemplate.operations.outerWhereClause =
            this.getAmsWhereClaues(unsavedState.ruleBuilderData, dataGroup);
        }
      });
    },
    async getData() {
      try {
        this.dataService.donutChartData = [];
        this.dataService.donutPopupData = [];
        const unsavedSettings = this.getUnsavedSettings();

        const metric = unsavedSettings.selectedPlacement;
        const bundleRequestTemplate = cloneDeep(
          this.metadata.metadata.dataAPI.request
        );
        this.formulateDataRequest(bundleRequestTemplate, metric);
        this.apiLimit = bundleRequestTemplate.operations.limit;
        bundleRequestTemplate.operations.page = this.currentApiPage;
        this.appendNonSovWhereClause(bundleRequestTemplate);
        const response = await this.dataService.getData(bundleRequestTemplate);
        this.maxPages = response?.entityData?.[0]?.totalEntityCount || 1;
        const { donutChartData, donutPopupData, additionalDigitalShelfData } =
          this.dataService.transformApiData(
            response.entityData,
            this.devMetadata,
            metric,
            'name',
            this.titleOptionSelected,
            this.intradayCrawlingAvailable,
            this.metadata.metrics
          );
        this.$emit('newDigitalShelfData', donutChartData);
        this.dataService.donutChartData = donutChartData;
        this.dataService.donutPopupData = donutPopupData;
        this.dataService.additionalDigitalShelfData =
          additionalDigitalShelfData;
      } catch (e) {
        console.error('e', e);
      }
    },
    range(offset, limit) {
      const min = offset * limit;
      const count = limit;
      const max = min + count;
      return {
        min,
        count,
        max
      };
    },
    handlePagination(data) {
      this.currentUiPage = data;
      const { max: uiMax } = this.range(
        this.currentUiPage - 1,
        this.uiPageLimit
      );
      const newApiPage = Math.ceil(uiMax / this.apiLimit);
      if (this.currentApiPage !== newApiPage) {
        this.currentApiPage = newApiPage;
        this.getData();
      }
      this.$emit('retainGlobalColors', true);
    },
    async handleDownload(index) {
      try {
        this.downloadApi.loading = true;
        this.downloadApi.error = false;
        this.updateActionsIconDownload(index);
        await this.downloadReport();
      } catch (e) {
        console.error('e', e);
        this.downloadApi.loading = false;
        this.downloadApi.error = true;
        this.updateActionsIconDownload(index);
      } finally {
        this.downloadApi.loading = false;
        this.updateActionsIconDownload(index);
        setTimeout(() => {
          this.downloadApi.error = false;
          this.updateActionsIconDownload(index);
        }, 1000);
      }
    },
    updateActionsIconDownload(index) {
      const actionIconsCopy = JSON.parse(JSON.stringify(this.actionIcons));
      actionIconsCopy[index] = {
        ...actionIconsCopy[index],
        ...this.downloadApi
      };
      this.actionIcons = actionIconsCopy;
    },
    setInitialUnsavedState() {
      const localUnsavedState = {};
      const localDefaultState = this.setInitialSavedState();
      const unsavedState = getWidgetUnsavedState(this) || {};
      const defaultStateKeys = Object.keys(localDefaultState);
      defaultStateKeys.forEach((key) => {
        localUnsavedState[key] = unsavedState[key] || localDefaultState[key];
      });
      this.pushToStateStore(localUnsavedState);
    },
    setInitialSavedState() {
      const localDefaultState = {};
      const initialState = this.initialState?.[this.widgetName] || {};
      const defaultState = this.getDefaultState();
      const defaultStateKeys = Object.keys(defaultState);
      defaultStateKeys.forEach((key) => {
        localDefaultState[key] = initialState[key] || defaultState[key];
      });
      if (this.multiCrawl) {
        // this is to handle the case where new retailers are onboarded into intraday crawling, but have old sovByShelves widgetState
        const titleOptionSelected = selectedTitleOptions(this, true).value;
        if (titleOptionSelected !== initialState.titleOptionSelected) {
          localDefaultState.titleOptionSelected = selectedTitleOptions(
            this,
            true
          ).value;
          localDefaultState.titlePath = [];
        }
      }
      this.pushToStateStore(localDefaultState, 'pushToInitialState');
      return localDefaultState;
    },
    getDefaultState() {
      const ruleBuilderData = [];
      const sortRuleBuilderData = [
        [
          {
            dimensionName: 'SOV',
            label: 'Share Of Voice',
            dataGroup: [
              'sov_top_2',
              'sov_top_3',
              'sov_top_5',
              'sov_top_10',
              'sov_total_count'
            ],
            prefix: {},
            infix: {
              text: 'Share Of Voice'
            },
            chipLabel: 'sov'
          }
        ],
        [
          {
            brandKey: 'brand_by_client_flag',
            brand: 'My Brands',
            clientType: 'client',
            prefix: {},
            suffix: {},
            infix: {
              text: 'My Brands'
            }
          }
        ],
        [
          {
            label: 'Absolute',
            value: 'ABSOLUTE',
            prefix: {},
            suffix: {}
          }
        ]
      ];
      return {
        ruleBuilderData,
        sortRuleBuilderData,
        sortDirection: 'DESC',
        titlePath: this.multiCrawl ? [] : ['STANDARD'],
        titleOptionSelected: this.multiCrawl
          ? 'search_term'
          : 'digital_shelf_l1'
      };
    },
    pushToStateStore(data, emit = 'pushToUnsavedState') {
      this.$emit(emit, { [this.widgetName]: data });
    },
    handleOptionSelect(data) {
      const { selectedOptions } = data || {};
      if (selectedOptions[0]) {
        titleOptionSelect(this, data, setUnsavedState);
        this.getData();
      }
    },
    resetPagination() {
      this.currentApiPage = 1;
      this.currentUiPage = 1;
    },
    handleSortFilterInvert(data) {
      this.toggleSortDirection();
      this.getData();
    },
    toggleSortDirection() {
      const oldSortDirection = this.sortDirection;
      const newSortDirection = oldSortDirection === 'ASC' ? 'DESC' : 'ASC';
      setUnsavedState(this, 'sortDirection', newSortDirection);
    },
    handleSortApply() {
      this.getData();
    },
    handleRadioSelection(data) {
      this.$emit('radioSelection', data);
    },
    deleteUnwantedKeys(ruleBuilder, unwantedKeys = ['nextStep', 'percentage']) {
      const unsavedStateRuleBuilderData = cloneDeep(ruleBuilder);
      const deleteMap = {};
      unwantedKeys.forEach((item, acc) => {
        deleteMap[item] = 1;
      });
      unsavedStateRuleBuilderData.forEach((step) => {
        step.forEach((selection) => {
          const selectionKeys = Object.keys(selection);
          selectionKeys.forEach((key) => {
            if (deleteMap[key]) {
              delete selection[key];
            }
          });
        });
      });
      return unsavedStateRuleBuilderData;
    },
    openSnackbar(message = 'Something Went Wrong !!!') {
      this.$snackbar.open({
        message: message,
        duration: 5000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    },
    changeFilterRuleBuilderSelectedBrand(snackbarMessgae) {
      const { ruleBuilderData } = getWidgetUnsavedState(this);
      this.changeRuleBuilderSelectedBrand(ruleBuilderData, snackbarMessgae);
    },
    changeSortRuleBuilderSelectedBrand(snackbarMessgae) {
      const { sortRuleBuilderData } = getWidgetUnsavedState(this);
      this.changeRuleBuilderSelectedBrand(
        sortRuleBuilderData,
        snackbarMessgae,
        'sortRuleBuilderData'
      );
    },
    changeRuleBuilderSelectedBrand(
      ruleBuilderData,
      snackbarMessgae = null,
      storeTarget = 'ruleBuilderData'
    ) {
      // incase the filter/sort selected is MY_BRANDS and change it to the anchored brands
      if (this.firstStepSovCondition(ruleBuilderData)) {
        const { anchoredBrand } = this.getUnsavedSettings();
        if (
          ruleBuilderData?.[1]?.[0]?.brand === this.previousAnchorBrand.title
        ) {
          setRuleBuilderBrand(ruleBuilderData[1][0], anchoredBrand);
          setUnsavedState(this, storeTarget, ruleBuilderData);
          if (snackbarMessgae) {
            this.openSnackbar(snackbarMessgae);
          }
        }
      }
    },
    handleRuleApply(data) {
      this.resetPagination();
      const unsavedStateRuleBuilderData = this.deleteUnwantedKeys(data);
      setUnsavedState(this, 'ruleBuilderData', unsavedStateRuleBuilderData);
      this.$emit('retainGlobalColors', false);
      this.getData();
    },
    handleSortChipRuleApply(data) {
      this.resetPagination();
      const unsavedStateRuleBuilderData = this.deleteUnwantedKeys(data);
      setUnsavedState(this, 'sortRuleBuilderData', unsavedStateRuleBuilderData);
      // this.devMetadata.metadata.footerMetricData = data?.[0]?.[0].dimensionName;
      this.getData();
    },
    getLocalWhereClause(dataGroup, unsavedSettings) {
      const globalWhereClause = cloneDeep(this.getGlobalWhereClause);
      const { anchoredBrand } = this.getUnsavedSettings();
      const where = {
        date: {},
        pvpDate: {},
        dimensionNameValueList: [],
        excludeDimensionsFromSharePercentage: []
      };
      where.date.from = globalWhereClause.date_range.from;
      where.date.to = globalWhereClause.date_range.to;
      where.date.name = globalWhereClause.date_range.name;
      where.date.page_wise_min_max_key = this.pageWiseMinMaxKey;
      where.pvpDate.from = globalWhereClause.date_range.compare_from;
      where.pvpDate.to = globalWhereClause.date_range.compare_to;
      where.pvpDate.compare_name = globalWhereClause.date_range.compare_name;
      if (this.multiCrawl) {
        where.time = {};
        where.pvpTime = {};
        where.time.from = globalWhereClause.time_range.startTime;
        where.time.to = globalWhereClause.time_range.endTime;
        where.pvpTime.from = globalWhereClause.time_range.compareToStartTime;
        where.pvpTime.to = globalWhereClause.time_range.compareToEndTime;
      }
      where.dimensionNameValueList = globalWhereClause.dimensionNameValueList;
      const titleOptionsSlected = selectedTitleOptions(this);
      where.dimensionNameValueList = getFilteredShelfHierarchy(
        where.dimensionNameValueList,
        titleOptionsSlected
      );
      if (this.sovDataGroups[dataGroup]) {
        appendPartialArrays(
          where.dimensionNameValueList,
          unsavedSettings.selectedType,
          this.types
        );
      }
      appendPartialArrays(
        where.dimensionNameValueList,
        unsavedSettings.selectedKeywordType,
        this.keywordType
      );
      const titleOptionSelected = selectedTitleOptions(this);
      const entityType = titleOptionSelected.value;
      appendAnchorFilter(where, anchoredBrand);
      appendIsNotNull(dataGroup, where.dimensionNameValueList, entityType);
      return where;
    },

    getOrderBy(sharePecentageKey, ruleBuilderData, dataGroup) {
      const returnValue = [];
      if (
        this.applyForDataGroup(ruleBuilderData, dataGroup) &&
        this.firstStepSovCondition(ruleBuilderData)
      ) {
        const unsavedState = getWidgetUnsavedState(this);
        const ruleBuilderSortType = ruleBuilderData[2][0].value;
        const dimensionToBeAppended = this.createMetricAbsoluteChangePayload(
          sharePecentageKey,
          ruleBuilderSortType
        );
        returnValue.push({
          dimension: dimensionToBeAppended,
          direction: unsavedState.sortDirection
        });
      }
      return returnValue;
    },
    createMetricAbsoluteChangePayload(
      metric,
      metricType,
      metricUnit = 'PERCENTAGE'
    ) {
      if (metricType === 'ABSOLUTE') {
        return metric;
      }
      if (metricUnit === 'PERCENTAGE') {
        return 'PVP_DIFF_' + metric;
      }
      return 'PVP_' + metric;
    },
    createFilterWhereClause(sharePecentageKey, ruleBuilderData, dataGroup) {
      const returnValue = {
        dimensionNameValueList: []
      };
      if (
        this.applyForDataGroup(ruleBuilderData, dataGroup) &&
        this.firstStepSovCondition(ruleBuilderData)
      ) {
        const brandToFilterOn = ruleBuilderData[1][0].brand;
        const queryBuilderValueType = ruleBuilderData[2][0].value;
        const queryBuilderValue = ruleBuilderData[2][1].value;
        const queryBuilderOperator = ruleBuilderData[2][1].operator.operator;
        const dimensionToBeAppended = this.createMetricAbsoluteChangePayload(
          sharePecentageKey,
          queryBuilderValueType
        );
        returnValue.dimensionNameValueList.push({
          dimensionName: 'brand',
          dimensionValue: brandToFilterOn,
          operator: 'EQUAL_TO'
        });
        returnValue.dimensionNameValueList.push({
          dimensionName: dimensionToBeAppended,
          dimensionValue: queryBuilderValue,
          operator: queryBuilderOperator
        });
      }
      return returnValue;
    },
    handleCreateDigitalShelf() {
      console.log('creating digital shelf');
    },
    setShelfLevel() {
      setShelfLevel(this);
    },
    setShelfToKeywords() {
      setLeafLevel(this, KEYWORDS_PATH);
    },
    setDefaultStateToKeywords() {
      setLeafLevel(this, KEYWORDS_PATH);
      setLeafLevel(this, KEYWORDS_PATH, 'initial');
    },
    async init() {
      try {
        this.metadata = await this.dataService?.getMetadata();
        this.lastUpdatedDate = moment(this.metadata.calendar.max_date).format(
          'LL'
        );
        this.totalMultiCrawlKeywordsMetric = 'total_keywords_multi_crawled';
        this.isIntradayKeywordKey = 'is_intraday';
        this.devMetadata.metadata = {
          ...this.devMetadata.metadata,
          totalKeywordsMetric: this.totalKeywordsMetric,
          totalMultiCrawlKeywordsMetric: this.totalMultiCrawlKeywordsMetric,
          isIntradayKeywordKey: this.isIntradayKeywordKey
        };
        const { metrics } = this.metadata;
        this.dataGrouping = groupApis(metrics);
        this.initialised = true;
      } catch (e) {
        console.error('e', e);
      }
    },
    transformAndFetch() {
      this.$emit('newMetadata', this.metadata);
      this.getData();
    },
    applyForDataGroup(ruleBuilderData, dataGroup) {
      const index = (ruleBuilderData?.[0]?.[0]?.dataGroup || []).findIndex(
        (item) => item === dataGroup
      );
      return index >= 0;
    },
    sovMetricsOuterWhereClause(
      sortRuleBuilderData,
      ruleBuilderData,
      dataGroup
    ) {
      const returnValue = {
        dimensionNameValueList: []
      };
      if (
        this.applyForDataGroup(sortRuleBuilderData, dataGroup) &&
        this.firstStepSovCondition(sortRuleBuilderData)
      ) {
        const brand = sortRuleBuilderData[1][0].brand;
        returnValue.dimensionNameValueList.push({
          dimensionName: 'brand',
          operator: 'EQUAL_TO',
          dimensionValue: brand
        });
      }
      return returnValue;
    },
    filterArray(arrayToBeFiltered, filterMetrics = []) {
      const filterMap = {};
      filterMetrics.forEach((item) => {
        filterMap[item] = 1;
      });
      return arrayToBeFiltered.filter((item) => {
        return !filterMap[item];
      });
    },
    getAmsWhereClaues(ruleBuilderData, dataGroup) {
      const returnValue = {
        dimensionNameValueList: []
      };
      if (this.applyForDataGroup(ruleBuilderData, dataGroup)) {
        try {
          const { dimensionName } = ruleBuilderData[0][0];
          const { value: metricType } = ruleBuilderData[1][0];
          const { value, operator } = ruleBuilderData[1][1];
          const dimension = this.createMetricAbsoluteChangePayload(
            dimensionName,
            metricType,
            this.metadata?.metrics?.[dimensionName]?.metadata?.unit
          );
          returnValue.dimensionNameValueList.push({
            dimensionName: dimension,
            dimensionValue: value,
            operator: operator.operator
          });
        } catch (e) {
          console.error('e', e);
        }
      }
      return returnValue;
    },
    createDataRequestPayload(request, metric, dataGroup, filters = {}) {
      const requestCopy = cloneDeep(request);
      const sharePecentageKey = this.getSharePercentageKey(metric);
      const unsavedState = getWidgetUnsavedState(this);
      const unsavedSettings = this.getUnsavedSettings();
      const titleOptionSelected = selectedTitleOptions(this);
      const entityType = titleOptionSelected.value;
      requestCopy.entityType = entityType;
      if (
        requestCopy?.operations?.dynamicSource?.tables?.[0]?.columns?.[0]
          ?.columnName
      ) {
        requestCopy.operations.dynamicSource.tables[0].columns[0].columnName =
          entityType;
      }
      requestCopy.metricsList = this.filterArray(
        requestCopy.metricsList,
        filters.metrics
      );
      // requestCopy.metricsList = [metric];
      requestCopy.operations.orderByList = this.getOrderBy(
        sharePecentageKey,
        unsavedState.sortRuleBuilderData,
        dataGroup
      );
      const filterWhereClause = this.createFilterWhereClause(
        sharePecentageKey,
        unsavedState.ruleBuilderData,
        dataGroup
      );
      requestCopy.operations.filterWhereClause = filterWhereClause;
      requestCopy.operations.outerWhereClause = this.sovMetricsOuterWhereClause(
        unsavedState.sortRuleBuilderData,
        unsavedState.ruleBuilderData,
        dataGroup
      );
      const whereClause = this.getLocalWhereClause(dataGroup, unsavedSettings);
      requestDateReplacement(requestCopy.where, whereClause);
      requestTimeReplacement(requestCopy.where, whereClause);
      requestCopy.where.dimensionNameValueList = [
        ...whereClause.dimensionNameValueList,
        ...(metric === this.totalMultiCrawlKeywordsMetric
          ? request.where.dimensionNameValueList
          : [])
      ];
      return requestCopy;
    },
    getSharePercentageKey(metric) {
      return `${metric}_share_percentage`;
    }
  }
};
</script>

<style lang="css" scoped>
.legend-width {
  width: 70%;
}
.child {
  width: calc(33% - 18px);
}
.intermediate-dimensions {
  height: 566px;
}
.create-custom-shelf {
  flex-grow: 0;
  margin: 0 24px 0 0;
  padding: 5px 16px;
  border-radius: 100px;
  border: solid 1px #007cf6;
  background-color: #ffffff;
}
.chartDimensions {
  max-height: 140px !important;
  max-width: 140px !important;
}
.dimensions {
  min-width: 8px;
  min-height: 8px;
}
.grid-container {
  min-width: 200px;
  display: grid;
  grid-template-columns: 60% 20% 10% 10%;
}
.box-shadows {
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
.grid-container-chart {
  min-width: 200px;
  display: grid;
  grid-template-columns: 31% 31% 31%;
  grid-gap: 3%;
}
.margins-right:nth-child(3n) {
  margin-right: 0;
}
.padding-bottom {
  padding-bottom: 0;
}
.sort-icons {
  padding-right: 6px;
}
.dot {
  min-height: 4px;
  min-width: 4px;
  max-height: 4px;
  max-width: 4px;
  border-radius: 100%;
  margin-left: 8px;
  margin-right: 8px;
}

.sort-inversion {
  transform: rotate(180deg);
}
</style>
