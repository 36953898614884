<template>
  <div class="u-display-flex u-flex-direction-column">
    <div class="fit-content">
      <div
        class="heading--input u-font-size-5 u-font-weight-600"
        data-cy="ProfileTxt"
      >
        Profile
      </div>
      <div
        class="u-border-all u-position-relative u-border-radius-s u-border-width-s u-border-color-grey-xxx-light u-color-grey-base u-spacing-mt-s"
      >
        <loader
          v-if="profileLoader"
          class="fill--parent"
          :color="'#3fadf7'"
        />
        <rb-select
          class="typeSelect"
          :send-details="true"
          :on-close="onSelectProfileAction"
          :options="settingsProfileOptions"
          data-cy="selectProfile"
        >
          <div
            slot="trigger"
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
          >
            <div class="u-display-flex u-flex-align-items-center u-spacing-p-s">
              <div class="u-display-flex u-flex-direction-column">
                <span
                  class="u-font-size-7"
                  data-cy="selectedProfile"
                  >{{
                    (selectedProfile && selectedProfile.profile_name) || 'NA'
                  }}</span
                >
              </div>
            </div>
            <rb-icon
              class="rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
              :icon="'caret-down'"
              data-cy="caretIcon"
            />
          </div>
          <template
            slot="item"
            slot-scope="option"
          >
            <div
              class="u-display-flex u-flex-align-items-center u-font-size-7"
              data-cy="selctedProfileName"
            >
              {{ option.profile_name }}
            </div>
          </template>
        </rb-select>
      </div>
    </div>
    <div class="u-spacing-pt-xl fit-content">
      <div
        class="heading--input u-font-size-5 u-font-weight-600"
        data-cy="PortfolioTxt"
      >
        Portfolio:
        <span
          data-cy="optionalTxt"
          class="u-color-grey-mid-light"
          >Optional</span
        >
      </div>
      <div
        class="u-border-all u-position-relative u-border-radius-s u-border-width-s u-border-color-grey-xxx-light u-color-grey-base u-spacing-mt-s"
      >
        <!-- valueDropdown -->
        <loader
          v-if="portfolioLoader || profileLoader"
          class="fill--parent"
          :color="'#3fadf7'"
        />
        <rb-select
          class="typeSelect"
          :send-details="true"
          :on-close="onSelectPortfolioAction"
          :options="settingsPortfolioOptions"
          data-cy="selectPortfolio"
        >
          <div
            slot="trigger"
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
          >
            <div class="u-display-flex u-flex-align-items-center u-spacing-p-s">
              <div class="u-display-flex u-flex-direction-column">
                <span
                  class="u-font-size-7"
                  data-cy="selectedPortfolio"
                  >{{
                    (selectedPortfolio && selectedPortfolio.portfolio_name) ||
                    'NA'
                  }}</span
                >
              </div>
            </div>
            <rb-icon
              class="rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
              :icon="'caret-down'"
              data-cy="caretIconPortfolio"
            />
          </div>
          <template
            slot="item"
            slot-scope="option"
          >
            <div
              class="u-display-flex u-flex-align-items-center u-font-size-7"
              data-cy="selectedPortfolioname"
            >
              {{ option.portfolio_name }}
            </div>
          </template>
        </rb-select>
      </div>
    </div>
    <div class="u-spacing-pt-xl fit-content">
      <div
        class="heading--input u-font-size-5 u-font-weight-600"
        data-cy="dailyBudgetTxt"
      >
        Daily Budget
      </div>
      <div class="u-spacing-pt-s">
        <rbNumberInput
          :options="inputOptions"
          data-cy="enterBudget"
          @handleValue="handleInput"
          @handleInput="handleInput"
        />
      </div>
    </div>
  </div>
</template>
<script>
import rbNumberInput from '@/components/widgets/rbNumberInput';
import loader from '@/components/basic/loader';
import Vue from 'vue';
export default {
  components: {
    rbNumberInput,
    loader
  },
  props: {
    profileLoader: {
      type: Boolean,
      default: false
    },
    portfolioLoader: {
      type: Boolean,
      default: false
    },
    settingsProfileOptions: {
      type: Array,
      default: () => []
    },
    selectedProfile: {
      type: Object,
      default: () => {}
    },
    settingsPortfolioOptions: {
      type: Array,
      default: () => []
    },
    selectedPortfolio: {
      type: Object,
      default: () => {}
    },
    defaultBudget: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    dailyBudget: 1,
    inputOptions: {
      prefix: Vue.prototype.$currency,
      value: 1
    }
  }),
  created() {
    this.inputOptions.value = this.defaultBudget;
  },
  methods: {
    onSelectProfileAction(context, action) {
      if (!action.length) return;
      this.$emit('handleSelectedProfile', action[0]);
    },
    onSelectPortfolioAction(context, action) {
      if (!action.length) return;
      this.$emit('handleSelectedPortfolio', action[0]);
    },
    handleInput(value) {
      this.$emit('handleBudgetChange', value);
    }
  }
};
</script>
<style lang="css" scoped>
.select-dropdown {
  border: solid;
  border-radius: 4px;
  border-color: #e9eaeb;
  color: #e9eaeb;
}
.fit-content {
  width: fit-content;
  min-width: 20%;
}
.budget-container {
  border-radius: 4px;
}
.budget-input {
  border: solid;
  border-radius: 4px;
  border-color: #e9eaeb;
}
.rb-input {
  border: none !important;
}
</style>
