<template>
  <div
    class="u-display-flex u-flex-direction-row u-color-grey-base u-flex-align-items-center"
  >
    <span
      v-if="showExpandIcon"
      class="u-spacing-mr-s"
      @click="handleExpandClick"
    >
      <rb-icon
        v-if="expanded"
        class="rb-icon--medium"
        icon="arrow1-up"
      />
      <rb-icon
        v-else
        class="rb-icon--medium"
        icon="arrow-right"
      />
    </span>
    <p
      v-if="tooltip.tooltipText"
      v-tippy="tippy"
      :title="tooltip.tooltipText"
      class="u-color-grey-base"
      data-cy="categoryText"
      :class="categoryTextClass"
    >
      {{ categoryText }}
    </p>
    <p
      v-else
      v-tippy="tippy"
      class="u-color-grey-base"
      :title="categoryText"
    >
      {{ categoryText }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'CategoryCell',
  props: {
    tooltip: {
      type: Object,
      default: () => {}
    },
    categoryText: {
      type: String,
      default: () => ''
    },
    categoryTextClass: {
      type: String,
      default: () => ''
    },
    rowType: {
      type: String,
      default: () => ''
    },
    hasSubCategories: {
      type: Boolean,
      default: () => false
    },
    expandButtonClickFunction: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      tippy: {
        placement: 'bottom-start',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      expanded: false
    };
  },
  computed: {
    showExpandIcon() {
      return this.rowType === 'category' && this.hasSubCategories;
    }
  },
  methods: {
    handleExpandClick() {
      this.expanded = !this.expanded;
      this.expandButtonClickFunction(this.expanded);
    }
  }
};
</script>
