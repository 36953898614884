import Vue from 'vue';
const urlHelper = {};
// const localSettings = require('../../../local').localSettings;
urlHelper.getURLParams = (url) => {
  url = decodeURIComponent(url);
  var paramsMap = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    paramsMap[key] = value;
  });
  return paramsMap;
};

urlHelper.getURLParamValue = (url, paramKey) => {
  return urlHelper.getURLParams(url)[paramKey];
};

urlHelper.getPageHierarchy = (url) => {
  const pathsSectionOfUrl = url.split('?')[0];
  const urlPaths = pathsSectionOfUrl.split('/').filter((path) => {
    return path.length;
  });
  const pagesData = {};
  pagesData.path = url;
  pagesData.tab = urlPaths[0];
  pagesData.subTab = urlPaths[1] || '';
  pagesData.page = urlPaths[urlPaths.length - 1];
  // Support for SDP Page.
  if (pagesData.tab === 'dp') {
    pagesData.page = 'sdp';
  }
  if (urlHelper.getURLParamValue(url, 'name')) {
    pagesData.page = urlHelper.getURLParamValue(url, 'name');
  }
  return pagesData;
};

urlHelper.getClientInfoFromUrl = () => {
  // const hostname = localSettings.url.replace('https://', '') || window.location.host;
  const hostname = window.location.host;
  const hostComponents = hostname.split('.');
  const client = hostComponents[0];
  const env = hostComponents.length === 4 ? hostComponents[1] : 'prod';
  return {
    client,
    env
  };
};

urlHelper.convertNameToUrlSection = (name = '') => {
  const urlFriendlyPath = name.toLowerCase().trim().replaceAll(' ', '_');
  return urlFriendlyPath;
};

urlHelper.getHostName = (url) => {
  return new URL(url).hostname;
};

urlHelper.getChildPath = (url, rootPath) => {
  return url.replace(rootPath, '');
};

urlHelper.getDefaultHomeRoute = (config, defaultPath) => {
  const retainPreviousPath =
    JSON.parse(localStorage.getItem('retainPreviousPath')) || false;
  if (retainPreviousPath) {
    const previousPath = localStorage.getItem('previousPath');
    localStorage.removeItem('retainPreviousPath');
    localStorage.removeItem('previousPath');
    return previousPath || defaultPath;
  }
  const configPath = Vue.options.filters.config_check(config);
  if (configPath) {
    return configPath;
  }
  return defaultPath;
};

export default urlHelper;
