import Vue from 'vue';
import axios from 'axios';
import router from './index';
import redirectConfig from '@/router/redirectConfig.js';
import { retailRoutes } from '@/router/retail.js';
import { walmartRoutes } from '@/router/walmart.js';
import { freshRoutes } from '@/router/fresh.js';
import { targetRoutes } from '@/router/target.js';
import { instacartRoutes } from '@/router/instacartRoutes.js';
import { homedepotRoutes } from '@/router/homedepotRoutes.js';
import { wayfairRoutes } from '@/router/wayfairRoutes.js';
import { lowesRoutes } from '@/router/lowes.js';
import { samsclubRoutes } from '@/router/samsclub.js';
import { samsclub_apiRoutes } from '@/router/samsclub_api.js';
import { chewyRoutes } from '@/router/chewy.js';
import { krogerRoutes } from '@/router/kroger.js';
import { albertsonsRoutes } from '@/router/albertsons.js';
import { shiptRoutes } from '@/router/shipt.js';
import { shiptCriteoRoutes } from '@/router/shiptcriteo.js';
import { walmartCriteoRoutes } from '@/router/walmartcriteo.js';
import { hyveeRoutes } from '@/router/hyvee.js';
import { shopriteRoutes } from '@/router/shoprite.js';
import { allRoutes } from '@/router/all.js';
import { targetcitrusRoutes } from '@/router/targetcitrus.js';
import { aholdcitrusRoutes } from '@/router/aholdcitrus';
import { krogerPromoteIqRoutes } from '@/router/krogerpromoteiq';
import { amazonVariantRoutes } from '@/router/amazonVariants.js';
import { bestbuyRoutes } from '@/router/bestbuy.js';
import { getCriteoRetailerRoutes } from '@/router/criteoRetailersRoute.js';
import { chewyPromoteIqRoutes } from '@/router/chewypromoteiq';
import commonCitrusRoutes from '@/router/commonCitrusRoutes.js';
import Login from '@/components/login.vue';
import Register from '@/components/register.vue';
import ForgotPassword from '@/components/forgotPassword.vue';
import SetOrResetPassword from '@/components/setOrResetPassword.vue';
import NotFound from '@/pages/notFound';
import { omniRoutes } from '@/router/omni.js';
import { overstockRoutes } from '@/router/overstockRoutes.js';
import Router from 'vue-router';

const withPrefix = (prefix, routes) =>
  routes.map((route) => {
    route.redirect = prefix + route.redirect;
    return route;
  });

const redirectRoutes = (path) => {
  return {
    path: '/',
    children: [
      {
        path: '',
        redirect: path
      },
      ...withPrefix(path, redirectConfig)
    ]
  };
};

const createRouter = () =>
  new Router({
    mode: 'history',
    routes: [
      {
        path: '/login',
        name: 'login',
        component: Login
      },
      {
        path: '/register',
        name: 'register',
        component: Register
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword
      },
      {
        path: '/reset',
        name: 'reset-password',
        component: SetOrResetPassword,
        props: (route) => {
          return {
            id: route.query.id,
            action: 'resetPassword'
          };
        }
      },
      {
        path: '/welcome',
        name: 'set-password',
        component: SetOrResetPassword,
        props: (route) => {
          return {
            id: route.query.id,
            action: 'setPassword'
          };
        }
      },
      {
        path: '*',
        component: NotFound,
        name: '404'
      }
    ]
  });

export function resetRouter(path) {
  const newRouter = createRouter();
  router.replace(path);
  router.matcher = newRouter.matcher; // the relevant part
}

const retailerMap = {
  wayfairretail: wayfairRoutes,
  lowesretail: lowesRoutes,
  samsclubretail: samsclubRoutes,
  samsclub_apiretail: samsclub_apiRoutes,
  chewyretail: chewyRoutes,
  krogerretail: krogerRoutes,
  albertsons_citrusretail: albertsonsRoutes,
  shipt_citrusretail: shiptRoutes,
  shipt_criteoretail: shiptCriteoRoutes,
  walmart_criteoretail: walmartCriteoRoutes,
  hyvee_citrusretail: hyveeRoutes,
  shoprite_citrusretail: shopriteRoutes,
  costcoretail: getCriteoRetailerRoutes('costco'),
  freshdirect_criteoretail: getCriteoRetailerRoutes('freshdirect_criteo'),
  meijerretail: getCriteoRetailerRoutes('meijer'),
  target_citrusretail: targetcitrusRoutes,
  ahold_citrusretail: aholdcitrusRoutes,
  kroger_promoteiqretail: krogerPromoteIqRoutes,
  chewy_promoteiqretail: chewyPromoteIqRoutes,
  amazon_3pretail: amazonVariantRoutes('amazon_3p'),
  amazon_b2bretail: amazonVariantRoutes('amazon_b2b'),
  amazon_hybridretail: amazonVariantRoutes('amazon_hybrid'),
  bestbuyretail: bestbuyRoutes,
  overstockretail: overstockRoutes,
  sainsburys_citrusretail: commonCitrusRoutes('sainsburys'),
  waitrose_citrusretail: commonCitrusRoutes('waitrose'),
  tesco_citrusretail: commonCitrusRoutes('tesco'),
  gopuff_citrusretail: commonCitrusRoutes('gopuff'),
  walgreen_criteoretail: getCriteoRetailerRoutes('walgreen_criteo'),
  cvs_criteoretail: getCriteoRetailerRoutes('cvs_criteo'),
  asda_criteoretail: getCriteoRetailerRoutes('asda_criteo'),
  ulta_criteoretail: getCriteoRetailerRoutes('ulta_criteo'),
  albertsons_criteoretail: getCriteoRetailerRoutes('albertsons_criteo'),
  ubereats_criteoretail: getCriteoRetailerRoutes('ubereats_criteo'),
  target_rmsretail: getCriteoRetailerRoutes('target_rms')
};

export function updateBasePathForRoutes(routeList, retailerConfig) {
  if (
    retailerConfig.location &&
    retailerConfig.retailer &&
    retailerConfig.program
  ) {
    routeList.path = `/${retailerConfig.location}/${retailerConfig.retailer}/${retailerConfig.program}`;
  }
  return routeList;
}

function addRetailers({ retailer, path, retailerConfig }) {
  const route = retailerMap?.[retailer] || retailRoutes;
  resetRouter(path);
  router.addRoutes([redirectRoutes(path)]);
  router.addRoutes([updateBasePathForRoutes(route, retailerConfig)]);
}

export function addRetailerRoutes(path, retailerConfig) {
  retailRoutes.path = path;
  axios.defaults.headers.location = retailerConfig.location;
  axios.defaults.headers.retailer = retailerConfig.retailer;
  axios.defaults.headers.program = retailerConfig.program;
  /**
   * Issue with router having - extra trailing slash in the path cause the problem
   */
  if (path?.substring(path.length, path.length - 1) === '/') {
    path = path.substring(0, path.length - 1);
  }
  const retailerProgram = retailerConfig.retailer + retailerConfig.program;
  // Remove the other routes and add the specific
  if (
    retailerProgram === 'walmartretail' &&
    Vue.options.filters.config_check('feature.walmart')
  ) {
    resetRouter(path);
    router.addRoutes([redirectRoutes(path)]);
    router.addRoutes([updateBasePathForRoutes(walmartRoutes, retailerConfig)]);
  } else if (
    retailerProgram === 'amazonfresh' &&
    Vue.options.filters.config_check('feature.fresh')
  ) {
    resetRouter(path);
    router.addRoutes([redirectRoutes(path)]);
    router.addRoutes([updateBasePathForRoutes(freshRoutes, retailerConfig)]);
  } else if (
    retailerProgram === 'targetretail' &&
    Vue.options.filters.config_check('feature.target')
  ) {
    resetRouter(path);
    router.addRoutes([redirectRoutes(path)]);
    router.addRoutes([updateBasePathForRoutes(targetRoutes, retailerConfig)]);
  } else if (retailerProgram === 'instacartretail') {
    resetRouter(path);
    router.addRoutes([redirectRoutes(path)]);
    router.addRoutes([
      updateBasePathForRoutes(instacartRoutes, retailerConfig)
    ]);
  } else if (
    retailerProgram === 'homedepotretail' &&
    Vue.options.filters.config_check('feature.homedepot')
  ) {
    resetRouter(path);
    router.addRoutes([redirectRoutes(path)]);
    router.addRoutes([
      updateBasePathForRoutes(homedepotRoutes, retailerConfig)
    ]);
  } else if (retailerProgram === 'allretail') {
    const isProductDemoClient = Vue.options.filters.config_check(
      'feature.demo.product_demo.enable'
    );
    resetRouter(path);
    router.addRoutes([redirectRoutes(path)]);
    router.addRoutes([
      isProductDemoClient
        ? updateBasePathForRoutes(allRoutes, retailerConfig)
        : updateBasePathForRoutes(omniRoutes, retailerConfig)
    ]);
  } else {
    addRetailers({ retailer: retailerProgram, path, retailerConfig });
  }
}

export function navigate_to_sdp(asin) {
  const routeData = router.resolve({
    name: 'SkuDetails',
    params: { id: asin }
  });
  window.open(routeData.href, '_blank');
}

export const allowedRegion = [
  'us',
  'uk',
  'ca',
  'fr',
  'de',
  'in',
  'es',
  'it',
  'mx',
  'nl',
  'pl',
  'eu',
  'au'
];
