import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import { cloneDeep } from 'lodash';

const state = {
  overviewFilters: [],
  insightsActiveTabRoute: null,
  insightsActiveTab: 0,
  insightsOverviewData: {},
  insightsOverviewSelectedFilter: {
    date_range: {
      name: 'Last 90 days'
    }
  },
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  filtersMapping: {
    overview_brand: 'brand',
    overview_category: 'category',
    overview_subcategory: 'subcategory',
    overview_product_group: 'product_group'
  },
  page_wise_min_max_key: 'sales_by_account_type'
};

const getters = {
  getActiveInsightsTabRoute: (state) => {
    return state.insightsActiveTabRoute;
  },
  getActiveInsightsTabIndex: (state) => {
    return state.insightsActiveTab;
  },
  getInsightsOverviewSelectedFilter: (state) => {
    return state.insightsOverviewSelectedFilter;
  },
  getInsightsOverviewData: (state) => {
    return state.insightsOverviewData;
  },
  getOverviewPageWiseMinMaxKey: (state) => {
    return state.page_wise_min_max_key;
  }
};

const mutations = {
  SET_INSIGHTS_ACTIVE_TAB_ROUTE: (state, data) => {
    state.insightsActiveTabRoute = data;
  },
  SET_INSIGHTS_ACTIVE_TAB: (state, data) => {
    state.insightsActiveTab = data;
  },
  IO_SUCCESS: (state, data) => {
    Vue.set(state.insightsOverviewData, data.chartDataKey, {});
    Vue.set(
      state.insightsOverviewData[data.chartDataKey],
      'data',
      data.columns
    );
    Vue.set(state.insightsOverviewData[data.chartDataKey], 'load', false);
    if (data.columns.length === 0) {
      Vue.set(state.insightsOverviewData[data.chartDataKey], 'noData', true);
    }
    Vue.set(
      state.insightsOverviewData[data.chartDataKey],
      'header1',
      data.header1
    );
    Vue.set(
      state.insightsOverviewData[data.chartDataKey],
      'header2',
      data.header2
    );
  },
  IO_RESET: (state, data) => {
    Vue.set(state.insightsOverviewData, data.chartDataKey, {});
    Vue.set(state.insightsOverviewData[data.chartDataKey], 'data', []);
    Vue.set(state.insightsOverviewData[data.chartDataKey], 'load', true);
    Vue.set(state.insightsOverviewData[data.chartDataKey], 'error', false);
    Vue.set(state.insightsOverviewData[data.chartDataKey], 'noData', false);
    Vue.set(state.insightsOverviewData[data.chartDataKey], 'header1', null);
    Vue.set(state.insightsOverviewData[data.chartDataKey], 'header2', null);
  },
  IO_ERROR: (state, data) => {
    Vue.set(state.insightsOverviewData, data.chartDataKey, {});
    Vue.set(state.insightsOverviewData[data.chartDataKey], 'data', []);
    Vue.set(state.insightsOverviewData[data.chartDataKey], 'load', false);
    Vue.set(state.insightsOverviewData[data.chartDataKey], 'error', true);
    Vue.set(state.insightsOverviewData[data.chartDataKey], 'noData', false);
    Vue.set(state.insightsOverviewData[data.chartDataKey], 'header1', null);
    Vue.set(state.insightsOverviewData[data.chartDataKey], 'header2', null);
  },
  IO_SET_SELECTED_FILTER: (state, data) => {
    Vue.set(
      state.insightsOverviewSelectedFilter,
      data.filterValueKey,
      data.values
    );
    getFiltersInFormat1(data, state);
  }
};

function getFiltersInFormat1(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      for (var j = 0; j < oFilter.values[i].length; j++) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][j]
        });
      }
    } else {
      var date = oFilter.values[i];
      var _date = {
        from: date.from,
        to: date.to,
        name: date.name,
        page_wise_min_max_key: state.page_wise_min_max_key
      };
      var _pvpDate;
      if (date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to,
          compare_name: date.compare_name
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  state.whereClause = where;
}

const actions = {
  getRevenueData: (context, data) => {
    context.commit('IO_RESET', { chartDataKey: data.chartDataKey });
    const finalWhereClause = transformer.getCompleteWhereClause(
      [],
      cloneDeep(context.state.whereClause)
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.APIConfig.where = transformedWhereClause;
    data.APIConfig.where.date = context.state.whereClause.date;
    data.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    data.APIConfig.tagWhereClause = transformedWhereClause.tagWhereClause || [];
    delete data.APIConfig.where.tagWhereClause;
    if (data.searchFilter) {
      var temp = {
        dimensionNameValueList: Vue.util.extend([], data.searchFilter),
        date: context.state.whereClause.date,
        pvpDate: context.state.whereClause.pvpDate
      };
      data.APIConfig.where = Vue.util.extend({}, temp);
    }

    HttpLayer.post({
      APIData: data.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('IO_ERROR', { chartDataKey: data.chartDataKey });
      } else {
        context.commit('IO_SUCCESS', {
          chartDataKey: data.chartDataKey,
          columns: transformer.getChartDataInFormat(
            data,
            response.data[0].TIMESERIES
          ),
          header1: data.getHeader1Data
            ? data.getHeader1Data(response.data[0])
            : undefined,
          header2: data.getHeader2Data
            ? data.getHeader2Data(response.data[0])
            : undefined
        });
      }
    });
  },
  getMarginData: (context, data) => {
    context.commit('IO_RESET', { chartDataKey: data.chartDataKey });
    const finalWhereClause = transformer.getCompleteWhereClause(
      [],
      cloneDeep(context.state.whereClause)
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.APIConfig.where = transformedWhereClause;
    data.APIConfig.where.date = context.state.whereClause.date;
    data.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    data.APIConfig.tagWhereClause = transformedWhereClause.tagWhereClause || [];
    delete data.APIConfig.where.tagWhereClause;

    if (data.searchFilter) {
      var temp = {
        dimensionNameValueList: Vue.util.extend([], data.searchFilter),
        date: context.state.whereClause.date,
        pvpDate: context.state.whereClause.pvpDate
      };
      data.APIConfig.where = Vue.util.extend({}, temp);
    }

    HttpLayer.post({
      APIData: data.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('IO_ERROR', { chartDataKey: data.chartDataKey });
      } else {
        context.commit('IO_SUCCESS', {
          chartDataKey: data.chartDataKey,
          columns: transformer.getChartDataInFormat(
            data,
            response.data[0].TIMESERIES
          ),
          header1: data.getHeader1Data
            ? data.getHeader1Data(response.data[0])
            : undefined,
          header2: data.getHeader2Data
            ? data.getHeader2Data(response.data[0])
            : undefined
        });
      }
    });
  },
  getOOSData: (context, data) => {
    context.commit('IO_RESET', {
      chartDataKey: data.chartDataKey
    });
    const finalWhereClause = transformer.getCompleteWhereClause(
      [],
      cloneDeep(context.state.whereClause)
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.APIConfig.where = transformedWhereClause;
    data.APIConfig.where.date = context.state.whereClause.date;
    data.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    data.APIConfig.tagWhereClause = transformedWhereClause.tagWhereClause || [];
    delete data.APIConfig.where.tagWhereClause;

    if (data.searchFilter) {
      var temp = {
        dimensionNameValueList: Vue.util.extend([], data.searchFilter),
        date: context.state.whereClause.date,
        pvpDate: context.state.whereClause.pvpDate
      };
      data.APIConfig.where = Vue.util.extend({}, temp);
    }

    if (data.APIConfig.where?.dimensionNameValueList) {
      data.APIConfig.where.dimensionNameValueList.push({
        dimensionName: 'rep_oos',
        dimensionValue: '0',
        operator: 'GREATER_THAN'
      });
    }

    HttpLayer.post({
      APIData: data.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('IO_ERROR', { chartDataKey: data.chartDataKey });
      } else {
        context.commit('IO_SUCCESS', {
          chartDataKey: data.chartDataKey,
          columns: transformer.getChartDataInFormat(
            data,
            response.data[0].TIMESERIES
          ),
          header1: data.getHeader1Data
            ? data.getHeader1Data(response.data[0])
            : undefined,
          header2: data.getHeader2Data
            ? data.getHeader2Data(response.data[0])
            : undefined
        });
      }
    });
  },
  setInsightsOverviewSelectedFilter: (context, data) => {
    context.commit('IO_SET_SELECTED_FILTER', data);
  },
  setInsightsActiveTab: (context, data) => {
    if (data.constructor === Number) {
      context.commit('SET_INSIGHTS_ACTIVE_TAB', data);
    } else {
      context.commit('SET_INSIGHTS_ACTIVE_TAB', data.index);
      context.commit('SET_INSIGHTS_ACTIVE_TAB_ROUTE', data.route);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
