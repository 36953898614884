<template>
  <section
    class="campaign-creation-setup u-position-fixed widget--full-screen"
    @click.stop="removeFocus"
  >
    <div class="u-position-fixed model u-width-100 campaign-creation-setup">
      <!-- Header Start -->
      <Header
        v-model="title"
        :is-edit-visible="isEditVisible"
        :show-launch-btn="showLaunchBtn"
        :back-button-icon="isConfiguringCampaign ? 'cross' : 'right-arrow'"
        :error-message="headerErrorMessage"
        data-cy="placeholderName"
        :placeholder="
          isConfiguringCampaign ? 'Untitled Campaign' : 'Untitled Ad Group'
        "
        @handleChangeIsEditVisible="handleChangeIsEditVisible"
        @handleGoBack="handleGoBack"
        @setHeaderRef="setHeaderRef"
      />
      <!-- Header ends -->
      <!-- Scroll section -->
      <StepComponent
        :config="stepsConfig"
        :panel-width="10"
        :all-steps-data="stepsData"
        :show-step-bullet-description="true"
        bullet-point-class="u-spacing-pt-m"
        :all-bullet-points-class="`${
          overrideBulletClass ? 'override-bullet-points-wrapper-class' : ''
        } bullet-points-wrapper-class`"
        class="u-width-100"
        section-right-class="u-display-flex u-flex-direction-column u-height-100"
        :enabled-bread-crumbs="enabledSteps"
        @createAdGroup="createAdGroup"
        @vueRef="setStepComponentRef"
      />
    </div>
  </section>
</template>

<script>
import StepComponent from '@/components/widgets/stepComponentV2.vue';
import Header from '@/components/pages/instacart/campaign-creation/components/campaign-creation-header.vue';
import {
  STEPS_CONFIG,
  STEPS_DATA_GETTER,
  STEPS_DATA_SETTER,
  OPTIMIZED_AD_GROUPS_STEPS,
  MANUAL_AD_GROUPS_STEPS,
  BID_TYPES,
  CAMPAIGN_NAME_EXISTS_CHECK,
  CAMPAIGN_DETAILS_GETTER
} from '@/components/pages/instacart/campaign-creation/config';
import utils from '@/utils/helpers/';

export default {
  components: {
    StepComponent,
    Header
  },
  data() {
    return {
      stepsConfig: STEPS_CONFIG,
      title: '',
      isEditVisible: true,
      stepComponentRef: {},
      showAdGroupSteps: false,
      isConfiguringCampaign: true,
      headerRef: {}
    };
  },
  computed: {
    stepsData() {
      return this.$store.getters[STEPS_DATA_GETTER];
    },
    enabledSteps() {
      if (this.isConfiguringCampaign) {
        return this.stepsData.enableSettings ? [0, 1, 2] : [0];
      }

      return [0];
    },
    showLaunchBtn() {
      return (
        this.stepComponentRef.activeSection === 2 && this.isConfiguringCampaign
      );
    },
    overrideBulletClass() {
      const {
        steps: { settings, adGroups },
        tempAdGroup,
        campaignEditFlow
      } = this.stepsData;
      const editingGroup =
        adGroups.findIndex(({ id }) => id === tempAdGroup?.id) > -1;
      return (
        !campaignEditFlow &&
        !this.isConfiguringCampaign &&
        editingGroup &&
        (!tempAdGroup?.defaultBid ||
          tempAdGroup?.defaultBid > settings.dailyBudget)
      );
    },
    headerErrorMessage() {
      if (
        this.stepsConfig.length - 1 === this.stepComponentRef.activeSection &&
        !this.title
      ) {
        if (this.isConfiguringCampaign) {
          return 'Enter a campaign name';
        }

        return 'Enter an ad group name';
      }

      if (this.title) {
        const characterLimit = this.isConfiguringCampaign ? 128 : 255;
        if (this.title.length > characterLimit) {
          return this.isConfiguringCampaign
            ? 'The campaign name should be within 128 characters'
            : 'The ad group name should be within 255 characters';
        }

        if (!this.isConfiguringCampaign) {
          const { tempAdGroup } = this.stepsData;
          const groupIndex = this.stepsData.steps.adGroups.findIndex(
            ({ adGroupName, id }) =>
              adGroupName === this.title && id !== tempAdGroup.id
          );
          return groupIndex > -1 ? 'Enter a unique ad group name' : '';
        }
      }

      return '';
    }
  },
  watch: {
    title(newValue) {
      const stepsData = { ...this.stepsData };
      if (this.isConfiguringCampaign) {
        stepsData.campaignName = newValue;
      } else {
        stepsData.tempAdGroup.adGroupName = newValue;
      }
      this.$store.dispatch(STEPS_DATA_SETTER, stepsData);
    },
    isConfiguringCampaign(newValue) {
      const { campaignName, tempAdGroup } = this.stepsData;
      if (newValue) {
        this.title = campaignName;
      } else if (tempAdGroup.adGroupName) {
        this.title = tempAdGroup.adGroupName;
      } else {
        this.title = '';
      }
    },
    headerErrorMessage(newValue) {
      const stepsData = { ...this.stepsData };
      stepsData.headerError = newValue;
      this.$store.dispatch(STEPS_DATA_SETTER, stepsData);
    }
  },
  created() {
    const stepsData = { ...this.stepsData };
    stepsData.campaignEditFlow = window.location.href
      .toLowerCase()
      .includes('create_ad_group'.toLowerCase());
    this.$store.dispatch(STEPS_DATA_SETTER, stepsData);
    if (stepsData.campaignEditFlow) {
      this.isConfiguringCampaign = false;
      this.stepsConfig = this.$store.getters[CAMPAIGN_DETAILS_GETTER]?.data
        ?.optimized_bidding_enabled
        ? OPTIMIZED_AD_GROUPS_STEPS
        : MANUAL_AD_GROUPS_STEPS;
    }
  },
  methods: {
    removeFocus() {
      if (this.isConfiguringCampaign && !this.isEditVisible && this.title) {
        this.$store.dispatch(CAMPAIGN_NAME_EXISTS_CHECK, {
          campaignName: this.title
        });
      }
      this.headerRef.$refs?.['campaign-creation-input']?.blur();
      this.isEditVisible = true;
    },
    handleChangeIsEditVisible(isVisible) {
      this.isEditVisible = isVisible;
    },
    updateStepsConfig(showCampaignStep, jumpToStep) {
      this.stepComponentRef.activeSection = jumpToStep;
      if (
        [BID_TYPES.max_sales.type, BID_TYPES.acquire_ntb.type].includes(
          this.stepsData.steps.settings.bidType
        )
      ) {
        this.stepsConfig = showCampaignStep
          ? STEPS_CONFIG
          : OPTIMIZED_AD_GROUPS_STEPS;
      } else {
        this.stepsConfig = showCampaignStep
          ? STEPS_CONFIG
          : MANUAL_AD_GROUPS_STEPS;
      }
    },
    createAdGroup(event, jumpToStep) {
      const isSubmitting = event === 'submitting';
      const isExiting = event === 'exiting';
      const isEditing = event === 'editing';
      const isCreating = event === 'creating';
      if (isSubmitting || isExiting) {
        this.isConfiguringCampaign = !this.stepsData.campaignEditFlow;
      }

      if (isCreating || isEditing) {
        this.isConfiguringCampaign = false;
      }
      this.updateStepsConfig(isSubmitting || isExiting, jumpToStep);
    },
    setStepComponentRef(ref) {
      this.stepComponentRef = ref;
    },
    setHeaderRef(ref) {
      this.headerRef = ref;
    },
    handleGoBack() {
      if (this.stepsData?.campaignEditFlow) {
        this.$router.push({ name: 'InstacartEntityDetails' });
      } else if (this.isConfiguringCampaign) {
        this.$router.push({ name: 'Campaigns' });
      } else {
        const stepsData = { ...this.stepsData };
        stepsData.tempAdGroup = {};
        this.isConfiguringCampaign = !this.stepsData?.campaignEditFlow;
        this.$store.dispatch(STEPS_DATA_SETTER, stepsData);
        this.updateStepsConfig(true, 2);
      }
    }
  }
};
</script>

<style lang="css">
.campaign-creation-setup {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 200;
  left: 0px;
  background-color: #ffffff;
}
.campaign-creation-setup .u-width-max-content {
  width: max-content;
}
.campaign-creation-setup .step-component-v2 .scrollable {
  padding-top: 0px;
  height: calc(100vh - 84px);
}
.campaign-creation-setup .step-component-v2 .bullet-points-wrapper-class {
  margin-top: 80px;
  padding-left: 8px;
}
.campaign-creation-setup
  .step-component-v2
  .override-bullet-points-wrapper-class
  > div:nth-child(2)
  span {
  color: #ffa800;
}
</style>
