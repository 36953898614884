var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-font-size-6 u-spacing-pb-xs u-color-grey-lighter u-spacing-ph-m u-spacing-mt-xs",class:{
    'u-spacing-pt-l': !(
      _vm.metric.key === 'Planned Budget' || _vm.metric.key === 'Ad Spend'
    )
  }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.metric.key === 'Planned Budget' || _vm.metric.key === 'Ad Spend'),expression:"metric.key === 'Planned Budget' || metric.key === 'Ad Spend'"}],staticClass:"u-font-size-6 u-color-grey-lighter u-spacing-pt-m u-spacing-pb-xs"},[_vm._v(" Cumulative ")]),_c('div',{staticClass:"u-font-size-4 u-spacing-pb-m",style:({ 'font-color': '#627178' })},[_vm._v(" "+_vm._s(_vm.metric.key)+" ")]),_c('div',[(_vm.metric.metricData)?_c('metric',{staticClass:"u-display-inline-flex",class:[
        _vm.metric.key === 'Planned Budget' || _vm.metric.key === 'Ad Spend'
          ? 'u-spacing-pb-m'
          : 'u-spacing-pb-l'
      ],attrs:{"size":'l',"config":_vm.metric.metricConfig || {},"data":_vm.metric.metricData || {}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }