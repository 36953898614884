import MetadataService from './MetadataService';
import HttpService from '@/utils/services/http-service';
export default class MetricTilesWidgetService {
  page = null;
  widget = null;
  metadata = null;
  pageId = null;
  globalViewId = null;
  data = null;
  loading = false;
  error = false;
  readApiConfig = null;
  formattedMetricsData = {};
  constructor(widgetConfig) {
    this.globalViewId = widgetConfig.globalViewId;
    this.pageId = widgetConfig.pageId;
    this.readApiConfig = widgetConfig.api;
    this.widget = this.readApiConfig.request.widget;
    this.page = this.readApiConfig.request.page;
    this.metadata = new MetadataService({
      globalViewId: this.globalViewId,
      pageId: this.pageId,
      api: this.readApiConfig
    });
  }

  prepareDataRequests() {
    const dataGroupsMap = Object.values(this.metadata.data.metrics).reduce(
      (acc, metricData) => {
        if (!acc[metricData.dataGroup]) {
          acc[metricData.dataGroup] = [];
          acc[metricData.dataGroup].push(metricData);
        } else {
          acc[metricData.dataGroup].push(metricData);
        }
        return acc;
      },
      {}
    );
    const requests = Object.entries(dataGroupsMap).map(
      ([dataGroup, metricData]) => {
        const { api } = metricData[0];
        const { request } = api;
        request.metricsList = [];
        metricData.forEach((metric) => {
          request.metricsList.push(metric.name);
        });
        request.operations.orderByList = [];
        request.page = this.page;
        request.widget = this.widget;
        request.where.dimensionNameValueList = [];
        request.where.date = {
          from: '2022-10-06',
          to: '2022-12-03',
          name: 'lastWeek'
        };
        request.where.pvpDate = {
          from: '2022-11-20',
          to: '2022-11-26',
          compare_name: null
        };
        return HttpService.post(
          api.service,
          { ...request },
          { append: api.endPoint }
        );
      }
    );
    return requests;
  }

  async fetchData() {
    try {
      this.loading = true;
      const requests = this.prepareDataRequests();
      const responses = await Promise.allSettled(requests);
      this.data = responses
        .filter((response) => response.status === 'fulfilled')
        .map((response) => response.value.data.entityData);
      this.formatMetricsData();
    } catch (err) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  formatMetricsData() {
    let tempMetricData = {};
    this.data?.forEach((entityData) => {
      const metricData = entityData.map((dataEl) => {
        return dataEl.data.reduce((prevVal, curVal) => {
          const unit =
            this.metadata.data.metrics?.[curVal.alias]?.metadata?.unit;
          const pvpPrefix =
            unit?.toUpperCase() === 'CURRENCY' ? 'PVP_' : 'PVP_DIFF_';
          const pvpValue = curVal?.PVP?.[pvpPrefix + curVal.name];
          prevVal[curVal.alias] = {
            label: this.metadata.data.metrics?.[curVal.alias]?.label
          };
          prevVal[curVal.alias].metricData = {
            data: {
              value: curVal?.RESULT[curVal.name],
              unit
            },
            pvp: pvpValue
          };
          // Computing chart data here
          prevVal[curVal.alias].timeSeries = [
            pvpValue > 0 ? 'positive' : 'negative'
          ];
          curVal.TIMESERIES.forEach((data) =>
            prevVal[curVal.alias].timeSeries.push(data[curVal.name])
          );
          return prevVal;
        }, {});
      });
      tempMetricData = { ...tempMetricData, ...(metricData?.[0] || {}) };
    });
    this.formattedMetricsData = tempMetricData;
  }
}
