var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tagsContainer",staticClass:"u-display-flex u-flex-align-items-space-between u-width-100 u-height-100 u-flex-wrap"},[_vm._l((_vm.tagsList),function(item,index){return _c('rb-select',{key:index,ref:"activeTags",refInFor:true,attrs:{"send-details":true,"context":item,"on-close":_vm.handleDropdownOnClose,"options":_vm.dropdownOptions},on:{"active-change":function (data) {
        _vm.handleActiveChange(data, index);
      }}},[_c('span',{attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{ref:("activeTags_" + index),refInFor:true}),_c('tag',{staticClass:"u-cursor-pointer",attrs:{"title":item.title,"enable-style-api":_vm.enableStyleApi,"tippy-title":item.tooltip ? item.tooltip : item.title},on:{"mouseEnter":function (data) {
            _vm.handleMouseEnter(data, index);
          },"mouseLeave":function (data) {
            _vm.handleMouseLeave(data, index);
          },"tagClicked":function (data) {
            _vm.handleTagClicked(data, index, item);
          }}})],1)])}),_c('div',{ref:"width-limiter",staticClass:"u-position-absolute positioning u-bg-color-grey-white u-font-weight-600",class:[
      _vm.hiddenTagsCount ? 'u-visibility-visible' : 'u-visibility-hidden'
    ]},[_c('span',{staticClass:"u-spacing-pr-xs"},[_vm._v("&")]),_c('span',{staticClass:"u-color-blue-base"},[_vm._v(_vm._s(_vm.computedOverflowText))])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }