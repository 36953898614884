import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import HttpService from '@/utils/services/http-service';
import transformer from '@/utils/services/data-transformer';
import moment from 'moment';
import { addRetailerRoutes, allowedRegion } from '@/router/dynamicRoutes.js';
import { filters } from '@ciq-dev/ciq-ui';
import { EntitiesForPostProcessingStatus } from '@/pages/toolsAndSettings/const.js';
import CONSTANTS from '@/utils/constants.js';
import { eventBus } from '@/utils/services/eventBus';
import { isCriteoRetailer } from '@/router/utils';

var pages = [
  'predictions',
  'pi',
  'alert_estimate_dashboard',
  'sales',
  'sales_by_account_type',
  'price_war',
  'po_fill_rate',
  'sri',
  'review_ratings',
  'catalog',
  'v2_forecast'
];
var defaultDateRanges = {};

for (var p = 0; p < pages.length; p++) {
  defaultDateRanges[pages[p]] = {
    'Last 7 days': getRange(7, null, pages[p]),
    'Last 30 days': getRange(30, null, pages[p]),
    'Last 90 days': getRange(90, null, pages[p])
  };
}

const state = {
  ingestionPostProcessing: {
    status: {
      [EntitiesForPostProcessingStatus.catalog]: false,
      'digital-shelves': false,
      campaign: false,
      [EntitiesForPostProcessingStatus.campaign]: false
    },
    lastUpdated: {
      [EntitiesForPostProcessingStatus.catalog]: false,
      'digital-shelves': false,
      campaign: false,
      [EntitiesForPostProcessingStatus.campaign]: false
    }
  },
  ingestPostProcessingStatus: {
    [EntitiesForPostProcessingStatus.catalog]: false,
    'digital-shelves': false,
    campaign: false,
    [EntitiesForPostProcessingStatus.campaign]: false
  },
  hotMetricsRefreshDates: {},
  appSecondaryActiveTab: 0,
  marketPlace: 'Amazon',
  outsideIn: true,
  dwFilters: [],
  today: getSingleDateInFormat(new Date()),
  featureEnableConfig: {},
  appActiveTab: 0,
  maxDate: null,
  globalFilterValues: {
    sales_channel: [{ title: '.com' }, { title: 'Pantry' }, { title: 'Fresh' }],
    date_range: [
      { title: 'Last 7 days' },
      { title: 'Last 30 days' },
      { title: 'Last 90 days' }
    ]
  },
  dateRanges: defaultDateRanges,
  avcotp: null,
  userInviteLoading: false,
  userInviteResponse: null,
  showFeedback: false,
  waitingForScreenShot: false,
  showWelcome: true,
  existingUser: [],
  // rootPath: '/us/amazon/retail/',
  rootPath: '',
  retailerConfig: {
    location: '',
    retailer: '',
    program: ''
  },
  asinDistributionTypeMap: null,
  recentDownloads: {
    loading: false,
    data: []
  },
  urlComponents: [],
  currencyExchangeList: {
    loading: false,
    data: []
  },
  selectedCurrency: {},
  migratingClientsData: {
    loading: false,
    dataFetched: false,
    data: []
  },
  migrateStatus: {
    loading: false,
    dataFetched: false,
    data: null
  }
};

Object.defineProperty(String.prototype, 'capitalize', {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

const getters = {
  showWelcome: (state) => {
    return state.showWelcome;
  },
  getMarketPlace: (state) => {
    return state.marketPlace;
  },
  getOutsideIn: (state) => {
    return state.outsideIn;
  },
  getFeatureEnableConfig: (state) => {
    return state.featureEnableConfig;
  },
  getMaxDate: (state) => {
    return state.maxDate;
  },
  getTodaysDate: (state) => {
    return state.today;
  },
  getActiveAppTabIndex: (state) => {
    return state.appActiveTab;
  },
  getGlobalFilterValues: (state) => {
    return state.globalFilterValues;
  },
  getDateRangeValues: (state) => {
    return JSON.parse(JSON.stringify(state.dateRanges));
  },
  getAVCTOTP: (state) => {
    return state.avcotp;
  },
  getuserInviteLoading: (state) => {
    return state.userInviteLoading;
  },
  getUserInviteResponse: (state) => {
    return state.userInviteResponse;
  },
  getShowFeedback: (state) => {
    return state.showFeedback;
  },
  getWaitingForScreenShot: (state) => {
    return state.waitingForScreenShot;
  },
  getAppSecondaryActiveTab: (state) => {
    return state.appSecondaryActiveTab;
  },
  getExistingUsers: (state) => {
    return state.existingUser;
  },
  getRootPath: (state) => {
    return state.rootPath;
  },
  getHotMetricRefreshDates: (state) => (pageId) => {
    return state.hotMetricsRefreshDates[pageId] || [];
  },
  getLocation: (state) => {
    return state.retailerConfig.location;
  },
  getProgram: (state) => {
    return state.retailerConfig.program;
  },
  getRetailer: (state) => {
    return state.retailerConfig.retailer;
  },
  getRetailerConfig: (state) => {
    return state.retailerConfig;
  },
  getRetailProgramConfig: (state) => {
    return `${state.retailerConfig.retailer}${state.retailerConfig.program}`;
  },
  getEntityPostProcessingStatus: (state) => (entityArray) => {
    for (const i of entityArray) {
      if (state.ingestionPostProcessing.status[i]) {
        return true;
      }
    }
    return false;
  },
  getEntityLastUpdatedTime: (state) => (entityName) => {
    return state.ingestionPostProcessing.lastUpdated[entityName];
  },
  getAsinDistributionTypeMap: (state) => {
    return state.asinDistributionTypeMap;
  },
  getRecentDownloads: (state) => {
    return state.recentDownloads;
  },
  getUrlComponents: (state) => {
    return state.urlComponents;
  },
  getCurrencyExchangeList: (state) => {
    return state.currencyExchangeList;
  },
  getSelectedCurrency: (state) => {
    return state.selectedCurrency;
  },
  getMigratingClientsData: (state) => {
    return state.migratingClientsData;
  },
  getMigrateStatus: (state) => {
    return state.migrateStatus;
  }
};

const mutations = {
  SET_INGESTION_POST_PROCESS_STATUS: (state, data) => {
    const { entity, status, time } = data;
    Vue.set(state.ingestPostProcessingStatus, entity, status);
    Vue.set(state.ingestionPostProcessing.status, entity, status);
    Vue.set(state.ingestionPostProcessing.lastUpdated, entity, time);
  },
  SET_HOT_REFRESH_METRICS_DATES: (state, refreshPayload) => {
    const refreshTransformedState = {};
    Object.keys(refreshPayload).forEach((refreshPageId) => {
      const dateMetricMap = [];
      const pageRefreshDates = refreshPayload[refreshPageId];
      Object.keys(pageRefreshDates).forEach((dateString) => {
        dateMetricMap.push({
          date: new Date(dateString),
          metrics: pageRefreshDates[dateString]
        });
      });
      refreshTransformedState[refreshPageId] = dateMetricMap;
    });
    Vue.set(state, 'hotMetricsRefreshDates', refreshTransformedState);
  },
  SHOW_WELCOME: (state, data) => {
    state.showWelcome = data;
  },
  SET_MARKETPLACE_DATA: (state, data) => {
    state.marketPlace = data.marketPlace;
    state.outsideIn = data.outsideIn;
  },
  SET_FEATURE_ENABLE_CONFIG: (state, data) => {
    state.featureEnableConfig = data;
  },
  SET_DW_FILTER_DATA: (state, data) => {
    for (var i in data) {
      Vue.set(state.dwFilters, i, [].concat(data[i]));
    }
  },
  SET_FILTER_DATA: (state, data) => {
    for (var i in data) {
      Vue.set(state.globalFilterValues, i, Object.freeze([].concat(data[i])));
    }
  },
  SET_APP_ACTIVE_TAB: (state, data) => {
    state.appActiveTab = data;
  },
  SET_APP_SECONDARY_ACTIVE_TAB: (state, data) => {
    state.appSecondaryActiveTab = data;
  },
  SET_MAX_DATE: (state, data) => {
    if (!state.maxDate) {
      state.maxDate = {};
    }
    for (var i in data) {
      state.dateRanges[i] = {
        'Last 7 days': getRange(7, data, i),
        'Last 30 days': getRange(30, data, i),
        'Last 90 days': getRange(90, data, i)
      };
    }

    for (i in state.dateRanges) {
      if (!data[i]) {
        state.maxDate[i] = {
          max_feed_date: getSingleDateInDisplayFormat(new Date())
        };
      } else {
        state.maxDate[i] = data[i];
      }
    }
  },
  OTP_FETCHED: (state, data) => {
    state.avcotp = data;
  },
  resetAVCTOTP: (state) => {
    state.avcotp = null;
  },
  USER_INVITE_LOADING: (state) => {
    state.userInviteLoading = true;
  },
  USERS_INVITED: (state, data) => {
    state.userInviteLoading = false;
    state.userInviteResponse = data;
  },
  SHOW_FEEDBACK_OPTIONS: (state) => {
    state.waitingForScreenShot = false;
    state.showFeedback = true;
  },
  CLOSE_FEEDBACK: (state) => {
    state.showFeedback = false;
  },
  WAITING_FOR_SCREENSHOT: (state) => {
    state.waitingForScreenShot = true;
  },
  EXISTING_USER: (state, users) => {
    state.existingUser = users;
  },
  USERS_UPDATED: (state) => {
    state.userInviteLoading = false;
  },
  SET_ROOT_PATH: (state, { path, retailerConfig }) => {
    state.rootPath = path;
    state.retailerConfig = { ...retailerConfig };
  },
  SET_ASIN_DISTRIBUTION_TYPE_MAPPING: (state, data) => {
    state.asinDistributionTypeMap = data;
  },
  SET_RECENT_DOWNLOADS: (state, data) => {
    state.recentDownloads = data;
  },
  SET_URL_COMPONENTS: (state, data) => {
    state.urlComponents = data;
  },
  SET_CURRENCY_EXCHANGE_LIST: (state, data) => {
    state.currencyExchangeList = data;
  },
  SET_SELECTED_CURRENCY: (state, data) => {
    state.selectedCurrency = data;
  },
  SET_MIGRATING_CLIENTS_DATA: (state, data) => {
    state.migratingClientsData = data;
  },
  SET_MIGRATE_STATUS: (state, data) => {
    state.migrateStatus = data;
  }
};

function getSingleDateInDisplayFormat(__date) {
  var _date = new Date(__date);
  var year = _date.getFullYear();
  var month = '0' + (_date.getMonth() + 1);
  var date = '0' + _date.getDate();
  return [
    month.substr(month.length - 2, month.length),
    date.substr(date.length - 2, date.length),
    year
  ].join('/');
}

function getSingleDateInFormat(__date) {
  var _date = new Date(__date);
  var year = _date.getFullYear();
  var month = '0' + (_date.getMonth() + 1);
  var date = '0' + _date.getDate();
  return [
    year,
    month.substr(month.length - 2, month.length),
    date.substr(date.length - 2, date.length)
  ].join('-');
}

function getDateInFormat(offset, maxDate, page) {
  if (!maxDate) {
    maxDate = {};
    maxDate[page] = {
      max_feed_date: null
    };
  }
  var _maxDate = maxDate[page].max_feed_date;
  var d = new Date();

  if (_maxDate !== null) {
    d = new Date(_maxDate);
  }

  d.setDate(d.getDate() - offset);
  var year = d.getFullYear();
  var month = '0' + (d.getMonth() + 1);
  var date = '0' + d.getDate();

  return [
    year,
    month.substr(month.length - 2, month.length),
    date.substr(date.length - 2, date.length)
  ].join('-');
}

function getDateRangeName(offset) {
  return `last${offset}Days`;
}

function getRange(offset, maxDate, page) {
  return {
    from: getDateInFormat(offset, maxDate, page),
    to: getDateInFormat(0, maxDate, page),
    name: getDateRangeName(offset)
  };
}

// Asin - Distribution type map to reduce search complexity.
function getAsinTypeMapping(catalogAsins) {
  const asinDistributionTypeMap = new Map();
  for (const asinItem of catalogAsins) {
    asinDistributionTypeMap.set(asinItem.asin, asinItem.distribution_type);
  }
  return asinDistributionTypeMap;
}

var postParams = {
  cubeName: 'client_workbench_data',
  measuresList: [],
  groupByDimensionsList: ['CATEGORY', 'BRAND', 'SUBCATEGORY', 'PRODUCT_GROUP'],
  timeseriesDimension: 'FEED_DATE',
  where: {
    date: {
      from: '2017-01-01',
      to: '2018-03-05'
    },
    dimensionNameValueList: []
  },
  timeseriesEnabled: false,
  pvpenabled: false,
  yoyenabled: false
};

const getCubeName = (context) => {
  const retailer = context.getters?.getRetailer;
  if (!retailer) {
    return null;
  }
  if (isCriteoRetailer(retailer)) {
    return 'criteo_common_page_wise_min_max_feed_dates';
  }
  const retailerMap = {
    default: 'page_wise_min_max_feed_dates',
    instacart: 'instacart_page_wise_min_max_feed_dates',
    walmart: 'walmart_page_wise_min_max_feed_dates',
    samsclub_api: 'samsclub_api_page_wise_min_max_feed_dates'
  };
  return retailerMap[retailer] || 'page_wise_min_max_feed_dates';
};

const validateClientName = (currentConfig) => {
  const savedClientName = localStorage.getItem('clientName');

  if (!savedClientName) {
    return currentConfig;
  }

  try {
    const accountV2Config = JSON.parse(
      Vue.options.filters.config_check('feature.accountMapping.config')
    );

    const matchingRegion = accountV2Config.find(
      (item) => item.clientName === savedClientName
    );

    if (matchingRegion?.region) {
      currentConfig[0] = matchingRegion.region;
    }

    accountV2Config.forEach((item) => {
      item.retailers.forEach((retailer) => {
        if (retailer.account) {
          const isAccountMatching = retailer.account.find(
            (account) => account.clientName === savedClientName
          );

          const isRetailerMatching =
            retailer.key === currentConfig[1] + currentConfig[2];

          if (isAccountMatching && isRetailerMatching) {
            const retailerConfig = CONSTANTS.retailerMapping[retailer.key];
            const routeParts = retailerConfig.route.split('/');

            currentConfig[0] = item.region;
            currentConfig[1] = routeParts[0];
            currentConfig[2] = routeParts[1];
          }
        }
      });
    });

    return currentConfig;
  } catch (error) {
    return currentConfig;
  }
};

const actions = {
  fetchPostProcessingStatus(context, entity) {
    return HttpService.post('ENTITY_POST_PROCESS_STATUS', {
      entityType: entity
    })
      .then((res) => {
        const status = res.data.response.inProgress;
        const time = res.data.time;
        Vue.prototype.$logger.logEvent({
          eventType: 'status_api_response',
          entityType: entity,
          status,
          time
        });
        context.commit('SET_INGESTION_POST_PROCESS_STATUS', {
          entity,
          status,
          time
        });
        return status;
      })
      .catch(() => {
        Vue.prototype.$logger.logEvent({
          eventType: 'status_api_response',
          entityType: entity,
          status: false
        });
        context.commit('SET_INGESTION_POST_PROCESS_STATUS', {
          entity,
          status: false
        });
        return false;
      });
  },
  updateDwFilters: (context, data) => {
    setTimeout(function () {
      var filters = transformer.getUniqueFilters(
        context.state.dwFilters || [],
        '',
        data.mapping
      );
      context.commit('SET_FILTER_DATA', filters);
    }, 1500);
  },
  getFilters(context, data) {
    postParams.where.date.from =
      this.state.app.dateRanges.catalog['Last 30 days'].from;
    postParams.where.date.to =
      this.state.app.dateRanges.catalog['Last 30 days'].to;
    HttpLayer.post({
      APIData: postParams
    })
      .then((response) => {
        // context.state.dwFilters = response.data;
        context.commit(
          'SET_DW_FILTER_DATA',
          transformer.getUniqueFilters(response.data, '')
        );
        context.commit(
          'SET_FILTER_DATA',
          transformer.getUniqueFilters(response.data, '')
        );
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getCatalogAsins: (context, catalogAsinOptions) => {
    if (!context.state.globalFilterValues?.catalogAsins) {
      var postParams = {
        cubeName: 'latest_brand_category_metadata_by_catalog_feed_date',
        groupByDimensionsList: ['asin'],
        measuresList: [
          'asin_name',
          'product_page_url',
          'image_url',
          'distribution_type'
        ],
        where: {
          date: {},
          dimensionNameValueList: []
        },
        timeseriesEnabled: false,
        pvpenabled: false,
        yoyenabled: false
      };
      HttpLayer.post({
        APIData: postParams
      })
        .then((response) => {
          context.state.dwFilters = response.data;
          var catalogAsins = transformer.mergeResultDimension(response.data);
          catalogAsins = catalogAsins.map((item) => {
            item.searchText = `${item?.asin_name}${item?.asin}`;
            return item;
          });
          context.commit('SET_FILTER_DATA', {
            catalogAsins
          });
          const asinDistributionTypeMap = getAsinTypeMapping(catalogAsins);
          context.commit(
            'SET_ASIN_DISTRIBUTION_TYPE_MAPPING',
            asinDistributionTypeMap
          );
          eventBus.$emit('catalogAsinDataReceived');
          if (
            CONSTANTS.callGetCatalogAsinInSync.includes(
              catalogAsinOptions.to.name
            )
          ) {
            catalogAsinOptions.next();
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            CONSTANTS.callGetCatalogAsinInSync.includes(
              catalogAsinOptions.to.name
            )
          ) {
            catalogAsinOptions.next();
          }
        });
    }
  },
  showWelcome: (context, data) => {
    context.commit('SHOW_WELCOME', data);
  },
  getMaxDate: (context, next) => {
    const cubeName = getCubeName(context);
    if (cubeName) {
      // make the call only if cubename
      // exists else call the callback function "next"
      let oApiPost = {
        cubeName,
        measuresList: ['min_feed_date', 'max_feed_date'],
        groupByDimensionsList: ['page'],
        where: {
          date: {},
          dimensionNameValueList: []
        },
        timeseriesEnabled: false,
        pvpenabled: false,
        yoyenabled: false,
        customAPIDecisionVars: { system: 'date' }
      };

      HttpLayer.post({
        APIData: oApiPost
      })
        .then((response) => {
          let dateRanges = {};
          let defaultDateRanges = {};
          for (let page of pages) {
            defaultDateRanges[page] = {
              max_feed_date: getSingleDateInDisplayFormat(new Date())
            };
          }
          defaultDateRanges.today_pst = {
            max_feed_date: moment(new Date() + 'PST')
              .utc()
              .format('MM/DD/YYYY')
          };
          let data = response.data;
          if (!response.success || !data || data.length === 0) {
            context.commit('SET_MAX_DATE', defaultDateRanges);
          } else {
            for (let element of data) {
              dateRanges[element.DIMENSION.page] = element.RESULT;
            }
            dateRanges.this_year = {
              min_feed_date: moment().startOf('year').format('MM/DD/YYYY'),
              max_feed_date: moment().endOf('year').format('MM/DD/YYYY')
            };
            dateRanges.today_date = {
              max_feed_date: moment
                .utc(moment())
                .startOf('day')
                .format('MM/DD/YYYY')
            };
            context.commit('SET_MAX_DATE', dateRanges);
          }

          next();
        })
        .catch((error) => {
          context.commit('SET_MAX_DATE', defaultDateRanges);
          console.log(error);
          next();
        });
    } else {
      next();
    }
  },
  fetchMetricHotRefreshDates: (context) => {
    const enabledToShowHotRefreshDates = filters.config_check(
      'feature.hot_metric_refresh.enable'
    );
    if (enabledToShowHotRefreshDates) {
      HttpService.get('HOT_REFRESH_METRICS_DATES')
        .then((res) => {
          context.commit('SET_HOT_REFRESH_METRICS_DATES', res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      context.commit('SET_HOT_REFRESH_METRICS_DATES', {});
    }
  },
  setAppActiveTab: (context, data) => {
    context.commit('SET_APP_ACTIVE_TAB', data);
  },
  getAVCTOTP: (context) => {
    HttpService.get('getOTP').then((response) => {
      context.commit('OTP_FETCHED', response.data);
    });
  },
  inviteUsers: (context, users) => {
    context.commit('USER_INVITE_LOADING');
    HttpService.post('INVITE_USERS_V2', users).then((response) => {
      context.commit('USERS_INVITED', response.data);
      context.dispatch('loadUsers');
    });
  },
  loadUsers: (context) => {
    context.commit('EXISTING_USER', []);
    HttpService.get('USERS').then((response) => {
      context.commit('EXISTING_USER', response.data);
    });
  },
  saveUsers: (context, users) => {
    context.commit('USER_INVITE_LOADING');
    return new Promise((resolve, reject) => {
      HttpService.put('USERS', users).then((response) => {
        context.commit('USERS_UPDATED', response.data);
        resolve();
      });
    });
  },
  feedbackScreenshot: (context) => {
    context.commit('WAITING_FOR_SCREENSHOT');
    context.commit('SHOW_FEEDBACK_OPTIONS');
  },
  setAppSecondaryActiveTab: (context, data) => {
    context.commit('SET_APP_SECONDARY_ACTIVE_TAB', data);
  },
  sendFeedback: (context, data) => {
    return new Promise((resolve, reject) => {
      HttpService.post('SEND_FEEDBACK', data).then((response) => {
        resolve();
      });
    });
  },
  setRootPath: (context, path) => {
    let config = [];
    let clientNameWithRegion = '';
    if (path) {
      config = path.split('/').filter((item) => item);
      if (Vue.options.filters.config_check('feature.accountMapping.enable')) {
        let tempConfig = config.slice();
        tempConfig = validateClientName(tempConfig);
        let tempPath = `/${tempConfig.join('/')}/`;

        if (tempPath !== path) {
          window.location.href =
            window.location.protocol + '//' + window.location.host + tempPath;
        }
      }
    } else {
      if (location.hostname === 'localhost') {
        config.push('us');
      } else {
        clientNameWithRegion = location.hostname.split('.')[0];
        const regionArr = clientNameWithRegion.split('-');
        let clientRegion = 'us';
        if (regionArr.length > 1) {
          clientRegion = regionArr[regionArr.length - 1] || 'us';
          if (allowedRegion.indexOf(clientRegion) === -1) {
            clientRegion = 'us';
          }
        }
        config.push(clientRegion);
      }

      // Read the defaultRetailer from config
      if (window?.configs?.client?.feature?.defaultRetailer) {
        config.push(window.configs.client.feature.defaultRetailer);
      } else {
        config.push('amazon');
      }

      // Read the default program from config
      const defaultProgram = Vue.options.filters.config_check(
        'feature.defaultProgram'
      );
      if (defaultProgram) {
        config.push(defaultProgram);
      } else {
        config.push('retail');
      }

      config = config.map((item) => item?.toLowerCase());
      if (Vue.options.filters.config_check('feature.accountMapping.enable')) {
        config = validateClientName(config);
      }
      path = config.join('/');
      path = `/${path}/`;
      console.log('setRootPath', config);
    }

    const retailerConfig = {
      location: config[0]?.toLowerCase(),
      retailer: config[1]?.toLowerCase(),
      program: config[2]?.toLowerCase()
    };
    addRetailerRoutes(path, retailerConfig);
    context.commit('SET_ROOT_PATH', { path, retailerConfig });
  },
  // Consumed in rbSku component to get distribution type and show as badge
  getDistributionType(context, asinDetailObj) {
    const retailer = context?.getters?.getRetailer;
    const distributionTypeValue = context.state?.asinDistributionTypeMap?.get(
      asinDetailObj?.asin
    );
    const distributionTypes = distributionTypeValue?.split('_')?.reverse();
    // Distribution type badge required only for amazon retailer
    if (retailer !== 'amazon' || !distributionTypes?.length) {
      return null;
    }
    const result = [];
    if (asinDetailObj?.targetPage && asinDetailObj?.targetPage === 'SDP') {
      const distributionTypeValueMap = {
        sourcing: 'Sourcing View',
        manufacturing: 'Manufacturing View'
      };
      const distributionTypeBgMap = {
        sourcing: 'u-bg-color-pink-base',
        manufacturing: 'u-bg-color-purple-base'
      };
      for (const type of distributionTypes) {
        const distributionTypeObj = {
          labelText: distributionTypeValueMap[type],
          class: distributionTypeBgMap[type]
        };
        result.push(distributionTypeObj);
      }
      return result;
    } else {
      const distributionTypeValueMap = {
        sourcing: 'S',
        manufacturing: 'M'
      };
      const distributionTypeColorMap = {
        sourcing: 'u-color-pink-base',
        manufacturing: 'u-color-purple-base'
      };
      for (const type of distributionTypes) {
        const distributionTypePascalCase =
          type?.charAt(0).toUpperCase() + type?.slice(1);
        const distributionTypeObj = {
          distributionType: distributionTypePascalCase,
          tooltipText: `${distributionTypePascalCase} view SKU`,
          labelText: distributionTypeValueMap[type],
          class: distributionTypeColorMap[type]
        };
        result.push(distributionTypeObj);
      }
      return result;
    }
  },
  fetchRecentDownloads: (context, data) => {
    context.commit('SET_RECENT_DOWNLOADS', { loading: true, data: [] });
    return HttpService.post('ORCHESTRATOR_GET_DOWNLOADS', {
      limit: filters.config_check('feature.report_downloads_list_limit') ?? 5
    })
      .then((res) => {
        context.commit('SET_RECENT_DOWNLOADS', {
          loading: false,
          data: res?.data?.list
        });
        return res?.data?.list;
      })
      .catch(() => {
        context.commit('SET_RECENT_DOWNLOADS', {
          loading: false,
          data: []
        });
        return [];
      });
  },
  setUrlComponents: (context, data) => {
    context.commit('SET_URL_COMPONENTS', data);
  },
  setRecentDownloads: (context, data) => {
    context.commit('SET_RECENT_DOWNLOADS', { loading: true, data: [] });
    context.commit('SET_RECENT_DOWNLOADS', data);
  },
  fetchCurrencyChangeList: (context, data) => {
    const { currency } = data;
    context.commit('SET_CURRENCY_EXCHANGE_LIST', { loading: true, data: [] });
    return HttpService.post(
      'GET_CURRENCY_RATES',
      {},
      { append: '?base=' + currency }
    )
      .then((res) => {
        context.commit('SET_CURRENCY_EXCHANGE_LIST', {
          loading: false,
          data: res?.data
        });
        return res?.data;
      })
      .catch(() => {
        context.commit('SET_CURRENCY_EXCHANGE_LIST', {
          loading: false,
          data: []
        });
        return [];
      });
  },
  setSelectedCurrency: (context, data) => {
    context.commit('SET_SELECTED_CURRENCY', data);
  },
  fetchMigratingClientsData: (context, data) => {
    if (context.getters.getMigratingClientsData?.dataFetched) {
      return;
    }

    context.commit('SET_MIGRATING_CLIENTS_DATA', {
      loading: true,
      dataFetched: false,
      data: []
    });
    return HttpService.post('FETCH_MIGRATING_CLIENT_DATA', {
      database: 'brands',
      role: 'brands_aramus_write_role',
      schema: 'avc',
      user: 'brands_aramus_write_user',
      warehouse: 'DEV_WH'
    })
      .then((res) => {
        if (res.data.success) {
          context.commit('SET_MIGRATING_CLIENTS_DATA', {
            loading: false,
            dataFetched: true,
            data: res.data
          });
          return res.data;
        } else {
          throw new Error(res.error);
        }
      })
      .catch(() => {
        context.commit('SET_MIGRATING_CLIENTS_DATA', {
          loading: false,
          dataFetched: true,
          data: []
        });
        return [];
      });
  },
  fetchMigrateStatus: (context) => {
    if (context.getters.getMigrateStatus?.dataFetched) {
      return;
    }

    const dataBricksPayload = {
      envName: 'databricks'
    };

    context.commit('SET_MIGRATE_STATUS', {
      loading: true,
      dataFetched: false,
      data: null
    });

    HttpService.post('FETCH_CLIENT_MIGRATE_STATUS', dataBricksPayload)
      .then((response) => {
        context.commit('SET_MIGRATE_STATUS', {
          loading: false,
          dataFetched: true,
          data: response.data?.migrate_status
        });
      })
      .catch(() => {
        context.commit('SET_MIGRATE_STATUS', {
          loading: false,
          dataFetched: true,
          data: null
        });
      });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
