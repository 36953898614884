import HttpService from '@/utils/services/http-service';
import { downloadLinkAsFile } from '@/utils/helpers/downloader.js';
import {
  groupApis,
  createDictionary,
  getDynamicColumnDefs,
  replacePlaceHolderWithData
} from './dashboard-service-utils';
import transformer from '@/utils/services/data-transformer';
import { pick, difference, cloneDeep, union } from 'lodash';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default class DashboardPivotedDataService {
  widgetName;
  page;
  pageId;
  globalViewId;
  constructor(metadata, widgetName, page, pageId, pivotInfo = {}) {
    this.widgetName = widgetName;
    this.pageId = pageId;
    this.page = page;
    this.load = true;
    this.metadata = metadata;
    this.globalDataPromise = null;
    this.tableGrouping = null;
    const pivotKeys = pivotInfo.keys;
    this.pivotKeys = pivotKeys;
    this.pivot = {
      primaryRow: pivotKeys[0],
      pivotColumn: pivotKeys[1]
    };
    this.nonPivotedMetrics = pivotInfo.nonPivotedMetrics;
  }

  async download(operations, widgetRequestParams) {
    try {
      this.load = true;
      const api = this.metadata.downloadApiTemplate;
      this.tableGrouping = groupApis(this.metadata.metrics);
      const keys = Object.keys(this.tableGrouping);
      if (keys.length === 1) {
        api.request.metricsList =
          this.tableGrouping[keys[0]]?.api?.request?.metricsList;
      }
      const request = replacePlaceHolderWithData(
        api.request,
        widgetRequestParams
      );
      request.where.dimensionNameValueList =
        widgetRequestParams[':dimensionNameValueList'];
      const response = await HttpService.post(api.service, request, {
        append: api.endPoint
      });
      downloadLinkAsFile(response?.data?.url);
    } catch (error) {
      throw Error(error);
    }
    this.load = false;
  }

  async getDataList(operations, widgetRequestParams) {
    try {
      this.load = true;
      this.tableGrouping = groupApis(this.metadata.metrics);
      const metricsResponseData = {};
      for (const groupName of Object.keys(this.tableGrouping)) {
        const api = this.tableGrouping[groupName].api;
        const request = replacePlaceHolderWithData(
          api.request,
          widgetRequestParams
        );
        request.operations = { ...request.operations, ...operations };
        request.where.dimensionNameValueList =
          widgetRequestParams[':dimensionNameValueList'];
        request.enablePaginationCount = true;
        const response = await HttpService.post(api.service, request, {
          append: api.endPoint
        });
        metricsResponseData[groupName] = response?.data;
      }
      this.load = false;
      return metricsResponseData;
    } catch (error) {
      this.load = false;
      throw Error(error);
    }
  }

  async getTableRows(operations, widgetRequestParams) {
    try {
      this.load = true;
      const nonPivotedMetrics = this.nonPivotedMetrics;
      const data = await this.getDataList(operations, widgetRequestParams);
      const formattedRowLevelData =
        dashUtils.mergeResponseToSingleRowObjArray(data);
      const unCurledJsonRowData = transformer.unCurlArrayArrayToObjectArray(
        formattedRowLevelData.rows
      );
      const ungroupedRows = transformer.convertObjectToArray(
        unCurledJsonRowData,
        this.pivotKeys[0]
      );
      const groupedRows = transformer.groupDataToPivotLevels(
        ungroupedRows,
        this.pivotKeys
      );
      const pivotMeta = this.pivot;
      formattedRowLevelData.rows = formattedRowLevelData.rows.map((obj) => {
        const out = pick(obj, nonPivotedMetrics);
        out.pivotedData = groupedRows[obj[pivotMeta.primaryRow]];
        return { ...out, ...out.pivotedData };
      });
      this.load = false;
      return formattedRowLevelData;
    } catch (error) {
      console.error(error);
      this.load = false;
      throw Error(error);
    }
  }

  getAllMetricsUnderSinglePivotMetadata(
    pivotValueMetric,
    columnPivotSingleData,
    enclosingPivotColumnMeta,
    metricsUnderPivot,
    metadata
  ) {
    const pivotMetadata = cloneDeep(enclosingPivotColumnMeta);
    const tableConfig = pivotMetadata.metadata.tableConfig;
    if (tableConfig.columnHeader) {
      tableConfig.columnHeader.props = dashUtils.mapPropToData(
        tableConfig.columnHeader.propsKeyDataMap,
        columnPivotSingleData
      );
    }
    const propToDataMap = tableConfig.propsKeyDataMap;
    const staticProps = tableConfig.staticProps || {};
    pivotMetadata.metadata.tableConfig.paramsToProps = (params) => {
      return dashUtils.mapPropToData(
        propToDataMap,
        params.data[params.colDef.field],
        { params, ...staticProps }
      );
    };
    pivotMetadata.metadata.visible = true;
    pivotMetadata.metadata.tableConfig.props = { value: pivotValueMetric };
    return pivotMetadata;
  }

  async getTableColumns(operations, widgetRequestParams) {
    try {
      this.load = true;
      const metricKeys = Object.keys(this.metadata.metrics);
      const nonPivotedMetrics = this.nonPivotedMetrics;
      const metricsUnderPivot = difference(
        metricKeys,
        nonPivotedMetrics.concat(this.pivotKeys)
      );
      const data = await this.getDataList(operations, widgetRequestParams);
      const formattedRowLevelData =
        dashUtils.mergeResponseToSingleRowObjArray(data).rows;
      const unCurledJsonRowData = transformer.unCurlArrayArrayToObjectArray(
        formattedRowLevelData
      );
      // get the unique values of all the column level pivot column
      const pivotedDataColumsToCreate = unCurledJsonRowData.reduce(
        (prev, curr) => {
          return union(curr[this.pivot.pivotColumn], prev);
        },
        []
      );
      const ungroupedRows =
        transformer.convertObjectToArray(unCurledJsonRowData);
      const columnPivotedData = transformer.groupDataToPivotLevels(
        ungroupedRows,
        [this.pivot.pivotColumn]
      );
      const pivotedDataColumnsMetadtaToCreate = {};
      for (let index = 0; index < pivotedDataColumsToCreate.length; index++) {
        const pivotingColumnDataValue = pivotedDataColumsToCreate[index];
        if (
          columnPivotedData[pivotingColumnDataValue] &&
          columnPivotedData[pivotingColumnDataValue][0]
        ) {
          const pivotDataColumnMeta =
            this.getAllMetricsUnderSinglePivotMetadata(
              pivotingColumnDataValue,
              columnPivotedData[pivotingColumnDataValue][0],
              this.metadata.metrics[this.pivot.pivotColumn],
              metricsUnderPivot,
              this.metadata
            );
          pivotedDataColumnsMetadtaToCreate[pivotingColumnDataValue] =
            pivotDataColumnMeta;
        }
      }
      const columns = createDictionary(
        nonPivotedMetrics.concat(this.pivotKeys, pivotedDataColumsToCreate),
        { ...this.metadata.metrics, ...pivotedDataColumnsMetadtaToCreate }
      );
      const colDefs = getDynamicColumnDefs(
        columns,
        this.metadata?.metadata?.defaultOperations
      );
      this.load = false;
      return colDefs;
    } catch (error) {
      console.error(error);
      this.load = false;
      throw Error(error);
    }
  }
}
