<template>
  <div
    class="u-bg-color-grey-white widget--full-screen add-entities-to-tag u-width-100"
  >
    <modal
      v-if="showModal"
      :modal-config="modalConfig"
      @clickedOutside="modalVisible(false)"
    >
      <div
        slot="header"
        class="u-text-align-center u-font-size-1 u-font-weight-600 u-width-100 u-spacing-pt-xxl u-spacing-ph-xl"
      >
        Are you sure about deleting {{ headerLabel }} '{{ headerTitle }}'?
      </div>
    </modal>
    <loader
      v-if="updateLoader"
      class="fill--screen entity_loader"
      :loading="updateLoader"
      :color="'#007cf6'"
    />
    <div class="u-height-auto">
      <!-- header -->
      <div
        class="page--header u-display-flex u-flex-align-items-center u-spacing-p-l u-width-100"
      >
        <div
          class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-width-100"
        >
          <div class="u-display-flex u-flex-align-items-center u-width-100">
            <span @click="handleCrossClick">
              <rb-icon
                icon="arrow_back"
                class="u-color-grey-lighter rb-icon--large u-cursor-pointer u-spacing-mr-s"
              />
            </span>
            <span
              v-if="staticHeaderText"
              :class="[
                headerTitle !== defaultValues.headerTitle || isTitleInputClicked
                  ? 'active-state'
                  : ' u-color-grey-lighter'
              ]"
              class="static-title-entity-sub-entity-page heading--input u-text-overflow-ellipsis u-font-size-1 u-font-weight-600"
              >{{ staticHeaderText }}</span
            >
            <input
              ref="titleInputRef"
              v-model="computedHeaderTitle"
              type="text"
              data-cy="inputEditableEntity"
              :placeholder="defaultValues.headerTitlePlaceholder"
              maxlength="56"
              :class="[
                headerTitle !== defaultValues.headerTitle || isTitleInputClicked
                  ? 'active-state'
                  : ' u-color-grey-lighter'
              ]"
              class="editable-title-entity-sub-entity-page heading--input u-text-overflow-ellipsis u-font-size-1 u-font-weight-600 u-color-purple-base"
              @click="titleInputClicked()"
            />
            <span
              v-if="headerIcon"
              @click="titleInputClicked()"
            >
              <rb-icon
                :icon="headerIcon"
                class="u-cursor-pointer"
                :class="headerIconClass"
              />
            </span>
            <div
              class="u-flex-1 u-display-flex u-flex-justify-content-flex-end u-flex-align-self-flex-end"
            >
              <div
                v-if="
                  headerTitle === defaultValues.headerTitle &&
                  mode === 'edit' &&
                  showDelete
                "
                @click="modalVisible(true)"
              >
                <rb-icon
                  icon="delete"
                  class="u-color-grey-lighter rb-icon--x-medium u-cursor-pointer u-spacing-mr-s delete-icon"
                />
              </div>
            </div>
          </div>
          <input
            v-if="defaultValues.headerDescriptionPlaceholder"
            v-model="headerDescription"
            type="text"
            maxlength="200"
            :placeholder="defaultValues.headerDescriptionPlaceholder"
            class="heading--input u-font-style-italic u-font-size-4 u-spacing-pt-s u-spacing-ml-xl"
            :class="[
              headerDescription !== defaultValues.headerDescription ||
              isTitleInputClicked
                ? 'active-state'
                : ' u-color-grey-lighter'
            ]"
          />
        </div>
      </div>

      <!-- body -->
      <div class="">
        <ingestionProgress :show-progress="entityProcessingStatus" />
        <!-- body title & description -->
        <section class="u-spacing-ph-xxxl u-spacing-pv-xl">
          <div
            data-cy="pageTitle"
            class="u-color-grey-base u-font-size-3 u-font-weight-600 u-spacing-pv-xs"
          >
            {{ pageTitle || defaultValues.pageTitle }}
          </div>
          <div class="u-font-size-5 u-color-grey-lighter u-spacing-pv-xs">
            {{ defaultValues.pageDescription }}
          </div>
        </section>
        <div class="split-table-section">
          <!-- tables -->
          <div
            class="table-container-entity-asin-tagging u-overflow-y-auto u-spacing-ph-xxxl u-spacing-pv-l u-width-100 u-display-flex"
          >
            <!-- left table -->
            <tableContainer
              :header="`${config.left.title} (${
                allEntities.totalRows || leftTableRows.length
              })`"
              :class="config.left.class || ['u-width-560px']"
              :data="{ error: allEntities.error }"
              @onSearch="handleLeftTableSearch"
            >
              <div slot="body">
                <rb-filter-panel
                  :id="localStorageFilterKey || 'tags-filters'"
                  :key="filterKey"
                  class="u-font-size-5 u-spacing-mh-m u-border-radius-s u-width-100 u-display-flex u-flex-align-items-center"
                  :listener-event="localStorageFilterKey || 'tag-filters'"
                  :hide-filter="true"
                  :enable-remove-all="false"
                  page="tags"
                  :state="config.left.meta.filterState"
                  :loader-status="loaderStatus"
                  :enable-save="false"
                  :has-search="false"
                  :data="filterData"
                  :use-local-storage="localStorageFilterKey ? true : false"
                  :primary="primaryFilterData"
                  :secondary-data="secondaryFilterData"
                  :config="config.left.meta.filters"
                  :emit="config.left.meta.filters.emit"
                  :get-filter-instance="getFilterInstance"
                  :filter-v2="filterV2"
                  @savedFilterApplied="filterKey++"
                />
                <div class="u-position-relative">
                  <loader
                    v-if="allEntities.load"
                    class="u-bg-color-grey-white fill--parent"
                    :loading="true"
                    :color="'#007cf6'"
                  />
                  <rb-insights-table
                    key="leftTable"
                    :config="config.left"
                    :grid-options="config.left.body.gridOptions"
                    :table-row="leftTableRows"
                    :table-column="config.left.body.columnDefs"
                    class="pagination-overlay"
                    :no-rows-message="defaultValues.noRowsLeftTable"
                    :row-height="66"
                    :enable-client-side-sorting="false"
                    :enable-server-side-sorting="true"
                    :pagination="true"
                    :pagination-total-key="allEntities.totalRows"
                    :pagination-per-page-limit="
                      config.left.body.APIConfig.limit
                    "
                    :pagination-change-event="config.left.meta.paginationAction"
                    :get-current-instance="getLeftTableInstance"
                  />
                </div>
              </div>
            </tableContainer>
            <!-- right table -->
            <tableContainer
              :header="`${
                rightTableTitle || config.right.title
              } &quot;${headerTitle}&quot; (${rightTableRows.length})`"
              :class="[
                ...(config.right.class || ['u-width-560px']),
                'u-spacing-ml-l'
              ]"
              :config="{}"
              :data="{ error: subsetEntities.error }"
              @onSearch="handleRightTableSearch"
            >
              <div
                slot="body"
                class="u-position-relative"
              >
                <loader
                  v-if="subsetEntities.load"
                  class="u-bg-color-grey-white fill--parent"
                  :loading="true"
                  :color="'#007cf6'"
                />
                <rb-insights-table
                  key="rightTable"
                  :config="tableConfigsRight"
                  :grid-options="config.right.body.gridOptions"
                  :table-row="
                    (rightTableSearch.length > 0
                      ? filteredRightTableRows
                      : rightTableRows) || []
                  "
                  :table-column="config.right.body.columnDefs"
                  class="pagination-overlay-right-table"
                  :row-height="66"
                  :no-rows-message="defaultValues.noRowsRightTable"
                  :client-side-pagination="true"
                  :enable-client-side-sorting="false"
                  :enable-server-side-sorting="false"
                  :pagination="true"
                  :pagination-total-key="
                    (rightTableSearch.length > 0
                      ? filteredRightTableRows.length
                      : rightTableRows.length) || 0
                  "
                  :pagination-per-page-limit="20"
                  :download-this-table="false"
                  :get-current-instance="getRightTableInstance"
                />
              </div>
            </tableContainer>
          </div>
          <section
            v-if="warningText && warningText.trim().length"
            class="u-spacing-mh-xxxl u-spacing-pt-s"
          >
            <iconText
              :icon="'warning-triangle'"
              :icon-classes="'u-flex-0 rb-icon--small u-color-orange-base u-spacing-mr-xs u-flex-align-self-flex-start'"
              :text="warningText"
              :text-classes="'u-spacing-ph-xxs u-font-size-6'"
            />
          </section>
        </div>
        <section class="u-display-flex u-spacing-mh-xxl footer-color">
          <rb-button
            :click-fn="handleCrossClick"
            :text="'Cancel'"
            :size="'m'"
            :type="'hollow'"
            class="hollow-outlined-btn u-spacing-mv-m u-spacing-mh-m u-cursor-pointer"
          />
          <rb-button
            :click-fn="updateTags"
            :disabled="checkUpdateBtnDisable || headerTitle.length === 0"
            :text="mode === 'create' ? 'Create' : 'Update'"
            :size="'m'"
            :type="'filled'"
            class="u-spacing-mv-m u-cursor-pointer"
          />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
// import widgetsConfig from './widgets';
import tableContainer from './tableContainer';
import widgetsUtil from '@/components/widgetMixin';
import rbFilterPanel from '@/components/widgets/filter-panel';
import modal from '@/components/widgets/modal.vue';
import loader from '@/components/basic/loader';
import utils from '@/utils/helpers';
import iconText from '@/components/basic/iconText';
import { eventBus } from '@/utils/services/eventBus';
import ingestionProgress from '@/pages/toolsAndSettings/entityTagging/common/ingestionProgress.vue';

export default {
  components: {
    modal,
    loader,
    tableContainer,
    rbFilterPanel,
    iconText,
    ingestionProgress
  },
  mixins: [widgetsUtil],
  props: {
    pageTitle: {
      type: String,
      default: ''
    },
    rightTableTitle: {
      type: String,
      default: ''
    },
    showLoader: {
      type: Boolean,
      default: false
    },
    preferEmitHandlers: {
      type: Boolean,
      default: false
    },
    staticHeaderText: {
      type: String,
      default: ''
    },
    headerIconClass: {
      type: String,
      default: ''
    },
    headerIcon: {
      type: String,
      default: ''
    },
    getTagDesc: {
      type: String,
      default: ''
    },
    doSpecialCharacterValidation: {
      type: Boolean,
      default: true
    },
    allEntities: {
      type: Object,
      default: () => {
        return { load: true };
      }
    },
    widgetsConfig: {
      type: Object,
      default: () => {
        return {};
      }
    },
    subsetEntities: {
      type: Object,
      default: () => {
        return { load: true };
      }
    },
    warningText: {
      type: String,
      default: null
    },
    entityProcessingStatus: {
      type: Boolean,
      default: false
    },
    initialHeaderValue: {
      type: String,
      default: null
    },
    showDelete: {
      type: Boolean,
      default: true
    },
    filterV2: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      filterKey: 0,
      modalConfig: {
        body: {
          text: 'This filter will no longer be available to use across CommerceIQ.'
        },
        footer: {
          leftButton: {
            text: 'Cancel',
            onClick: () => {
              this.showModal = false;
            },
            icon: '',
            show: true
          },
          rightButton: {
            text: 'Delete',
            onClick: () => {
              this.handleRemoveTag();
            },
            icon: '',
            show: true
          }
        }
      },
      showModal: false,
      headerTitle: '',
      headerLabel: '',
      updateLoader: false,
      headerDescription: '',
      defaultValues: null,
      isTitleInputClicked: false,
      config: null,
      leftTableInstance: null,
      rightTableInstance: null,
      mode: 'create', // ['create', 'edit']
      leftTableRows: [],
      rightTableRows: [],
      filteredRightTableRows: [],
      filterInstance: null,
      rightTableSearch: '',
      crudOperations: [],
      tableConfigsRight: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      queryParams: null
    };
  },
  computed: {
    computedHeaderTitle: {
      get() {
        return this.headerTitle;
      },
      set(val) {
        this.headerTitle = val.trim();
      }
    },
    localStorageFilterKey() {
      return this?.config?.left?.meta?.localStoreageFilterKey || null;
    },
    checkUpdateBtnDisable() {
      let disabled = true;
      if (!this.initialHeaderValue) {
        if (this.mode === 'create') {
          disabled = !(
            this.headerTitle !== this.defaultValues.headerTitle &&
            this.crudOperations.length !== 0
          );
        } else if (this.mode === 'edit') {
          disabled = !(
            (this.headerTitle !== this.defaultValues.headerTitle ||
              this.crudOperations.length !== 0 ||
              this.headerDescription !==
                this.defaultValues.headerDescription) &&
            this.rightTableRows.length !== 0
          );
        }
      } else {
        disabled =
          this.initialHeaderValue === this.headerTitle &&
          this.crudOperations.length === 0;
      }
      return disabled;
    }
  },
  watch: {
    showLoader(showLoader) {
      this.updateLoader = showLoader;
    },
    getTagDesc(newVal) {
      this.$nextTick(() => {
        this.setDefaultValues(this.config.default);
      });
    },
    'allEntities.rows': function (newVal) {
      this.leftTableRows = [...newVal];
    },
    leftTableRows: function (newVal) {
      this.updateEntityInformation();
    },
    'subsetEntities.rows': function (newVal) {
      this.rightTableRows = [...newVal];
      this.updateEntityInformation();
    }
  },
  created() {
    this.queryParams = this.decodeQueryParams(this.$route?.query);
    this.doesTagExist();
    this.config = this.widgetsConfig.config(this);
    this.fetchFilters(
      this.config.left.body.APIConfig.cubeName,
      'FETCH_FILTERS_V2'
    );
    this.getLeftTable(this.config.left);
    this.setDefaultValues(this.config.default);
    eventBus.$on(this.config.left.meta.filters.emit, () => {
      this.config.left.body.APIConfig.page = 1;
      this.getLeftTable(this.config.left);
    });
    this.getRightTableData(this.queryParams);
  },
  methods: {
    decodeQueryParams(queryObj = {}) {
      Object.keys(queryObj).forEach((key) => {
        queryObj[key] = decodeURIComponent(queryObj[key]);
      });
      return queryObj;
    },
    modalVisible(value) {
      this.showModal = value;
    },
    handleRemoveTag() {
      const data = {
        tag: {
          level1: this.queryParams?.tag || this.headerTitle?.trim(),
          type: this.config.updateKeyMapper.tagType,
          customType: this.config.updateKeyMapper.tagCustomType
        },
        entityType: this.config.updateKeyMapper.type
      };
      if (this.preferEmitHandlers) {
        this.$emit('doDeleteEntity', data);
        return;
      }
      let error = false;
      this.updateLoader = true;
      this.$store
        .dispatch('removeTag', data)
        .catch(() => {
          error = true;
        })
        .finally(() => {
          let message = 'Something went wrong!';
          if (!error) {
            message = `${
              this.defaultValues?.alias?.tag || 'Tag'
            } was deleted successfully!`;
          }
          this.openSnackbar(message);
          if (!error) {
            this.returnToParentPage();
          }
        });
    },
    getLeftTable(data) {
      this.$store.dispatch(this.config.left.meta.action, data);
    },
    getFilterInstance(that) {
      this.filterInstance = that;
    },
    handleLeftTableSearch(value, activateSearch) {
      if (!activateSearch) return;
      this.config.left.meta.localFilters =
        value === ''
          ? []
          : [
              {
                dimensionName: this.config.left.searchKey,
                operator: 'ILIKE',
                dimensionValue: value
              }
            ];
      this.config.left.body.APIConfig.page = 1;
      this.getLeftTable(this.config.left);
    },
    handleRightTableSearch(value, activateSearch) {
      if (!activateSearch) return;
      this.rightTableSearch = value;
      if (value !== '') {
        this.filteredRightTableRows = this.rightTableRows.filter((row) => {
          if (JSON.stringify(row).toLowerCase().includes(value.toLowerCase())) {
            return row;
          }
        });
      }
      this.rightTableInstance.redrawTableRows();
    },
    updateEntityInformation() {
      let redrawTable = false;
      this.rightTableRows.forEach((rightTableRow) => {
        this.leftTableRows.forEach((leftTableRow) => {
          if (
            leftTableRow[this.config.primaryKey] ===
            rightTableRow[this.config.primaryKey]
          ) {
            leftTableRow.isRowSelected = true;
            redrawTable = true;
          }
        });
      });
      if (redrawTable) {
        this.leftTableInstance.redrawTableRows();
      }
    },
    doesTagExist() {
      this.mode = this.queryParams?.tag ? 'edit' : 'create';
    },
    getRightTableData(queryParams) {
      let payload = null;
      if (this.mode === 'edit') {
        if (this.config.right?.customPayload) {
          payload = { ...this.config.right };
          payload.meta.localFilters = [
            {
              dimensionName: queryParams.dimension,
              dimensionValue: queryParams.tag,
              esDataSetName: queryParams.esDataSetName
            }
          ];
        } else {
          payload = this.config.right.body.APIConfig;
          payload.tag = this.headerTitle;
          payload.params = queryParams;
        }
        this.$store.dispatch(this.config.right.meta.action, payload);
      }
    },
    getLeftTableInstance(that) {
      this.leftTableInstance = that;
    },
    getRightTableInstance(that) {
      this.rightTableInstance = that;
    },
    setDefaultValues(val) {
      const defaultValues = val;
      this.headerTitle = this.initialHeaderValue ?? val.headerTitle;
      this.headerLabel = this.queryParams?.headerLabel;
      if (this.mode === 'edit') {
        this.headerTitle = this.queryParams?.tag;
        this.headerDescription = this.getTagDesc.length
          ? this.getTagDesc
          : val.headerDescription;
        defaultValues.headerTitle = this.headerTitle;
        defaultValues.headerDescription = this.headerDescription;
      } else {
        this.headerDescription = val.headerDescription;
      }
      this.defaultValues = defaultValues;
    },
    handleCrossClick() {
      if (this.localStorageFilterKey) {
        localStorage.removeItem(this.localStorageFilterKey);
      }
      this.returnToParentPage();
    },
    returnToParentPage() {
      const { path, query } = this.config.redirectToParentRoute;
      this.$router.push({ path: path || '/', query: query || null });
    },
    titleInputClicked() {
      this.$refs.titleInputRef.focus();
    },
    removeEntity(row) {
      this.rightTableRows = this.rightTableRows.filter((rightTableRow) => {
        if (
          row.params.data[this.config.primaryKey] !==
          rightTableRow[this.config.primaryKey]
        ) {
          return rightTableRow;
        }
      });
      this.updateCrudOperations(row.params.data[this.config.primaryKey]);
      this.filteredRightTableRows = this.filteredRightTableRows.filter(
        (rightTableRow) => {
          if (
            row.params.data[this.config.primaryKey] !==
            rightTableRow[this.config.primaryKey]
          ) {
            return rightTableRow;
          }
        }
      );
      this.rightTableInstance.redrawTableRows();
      this.leftTableRows.every((leftTableRow) => {
        if (
          leftTableRow[this.config.primaryKey] ===
          row.params.data[this.config.primaryKey]
        ) {
          leftTableRow.isRowSelected = false;
          this.leftTableInstance.redrawTableRows();
          return false;
        }
        return true;
      });
    },
    removeAll() {
      this.leftTableRows.forEach((row) => {
        row.isRowSelected = false;
      });
      for (const row of this.rightTableRows) {
        this.updateCrudOperations(row[this.config.primaryKey]);
      }
      this.rightTableRows = [];
      this.filteredRightTableRows = [];
      this.rightTableInstance.redrawTableRows();
      this.leftTableInstance.redrawTableRows();
    },
    addAll() {
      const rowsToAddToRightTable = [];
      for (const row of this.leftTableRows) {
        let isRowPresent = false;
        row.isRowSelected = true;
        for (const rightTableRow of this.rightTableRows) {
          if (
            row[this.config.primaryKey] ===
            rightTableRow[this.config.primaryKey]
          ) {
            isRowPresent = true;
          }
        }
        if (!isRowPresent) rowsToAddToRightTable.push(row);
      }
      for (const row of rowsToAddToRightTable) {
        this.updateCrudOperations(row[this.config.primaryKey]);
      }
      this.rightTableRows = [...this.rightTableRows, ...rowsToAddToRightTable];
      this.leftTableRows = [...this.leftTableRows];
    },
    addEntity(row) {
      if (!row.params.data?.isRowSelected) {
        row.params.data.isRowSelected = true;
        this.leftTableInstance.redrawTableRows([row.params.node]);
      }
      let rowExistsInRightTable = false;
      this.rightTableRows.every((rightTableRow) => {
        if (
          rightTableRow[this.config.primaryKey] ===
          row.params.data[this.config.primaryKey]
        ) {
          rowExistsInRightTable = true;
          return false;
        } else return true;
      });
      if (!rowExistsInRightTable) {
        this.rightTableRows.push(row.params.data);
        this.updateCrudOperations(row.params.data[this.config.primaryKey]);
        this.rightTableInstance.redrawTableRows();
      }
    },
    checkIfTableIsRemoved() {
      const totalRows = this.subsetEntities?.rows.filter((item) => {
        for (let i = 0; i < this.rightTableRows.length; i++) {
          if (
            this.rightTableRows[i][this.config.primaryKey] ===
            item[this.config.primaryKey]
          ) {
            return undefined;
          }
        }
        return item;
      });
      return totalRows.length > 0;
    },
    openSnackbar(message) {
      const snackbarObject = {
        message: '',
        duration: 5000,
        actionText: ''
      };
      snackbarObject.message = message;
      this.$snackbar.open(snackbarObject);
    },
    updateTags() {
      const remove = this.checkIfTableIsRemoved();
      // Perform special char validation everywhere except on standard internal catalog page
      if (this.doSpecialCharacterValidation) {
        const validationCheck =
          this.defaultValues?.validations ||
          /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
        const invalidText = utils.textValidation(validationCheck, [
          {
            value: this.headerTitle,
            name: `${this.defaultValues?.alias?.tag || 'Tag'} name`,
            maxLength: 100
          }
        ]);

        if (invalidText) {
          const commonMessage = `${invalidText.name} cannot `;
          const reasonMessage =
            invalidText.reason === 'character'
              ? 'have special characters'
              : `exceed ${invalidText.maxLength} characters`;
          this.openSnackbar(commonMessage + reasonMessage);
          return;
        }
      } else {
        // encode apostophe for standard internal catalog only
        this.headerTitle = this.headerTitle.toLowerCase().replace("'", '%27');
        this.headerDescription = this.headerDescription
          .toLowerCase()
          .replace("'", '%27');
      }

      if (this.preferEmitHandlers) {
        this.$emit('onUpdate', {
          desc: this.headerDescription?.trim(),
          title: this.headerTitle?.trim(),
          mode: this.mode,
          updatedRows: this.rightTableRows,
          renameFilter: !remove // rename filter only when right table data isn't updated
        });
        return;
      }
      const updateKeys = this.config.updateKeyMapper;
      let renameTagRequest = null;
      if (
        (this.headerTitle !== this.queryParams?.tag ||
          this.headerDescription !== this.getTagDesc) &&
        this.mode === 'edit'
      ) {
        renameTagRequest = {
          oldTag: {
            tag: this.queryParams?.tag,
            tagLevel: 'level1',
            tagDescription: this.getTagDesc,
            tagType: updateKeys.tagType,
            customType: updateKeys.tagCustomType
          },
          newTag: {
            tag: this.headerTitle,
            tagDescription: this.headerDescription,
            tagLevel: 'level1',
            tagType: updateKeys.tagType,
            customType: updateKeys.tagCustomType
          },
          entityType: updateKeys.type
        };
      }
      let error = false;
      const entities = this.rightTableRows.map((item) => {
        return {
          value: item[updateKeys.value],
          type: updateKeys.type,
          description: updateKeys?.description
            ? item[updateKeys.description]
            : '',
          image: updateKeys?.image ? item[updateKeys.image] : ''
        };
      });

      const tags = [
        {
          level1: this.headerTitle?.trim(),
          level2: '',
          level3: '',
          level4: '',
          level5: '',
          type: updateKeys.tagType,
          desc: this.headerDescription?.trim(),
          customType: updateKeys.tagCustomType
        }
      ];
      this.updateLoader = true;
      const request = {};
      if (renameTagRequest) {
        request.renameTagRequest = renameTagRequest;
      }
      if (
        remove ||
        this.rightTableRows.length !== this.subsetEntities?.totalRows
      ) {
        request.entityTaggingAddRequest = {
          entities,
          tags,
          remove,
          create: this.mode === 'create'
        };
      }
      let noNewEntityTag = false;
      this.$store
        .dispatch('addTagToEntity', request)
        .then((result) => {
          noNewEntityTag = result?.data === 'No new tag-entity was added';
        })
        .catch((e) => {
          error = true;
        })
        .finally(() => {
          this.updateLoader = false;
          let message = 'Something went wrong!';
          if (!error) {
            const tagAlias = this.defaultValues?.alias?.tag || 'Tag';
            const entityAlias = this.defaultValues?.alias?.enity || 'value';
            message =
              this.mode === 'create'
                ? this.defaultValues?.successMessage?.create ||
                  `${tagAlias} was created successfully!`
                : this.defaultValues?.successMessage?.edit ||
                  `${tagAlias} was updated successfully!`;
            if (noNewEntityTag) {
              message = `Selected ${entityAlias}(s) were already a part of the ${tagAlias}.`;
            }
          }
          this.openSnackbar(message);
          if (!error) {
            this.$store
              .dispatch('fetchPostProcessingStatus', updateKeys.type)
              .then(() => {
                utils.checkAndClearLSFilterForEntity(updateKeys.type);
              });
            this.returnToParentPage();
          }
        });
    },
    updateCrudOperations(asin) {
      if (this.crudOperations.includes(asin)) {
        this.crudOperations = this.crudOperations.filter((d) => d !== asin);
      } else {
        this.crudOperations.push(asin);
      }
    }
  }
};
</script>
<style lang="css">
.add-entities-to-tag .ag-body-viewport.ag-layout-normal {
  overflow-x: hidden !important;
}
.add-entities-to-tag .ag-theme-material .ag-row {
  border-style: none;
}
.add-entities-to-tag .u-height-80 {
  height: 80%;
}
.add-entities-to-tag .ag-theme-material .ag-row:last-child {
  border-style: solid;
  border-top: none;
}
.add-entities-to-tag .heading--input {
  border: 0;
  outline: none;
  font-family: 'ProximaNova', sans-serif;
}
.add-entities-to-tag .heading--input:focus {
  border: 0;
  outline: none;
}
.add-entities-to-tag .page--header {
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  z-index: 0;
  width: 100%;
}
.add-entities-to-tag .paginationFooter {
  border-top: 1px solid #e9eaeb;
}
.add-entities-to-tag .pagination-overlay {
  width: 100%;
  height: calc(100vh - 200px);
}
.add-entities-to-tag .pagination-overlay-right-table {
  width: 100%;
  height: calc(100vh - 148px);
}
.add-entities-to-tag .loading-overlay {
  width: 100%;
  height: calc(100vh - 100px);
}
.add-entities-to-tag .delete-icon {
  margin-bottom: 12px;
}
.add-entities-to-tag .table-container-entity-asin-tagging {
  height: calc(100vh - 350px);
}
</style>
