import { BoKeyTypeMapper } from '@/components/ams/campaigns/constants.js';

export function getYearsToAdd(isNextYearPlan, year = 1) {
  return isNextYearPlan ? year : year - 1;
}

export const currentInputValue = (
  actionType,
  editMode,
  rowData,
  editPayload,
  actionPayloadApiObj,
  context
) => {
  if (actionType === 'campaignBudgetChange' && editMode) {
    const currentRowInEditPayload = editPayload.actions.find(
      (row) => row.campaign_id === rowData.campaign_id
    );
    return currentRowInEditPayload.new_daily_budget;
  } else if (context.openActionWorkSpaceId !== 'approve-actions') {
    const changeType = context.bidValue.dropdownValue;
    if (changeType === 'percentage') {
      const percentageValue =
        actionPayloadApiObj.viewPayload.budgetPlannerMetadata.previous_value *
        (parseFloat(context.bidValue.inputValue) / 100);
      const pastDataValue =
        actionPayloadApiObj.viewPayload.budgetPlannerMetadata.previous_value;
      const finalVal =
        context.bidValue.radioValue === 'increase_bid'
          ? percentageValue + pastDataValue
          : pastDataValue - percentageValue;
      return parseFloat(finalVal.toFixed(2));
    }
    return parseFloat(context.bidValue.inputValue);
  } else if (!editMode) {
    return actionPayloadApiObj.viewPayload.budgetPlannerMetadata.current_value;
  }
  return editPayload.actions[0].new_bid;
};
export const transformEditPayload = (
  selectedRows,
  actionType,
  context,
  editMode,
  editPayload
) => {
  const transformedPayload = [];
  selectedRows.forEach((selectedRow) => {
    const rowData = selectedRow.data;
    const actionPayloadApiObj = JSON.parse(rowData.action_payload_for_api);
    let entityType = actionType === 'keywordBidChange' ? 'keyword' : 'campaign';
    if (
      entityType === 'keyword' &&
      actionPayloadApiObj.viewPayload.budgetPlannerMetadata.matchType.includes(
        'TARGETING'
      )
    ) {
      entityType = 'target';
    }
    let inputValue =
      actionPayloadApiObj.viewPayload.budgetPlannerMetadata.current_value;
    let comparativeValue = currentInputValue(
      actionType,
      editMode,
      rowData,
      editPayload,
      actionPayloadApiObj,
      context
    );
    if (
      (editMode ||
        (editPayload.actions.length > 0 &&
          context.openActionWorkSpaceId !== 'approve-actions')) &&
      inputValue !== comparativeValue
    ) {
      inputValue = comparativeValue;
    }
    const newKeyValue = BoKeyTypeMapper.newValueKey[entityType].keyName;
    const oldKeyValue = BoKeyTypeMapper.oldValueKey[entityType].keyName;
    const typeIdKeyName = BoKeyTypeMapper.typeId[entityType].keyName;
    const entityRow = {
      profileId: rowData.profile_id,
      name: rowData.campaign_name,
      campaignId: rowData.campaign_id,
      primaryKey: actionPayloadApiObj.viewPayload.secondaryKey,
      campaignType: rowData.campaign_type,
      actionType: entityType === 'target' ? 'targetBidChange' : actionType,
      [newKeyValue]: inputValue,
      [oldKeyValue]:
        actionPayloadApiObj.viewPayload.budgetPlannerMetadata.previous_value,
      ...((entityType === 'keyword' || entityType === 'target') && {
        adGroupId: actionPayloadApiObj.actionPayload.adgroupId,
        keywordText: rowData.keyword_text || null,
        [typeIdKeyName]: actionPayloadApiObj.actionPayload[typeIdKeyName]
      }),
      budgetPlannerMetadata: {
        ...actionPayloadApiObj.viewPayload.budgetPlannerMetadata,
        current_value: inputValue,
        username: context.$store.getters.getSessionUser?.email
      }
    };
    transformedPayload.push(entityRow);
  });
  return transformedPayload;
};
