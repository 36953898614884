<template>
  <div>
    <!-- <img class="u-position-absolute login-logo-svg" src="/images/logo.svg"/> -->
    <div class="u-display-flex page-body u-height-100vh">
      <loader
        class="fill--parent"
        :loading="sessionValidity"
        :color="'#3684bb'"
      />
      <div
        class="u-flex-1 u-display-flex u-flex-align-items-center bg-blue-base-gradient"
      >
        <div
          class="u-display-flex u-flex-justify-content-center u-color-grey-white u-width-100"
        >
          <div
            class="u-display-flex u-flex-direction-column u-flex-align-items-center"
          >
            <div>
              <img
                style="max-width: 537px"
                src="/images/welcome.png"
              />
            </div>
            <div class="u-spacing-mt-xxl u-font-size-2 u-font-weight-bold">
              MACHINE POWERED, HUMAN EMPOWERED
            </div>
            <div
              class="login-long-message u-spacing-mt-m u-font-size-4 u-text-align-center"
            >
              Save time and drive sustainable growth by minimizing revenue
              leakage and maximizing sales opportunities.
            </div>
          </div>
        </div>
      </div>
      <div
        class="u-flex-1 u-display-flex u-flex-align-items-center u-flex-justify-content-center"
      >
        <div class="u-display-flex u-width-400px">
          <div class="u-display-flex u-flex-direction-column">
            <div class="u-font-size-4 u-spacing-mb-s">Welcome to</div>
            <div
              class="u-font-size-1 u-font-weight-bold u-spacing-mb-xl u-color-grey-light"
            >
              <img
                style="width: 200px"
                src="/images/logo.svg"
              />
            </div>
            <!-- <div class="u-display-flex u-color-red-base u-spacing-mt-l u-flex-align-items-center" v-if="!errorMessage && (sessionMessage || errorFromRedirect)">
              <div class="u-font-size-7">{{errorFromRedirect ? errorFromRedirect : 'Session expired. Please login again to access.'}}</div>
            </div> -->
            <form
              class="u-width-400px"
              @submit.prevent="login()"
            >
              <div class="u-spacing-mt-l">
                <label
                  class="u-display-block u-font-size-6 u-font-weight-600 u-color-grey-mid-light"
                  >Email ID</label
                >
                <input
                  v-model="username"
                  class="u-spacing-mv-xs u-font-size-6 u-spacing-p-s"
                  :class="{ error: formTouched && username === '' }"
                  placeholder="tim@emailclient.com"
                  type="text"
                  name="username"
                />
                <label
                  v-if="formTouched && username === ''"
                  class="u-color-red-base u-font-size-7"
                  >Please enter an email ID.</label
                >
              </div>

              <div class="u-spacing-mt-xl">
                <label
                  class="u-display-block u-font-size-6 u-font-weight-600 u-color-grey-mid-light"
                  >Password</label
                >
                <input
                  v-model="password"
                  class="u-spacing-mv-xs u-font-size-6 u-spacing-p-s"
                  :class="{ error: formTouched && password === '' }"
                  placeholder="Please keep it to yourself"
                  type="password"
                  name="password"
                />
                <label
                  v-if="formTouched && password === ''"
                  class="u-color-red-base u-font-size-7"
                  >Please enter the password.</label
                >
              </div>

              <div
                v-if="errorMessage && showErrorMsg"
                class="u-display-flex u-color-red-base u-spacing-mt-l u-flex-align-items-center"
              >
                <div><rb-icon icon="cross-fill-circle" /></div>
                <div
                  v-if="attemptsCount < 5"
                  class="u-spacing-ml-xs u-font-size-7"
                >
                  {{ errorMessage }}
                  <span v-if="attemptsCount > 2"
                    >You have tried to login {{ attemptsCount }} times within
                    the last 30 minutes. For security reasons, your user account
                    will be locked for next 30 minutes if you enter the wrong
                    credentials {{ 5 - attemptsCount }} more times.</span
                  >
                </div>
                <div
                  v-if="attemptsCount > 4"
                  class="u-spacing-ml-xs u-font-size-7"
                >
                  Your account has been locked due to too many failed login
                  attempts (username/password mismatch). It is done to protect
                  sensitive information from potential hacking attempts. Please
                  try again after 30 minutes.
                </div>
              </div>

              <div
                class="u-spacing-mt-l u-display-flex u-flex-justify-content-space-between"
              >
                <div class="u-width-50 u-color-grey-light">
                  <rb-checkbox v-model="rememberme">
                    Keep me signed in
                  </rb-checkbox>
                </div>
                <div class="u-display-flex u-flex-align-items-center">
                  <!-- eslint-disable -->
                  <router-link
                    to="/forgot-password"
                    style="font-weight: 400"
                    class="u-cursor-pointer u-font-size-5 link"
                    :class="{
                      'u-color-blue-base': formTouched,
                      'u-color-grey-lighter': !formTouched
                    }"
                    v-html="'Forgot password?'"
                  />
                  <!-- eslint-enable -->
                </div>
              </div>

              <div class="u-spacing-mt-l">
                <rb-button
                  :click-fn="login"
                  class="u-width-100"
                  :text="'Login'"
                  :loading="loginLoading"
                  :disabled="loginLoading"
                  :type="'filled'"
                />
              </div>
              <input
                class="u-display-none"
                type="submit"
                value="ok"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';

export default {
  components: {
    loader
  },
  data() {
    return {
      username: '',
      password: '',
      formTouched: false,
      rememberme: true,
      showErrorMsg: true,
      internalDomains: [
        'boomerangcommerce.com',
        'rboomerang.com',
        'boomerangvendor.com',
        'commerceiq.ai'
      ]
    };
  },
  computed: {
    loginLoading() {
      return this.$store.state.login.loginLoading;
    },
    errorMessage() {
      if (this.$store.getters.getErrorMessage) {
        this.formTouched = true;
      }
      return this.$store.getters.getErrorMessage;
    },
    sessionMessage() {
      return window.location.href.indexOf('returnslink') !== -1;
    },
    errorFromRedirect() {
      if (window.location.href.indexOf('interror=domainNotAllowed') !== -1) {
        return 'email used to login is not allowed';
      } else if (window.location.href.indexOf('interror=failedLogin') !== -1) {
        return 'login failed';
      }
      return false;
    },
    attemptsCount() {
      return this.$store.getters.getAttemptsCount;
    },
    fallback() {
      return this.$store.getters.getFallback;
    },
    sessionValidity() {
      return this.$store.getters.getSessionValidity === 1;
    }
  },
  watch: {
    fallback: function (val) {
      if (val) {
        if (this.internalDomains.indexOf(this.username.split('@')[1]) > -1) {
          document.location.href = this.oauthLink();
          this.showErrorMsg = false;
        }
      }
    }
  },
  beforeCreate() {
    localStorage.removeItem('browserIncompatibilityWidgetClosed');
  },
  beforeRouteEnter(to, from, next) {
    if (from && (from.path || '').length > 1) {
      window.location.href = to.fullPath;
    }
    next();
  },
  methods: {
    login() {
      localStorage.removeItem('clientName');
      localStorage.removeItem('showE2EBanner');
      if (
        this.password === '' &&
        this.internalDomains.indexOf(this.username.split('@')[1]) > -1
      ) {
        document.location.href = this.oauthLink();
      } else {
        const loginParams = {
          username: this.username,
          password: this.password,
          rememberme: this.rememberme
        };
        this.$store.dispatch('login', loginParams);
        return false;
      }
    },
    oauthLink() {
      return (
        'https://auth.rboomerang.com/api/google?ref=' +
        encodeURIComponent(document.location.href)
      );
    }
  }
};
</script>

<style lang="css" scoped>
input {
  width: 382px;
  border-radius: 2px;
  border: 1px solid #e9eaeb;
  outline: none;
}
input::placeholder {
  color: #caccce;
}
input.error {
  border-color: #d7263d;
}
input:focus {
  border-color: #007cf6;
}

.link:hover {
  color: #007cf6;
  text-decoration: none;
}
</style>
