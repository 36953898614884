import Vue from 'vue';
import { store } from '@/store/store';
import Router from 'vue-router';
import { routeDecider } from './routeDecider';
import constants from '@/utils/constants';
// Modules
import Login from '@/components/login.vue';
import Register from '@/components/register.vue';
import ForgotPassword from '@/components/forgotPassword.vue';
import SetOrResetPassword from '@/components/setOrResetPassword.vue';
import NotFound from '@/pages/notFound';
import axios from 'axios';

// Pages
// import Home from '@/components/home.vue';
// import Recommendations from '@/components/pages/recommendations/recommendations.vue';
// import RecommendationsRoute from '@/components/pages/recommendations/route.js';
// import Data from '@/components/pages/data/data.vue'
// import Strategies from '@/components/pages/strategies/strategies.vue';
// import PerformanceDashboard from '@/components/pages/performance/performance.vue';
// import PerformanceDashboard from '@/components/pages/performance/performance.vue';

import urlHelper from '@/utils/helpers/url';
import logger from '@/utils/helpers/logger';
import { filters } from '@ciq-dev/ciq-ui';
import { fetchPostProcessingStatusAtInterval } from '../pages/toolsAndSettings/entityTagging/entityUtils';
import { Entities } from '@/pages/toolsAndSettings/const';
// // var campaignManagementNavigation = null;
// eventBus.$on('campaignManagementNavigation', function (data) {
//   campaignManagementNavigation = data;
// });
// var recommendationNavigation = null;
// eventBus.$on('recommendationNavigation', function (data) {
//   recommendationNavigation = data;
// });

Vue.use(Router);
Vue.use(logger);
Vue.prototype.$eventKey = null;
// console.log(Vue.prototype);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword
    },
    {
      path: '/reset',
      name: 'reset-password',
      component: SetOrResetPassword,
      props: (route) => {
        return {
          id: route.query.id,
          action: 'resetPassword'
        };
      }
    },
    {
      path: '/welcome',
      name: 'set-password',
      component: SetOrResetPassword,
      props: (route) => {
        return {
          id: route.query.id,
          action: 'setPassword'
        };
      }
    },
    {
      path: '*',
      component: NotFound,
      name: '404'
    }
  ]
});

router.beforeEach((to, from, next) => {
  const client = location.hostname.split('.')[0];
  if (client === 'data-pipeline') {
    if (to.name !== 'login' && to.name !== 'e2e-overview') {
      next({ name: 'e2e-overview' });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to, from, failure) => {
  let bShouldRefresh = false;
  const reloadLinks = (
    filters.config_check('feature.polymorphism.reporting_page_ids') ||
    'kiosk,/retail/reports'
  ).split(',');
  for (const linkPath of reloadLinks) {
    if (to.fullPath.includes(linkPath) && from.fullPath.includes(linkPath)) {
      return;
    }
    if (to.fullPath.includes(linkPath) || from.fullPath.includes(linkPath)) {
      bShouldRefresh = true;
    }
  }
  if (bShouldRefresh && !to.query.refresh) {
    const newPath = router.resolve({ query: { refresh: true, ...to.query } });
    location.replace(newPath.href);
  } else {
    const query = to.query;
    delete query.refresh;
    router.push({ query: { ...query } });
  }
});

router.beforeEach((to, from, next) => {
  const publicPages = constants.publicPages;
  if (publicPages.indexOf(to.path) === -1) {
    if (store.getters.getSessionValidity) {
      next();
    } else {
      const routeParams = {
        query: to.query,
        path: to.path
      };
      store.dispatch('getSessionData', routeParams);
    }
  } else {
    if (store.getters.getSessionValidity) {
      routeDecider(store.getters.getSessionUser);
    } else {
      next();
    }
  }
});

router.beforeEach((to, from, next) => {
  if (!store.getters.getMaxDate && store.getters.getSessionValidity) {
    store.dispatch('fetchMetricHotRefreshDates');
    fetchPostProcessingStatusAtInterval();
    if (constants.callFetchMaxDatesPages.includes(to.name)) {
      store.dispatch('getMaxDate', next);
    } else {
      next();
    }
  } else {
    next();
  }
});

// Used across the application to get distribution type. Also used in sales overview, Performance, price war
router.beforeEach((to, from, next) => {
  if (
    !store.getters.getAsinDistributionTypeMap &&
    constants.callGetCatalogAsinPages.includes(to.name)
  ) {
    store.dispatch('getCatalogAsins', { next, to });
    if (!constants.callGetCatalogAsinInSync.includes(to.name)) {
      next();
    }
  } else {
    setTimeout(() => {
      next();
    }, 0);
  }
});

router.beforeEach((to, from, next) => {
  // Call the page wise min_max date API whenever we land on the SKUs or reports page
  const dateHotReloadPageNames = ['Financial', 'Sales Overview', 'SKUs'];

  // Check if the current page is the one we need
  const isHotReloadRequired = dateHotReloadPageNames.includes(to.name);

  // Check if the config for hot reload is enabled or not
  const enabledToShowHotRefreshDates = filters.config_check(
    'feature.hot_metric_refresh.enable'
  );

  // Call the dates API
  if (isHotReloadRequired && enabledToShowHotRefreshDates) {
    // Don't call thr API if we navigate tabs on the SKU page
    if (!(to.name === from.name)) {
      store.dispatch('getMaxDate', next);
    }
  }

  // @TODO: We can move this dispatch to page specific, it's used in sales overview, Performance, price war
  // if (to.path !== '/Business-Overview' && to.path.indexOf('/recommendations/') !== 0) {
  //   store.dispatch('getCatalogAsins');
  // }
  // NewRelic Page Metric
  const pagesData = urlHelper.getPageHierarchy(decodeURIComponent(to.fullPath));
  if (window.newrelic) {
    window.newrelic.setCustomAttribute('page', pagesData.page);
    window.newrelic.setCustomAttribute('path', pagesData.path);
    window.newrelic.setCustomAttribute('subTab', pagesData.subTab);
    window.newrelic.setCustomAttribute('tab', pagesData.tab);
  }

  // Inline Manual - Update Player on State Changes.
  if (window.inline_manual_player && window.inline_manual_player.update) {
    window.inline_manual_player.update();
  }

  // On Refresh, read the to.fullPath otherwise read from.fullPath
  if (from.fullPath && from.fullPath === '/') {
    if (to.fullPath) {
      store.dispatch('updatePreviousPage', to.fullPath);
    }
  } else if (from.fullPath && from.fullPath !== '/') {
    store.dispatch('updatePreviousPage', from.fullPath);
  }

  if (to.query && to.query.emailFilters) {
    const filters = JSON.parse(
      window.atob(decodeURIComponent(to.query.emailFilters))
    );
    // let query = Object.assign({}, to.query);
    // delete query.emailFilters;
    // router.replace({ query });
    localStorage.removeItem('recommendations-filters');
    localStorage.removeItem('recommendations-filters-order');
    if (filters.length > 0) {
      const recommendationFilterOrder = Object.keys(filters[0]);
      const recommendationFilter = {};
      Object.keys(filters[0]).map((item) => {
        recommendationFilter[item] = filters[0][item].values;
        return recommendationFilter;
      });
      if (recommendationFilterOrder.length > 0) {
        localStorage.setItem(
          'recommendations-filters-order',
          JSON.stringify(recommendationFilterOrder)
        );
        localStorage.setItem(
          'recommendations-filters',
          JSON.stringify(recommendationFilter)
        );
      }
    }
  }

  if (Vue.prototype.$eventKey === true) {
    if (from.path === '/hourly_bidder/edit' && to.path === '/hourly_bidder') {
      next();
      return;
    }
    Vue.prototype.$eventKey = null;
    window.open(to.fullPath, '_blank');
    next(false);
  } else {
    next();
  }
});

// Changes to handle SDP Navigation
router.beforeEach((to, from, next) => {
  localStorage.removeItem('SDP-saveSelection');
  if (
    window.location.href.includes('product-demo') ||
    window.location.href.includes('cpg.vision')
  ) {
    if (to.name === 'SkuDetails') {
      if (from.name === 'AMS SKUs') {
        if (to?.params?.id !== 'BS700TDOGH5') {
          router.push({ name: 'SkuDetails', params: { id: 'BS700TDOGH5' } });
        }
        localStorage.setItem(
          'SDP-saveSelection',
          JSON.stringify({
            metric: [
              { key: 'AMS Spend', selected: true },
              { key: 'On hand inventory', selected: true },
              { key: 'Paid sales 14 days' },
              { key: 'ACoS 14 Days', selected: true },
              { key: 'OPS' }
            ],
            template: 'Custom',
            defaultRangeSelect: 'last30Days',
            event: [
              { key: 'Unavailable', selected: true },
              { key: 'Search exit from Page 1', selected: true }
            ]
          })
        );
      } else if (
        to?.params?.id !== 'BP5WZ0B01C0' &&
        to?.params?.id !== 'BS700TDOGH5'
      ) {
        router.push({ name: 'SkuDetails', params: { id: 'BP5WZ0B01C0' } });
        localStorage.setItem(
          'SDP-saveSelection',
          JSON.stringify({
            metric: [
              { key: 'OPS', selected: true },
              { key: 'Unit Conversion', selected: true },
              { key: 'Glance Views', selected: true }
            ],
            template: 'Custom',
            defaultRangeSelect: 'last30Days',
            event: [
              { key: 'Content Change', selected: true },
              { key: '3P Variant', selected: true }
            ]
          })
        );
      }
    }
  }
  next();
});

// for unmapped campaign redirection
router.beforeEach((to, from, next) => {
  const path = '/tools_and_settings/campaign';
  if (!to.fullPath.includes(path)) {
    localStorage.removeItem('taxonomyPersistedFilters');
    store.dispatch('clearPersistedFilters', Entities.campaign);
  }
  next();
});

router.beforeEach((to, from, next) => {
  const retailerName = store.getters.getRetailer;
  const isRetailerRedirectEnabled = filters.config_check(
    'feature.redirectRetailer.enable'
  );
  if (!isRetailerRedirectEnabled) {
    next();
    return;
  } else {
    const retailerRedirectConfig = JSON.parse(
      filters.config_check('feature.redirectRetailer.config')
    );
    if (
      isRetailerRedirectEnabled &&
      retailerRedirectConfig[retailerName] &&
      !retailerRedirectConfig[retailerName]?.excluded_pages?.includes(to.name)
    ) {
      axios.defaults.headers.retailer =
        retailerRedirectConfig[retailerName]?.redirect_retailer;
    } else {
      axios.defaults.headers.retailer = retailerName;
    }
  }
  next();
});

export default router;
