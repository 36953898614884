import ImageWithTextHeader from '@/components/pages/insights/amazon/cva/atoms/ImageWithTextHeader.vue';
import BaseWidgetService from '@/components/pages/insights/amazon/cva/services/BaseWidgetService.js';
import HttpService from '@/utils/services/http-service';
import { cloneDeep } from 'lodash';
import {
  observationFormatter,
  checkForNoDataRow,
  spotlightContent,
  rem1V2ConfigEnabled
} from '@/components/pages/insights/amazon/cva/utils';
import { store } from '@/store/store';
import { eventBus } from '@/utils/services/eventBus';

export default class TableService extends BaseWidgetService {
  isDetailedView = false;
  dimensionList = [];
  tableData = [];
  allInsights = [];
  spotlights = [];
  allInsightsLoading = false;
  spotlightError = false;
  availableDimension = [];
  cdnUrl = 'https://cdn.rboomerang.com/cva/';
  constructor(page, widget, globalViewId, pageId, isDetailedView) {
    super(page, widget, globalViewId, pageId);
    this.isDetailedView = isDetailedView;
  }

  allModules = ['Competitor Landscape'];

  actionHandler(payload) {
    eventBus.$emit('toggleExpandable', payload);
  }

  async fetchData(pageSettings) {
    const pageSettingsCopy = cloneDeep(pageSettings);
    try {
      this.loading = true;
      this.allInsightsLoading = true;
      this.error = false;
      this.spotlightError = false;
      await this.fetchDimensionList();
      await this.fetchDimensionData(pageSettingsCopy);
      this.fetchInsights(this.allModules, pageSettings, 100)
        .then((data) => {
          const insights = data?.data?.entityData;
          this.allInsights = [];
          let insightsSpotlight = [];
          this.spotlights = [];

          let spotlightMetric = [
            'insight_header',
            'insight_with_metadata',
            'cta',
            'cta_link'
          ];
          for (const entity of insights) {
            let spotlightTemplate = {};
            if (entity?.entityValue) {
              this.allInsights.push(observationFormatter(entity?.entityValue));
            }
            let loadSpotlights = false;
            let spotlightTemp = this.constructSpotlight(
              entity,
              spotlightMetric,
              loadSpotlights,
              spotlightTemplate
            );
            spotlightTemplate = spotlightTemp?.spotlightTemplate;
            loadSpotlights = spotlightTemp?.loadSpotlights;
            if (loadSpotlights) {
              this.spotlightList(spotlightTemplate, insightsSpotlight);
            }
          }
          this.allInsightsLoading = false;
        })
        .catch((e) => {
          this.error = true;
          this.spotlightError = true;
          this.spotlights = [];
          this.allInsightsLoading = false;
        });
      this.computeRowData();
      if (rem1V2ConfigEnabled()) {
        this.getDynamicColumnsMetadata();
      }
    } catch (e) {
      this.error = true;
      this.spotlightError = true;
      this.spotlights = [];
      this.tableData = [];
      this.allInsightsLoading = false;
    } finally {
      this.loading = false;
    }
  }

  constructSpotlight(
    entity,
    spotlightMetric,
    loadSpotlights,
    spotlightTemplate
  ) {
    entity.data.forEach((item) => {
      if (spotlightMetric?.indexOf(item.name) >= 0) {
        let metric = spotlightMetric?.[spotlightMetric.indexOf(item.name)];
        if (item.name === 'insight_with_metadata') {
          loadSpotlights = true;
          item.RESULT[metric] = spotlightContent(item?.RESULT?.[metric]);
        }
        spotlightTemplate[metric] = item?.RESULT?.[metric];
      }
    });
    return { spotlightTemplate, loadSpotlights };
  }

  spotlightList(spotlightTemplate, insightsSpotlight) {
    let cardAction = {};
    if (spotlightTemplate?.cta_link) {
      cardAction = this.getDimensionData(spotlightTemplate);
      cardAction.defaultTab = spotlightTemplate.cta_link.split('|')[1];
    }
    if (
      this.availableDimension.includes(
        spotlightTemplate?.cta_link?.split('|')?.[0]
      )
    ) {
      insightsSpotlight.push({
        heading: spotlightTemplate?.insight_header,
        content: spotlightTemplate?.insight_with_metadata,
        cta_link: spotlightTemplate?.cta_link,
        actions: [
          {
            text: spotlightTemplate?.cta,
            handler: () => {
              this.actionHandler(cardAction);
            }
          }
        ]
      });
      this.spotlights = insightsSpotlight;
    }
  }

  getDimensionData(spotlightTemplate) {
    let cardAction;
    for (
      let dimension = 0;
      dimension <= this.dimensionList.length - 1;
      dimension++
    ) {
      if (
        this.dimensionList[dimension].DIMENSION_NAME ===
        spotlightTemplate?.cta_link.split('|')[0]
      ) {
        cardAction = {
          label: this.dimensionList[dimension]?.DIMENSION_LABEL,
          desc: this.dimensionList[dimension]?.desc,
          name: this.dimensionList[dimension].DIMENSION_NAME
        };
        break;
      }
    }
    return cardAction;
  }

  async fetchDimensionList() {
    const body = {
      entityType: 'dimension',
      operations: {
        system: 'cva'
      },
      where: {
        dimensionNameValueList: []
      }
    };
    if (rem1V2ConfigEnabled()) {
      body.where.dimensionNameValueList.push(this.getRem1RetailerPayload());
    }
    const data = await HttpService.post('DASHBOARD_SERVICE', body, {
      append: '/cat_analytics/dms/data'
    });
    this.dimensionList = data.data.response;
  }

  async fetchDimensionData(pageSettings) {
    const body = {
      page: 'customer_value_assesment',
      widget: 'cva_leader_laggard',
      entityType: 'brand',
      metricsList: rem1V2ConfigEnabled()
        ? ['cva_comp_landscape_score_percentage_v2']
        : ['cva_comp_landscape_score_percentage'],
      dimensionsList: [
        'cohort_rank',
        'cohort',
        'attributes',
        'time_period',
        'brand',
        'client_flag',
        'score',
        'browse_node',
        'level',
        'client_id',
        'module',
        'sop_lower',
        'sop_higher'
      ],
      eventsList: [],
      enablePaginationCount: true,
      operations: {
        system: 'cva',
        enableNewPVPFormulaForSOV: false,
        enableDedupBeforeRollup: false,
        additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
        orderByList: [
          {
            dimension: 'client_flag',
            direction: 'ASC'
          },
          {
            dimension: 'score',
            direction: 'DESC'
          }
        ],
        limit: 1000,
        page: 1,
        outerWhereClause: {
          dimensionNameValueList: []
        }
      },
      where: {
        dimensionNameValueList: [
          {
            dimensionName: 'time_period',
            dimensionValue: 'L90D',
            operator: 'EQUAL_TO'
          },
          {
            dimensionName: 'cohort_rank',
            dimensionValue: '10',
            operator: 'LESS_THAN_OR_EQUAL_TO'
          }
        ]
      }
    };
    if (rem1V2ConfigEnabled()) {
      body.where.dimensionNameValueList.push(this.getRem1RetailerPayload());
    }
    this.dimensionList.forEach((dimension) => {
      body.where.dimensionNameValueList.push({
        dimensionName: 'module',
        dimensionValue: dimension.DIMENSION_NAME,
        operator: 'EQUAL_TO'
      });
    });
    body.where.dimensionNameValueList.push(
      ...(pageSettings?.where?.dimensionNameValueList || [])
    );
    const data = await HttpService.post('DASHBOARD_SERVICE', body, {
      append: '/cat_analytics/data'
    });
    this.data = data.data;
    this.availableDimension = Object.keys(data.data.dimensionData);
  }

  getTooltipHeader(headerName) {
    return `Who is ${
      headerName === 'Upstart/Rising' ? 'an' : 'a'
    } <span class="u-font-weight-bold">${headerName}?</span>`;
  }

  getColumnDefs() {
    return [
      {
        headerName: 'Dimensions',
        headerComponentFramework: ImageWithTextHeader,
        cellRendererFramework: 'DimensionCell',
        field: 'dimension',
        headerComponentParams: {
          enableSorting: false,
          headerText: 'Dimensions',
          imageUrl: this.cdnUrl + 'Dimension.svg',
          color: 'white'
        },
        minWidth: 200,
        maxWidth: 200,
        width: 200,
        cellRendererParams: {
          desc: 'One liner about brand strength',
          dataListLength: this.tableData?.length,
          getDisabledRowValue: checkForNoDataRow
        },
        pinned: 'left'
      },
      {
        headerName: 'Leaders',
        headerComponentFramework: ImageWithTextHeader,
        field: 'Leader',
        headerComponentParams: {
          enableSorting: false,
          imageUrl: this.cdnUrl + 'Leader.svg',
          headerText: 'Leaders',
          tooltipText:
            'Leaders demonstrate best in class performance in the dimensions of Retail Ecommerce Management',
          tooltipHeader: this.getTooltipHeader('Leader')
        },
        minWidth: 250,
        maxWidth: 270,
        width: 270,

        cellRendererFramework: 'DynamicCellComponent',
        cellRendererParams: {
          component: 'BrandIconCell',
          eventMap: {},
          paramsToProps: this.brandIconParamsToProps.bind(this)
        }
      },
      {
        headerName: 'Challengers',
        headerComponentFramework: ImageWithTextHeader,
        cellRendererFramework: 'DynamicCellComponent',
        cellRendererParams: {
          component: 'BrandIconCell',
          eventMap: {},
          paramsToProps: this.brandIconParamsToProps.bind(this)
        },
        field: 'Challenger',
        headerComponentParams: {
          enableSorting: false,
          headerText: 'Challengers',
          tooltipHeader: this.getTooltipHeader('Challenger'),
          tooltipText:
            'Challengers are competitive in their category and can grow to leaders over time',
          imageUrl: this.cdnUrl + 'Challenger.svg',
          color: '#598df0'
        },
        minWidth: 260,
        maxWidth: 260,
        width: 260
      },
      {
        headerName: 'Followers',
        headerComponentFramework: ImageWithTextHeader,
        cellRendererFramework: 'DynamicCellComponent',
        cellRendererParams: {
          component: 'BrandIconCell',
          eventMap: {},
          paramsToProps: this.brandIconParamsToProps.bind(this)
        },
        field: 'Laggard',
        headerComponentParams: {
          enableSorting: false,
          tooltipHeader: this.getTooltipHeader('Follower'),
          tooltipText:
            'Followers trail their peers and have a large opportunity to improve to best in class ',
          imageUrl: this.cdnUrl + 'Novices.svg',
          headerText: 'Followers'
        },
        minWidth: 260,
        maxWidth: 260,
        width: 260
      },
      {
        headerName: 'Upstarts/Rising',
        headerComponentFramework: ImageWithTextHeader,
        cellRendererFramework: 'DynamicCellComponent',
        cellRendererParams: {
          component: 'BrandIconCell',
          eventMap: {},
          paramsToProps: this.brandIconParamsToProps.bind(this)
        },
        field: 'Upstart',
        headerComponentParams: {
          enableSorting: false,
          headerText: 'Upstarts/Rising',
          tooltipHeader: this.getTooltipHeader('Upstart/Rising'),
          tooltipText:
            'Brands who are new to the market or growing significantly to make an impact in the category',
          imageUrl: this.cdnUrl + 'Upstarts:Rising.svg'
        },
        minWidth: 260,
        maxWidth: 260,
        width: 260
      }
    ];
  }

  customCss(params) {
    const cssClassMap = {
      Leader: [
        'u-bg-color-green-lighter-bg',
        'u-spacing-p-m',
        'u-spacing-pr-xs'
      ],
      Challenger: [
        'u-bg-color-blue-lighter-bg',
        'u-flex-direction-column',
        'u-spacing-p-m',
        'u-spacing-pr-xs'
      ],
      Laggard: [
        'u-bg-color-red-light-bg',
        'u-flex-direction-column',
        'u-spacing-p-m',
        'u-spacing-pr-xs'
      ],
      Upstart: [
        'u-bg-color-yellow-xx-light',
        'u-spacing-p-m',
        'u-spacing-pr-xs'
      ]
    };
    const borderClass =
      params.rowIndex === 0
        ? 'border-top-radius-left-right dashed-border'
        : params.rowIndex === this.tableData.length - 1
        ? 'border-bottom-radius-left-right'
        : 'dashed-border';
    const customCssClass = cssClassMap[params.colDef.field];
    return [borderClass, customCssClass];
  }

  verbMap = {
    Laggard: 'Lagging',
    Challenger: 'Challenging',
    Leader: 'Leading',
    Upstart: 'Rising'
  };

  sopCalculation(sopLower, sopHigher) {
    return sopLower + (sopHigher ? ' - ' + sopHigher : '');
  }

  brandIconParamsToProps(params) {
    let data = params.value;
    let tooltipData;
    const dimensionName = params.data?.dimension?.name;
    data = data?.map((dataEl) => {
      if (dimensionName === 'Pricing Leadership') {
        dataEl.desc = `${this.verbMap[dataEl.cohort]} in ${
          dataEl.attributes
        } Price Range`;
      } else if (dimensionName === 'Assortment') {
        const attributes = JSON.parse(dataEl.attributes);
        const cohorts = ['Leader', 'Challenger', 'Laggard', 'Upstart'];
        let cohort;
        for (let i = 0; i < cohorts.length; i++) {
          if (attributes[cohorts[i]] && parseInt(attributes[cohorts[i]])) {
            cohort = cohorts[i];
            break;
          }
        }
        if (cohort) {
          dataEl.desc = `${this.verbMap[cohort]} in ${attributes?.[cohort]} Sub Categories`;
        } else {
          data.desc = '';
        }
      } else if (dimensionName === 'RnR') {
        const reviewData = JSON.parse(dataEl.attributes);
        dataEl.info = [
          {
            preText: 'Average Reviews: ',
            text: reviewData?.reviews || '-'
          },
          {
            preText: 'Average Rating: ',
            text: reviewData?.ratings || '-',
            textIcon: 'star',
            textIconClasses: 'u-color-orange-base rb-icon--medium'
          }
        ];
      } else if (dimensionName === 'Brand Strength') {
        dataEl.metricData = {
          result: dataEl.score + 'x'
        };
      } else {
        dataEl.metricData = {
          result: dataEl.score_percentage + '%',
          pvp: '-5%'
        };
      }
      const formattedBrandName = dataEl.brand
        .replaceAll(' ', '_')
        .toLowerCase();
      dataEl.imageUrl = `https://cdn.rboomerang.com/images/brands/${formattedBrandName}.jpeg`;
      return dataEl;
    });
    if (params.value) {
      if (dimensionName === 'Pricing Leadership') {
        data = params.value.slice(0, 3);
        if (params.value.length > 3) {
          tooltipData = params.value.slice(3, 6);
        }
      } else {
        data = params.value.slice(0, 2);
        if (params.value.length > 2) {
          tooltipData = params.value.slice(2, 4);
        }
      }
    }
    let hideMetricData = false;
    let hideDesc = false;
    if (dimensionName === 'Ad inefficiency') {
      hideMetricData = true;
    }
    if (dimensionName === 'Assortment') {
      hideDesc = true;
    }
    return {
      customClasses: this.customCss(params),
      hideDesc,
      data: data || [],
      tooltipData: tooltipData || [],
      leader: params.column?.colDef?.field === 'Leader',
      isDetailedView: this.isDetailedView,
      isDisabledRow: checkForNoDataRow(params),
      enableShowMore:
        params.column?.colDef?.field === 'Laggard' ||
        params.column?.colDef?.field === 'Challenger',
      enablePrice: params.column?.colDef?.field !== 'Leader',
      hideMetricData,
      sopCalculation: this.sopCalculation
    };
  }

  computeRowData() {
    const data = (this.dimensionList || []).map((dimension) => {
      const dimensionProp = {
        label: dimension.DIMENSION_LABEL || '',
        desc: dimension.DIMENSION_DESCRIPTION || '',
        name: dimension.DIMENSION_NAME || ''
      };
      dimension.desc = dimension.DIMENSION_DESCRIPTION || '';
      const dataObj =
        this.data?.dimensionData?.[dimension.DIMENSION_NAME]?.data;
      const columns = Object.keys(dataObj || {});
      const returnObj = {};
      columns.forEach((column) => {
        let brandsData = dataObj[column];
        brandsData = brandsData.map((brandData) => {
          return brandData.data.reduce((prevVal, curVal) => {
            if (
              (curVal.name === 'sop_lower' || curVal.name === 'sop_higher') &&
              curVal.RESULT?.[curVal.name]
            ) {
              prevVal[curVal.name] =
                Math.trunc(curVal.RESULT?.[curVal.name] * 100) + '%';
            } else {
              prevVal[curVal.name] = curVal.RESULT?.[curVal.name];
            }
            return prevVal;
          }, {});
        });
        returnObj[column] = brandsData;
      });
      return {
        dimension: dimensionProp,
        ...returnObj
      };
    });
    this.tableData = data;
  }

  getDynamicColumnsMetadata() {
    const amazonRetailId = '4';
    const payload = {
      retailerId:
        store?.getters['cvaRem/getSelectedRem1Retailer']?.ID || amazonRetailId
    };
    store.dispatch('cvaRem/fetchDoubleClickDynamicColumnsMetadata', payload);
  }
}
