<template>
  <div>
    <dashboard-inflator
      class="seller-insights-dashboard"
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions()"
      :filter="filterSettings"
      @requestParamsChange="requestParamsChange"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import salesOverviewCardsWidget from '@/components/widgets/custom_widgets/custom_widget_components/cards/cardsListContainer.vue';
import sellerBreakdown3P from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';

export default {
  mixins: [dashboardMixin],
  data() {
    const pageInfo = {
      id: 24,
      name: 'walmart_3p_seller_insights',
      title: 'Seller Insights'
    };
    const context = this;
    return {
      context,
      dataService: {},
      pageInfo,
      headerOptions: () => {
        return {
          walmart_3p_seller_insights_overview: {
            props: {
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto'
              },
              customContainerStyle: {
                background: '#f5f7fa',
                'box-shadow': 'none'
              }
            }
          },
          walmart_3p_seller_insights_seller_breakdown: {
            props: {
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto auto'
              },
              searchParams: {
                show: true,
                placeholder: 'Search by Seller Name or ID',
                searchDimension: 'search_3p'
              },
              enableDownload: true
            }
          },
          walmart_3p_seller_insights_sku_breakdown: {
            props: {
              headerContainerGridStyles: {
                'grid-template-columns': '1fr auto auto'
              },
              searchParams: {
                show: true
              },
              enableDownload: true
            }
          }
        };
      },
      initRequestParams: {
        global: {
          ...pageInfo,
          ':from': '2021-10-21',
          ':to': '2021-10-27',
          ':page': pageInfo.name,
          ':entityType': '#ALLOVER_AGGREGATE'
        },
        walmart_3p_seller_insights_overview: {
          ':widget': 'walmart_3p_seller_insights_overview',
          ':orderByList': [],
          ':dimensionNameValueList': []
        },
        walmart_3p_seller_insights_seller_breakdown: {
          ':widget': 'walmart_3p_seller_insights_seller_breakdown',
          ':orderByList': [],
          ':dimensionNameValueList': []
        },
        walmart_3p_seller_insights_sku_breakdown: {
          ':widget': 'walmart_3p_seller_insights_sku_breakdown',
          ':orderByList': [],
          ':dimensionNameValueList': []
        }
      },
      filterSettings: {
        emitEvent: 'sellerFilterApply',
        uiKey: 0,
        show: true,
        backendCube: 'walmart_3p_seller_insights',
        defaultDatePostRange: 'last12Months',
        additionalRanges: {
          add: ['last12Months'],
          order: {
            last12Months: 7
          }
        },
        enableSave: false,
        listenerEvent: 'seller-insights-filter',
        allowCompare: false,
        externalFilters: {}
      },
      zipcodesList: []
    };
  },
  watch: {
    requestParams(newVal) {}
  },
  created() {
    this.updateEntireRequestParams(this.initRequestParams);
    Vue.component(
      'walmart_3p_seller_insights_overview',
      salesOverviewCardsWidget
    );
    Vue.component(
      'walmart_3p_seller_insights_seller_breakdown',
      sellerBreakdown3P
    );
    Vue.component(
      'walmart_3p_seller_insights_sku_breakdown',
      sellerBreakdown3P
    );
  },
  methods: {
    metadataUpdated(data) {
      console.log('metadata updated');
    },
    widgetParamsChanged(data) {
      console.log('widgetParams Changed');
    }
  }
};
</script>

<style lang="css">
.seller-insights-dashboard .cardListContainerClass {
  padding-bottom: 0;
  margin-right: 0;
}
.seller-insights-dashboard .cardListContainerClass .custom_widget_body {
  padding-right: 0;
  padding-left: 0;
}
.seller-insights-dashboard .cardListContainerClass .header-container-cwc {
  display: none;
}
</style>
