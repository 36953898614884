import { formatter } from '@/utils/helpers/formatter.js';
import { store } from '@/store/store';
import { eventBus } from '@/utils/services/eventBus';

export const CONSTANTS = {
  dynamicColumnGetter: 'cvaRem/getDoubleClickDynamicColumnMetadata',
  getRem1SelectedRetailerGetter: 'cvaRem/getSelectedRem1Retailer',
  adIncrementality: {
    IncrementalFraction: 80
  },
  contentScore: {
    descriptionLn: 60,
    titleLn: 40,
    bulletPoints: 4,
    images: 4,
    averageRating: 3.5,
    aContent: 'NO',
    videoContent: 'NO'
  },
  brandStrength: {
    top: {
      orderByList: [
        {
          dimension: 'search_frequency_rank',
          direction: 'ASC'
        }
      ],
      limit: 100
    },
    trending: {
      orderByList: [
        {
          dimension: 'cva_bs_search_frequency_rank_percentage_change_v2',
          direction: 'ASC'
        }
      ],
      limit: 20
    },
    declining: {
      orderByList: [
        {
          dimension: 'cva_bs_search_frequency_rank_percentage_change_v2',
          direction: 'DESC'
        }
      ],
      limit: 20
    }
  },
  combinedSovAssortment: {
    top: {
      orderByList: [
        {
          dimension: 'search_frequency_rank',
          direction: 'ASC'
        }
      ],
      limit: 100
    },
    trending: {
      orderByList: [
        {
          dimension: 'cva_sov_combined_sfr_percentage_change_v2',
          direction: 'ASC'
        }
      ],
      limit: 20
    },
    declining: {
      orderByList: [
        {
          dimension: 'cva_sov_combined_sfr_percentage_change_v2',
          direction: 'DESC'
        }
      ],
      limit: 20
    }
  },
  buttonOptions: [
    {
      label: 'Top',
      selected: true,
      value: 'top'
    },
    {
      label: 'Trending',
      value: 'trending'
    },
    {
      label: 'Declining',
      value: 'declining'
    }
  ],
  defaultTrendSelection: 'top',
  dcNameMapping: {
    combinedSovAssortment: 'sov',
    brandStrength: 'bs'
  },
  columnsGroup: [
    {
      headerName: 'Keyword info',
      key: 'keywordInfo',
      children: []
    },
    {
      headerName: 'Organic SoV',
      key: 'organicSov',
      children: []
    },
    {
      headerName: 'Sponsored SoV',
      key: 'sponsoredSov',
      children: []
    }
  ],
  columnGroupMapping: {
    search_term: 'keywordInfo',
    search_frequency_rank: 'keywordInfo',
    ORGANIC_COMP_BRAND: 'organicSov',
    SPONSORED_COMP_BRAND: 'sponsoredSov',
    your_organic_share_of_voice: 'organicSov',
    your_organic_comp_share_of_voice: 'organicSov',
    your_sponsored_share_of_voice: 'sponsoredSov',
    your_sponsored_comp_share_of_voice: 'sponsoredSov'
  }
};

export const getColumnDefinition = (column) => {
  const columnDef = {
    headerName: column.headerName,
    field: column.field,
    type: 'string',
    headerComponentFramework: 'genericTableHeader',
    cellRendererFramework: 'genericTableCell',
    ...column,
    headerComponentParams: {
      enableSorting: true,
      ...column?.headerComponentParams
    },
    cellRendererParams: {
      formatterFn: formatter,
      ...(column.cellRendererParams || {})
    }
  };
  if (column.toolTipText) {
    delete columnDef.toolTipText;
    columnDef.headerComponentParams.toolTipText = column.toolTipText;
  }
  return columnDef;
};

const styleConst = {
  'u-font-style-italic': 'u-font-style-italic',
  'u-color-green-base': 'u-color-green-base',
  'u-text-decoration-underline': 'u-text-decoration-underline',
  'u-font-weight-bold': 'u-font-weight-bold',
  green: 'u-color-green-base',
  red: 'u-color-red-base',
  bold: 'u-font-weight-bold',
  underline: 'u-text-decoration-underline',
  italic: 'u-font-style-italic'
};

export const observationFormatter = (observation) => {
  // RegExp for matching numbers with decimals eg: 25,-25.8,0.01%,20%
  const numberPattern = /-*[0-9]+.{0,1}[0-9]*%*/g;
  return observation?.replace(numberPattern, (match) => {
    return `<span class="u-font-weight-600">${match}</span>`;
  });
};

function stringToArray(str) {
  return str
    .slice(1, -1)
    .split(',')
    .map(function (item) {
      return item.slice(1, -1).trim();
    });
}

export const spotlightContent = (data) => {
  const idenifierReg = /<<\s*([^<>]{0,150})\s*>>/g;
  let tagList = data?.match(idenifierReg);
  tagList?.map((tag) => {
    const classRegex = /\[(.*?)\]/g;
    let attachment = {};
    let dynamicClass = stringToArray(tag?.match(classRegex)[0]);
    let classToApply = [];
    dynamicClass.map((cls) => {
      if (cls.includes('link')) {
        cls.replace(/([^:]+):\s*([^,]+)/g, (_, key, value) => {
          attachment[key.trim()] = value.slice(1, -1).trim();
          return '';
        });
      } else {
        classToApply.push(styleConst[cls]);
      }
    });
    data = data?.replace(classRegex, '');
    tag = tag?.replace(classRegex, '');
    const valueRegex = /<<\s*([^<>]{0,150})\s*>>/;
    let tagsValue = tag?.match(valueRegex);
    if (Object.keys(attachment).length) {
      data = data?.replace(valueRegex, () => {
        return `<a href="${
          attachment.link
        }" target="_blank" class="${classToApply.join(' ')}">${
          tagsValue[1]
        }</a>`;
      });
    } else {
      data = data?.replace(valueRegex, () => {
        return `<span class="${classToApply.join(' ')}">${tagsValue[1]}</span>`;
      });
    }
    return tag;
  });
  return data;
};

export function getRetailerName() {
  if (rem1V2ConfigEnabled()) {
    return store.getters[
      CONSTANTS.getRem1SelectedRetailerGetter
    ].RETAILER.split(' ')[0];
  } else {
    return store.getters.getRetailer;
  }
}

export function getImage(value) {
  const fallBackImageUrl =
    'https://cdn.rboomerang.com/images/brands/NoSKU_Image.svg';
  if (getRetailerName().toLowerCase().includes('amazon')) {
    return value?.IMAGE_URL || fallBackImageUrl;
  } else {
    return value?.IMAGE_URL || null;
  }
}

export const skuParamsToProps = (params) => {
  const value = params.value;
  return {
    activeClass: 'u-color-grey-base',
    asin: value?.ASIN,
    enableSDP: false,
    iconSize: 'rb-icon--medium',
    imageUrl: getImage(value),
    inactiveClass: 'u-color-grey-xxx-light',
    latestAvgReviewRating: value?.RATING,
    latestTotalReviews: value?.RATING_COUNT,
    pdpTooltip: value?.PRODUCT_URL
      ? 'View ' + getRetailerName() + ' detail page'
      : '',
    productPageUrl: value?.PRODUCT_URL,
    showImage: true,
    showRating: true,
    title: value?.TITLE
  };
};

export const getNodePayload = (totalCategories, nodeType) => {
  if (rem1V2ConfigEnabled()) {
    const categoryPayload = [];
    totalCategories.forEach((item) => {
      categoryPayload.push({
        dimensionName: 'browse_node',
        dimensionValue: item.requestParam,
        operator: 'EQUAL_TO'
      });
      if (item.subCategories.length > 0) {
        categoryPayload.push(...getNodePayload(item.subCategories));
      }
    });
    return categoryPayload;
  } else {
    const filteredCategories = totalCategories.filter((item) => {
      return item.DIMENSION[nodeType] !== null;
    });
    const categoryPayload = filteredCategories.map((item) => {
      return {
        dimensionName: 'browse_node',
        dimensionValue: item.DIMENSION.request_param,
        operator: 'EQUAL_TO'
      };
    });
    return categoryPayload;
  }
};

export const competitorScorecardColumnKeys = [
  'Challenger',
  'Leader',
  'Laggard',
  'Upstart'
];

export function checkForNoDataRow(params) {
  const columnData = params?.data || {};
  for (const column of competitorScorecardColumnKeys) {
    if (columnData[column]) {
      return false;
    }
  }
  return true;
}

export const retailersWithSearchVolumeColumn = ['amazonretail'];

/**
 * @description returns list of active columns for given double click widget
 * @param {object} columns - list of default columns for given table
 * @param {String} table - name of double click widget
 * @returns {Array} columns - list of active columns
 */
export function getActiveColumns(columns, table) {
  const activeColumns =
    store?.getters?.[CONSTANTS.dynamicColumnGetter]?.[table] || [];
  if (activeColumns.length > 0) {
    columns = columns.filter((col) => activeColumns.indexOf(col.field) !== -1);
  }
  return columns;
}

export function showSearchVolumeColumn() {
  return retailersWithSearchVolumeColumn.includes(
    store.getters[CONSTANTS.getRem1SelectedRetailerGetter]?.ALIAS
  );
}

export function rem1V2ConfigEnabled() {
  if (window.environment !== 'prod') {
    return getLocalstorageRem1SwitchValue() === 'v2';
  }
  const rem1V2Config =
    store?.getters?.getFeatureEnableConfig?.insights?.rem_v2?.enable || false;
  return rem1V2Config;
}

export function getLocalstorageRem1SwitchValue() {
  const rem1UISwitch = localStorage.getItem('rem1UISwitch') || 'v1';
  return rem1UISwitch;
}

export function getOrderByList(dimension, direction) {
  return [
    {
      dimension: dimension,
      direction: direction
    }
  ];
}

export function isCloseDC(dimensionList, clickedDimension) {
  const isOpen = dimensionList.includes(clickedDimension);
  if (!isOpen) {
    eventBus.$emit('onClose');
  }
}

export const getUpdatedTrendOptions = (buttonOptions, selectedButton) => {
  // Update the selected key based on the clicked button value
  buttonOptions.forEach((option) => {
    option.selected = option.value === selectedButton;
  });
  return buttonOptions;
};

export const getDimensionList = (selectedTrend, dcName) => {
  switch (selectedTrend) {
    case 'trending':
      return [
        {
          dimensionName:
            'cva_' + CONSTANTS.dcNameMapping[dcName] + '_combined_sfr_pvp_v2',
          dimensionValue: '0',
          operator: 'LESS_THAN'
        }
      ];
    case 'declining':
      return [
        {
          dimensionName:
            'cva_' + CONSTANTS.dcNameMapping[dcName] + '_combined_sfr_pvp_v2',
          dimensionValue: '0',
          operator: 'GREATER_THAN'
        }
      ];
    default:
      return [];
  }
};

export const getKeywordColumn = (pvpMetric) => {
  return {
    headerName: 'Keyword Rank',
    field: 'search_frequency_rank',
    headerComponentParams: {
      keyType: 'number',
      toolTipText: 'Latest retailer search frequency rank for the keyword'
    },
    cellRendererFramework: 'progressTableComponent',
    cellRendererParams: {
      showBarColorsOnValueSign: false,
      handleNull: true,
      unit: 'volume',
      keys: {
        keyOne: 'search_frequency_rank',
        keyTwo: 'pre_search_frequency_rank',
        pvp: pvpMetric,
        greaterThanTextColor: '#d7263d',
        lesserThanTextColor: '#3ea95e',
        lesserThanIcon: 'sort-desc',
        greaterThanIcon: 'sort-asc',
        equalToColor: '#fafafa',
        equalToTextColor: '#8b8f93',
        hideComparator: true
      }
    },
    maxWidth: 200
  };
};

export const isAssortment = (clickedDimension) => {
  return clickedDimension === 'Assortment';
};

export const sovDcPayload = (clickedDimension, dcName, selectedFilter) => {
  const body = {
    page: 'customer_value_assesment',
    widget: 'cva_sov_combined',
    entityType: 'search_term',
    metricsList: isAssortment(clickedDimension.name)
      ? [
          'cva_sov_organic_client_sov_v2',
          'cva_sov_organic_comp_sov_v2',
          'cva_sov_your_organic_sov_pvp_v2',
          'cva_sov_your_organic_comp_sov_pvp_v2',
          'cva_sov_combined_sfr_percentage_change_v2',
          'cva_sov_combined_sfr_pvp_v2'
        ]
      : [
          'cva_sov_combined_client_id_v2',
          'cva_sov_organic_client_sov_v2',
          'cva_sov_sponsored_client_sov_v2',
          'cva_sov_organic_comp_sov_v2',
          'cva_sov_sponsored_comp_sov_v2',
          'cva_sov_your_organic_sov_pvp_v2',
          'cva_sov_your_organic_comp_sov_pvp_v2',
          'cva_sov_your_sponsored_sov_pvp_v2',
          'cva_sov_your_sponsored_comp_sov_pvp_v2',
          'cva_sov_combined_sfr_percentage_change_v2',
          'cva_sov_combined_sfr_pvp_v2'
        ],
    dimensionsList: isAssortment(clickedDimension.name)
      ? [
          'search_term',
          'search_frequency_rank',
          'pre_search_frequency_rank',
          'ORGANIC_BRAND',
          'ORGANIC_COMP_BRAND',
          'time_period',
          'ORGANIC_SCORE',
          'ORGANIC_COMP_SCORE',
          'ORGANIC_PRE_SCORE',
          'ORGANIC_COMP_PRE_SCORE',
          'client_id',
          'module',
          'LEVEL',
          'BROWSE_NODE',
          'retailer_id'
        ]
      : [
          'search_term',
          'search_frequency_rank',
          'pre_search_frequency_rank',
          'ORGANIC_BRAND',
          'SPONSORED_BRAND',
          'ORGANIC_COMP_BRAND',
          'SPONSORED_COMP_BRAND',
          'time_period',
          'ORGANIC_SCORE',
          'SPONSORED_SCORE',
          'ORGANIC_COMP_SCORE',
          'SPONSORED_COMP_SCORE',
          'ORGANIC_PRE_SCORE',
          'SPONSORED_PRE_SCORE',
          'ORGANIC_COMP_PRE_SCORE',
          'SPONSORED_COMP_PRE_SCORE',
          'client_id',
          'module',
          'ORGANIC_DATA_TYPE',
          'SPONSORED_DATA_TYPE',
          'ORGANIC_CLIENT_FLAG',
          'SPONSORED_CLIENT_FLAG',
          'SPONSORED_COMP_BRAND',
          'LEVEL',
          'BROWSE_NODE',
          'retailer_id'
        ],
    eventsList: [],
    enablePaginationCount: true,
    operations: {
      system: 'cva',
      enableNewPVPFormulaForSOV: false,
      enableDedupBeforeRollup: false,
      additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
      orderByList: CONSTANTS[dcName]?.[selectedFilter]?.orderByList,
      limit: CONSTANTS[dcName]?.[selectedFilter]?.limit,
      page: 1,
      outerWhereClause: {
        dimensionNameValueList: []
      }
    },
    where: {
      dimensionNameValueList: [
        {
          dimensionName: 'time_period',
          dimensionValue: 'L90D',
          operator: 'EQUAL_TO'
        },
        {
          dimensionName: 'module',
          dimensionValue: 'SoV_SKU',
          operator: 'EQUAL_TO'
        },
        ...getDimensionList(selectedFilter, dcName)
      ]
    }
  };
  if (isAssortment(clickedDimension.name)) {
    body.where.dimensionNameValueList.push({
      dimensionName: 'cva_sov_organic_client_sov_v2',
      operator: 'IS_NULL'
    });
  }
  return body;
};
