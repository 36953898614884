<template>
  <div>
    <div
      class="flex-horizontally reported-issue-tabs u-spacing-pl-l u-spacing-pr-l"
    >
      <div
        v-for="tab in tabs"
        :key="tab.id"
        class="reported-issue-tab"
        :class="{
          'reported-issue-tab-active': selectedTab && selectedTab.id === tab.id
        }"
        @click="selectTab(tab)"
      >
        <div v-if="shouldShowTab(tab)">
          {{ tab.name }}
        </div>
      </div>
    </div>
    <div class="select-field u-spacing-p-m">
      <div class="flex-horizontally u-font-size-5 u-color-white-light">
        <rb-select
          v-show="selectedTab.id !== urgentTabId"
          class="valueDropdown"
          :send-details="true"
          :is-multi-select="true"
          :on-close="prioritySelected"
          :options="priorities"
          class-name="ticket-select u-spacing-mr-m"
          :search-enabled="true"
          place-holder="Select Priority"
          @change="(data) => handleSelectionChange(data, selectionTypePriority)"
        >
          <div
            slot="trigger"
            class="flex-horizontally u-spacing-p-s u-cursor-pointer u-width-100"
          >
            <div class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis">
              {{ placeholders.priority }}
            </div>
            <rb-icon
              class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base"
              icon="caret-down"
            />
          </div>
          <div
            slot="footer"
            class="u-display-flex u-spacing-pb-s u-spacing-pt-m u-border-top u-border-width-s u-border-color-grey-xxx-light u-margin-top-10"
          >
            <div
              class="u-flex-justify-content-flex-start u-font-weight-400 u-line-height-27 u-spacing-pl-s u-font-size-7 u-font-style-italic"
            >
              *Note: Max {{ maxSelectionLimits['priority'] }} options can be
              selected
            </div>
            <div class="u-flex-justify-content-flex-end u-spacing-pl-xl">
              <rb-button
                :click-fn="clickOutside"
                :type="'filled'"
                text="Apply"
                :disabled="isSubmitForPriorityDisabled"
                size="s"
              />
            </div>
          </div>
        </rb-select>
        <rb-select
          class="valueDropdown"
          :send-details="true"
          :is-multi-select="true"
          :on-close="ticketTypeSelected"
          :options="ticketTypes"
          class-name="ticket-select u-spacing-mr-m"
          :search-enabled="true"
          place-holder="Select Ticket Types"
          @change="
            (data) => handleSelectionChange(data, selectionTypeTicketType)
          "
        >
          <div
            slot="trigger"
            class="flex-horizontally u-spacing-p-s u-cursor-pointer u-width-100"
          >
            <div class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis">
              {{ placeholders.ticketType }}
            </div>
            <rb-icon
              class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base"
              icon="caret-down"
            />
          </div>
          <div
            slot="footer"
            class="u-display-flex u-spacing-pb-s u-spacing-pt-m u-border-top u-border-width-s u-border-color-grey-xxx-light u-margin-top-10"
          >
            <div
              class="u-flex-justify-content-flex-start u-font-weight-400 u-line-height-27 u-spacing-pl-s u-font-size-7 u-font-style-italic"
            >
              *Note: Max {{ maxSelectionLimits['type'] }} options can be
              selected
            </div>
            <div class="u-flex-justify-content-flex-end u-spacing-pl-xl">
              <rb-button
                :click-fn="clickOutside"
                :type="'filled'"
                text="Apply"
                :disabled="isSubmitForTypeDisabled"
                size="s"
              />
            </div>
          </div>
        </rb-select>
        <rb-select
          class="valueDropdown"
          :send-details="true"
          :is-multi-select="true"
          :on-close="statusSelected"
          :options="ticketStatus"
          class-name="ticket-select u-spacing-mr-m"
          :search-enabled="true"
          place-holder="Select Status"
          @change="(data) => handleSelectionChange(data, selectionTypeStatus)"
        >
          <div
            slot="trigger"
            class="flex-horizontally u-spacing-p-s u-cursor-pointer u-width-100"
          >
            <div class="u-flex-1 u-font-size-5 u-text-overflow-ellipsis">
              {{ placeholders.status }}
            </div>
            <rb-icon
              class="u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base"
              icon="caret-down"
            />
          </div>
          <div
            slot="footer"
            class="u-display-flex u-spacing-pb-s u-spacing-pt-m u-border-top u-border-width-s u-border-color-grey-xxx-light u-margin-top-10"
          >
            <div
              class="u-flex-justify-content-flex-start u-font-weight-400 u-line-height-27 u-spacing-pl-s u-font-size-7 u-font-style-italic"
            >
              *Note: Max {{ maxSelectionLimits['status'] }} options can be
              selected
            </div>
            <div class="u-flex-justify-content-flex-end u-spacing-pl-xl">
              <rb-button
                :click-fn="clickOutside"
                :type="'filled'"
                text="Apply"
                :disabled="isSubmitForStatusDisabled"
                size="s"
              />
            </div>
          </div>
        </rb-select>
        <div class="dummy-element">&nbsp;</div>
      </div>
    </div>
    <loader
      :loading="loading"
      class="u-display-flex u-flex-justify-content-center u-width-100 u-height-100"
      :color="'#007cf6'"
    />
    <div>
      <div
        v-if="tickets.length > 0"
        class="ticket-list"
      >
        <div
          v-for="item in tickets"
          :key="item.id"
          class="ticket-list-row u-spacing-mb-m u-spacing-pl-l u-spacing-pt-l u-border-width-s"
        >
          <div class="ticket-details">
            <div class="u-display-flex u-flex-direction-row">
              <div class="u-spacing-pt-m u-spacing-pl-s">
                <div
                  class="ticket-priority u-border-top u-border-bottom u-border-left u-border-right u-border-color-grey-xxx-light u-border-radius-s u-padding-10"
                >
                  {{ priorityLabel(item.priority) }}
                </div>
              </div>
              <div class="u-spacing-pl-l">
                <a
                  class="u-cursor-pointer"
                  @click="openFDTicket(item)"
                >
                  <div
                    class="subject u-color-blue-base u-text-decoration-underline u-spacing-pt-xs"
                  >
                    {{ item.subject }}
                  </div>
                  <div class="ticket-created-at">
                    Raised on {{ formatDate(item.created_at) }}
                  </div>
                  <div
                    class="u-display-flex u-flex-direction-row u-spacing-pt-s"
                    style="color: gray"
                  >
                    <div class="flex-horizontally">
                      <div class="title u-spacing-pr-s">Status:</div>
                      {{ getCustomStatus(item.status) }}
                    </div>
                    <div
                      v-if="isInternalUser && displayZoomLink(item)"
                      class="u-spacing-pl-l"
                    >
                      <a
                        class="link"
                        @click="openZoomLink(item)"
                      >
                        Join Meeting Bridge
                      </a>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showViewMoreButton">
          <div v-if="loadingMoreTickets">
            <loader
              :loading="loadingMoreTickets"
              class="u-display-flex u-flex-justify-content-center u-width-100 u-height-100"
              :color="'#007cf6'"
            />
          </div>
          <div v-else>
            <button
              class="view-more"
              @click="loadMoreTickets"
            >
              View More
            </button>
          </div>
        </div>
        <div
          v-if="!showViewMoreButton"
          class="u-text-align-center"
        >
          <div
            class="u-color-grey-lighter u-font-size-5 u-border-width-s u-border-top u-border-bottom u-border-left u-border-right u-border-color-grey-xxx-light u-bg-color-white-base u-margin-bottom-10 u-padding-10 u-width-100"
          >
            No more tickets available
          </div>
          <div>
            <div class="u-font-size-7 u-font-style-italic u-margin-bottom-20">
              *Showing only the latest 300 tickets
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-if="!loading"
          class="no-tickets-found"
        >
          <img
            src="/images/no_sku_found.png"
            alt="no tickets found"
          />
          <div class="no-tickets-found-txt">
            There are no tickets under {{ selectedTab.name }}!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HttpService from '@/utils/services/http-service';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import loader from '@/components/basic/loader';
import CONSTANTS from '@/utils/constants';
import utils from '@/utils/helpers/';
import { cloneDeep } from 'lodash';
import { areArraysEqual } from '@/utils/helpers/utils.js';

export default {
  components: {
    rbFilterPanel,
    loader
  },
  data() {
    return {
      tabs: [],
      allTicketsTabId: '',
      selectedTab: null,
      tabChanged: false,
      tickets: [],
      initParams: [],
      priorities: [],
      ticketStatus: [],
      ticketTypes: [],
      ticketStatusMap: [],
      currentPage: 1,
      totalPages: 1,
      defaultTicketsPerPage: 30,
      maxTotalPages: 10,
      selectedPriority: [],
      selectedStatus: [],
      selectedType: [],
      selectedReporter: null,
      loading: true,
      loadingMoreTickets: false,
      fdTicketUrl: CONSTANTS.FRESHDESK_TICKET_URL,
      zoomMeetLinks: CONSTANTS.ZOOM_MEETING_LINKS,
      isInternalUser: false,
      selectionTypePriority: 'priority',
      selectionTypeStatus: 'status',
      selectionTypeTicketType: 'type',
      urgentTabId: 'urgent',
      totalTicketsPerPageForRaisedByMe: 100,
      isLastPage: false,
      selectFieldMap: {
        priority: 'selectedPriority',
        status: 'selectedStatus',
        type: 'selectedType'
      },
      maxSelectionLimits: {
        priority: 3,
        status: 2,
        type: 3
      },
      priorityToShowZoomLink: 'P0',
      statusToShowZoomLink: 'Processing',
      applyClicked: false,
      previouslySelectedPriority: [],
      previouslySelectedStatus: [],
      previouslySelectedType: [],
      isSubmitForPriorityDisabled: false,
      isSubmitForStatusDisabled: false,
      isSubmitForTypeDisabled: false,
      placeholders: {
        priority: 'Select Priority',
        status: 'Select Type',
        ticketType: 'Select Status'
      }
    };
  },
  computed: {
    priorityLabel() {
      return (priority) => CONSTANTS.PRIORITY_MAP[priority] || 'Unknown';
    },
    showViewMoreButton() {
      if (this.selectedReporter !== null) {
        return !this.isLastPage;
      } else {
        return this.currentPage < this.totalPages;
      }
    }
  },
  watch: {
    selectedPriority(newPriority, oldPriority) {
      if (!this.tabChanged) {
        this.handleFilterSelectionChange(newPriority, oldPriority, 'priority');
      }
      this.placeholders.priority = this.selectFieldPlaceholder(
        this.selectionTypePriority
      );
    },
    selectedStatus(newStatus, oldStatus) {
      if (!this.tabChanged) {
        this.handleFilterSelectionChange(newStatus, oldStatus, 'status');
      }
      this.placeholders.status = this.selectFieldPlaceholder(
        this.selectionTypeStatus
      );
    },
    selectedType(newType, oldType) {
      if (!this.tabChanged) {
        this.handleFilterSelectionChange(newType, oldType, 'type');
      }
      this.placeholders.ticketType = this.selectFieldPlaceholder(
        this.selectionTypeTicketType
      );
    },
    selectedTab(tab, oldTab) {
      // clearing out tickets array and default filters
      this.tickets = [];
      this.loading = true;
      this.clearfilters();
      this.handleTabChange(this.initParams[tab.id]);
    }
  },
  async created() {
    this.tabs = CONSTANTS.FRESHDESK_DASHBOARD_TABS;
    this.allTicketsTabId = CONSTANTS.FRESHDESK_DASHBOARD_TABS.AllTickets.id;
    this.initParams = CONSTANTS.INITIAL_TAB_PARAMS;
    this.priorities = CONSTANTS.PRIORITY_OPTIONS;
    this.ticketStatus = CONSTANTS.STATUS_OPTIONS;
    await this.fetchTicketTypes();
    await this.fetchTicketStatusMap();
    this.isInternalUser = utils.internalUserCheck(window.user);
    this.selectTab(this.tabs.Urgent);
  },
  methods: {
    shouldShowTab(tab) {
      return this.isInternalUser || tab.id !== this.allTicketsTabId;
    },
    selectFieldPlaceholder(selectField) {
      const selectFieldKey = this.selectFieldMap[selectField];
      const selectItems = this[selectFieldKey];
      const selectedOptionsCount = selectItems.length;
      const totalOptionsToSelectFieldMap = {
        [this.selectionTypePriority]: this.priorities.length,
        [this.selectionTypeStatus]: this.ticketStatus.length,
        [this.selectionTypeTicketType]: this.ticketTypes.length
      };
      const totalOptionsCount = totalOptionsToSelectFieldMap[selectField];
      const fieldName =
        selectField.charAt(0).toUpperCase() + selectField.slice(1);
      if (selectedOptionsCount === 0) {
        return 'Select ' + fieldName;
      }
      return `${fieldName}: ${selectedOptionsCount}/${totalOptionsCount} selected`;
    },
    displayZoomLink(item) {
      if (
        item.custom_fields.cf_ciq_sales !== null &&
        this.zoomMeetLinks.hasOwnProperty(item.custom_fields.cf_ciq_sales)
      ) {
        return (
          this.priorityLabel(item.priority) === this.priorityToShowZoomLink &&
          CONSTANTS.FRESHDESK_STATUS_MAP[item.status] ===
            this.statusToShowZoomLink
        );
      }
      return false;
    },
    openZoomLink(item) {
      const url = this.zoomMeetLinks[item.custom_fields.cf_ciq_sales];
      window.open(url, '_blank');
    },
    openFDTicket(item) {
      const url = `${this.fdTicketUrl}/${item.id}`;
      window.open(url, '_blank');
    },
    handleSelectionChange(data, selectionType) {
      const selectedValuesCount = data.length;
      const maxLimit = this.maxSelectionLimits[selectionType];
      const typeToDisabledPropertyMap = {
        [this.selectionTypePriority]: 'isSubmitForPriorityDisabled',
        [this.selectionTypeStatus]: 'isSubmitForStatusDisabled',
        [this.selectionTypeTicketType]: 'isSubmitForTypeDisabled'
      };
      const disabledProperty = typeToDisabledPropertyMap[selectionType];
      this[disabledProperty] = selectedValuesCount > maxLimit;
    },
    formatDate(inputDate) {
      const date = new Date(inputDate);
      const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      };
      const formattedDate = date.toLocaleDateString(undefined, options);
      return formattedDate;
    },
    async fetchTicketTypes() {
      try {
        const response = await HttpService.get('GET_TICKET_TYPES');
        this.ticketTypes = response.data.map((value) => ({
          value: value,
          title: value,
          selected: false
        }));
      } catch (e) {
        this.$snackbar.open({
          message: 'Error encountered while fetching ticket types',
          duration: 2000,
          actionText: 'Failed'
        });
        console.error('Error encountered while fetching ticket types: ', e);
      }
    },
    async fetchTicketStatusMap() {
      try {
        const response = await HttpService.get('GET_TICKET_STATUS');
        this.ticketStatusMap = response.data;
      } catch (e) {
        this.$snackbar.open({
          message: 'Error encountered while fetching ticket status',
          duration: 2000,
          actionText: 'Failed'
        });
        console.error('Error encountered while fetching ticket status: ', e);
      }
    },
    getCustomStatus(statusKey) {
      const status = CONSTANTS.FRESHDESK_STATUS_MAP[statusKey];
      for (const key in this.ticketStatusMap) {
        if (this.ticketStatusMap[key].includes(status)) {
          return key;
        }
      }
      return 'Unknown';
    },
    buildQueryParams(reporter, priority, status, type, page) {
      const params = new URLSearchParams();
      if (reporter !== null) {
        params.append('reporter', reporter);
      }
      priority.forEach((priority) => {
        params.append('priority[]', priority);
      });
      status.forEach((status) => {
        params.append('status[]', status);
      });
      type.forEach((type) => {
        params.append('type[]', type);
      });
      params.append('page', page);
      return `?${params.toString()}`;
    },
    async sendTicketRequest(queryParams) {
      try {
        const res = await HttpService.get('GET_FRESHDESK_TICKETS', {
          append: queryParams
        });
        return res;
      } catch (error) {
        console.error('Error sending ticket request:', error);
        throw error;
      }
    },
    handleSelectFields(data, selectField) {
      this.tabChanged = false;
      const selectedItems = data.filter((item) => item.selected);
      const selectFieldKey = this.selectFieldMap[selectField];
      this[selectFieldKey] = selectedItems.map((item) => item.value);
    },
    clickOutside() {
      this.applyClicked = true;
      document.querySelector('.dummy-element').click();
    },
    prioritySelected(context, data) {
      if (this.applyClicked) {
        this.handleSelectFields(data, this.selectionTypePriority);
        this.applyClicked = false;
        this.previouslySelectedPriority = cloneDeep(data);
      } else {
        const selectedItems = this.previouslySelectedPriority.filter(
          (item) => item.selected
        );
        const previouslySelectedValues = selectedItems.map(
          (item) => item.value
        );
        this.priorities.forEach((item) => {
          item.selected = previouslySelectedValues.includes(item.value);
        });
      }
    },
    statusSelected(context, data) {
      if (this.applyClicked) {
        this.handleSelectFields(data, this.selectionTypeStatus);
        this.applyClicked = false;
        this.previouslySelectedStatus = cloneDeep(data);
      } else {
        const selectedItems = this.previouslySelectedStatus.filter(
          (item) => item.selected
        );
        const previouslySelectedValues = selectedItems.map(
          (item) => item.value
        );
        this.ticketStatus.forEach((item) => {
          item.selected = previouslySelectedValues.includes(item.value);
        });
      }
    },
    ticketTypeSelected(context, data) {
      if (this.applyClicked) {
        this.handleSelectFields(data, this.selectionTypeTicketType);
        this.applyClicked = false;
        this.previouslySelectedType = cloneDeep(data);
      } else {
        const selectedItems = this.previouslySelectedType.filter(
          (item) => item.selected
        );
        const previouslySelectedValues = selectedItems.map(
          (item) => item.value
        );
        this.ticketTypes.forEach((item) => {
          item.selected = previouslySelectedValues.includes(item.value);
        });
      }
    },
    clearfilters() {
      this.priorities.map((item) => (item.selected = false));
      this.ticketStatus.map((item) => (item.selected = false));
      this.ticketTypes.map((item) => (item.selected = false));
    },
    selectTab(tab) {
      const previousTab = this.selectedTab;
      if (previousTab === null || previousTab !== tab) {
        if (previousTab !== null) {
          previousTab.active = false;
        }
        this.tabChanged = true;
        tab.active = true;
        this.selectedTab = tab;
      }
    },
    setPreviouslySelectedFields() {
      this.previouslySelectedPriority = cloneDeep(this.priorities);
      this.previouslySelectedStatus = cloneDeep(this.ticketStatus);
      this.previouslySelectedType = cloneDeep(this.ticketTypes);
    },
    handleTabChange(selectedTabParams) {
      this.isLastPage = false;
      this.priorities.forEach((item) => {
        item.selected = selectedTabParams.priorities.includes(item.value);
      });
      this.ticketStatus.forEach((item) => {
        item.selected = selectedTabParams.ticketStatus.includes(item.value);
      });
      this.ticketTypes.forEach((item) => {
        item.selected = selectedTabParams.ticketTypes.includes(item.value);
      });
      this.selectedPriority = selectedTabParams.priorities;
      this.selectedStatus = selectedTabParams.ticketStatus;
      this.selectedType = selectedTabParams.ticketTypes;
      this.selectedReporter = selectedTabParams.reporter;
      this.currentPage = 1;
      this.setPreviouslySelectedFields();
      const queryParams = this.buildQueryParams(
        this.selectedReporter,
        this.selectedPriority,
        this.selectedStatus,
        this.selectedType,
        this.currentPage
      );
      this.processTicketData(queryParams);
    },
    async processTicketData(queryParams) {
      try {
        const response = await this.sendTicketRequest(queryParams);
        if (this.selectedReporter !== null) {
          if (response.data.total < this.totalTicketsPerPageForRaisedByMe) {
            this.isLastPage = true;
          }
        } else {
          this.totalPages = Math.min(
            this.maxTotalPages,
            Math.ceil(response.data.total / this.defaultTicketsPerPage)
          );
        }
        response.data.results.forEach((item) => this.tickets.push(item));
      } catch (e) {
        this.$snackbar.open({
          message: 'Error encountered while fetching ticket details',
          duration: 2000,
          actionText: 'Failed'
        });
        console.error('Error encountered while fetching ticket details: ', e);
      } finally {
        this.loading = false;
      }
    },
    handleFilterSelectionChange(newValues, oldValues, selectionType) {
      const isValueChanged = !areArraysEqual(newValues, oldValues);
      if (isValueChanged) {
        this.currentPage = 1;
        this.tickets = [];
        this.loading = true;
        this.isLastPage = false;
        const queryParams = this.buildQueryParams(
          this.selectedReporter,
          selectionType === this.selectionTypePriority
            ? newValues
            : this.selectedPriority,
          selectionType === this.selectionTypeStatus
            ? newValues
            : this.selectedStatus,
          selectionType === this.selectionTypeTicketType
            ? newValues
            : this.selectedType,
          this.currentPage
        );
        this.processTicketData(queryParams);
      }
    },
    async loadMoreTickets() {
      this.currentPage++;
      const queryParams = this.buildQueryParams(
        this.selectedReporter,
        this.selectedPriority,
        this.selectedStatus,
        this.selectedType,
        this.currentPage
      );
      this.loadingMoreTickets = true;
      try {
        const response = await this.sendTicketRequest(queryParams);
        if (this.selectedReporter !== null) {
          if (response.data.total < this.totalTicketsPerPageForRaisedByMe) {
            this.isLastPage = true;
          }
        }
        response.data.results.forEach((item) => this.tickets.push(item));
      } catch (e) {
        this.$snackbar.open({
          message: 'Error encountered while loading more tickets',
          duration: 2000,
          actionText: 'Failed'
        });
        console.error('Error encountered while loading more tickets: ', e);
      } finally {
        this.loadingMoreTickets = false;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.flex-horizontally {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ticket-select {
  background-color: #fff;
  width: 18%;
  box-shadow: 0px 1px 3px rgba(43, 51, 59, 0.15);
  border-radius: 4px;
}
.reported-issue-tabs {
  height: 45px;
  box-shadow: 0px 1px 4px #e9eaeb;
}
.reported-issue-tab {
  font-size: 16px;
  font-weight: 500;
  color: #212426;
  cursor: pointer;
}
.ticket-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ticket-list-row {
  width: 70%;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(43, 51, 59, 0.15);
  border-radius: 4px;
}
.ticket-details {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 30px;
}
.ticket-details .ticket-priority {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.ticket-details .subject {
  font-weight: 600;
  line-height: 20px;
}
.ticket-details .ticket-created-at {
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 20px;
  color: gray;
}
.ticket-details .title {
  color: #707070;
  font-weight: 700;
}
.ticket-details .link {
  color: #6941c6;
  cursor: pointer;
}

.reported-issue-tab + .reported-issue-tab {
  margin-left: 40px;
}
.reported-issue-tab-active {
  border-bottom: 2px solid rgb(25, 25, 197);
  padding-bottom: 3px;
}
.select-field {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.view-more {
  background-color: #212426;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.no-tickets-found {
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.no-tickets-found > img {
  width: 403px;
  height: 273px;
  margin-bottom: 30px;
}

.no-tickets-found-txt {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 18px;
  color: #2b333b;
}
</style>
