<template>
  <div
    class="u-display-flex u-flex-direction-column u-font-size-1 u-height-100 u-overflow-auto detail-panel"
  >
    <rightPanelActionLogs
      :show-side-panel="isSidePanelActive"
      :side-panel-content="sidePanelContent"
      title="Action details"
      title-icon="info-circle-fill"
      @hideSidePanel="hideSidePanel"
    />
    <div
      class="u-display-flex u-bg-color-grey-white u-spacing-mb-m u-bg-color-grey-white u-spacing-ph-l sticky u-header-box-shadow"
      style="z-index: 99"
    >
      <rb-filter-panel
        id="wk-actionLogs"
        :key="filterKey"
        :data="filterData"
        :primary="primaryFilterData"
        :secondary-data="secondaryFilterData"
        :listener-event="'actionlog-filters'"
        :state="filterState"
        :loader-status="loaderStatus"
        :config="config.filters"
        :emit="config.filters.emit"
        :allow-date-compare="false"
        :enable-save="false"
        :has-search="true"
        :new-date="true"
        :default-date-post-range="'last30Days'"
        :max-date="currentDate"
        :on-create="onCreate"
        :get-filter-instance="getFilterInstance"
        @savedFilterApplied="filterKey++"
      >
        <div
          slot="leftSide-searchPanel"
          class="u-display-flex u-flex-align-items-center u-spacing-pt-xxs u-spacing-mt-xs"
        >
          <PageTitle :title="'Actions Log'" />
        </div>
      </rb-filter-panel>
    </div>
    <div class="u-display-flex u-spacing-ph-s">
      <filterCard
        v-for="(item, index) in getActionGroupData"
        :key="index"
        :text="item.action_group"
        :count="item.action_count"
        :is-active="item.isActive"
        :emit="config.filterCards.emit"
        :loading="getActionGroupDataLoading"
        :error="getActionGroupDataError"
        @cardClicked="handleCardClick"
      />
    </div>
    <div
      class="u-display-flex u-spacing-p-m u-bg-color-grey-white u-flex-align-items-center u-flex-justify-content-space-between actionspace-dummy u-position-sticky top-0 z-index-2"
    >
      <div class="u-font-weight-600 u-color-grey-lighter u-font-size-3">
        {{ activeCardFilterUpperCase }}
      </div>
      <rb-download-button
        :disabled="
          (getWorkbenchActionLogsData[config.widgets['widget2'].meta.key] || {})
            .rows &&
          (getWorkbenchActionLogsData[config.widgets['widget2'].meta.key] || {})
            .rows.length === 0
        "
        :config="config.widgets['widget2']"
        :column-map="config.widgets['widget2'].body.download.columnMap"
        :file-name="config.widgets['widget2'].body.download.fileName"
      />
    </div>
    <card-widget
      :apply-card-class="false"
      class="u-flex-1 u-bg-color-grey-white u-height-100"
      :config="config.widgets['widget2']"
      :data="
        getWorkbenchActionLogsData[config.widgets['widget2'].meta.key] || {}
      "
    >
      <div
        slot="body"
        class="u-spacing-pb-s"
      >
        <div class="u-display-flex u-flex-wrap-yes u-height-100">
          <rb-insights-table
            :config="config.widgets['widget2']"
            :grid-options="config.widgets['widget2'].body.gridOptions"
            :table-row="
              (
                getWorkbenchActionLogsData[
                  config.widgets['widget2'].meta.key
                ] || {}
              ).rows
            "
            :table-column="config.widgets['widget2'].body.columnDefs"
            class="pagination-overlay"
            :row-selection="rowSelection"
            :row-height="45"
            :enable-client-side-sorting="false"
            :enable-server-side-sorting="true"
            :scroll-to-node-id="scrollToNodeId"
            primary-key="actions_log"
            :pagination="true"
            :pagination-total-key="
              (
                getWorkbenchActionLogsData[
                  config.widgets['widget2'].meta.key
                ] || {}
              ).totalRows
            "
            :pagination-per-page-limit="
              config.widgets['widget2'].body.APIConfig.limit
            "
            :pagination-change-event="
              config.widgets['widget2'].meta.paginationAction
            "
            :row-class-rules="rowClassRules"
            :get-current-instance="getCurrentInstance"
            :show-pagination-options="true"
            :table-header-sticky-obj="sticky"
          />
        </div>
      </div>
    </card-widget>
  </div>
</template>

<script>
// import Vue from 'vue';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import chart from '@/components/basic/chart';
import widgetsUtil from '@/components/widgetMixin';
import metric from '@/components/basic/metric';
import widgetsConfig from './widgets';
import cardWidget from '@/components/widgets/cardWidget';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import { eventBus } from '@/utils/services/eventBus';
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
import utils from '@/utils/helpers/';
import moment from 'moment-timezone';
import rightPanelActionLogs from './rightPanelActionLogs';
import columnConfig from './columnConfig';
import { utcToLocalDate } from '@/utils/helpers/formatter.js';
import filterCard from './filterCard';
import multiRetailerActionLogsConfig from '@/components/pages/action_logs/multiRetailerActionLogsConfig.js';
export default {
  components: {
    rbFilterPanel,
    chart,
    cardWidget,
    metric,
    iconTextActions,
    rbDownloadButton,
    rightPanelActionLogs,
    filterCard
  },
  mixins: [widgetsUtil],
  data() {
    return {
      selectedRow: null,
      sidePanelContent: [],
      pageContainerHeight: '400px',
      sticky: {
        stickySelector: '.ag-header',
        scrollAbleSelector: '.detail-panel',
        addSpaceElementsSelector: ['.page-header', '.actionspace-dummy'],
        styles: [
          {
            key: 'backgroundColor',
            value: 'white'
          },
          {
            key: 'zIndex',
            value: '2'
          }
        ]
      },
      widgetsConfig: {},
      parentRef: {},
      resultLength: 0,
      bidChangePanelTitle: 'Budget Change',
      filterKey: 0,
      rowSelection: {
        mode: undefined,
        onSelectionChanged: this.onSelectionChanged
      },
      showBlueBar: false,
      timer: 30000,
      reDrawRows: true,
      rbTableInstance: null,
      isHighlighted: false,
      filterInstance: null,
      showPaginationOptions: utils.internalUserCheck(window.user),
      currentDate: moment().format('MM/DD/YYYY')
    };
  },
  computed: {
    filterState() {
      return {
        getter: this.config.namespace + 'getWorkbenchActionLogsSelectedFilters',
        setter: this.config.namespace + 'setWorkbenchActionLogsSelectedFilters'
      };
    },
    getActionGroupData() {
      const rows =
        this.$store.getters[this.config.namespace + 'getActionGroupData'].rows;
      for (let i = 0; i < rows.length; i++) {
        const item = rows[i];
        rows[i].isActive = false;
        if (
          this.activeCardFilter === item.action_group &&
          !this.getActionGroupDataLoading
        ) {
          if (item.action_count > 0) {
            rows[i].isActive = true;
          }
        }
      }
      return rows;
    },
    getActionGroupDataLoading() {
      return this.$store.getters[this.config.namespace + 'getActionGroupData']
        .loading;
    },
    getActionGroupDataError() {
      return this.$store.getters[this.config.namespace + 'getActionGroupData']
        .error;
    },
    numberOfPlottedSelections() {},
    getWorkbenchActionLogsData() {
      this.resultLength = (
        (
          this.$store.getters[
            this.config.namespace + 'getWorkbenchActionLogsData'
          ][this.config.widgets.widget2.meta.key] || {}
        ).rows || []
      ).length;
      return this.$store.getters[
        this.config.namespace + 'getWorkbenchActionLogsData'
      ];
    },
    getWorkbenchActionLogsDataLoad() {
      return this.$store.getters[
        this.config.namespace + 'getWorkbenchActionLogsData'
      ][this.config.widgets.widget2.meta.key].load;
    },
    activeCardFilter() {
      return this.$store.getters[this.config.namespace + 'getActiveCardFilter'];
    },
    activeCardFilterUpperCase() {
      return this.$store.getters[
        this.config.namespace + 'getActiveCardFilter'
      ].toUpperCase();
    },
    isSidePanelActive() {
      return this.$store.getters[
        this.config.namespace + 'getIsSidePanelActive'
      ];
    },
    rowClassRules() {
      return {
        'ag-row-selected': function (params) {
          return params.data.uniqueId === this.selectedRow;
        }.bind(this)
      };
    }
  },
  watch: {
    resultLength() {
      if (this.resultLength > 1) {
      }
    }
  },
  beforeCreate() {},
  mounted() {},
  created() {
    let retailer = this.$store.getters.getRetailer;
    let cubeName = multiRetailerActionLogsConfig.cubeName[retailer];
    this.fetchFilters(
      cubeName,
      'FETCH_FILTERS_V2',
      'actionLog',
      undefined,
      undefined,
      {
        headers: {
          cache: false
        }
      }
    );
    this.widgetsConfig = widgetsConfig;
    this.parentRef = this;
    this.config = widgetsConfig.config(this);
    eventBus.$on('workbenchCampaignsFilterApplied', () => {});
    eventBus.$on('workbenchCampaignsSearchTriggered', () => {});
    eventBus.$on('workbenchCampaignsPaginationTriggered', () => {});
  },
  destroyed() {
    this.$store.dispatch(this.config.namespace + 'resetActionLogsState');
  },
  methods: {
    handleCardClick(data) {
      const { emit, text } = data;
      if (this.activeCardFilter !== text) {
        this.$store.dispatch(this.config.namespace + 'updateCardFilter', text);
        eventBus.$emit(emit);
      }
    },
    hideSidePanel() {
      this.$store.dispatch(this.config.namespace + 'hideSidePanel');
      this.sidePanelContent = [];
      this.selectedRow = null;
      this.rbTableInstance.redrawTableRows();
    },
    openSidePanel(that) {
      this.$store.dispatch(this.config.namespace + 'showSidePanel');
      this.selectedRow = that.data.uniqueId;
      const sidePanelContentKeys = (that.data && Object.keys(that.data)) || [];
      const renderData = sidePanelContentKeys.map((key) => {
        let returnObject = {
          title:
            columnConfig.map[key] &&
            columnConfig.map[key].showOnRightPanel &&
            columnConfig.map[key].title,
          value: that.data && that.data[key]
        };
        if (returnObject.title && returnObject.title === 'Date') {
          returnObject = {
            ...returnObject,
            value: that.data && that.data[key] && utcToLocalDate(that.data[key])
          };
        }
        return returnObject;
      });
      this.sidePanelContent = renderData;
      this.rbTableInstance.redrawTableRows();
    },
    getFilterInstance(that) {
      this.filterInstance = that;
    },
    scrollToNodeId() {
      // return this.$store.getters.getCampaignPrimaryKey
      return null;
    },
    onCreate(instance) {
      this.filterInstance = instance;
    },
    onSelectionChanged(data) {},
    getCurrentInstance(that) {
      this.rbTableInstance = that;
    }
  }
};
</script>

<style lang="css" scoped>
.pagination-overlay {
  width: 100%;
  height: calc(100vh - 182px);
}
.padding--right {
  padding-right: 0% !important;
}
</style>
