<template>
  <div
    class="u-font-size-6 u-spacing-pb-xs u-color-grey-lighter u-spacing-ph-m u-spacing-mt-xs"
    :class="{
      'u-spacing-pt-l': !(
        metric.key === 'Planned Budget' || metric.key === 'Ad Spend'
      )
    }"
  >
    <div
      v-show="metric.key === 'Planned Budget' || metric.key === 'Ad Spend'"
      class="u-font-size-6 u-color-grey-lighter u-spacing-pt-m u-spacing-pb-xs"
    >
      Cumulative
    </div>
    <div
      class="u-font-size-4 u-spacing-pb-m"
      :style="{ 'font-color': '#627178' }"
    >
      {{ metric.key }}
    </div>
    <div>
      <metric
        v-if="metric.metricData"
        :size="'l'"
        class="u-display-inline-flex"
        :class="[
          metric.key === 'Planned Budget' || metric.key === 'Ad Spend'
            ? 'u-spacing-pb-m'
            : 'u-spacing-pb-l'
        ]"
        :config="metric.metricConfig || {}"
        :data="metric.metricData || {}"
      />
    </div>
  </div>
</template>

<script>
import metric from '@/components/basic/metric';

export default {
  components: {
    metric
  },
  props: {
    metric: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
