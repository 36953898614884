import HttpService from '@/utils/services/http-service';
import { routeDecider } from '../../router/routeDecider';
import Vue from 'vue';
import utils from '@/utils/helpers/';
import wootric from '@/external/wootric.js';
import inlineManual from '@/external/inlineManual.js';
import currency from '@/utils/helpers/currency';
import constants from '@/utils/constants';
const sentry = require('@/external/sentry');
const { filters } = require('@ciq-dev/ciq-ui');

const state = {
  user: null,
  sessionValidity: null,
  errorMessage: null,
  configs: null,
  loginLoading: false,
  registerLoading: false,
  attempts: 0,
  fallback: false,
  rememberme: false,
  loggedIn: false,
  previousPage: null
};

const getters = {
  getLoginSuccess: (state) => {
    return state.loggedIn;
  },
  getSessionValidity: (state) => {
    return state.sessionValidity;
  },
  getConfigs: (state) => {
    if (state.sessionValidity && state.configs) {
      return state.configs;
    } else {
      return null;
    }
  },
  getSessionUser: (state) => {
    if (state.sessionValidity && state.user) {
      return state.user;
    } else {
      return null;
    }
  },
  getErrorMessage: (state) => {
    if (state.errorMessage) {
      return state.errorMessage;
    } else {
      return null;
    }
  },
  getAttemptsCount: (state) => {
    return state.attempts;
  },
  getFallback: (state) => {
    return state.fallback;
  },
  getProductConfig: (state, getters) => {
    let configs;
    if (state.sessionValidity && state.configs) {
      let configToRead = 'feature.productHeader';
      if (getters.getRetailer) {
        configToRead = `feature.${getters.getRetailer}.productHeader`;
        if (!Vue.options.filters.config_check(configToRead)) {
          configToRead = 'feature.productHeader';
        }
      }
      configs = Vue.options.filters.config_check(configToRead);
    }
    return (configs && configs.split(',')) || undefined;
  },
  isPureSalesOnlyClient: (state) => {
    let configs;
    let isAMSLiteClient;
    if (state.sessionValidity && state.configs) {
      configs = Vue.options.filters.config_check('feature.productHeader');
      isAMSLiteClient = Vue.options.filters.config_check(
        'feature.product.ams_lite'
      );
    }
    return configs === 'SalesIQ' && !isAMSLiteClient;
  },
  getInternalUserConfig: (state) => {
    let configs;
    if (state.sessionValidity && state.configs) {
      configs = Vue.options.filters.config_check(
        'feature.allowInternalUserCheck'
      );
    }
    return configs || undefined;
  },
  getPreviousBackState: (state) => {
    return state.previousPage;
  }
};

const mutations = {
  LOGIN_SUCCESS: (state, data) => {
    state.loginLoading = false;
    state.loggedIn = true;
    state.sessionValidity = 1;
    state.errorMessage = null;
  },
  STORE_USER_CONFIG: (state, data) => {
    state.user = data.user;
    if (data.rememberme === true) {
      state.rememberme = data.rememberme;
    }
    var configMasher = function (dest, source) {
      var destKeys = Object.keys(dest);
      var sourceKeys = Object.keys(source);
      for (var sourceKey of sourceKeys) {
        if (destKeys.indexOf(sourceKey) === -1) {
          dest[sourceKey] = source[sourceKey];
        } else {
          if (
            Object.prototype.toString.call(dest[sourceKey]) ===
            '[object Object]'
          ) {
            configMasher(dest[sourceKey], source[sourceKey]);
          }
        }
      }
    };
    configMasher(data.config.client, data.config.common);
    state.configs = data.config;
    window.configs = state.configs;
    window.user = state.user;
    if (Vue && Vue.$ga) {
      Vue.$ga.set('dimension2', data.user.email);
    }
    if (data.user && window.FS) {
      window.FS.identify(data.user.email);
      window.FS.setUserVars({
        email: data.user.email,
        domain: location.host
      });
    }
    if (data.user && window.Intercom) {
      // user_id is the unique identifier.
      const user_id = location.host + data.user.email;
      window.Intercom('boot', {
        app_id: constants.INTERCOM_APP_ID,
        email: data.user.email,
        user_id: user_id,
        domain: location.host
      });
    }
    sentry.init(Vue);
    var wootricEnabled = Vue.options.filters.config_check(
      'feature.wootric.enable'
    );
    var wootricAccount = Vue.options.filters.config_check(
      'feature.wootric.NPS'
    );
    if (
      wootricEnabled &&
      data &&
      data.user &&
      data.user.email &&
      data.user.email.indexOf('boomerang') === -1 &&
      wootric &&
      wootric.init
    ) {
      data.user.nps = wootricAccount;
      wootric.init(data);
    }
    // reading configs for inline manual
    var inlineManualEnabled = Vue.options.filters.config_check(
      'feature.inlineManual.enable'
    );
    var apiKey = Vue.options.filters.config_check('feature.inlineManual.key');
    if (inlineManualEnabled && inlineManual && inlineManual.init && apiKey) {
      inlineManual.init(apiKey, data.user);
    }
    // initalize the config
    const locale = filters.config_check('feature.i18n.locale');
    const currencyString = filters.config_check('feature.i18n.currency');
    // extract currency symbol out for the locale
    const currencySymbol = (0)
      .toLocaleString(locale, {
        style: 'currency',
        currency: currencyString,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
      .replace(/\d/g, '')
      .trim();
    Vue.use(currency, currencySymbol);
    // New Relic Browser Config check
    if (Vue.options.filters.config_check('feature.newRelicBrowser.enable')) {
      // require('@/assets/js/newRelicBrowser.js');
      window.newrelic.setCustomAttribute('email', data.user.email);
    }
  },
  LOGIN_ERROR: (state, response) => {
    state.loginLoading = false;
    state.sessionValidity = 0;
    state.errorMessage = response.data.err.message || response.data.err;
    state.attempts = response.data.wrongAttemptsCount || 0;
    state.fallback = response.data.fallBack;
  },
  LOGOUT: (state, logback) => {
    state.sessionValidity = 0;
    state.loggedIn = false;
    state.user = null;
    state.configs = null;
    routeDecider(null, null, logback || '');
  },
  START_LOGIN: (state, response) => {
    state.loginLoading = true;
  },
  START_REGISTER: (state) => {
    state.registerLoading = true;
  },
  REGISTER_SUCCESS: (state, response) => {
    state.registerLoading = false;
    state.errorMessage = response.data.message;
  },
  REGISTER_ERROR: (state, response) => {
    state.errorMessage = response.data.err.message;
  },
  USER_STATUS_SUCCESS: (state, response) => {
    state.errorMessage = response.data.message;
  },
  USER_STATUS_ERROR: (state, response) => {
    state.errorMessage = response.data.err.message;
  },
  CLEAR_ERROR_MESSAGE: (state) => {
    state.errorMessage = null;
  },
  SET_ERROR_MESSAGE: (state, message) => {
    state.errorMessage = message;
  },
  UPDATE_PREVIOUS_PAGE: (state, data) => {
    state.previousPage = data;
  }
};

const actions = {
  login: (context, data) => {
    context.commit('START_LOGIN');
    HttpService.post('LOGIN', data)
      .then((response) => {
        context.commit('LOGIN_SUCCESS', response);
        context.commit('STORE_USER_CONFIG', response.data);
        context.commit('SET_FEATURE_ENABLE_CONFIG', {
          pages: Vue.options.filters.config_check('feature.pages'),
          insights: Vue.options.filters.config_check('feature.insights'),
          campaignManagement: Vue.options.filters.config_check(
            'feature.campaign_management'
          ),
          budgetPlanner: Vue.options.filters.config_check(
            'feature.budget_planner'
          ),
          budgetPlannerV2: Vue.options.filters.config_check(
            'feature.budget_planner_v2'
          ),
          workbench: Vue.options.filters.config_check('feature.workbench'),
          recommendations: Vue.options.filters.config_check(
            'feature.recommendations'
          ),
          simulation: utils.internalUserCheck(window.user),
          dw: Vue.options.filters.config_check('feature.dw'),
          ...(Vue.options.filters.config_check(
            'feature.walmart_campaign_management'
          )
            ? {
                walmart_campaign_management: Vue.options.filters.config_check(
                  'feature.walmart_campaign_management'
                )
              }
            : [])
        });
        context.commit('SET_MARKETPLACE_DATA', {
          marketPlace:
            Vue.options.filters.config_check('marketplace') || 'Amazon',
          outsideIn: Vue.options.filters.config_check(
            'feature.outsideIn.enable'
          )
        });
        context.dispatch('commonSetTimezone');
        context.dispatch('setRootPath');
        window.heap.identify(response.data.user.email);
        window.heap.addUserProperties({
          email: response.data.user.email,
          roles: response.data.user.roles,
          clientName: window.clientName
        });
        // context.dispatch('getCatalogAsins');
        routeDecider(response);
      })
      .catch((error) => {
        if (error.response) {
          context.commit('LOGIN_ERROR', error.response);
        }
      });
  },
  logout: (context, keepContext) => {
    HttpService.post('LOGOUT')
      .then((response) => {
        if (keepContext) {
          context.commit(
            'LOGOUT',
            'returnslink=' + encodeURIComponent(window.location)
          );
        } else {
          context.commit('LOGOUT');
        }
        // routeDecider(null)
      })
      .catch((error) => {
        if (error.response) {
          routeDecider(null);
        }
      });
    if (Vue.options.filters.config_check('feature.efIntegration')) {
      HttpService.post('revokeSession');
    }
  },
  register: (context, data) => {
    context.commit('START_REGISTER');
    HttpService.post('REGISTER', data)
      .then((response) => {
        context.commit('REGISTER_SUCCESS', response);
      })
      .catch((error) => {
        if (error.response) {
          context.commit('REGISTER_ERROR', error.response);
        }
      });
  },
  // send verification link to user email id
  passwordResetLink: (context, data) => {
    context.commit('START_PASSWORD_RESET_LINK');
    return new Promise((resolve, reject) => {
      HttpService.post('PASSWORD_RESET_LINK', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // To set or reset password
  setOrResetPassword: (context, data) => {
    return new Promise((resolve, reject) => {
      HttpService.post('PASSWORD_CHANGE', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  checkUserStatus: (context, data) => {
    return new Promise((resolve, reject) => {
      HttpService.post('CHECK_USER_STATUS', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logActivity: (context, data) => {
    if (!context.getters.getSessionValidity) {
      return false;
    }
    var lastActivityTime =
      localStorage.getItem('lastActivityTime') || data.getTime();
    lastActivityTime =
      data.getTime() > lastActivityTime ? data.getTime() : lastActivityTime;
    var now = new Date().getTime();
    if (now - lastActivityTime < 1000 * 60) {
      HttpService.get('KEEPALIVE').catch((error) => {
        console.log('logged out elsewhere', error);
      });
    } else if (now - lastActivityTime > 1000 * 60 * 30 && !state.rememberme) {
      if (context.getters.getConfigs) {
        context.dispatch('logout', true);
      }
    }
    localStorage.setItem('lastActivityTime', lastActivityTime);
  },
  updatePreviousPage: (context, path) => {
    if (
      path.indexOf('login') === -1 &&
      path.indexOf('manageSubscription') === -1
    ) {
      context.commit('UPDATE_PREVIOUS_PAGE', path);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
