import CVA from '@/components/pages/insights/amazon/cva/index.vue';
import REM_CC from '@/components/pages/insights/amazon/rem-cc/index.vue';
import CONSTANTS from '@/utils/constants';

export default {
  configCheckRequired: true,
  routes: [
    {
      title: 'Category Leaderboard',
      path: 'category_leaderboard/:id?',
      name: 'cva',
      page: 'insights',
      config: 'cva',
      productRoles: ['MarketingIQ', 'SalesIQ'],
      component: CVA,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.REM
      }
    },
    {
      title: 'Ecommerce Command Centre',
      path: 'ecommerce_command_centre/:id?',
      name: 'remcc',
      page: 'insights',
      config: 'cva',
      productRoles: ['MarketingIQ', 'SalesIQ'],
      component: REM_CC
    }
  ]
};
