var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"media-planner-setup--header u-display-flex u-spacing-p-l"},[_c('div',{staticClass:"u-flex-1"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-flex-end"},[_c('span',{on:{"click":function($event){return _vm.handleGoBack()}}},[_c('rb-icon',{staticClass:"u-color-grey-lighter u-cursor-pointer is-flipped u-spacing-mr-s",class:'rb-icon--x-medium',attrs:{"icon":"cross"}})],1),_c('div',{staticClass:"u-display-flex",style:({ width: ("calc(" + (_vm.headerStyle()) + " + 30px)") })},[_c('input',{ref:"media-planner-input",staticClass:"heading--input u-text-overflow-ellipsis u-font-size-2 u-font-weight-bold",class:{
            'u-pointer-events-none': _vm.isEditVisible,
            'u-width-100': _vm.title,
            'text-underline': !_vm.isEditVisible && _vm.title
          },attrs:{"type":"text","placeholder":_vm.placeholder},domProps:{"value":_vm.title},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }$event.stopPropagation();},"input":_vm.handleEditTitle,"keydown":_vm.onPressEnter}}),(_vm.isEditVisible && !_vm.savingName)?_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.handleEditBtnClick($event)}}},[_c('rb-icon',{staticClass:"rb-icon--x-medium u-cursor-pointer u-color-grey-lighter",attrs:{"icon":"pencil"}})],1):_vm._e(),_c('Loader',{attrs:{"loading":_vm.savingName,"color":'#8b8f93'}})],1),(_vm.showRightBtn)?_c('div',{staticClass:"u-spacing-ml-auto u-display-flex"},[_c('rb-button',{staticClass:"u-spacing-mr-l",attrs:{"disabled":_vm.loading || _vm.disabledDownloadBtn,"click-fn":_vm.handleClickDownloadBtn,"text":"Budget Plan","type":"filled","icon-left":"download"}}),_c('rb-select',{attrs:{"open-direction":'bottom-end',"send-details":true,"options":_vm.actions,"on-close":_vm.actionSelected},scopedSlots:_vm._u([{key:"item",fn:function(option){return [_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                placement: 'bottom',
                maxWidth: '200px',
                distance: -30,
                arrow: false
              }),expression:"{\n                placement: 'bottom',\n                maxWidth: '200px',\n                distance: -30,\n                arrow: false\n              }"}],staticClass:"u-flex-0 u-display-flex u-flex-align-items-center u-cursor-pointer u-action-padding",class:{ 'disabled-btn': option.disabledBOSheet },attrs:{"title":option.disabledBOSheet
                  ? option.disabledTippyTitle
                  : option.tippyTitle}},[(option.icon)?_c('rb-icon',{staticClass:"u-color-grey-base",class:option.class,attrs:{"icon":option.icon}}):_vm._e(),_c('div',{staticClass:"u-flex-1 u-font-size-5 u-text-overflow-ellipsis u-color-grey-base"},[_vm._v(" "+_vm._s(option.name)+" ")])],1)]}}],null,false,817758580)},[_c('div',{staticClass:"u-more-action-color u-cursor-pointer u-more-action u-flex-1 u-border-all u-border-radius-s u-border-width-s u-color-grey-lighter u-border-color-grey-xxx-light u-display-flex u-max-width-100 u-flex-align-items-center u-spacing-p-s",attrs:{"slot":"trigger"},slot:"trigger"},[_c('rb-icon',{staticClass:"rb-icon--medium u-spacing-mh-s",staticStyle:{"transform":"rotate(270deg) scaleY(1.3)"},attrs:{"icon":'more-horizontal'}}),_c('div',{staticClass:"u-flex-1 u-font-size-6 u-font-weight-600 u-text-overflow-ellipsis u-spacing-mr-s"},[_vm._v(" "+_vm._s(' More Actions')+" ")])],1)])],1):_vm._e()]),_c('div',{staticClass:"u-font-size-6 u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-color-red-base u-spacing-mt-xs"},[(_vm.errorMessage)?_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_c('rb-icon',{staticClass:"u-flex-0 rb-icon--small",attrs:{"icon":"error-fill"}}),_c('div',{staticClass:"u-flex-0 u-spacing-ml-xxs"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }