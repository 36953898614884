<template>
  <div>
    <div
      v-if="showHeader"
      class="filter-wrapper u-spacing-pt-0 u-spacing-mt-0 u-bg-color-grey-white u-spacing-ph-l sticky u-header-box-shadow negative_margin_class"
      style="z-index: 12"
    >
      <slot name="slotAboveHeader" />
      <div
        class="u-display-flex u-flex-justify-content-space-between title-bar"
      >
        <div class="u-display-flex u-flex-align-items-center">
          <span
            v-if="showBackButton"
            class="u-spacing-mr-s back-arrow"
            @click.stop="backButton()"
          >
            <rb-icon
              :icon="'arrow_back'"
              class="rb-icon--xx-medium u-color-grey-lighter u-cursor-pointer"
            />
          </span>
          <PageTitle :title="page.title" />
        </div>
        <div
          ref="right-panel"
          class="u-display-flex u-flex-align-self-flex-end"
          :class="{
            'u-flex-direction-column u-flex-align-items-flex-end':
              headerDropdown
          }"
        >
          <slot
            name="pre-right-panel"
            class="u-flex-align-self-flex-start"
          />
          <div
            v-if="(searchConfig.search || {}).isFilterIcon"
            class="icon-frame u-height-100"
            @click.stop="toggleFilter"
          >
            <rb-button
              :title="'Filters'"
              :type="'icon'"
              :size="'medium'"
              :icon-right="'filter'"
              class="u-border-none u-filter-trigger-button"
            />
          </div>
          <span
            v-if="isBulkSearchEnabled"
            class="u-display-flex u-flex-align-items-center u-spacing-mr-s"
          >
            <BulkSearch
              :bulk-search-ref="bulkSearchConfigs.bulkSearchComponentRefName"
              :bulk-search-props="bulkSearchProps"
              :standard-search-config="
                standardSearchConfigInsideBulkSearchProps
              "
            />
          </span>
          <form
            v-if="(searchConfig.search || {}).enable && !isBulkSearchEnabled"
            class="u-position-relative u-display-flex u-spacing-mr-s"
            :style="{
              width: [searchConfig.search.width || '480px']
            }"
            @submit.prevent="searchSubmit"
          >
            <rb-input
              v-model="searchText"
              :placeholder="searchConfig.search.placeholder || 'Search'"
              class="search__input u-width-100 u-border-left-none"
              :rounded="false"
              @input="onSearchInput"
            />

            <span
              class="u-position-absolute u-display-flex"
              style="top: 0px; left: 8px; bottom: 0; margin: auto"
              @click="searchSubmit"
            >
              <rb-icon
                icon="search"
                class="search__input-icon rb-icon--medium"
              />
            </span>
          </form>
          <slot name="right-panel" />
          <div v-if="dashboardHelpOverview.isDashboardHelpOverviewPresent">
            <div
              v-tippy="{ placement: 'top', arrow: false }"
              class="u-border-radius-s u-spacing-p-s u-border-all u-border-width-s u-border-color-grey-xxx-light u-cursor-pointer u-spacing-mr-s u-bg-color-grey-white u-display-flex u-height-36px"
              title="Know more about this dashboard"
              expoter="display-none"
              @click="openHelpDialog"
            >
              <rb-icon
                class="u-color-grey-lighter"
                :icon="'info-circle-fill'"
              />
            </div>
          </div>
          <div
            class="u-display-flex u-flex-direction-row"
            :class="{ 'pin-date-range-wrapper': commonDateKey }"
          >
            <div>
              <date-range
                v-if="enableDate"
                :key="localFilterKey"
                :new-date="enableDate"
                :max-date="(minMaxDate || {}).maxDate"
                :min-date="(minMaxDate || {}).minDate"
                :allow-compare="filter.allowCompare"
                :default-range-select="filter.defaultDatePostRange"
                :additional-ranges="filter.additionalRanges || {}"
                :common-date-key="commonDateKey"
                :local-storage-key="localDateKey"
                :last-week="lastWeek"
                :date-mode="filter.dateMode"
                :custom-mandatory-date-ranges="customMandatoryDateRanges"
                :selection-mode="filter.selectionMode"
                :extra-calendar-info-v-html-text="
                  filter.extraCalendarInfoVHtmlText
                "
                @create="newUpdatedateSelection"
                @submit="newUpdatedateSelection"
              />
            </div>
            <div
              v-if="commonDateKey"
              class="u-display-flex pin-wrapper calender-pin-height"
            >
              <span
                v-tippy="{ placement: 'top', arrow: false }"
                :title="
                  !useCommonDate
                    ? calendarPinMessage.pinMessage
                    : calendarPinMessage.unpinMessage
                "
                data-cy="pinCalenderIcon"
                class="u-display-flex u-cursor-pointer"
                :class="{
                  'u-color-blue-base': useCommonDate,
                  'u-color-grey-lighter': !useCommonDate
                }"
                @click.stop="useCommonDate = !useCommonDate"
              >
                <rb-icon
                  icon="pin"
                  class="rb-icon--x-medium"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="u-display-flex u-display-flex u-flex-align-items-center filter-panel"
      >
        <slot name="custom-filter" />
        <rb-filter-panel
          v-if="filter.show"
          id="dashboard-filter-wrapper"
          ref="inflator-filter-ref"
          :key="filter.uiKey + localFilterKey"
          :disable-between-operator="!filter.enableBetweenOperator"
          :listener-event="filter.listenerEvent"
          :page="page.name"
          :data="filterData"
          :primary="primaryFilterData"
          :emit="filter.emitEvent"
          :hide-filter="true"
          :new-date="false"
          :enable-save="filter.enableSave"
          :enable-remove-all="filter.enableRemoveAll || false"
          :default-date-post-range="filter.defaultDatePostRange"
          :min-max-date="minMaxDate"
          :filter-v2="filterV2"
          :on-create="getFilterInstance"
          :show-ingestion-progress="filterV2"
          :ingestion-dependent-entities="filter.ingestionDependentEntities"
          @filterMounted="handleFilterMount"
          @savedFilterApplied="localFilterKey++"
        >
          <div
            slot="leftSide-searchPanel"
            class="u-display-flex u-flex-align-items-center"
          />
        </rb-filter-panel>
      </div>
      <ActiveFilterBanner
        v-if="showActiveFilterBanner"
        icon-name="info-circle-fill"
        :filter-instance="filterInstance"
      />
      <div class="u-display-flex u-display-flex u-flex-align-items-center">
        <filter-bar
          v-if="filter.showFilterBar"
          class="filter-bar"
          :filter-components="filterBarComponents"
          :ls-key="filter.filterBarLSKey"
          :is-update-key="filter.isUpdateKey"
          @barFiltersApplied="barFiltersApplied"
          @filterBarInitialized="barFiltersApplied"
          @updateUIOnly="updateUIOnlyHandler"
        />
      </div>
    </div>
    <Loader
      v-if="isLoading"
      :loading="isLoading"
      class="fill--parent u-bg-color-grey-white"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
    />

    <slot name="slotBelowFilter" />

    <span
      v-for="(widgetItem, index) in widgetMetadata"
      :key="index"
    >
      <!-- do not render tabs component here -->
      <div
        v-if="
          (widgetItem || {}).name &&
          ((widgetItem || {}).metadata || {}).type !== 'tabs' &&
          isWidgetVisible(widgetItem)
        "
        :class="{ 'u-envelop-width': !disableEnvelopWidthClass }"
      >
        <component
          :is="widgetItem.name"
          :metadata="widgetItem"
          :widget-name="widgetItem.name"
          :context="pageContext"
          :min-max-date="minMaxDate"
          class="u-spacing-mh-m u-spacing-mv-m"
          :class="[widgetItem.name]"
          v-bind="
            widgetLevelSettings[widgetItem.name] &&
            widgetLevelSettings[widgetItem.name].props
          "
          :widget-request-params="widgetParamsCollection[widgetItem.name]"
          v-on="
            widgetLevelSettings[widgetItem.name] &&
            widgetLevelSettings[widgetItem.name].events
          "
          @widgetRequestParamsChanged="widgetRequestParamsChanged"
          @dataApiResolved="dataApiResolved"
        />
      </div>
    </span>
    <HelpDocumentDialog
      v-if="showHelpDialog"
      :full-path="dashboardHelpOverview.documentPath"
      @closeCallback="closeCallback"
    />
    <how-to-bulk-search-modal
      v-if="isBulkSearchEnabled"
      ref="bulkSearchHelpModal"
      :help-modal-local-storage-key="bulkSearchConfigs.helpModalLocalStorageKey"
      :entity="bulkSearchProps.subTitle"
    />
    <exit-bulk-search-modal
      v-if="isBulkSearchEnabled"
      ref="bulkSearchExitModal"
      @handleExitBulkSearch="handleExitBulkSearch"
    />
  </div>
</template>

<script>
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import widgetsUtil from '@/components/widgetMixin';
import moment from 'moment-timezone';
import Vue from 'vue';
import { eventBus } from '@/utils/services/eventBus';
import utils from '@/utils/helpers';
import { mapKeys, cloneDeep, isEqual } from 'lodash';
import Loader from '@/components/basic/loader';
import dateRange from '@/components/widgets/datePicker/DateRangePicker.vue';
import { inject } from '@vue/composition-api';
import transformer from '@/utils/services/data-transformer';
import { setDefaultFilters } from '@/utils/helpers/filter';
// import constants from '@/utils/constants';
import filterBar from '@/components/widgets/custom_widgets/filter-bar.vue';
import HelpDocumentDialog from '@/components/basic/HelpDocumentDialog.vue';
import BulkSearch from '@/components/basic/bulk-search/BulkSearch.vue';
import HowToBulkSearchModal from '@/components/basic/bulk-search/HowToBulkSearchModal.vue';
import ExitBulkSearchModal from '@/components/basic/bulk-search/ExitBulkSearchModal.vue';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default {
  components: {
    rbFilterPanel,
    Loader,
    dateRange,
    filterBar,
    HelpDocumentDialog,
    BulkSearch,
    HowToBulkSearchModal,
    ExitBulkSearchModal
  },
  mixins: [widgetsUtil],
  setup() {
    const requestParams = inject('requestParams');
    const updateEntireRequestParams = inject('updateEntireRequestParams');
    const updateWidgetRequestParams = inject('updateWidgetRequestParams');
    const updateGlobalRequestParams = inject('updateGlobalRequestParams');
    const getGlobalRequestParams = inject('getGlobalRequestParams');
    const getWidgetRequestParams = inject('getWidgetRequestParams');

    return {
      requestParams,
      updateEntireRequestParams,
      updateWidgetRequestParams,
      updateGlobalRequestParams,
      getGlobalRequestParams,
      getWidgetRequestParams
    };
  },
  props: {
    disableEnvelopWidthClass: {
      default: false,
      type: Boolean
    },
    showHeader: {
      default: true,
      type: Boolean
    },
    filter: {
      type: Object,
      default() {
        return {
          uiKey: 0,
          show: true,
          backendCube: 'inventory_availability_dashboard',
          defaultDatePostRange: 'last7Days',
          emitEvent: '',
          enableSave: false,
          allowCompare: true,
          enableBetweenOperator: false,
          filterV2: false
        };
      }
    },
    page: {
      type: Object,
      default() {
        return {
          id: 9,
          name: 'inventory_availability_dashboard',
          title: ''
        };
      }
    },
    pageContext: {
      type: Object,
      default: () => {
        return {};
      }
    },
    widgetLevelSettings: {
      type: Object,
      default() {
        return {
          widgetKey: {
            props: {
              headerOptions: [
                {
                  ui_component: 'iconText',
                  props: {
                    text: 'Download',
                    icon: 'download',
                    alignDirection: 'vertical',
                    iconClasses:
                      'rb-icon u-color-grey-lighter icon-download u-cursor-pointer',
                    textClasses:
                      'u-spacing-ph-s u-font-size-6 u-color-grey-base'
                  },
                  events: {
                    onClick: 'downloadTriggered'
                  }
                }
              ]
            }
          }
        };
      }
    },
    bookmarkPath: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    bookmarkWrapperClass: {
      type: String,
      default: ''
    },
    enableDate: {
      type: Boolean,
      default: true
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
    backEventListener: {
      type: String,
      default: null
    },
    dashboardHelpOverview: {
      type: Object,
      default() {
        return {
          isDashboardHelpOverviewPresent: false,
          documentPath: ''
        };
      }
    },
    headerDropdown: {
      type: Boolean,
      default: false
    },
    minMaxDateRange: {
      type: Object,
      default: () => ({})
    },
    lastWeek: {
      type: Object,
      default: function () {
        return {
          startDay: 'Saturday',
          endDay: 'Friday'
        };
      }
    },
    showActiveFilterBanner: {
      type: Boolean,
      default: false
    },
    customMandatoryDateRanges: {
      type: Array,
      default: function () {
        return ['mtd', 'qtd', 'ytd', 'lastYear'];
      }
    }
  },
  data() {
    return {
      // constants,
      // secondaryFilterData1: constants.secondaryFilterData,
      pageMetaData: {},
      widgetMetadata: [],
      minMaxDate: {},
      deepMerge: utils.deepMerge,
      isLoading: false,
      localFilterKey: 0,
      filterInstance: null,
      searchText: '',
      filterBarComponents: null,
      showHelpDialog: false,
      useCommonDate: false,
      currentDateInLocalStorage: null
    };
  },
  computed: {
    bulkSearchConfigs() {
      return this.filter?.bulkSearchConfigs;
    },
    standardSearchConfigInsideBulkSearchProps() {
      return this.bulkSearchConfigs?.standardSearchConfigInsideBulkSearchProps;
    },
    bulkSearchProps() {
      return this.bulkSearchConfigs?.bulkSearchProps;
    },
    bulkSearchAppliedEmitString() {
      return `${this.bulkSearchConfigs.bulkSearchComponentRefName}BulkSearchApplied`;
    },
    standardSearchAppliedInsideBulkSearchEmitString() {
      return this.standardSearchConfigInsideBulkSearchProps.emit;
    },
    isBulkSearchEnabled() {
      return this.filter?.bulkSearchConfigs;
    },
    widgetParamsCollection() {
      const widgetParamsCollection = {};
      this.widgetMetadata.forEach((widgetItem, index) => {
        const mergedWidgetParams = this.deepMerge(
          this.getGlobalRequestParams(),
          this.getWidgetRequestParams(widgetItem.name)
        );
        mergedWidgetParams.metadata = widgetItem.metadata;
        if (
          !isEqual(mergedWidgetParams, widgetParamsCollection[widgetItem.name])
        ) {
          Vue.set(widgetParamsCollection, widgetItem.name, mergedWidgetParams);
        }
      });
      this.$emit('widgetParamsCollectionUpdated', widgetParamsCollection);
      return widgetParamsCollection;
    },
    searchConfig() {
      let config = {};
      const searchOptions = this.filter?.search;
      if (searchOptions?.enable) {
        config = {
          search: {
            width: searchOptions.width,
            enable: true,
            emit: searchOptions.searchUniqKey,
            placeholder: searchOptions.placeholder,
            isFilterIcon: searchOptions.isFilterIcon
          }
        };
      }
      return config;
    },
    localDateKey() {
      return this.page.name.concat('-date-picker');
    },
    filterV2() {
      return !!this.filter?.filterV2;
    },
    commonDateKey() {
      return this.filter?.commonDateKey;
    },
    calendarPinMessage() {
      return {
        pinMessage:
          this.filter?.pinMessage ||
          "'Pin' to persist the date for all dashboards related to Campaign Management.",
        unpinMessage:
          this.filter?.unpinMessage ||
          "'Un-Pin' to not persist the date for all dashboards related to Campaign Management."
      };
    }
  },
  watch: {
    widgetParamsCollection(nv, ov) {
      this.$emit('widgetParamsChanged', nv);
    },
    primaryFilterData() {
      if (this.filter?.filterBar) {
        this.filterBarComponents = dashUtils.getFilterBarData(
          this.filter.filterBar,
          this.filterData
        );
      }
    },
    minMaxDateRange(newVal) {
      this.minMaxDate = newVal;
      this.localFilterKey++;
    },
    useCommonDate(val) {
      if (val === false && this.commonDateKey) {
        localStorage.removeItem(this.commonDateKey);
      } else if (val === true) {
        localStorage.setItem(
          this.commonDateKey,
          JSON.stringify(this.currentDateInLocalStorage)
        );
      }
    }
  },
  // watch: {
  //   filter (newVal) {
  //     this.localFilterKey = this.localFilterKey + 1;
  //   }
  // },
  mounted() {
    const that = this;
    if (this.isBulkSearchEnabled) {
      eventBus.$on(this.bulkSearchAppliedEmitString, (payload) => {
        that.searchText = '';
        that.searchSubmit(payload);
      });
      eventBus.$on(
        this.standardSearchAppliedInsideBulkSearchEmitString,
        (payload) => {
          that.searchText = payload;
          that.searchSubmit();
        }
      );
      eventBus.$on('openBulkSearchHelperPopup', () => {
        that.openBulkSearchHelperPopup();
      });
      eventBus.$on('openBulkSearchExitPopup', () => {
        that.$refs.bulkSearchExitModal?.openBulkSearchExitPopup();
      });
      eventBus.$on('openBulkSearchOverview', () => {
        that.$refs.entity?.openBulkSearchPopup();
      });
    }
  },
  beforeDestroy() {
    if (this.isBulkSearchEnabled) {
      eventBus.$off(this.bulkSearchAppliedEmitString);
      eventBus.$off(this.standardSearchAppliedInsideBulkSearchEmitString);
      eventBus.$off('openBulkSearchHelperPopup');
      eventBus.$off('openBulkSearchExitPopup');
      eventBus.$off('openBulkSearchOverview');
    }
  },
  created() {
    if (this.commonDateKey) {
      this.useCommonDate =
        JSON.parse(localStorage.getItem(this.commonDateKey)) !== null;
    }
    this.isLoading = true;
    dashUtils
      .getPageWidgets(this.page.id, this.page.name, this.page.globalViewId)
      .then((widgetListResponse) => {
        const pageApiData = widgetListResponse?.data || {};
        this.$emit('onPageListApiSuccess', pageApiData);
        const widgetList = widgetListResponse.data.widgets || [];
        if (widgetListResponse?.data?.API?.filterAPI) {
          const { service, request, endPoint } =
            widgetListResponse.data.API.filterAPI;
          this.getGlobalFilters(service, request, { append: endPoint });
          if (
            widgetListResponse.data.calendar.min_date &&
            widgetListResponse.data.calendar.max_date
          ) {
            const minDate = this.formatDate(
              widgetListResponse.data.calendar.min_date
            );
            const maxDate = this.formatDate(
              widgetListResponse.data.calendar.max_date
            );
            Vue.set(this.minMaxDate, 'maxDate', maxDate);
            Vue.set(this.minMaxDate, 'minDate', minDate);
          } else {
            const today = moment().format('YYYY-MM-DD');
            const yearBack = moment().subtract(1, 'years').format('YYYY-MM-DD');
            Vue.set(this.minMaxDate, 'maxDate', today);
            Vue.set(this.minMaxDate, 'minDate', yearBack);
          }
          this.localFilterKey = this.localFilterKey + 1;
          this.isLoading = false;
        }
        widgetList.forEach((element, index) => {
          dashUtils
            .getWidgetMetadata(element, this.page.id)
            .then((widgetMeta) => {
              Vue.set(this.widgetMetadata, index, widgetMeta[0].data);
              this.$emit('metadataUpdated', this.widgetMetadata);
            })
            .finally(() => {
              // if dashboard inflator does not have filters, stop loader
              if (!widgetListResponse?.data?.API?.filterAPI) {
                this.isLoading = false;
              }
            });
        });
      })
      .catch((error) => {
        console.error({ error });
        console.log('List API error');
      })
      .finally(() => {
        this.isLoading = false;
      });
    this.addDefaultFilters();
  },
  methods: {
    handleExitBulkSearch(dontShowAgain) {
      eventBus.$emit('handleExitBulkSearch', dontShowAgain);
    },
    updateUIOnlyHandler(data) {
      this.$emit('updateUIOnly', data);
    },
    dataApiResolved(valueTobeSet) {
      for (const widget in this.widgetParamsCollection) {
        if (
          this.widgetParamsCollection[widget].metadata.hasOwnProperty(
            'isWidgetVisibile'
          )
        )
          this.widgetParamsCollection[widget].metadata.isWidgetVisibile =
            valueTobeSet;
      }
    },
    barFiltersApplied(data) {
      const global = this.getGlobalRequestParams();
      const filterBarData = this?.filter?.filterBar;
      global[':dimensionNameValueList'] = global[
        ':dimensionNameValueList'
      ]?.filter((e) => {
        console.log(filterBarData.find((i) => i.key === e.dimensionName));
        return !filterBarData.find((i) => i.key === e.dimensionName);
      });
      global[':dimensionNameValueList'] = [
        ...global[':dimensionNameValueList'],
        ...data
      ];
      this.updateGlobalRequestParams(global);
      this.$emit('filtersUpdated', global[':dimensionNameValueList']);
    },
    addDefaultFilters() {
      // Default filter object from the metadata
      const defaultFiltersObj = this.pageMetaData?.defaultOperations?.filters;

      // Pass the filter object and filter key to set default filters in local storage
      if (defaultFiltersObj) {
        setDefaultFilters(
          defaultFiltersObj,
          this.filter,
          this.$route?.meta?.appendEsDataSetName
        );
      }
    },
    updateGlobalDates(date) {
      const global = cloneDeep(this.getGlobalRequestParams());
      const { from, to, compare_from, compare_to } = date;
      global[':from'] = from;
      global[':to'] = to;
      global[':pvpFrom'] = compare_from ?? global[':pvpFrom'];
      global[':pvpTo'] = compare_to ?? global[':pvpTo'];
      this.updateGlobalRequestParams(global);
    },
    async getGlobalFilters(service, request, header) {
      const { cubeName, pageName, where } = request;
      this.resetFilters();
      await this.fetchFilters(
        cubeName,
        service,
        pageName,
        where,
        undefined,
        header
        // undefined
      );
    },
    widgetRequestParamsChanged({ widgetName, widgetRequestParams }) {
      this.updateWidgetRequestParams(widgetRequestParams, widgetName);
    },
    resetFilters() {
      this.filterData = [];
      this.primaryFilterData = [];
      this.secondaryFilterData = {};
    },
    getFilterInstance(filter) {
      this.filterInstance = filter;
      this.handleFilterMount(this.filterInstance);
    },
    handleFilterMount(instance) {
      if (instance?.getSelectedFilters()) {
        let filters = instance.getSelectedFilters();
        let dimensionNameValueList = [];
        if (filters?.values) {
          const global = cloneDeep(this.getGlobalRequestParams());
          const where = transformer.generateWhereClause({}, filters, true);
          // currently does not handle the global pre existing dimensionNameValue lists gracefully since we updating this directly not considering that case.
          // to be improved
          global[':dimensionNameValueList'] =
            where.dimensionNameValueList || [];
          this.updateGlobalRequestParams(global);
        }
        eventBus.$on(this.filter.emitEvent, (data) => {
          const global = this.getGlobalRequestParams();
          filters = instance.getSelectedFilters();
          const updatedInputParams = mapKeys(data, (value, key) => {
            return `:${key}`;
          });
          const filterData = {
            values: { ...data, ...filters.values }
          };
          delete filterData.values.date_range;
          const where = transformer.generateWhereClause({}, filterData, true);
          dimensionNameValueList = where.dimensionNameValueList || [];
          updatedInputParams[':dimensionNameValueList'] =
            dimensionNameValueList;
          const filterBarData = this.filter?.filterBar;
          if (filterBarData) {
            const filterBarDimensionList = global[
              ':dimensionNameValueList'
            ].filter((e) => {
              return filterBarData.find((i) => i.key === e.dimensionName);
            });
            updatedInputParams[':dimensionNameValueList'] = [
              ...updatedInputParams[':dimensionNameValueList'],
              ...filterBarDimensionList
            ];
          }
          this.updateGlobalRequestParams({ ...global, ...updatedInputParams });
          this.$emit(
            'filtersUpdated',
            updatedInputParams[':dimensionNameValueList']
          );
        });
      }
    },
    newUpdatedateSelection(date) {
      this.currentDateInLocalStorage = date;
      localStorage.setItem(
        this.localDateKey,
        JSON.stringify({ date_range: date })
      );
      if (this.useCommonDate) {
        localStorage.setItem(this.commonDateKey, JSON.stringify(date));
      } else {
        localStorage.removeItem(this.commonDateKey);
      }
      this.updateGlobalDates(date);
    },
    backButton() {
      if (this.backEventListener) {
        this.$emit(this.backEventListener);
      } else {
        window.history.back();
      }
    },
    searchSubmit(arrayPayloadForBulkSearch = []) {
      // Initial default values for search dimension object
      let dimensionName = this?.filter?.search?.searchDimension || 'search';
      let dimensionValueForSearch = this.searchText;
      let operator = 'ILIKE';

      const global = cloneDeep(this.getGlobalRequestParams());
      const updatedInputParams = {};
      let newDimensionNameValueList = global[':dimensionNameValueList'].filter(
        (dimension) => dimension.dimensionName !== dimensionName
      ); // Keep every other dimension apart from search

      // Logic regarding bulk search starts here
      const dimensionNameForBulkSearch =
        this.bulkSearchConfigs?.dimensionNameForBulkSearch;
      if (this.isBulkSearchEnabled) {
        const dimensionNameForBulkSearch =
          this.bulkSearchConfigs.dimensionNameForBulkSearch;
        // Remove if there are previous entries of bulk search operators
        newDimensionNameValueList = newDimensionNameValueList.filter(
          (dimension) => dimension.dimensionName !== dimensionNameForBulkSearch
        );
      }
      if (arrayPayloadForBulkSearch.length && this.isBulkSearchEnabled) {
        dimensionValueForSearch = arrayPayloadForBulkSearch
          .map((searchTerm) => `'${searchTerm.replaceAll("'", "\\'")}'`) // adding escape character for search terms containing single quote
          .join(',');
        dimensionName = dimensionNameForBulkSearch;
        operator = this.bulkSearchConfigs.operator;
      }

      // Finally update the dimensionNameValueList
      updatedInputParams[':dimensionNameValueList'] = [
        ...newDimensionNameValueList,
        {
          dimensionName: dimensionName,
          dimensionValue: dimensionValueForSearch,
          operator: operator
        }
      ];
      this.updateGlobalRequestParams({ ...global, ...updatedInputParams });
    },
    onSearchInput() {},
    formatDate(dateObj) {
      return moment(dateObj, 'YYYY-MM-DD').format('MM/DD/YYYY');
    },
    isStickyWidget(widgetItem) {
      return widgetItem.name === 'roi_automation_types';
    },
    // Workaround - will be removed once new changes of DI comes in
    isWidgetVisible(widgetItem) {
      // Logic to handle widget views.
      // By default every widget is true.
      // hide only if isWidgetVisibile property is false
      if (widgetItem.metadata?.hasOwnProperty('isWidgetVisibile')) {
        return widgetItem.metadata.isWidgetVisibile;
      } else {
        return true;
      }
    },
    toggleFilter() {
      this.$refs['inflator-filter-ref'].openAddFilter();
    },
    closeCallback() {
      this.showHelpDialog = false;
    },
    openHelpDialog() {
      this.showHelpDialog = true;
    },
    openBulkSearchHelperPopup(showCheckbox = false) {
      let showHelpModalCheckbox = showCheckbox;
      this.$refs.bulkSearchHelpModal?.openBulkSearchHelperPopup(
        showHelpModalCheckbox
      );
    }
  }
};
</script>

<style lang="css">
.negative_margin_class .margin_top_negative {
  margin-top: -5rem;
}

.filter-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.filter-panel,
.title-bar {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.u-height-36px {
  height: 36px;
}

.calender-pin-height {
  height: 36px;
}
</style>
