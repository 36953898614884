import { groupsTableColDefinition as groupsTableColDefinitionFromAmazon } from '@/components/ams/campaigns/campaigns/widgets.js';

let tempGroupsTableColDefinition = groupsTableColDefinitionFromAmazon;
// Filter out total_targets array item
tempGroupsTableColDefinition = tempGroupsTableColDefinition.filter(
  (item) => item.name !== 'total_targets'
);
// Change the tableColumnName for ad_spend and roas
tempGroupsTableColDefinition = tempGroupsTableColDefinition.map((item) => {
  if (item.name === 'spend') {
    item.uiField.metadata.tableColumnName = 'ad_spend';
  }
  if (item.name === 'roas') {
    item.uiField.metadata.tableColumnName = 'roas_14d';
  }
  return item;
});
export const groupsTableColDefinition = tempGroupsTableColDefinition;

const bidConfigSourceTable = (isNegative) => {
  return !isNegative
    ? [
        {
          name: 'new_bid',
          type: 'STRING',
          uiField: {
            uiLabel: 'Sugg Bid',
            uiType: 'string',
            uiOrder: '3',
            metadata: {
              toolTipText: '',
              tableColumnName: 'new_bid',
              showOnUi: true,
              isFixed: false,
              width: 90
            }
          }
        }
      ]
    : [];
};

const bidConfigResultTable = (isNegative, that) => {
  return !isNegative
    ? [
        {
          name: 'Bid',
          type: 'custom',
          uiField: {
            uiLabel: 'Bid',
            uiOrder: '4',
            uiType: 'custom',
            metadata: {
              toolTipText: '',
              widget: 'input',
              type: 'number',
              tableColumnName: 'bid',
              defaultValueColumnName: 'newBid',
              showOnUi: true,
              isFixed: false,
              formatType: 'currency',
              width: 100,
              keyupEvent: '',
              // blurEvent: that.validateTableData
              onchangeEvent: that.validateTableData
              // contextReturnEvent: this.getInputFieldContext
            }
          }
        }
      ]
    : [];
};

export function getAPIConfigForFetchingAdGroups(retailer = 'walmart') {
  return {
    cubeName: `${retailer}_campaigns_adgroup_workbench`,
    measuresList: [
      'ad_group_name',
      'campaign_name',
      'adgroup_status',
      'ad_spend',
      'roas_14d'
    ],
    groupByDimensionsList: ['campaign_id', 'advertiser_id', 'ad_group_id'],
    timeseriesDimension: 'report_date',
    timeseriesEnabled: false,
    orderByList: [
      {
        dimension: 'campaign_id',
        direction: 'ASC'
      }
    ],
    where: {
      dimensionNameValueList: [
        {
          dimensionName: 'campaign_id',
          dimensionValue: '-'
        }
      ]
    }
  };
}

export default (isNegative = false) => {
  return {
    config: (that) => {
      return {
        widgets: {
          fetchAddGroups: {
            APIConfig: getAPIConfigForFetchingAdGroups(
              that.$store.getters.getRetailer
            )
          },
          addNegativeKeywords: {
            tableConfigs: {
              metadata: {
                sourceTable: [
                  {
                    name: 'keyword_text',
                    type: 'STRING',
                    uiField: {
                      uiLabel: `${isNegative ? 'Negative ' : ''}Keyword`,
                      uiType: 'string',
                      uiOrder: '1',
                      metadata: {
                        toolTipText: '',
                        tableColumnName: 'keyword_text',
                        showOnUi: true,
                        isFixed: false,
                        width: 170
                      }
                    }
                  },
                  {
                    name: 'match_type',
                    type: 'STRING',
                    uiField: {
                      uiLabel: 'Match type',
                      uiType: 'string',
                      uiOrder: '2',
                      metadata: {
                        toolTipText: '',
                        tableColumnName: 'match_type',
                        showOnUi: true,
                        isFixed: false,
                        width: 90
                      }
                    }
                  },
                  ...bidConfigSourceTable(isNegative),
                  {
                    name: 'Add all',
                    type: 'custom',
                    uiField: {
                      uiLabel: 'Add all',
                      uiType: 'custom',
                      clickHeader: that.addAllKeywordsToTable,
                      customStyles:
                        'cursor:pointer; color:#037ef6 !important; padding-left:15px',
                      headerIcon: {
                        icon: 'info-circle-fill',
                        toolTip: `Add all ${
                          isNegative ? 'negative ' : ''
                        }keywords on this page`
                      },
                      uiOrder: '4',
                      metadata: {
                        toolTipText: `Add ${
                          isNegative ? 'negative ' : ''
                        }keyword`,
                        toolTipPosition: 'left',
                        widget: 'icon',
                        tableColumnName: 'na',
                        showOnUi: true,
                        suppressSizeToFit: true,
                        isFixed: false,
                        iconClickEvent: that.addToSelection,
                        displayIcon: 'plus',
                        alternateIcon: 'check-fill-circle',
                        alternateIconClass: 'u-color-green-base',
                        width: 110
                      }
                    }
                  }
                ],
                resultTable: [
                  {
                    name: 'keyword',
                    type: 'STRING',
                    uiField: {
                      uiLabel: `${isNegative ? 'Negative ' : ''}Keyword`,
                      uiType: 'string',
                      uiOrder: '1',
                      metadata: {
                        toolTipText: '',
                        tableColumnName: 'keyword',
                        showOnUi: true,
                        isFixed: false,
                        width: 170
                      }
                    }
                  },
                  {
                    name: 'match_type',
                    type: 'STRING',
                    uiField: {
                      uiLabel: 'Match type',
                      uiType: 'string',
                      uiOrder: '2',
                      metadata: {
                        toolTipText: '',
                        tableColumnName: 'match_type',
                        showOnUi: true,
                        isFixed: false,
                        width: 90
                      }
                    }
                  },
                  ...bidConfigResultTable(isNegative, that),
                  {
                    name: 'Remove all',
                    type: 'custom',
                    uiField: {
                      uiLabel: 'Remove all',
                      uiType: 'custom',
                      uiOrder: '5',
                      customStyles:
                        'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
                      clickHeader: that.removeAllKeywords,
                      metadata: {
                        toolTipText: 'Remove keyword',
                        toolTipPosition: 'left',
                        widget: 'icon',
                        tableColumnName: 'na',
                        showOnUi: true,
                        isFixed: false,
                        iconClickEvent: that.removeKeyword,
                        displayIcon: 'cross',
                        iconSize: 'small',
                        suppressSizeToFit: true,
                        width: 110
                      }
                    }
                  }
                ]
              }
            },
            groupsTableColDefinition
          }
        }
      };
    }
  };
};
