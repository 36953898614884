<template>
  <div class="bulk-search-wrapper">
    <div class="bulk-search-input-overview-wrapper">
      <bulk-search-input
        :total-entity-count="totalEntityCount"
        :is-bulk-search-active="isBulkSearchActive"
        :has-invalid-entities="hasInvalidEntities"
        :search-config="standardSearchConfig"
        @toggleBulkSearch="toggleBulkSearch"
        @handleAggregratedTagActions="handleAggregratedTagActions"
        v-on="$listeners"
      >
        <template v-slot:custom-standard-search>
          <slot name="custom-standard-search" />
        </template>
      </bulk-search-input>
      <bulk-search-overview-popup
        :key="uiKey"
        ref="bulkSearchOverviewPopup"
        :entity-list="entityList"
        :bulk-search-ref="bulkSearchRef"
        :bulk-search-popup-props="bulkSearchProps"
        :current-standard-searched-term="currentStandardSearchedTerm"
        :previously-searched-text="previousSearchedText"
        @handleClearAll="handleBulkSearchClear"
        @handleBulkSearchApplied="handleBulkSearchApplied"
      >
        <template v-slot:custom-bulk-type>
          <slot name="custom-bulk-type" />
        </template>
      </bulk-search-overview-popup>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';
import BulkSearchInput from '@/components/basic/bulk-search/bulkSerachInput/BulkSearchInput.vue';
import BulkSearchOverviewPopup from '@/components/basic/bulk-search/bulkSearchOverview/BulkSearchOverview.vue';
import {
  getAllowedMaxEntityListArr,
  getParsedList
} from '@/components/basic/bulk-search/bulkSearchUtils';
import { cloneDeep } from 'lodash';

export default {
  components: {
    BulkSearchInput,
    BulkSearchOverviewPopup
  },
  props: {
    bulkSearchProps: {
      type: Object,
      default: () => ({
        title: 'Bulk Search',
        subTitle: 'entity names',
        inputFieldPlaceholder: 'Search',
        maxPasteLimit: 1500,
        textAreaPlaceholder:
          'Type or Paste inputs, each of them seperated with a new line',
        findEntityInputPlaceholder: 'Find entity'
      })
    },
    bulkSearchRef: {
      type: String,
      default: ''
    },
    currentStandardSearchedTerm: {
      // standard search term should be retained while opening the bulk-search popup
      type: String,
      default: ''
    },
    prepopulatedSearchParams: {
      type: [Array, String],
      default: function () {
        return [];
      }
    },
    standardSearchConfig: {
      type: Object,
      default: () => ({
        emit: '',
        placeholder: ''
      })
    }
  },
  data() {
    return {
      entityList: [],
      isBulkSearchActive: false,
      exitModalLSKey: `${this.bulkSearchRef}-bulk-search-exit-dont-show`,
      hasInvalidEntities: false,
      tippyConfig: {
        placement: 'bottom',
        arrow: false,
        interactive: true,
        distance: 5,
        size: 'regular',
        maxWidth: '300px'
      },
      previousSearchedText: '',
      uiKey: 0
    };
  },
  computed: {
    totalEntityCount() {
      return this.entityList.length;
    }
  },
  created() {
    eventBus.$on('handleExitBulkSearch', (dontShowAgain) => {
      this.handleExitBulkSearch(dontShowAgain);
    });
  },
  mounted() {
    if (
      Array.isArray(this.prepopulatedSearchParams) &&
      this.prepopulatedSearchParams.length
    ) {
      const generatedPastedText = this.generatePastedTextFromArray(
        this.prepopulatedSearchParams
      );
      this.entityList = getParsedList(generatedPastedText);
      this.previousSearchedText = cloneDeep(generatedPastedText);
      this.isBulkSearchActive = true;
      this.uiKey++;
    }
  },
  methods: {
    bulkSearchDeactivate() {
      // close bulk search popup on exit
      // clear serached entities
      this.isBulkSearchActive = false;
      if (this.entityList.length) {
        this.$refs.bulkSearchOverviewPopup?.closeSearchOverviewPopup();
        this.handleBulkSearchClear();
      } else {
        this.$refs.bulkSearchOverviewPopup?.closeSearchOverviewPopup();
      }
      this.$refs.bulkSearchOverviewPopup?.handleClearPopup();
    },
    handleBulkSearchApplied(pastedText) {
      // clears the search field inside the search overview popup
      this.$refs.bulkSearchOverviewPopup?.handleClearBulkSeachEntitySearchedTerm();
      // Finds the valid entities and emits it as an array of object to the root where Bulk search is used.
      this.entityList = getParsedList(pastedText);
      this.entityList = getAllowedMaxEntityListArr(
        this.entityList,
        this.bulkSearchProps.maxPasteLimit
      );
      let entitiesForPayload = [];
      this.hasInvalidEntities = false;
      this.entityList.forEach((entity) => {
        if (!entity.isDuplicate) {
          entitiesForPayload.push(entity.entityValue);
        } else {
          this.hasInvalidEntities = true;
        }
      });
      eventBus.$emit(
        `${this.bulkSearchRef}BulkSearchApplied`,
        entitiesForPayload
      );
      this.$refs.bulkSearchOverviewPopup?.closeSearchOverviewPopup();
    },
    handleBulkSearchClear() {
      this.$refs.bulkSearchOverviewPopup?.handleClearPopup();
      if (this.totalEntityCount > 0) {
        this.entityList = [];
        this.hasInvalidEntities = false;
        eventBus.$emit(`${this.bulkSearchRef}BulkSearchApplied`, []);
      }
    },
    openBulkSearchHelperPopup() {
      eventBus.$emit('openBulkSearchHelperPopup');
    },
    openBulkSearchPopup() {
      this.isBulkSearchActive = true;
      this.$refs.bulkSearchOverviewPopup?.openBulkSearchPopup();
    },
    generatePastedTextFromArray(requestArr) {
      let pastedText = '';

      requestArr.forEach((item) => {
        pastedText += `${item}\n`;
      });

      return pastedText;
    },
    toggleBulkSearch(isEnable) {
      // toggle - open / close bulk-search
      if (isEnable) {
        this.isBulkSearchActive = isEnable;
        this.$refs.bulkSearchOverviewPopup?.openBulkSearchPopup();
      } else {
        // use Local storage don't show BS exit modal if user checkbox input -> true
        let bulkSearchExitModalLS = JSON.parse(
          localStorage.getItem(this.exitModalLSKey)
        );
        if (!bulkSearchExitModalLS && this.entityList.length) {
          eventBus.$emit('openBulkSearchExitPopup');
        } else {
          this.bulkSearchDeactivate();
        }
      }
    },
    handleAggregratedTagActions(event) {
      // open ssearch overview on aggrgated tag click / edit btn click
      if (event === 'edit') {
        this.$refs.bulkSearchOverviewPopup?.openBulkSearchPopup();
      }
    },
    handleExitBulkSearch(dontShowAgain) {
      if (dontShowAgain) {
        localStorage.setItem(this.exitModalLSKey, true);
      }
      this.bulkSearchDeactivate();
    }
  }
};
</script>

<style lang="css">
.bulk-search-wrapper .bulk-search-input-overview-wrapper {
  position: relative;
}

.bulk-search-input {
  display: flex;
  justify-content: space-between;
  border-radius: 2px;
  background-color: #f5f7fa;
  padding: 8px;
  width: 280px;
  height: 36px;
}
</style>
