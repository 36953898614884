<template>
  <div class="u-display-flex u-flex-align-items-center">
    <div
      v-for="({ type, label, disabled }, index) in checkboxOptions"
      :key="index"
      :class="{
        'u-border-color-blue-x-light': sourceCheckBox[index],
        'u-color-grey-x-lighter': !sourceCheckBox[index]
      }"
      class="u-border-radius-xxxl u-spacing-mr-m"
    >
      <label class="check-container">
        <rb-checkbox
          v-model="sourceCheckBox[index]"
          :true-value="type"
          :false-value="false"
          :disabled="disabled"
          class="u-spacing-ph-s u-spacing-pv-xs u-border-color-blue-base"
          @input="selectedCheckbox(index, type)"
        >
          <div class="u-spacing-mr-s u-font-size-5">{{ label }}</div>
        </rb-checkbox>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: false
    },
    checkboxOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      sourceCheckBox: this.value
    };
  },
  methods: {
    selectedCheckbox(index, type) {
      this.$emit('onCheckboxChanged', this.sourceCheckBox);
    }
  }
};
</script>
<style lang="css">
.u-border-radius-xxxl {
  border-radius: 50px;
}
.u-border-color-blue-x-light {
  border: 1px solid #007cf6 !important;
  font-family: ProximaNova;
}
.u-color-grey-x-lighter {
  border: 1px solid #e9eaeb;
  font-family: ProximaNova;
  color: #6a7075;
}
.u-color-grey-x-lighter:hover {
  border: 1px solid #6a7075;
}
.rb-checkbox.checkbox .rb-control-label {
  padding-left: 4px !important;
}
</style>
