import moment from 'moment/moment';
import HttpService from '@/utils/services/http-service';
import { dataDiscrepancyVHtmlText } from '@/pages/dashboard_service_campaign_management/promoteiq/commonUtils.js';
import chartingWb from '@/components/widgets/custom_widgets/custom_widget_components/charts/charting-wb.vue';
import dashboardServiceTable from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import plotRowsChip from '@/pages/dashboard_service_campaign_management/common_components/plotRowsChip.vue';
import tableSwitchComponent from '@/pages/dashboard_service_campaign_management/common_components/tableSwitchComponent.vue';
import iconCell from '@/pages/dashboard_service_campaign_management/common_components/iconCell.vue';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import actionsBar from '@/components/widgets/custom_widgets/cw_actionsBar';
import activityLog from '@/components/widgets/collaboration/collaboration.vue';
import iconHeader from '@/pages/dashboard_service_campaign_management/common_components/iconHeader.vue';
import EditBid from '@/pages/dashboard_service_campaign_management/promoteiq/editBid.vue';
import customActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';
import nameChangePanel from '@/components/ams/actionPanels/nameChangePanel.vue';
import bidManagementPanel from '@/components/ams/actionPanels/bidManagementPanel.vue';
import adjustDatesPanel from '@/components/ams/actionPanels/adjustDatesPanel.vue';
import { getAdSkusActionObject as getKrogerAdSkusActionObject } from './common';
import {
  nativeRetailers,
  UtilServiceForConfig
} from '@/pages/dashboard_service_campaign_management/utils/utilServiceForConfig';

function getEditStatusActionObject(retailer, aggregator) {
  return {
    icon: 'task-status',
    title: 'Edit Status',
    id: 'edit-status',
    dropDown: true,
    dropDownOptions: [
      { title: 'Enable', value: 'Enable' },
      { title: 'Pause', value: 'Pause' }
    ],
    getValidSelections: (selections) => {
      return selections.filter((selection) =>
        ['ACTIVE', 'PAUSED'].includes(
          selection.data[`${aggregator}_cm_adgroup_adgroup_status`]
        )
      );
    },
    invalidSelectionsMessage:
      'Invalid selections have been automatically deselected.',
    async handler(selectionLength, dropDownSelection, selections) {
      const payload = [];
      selections.forEach((adGroup) => {
        const adGroupId =
          adGroup?.data?.[`${aggregator}_cm_adgroup_adgroup_id`];
        const dropdownToStateMapping = {
          Enable: 'active',
          Pause: 'paused'
        };
        const newState = dropdownToStateMapping[dropDownSelection[0].value];
        payload.push({
          widget: 'adGroup',
          actionType: `${retailer}AdGroupStatusChange`,
          actionSource: {
            pageUrl: window.location.href
          },
          primaryKey: adGroupId,
          actionPayload: {
            adgroup_id: adGroupId,
            state: newState
          },
          viewPayload: {
            current_status:
              adGroup?.data?.[
                `${aggregator}_cm_adgroup_adgroup_status`
              ]?.toUpperCase(),
            status: newState?.toUpperCase()
          }
        });
      });
      try {
        await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
        this.$emit('openSnackbar', 'Action has been submitted');
      } catch (err) {
        console.log(err);
        this.$emit('openSnackbar', 'Something went wrong.');
      }
    }
  };
}

const defaultStartEndDate = (adjustDatesPanelVueRef, aggregator) => {
  const rowSelections = adjustDatesPanelVueRef.selections;
  if (rowSelections.length === 1) {
    const startDate =
      rowSelections[0].data?.[`${aggregator}_cm_adgroup_adgroup_start_date`];
    const endDate =
      rowSelections[0].data?.[`${aggregator}_cm_adgroup_adgroup_end_date`];
    return {
      start: startDate
        ? new Date(moment(startDate).format('MM/DD/YYYY'))
        : null,
      end: endDate ? new Date(moment(endDate).format('MM/DD/YYYY')) : null
    };
  }
  return {
    start: new Date(),
    end: new Date(moment().add(1, 'days').format('MM/DD/YYYY'))
  };
};

const adjustEndDatesOnLeftBtnClick = (
  adjustDatesPanelVueRef,
  retailer,
  aggregator
) => {
  return async function onLeftBtnClick(newCampaignsDataObject) {
    const selectedCampaigns = adjustDatesPanelVueRef.selections;
    const payload = selectedCampaigns.map((selectedRow) => {
      const campaignId =
        selectedRow?.data?.[`${aggregator}_cm_adgroup_campaign_id`];
      // Setting newEndDate to '-1'. Since sending null is causing issue in BE
      const newEndDate = newCampaignsDataObject.end
        ? moment(newCampaignsDataObject.end).format('YYYY-MM-DD')
        : '-1';
      return {
        widget: 'adGroup',
        actionType: `${retailer}AdGroupEndDateChange`,
        actionSource: {
          pageUrl: window.location.href
        },
        primaryKey: selectedRow?.data?.[`${aggregator}_cm_adgroup_adgroup_id`],
        actionPayload: {
          campaign_id: campaignId,
          adgroup_id:
            selectedRow?.data?.[`${aggregator}_cm_adgroup_adgroup_id`],
          new_end_date: newEndDate
        },
        viewPayload: {
          current_end_date:
            selectedRow?.data?.[`${aggregator}_cm_adgroup_adgroup_end_date`] ||
            'No End Date',
          new_end_date:
            newEndDate && newEndDate !== '-1'
              ? moment(newEndDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
              : 'No End Date'
        }
      };
    });
    try {
      await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
      adjustDatesPanelVueRef.$emit('afterAPIActionToCall');
    } catch (err) {
      console.log(err);
    }
  };
};

export const onStartDateUpdated = (adjustDatesPanelVueRef, aggregator) => {
  return function onStartDateUpdated() {
    const rowSelections = adjustDatesPanelVueRef.selections;
    if (rowSelections.length === 1) {
      const startDate =
        rowSelections[0].data?.[`${aggregator}_cm_adgroup_adgroup_start_date`];
      let endDateToBeDisabledTill = new Date();
      if (moment(startDate).isAfter(moment().format('MM/DD/YYYY'))) {
        endDateToBeDisabledTill = new Date(
          moment(startDate, 'MM/DD/YYYY').add(1, 'day').format('MM/DD/YYYY')
        );
      }
      const campaignEndDate =
        rowSelections[0].data?.[`${aggregator}_cm_adgroup_campaign_end_date`];
      const endDateToBeDisabledFrom = new Date(campaignEndDate);
      return {
        endDateToBeDisabledTill,
        endDateToBeDisabledFrom
      };
    }
    return {
      endDateToBeDisabledTill: new Date()
    };
  };
};

const getAdjustEndDatesActionObject = (retailer, aggregator) => ({
  icon: 'calendar',
  title: 'Adjust End Dates',
  id: 'adjust-end-dates',
  getValidSelections: (selections) => {
    return selections.filter(
      (selection) =>
        !['ENDED', 'FAILED'].includes(
          selection.data[`${aggregator}_cm_adgroup_adgroup_status`]
        )
    );
  },
  invalidSelectionsMessage:
    'Invalid selections have been automatically deselected.',
  customState: {
    component: 'adjustDatesPanel',
    props: {
      timezone: 'EDT/EST',
      widgetsConfig: (adjustDatesPanelVueRef) => {
        return {
          headerText: () => {
            const selectionLength = adjustDatesPanelVueRef.selections.length;
            return `Adjust End Date (${selectionLength} Campaign${
              selectionLength > 1 ? 's' : ''
            } Selected)`;
          },
          leftCalendarDisabled: true,
          defaultStartEndDate: (adjustDatesPanelVueRef) =>
            defaultStartEndDate(adjustDatesPanelVueRef, aggregator),
          onStartDateUpdated: onStartDateUpdated(
            adjustDatesPanelVueRef,
            aggregator
          ),
          onLeftBtnClick: adjustEndDatesOnLeftBtnClick(
            adjustDatesPanelVueRef,
            retailer,
            aggregator
          )
        };
      }
    },
    events: {
      closePanelMethod() {
        this.removeCustomCustomComponents();
      },
      afterAPIActionToCall(payload) {
        this.$emit('openSnackbar', 'Action has been submitted');
        this.switchBlueBar('line item');
        this.removeCustomCustomComponents();
        this.closeActionsWorkflow();
      }
    }
  }
});

function getEditBudgetActionObject(retailer, aggregator) {
  return {
    icon: 'dollar',
    title: 'Edit Budget',
    id: 'edit-budget',
    getValidSelections: (selections) => {
      return selections.filter(
        (selection) =>
          !['ENDED', 'FAILED'].includes(
            selection.data[`${aggregator}_cm_adgroup_adgroup_status`]
          )
      );
    },
    invalidSelectionsMessage:
      'Invalid selections have been automatically deselected.',
    customState: {
      component: 'editBid',
      props: {
        actionType: `${retailer}AdGroupBudgetChange`,
        title: 'Edit Budget',
        selectedEntity: 'Ad Group',
        showInputTitle: true,
        aggregator: aggregator,
        radioConfig: [
          {
            title: 'Set new budget',
            type: 'input',
            value: 'set_new'
          },
          {
            title: 'Increase budget',
            value: 'increase',
            type: 'dropdown',
            dropdown: [
              {
                label: 'Change by percentage',
                value: 'percentage'
              },
              {
                label: 'Change absolute value',
                value: 'absolute_value'
              }
            ]
          },
          {
            title: 'Decrease budget',
            value: 'decrease',
            type: 'dropdown',
            dropdown: [
              {
                label: 'Change by percentage',
                value: 'percentage'
              },
              {
                label: 'Change absolute value',
                value: 'absolute_value'
              }
            ]
          }
        ],
        currentBidKey: `${aggregator}_cm_adgroup_adgroup_total_budget`,
        maxBidKey: `${aggregator}_cm_adgroup_campaign_total_budget`,
        customCreatePayload(editBidComponentRef) {
          return editBidComponentRef.selections.map((adGroup) => {
            const updatedBudget =
              editBidComponentRef.getUpdatedBidValue(adGroup);
            return {
              widget: 'adGroup',
              actionType: `${retailer}AdGroupBudgetChange`,
              actionSource: {
                pageUrl: window.location.href
              },
              primaryKey:
                adGroup?.data?.[`${aggregator}_cm_adgroup_adgroup_id`],
              actionPayload: {
                campaign_id:
                  adGroup?.data?.[`${aggregator}_cm_adgroup_campaign_id`],
                adgroup_id:
                  adGroup?.data?.[`${aggregator}_cm_adgroup_adgroup_id`],
                new_budget: updatedBudget
              },
              viewPayload: {
                old_budget:
                  adGroup?.data?.[
                    `${aggregator}_cm_adgroup_adgroup_total_budget`
                  ],
                new_budget: updatedBudget
              }
            };
          });
        },
        async handleApply(vueRef, payload) {
          try {
            await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
            vueRef.$emit('afterAPIActionToCall');
          } catch (err) {
            vueRef.openSnackbar('Something went wrong.');
          }
        }
      },
      events: {
        closePanelMethod() {
          this.removeCustomCustomComponents();
        },
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        }
      }
    }
  };
}

function getEditBaseBidActionObject(retailer, aggregator) {
  return {
    icon: 'dollar',
    title: 'Edit Base Bid',
    id: 'edit-base-bid',
    getValidSelections: (selections) => {
      return selections.filter(
        (selection) =>
          !['ENDED', 'FAILED'].includes(
            selection.data[`${aggregator}_cm_adgroup_adgroup_status`]
          )
      );
    },
    invalidSelectionsMessage:
      'Invalid selections have been automatically deselected.',
    customState: {
      component: 'editBid',
      props: {
        actionType: `${retailer}AdGroupBaseBidChange`,
        title: 'Edit Base Bid',
        selectedEntity: 'Ad Group',
        showInputTitle: true,
        aggregator: aggregator,
        radioConfig: [
          {
            title: 'Set new base bid',
            type: 'input',
            value: 'set_new'
          },
          {
            title: 'Increase base bid',
            value: 'increase',
            type: 'dropdown',
            dropdown: [
              {
                label: 'Change by percentage',
                value: 'percentage'
              },
              {
                label: 'Change absolute value',
                value: 'absolute_value'
              }
            ]
          },
          {
            title: 'Decrease base bid',
            value: 'decrease',
            type: 'dropdown',
            dropdown: [
              {
                label: 'Change by percentage',
                value: 'percentage'
              },
              {
                label: 'Change absolute value',
                value: 'absolute_value'
              }
            ]
          }
        ],
        currentBidKey: `${aggregator}_cm_adgroup_base_bid`,
        defaultMinBid: 0.1,
        defaultMaxBid: 50,
        minBidKey: `${aggregator}_cm_adgroup_min_base_bid`,
        customCreatePayload(editBidComponentRef) {
          return editBidComponentRef.selections.map((adGroup) => {
            const updatedBaseBid =
              editBidComponentRef.getUpdatedBidValue(adGroup);
            return {
              widget: 'adGroup',
              actionType: `${retailer}AdGroupBaseBidChange`,
              actionSource: {
                pageUrl: window.location.href
              },
              primaryKey:
                adGroup?.data?.[`${aggregator}_cm_adgroup_adgroup_id`],
              actionPayload: {
                campaign_id:
                  adGroup?.data?.[`${aggregator}_cm_adgroup_campaign_id`],
                adgroup_id:
                  adGroup?.data?.[`${aggregator}_cm_adgroup_adgroup_id`],
                new_base_bid: updatedBaseBid
              },
              viewPayload: {
                new_base_bid: updatedBaseBid,
                old_base_bid:
                  adGroup?.data?.[`${aggregator}_cm_adgroup_base_bid`]
              }
            };
          });
        },
        async handleApply(vueRef, payload) {
          try {
            await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
            vueRef.$emit('afterAPIActionToCall');
          } catch (err) {
            vueRef.openSnackbar('Something went wrong.');
          }
        }
      },
      events: {
        closePanelMethod() {
          this.removeCustomCustomComponents();
        },
        afterAPIActionToCall(payload) {
          this.$emit('openSnackbar', 'Action has been submitted');
          this.removeCustomCustomComponents();
          this.closeActionsWorkflow();
        }
      }
    }
  };
}

export function getAdSkusActionObject(retailer, aggregator) {
  return getKrogerAdSkusActionObject(retailer, aggregator, 'Ad Groups');
}

function tableDeselectSelections(context, args, aggregator) {
  // this is on x click in actions bar;
  if (Array.isArray(args)) {
    context.handleSelectionChange(args, true);
  } else {
    const selections = args.selections;
    context.handleSelectionChange(selections);
    const selectedNodes = context.gridOptions?.api?.getSelectedNodes();
    selectedNodes?.forEach((node) => {
      if (
        !selections.find(
          (sel) =>
            sel.data?.[`${aggregator}_cm_adgroup_adgroup_id`] ===
            node.data?.[`${aggregator}_cm_adgroup_adgroup_id`]
        )
      ) {
        node.setSelected(false);
      }
    });
  }
}

export function adGroupsConfig(retailer) {
  const listApiPageName = `${retailer}_cm_adgroups`;
  const chartWidgetName = `${retailer}_cm_adgroups_charting_wb`;
  const tableWidgetName = `${retailer}_cm_adgroups_table`;
  const localStorageFilterKey = `${retailer}-adgroups-filter`;
  const localStorageFilterOrderKey = `${retailer}-adgroups-filter-order`;
  const tabName = 'Ad Groups';

  const utilServiceForConfig = new UtilServiceForConfig({
    retailer,
    listApiPageName,
    chartWidgetName,
    tableWidgetName,
    localStorageFilterKey,
    localStorageFilterOrderKey,
    tabName
  });
  let aggregator = nativeRetailers.includes(retailer) ? retailer : 'promoteiq';
  return {
    PRIMARY_KEY: 'adgroup_id',
    entity: 'adgroups',
    styleClass: 'orders generic-campaign-management',
    pageInfo: utilServiceForConfig.getPageInfo(),
    initRequestParams: utilServiceForConfig.getInitRequestParams(),
    filterSettings: {
      ...utilServiceForConfig.getFilterSettings({
        backendCube: `${aggregator}_campaigns_adgroup_workbench`,
        searchDimension: 'search',
        searchInputPlaceHolder: 'Search for a Ad Group',
        bulkSearchStringConstants: {
          dimensionNameMapping: 'adgroup_name',
          findEntityInputPlaceholder: 'Find a AdGroup',
          subTitle: 'AdGroups'
        },
        enableTaxonomyFilters: true
      }),
      extraCalendarInfoVHtmlText: dataDiscrepancyVHtmlText
    },
    // components for dynamic registration in DashboardWrapper
    components: {
      [chartWidgetName]: chartingWb,
      [tableWidgetName]: dashboardServiceTable,
      plotRowsChip: plotRowsChip,
      tableSwitchComponent: tableSwitchComponent,
      iconCell: iconCell,
      EditBid: EditBid,
      iconTextActions: iconTextActions,
      actionsBar: actionsBar,
      activityLog: activityLog,
      iconHeader: iconHeader,
      customActionPanel: customActionPanel,
      nameChangePanel: nameChangePanel,
      bidManagementPanel: bidManagementPanel,
      adjustDatesPanel: adjustDatesPanel
    },
    widgetNames: utilServiceForConfig.getWidgetNames(),

    headerOptions: function (context) {
      return {
        // context is bound to the DashboardWrapper cðomponent
        [chartWidgetName]: {
          props: utilServiceForConfig.getChartWidgetProps(context)
        },
        [tableWidgetName]: {
          props: {
            ...utilServiceForConfig.getTableWidgetProps(context),
            headerOptions: [
              {
                ui_component: 'actionsBar',
                props: {
                  // contains actions and their handlers similar to that of walmart campaign management
                  actionBarActions: [
                    utilServiceForConfig.getPlotRowsActionObject(),
                    getEditStatusActionObject(retailer, aggregator),
                    getEditBudgetActionObject(retailer, aggregator),
                    getEditBaseBidActionObject(retailer, aggregator),
                    getAdSkusActionObject(retailer, aggregator),
                    getAdjustEndDatesActionObject(retailer, aggregator)
                  ],
                  isCustomActionStateVisible:
                    context.isCustomActionStateVisible,
                  selections: context.rowSelections,
                  showPlotRowsChip: context.showPlotRowsChip
                },
                events: {
                  handlePlotRows: context.handlePlotRows,
                  deselectSelections: (args) => {
                    tableDeselectSelections(context, args, aggregator);
                  },
                  openSnackbar: context.openSnackbar,
                  toggleCustomActionState: context.toggleCustomActionState,
                  switchBlueBar: context.switchBlueBar
                }
              },
              ...utilServiceForConfig.getEmailActionComponentRenderObjectArray(
                context
              )
            ],
            enableManageColumns: !context.rowSelections.length
          }
        }
      };
    },
    getActivityData: utilServiceForConfig.getGetActivityDataMethod('adGroup'),
    modal: utilServiceForConfig.getModalBaseConfigForEmail()
  };
}
