<template>
  <div
    class="u-display-inline-flex u-width-100 u-height-100 u-flex-align-items-center u-cursor-default"
  >
    <div v-if="value">
      <div
        v-if="data.showToolTip"
        v-tippy="tippy"
        class="u-width-100 switchContainer"
        :class="[extraClass]"
        :title="toolTipText"
      >
        <rb-switch
          :true-value="trueValue"
          :false-value="falseValue"
          :value="value"
          :disabled="true"
          :native-value="value"
          :super-off="setSuperOff"
          @input="updatedFunction"
        />
      </div>
      <div
        v-else
        class="u-width-100 switchContainer"
        :class="[extraClass]"
      >
        <rb-switch
          :true-value="trueValue"
          :false-value="falseValue"
          :value="value"
          :disabled="true"
          :native-value="value"
          :super-off="setSuperOff"
          @input="updatedFunction"
        />
      </div>
    </div>
    <div
      v-else
      class="cell--content u-white-space-normal u-flex-shrink-past-min-size u-color-grey-base u-cursor-text"
      data-cy="cellValue"
    >
      <div
        v-tippy="tippy"
        class="u-width-100 switchContainer"
        :class="[extraClass]"
        :title="toolTipText"
      >
        NA
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */

export default {
  name: 'TableSwitch',
  components: {},
  props: {
    data: [String, Number, Boolean, Function, Object, Array, Symbol],
    value: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    trueValue: {
      type: [String, Number, Boolean, Function, Object, Array, Symbol],
      default: true
    },
    falseValue: {
      type: [String, Number, Boolean, Function, Object, Array, Symbol],
      default: false
    },
    overrideToolTipText: {
      type: [String],
      default: null
    },
    extraClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tippy: {
        placement: 'bottom-start',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    toolTipText() {
      if (this.overrideToolTipText) {
        return this.overrideToolTipText;
      }
      if (!this.value) {
        return '';
      } else {
        return this.value.charAt(0).toUpperCase() + this.value.slice(1);
      }
    },
    setSuperOff() {
      return this.value === 'archived';
    }
  },
  created() {},
  methods: {
    updatedFunction(updatedVal) {
      this.$emit('updateFunction', this.data, updatedVal);
    }
  }
};
</script>

<style lang="css" scoped>
.switchContainer {
  padding: 2px 0px;
}
.rb-switch[disabled] {
  cursor: pointer;
}
</style>
