<template>
  <div>
    <div class="u-display-flex">
      <div
        v-if="title"
        class="u-spacing-mr-xs"
        :class="[getUniqueClass(title), { 'u-font-weight-600': isExpanded }]"
      >
        {{ title }}
      </div>
      <div
        v-if="!isExpanded && selectType === 'multi'"
        class="u-color-grey-base u-font-weight-600"
      >
        {{ internalSelection.shareType }} in {{ internalSelection.label }}
      </div>
      <div
        v-else-if="!isExpanded"
        class="u-color-grey-base u-font-weight-600"
      >
        {{ selectedLabels }}
      </div>
    </div>
    <div
      v-if="isExpanded && selectType === 'multi'"
      class="u-display-flex u-spacing-mt-m"
    >
      <RbSelectV2
        width="500px"
        :search-enable-threshold="10"
        class="u-spacing-mr-m"
        :send-details="true"
        :on-close="updateSelection"
        :options="options"
        class-name="tableSelect"
        :is-waterfall-dropdown="true"
      >
        <div
          slot="trigger"
          :class="['u-display-flex u-flex-align-items-center u-cursor-pointer']"
        >
          <div class="u-display-flex u-flex-align-items-center">
            <span :class="[metricFontSize, 'overflow-styles u-display-block']">
              {{ internalSelection.shareType }} in {{ internalSelection.label }}
            </span>
            <rb-icon
              class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
              icon="caret-down"
            />
          </div>
        </div>
        <template
          slot="item"
          slot-scope="option"
        >
          <div class="u-display-flex u-flex-direction-column">
            <div class="u-display-flex u-flex-align-items-center">
              <div class="u-width-100">
                <div
                  class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
                >
                  <div class="u-spacing-pr-m u-width-100">
                    {{ option.label }}
                  </div>
                </div>
              </div>
              <span
                v-if="option.children"
                class="rb-icon u-display-flex u-flex-align-items-center u-flex-justify-content-center rb-icon--small icon-arrow-up"
                style="transform: rotate(90deg)"
              />
            </div>
          </div>
        </template>
      </RbSelectV2>
    </div>
    <div
      v-else-if="isExpanded && selectType !== 'multi'"
      class="u-display-flex u-spacing-mt-m"
    >
      <div
        v-for="(item, index) in options"
        :key="index"
        class="u-font-weight-normal u-color-grey-light u-display-flex u-flex-align-items-center u-spacing-mr-6px"
        :class="getUniqueClass(item.label)"
      >
        <label class="u-display-flex u-flex-align-items-center">
          <input
            v-model="internalSelection"
            class="no-margin"
            :style="{ height: inputElementHeight, width: inputElementWidth }"
            :type="selectType"
            :value="item"
          />
          <div class="u-spacing-ml-xs">{{ item.label }}</div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';
export default {
  components: {
    RbSelectV2
  },
  props: {
    inputElementHeight: {
      type: String,
      default: '11px'
    },
    inputElementWidth: {
      type: String,
      default: '11px'
    },
    isExpanded: {
      type: Boolean,
      default: false
    },
    selectType: {
      type: String,
      default: 'checkbox'
    },
    options: {
      type: Array,
      default: () => []
    },
    originalSelections: {
      type: [Array, Object],
      default: () => ({})
    },
    title: {
      type: String,
      default: null
    },
    notSelectedLabel: {
      type: String,
      default: 'None'
    },
    metricFontSize: {
      type: String,
      default: 'u-font-size-5'
    }
  },
  data() {
    return {
      internalSelection: []
    };
  },
  computed: {
    selectedLabels() {
      if (this.originalSelections) {
        if (
          Array.isArray(this.originalSelections) &&
          this.originalSelections.length
        ) {
          return this.originalSelections.map((item) => item?.label)?.join(', ');
        } else {
          return this.originalSelections.label || this.notSelectedLabel;
        }
      }
    }
  },
  watch: {
    internalSelection(newInternalSelection) {
      this.$emit('selection', newInternalSelection);
    },
    originalSelections: {
      immediate: true,
      handler(originalSelections) {
        this.internalSelection = originalSelections;
      }
    }
  },
  methods: {
    getUniqueClass(str) {
      return 'text-' + str?.toLowerCase().split(' ').join('-');
    },
    updateSelection(context, selected) {
      this.internalSelection = {
        shareType: selected?.treePath[0]?.shareType || 'Marketshare',
        label: selected?.selectedOptions[0].label || 'Dollars',
        metricsList:
          selected?.selectedOptions[0].metricsList || 'actual_sales_marketshare'
      };
    }
  }
};
</script>

<style lang="css" scoped>
.no-margin {
  margin: 0;
}
.u-spacing-mr-6px {
  margin-right: 6px;
}
</style>
