<template>
  <section
    class="u-bg-color-grey-white box-shadows u-font-size-5 u-spacing-pv-s"
  >
    <div
      v-for="(option, index) in options"
      :key="index"
      class="dropdown-element select__dropdown-list-item"
      @click="$emit('select', option)"
    >
      {{ option.title }}
    </div>
  </section>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: []
    }
  }
};
</script>

<style lang="css" scope>
.bos-shadows {
  box-shadow: 0 0 8px 0 #caccce;
}
.dropdown-element :hover {
  color: #fff;
}
</style>
