<template>
  <div
    class="bg__full-screen--semitransparent u-display-flex u-flex-align-items-center u-flex-justify-content-center"
  >
    <div
      class="u-position-fixed modal u-display-flex u-flex-align-items-center u-flex-justify-content-center u-bg-color-grey-white u-border-radius-s"
      :class="width"
    >
      <div class="u-overflow-hidden u-spacing-ph-xxl">
        <slot name="header">
          <div
            class="u-display-flex u-flex-align-items-center u-flex-justify-content-center u-font-size-4 u-font-weight-600 u-spacing-pt-xxl"
          >
            <!-- <rb-icon class="rb-icon--x-large u-color-blue-base" :icon="'light-bulb'"></rb-icon> -->
            <div class="u-flex-0 u-spacing-pt-xs u-font-size-3">
              Subscription not saved.
            </div>
          </div>
        </slot>
        <slot name="content">
          <div
            class="u-display-flex u-flex-justify-content-space-between u-spacing-pv-s"
          >
            <div
              class="u-font-size-4 u-text-align-center"
              style="line-height: 23px"
            >
              To apply your latest changes, please save your subscription.
            </div>
          </div>
        </slot>
        <slot name="footer">
          <div
            class="u-display-flex u-flex-justify-content-space-between u-spacing-pt-m u-spacing-pb-xxl"
          >
            <rb-button
              class="hollow u-spacing-mr-m u-width-20"
              :text="'Exit'"
              :type="'hollow'"
              :click-fn="closeDialog.bind(this, 'exit')"
            />
            <div class="u-display-flex u-flex-justify-content-space-between">
              <rb-button
                class="hollow-outlined-btn u-spacing-mr-m"
                :text="'Cancel'"
                :type="'hollow'"
                :click-fn="closeDialog.bind(this)"
              />
              <rb-button
                class="open-filter-btn"
                :text="'Save and Exit '"
                :type="'filled'"
                :click-fn="submit.bind(this)"
              />
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      reason: '',
      checkboxGroup: [],
      templateName: ''
    };
  },
  computed: {},
  created() {},
  methods: {
    closeDialog(params) {
      this.$emit('cancelCallback', params);
    },
    submit() {
      this.$emit('submitCallback');
    }
  }
};
</script>

<style scoped lang="css">
textarea {
  border-radius: 2px;
  border-width: 1px;
  border-color: #e9eaeb;
  height: 88px;
  width: 100%;
  resize: none;
  padding: 6px;
}
.remove-alert-modal {
  width: 396px;
}
.remove-alert-modal .text--line-height {
  line-height: 14px;
}
.remove-alert-modal .header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #4b5158;
  color: #fff;
}
.remove-alert-modal .body {
  background-color: #fff;
}
.remove-alert-modal .body .rb-button {
  background-color: #d7263d;
}
.remove-alert-modal .modal-footer {
  background: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
