import sp from '@/components/ams/campaign_creation/edit_configs/amazon/sp/index.js';
import sb from '@/components/ams/campaign_creation/edit_configs/amazon/sb/index.js';
import createAdGroupSp from '@/components/ams/campaign_creation/edit_configs/amazon/createAdGroupSp/index.js';
import createAdGroupSd from '@/components/ams/campaign_creation/edit_configs/amazon/createAdGroupSd/index.js';
import createOwnCampaignSP from '@/components/ams/campaign_creation/edit_configs/amazon/createOwnSP/index.js';
import createOwnCampaignSB from '@/components/ams/campaign_creation/edit_configs/amazon/createOwnSB/index.js';
export default {
  sp: sp,
  sb: sb,
  createAdGroupSp: createAdGroupSp,
  createAdGroupSd: createAdGroupSd,
  createOwnCampaignSP: createOwnCampaignSP,
  createOwnCampaignSB: createOwnCampaignSB
};
