<template>
  <!-- eslint-disable -->
  <div
    v-if="this.params.data[this.params.fill] === null"
    class="u-display-inline-flex u-width-100 u-height-100 u-flex-align-items-center u-cursor-default u-flex-justify-content-flex-end"
  >
    <!-- eslint-enable -->
    <div
      class="cell--content u-white-space-normal u-flex-shrink-past-min-size u-color-grey-base"
      data-cy="cellValue"
    >
      {{ fillValue }}
    </div>
  </div>
  <progressFiller
    v-else
    :fill="fillValue"
    gutter-height="8px"
    :show-value="true"
    :tooltip="false"
    :tool-tip-text="toolTipText"
    :decimal-round-off="params.decimalRoundOff"
    :fill-type="params.fillType"
  />
</template>

<script>
import progressFiller from '@/components/basic/progressFiller';
import { formatter } from '@/utils/helpers/formatter.js';
export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'progressDisplay',
  components: {
    progressFiller
  },
  props: {
    tooltipAcive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fillValue: '0',
      toolTipText: '----'
    };
  },
  created() {
    this.getFillValue();
    // this.getToolTipText();
  },
  methods: {
    getFillValue() {
      this.fillValue = formatter(this.params.data[this.params.fill]);
      this.fillValue = this.fillValue + '';
    }
    // getToolTipText () {
    //   this.toolTipText = this.params.data[this.params.numerator] + '/' + this.params.data[this.params.dinominator];
    // }
  }
};
</script>
