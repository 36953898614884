<template>
  <div class="automation-types">
    <div>
      <div class="u-spacing-pb-m">
        <dynamic-component-composer
          :child-components="headerOptionsComputed"
          :container-styles="{
            'grid-template-columns': '1fr auto auto'
          }"
        />
      </div>
      <div class="automation-card-container u-display-flex">
        <automation-type-card
          v-for="(item, i) in automationTypeGroupsConfig"
          :key="i"
          :index="i"
          :is-automated="item.isAutomated"
          :is-card-expanded="i === selectedGroup"
          :card-title="item.label"
          :is-widget-loading="isWidgetLoading"
          :is-metric-loading="isMetricLoading"
          :is-chart-loading="isChartLoading"
          :card-overview-data="getCardOverviewData(item.key)"
          :card-chart-config="cardChartConfig[item.key]"
          :chart-metrics-list="chartMetricsList[item.key]"
          :card-chart-data="getCardChartData(item.key)"
          :class="{
            'is-expanded': i === selectedGroup && showExpandedCard
          }"
          :is-automation-supported="
            selectedFilterOption !== '3p' || item.isAutomationSupportedUnder3P
          "
          :is-three-p-supported="item.isThreePSupported"
          :selected-retailer="selectedFilterOption"
          @toggleAutomationTypeCard="toggleAutomationTypeCard"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AutomationTypeCard from './AutomationTypeCard.vue';
import cardGroupHeader from './cardGroupHeader.vue';
import { eventBus } from '@/utils/services/eventBus';
import Vue from 'vue';
import { cloneDeep, isEqual } from 'lodash';
import DynamicComponentComposer from '@/components/layouts/containers/dynamicComponentComposer.vue';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin';
import * as CONSTANTS from './listingsHealthConstants.js';
import { getFormattedAutomationTypesCardData } from './utils.js';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');
export default {
  name: 'AutomationTypes',
  components: {
    DynamicComponentComposer,
    AutomationTypeCard
  },
  mixins: [cwMixin],
  data() {
    return {
      selectedGroup: null,
      automationTypeGroupsConfig: [
        {
          label: 'Unavailable SKUs',
          isAutomationSupportedUnder3P: true,
          isAutomated: true,
          isThreePSupported: true,
          key: 'unavailable'
        },
        {
          label: '3P Variants',
          isAutomationSupportedUnder3P: false,
          isAutomated: true,
          isThreePSupported: false,
          key: '3pVariant'
        },
        {
          label: 'Variation Authority',
          isAutomationSupportedUnder3P: false,
          isAutomated: true,
          isThreePSupported: false,
          key: 'variationAuthority'
        },
        {
          label: 'Content Authority',
          isAutomationSupportedUnder3P: false,
          isAutomated: true,
          isThreePSupported: false,
          key: 'contentAuthority'
        }
      ],
      showExpandedCard: false,
      automationTypeVsChartMetrics:
        CONSTANTS.AUTOMATION_TYPE_VS_CHART_METRICS_MAP,
      selectedFilterOption: 'retail_and_3p',
      availableFilterOptions: [
        {
          value: 'retail_and_3p',
          label: 'Retail + 3P'
        },
        {
          value: '3p',
          label: '3P Only'
        },
        {
          value: 'retail',
          label: 'Retail Only'
        }
      ],
      cardChartConfig:
        CONSTANTS.AUTOMATION_TYPE_AUTOMATION_VS_RETAILER_VS_METRICS,
      chartMetricsList: CONSTANTS.AUTOMATION_TYPE_CHART_METRIC_LIST,
      metricConfig: {},
      overviewData: {},
      chartData: [],
      isWidgetLoading: true,
      isChartLoading: true,
      isMetricLoading: true
    };
  },
  inject: {
    setExpandedWidgetVisibility: {
      type: Function,
      default: function () {}
    }
  },
  computed: {
    headerOptionsComputed() {
      let headerOptions = [];
      headerOptions.push({
        ui_component: 'cardGroupHeader',
        props: {
          title: 'Automation Types',
          description:
            'Click on "view more details" button in each card to analyse related insights and automation details.',
          filterOptions: this.availableFilterOptions,
          selectedOption: this.selectedFilterOption,
          onFilterChange: this.onFilterUpdate
        }
      });
      return headerOptions;
    }
  },
  watch: {
    selectedGroup: {
      immediate: true,
      handler(newVal) {
        if (newVal == null) {
          this.setExpandedWidgetVisibility(false);
        } else {
          this.setExpandedWidgetVisibility(true);
        }
      }
    },
    widgetRequestParams: {
      deep: true,
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.fetchData();
        }
      }
    }
  },
  created() {
    Vue.component('cardGroupHeader', cardGroupHeader);
    this.generateMetricConfig();
    this.fetchData();
    eventBus.$on('closeAutomationTypeCard', () => {
      this.closeCard();
    });
  },
  beforeDestroy() {
    eventBus.$off('closeAutomationTypeCard');
  },
  methods: {
    onFilterUpdate(data) {
      if (data.value === '3p') {
        this.closeCard();
      }
      this.selectedFilterOption = data.value;
      eventBus.$emit('selectedRetailerUpdated', data);
    },
    toggleAutomationTypeCard(data) {
      let { cardId } = data;
      if (cardId !== this.selectedGroup) {
        this.showExpandedCard = true;
        this.selectedGroup = cardId;
      } else {
        this.showExpandedCard = !this.showExpandedCard;
        this.selectedGroup = this.showExpandedCard ? data.cardId : null;
      }
      if (this.automationTypeGroupsConfig[this.selectedGroup]) {
        eventBus.$emit(
          'selectedAutomationCardUpdated',
          this.automationTypeGroupsConfig[this.selectedGroup].key
        );
      }
    },
    closeCard() {
      this.showExpandedCard = false;
      this.selectedGroup = null;
    },
    generateMetricConfig() {
      const metricConfig = {};
      const metricData = Object.values(this.metadata.metrics);
      metricData.forEach((metric) => {
        const tagConfig = metric.metadata.tagConfig;
        metricConfig[metric.alias] = tagConfig;
      });
      this.metricConfig = metricConfig;
    },
    fetchData() {
      this.isWidgetLoading = false;
      this.isMetricLoading = true;
      this.isChartLoading = true;
      let api = Object.values(this.metadata.metrics)[0].api;
      let request = api.request;
      request.where.date = {};
      request.where.date.from = ':from';
      request.where.date.to = ':to';
      request = dashUtils.replacePlaceHolderWithData(
        request,
        this.widgetRequestParams
      );
      request = this.tempTransformer(
        request,
        this.metadata.metadata.cardMetricList
      );
      dashUtils
        .fetchDataAPI(request, api)
        .then((args) => {
          this.transformData(args.entityData);
        })
        .catch((exception) => {
          console.error(
            'Exception occurred when fetching low inventory data',
            exception
          );
        });
    },
    tempTransformer(request, metricsList) {
      let modifiedRequest = cloneDeep(request);
      modifiedRequest.metricsList = ':metricsList';
      modifiedRequest.operations.timeseriesEnabled = true;
      modifiedRequest.entityType = '#ALLOVER_AGGREGATE';
      const tempParams = {
        ':metricsList': metricsList
      };
      modifiedRequest = dashUtils.replacePlaceHolderWithData(
        modifiedRequest,
        tempParams
      );
      return modifiedRequest;
    },
    transformData(value) {
      const overviewResponseData = value[0]?.data;
      const chartData = [];
      overviewResponseData?.forEach((el) => {
        this.overviewData[el.name] = {
          tag1: el.RESULT[el.name]
        };
        chartData.push({
          name: el.name,
          alias: el.alias,
          type: el.type,
          TIMESERIES: el.TIMESERIES
        });
      });
      this.chartData = chartData;
      this.isMetricLoading = false;
      this.isChartLoading = false;
    },
    getCardOverviewData(automationType) {
      return getFormattedAutomationTypesCardData(
        automationType,
        this.overviewData,
        this.metricConfig,
        this.selectedFilterOption
      );
    },
    getCardChartData(automationType) {
      let automationSpecificChartData = cloneDeep(this.chartData);
      const metricsToBeIncluded =
        this.automationTypeVsChartMetrics[automationType];
      return automationSpecificChartData.filter((metricData) =>
        metricsToBeIncluded.includes(metricData.name)
      );
    }
  }
};
</script>

<style lang="css">
.automation-types .dropdown-trigger > div:nth-child(1) > div:nth-child(3) {
  font-weight: 600;
}
.automation-types .automation-card-container {
  gap: 16px;
}
.automation-types .is-expanded {
  position: relative;
}
.automation-types .is-expanded:after {
  content: ' ';
  display: block;
  background: #fff;
  height: 24px;
  width: 100%;
  bottom: -20px;
  position: absolute;
  left: 0em;
  clip-path: inset(0px -2px 0px -2px);
  z-index: 1;
}
</style>
