import DashboardServiceCMRouteWrapper from '@/pages/dashboard_service_campaign_management/DashboardServiceCMRouteWrapper.vue';
import CONSTANTS from '@/utils/constants';

const EntityDetails = () => import('@/pages/entity_details/dsp.vue');

export default {
  configCheckRequired: true,
  routes: [
    {
      path: 'orders/:entityId',
      name: 'Order Details',
      title: 'Order Details',
      component: EntityDetails,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'orders',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'line_items/:entityId',
      name: 'Line Item Details',
      title: 'Line Item Details',
      component: EntityDetails,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'line_items',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'orders',
      name: 'Orders',
      title: 'Orders',
      component: DashboardServiceCMRouteWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      props: { tabName: 'Orders', configKey: 'amazonDsp' },
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'line_items',
      name: 'Line Items',
      title: 'Line Items',
      component: DashboardServiceCMRouteWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'line_items',
      props: { tabName: 'Line Items', configKey: 'amazonDsp' },
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: '',
      redirect: () => {
        return 'orders';
      }
    }
  ]
};
