<template>
  <div class="release-notes-search-wrapper">
    <form
      class="u-position-relative u-display-flex"
      @submit.prevent="onSearchInput"
    >
      <rb-input
        v-model="searchText"
        :placeholder="'Search'"
        class="search__input u-width-100 u-border-left-none"
        :rounded="false"
        @input="onSearchInput"
      />
      <span
        v-if="searchText"
        class="search-text-input u-position-absolute u-display-flex u-color-grey-lighter u-font-size-7"
      >
        {{
          searchTermCount === 1
            ? `${searchTermCount} Result`
            : `${searchTermCount} Results`
        }}
      </span>
      <span
        class="search-icon u-position-absolute u-display-flex"
        @click="filterAndSearchReleaseNotes"
      >
        <rb-icon
          icon="search"
          class="search__input-icon rb-icon--medium"
        />
      </span>
    </form>
  </div>
</template>

<script>
import { debounce, cloneDeep } from 'lodash';
export default {
  props: {
    releases: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data() {
    return {
      searchText: '',
      searchTermCount: 0,
      releaseItemHasSearchText: false,
      ciqSalesForSearch: []
    };
  },
  mounted() {
    // preserve initial release notes array for search after initial load
    this.ciqSalesForSearch = cloneDeep(this.releases);
  },
  methods: {
    onSearchInput: debounce(function () {
      this.filterAndSearchReleaseNotes();
    }, 500),
    filterAndSearchReleaseNotes() {
      this.searchTermCount = 0;
      let filteredAndSearchedArray = [];
      let releaseNotesArrForSearch = cloneDeep(this.ciqSalesForSearch);
      // edge cases
      // 1 Search with white space
      // 2 Searching with special chars which are not allowed ( chars used in HTML as string )
      // return no results for these
      if (
        (this.searchText?.length && !/\S/.test(this.searchText)) || // contains only whitespace
        !this.isAllowedChars(this.searchText) || // contains not allowed special chars
        !releaseNotesArrForSearch.length
      ) {
        // empty release notes
        this.filteredAndSearchedArray = [];
      } else {
        filteredAndSearchedArray = releaseNotesArrForSearch?.filter(
          (releaseItem) => {
            // Ignore searching for config: false release notes
            if (this.$parent.configToCheck(releaseItem?.configToCheck)) {
              if (this.searchText !== '') {
                this.releaseItemHasSearchText = false;
                releaseItem.date = this.handleSearchHighlight(
                  releaseItem?.date
                );
                releaseItem.features = this.recursiveSearch(
                  releaseItem?.features
                );
                if (this.releaseItemHasSearchText) {
                  return releaseItem;
                }
              } else {
                return releaseItem;
              }
            }
          }
        );
      }
      this.$emit('updateReleaseNotesArray', filteredAndSearchedArray); // emits to parent to update release notes
    },
    recursiveSearch(arr, prefix = '') {
      // this method handles search by recursion based on the json defined in releaseNotesContent.js
      return arr.map((item) => {
        let updatedPrefix = `${prefix}sub`;
        let obj = {};
        if (
          this.$parent.configToCheck(item?.configToCheck) &&
          !item?.disableSearch
        ) {
          obj = {
            [`${prefix}title`]: this.handleSearchHighlight(
              item?.[`${prefix}title`]
            ),
            [`${prefix}points`]: item?.[`${prefix}points`]?.length
              ? this.recursiveSearch(item?.[`${prefix}points`], updatedPrefix)
              : []
          };
        } else if (item?.disableSearch) {
          obj = {
            [`${prefix}title`]: item?.[`${prefix}title`],
            [`${prefix}points`]: item?.[`${prefix}points`]
          };
        }
        if (item?.configToCheck) {
          obj.configToCheck = item.configToCheck;
        }
        return obj;
      });
    },
    handleSearchHighlight(text) {
      // highlight the searched text in yellow color
      let str = this.searchText;
      let reg = new RegExp(str, 'gi');
      let replaced = text?.search(reg) >= 0;
      if (replaced && !this.releaseItemHasSearchText) {
        this.releaseItemHasSearchText = true;
      }
      return text?.replace(reg, (str) => {
        this.searchTermCount += 1;
        return `<mark class="searched-term">${str}</mark>`;
      });
    },
    isAllowedChars(str) {
      // checking for allowed chars in search term
      let charCode;
      let strLength = str.length;
      // allowedSpecialCharecters - [space, !, #, &, *];
      const allowedSpecialCharecters = [32, 33, 35, 38, 42];
      for (let i = 0; i < strLength; i++) {
        charCode = str.charCodeAt(i);
        if (
          !(charCode > 47 && charCode < 58) && // numeric (0-9)
          !(charCode > 64 && charCode < 91) && // upper alpha (A-Z)
          !(charCode > 96 && charCode < 123) && // lower alpha (a-z)
          allowedSpecialCharecters.indexOf(charCode) === -1
        ) {
          return false;
        }
      }
      return true;
    }
  }
};
</script>

<style lang="css" scoped>
.release-notes-search-wrapper {
  position: sticky;
  top: 0px;
  padding: 1.6rem;
  background-color: #ffffff;
  z-index: 100;
}
.release-notes-search-wrapper .search-text-input {
  top: 13px;
  right: 0px;
  margin-right: 10px;
}
.release-notes-search-wrapper .search-icon {
  top: 10px;
  left: 8px;
  margin: auto;
}
</style>
