<template>
  <GenericCampaignManagement
    ref="campaignManagement"
    :key="$route.path"
    :config="config"
  />
</template>

<script>
import GenericCampaignManagement from '@/pages/campaign-management/generic-campaign-management.vue';
import retailerConfig from '@/pages/campaign-management/retailers/index.js';

export default {
  components: {
    GenericCampaignManagement
  },
  data() {
    return {
      currentTab: null
    };
  },
  computed: {
    config() {
      const splitRoute = this.$route.path.split('/');
      const tab = splitRoute[splitRoute.length - 1];
      return retailerConfig(this)[this.$store.getters.getRetailer][tab];
    }
  },
  methods: {
    openSnackbar(content) {
      this.$snackbar.open({
        message: content,
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    }
  }
};
</script>

<style></style>
