var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-display-flex setProgressStyle u-flex-align-items-center",style:({
    width: _vm.width() + '%',
    background: _vm.findBackgroundColor(),
    color: _vm.findTextColor()
  })},[_c('div',{staticClass:"u-display-flex u-flex-direction-column",staticStyle:{"position":"absolute","left":"16px","color":"#2b333b"}},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippyConfig),expression:"tippyConfig"}],staticClass:"u-font-size-5 u-spacing-mb-xs",attrs:{"title":_vm.getTooltip(_vm.formatter(_vm.progressData.keyOne))}},[_vm._v(" "+_vm._s(_vm.formatter(_vm.progressData.keyOne))+" ")]),_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippyConfig),expression:"tippyConfig"}],staticClass:"u-font-size-7",staticStyle:{"opacity":"0.7"},attrs:{"title":_vm.getTooltip(_vm.formatter(_vm.progressData.keyTwo))}},[(!_vm.progressData.hideComparator)?_c('span',[_vm._v(" / ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.formatter(_vm.progressData.keyTwo))+" ")])]),_c('div',{staticClass:"u-display-flex u-position-absolute u-min-width-80px metricPostion u-flex-align-items-center u-flex-justify-content-space-between"},[_c('div',[_vm._t("customComponent")],2),_c('span',{staticClass:"u-font-size-7",class:{
        'u-fill-color-grey-light': _vm.progressData.keyOne === _vm.progressData.keyTwo
      }},[(_vm.progressData.keyOne !== _vm.progressData.keyTwo)?_c('rb-icon',{staticClass:"rb-icon rb-icon--x-small",class:{ 'u-display-none': _vm.progressData.pvp === 'NA' },attrs:{"icon":_vm.getProgressBarIcon()}}):_vm._e(),(!!_vm.progressData.pvp || _vm.progressData.pvp === 0)?_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippyConfig),expression:"tippyConfig"}],class:{ 'u-fill-color-grey-light': _vm.progressData.pvp === 'NA' },attrs:{"title":_vm.getTooltip(_vm.formatPVP(_vm.progressData.pvp))}},[_vm._v(" "+_vm._s(_vm.formatPVP(_vm.progressData.pvp))+" ")]):_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippyConfig),expression:"tippyConfig"}],attrs:{"title":_vm.getTooltip(
            _vm.formatter(_vm.getPvp(_vm.progressData.keyOne, _vm.progressData.keyTwo))
          )}},[_vm._v(" "+_vm._s(_vm.formatter(_vm.getPvp(_vm.progressData.keyOne, _vm.progressData.keyTwo))))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }