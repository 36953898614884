<template>
  <div class="u-display-flex u-text-overflow-ellipsis u-font-size-6">
    <rb-sku
      :id="'tooltipSKU'"
      :title="data.product_title"
      :asin="data.sku"
      :image-url="data.image_url ? data.image_url : ''"
      :product-page-url="data.product_url ? data.product_url : '#'"
      :enable-s-d-p="enableSDP"
      @navigateToSDP="navigateToSDP"
    />
  </div>
</template>

<script>
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';

export default {
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          product_title: 'NA',
          sku: 'NA'
        };
      }
    },
    config: {
      type: Object,
      default: {}
    }
  },
  computed: {
    enableSDP() {
      return this.config?.enableSDP ?? true;
    }
  },
  methods: {
    navigateToSDP(asin) {
      navigate_to_sdp(asin);
    }
  }
};
</script>
