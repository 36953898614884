<template>
  <PercentageBackgroundComponent
    :value="percentValue"
    :background-color="backgroundColor"
    :label-color="labelColor"
    :label="labelData"
    :container-classes="containerClasses"
    :na-text="naText"
    :na-tooltip="naTooltip"
  />
</template>

<script>
import PercentageBackgroundComponent from '@/components/basic/percentageBackgroundComponent.vue';
import { isEmpty } from 'lodash';
export default {
  components: {
    PercentageBackgroundComponent
  },
  props: {
    label: {
      type: [String, Number],
      default: ''
    },
    // instock array input. keep the name value for backward compatability for existing metadata contract
    value: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    labelColor: {
      type: String,
      default: '#2b333b'
    },
    height: {
      type: Number,
      default: 50
    },
    containerClasses: {
      type: String,
      default:
        'u-font-size-5 u-width-100 u-height-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center u-border-left u-border-width-s u-border-color-grey-xxx-light'
    },
    naText: {
      type: String,
      default: '-'
    },
    naTooltip: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      percentValue: null,
      labelData: ''
    };
  },
  created() {
    const instockOccurence = JSON.parse(this.value) || [];
    if (isEmpty(instockOccurence)) {
      this.percentValue = null;
      this.labelData = '-';
      return;
    }
    const value =
      instockOccurence.reduce((prev, curr) => {
        return prev + curr;
      }) / instockOccurence.length;
    this.percentValue = value * 100;
  }
};
</script>

<style></style>
