import transformer from '@/utils/services/data-transformer';
import HttpLayer from '@/utils/services/http-layer';
import utils from '@/utils/helpers/';
import { cloneDeep, groupBy, omit } from 'lodash';
import moment from 'moment-timezone';
import Vue from 'vue';

const timeZone = 'America/Los_Angeles';

const cacheClearHeader = {
  headers: {
    cache: false
  }
};
const WhiteListFilters = {
  Campaigns: [
    'search',
    'campaign_type',
    'targeting_type',
    'portfolio_name',
    'profile_name',
    'tactic',
    'serving_status',
    'campaign_id'
  ],
  Keywords: [
    'campaign_type',
    'targeting_type',
    'portfolio_name',
    'profile_name',
    'tactic',
    'keyword_serving_status_latest',
    'match_type',
    'keyword_id',
    'search'
  ]
};
const disableStreamChart = () =>
  Vue.options.filters.config_check('feature.pages.disableStreamChart');

const setSKUSuccessPayload = (context, data, response, mutations) => {
  response.data = response.data.map((item) => {
    if (
      item.RESULT.total_sales === null ||
      item.RESULT.total_sales - item.RESULT.attributedSales14dSameSKU < 0
    ) {
      item.RESULT.organic_sales = null;
    } else {
      item.RESULT.organic_sales =
        item.RESULT.total_sales - item.RESULT.attributedSales14dSameSKU;
    }
    return item;
  });
  let _aArray = [];
  _aArray = transformer.mergeResultDimension(response.data, true);
  if (_aArray.length > 0) {
    fetchRealtimeMetadata(context, data, _aArray, mutations);
    context.commit(mutations.success, {
      key: data.meta.key,
      rows: _aArray,
      totalRows: _aArray[0].auto_cubesdk_count || _aArray[0].count,
      page: data.body.APIConfig.page
    });
  } else {
    context.commit(mutations.success, {
      key: data.meta.key,
      rows: [],
      page: data.body.APIConfig.page
    });
  }
};

export const realtimeTableData = (context, data, mutations, isSKUs = false) => {
  const isInternalUser = utils.internalUserCheck(window.user);
  context.commit(mutations.reset, {
    key: data.meta.key
  });
  const finalWhereClause = transformer.getCompleteWhereClause(
    data.meta.localFilters || [],
    context.state.whereClause
  );
  const transformedWhereClause = transformToFinalWhereClause({
    data,
    configCheck: Vue.options.filters.config_check('feature.campaignListFix'),
    context,
    finalWhereClause
  });
  let modifiedWhereClauseForAdvMetadata;

  // here we are setting the where clause
  if (!isSKUs) {
    modifiedWhereClauseForAdvMetadata = cloneDeep(transformedWhereClause);
    modifiedWhereClauseForAdvMetadata.date = {};
    modifiedWhereClauseForAdvMetadata.pvpDate = {};

    if (data.body.APIConfig.bundleCubeExecutionRequest.adv_metadata) {
      data.body.APIConfig.bundleCubeExecutionRequest.adv_metadata.where =
        modifiedWhereClauseForAdvMetadata;
    }

    data.body.APIConfig.bundleCubeExecutionRequest =
      transformWhereClauseDimensionList(
        data.body.APIConfig,
        transformedWhereClause
      );

    const isDedupeEnabled = Vue.options?.filters?.config_check(
      'feature.pages.enable_dedup_before_rollup'
    );
    if (!isDedupeEnabled) {
      data.body.APIConfig = dedupBeforeRollupHandling(data.body?.APIConfig);
    }
  }

  const requestObject = {
    cube: isSKUs ? 'EXECUTE_CUSTOM_CUBE_SKU_API' : null,
    APIData: data.body.APIConfig
  };

  HttpLayer.post(requestObject)
    .then((response) => {
      if (!response.success) {
        context.commit(mutations.error, {
          key: data.meta.key
        });
      } else {
        isSKUs
          ? setSKUSuccessPayload(context, data, response, mutations)
          : transformSuccessPayload(
              context,
              response,
              data,
              mutations,
              isInternalUser
            );
      }
    })
    .catch(() => {
      context.commit(mutations.error, {
        key: data.meta.key
      });
    });
};

export const dedupBeforeRollupHandling = (payload = {}) => {
  const data = cloneDeep(payload);
  for (const cube in data.bundleCubeExecutionRequest) {
    const isDedupPresent =
      data.bundleCubeExecutionRequest[cube]?.dedupBeforeRollup
        ?.enableDedupBeforeRollup;
    const isKeywordIdPresent =
      data.bundleCubeExecutionRequest[cube]?.entityType === 'keyword_id';
    const isCampaignIdPresent =
      data.bundleCubeExecutionRequest[cube]?.entityType === 'campaign_id';
    const isValidEntityTypePresentInGroupBy = !!data.bundleCubeExecutionRequest[
      cube
    ]?.groupByDimensionsList?.includes('keyword_id', 'campaign_id');
    if (
      isDedupPresent &&
      (isKeywordIdPresent ||
        (isCampaignIdPresent && cube === 'adv_metadata') ||
        isValidEntityTypePresentInGroupBy)
    ) {
      data.bundleCubeExecutionRequest[
        cube
      ].dedupBeforeRollup.enableDedupBeforeRollup = false;
    }
    if (isCampaignIdPresent && cube !== 'adv_metadata') {
      data.bundleCubeExecutionRequest[
        cube
      ].dedupBeforeRollup.disableDistinctInRollup = true;
    }
  }
  return data;
};

export const modifyWhereClauseForEsName = (where) => {
  const modifiedDimensionList = [];
  const whereClone = cloneDeep(where);
  whereClone?.dimensionNameValueList?.length > 0 &&
    whereClone.dimensionNameValueList.forEach((dimension) => {
      const filter = {
        ...transformer.addEsDataSetName(
          dimension.dimensionName,
          dimension.dimensionValue
        ),
        operator: dimension.operator
      };
      modifiedDimensionList.push(filter);
    });
  whereClone.dimensionNameValueList = modifiedDimensionList;
  return whereClone;
};

export const transformWhereClauseDimensionList = (config, where) => {
  const whereClauseInitialiser = {};
  for (const cube in config.bundleCubeExecutionRequest) {
    whereClauseInitialiser[cube] = cloneDeep(where.dimensionNameValueList);
    const { measuresList, groupByDimensionsList } =
      config.bundleCubeExecutionRequest[cube];

    whereClauseInitialiser[cube] = whereClauseInitialiser[cube].filter(
      (dimensionValue) => {
        const isAdvMetadata = cube === 'adv_metadata';
        const categoryFilterCheck =
          !isAdvMetadata && dimensionValue.dimensionName.includes('dimension');
        const isFilterPresent =
          measuresList.includes(dimensionValue.dimensionName) ||
          groupByDimensionsList.includes(dimensionValue.dimensionName) ||
          ['search'].includes(dimensionValue.dimensionName) ||
          categoryFilterCheck;
        return isFilterPresent;
      }
    );
  }
  Object.keys(config.bundleCubeExecutionRequest).forEach((cube) => {
    const cloneCube = cloneDeep(config.bundleCubeExecutionRequest[cube]);
    cloneCube.where.dimensionNameValueList = whereClauseInitialiser[cube];
    config.bundleCubeExecutionRequest[cube] = cloneCube;
    if (cube === 'adv_metadata') {
      config.bundleCubeExecutionRequest[cube].where.date = {};
      config.bundleCubeExecutionRequest[cube].where.pvpDate = {};
    }
  });
  return config.bundleCubeExecutionRequest;
};

export const timeSeriesDimensionValue = {
  DAY: 'report_date',
  MONTH: 'month_start_date',
  WEEK: 'week_start_date'
};

export function transformRollUpDataPayload(data, context) {
  data.body.APIConfig.timeseriesRollupBy = context.state.timeSeriesRollUpBy;
  data.body.APIConfig.timeseriesDimension =
    timeSeriesDimensionValue[context.state.timeSeriesRollUpBy];
}

export function commonMetricDataUpdate(data, key, keyValue, isIndexed = false) {
  const metrics = ['adv_metrics', 'sov_metrics'];
  metrics.forEach((value, index) => {
    if (isIndexed) {
      const arrData = data[value][key];
      arrData[0] = keyValue[index] || keyValue[0];
    } else {
      data[value][key] = keyValue[index] || keyValue[0];
    }
  });
}

function transformSuccessPayload(
  context,
  response,
  data,
  mutations,
  isInternalUser
) {
  const { stateKey } = data.body.metadata;
  let _aArray = [];
  _aArray = transformer.mergeResultDimension(response.data, true);
  if (_aArray.length > 0) {
    context.commit(mutations.success, {
      key: data.meta.key,
      rows: _aArray,
      totalRows: _aArray[0].final_auto_cubesdk_count || _aArray[0].count,
      page: data.body.APIConfig.page
    });
    const streamTableDataState = `SET_STREAM_${data.body.APIConfig?.entityType
      .split('_')[0]
      .toUpperCase()}S_TABLE_DATA`;
    const streamTableDataGetter = `getStream${
      data.body.APIConfig?.entityType.split('_')[0].charAt(0).toUpperCase() +
      data.body.APIConfig?.entityType.split('_')[0].slice(1)
    }sToggle`;
    if (!context.getters[streamTableDataGetter]) {
      context.commit(streamTableDataState, {
        arr: _aArray,
        tableData: data,
        mutations
      });
    } else {
      fetchStreamData(context, data, _aArray, mutations);
    }

    if (isInternalUser) {
      const existingInterval = context.state?.[stateKey]?.intervalId;
      if (data.meta?.realTimeRefresh || typeof existingInterval === 'string') {
        refreshRealtimeData(
          context,
          data,
          _aArray,
          mutations,
          existingInterval
        );
      } else {
        fetchRealtimeMetadata(context, data, _aArray, mutations);
      }
    }
  } else {
    context.commit(mutations.success, {
      key: data.meta.key,
      rows: [],
      page: data.body.APIConfig.page
    });
  }
}

export function refreshRealtimeData(
  context,
  data,
  _aArray,
  mutations,
  existingInterval
) {
  let count = 0;
  const limit = 3;
  const time = 20000; // 20 seconds
  if (existingInterval) {
    let [intervalId, newCount] = existingInterval.split('_');
    count = data?.meta?.realTimeRefresh ? 0 : parseInt(newCount);
    intervalId = parseInt(intervalId);
    clearInterval(intervalId);
  }
  delete data?.meta?.realTimeRefresh;

  // setting dummy intervalId to show loader immediately
  relatimeIntervalFunction(
    context,
    data,
    _aArray,
    mutations,
    '01',
    count,
    false
  );
  const interval = setInterval(() => {
    const intervalLimitReached = count >= limit;
    if (intervalLimitReached) {
      clearInterval(interval);
      return;
    }

    relatimeIntervalFunction(
      context,
      data,
      _aArray,
      mutations,
      interval,
      count,
      intervalLimitReached
    );
    count++;
  }, time);
}

function relatimeIntervalFunction(
  context,
  data,
  _aArray,
  mutations,
  interval,
  count,
  intervalLimitReached
) {
  fetchRealtimeMetadata(
    context,
    data,
    _aArray,
    mutations,
    intervalLimitReached ? null : `${interval}_${count}`
  );
}

function fetchRealtimeMetadata(
  context,
  data,
  _array,
  stateMap,
  intervalId = null
) {
  const realtimeMetadataState = stateMap.realtimeMetadata;
  const { primaryKey, stateKey, apiConfig } = data.body.metadata;
  context.commit(realtimeMetadataState, {
    data: {},
    dataFetched: false,
    intervalId,
    primaryKey,
    stateKey,
    realtime: true,
    apiFailed: false
  });

  const dimensionNameValueList = _array.map((element) => {
    return {
      dimensionName: primaryKey,
      dimensionValue: element[primaryKey]
    };
  });
  if (dimensionNameValueList.length > 0) {
    const apiPayload = apiConfig;
    apiPayload.where.dimensionNameValueList = dimensionNameValueList;
    HttpLayer.post({
      cube: 'EXECUTE_CUBE_REALTIME_METADATA_API',
      APIData: apiPayload,
      header: cacheClearHeader
    }).then((response) => {
      if (!response.success) {
        context.commit(realtimeMetadataState, {
          data: {},
          dataFetched: true,
          intervalId,
          primaryKey,
          stateKey,
          realtime: true,
          apiFailed: true
        });
        return;
      }
      const responseData = transformer.mergeResultDimension(response.data);
      const responseMetadata = responseData?.reduce((acc, entity) => {
        acc[entity[primaryKey]] = entity;
        return acc;
      }, {});
      context.commit(realtimeMetadataState, {
        data: responseMetadata,
        dataFetched: true,
        lastUpdated: new Date(),
        intervalId,
        primaryKey,
        stateKey,
        realtime: true,
        apiFailed: false
      });
      if (primaryKey === 'ad_id') {
        transformer.mutateTableDataStateWithMetadataRows(
          _array,
          responseMetadata,
          primaryKey
        );
      }
    });
  } else {
    context.commit(realtimeMetadataState, {
      data: {},
      dataFetched: true,
      intervalId,
      primaryKey,
      realtime: true,
      stateKey,
      apiFailed: true
    });
  }
}

export const mutateRealtimeState = (state, data) => {
  state[data.stateKey] = data;
};

export const getCampaignChartAPITemplates = (context, data) => {
  const chartAPIPayload = cloneDeep(data.body.APIConfig);
  const finalWhereClause = transformer.getCompleteWhereClause(
    (data.meta.localFilters || []).concat(data.meta.plotSelections || []),
    context.state.whereClause
  );
  const transformedWhereClause =
    transformer.transformWhereClauseWithTag(finalWhereClause);
  chartAPIPayload.where = transformedWhereClause;
  chartAPIPayload.tagWhereClause = transformedWhereClause.tagWhereClause || [];
  chartAPIPayload.where.date = context.state.whereClause.date;
  chartAPIPayload.where.pvpDate = context.state.whereClause.pvpDate;
  delete chartAPIPayload.where.tagWhereClause;
  chartAPIPayload.pvpenabled = chartAPIPayload.where.pvpDate !== undefined;
  chartAPIPayload.timeseriesRollupBy = context.state.timeSeriesRollUpBy;
  chartAPIPayload.timeseriesDimension =
    timeSeriesDimensionValue[context.state.timeSeriesRollUpBy];
  const streamChartAPIPayload = transforPayloadForRealtime(
    cloneDeep(data.body.SreamAPIConfig),
    context.state,
    data,
    'Campaigns'
  );
  return { chartAPIPayload, streamChartAPIPayload };
};

export const transforPayloadForRealtime = (
  apiTemplate,
  state,
  data,
  pageType
) => {
  const finalWhereClause = transformer.getCompleteWhereClause(
    (data.meta.localFilters || []).concat(data.meta.plotSelections || []),
    state.whereClause
  );
  const transformedWhereClause =
    transformer.transformWhereClauseWithTag(finalWhereClause);
  apiTemplate.where = transformedWhereClause;
  apiTemplate.pvptimeseriesEnabled =
    pageType === 'Campaigns'
      ? state.enablePVPStream
      : state.enableKeywordsPVPStream;
  apiTemplate.timeseriesDimension = 'hour';
  apiTemplate.where.date = {
    from: moment()
      .tz(timeZone)
      .subtract(apiTemplate.pvptimeseriesEnabled ? 1 : 0, 'days')
      .format('YYYY-MM-DD'),
    to: moment()
      .tz(timeZone)
      .subtract(apiTemplate.pvptimeseriesEnabled ? 1 : 0, 'days')
      .format('YYYY-MM-DD'),
    name: 'last90Days',
    page_wise_min_max_key: 'ams_campaign'
  };
  apiTemplate.where.dimensionNameValueList =
    apiTemplate.where.dimensionNameValueList?.filter((value) => {
      if (
        apiTemplate.measuresList?.includes(value.dimensionName) ||
        WhiteListFilters[pageType].includes(value.dimensionName) ||
        apiTemplate.groupByDimensionsList?.includes(value.dimensionName)
      ) {
        return value;
      }
    }) ?? [];
  return apiTemplate;
};

export const populateCampaignsChartData = async (
  data,
  context,
  chartAPIPayload,
  commitMessage
) => {
  try {
    const response = await HttpLayer.post({
      cube: 'EXECUTE_CUBE_CHART_API',
      APIData: chartAPIPayload
    });
    if (!response.success) {
      context.commit(commitMessage.ERROR, {
        key: data.meta.key
      });
    } else if (response.data.length > 0) {
      handleChartAPIResponse(
        context,
        data,
        response,
        commitMessage,
        false,
        'Campaigns'
      );
    } else {
      context.commit(commitMessage.SUCCESS, {
        key: data.meta.key,
        columns: [],
        metrics: {}
      });
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const populateStreamChartData = async (
  data,
  context,
  chartAPIPayload,
  commitMessage,
  enablePVPStream,
  pageName
) => {
  if (disableStreamChart()) {
    return;
  }
  try {
    const response = await HttpLayer.post({
      cube: 'EXECUTE_CUBE_CHART_API',
      APIData: chartAPIPayload,
      header: cacheClearHeader
    });
    if (!response.success) {
      context.commit(commitMessage.ERROR, {
        key: data.meta.key
      });
    } else if (response.data.length > 0) {
      handleChartAPIResponse(
        context,
        data,
        response,
        commitMessage,
        enablePVPStream,
        pageName
      );
    } else {
      context.commit(commitMessage.SUCCESS, {
        key: data.meta.key,
        columns: [],
        metrics: {}
      });
    }
  } catch (error) {
    throw new Error(error);
  }
};
const handleChartAPIResponse = (
  context,
  data,
  response,
  commitMessage,
  enablePVPStream,
  pageName
) => {
  let xs = {};
  response.data[0].TIMESERIES = transformer.getChartRollUpMapping(
    response.data[0].TIMESERIES,
    timeSeriesDimensionValue[context.state.timeSeriesRollUpBy]
  );
  let aTemp = [];
  for (const item of response.data) {
    aTemp = transformer.getChartDataInFormat(data, item.TIMESERIES);
  }
  for (const item of aTemp) {
    if (item[0] !== 'report_date') {
      xs[item[0]] = 'report_date';
    }
  }
  const commitData = {
    key: data.meta.key,
    xs,
    metrics: data.body.getTagData
      ? data.body.getTagData(response.data)
      : undefined,
    metricsUnits: data.body.getTagUnitData
      ? data.body.getTagUnitData(response.data)
      : {}
  };
  if (enablePVPStream) {
    commitData.pvp_columns = cloneDeep(aTemp);
    commitData.columns =
      pageName === 'Campaigns'
        ? context.getters.getStreamChartData.data
        : context.getters.getKeywordsStreamChartData.data;
  } else {
    commitData.columns = cloneDeep(aTemp);
    delete commitData.pvp_columns;
  }
  context.commit(commitMessage.SUCCESS, commitData);
};
export const fetchStreamData = (context, data, responseRows, mutations) => {
  if (disableStreamChart()) {
    return;
  }
  const streamRequest = data.body.stream;
  const cubeName = streamRequest.cubeName;

  const dimensionValueMap = {
    amazon_hourly_keywords_stream_workbench: 'keyword_id',
    amazon_hourly_campaigns_stream_workbench: 'campaign_id'
  };

  const newWhereClause = addWhereClause(
    { dimensionNameValueList: [] },
    responseRows,
    cubeName,
    dimensionValueMap
  );
  streamRequest.where = newWhereClause;
  context.commit(mutations.streamData, {});
  const setDate = (streamConfigWhere) => {
    const streamDataWhereCopy = cloneDeep(streamConfigWhere);

    const presentDate = moment().tz(timeZone).format('YYYY-MM-DD');
    const dateBeforePresent = moment()
      .tz(timeZone)
      .add(-1, 'days')
      .format('YYYY-MM-DD');
    streamDataWhereCopy.date = {
      from: presentDate,
      to: presentDate,
      name: 'last30Days',
      page_wise_min_max_key: 'ams_campaign'
    };
    streamDataWhereCopy.pvpDate = {
      from: dateBeforePresent,
      to: dateBeforePresent,
      compare_name: null
    };
    return streamDataWhereCopy;
  };

  streamRequest.where = setDate(streamRequest.where);

  HttpLayer.post({
    APIData: streamRequest,
    header: cacheClearHeader
  })
    .then((response) => {
      const transformedResponse = transformer.mergeResultDimension(
        response.data
      );
      const streamData = groupBy(
        transformedResponse,
        dimensionValueMap[cubeName]
      );
      context.commit(mutations.streamData, {
        primaryKey: dimensionValueMap[cubeName],
        data: streamData
      });
    })
    .catch(() => {
      context.commit(mutations.error, {
        key: data.meta.key
      });
    });
};

function addWhereClause(
  streamWhereclause,
  responseRows,
  cubeName,
  dimensionValueMap
) {
  const streamWhereClauseCopy = cloneDeep(streamWhereclause);
  responseRows.forEach((rowData) => {
    streamWhereClauseCopy.dimensionNameValueList.push({
      dimensionName: dimensionValueMap[cubeName],
      dimensionValue: rowData[dimensionValueMap[cubeName]]
    });
  });
  return streamWhereClauseCopy;
}

export function transformToFinalWhereClause({
  data,
  configCheck,
  context,
  finalWhereClause
}) {
  const transformedWhereClause =
    transformer.transformWhereClauseWithTag(finalWhereClause);
  if (configCheck) {
    data.body.APIConfig.where = omit(transformedWhereClause, 'tagWhereClause');
  } else {
    data.body.APIConfig.where = transformedWhereClause;
  }
  data.body.APIConfig.tagWhereClause =
    transformedWhereClause.tagWhereClause || [];
  data.body.APIConfig.where.date = context.state.whereClause.date;
  data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
  if (!configCheck) {
    delete data.body.APIConfig.where.tagWhereClause;
  }
  if (data.body.APIConfig.bundleCubeExecutionRequest) {
    transformer.modifyPayloadForBundleCubeExecutionRequest(
      data.body.APIConfig,
      transformedWhereClause,
      context
    );
  }
  return transformedWhereClause;
}
