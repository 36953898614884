// Entity details config file for Sponsored brands
// Widget_template's are tabs that are imported below
// Common components used within widget_template files

// -- importing componenets here --
import { actionPanelAddCustomProductsConfig } from '@/pages/entity_details/configs/walmart/actionConfigs.js';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import { sbaAddKeywords } from '@/pages/entity_details/configs/walmart/addKeywordsConfig.js';
import { cloneDeep } from 'lodash';
import HttpService from '@/utils/services/http-service';
import { getAdvertiserRequestPayload } from '@/pages/entity_details/configs/walmart/utils.js';

const budget = () =>
  import('@/pages/entity_details/configs/walmart/widget_templates/budget.vue');
const adGroups = () =>
  import('@/pages/entity_details/widget_templates/adGroups.vue');
const settings = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/settings.vue'
  );
const adgroupsTargetingKeyword = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/adgroupKeywordTargeting.vue'
  );
const adgroupSettings = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/adgroupSettings.vue'
  );
const adgroupProductADs = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupProductADs.vue'
  );
const adgroupProfile = () =>
  import(
    '@/pages/entity_details/configs/walmart/widget_templates/adgroupProfile.vue'
  );

export const getAddCustomProductsRequestPayloadTemplate = (retailer) => {
  return {
    actionPayload: {
      campaign_id: ':campaignId',
      ad_group_id: ':adgroupId',
      item_id: ':item_id',
      status: 'enabled',
      advertiser_id: ':advertiserId',
      product_title: ':sku_title',
      image_url: ':image_url',
      product_url: ':product_url',
      search_text: ':search_text',
      review_status: 'pending'
    },
    viewPayload: {
      campaign_id: ':campaignId',
      item_id: ':item_id',
      product_title: ':sku_title',
      image_url: ':image_url',
      product_url: ':product_url',
      adgroupName: ':adgroupName'
    },
    actionType: `${retailer}AddSkuToCampaigns`,
    actionSource: {
      pageUrl: ':pageUrl'
    },
    widget: 'campaign',
    primaryKey: ':campaignId'
  };
};

const fetchExtraMetadata = ({
  context,
  type,
  adGroupId,
  entityType,
  routeData
}) => {
  let reqPayload;
  reqPayload = {
    retailer: 'walmart',
    campaignId: routeData?.params?.entityId,
    advertiserId: routeData?.query?.advertiser_id,
    adGroupId: routeData?.params?.subEntityId ?? adGroupId,
    filterName: null,
    filterDate: null,
    type
  };

  return HttpService.post('FETCH_AMS_ACTIONS_MANAGER', reqPayload);
};

const updateProfileLogo = async ({ vueRef, sbaProfileId }) => {
  const fetchProfiles = await fetchExtraMetadata({
    type: 'SBA_PROFILES',
    routeData: vueRef?.$route
  });
  const currentProfile = fetchProfiles?.data?.response?.find?.(
    (profile) => profile.sbaProfileId === Number(sbaProfileId)
  );
  const logoUrl = currentProfile?.logoUrl;
  const review_status = currentProfile?.reviewStatus;
  const updateProfileLogoPayload = {
    actionType: 'walmartProfileMetadataChange',
    dataFields: [
      {
        sba_profile_id: sbaProfileId,
        logo_url: logoUrl,
        campaign_id: vueRef.parentEntityId,
        review_status
      }
    ]
  };
  return HttpService.post('ADD_PROFILE_IMAGE', updateProfileLogoPayload);
};

const formatReviewStatusData = ({
  data,
  campaignId,
  adGroupId,
  reviewStatusKey,
  reviewReasonKey,
  tabName = 'adgroupProfile'
}) => {
  data = data
    ?.filter?.(
      (item) =>
        item.campaignId === parseInt(campaignId) &&
        item.adGroupId === parseInt(adGroupId)
    )
    ?.sort?.((a, b) => b.reviewId - a.reviewId);
  let result;
  const statusMap = {
    rejected: 0,
    pending: 0,
    approved: 0
  };
  if (Array.isArray(data) && data.length) {
    result = {};
    const statusTextSet = new Set();
    data.forEach((item) => {
      statusMap[item[reviewStatusKey]]++;
      statusTextSet.add(item[reviewReasonKey]);
    });
    result.reviewStatus = statusMap;
    result.reviewComments =
      Array.from(statusTextSet)?.filter?.(Boolean)?.join?.(', ') ?? '';

    if (tabName === 'adgroupADs') {
      let reviewComment = '';
      const filteredRejectedSkus = data?.filter(
        (item) => item[reviewStatusKey] === 'rejected'
      );
      if (filteredRejectedSkus.length > 0) {
        const rejectedTextsForSkus = filteredRejectedSkus?.map(
          (item) => item?.itemId.toString() + ':' + item[reviewReasonKey]
        );
        reviewComment = reviewComment + rejectedTextsForSkus.join('\n');
        if (reviewComment) {
          result.reviewComments = reviewComment;
        }
      }
    }
    if (tabName === 'adgroupsTargetingKeyword') {
      let reviewComment = '';
      const filteredRejectedKeywords = data?.filter(
        (item) => item[reviewStatusKey] === 'rejected'
      );
      if (filteredRejectedKeywords.length > 0) {
        reviewComment = 'Rejected - ';
        const rejectedItemIds = filteredRejectedKeywords?.map(
          (item) => item?.keywordText
        );
        reviewComment = reviewComment + rejectedItemIds.join(', ');
        if (reviewComment) {
          result.reviewComments = reviewComment;
        }
      }
    }
  }
  return result;
};

const uploadProfileImage = (
  vueRef,
  logo,
  campaignId,
  adGroupId,
  sbaProfileId
) => {
  let message = '';
  const formData = new FormData();
  formData.append('multipartFile', logo);
  return HttpService.post('UPLOAD_PROFILE_AMS_ACTION_MANAGER', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      campaignId,
      adGroupId,
      sbaProfileId
    }
  })
    .then(() => {
      message = 'Logo was uploaded successfully';
    })
    .catch(() => {
      message = 'Error while uploading logo';
    })
    .finally(() => {
      vueRef.$snackbar.open({
        message,
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ''
      });
      vueRef.$store.dispatch('entityDetails/fetchIncrementTableDataKey');
    });
};
const adgroupProfileConfig = {
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchMetadata: (vueRef) => {
      fetchExtraMetadata({
        type: 'SBA_PROFILES',
        routeData: vueRef?.$route
      }).then((res) => {
        const isPending = res?.data?.response?.some?.(
          (profile) => profile.reviewStatus === 'pending'
        );
        if (isPending) {
          vueRef.$store.commit('entityDetails/TOGGLE_BANNER', isPending);
        }
        vueRef.reviewData = formatReviewStatusData({
          data: res?.data?.response,
          campaignId: vueRef.parentEntityId,
          adGroupId: vueRef.entityId,
          reviewStatusKey: 'reviewStatus',
          reviewReasonKey: 'reviewComments',
          tabName: 'adgroupProfile'
        });
      });
    }
  },
  component: adgroupProfile,
  stateChange: {
    requestPayload: {
      actionPayload: {
        campaign_id: ':campaignId',
        campaignType: 'SBA',
        ad_group_id: ':adgroupId',
        sba_profile_id: ':profileId',
        advertiser_id: ':advertiserId',
        status: ':state',
        review_status: 'pending'
      },
      viewPayload: {
        campaign_id: ':campaignId',
        oldStatus: ':oldState',
        newStatus: ':state',
        search_amp_name: ':brandName',
        headline_text: ':headline',
        click_url: ':url',
        sba_profile_id: ':profileId'
      },
      actionType: 'walmartProfileMetadataChange',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  },
  changeStatus: (vueRef) => {
    const payloadArr = [];
    const payload = cloneDeep(vueRef.stateChangeRequestPayload);
    const reqValues = {
      ':campaignId': vueRef.parentEntityId,
      ':adgroupId': vueRef.entityId,
      ':oldState': 'disabled',
      ':state': 'enabled',
      ':pageUrl': window.location.href,
      ':adgroupName': vueRef.campaignInfo?.data?.name || 'NA',
      ':advertiserId': vueRef?.$route?.query?.advertiser_id,
      ':profileId': vueRef.selections?.[0]?.data?.sba_profile_id,
      ':brandName':
        vueRef.selections?.[0]?.data?.walmart_cm_profile_search_amp_name,
      ':headline':
        vueRef.selections?.[0]?.data?.walmart_cm_profile_headline_text,
      ':url': vueRef.selections?.[0]?.data?.walmart_cm_profile_click_url
    };
    for (const item in reqValues) {
      deepReplaceObjectValues(payload, item, reqValues[item]);
    }
    payloadArr.push(payload);

    if (payloadArr.length) {
      vueRef.updateMetadataAction(payloadArr, {
        singular: 'Profile Status',
        plural: '',
        stopFetchApi: true
      });
    } else {
      vueRef.closeActionBar();
    }
  },
  editProfile: {
    requestPayload: {
      actionPayload: {
        campaign_id: ':campaignId',
        campaignType: 'SBA',
        ad_group_id: ':adgroupId',
        search_amp_name: ':brandName',
        headline_text: ':headline',
        click_url: ':url',
        sba_profile_id: ':profileId',
        advertiser_id: ':advertiserId',
        review_status: 'pending'
      },
      viewPayload: {
        campaign_id: ':campaignId',
        adgroup_name: ':adgroupName',
        search_amp_name: ':brandName',
        headline_text: ':headline',
        click_url: ':url',
        old_search_amp_name: ':oldBrandName',
        old_headline_text: ':oldHeadline',
        old_click_url: ':oldUrl',
        sba_profile_id: ':profileId'
      },
      actionType: 'walmartProfileMetadataChange',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  },
  addProfile: {
    requestPayload: {
      actionPayload: {
        campaign_id: ':campaignId',
        campaignType: 'SBA',
        ad_group_id: ':adgroupId',
        search_amp_name: ':brandName',
        headline_text: ':headline',
        click_url: ':url',
        advertiser_id: ':advertiserId',
        review_status: 'pending'
      },
      viewPayload: {
        campaign_id: ':campaignId',
        adgroup_name: ':adgroupName',
        search_amp_name: ':brandName',
        headline_text: ':headline',
        click_url: ':url'
      },
      actionType: 'walmartAddProfileToCampaigns',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  },
  addProfileAction: async (vueRef, { brandName, headline, url, logo }) => {
    let message = '';
    const payloadArr = [];
    const reqPayload = cloneDeep(vueRef.addProfileRequestPayload);
    const reqValues = {
      ':campaignId': vueRef.parentEntityId,
      ':adgroupId': vueRef.entityId,
      ':brandName': brandName,
      ':pageUrl': window.location.href,
      ':headline': headline,
      ':url': url,
      ':adgroupName': vueRef.campaignInfo?.data?.name || 'NA',
      ':advertiserId': vueRef?.$route?.query?.advertiser_id
    };
    for (const item in reqValues) {
      deepReplaceObjectValues(reqPayload, item, reqValues[item]);
    }
    payloadArr.push(reqPayload);
    if (payloadArr.length) {
      HttpService.post('UPDATE_AMS_ACTIONS_MANAGER', payloadArr)
        .then(async ({ data }) => {
          const sbaProfileId =
            data?.response?.[0]?.handlerResponse?.[0]?.sbaProfileId;
          await uploadProfileImage(
            vueRef,
            logo,
            vueRef.parentEntityId,
            vueRef.entityId,
            sbaProfileId
          );
          await updateProfileLogo({ vueRef, sbaProfileId });
          message = 'Profile was added succesfully';
        })
        .catch(() => {
          message = 'Error while adding profile';
        })
        .finally(() => {
          vueRef.$snackbar.open({
            message,
            duration: 6000,
            buttonColor: '#f5d908',
            actionText: ''
          });
          vueRef.$store.dispatch('entityDetails/fetchIncrementTableDataKey');
        });
      vueRef.closeActionBar();
      vueRef.showAddProfile = false;
    } else {
      vueRef.closeActionBar();
    }
  },
  editProfileAction: async (vueRef, { brandName, headline, url, logo }) => {
    if (typeof logo === 'object') {
      await uploadProfileImage(
        vueRef,
        logo,
        vueRef.parentEntityId,
        vueRef.entityId,
        vueRef.selections?.[0]?.data?.sba_profile_id
      );
    }
    const payloadArr = [];
    const reqPayload = cloneDeep(vueRef.editProfileRequestPayload);
    const reqValues = {
      ':campaignId': vueRef.parentEntityId,
      ':adgroupId': vueRef.entityId,
      ':oldState': vueRef.selections?.[0]?.data?.walmart_cm_profile_status,
      ':state': 'disabled',
      ':pageUrl': window.location.href,
      ':adgroupName': vueRef.campaignInfo?.data?.name || 'NA',
      ':advertiserId': vueRef?.$route?.query?.advertiser_id,
      ':brandName': brandName,
      ':headline': headline,
      ':url': url,
      ':profileId': vueRef.selections?.[0]?.data?.sba_profile_id,
      ':oldBrandName':
        vueRef.selections?.[0]?.data?.walmart_cm_profile_search_amp_name,
      ':oldHeadline':
        vueRef.selections?.[0]?.data?.walmart_cm_profile_headline_text,
      ':oldUrl': vueRef.selections?.[0]?.data?.walmart_cm_profile_click_url
    };
    if (
      reqValues[':oldBrandName'] === brandName &&
      reqValues[':oldHeadline'] === headline &&
      reqValues[':oldUrl'] === url
    ) {
      const sbaProfileId = vueRef.selections?.[0]?.data?.sba_profile_id;
      await updateProfileLogo({ vueRef, sbaProfileId });
      vueRef.showAddProfile = false;
      return vueRef.closeActionBar();
    }
    for (const item in reqValues) {
      deepReplaceObjectValues(reqPayload, item, reqValues[item]);
    }
    payloadArr.push(reqPayload);
    if (payloadArr.length) {
      vueRef.updateMetadataAction(payloadArr, {
        singular: 'Profile',
        plural: '',
        action: 'updated',
        stopFetchApi: true
      });
    } else {
      vueRef.showAddProfile = false;
      vueRef.closeActionBar();
    }
  },
  allowedActions: (vueRef) => {
    const actions = [
      {
        icon: 'task-status',
        title: 'ENABLE PROFILE',
        id: 'enabledProfile',
        disabled: vueRef.campaignInfo?.data?.reviewStatus === 'pending',
        tippy:
          vueRef.campaignInfo?.data?.reviewStatus === 'pending'
            ? 'Action unavailable while review is pending'
            : ''
      },
      {
        icon: 'pencil',
        title: 'Edit Profile',
        id: 'editProfile',
        disabled: vueRef.campaignInfo?.data?.reviewStatus === 'pending',
        tippy:
          vueRef.campaignInfo?.data?.reviewStatus === 'pending'
            ? 'Action unavailable while review is pending'
            : ''
      }
    ];

    return vueRef.selections.length === 1
      ? vueRef.selections[0].data.walmart_cm_profile_status === 'enabled'
        ? vueRef.selections[0].data.walmart_cm_review_status?.toLowerCase() !==
            'approved' &&
          vueRef?.parentEntityData?.data?.state?.toLowerCase() !== 'live'
          ? [
              {
                icon: 'pencil',
                title: 'Edit Profile',
                id: 'editProfile',
                disabled: vueRef.campaignInfo?.data?.reviewStatus === 'pending',
                tippy:
                  vueRef.campaignInfo?.data?.reviewStatus === 'pending'
                    ? 'Action unavailable while review is pending'
                    : ''
              }
            ]
          : []
        : actions
      : [];
  },
  transformData: (selections, profile) => {
    profile.props.config = {
      ...profile.props.config,
      edit: true,
      brandName: selections[0].data.walmart_cm_profile_search_amp_name,
      url: selections[0].data.walmart_cm_profile_click_url,
      headline: selections[0].data.walmart_cm_profile_headline_text,
      logo: selections[0].data.walmart_cm_profile_brand_logo
    };
    return profile;
  },
  headerOptions: (vueRef) => {
    return [
      ...(vueRef.showAddProfileButton
        ? [
            {
              ui_component: 'rb-button',
              props: {
                clickFn: vueRef.addProfile,
                text: 'Add Profile 2',
                type: 'filled',
                disabled: vueRef.campaignInfo?.data?.reviewStatus === 'pending'
              },
              class: 'u-spacing-mr-xl'
            }
          ]
        : []),
      ...(vueRef.reviewData
        ? [
            (() => {
              let approvedCount = vueRef.reviewData.reviewStatus.approved;
              let pendingCount = vueRef.reviewData.reviewStatus.pending;
              let rejectedCount = vueRef.reviewData.reviewStatus.rejected;
              return {
                ui_component: 'reviewDataComp',
                class:
                  'u-flex-1 u-flex-justify-content-flex-end u-spacing-mr-m',
                props: {
                  state: [
                    {
                      title: 'Approved',
                      count: approvedCount,
                      entity: 'profiles',
                      circleColor: 'green'
                    },
                    {
                      title: 'Pending',
                      count: pendingCount,
                      entity: 'profiles',
                      circleColor: 'orange'
                    },
                    {
                      title: 'Rejected',
                      count: rejectedCount,
                      entity: 'profiles',
                      circleColor: 'red'
                    }
                  ],
                  tooltipText: vueRef.reviewData.reviewComments
                },
                order: 900
              };
            })()
          ]
        : [])
    ];
  }
};

const adGroupSettingsConfig = {
  isAdgroup: true,
  requestPayload: {
    actionPayload: {
      campaign_id: ':campaignId',
      ad_group_id: ':entityId',
      advertiser_id: ':advertiserId'
    },
    viewPayload: {
      campaign_id: ':campaignId',
      view_adgroup_name: ':name'
    },
    actionType: 'walmartAdgroupMetadataChange',
    actionSource: {
      pageUrl: ':pageUrl'
    },
    widget: 'campaign',
    primaryKey: ':campaignId'
  },
  component: adgroupSettings,
  getAdvertiserRequestPayload: (advertiser_id) =>
    getAdvertiserRequestPayload('walmart', advertiser_id),
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchAdvertiser: 'entityDetails/fetchAdvertiserData',
    getAdvertiser: 'entityDetails/getAdvertiser',
    getReviewStatusData: 'entityDetails/getReviewStatusData',
    fetchReviewStatusData: 'entityDetails/fetchReviewStatusData'
  }
};

const adgroupsTargetingKeywordConfig = {
  component: adgroupsTargetingKeyword,
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    campaignGetter: 'entityDetails/getCampaignDetails',
    fetchCampaignAction: 'entityDetails/fetchCampaingDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter:
      'entityDetails/fetchIncrementTableDataKey',
    fetchMetadata: (vueRef) => {
      fetchExtraMetadata({ type: 'KEYWORDS', routeData: vueRef?.$route }).then(
        (res) => {
          const isPending = res?.data?.response?.some?.(
            (keyword) => keyword.status === 'pending'
          );
          if (isPending) {
            vueRef.$store.commit('entityDetails/TOGGLE_BANNER', isPending);
          }
          vueRef.reviewData = formatReviewStatusData({
            data: res?.data?.response,
            campaignId: vueRef.parentEntityId,
            adGroupId: vueRef.entityId,
            reviewStatusKey: 'status',
            reviewReasonKey: '',
            tabName: 'adgroupsTargetingKeyword'
          });
        }
      );
    }
  },
  bidChange: {
    requestPayload: {
      actionPayload: {
        campaign_id: ':campaignId',
        campaignType: 'SBA',
        new_bid: ':newBid',
        keyword_id: ':keywordId',
        state: ':state',
        advertiser_id: ':advertiserId',
        old_bid: ':oldBid'
      },
      viewPayload: {
        campaign_id: ':campaignId',
        view_adgroup_name: ':adgroupName',
        view_old_bid: ':oldBid',
        new_bid: ':newBid',
        keyword_text: ':keywordText',
        adgroup_name: ':adgroupName',
        match_type: ':matchType',
        keywordId: ':keywordId'
      },
      actionType: 'walmartKeywordMetadataChange',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  },
  stateChange: {
    requestPayload: {
      actionPayload: {
        campaign_id: ':campaignId',
        keyword_id: ':keywordId',
        campaignType: 'SBA',
        state: ':state',
        advertiser_id: ':advertiserId'
      },
      viewPayload: {
        campaign_id: ':campaignId',
        previousStatus: ':oldState',
        newStatus: ':state',
        keyword_text: ':keywordText',
        adgroup_name: ':adgroupName',
        match_type: ':matchType',
        keywordId: ':keywordId'
      },
      actionType: 'walmartKeywordMetadataChange',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  },
  changeStatus: (vueRef, dropDownSelection, selections) => {
    const reviewStatus = vueRef.campaignInfo?.data?.reviewStatus;
    if (
      reviewStatus?.toLowerCase() === 'pending' &&
      dropDownSelection[0].value === 'enabled'
    ) {
      return vueRef.$snackbar.open({
        message: 'Cannot enable status while review is pending',
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ''
      });
    }
    const payloadArr = [];
    for (const selection of selections) {
      if (
        selection?.data?.walmart_cm_keyword_state?.toLowerCase() ===
        dropDownSelection[0].value
      ) {
        vueRef.$snackbar.open({
          message: 'No new state selected for one or more entities.',
          duration: 6000,
          buttonColor: '#f5d908',
          actionText: ''
        });
      } else {
        const payload = cloneDeep(vueRef.stateChangeRequestPayload);
        const reqValues = {
          ':campaignId': vueRef.parentEntityId,
          ':oldState': selection?.data?.walmart_cm_keyword_state?.toLowerCase(),
          ':state': dropDownSelection[0].value,
          ':pageUrl': window.location.href,
          ':keywordId': selection?.data?.keyword_id,
          ':keywordText':
            selection?.data?.walmart_cm_keyword_keyword_text || 'NA',
          ':matchType': selection?.data?.walmart_cm_keyword_match_type || 'NA',
          ':adgroupName': vueRef.campaignInfo?.data?.name || 'NA',
          ':advertiserId': vueRef?.$route?.query?.advertiser_id
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        if (dropDownSelection[0].value === 'enabled') {
          payload.actionPayload.status = 'pending';
        }
        payloadArr.push(payload);
      }
    }
    if (payloadArr.length) {
      vueRef.updateMetadataAction(payloadArr, {
        singular: 'Keyword Status',
        plural: 'Keyword Statuses',
        stopFetchApi: true
      });
    } else {
      vueRef.closeActionBar();
    }
  },
  checkForSuggestedBid: (vueRef) => {
    vueRef.bidSuggestion = { show: false };
    vueRef.bidValue = null;
    if (
      vueRef.selections.length === 1 &&
      vueRef.selections[0]?.data?.walmart_cm_keyword_bid_suggested
    ) {
      vueRef.bidSuggestion = {
        show: true,
        title: 'Walmart Suggested Bid',
        value:
          vueRef.selections[0]?.data?.walmart_cm_keyword_bid_suggested.toString(),
        currentBid:
          vueRef.selections[0]?.data?.walmart_cm_keyword_bid.toString(),
        multipleSuggestions: false
      };
    } else if (vueRef.selections.length > 1) {
      let showBidSuggestion = true;
      vueRef.selections.map((selection, index) => {
        if (!selection.data.walmart_cm_keyword_bid_suggested) {
          showBidSuggestion = false;
        }
      });
      vueRef.bidSuggestion = {
        show: showBidSuggestion,
        title: 'Walmart Suggested Bid',
        value: 'XX',
        currentBid: 'YY',
        multipleSuggestions: showBidSuggestion,
        currentBidNote: 'YY - Respective current bid in the table below',
        suggestedBidNote:
          'XX - Respective Walmart suggested bid in the table below'
      };
    } else {
      vueRef.adjustBids = false;
    }
  },
  onSaveBid: (vueRef) => {
    const payloadArr = [];
    for (const selection of vueRef.selections) {
      const oldBid = selection?.data?.walmart_cm_keyword_bid || 0;
      let newBid = vueRef.transformBid(
        oldBid,
        selection,
        'walmart_cm_keyword_bid'
      );
      newBid = parseFloat(newBid) < 1 ? 1 : newBid;
      if (newBid === oldBid) {
        vueRef.$snackbar.open({
          message: 'No new bid selected for one or more entities.',
          duration: 6000,
          buttonColor: '#f5d908',
          actionText: ''
        });
      } else {
        if (parseFloat(newBid || 0) > 100) {
          return vueRef.$snackbar.open({
            message: 'Bid should not be greater than $100(exclusive).',
            duration: 6000,
            buttonColor: '#f5d908',
            actionText: ''
          });
        }
        const payload = cloneDeep(vueRef.bidChangeRequestPayload);
        const reqValues = {
          ':campaignId': vueRef.parentEntityId,
          ':adgroupId': vueRef.entityId,
          ':oldBid': oldBid,
          ':newBid': newBid,
          ':pageUrl': window.location.href,
          ':keywordId': selection?.data?.keyword_id,
          ':keywordText':
            selection.data.walmart_cm_keyword_keyword_text || 'NA',
          ':adgroupName': vueRef.campaignInfo?.data?.name || 'NA',
          ':matchType': selection.data.walmart_cm_keyword_match_type || 'NA',
          ':state': selection?.data?.walmart_cm_keyword_state,
          ':advertiserId': vueRef?.$route?.query?.advertiser_id
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        payloadArr.push(payload);
      }
    }
    if (payloadArr.length) {
      vueRef.updateMetadataAction(payloadArr, {
        singular: 'Keyword Bid',
        plural: 'Keyword Bids',
        stopFetchApi: true
      });
    } else {
      vueRef.closeActionBar();
    }
  },
  addCustomKeywordPanelConfig: sbaAddKeywords,
  searchText: 'walmart_cm_keyword_keyword_text',
  headerOptions: (vueRef) => {
    return vueRef.reviewData
      ? [
          (() => {
            let approvedCount = vueRef.reviewData.reviewStatus.approved;
            let pendingCount = vueRef.reviewData.reviewStatus.pending;
            let rejectedCount = vueRef.reviewData.reviewStatus.rejected;
            return {
              ui_component: 'reviewDataComp',
              class: 'u-flex-1 u-flex-justify-content-flex-end u-spacing-mr-m',
              props: {
                state: [
                  {
                    title: 'Approved',
                    count: approvedCount,
                    entity: 'keywords',
                    circleColor: 'green'
                  },
                  {
                    title: 'Pending',
                    count: pendingCount,
                    entity: 'keywords',
                    circleColor: 'orange'
                  },
                  {
                    title: 'Rejected',
                    count: rejectedCount,
                    entity: 'keywords',
                    circleColor: 'red'
                  }
                ],
                tooltipText: vueRef.reviewData.reviewComments
              },
              order: 900
            };
          })()
        ]
      : [];
  },
  bottomPadding: '11.5rem'
};

const adgroupADsConfig = (retailer) => ({
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchMetadata: (vueRef) => {
      fetchExtraMetadata({ type: 'SKUS', routeData: vueRef?.$route }).then(
        (res) => {
          const isPending = res?.data?.response?.some?.(
            (sku) => sku.reviewStatus === 'pending'
          );
          if (isPending) {
            vueRef.$store.commit('entityDetails/TOGGLE_BANNER', isPending);
          }
          vueRef.reviewData = formatReviewStatusData({
            data: res?.data?.response,
            campaignId: vueRef.parentEntityId,
            adGroupId: vueRef.entityId,
            reviewStatusKey: 'reviewStatus',
            reviewReasonKey: 'reviewReason',
            tabName: 'adgroupADs'
          });
        }
      );
    }
  },
  allowedActions: [
    {
      icon: 'task-status',
      title: 'EDIT STATUS',
      id: 'editStatus',
      dropDown: true,
      dropDownOptions: [
        { title: 'Enable', value: 'enabled', color: 'u-color-green-base' },
        { title: 'Disable', value: 'disabled', color: 'u-color-orange-base' }
      ]
    }
  ],
  stateChange: {
    requestPayload: {
      actionPayload: {
        campaign_id: ':campaignId',
        item_id: ':itemId',
        ad_group_id: ':adgroupId',
        status: ':state',
        advertiser_id: ':advertiserId'
      },
      viewPayload: {
        campaign_id: ':campaignId',
        previousStatus: ':oldState',
        adgroupName: ':adgroupName',
        newStatus: ':state',
        item_id: ':itemId',
        itemName: ':itemName'
      },
      actionType: 'walmartProductadMetadataChange',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  },
  actionPanelAddCustomProductsConfig,
  addCustomProducts: {
    requestPayload: getAddCustomProductsRequestPayloadTemplate(retailer)
  },
  changeStatus: (vueRef, dropDownSelection, selections) => {
    const enabledSkusCount =
      vueRef.gridOptions.api.rowModel.rowsToDisplay.filter(
        (row) => row.data.walmart_cm_skus_status === 'enabled'
      ).length;
    const payloadArr = [];
    const adGroupContentIdKey = `${retailer}_cm_skus_aditem_id`;
    const reviewStatus = vueRef.campaignInfo?.data?.reviewStatus;
    if (
      reviewStatus?.toLowerCase() === 'pending' &&
      dropDownSelection[0].value === 'enabled'
    ) {
      return vueRef.$snackbar.open({
        message: 'Cannot enable status while review is pending',
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ''
      });
    }
    for (const selection of selections) {
      if (
        selection?.data?.walmart_cm_skus_status?.toLowerCase() ===
        dropDownSelection[0].value
      ) {
        vueRef.$snackbar.open({
          message: 'No new state selected for one or more entities.',
          duration: 6000,
          buttonColor: '#f5d908',
          actionText: ''
        });
      } else {
        let payload = cloneDeep(vueRef.stateChangeRequestPayload);
        if (retailer === 'walmart') {
          payload.actionPayload.ad_group_content_id = ':adGroupContentId';
          payload.viewPayload.ad_group_content_id = ':adGroupContentId';
        }
        const reqValues = {
          ':campaignId': vueRef.parentEntityId,
          ':adgroupId': vueRef.entityId,
          ':oldState': selection?.data?.walmart_cm_skus_status?.toLowerCase(),
          ':state': dropDownSelection[0].value,
          ':pageUrl': window.location.href,
          ':itemId': selection?.data?.walmart_cm_skus_sku,
          ...(retailer === 'walmart' && {
            ':adGroupContentId': selection?.data?.[adGroupContentIdKey]
          }),
          ':itemName': selection?.data?.walmart_cm_skus_sku_name,
          ':advertiserId': vueRef?.$route?.query?.advertiser_id,
          ':adgroupName': vueRef.campaignInfo?.data?.name || 'NA'
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        if (dropDownSelection[0].value === 'enabled') {
          payload.actionPayload.review_status = 'pending';
        }
        payloadArr.push(payload);
      }
    }
    if (payloadArr.length > 10 && dropDownSelection[0].value === 'enabled') {
      return vueRef.$snackbar.open({
        message: 'Cannot enable more than 10 items in an adgroup.',
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ''
      });
    } else if (
      dropDownSelection[0].value === 'disabled' &&
      (payloadArr.length === enabledSkusCount ||
        payloadArr.length + 1 === enabledSkusCount)
    ) {
      return vueRef.$snackbar.open({
        message: 'Cannot disable final 2 enabled items in an adgroup.',
        duration: 6000,
        buttonColor: '#f5d908',
        actionText: ''
      });
    }
    if (payloadArr.length) {
      vueRef.updateMetadataAction(payloadArr, {
        singular: 'Sku status',
        plural: 'Sku Statuses',
        stopFetchApi: true
      });
    } else {
      vueRef.closeActionBar();
    }
  },
  addProducts: (vueRef, selections, actionPanelRef) => {
    selections = selections.filter(
      (row) =>
        actionPanelRef.fetchedRightTableRows.rows.findIndex(
          (fetchedRow) =>
            fetchedRow.sku === row.sku && fetchedRow.reviewStatus !== 'rejected'
        ) === -1
    );
    if (!selections.length)
      return actionPanelRef.openSnackbar('No products were added/modified');
    const payloadArr = [];
    for (const selection of selections) {
      const payload = cloneDeep(vueRef.addCustomProductsRequestPayload);
      const reqValues = {
        ':campaignId': vueRef.parentEntityId,
        ':adgroupId': vueRef.entityId,
        ':sku_title': selection.product_title,
        ':search_text': `${selection.sku}|${selection.product_title}`,
        ':pageUrl': window.location.href,
        ':image_url': selection.image_url,
        ':product_url': selection.product_url,
        ':item_id': selection.sku || 'NA',
        ':adgroupName': vueRef.campaignInfo?.data?.name || 'NA',
        ':advertiserId': vueRef?.$route?.query?.advertiser_id
      };
      for (const item in reqValues) {
        deepReplaceObjectValues(payload, item, reqValues[item]);
      }
      payloadArr.push(payload);
    }
    if (payloadArr.length) {
      vueRef.updateMetadataAction(payloadArr, {
        singular: 'Campaign Product',
        plural: 'Campaign Products',
        action: 'added',
        stopFetchApi: true
      });
    } else {
      vueRef.closeActionBar();
    }
  },
  searchText: 'walmart_cm_skus_search_text',
  headerOptions: (vueRef) => {
    return vueRef.reviewData
      ? [
          (() => {
            let approvedCount = vueRef.reviewData.reviewStatus.approved;
            let pendingCount = vueRef.reviewData.reviewStatus.pending;
            let rejectedCount = vueRef.reviewData.reviewStatus.rejected;
            return {
              ui_component: 'reviewDataComp',
              class: 'u-flex-1 u-flex-justify-content-flex-end u-spacing-mr-m',
              props: {
                state: [
                  {
                    title: 'Approved',
                    count: approvedCount,
                    entity: 'skus',
                    circleColor: 'green'
                  },
                  {
                    title: 'Pending',
                    count: pendingCount,
                    entity: 'skus',
                    circleColor: 'orange'
                  },
                  {
                    title: 'Rejected',
                    count: rejectedCount,
                    entity: 'skus',
                    circleColor: 'red'
                  }
                ],
                tooltipText: vueRef.reviewData.reviewComments
              },
              order: 900
            };
          })()
        ]
      : [];
  }
});

export default function (retailer = 'walmart') {
  return {
    campaign: {
      page: 'campaignDetailsSBA',
      metadata: [
        {
          action: 'entityDetails/fetchCampaingDetails',
          getters: 'entityDetails/getCampaignDetails',
          bannerGetter: 'entityDetails/getBannerStatus'
        }
      ],
      header: {
        maxFeedDate: 'campaigns_campaign',
        filters: {
          config: {
            emit: 'sbaCampaignDetailsFiltersApplied',
            hasSearch: false,
            addToFilter: 'sbaCampaignDetailsAddToFilters'
          },
          filterLocalStorageKey: 'campaign-filters',
          page: 'sbaCampaingDetails'
        },
        activityLog: {
          show: true
        },
        bookmark: {
          value: true,
          title: 'name',
          fromMetadata: true
        },
        redirectTo: {
          value: true,
          path: '/us/walmart/retail/advertising/campaign_management/campaigns'
        },
        // line 1 and 2 will wrap around date picker if its present
        metadata_api_state: {
          getter: 'entityDetails/getCampaignDetails'
        },
        line1: {
          items: [
            {
              fromMetadata: true,
              attribute: {
                title: 'Campaign',
                values: ['name'],
                hoverText: ['name'],
                titleClass:
                  'u-font-weight-700 u-color-grey-lighter u-font-size-2',
                valueClasses: ['u-font-weight-700 u-font-size-2'],
                altValues: ['NA']
              },
              class: 'u-spacing-pt-xxs u-text-overflow-ellipsis',
              itemType: 'attribute'
            }
          ],
          class: ''
        },
        line2: {
          items: [
            {
              fromMetadata: true,
              attribute: {
                title: 'Duration',
                values: ['startDate', 'endDate'],
                titleClass: null,
                delimiter: '-',
                valueClasses: null,
                altValues: ['No start date', 'No end date']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            },
            {
              fromMetadata: true,
              attribute: {
                pre: [],
                title: 'Total Budget',
                values: ['totalBudgetView'],
                titleClass: null,
                delimiter: ' ',
                valueClasses: null,
                altValues: ['NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            },
            {
              fromMetadata: true,
              attribute: {
                pre: [],
                title: 'Daily Budget',
                values: ['dailyBudgetView'],
                titleClass: null,
                delimiter: ' ',
                valueClasses: null,
                altValues: ['NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            },
            {
              fromMetadata: true,
              attribute: {
                title: 'Campaign ID',
                values: ['campaignId'],
                titleClass: null,
                delimiter: '',
                valueClasses: null,
                altValues: ['NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            },
            {
              fromMetadata: true,
              attribute: {
                title: 'Type',
                values: ['campaignType'],
                titleClass: null,
                delimiter: '/',
                valueClasses: ['', 'u-text-case-title'],
                altValues: ['NA', 'NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            }
          ],
          class: 'u-spacing-pb-m u-font-size-5'
        },
        line3: {
          items: [
            {
              fromMetadata: true,
              attribute: {
                title: 'Status',
                values: ['state'],
                titleClass: null,
                delimiter: '',
                valueClasses: ['u-color-blue-base u-font-weight-600'],
                altValues: ['NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            },
            {
              fromMetadata: true,
              attribute: {
                pre: [],
                title: 'Remaining Budget',
                titleIcon: 'info-circle-fill',
                titleIconTooltip:
                  'Remaining Budget is reset daily at 12am PST. It is refreshed every 15 minutes to fetch realtime values for the day.',
                values: ['dailyRemainingBudget'],
                titleClass: null,
                delimiter: ' ',
                valueClasses: null,
                altValues: ['NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            },
            {
              fromMetadata: true,
              attribute: {
                pre: [],
                title: 'Ad Spend today',
                titleIcon: 'info-circle-fill',
                titleIconTooltip:
                  'Ad Spend today is reset daily at 12am PST. It is refreshed every 15 minutes to fetch realtime values for the day.',
                values: ['todayAdSpend'],
                titleClass: null,
                delimiter: ' ',
                valueClasses: null,
                altValues: ['NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            },
            {
              fromMetadata: true,
              attribute: {
                pre: [],
                title: 'Impressions today',
                titleIcon: 'info-circle-fill',
                titleIconTooltip:
                  'Impressions today is reset daily at 12am PST. It is refreshed every 15 minutes to fetch realtime values for the day.',
                values: ['todayImpressions'],
                titleClass: null,
                delimiter: ' ',
                valueClasses: null,
                altValues: ['NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            },
            {
              fromMetadata: true,
              attribute: {
                pre: [],
                title: 'Clicks today',
                titleIcon: 'info-circle-fill',
                titleIconTooltip:
                  'Clicks today is reset daily at 12am PST. It is refreshed every 15 minutes to fetch realtime values for the day.',
                values: ['todayClicks'],
                titleClass: null,
                delimiter: ' ',
                valueClasses: null,
                altValues: ['NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            },
            {
              fromMetadata: true,
              attribute: {
                title: 'Review Status',
                values: ['reviewStatus'],
                titleClass: null,
                delimiter: '',
                valueClasses: ['u-text-case-title'],
                hoverText: ['reviewComments'],
                altValues: ['NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            }
          ],
          class: 'u-spacing-pb-l u-font-size-5'
        }
      },
      chart: {
        page: 'campaignDetailsSBA',
        maxFeedDate: 'campaigns_campaign',
        globalViewId: 0,
        pageId: 0,
        filter: 'sbaCampaignDetailsFiltersApplied',
        widget: 'campaignDetailsChartingWbSBA',
        enableDownload: false,
        enableRollup: true
      },
      // show tabs data if present
      tabs: {
        budget: {
          component: budget,
          config: {
            title1: 'Total Budget',
            title2: 'Daily Budget',
            title3: 'Total & Daily Budget',
            validation: {
              ':oldDailyBudget': {
                min: 50,
                text: 'Daily budget must be $50.00 or above'
              },
              ':oldTotalBudget': {
                min: 100,
                text: 'Total budget must be $100.00 or above'
              },
              genericErrorText: {
                text: 'Please enter either total budget, daily budget, or both'
              }
            }
          },
          apiConfigs: {
            getter: 'entityDetails/getCampaignDetails',
            fetchAction: 'entityDetails/fetchCampaingDetails',
            updateAction: 'entityDetails/updateCampaignDetails',
            getTableDataComponentKeyGetter:
              'entityDetails/getTableDataComponentKey'
          },
          reqMetadataMap: {
            ':oldDailyBudget': 'dailyBudget',
            ':oldTotalBudget': 'totalBudget',
            ':campaignId': 'entityId'
          },
          requestPayload: {
            actionPayload: {
              campaign_id: ':campaignId',
              campaignType: 'SBA',
              new_daily_budget: ':newDailyBudget',
              new_total_budget: ':newTotalBudget',
              advertiser_id: ':advertiserId'
            },
            viewPayload: {
              name: ':name',
              campaignId: ':campaignId',
              campaignType: 'SBA',
              previousDailyBudget: ':oldDailyBudget',
              newDailyBudget: ':newDailyBudget',
              previousTotalBudget: ':oldTotalBudget',
              newTotalBudget: ':newTotalBudget'
            },
            actionType: 'walmartCampaignMetadataChange',
            actionSource: {
              pageUrl: ':pageUrl'
            },
            widget: 'campaign',
            primaryKey: ':campaignId'
          }
        },
        adGroups: {
          component: adGroups,
          stateChange: {
            requestPayload: {
              actionPayload: {
                campaign_id: ':campaignId',
                campaignType: 'SBA',
                ad_group_id: ':adgroupId',
                status: ':state',
                advertiser_id: ':advertiserId'
              },
              viewPayload: {
                campaign_id: ':campaignId',
                view_adgroup_name: ':adgroupName',
                view_old_state: ':oldState',
                status: ':state'
              },
              actionType: 'walmartAdgroupMetadataChange',
              actionSource: {
                pageUrl: ':pageUrl'
              },
              widget: 'campaign',
              primaryKey: ':campaignId'
            }
          },
          apiConfigs: {
            updateAction: 'entityDetails/updateCampaignDetails',
            getter: 'entityDetails/getCampaignDetails',
            getTableDataComponentKeyGetter:
              'entityDetails/getTableDataComponentKey'
          },
          disableCreateAdgroup: true,
          disableRowSelection: false,
          allowedActions: [
            {
              icon: 'task-status',
              title: 'EDIT STATUS',
              id: 'editStatus',
              dropDown: true,
              dropDownOptions: [
                {
                  title: 'Enable',
                  value: 'enabled',
                  color: 'u-color-green-base'
                },
                {
                  title: 'Disable',
                  value: 'disabled',
                  color: 'u-color-orange-base'
                }
              ]
            }
          ],
          changeStatus: (vueRef, dropDownSelection, selections) => {
            const payloadArr = [];
            for (const selection of selections) {
              if (
                selection?.data?.walmart_cm_adgroup_status?.toLowerCase() ===
                dropDownSelection[0].value
              ) {
                vueRef.$snackbar.open({
                  message: 'No new state selected for one or more entities.',
                  duration: 6000,
                  buttonColor: '#f5d908',
                  actionText: ''
                });
              } else {
                const payload = cloneDeep(vueRef.stateChangeRequestPayload);
                const reqValues = {
                  ':campaignId': vueRef.entityId,
                  ':oldState':
                    selection?.data?.walmart_cm_adgroup_status?.toLowerCase(),
                  ':state': dropDownSelection[0].value,
                  ':pageUrl': window.location.href,
                  ':adgroupName':
                    selection?.data?.walmart_cm_adgroup_name || 'NA',
                  ':advertiserId': vueRef?.$route?.query?.advertiser_id,
                  ':adgroupId': selection?.data?.ad_group_id
                };
                for (const item in reqValues) {
                  deepReplaceObjectValues(payload, item, reqValues[item]);
                }
                payloadArr.push(payload);
              }
            }
            if (payloadArr.length) {
              vueRef.updateMetadataAction(payloadArr, {
                singular: 'Ad Group Status',
                plural: 'Ad Group Statuses',
                stopFetchApi: true
              });
            } else {
              vueRef.closeActionBar();
            }
          }
        },
        settings: {
          requestPayload: {
            actionPayload: {
              campaign_id: ':entityId',
              campaignType: 'SBA',
              advertiser_id: ':advertiserId'
            },
            viewPayload: {
              campaignId: ':entityId'
            },
            actionType: 'walmartCampaignMetadataChange',
            actionSource: {
              pageUrl: ':pageUrl'
            },
            widget: 'campaign',
            primaryKey: ':entityId'
          },
          getAdvertiserRequestPayload: (advertiser_id) =>
            getAdvertiserRequestPayload('walmart', advertiser_id),
          component: settings,
          apiConfigs: {
            getter: 'entityDetails/getCampaignDetails',
            fetchAction: 'entityDetails/fetchCampaingDetails',
            updateAction: 'entityDetails/updateCampaignDetails',
            getTableDataComponentKeyGetter:
              'entityDetails/getTableDataComponentKey',
            portfolio_campaignList: false,
            showAdvertiser: true,
            fetchAdvertiser: 'entityDetails/fetchAdvertiserData',
            getAdvertiser: 'entityDetails/getAdvertiser',
            statusValues: [
              {
                title: 'Proposal',
                value: 'Proposal'
              },
              {
                title: 'Scheduled',
                value: 'scheduled'
              },
              {
                title: 'Rescheduled',
                value: 'rescheduled'
              },
              {
                title: 'Live',
                value: 'live'
              },
              {
                title: 'Paused',
                value: 'paused'
              },
              {
                title: 'Completed',
                value: 'ended'
              },
              {
                title: 'Extend',
                value: 'extended'
              },
              {
                title: 'Enabled',
                value: 'live'
              }
            ]
          }
        }
      },
      validation: (vueRef, campaignInfo) => {
        if (
          campaignInfo.status === 'loaded' &&
          !campaignInfo?.data?.reviewStatus
        ) {
          return vueRef.$router.push({
            name: 'create_campaign',
            query: {
              advertiserId: vueRef.$route.query.advertiser_id,
              adGroupId: campaignInfo?.data?.adGroupId,
              campaignId: campaignInfo?.data?.campaignId,
              campaignType: 'sponsoredBrands',
              targetingType: 'manual',
              edit: true
            }
          });
        }
      },
      showLoader: true,
      banner: (vueRef) => {
        return vueRef?.entityDetails?.data?.reviewStatus === 'pending'
          ? {
              text: 'Review is in progress for the campaign. Add/re-enable items/keywords and update profile actions have been disabled. Please cancel the review to update the components.',
              ctaText: 'Cancel Review',
              action: () => {
                const { entityId, entityType } = vueRef.$route.params;
                vueRef.$store.dispatch('entityDetails/updateCampaignDetails', {
                  updateRequest: [
                    {
                      actionPayload: {
                        campaign_id: entityId,
                        ad_group_id: vueRef?.entityDetails?.data?.adGroupId,
                        advertiser_id: vueRef.$route.query.advertiser_id,
                        review_id: vueRef?.entityDetails?.data?.reviewId
                      },
                      viewPayload: {
                        campaignId: entityId,
                        view_old_name: vueRef.$route.query?.parent_entity_name
                      },
                      actionType: 'walmartCampaignCancelReview',
                      actionSource: {
                        pageUrl: window.location.href
                      },
                      widget: 'campaign',
                      primaryKey: entityId
                    }
                  ],
                  entityId: entityId,
                  entityType: entityType.toUpperCase(),
                  snackbar: vueRef.$snackbar,
                  entityName: {
                    singular: 'Review status',
                    plural: 'Review status',
                    noCount: true,
                    stopFetchApi: false
                  },
                  routeData: vueRef.$route,
                  callAdgroupFetch: false
                });
              }
            }
          : {
              text: 'Adding or re-enabling items/keywords and any change to the profile needs a campaign review to go live and will take 1-3 days for the process to complete. Please update all the fields required before sending for review.',
              ctaText: 'Send for Review',
              action: () => {
                const { entityId, entityType, subEntityType, subEntityId } =
                  vueRef.$route.params;
                vueRef.$store.dispatch('entityDetails/updateCampaignDetails', {
                  updateRequest: [
                    {
                      actionPayload: {
                        campaign_id: entityId,
                        ad_group_id: vueRef?.entityDetails?.data?.adGroupId,
                        advertiser_id: vueRef.$route.query.advertiser_id
                      },
                      viewPayload: {
                        campaignId: entityId,
                        view_old_name: vueRef.$route.query?.parent_entity_name
                      },
                      actionType: 'walmartCampaignSubmitReview',
                      actionSource: {
                        pageUrl: window.location.href
                      },
                      widget: 'campaign',
                      primaryKey: entityId
                    }
                  ],
                  entityId: entityId,
                  entityType: entityType.toUpperCase(),
                  snackbar: vueRef.$snackbar,
                  entityName: {
                    singular: 'Review status',
                    plural: 'Review status',
                    noCount: true,
                    stopFetchApi: false
                  },
                  routeData: vueRef.$route,
                  callAdgroupFetch: false
                });
              }
            };
      }
    },
    manual_adgroup: {
      page: 'adgroupDetailsSBA',
      metadata: [
        {
          action: 'entityDetails/fetchAdgroupDetails',
          getters: 'entityDetails/getAdgroupDetails',
          // campaignFetchAction: 'entityDetails/fetchCampaingDetails',
          campaignGetter: 'entityDetails/getCampaignDetails',
          bannerGetter: 'entityDetails/getBannerStatus'
        }
      ],
      header: {
        maxFeedDate: 'campaigns_campaign',
        line1: {
          items: [
            {
              fromMetadata: true,
              attribute: {
                title: 'Ad Group',
                values: ['name'],
                hoverText: ['name'],
                titleClass:
                  'u-color-grey-lighter u-font-weight-700 u-font-size-2',
                valueClasses: ['u-font-weight-700 u-font-size-2'],
                altValues: ['']
              },
              class: 'u-spacing-pt-xxs',
              itemType: 'attribute'
            }
          ],
          class: ''
        },
        line2: {
          items: [
            {
              fromMetadata: true,
              attribute: {
                title: 'Status',
                values: ['state'],
                titleClass: null,
                delimiter: '',
                valueClasses: ['u-color-[color]-base u-text-case-title'],
                altValues: ['NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            },
            {
              fromMetadata: true,
              attribute: {
                title: 'Ad Group ID',
                values: ['adGroupId'],
                titleClass: null,
                delimiter: '',
                valueClasses: null,
                altValues: ['NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            },
            {
              fromMetadata: true,
              attribute: {
                title: 'Review Status',
                values: ['reviewStatus'],
                titleClass: null,
                delimiter: '',
                valueClasses: ['u-text-case-title'],
                hoverText: ['reviewComments'],
                altValues: ['NA']
              },
              class: 'u-spacing-pr-l',
              itemType: 'attribute'
            }
          ],
          class: 'u-spacing-pb-m u-font-size-5'
        },
        filters: {
          config: {
            emit: 'sbaAdgroupDetailsFiltersApplied',
            hasSearch: false,
            addToFilter: 'sbaAdgroupDetailsAddToFilters'
          },
          filterLocalStorageKey: 'campaign-filters',
          page: 'sbaAdgroupDetails'
        },
        bookmark: {
          value: true,
          title: 'name',
          fromMetadata: true
        },
        activityLog: {
          show: true
        },
        redirectTo: {
          value: true,
          config: (that) => ({
            name: 'walmartEntityDetails',
            params: {
              entityId: ':entityId',
              entityType: 'sb_manual'
            },
            query: that?.$route?.query
          })
        },
        // line 1 and 2 will wrap around date picker if its present
        metadata_api_state: {
          getter: 'entityDetails/getAdgroupDetails'
        }
      },
      chart: {
        page: 'adgroupDetailsSBA',
        globalViewId: 0,
        pageId: 0,
        filter: 'sbaAdgroupDetailsFiltersApplied',
        maxFeedDate: 'campaigns_campaign',
        widget: 'adGroupDetailsChartingWbSBA',
        enableDownload: false,
        enableRollup: true,
        config: {
          chartingWb: {
            ':widget': 'adGroupDetailsChartingWbSBA',
            ':timeseriesRollupBy': 'DAY',
            keepExistingDimensionNameValueList: true,
            replaceEntityInDimensionNameValueList: {
              ':ad_group_id': ':entityId'
            },
            timeseries: 'report_date'
          }
        }
      },
      banner: (vueRef) => {
        return vueRef?.entityDetails?.data?.reviewStatus === 'pending'
          ? {
              text: 'Review is in progress for the campaign. Add/re-enable items/keywords and update profile actions have been disabled. Please cancel the review to update the components.',
              ctaText: 'Cancel Review',
              action: () => {
                const { entityId, entityType, subEntityType, subEntityId } =
                  vueRef.$route.params;
                vueRef.$store.dispatch('entityDetails/updateCampaignDetails', {
                  updateRequest: [
                    {
                      actionPayload: {
                        campaign_id: entityId,
                        ad_group_id: subEntityId,
                        advertiser_id: vueRef.$route.query.advertiser_id,
                        review_id: vueRef?.entityDetails?.data?.reviewId
                      },
                      viewPayload: {
                        campaignId: entityId,
                        view_old_name: vueRef.$route.query?.parent_entity_name
                      },
                      actionType: 'walmartCampaignCancelReview',
                      actionSource: {
                        pageUrl: window.location.href
                      },
                      widget: 'campaign',
                      primaryKey: entityId
                    }
                  ],
                  entityId: entityId,
                  entityType: entityType.toUpperCase(),
                  snackbar: vueRef.$snackbar,
                  entityName: {
                    singular: 'Review status',
                    plural: 'Review status',
                    noCount: true,
                    stopFetchApi: false
                  },
                  routeData: vueRef.$route,
                  callAdgroupFetch: true
                });
              }
            }
          : {
              text: 'All the items being added like skus, keywords, change in profile data will go under review process and will take between 1-3 days for the review process to be completed. Please update all the required fields before clicking send for review button.',
              ctaText: 'Send for Review',
              action: () => {
                const { entityId, entityType, subEntityType, subEntityId } =
                  vueRef.$route.params;
                vueRef.$store.dispatch('entityDetails/updateCampaignDetails', {
                  updateRequest: [
                    {
                      actionPayload: {
                        campaign_id: entityId,
                        ad_group_id: subEntityId,
                        advertiser_id: vueRef.$route.query.advertiser_id
                      },
                      viewPayload: {
                        campaignId: entityId,
                        view_old_name: vueRef.$route.query?.parent_entity_name
                      },
                      actionType: 'walmartCampaignSubmitReview',
                      actionSource: {
                        pageUrl: window.location.href
                      },
                      widget: 'campaign',
                      primaryKey: entityId
                    }
                  ],
                  entityId: entityId,
                  entityType: entityType.toUpperCase(),
                  snackbar: vueRef.$snackbar,
                  entityName: {
                    singular: 'Review status',
                    plural: 'Review status',
                    noCount: true,
                    stopFetchApi: false
                  },
                  routeData: vueRef.$route,
                  callAdgroupFetch: true
                });
              }
            };
      },
      tabs: {
        keywordTargeting: adgroupsTargetingKeywordConfig,
        adItems: {
          component: adgroupProductADs,
          ...adgroupADsConfig(retailer)
        },
        settings: adGroupSettingsConfig,
        profile: adgroupProfileConfig
      }
    }
  };
}
