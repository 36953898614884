<template>
  <div
    class="u-position-relative u-font-size-size-5 u-spacing-pv-m u-spacing-pr-xxl"
  >
    <p class="u-font-size-3 u-font-weight-600">
      {{ $t('_sales') }} {{ $t('_decrease') }}
    </p>
    <p class="u-font-size-4 u-font-weight-600 u-spacing-pt-l">
      {{ $t('average_weekly_sales_has_dropped_by') }}
      {{ formatter(alert.attributes.rev_change, 'percent') }} ({{
        formatter(alert.attributes.rev_change_doll, 'currency')
      }}).
    </p>
    <p class="u-font-size-6 u-spacing-pt-s u-spacing-mb-xl">
      {{ $t('last_appeared_on_')
      }}{{ formatter(alert.attributes.last_appeared_on, 'date') }} &bull;
      {{ $t('appeared_')
      }}{{
        alert.attributes.last_appeared_count
          ? alert.attributes.last_appeared_count
          : '0'
      }}{{ $t('_out_of_last_30_days') }}
    </p>
    <div class="detail-view--card">
      <el-table
        :data="detailsTable"
        style="width: 100%"
        :empty-text="'NA'"
      >
        <el-table-column
          prop="type"
          label=""
          :width="300"
        >
          <div slot-scope="scope">
            <el-tooltip
              effect="dark"
              :content="scope.row.tooltipText"
              placement="top"
            >
              <span
                :class="{
                  'u-line-height-1': scope.row.type === 'Best Seller Rank'
                }"
                >{{ scope.row.type }}</span
              >
            </el-tooltip>
            <span
              v-if="scope.row.type === 'Best Seller Rank'"
              class="u-font-size-7 u-line-height-1"
              :title="alert.attributes.bsr_cat"
            >
              <a
                v-if="alert.attributes.bsr_category_url"
                target="_blank"
                :href="alert.attributes.bsr_category_url"
                class="link_outline_none"
              >
                <div class="link--asin">{{ alert.attributes.bsr_cat }}</div>
              </a>
              <div v-else>{{ alert.attributes.bsr_cat }}</div>
            </span>
          </div>
        </el-table-column>
        <el-table-column
          prop="last_week"
          :label="last7Text"
        >
          <div slot-scope="scope">
            <el-tooltip
              v-if="
                scope.row.type === 'Content change' &&
                scope.row.last_week != scope.row.prior_week
              "
              effect="dark"
              :content="scope.row.last_week"
              placement="right"
            >
              <span>
                {{ scope.row.last_week }}
              </span>
            </el-tooltip>
            <span v-else>
              {{ scope.row.last_week }}
            </span>
          </div>
        </el-table-column>
        <el-table-column
          prop="prior_week"
          :label="last14Text"
        >
          <div slot-scope="scope">
            <el-tooltip
              v-if="
                scope.row.type === 'Content change' &&
                scope.row.last_week != scope.row.prior_week
              "
              effect="dark"
              :content="scope.row.prior_week"
              placement="right"
            >
              <span>
                {{ scope.row.prior_week }}
              </span>
            </el-tooltip>
            <span v-else>
              {{ scope.row.prior_week }}
            </span>
          </div>
        </el-table-column>
        <el-table-column
          prop="change"
          label="Change"
        >
          <div slot-scope="scope">
            <el-tooltip
              v-if="
                scope.row.type === 'Content change' &&
                scope.row.last_week != scope.row.prior_week
              "
              effect="dark"
              :content="scope.row.change"
              placement="right"
            >
              <span>
                {{ scope.row.change }}
              </span>
            </el-tooltip>
            <span v-else>
              {{ scope.row.change }}
            </span>
          </div>
        </el-table-column>
      </el-table>
      <!--  <div class="date--block u-spacing-pv-s u-spacing-ph-m">
        {{$t('last_updated')}}: {{formatter(alert.attributes.feed_date, 'date')}}
      </div> -->
    </div>
    <div class="u-max-width-100 detail-view--card chart--card u-spacing-mt-xl">
      <div class="detail--chart">
        <chart
          v-if="showCharts"
          style="height: 270px"
          class="u-spacing-mh-l u-spacing-pv-l"
          :config="barChartConfig"
          :data="barChartData"
        />
      </div>
      <!-- <div class="date--block u-spacing-pv-s u-spacing-ph-m">
        {{$t('last_updated')}}: {{formatter(alert.attributes.feed_date, 'date')}}
      </div> -->
    </div>
    <p
      class="u-spacing-pt-xl u-font-size-5 u-font-weight-600 u-color-grey-lighter u-spacing-mb-s"
    >
      TOP 5 SEARCH KEYWORDS
    </p>
    <div class="detail-view--card">
      <el-table
        :data="searchTable"
        style="width: 100%"
        :empty-text="'NA'"
      >
        <el-table-column
          prop="keyword"
          label="Keyword"
          :render-header="appendTip"
        >
          <div slot-scope="scope">
            <el-tooltip
              effect="dark"
              :content="scope.row.keyword"
              placement="right"
            >
              <a
                v-if="scope.row.keyword_url !== '-'"
                target="_blank"
                :href="scope.row.keyword_url"
                class="link_outline_none"
              >
                <span class="link--asin"> {{ scope.row.keyword }}</span>
              </a>
              <span v-else>{{ scope.row.keyword }}</span>
            </el-tooltip>
          </div>
        </el-table-column>
        <el-table-column
          prop="rank"
          label="Keyword Freq. Rank"
          :width="200"
        />
        <el-table-column
          prop="avg_rank_last_week"
          label="Avg. Search Rank (Last 7 days)"
          :render-header="appendTip"
        />
        <el-table-column
          prop="avg_rank_prev_week"
          label="Avg. Search Rank (Prior week)"
          :render-header="appendTip"
        />
      </el-table>
      <!-- <div class="date--block u-spacing-pv-s u-spacing-ph-m">
        {{$t('last_updated')}}: {{formatter(alert.attributes.feed_date, 'date')}}
      </div> -->
    </div>
    <p
      v-if="reasons.length > 0"
      class="u-spacing-pt-xl u-font-size-5 u-font-weight-600 u-color-grey-lighter u-spacing-pb-s"
    >
      POSSIBLE CAUSES
    </p>
    <ol
      v-if="reasons.length > 0"
      style="list-style: decimal inside"
    >
      <li
        v-for="(value, $index) of reasons"
        :key="$index"
        class="u-font-size-4 possible_cause--padding"
      >
        {{ value }}.
      </li>
    </ol>
  </div>
</template>

<script>
import Vue from 'vue';
import chart from '@/components/basic/chart';
import renderColumnHeaders from '@/utils/services/el-table-column-header';

export default {
  components: {
    chart
  },
  props: {
    alert: {
      type: Object,
      default: null
    },
    // eslint-disable-next-line vue/require-default-prop
    formatter: {
      type: Function
    }
  },
  data() {
    return {
      showCharts: false,
      barChartConfig: {
        xAxisType: 'timeseries',
        load: false,
        chartOptions: {
          axes: {
            'Rep OOS': 'y2',
            Revenue: 'y',
            'Lost Buy Box Rate': 'y2',
            'Conversion Change': 'y2'
          },
          timeseries: {},
          chartWidth: 's',
          tooltip_format: {
            'Best Seller Rank': {
              roundoff: 0
            },
            SRI: {
              roundoff: 0
            },
            Revenue: {
              pre: 'currency'
            },
            'Content change': function (value) {
              return value && value === 1 ? 'Content changed' : ' No change';
            },
            OOS: function (value) {
              return value && value === 1 ? 'OOS/Unavailable' : 'In Stock';
            },
            'Lost buybox': function (value) {
              return value && value === 1 ? 'On Lost buybox' : 'Had Buybox';
            },
            Promo: function (value, ratio, id, index) {
              return this.promoDesc[index];
            }.bind(this),
            'Rep OOS': {
              suff: '%'
            },
            'Lost Buy Box Rate': {
              suff: '%'
            },
            'Conversion Change': {
              suff: '%'
            }
          },
          axis_format: {
            y: function (a) {
              return Vue.options.filters.num_format(a.toFixed(2), 'currency');
            },
            y2: {
              suff: '%'
            },
            x: '%b %e, %y'
          }
        }
      },
      barChartData: [],
      detailsTable: [],
      searchTable: [],
      reasons: [],
      promoDesc: [],
      last7Text: '',
      last14Text: ''
    };
  },
  mounted() {
    var maxDates = this.$store.getters.getMaxDate || {};
    var maxSalesDate =
      (maxDates.sales_by_account_type || {}).max_feed_date ||
      this.alert.attributes.feed_date;
    const date = new Date(maxSalesDate);
    const last7 = new Date(date.setDate(date.getDate() - 6));
    const last8 = new Date(date.setDate(date.getDate() - 1));
    const last14 = new Date(date.setDate(date.getDate() - 6));
    this.last7Text = `Last 7 days (${
      new Date(maxSalesDate).getMonth() + 1
    }/${new Date(maxSalesDate).getDate()}  - ${
      last7.getMonth() + 1
    }/${last7.getDate()})`;
    this.last14Text = `Prior week (${
      last8.getMonth() + 1
    }/${last8.getDate()} - ${last14.getMonth() + 1}/${last14.getDate()})`;

    this.detailsTable.push({
      type: 'Revenue',
      tooltipText: "Retailer's revenue for the time period",
      last_week: this.formatter(this.alert.attributes.rev_1wa, 'currency'),
      prior_week: this.formatter(this.alert.attributes.rev_2wa, 'currency'),
      change: this.formatter(this.alert.attributes.rev_change_doll, 'currency')
    });
    this.detailsTable.push({
      type: 'Avg. offer price',
      tooltipText: 'Average offer Price for the time period',
      last_week: this.formatter(
        this.alert.attributes.offer_price_1wa,
        'currency'
      ),
      prior_week: this.formatter(
        this.alert.attributes.offer_price_2wa,
        'currency'
      ),
      change: this.formatter(
        this.alert.attributes.offer_price_change,
        'percent'
      )
    });
    this.detailsTable.push({
      type: 'Avg. daily glance view',
      tooltipText: 'Average daily glance view for the time period',
      last_week: this.formatter(this.alert.attributes.gv_1wa),
      prior_week: this.formatter(this.alert.attributes.gv_2wa),
      change: this.formatter(this.alert.attributes.gv_change_wow, 'percent')
    });
    this.detailsTable.push({
      type: 'Replenishment OOS',
      tooltipText:
        'Glance views the SKU received when it was out of stock but replenishable as a % of total glance views',
      last_week: this.formatter(this.alert.attributes.rep_oos_1wa, 'percent'),
      prior_week: this.formatter(this.alert.attributes.rep_oos_2wa, 'percent'),
      change: this.formatter(this.alert.attributes.rep_oos_change, 'percent')
    });
    this.detailsTable.push({
      type: 'OOS/Unavailable days',
      tooltipText: 'Number of OOS or Unavailable day in the time period',
      last_week: Vue.options.filters.null_check(
        this.alert.attributes.oos_days_1wa,
        '-'
      ),
      prior_week: Vue.options.filters.null_check(
        this.alert.attributes.oos_days_2wa,
        '-'
      ),
      change: Vue.options.filters.null_check(
        this.alert.attributes.oos_days_change,
        '-'
      )
    });
    this.detailsTable.push({
      type: 'Lost buy box',
      tooltipText: 'Percentage of time the SKU Lost Buy Box in the time period',
      last_week: this.formatter(this.alert.attributes.lbb_1wa, 'percent'),
      prior_week: this.formatter(this.alert.attributes.lbb_2wa, 'percent'),
      change: this.formatter(this.alert.attributes.lbb_change, 'percent')
    });
    // this.detailsTable.push({
    //   type: 'Search Rank Index',
    //   last_week: Vue.options.filters.null_check(this.alert.attributes.sri_1wa, '-'),
    //   prior_week: Vue.options.filters.null_check(this.alert.attributes.sri_2wa, '-'),
    //   change: this.formatter(this.alert.attributes.sri_change, 'percent')
    // });
    this.detailsTable.push({
      type: 'Best Seller Rank',
      tooltipText: 'Average Best Seller rank for the time period',
      last_week: Vue.options.filters.null_check(
        this.alert.attributes.bsr_1wa,
        '-'
      ),
      prior_week: Vue.options.filters.null_check(
        this.alert.attributes.bsr_2wa,
        '-'
      ),
      change: Vue.options.filters.null_check(
        this.alert.attributes.bsr_change,
        '-'
      )
    });
    this.detailsTable.push({
      type: 'VPC days',
      tooltipText: 'Number of days this SKU was on VPC',
      last_week: Vue.options.filters.null_check(
        this.alert.attributes.vpc_days_1wa,
        '-'
      ),
      prior_week: Vue.options.filters.null_check(
        this.alert.attributes.vpc_days_2wa,
        '-'
      ),
      change: Vue.options.filters.null_check(
        this.alert.attributes.vpc_days_change,
        '-'
      )
    });
    this.detailsTable.push({
      type: 'Subscribe and Save status',
      tooltipText: 'Was SKU on SnS in the time period',
      last_week: this.alert.attributes.sns_1wa === 'On S&S' ? 'Yes' : 'No',
      prior_week: this.alert.attributes.sns_2wa === 'On S&S' ? 'Yes' : 'No',
      change: Vue.options.filters.null_check(
        this.alert.attributes.sns_change,
        '-'
      )
    });
    this.detailsTable.push({
      type: 'Content change',
      tooltipText:
        'Was there a content change in the time period (both title and description)',
      last_week: Vue.options.filters.null_check(
        `${this.alert.attributes.content_1wa_data}`,
        '-'
      ),
      prior_week: Vue.options.filters.null_check(
        `${this.alert.attributes.content_2wa_data}`,
        '-'
      ),
      change: this.alert.attributes.content_change
    });
    this.detailsTable.push({
      type: 'Purchase restrictions',
      tooltipText: 'If there was any purchase restriction in the time period',
      last_week: this.alert.attributes.purch_restrictions_1wa
        ? this.alert.attributes.purch_restrictions_1wa
        : 'No Restrictions',
      prior_week: this.alert.attributes.purch_restrictions_2wa
        ? this.alert.attributes.purch_restrictions_2wa
        : 'No Restrictions',
      change: Vue.options.filters.null_check(
        this.alert.attributes.purch_restrictions_change,
        'NA'
      )
    });
    this.detailsTable.push({
      type: 'Rating (Reviews)',
      tooltipText: 'Rating and Reviews of the SKU in the time period',
      last_week: `${Vue.options.filters.null_check(
        this.alert.attributes.rating_1wa,
        '-'
      )} (${Vue.options.filters.null_check(
        this.alert.attributes.reviews_1wa,
        '-'
      )} reviews)`,
      prior_week: `${Vue.options.filters.null_check(
        this.alert.attributes.rating_2wa,
        '-'
      )} (${Vue.options.filters.null_check(
        this.alert.attributes.reviews_2wa,
        '-'
      )} reviews)`,
      change: `${Vue.options.filters.null_check(
        this.alert.attributes.rating_change,
        '-'
      )} (${Vue.options.filters.null_check(
        this.alert.attributes.reviews_change,
        '-'
      )} reviews)`
    });
    this.detailsTable.push({
      type: 'Unit Conversion',
      tooltipText: 'Calculated as (Units Ordered / Glance views)',
      last_week: this.formatter(
        this.alert.attributes.conversion_change_1wa,
        'percent'
      ),
      prior_week: this.formatter(
        this.alert.attributes.conversion_change_2wa,
        'percent'
      ),
      change: this.formatter(
        this.alert.attributes.conversion_wow_change,
        'percent'
      )
    });

    if (
      this.alert.attributes.avg_units_1wa ||
      this.alert.attributes.avg_units_2wa ||
      this.alert.attributes.wow_growth_units
    ) {
      this.detailsTable.push({
        type: 'Avg. daily ordered units',
        tooltipText: 'Average daily ordered units for the time period',
        last_week: Vue.options.filters.null_check(
          `${this.alert.attributes.avg_units_1wa}`,
          '-'
        ),
        prior_week: Vue.options.filters.null_check(
          `${this.alert.attributes.avg_units_2wa}`,
          '-'
        ),
        change: this.formatter(
          this.alert.attributes.wow_growth_units,
          'percent'
        )
      });
    }

    var xs = {
      // 'Best Seller Rank': 'BSR_x'
      Revenue: 'Revenue_x'
      // 'SRI': 'SRI_x'
    };
    var dataArray = [];
    var dateArray = [];
    var chartData = [];
    var entry;

    // var bsrTrend = JSON.parse(this.alert.attributes.bsr_trend);

    // if (bsrTrend && bsrTrend.length) {
    //   dataArray = ['Best Seller Rank']
    //   dateArray = ['BSR_x']
    //   for (entry of bsrTrend) {
    //     if (entry.bsr === undefined) continue
    //     dataArray.push(entry.bsr)
    //     dateArray.push(entry.Date)
    //   }
    //   chartData.push(dataArray, dateArray)
    // }

    var revenueTrend = [];
    if (this.alert.attributes.revenue_trend) {
      revenueTrend = JSON.parse(this.alert.attributes.revenue_trend);
      if (revenueTrend.length) {
        dataArray = ['Revenue'];
        dateArray = ['Revenue_x'];
        for (entry of revenueTrend) {
          if (entry.daily_revenue === undefined) continue;
          dataArray.push(entry.daily_revenue);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
      }
    }

    // var sriTrend = JSON.parse(this.alert.attributes.sri_trend);
    // if (sriTrend && sriTrend.length) {
    //   dataArray = ['SRI']
    //   dateArray = ['SRI_x']
    //   for (entry of sriTrend) {
    //     if (entry.sri === undefined) continue
    //     dataArray.push(entry.sri)
    //     dateArray.push(entry.Date)
    //   }
    //   chartData.push(dataArray, dateArray)
    // }

    // xs['Content change'] = 'Content change_x';
    // var contentChange = JSON.parse(this.alert.attributes.content_change_flag_trend);
    // if (contentChange && contentChange.length) {
    //   dataArray = ['Content change']
    //   dateArray = ['Content change_x'];
    //   for (entry of contentChange) {
    //     if (entry.content_flag === undefined) continue
    //     dataArray.push(entry.content_flag)
    //     dateArray.push(entry.Date)
    //   }
    //   chartData.push(dataArray, dateArray)
    // }

    // xs['Glance views'] = 'Glance views_x';
    // var glanceView = JSON.parse(this.alert.attributes.glance_views_trend);
    // if (glanceView && glanceView.length) {
    //   dataArray = ['Glance views']
    //   dateArray = ['Glance views_x'];
    //   for (entry of glanceView) {
    //     if (entry.gv === undefined) continue
    //     dataArray.push(entry.gv)
    //     dateArray.push(entry.Date)
    //   }
    //   chartData.push(dataArray, dateArray)
    // }

    // xs['OOS'] = 'OOS_x';
    // var ossTrend = JSON.parse(this.alert.attributes.oos_trend);
    // if (ossTrend && ossTrend.length) {
    //   dataArray = ['OOS']
    //   dateArray = ['OOS_x'];
    //   for (entry of ossTrend) {
    //     if (entry.oos_flag === undefined) continue
    //     dataArray.push(entry.oos_flag)
    //     dateArray.push(entry.Date)
    //   }
    //   chartData.push(dataArray, dateArray)
    // }

    // xs['Promo'] = 'Promo_x';
    // var promoTrend = JSON.parse(this.alert.attributes.promo_flag_trend);
    // if (promoTrend && promoTrend.length) {
    //   dataArray = ['Promo']
    //   dateArray = ['Promo_x'];
    //   for (entry of promoTrend) {
    //     if (entry.promo_flag === undefined) continue
    //     dataArray.push(entry.promo_flag)
    //     dateArray.push(entry.Date)
    //     var desc = JSON.parse(entry.promo_desc);
    //     if (desc.length === 0) desc = ['Not on Promo'];
    //     this.promoDesc.push(desc.join(', '));
    //   }
    //   chartData.push(dataArray, dateArray)
    // }

    // xs['Lost buybox'] = 'Lost buybox_x';
    // const llbTrend = JSON.parse(this.alert.attributes.llb_trend);
    // if (llbTrend && llbTrend.length) {
    //   dataArray = ['Lost buybox']
    //   dateArray = ['Lost buybox_x'];
    //   for (entry of llbTrend) {
    //     if (entry.llb_flag === undefined) continue
    //     dataArray.push(entry.llb_flag)
    //     dateArray.push(entry.Date)
    //   }
    //   chartData.push(dataArray, dateArray)
    // }

    xs['Rep OOS'] = 'Rep OOS_x';
    var RepOSSTrend = [];
    if (this.alert.attributes.rep_oos_trend) {
      RepOSSTrend = JSON.parse(this.alert.attributes.rep_oos_trend);
      if (RepOSSTrend.length) {
        dataArray = ['Rep OOS'];
        dateArray = ['Rep OOS_x'];
        for (entry of RepOSSTrend) {
          if (entry.rep_oos === undefined) continue;
          dataArray.push(entry.rep_oos);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
      }
    }

    xs['Lost Buy Box Rate'] = 'Lost Buy Box Rate_x';
    var LLBRateTrend = [];
    if (this.alert.attributes.llb_rate_trend) {
      LLBRateTrend = JSON.parse(this.alert.attributes.llb_rate_trend);
      if (LLBRateTrend.length) {
        dataArray = ['Lost Buy Box Rate'];
        dateArray = ['Lost Buy Box Rate_x'];
        for (entry of LLBRateTrend) {
          if (entry.llb_rate === undefined) continue;
          dataArray.push(entry.llb_rate);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
      }
    }

    var convTrend = [];
    if (this.alert.attributes.conversion_change_trend) {
      xs['Conversion Change'] = 'Conversion Change_x';
      convTrend = JSON.parse(this.alert.attributes.conversion_change_trend);
      if (convTrend.length) {
        dataArray = ['Conversion Change'];
        dateArray = ['Conversion Change_x'];
        for (entry of convTrend) {
          if (entry.conversion === undefined) continue;
          dataArray.push(entry.conversion);
          dateArray.push(entry.Date);
        }
        chartData.push(dataArray, dateArray);
      }
    }

    this.barChartConfig.chartOptions.timeseries = xs;
    this.barChartData = chartData;
    this.showCharts = true;

    if (this.alert.attributes.keyword_1) {
      this.searchTable.push({
        keyword: this.alert.attributes.keyword_1,
        rank: Vue.options.filters.null_check(
          this.alert.attributes.keyword_1_curr_sr,
          'Not Available'
        ),
        avg_rank_last_week: Vue.options.filters.null_check(
          this.alert.attributes.keyword_1_lw_sr,
          'Out of Page 1'
        ),
        avg_rank_prev_week: Vue.options.filters.null_check(
          this.alert.attributes.keyword_1_pw_sr,
          'Out of Page 1'
        ),
        keyword_url: Vue.options.filters.null_check(
          this.alert.attributes.keyword_1_url,
          '-'
        )
      });
    }

    this.alert.attributes.keyword_2 &&
      this.searchTable.push({
        keyword: this.alert.attributes.keyword_2,
        rank: Vue.options.filters.null_check(
          this.alert.attributes.keyword_2_curr_sr,
          'Not Available'
        ),
        avg_rank_last_week: Vue.options.filters.null_check(
          this.alert.attributes.keyword_2_lw_sr,
          'Out of Page 1'
        ),
        avg_rank_prev_week: Vue.options.filters.null_check(
          this.alert.attributes.keyword_2_pw_sr,
          'Out of Page 1'
        ),
        keyword_url: Vue.options.filters.null_check(
          this.alert.attributes.keyword_2_url,
          '-'
        )
      });

    this.alert.attributes.keyword_3 &&
      this.searchTable.push({
        keyword: this.alert.attributes.keyword_3,
        rank: Vue.options.filters.null_check(
          this.alert.attributes.keyword_3_curr_sr,
          'Not Available'
        ),
        avg_rank_last_week: Vue.options.filters.null_check(
          this.alert.attributes.keyword_3_lw_sr,
          'Out of Page 1'
        ),
        avg_rank_prev_week: Vue.options.filters.null_check(
          this.alert.attributes.keyword_3_pw_sr,
          'Out of Page 1'
        ),
        keyword_url: Vue.options.filters.null_check(
          this.alert.attributes.keyword_3_url,
          '-'
        )
      });

    this.alert.attributes.keyword_4 &&
      this.searchTable.push({
        keyword: this.alert.attributes.keyword_4,
        rank: Vue.options.filters.null_check(
          this.alert.attributes.keyword_4_curr_sr,
          'Not Available'
        ),
        avg_rank_last_week: Vue.options.filters.null_check(
          this.alert.attributes.keyword_4_lw_sr,
          'Out of Page 1'
        ),
        avg_rank_prev_week: Vue.options.filters.null_check(
          this.alert.attributes.keyword_4_pw_sr,
          'Out of Page 1'
        ),
        keyword_url: Vue.options.filters.null_check(
          this.alert.attributes.keyword_4_url,
          '-'
        )
      });

    this.alert.attributes.keyword_5 &&
      this.searchTable.push({
        keyword: this.alert.attributes.keyword_5,
        rank: Vue.options.filters.null_check(
          this.alert.attributes.keyword_5_curr_sr,
          'Not Available'
        ),
        avg_rank_last_week: Vue.options.filters.null_check(
          this.alert.attributes.keyword_5_lw_sr,
          'Out of Page 1'
        ),
        avg_rank_prev_week: Vue.options.filters.null_check(
          this.alert.attributes.keyword_5_pw_sr,
          'Out of Page 1'
        ),
        keyword_url: Vue.options.filters.null_check(
          this.alert.attributes.keyword_5_url,
          '-'
        )
      });
    if (this.alert.attributes.reason_code) {
      this.reasons = this.alert.attributes.reason_code
        .split(',')
        .filter((a) => {
          return a !== '';
        });
    }
  },
  methods: {
    appendTip(h, options) {
      var toolTipText = '';
      var toolTipTextArray = [
        'Importance of keyword: lower the frequency higher the importance of the keyword',
        '',
        'Average search rank of the SKU in last 7 days',
        'Average search rank of SKU in the week prior to last 7 days'
      ];
      if (options) {
        toolTipText = toolTipTextArray[options.$index];
      }
      return renderColumnHeaders.renderHeader(
        h,
        options.column,
        true,
        toolTipText
      );
    }
  }
};
</script>

<style scoped>
.possible_cause--padding {
  padding-top: 4px;
  padding-bottom: 4px;
}

.link_outline_none {
  outline: none;
}
</style>
