export interface PvpDate {
  from: string;
  to: string;
  compare_name: string;
}

export interface DimensionNameValueList {
  dimensionName: string;
  dimensionValue: string | boolean;
  operator?: string;
  dimensionLabel?: string;
}

export interface Where {
  date: Date;
  pvpDate: PvpDate;
  dimensionNameValueList: DimensionNameValueList[];
}

export interface Date {
  from: string;
  to: string;
  name?: string;
  page_wise_min_max_key?: string;
}

export enum ThreePSelection {
  INCLUDE_3P = 'include3p',
  EXCLUDE_3P = 'exclude3p',
  EXCLUDE_3P_CLIENT = 'exclude3pclient',
  ONLY_3P = 'only3p'
}

export type V2Metrics =
  | 'SALES_ESTIMATES_SHARE_PERCENT_EXCLUDE_3P'
  | 'SALES_ESTIMATES_SHARE_PERCENT_EXCLUDE_MY_3P'
  | 'SALES_ESTIMATES_SHARE_PERCENT'
  | 'UNIT_ESTIMATES_SHARE_PERCENT'
  | 'UNIT_ESTIMATES_SHARE_PERCENT_EXCLUDE_3P'
  | 'UNIT_ESTIMATES_SHARE_PERCENT_EXCLUDE_MY_3P';
export type V1Metrics = 'SALES_ESTIMATES' | 'UNIT_ESTIMATES';
export type DisplayShareTypes = 'Marketshare' | 'Sales';
export interface GlobalSettings {
  [key: string]: unknown;
  subcategory: {
    subcategory: {
      [key: string]: unknown;
    };
  };
  category_hierarchy: string[];
  date_range: {
    compare: boolean;
    compare_name: string;
    name: string;
  };
  where: Where;
  nestedSettings: {
    viewBy: {
      entityType: 'brand' | 'manufacturer';
      label: string;
    };
    displayShareIn: {
      metricsList: V2Metrics | V1Metrics;
      label: string;
      shareType: DisplayShareTypes;
    };
    include3p: {
      label: string;
      value: ThreePSelection;
      tooltip: string;
    };
  };
  settingValue: {
    displayShareIn: {
      label: string;
      metricsList: string;
    };
    include3p: {
      label: string;
      value: ThreePSelection;
      tooltip: string;
    };
  };
}

export interface CustomerFilter {
  dimensionName: string;
  label: string;
}
export interface AttributeType {
  dimensionLabel: string;
  dimensionName: string;
  possibleValues?: string[];
  path?: string[];
}
export interface CategoryFilterType {
  attributes: AttributeType[];
  children: {
    [key: string]: CategoryFilterType;
  };
  id?: string;
}
export interface FilterData {
  category: {
    [key: string]: CategoryFilterType;
  };
  customer: {
    attributes: AttributeType[];
  };
}
export interface CategorySelectionList {
  [key: string]: AttributeType[];
}

export interface CategoryList {
  [key: string]: string;
}
export interface ConstructedPaths {
  [key: string]: boolean;
}
export interface AttributeData {
  [key: string]: string;
}
export interface SelectionData {
  dimensionName: string;
  selections: string[];
  path: string[];
  title: string;
}
export interface AttributeOption {
  title: string;
  enable: boolean;
}
export interface AttributeDropdownObject {
  multi: boolean;
  v1: boolean;
  key: string;
  label: string;
  values: AttributeOption[];
}

export interface AttributeDropdownData {
  [key: string]: AttributeDropdownObject;
}

export interface SelectedCategoryLevelList {
  attributes: string[];
}
export interface CategoryStatus {
  isEnabled: boolean;
  children: {
    [key: string]: CategoryStatus;
  };
}
export interface FilterStatusData {
  category: {
    [key: string]: CategoryStatus;
  };
}

export interface CategoryChildList {
  [key: string]: boolean;
}

export interface MetaData {
  [key: string]: {
    [key: string]: {
      [key: string]: string /* Define your specific type here */;
    };
  };
}

export interface BreakdownTableItem {
  [key: string]: string;
}
