<template>
  <div
    v-if="isInternalUserPage"
    class="internal-page-label u-width-100 u-display-flex u-flex-justify-content-center u-position-fixed"
  >
    <div
      class="internal-page-card u-bg-color-grey-white u-spacing-ph-m u-spacing-pv-xs u-display-flex u-flex-justify-content-center u-flex-align-items-center u-border-left u-border-right u-border-bottom u-border-width-s u-border-color-grey-xxx-light"
    >
      <div class="u-spacing-mr-s">
        <div><rb-icon icon="visibility-off" /></div>
      </div>
      <div class="u-color-grey-lighter u-font-size-6 u-font-weight-600">
        This feature is currently hidden from our customers
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  name: 'InternalUserLabel',
  data() {
    return {
      isInternalUserPage: false
    };
  },
  computed: {
    activeRetailer() {
      return this.$store.getters.getRetailer;
    },
    retailerProgram() {
      return this.$store.getters.getProgram;
    }
  },
  watch: {
    $route(to, from) {
      this.findIfPageIsInternal();
    }
  },
  mounted() {
    this.findIfPageIsInternal();
  },
  methods: {
    findIfPageIsInternal() {
      const internalRouteList =
        Vue.options.filters.config_check('feature.internal_user_routes') || '';
      const currentRoute = this.$router?.currentRoute?.fullPath || '';
      if (!currentRoute || !internalRouteList) return;
      // check if the current route path includes in any of the internal user route path.
      this.isInternalUserPage = internalRouteList
        ?.split(',')
        .some((route) => route && currentRoute?.includes(route));
      // client specific page inernal route check
      this.clientSpecificInternalRouteCheck(currentRoute);
      if (this.isInternalUserPage) {
        this.$nextTick(() => {
          this.findTopHeight();
        });
      }
    },
    clientSpecificInternalRouteCheck(currentRoute) {
      const urlSplitCollection = currentRoute?.split('/');
      const currentPageRoute =
        urlSplitCollection && urlSplitCollection[urlSplitCollection.length - 1];
      // adding retailer/program incase same page needs to internal in one client in the future
      const isClientSpecificInternalRoute = Vue.options.filters.config_check(
        `feature.${this.activeRetailer}-${this.retailerProgram}.pages.${currentPageRoute}.showInternalUserBadge`
      );
      if (typeof isClientSpecificInternalRoute === 'string') {
        this.isInternalUserPage = isClientSpecificInternalRoute === 'Yes';
      }
    },
    findTopHeight() {
      let height = 0;
      height += document.querySelector('.nav-h-primary')?.offsetHeight || 0;
      height += document.querySelector('.nav-h-secondary')?.offsetHeight || 0;
      document.querySelector('.internal-page-label').style.top = height + 'px';
    }
  }
};
</script>
<style lang="css" scoped>
.internal-page-label {
  z-index: 102;
  left: 50%;
  transform: translate(-50%, 0);
}
.internal-page-card {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
</style>
