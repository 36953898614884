<template>
  <section
    class="strategy_description_wrp u-display-flex u-width-100 u-align-items-flex-start"
  >
    <div
      v-for="(list, $index) in detailsListMapping"
      :key="list.displayName"
      class="u-display-flex u-width-25 u-flex-direction-column u-spacing-pr-m u-border-width-s u-border-color-grey-xxx-light u-flex-justify-content-flex-start u-color-grey-base"
      :class="{
        'u-spacing-pl-m': $index !== 0,
        'u-border-right': $index !== detailsListMapping.length - 1
      }"
    >
      <div class="header u-font-weight-600 u-font-size-5 u-spacing-mb-s">
        <span>{{ $index + 1 }}. {{ list.displayName }}</span>
      </div>
      <!-- eslint-disable -->
      <div class="desc u-font-size-5 u-line-height-1-3 u-spacing-pl-m">
        <span
          v-html="
            formatTextToDisplay(details[list.keyToRead], list.displayName)
          "
        />
      </div>
      <!-- eslint-enable -->
    </div>
  </section>
</template>
<script>
import { operator } from '@/utils/helpers/operator.js';
import moment from 'moment-timezone';
import multiRetailerConfig from '@/components/pages/strategy_builder/multiRetailerConfig.js';
import Vue from 'vue';
export default {
  name: 'StrategyDescription',
  owner: 'Rajan V',
  filters: {
    dateFormatFilter: function (value) {
      if (!value) return 'No Date';
      return moment(value).format('MMM DD, YYYY');
    }
  },
  props: {
    details: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      detailsListMapping: [
        { displayName: 'Objective', keyToRead: 'objective' },
        { displayName: 'Scope', keyToRead: 'scope' },
        { displayName: 'Condition', keyToRead: 'condition' },
        { displayName: 'Action', keyToRead: 'actions' },
        { displayName: 'Time period', keyToRead: 'timeperiod' }
      ],
      numberOfDaysmutiplier: {
        Days: 1,
        Months: 30,
        Years: 365
      }
    };
  },
  computed: {
    filterMetaDataInfo() {
      return this.$store.getters.getFilterMetaDataInfo;
    }
  },
  methods: {
    dimensionNameMapping(dimensionName) {
      if (dimensionName) {
        const strategyEntityType = this.details.scope.entityType.toUpperCase();
        return this.filterMetaDataInfo[strategyEntityType][dimensionName];
      }
    },
    findOptimalMetric(days) {
      let optimalMetric, optimalValue;
      if (days % this.numberOfDaysmutiplier.Years === 0) {
        optimalValue = Math.floor(days / this.numberOfDaysmutiplier.Years);
        optimalMetric = optimalValue > 1 ? 'years' : 'year';
      } else if (days % this.numberOfDaysmutiplier.Months === 0) {
        optimalValue = Math.floor(days / this.numberOfDaysmutiplier.Months);
        optimalMetric = optimalValue > 1 ? 'months' : 'month';
      } else {
        optimalValue = days;
        optimalMetric = optimalValue > 1 ? 'days' : 'day';
      }
      return `${optimalValue + ' ' + optimalMetric}`;
    },
    formatTextToDisplay(value, type) {
      let text = '';
      if (type === 'Objective') {
        return value.name;
      } else if (type === 'Scope') {
        const filtersExtension = multiRetailerConfig.filtersExtension.default;
        if (value.subFilter) {
          text = `${value.subFilter} of ${value.strategyEntityType}`;
        } else {
          text = `All ${value.strategyEntityType}s`;
        }
        if (value.scopeRules) {
          text += ' in ';
          const tempObj = value.scopeRules.reduce((initial, value) => {
            for (const item in filtersExtension) {
              if (
                filtersExtension[item].apiValue === value.dimensionName &&
                value.dimensionValue.includes(filtersExtension[item].type)
              ) {
                value.dimensionName = item;
                value.dimensionValue = value.dimensionValue
                  .replaceAll(
                    filtersExtension[item].replaceDelimiter,
                    filtersExtension[item].uiDelimiter
                  )
                  .replace(
                    filtersExtension[item].type +
                      filtersExtension[item].uiDelimiter,
                    ''
                  );
                value.dimensionValue = filtersExtension[item].endsWithADelimiter
                  ? value.dimensionValue.slice(0, -1)
                  : value.dimensionValue;
              }
            }
            if (!initial[value.dimensionName]) {
              initial[value.dimensionName] = [];
              initial[value.dimensionName].push(value.dimensionValue);
            } else {
              initial[value.dimensionName].push(value.dimensionValue);
            }
            return initial;
          }, {});
          Object.keys(tempObj).forEach((rule, index) => {
            const shelfLabel =
              (filtersExtension[rule]?.name && {
                label: filtersExtension[rule].name
              }) ||
              null;
            const label = this.dimensionNameMapping(rule) || shelfLabel || {};
            text += `${label.label || 'NA'} ${this.showFiltersValues(
              tempObj[rule]
            )}`;
            if (Object.keys(tempObj).length - 1 !== index) {
              text += ' and ';
            }
          });
        }
        return text;
      } else if (type === 'Condition') {
        value.rules.forEach((rule, index) => {
          const label = this.dimensionNameMapping(rule.dimensionName) || {};
          text += `${label.label || 'NA'}`;
          const operatorType = this.dimensionNameMapping(rule.dimensionName);
          if (
            operatorType &&
            operatorType.unit &&
            operatorType.dimensionType !== 'STRATEGY'
          ) {
            if (operatorType.unit === 'PERCENTAGE') {
              text += ` ${this.operatorNameMapping(rule.operator)} ${
                rule.dimensionValue
              }%`;
            } else {
              text += ` ${this.operatorNameMapping(
                rule.operator
              )} ${Vue.options.filters.num_format(
                rule.dimensionValue,
                'currency'
              )}`;
            }
          } else {
            if (operatorType.dimensionType === 'STRATEGY') {
              const displayValue = JSON.parse(rule.dimensionValue);
              text += `: ${displayValue.strategyName}`;
            } else {
              text += ` ${this.operatorNameMapping(rule.operator)} ${
                rule.dimensionValue
              }`;
            }
          }
          if (Object.keys(value.rules).length - 1 !== index) {
            text += ' and ';
          }
        });
        if (
          value.lookBackWindow.name &&
          value.lookBackWindow.name === 'Choose custom last X days'
        ) {
          text =
            text +
            ` from last ${this.findOptimalMetric(
              value.lookBackWindow.numberOfDays
            )} `;
          if (value.lookBackWindow.numberOfDaysExcluded > 0) {
            text =
              text +
              ` except last ${this.findOptimalMetric(
                value.lookBackWindow.numberOfDaysExcluded
              )} `;
          }
        } else {
          text =
            text +
            ` from ${
              (value.lookBackWindow && value.lookBackWindow.name) || ''
            } `;
        }
        return text;
      } else if (type === 'Action') {
        return value.actionName;
      } else if (type === 'Time period') {
        text += `Starting from ${this.$options.filters.dateFormatFilter(
          value.strategyStartDate
        )} `;
        if (value.strategyEndDate) {
          text += `to ${this.$options.filters.dateFormatFilter(
            moment.utc(value.strategyEndDate).format('YYYY-MM-DD')
          )}`;
        } else {
          text += 'Until forever';
        }
        return text;
      }
    },
    operatorNameMapping(name) {
      return operator[name];
    },
    showFiltersValues(filterArray) {
      if (filterArray.length > 0) {
        var maxStrLen = 36;
        var concatStr = '';
        for (var idx = 0; idx <= filterArray.length; idx++) {
          var curSelected = filterArray[idx];
          if (concatStr.length >= maxStrLen - 3) {
            concatStr = concatStr.slice(0, maxStrLen - 3);
            // add '...' if all the selections is not fit into concatStr
            for (var k = 0; k < idx; k++) {
              if (!concatStr.includes(filterArray[k])) {
                concatStr = concatStr.concat('...');
              }
            }
            // Handling edge case ',' in the end for unclipped selections.
            if (concatStr.charAt(concatStr.length - 1).includes(',')) {
              concatStr = concatStr.slice(0, concatStr.length - 1);
            }
            var moreCount = filterArray.length - idx;
            concatStr = concatStr.concat(
              idx < filterArray.length ? ' & ' + moreCount + ' more' : ''
            );
            break;
          } else if (curSelected && curSelected.length > 0) {
            concatStr = concatStr.concat(
              curSelected,
              idx < filterArray.length - 1 ? ', ' : ''
            );
          }
        }
        return `<span v-tippy title="${concatStr}">${concatStr}</span>`;
      }
    }
  }
};
</script>
