<template>
  <div
    class="automation-card u-bg-color-grey-white shadows u-border-radius-s u-spacing-p-m"
  >
    <div
      class="card-header u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
    >
      <span class="u-font-size-6 u-color-grey-light u-font-weight-600">{{
        cardTitle
      }}</span>
      <icon-text
        v-if="
          isAutomationSupported ||
          selectedRetailer === 'retail' ||
          selectedRetailer === 'retail_and_3p'
        "
        icon="bot"
        icon-classes="rb-icon--small u-color-grey-light u-spacing-ml-s"
        :text="isAutomated ? 'Automated by CIQ' : 'Not Automated'"
        text-classes="badge-text u-font-size-7 u-color-grey-light u-font-weight-400 u-spacing-mv-xxs u-spacing-mr-s"
        :container-class="
          isAutomated
            ? 'u-border-radius-l automation-badge badge-automated'
            : 'u-border-radius-l automation-badge badge-not-automated'
        "
      />
    </div>
    <div
      v-if="isAutomationSupported"
      class="card-body"
    >
      <div class="u-display-flex u-flex-direction-column">
        <div class="card-summary-data u-display-flex">
          <div
            class="u-display-flex u-spacing-pt-l u-width-100 u-flex-justify-content-space-between"
          >
            <div>
              <metric
                size="l"
                :class="['u-font-weight-600', 'u-font-size-3']"
                :config="cardOverviewData['skus_hybrid'].config"
                :data="
                  isMetricLoading
                    ? loadingMetricData
                    : cardOverviewData['skus_hybrid'].value
                "
                :hide-zero="false"
              />
              <div class="u-display-flex u-spacing-pt-s">
                <span @click="scrollToTable()">
                  <metric
                    size="13-12"
                    :class="[
                      'u-font-weight-600',
                      'u-font-size-3',
                      'u-display-inline',
                      'u-text-decoration-underline',
                      'u-cursor-pointer'
                    ]"
                    :config="cardOverviewData['tickets_logged'].config"
                    :data="
                      isMetricLoading
                        ? loadingMetricData
                        : cardOverviewData['tickets_logged'].value
                    "
                    :hide-zero="false"
                  />
                </span>
                <span
                  class="u-font-size-6 u-spacing-pl-xs u-font-weight-400 u-color-grey-lighter"
                  >Tickets logged</span
                >
              </div>
            </div>
            <span class="vertical-divider u-spacing-mh-m u-spacing-mv-xxs" />
            <div
              class="u-display-flex u-flex-direction-column u-flex-align-items-flex-end"
            >
              <metric
                size="l"
                :class="['u-font-weight-600', 'u-font-size-3']"
                :label-color="isAutomated ? '#3EA95E' : '#D7263D'"
                :config="cardOverviewData['revenue_impact_hybrid'].config"
                :data="
                  isMetricLoading
                    ? loadingMetricData
                    : cardOverviewData['revenue_impact_hybrid'].value
                "
                :hide-zero="false"
              />
              <div
                class="u-font-size-6 u-font-weight-400 u-color-grey-lighter u-spacing-pt-s"
              >
                Revenue impact
              </div>
            </div>
          </div>
        </div>
        <span
          class="u-font-size-6 u-font-weight-400 u-color-grey-lighter u-spacing-pt-l"
          >SKUs impact vs Revenue Impact</span
        >
        <div
          class="trend-chart u-width-100 u-spacing-pt-s"
          :class="
            isChartLoading ? 'u-flex-justify-content-center u-display-flex' : ''
          "
        >
          <Loader
            v-if="isChartLoading"
            :loading="isChartLoading"
            class="u-bg-color-grey-white"
            :color="'#3fadf7'"
            size="6rem"
            :thickness="'.2rem'"
          />
          <chartWithGradient
            v-else
            :metric-data="retailerSpecificChartData"
            :card-primary-metric-color-code="chartColor"
            :metadata="{ name: 'automation_type' }"
            :metrics-list="chartMetricsList[selectedRetailer]"
            :chart-config="chartConfig"
          />
        </div>
        <div
          v-if="selectedRetailer === 'retail_and_3p'"
          class="retailer-split u-display-flex u-spacing-pt-m u-flex-justify-content-space-between"
        >
          <div class="u-display-flex u-flex-direction-column">
            <span class="u-font-size-6 u-font-weight-400 u-color-grey-lighter"
              >Retail</span
            >
            <div class="u-display-flex u-flex-direction-row u-spacing-pt-s">
              <metric
                size="14-12"
                :class="[
                  'u-font-weight-600',
                  'u-font-size-3',
                  'u-display-inline'
                ]"
                :config="cardOverviewData['skus_retail'].config"
                :data="
                  isMetricLoading
                    ? loadingMetricData
                    : cardOverviewData['skus_retail'].value
                "
                :hide-zero="false"
              />
              <rb-icon
                icon="dot"
                class="rb-icon u-color-grey-xx-light u-spacing-mh-s u-spacing-pb-xxs rb-icon--x-small"
              />
              <metric
                size="14-12"
                :class="[
                  'u-font-weight-600',
                  'u-font-size-3',
                  'u-display-inline'
                ]"
                :label-color="isAutomated ? '#3EA95E' : '#D7263D'"
                :config="cardOverviewData['revenue_impact_retail'].config"
                :data="
                  isMetricLoading
                    ? loadingMetricData
                    : cardOverviewData['revenue_impact_retail'].value
                "
                :hide-zero="false"
              />
            </div>
          </div>
          <div
            v-if="isThreePSupported"
            class="u-display-flex u-flex-direction-column"
          >
            <span class="u-font-size-6 u-font-weight-400 u-color-grey-lighter"
              >3P</span
            >
            <div class="u-display-flex u-flex-direction-row u-spacing-pt-s">
              <metric
                size="14-12"
                :class="[
                  'u-font-weight-600',
                  'u-font-size-3',
                  'u-display-inline'
                ]"
                :config="cardOverviewData['skus_3p'].config"
                :data="
                  isMetricLoading
                    ? loadingMetricData
                    : cardOverviewData['skus_3p'].value
                "
                :hide-zero="false"
              />
              <rb-icon
                icon="dot"
                class="rb-icon u-color-grey-xx-light u-spacing-mh-s u-spacing-pb-xxs rb-icon--x-small"
              />
              <metric
                size="14-12"
                :class="[
                  'u-font-weight-600',
                  'u-font-size-3',
                  'u-display-inline'
                ]"
                :label-color="isAutomated ? '#3EA95E' : '#D7263D'"
                :config="cardOverviewData['revenue_impact_3p'].config"
                :data="
                  isMetricLoading
                    ? loadingMetricData
                    : cardOverviewData['revenue_impact_3p'].value
                "
                :hide-zero="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="selectedRetailer !== '3p'"
        class="u-display-flex u-flex-align-items-center u-spacing-pt-m"
      >
        <rb-icon
          icon="ticket"
          class="u-color-pink-base"
        />
        <metric
          size="13-12"
          class="u-font-size-6 u-spacing-pl-xs u-font-weight-600 u-color-grey-lighter u-cursor-default"
          :config="cardOverviewData['attention_tickets'].config"
          :data="
            isMetricLoading
              ? loadingMetricData
              : cardOverviewData['attention_tickets'].value
          "
          :hide-zero="false"
        />
        <span class="u-spacing-pl-xs u-color-grey-lighter u-font-size-6"
          >need your attention</span
        >
      </div>
    </div>
    <div
      v-else
      class="u-display-flex u-flex-direction-column u-flex-align-items-center"
    >
      <img
        height="96px"
        src="/images/unavailable_report.png"
        alt=""
        class="u-spacing-mt-l u-spacing-mb-m"
      />
      <span
        class="u-font-size-6 u-color-grey-light u-font-weight-600 u-spacing-mb-s"
        >Not Applicable</span
      >
      <span
        class="u-font-size-7 u-color-grey-light u-font-weight-400 u-text-align-center"
        >The automation type chosen is incompatible with the selected business
        model filter.</span
      >
    </div>
    <div class="u-display-inline-block">
      <div
        v-if="isAutomationSupported"
        class="u-font-size-7 u-color-blue-base u-font-weight-600 u-cursor-pointer u-display-flex u-flex-align-items-center u-spacing-pt-m"
        @click="toggleCard"
      >
        <rb-icon
          :icon="isCardExpanded ? 'collapse' : 'full-screen'"
          class="rb-icon--small u-spacing-mr-s"
        />
        <span class="u-font-size-6">
          {{ isCardExpanded ? 'Hide details' : 'View more details' }}
        </span>
      </div>
      <div
        v-else
        class="u-font-size-7 u-color-grey-lighter u-font-weight-600 u-cursor-default u-display-flex u-flex-align-items-center u-spacing-pt-m"
      >
        <rb-icon
          icon="full-screen"
          class="rb-icon--small u-spacing-mr-s"
        />
        <span class="u-spacing-mt-xxs u-font-size-6"> View more details </span>
      </div>
    </div>
  </div>
</template>

<script>
import iconText from '@/components/basic/iconText.vue';
import chartWithGradient from '@/components/basic/chart-with-gradient.vue';
import Loader from '@/components/basic/loader.vue';
import metric from '@/components/basic/metric.vue';
import { eventBus } from '@/utils/services/eventBus';

export default {
  name: 'AutomationTypeCard',
  components: {
    iconText,
    chartWithGradient,
    Loader,
    metric
  },
  props: {
    cardTitle: {
      type: String,
      default: ''
    },
    cardOverviewData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    cardChartData: {
      type: Array,
      default: () => []
    },
    chartMetricsList: {
      type: Object,
      default: () => {}
    },
    cardChartConfig: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    isAutomated: {
      type: Boolean,
      default: false
    },
    isCardExpanded: {
      type: Boolean,
      default: false
    },
    selectedRetailer: {
      type: String,
      default: 'retail_and_3p'
    },
    isAutomationSupported: {
      type: Boolean,
      default: false
    },
    isWidgetLoading: {
      type: Boolean,
      default: true
    },
    isMetricLoading: {
      type: Boolean,
      default: true
    },
    isChartLoading: {
      type: Boolean,
      default: true
    },
    isThreePSupported: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loadingMetricData: {
        tag1: 'loading'
      },
      chartConfig: {
        xFormat: '%m/%d/%Y',
        axis: { x: { show: false }, y: { show: false }, y2: { show: false } },
        legend: false,
        axes: {
          SKUs: 'y2',
          gradient0: 'y2'
        },
        size: { height: 65 },
        tooltip: false,
        tooltip_format: {
          'Revenue Impact': { pre: '', roundoff: 2, suff: 'currency' },
          SKUs: { pre: '', roundoff: 2, suff: ' SKUs' }
        },
        point: false,
        line: { connectNull: true },
        padding: { left: -8, right: -7, bottom: 5 },
        regions: {},
        timeseries: 'feed_date',
        tooltip_position: function (a, b, c, d) {
          return { top: -45, left: -20 };
        }
      }
    };
  },
  computed: {
    retailerSpecificChartData() {
      return this.cardChartData.filter((data) =>
        this.cardChartConfig[this.selectedRetailer].includes(data.alias)
      );
    },
    chartColor() {
      let chartColorConfig = {};
      this.chartMetricsList[this.selectedRetailer].forEach((metric) => {
        if (metric.alias.includes('impact')) {
          chartColorConfig[metric.alias] = this.isAutomated
            ? '#3EA95E'
            : '#D7263D';
        } else {
          chartColorConfig[metric.alias] = '#8B8F93';
        }
      });
      return chartColorConfig;
    }
  },
  methods: {
    toggleCard() {
      this.$emit('toggleAutomationTypeCard', { cardId: this.index });
    },
    scrollToTable() {
      if (!this.isCardExpanded) {
        this.toggleCard();
      }
      eventBus.$emit('switchToTicketStatusTab');
      setTimeout(() => {
        document
          .getElementsByClassName('listings-health-table')[0]
          .scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start'
          });
      }, 250);
    }
  }
};
</script>

<style lang="css">
.automation-card {
  width: 25%;
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
.automation-card .automation-badge div {
  display: flex;
  align-items: center;
  align-content: center;
}
.automation-card .automation-badge .badge-text {
  padding: 2px 0 0 4px;
}
.automation-card .badge-automated {
  background: #007cf61a;
}
.automation-card .badge-not-automated {
  background: #e9eaeb;
}
.automation-card .vertical-divider {
  background: #e9eaeb;
  width: 1px;
  height: auto;
}
</style>
