<template>
  <div
    class="u-width-100 u-height-100 u-color-grey-base u-text-case-title u-text-align-left"
  >
    <div
      class="u-text-overflow-ellipsis u-width-100 u-height-100"
      @mouseover="showEdit = true"
      @mouseleave="onMouseLeave"
    >
      <div
        v-tippy="tippyOptions"
        class="u-display-flex u-flex-align-items-center u-width-100 u-cursor-pointer u-flex-justify-content-center u-height-100"
        :class="{
          'u-color-orange-base': tempValue.value || valuesUpdated,
          'u-color-blue-base': showEditByDefault
        }"
        role="button"
        @click="() => setStatusShowingElement(!hideEditIcon)"
      >
        <div v-if="useJustPercentage">
          <div
            v-if="(tempValue.value ? tempValue : value).value"
            class="u-font-size-5"
            :class="showError ? 'u-color-red-base' : ''"
          >
            {{ (tempValue.value ? tempValue : value).value }}%
          </div>
        </div>
        <div
          v-else
          class="u-font-size-5"
        >
          Change By
          {{
            (tempValue.value ? tempValue : value).achieveOPSGoalsBy ===
            'percentage'
              ? (tempValue.value ? tempValue : value).value
              : filteredCurrency((tempValue.value ? tempValue : value).value)
          }}{{
            (tempValue.value ? tempValue : value).achieveOPSGoalsBy ===
            'percentage'
              ? '%'
              : ''
          }}
        </div>
        <div v-if="!hideEditIcon">
          <div
            v-show="showEdit"
            class="u-display-inline-flex"
          >
            <rb-icon
              :class="{
                'u-color-blue-base': showEditByDefault,
                'u-color-grey-lighter': !showEditByDefault
              }"
              class="rb-icon--small u-spacing-ml-m"
              :icon="'pencil'"
            />
          </div>
          <div
            :id="id"
            ref="adjust_percentage"
            class="u-spacing-p-s u-min-width-220px"
          >
            <div class="u-spacing-mb-s">
              <div
                v-if="!useJustPercentage"
                class="u-spacing-mb-s"
              >
                Change By
              </div>

              <input
                v-model="percent"
                type="number"
                min="0"
                class="rb-input roas-input u-display-flex u-flex-align-items-center"
                step="0.1"
              />
            </div>
            <div
              v-if="!useJustPercentage && !useForOutputScreen && !isOverall"
              class="u-spacing-pb-s"
            >
              <rb-checkbox v-model="useForAllRows">
                <div class="u-font-size-7 u-color-grey-light u-spacing-mb-2px">
                  Use this value for all rows below
                </div>
              </rb-checkbox>
            </div>
            <div
              v-show="!allowNegative && percent < 0"
              class="u-display-flex u-flex-align-items-center u-font-size-7 u-color-red-base u-spacing-mv-s"
            >
              <rb-icon
                class="u-flex-0 rb-icon--small"
                icon="error-fill"
              />
              <div class="u-flex-0 u-spacing-ml-xxs">
                Negative values are not allowed
              </div>
            </div>
            <div
              class="u-display-flex u-flex-align-items-center u-spacing-p-s action-buttons"
            >
              <rb-button
                :click-fn="() => save()"
                text="Save"
                :size="'s'"
                type="filled"
                class="u-cursor-pointer u-spacing-mr-s"
                :disabled="!allowNegative && percent < 0"
              />
              <rb-button
                :click-fn="() => close()"
                text="Cancel"
                :size="'s'"
                type="hollow"
                class="u-cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import RadioGroup from '@/components/ams/campaign_creation/steps/common/rbRadioGroupSubLabel.vue';
export default {
  name: 'AdjustPercentage',
  components: {
    RadioGroup
  },
  props: {
    rowIndex: {
      default: 0,
      type: Number
    },
    tableId: {
      default: '',
      type: String
    },
    value: {
      default() {
        return {};
      },
      type: Object
    },
    useJustPercentage: {
      default: false,
      type: Boolean
    },
    colId: {
      type: String,
      default: ''
    },
    hideEditIcon: {
      type: Boolean,
      default: false
    },
    useForOutputScreen: {
      type: Boolean,
      default: false
    },
    showError: {
      type: Boolean,
      default: false
    },
    setValue: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    valuesUpdated: {
      type: Boolean,
      default: false
    },
    allowNegative: {
      type: Boolean,
      default: true
    },
    isOverall: {
      type: Boolean,
      default: false
    },
    showEditByDefault: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const id = `${this.tableId}-infoTooltip-${
      this.rowIndex
    }-${this.colId.replace(/[^a-z0-9]/gi, '')}`;
    return {
      id,
      statusElement: false,
      useForAllRows: false,
      showEdit: false,
      percent: this.value.value,
      isActive: false,
      name: '',
      tippyOptions: {
        html: `#${id}`,
        reactive: true,
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        placement: 'top-start',
        offset: '20, 0',
        duration: [0, 0],
        onShow: this.onShow,
        onHide: this.onHide
      },
      tippyInstance: null,
      tempValue: {},
      valueChanged: false
    };
  },
  watch: {
    statusElement(isShow) {
      if (isShow) {
        document.addEventListener('mousedown', this.handleClickOutside);
      } else this.stopEventListener();
    },
    value(newValue) {
      this.percent = newValue.value;
    }
  },
  created() {
    if (this.showEditByDefault) {
      this.showEdit = true;
    }
  },
  beforeDestroy() {
    this.stopEventListener();
  },
  methods: {
    stopEventListener() {
      this.statusElement = false;
      document.removeEventListener('mousedown', this.handleClickOutside);
    },
    setStatusShowingElement(status) {
      this.statusElement = status;
    },
    handleClickOutside({ target }) {
      if (
        this.$refs.adjust_percentage &&
        !this.$refs.adjust_percentage.contains(target)
      ) {
        this.close();
      }
    },
    filteredCurrency(currency) {
      return Vue.options.filters.num_format(currency, 'currency');
    },
    close() {
      if (this.tippyInstance) {
        this.tippyInstance.hide();
        this.setStatusShowingElement(false);
        if (!this.showEditByDefault) this.showEdit = false;
      }
    },
    save() {
      const value = isNaN(this.percent) ? 0 : parseFloat(this.percent);
      if (this.useForOutputScreen) {
        this.setValue(value);
        this.tempValue = {
          value,
          achieveOPSGoalsBy: 'percentage'
        };
      }

      this.$emit('updateRow', {
        tableId: this.tableId,
        rowId: this.rowIndex,
        useForAllRows: this.useForAllRows,
        value,
        colId: this.colId
      });
      this.close();
    },
    onShow(instance) {
      this.tippyInstance = instance;
      this.isActive = true;
    },
    onHide() {
      this.isActive = false;
    },
    onMouseLeave() {
      if (!this.statusElement && !this.showEditByDefault) this.showEdit = false;
    }
  }
};
</script>

<style lang="css" scoped>
.white-space-normal {
  white-space: normal !important;
}

.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input.roas-input {
  padding-left: 4px;
  height: 36px;
  width: 100%;
  color: #6a7075;
}

.u-spacing-mb-2px {
  margin-bottom: 2px;
}

.action-buttons {
  border-top: 1px solid #e9eaeb;
  margin: 0 -8px -8px -8px;
}

.tippy-content .rb-select {
  position: relative;
}

.u-width-120px {
  width: 120px;
}

.u-min-width-220px {
  min-width: 220px;
}
</style>
