<template>
  <!-- Container -->
  <div
    class="u-color-grey-base u-position-relative u-bg-color-white-base u-width-100 u-display-flex u-flex-direction-column"
  >
    <!-- Loader -->
    <div
      v-if="loader"
      class="loaderContainer"
    >
      <loader
        class="fill--parent"
        :loading="loader"
        :color="'#007cf6'"
      />
    </div>

    <div class="u-spacing-pb-l u-spacing-ph-l u-spacing-pt-l">
      <!-- Heading -->
      <div
        class="title u-font-size-5 u-font-weight-600 u-spacing-pb-l u-font-weight-600 u-text-case-upper"
      >
        Edit ATTRIBUTION SETTINGS ({{ selections.length }} Campaign{{
          selections.length > 1 ? 's' : ''
        }}
        selected)
      </div>
      <div class="u-display-flex">
        <div class="u-width-20">
          <div class="u-display-flex u-flex-direction-column">
            <div class="u-font-size-5 u-font-weight-600 u-spacing-pb-s">
              Post click lookback window:
            </div>
            <div>
              <rb-select
                class="u-spacing-mr-s valueDropdown"
                :send-details="true"
                :on-close="postClickWindowOptionSelected"
                :options="config.postClickWindowOptions"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                >
                  <div
                    class="u-display-flex u-flex-direction-column u-spacing-p-s"
                  >
                    <span class="u-font-size-5">{{
                      selectedPostClickWindowOption.title
                    }}</span>
                  </div>
                  <rb-icon
                    class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                    :icon="'caret-down'"
                  />
                </div>
              </rb-select>
            </div>
          </div>
          <div class="u-display-flex u-flex-direction-column u-spacing-pt-m">
            <div class="u-font-size-5 u-font-weight-600 u-spacing-pb-s">
              Post view lookback window:
            </div>
            <div>
              <rb-select
                class="u-spacing-mr-s valueDropdown"
                :send-details="true"
                :on-close="postViewWindowOptionSelected"
                :options="postViewWindowOptions"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                >
                  <div
                    class="u-display-flex u-flex-direction-column u-spacing-p-s"
                  >
                    <span class="u-font-size-5">{{
                      selectedPostViewWindowOption.title
                    }}</span>
                  </div>
                  <rb-icon
                    class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                    :icon="'caret-down'"
                  />
                </div>
              </rb-select>
            </div>
          </div>
        </div>
        <div class="u-spacing-ml-xl u-width-50">
          <div class="u-display-flex u-flex-direction-column u-width-60">
            <div class="u-font-size-5 u-font-weight-600 u-spacing-pb-s">
              Post click product match:
            </div>
            <div>
              <rb-select
                class="u-spacing-mr-s valueDropdown"
                :send-details="true"
                :on-close="postClickProductOptionSelected"
                :options="config.postClickProductOptions"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                >
                  <div
                    class="u-display-flex u-flex-direction-column u-spacing-p-s"
                  >
                    <span class="u-font-size-5">{{
                      selectedPostClickProductOption.title
                    }}</span>
                  </div>
                  <rb-icon
                    class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                    :icon="'caret-down'"
                  />
                </div>
              </rb-select>
            </div>
          </div>
          <div
            v-show="!hideViewProduct"
            class="u-display-flex u-flex-direction-column u-spacing-pt-m u-width-60"
          >
            <div class="u-font-size-5 u-font-weight-600 u-spacing-pb-s">
              Post view product match:
            </div>
            <div>
              <rb-select
                class="u-spacing-mr-s valueDropdown"
                :send-details="true"
                :on-close="postViewProductOptionSelected"
                :options="postViewProductOptions"
              >
                <div
                  slot="trigger"
                  class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                >
                  <div
                    class="u-display-flex u-flex-direction-column u-spacing-p-s"
                  >
                    <span class="u-font-size-5">{{
                      (selectedPostViewProductOption &&
                        selectedPostViewProductOption.title) ||
                      'Same product (Criteo Default)'
                    }}</span>
                  </div>
                  <rb-icon
                    class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                    :icon="'caret-down'"
                  />
                </div>
              </rb-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Apply and Cancel buttons -->
    <div
      class="u-width-100 u-border-top u-border-bottom u-border-color-grey-xxx-light u-border-width-s u-spacing-pv-m u-spacing-ph-l u-display-flex"
    >
      <div
        v-if="applyButtonDisabled"
        v-tippy="{ placement: 'top', arrow: true }"
        :title="applyButtonDisabled ? tooltipErrorMessage : ''"
      >
        <rb-button
          text="Apply"
          type="filled"
          :click-fn="onApply"
          :disabled="true"
          class="u-spacing-mr-12"
          size="s"
        />
      </div>
      <rb-button
        v-else
        text="Apply"
        type="filled"
        :click-fn="onApply"
        :disabled="false"
        class="u-spacing-mr-12"
        size="s"
      />
      <rb-button
        text="Cancel"
        :click-fn="onClose"
        size="s"
      />
    </div>
  </div>
</template>

<script>
import config from '@/pages/campaign-management/retailers/criteo/campaigns/EditAttributionSettings/EditAttributionSettings.js';
import loader from '@/components/basic/loader.vue';
import { commons } from '@/components/ams/campaigns/commons.js';

export default {
  components: {
    loader
  },
  props: {
    parentRef: {
      type: Object,
      default: function () {
        return {};
      }
    },
    actionType: {
      type: String,
      default: 'targetOptimizationStrategyChange'
    },
    retailer: {
      type: String,
      default: 'target'
    },
    selections: {
      type: Array,
      default: () => {
        return [];
      }
    },
    widget: {
      type: String,
      default: 'lineItem'
    },
    primaryKey: {
      type: String,
      default: 'line_item_campaign_id'
    },
    apiVersion: {
      type: String,
      default: 'actionlog'
    }
  },
  data() {
    return {
      loader: false,
      config: null,
      errorMessage: null,
      selectedPostClickWindowOption: null,
      selectedPostClickProductOption: null,
      selectedPostViewProductOption: null,
      selectedPostViewWindowOption: null,
      updatedPostClickWindowOption: false,
      updatedPostClickProductOption: false,
      updatedPostViewProductOption: false,
      postViewWindowOptions: [],
      postViewProductOptions: [],
      updatedPostViewWindowOption: false,
      hideViewProduct: false,
      tooltipErrorMessage: '',
      showTooltip: true
    };
  },
  computed: {
    applyButtonDisabled() {
      if (
        this.selections?.length === 1 &&
        this.updatedPostViewProductOption === false &&
        this.updatedPostViewWindowOption === false &&
        this.updatedPostClickWindowOption === false &&
        this.updatedPostClickProductOption === false
      ) {
        this.tooltipErrorMessage = "You didn't changed any settings";
        return true;
      } else if (this.selections?.length > 1) {
        let result = false;
        result = this.selections?.some((item) => {
          return (
            item?.data?.click_window !==
              this.config.convertClickWindow(
                this.selectedPostClickWindowOption?.title
              ) ||
            item?.data?.view_window !==
              this.config.convertViewWindow(
                this.selectedPostViewWindowOption?.title
              ) ||
            item?.data?.click_scope !==
              this.config.convertProductClickMatch(
                this.selectedPostClickProductOption?.title
              ) ||
            item?.data?.view_scope !==
              this.config.convertProductViewMatch(
                this.selectedPostViewProductOption?.title
              )
          );
        });
        if (result) {
          this.tooltipErrorMessage = '';
          return false;
        } else if (!result) {
          this.tooltipErrorMessage = "You didn't changed any settings";
          return true;
        }
      } else return false;
    }
  },
  watch: {
    selections(newVal) {
      if (newVal?.length === 0) {
        this.$emit('closePanelMethod');
      }
      if (newVal?.length > 1) {
        this.updateStateOnMultiSelection(newVal);
      } else if (newVal?.length === 1) {
        this.updateStateOnSingleSelection();
      }
      this.hideViewProduct =
        this.selectedPostViewWindowOption.title ===
        'No Post View (CIQ Default)';
    },
    selectedPostClickWindowOption(val) {
      if (
        this.config.convertClickWindow(
          this.selections[0]?.data?.click_window
        ) !== val.title
      ) {
        this.updatedPostClickWindowOption = true;
      } else {
        this.updatedPostClickWindowOption = false;
      }

      Object.keys(this.config.tempPostViewWindowOptions)?.forEach((index) => {
        let currentPostViewWindowOption = this.selectedPostViewWindowOption;
        if (+index === val.value) {
          this.postViewWindowOptions = [];
          this.postViewWindowOptions =
            this.config.tempPostViewWindowOptions[index];
          const currentOptionExists = this.postViewWindowOptions?.some(
            (item) => {
              return item?.title === currentPostViewWindowOption?.title;
            }
          );

          if (!currentOptionExists) {
            this.selectedPostViewWindowOption =
              this.config.tempPostViewWindowOptions[index][0];
          }
        }
      });
    },
    selectedPostViewWindowOption(val) {
      if (
        this.config.convertViewWindow(this.selections[0]?.data?.view_window) !==
        val.title
      ) {
        this.updatedPostViewWindowOption = true;
      } else {
        this.updatedPostViewWindowOption = false;
      }
      if (
        this.selectedPostViewWindowOption.title === 'No Post View (CIQ Default)'
      ) {
        this.hideViewProduct = true;
      } else this.hideViewProduct = false;
    },
    selectedPostViewProductOption(val) {
      if (
        this.config.convertProductViewMatch(
          this.selections[0]?.data?.view_scope
        ) !== val.title
      ) {
        this.updatedPostViewProductOption = true;
      } else {
        this.updatedPostViewProductOption = false;
      }
    },
    selectedPostClickProductOption(newVal) {
      if (
        this.config.convertProductClickMatch(
          this.selections[0]?.data?.click_scope
        ) !== newVal.title
      ) {
        this.updatedPostClickProductOption = true;
      } else {
        this.updatedPostClickProductOption = false;
      }
      Object.keys(this.config.tempPostViewProductOptions)?.forEach((index) => {
        if (+index === newVal.value) {
          this.postViewProductOptions = [];
          this.postViewProductOptions =
            this.config.tempPostViewProductOptions[index];
          this.selectedPostViewProductOption =
            this.config.tempPostViewProductOptions[index][0];
        }
      });
    }
  },
  created() {
    this.config = config[this.retailer];
    let postClickProductIndex = null;
    this.config.postClickProductOptions?.forEach((item) => {
      if (
        item.title ===
        this.config.convertProductClickMatch(
          this.selections[0]?.data?.click_scope
        )
      ) {
        postClickProductIndex = item.value;
      }
    });
    let postViewWindowIndex = null;
    this.config.postViewWindowOptions?.forEach((item) => {
      if (
        item.title ===
        this.config.convertViewWindow(this.selections[0]?.data?.view_window)
      ) {
        postViewWindowIndex = item.value;
      }
    });
    Object.keys(this.config.tempPostViewProductOptions)?.forEach((index) => {
      if (+index === +postClickProductIndex) {
        this.postViewProductOptions = [];
        this.postViewProductOptions =
          this.config.tempPostViewProductOptions[index];
        this.selectedPostViewProductOption =
          this.config.tempPostViewProductOptions[index][0];
      }
    });
    Object.keys(this.config.tempPostViewWindowOptions)?.forEach((index) => {
      if (+index === postViewWindowIndex) {
        this.postViewWindowOptions = [];
        this.postViewWindowOptions =
          this.config.tempPostViewWindowOptions[index];
        this.selectedPostViewWindowOption =
          this.config.tempPostViewWindowOptions[index][0];
      }
    });
    if (this.selections?.length > 1) {
      const allMetricsAreNotEqual = this.selections?.some((item) => {
        return (
          item?.data.click_window !== this.selections[0]?.data?.click_window ||
          item?.data.view_window !== this.selections[0]?.data?.view_window ||
          item?.data.click_scope !== this.selections[0]?.data?.click_scope ||
          item?.data.view_scope !== this.selections[0]?.data?.view_scope
        );
      });

      if (allMetricsAreNotEqual) {
        this.selectedPostClickWindowOption =
          this.config.postClickWindowOptions[1];
        this.selectedPostViewWindowOption =
          this.config.postViewWindowOptions[0];
        this.selectedPostClickProductOption =
          this.config.postClickProductOptions[1];
        this.selectedPostViewProductOption =
          this.selectedPostViewWindowOption?.value === 0
            ? null
            : this.postViewProductOptions[0];
      } else {
        this.selectedPostClickWindowOption = {
          title: this.config.convertClickWindow(
            this.selections[0]?.data?.click_window
          )
        };
        this.selectedPostViewWindowOption = {
          title: this.config.convertViewWindow(
            this.selections[0]?.data?.view_window
          )
        };
        this.selectedPostClickProductOption = {
          title: this.config.convertProductClickMatch(
            this.selections[0]?.data?.click_scope
          )
        };
        this.selectedPostViewProductOption =
          this.selectedPostViewWindowOption?.title ===
          'No Post View (CIQ Default)'
            ? null
            : {
                title: this.config.convertProductViewMatch(
                  this.selections[0]?.data?.view_scope
                )
              };
        if (
          this.selectedPostClickWindowOption?.title ===
          '30 Days (Criteo Default)'
        ) {
          this.postViewWindowOptions = this.config.tempPostViewWindowOptions[2];
        }
        if (
          this.selectedPostClickWindowOption?.title === '14 Days (CIQ Default)'
        ) {
          this.postViewWindowOptions = this.config.tempPostViewWindowOptions[1];
        }
        if (this.selectedPostClickWindowOption?.title === '7 Days') {
          this.postViewWindowOptions = this.config.tempPostViewWindowOptions[0];
        }
      }
    } else if (this.selections?.length === 1) {
      this.selectedPostClickWindowOption = {
        title: this.config.convertClickWindow(
          this.selections[0]?.data?.click_window
        )
      };
      this.selectedPostViewWindowOption = {
        title: this.config.convertViewWindow(
          this.selections[0]?.data?.view_window
        )
      };
      this.selectedPostClickProductOption = {
        title: this.config.convertProductClickMatch(
          this.selections[0]?.data?.click_scope
        )
      };
      this.selectedPostViewProductOption =
        this.selectedPostViewWindowOption.title === 'No Post View (CIQ Default)'
          ? null
          : {
              title: this.config.convertProductViewMatch(
                this.selections[0]?.data?.view_scope
              )
            };

      if (
        this.selectedPostClickWindowOption.title === '30 Days (Criteo Default)'
      ) {
        this.postViewWindowOptions = this.config.tempPostViewWindowOptions[2];
      }
      if (
        this.selectedPostClickWindowOption.title === '14 Days (CIQ Default)'
      ) {
        this.postViewWindowOptions = this.config.tempPostViewWindowOptions[1];
      }
      if (this.selectedPostClickWindowOption.title === '7 Days') {
        this.postViewWindowOptions = this.config.tempPostViewWindowOptions[0];
      }
    }
    if (
      this.selectedPostViewWindowOption.title === 'No Post View (CIQ Default)'
    ) {
      this.hideViewProduct = true;
    } else this.hideViewProduct = false;
  },
  methods: {
    postClickWindowOptionSelected(context, selectedPostClickWindowOption) {
      if (!selectedPostClickWindowOption?.length) {
        return;
      }
      this.selectedPostClickWindowOption = selectedPostClickWindowOption[0];
    },
    postClickProductOptionSelected(context, selectedPostClickProductOption) {
      if (!selectedPostClickProductOption?.length) return;
      this.selectedPostClickProductOption = selectedPostClickProductOption[0];
    },
    postViewWindowOptionSelected(context, selectedPostViewWindowOption) {
      if (!selectedPostViewWindowOption?.length) return;
      this.selectedPostViewWindowOption = selectedPostViewWindowOption[0];
      if (this.postViewProductOptions?.length)
        this.selectedPostViewProductOption =
          this.postViewProductOptions?.length &&
          this.selectedPostViewWindowOption.title !==
            'No Post View (CIQ Default)'
            ? this.postViewProductOptions[0]
            : null;
    },
    postViewProductOptionSelected(context, selectedPostViewProductOption) {
      if (!selectedPostViewProductOption?.length) return;
      this.selectedPostViewProductOption = selectedPostViewProductOption[0];
    },

    onClose() {
      this.$emit('closePanelMethod');
    },
    onApply() {
      let payload = [];
      payload = [...this.createPayload()];
      this.callAPI(payload);
    },
    callAPI(payload) {
      const that = this;
      const othersObj = {
        loader: that.loader,
        successMessage: 'Campaign Attribution settings changed successfully.',
        errorMessage: 'Something went wrong.',
        successFn: () => {
          that.parentRef.switchBlueBar();
        },
        errorFn: () => {},
        finallyFn: () => {
          that.loader = false;
          this.$emit('afterAPIActionToCall');
        }
      };
      this.loader = true;
      commons.bidChangeApiAction.call(
        this,
        {},
        payload,
        othersObj,
        this.apiVersion
      );
    },
    createPayload() {
      let newViewProductScope = null;
      if (
        this.config.convertViewWindow(
          this.selectedPostViewWindowOption.title
        ) === 'none'
      ) {
        newViewProductScope = this.config.convertProductViewMatch(
          'Same product (Criteo Default)'
        );
      } else {
        newViewProductScope = this.config.convertProductViewMatch(
          this.selectedPostViewProductOption.title
        );
      }
      newViewProductScope = newViewProductScope || 'sameSku';
      const payload = [];
      const viewAttributionScope = newViewProductScope
        ? this.config.convertProductViewMatch(newViewProductScope)
        : 'Same product (Criteo Default)';
      this.selections.forEach((item) => {
        const payloadObject = {
          widget: this.widget,
          actionType: this.actionType,
          actionSource: {
            pageUrl: window.location.href
          },
          actionPayload: {
            actionType: this.actionType,
            campaign_id: item?.data?.campaign_id,
            clickAttributionWindow: this.config.convertClickWindow(
              this.selectedPostClickWindowOption.title
            ),
            viewAttributionWindow: this.config.convertViewWindow(
              this.selectedPostViewWindowOption.title
            ),
            clickAttributionScope: this.config.convertProductClickMatch(
              this.selectedPostClickProductOption.title
            ),
            viewAttributionScope: newViewProductScope
          },
          viewPayload: {
            actionType: this.actionType,
            campaign_id: item?.data?.campaign_id,
            clickAttributionWindow:
              this.selectedPostClickWindowOption.title !==
              this.config.convertClickWindow(
                this.selections[0]?.data?.click_window
              )
                ? this.selectedPostClickWindowOption.title
                : null,
            viewAttributionWindow:
              this.selectedPostViewWindowOption.title !==
              this.config.convertViewWindow(
                this.selections[0]?.data?.view_window
              )
                ? this.selectedPostViewWindowOption.title
                : null,
            clickAttributionScope:
              this.selectedPostClickProductOption.title !==
              this.config.convertProductClickMatch(
                this.selections[0]?.data?.click_scope
              )
                ? this.selectedPostClickProductOption.title
                : null,
            viewAttributionScope:
              this.config.convertProductViewMatch(newViewProductScope) !==
              this.config.convertProductViewMatch(
                this.selections[0]?.data?.view_scope
              )
                ? viewAttributionScope
                : null,
            old_clickAttributionWindow:
              this.selectedPostClickWindowOption.title !==
              this.config.convertClickWindow(
                this.selections[0]?.data?.click_window
              )
                ? this.config.convertClickWindow(
                    this.selections[0]?.data?.click_window
                  )
                : null,
            old_viewAttributionWindow:
              this.selectedPostViewWindowOption.title !==
              this.config.convertViewWindow(
                this.selections[0]?.data?.view_window
              )
                ? this.config.convertViewWindow(
                    this.selections[0]?.data?.view_window
                  )
                : null,
            old_clickAttributionScope:
              this.selectedPostClickProductOption.title !==
              this.config.convertProductClickMatch(
                this.selections[0]?.data?.click_scope
              )
                ? this.config.convertProductClickMatch(
                    this.selections[0]?.data?.click_scope
                  )
                : null,
            old_viewAttributionScope:
              this.config.convertProductViewMatch(newViewProductScope) !==
              this.config.convertProductViewMatch(
                this.selections[0]?.data?.view_scope
              )
                ? this.config.convertProductViewMatch(
                    this.selections[0]?.data?.view_scope
                  )
                : null
          },
          primaryKey: item?.data?.[this.primaryKey]
        };
        payload.push(payloadObject);
      });
      return payload;
    },
    updateStateOnSingleSelection() {
      this.selectedPostClickWindowOption = {
        title: this.config.convertClickWindow(
          this.selections[0]?.data?.click_window
        )
      };
      this.selectedPostViewWindowOption = {
        title: this.config.convertViewWindow(
          this.selections[0]?.data?.view_window
        )
      };
      this.selectedPostClickProductOption = {
        title: this.config.convertProductClickMatch(
          this.selections[0]?.data?.click_scope
        )
      };
      let postClickProductIndex = null;
      this.config.postClickProductOptions?.forEach((item) => {
        if (
          item.title ===
          this.config.convertProductClickMatch(
            this.selections[0]?.data?.click_scope
          )
        ) {
          postClickProductIndex = item.value;
        }
      });
      let currentPostViewProductOption = this.selectedPostViewProductOption;
      Object.keys(this.config.tempPostViewProductOptions)?.forEach((index) => {
        if (+index === postClickProductIndex) {
          this.postViewProductOptions = [];
          this.postViewProductOptions =
            this.config.tempPostViewProductOptions[index];

          const currentOptionExists = this.postViewProductOptions?.some(
            (item) => {
              return item?.title === currentPostViewProductOption?.title;
            }
          );

          if (!currentOptionExists) {
            this.selectedPostViewProductOption =
              this.config.tempPostViewProductOptions[index][0];
          }
        }
      });
    },
    updateStateOnMultiSelection(newVal) {
      const allMetricsAreNotEqual = newVal?.some((item) => {
        return (
          item?.data.click_window !== newVal[0]?.data?.click_window ||
          item?.data.view_window !== newVal[0]?.data?.view_window ||
          item?.data.click_scope !== newVal[0]?.data?.click_scope ||
          item?.data.view_scope !== newVal[0]?.data?.view_scope
        );
      });
      if (allMetricsAreNotEqual) {
        this.selectedPostClickWindowOption =
          this.config.postClickWindowOptions[1];
        this.selectedPostViewWindowOption =
          this.config.postViewWindowOptions[0];
        this.selectedPostClickProductOption =
          this.config.postClickProductOptions[1];
        this.selectedPostViewProductOption =
          this.selectedPostViewWindowOption?.value === 0
            ? null
            : this.postViewProductOptions[0];
      } else {
        this.selectedPostClickWindowOption = {
          title: this.config.convertClickWindow(newVal[0]?.data?.click_window)
        };
        this.selectedPostViewWindowOption = {
          title: this.config.convertViewWindow(newVal[0]?.data?.view_window)
        };
        this.selectedPostClickProductOption = {
          title: this.config.convertProductClickMatch(
            newVal[0]?.data?.click_scope
          )
        };
        this.selectedPostViewProductOption =
          this.selectedPostViewWindowOption?.title ===
          'No Post View (CIQ Default)'
            ? null
            : {
                title: this.config.convertProductViewMatch(
                  newVal[0]?.data?.view_scope
                )
              };
        if (
          this.selectedPostClickWindowOption?.title ===
          '30 Days (Criteo Default)'
        ) {
          this.postViewWindowOptions = this.config.tempPostViewWindowOptions[2];
        }
        if (
          this.selectedPostClickWindowOption?.title === '14 Days (CIQ Default)'
        ) {
          this.postViewWindowOptions = this.config.tempPostViewWindowOptions[1];
        }
        if (this.selectedPostClickWindowOption?.title === '7 Days') {
          this.postViewWindowOptions = this.config.tempPostViewWindowOptions[0];
        }
      }
    }
  }
};
</script>

<style lang="css" scoped>
.line-item-name-input {
  width: 30%;
}
</style>
