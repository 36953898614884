// add changes to comments
import Vue from 'vue';
import { campaignStatusComponent } from '@/components/globals/dataTable/tableComponentsWrapper/custom-table-cells/campaignStatus';
import { skuData } from '@/components/globals/dataTable/tableComponentsWrapper/custom-table-cells/constants';
import { skuCellDisplay } from '@/components/globals/dataTable/tableComponentsWrapper/custom-table-cells/skusCell';
import { get } from 'lodash';
import { legendCell } from '@/pages/campaign-management/retailers/walmart/campaigns/legends-cell.js';
import samsclub_apiOverrideDictionary from '@/pages/campaign-management/retailers/samsclub_api/samsclub_apiOverrideDictionary.js';
import addWalmartSpecificMetricsToDictionary from '@/pages/campaign-management/retailers/walmart/additionalMetrics.js';
import addSamsClubAPISpecificMetricsToDictionary from '@/pages/campaign-management/retailers/samsclub_api/additionalMetrics.js';
import { criteoRetailers } from '@/router/utils';

const defaultCampaignSatusMapping = {
  liveValue: 'enabled',
  pausedValue: 'paused',
  disabledValue: 'disabled'
};

const campaignStatusCallback = (
  obj,
  currentDictionaryObject,
  statusMapping = defaultCampaignSatusMapping
) => {
  delete obj.cellRenderer;
  obj.cellRendererFramework = campaignStatusComponent(statusMapping);
  obj.cellRendererParams.showToolTip = currentDictionaryObject.showToolTip;
  obj.headerComponentParams.enableSorting = false;
  obj.minWidth = 100;
  return obj;
};

export const deleteMetricsForPage = (dictionary, page, metrics) => {
  metrics.forEach((metric) => {
    delete dictionary?.[metric]?.pages?.[page];
  });
};

export const walmartSpecificMetrics = [
  'attributed_orders',
  'advertised_sku_units',
  'other_sku_units',
  'advertised_sku_sales',
  'other_sku_sales',
  'conversion_rate_14d_orders_based',
  'ntb_orders',
  'percentageordersnewtobrand14d',
  'ntb_revenue',
  'percentagesalesnewtobrand14d',
  'ntb_units',
  'percentageunitsnewtobrand14d',
  'conversion_rate_ntb'
];

const samsclubAPISpecificMetrics = [
  'attributed_orders_14d',
  'attributed_online_sales_14d',
  'ntb_orders_14d',
  'ntb_sales_14d',
  'ntb_units_14d',
  'offline_attributed_sales_14d',
  'percentage_new_to_brand_orders',
  'percentage_new_to_brand_units',
  'percentage_new_to_brand_sales',
  'conversion_rate_orders_based',
  'conversion_rate_ntb_units',
  'conversion_rate_ntb_orders',
  'percentage_offline_sales',
  'percentage_online_sales'
];

export const retailerSpecificMetrics = {
  walmart: walmartSpecificMetrics,
  samsclub_api: samsclubAPISpecificMetrics
};

const notLevel0Condition = (params) => {
  return (
    params.data['ag-grid-level'] !== undefined &&
    params.data['ag-grid-level'] !== null &&
    params.data['ag-grid-level'] !== 0
  );
};

const hideCellCondition = (params) => {
  return notLevel0Condition(params) && 'u-visibility-hidden';
};

const legendsCell = (obj, vueRef, currentDictionaryObject, dataProps) => {
  delete obj.cellRenderer;
  obj.cellRendererFramework = legendCell();
  return obj;
};

const redirectComponent = (obj) => {
  delete obj.cellRenderer;
  obj.cellRendererFramework = Vue.extend({
    template: `<redirect :params="params" />`
  });
  return obj;
};

const conversionRateDictionaryObject = {
  keyOrder: 34,
  table: {
    show: true
  },
  chart: {
    shown: false
  },
  toolTipText:
    'Measure of how many clicks converted into orders calculated as orders divided by clicks'
};

const samsclub_apiConversionRateDictionaryObject = {
  ...conversionRateDictionaryObject,
  title: 'Conversion Rate 14D (Units Based)',
  toolTipText:
    'Measure of how many clicks converted into orders calculated as orders using 14 day attribution method divided by clicks'
};

const walmartConversionRateDictionaryObject = {
  ...conversionRateDictionaryObject,
  title: 'Conversion Rate 14D (Units Based)',
  toolTipText:
    'Measure of how many clicks converted into units sold calculated as units sold using 14 day attribution method divided by clicks.'
};

const uniqueVisitorsDictionaryObject = {
  keyOrder: 35,
  table: {
    show: false
  },
  chart: {
    shown: false,
    selected: false
  },
  toolTipText:
    'Number of unique visitors an impression is attributed to. The impressions are attributed within a period of one week. If a shopper visits the site on Monday, they will not be counted as a unique visitor on Wednesday but they will be counted as a unique visitor on the next Monday.'
};

const impressionFrequencyDictionaryObject = {
  keyOrder: 36,
  table: {
    show: false
  },
  chart: {
    shown: false,
    selected: false
  },
  toolTipText:
    'Average number of times an impression is attributed to a user. The impressions are attributed within a period of one week. If a shopper visits the site on Monday, they will not be counted as a unique visitor on Wednesday but they will be counted as a unique visitor on the next Monday.'
};

export const commonChartMetrics = {
  attributed_direct_view_sales: {
    title: 'Attributed Direct View Sales 14D',
    toolTipText:
      'Attributed sales revenue based on the view of the advertised item',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    pages: {
      keywords: {
        keyOrder: 31,
        table: {
          show: true
        },
        chart: {}
      },
      campaigns: {
        keyOrder: 33,
        table: {
          show: true
        },
        chart: {}
      },
      ad_skus: {
        keyOrder: 29,
        table: {
          show: true
        },
        chart: {}
      },
      search_terms: {
        keyOrder: 26,
        table: {
          show: true
        },
        chart: {}
      }
    }
  },
  total_attributed_direct_click_sales: {
    title: 'Attributed Direct Click Sales 14D',
    toolTipText:
      'Attributed sales revenue based on the direct click of the advertised item',
    pages: {
      campaigns: {
        keyOrder: 35,
        table: {
          show: true
        },
        chart: {}
      },
      ad_skus: {
        keyOrder: 31,
        table: {
          show: true
        },
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'CURRENCY'
  },
  total_attributed_related_click_sales: {
    title: 'Attributed Related Click Sales 14D',
    toolTipText:
      'Attributed sales revenue based on the direct click of the same brand and category of the advertised item and fulfillment by shipping',
    type: 'NUMERIC',
    unit: 'CURRENCY',
    pages: {
      campaigns: {
        keyOrder: 38,
        table: {
          show: true
        }
      },
      ad_skus: {
        keyOrder: 34,
        table: {
          show: true
        }
      },
      search_term: {
        keyOrder: 29,
        table: {
          show: true
        }
      }
    }
  },
  total_attributed_brand_click_sales: {
    title: 'Attributed Brand Click Sales 14D',
    toolTipText:
      'Attributed sales based on the direct click of the same brand as the advertised item',
    pages: {
      campaigns: {
        keyOrder: 41,
        table: {
          show: true
        },
        chart: {}
      },
      ad_skus: {
        keyOrder: 37,
        table: {
          show: true
        },
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'CURRENCY'
  },
  attributed_brand_click_sales_shipping: {
    title: 'Attributed Brand Click Sales Shipping 14D',
    toolTipText:
      'Attributed sales based on the direct click of the same brand as the advertised item fulfillment by shipping',
    pages: {
      campaigns: {
        keyOrder: 42,
        table: {
          show: true
        },
        chart: {}
      },
      ad_skus: {
        keyOrder: 38,
        table: {
          show: true
        },
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'CURRENCY'
  },
  attributed_brand_click_sales_curbside_pickup_and_delivery: {
    title: 'Attributed Brand Click Sales Curbside Pickup And Delivery 14D',
    pages: {
      campaigns: {
        keyOrder: 43,
        table: {
          show: true
        },
        chart: {},
        toolTipText:
          'Attributed sales based on the direct click of the same brand as the advertised item and fulfillment by curbside pickup and delivery'
      },
      ad_skus: {
        keyOrder: 39,
        table: {
          show: true
        },
        chart: {},
        toolTipText:
          'Attributed sales based on the direct click of the same brand as the advertised item and fulfillment by curbside pickup and delivery'
      }
    },
    type: 'NUMERIC',
    unit: 'CURRENCY'
  },
  attributed_related_click_sales_curbside_pickup_and_delivery: {
    title: 'Attributed Related Click Sales Curbside Pickup and Delivery 14D',
    toolTipText:
      'Attributed sales revenue based on the direct click of the same brand and category of the advertised item and fulfillment by curbside pickup and delivery',
    pages: {
      campaigns: {
        keyOrder: 40,
        table: {
          show: true
        },
        chart: {}
      },
      ad_skus: {
        keyOrder: 36,
        table: {
          show: true
        },
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'CURRENCY'
  },
  attributed_related_click_sales_shipping: {
    title: 'Attributed Related click Sales Shipping 14D',
    toolTipText:
      'Attributed sales revenue based on the direct click of the same brand and category of the advertised item and fulfillment by curbside pickup and delivery',
    pages: {
      campaigns: {
        keyOrder: 39,
        table: {
          show: true
        },
        chart: {}
      },
      ad_skus: {
        keyOrder: 35,
        table: {
          show: true
        },
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'CURRENCY'
  },
  attributed_direct_click_sales_curbside_pickup_and_delivery: {
    title: 'Attributed Direct Click Sales Curbside Pickup and Delivery 14D',
    toolTipText:
      'Attributed sales revenue based on the direct click of the advertised item and fulfillment by curbside pickup and delivery',
    pages: {
      campaigns: {
        keyOrder: 37,
        table: {
          show: true
        },
        chart: {}
      },
      ad_skus: {
        keyOrder: 33,
        table: {
          show: true
        },
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'CURRENCY'
  },
  attributed_direct_click_sales_shipping: {
    title: 'Attributed Direct Click Sales Shipping 14D',
    toolTipText:
      'Attributed sales revenue based on the direct click of the advertised item and fulfillment by shipping.',
    pages: {
      campaigns: {
        keyOrder: 36,
        table: {
          show: true
        },
        chart: {}
      },
      ad_skus: {
        keyOrder: 32,
        table: {
          show: true
        },
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'CURRENCY'
  },
  total_attributed_sales_shipping: {
    title: 'Total Attributed Sales Shipping 14D',
    pages: {
      campaigns: {
        keyOrder: 31,
        table: {
          show: true
        },
        toolTipText:
          'Total sales generated on the campaign in the selected time period using 14 days attribution method and fulfillment by shipping.',
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'CURRENCY'
  },
  total_attributed_sales_curbside_pickup_and_delivery: {
    title: 'Total Attributed Sales Curbside Pickup and Delivery 14D',
    pages: {
      campaigns: {
        keyOrder: 32,
        table: {
          show: true
        },
        toolTipText:
          'Total sales generated on the campaign in the selected time period using 14 days attribution method and fulfillment by Curbside pickup and delivery.',
        chart: {}
      },
      ad_skus: {
        keyOrder: 28,
        table: {
          show: true
        },
        toolTipText:
          'Total sales generated on the SKU in the selected time period using 14 days attribution method and fulfillment by Curbside pickup and delivery.',
        chart: {}
      }
    },
    type: 'NUMERIC',
    unit: 'CURRENCY'
  }
};

const allMetrics = (retailer) => {
  const isWalmart = retailer === 'walmart';
  const dictionary = {
    ad_spend: {
      title: 'Ad Spend',
      pages: {
        keywords: {
          keyOrder: 13,
          table: {
            show: true
          },
          chart: {
            shown: true,
            selected: true
          },
          toolTipText: 'Total spend on the keyword in the selected time period',
          sort: 'desc'
        },
        campaigns: {
          keyOrder: 8,
          table: {
            show: true
          },
          chart: {
            shown: true,
            selected: true
          },
          toolTipText: 'Total spend on the campaign in the selected time period'
        },
        target_campaigns: {
          keyOrder: 23,
          table: {
            show: false
          },
          chart: {
            shown: true,
            selected: true
          },
          toolTipText: 'Total spend including the platform fees.'
        },
        target_line_items: {
          keyOrder: 23,
          table: {
            show: true
          },
          chart: {
            shown: true,
            selected: true
          },
          toolTipText: 'Total spend including the platform fees.'
        },
        target_ad_skus: {
          keyOrder: 20,
          table: {
            show: true
          },
          chart: {
            shown: true,
            selected: true
          },
          toolTipText: 'Total spend including the platform fees.'
        },
        target_search_terms: {
          keyOrder: 13,
          table: {
            show: true
          },
          chart: {
            shown: true,
            selected: true
          },
          toolTipText: 'Total spend including the platform fees.'
        },
        target_keywords: {
          keyOrder: 13,
          table: {
            show: true
          },
          chart: {
            shown: true,
            selected: true
          },
          toolTipText: 'Total spend including the platform fees.'
        },
        ad_skus: {
          keyOrder: 14,
          table: {
            show: true
          },
          chart: {
            shown: true,
            selected: true
          },
          toolTipText: 'Total spend on the SKU in the selected time period',
          sort: 'desc'
        },
        search_terms: {
          keyOrder: 13,
          table: {
            show: true
          },
          chart: {
            shown: true,
            selected: true
          },
          toolTipText:
            'Total spend on the search term in the selected time period',
          sort: 'desc'
        }
      },
      type: 'NUMERIC',
      unit: 'CURRENCY'
    },
    total_attributed_sales: {
      title: 'Total Attributed Sales 14D',
      pages: {
        keywords: {
          keyOrder: 14,
          table: {
            show: true
          },
          chart: {
            shown: true,
            selected: true
          },
          toolTipText:
            'Total sales generated on the keyword in the selected time period using 14 days attribution method'
        },
        campaigns: {
          keyOrder: 9,
          table: {
            show: true
          },
          chart: {
            shown: true,
            selected: true
          },
          toolTipText:
            'Total sales generated on the campaign in the selected time period using 14 days attribution method'
        },
        ad_skus: {
          keyOrder: 15,
          table: {
            show: true
          },
          chart: {
            shown: true,
            selected: true
          },
          toolTipText:
            'Total sales generated on the SKU in the selected time period using 14 days attribution method'
        },
        search_terms: {
          keyOrder: 14,
          table: {
            show: true
          },
          chart: {
            shown: true,
            selected: true
          },
          toolTipText:
            'Total sales generated from the search term in the selected time period using 14 days attribution method'
        },
        target_line_items: {
          keyOrder: 30,
          chart: {},
          table: {
            show: true
          },
          toolTipText:
            'Total sales revenue attributed to a click or impression; inclusive of assisted sales within a 14days lookback window for post-click and 1-day lookback window for post view.'
        },
        target_ad_skus: {
          keyOrder: 27,
          chart: {},
          table: {
            show: true
          },
          toolTipText:
            'Total sales revenue attributed to a click or impression; inclusive of assisted sales within a 14days lookback window for post-click and 1-day lookback window for post view.'
        },
        target_search_terms: {
          keyOrder: 20,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Total sales revenue attributed to a click or impression; inclusive of assisted sales within a 14days lookback window for post-click and 1-day lookback window for post view.'
        },
        target_keywords: {
          keyOrder: 20,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Total sales revenue attributed to a click or impression; inclusive of assisted sales within a 14days lookback window for post-click and 1-day lookback window for post view.'
        }
      },
      type: 'NUMERIC',
      unit: 'CURRENCY'
    },
    total_units_sold: {
      title: 'Total Units Sold 14D',
      pages: {
        target_line_items: {
          keyOrder: 31,
          chart: {},
          table: {
            show: true
          },
          toolTipText:
            'Total units sold attributed to a click or impression; inclusive of assisted units sold within a 14days lookback window for post-click and 1-day lookback window for post view.'
        },
        target_ad_skus: {
          keyOrder: 28,
          chart: {},
          table: {
            show: true
          },
          toolTipText:
            'Total units sold attributed to a click or impression; inclusive of assisted units sold within a 14days lookback window for post-click and 1-day lookback window for post view.'
        },
        target_search_terms: {
          keyOrder: 21,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Total units sold attributed to a click or impression; inclusive of assisted units sold within a 14days lookback window for post-click and 1-day lookback window for post view.'
        },
        target_keywords: {
          keyOrder: 21,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Total units sold attributed to a click or impression; inclusive of assisted units sold within a 14days lookback window for post-click and 1-day lookback window for post view.'
        }
      },
      type: 'NUMERIC'
    },
    attributed_sales: {
      title: 'Attributed Sales 14D',
      pages: {
        target_campaigns: {
          keyOrder: 24,
          table: {
            show: true
          },
          chart: {
            shown: true,
            selected: true
          },
          toolTipText:
            'Sales revenue attributed to a click or impression; exclusive of assisted sales within a 14days lookback window for post-click and no lookback for post view.'
        },
        target_line_items: {
          keyOrder: 27,
          chart: {
            shown: true,
            selected: true
          },
          toolTipText:
            'Sales revenue attributed to a click or impression; exclusive of assisted sales within a 14days lookback window for post-click and 1-day lookback window for post view.'
        },
        target_ad_skus: {
          keyOrder: 24,
          chart: {},
          toolTipText:
            'Sales revenue attributed to a click or impression; exclusive of assisted sales within a 14days lookback window for post-click and 1-day lookback window for post view.'
        },
        target_search_terms: {
          keyOrder: 17,
          chart: {
            shown: true,
            selected: true
          },
          toolTipText:
            'Sales revenue attributed to a click or impression; exclusive of assisted sales within a 14days lookback window for post-click and 1-day lookback window for post view.'
        },
        target_keywords: {
          keyOrder: 17,
          chart: {
            shown: true,
            selected: true
          },
          toolTipText:
            'Sales revenue attributed to a click or impression; exclusive of assisted sales within a 14days lookback window for post-click and 1-day lookback window for post view.'
        }
      },
      type: 'NUMERIC',
      unit: 'CURRENCY'
    },
    average_cpc: {
      title: 'Average CPC',
      pages: {
        target_campaigns: {
          keyOrder: 25,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Average cost-per-click (Avg. CPC) is the average price paid for clicks of the Criteo Ads. It is a calculation of the total spend (including platform fees) divided by the total number of clicks.'
        },
        target_line_items: {
          keyOrder: 23,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Average cost-per-click (Avg. CPC) is the average price paid for clicks of the Criteo Ads. It is a calculation of the total spend (including platform fees) divided by the total number of clicks.'
        },
        target_ad_skus: {
          keyOrder: 21,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Average cost-per-click (Avg. CPC) is the average price paid for clicks of the Criteo Ads. It is a calculation of the total spend (including platform fees) divided by the total number of clicks.'
        },
        target_search_terms: {
          keyOrder: 14,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Average cost-per-click (Avg. CPC) is the average price paid for clicks of the Criteo Ads. It is a calculation of the total spend (including platform fees) divided by the total number of clicks.'
        },
        target_keywords: {
          keyOrder: 14,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Average cost-per-click (Avg. CPC) is the average price paid for clicks of the Criteo Ads. It is a calculation of the total spend (including platform fees) divided by the total number of clicks.'
        }
      },
      type: 'NUMERIC',
      unit: 'CURRENCY'
    },
    roas: {
      title: 'ROAS 14D',
      pages: {
        target_campaigns: {
          keyOrder: 25,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Paid sales generated for each dollar of ad spend for the selected time period calculated as Attributed Sales 14D divided by ad spend'
        },
        target_line_items: {
          keyOrder: 33,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Paid sales generated for each dollar of ad spend for the selected time period calculated as Attributed Sales 14D divided by ad spend'
        },
        target_ad_skus: {
          keyOrder: 30,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Paid sales generated for each dollar of ad spend for the selected time period calculated as Attributed Sales 14D divided by ad spend'
        },
        target_search_terms: {
          keyOrder: 23,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Paid sales generated for each dollar of ad spend for the selected time period calculated as Attributed Sales 14D divided by ad spend'
        },
        target_keywords: {
          keyOrder: 23,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Paid sales generated for each dollar of ad spend for the selected time period calculated as Attributed Sales 14D divided by ad spend'
        }
      },
      type: 'NUMERIC',
      unit: 'CURRENCY'
    },
    cpc: {
      title: 'Cost Per Click',
      toolTipText:
        'Average cost per click in the selected time period calculated as spend divided by clicks',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        keywords: {
          keyOrder: 21,
          table: {
            show: true
          },
          chart: {
            shown: true
          }
        },
        campaigns: {
          keyOrder: 15,
          table: {
            show: true
          },
          chart: {
            shown: true
          }
        },
        ad_skus: {
          keyOrder: 18,
          table: {
            show: true
          },
          chart: {
            shown: true
          }
        },
        search_terms: {
          keyOrder: 19,
          table: {
            show: true
          },
          chart: {
            shown: true
          }
        }
      }
    },
    cpa: {
      title: 'CPA',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        campaigns: {
          keyOrder: 26,
          table: {
            show: true
          },
          chart: {}
        }
      },
      toolTipText:
        'Cost Per Acquisition is the cost incurred to sell one unit from the campaign in the selected time period using 14 days attribution method calculated as Total spend divided by units sold.'
    },
    roas_14d: {
      title: 'ROAS 14D',
      toolTipText:
        'Paid sales generated for each dollar of ad spend for the selected time period calculated as 14 day attributed sales divided by ad spend',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        keywords: {
          keyOrder: 15,
          table: {
            show: true
          },
          chart: {
            shown: true
          }
        },
        campaigns: {
          keyOrder: 10,
          table: {
            show: true
          },
          chart: {
            shown: true
          }
        },
        ad_skus: {
          keyOrder: 16,
          table: {
            show: true
          },
          chart: {
            shown: true
          }
        },
        search_terms: {
          keyOrder: 15,
          table: {
            show: true
          },
          chart: {
            shown: true
          }
        }
      }
    },
    impressions: {
      title: 'Impressions',
      pages: {
        keywords: {
          keyOrder: 26,
          table: {
            show: true
          },
          toolTipText:
            'Total number of impressions generated from the keyword in the selected time period',
          chart: {
            shown: true
          }
        },
        campaigns: {
          keyOrder: 27,
          table: {
            show: true
          },
          toolTipText:
            'Total number of impressions generated from the campaign in the selected time period',
          chart: {
            shown: true
          }
        },
        target_campaigns: {
          keyOrder: 26,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Number of times the Criteo Ads were displayed on Target’s websites. Corresponds to the entire Criteo Ad impression, regardless of the number of products in the Criteo Ad.'
        },
        target_line_items: {
          keyOrder: 19,
          table: {
            show: true
          },
          toolTipText:
            'Number of times the Criteo Ads were displayed on Target’s websites. Corresponds to the entire Criteo Ad impression, regardless of the number of products in the Criteo Ad.',
          chart: {
            shown: true
          }
        },
        target_search_terms: {
          keyOrder: 9,
          table: {
            show: true
          },
          toolTipText:
            'Number of times the Criteo Ads were displayed on Target’s websites. Corresponds to the entire Criteo Ad impression, regardless of the number of products in the Criteo Ad.',
          chart: {
            shown: true
          }
        },
        target_keywords: {
          keyOrder: 9,
          table: {
            show: true
          },
          toolTipText:
            'Number of times the Criteo Ads were displayed on Target’s websites. Corresponds to the entire Criteo Ad impression, regardless of the number of products in the Criteo Ad.',
          chart: {
            shown: true
          }
        },
        ad_skus: {
          keyOrder: 24,
          table: {
            show: true
          },
          toolTipText:
            'Total number of impressions generated from the SKU in the selected time period',
          chart: {
            shown: true
          }
        },
        search_terms: {
          keyOrder: 23,
          table: {
            show: true
          },
          toolTipText:
            'Total number of impressions generated from the search term in the selected time period',
          chart: {
            shown: true
          }
        }
      },
      type: 'NUMERIC'
    },
    product_impressions: {
      title: 'Impressions',
      pages: {
        target_ad_skus: {
          keyOrder: 16,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            "Number of times your products were displayed in Criteo Ads on publishers' websites. Note, there may be more than one product in a single Criteo Ad."
        }
      }
    },
    attributed_units_sold: {
      title: 'Attributed Units Sold 14D',
      type: 'NUMERIC',
      pages: {
        target_campaigns: {
          keyOrder: 27,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Units sold attributed to a click or impression; exclusive of assisted units within a 14days lookback window for post-click and no lookback for post view.'
        },
        target_line_items: {
          keyOrder: 25,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Units sold attributed to a click or impression; exclusive of assisted units.'
        },
        target_ad_skus: {
          keyOrder: 22,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Units sold attributed to a click or impression; exclusive of assisted units.'
        },
        target_search_terms: {
          keyOrder: 15,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Units sold attributed to a click or impression; exclusive of assisted units.'
        },
        target_keywords: {
          keyOrder: 15,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Units sold attributed to a click or impression; exclusive of assisted units.'
        }
      }
    },
    clicks: {
      title: 'Clicks',
      pages: {
        keywords: {
          keyOrder: 29,
          table: {
            show: true
          },
          toolTipText:
            'Total number of clicks generated from the keyword in the selected time period',
          chart: {}
        },
        campaigns: {
          keyOrder: 28,
          table: {
            show: true
          },
          toolTipText:
            'Total number of clicks generated from the campaign in the selected time period',
          chart: {}
        },
        target_campaigns: {
          keyOrder: 28,
          table: {
            show: false
          },
          chart: {
            shown: false,
            selected: false
          },
          toolTipText:
            "Number of times the ads were clicked in Target's website."
        },
        target_line_items: {
          keyOrder: 20,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            "Number of times the ads were clicked in Target's website."
        },
        target_ad_skus: {
          keyOrder: 17,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            "Number of times the ads were clicked in Target's website."
        },
        target_search_terms: {
          keyOrder: 10,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            "Number of times the ads were clicked in Target's website."
        },
        target_keywords: {
          keyOrder: 10,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            "Number of times the ads were clicked in Target's website."
        },
        ad_skus: {
          keyOrder: 25,
          table: {
            show: true
          },
          toolTipText:
            'Total number of clicks generated from the SKU in the selected time period',
          chart: {}
        },
        search_terms: {
          keyOrder: 23,
          table: {
            show: true
          },
          toolTipText:
            'Total number of clicks generated from the search term in the selected time period',
          chart: {}
        }
      },
      type: 'NUMERIC'
    },
    units_sold: {
      title: 'Units Sold 14D',
      pages: {
        keywords: {
          keyOrder: 30,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Total number of units sold from the keyword in the selected time period using 14 days attribution method'
        },
        campaigns: {
          keyOrder: 25,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Total number of units sold from the campaign in the selected time period using 14 days attribution method'
        },
        ad_skus: {
          keyOrder: 23,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Total number of units sold on the SKU in the selected time period using 14 day attribution method'
        },
        search_terms: {
          keyOrder: 18,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Total number of units sold from the search term in the selected time period using 14 days attribution method'
        }
      },
      type: 'NUMERIC'
    },
    budget_type: {
      title: 'Budget Type',
      pages: {
        campaigns: {
          keyOrder: 17,
          table: {
            show: true
          }
        }
      },
      toolTipText: 'Budget Type'
    },
    total_budget: {
      title: 'Total Budget',
      ...(isWalmart && {
        getter: {
          walmart: {
            campaign: 'campaignManagement/getCampaignsRealTimeMetadata'
          }
        },
        realTimeMetadataCell: true,
        realTimeHeader: ['campaign']
      }),
      pages: {
        campaigns: {
          keyOrder: 18,
          table: {
            show: true
          }
        },
        target_campaigns: {
          keyOrder: 10,
          table: {
            show: true
          },
          toolTipText:
            'Lifetime budget for the campaign. NA if the Budget is uncapped.'
        }
      },
      type: 'NUMERIC',
      unit: 'CURRENCY',
      toolTipText: 'Lifetime Budget allocated to the campaign'
    },
    daily_budget: {
      title: 'Daily Budget',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      ...(isWalmart && {
        getter: {
          walmart: {
            campaign: 'campaignManagement/getCampaignsRealTimeMetadata'
          }
        },
        realTimeMetadataCell: true,
        realTimeHeader: ['campaign']
      }),
      pages: {
        campaigns: {
          keyOrder: 20,
          table: {
            show: true
          }
        }
      },
      toolTipText: 'Daily budget allocated to the campaign'
    },
    acos_14d: {
      title: 'ACoS 14D',
      pages: {
        keywords: {
          keyOrder: 20,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Advertised cost of sales generated on the keyword in the selected time period using 14 day attribution method calculated as Total spend/ attributed sales'
        },
        campaigns: {
          keyOrder: 14,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Advertised cost of sales generated on the campaign in the selected time period using 14 day attribution method calculated as Total spend/ attributed sales'
        },
        ad_skus: {
          keyOrder: 17,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Advertised cost of sales generated on the SKU in the selected time period using 14 day attribution method calculated as Total spend/ attributed sales'
        },
        search_terms: {
          keyOrder: 16,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Advertised cost of sales generated from the search term in the selected time period using 14 days attribution method calculated as Total spend divided by 14 days attributed sales'
        },
        target_campaigns: {
          keyOrder: 29,
          table: {
            show: false
          },
          chart: {
            shown: false,
            selected: false
          },
          toolTipText:
            'The advertised cost of sales generated from the line item in the selected time period using 14 days attribution method calculated as Total spend divided by 14 days attributed sales.'
        },
        target_line_items: {
          keyOrder: 32,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'The advertised cost of sales generated from the line item in the selected time period using 14 days attribution method calculated as Total spend divided by 14 days attributed sales.'
        },
        target_ad_skus: {
          keyOrder: 29,
          table: {
            show: true
          },
          chart: {
            shown: true,
            selected: true
          },
          toolTipText:
            'The advertised cost of sales generated from the line item in the selected time period using 14 days attribution method calculated as Total spend divided by 14 days attributed sales.'
        },
        target_search_terms: {
          keyOrder: 22,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'The advertised cost of sales generated from the line item in the selected time period using 14 days attribution method calculated as Total spend divided by 14 days attributed sales.'
        },
        target_keywords: {
          keyOrder: 22,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'The advertised cost of sales generated from the line item in the selected time period using 14 days attribution method calculated as Total spend divided by 14 days attributed sales.'
        }
      },
      type: 'NUMERIC',
      unit: 'PERCENTAGE'
    },
    ctr: {
      title: 'Click Through Rate',
      pages: {
        keywords: {
          keyOrder: 27,
          table: {
            show: true
          },
          chart: {}
        },
        campaigns: {
          keyOrder: 29,
          table: {
            show: true
          },
          chart: {}
        },
        ad_skus: {
          keyOrder: 27,
          table: {
            show: true
          },
          chart: {}
        },
        search_terms: {
          keyOrder: 24,
          table: {
            show: true
          },
          chart: {}
        },
        target_campaigns: {
          keyOrder: 30,
          table: {
            show: false
          },
          chart: {
            shown: false,
            selected: false
          },
          toolTipText:
            'Measure of how many clicks generated from the impressions in the selected time period calculated as clicks divided by impressions'
        },
        target_line_items: {
          keyOrder: 21,
          title: 'CTR',
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Measure of how many clicks generated from the impressions in the selected time period calculated as clicks divided by impressions'
        },
        target_ad_skus: {
          keyOrder: 18,
          title: 'CTR',
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Measure of how many clicks generated from the impressions in the selected time period calculated as clicks divided by impressions'
        },
        target_search_terms: {
          keyOrder: 11,
          title: 'CTR',
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Measure of how many clicks generated from the impressions in the selected time period calculated as clicks divided by impressions'
        },
        target_keywords: {
          keyOrder: 11,
          title: 'CTR',
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Measure of how many clicks generated from the impressions in the selected time period calculated as clicks divided by impressions'
        }
      },
      toolTipText:
        'Measure of how many clicks generated from the impressions in the selected time period calculated as clicks divided by impressions',
      type: 'NUMERIC',
      unit: 'PERCENTAGE'
    },
    organic_sov_page_1: {
      title: 'Share of Organic Search',
      toolTipText:
        'Number of client SKUs on page 1 ranked organically divided by total number of SKUs on page 1 ranked organically in selected time period',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      pages: {
        keywords: {
          keyOrder: 23,
          table: {
            show: true
          },
          chart: {}
        },
        search_terms: {
          keyOrder: 20,
          table: {
            show: true
          },
          chart: {}
        },
        target_keywords: {
          keyOrder: 25,
          table: {
            show: true
          },
          chart: {}
        },
        target_search_terms: {
          keyOrder: 25,
          table: {
            show: true
          },
          chart: {}
        }
      }
    },
    sp_sov_page_1: {
      title: 'Share of SP Ad Space',
      toolTipText:
        'Number of client sponsored products on page 1 divided by total number of sponsored products on page 1 in in selected time period',
      pages: {
        keywords: {
          keyOrder: 24,
          table: {
            show: true
          },
          chart: {}
        },
        search_terms: {
          keyOrder: 21,
          table: {
            show: true
          },
          chart: {}
        },
        target_keywords: {
          keyOrder: 25,
          table: {
            show: true
          },
          chart: {}
        },
        target_search_terms: {
          keyOrder: 25,
          table: {
            show: true
          },
          chart: {}
        }
      },
      type: 'NUMERIC',
      unit: 'PERCENTAGE'
    },
    sb_sov_page_1: {
      title: 'Share of SB Ad Space',
      toolTipText:
        'Number of client sponsored brands on page 1 divided by total number of sponsored brands on page1 in selected time period.',
      pages: {
        keywords: {
          keyOrder: 25,
          table: {
            show: true
          },
          chart: {}
        },
        search_terms: {
          keyOrder: 22,
          table: {
            show: true
          },
          chart: {}
        },
        target_keywords: {
          keyOrder: 25,
          table: {
            show: true
          },
          chart: {}
        },
        target_search_terms: {
          keyOrder: 25,
          table: {
            show: true
          },
          chart: {}
        }
      },
      type: 'NUMERIC',
      unit: 'PERCENTAGE'
    },
    attributed_brand_view_sales: {
      title: 'Attributed Brand View Sales 14D',
      toolTipText:
        'Attributed sales based on the view of the same brand as the advertised item',
      pages: {
        keywords: {
          keyOrder: 32,
          table: {
            show: true
          },
          chart: {}
        },
        campaigns: {
          keyOrder: 34,
          table: {
            show: true
          },
          chart: {}
        },
        ad_skus: {
          keyOrder: 30,
          table: {
            show: true
          },
          chart: {}
        },
        search_terms: {
          keyOrder: 27,
          table: {
            show: true
          },
          chart: {}
        }
      },
      type: 'NUMERIC',
      unit: 'CURRENCY'
    },
    attributed_direct_click_sales: {
      // copy of total_attributed_direct_click_sales table nameing issue
      title: 'Attributed Direct Click Sales 14D',
      toolTipText:
        'Attributed sales revenue based on the direct click of the advertised item',
      pages: {
        keywords: {
          keyOrder: 33,
          table: {
            show: true
          },
          chart: {}
        },
        search_terms: {
          keyOrder: 28,
          table: {
            show: true
          },
          chart: {}
        }
      },
      type: 'NUMERIC',
      unit: 'CURRENCY'
    },
    attributed_related_click_sales: {
      //  copt of total_attributed_related_click_sales
      title: 'Attributed Related Click Sales 14D',
      toolTipText:
        'Attributed sales revenue based on the direct click of the same brand and category of the advertised item and fulfillment by shipping',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        keywords: {
          keyOrder: 34,
          table: {
            show: true
          },
          chart: {}
        },
        search_terms: {
          keyOrder: 29,
          table: {
            show: true
          },
          chart: {}
        }
      }
    },
    attributed_brand_click_sales: {
      title: 'Attributed Brand Click Sales 14D',
      toolTipText:
        'Attributed sales based on the direct click of the same brand as the advertised item',
      pages: {
        keywords: {
          keyOrder: 35,
          table: {
            show: true
          },
          chart: {}
        },
        search_terms: {
          keyOrder: 30,
          table: {
            show: true
          },
          chart: {}
        }
      },
      type: 'NUMERIC',
      unit: 'CURRENCY'
    },
    start_date: {
      title: 'Start Date',
      toolTipText: 'Start Date',
      pages: {
        campaigns: {
          keyOrder: 44,
          table: {
            show: true
          }
        },
        target_line_items: {
          keyOrder: 11,
          table: {
            show: true
          },
          toolTipText: 'Start Date of the line item'
        }
      },
      type: 'date'
    },
    end_date: {
      title: 'End Date',
      toolTipText: 'End Date',
      type: 'date',
      pages: {
        campaigns: {
          keyOrder: 45,
          table: {
            show: true
          }
        },
        target_line_items: {
          keyOrder: 12,
          table: {
            show: true
          },
          toolTipText: 'Ending Date of the line item'
        }
      }
    },
    search_term: {
      title: 'Search Term',
      toolTipText: 'Search Term used by customer',
      pages: {
        search_terms: {
          keyOrder: 3,
          table: {
            show: true
          },
          pinned: 'left'
        },
        target_search_terms: {
          keyOrder: 2,
          table: {
            show: true
          },
          pinned: 'left',
          toolTipText: 'Search Term'
        }
      }
    },
    search_term_type: {
      title: 'Search Term Type',
      toolTipText: 'Type of search term (generic, branded, competitor)',
      pages: {
        search_terms: {
          keyOrder: 9,
          table: {
            show: true
          },
          pinned: ''
        },
        target_search_terms: {
          keyOrder: 3,
          table: {
            show: true
          }
        }
      }
    },
    keyword_text: {
      title: 'Keyword',
      pages: {
        keywords: {
          keyOrder: 4,
          table: {
            show: true
          },
          pinned: 'left',
          toolTipText: 'Keyword'
        },
        search_terms: {
          keyOrder: 3,
          table: {
            show: true
          },
          toolTipText: 'Keyword associated with the search term'
        }
      }
    },
    keyword_type: {
      title: 'Keyword Type',
      toolTipText: 'Type of keyword(generic, branded, competitor)',
      pages: {
        keywords: {
          keyOrder: 4,
          table: {
            show: true
          }
        },
        target_keywords: {
          keyOrder: 3,
          table: {
            show: true
          }
        }
      }
    },
    campaign_name: {
      title: 'Campaign Name',
      pages: {
        keywords: {
          keyOrder: 5,
          table: {
            show: true
          },
          toolTipText: 'Campaign name of which the keyword is a part of',
          callback(obj) {
            return redirectComponent(obj);
          }
        },
        campaigns: {
          keyOrder: 3,
          table: {
            show: true
          },
          toolTipText: 'Name of the campaign',
          pinned: 'left',
          callback(obj, vueRef, currentDictionaryObject, dataProps) {
            return legendsCell(obj, vueRef, currentDictionaryObject, dataProps);
          }
        },
        target_campaigns: {
          keyOrder: 5,
          table: {
            show: true
          },
          pinned: 'left',
          toolTipText: 'Name of the Campaign of which the Line item is part of'
        },
        target_line_items: {
          keyOrder: 5,
          table: {
            show: true
          },
          toolTipText: 'Name of the Campaign of which the Line item is part of'
        },
        target_ad_skus: {
          keyOrder: 6,
          table: {
            show: true
          },
          toolTipText: 'Name of the Campaign of which the Line item is part of'
        },
        target_search_terms: {
          keyOrder: 4,
          table: {
            show: true
          },
          toolTipText: 'Campaign name of which the Line item is a part of'
        },
        target_keywords: {
          keyOrder: 4,
          table: {
            show: true
          },
          toolTipText: 'Campaign name of which the Keyword is a part of'
        },
        ad_skus: {
          keyOrder: 4,
          table: {
            show: true
          },
          toolTipText: 'Campaign name of which the SKU is a part of',
          callback(obj) {
            return redirectComponent(obj);
          }
        },
        search_terms: {
          keyOrder: 3,
          table: {
            show: true
          },
          toolTipText: 'Name of the campaign',
          callback(obj) {
            return redirectComponent(obj);
          }
        }
      }
    },
    brand: {
      title: 'Brand',
      toolTipText: 'SKU’s Brand',
      pages: {
        ad_skus: {
          keyOrder: 11,
          table: {
            show: true
          }
        },
        target_ad_skus: {
          keyOrder: 9,
          table: {
            show: true
          },
          toolTipText: 'Associated brand(s) to the campaign'
        },
        target_campaigns: {
          keyOrder: 9,
          table: {
            show: true
          },
          toolTipText:
            'Brands associated to your campaign. Associated brands are never removed, only added based on the products added at the line item level.'
        }
      }
    },
    category: {
      title: 'Category',
      toolTipText: 'Category of the SKU',
      pages: {
        ad_skus: {
          keyOrder: 13,
          table: {
            show: true
          }
        }
      }
    },
    campaign_type: {
      title: 'Campaign Type',
      toolTipText: 'Type of Campaign(Sponsored Product)',
      pages: {
        keywords: {
          keyOrder: 10,
          table: {
            show: true
          }
        },
        campaigns: {
          keyOrder: 4,
          table: {
            show: true
          }
        },
        target_campaigns: {
          keyOrder: 6,
          table: {
            show: true
          },
          toolTipText: 'Type of Campaign'
        },
        target_line_items: {
          keyOrder: 6,
          table: {
            show: true
          },
          toolTipText: 'Type of Campaign'
        },
        target_ad_skus: {
          keyOrder: 7,
          table: {
            show: true
          },
          toolTipText: 'Type of Campaign'
        },
        target_search_terms: {
          keyOrder: 5,
          table: {
            show: true
          },
          toolTipText: 'Type of Campaign'
        },
        ad_skus: {
          keyOrder: 5,
          table: {
            show: true
          }
        },
        search_terms: {
          keyOrder: 5,
          table: {
            show: true
          }
        }
      }
    },
    adgroup_name: {
      title: 'Adgroup Name',
      pages: {
        keywords: {
          keyOrder: 6,
          table: {
            show: true
          },
          toolTipText: 'Adgroup name of which the campaign is a part of'
        },
        ad_skus: {
          keyOrder: 7,
          table: {
            show: true
          },
          toolTipText: 'Adgroup name of which the SKU is a part of '
        },
        search_terms: {
          keyOrder: 3,
          table: {
            show: true
          },
          toolTipText: 'Adgroup name of which the campaign is a part of'
        }
      }
    },
    adgroup_status: {
      title: 'Adgroup Status',
      pages: {
        keywords: {
          keyOrder: 8,
          table: {
            show: true
          },
          toolTipText: 'Status of the Adgroup which the keyword is part of'
        },
        ad_skus: {
          keyOrder: 9,
          table: {
            show: true
          },
          toolTipText: 'Status of the Adgroup of which the SKU is part of'
        }
      }
    },
    advertiser_name: {
      title: 'Advertiser Name',
      pages: {
        keywords: {
          keyOrder: 8,
          table: {
            show: true
          },
          toolTipText: 'Advertiser Name of which the keyword is part of'
        },
        campaigns: {
          keyOrder: 5,
          table: {
            show: true
          },
          toolTipText: 'Advertiser Name of which the campaign is part of'
        },
        ad_skus: {
          keyOrder: 10,
          table: {
            show: true
          },
          toolTipText: 'Advertiser Name of which the SKU is part of'
        },
        search_terms: {
          keyOrder: 4,
          table: {
            show: true
          },
          toolTipText: 'Advertiser Name of which the campaign is a part of'
        }
      }
    },
    status: {
      title: 'Status',
      ...(isWalmart && {
        getter: {
          walmart: {
            keyword: 'campaignManagement/getKeywordsRealTimeMetadata',
            campaign: 'campaignManagement/getCampaignsRealTimeMetadata',
            skus: 'campaignManagement/getSkusRealTimeMetadata'
          }
        },
        realTimeMetadataCell: true,
        realTimeHeader: ['keyword', 'campaign', 'skus'],
        type: 'Text'
      }),
      pages: {
        keywords: {
          keyOrder: 9,
          table: {
            show: true
          },
          toolTipText: 'Current status of the keyword.'
        },
        campaigns: {
          keyOrder: 6,
          table: {
            show: true
          },
          toolTipText: 'Status of the campaign'
        },
        target_line_items: {
          keyOrder: 4,
          table: {
            show: true
          },
          toolTipText: 'Status of the Line Item'
        },
        ad_skus: {
          keyOrder: 8,
          table: {
            show: true
          },
          toolTipText: 'Status of the SKU'
        },
        search_term: {
          keyOrder: 6,
          table: {
            show: true
          },
          toolTipText: 'Status of the SKU'
        }
      }
    },
    line_item_status: {
      title: 'Line Item Status',
      pages: {
        target_ad_skus: {
          keyOrder: 5,
          table: {
            show: true
          },
          toolTipText: 'Status of the Line Item'
        },
        target_search_terms: {
          keyOrder: 7,
          table: {
            show: true
          },
          toolTipText:
            'Status of the Line item which the whose products were shown for the search term'
        },
        target_keywords: {
          keyOrder: 7,
          table: {
            show: true
          },
          toolTipText:
            'Status of the Line item which the whose products were shown for the Keywords'
        }
      }
    },
    line_item_bid: {
      title: 'Line Item Bid',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        target_ad_skus: {
          keyOrder: 13,
          table: {
            show: true
          },
          toolTipText: 'The bid amount at the Line Item Level'
        }
      }
    },
    minimum_bid: {
      title: 'Minimum Bid',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        target_ad_skus: {
          keyOrder: 14,
          table: {
            show: true
          },
          toolTipText: 'The minimum amount of bid set by the retailer.'
        }
      }
    },
    bid_override: {
      title: 'Product Bid Override',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        target_ad_skus: {
          keyOrder: 15,
          table: {
            show: true
          },
          toolTipText:
            'Product Bid Override will be used as the bid for this product instead of the Line Item bid. If an override is not entered, the Line Item bid is used.'
        }
      }
    },
    campaign_status: {
      // to do: backend change, flow status to active and campaign_status to campaign_state
      title: 'Campaign Status',
      toolTipText: 'Status of the campaign',
      pages: {
        keywords: {
          keyOrder: 9,
          table: {
            show: true
          }
        },
        target_campaigns: {
          keyOrder: 7,
          table: {
            show: true
          }
        },
        target_line_items: {
          keyOrder: 7,
          table: {
            show: true
          }
        },
        target_ad_skus: {
          keyOrder: 8,
          table: {
            show: true
          }
        },
        target_search_terms: {
          keyOrder: 8,
          table: {
            show: true
          }
        },
        target_keywords: {
          keyOrder: 5,
          table: {
            show: true
          }
        },
        ad_skus: {
          keyOrder: 12,
          table: {
            show: true
          }
        }
      }
    },
    toggle_status: {
      title: 'Active Status',
      showToolTip: true,
      ...(isWalmart && {
        type: 'Icon',
        getter: {
          walmart: {
            campaign: 'campaignManagement/getCampaignsRealTimeMetadata',
            keyword: 'campaignManagement/getKeywordsRealTimeMetadata',
            skus: 'campaignManagement/getSkusRealTimeMetadata'
          }
        },
        realTimeHeader: ['campaign', 'keyword', 'skus']
      }),
      pages: {
        keywords: {
          table: {
            show: true
          },
          toolTipText: 'Current status of the keyword',
          callback(obj, vueRef, currentDictionaryObject) {
            return campaignStatusCallback(obj, currentDictionaryObject);
          }
        },
        campaigns: {
          table: {
            show: true
          },
          toolTipText: 'Current status of the campaign',
          callback(obj, vueRef, currentDictionaryObject) {
            return campaignStatusCallback(obj, currentDictionaryObject, {
              liveValue: 'live',
              pausedValue: 'paused',
              proposalValue: 'proposal'
            });
          },
          cellClass: (params) => hideCellCondition(params)
        },
        target_campaigns: {
          table: {
            show: true
          },
          toolTipText: 'Current status of the Campaign.',
          callback(obj, vueRef, currentDictionaryObject) {
            return campaignStatusCallback(obj, currentDictionaryObject, {
              liveValue: 'active'
            });
          }
        },
        target_line_items: {
          table: {
            show: true
          },
          toolTipText: 'Current status of the Line Item.',
          callback(obj, vueRef, currentDictionaryObject) {
            return campaignStatusCallback(obj, currentDictionaryObject, {
              liveValue: 'active',
              pausedValue: 'paused'
            });
          }
        },
        target_ad_skus: {
          table: {
            show: true
          },
          toolTipText: 'Current status of the Ad_SKU.',
          callback(obj, vueRef, currentDictionaryObject) {
            return campaignStatusCallback(obj, currentDictionaryObject, {
              liveValue: 'active',
              pausedValue: 'paused'
            });
          }
        },
        target_keywords: {
          keyOrder: 0,
          table: {
            show: true
          },
          callback(obj, vueRef, currentDictionaryObject) {
            return campaignStatusCallback(obj, currentDictionaryObject, {
              statusField: 'keyword_status',
              liveValue: 'enabled',
              inReviewValue: 'inreview',
              rejectedValue: 'rejected',
              disabledValue: 'deleted'
            });
          },
          toolTipText:
            'Status of the keyword denoting whether the keyword is enabled or deleted'
        },
        search_terms: {
          table: {
            show: true
          },
          toolTipText: 'Current status of the Search Term',
          callback(obj, vueRef, currentDictionaryObject) {
            return campaignStatusCallback(obj, currentDictionaryObject);
          }
        },
        ad_skus: {
          table: {
            show: true
          },
          toolTipText: 'Current status of the SKU',
          callback(obj, vueRef, currentDictionaryObject) {
            return campaignStatusCallback(obj, currentDictionaryObject, {
              liveValue: 'enabled'
            });
          }
        }
      },
      keyOrder: 1,
      pinned: 'left'
    },
    match_type: {
      title: 'Match Type',
      toolTipText: 'Match Type(exact, phrase, broad)',
      pages: {
        keywords: {
          keyOrder: 12,
          table: {
            show: true
          }
        },
        search_terms: {
          keyOrder: 12,
          table: {
            show: true
          }
        },
        target_keywords: {
          keyOrder: 12,
          table: {
            show: true
          },
          toolTipText:
            'Match type of the keyword, determining what search terms will be targeted against it'
        }
      }
    },
    bid: {
      title: 'Current Bid',
      ...(isWalmart && {
        getter: {
          walmart: {
            keyword: 'campaignManagement/getKeywordsRealTimeMetadata',
            skus: 'campaignManagement/getSkusRealTimeMetadata'
          }
        },
        realTimeMetadataCell: true,
        realTimeHeader: ['keyword', 'skus']
      }),
      pages: {
        keywords: {
          keyOrder: 22,
          table: {
            show: true
          },
          toolTipText: 'Current bid amount on the keyword'
        },
        ad_skus: {
          keyOrder: 19,
          table: {
            show: true
          },
          toolTipText: 'Current bid on the SKU'
        },
        search_terms: {
          keyOrder: 17,
          table: {
            show: true
          },
          toolTipText: 'Current bid amount on the keyword'
        },
        target_keywords: {
          keyOrder: 8,
          table: {
            show: true
          },
          toolTipText:
            'This is the bid override value for the positive keyword. The keyword will use the default line item bid if the value is null. '
        }
      },
      type: 'NUMERIC',
      unit: 'CURRENCY'
    },
    availability_pct: {
      title: 'Availability Percent',
      pages: {
        ad_skus: {
          keyOrder: 20,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Percentage of time the item was in stock today or in last 7 days'
        }
      },
      type: 'NUMERIC',
      unit: 'PERCENTAGE'
    },
    buy_box_pct: {
      title: 'Buy Box Percentage',
      pages: {
        ad_skus: {
          keyOrder: 21,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Percentage of time the item won the Buy Box today or in last 7 days'
        }
      },
      type: 'NUMERIC',
      unit: 'PERCENTAGE'
    },
    page_views: {
      title: 'Page Views',
      toolTipText:
        'Number of times the product detail page for this item was visited today or in last 7 days',
      pages: {
        ad_skus: {
          keyOrder: 22,
          table: {
            show: true
          },
          chart: {}
        }
      },
      type: 'NUMERIC'
    },
    targeting_type: {
      title: 'Targeting Type',
      toolTipText: 'Targeting Type(manual, auto)',
      pages: {
        keywords: {
          keyOrder: 11,
          table: {
            show: true
          }
        },
        campaigns: {
          keyOrder: 7,
          table: {
            show: true
          }
        },
        ad_skus: {
          keyOrder: 6,
          table: {
            show: true
          }
        },
        search_terms: {
          keyOrder: 11,
          table: {
            show: true
          }
        },
        target_keywords: {
          title: 'Keyword Source',
          keyOrder: 2,
          table: {
            show: true
          },
          toolTipText:
            'The source of the keyword, determines whether the keyword was manually added or automatically added to the campaign'
        }
      }
    },
    sku_name: {
      showOnUI: false,
      title: 'SKU',
      toolTipText: 'Walmart Item',
      pages: {
        ad_skus: {
          keyOrder: 3,
          pinned: 'left'
        }
      }
    },
    sku: {
      title: 'SKU',
      toolTipText: 'Walmart Item',
      pages: {
        ad_skus: {
          keyOrder: 3,
          pinned: 'left'
        }
      },
      callback(obj, vueRef, currentDictionaryObject) {
        delete obj.cellRenderer;
        const initialState = skuData();
        const renderParams = {
          ...(initialState.data?.renderParams || {}),
          name: 'sku_name',
          asin: 'sku',
          imageUrl: 'latest_image_url',
          productPageUrl: 'latest_product_page_url',
          showImage: true,
          enableSDP: false,
          asinTippyText: ''
        };
        const payload = {
          data: {
            renderParams
          }
        };
        obj.cellRendererFramework = skuCellDisplay(payload);
        obj.headerComponentParams.enableSorting = false;
        obj.minWidth = 300;
        return obj;
      }
    },
    multiplier: {
      title: 'Bid Multiplier',
      toolTipText:
        'The percentage by which the bids will be increased on prime placement and platform.',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      pages: {
        campaigns: {
          keyOrder: 23,
          table: {
            show: true
          }
        }
      }
    },
    multiplier_status: {
      title: 'Bid Multiplier Status',
      toolTipText:
        'The status if the placement is enabled to show ads for the campaign',
      pages: {
        campaigns: {
          keyOrder: 24,
          table: {
            show: true
          }
        }
      }
    },
    timeline: {
      title: 'Activity Log',
      toolTipText: 'Activity log',
      type: 'icon',
      pages: {
        keywords: {
          table: {
            show: true
          }
        },
        campaigns: {
          table: {
            show: true
          },
          cellClass: (params) => hideCellCondition(params)
        },
        target_campaigns: {
          table: {
            show: true
          }
        },
        target_line_items: {
          table: {
            show: true
          }
        },
        target_ad_skus: {
          table: {
            show: true
          }
        },
        target_search_terms: {
          table: {
            show: true
          },
          keyOrder: 1,
          disableActivityLog: true
        },
        target_keywords: {
          table: {
            show: true
          },
          keyOrder: 1
        },
        ad_skus: {
          table: {
            show: true
          }
        },
        search_terms: {
          table: {
            show: true
          }
        }
      },
      pinned: 'left',
      keyOrder: 2,
      callback(obj, vueRef, currentDictionaryObject) {
        delete obj.cellRenderer;
        obj.cellRendererFramework = 'iconTableCell';
        obj.headerComponentFramework = 'iconTableHeader';
        obj.cellRendererParams.iconClickEvent = vueRef.openActivityLog;
        obj.cellRendererParams.displayIcon = 'timeline';
        obj.headerComponentParams.displayName = 'Activity log';
        obj.cellRendererParams.toolTipText =
          currentDictionaryObject.toolTipText;
        obj.notDownloadable = true;
        obj.headerComponentParams.displayIcon = 'timeline';
        obj.minWidth = 60;
        if (currentDictionaryObject.disableActivityLog) {
          obj.cellRendererParams.extraClass = 'disabled';
          obj.minWidth = 90;
          obj.cellRendererParams.iconClickEvent = undefined;
          obj.cellRendererParams.toolTipText =
            'Activity log will be available once actions are enabled';
        }
        return obj;
      }
    },
    cpo: {
      title: 'CPO 14D',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        target_campaigns: {
          keyOrder: 31,
          table: {
            show: false
          },
          chart: {
            shown: false,
            selected: false
          },
          toolTipText:
            'Average of the amount spent on advertising that resulted in a purchase order from a shopper for the selected time period within a 14days lookback window for post-click and no lookback for post view.'
        },
        target_line_items: {
          keyOrder: 22,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Average of the amount spent on advertising that resulted in a purchase order from a shopper for the selected time period within a 14days lookback window for post-click and a 1-day lookback window for post view.'
        },
        target_ad_skus: {
          keyOrder: 19,
          table: {
            show: true
          },
          chart: {
            shown: true
          },
          toolTipText:
            'Average of the amount spent on advertising that resulted in a purchase order from a shopper for the selected time period within a 14days lookback window for post-click and a 1-day lookback window for post view.'
        },
        target_search_terms: {
          keyOrder: 12,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Average of the amount spent on advertising that resulted in a purchase order from a shopper for the selected time period within a 14days lookback window for post-click and a 1-day lookback window for post view.'
        },
        target_keywords: {
          keyOrder: 12,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Average of the amount spent on advertising that resulted in a purchase order from a shopper for the selected time period within a 14days lookback window for post-click and a 1-day lookback window for post view.'
        }
      }
    },
    attributed_orders: {
      title: 'Attributed Orders 14D',
      type: 'NUMERIC',
      pages: {
        campaigns: {
          keyOrder: 32,
          table: {
            show: true
          },
          toolTipText:
            'Total number of orders generated from the campaign in the selected time period using 14 days attribution method.',
          chart: {}
        },
        ad_skus: {
          keyOrder: 28,
          table: {
            show: true
          },
          toolTipText:
            'Total number of orders generated by the SKU in the selected time period using 14 days attribution method.',
          chart: {}
        },
        search_terms: {
          keyOrder: 28,
          table: {
            show: true
          },
          toolTipText:
            'Total number of orders generated by the Search term in the selected time period using 14 days attribution method.',
          chart: {}
        },
        keywords: {
          keyOrder: 28,
          table: {
            show: true
          },
          toolTipText:
            'Total number of orders generated by the Keyword in the selected time period using 14 days attribution method.',
          chart: {}
        },
        target_campaigns: {
          keyOrder: 32,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Orders (or transactions) attributed to a click or impression within a 14days lookback window for post-click and no lookback for post view.'
        },
        target_line_items: {
          keyOrder: 26,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Orders (or transactions) attributed to a click or impression within a 14days lookback window for post-click and a 1-day lookback window for post view.'
        },
        target_ad_skus: {
          keyOrder: 23,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Orders (or transactions) attributed to a click or impression within a 14days lookback window for post-click and a 1-day lookback window for post view.'
        },
        target_search_terms: {
          keyOrder: 16,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Orders (or transactions) attributed to a click or impression within a 14days lookback window for post-click and a 1-day lookback window for post view.'
        },
        target_keywords: {
          keyOrder: 16,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Orders (or transactions) attributed to a click or impression within a 14days lookback window for post-click and a 1-day lookback window for post view.'
        }
      }
    },
    assisted_units: {
      title: 'Assisted Units 14D',
      type: 'NUMERIC',
      pages: {
        target_line_items: {
          keyOrder: 28,
          chart: {},
          table: {
            show: true
          },
          toolTipText:
            'Units sold attributed to a click or impression on the path to purchase leading to the sale within a 14days lookback window for post-click and a 1-day lookback window for post view.'
        },
        target_ad_skus: {
          keyOrder: 25,
          chart: {},
          table: {
            show: true
          },
          toolTipText:
            'Units sold attributed to a click or impression on the path to purchase leading to the sale within a 14days lookback window for post-click and a 1-day lookback window for post view.'
        },
        target_search_terms: {
          keyOrder: 18,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Units sold attributed to a click or impression on the path to purchase leading to the sale within a 14days lookback window for post-click and a 1-day lookback window for post view.'
        },
        target_keywords: {
          keyOrder: 18,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Units sold attributed to a click or impression on the path to purchase leading to the sale within a 14days lookback window for post-click and a 1-day lookback window for post view.'
        }
      }
    },
    assisted_sales: {
      title: 'Assisted Sales 14D',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        target_line_items: {
          keyOrder: 29,
          chart: {},
          table: {
            show: true
          },
          toolTipText:
            'Sales revenue attributed to a click or impression on the path to purchase leading to the sale; exclusive of attributed sale within a 14days lookback window for post-click and 1-day lookback window for post view.'
        },
        target_ad_skus: {
          keyOrder: 26,
          chart: {},
          table: {
            show: true
          },
          toolTipText:
            'Sales revenue attributed to a click or impression on the path to purchase leading to the sale; exclusive of attributed sale within a 14days lookback window for post-click and 1-day lookback window for post view.'
        },
        target_search_terms: {
          keyOrder: 19,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Sales revenue attributed to a click or impression on the path to purchase leading to the sale; exclusive of attributed sale within a 14days lookback window for post-click and 1-day lookback window for post view.'
        },
        target_keywords: {
          keyOrder: 19,
          table: {
            show: true
          },
          chart: {},
          toolTipText:
            'Sales revenue attributed to a click or impression on the path to purchase leading to the sale; exclusive of attributed sale within a 14days lookback window for post-click and 1-day lookback window for post view.'
        }
      }
    },
    line_item_name: {
      title: 'Line Item Name',
      pages: {
        target_line_items: {
          keyOrder: 3,
          table: {
            show: true
          },
          toolTipText: 'Name of the Line Item',
          pinned: 'left'
        },
        target_ad_skus: {
          keyOrder: 4,
          table: {
            show: true
          },
          toolTipText: 'Name of the Line Item'
        },
        target_search_terms: {
          keyOrder: 6,
          table: {
            show: true
          },
          toolTipText: 'Line Item name of which the campaign is a part of'
        },
        target_keywords: {
          keyOrder: 6,
          table: {
            show: true
          },
          toolTipText: 'Line Item name of which the Keyword is a part of',
          pinned: 'left'
        }
      }
    },
    bid_strategy: {
      title: 'Bid Strategy',
      pages: {
        target_line_items: {
          keyOrder: 8,
          table: {
            show: true
          },
          toolTipText:
            'Bid algorithm optimizing for sales conversions, sales revenue, or clicks'
        }
      }
    },
    target_bid: {
      title: 'Target Bid (CPC)',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        target_line_items: {
          keyOrder: 9,
          table: {
            show: true
          },
          toolTipText:
            'The baseline amount you are willing to pay for one click of the ad and that will enter the auction. It does not include the account fees.'
        }
      }
    },
    max_bid: {
      title: 'Max Bid',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        target_line_items: {
          keyOrder: 10,
          table: {
            show: true
          },
          toolTipText:
            'The maximum amount allowed to bid for each bid; respected regardless of targetBid'
        }
      }
    },
    budget: {
      title: 'Budget',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        target_line_items: {
          keyOrder: 13,
          table: {
            show: true
          },
          toolTipText:
            'Lifetime budget for the line item. Null if the Budget Type is uncapped.'
        }
      }
    },
    budget_spent: {
      title: 'Budget Spent',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        target_campaigns: {
          keyOrder: 14,
          table: {
            show: true
          },
          toolTipText:
            'Amount the campaign has already spent including the platform fees.'
        },
        target_line_items: {
          keyOrder: 14,
          table: {
            show: true
          },
          toolTipText: 'Amount the line item has already spent.'
        }
      }
    },
    budget_remaining: {
      title: 'Budget Remaining',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        target_campaigns: {
          keyOrder: 15,
          table: {
            show: true
          },
          toolTipText:
            'Amount remaining for the ads to spend. It is the calculated as the Total budget minus the Spend budget.'
        },
        target_line_items: {
          keyOrder: 15,
          table: {
            show: true
          },
          toolTipText:
            'Amount remaining for the ads to spend. It is the calculated as the Total budget minus the Spend budget.'
        }
      }
    },
    is_auto_daily_pacing: {
      title: 'Auto Daily Pacing',
      pages: {
        target_line_items: {
          keyOrder: 16,
          table: {
            show: true
          },
          toolTipText:
            'This indicates if Daily Budget Pacing is set as Automatic.'
        }
      }
    },
    daily_pacing: {
      title: 'Daily Pacing',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        target_line_items: {
          keyOrder: 17,
          table: {
            show: true
          },
          toolTipText: 'Limit how much your line item can spend per day. '
        }
      }
    },
    monthly_pacing: {
      title: 'Monthly Pacing',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        target_line_items: {
          keyOrder: 18,
          table: {
            show: true
          },
          toolTipText:
            'Amount the line item can spend per calendar month ; resets each calendar month; uncapped if omitted or set to null.'
        }
      }
    },
    product_name: {
      showOnUI: false,
      title: 'Product',
      toolTipText: 'Product Name',
      pages: {
        target_ad_skus: {
          keyOrder: 3,
          pinned: 'left'
        }
      }
    },
    product_id: {
      title: 'Product',
      pages: {
        target_ad_skus: {
          keyOrder: 3,
          toolTipText: 'Product Name',
          pinned: 'left'
        }
      },
      callback(obj, vueRef, currentDictionaryObject) {
        delete obj.cellRenderer;
        const initialState = skuData();
        const renderParams = {
          ...(initialState.data?.renderParams || {}),
          name: 'product_name',
          asin: 'product_id',
          imageUrl: 'image_url',
          showImage: true,
          enableSDP: false,
          asinTippyText: ''
        };
        const payload = {
          data: {
            renderParams
          }
        };
        obj.cellRendererFramework = skuCellDisplay(payload);
        obj.headerComponentParams.enableSorting = false;
        obj.minWidth = 300;
        return obj;
      }
    },
    product_category: {
      title: 'Product Category',
      pages: {
        target_ad_skus: {
          keyOrder: 10,
          table: {
            show: true
          },
          toolTipText: 'Category of product'
        }
      }
    },
    price: {
      title: 'Price',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      pages: {
        target_ad_skus: {
          keyOrder: 11,
          table: {
            show: true
          },
          toolTipText: 'Price of the product'
        }
      }
    },
    product_availabilty: {
      title: 'Product Availability',
      pages: {
        target_ad_skus: {
          keyOrder: 12,
          table: {
            show: true
          }
        }
      }
    },
    conversion_rate: {
      title: 'Conversion Rate',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      pages: {
        campaigns: walmartConversionRateDictionaryObject,
        line_items: walmartConversionRateDictionaryObject,
        ad_skus: walmartConversionRateDictionaryObject,
        samsclub_api_keywords: samsclub_apiConversionRateDictionaryObject,
        samsclub_api_campaigns: samsclub_apiConversionRateDictionaryObject,
        samsclub_api_ad_skus: samsclub_apiConversionRateDictionaryObject,
        target_campaigns: conversionRateDictionaryObject,
        target_line_items: conversionRateDictionaryObject,
        target_ad_skus: conversionRateDictionaryObject,
        target_keywords: conversionRateDictionaryObject
      }
    },
    unique_vistors: {
      title: 'Unique Visitors',
      type: 'NUMERIC',
      pages: {
        target_campaigns: uniqueVisitorsDictionaryObject,
        target_line_items: uniqueVisitorsDictionaryObject,
        target_rms_line_items: uniqueVisitorsDictionaryObject,
        freshdirect_criteo_line_items: uniqueVisitorsDictionaryObject
      }
    },
    impression_frequency: {
      title: 'Impressions Frequency',
      type: 'NUMERIC',
      pages: {
        target_campaigns: impressionFrequencyDictionaryObject,
        target_line_items: impressionFrequencyDictionaryObject,
        target_rms_line_items: impressionFrequencyDictionaryObject,
        freshdirect_criteo_line_items: impressionFrequencyDictionaryObject
      }
    },
    table_click_window: {
      title: 'Post Click Lookback Window',
      type: 'String',
      pages: {
        target_campaigns: {
          keyOrder: 37,
          table: {
            show: false
          },
          toolTipText:
            'Post click lookback window is the time between a sale and an ad event.'
        }
      }
    },
    table_view_window: {
      title: 'Post View Lookback Window',
      type: 'String',
      pages: {
        target_campaigns: {
          keyOrder: 38,
          table: {
            show: false
          },
          toolTipText:
            'Post view lookback window is the time between a view and an ad event.'
        }
      }
    },
    table_click_scope: {
      title: 'Post Click Product Match',
      type: 'String',
      pages: {
        target_campaigns: {
          keyOrder: 39,
          table: {
            show: false
          },
          toolTipText:
            'Post click product match is the relationship between the clicked and the purchased products.'
        }
      },
      callback(obj) {
        obj.minWidth = 320;
        return obj;
      }
    },
    table_view_scope: {
      title: 'Post View Product Match',
      type: 'String',
      pages: {
        target_campaigns: {
          keyOrder: 40,
          table: {
            show: false
          },
          toolTipText:
            'Post view product match is the relationship between the viewed and the purchased products.'
        }
      },
      callback(obj) {
        obj.minWidth = 320;
        return obj;
      }
    },
    incremental_fraction: {
      title: 'Incremental Fraction',
      toolTipText:
        'Estimated share of total paid sales that are truly incremental (would not be possible without advertising). This is an ML-driven metric.',
      pages: {
        target_line_items: {
          keyOrder: 25,
          table: {
            show: true
          },
          chart: {
            show: true
          }
        },
        target_keywords: {
          keyOrder: 25,
          table: {
            show: true
          },
          chart: {
            show: true
          }
        }
      },
      type: 'NUMERIC',
      unit: 'PERCENTAGE'
    },
    iroas: {
      title: 'IROAS',
      toolTipText:
        'Incremental Sales generated for each dollar of ad spend. This is an ML-driven metric that is currently in beta.',
      pages: {
        target_line_items: {
          keyOrder: 26,
          table: {
            show: true
          },
          chart: {
            show: true
          }
        },
        target_keywords: {
          keyOrder: 26,
          table: {
            show: true
          },
          chart: {
            show: true
          }
        }
      },
      type: 'NUMERIC',
      unit: 'CURRENCY'
    },
    incremental_sales: {
      title: 'Incremental Sales',
      toolTipText:
        'Amount of total paid sales that are truly incremental (would not be possible without advertising). This is an ML-driven metric which is currently in beta.',
      pages: {
        target_line_items: {
          keyOrder: 27,
          table: {
            show: true
          },
          chart: {
            show: true
          }
        },
        target_keywords: {
          keyOrder: 27,
          table: {
            show: true
          },
          chart: {
            show: true
          }
        }
      },
      type: 'NUMERIC',
      unit: 'CURRENCY'
    },
    incremental_fraction_band: {
      title: 'Incrementality Band',
      toolTipText:
        "Classification of performance on the basis of incrementality. 'High' band implies majority of paid sales are incremental and ‘Low’ band implies that majority of sales are not incremental.",
      pages: {
        target_line_items: {
          keyOrder: 28,
          table: {
            show: true
          }
        },
        target_keywords: {
          keyOrder: 28,
          table: {
            show: true
          }
        }
      }
    },
    keyword: {
      title: 'Keyword',
      toolTipText:
        'Keyword or phrase used to land on the search page where an ad creative rendered. This is the normalized keyword submitted by the advertisers. Criteo also refers this as the stemmed search term.',
      pages: {
        target_keywords: {
          keyOrder: 1,
          table: {
            show: true
          },
          pinned: 'left'
        }
      }
    },
    keyword_status: {
      title: 'Keyword Status',
      toolTipText:
        'Status of the keyword denoting whether the keyword is enabled or deleted',
      showToolTip: true,
      pages: {
        target_keywords: {
          keyOrder: 2,
          table: {
            show: true
          }
        }
      }
    },
    ...commonChartMetrics
  };
  copyMetricsForCM(
    '',
    'samsclub_api',
    dictionary,
    samsclub_apiOverrideDictionary,
    ['campaigns', 'keywords', 'ad_skus', 'search_terms']
  );

  criteoRetailers.forEach((retailer) => {
    copyMetricsForCM('target', retailer, dictionary);
  });

  const incrementalityMetrics = [
    'incremental_fraction',
    'iroas',
    'incremental_sales',
    'incremental_fraction_band'
  ];

  const metricsToRemoveForWalmartAdSkus = [
    'total_attributed_sales_curbside_pickup_and_delivery',
    'total_attributed_direct_click_sales',
    'total_attributed_related_click_sales',
    'total_attributed_brand_click_sales',
    'attributed_brand_view_sales',
    'attributed_direct_click_sales_shipping',
    'attributed_direct_click_sales_curbside_pickup_and_delivery',
    'attributed_related_click_sales_shipping',
    'attributed_related_click_sales_curbside_pickup_and_delivery',
    'attributed_brand_click_sales_shipping',
    'attributed_brand_click_sales_curbside_pickup_and_delivery',
    'attributed_direct_view_sales'
  ];

  const metricsToRemoveForWalmartCampaigns = [
    ...metricsToRemoveForWalmartAdSkus,
    'total_attributed_sales_shipping',
    'attributed_direct_view_sales'
  ];

  const metricsToRemoveForWalmartSearchTermsAndKeywords = [
    'attributed_direct_view_sales',
    'attributed_brand_view_sales',
    'attributed_direct_click_sales',
    'attributed_related_click_sales',
    'attributed_brand_click_sales'
  ];

  const metricsToRemoveForSamsclubAPISearchTermsAndKeywords = [
    'attributed_direct_view_sales',
    'attributed_brand_view_sales',
    'attributed_direct_click_sales',
    'attributed_related_click_sales',
    'attributed_brand_click_sales'
  ];

  const metricsToRemoveForSamsclubAPICampaignsAndSKUs = [
    ...metricsToRemoveForSamsclubAPISearchTermsAndKeywords,
    'total_attributed_sales_shipping',
    'total_attributed_sales_curbside_pickup_and_delivery',
    'attributed_direct_click_sales_shipping',
    'attributed_direct_click_sales_curbside_pickup_and_delivery',
    'attributed_related_click_sales_shipping',
    'attributed_related_click_sales_curbside_pickup_and_delivery',
    'attributed_brand_click_sales_shipping',
    'attributed_brand_click_sales_curbside_pickup_and_delivery'
  ];

  // remove the deprecated walmart metrics
  deleteMetricsForPage(
    dictionary,
    'search_terms',
    metricsToRemoveForWalmartSearchTermsAndKeywords
  );
  deleteMetricsForPage(
    dictionary,
    'keywords',
    metricsToRemoveForWalmartSearchTermsAndKeywords
  );
  deleteMetricsForPage(dictionary, 'ad_skus', metricsToRemoveForWalmartAdSkus);
  deleteMetricsForPage(
    dictionary,
    'campaigns',
    metricsToRemoveForWalmartCampaigns
  );

  deleteMetricsForPage(
    dictionary,
    'samsclub_api_campaigns',
    metricsToRemoveForSamsclubAPICampaignsAndSKUs
  );
  deleteMetricsForPage(
    dictionary,
    'samsclub_api_ad_skus',
    metricsToRemoveForSamsclubAPICampaignsAndSKUs
  );
  deleteMetricsForPage(
    dictionary,
    'samsclub_api_search_terms',
    metricsToRemoveForSamsclubAPISearchTermsAndKeywords
  );
  deleteMetricsForPage(
    dictionary,
    'samsclub_api_keywords',
    metricsToRemoveForSamsclubAPISearchTermsAndKeywords
  );
  // temporary code since freshDirect doesn't have support for incrementality.
  deleteMetricsForPage(
    dictionary,
    'freshdirect_criteo_line_items',
    incrementalityMetrics
  );
  deleteMetricsForPage(
    dictionary,
    'freshdirect_criteo_keywords',
    incrementalityMetrics
  );
  return {
    ...dictionary,
    ...addWalmartSpecificMetricsToDictionary(dictionary),
    ...addSamsClubAPISpecificMetricsToDictionary(dictionary)
  };
};

const copyMetrics = (
  fromTab,
  toTab,
  dictionaryObject,
  overrideDictionaryObject = {}
) => {
  for (const key of Object.keys(dictionaryObject)) {
    if (fromTab in dictionaryObject[key].pages) {
      // Check if the key is already present in the overrRide dictionary.
      // If yes, use that value and copy that value
      if (key in overrideDictionaryObject) {
        dictionaryObject[key].pages[toTab] = overrideDictionaryObject[key];
        continue;
      }
      dictionaryObject[key].pages[toTab] = dictionaryObject[key].pages[fromTab];
    }
  }
};

const copyMetricsForCM = (
  fromRetailer,
  toRetailer,
  dictionary,
  overrideDictionary = {},
  pages = ['campaigns', 'line_items', 'ad_skus', 'search_terms', 'keywords']
) => {
  pages.forEach((page) => {
    const fromTab = fromRetailer ? `${fromRetailer}_${page}` : page;
    copyMetrics(
      fromTab,
      `${toRetailer}_${page}`,
      dictionary,
      overrideDictionary
    );
  });
};

const addKeyToObject = (obj, keyToSet = 'key', ketToReplicate = 'title') => {
  Object.keys(obj).forEach((key) => {
    obj[key][keyToSet] = obj[key][ketToReplicate];
  });
};

const createArrayFromObject = (obj) => {
  const rArray = [];
  Object.keys(obj).forEach((key) => {
    obj[key].alias = key;
    rArray.push(obj[key]);
  });
  return rArray;
};
const getAllMetrics = (retailer) => {
  return allMetrics(retailer);
};

const getCurrentTabMetrics = (tab, retailer) => {
  const allMetrics = getAllMetrics(retailer);
  const allKeys = Object.keys(allMetrics);
  const currentTabMetrics = {};
  allKeys.forEach((key) => {
    if (tab in allMetrics[key].pages) {
      currentTabMetrics[key] = {
        ...allMetrics[key],
        ...allMetrics[key].pages[tab]
      };
    }
  });
  return currentTabMetrics;
};
const getAllCurrentChartMetrics = (tab) => {
  const currentTabMetrics = getCurrentTabMetrics(tab);
  const chartMetrics = {};
  for (const property in currentTabMetrics) {
    if (currentTabMetrics[property].chart) {
      chartMetrics[property] = currentTabMetrics[property];
    }
  }
  return chartMetrics;
};

const getCurrentCardMetrics = (tab, propertyToChck = 'chart.shown') => {
  const currentChartMetrics = getAllCurrentChartMetrics(tab);
  const shownChartMetrics = {};
  for (const property in currentChartMetrics) {
    if (get(currentChartMetrics[property], propertyToChck)) {
      shownChartMetrics[property] = currentChartMetrics[property];
    }
  }
  return shownChartMetrics;
};

const applyCommonValuesToKeys = (obj) => {
  addKeyToObject(obj);
  addKeyToObject(obj, 'tooltip', 'toolTipText');
  addKeyToObject(obj, 'label', 'title');
};

export default (tab, widget, retailer = '') => {
  if (widget === 'chart') {
    const _metricsShown = getCurrentCardMetrics(tab);
    const _defaultSelectMetric = getCurrentCardMetrics(tab, 'chart.selected');
    const _metricsList = getCurrentCardMetrics(tab, 'chart');
    applyCommonValuesToKeys(_metricsShown);
    applyCommonValuesToKeys(_defaultSelectMetric);
    applyCommonValuesToKeys(_metricsList);
    const metricsShown = createArrayFromObject(_metricsShown);
    const defaultSelectMetric = createArrayFromObject(_defaultSelectMetric);
    const metricsList = createArrayFromObject(_metricsList);
    return {
      metricsList,
      metricsShown,
      defaultSelectMetric
    };
  } else {
    return {
      allMetrics: getCurrentTabMetrics(tab, retailer)
    };
  }
};
