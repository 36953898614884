<template>
  <div
    v-if="showPopup"
    class="u-display-flex u-height-100 u-width-100 u-flex-align-items-center u-flex-justify-content-center rebranding-popup"
  >
    <div
      class="u-display-flex u-flex-align-items-center u-flex-direction-column u-position-relative u-flex-align-items u-flex-justify-content-center rebranding-popup-container"
    >
      <span
        class="u-color-grey-lighter u-font-size-3 u-cursor-pointer u-position-absolute"
        style="top: 16px; right: 16px"
        @click="closeRebrandingPopup()"
        >X</span
      >
      <span class="u-display-flex u-flex-align-items-center attention_imp_wrp">
        <img
          class="u-border-radius-xl"
          src="/images/extension-splash-message.png"
          usemap="#extensionMap"
        />
        <map name="extensionMap">
          <area
            target="_blank"
            shape="rect"
            coords="375,499,200,463"
            :href="redirectURL"
            alt="Chrome Redirect URL"
          />
        </map>
      </span>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
export default {
  data() {
    return {
      closePopup: false
    };
  },
  computed: {
    showPopup() {
      var showPopupConfig = Vue.options.filters.config_check(
        'feature.extensionLaunchSplashImg.showpopup'
      );
      if (this.closePopup || !showPopupConfig) {
        return false;
      }

      var rebrandingPopupSeen = this.getCookie(
        'ciq-commerceIQ-insight-popup-seen'
      );
      if (rebrandingPopupSeen !== 'true') {
        return true;
      } else {
        return false;
      }
    },
    redirectURL() {
      return (
        Vue.options.filters.config_check('feature.extension.chrome.id') ||
        'https://chrome.google.com/webstore/detail/commerceiq/knmkalnmhkhjpojmnlfkmfbdagpcommg'
      );
    }
  },
  created() {},
  methods: {
    closeRebrandingPopup() {
      Vue.nextTick(() => {
        this.closePopup = true;
      });
      this.setCookie('ciq-commerceIQ-insight-popup-seen', 'true', 10);
    },
    setCookie(cname, cvalue, expYears) {
      var date = new Date();
      date.setTime(date.getTime() + expYears * 365 * 24 * 60 * 60 * 1000);
      var expires = 'expires=' + date.toGMTString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    },
    getCookie(cname) {
      var name = cname + '=';
      var decodedCookie = decodeURIComponent(document.cookie);
      var cookieArray = decodedCookie.split(';');
      for (var i = 0; i < cookieArray.length; i++) {
        var cookieInstance = cookieArray[i];
        while (cookieInstance.charAt(0) === ' ') {
          cookieInstance = cookieInstance.substring(1);
        }
        if (cookieInstance.indexOf(name) === 0) {
          return cookieInstance.substring(name.length, cookieInstance.length);
        }
      }
      return '';
    }
  }
};
</script>
<style scoped lang="css">
.rebranding-popup {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
}

.rebranding-popup-container {
  padding: 0rem;
}

.attention_imp_wrp {
  max-width: 572px;
  max-height: 546px;
}
.attention_imp_wrp img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
</style>
