import transformer from '@/utils/services/data-transformer';

const config = {
  search: {
    placeholder: 'Search for SKUs with a word, phrase or ASIN(s)',
    dimensionName: 'search'
  },
  APIConfig: {
    brandsRequest: {
      cubeName: 'client_workbench_full_catalogue_latest_comp_data',
      getLatestAvailableInsteadOfRollup: false,
      timeseriesEnabled: false,
      pvpenabled: false,
      yoyenabled: false,
      measuresList: [
        'asin_name',
        'scrape_date',
        'latest_image_url',
        'latest_product_page_url'
      ],
      groupByDimensionsList: ['asin', 'asin_hyperlink', 'cache_link'],
      orderByList: [
        {
          dimension: 'asin',
          direction: 'DESC'
        }
      ],
      where: {
        dimensionNameValueList: []
      }
    },
    tagType: 'custom',
    customType: 'Custom',
    entityType: 'sku',
    paginationRequest: {
      limit: 20,
      offset: 0
    }
  },
  tableConstants(that) {
    return {
      gridApi: {
        context: {
          componentParent: that
        },
        rowClassRules: {},
        defaultColDef: {
          wrapText: true,
          autoHeight: true
        }
      },
      tableConfig: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      }
    };
  },

  generateColumns(that) {
    const colDefs = this.columnDefination.map((column, index) => {
      let columnMetaObject = {};
      const columnDefinition = {
        name: column.dimensionColumn,
        uiField: {
          uiLabel: column.dimensionLabel,
          uiOrder: index + 1,
          uiType: 'custom',
          metadata: {
            tableColumnName: column.dimensionColumn,
            showOnUi: true
          }
        }
      };
      columnMetaObject = this[column.dimensionColumn](column, that);
      columnDefinition.uiField.metadata = {
        ...columnDefinition.uiField.metadata,
        ...columnMetaObject
      };
      return columnDefinition;
    });
    const columnDefs = transformer.getColumnDefinition(colDefs);
    return columnDefs.displayColConfigs;
  },

  tag(columnContext, that) {
    return {
      widget: 'DynamicCellComponent',
      dynamicCellComponentParams: {
        componentName: 'TagsViewCell',
        paramsToProps: (params) => {
          return {
            allSuggestionEntities: [],
            tagsList: params.data.tags,
            interactiveState: 'view',
            enableStyleApi: true,
            dropdownOptions: [
              { title: 'Manage Custom Catalog', key: 'manage_custom_catalog' },
              { title: 'Remove Custom Catalog', key: 'remove_custom_catalog' }
            ]
          };
        },
        eventMap: {
          onDropdownOptionSelect: 'customCatalogDropdown'
        }
      },
      width:
        Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        ) - 416
    };
  },

  asin(columnContext, that) {
    return {
      widget: 'DynamicCellComponent',
      width: 400,
      dynamicCellComponentParams: {
        componentName: 'rb-sku',
        paramsToProps: (params) => {
          return {
            title: params.data.asin_name,
            asin: params.data.asin,
            'image-url': params.data.image_url,
            'show-image': true,
            'product-page-url': params.data.product_page_url
          };
        }
      }
    };
  },
  columnDefination: [
    {
      dimensionLabel: 'SKU Details',
      dimensionColumn: 'asin'
    },
    {
      dimensionLabel: 'Custom Catalog',
      dimensionColumn: 'tag'
    }
  ]
};

export default config;
