<template>
  <!-- <div class="sku_details u-display-flex u-flex-direction-row u-font-size-1 u-height-100 u-overflow-auto"> -->
  <section
    :key="uiKey"
    :class="{ 'u-height-100': isLoading || showError }"
  >
    <div
      class="u-display-flex u-flex-direction-column u-spacing-ph-l sticky u-header-box-shadow u-spacing-mb-0"
      style="z-index: 100"
    >
      <div class="u-display-flex u-flex-direction-column">
        <PageTitle
          :title="skuDetails.asinName || 'SKU Details'"
          :is-bookmark-require-title="true"
          :show-bookmark="skuDetails.asinName ? true : false"
        />
        <rb-asin
          v-if="skuDetails.entity"
          class="u-spacing-ml-xl u-spacing-mt-s"
          :asin="skuDetails.entity"
          :product-page-url="skuDetails.productUrl"
        />
      </div>
      <div class="u-spacing-pt-s">
        <DataIssueBanner class="banner-with-negative-margin" />
      </div>
    </div>
    <div
      v-if="!isLoading && !showError"
      class="u-display-flex u-spacing-p-m sku_details u-maxWidth"
    >
      <div
        v-for="(widget, $index) in widgetsData"
        :key="$index"
        class="u-display-flex"
        :class="
          widget.widgetName === 'chartingWb'
            ? 'u-flex-1 u-height-100 u-sticky-on-scroll'
            : ''
        "
      >
        <component
          :is="widget.widgetName"
          :config="widget"
          :sku-i-d="$route.params.id"
          :page="page"
          metric-refresh-page-id="SDP"
        />
      </div>
    </div>
    <div
      v-if="isLoading"
      class="u-height-100 u-position-relative"
    >
      <loader
        class="fill--parent u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </div>
    <div
      v-if="!isLoading && showError"
      class="u-display-flex u-spacing-p-m sku_details u-maxWidth u-height-100 u-flex-justify-content-center u-flex-align-items-center u-flex-direction-column"
    >
      <div class="u-display-flex noskuImage_container">
        <img
          src="/images/no_sku_found.png"
          class="u-width-100"
          alt="SKU NO found"
        />
      </div>
      <div class="u-font-size-3 u-spacing-mt-xl u-font-weight-600">
        <span class="u-color-grey-base u-spacing-mr-s"
          >Unable to find this SKU in your catalog!</span
        >
        <span class="u-color-blue-base">Please search for a valid SKU ID.</span>
      </div>
    </div>
  </section>
</template>

<script>
import loader from '@/components/basic/loader';
import skuDetailInfo from './skuDetailInfo';
import chartingWb from '@/components/pages/skuDetails/chartingWb.vue';
import HttpService from '@/utils/services/http-service';
import { eventBus } from '@/utils/services/eventBus';

export default {
  name: 'SkuDetails',
  owner: 'Rajan V',
  components: {
    skuDetailInfo,
    loader,
    chartingWb
  },
  data() {
    return {
      uiKey: 0,
      widgetsData: null,
      isLoading: true,
      page: 'skuDetails',
      showError: false,
      skuDetails: {}
    };
  },
  watch: {
    '$route.params.id': function () {
      this.init();
    },
    '$store.getters.getSelectedCurrency': function () {
      this.uiKey = this.uiKey + 1;
    }
  },
  beforeCreate() {},
  mounted() {},
  created() {
    eventBus.$emit('sideMenuToggle', true);
    this.init();
  },
  destroyed() {
    eventBus.$emit('sideMenuToggle', false);
  },
  methods: {
    init() {
      this.$logger.logEvent({
        eventType: 'sku_detail_requested',
        agent: 'Webapp',
        eventContext: this.$route.params.id
      });
      this.isLoading = true;
      const request = {
        entityType: 'SKU',
        entity: this.$route.params.id
      };
      if (this.$route.params && this.$route.params.id) {
        HttpService.post('DASHBOARD_SERVICE', request, {
          append: '/extension/sku/getXPath'
        }).then(
          (response) => {
            if (response.data.success) {
              this.skuDetails = response.data;
              this.fetchData();
            } else {
              this.isLoading = false;
              this.showError = true;
            }
          },
          (error) => {
            this.isLoading = false;
            this.$snackbar.open({
              message: 'Something Went Wrong !!!' || JSON.stringify(error),
              duration: 5000,
              buttonColor: '#f5d908',
              actionText: ' '
            });
          }
        );
      } else {
        this.$snackbar.open({
          message: 'SKU id is missing in the request',
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
      }
    },
    fetchData() {
      HttpService.post(
        'DASHBOARD_SERVICE',
        { page: this.page },
        { append: '/page/widgets/list' }
      )
        .then((response) => {
          if (response.status === 200) {
            this.widgetsData = response.data.widgets;
          }
        })
        .catch((error) => {
          this.$snackbar.open({
            message: 'Something Went Wrong !!!' || JSON.stringify(error),
            duration: 5000,
            buttonColor: '#f5d908',
            actionText: ' '
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="css" scoped>
.sku-info-holder {
  width: 348px;
}

.u-sticky-on-scroll {
  position: sticky;
  top: 16px;
}

.u-maxWidth {
  max-width: 1440px;
  margin: auto;
}

.noskuImage_container {
  width: 50%;
  height: 320px;
}
.noskuImage_container img {
  object-fit: contain;
}
</style>
