<template>
  <section>
    <ul
      class="u-display-flex u-bg-color-grey-white u-bg-color-grey-white u-border-width-s"
      :class="computedClasses"
      :style="tabGap"
    >
      <li
        v-for="(tab, index) in tabsList"
        :key="index"
        class="tab"
        :class="{
          underline: tab === currentTab && underLine,
          'u-spacing-pt-l': size === 'm'
        }"
      >
        <span
          class="u-cursor-pointer"
          :class="{
            'u-color-blue-base u-font-weight-bold': tab === currentTab,
            'u-font-size-5': size === 'm',
            'u-font-size-7': size === 's'
          }"
          :data-cy="`tabName-${tab}`"
          @click="handleTabsClick(tab)"
        >
          {{ tab }}
        </span>
      </li>
      <!-- replace with component -->
    </ul>
  </section>
</template>

<script>
export default {
  name: 'GenericTabsComponent',
  props: {
    defaultTabIndex: {
      default: 0,
      type: Number
    },
    tabsList: {
      type: Array,
      default() {
        return [];
      }
    },
    underLine: {
      type: Boolean,
      default: true
    },
    shadow: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'm'
    },
    tabStyles: {
      type: Object,
      default() {
        return {
          gap: '2.6rem'
        };
      }
    }
  },
  data() {
    return {
      currentTab: null
    };
  },
  computed: {
    tabGap() {
      return {
        '--tab-gap': this.tabStyles.gap
      };
    },
    computedClasses() {
      return {
        shadow: this.shadow,
        'u-spacing-ph-l': this.size === 'm'
      };
    }
  },
  created() {
    this.currentTab = this.tabsList[this.defaultTabIndex];
    this.$emit('currentTab', this.currentTab);
  },
  methods: {
    handleTabsClick(selectedTab) {
      this.currentTab = selectedTab;
      this.$emit('handleTabsClick', this.currentTab);
    }
  }
};
</script>

<style lang="css" scoped>
ul {
  gap: var(--tab-gap);
  flex-wrap: wrap;
  row-gap: 0;
}

.tab {
  padding-inline: 1.5rem;
}

.underline {
  padding-bottom: 1rem;
  border-bottom: 0.2rem solid #007cf6;
}

.shadow {
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
</style>
