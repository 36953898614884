import dictionary from './columnConfig.js';
import { formatter } from '@/utils/helpers/formatter.js';
// import { commons } from '../commons';
import cellRenderer from '@/components/globals/dataTable/tableCellComponent.js';
import multiRetailerActionLogsConfig from '@/components/pages/action_logs/multiRetailerActionLogsConfig.js';
// import userCellRenderer from './userCell'
// import userCellRenderer from '@/components/globals/dataTable/'
var map = {};
var units = {};
var axes = {};

const namespace = 'actionLogs/';

for (var i in dictionary.map) {
  var configuredUnit = dictionary.map[i].unit;
  var unit = null;
  map[i] = dictionary.map[i].title;
  if (configuredUnit === 'CURRENCY') {
    unit = {
      pre: 'currency'
    };
  } else if (configuredUnit === 'PERCENTAGE') {
    unit = {
      suff: '%'
    };
  }
  units[map[i]] = unit;
  if (configuredUnit === 'CURRENCY') {
    axes[map[i]] = 'y';
  } else {
    axes[map[i]] = 'y2';
  }
}

/**
 * generate table column from the dictonary.js - Hardcoded column defn for ActionLogs page. Same Column definition will be used for CSV download(field and title)
 * To Disable Sorting for specific column - Disable enableSorting key in headerComponentParams
 */
var columnDefs = [];
const downloadConfig = [];
function getColumnDefinition(vueRef) {
  var _columnDefs = [];
  for (const i in dictionary.map) {
    if (
      (Array.isArray(dictionary.map[i].page) &&
        (dictionary.map[i].page.indexOf('common') !== -1 ||
          dictionary.map[i].page.indexOf('campaign') !== -1)) ||
      dictionary.map[i].page === 'common' ||
      dictionary.map[i].page === 'campaign'
    ) {
      const obj = {};
      obj.field = i;
      obj.title = dictionary.map[i].title;
      // obj.suppressSizeToFit = true;
      obj.headerComponentFramework = 'genericTableHeader';
      // obj.enableSorting = true;
      let keyType = 'string';
      if (dictionary.map[i].type === 'NUMERIC') {
        keyType = dictionary.map[i].unit ? dictionary.map[i].unit : 'NUMERIC';
      }
      if (dictionary.map[i].type === 'date') {
        keyType = dictionary.map[i].type;
      }
      obj.cellRendererParams = {
        keyType: keyType,
        formatterFn: formatter
      };
      obj.cellRenderer = cellRenderer;
      if (dictionary.map[i].type === 'user') {
        delete obj.cellRenderer;
        obj.cellRendererFramework = 'userCell';
        obj.minWidth = 130;
      }
      if (dictionary.map[i].type === 'icon') {
        delete obj.cellRenderer;
        obj.cellRendererFramework = 'iconTableCell';
        obj.cellRendererParams.displayIcon = 'info-circle-fill';
        obj.cellRendererParams.onHoverClass = 'iconColorBlueBase';
        obj.cellRendererParams.iconSize = 'medium';
        obj.cellRendererParams.extraClass = 'icon-active-blue-base';
        obj.minWidth = 70;
        obj.maxWidth = 70;
      }
      if (dictionary.map[i].type === 'status') {
        delete obj.cellRenderer;
        obj.cellRendererFramework = 'statusCell';
        obj.minWidth = 90;
        obj.maxWidth = 90;
      }
      if (dictionary.map[i].title === 'Entity Type') {
        obj.minWidth = 100;
      }
      if (dictionary.map[i].title === 'Entity') {
        obj.minWidth = 130;
      }
      obj.headerName = dictionary.map[i].title;
      obj.headerComponentParams = {
        enableSorting: true
      };
      if (obj.headerName === 'Date') {
        obj.headerComponentParams.sort = 'desc';
      }
      obj.keyOrder = dictionary.map[i].campaign_keyOrder;
      dictionary.map[i].pinned && (obj.pinned = dictionary.map[i].pinned);
      // dictionary.map[i].pinned && (delete obj.cellRendererFramework);
      if (dictionary.map[i].toolTipText) {
        obj.headerComponentParams.toolTipText = dictionary.map[i].toolTipText;
      }
      obj.headerComponentParams.keyType = keyType;
      if (dictionary.map[i].showOnUi) {
        _columnDefs.push(obj);
      }
      if (dictionary.map[i].downloadable) {
        downloadConfig.push(obj);
      }
      // _columnDefs[0].minWidth = 350;
      // _columnDefs[0].pinned = 'left';
    }
  }
  /**
   * Sort based on key order
   */
  columnDefs = _columnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });
  return columnDefs;
}

export default {
  config: (that) => {
    let retailer = that.$store.getters.getRetailer;
    return {
      namespace: namespace,
      getColumnDefinition: getColumnDefinition,
      filters: {
        emit: 'actionLogsFilterApplied',
        hasSearch: true,
        search: {
          width: '240px',
          enable: true,
          emit: 'actionLogsSearchTriggered',
          placeholder: 'Search for a Campaign, Keyword, Search terms or Asin'
        }
      },
      filterCards: {
        emit: 'actionLogsCardFilterApplied'
      },
      widgets: {
        widget1: {},
        widget2: {
          meta: {
            type: 'table',
            key: 'campaignsTable',
            primaryKey: 'campaign_id',
            action: namespace + 'getWorkbenchActionLogTableData',
            listen: {
              actionLogsCardFilterApplied: {
                action: namespace + 'getWorkbenchActionLogTableData',
                transform: function (config, data, that) {
                  config.body.APIConfig.page = 1;
                  return config;
                }
              },
              actionLogsFilterApplied: {
                action: namespace + 'getWorkbenchActionLogTableData',
                transform: function (config) {
                  config.body.APIConfig.page = 1;
                  return config;
                }
              },
              actionLogsSearchTriggered: {
                action: namespace + 'getWorkbenchActionLogTableData',
                transform: function (config, data, that) {
                  config.body.APIConfig.page = 1;
                  if (data !== undefined) {
                    config.meta.localFilters = [];
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'search',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    }
                  }
                  if (data !== undefined) {
                    config.meta.localCardFilters = [];
                    if (data.length > 0) {
                      config.meta.localCardFilters.push({
                        dimensionName: 'entity',
                        operator: 'ILIKE',
                        dimensionValue: data
                      });
                    }
                  }
                  return config;
                }
              }
            },
            paginationAction: 'workbenchCampaignsPaginationTriggered'
          },
          header: {
            show: false
          },
          footer: {
            show: false
          },
          body: {
            APIConfig: multiRetailerActionLogsConfig.APIConfig[retailer],
            APIConfigFilterCards:
              multiRetailerActionLogsConfig.APIConfigFilterCards[retailer],
            download: {
              fileName: 'ActionLogsData',
              columnMap: downloadConfig,
              action: namespace + 'downloadActionLogsTableData'
            },
            gridOptions: {
              suppressColumnVirtualisation: true,
              onRowClicked: that.openSidePanel,
              context: {
                componentParent: this
              },
              domLayout: 'normal'
            },
            columnDefs: getColumnDefinition(that)
          }
        }
      }
    };
  }
};
