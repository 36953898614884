<template>
  <div class="u-display-flex u-flex-justify-content-center">
    <template v-for="place in computedPlaces">
      <div
        v-if="topPerformers[place - 1]"
        :id="'place-' + place"
        :key="place"
        class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-end u-width-100 u-height-100 u-spacing-mh-s"
        :style="{ overflowWrap: 'anywhere' }"
      >
        <div
          class="stand u-border-radius-s u-width-100 u-position-relative u-display-flex u-flex-align-items-center u-flex-direction-column u-flex-justify-content-flex-end u-spacing-pv-s"
          :style="{ backgroundColor: colors[place - 1] }"
        >
          <div
            class="image-wrapper u-spacing-p-s u-position-absolute u-border-radius-xxxl u-border-all u-display-flex u-flex-justify-content-center u-flex-align-items-center"
            :class="{ clientFlag: isClientFlag(place) }"
            :style="{
              backgroundColor: colors[place - 1],
              borderColor: isClient(
                topPerformers[place - 1].client_flag,
                colorsDark[place - 1]
              ),
              'border-width': isClientFlag(place) ? 'thick' : ''
            }"
          >
            <span
              v-if="isFirst(place)"
              class="image-badge u-display-flex u-flex-align-items-center u-flex-justify-content-center u-position-absolute u-icon-size-1"
              :style="{ backgroundColor: colors[place - 1] }"
            >
              <img
                src="https://cdn.rboomerang.com/cva/Leader_Crown.svg"
                class="u-icon-size-2"
            /></span>
            <img
              v-if="!initials[topPerformers[place - 1].name]"
              :src="topPerformers[place - 1].image"
              class="u-border-radius-xxxl u-width-100"
              @error="handleNoImage(topPerformers[place - 1].name)"
            />
            <div
              v-else
              class="u-border-radius-xxxl u-width-100 u-height-100 u-display-flex u-flex-align-items-center u-flex-justify-content-center u-font-size-2 u-text-case-upper u-color-white-base"
              :style="{ backgroundColor: colorsDark[place - 1] }"
            >
              {{ initials[topPerformers[place - 1].name] }}
            </div>
          </div>
          <div
            class="u-display-flex desc-container u-flex-direction-column u-flex-align-items-center"
            :class="{
              'u-flex-justify-content-space-between': (
                topPerformers[place - 1].leadingIn || ''
              ).length,
              'u-flex-justify-content-space-evenly': !(
                topPerformers[place - 1].leadingIn || ''
              ).length
            }"
          >
            <span
              class="u-font-size-6 u-text-case-title u-text-align-center"
              :style="{
                color: isClient(
                  topPerformers[place - 1].client_flag,
                  defaultColor
                )
              }"
              >{{ topPerformers[place - 1].name }}</span
            >
            <div
              class="badge u-border-radius-xxxl u-font-size-4 u-font-weight-bolder u-display-flex u-flex-justify-content-center u-flex-align-items-center"
              :style="{ backgroundColor: colorsDark[place - 1] }"
            >
              {{ place }}
            </div>
            <div
              v-if="(topPerformers[place - 1].leadingIn || '').length"
              class="u-display-flex u-flex-direction-column u-text-align-center u-font-size-7"
            >
              <span>{{ leadingText }}</span>
              <div
                class="u-spacing-mt-xs u-display-flex u-flex-align-items-center u-flex-justify-content-center u-text-case-title"
              >
                <img src="https://cdn.rboomerang.com/cva/Star.svg" />
                <span
                  class="u-color-green-base u-spacing-pl-xs u-font-weight-600"
                  >{{ topPerformers[place - 1].leadingIn }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  props: {
    topPerformers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      initials: {},
      colors: ['#f1f8f2', '#fff8eb', '#fbebfd'],
      colorsDark: ['#3da95d', '#fea800', '#bd11e0'],
      leadingText: 'Leading in:',
      defaultColor: '#2b333b',
      clientFlag: '#007CF6'
    };
  },
  computed: {
    computedPlaces() {
      if (this.topPerformers.length < 3) {
        return [1, 2];
      } else {
        return [2, 1, 3];
      }
    }
  },
  methods: {
    isClientFlag(place) {
      return this.topPerformers[place - 1].client_flag === 'client';
    },
    isClient(clientFlag, defaultColor) {
      return clientFlag === 'client' ? this.clientFlag : defaultColor;
    },
    handleNoImage(brandName) {
      const initials = brandName
        .split(' ')
        .map((item) => item[0])
        .slice(0, 2)
        .join('');
      Vue.set(this.initials, brandName, initials);
    },
    isFirst(rank) {
      if (rank === 1) return true;
    }
  }
};
</script>

<style lang="css" scoped>
.u-flex-justify-content-space-evenly {
  justify-content: space-evenly;
}
.badge {
  width: 22px;
  height: 22px;
  color: #fff;
  text-align: center;
}
.image-wrapper {
  width: 60px;
  height: 60px;
  bottom: calc(100% - 20%);
}
.image-wrapper img {
  height: 100%;
  object-fit: contain;
  background-color: #fff;
}
.image-wrapper .image-badge {
  top: -20%;
  border-radius: 50%;
  z-index: 1;
}
.desc-container {
  height: 80%;
}

#place-2 {
  max-width: 40%;
}
#place-2 .stand {
  height: 60%;
}
#place-2 .stand .image-wrapper.clientFlag {
  width: 75px;
  height: 75px;
}
#place-2 .stand .image-wrapper {
  width: 70px;
  height: 70px;
}

#place-1 {
  max-width: 40%;
}
#place-1 .stand {
  height: 70%;
}
#place-1 .stand .image-wrapper.clientFlag {
  width: 85px;
  height: 85px;
}
#place-1 .stand .image-wrapper {
  width: 80px;
  height: 80px;
}

#place-3 .stand {
  height: 50%;
}
#place-3 .stand .image-wrapper.clientFlag {
  width: 65px;
  height: 65px;
}
</style>
