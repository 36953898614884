<!--
Values expected in props for rendering

icon:             icon to be displayed
iconSize:         size of icon, small/medium/large etc
iconClickUrl:     redirect url on icon click
iconClasses:      CSS classes to apply to the icon
text:             Main text to be displayed alongside icon
textClasses:      CSS classes to apply to the text
tippyText:        v-tippy text to display on the icon
backgroundColor:  Background color of the cell
-->
<template>
  <div
    :style="{ background: (keyTransformMap[text] || {}).color }"
    class="u-height-100 u-display-flex u-flex-justify-content-space-between u-text-overflow-ellipsis u-width-100 u-height-100 u-flex-align-items-center u-spacing-pl-m u-spacing-pr-m status-text-with-icon"
  >
    <div class="u-display-inline-flex u-flex-align-items-center">
      <span
        v-tippy
        :class="textClasses"
        :title="(keyTransformMap[text] || {}).tooltip || naTooltip"
      >
        {{ (keyTransformMap[text] || {}).text || naText }}
      </span>
    </div>
    <div
      v-tippy
      class="u-display-inline-flex"
      :title="tippyText"
    >
      <a
        v-if="iconClickUrl"
        :href="iconClickUrl"
        target="_blank"
      >
        <rb-icon
          :icon="icon"
          :class="[iconSizeClass, iconClasses]"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusTextWithIconAndBg',
  components: {},
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'cached'
    },
    iconClickUrl: {
      type: String,
      default: null
    },
    iconSize: {
      type: String,
      default: 'medium'
    },
    tippyText: {
      type: String,
      default: 'View Cached Page'
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    iconClasses: {
      type: String,
      default: 'u-cursor-pointer u-color-grey-lighter'
    },
    textClasses: {
      type: String,
      default: 'u-font-size-6 u-line-height-2 u-color-grey-base'
    },
    naText: {
      type: String,
      default: 'NA'
    },
    naTooltip: {
      type: String,
      default: ''
    },
    keyTransformMap: {
      type: Object,
      default: () => {
        return {
          OUT_OF_STOCK: {
            color: 'rgba(255, 168, 0, 0.1)',
            text: 'Out Of Stock',
            tooltip: ''
          },
          AVAILABLE: {
            color: 'rgba(62, 169, 94, 0.1)',
            text: 'Available',
            tooltip: ''
          },
          UNAVAILABLE: {
            color: 'rgba(215, 38, 61, 0.1)',
            text: 'Unavailable',
            tooltip: ''
          }
        };
      }
    }
  },
  computed: {
    iconSizeClass() {
      return 'rb-icon--' + this.iconSize;
    }
  }
};
</script>
