// Base configs
export const hourlyBidderBase = {
  title: 'Hourly Bidder',
  icon: 'hourly_bidder',
  path: 'advertising/hourly_bidder',
  config: undefined,
  page: 'pages',
  productRoles: ['MarketingIQ'],
  configCheckRequired: true
};

export const digitalShelfBase = {
  title: 'digital shelf',
  icon: '',
  redirect: '',
  defaultRoute: '/insights/share-of-voice',
  isCollapsed: false,
  configCheckRequired: true,
  children: []
};

export const advertisingBase = {
  title: 'Advertising',
  icon: 'topMovers',
  productRoles: ['MarketingIQ'],
  redirect: '',
  defaultRoute: '/strategyList',
  isCollapsed: false,
  configCheckRequired: true,
  children: []
};

export const campaignManagementBase = {
  title: 'Campaign Management',
  icon: 'campaign_management',
  path: 'advertising/campaign_management',
  redirect: '',
  productRoles: ['MarketingIQ'],
  configCheckRequired: true,
  page: 'pages',
  config: undefined,
  isCollapsed: true,
  children: []
};
// Base configs ends here

// Specific tabs configs start here

export const reportsTabBase = {
  title: 'Reports',
  icon: 'reports',
  path: 'reports',
  redirect: '',
  config: undefined,
  productRoles: ['SalesIQ', 'MarketingIQ'],
  configCheckRequired: true,
  page: 'pages'
};

export const budgetOptimizerTabBase = {
  title: 'Budget Optimizer',
  icon: 'budget_optimizer',
  path: 'advertising/budget_optimizer',
  config: undefined,
  page: 'pages',
  productRoles: ['MarketingIQ'],
  configCheckRequired: true
};

export const campaignOptimizerTabBase = {
  title: 'Campaign Optimizer',
  icon: 'campaign_optimizer',
  redirect: '',
  path: 'advertising/campaign_optimizer',
  config: undefined,
  productRoles: ['MarketingIQ'],
  configCheckRequired: true,
  page: 'pages'
};
export const marketInsightsTabBase = {
  title: 'Market Insights',
  icon: 'market_insights',
  path: 'digital_shelf/market_insights',
  redirect: '',
  config: 'sov_v2',
  overrideConfig: undefined,
  productRoles: ['SalesIQ'],
  configCheckRequired: true,
  page: 'insights'
};

export const campaignsTabBase = {
  title: 'Campaigns',
  path: 'advertising/campaign_management/campaigns',
  icon: '',
  redirect: '',
  productRoles: ['MarketingIQ'],
  configCheckRequired: true,
  config: undefined,
  page: 'pages'
};

export const advertisingTabBase = {
  title: 'Advertising',
  icon: 'topMovers',
  productRoles: ['MarketingIQ'],
  redirect: '',
  defaultRoute: '/advertising',
  isCollapsed: false,
  configCheckRequired: true,
  children: []
};

export const campaignOptimizerTab = {
  title: 'Campaign Optimizer',
  icon: 'campaign_optimizer',
  redirect: '',
  path: 'advertising/campaign_optimizer',
  config: undefined,
  productRoles: ['MarketingIQ'],
  configCheckRequired: true,
  page: 'pages'
};

export const campaignManagementTabBase = {
  title: 'Campaign Management',
  icon: 'campaign_management',
  path: 'advertising/campaign_management',
  redirect: '',
  productRoles: ['MarketingIQ'],
  configCheckRequired: true,
  page: 'pages',
  config: 'campaign_management',
  isCollapsed: true,
  children: []
};

export const campaignManagementChildBase = {
  title: undefined,
  path: undefined,
  icon: '',
  redirect: '',
  productRoles: ['MarketingIQ'],
  configCheckRequired: true,
  config: undefined,
  page: 'pages'
};

export const lineItemsTabBase = {
  title: 'Line Items',
  path: 'advertising/campaign_management/line_items',
  icon: '',
  redirect: '',
  productRoles: ['MarketingIQ'],
  configCheckRequired: true,
  config: undefined,
  page: 'pages'
};

export const adSkusTabBase = {
  title: 'Ad SKUs',
  path: 'advertising/campaign_management/ad_skus',
  icon: '',
  redirect: '',
  productRoles: ['MarketingIQ'],
  configCheckRequired: true,
  config: undefined,
  page: 'pages'
};

export const searchTermsTabBase = {
  title: 'Search Terms',
  path: 'advertising/campaign_management/search_terms',
  icon: '',
  redirect: '',
  productRoles: ['MarketingIQ'],
  configCheckRequired: true,
  config: undefined,
  page: 'pages'
};

export const keywordsTabBase = {
  title: 'Keywords',
  path: 'advertising/campaign_management/keywords',
  icon: '',
  redirect: '',
  productRoles: ['MarketingIQ'],
  configCheckRequired: true,
  config: undefined,
  page: 'pages'
};

export const marketShareLiteTabBase = {
  title: 'Market Share',
  icon: 'market_share',
  path: 'digital_shelf/market_share_lite',
  redirect: '',
  config: undefined,
  productRoles: ['SalesIQ'],
  configCheckRequired: true,
  page: 'insights',
  newSection: true,
  overrideConfig: undefined
};

// Specific tabs configs ends here

export const getPromoteIqRetailerMenuArray = (
  retailer = 'kroger_promoteiq'
) => {
  return [
    {
      ...reportsTabBase,
      config: `${retailer}Reports`
    },
    {
      ...digitalShelfBase,
      children: [
        {
          ...marketInsightsTabBase,
          overrideConfig: `${retailer}_super_sov_v2`
        },
        {
          ...marketShareLiteTabBase,
          overrideConfig: `${retailer}_super_market_share_lite`
        }
      ]
    },
    {
      ...advertisingTabBase,
      children: [
        {
          ...campaignOptimizerTab,
          config: `campaign_optimizer_${retailer}`
        },

        {
          ...hourlyBidderBase,
          config: `${retailer}_strategies`
        },
        {
          ...campaignManagementTabBase,
          children: [
            {
              ...campaignManagementChildBase,
              title: 'Campaigns',
              path: 'advertising/campaign_management/campaigns',
              config: `${retailer}_campaigns`
            },
            {
              ...campaignManagementChildBase,
              title: 'Ad SKUs',
              path: 'advertising/campaign_management/ad_skus',
              config: `${retailer}_ad_skus`
            },
            {
              ...campaignManagementChildBase,
              title: 'Categories',
              path: 'advertising/campaign_management/categories',
              config: `${retailer}_categories`
            },
            ...(retailer === 'kroger_promoteiq'
              ? [
                  {
                    ...campaignManagementChildBase,
                    title: 'Regions',
                    path: 'advertising/campaign_management/regions',
                    config: `${retailer}_regions`
                  }
                ]
              : []),
            ...(retailer === 'chewy_promoteiq'
              ? [
                  {
                    ...campaignManagementChildBase,
                    title: 'Keywords',
                    path: 'advertising/campaign_management/keywords',
                    config: `${retailer}_keywords`
                  }
                ]
              : [])
          ]
        }
      ]
    }
  ];
};

export const citrusRetailRoutes = (retailer) => [
  {
    ...reportsTabBase,
    config: `${retailer}_citrusReports`
  },
  {
    ...digitalShelfBase,
    children: [
      {
        ...marketInsightsTabBase,
        overrideConfig: `${retailer}_citrus_super_sov_v2`
      }
    ]
  },
  {
    ...advertisingTabBase,
    children: [
      {
        ...campaignOptimizerTab,
        config: `campaign_optimizer_${retailer}_citrus`
      },
      {
        ...campaignManagementTabBase,
        config: `${retailer}_citrus_campaign_management`,
        children: [
          {
            ...campaignManagementChildBase,
            title: 'Campaigns',
            path: 'advertising/campaign_management/campaigns',
            config: `${retailer}_citrus_campaigns`
          },
          {
            ...campaignManagementChildBase,
            title: 'Ad SKUs',
            path: 'advertising/campaign_management/ad_skus',
            config: `${retailer}_citrus_ad_skus`
          },
          {
            ...campaignManagementChildBase,
            title: 'Keywords',
            path: 'advertising/campaign_management/keywords',
            config: `${retailer}_citrus_keywords`
          }
        ]
      }
    ]
  }
];

export const getCriteoRetailerRoutes = (retailer) => [
  {
    ...reportsTabBase,
    config: `${retailer}Reports`
  },
  ...(retailer === 'target'
    ? [
        {
          title: 'Strategy & Planning',
          icon: '',
          redirect: '',
          defaultRoute: 'strategy_and_planning/category_leaderboard',
          isCollapsed: false,
          configCheckRequired: true,
          children: [
            {
              title: 'Ecommerce Command Centre',
              icon: 'category_landscape',
              path: 'strategy_and_planning/ecommerce_command_centre',
              redirect: '',
              config: 'remcc',
              productRoles: ['SalesIQ', 'MarketingIQ'],
              configCheckRequired: true,
              page: 'insights',
              newSection: true
            }
          ]
        }
      ]
    : []),
  {
    ...digitalShelfBase,
    children: [
      {
        ...marketInsightsTabBase,
        overrideConfig:
          retailer === 'meijer'
            ? 'meijer_criteo_super_sov_v2'
            : `${retailer}_super_sov_v2`
      },
      ...(retailer === 'target'
        ? [
            {
              ...marketShareLiteTabBase,
              overrideConfig: `${retailer}_super_sov_v2`
            }
          ]
        : [])
    ]
  },
  {
    ...advertisingBase,
    children: [
      {
        ...campaignOptimizerTabBase,
        config: ['samsclub', 'meijer'].includes(retailer)
          ? `campaign_optimizer_${retailer}`
          : `${retailer}_campaign_optimizer`
      },
      {
        ...budgetOptimizerTabBase,
        config: `${retailer}_budget_planner`
      },
      {
        ...hourlyBidderBase,
        config: retailer === 'target' ? 'strategies' : `${retailer}_strategies`
      },
      {
        ...campaignManagementBase,
        config: ['samsclub', 'meijer'].includes(retailer)
          ? 'campaign_management'
          : `${retailer}_campaign_management`,
        children: [
          {
            ...campaignsTabBase,
            config: `${retailer}_campaigns`
          },
          {
            ...lineItemsTabBase,
            config: `${retailer}_line_items`
          },
          {
            ...adSkusTabBase,
            config: `${retailer}_ad_skus`
          },
          // temporary hotfix since keywords tab is breaking for these retailers
          ...(['walgreen_criteo', 'cvs_criteo'].includes(retailer)
            ? []
            : [
                {
                  ...keywordsTabBase,
                  config: `${retailer}_keywords`
                }
              ])
        ]
      }
    ]
  }
];
