import Vue from 'vue';
import dictionary from './dashboardDictionary';
import HttpLayer from '@/utils/services/http-layer';
import { cloneDeep, groupBy } from 'lodash';
import transformer from '@/utils/services/data-transformer';
import { multiRetailerConfig } from '@/components/ams/budgetPlanner/retailerConfig.js';

const state = {
  showDashboardLoader: false,
  dashboardError: {},
  dashboardChartRollup: 'rollup_days', // ['rollup_days', 'rollup_months', 'rollup_weeks']
  dashboardChartDataBP: {
    error: false,
    load: false,
    timeSeriesData: [],
    legendData: [],
    noData: false
  },
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  BPDashboardActionStatus: {
    load: false,
    error: false,
    noData: false
  },
  workbenchBudgetPlannerSelectedFilters: {
    date_range: {
      value: 'Last 30 days'
    }
  },
  budgetPlannerDashboardTable: [],
  budgetPlannerDashboardEntireBusiness: {
    rows: [],
    error: false,
    noData: false,
    load: false
  },
  budgetPlannerDashboardProfiles: {},
  budgetPlannerDashboardPortfolios: {},
  budgetPlanMTDProfilesTooltipContent: [],
  createPlanActiveSelection: 0,
  page_wise_min_max_key: 'budget_optimizer_dashboard'
};

const getters = {
  isDashboardLoading: (state) => state.showDashboardLoader,
  getBudgetPlanMTDProfilesTooltipContent: (state) =>
    state.budgetPlanMTDProfilesTooltipContent,
  getCreatePlanActiveSelection: (state) => state.createPlanActiveSelection,
  getDashboardChartDataBP: (state) => state.dashboardChartDataBP,
  getWorkbenchBudgetPlannerFilters: (state) =>
    state.workbenchBudgetPlannerSelectedFilters,
  getBPDashboardActionStatus: (state) => state.BPDashboardActionStatus,
  getBudgetPlannerDashboardTable: (state) => state.budgetPlannerDashboardTable,
  getBudgetPlannerDashboardEntireBusiness: (state) =>
    state.budgetPlannerDashboardEntireBusiness,
  getBudgetPlannerDashboardProfiles: (state) =>
    state.budgetPlannerDashboardProfiles,
  getBudgetPlannerDashboardPortfolios: (state) =>
    state.budgetPlannerDashboardPortfolios,
  getBudgetPlannerDateWiseMinMaxKey: (state) => state.page_wise_min_max_key
};

const mutations = {
  BUDGET_PLAN_MTD_PROFILES_TOOLTIP_CONTENT: (state, data) => {
    state.budgetPlanMTDProfilesTooltipContent = data;
  },
  DASHBOARD_CHART_DATA: (state, data) => {
    state.dashboardChartDataBP = data;
  },
  CREATE_PLAN_ACTIVE_SELECTION: (state, data) => {
    state.createPlanActiveSelection = data;
  },
  DASHBOARD_CHART_ROLLUP: (state, rollup) => {
    state.dashboardChartRollup = rollup;
  },
  IWKBUDGETPLANNER_SET_SELECTED_FILTER: (state, data) => {
    var dateRange = state.workbenchBudgetPlannerSelectedFilters.date_range;
    Vue.set(state, 'workbenchBudgetPlannerSelectedFilters', data.values);
    if (!state.workbenchBudgetPlannerSelectedFilters.date_range) {
      Vue.set(
        state.workbenchBudgetPlannerSelectedFilters,
        'date_range',
        dateRange
      );
    }
    getFiltersInFormatWKBudgetPlanner(data, state);
  },
  BP_DASHBOARD_ACTIONS_STATUS: (state, data) => {
    state.BPDashboardActionStatus = data;
  },
  SHOW_BP_DASHBOARD_LOADER: (state) => {
    state.showDashboardLoader = true;
  },
  HIDE_BP_DASHBOARD_LOADER: (state) => {
    state.showDashboardLoader = false;
  },
  BUDGET_PLANNER_DASHBOARD_TABLE: (state, budgetPlannerDashboardTable) => {
    state.budgetPlannerDashboardTable = budgetPlannerDashboardTable;
  },
  BUDGET_PLANNER_DASHBOARD_ENTIRE_BUSINESS: (
    state,
    budgetPlannerDashboardEntireBusiness
  ) => {
    state.budgetPlannerDashboardEntireBusiness =
      budgetPlannerDashboardEntireBusiness;
  },
  BUDGET_PLANNER_DASHBOARD_PROFILES: (state, data) => {
    const levelName = 'budgetPlannerDashboardProfiles';
    if (!state[levelName]) {
      Vue.set(state, levelName, {});
    }
    if (!state[levelName][data.id]) {
      Vue.set(state[levelName], data.id, {});
    }
    Vue.set(state, levelName, {});
    Vue.set(state[levelName], data.id, {});
    Vue.set(state[levelName][data.id], 'rows', data.rows);
    Vue.set(state[levelName][data.id], 'load', false);
    Vue.set(state[levelName][data.id], 'error', false);
    Vue.set(state[levelName][data.id], 'id', data.id);
    Vue.set(state[levelName][data.id], 'index', data.index);
    Vue.set(state[levelName][data.id], 'level', data.level);
  },
  BUDGET_PLANNER_DASHBOARD_PORTFOLIOS: (state, data) => {
    const levelName = 'budgetPlannerDashboardPortfolios';
    if (!state[levelName]) {
      Vue.set(state, levelName, {});
    }
    if (!state[levelName][data.id]) {
      Vue.set(state[levelName], data.id, {});
    }
    Vue.set(state, levelName, {});
    Vue.set(state[levelName], data.id, {});
    Vue.set(state[levelName][data.id], 'rows', data.rows);
    Vue.set(state[levelName][data.id], 'load', false);
    Vue.set(state[levelName][data.id], 'error', false);
    Vue.set(state[levelName][data.id], 'id', data.id);
    Vue.set(state[levelName][data.id], 'index', data.index);
    Vue.set(state[levelName][data.id], 'level', data.level);
  }
};

const actions = {
  setCreatePlanActiveSelection: (context, selection) => {
    context.commit('CREATE_PLAN_ACTIVE_SELECTION', selection);
  },
  setDashboardChartRollup: (context, rollup) => {
    context.commit('DASHBOARD_CHART_ROLLUP', rollup);
  },
  setWorkbenchBudgetPlannerFilters: (context, data) => {
    context.commit('IWKBUDGETPLANNER_SET_SELECTED_FILTER', data);
  },
  downloadDashboardTable: async (context, configData) => {
    const where = configData.where;
    // resetting where clause due to client side filtering
    configData.where = {
      dimensionNameValueList: []
    };
    configData.where.date = context.state.whereClause.date;
    const response = await HttpLayer.post({
      APIData: configData
    });
    let data = transformer.mergeResultDimension(response.data, true);
    const entireBusiness = data.filter(
      (row) => row.entity_type === 'ENTIRE_BUSINESS'
    );
    if (
      where.dimensionNameValueList &&
      where.dimensionNameValueList.length > 0
    ) {
      data = data.map((row, index) => {
        row._key = `key_${index}`;
        return row;
      });
      data = filterBudgetPlannerCollectiveSummary(data, where);
    }
    const objKeys = Object.keys(data[0] || {});
    for (const key of objKeys) {
      for (const row of data) {
        if (typeof row[key] === 'number') {
          row[key] = row[key].toFixed(2);
        }
      }
    }
    const groupedData = groupBy(data, 'entity_type') || {};
    const sortedEnities = [];
    const profiles = groupedData.PROFILE || [];
    const portfolios = groupedData.PORTFOLIO || [];
    // pushing by order of (eb) -> (profile) -> (portfolio for each profile.)
    sortedEnities.push(entireBusiness[0]);
    for (const profile of profiles) {
      sortedEnities.push(profile);
      for (const portfolio of portfolios) {
        if (portfolio.related_entity_id === profile.entity_id) {
          sortedEnities.push(portfolio);
        }
      }
    }
    return sortedEnities;
  },
  dashboardChartDataBP: async (context, data) => {
    // resetting data
    const dataObj = {
      error: false,
      load: true,
      timeSeriesData: [],
      legendData: [],
      noData: false
    };
    context.commit('DASHBOARD_CHART_DATA', dataObj);
    const retailer = context.getters.getRetailer;
    const rollup = context.state.dashboardChartRollup;
    const spendVSPcogs = 'spend_vs_pcogs';
    let apiData = null;
    data.body.retailer[retailer].APIConfig.where =
      transformer.getCompleteWhereClause(
        data.meta.localFilters || [],
        context.state.whereClause
      );
    data.body.retailer[retailer].APIConfig.where.date =
      context.state.whereClause.date;
    const monthlyApi = cloneDeep(data.body.retailer[retailer].APIConfig);
    const weeklyApi = cloneDeep(data.body.retailer[retailer].APIConfig);
    delete weeklyApi.timeseriesEnabled;
    delete weeklyApi.timeseriesDimension;
    delete monthlyApi.timeseriesDimension;
    delete monthlyApi.timeseriesEnabled;
    monthlyApi.groupByDimensionsList = [
      'month_number',
      'month',
      'max_report_date'
    ];
    monthlyApi.measuresList = [
      'planned_budget_sum',
      'planned_budget_cumulative_monthly',
      'actual_spend_sum',
      'actual_spend_cumulative_monthly',
      'spend_vs_budget'
    ];
    if (retailer === 'amazon') {
      monthlyApi.measuresList.push(spendVSPcogs);
    }
    weeklyApi.groupByDimensionsList = [
      'week_number',
      'week',
      'max_report_date'
    ];
    weeklyApi.measuresList = [
      'planned_budget_sum',
      'planned_budget_cumulative_weekly',
      'actual_spend_sum',
      'actual_spend_cumulative_weekly',
      'spend_vs_budget'
    ];
    if (retailer === 'amazon') {
      weeklyApi.measuresList.push(spendVSPcogs);
    }
    if (rollup === 'rollup_weeks') {
      apiData = weeklyApi;
    } else if (rollup === 'rollup_months') {
      apiData = monthlyApi;
    } else {
      apiData = data.body.retailer[retailer].APIConfig;
    }
    try {
      const response = await HttpLayer.post({
        APIData: apiData
      });
      const timeSeriesData = [];
      if (!response.success) {
        dataObj.error = true;
      } else {
        if (response.data && response.data.length > 0) {
          const data = transformer.mergeResultDimension(response.data);
          const objKeys = Object.keys(data[0]);
          objKeys.forEach((key, index) => {
            if (!dictionary.map[key]) {
              timeSeriesData.push([key]);
            } else {
              timeSeriesData.push([dictionary.map[key].title]);
            }
            timeSeriesData[index].push(...data.map((res) => res[key]));
          });
          // data exists
          dataObj.timeSeriesData = timeSeriesData;
        } else {
          // no data
          dataObj.timeSeriesData = [];
          dataObj.noData = true;
        }
      }
    } catch (err) {
      // error
      dataObj.timeSeriesData = [];
      dataObj.error = true;
    }
    // modifiying request for legends data
    const legendData = cloneDeep(data);
    delete legendData.body.retailer[retailer].APIConfig.timeseriesDimension;
    legendData.body.retailer[retailer].APIConfig.groupByDimensionsList = [];
    legendData.body.retailer[retailer].APIConfig.measuresList = [
      'planned_budget_sum',
      'actual_spend_sum',
      'spend_vs_budget',
      'planned_budget_till_max_report_date'
    ];
    if (retailer === 'amazon') {
      legendData.body.retailer[retailer].APIConfig.measuresList.push(
        spendVSPcogs
      );
    }
    legendData.body.retailer[retailer].APIConfig.timeseriesEnabled = false;
    try {
      const legendResponse = await HttpLayer.post({
        APIData: legendData.body.retailer[retailer].APIConfig
      });
      if (!legendResponse.success) {
        data.error = true;
        data.timeSeriesData = [];
      } else {
        if (legendResponse.data && legendResponse.data.length > 0) {
          // data exists
          dataObj.legendData = transformer.mergeResultDimension(
            legendResponse.data
          )[0];
        } else {
          // no data
          dataObj.timeSeriesData = [];
          if (!dataObj.error) {
            dataObj.noData = true;
          }
        }
      }
    } catch (err) {
      // error
      dataObj.timeSeriesData = [];
      dataObj.legendData = [];
      dataObj.error = true;
      dataObj.noData = false;
    } finally {
      dataObj.load = false;
      context.commit('DASHBOARD_CHART_DATA', dataObj);
    }
  },
  budgetPlannerDashboardActions: async (context, data) => {
    const obj = {
      load: true,
      error: false,
      noData: false
    };
    context.commit('BP_DASHBOARD_ACTIONS_STATUS', obj);
    try {
      for (let i = 0; i < data.body.cubesToCall.length; i++) {
        data.body.APIConfig.cubeName = data.body.cubesToCall[i];
        const response = await HttpLayer.post({
          APIData: data.body.APIConfig,
          urlId: 'BUDGET_OPTIMIZER_NO_CACHE_CUBE'
        });
        if (!response.success) {
          // error if any success is false
          obj.error = true;
        } else {
          if (response.data && response.data.length > 0) {
            const innerData = transformer.mergeResultDimension(response.data);
            obj[data.body.APIConfig.cubeName] = innerData;
          }
        }
      }
    } catch (error) {
      obj.error = true;
    } finally {
      obj.load = false;
      context.commit('BP_DASHBOARD_ACTIONS_STATUS', obj);
    }
  },
  budgetPlanMTDProfilesTooltipContent: (context, data) => {
    // fetching dashoborad profile description for explainability tooltips.
    let mtdProfilesTooltipContent = [];
    HttpLayer.post({
      APIData: data.body.profileDescriptionAPIConfig
    })
      .then((response) => {
        mtdProfilesTooltipContent =
          response.success && response?.data.length > 0
            ? transformer.mergeResultDimension(response.data)
            : [];
      })
      .catch(() => {
        // ignoring the error, if this api fails it should not block the ui as its only tooltip data.
        mtdProfilesTooltipContent = [];
      })
      .finally(() => {
        context.commit(
          'BUDGET_PLAN_MTD_PROFILES_TOOLTIP_CONTENT',
          mtdProfilesTooltipContent
        );
      });
  },
  budgetPlannerSummaryColletiveData: async (context, data) => {
    const retailer = context.getters.getRetailer;
    data.body.APIConfig = data.body.retailer[retailer].APIConfig;
    const dataObj = {
      error: false,
      load: true,
      rows: [],
      noData: false
    };
    context.commit('BUDGET_PLANNER_DASHBOARD_ENTIRE_BUSINESS', dataObj);
    // resetting widget where clause for download api as filtering is done on client.
    const where = transformer.getCompleteWhereClause(
      data.meta.localFilters || [],
      context.state.whereClause
    );
    data.body.APIConfig.where = {
      dimensionNameValueList: []
    };
    data.body.APIConfig.where.date = context.state.whereClause.date;
    if (data.body.APIConfig.page) {
      delete data.body.APIConfig.page;
    }
    try {
      const response = await HttpLayer.post({
        APIData: data.body.APIConfig
      });
      if (!response.success) {
        dataObj.error = true;
      } else {
        if (response.data && response.data.length > 0) {
          let innerData = transformer.mergeResultDimension(response.data);
          const entireBusiness = innerData.filter(
            (row) => row.entity_type === 'ENTIRE_BUSINESS'
          );
          innerData = innerData.map((row, index) => {
            row._key = `key_${index}`;
            return row;
          });
          if (
            where.dimensionNameValueList &&
            where.dimensionNameValueList.length > 0
          ) {
            innerData = filterBudgetPlannerCollectiveSummary(innerData, where);
          }
          innerData = addChildRowCount(innerData, retailer);
          context.commit('BUDGET_PLANNER_DASHBOARD_TABLE', innerData);
          dataObj.rows = entireBusiness;
        } else {
          dataObj.noData = true;
        }
      }
    } catch (error) {
      dataObj.error = true;
    } finally {
      dataObj.load = false;
      context.commit('BUDGET_PLANNER_DASHBOARD_ENTIRE_BUSINESS', dataObj);
    }
    // setting where clause for download widget post the api call as filtering is happening on ui
    data.body.retailer[retailer].APIConfig.where =
      transformer.getCompleteWhereClause(
        data.meta.localFilters || [],
        context.state.whereClause
      );
  },
  budgetPlannerDashboardTableProfile: (context, data) => {
    const dashboardTable = context.state.budgetPlannerDashboardTable;
    data.rows = dashboardTable.filter(
      (row) => row.related_entity_id === data.row.entity_id
    );
    // sorting in asc order of name
    data.rows.sort((a, b) => (a.entity_name > b.entity_name ? 1 : -1));
    context.commit('BUDGET_PLANNER_DASHBOARD_PROFILES', data);
  },
  budgetPlannerDashboardTablePortfolio: (context, data) => {
    const retailer = context.getters.getRetailer;
    const dashboardTable = context.state.budgetPlannerDashboardTable;
    data.rows = dashboardTable.filter(
      (row) => row.related_entity_id === data.row.entity_id
    );
    if (multiRetailerConfig.hideRestForSinglePortfolio.includes(retailer)) {
      data.rows = data.rows.filter(
        (row) => !(data.rows.length === 1 && row.entity_name === 'Rest')
      );
    }
    // sorting in asc order of name
    data.rows.sort((a, b) =>
      a.entity_name.toLowerCase() > b.entity_name.toLowerCase() ? 1 : -1
    );
    context.commit('BUDGET_PLANNER_DASHBOARD_PORTFOLIOS', data);
  }
};

function filterBudgetPlannerCollectiveSummary(response, where) {
  const groupedDimensionList = groupBy(
    where.dimensionNameValueList,
    'dimensionName'
  );
  const groupedResponse = groupBy(response, 'entity_type');
  const allProfiles = groupedResponse.PROFILE || [];
  const allPortfolios = groupedResponse.PORTFOLIO || [];
  const search =
    (groupedDimensionList.search &&
      groupedDimensionList.search.filter((row) => row.dimensionValue)) ||
    [];
  const filterByProfile =
    (groupedDimensionList.profile_name &&
      groupedDimensionList.profile_name.filter((row) => row.dimensionValue)) ||
    [];
  const filterByPortfolio =
    (groupedDimensionList.portfolio_name &&
      groupedDimensionList.portfolio_name.filter(
        (row) => row.dimensionValue
      )) ||
    [];
  const filteredProfiles = [];
  let filteredProtfolios = [];
  // if profile filter is applied push profiles.
  if (filterByProfile.length > 0) {
    for (const item of filterByProfile) {
      const tempProfileArr = allProfiles.filter((row) =>
        row?.entity_name
          ?.toLowerCase()
          .includes(item?.dimensionValue?.toLowerCase())
      );
      filteredProfiles.push(...tempProfileArr);
    }
  }

  // if portfolio filter is applied push profolios and its parent(profiles).
  if (filterByPortfolio.length > 0) {
    // portfolio logic

    for (const item of filterByPortfolio) {
      const tempPortfolioArr = allPortfolios.filter((row) =>
        row?.entity_name
          ?.toLowerCase()
          .includes(item.dimensionValue?.toLowerCase())
      );
      filteredProtfolios.push(...tempPortfolioArr);
    }
    // if profiles exist
    if (filterByProfile.length > 0) {
      const tempPortfolioArray = [];
      for (const profile of filteredProfiles) {
        const tempProfileArray = filteredProtfolios.filter(
          (row) => row.related_entity_id === profile.entity_id
        );
        tempPortfolioArray.push(...tempProfileArray);
      }
      filteredProtfolios = tempPortfolioArray;
    } else {
      // if profiles dont exist
      for (const profile of allProfiles) {
        for (const portfolio of filteredProtfolios) {
          if (portfolio.related_entity_id === profile.entity_id) {
            filteredProfiles.push(profile);
          }
        }
      }
    }
  }
  let filteredProfilesAndPortfolios = [];
  // adding filtered profiles and portfolions
  if (filterByPortfolio.length > 0 || filterByProfile.length > 0) {
    filteredProfilesAndPortfolios = [
      ...filteredProtfolios,
      ...filteredProfiles
    ];
  } else {
    filteredProfilesAndPortfolios = [...allProfiles, ...allPortfolios];
  }

  let finalFilteredEntities = [];
  // if search filter is present filter by everything.
  if (search.length > 0) {
    for (const item of search) {
      const tempSearchArr = filteredProfilesAndPortfolios.filter((row) =>
        JSON.stringify(row)
          .toLowerCase()
          .includes(item?.dimensionValue?.toLowerCase())
      );
      finalFilteredEntities.push(...tempSearchArr);
    }
    const tempProfiles = [];
    // profile logic
    for (const profile of allProfiles) {
      for (const entity of finalFilteredEntities) {
        if (entity.related_entity_id === profile.entity_id) {
          tempProfiles.push(profile);
        }
      }
    }
    finalFilteredEntities = [...finalFilteredEntities, ...tempProfiles];
  } else {
    finalFilteredEntities = filteredProfilesAndPortfolios;
  }
  // deleting duplicate rows
  finalFilteredEntities = finalFilteredEntities.filter(
    (entity, index, self) =>
      index === self.findIndex((t) => t._key === entity._key)
  );
  return finalFilteredEntities;
}

function getFiltersInFormatWKBudgetPlanner(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      if (oFilter.values[i].length > 0 && oFilter.values[i][0].operator) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][0].value,
          operator: oFilter.values[i][0].operator.operator
        });
      } else {
        for (var j = 0; j < oFilter.values[i].length; j++) {
          where.dimensionNameValueList.push({
            dimensionName: i,
            dimensionValue: oFilter.values[i][j]
          });
        }
      }
    } else {
      var date = oFilter.values[i];
      var _date = {
        from: date.from,
        to: date.to,
        name: date.name,
        page_wise_min_max_key: state.page_wise_min_max_key
      };
      var _pvpDate;
      if (date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to,
          compare_name: date.compare_name
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  state.whereClause = where;
}

const addChildRowCount = (tableData, retailer) => {
  const relatedMap = {};
  tableData.map((row, index) => {
    row['ag-grid-has-expand'] = false;
    if (!relatedMap[row.related_entity_id]) {
      relatedMap[row.related_entity_id] = {
        count: 0
      };
    }
    if (multiRetailerConfig.hideRestForSinglePortfolio.includes(retailer)) {
      if (row.entity_name !== 'Rest') relatedMap[row.related_entity_id].count++;
    } else {
      relatedMap[row.related_entity_id].count++;
    }
  });
  for (const relatedEntity in relatedMap) {
    const index = tableData.findIndex((item) => {
      // comparing string with number
      // eslint-disable-next-line
      return relatedEntity == item.entity_id;
    });
    if (index > -1) {
      tableData[index].childCount = relatedMap[relatedEntity].count;
      if (
        multiRetailerConfig.disableCollapseForUncategorized.includes(retailer)
      ) {
        tableData[index]['ag-grid-has-expand'] =
          tableData[index].entity_name !== 'Uncategorized' &&
          relatedMap[relatedEntity].count > 0;
      } else {
        tableData[index]['ag-grid-has-expand'] =
          relatedMap[relatedEntity].count > 0;
      }
    }
  }
  return tableData;
};

export default {
  state,
  getters,
  mutations,
  actions
};
