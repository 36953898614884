import moment from 'moment-timezone';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import { cloneDeep } from 'lodash';
// -- importing componenets here --
import {
  spAddKeywordsRecommendationComponentConfig,
  addEfficientSearchTermsAsKeywordsRecommendationComponentConfig,
  alignCampaignToBestPracticesRecommendationComponentConfig,
  addInEfficientSearchTermsAsNegativeTargetRecommendationComponentConfig
} from '@/pages/entity_details/configs/amazon/recommendations-config.js';
import {
  addTargetsToCampaignActionPanelSD,
  addCategoriesToCampaignActionPanel,
  addSearchTermTargetsToCampaignActionPanel,
  addSearchTermNegativeTargetsToCampaignActionPanel,
  addAudiencesToCampaignActionPanel,
  addNegativeKeywordsEnterListComponentConfig,
  addNegativeTargetsEnterListComponentConfig,
  addCategoriesToAudienceCampaigns
} from '@/pages/entity_details/configs/amazon/actionConfigs.js';

const budget = () =>
  import('@/pages/entity_details/widget_templates/budget.vue');
const settings = () =>
  import('@/pages/entity_details/widget_templates/settings/index.vue');
const adGroups = () =>
  import('@/pages/entity_details/widget_templates/adGroups.vue');
const negativeTargeting = () =>
  import('@/pages/entity_details/widget_templates/SB/sbNegativeTargeting.vue');
const adgroupProductADs = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupProductADs.vue'
  );
const adgroupsTargetingProduct = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupsTargetingProduct.vue'
  );
const adgroupsTargetingAudience = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupsTargetingProduct.vue'
  );
const adgroupProductNegativeTargeting = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupKeywordNegativeTargeting.vue'
  );
const adgroupSettings = () =>
  import(
    '@/pages/entity_details/widget_templates/adgroups/adgroupSettings.vue'
  );
const biddingStrategy = () =>
  import('@/pages/entity_details/widget_templates/biddingStrategy.vue');
const adgroupDefaultBid = () =>
  import('@/pages/entity_details/widget_templates/budget.vue');

const adgroupADsConfig = {
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter: 'entityDetails/fetchIncrementTableDataKey'
  },
  allowedActions: [
    {
      icon: 'task-status',
      title: 'EDIT STATUS',
      id: 'editStatus',
      dropDown: true,
      dropDownOptions: [
        { title: 'Enable', value: 'enabled', color: 'u-color-green-base' },
        { title: 'Pause', value: 'paused', color: 'u-color-orange-base' },
        { title: 'Archive', value: 'archived', color: 'u-color-red-base' }
      ]
    }
  ],
  stateChange: {
    requestPayload: {
      actionPayload: {
        campaignId: ':campaignId',
        ad_id: ':ad_id',
        campaignType: 'SD',
        adgroup_id: ':adgroupId',
        state: ':state'
      },
      viewPayload: {
        campaignId: ':campaignId',
        previousStatus: ':oldState',
        newStatus: ':state',
        keyword_text: ':keywordText',
        view_adgroup_name: ':adgroupName',
        ad_id: ':ad_id',
        asin_id: ':asin_id'
      },
      actionType: 'productAdStatusChange',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  },
  addCustomProducts: {
    requestPayload: {
      actionPayload: {
        campaignId: ':campaignId',
        campaignType: 'SD',
        adgroup_id: ':adgroupId',
        sku: ':asin_id',
        asin: ':asin_id',
        state: 'enabled'
      },
      viewPayload: {
        campaignId: ':campaignId',
        sku: ':asin_id',
        asin: ':asin_id',
        product_title: ':asin_title',
        image_url: ':image_url',
        adgroupName: ':adgroupName'
      },
      actionType: 'addAsinCampaign',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  }
};

export const adgroupsTargetingProductConfig = {
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter: 'entityDetails/fetchIncrementTableDataKey'
  },
  customActionPanelConfigs: {
    products: addTargetsToCampaignActionPanelSD,
    categories: addCategoriesToCampaignActionPanel
  },
  searchMetric: 'ams_cm_targeting_asin_id',
  allowedActions: [
    {
      icon: 'task-status',
      title: 'EDIT STATUS',
      id: 'editStatus',
      dropDown: true,
      dropDownOptions: [
        { title: 'Enable', value: 'enabled', color: 'u-color-green-base' },
        { title: 'Pause', value: 'paused', color: 'u-color-orange-base' },
        { title: 'Archive', value: 'archived', color: 'u-color-red-base' }
      ]
    },
    {
      icon: 'dollar',
      title: 'Adjust Bid',
      id: 'adjustBids'
    }
  ],
  bidChange: {
    requestPayload: {
      actionPayload: {
        campaignId: ':campaignId',
        adgroup_id: ':adgroupId',
        campaignType: 'SD',
        new_bid: ':newBid',
        target_id: ':keywordId',
        old_bid: ':oldBid'
      },
      viewPayload: {
        campaignId: ':campaignId',
        view_adgroup_name: ':adgroupName',
        view_old_bid: ':oldBid',
        new_bid: ':newBid',
        keyword_text: ':keywordText',
        match_type: ':matchType',
        target_id: ':keywordId',
        asin_id: ':asin_id'
      },
      actionType: 'targetMetadataChange',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  },
  stateChange: {
    requestPayload: {
      actionPayload: {
        campaignId: ':campaignId',
        target_id: ':keywordId',
        campaignType: 'SD',
        adgroup_id: ':adgroupId',
        state: ':state'
      },
      viewPayload: {
        campaignId: ':campaignId',
        previousStatus: ':oldState',
        newStatus: ':state',
        keyword_text: ':keywordText',
        match_type: ':matchType',
        adgroup_name: ':adgroupName',
        asin_id: ':asin_id'
      },
      actionType: 'targetMetadataChange',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId'
    }
  },
  addAudiencesToCampaigns: {
    actionType: 'addAudienceTargetToCampaigns',
    actionSource: {
      pageUrl: ':pageUrl'
    },
    widget: 'campaign',
    primaryKey: ':campaignId',
    actionPayload: {
      campaignType: 'SD',
      campaignId: ':campaignId',
      adgroup_id: ':adgroupId',
      new_expression_type: 'manual',
      new_bid: ':bid',
      state: 'enabled',
      new_expression: [
        {
          type: ':marketingType',
          value: [
            {
              type: ':expressionType', // asinSameAs OR asinCategorySameAs
              value: ':value' // asin or category
            }
          ]
        }
      ],
      new_keyword_text: ':asin_id'
    },
    viewPayload: {
      adgroupName: ':adgroupName',
      entityId: ':asin_id',
      asin: ':asin_id',
      type: ':entityType',
      entityName: ':asin_title',
      match_type: 'TARGETING_EXPRESSION',
      new_bid: ':bid'
    }
  },
  addTargetsToCampain: {
    requestPayload: {
      actionType: 'addTargetToCampaigns',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId',
      actionPayload: {
        campaignId: ':campaignId',
        campaignType: 'SD',
        adgroup_id: ':adgroupId',
        new_expression_type: 'manual',
        new_match_type: 'TARGETING_EXPRESSION',
        new_bid: ':bid',
        state: 'enabled',
        new_expression: [
          {
            type: ':expressionType', // asinSameAs OR asinCategorySameAs
            value: ':asin_id' // asing or category
          }
        ],
        new_keyword_text: ':asin_id' // value in the new_expression
      },
      viewPayload: {
        adgroupName: ':adgroupName',
        entityId: ':asin_id',
        asin: ':asin_id',
        type: ':entityType', // product or category
        entityName: ':asin_title',
        match_type: 'TARGETING_EXPRESSION',
        new_bid: ':bid'
      }
    }
  }
};

const adGroupSettingsConfig = {
  isAdgroup: true,
  requestPayload: {
    actionPayload: {
      campaignId: ':campaignId',
      adgroup_id: ':entityId',
      campaignType: 'SD'
    },
    viewPayload: {
      campaignId: ':campaignId',
      view_adgroup_name: ':name'
    },
    actionType: 'adgroupMetadataChange',
    actionSource: {
      pageUrl: ':pageUrl'
    },
    widget: 'campaign',
    primaryKey: ':campaignId'
  },
  component: adgroupSettings,
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter: 'entityDetails/fetchIncrementTableDataKey'
  }
};

const adgroupDefaultBidConfig = {
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter: 'entityDetails/fetchIncrementTableDataKey'
  },
  reqMetadataMap: {
    ':oldBudget': 'defaultBid',
    ':campaignId': 'parentEntityId'
  },
  config: {
    title: 'Default bid:',
    adjustButton: 'Adjust bid',
    adjustTitle: 'Adjust default bid:',
    type: 'default bid'
  },
  isAdgroup: true,
  component: adgroupDefaultBid,
  requestPayload: {
    actionPayload: {
      campaignId: ':campaignId',
      campaignType: 'SD',
      adgroup_id: ':entityId',
      new_bid: ':newBudget'
    },
    viewPayload: {
      campaignId: ':campaignId',
      view_adgroup_name: ':name',
      view_old_bid: ':oldBudget',
      new_bid: ':newBudget'
    },
    actionType: 'adgroupMetadataChange',
    actionSource: {
      pageUrl: ':pageUrl'
    },
    widget: 'campaign',
    primaryKey: ':campaignId'
  }
};

const adgroupNegativeTargetingConfig = {
  recCustomComponent: (context) => {
    return {
      RECOMMENDATIONS_NEGATIVEKEYWORDS_INEFFICIENT_SEARCHTERMS:
        addInEfficientSearchTermsAsNegativeTargetRecommendationComponentConfig(
          context
        )
    };
  },
  apiConfigs: {
    updateAction: 'entityDetails/updateCampaignDetails',
    fetchAction: 'entityDetails/fetchAdgroupDetails',
    getter: 'entityDetails/getAdgroupDetails',
    getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey',
    fetchTableDataComponentKeyGetter: 'entityDetails/fetchIncrementTableDataKey'
  },
  negativeKeyword: {
    customActionPanelComponent: addNegativeKeywordsEnterListComponentConfig,
    allowedActions: [
      {
        icon: 'archive',
        title: 'Archive',
        id: 'makeArchive'
      }
    ],
    stateChange: {
      requestPayload: {
        actionPayload: {
          campaignId: ':campaignId',
          keyword_id: ':keywordId',
          campaignType: 'SD',
          adgroup_id: ':adgroupId',
          state: ':state'
        },
        viewPayload: {
          campaignId: ':campaignId',
          previousStatus: ':oldState',
          newStatus: ':state',
          keyword_text: ':keywordText',
          match_type: ':matchType',
          adgroup_name: ':adgroupName'
        },
        actionType: 'updateNegativeKeywordState',
        actionSource: {
          pageUrl: ':pageUrl'
        },
        widget: 'campaign',
        primaryKey: ':campaignId'
      }
    }
  },
  negativeTarget: {
    customActionPanelComponent: addNegativeTargetsEnterListComponentConfig,
    allowedActions: [
      {
        icon: 'archive',
        title: 'Archive',
        id: 'makeTargetArchive'
      }
    ],
    addNegativeTargetRequestPayload: {
      actionType: 'addNegativeTarget',
      actionSource: {
        pageUrl: ':pageUrl'
      },
      widget: 'campaign',
      primaryKey: ':campaignId',
      actionPayload: {
        campaignId: ':campaignId',
        campaignType: 'SD',
        state: 'enabled',
        adgroup_id: ':adgroupId',
        new_expression_type: 'manual',
        new_match_type: ':matchType',
        new_keyword_text: ':newKeywordText',
        new_expression: [
          {
            type: 'asinSameAs', // asinCategorySameAs
            value: ':newKeywordText'
          }
        ]
      },
      viewPayload: {
        campaignId: ':campaignId',
        adgroupId: ':adgroupId',
        // adgroupName: "Terminate",
        keywordText: ':newKeywordText', // value in new expression
        matchType: ':matchType'
      }
    },
    stateChange: {
      requestPayload: {
        actionPayload: {
          campaignId: ':campaignId',
          target_id: ':keywordId',
          campaignType: 'SD',
          expressionType: 'manual',
          adgroup_id: ':adgroupId',
          state: ':state'
        },
        viewPayload: {
          campaignId: ':campaignId',
          previousStatus: ':oldState',
          newStatus: ':state',
          keyword_text: ':keywordText',
          match_type: ':matchType',
          adgroup_name: ':adgroupName'
        },
        actionType: 'updateNegativeTargetState',
        actionSource: {
          pageUrl: ':pageUrl'
        },
        widget: 'campaign',
        primaryKey: ':campaignId'
      }
    }
  },
  getHeaderOptions: (vueRef) => {
    const headerOptions = {
      props: {
        headerOptions: [
          {
            ui_component: 'rb-button',
            props: {
              clickFn: vueRef.addNegativeTargetingBtnClickFn,
              text: 'Add Custom Negative Products',
              type: 'filled'
            },
            class: 'u-spacing-mr-xl'
          }
        ],
        enableDownload: false,
        enableManageColumns: !vueRef.selections.length,
        manageColumnGridPosition: 4,
        headerContainerGridStyles: {
          'grid-template-columns': 'auto 1fr auto',
          display: vueRef.selections.length ? 'none' : 'grid'
        },
        searchParams: {
          show: true,
          searchDimension: 'ams_cm_negativetargets_keyword_text',
          placeholder: 'Find a Negative Keyword'
        },
        rowSelection: {
          mode: 'multiple',
          onSelectionChanged: vueRef.handleSelectionChange
        },
        rowHeight: 45
      }
    };
    return headerOptions;
  }
};
// product and audience adgroups
const common_configs = {
  metadata: [
    {
      action: 'entityDetails/fetchAdgroupDetails',
      getters: 'entityDetails/getAdgroupDetails',
      campaignFetchAction: 'entityDetails/fetchCampaingDetails',
      campaignGetter: 'entityDetails/getCampaignDetails'
    }
  ],
  header: {
    maxFeedDate: 'ams_campaign',
    line1: {
      items: [
        {
          fromMetadata: true,
          attribute: {
            title: 'Ad Group',
            values: ['name'],
            titleClass: 'u-color-grey-lighter u-font-weight-700 u-font-size-2',
            valueClasses: ['u-font-weight-700 u-font-size-2'],
            altValues: ['NA']
          },
          class: 'u-spacing-pt-xxs',
          itemType: 'attribute'
        }
      ],
      class: ''
    },
    line2: {
      items: [
        {
          fromMetadata: true,
          attribute: {
            title: 'Status',
            values: ['state'],
            titleClass: null,
            delimiter: '',
            valueClasses: ['u-color-[color]-base u-text-case-title'],
            altValues: ['NA']
          },
          class: 'u-spacing-pr-l',
          itemType: 'attribute'
        },
        {
          fromMetadata: true,
          attribute: {
            pre: [],
            title: 'Bid',
            values: ['defaultBidView'],
            titleClass: null,
            delimiter: ' ',
            valueClasses: null,
            altValues: ['NA']
          },
          class: 'u-spacing-pr-l',
          itemType: 'attribute'
        },
        {
          fromMetadata: true,
          attribute: {
            pre: [],
            title: 'Bid Optimization',
            values: ['bidOptimization'],
            titleClass: null,
            delimiter: ' ',
            valueClasses: ['u-text-case-title'],
            altValues: ['NA']
          },
          class: 'u-spacing-pr-l',
          itemType: 'attribute'
        }
      ],
      class: 'u-spacing-pb-m u-font-size-5'
    },
    filters: {
      config: {
        emit: 'sdAdgroupDetailsFiltersApplied',
        hasSearch: false,
        addToFilter: 'sdAdgroupDetailsAddToFilters'
      },
      filterLocalStorageKey: 'campaigns-filters',
      page: 'sdAdgrpoupDetails'
    },
    bookmark: {
      value: true,
      title: 'name',
      fromMetadata: true
    },
    redirectTo: {
      value: true,
      config: {
        name: 'EntityDetails',
        params: {
          entityId: ':entityId',
          entityType: 'sd'
        }
      }
    },
    // line 1 and 2 will wrap around date picker if its present
    metadata_api_state: {
      getter: 'entityDetails/getAdgroupDetails'
    }
  },
  tabs: {
    adgroupsTargetingProduct: {
      component: adgroupsTargetingProduct,
      ...adgroupsTargetingProductConfig
    },
    adgroupsTargetingAudience: {
      component: adgroupsTargetingAudience,
      audiences: addAudiencesToCampaignActionPanel,
      categories: addCategoriesToAudienceCampaigns,
      hideCustomActionPanel: true,
      ...adgroupsTargetingProductConfig,
      getHeaderOptions: (vueRef) => {
        const headerOptions = {
          props: {
            headerOptions: [
              {
                ui_component: 'rb-button',
                props: {
                  clickFn: vueRef.handleAddAudiences,
                  text: 'Add Audiences',
                  type: 'filled'
                },
                class: 'u-spacing-mr-xl'
              }
            ],
            enableDownload: false,
            headerContainerGridStyles: {
              'grid-template-columns': '1fr auto auto',
              display: vueRef.selections.length ? 'none' : 'flex'
            },
            searchParams: {
              show: true,
              placeholder: 'Find an Audience',
              searchDimension: 'ams_cm_targeting_keyword_text'
            },
            rowSelection: {
              mode: 'multiple',
              onSelectionChanged: vueRef.handleSelectionChange
            },
            rowHeight: 70
          }
        };
        return headerOptions;
      }
    },
    adgroupProductNegativeTargeting: {
      component: adgroupProductNegativeTargeting,
      isProductAdgroup: true,
      ...adgroupNegativeTargetingConfig
    },
    adgroupProductADs: {
      component: adgroupProductADs,
      ...adgroupADsConfig
    },
    adgroupSettings: adGroupSettingsConfig,
    biddingStrategy: {
      hideFooterButton: false,
      component: biddingStrategy,
      requestPayload: {
        actionPayload: {
          adgroupId: ':entityId',
          campaignId: ':campaignId',
          campaignType: 'SD',
          bidding_strategy: ':bidStrategy'
        },
        viewPayload: {
          bidding_strategy: ':viewBidStrategy',
          view_old_bidding_strategy: ':oldBidStrategy',
          campaignId: ':entityId'
        },
        actionType: 'adgroupMetadataChange',
        actionSource: {
          pageUrl: ':pageUrl'
        },
        widget: 'campaign',
        primaryKey: ':campaignId'
      },
      apiConfigs: {
        action: 'entityDetails/fetchAdgroupDetails',
        updateAction: 'entityDetails/updateCampaignDetails',
        getter: 'entityDetails/getAdgroupDetails',
        campaignFetchAction: 'entityDetails/fetchCampaingDetails',
        campaignGetter: 'entityDetails/getCampaignDetails',
        fetchTableDataComponentKeyGetter:
          'entityDetails/fetchIncrementTableDataKey'
      },
      getBiddingStrategy: (campaignInfo) => {
        return campaignInfo?.data?.bidOptimization?.toLowerCase() || null;
      }
    },
    adgroupDefaultBid: adgroupDefaultBidConfig
  },
  getWidget: (entityId, tabMetaData, parentEntityId) => {
    const widget = {
      ':widget': tabMetaData?.widgets[0],
      ':orderByList': [],
      ':dimensionNameValueList': [],
      keepExistingDimensionNameValueList: true,
      replaceEntityInDimensionNameValueList: {
        ':adgroup_id': entityId
      },
      replaceEntityInOperationsObject: {
        ':system': `campaign_${parentEntityId || entityId}`
      }
    };
    return widget;
  }
};

const product_configs = {
  page: 'cm_adgroups_product_sd',
  chart: {
    page: 'cm_adgroups_product_sd',
    globalViewId: 0,
    maxFeedDate: 'ams_campaign',
    pageId: 0,
    filter: 'sdAdgroupDetailsFiltersApplied',
    config: {
      chartingWb: {
        ':widget': 'ChartingWB',
        ':timeseriesRollupBy': 'DAY',
        keepExistingDimensionNameValueList: true,
        replaceEntityInDimensionNameValueList: {
          ':adgroup_id': ':entityId'
        },
        timeseries: 'report_date'
      }
    }
  },
  ...common_configs
};
const audience_configs = {
  page: 'cm_adgroups_audience_sd',
  chart: {
    page: 'cm_adgroups_audience_sd',
    globalViewId: 0,
    maxFeedDate: 'ams_campaign',
    pageId: 0,
    filter: 'sdAdgroupDetailsFiltersApplied',
    config: {
      chartingWb: {
        ':widget': 'ChartingWB',
        ':timeseriesRollupBy': 'DAY',
        keepExistingDimensionNameValueList: true,
        replaceEntityInDimensionNameValueList: {
          ':adgroup_id': ':entityId'
        },
        timeseries: 'report_date'
      }
    }
  },
  ...common_configs
};

export default {
  campaign: {
    page: 'cm_campaign_sd',
    metadata: [
      {
        action: 'entityDetails/fetchCampaingDetails',
        getters: 'entityDetails/getCampaignDetails'
      }
    ],
    header: {
      maxFeedDate: 'ams_campaign',
      filters: {
        config: {
          emit: 'sdCampaignDetailsFiltersApplied',
          hasSearch: false,
          addToFilter: 'sdCampaignDetailsAddToFilters'
        },
        filterLocalStorageKey: 'campaigns-filters',
        page: 'sdCampaingDetails'
      },
      activityLog: {
        show: true
      },
      bookmark: {
        value: true,
        title: 'name',
        fromMetadata: true
      },
      redirectTo: {
        value: true,
        config: {
          name: 'Campaigns',
          params: {}
        }
      },
      // line 1 and 2 will wrap around date picker if its present
      metadata_api_state: {
        getter: 'entityDetails/getCampaignDetails'
      },
      line1: {
        items: [
          {
            fromMetadata: true,
            attribute: {
              title: 'Campaign',
              values: ['name'],
              hoverText: ['name'],
              titleClass:
                'u-font-weight-700 u-color-grey-lighter u-font-size-2',
              valueClasses: ['u-font-weight-700 u-font-size-2'],
              altValues: ['NA']
            },
            class: 'u-spacing-pt-xxs u-text-overflow-ellipsis',
            itemType: 'attribute'
          }
        ],
        class: ''
      },
      line2: {
        items: [
          {
            fromMetadata: true,
            attribute: {
              title: 'Duration',
              values: ['startDate', 'endDate'],
              titleClass: null,
              delimiter: '-',
              valueClasses: null,
              altValues: ['No start date', 'No end date']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              pre: [],
              title: 'Budget',
              values: ['dailyBudgetView', ''],
              titleClass: null,
              delimiter: ' ',
              valueClasses: null,
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              title: 'Type',
              values: ['campaignType', 'targetingType'],
              titleClass: null,
              delimiter: '-',
              valueClasses: ['', 'u-text-case-title'],
              altValues: ['NA', 'NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          }
        ],
        class: 'u-spacing-pb-m u-font-size-5'
      },
      line3: {
        items: [
          {
            fromMetadata: true,
            attribute: {
              title: 'Status',
              values: ['state'],
              titleClass: null,
              delimiter: '',
              valueClasses: ['u-color-[color]-base u-text-case-title'],
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              title: 'Cost Type',
              values: ['costType'],
              titleClass: null,
              delimiter: '',
              valueClasses: ['u-text-case-upper'],
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            hide: true,
            fromMetadata: true,
            attribute: {
              title: 'Campaign ID',
              values: ['campaignId'],
              titleClass: null,
              delimiter: '',
              valueClasses: null,
              altValues: ['NA']
            },
            class: 'u-spacing-pr-l',
            itemType: 'attribute'
          },
          {
            fromMetadata: true,
            attribute: {
              title: 'Hourly Bidder Status',
              values: ['hourly_bidder_status'],
              titleClass: null,
              delimiter: null,
              valueClasses: null,
              altValues: ['NA']
            },
            class: '',
            itemType: 'attribute'
          }
        ],
        class: 'u-spacing-pb-l u-font-size-5'
      }
    },
    chart: {
      page: 'cm_campaign_sd',
      campaignType: 'SD',
      maxFeedDate: 'ams_campaign',
      globalViewId: 0,
      pageId: 0,
      filter: 'sdCampaignDetailsFiltersApplied',
      apiConfigs: {
        getter: 'entityDetails/getCampaignDetails',
        fetchExecuteApiData: 'entityDetails/fetchTargetingPageData',
        getTargetingPageData: 'entityDetails/getTargetingPageDetails',
        getTableDataComponentKeyGetter: 'entityDetails/getTableDataComponentKey'
      }
    },
    getWidget: (entityId, tabMetaData) => {
      const widget = {
        ':widget': tabMetaData?.widgets[0],
        ':orderByList': [],
        ':dimensionNameValueList': [],
        keepExistingDimensionNameValueList: true,
        replaceEntityInDimensionNameValueList: {
          ':campaign_id': entityId
        },
        replaceEntityInOperationsObject: {
          ':system': `campaign_${entityId}`
        }
      };
      return widget;
    },
    // show tabs data if present
    tabs: {
      adGroups: {
        component: adGroups,
        apiConfigs: {
          updateAction: 'entityDetails/updateCampaignDetails',
          getter: 'entityDetails/getCampaignDetails',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey',
          fetchTableDataComponentKeyGetter:
            'entityDetails/fetchIncrementTableDataKey'
        },
        stateChange: {
          requestPayload: {
            actionPayload: {
              campaignId: ':entityId',
              campaignType: 'SD',
              adgroup_id: ':adgroupId',
              state: ':state'
            },
            viewPayload: {
              campaignId: ':entityId',
              view_adgroup_name: ':adgroupName',
              view_old_state: ':oldState',
              state: ':state'
            },
            actionType: 'adgroupMetadataChange',
            actionSource: {
              pageUrl: ':pageUrl'
            },
            widget: 'campaign',
            primaryKey: ':entityId'
          }
        },
        bidChange: {
          requestPayload: {
            actionPayload: {
              campaignId: ':entityId',
              campaignType: 'SD',
              adgroup_id: ':adgroupId',
              new_bid: ':newBid',
              old_bid: ':oldBid'
            },
            viewPayload: {
              campaignId: ':entityId',
              view_adgroup_name: ':adgroupName',
              view_old_bid: ':oldBid',
              new_bid: ':newBid'
            },
            actionType: 'adgroupMetadataChange',
            actionSource: {
              pageUrl: ':pageUrl'
            },
            widget: 'campaign',
            primaryKey: ':entityId'
          }
        },
        disableCreateAdgroup: true,
        disableRowSelection: false,
        allowedActions: [
          {
            icon: 'task-status',
            title: 'EDIT STATUS',
            id: 'editStatus',
            dropDown: true,
            dropDownOptions: [
              {
                title: 'Enable',
                value: 'enabled',
                color: 'u-color-green-base'
              },
              { title: 'Pause', value: 'paused', color: 'u-color-orange-base' },
              { title: 'Archive', value: 'archived', color: 'u-color-red-base' }
            ]
          },
          {
            icon: 'dollar',
            title: 'Adjust Bid',
            id: 'adjustBids'
          }
        ],
        changeStatus: (vueRef, dropDownSelection, selections) => {
          const payloadArr = [];
          for (const selection of selections) {
            if (
              selection?.data?.walmart_cm_adgroup_status?.toLowerCase() ===
              dropDownSelection[0].value
            ) {
              vueRef.$snackbar.open({
                message: 'No new state selected for one or more entities.',
                duration: 6000,
                buttonColor: '#f5d908',
                actionText: ''
              });
            } else {
              const payload = cloneDeep(vueRef.stateChangeRequestPayload);
              const reqValues = {
                ':entityId': vueRef.entityId,
                ':adgroupId': selection?.data?.adgroup_id,
                ':adgroupName': selection?.data?.ams_cm_adgroup_adgroup_name,
                ':oldState': selection?.data?.ams_cm_adgroup_status,
                ':state': dropDownSelection[0].value,
                ':pageUrl': window.location.href
              };
              for (const item in reqValues) {
                deepReplaceObjectValues(payload, item, reqValues[item]);
              }
              payloadArr.push(payload);
            }
          }
          if (payloadArr.length) {
            vueRef.updateMetadataAction(payloadArr, {
              singular: 'Ad Group Status',
              plural: 'Ad Group Statuses',
              stopFetchApi: true
            });
          } else {
            vueRef.closeActionBar();
          }
        }
      },
      budget: {
        component: budget,
        apiConfigs: {
          getter: 'entityDetails/getCampaignDetails',
          fetchAction: 'entityDetails/fetchCampaingDetails',
          updateAction: 'entityDetails/updateCampaignDetails',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey',
          fetchTableDataComponentKeyGetter:
            'entityDetails/fetchIncrementTableDataKey'
        },
        reqMetadataMap: {
          ':oldBudget': 'dailyBudget',
          ':campaignId': 'entityId'
        },
        requestPayload: {
          actionPayload: {
            campaignId: ':campaignId',
            campaignType: 'SD',
            daily_budget: ':oldBudget',
            new_daily_budget: ':newBudget'
          },
          viewPayload: {
            name: ':name',
            campaignId: ':campaignId',
            previousBudget: ':oldBudget',
            newBudget: ':newBudget'
          },
          actionType: 'campaignMetadataChange',
          actionSource: {
            pageUrl: ':pageUrl'
          },
          widget: 'campaign',
          primaryKey: ':campaignId'
        }
      },
      settings: {
        requestPayload: {
          actionPayload: {
            campaignId: ':entityId',
            campaignType: 'SD'
          },
          viewPayload: {
            campaignId: ':entityId'
          },
          actionType: 'campaignMetadataChange',
          actionSource: {
            pageUrl: ':pageUrl'
          },
          widget: 'campaign',
          primaryKey: ':entityId'
        },
        component: settings,
        apiConfigs: {
          getter: 'entityDetails/getCampaignDetails',
          fetchAction: 'entityDetails/fetchCampaingDetails',
          updateAction: 'entityDetails/updateCampaignDetails',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey',
          fetchTableDataComponentKeyGetter:
            'entityDetails/fetchIncrementTableDataKey'
        }
      },
      negativeTargeting: {
        component: negativeTargeting,
        apiConfigs: {
          updateAction: 'entityDetails/updateCampaignDetails',
          getter: 'entityDetails/getCampaignDetails',
          getTargetingPageData: 'entityDetails/getTargetingPageDetails',
          fetchTargetPages: 'entityDetails/fetchTargetingPageData',
          getTableDataComponentKeyGetter:
            'entityDetails/getTableDataComponentKey',
          fetchTableDataComponentKeyGetter:
            'entityDetails/fetchIncrementTableDataKey'
        },
        negativeKeyword: {
          customActionPanelComponent: null,
          allowedActions: [
            {
              icon: 'archive',
              title: 'Archive',
              id: 'makeKeywordArchive'
            }
          ],
          addNegativeKeywordRequestPayload: {
            actionType: 'addNegativeKeyword',
            actionSource: {
              pageUrl: ':pageUrl'
            },
            widget: 'campaign',
            primaryKey: ':campaignId',
            actionPayload: {
              campaignId: ':campaignId',
              campaignType: 'SD',
              adgroup_id: ':adgroupId',
              new_keyword_text: ':newKeywordText',
              new_match_type: ':matchType'
            },
            viewPayload: {
              campaignId: ':campaignId',
              campaignType: 'SD',
              adgroupId: ':adgroupId',
              adgroupName: ':adgroupName',
              keywordText: ':newKeywordText',
              matchType: ':matchType'
            }
          },
          stateChange: {
            requestPayload: {
              actionPayload: {
                campaignId: ':campaignId',
                keyword_id: ':keywordId',
                campaignType: 'SD',
                adgroup_id: ':adgroupId',
                state: ':state'
              },
              viewPayload: {
                campaignId: ':campaignId',
                previousStatus: ':oldState',
                newStatus: ':state',
                keyword_text: ':keywordText',
                adgroup_name: ':adgroupName'
              },
              actionType: 'updateNegativeKeywordState',
              actionSource: {
                pageUrl: ':pageUrl'
              },
              widget: 'campaign',
              primaryKey: ':campaignId'
            }
          }
        },
        negativeTarget: {
          customActionPanelComponent: null, // addNegativeTargetsEnterListComponentConfig,
          allowedActions: [
            {
              icon: 'archive',
              title: 'Archive',
              id: 'makeTargetArchive'
            }
          ],
          addNegativeTargetRequestPayload: {
            actionType: 'addNegativeTarget',
            actionSource: {
              pageUrl: ':pageUrl'
            },
            widget: 'campaign',
            primaryKey: ':campaignId',
            actionPayload: {
              campaignId: ':campaignId',
              campaignType: 'SD',
              adgroupId: ':adgroupId',
              new_match_type: ':matchType',
              new_keyword_text: ':newKeywordText',
              new_expression: [
                {
                  type: 'asinSameAs', // asinCategorySameAs
                  value: ':newKeywordText'
                }
              ]
            },
            viewPayload: {
              campaignId: ':campaignId',
              adgroupId: ':adgroupId',
              keywordText: ':newKeywordText', // value in new expression
              matchType: ':matchType'
            }
          },
          stateChange: {
            requestPayload: {
              actionType: 'updateNegativeTargetState',
              actionSource: {
                pageUrl: ':pageUrl'
              },
              widget: 'campaign',
              primaryKey: ':campaignId',
              actionPayload: {
                campaignId: ':campaignId',
                campaignType: 'SD',
                target_id: ':keywordId',
                adgroupId: ':adgroupId',
                state: ':state'
              },
              viewPayload: {
                campaignId: ':campaignId',
                adgroupId: ':adgroupId',
                previousStatus: ':oldState',
                newStatus: ':state',
                adgroupName: ':adgroupName',
                keyword_text: ':keywordText',
                match_type: ':matchType'
              }
            }
          }
        },
        getWidget: (entityId, tabMetaData) => {
          const widget = {
            ':widget': tabMetaData?.widgets[0],
            ':orderByList': [],
            ':dimensionNameValueList': [],
            keepExistingDimensionNameValueList: true,
            replaceEntityInDimensionNameValueList: {
              ':campaign_id': entityId
            },
            replaceEntityInOperationsObject: {
              ':system': `campaign_${entityId}`
            }
          };
          return widget;
        },
        getHeaderOptions: (vueRef) => {
          const headerOptions = {
            props: {
              headerOptions: [
                {
                  ui_component: 'rb-button',
                  props: {
                    clickFn: vueRef.addCustomProductsButtonClick,
                    text: 'Add Custom Negative Products',
                    type: 'filled'
                  },
                  class: 'u-spacing-mr-xl'
                }
              ],
              enableDownload: false,
              enableManageColumns: !vueRef.selections.length,
              manageColumnGridPosition: 4,
              headerContainerGridStyles: {
                'grid-template-columns': 'auto 1fr auto',
                display: vueRef.selections.length ? 'none' : 'grid'
              },
              searchParams: {
                show: true,
                placeholder: 'Find a Negative Product1',
                searchDimension: 'ams_cm_negativetargets_keyword_text'
              },
              rowSelection: {
                mode: 'multiple',
                onSelectionChanged: vueRef.handleSelectionChange
              },
              rowHeight: 45
            }
          };
          return headerOptions;
        }
      }
    }
  },
  product_adgroup: product_configs,
  audience_adgroup: audience_configs
};
