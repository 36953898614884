<template>
  <section class="u-display-flex u-flex-align-items-center">
    <div
      v-for="(option, index) in options"
      :key="index"
      class="u-spacing-ml-m u-color-grey-lighter u-bg-color-grey-white"
      @click="handleIconClick(index)"
    >
      <div
        class="u-width-100"
        :class="{
          'not-enabled u-pointer-event-none u-cursor-default': !option.enabled,
          'u-cursor-pointer': option.enabled
        }"
      >
        <div class="u-width-100 u-display-flex u-flex-justify-content-center">
          <rb-icon
            class="rb-icon--medium"
            :icon="option.icon"
          />
        </div>
        <div
          class="u-font-size-8 u-spacing-mt-xs u-display-flex u-flex-justify-content-center u-flex-align-items-center"
        >
          {{ option.text }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    selectedOptionIndex: {
      type: Number,
      default: null
    }
  },
  data() {
    return {};
  },
  methods: {
    handleIconClick(clickIndex) {
      if (this.options[clickIndex].emit) {
        this.$emit(this.options[clickIndex].emit, clickIndex);
      }
    }
  }
};
</script>

<style lang="css" scoped>
.not-enabled {
  opacity: 0.5;
}
</style>
