import {
  getBaseActionType,
  getFinalMeasuresList
} from '@/pages/campaign-management/retailers/criteo/utils';
import requestConfig from '@/pages/campaign-management/retailers/criteo/campaigns/requestConfig.json';
import { klona } from 'klona/json';
import {
  additionalDateRanges,
  baseChartConfig,
  commonChartActionIcons,
  commonTableActionIcons,
  gridOptions
} from '@/pages/campaign-management/constants';
import {
  generatePlotRowChip,
  plotRowsPayloadExtraction
} from '@/pages/campaign-management/common';
import metricsDictionary from '@/pages/campaign-management/retailers/dictionary';

function chartRequest(retailer) {
  return {
    ...klona(requestConfig.chart),
    measuresList: getFinalMeasuresList(
      klona(requestConfig.chart.measuresList),
      retailer
    )
  };
}

function tableRequest(retailer) {
  return {
    ...klona(requestConfig.table),
    measuresList: getFinalMeasuresList(
      klona(requestConfig.table.measuresList),
      retailer
    )
  };
}

const WIDGET = 'campaign';
const PRIMARY_KEY = 'campaign_id';

export default (context, tab, retailer) => {
  const namespace = 'campaignManagement/';
  return {
    namespace,
    bookmark: {
      title: 'Campaigns'
    },
    filters: {
      additionalRanges: additionalDateRanges(),
      date: {
        max: 'criteo_common_campaigns.max_feed_date',
        min: 'criteo_common_campaigns.min_feed_date'
      },
      api: {
        cube: 'criteo_common_campaigns_workbench',
        page: `${retailer}_campaigns`,
        endPoint: 'FETCH_FILTERS_V2'
      },
      commonDateKey: `${retailer}-cm-common-date`,
      id: 'campaigns-filter',
      page: `${retailer}_campaigns`,
      listenerEvent: `${retailer}-campaigns-filters`,
      state: {
        setter: namespace + 'setGlobalFilters',
        getter: namespace + 'getGlobalFilters'
      },
      config: {
        search: {
          enable: true,
          width: '240px',
          emit: 'search-submit-campaign',
          placeholder: 'Search for a Campaign',
          metadata: {
            dimensionName: 'search',
            operator: 'ILIKE'
          }
        },
        emit: 'set-campaign-managment-campaigns-filter'
      }
    },
    widget1: {
      widget: WIDGET,
      headerOptions: commonChartActionIcons(),
      dynamicComponents: {
        plotRows(plotRowData) {
          return generatePlotRowChip.call(this, plotRowData);
        }
      },
      // change name to chart widget and table widget
      api: {
        request: chartRequest(retailer)
      },
      chart: {
        props: {
          showBigTooltip: true,
          chartConfig: {
            chartOptions: {
              ...baseChartConfig().chartOptions,
              axis_format: {
                y: { pre: 'currency' },
                y2: { pre: 'currency' }
              }
            }
          },
          ...metricsDictionary(tab, 'chart'),
          selectedMetricLimit: 2,
          minimumMetric: 6
        }
      }
    },
    widget2: {
      activityBar: {
        pageType: 'campaign'
      },
      messageBar: {
        pageType: 'campaigns'
      },
      actionsBar: {
        actions: [
          {
            icon: 'show-chart',
            title: 'PLOT ROWS',
            id: 'show-chart',
            removeSelectionState: false,
            handler(selectionLength, dropDownSelection, selections) {
              const payload = plotRowsPayloadExtraction(
                selections,
                PRIMARY_KEY
              );
              // this points to table-widget because have used call in the method call
              this.$emit('plotRowData', payload);
            }
          },
          {
            icon: 'dollar',
            title: 'Edit Budget',
            id: 'edit-budget',
            customState: {
              component: 'EditBudgetAndPacing',
              props: {
                heading: 'Adjust Budget',
                title: 'Campaign Budget',
                subHeading: 'campaign',
                showCheckbox: false,
                actionType: `${getBaseActionType(retailer)}CampaignEditBudget`,
                apiVersion: 'actionlog',
                retailer: context.$store.getters.getRetailer,
                widget: WIDGET,
                primaryKey: PRIMARY_KEY
              },
              events: {
                afterAPIActionToCall(payload) {
                  context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
                  context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
                },
                closePanelMethod: () => {
                  context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
                }
              }
            }
          },
          {
            icon: 'settings',
            title: 'Edit Attribution Settings',
            id: 'edit-attribution-settings',
            customState: {
              component: 'EditAttributionSettings',
              props: {
                title: '',
                actionType: `${getBaseActionType(
                  retailer
                )}CampaignEditAttribution`,
                apiVersion: 'actionlog',
                retailer: context.$store.getters.getRetailer,
                widget: WIDGET,
                primaryKey: PRIMARY_KEY
              },
              events: {
                afterAPIActionToCall(payload) {
                  context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
                  context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
                },
                closePanelMethod: () => {
                  context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
                }
              }
            }
          }
        ]
      },
      widget: WIDGET,
      primaryKey: PRIMARY_KEY,
      headerOptions: commonTableActionIcons(),
      api: {
        request: tableRequest(retailer),
        metadata: {
          paginationKey: 'final_auto_cubesdk_count'
        }
      },
      email: {
        fileSuffix: 'campaigns'
      },
      metrics: metricsDictionary(tab, 'table').allMetrics,
      table: {
        props: {
          gridOptions: gridOptions(),
          enableClientSideSorting: false,
          enableServerSideSorting: true,
          paginationChangeEvent: 'campaign-management-pagination',
          sortingChangeEvent: 'campaign-management-table-sort'
        },
        events: {}
      },
      plotRowsPayloadExtractor: plotRowsPayloadExtraction
    }
  };
};
