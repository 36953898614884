export const baseConfig = () => {
  return {
    name: 'username',
    email: 'username',
    date: 'date',
    username: 'username'
  };
};

export const addProductToLineItemsConfig = {
  props: {
    sku: 'promoted_product_id',
    image_url: 'image_url',
    product_title: 'name',
    product_url: 'product_url',
    ...baseConfig()
  },
  message: 'added product to a line item.',
  component: 'name-message-template',
  midComponents: [
    {
      component: 'skuUpdated',
      'sub-heading': 'Campaign budget'
    }
  ],
  displayType: 'currency'
};
