import BaseWidgetService from '@/components/pages/insights/amazon/cva/services/BaseWidgetService.js';
import HttpService from '@/utils/services/http-service';
import {
  observationFormatter,
  rem1V2ConfigEnabled
} from '@/components/pages/insights/amazon/cva/utils';

export default class CategoryLandscapeService extends BaseWidgetService {
  topCategoriesData = null;
  insightsData = [];
  allInsights = [];
  async fetchData(payloadCategories) {
    try {
      this.loading = true;
      this.error = false;
      await this.fetchInsightsData();
      await this.fetchTopCategories(payloadCategories);
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  async fetchInsightsData() {
    const metricsList = rem1V2ConfigEnabled()
      ? ['cva_insights_rank_v2']
      : ['cva_insights_rank'];
    const body = {
      page: 'customer_value_assesment',
      widget: 'cva_insights',
      entityType: 'insight',
      metricsList,
      dimensionsList: [
        'rank',
        'attributes',
        'client_id',
        'time_period',
        'browse_node'
      ],
      eventsList: [],
      enablePaginationCount: true,
      operations: {
        system: 'cva',
        enableNewPVPFormulaForSOV: false,
        enableDedupBeforeRollup: false,
        additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
        orderByList: [
          {
            dimension: 'attributes',
            direction: 'ASC'
          }
        ],
        limit: 3,
        page: 1,
        outerWhereClause: {
          dimensionNameValueList: []
        }
      },
      where: {
        dimensionNameValueList: [
          {
            dimensionName: 'time_period',
            dimensionValue: 'L90D',
            operator: 'EQUAL_TO'
          },
          {
            dimensionName: 'module',
            dimensionValue: 'Category Landscape',
            operator: 'EQUAL_TO'
          },
          {
            dimensionName: 'rank',
            dimensionValue: '1',
            operator: 'EQUAL_TO'
          }
        ]
      }
    };
    if (rem1V2ConfigEnabled()) {
      body.where.dimensionNameValueList.push(this.getRem1RetailerPayload());
    }
    const data = await HttpService.post('DASHBOARD_SERVICE', body, {
      append: '/cat_analytics/data'
    });
    const maxLettersCount = 400;
    let letterCount = 0;
    const insights = data?.data?.entityData;
    for (const entity of insights) {
      if (entity?.entityValue) {
        this.allInsights.push(observationFormatter(entity?.entityValue));
      }
    }
    this.insightsData = data?.data?.entityData
      ?.map((entity) => entity.entityValue)
      .filter((insight) => {
        letterCount += insight?.length;
        if (letterCount < maxLettersCount) {
          return true;
        } else {
          letterCount -= insight?.length;
          return false;
        }
      })
      .map(observationFormatter);
  }

  async fetchTopCategories(payloadCategories) {
    const metricsList = rem1V2ConfigEnabled()
      ? ['cva_sov_fill_percentage_v2', 'cva_cat_landscape_score_v2']
      : ['cva_sov_fill_percentage', 'cva_cat_landscape_score'];
    const body = {
      page: 'customer_value_assesment',
      widget: 'cva_category_landscape_top3',
      entityType: 'BROWSE_NODE',
      metricsList,
      dimensionsList: [
        'module',
        'client_id',
        'time_period',
        'level',
        'search_vol_post_value',
        'sov_post_value'
      ],
      eventsList: [],
      enablePaginationCount: true,
      operations: {
        system: 'cva',
        enableNewPVPFormulaForSOV: false,
        enableDedupBeforeRollup: false,
        additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: [],
        orderByList: [
          {
            dimension: rem1V2ConfigEnabled()
              ? 'cva_cat_landscape_score_v2'
              : 'cva_cat_landscape_score',
            direction: 'DESC'
          }
        ],
        limit: 3,
        page: 1,
        outerWhereClause: {
          dimensionNameValueList: []
        }
      },
      where: {
        dimensionNameValueList: [
          {
            dimensionName: 'time_period',
            dimensionValue: 'L90D',
            operator: 'EQUAL_TO'
          },
          {
            dimensionName: 'module',
            dimensionValue: 'Category Landscape',
            operator: 'EQUAL_TO'
          },
          {
            dimensionName: 'sov_post_value',
            dimensionValue: 0,
            operator: 'NOT_EQUAL_TO'
          }
        ]
      }
    };
    if (rem1V2ConfigEnabled()) {
      body.where.dimensionNameValueList.push(this.getRem1RetailerPayload());
    }
    body.where.dimensionNameValueList.push(...payloadCategories);
    const data = await HttpService.post('DASHBOARD_SERVICE', body, {
      append: '/cat_analytics/data'
    });
    const filterSet = [
      'search_vol_post_value',
      'sov_post_value',
      'sov_fill_percentage',
      'BROWSE_NODE'
    ];
    const nodeCountForCategory = 2;
    this.topCategoriesData = (data?.data?.entityData || []).map((entity) => {
      let filteredData = entity?.data
        .filter((item) => {
          if (filterSet.includes(item.name)) {
            return item;
          }
        })
        .map((item) => ({ [item.name]: item.RESULT?.[item.name] }));
      filteredData = Object.assign({}, ...filteredData);
      filteredData.entityType = entity?.entityType || 'BROWSE_NODE';
      let categoryNameFiltered = entity?.entityValue?.split('>');
      categoryNameFiltered = categoryNameFiltered
        .slice(categoryNameFiltered.length - nodeCountForCategory)
        .join('>')
        .trim(' ');
      filteredData.category_name = categoryNameFiltered;
      return filteredData;
    });
    this.topCategoriesData = this.topCategoriesData.sort(
      (a, b) => b.sov_fill_percentage - a.sov_fill_percentage
    );
  }
}
