// import Vue from 'vue';
// import Raven from 'raven-js';
// import RavenVue from 'raven-js/plugins/vue';

// var environment = 'dev';
// var domainArray = location.hostname.split('.');

// if (domainArray.length === 3) {
//   environment = 'prod';
// } else if (domainArray.length === 4) {
//   environment = domainArray[1];
// } else {
//   environment = 'dev';
// }

// if (document.location.port !== '8081' || document.location.port !== '8080') {
//   Raven
//     .config('https://bea1f4f97f024fd88e2d885663bb91f3@sentry.io/299049', {
//       environment
//     })
//     .addPlugin(RavenVue, Vue)
//     .install();
// }

const Sentry = require('@sentry/browser');
// import * as SentryFullStory from '@sentry/fullstory';
const SentryFullStory = require('@sentry/fullstory');
const VueIntegration = require('@sentry/integrations').Vue;
const { errorBlackList } = require('../constants/sentry');

exports.init = (VueInstance) => {
  if (location.hostname === 'localhost') {
    console.log('skipping sentry logs checks');
    return;
  }
  const dsn = 'https://bea1f4f97f024fd88e2d885663bb91f3@sentry.io/299049';
  const sentryOrgSlug = 'commerce-iq';
  Sentry.init({
    dsn,
    environment: window.environment || 'unknown',
    integrations: [
      new SentryFullStory.default(sentryOrgSlug),
      new VueIntegration({ Vue: VueInstance, attachProps: true })
    ],
    beforeSend(event, hint) {
      try {
        for (let i = 0; i < errorBlackList.length; i++) {
          if (
            hint.originalException.message.includes(errorBlackList[i].message)
          ) {
            return null;
          }
        }
        return event;
      } catch (err) {
        return event;
      }
    }
  });
  Sentry.setUser({ email: window.user });
  Sentry.configureScope((scope) => {
    scope.setTag('clientName', window.clientName);
    console.info('Sentry Error Handling Configured');
  });
};
