<template>
  <div class="u-display-inline-flex u-flex-align-items-center">
    <!-- <RbSelectV2
      class=" u-spacing-mr-s"
      :send-details="true"
      :on-close="onClose"
      :options="options"
    >
      <div
        slot="trigger"
        class="u-spacing-ph-l u-spacing-pv-s u-bg-color-grey-white u-color-blue-base u-font-size-5 u-border-radius-s u-display-inline-flex u-cursor-pointer"
      >
        Upload Changes
      </div>
    </RbSelectV2> -->
    <span
      class="u-spacing-ph-xl u-spacing-pv-m u-bg-color-grey-white u-color-blue-base u-font-size-5 u-border-radius-s u-display-inline-flex u-cursor-pointer u-font-size-6 u-font-weight-600"
      @click="triggerOverride"
    >
      {{ text }}
    </span>
    <span @click="downloadPress">
      <rb-icon
        class="u-spacing-ml-m u-color-grey-white rb-icon--large u-cursor-pointer"
        icon="download"
      />
    </span>
  </div>
</template>

<script>
import RbSelectV2 from '@/components/pages/businessInsights/rbSelectV2.vue';

export default {
  name: 'ReUploadSelect',
  components: {
    RbSelectV2
  },
  props: {
    text: {
      type: String,
      default: 'Overwrite Standard Internal Catalog'
    }
  },
  data() {
    return {
      options: [
        { title: 'Add or update few Catalog Columns ', key: 'append' },
        { title: 'Overwrite entire Standard  Catalog', key: 'overwrite' }
      ],
      showSelect: false
    };
  },
  methods: {
    triggerOverride(event) {
      this.$emit('onReUploadOptionSelect', { key: 'overwrite' });
    },
    onClose(context, val) {
      const option = val[0];
      this.$emit('onReUploadOptionSelect', option);
    },
    downloadPress() {
      this.$emit('onDownloadPress');
    }
  }
};
</script>

<style></style>
