const data = {
  crossRetailerSOV: [
    {
      success: true,
      entityType: 'dimension16',
      entityValue: 'tete7',
      data: [
        {
          name: 'client_flag',
          alias: 'client_flag',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            client_flag: 'client'
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'tete7'
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'generic_total_count_page_1_share_percentage',
          alias: 'generic_total_count_page_1',
          type: 'METRICS',
          PVP: {
            PVP_DIFF_generic_total_count_page_1_share_percentage: -2.67,
            PVP_generic_total_count_page_1_share_percentage: -2.67,
            generic_total_count_page_1_share_percentage: -2.67
          },
          RESULT: {
            generic_total_count_page_1_share_percentage: 9.27
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    },
    {
      success: true,
      entityType: 'dimension16',
      entityValue: 'unikw',
      data: [
        {
          name: 'client_flag',
          alias: 'client_flag',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            client_flag: 'client'
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'unikw'
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'generic_total_count_page_1_share_percentage',
          alias: 'generic_total_count_page_1',
          type: 'METRICS',
          PVP: {
            PVP_DIFF_generic_total_count_page_1_share_percentage: -2.4,
            PVP_generic_total_count_page_1_share_percentage: -2.4,
            generic_total_count_page_1_share_percentage: -2.4
          },
          RESULT: {
            generic_total_count_page_1_share_percentage: 14.5
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    },
    {
      success: true,
      entityType: 'dimension16',
      entityValue: 'unp0t',
      data: [
        {
          name: 'client_flag',
          alias: 'client_flag',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            client_flag: 'client'
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'unp0t'
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'generic_total_count_page_1_share_percentage',
          alias: 'generic_total_count_page_1',
          type: 'METRICS',
          PVP: {
            PVP_DIFF_generic_total_count_page_1_share_percentage: 0.4,
            PVP_generic_total_count_page_1_share_percentage: 0.4,
            generic_total_count_page_1_share_percentage: 0.4
          },
          RESULT: {
            generic_total_count_page_1_share_percentage: 3.9
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    },
    {
      success: true,
      entityType: 'dimension16',
      entityValue: 'unk2p',
      data: [
        {
          name: 'client_flag',
          alias: 'client_flag',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            client_flag: 'client'
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'unk2p'
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'generic_total_count_page_1_share_percentage',
          alias: 'generic_total_count_page_1',
          type: 'METRICS',
          PVP: {
            PVP_DIFF_generic_total_count_page_1_share_percentage: 2.9,
            PVP_generic_total_count_page_1_share_percentage: 2.9,
            generic_total_count_page_1_share_percentage: 2.9
          },
          RESULT: {
            generic_total_count_page_1_share_percentage: 7.5
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    }
  ],
  BOBase: [
    {
      success: true,
      entityType: 'asin',
      entityValue: 'B1N9IPD6IPE',
      data: [
        {
          name: 'client_flag',
          alias: 'client_flag',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            client_flag: 'client'
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'B1N9IPD6IPE'
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'generic_total_count_page_1_share_percentage',
          alias: 'generic_total_count_page_1',
          type: 'METRICS',
          PVP: {
            PVP_DIFF_generic_total_count_page_1_share_percentage: -2.67,
            PVP_generic_total_count_page_1_share_percentage: -2.67,
            generic_total_count_page_1_share_percentage: -2.67
          },
          RESULT: {
            generic_total_count_page_1_share_percentage: 9.27
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    },
    {
      success: true,
      entityType: 'asin',
      entityValue: 'B1M06LBE7UH',
      data: [
        {
          name: 'client_flag',
          alias: 'client_flag',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            client_flag: 'client'
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'B1M06LBE7UH'
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'generic_total_count_page_1_share_percentage',
          alias: 'generic_total_count_page_1',
          type: 'METRICS',
          PVP: {
            PVP_DIFF_generic_total_count_page_1_share_percentage: -2.43,
            PVP_generic_total_count_page_1_share_percentage: -2.43,
            generic_total_count_page_1_share_percentage: -2.43
          },
          RESULT: {
            generic_total_count_page_1_share_percentage: 10.83
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    },
    {
      success: true,
      entityType: 'asin',
      entityValue: 'B2H00LDD2UL',
      data: [
        {
          name: 'client_flag',
          alias: 'client_flag',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            client_flag: 'client'
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'B2H00LDD2UL'
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'generic_total_count_page_1_share_percentage',
          alias: 'generic_total_count_page_1',
          type: 'METRICS',
          PVP: {
            PVP_DIFF_generic_total_count_page_1_share_percentage: -1.15,
            PVP_generic_total_count_page_1_share_percentage: -1.15,
            generic_total_count_page_1_share_percentage: -1.15
          },
          RESULT: {
            generic_total_count_page_1_share_percentage: 8.75
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    },
    {
      success: true,
      entityType: 'asin',
      entityValue: 'B79Y6XB2G5P',
      data: [
        {
          name: 'client_flag',
          alias: 'client_flag',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            client_flag: 'client'
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'B79Y6XB2G5P'
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'generic_total_count_page_1_share_percentage',
          alias: 'generic_total_count_page_1',
          type: 'METRICS',
          PVP: {
            PVP_DIFF_generic_total_count_page_1_share_percentage: -1.45,
            PVP_generic_total_count_page_1_share_percentage: -1.45,
            generic_total_count_page_1_share_percentage: -1.45
          },
          RESULT: {
            generic_total_count_page_1_share_percentage: 7.5
          },
          TIMESERIES: null,
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    }
  ],
  OPS_Timeseries: [
    {
      success: true,
      entityType: 'dimension16',
      entityValue: 'unp0t',
      data: [
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'unp0t'
          },
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'ordered_product_sales',
          alias: 'ordered_product_sales',
          type: 'METRICS',
          PVP: {
            ordered_product_sales: 56.15,
            PVP_ordered_product_sales: -56.15,
            PVP_DIFF_ordered_product_sales: 56.15
          },
          RESULT: {
            ordered_product_sales: 23
          },
          TIMESERIES: [
            {
              feed_date: '04/15/2022',
              ordered_product_sales: 235000
            },
            {
              feed_date: '04/16/2022',
              ordered_product_sales: 230300
            },
            {
              feed_date: '04/17/2022',
              ordered_product_sales: 230300
            },
            {
              feed_date: '04/18/2022',
              ordered_product_sales: 230300
            },
            {
              feed_date: '04/19/2022',
              ordered_product_sales: 249100
            },
            {
              feed_date: '04/20/2022',
              ordered_product_sales: 249100
            },
            {
              feed_date: '04/21/2022',
              ordered_product_sales: 249100
            },
            {
              feed_date: '04/22/2022',
              ordered_product_sales: 249100
            },
            {
              feed_date: '04/23/2022',
              ordered_product_sales: 230300
            },
            {
              feed_date: '04/24/2022',
              ordered_product_sales: 225600
            },
            {
              feed_date: '04/25/2022',
              ordered_product_sales: 225600
            },
            {
              feed_date: '04/26/2022',
              ordered_product_sales: 225600
            },
            {
              feed_date: '04/27/2022',
              ordered_product_sales: 225600
            },
            {
              feed_date: '04/28/2022',
              ordered_product_sales: 249100
            },
            {
              feed_date: '04/29/2022',
              ordered_product_sales: 249100
            },
            {
              feed_date: '04/30/2022',
              ordered_product_sales: 249100
            },
            {
              feed_date: '05/01/2022',
              ordered_product_sales: 227950
            },
            {
              feed_date: '05/02/2022',
              ordered_product_sales: 211500
            },
            {
              feed_date: '05/03/2022',
              ordered_product_sales: 204450
            },
            {
              feed_date: '05/04/2022',
              ordered_product_sales: 211500
            },
            {
              feed_date: '05/05/2022',
              ordered_product_sales: 244400
            },
            {
              feed_date: '05/06/2022',
              ordered_product_sales: 249100
            },
            {
              feed_date: '05/07/2022',
              ordered_product_sales: 253800
            },
            {
              feed_date: '05/08/2022',
              ordered_product_sales: 230300
            },
            {
              feed_date: '05/09/2022',
              ordered_product_sales: 253800
            },
            {
              feed_date: '05/10/2022',
              ordered_product_sales: 251450
            },
            {
              feed_date: '05/11/2022',
              ordered_product_sales: 232650
            },
            {
              feed_date: '05/12/2022',
              ordered_product_sales: 249100
            },
            {
              feed_date: '05/13/2022',
              ordered_product_sales: 249100
            },
            {
              feed_date: '05/14/2022',
              ordered_product_sales: 267823
            }
          ],
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    },
    {
      success: true,
      entityType: 'dimension16',
      entityValue: 'unikw',
      data: [
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'unikw'
          },
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'ordered_product_sales',
          alias: 'ordered_product_sales',
          type: 'METRICS',
          PVP: {
            ordered_product_sales: 1794543.78,
            PVP_ordered_product_sales: 51.34,
            PVP_DIFF_ordered_product_sales: 921314.89
          },
          RESULT: {
            ordered_product_sales: 2715858.67
          },
          TIMESERIES: [
            {
              feed_date: '04/15/2022',
              ordered_product_sales: 321345.53
            },
            {
              feed_date: '04/16/2022',
              ordered_product_sales: 314918.34
            },
            {
              feed_date: '04/17/2022',
              ordered_product_sales: 318067.36
            },
            {
              feed_date: '04/18/2022',
              ordered_product_sales: 321247.75
            },
            {
              feed_date: '04/19/2022',
              ordered_product_sales: 334097.31
            },
            {
              feed_date: '04/20/2022',
              ordered_product_sales: 337438.34
            },
            {
              feed_date: '04/21/2022',
              ordered_product_sales: 327315
            },
            {
              feed_date: '04/22/2022',
              ordered_product_sales: 317496.98
            },
            {
              feed_date: '04/23/2022',
              ordered_product_sales: 301621.22
            },
            {
              feed_date: '04/24/2022',
              ordered_product_sales: 283524.86
            },
            {
              feed_date: '04/25/2022',
              ordered_product_sales: 275018.29
            },
            {
              feed_date: '04/26/2022',
              ordered_product_sales: 286019.71
            },
            {
              feed_date: '04/27/2022',
              ordered_product_sales: 294599.45
            },
            {
              feed_date: '04/28/2022',
              ordered_product_sales: 300491.17
            },
            {
              feed_date: '04/29/2022',
              ordered_product_sales: 294481.86
            },
            {
              feed_date: '04/30/2022',
              ordered_product_sales: 288592.67
            },
            {
              feed_date: '05/01/2022',
              ordered_product_sales: 285706.53
            },
            {
              feed_date: '05/02/2022',
              ordered_product_sales: 277135.87
            },
            {
              feed_date: '05/03/2022',
              ordered_product_sales: 279906.76
            },
            {
              feed_date: '05/04/2022',
              ordered_product_sales: 282705.42
            },
            {
              feed_date: '05/05/2022',
              ordered_product_sales: 274224.99
            },
            {
              feed_date: '05/06/2022',
              ordered_product_sales: 282451.45
            },
            {
              feed_date: '05/07/2022',
              ordered_product_sales: 307871.98
            },
            {
              feed_date: '05/08/2022',
              ordered_product_sales: 286320.87
            },
            {
              feed_date: '05/09/2022',
              ordered_product_sales: 272004.94
            },
            {
              feed_date: '05/10/2022',
              ordered_product_sales: 255684.24
            },
            {
              feed_date: '05/11/2022',
              ordered_product_sales: 260798.75
            },
            {
              feed_date: '05/12/2022',
              ordered_product_sales: 250366.79
            },
            {
              feed_date: '05/13/2022',
              ordered_product_sales: 240351.84
            },
            {
              feed_date: '05/14/2022',
              ordered_product_sales: 245612.4
            }
          ],
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    },
    {
      success: true,
      entityType: 'dimension16',
      entityValue: 'tete7',
      data: [
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'tete7'
          },
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'ordered_product_sales',
          alias: 'ordered_product_sales',
          type: 'METRICS',
          PVP: {
            ordered_product_sales: 14724749.18,
            PVP_ordered_product_sales: 2.54,
            PVP_DIFF_ordered_product_sales: 374660.71
          },
          RESULT: {
            ordered_product_sales: 15099409.89
          },
          TIMESERIES: [
            {
              feed_date: '04/15/2022',
              ordered_product_sales: 16721123
            },
            {
              feed_date: '04/16/2022',
              ordered_product_sales: 16470306
            },
            {
              feed_date: '04/17/2022',
              ordered_product_sales: 16635009
            },
            {
              feed_date: '04/18/2022',
              ordered_product_sales: 16801359
            },
            {
              feed_date: '04/19/2022',
              ordered_product_sales: 17473413
            },
            {
              feed_date: '04/20/2022',
              ordered_product_sales: 17648147
            },
            {
              feed_date: '04/21/2022',
              ordered_product_sales: 17251064
            },
            {
              feed_date: '04/22/2022',
              ordered_product_sales: 16862915
            },
            {
              feed_date: '04/23/2022',
              ordered_product_sales: 16230556
            },
            {
              feed_date: '04/24/2022',
              ordered_product_sales: 15500181
            },
            {
              feed_date: '04/25/2022',
              ordered_product_sales: 15151427
            },
            {
              feed_date: '04/26/2022',
              ordered_product_sales: 15757484
            },
            {
              feed_date: '04/27/2022',
              ordered_product_sales: 16230208
            },
            {
              feed_date: '04/28/2022',
              ordered_product_sales: 16554812
            },
            {
              feed_date: '04/29/2022',
              ordered_product_sales: 16306490
            },
            {
              feed_date: '04/30/2022',
              ordered_product_sales: 16061893
            },
            {
              feed_date: '05/01/2022',
              ordered_product_sales: 15941429
            },
            {
              feed_date: '05/02/2022',
              ordered_product_sales: 15582746
            },
            {
              feed_date: '05/03/2022',
              ordered_product_sales: 15738574
            },
            {
              feed_date: '05/04/2022',
              ordered_product_sales: 15895960
            },
            {
              feed_date: '05/05/2022',
              ordered_product_sales: 15538301
            },
            {
              feed_date: '05/06/2022',
              ordered_product_sales: 16004450
            },
            {
              feed_date: '05/07/2022',
              ordered_product_sales: 17444850
            },
            {
              feed_date: '05/08/2022',
              ordered_product_sales: 16528995
            },
            {
              feed_date: '05/09/2022',
              ordered_product_sales: 15909158
            },
            {
              feed_date: '05/10/2022',
              ordered_product_sales: 15193246
            },
            {
              feed_date: '05/11/2022',
              ordered_product_sales: 15497111
            },
            {
              feed_date: '05/12/2022',
              ordered_product_sales: 15032198
            },
            {
              feed_date: '05/13/2022',
              ordered_product_sales: 14581232
            },
            {
              feed_date: '05/14/2022',
              ordered_product_sales: 14234561
            }
          ],
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    },
    {
      success: true,
      entityType: 'dimension16',
      entityValue: 'unk2p',
      data: [
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'unk2p'
          },
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'ordered_product_sales',
          alias: 'ordered_product_sales',
          type: 'METRICS',
          PVP: {
            ordered_product_sales: 563149.92,
            PVP_ordered_product_sales: 49,
            PVP_DIFF_ordered_product_sales: 275937.68
          },
          RESULT: {
            ordered_product_sales: 839087.6
          },
          TIMESERIES: [
            {
              feed_date: '04/15/2022',
              ordered_product_sales: 235461.65
            },
            {
              feed_date: '04/16/2022',
              ordered_product_sales: 244879.83
            },
            {
              feed_date: '04/17/2022',
              ordered_product_sales: 239981.78
            },
            {
              feed_date: '04/18/2022',
              ordered_product_sales: 235182.37
            },
            {
              feed_date: '04/19/2022',
              ordered_product_sales: 216367.64
            },
            {
              feed_date: '04/20/2022',
              ordered_product_sales: 212040
            },
            {
              feed_date: '04/21/2022',
              ordered_product_sales: 224762.93
            },
            {
              feed_date: '04/22/2022',
              ordered_product_sales: 238248.14
            },
            {
              feed_date: '04/23/2022',
              ordered_product_sales: 262073.54
            },
            {
              feed_date: '04/24/2022',
              ordered_product_sales: 293522.65
            },
            {
              feed_date: '04/25/2022',
              ordered_product_sales: 311133.04
            },
            {
              feed_date: '04/26/2022',
              ordered_product_sales: 286242.36
            },
            {
              feed_date: '04/27/2022',
              ordered_product_sales: 269068.35
            },
            {
              feed_date: '04/28/2022',
              ordered_product_sales: 258305.31
            },
            {
              feed_date: '04/29/2022',
              ordered_product_sales: 289302.56
            },
            {
              feed_date: '04/30/2022',
              ordered_product_sales: 324018.63
            },
            {
              feed_date: '05/01/2022',
              ordered_product_sales: 362900.08
            },
            {
              feed_date: '05/02/2022',
              ordered_product_sales: 391932.11
            },
            {
              feed_date: '05/03/2022',
              ordered_product_sales: 438964.43
            },
            {
              feed_date: '05/04/2022',
              ordered_product_sales: 517978.28
            },
            {
              feed_date: '05/05/2022',
              ordered_product_sales: 549056.74
            },
            {
              feed_date: '05/06/2022',
              ordered_product_sales: 647887.8
            },
            {
              feed_date: '05/07/2022',
              ordered_product_sales: 531267.48
            },
            {
              feed_date: '05/08/2022',
              ordered_product_sales: 547623.49
            },
            {
              feed_date: '05/09/2022',
              ordered_product_sales: 602385.6
            },
            {
              feed_date: '05/10/2022',
              ordered_product_sales: 674671.04
            },
            {
              feed_date: '05/11/2022',
              ordered_product_sales: 653421.74
            },
            {
              feed_date: '05/12/2022',
              ordered_product_sales: 705694.23
            },
            {
              feed_date: '05/13/2022',
              ordered_product_sales: 762150.03
            },
            {
              feed_date: '05/14/2022',
              ordered_product_sales: 765321.77
            }
          ],
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    }
  ],
  ROAS_Timeseries: [
    {
      success: true,
      entityType: 'dimension16',
      entityValue: 'unp0t',
      data: [
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'unp0t'
          },
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'campaign_name',
          alias: 'campaign_name',
          type: 'METRICS',
          PVP: null,
          RESULT: null,
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'asin',
          alias: 'asin',
          type: 'METRICS',
          PVP: null,
          RESULT: null,
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'roi',
          alias: 'roi',
          type: 'METRICS',
          PVP: {
            PVP_roi: -41.67,
            PVP_DIFF_roi: -18.35,
            roi: 44.04
          },
          RESULT: {
            roi: 25.69
          },
          TIMESERIES: [
            {
              feed_date: '04/15/2022',
              roi: 5.95
            },
            {
              feed_date: '04/16/2022',
              roi: 5.83
            },
            {
              feed_date: '04/17/2022',
              roi: 5.83
            },
            {
              feed_date: '04/18/2022',
              roi: 6.31
            },
            {
              feed_date: '04/19/2022',
              roi: 6.31
            },
            {
              feed_date: '04/20/2022',
              roi: 6.31
            },
            {
              feed_date: '04/21/2022',
              roi: 6.31
            },
            {
              feed_date: '04/22/2022',
              roi: 5.83
            },
            {
              feed_date: '04/23/2022',
              roi: 5.71
            },
            {
              feed_date: '04/24/2022',
              roi: 5.71
            },
            {
              feed_date: '04/25/2022',
              roi: 5.71
            },
            {
              feed_date: '04/26/2022',
              roi: 5.71
            },
            {
              feed_date: '04/27/2022',
              roi: 6.31
            },
            {
              feed_date: '04/28/2022',
              roi: 6.31
            },
            {
              feed_date: '04/29/2022',
              roi: 6.31
            },
            {
              feed_date: '04/30/2022',
              roi: 5.77
            },
            {
              feed_date: '05/01/2022',
              roi: 5.36
            },
            {
              feed_date: '05/02/2022',
              roi: 5.18
            },
            {
              feed_date: '05/03/2022',
              roi: 5.36
            },
            {
              feed_date: '05/04/2022',
              roi: 6.19
            },
            {
              feed_date: '05/05/2022',
              roi: 6.31
            },
            {
              feed_date: '05/06/2022',
              roi: 6.43
            },
            {
              feed_date: '05/07/2022',
              roi: 5.83
            },
            {
              feed_date: '05/08/2022',
              roi: 6.43
            },
            {
              feed_date: '05/09/2022',
              roi: 6.37
            },
            {
              feed_date: '05/10/2022',
              roi: 5.89
            },
            {
              feed_date: '05/11/2022',
              roi: 6.31
            },
            {
              feed_date: '05/12/2022',
              roi: 6.31
            },
            {
              feed_date: '05/13/2022',
              roi: 6.31
            },
            {
              feed_date: '05/14/2022',
              roi: 5.95
            }
          ],
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    },
    {
      success: true,
      entityType: 'dimension16',
      entityValue: 'unk2p',
      data: [
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'unk2p'
          },
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'campaign_name',
          alias: 'campaign_name',
          type: 'METRICS',
          PVP: null,
          RESULT: null,
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'asin',
          alias: 'asin',
          type: 'METRICS',
          PVP: null,
          RESULT: null,
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'roi',
          alias: 'roi',
          type: 'METRICS',
          PVP: {
            PVP_roi: 8.93,
            PVP_DIFF_roi: 4.03,
            roi: 45.13
          },
          RESULT: {
            roi: 49.16
          },
          TIMESERIES: [
            {
              feed_date: '04/15/2022',
              roi: 6.1
            },
            {
              feed_date: '04/16/2022',
              roi: 6.34
            },
            {
              feed_date: '04/17/2022',
              roi: 6.22
            },
            {
              feed_date: '04/18/2022',
              roi: 6.09
            },
            {
              feed_date: '04/19/2022',
              roi: 5.61
            },
            {
              feed_date: '04/20/2022',
              roi: 5.49
            },
            {
              feed_date: '04/21/2022',
              roi: 5.82
            },
            {
              feed_date: '04/22/2022',
              roi: 6.17
            },
            {
              feed_date: '04/23/2022',
              roi: 6.79
            },
            {
              feed_date: '04/24/2022',
              roi: 7.6
            },
            {
              feed_date: '04/25/2022',
              roi: 8.06
            },
            {
              feed_date: '04/26/2022',
              roi: 7.42
            },
            {
              feed_date: '04/27/2022',
              roi: 6.97
            },
            {
              feed_date: '04/28/2022',
              roi: 6.69
            },
            {
              feed_date: '04/29/2022',
              roi: 7.49
            },
            {
              feed_date: '04/30/2022',
              roi: 8.39
            },
            {
              feed_date: '05/01/2022',
              roi: 9.4
            },
            {
              feed_date: '05/02/2022',
              roi: 8.0
            },
            {
              feed_date: '05/03/2022',
              roi: 8.96
            },
            {
              feed_date: '05/04/2022',
              roi: 7.0
            },
            {
              feed_date: '05/05/2022',
              roi: 7.42
            },
            {
              feed_date: '05/06/2022',
              roi: 8.76
            },
            {
              feed_date: '05/07/2022',
              roi: 7.18
            },
            {
              feed_date: '05/08/2022',
              roi: 8.18
            },
            {
              feed_date: '05/09/2022',
              roi: 7.0
            },
            {
              feed_date: '05/10/2022',
              roi: 7.84
            },
            {
              feed_date: '05/11/2022',
              roi: 8.0
            },
            {
              feed_date: '05/12/2022',
              roi: 8.64
            },
            {
              feed_date: '05/13/2022',
              roi: 9.33
            },
            {
              feed_date: '05/14/2022',
              roi: 10.08
            }
          ],
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    },
    {
      success: true,
      entityType: 'dimension16',
      entityValue: 'unikw',
      data: [
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'unikw'
          },
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'campaign_name',
          alias: 'campaign_name',
          type: 'METRICS',
          PVP: null,
          RESULT: null,
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'asin',
          alias: 'asin',
          type: 'METRICS',
          PVP: null,
          RESULT: null,
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'roi',
          alias: 'roi',
          type: 'METRICS',
          PVP: {
            PVP_roi: -95.29,
            PVP_DIFF_roi: -26.49,
            roi: 27.8
          },
          RESULT: {
            roi: 1.31
          },
          TIMESERIES: [
            {
              feed_date: '04/15/2022',
              roi: 8.2
            },
            {
              feed_date: '04/16/2022',
              roi: 8.28
            },
            {
              feed_date: '04/17/2022',
              roi: 8.36
            },
            {
              feed_date: '04/18/2022',
              roi: 8.7
            },
            {
              feed_date: '04/19/2022',
              roi: 8.79
            },
            {
              feed_date: '04/20/2022',
              roi: 8.52
            },
            {
              feed_date: '04/21/2022',
              roi: 8.27
            },
            {
              feed_date: '04/22/2022',
              roi: 7.85
            },
            {
              feed_date: '04/23/2022',
              roi: 7.38
            },
            {
              feed_date: '04/24/2022',
              roi: 7.16
            },
            {
              feed_date: '04/25/2022',
              roi: 7.45
            },
            {
              feed_date: '04/26/2022',
              roi: 7.67
            },
            {
              feed_date: '04/27/2022',
              roi: 7.82
            },
            {
              feed_date: '04/28/2022',
              roi: 7.67
            },
            {
              feed_date: '04/29/2022',
              roi: 7.51
            },
            {
              feed_date: '04/30/2022',
              roi: 7.44
            },
            {
              feed_date: '05/01/2022',
              roi: 7.22
            },
            {
              feed_date: '05/02/2022',
              roi: 7.29
            },
            {
              feed_date: '05/03/2022',
              roi: 7.36
            },
            {
              feed_date: '05/04/2022',
              roi: 7.14
            },
            {
              feed_date: '05/05/2022',
              roi: 7.35
            },
            {
              feed_date: '05/06/2022',
              roi: 8.02
            },
            {
              feed_date: '05/07/2022',
              roi: 7.46
            },
            {
              feed_date: '05/08/2022',
              roi: 7.08
            },
            {
              feed_date: '05/09/2022',
              roi: 6.66
            },
            {
              feed_date: '05/10/2022',
              roi: 6.79
            },
            {
              feed_date: '05/11/2022',
              roi: 6.52
            },
            {
              feed_date: '05/12/2022',
              roi: 6.26
            },
            {
              feed_date: '05/13/2022',
              roi: 6.01
            },
            {
              feed_date: '05/14/2022',
              roi: 6.19
            }
          ],
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    },
    {
      success: true,
      entityType: 'dimension16',
      entityValue: 'tete7',
      data: [
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'tete7'
          },
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'campaign_name',
          alias: 'campaign_name',
          type: 'METRICS',
          PVP: null,
          RESULT: null,
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'asin',
          alias: 'asin',
          type: 'METRICS',
          PVP: null,
          RESULT: null,
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'roi',
          alias: 'roi',
          type: 'METRICS',
          PVP: {
            PVP_roi: -15.59,
            PVP_DIFF_roi: -1.9,
            roi: 12.19
          },
          RESULT: {
            roi: 10.29
          },
          TIMESERIES: [
            {
              feed_date: '04/15/2022',
              roi: 6.1
            },
            {
              feed_date: '04/16/2022',
              roi: 6.16
            },
            {
              feed_date: '04/17/2022',
              roi: 6.22
            },
            {
              feed_date: '04/18/2022',
              roi: 6.47
            },
            {
              feed_date: '04/19/2022',
              roi: 6.54
            },
            {
              feed_date: '04/20/2022',
              roi: 6.39
            },
            {
              feed_date: '04/21/2022',
              roi: 6.25
            },
            {
              feed_date: '04/22/2022',
              roi: 6.01
            },
            {
              feed_date: '04/23/2022',
              roi: 5.74
            },
            {
              feed_date: '04/24/2022',
              roi: 5.61
            },
            {
              feed_date: '04/25/2022',
              roi: 5.84
            },
            {
              feed_date: '04/26/2022',
              roi: 6.01
            },
            {
              feed_date: '04/27/2022',
              roi: 6.13
            },
            {
              feed_date: '04/28/2022',
              roi: 6.04
            },
            {
              feed_date: '04/29/2022',
              roi: 5.95
            },
            {
              feed_date: '04/30/2022',
              roi: 5.9
            },
            {
              feed_date: '05/01/2022',
              roi: 5.77
            },
            {
              feed_date: '05/02/2022',
              roi: 5.83
            },
            {
              feed_date: '05/03/2022',
              roi: 5.89
            },
            {
              feed_date: '05/04/2022',
              roi: 5.75
            },
            {
              feed_date: '05/05/2022',
              roi: 5.93
            },
            {
              feed_date: '05/06/2022',
              roi: 6.46
            },
            {
              feed_date: '05/07/2022',
              roi: 6.12
            },
            {
              feed_date: '05/08/2022',
              roi: 5.89
            },
            {
              feed_date: '05/09/2022',
              roi: 5.63
            },
            {
              feed_date: '05/10/2022',
              roi: 5.74
            },
            {
              feed_date: '05/11/2022',
              roi: 5.57
            },
            {
              feed_date: '05/12/2022',
              roi: 5.4
            },
            {
              feed_date: '05/13/2022',
              roi: 5.24
            },
            {
              feed_date: '05/14/2022',
              roi: 5.4
            }
          ],
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    }
  ],
  SOV_Timeseries: [
    {
      success: true,
      entityType: 'dimension16',
      entityValue: 'tete7',
      data: [
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'tete7'
          },
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'generic_total_count_page_1_share_percentage',
          alias: 'generic_total_count_page_1',
          type: 'METRICS',
          PVP: {
            PVP_DIFF_generic_total_count_page_1_share_percentage: -2.67,
            PVP_generic_total_count_page_1_share_percentage: -2.67,
            generic_total_count_page_1_share_percentage: -2.67
          },
          RESULT: {
            generic_total_count_page_1_share_percentage: 9.27
          },
          TIMESERIES: [
            {
              feed_date: '04/15/2022',
              generic_total_count_page_1_share_percentage: 9.5
            },
            {
              feed_date: '04/16/2022',
              generic_total_count_page_1_share_percentage: 10
            },
            {
              feed_date: '04/17/2022',
              generic_total_count_page_1_share_percentage: 10
            },
            {
              feed_date: '04/18/2022',
              generic_total_count_page_1_share_percentage: 10
            },
            {
              feed_date: '04/19/2022',
              generic_total_count_page_1_share_percentage: 10
            },
            {
              feed_date: '04/20/2022',
              generic_total_count_page_1_share_percentage: 10
            },
            {
              feed_date: '04/21/2022',
              generic_total_count_page_1_share_percentage: 10
            },
            {
              feed_date: '04/22/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '04/23/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '04/24/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '04/25/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '04/26/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '04/27/2022',
              generic_total_count_page_1_share_percentage: 10
            },
            {
              feed_date: '04/28/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '04/29/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '04/30/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '05/01/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '05/02/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '05/03/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '05/04/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '05/05/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '05/06/2022',
              generic_total_count_page_1_share_percentage: 10
            },
            {
              feed_date: '05/07/2022',
              generic_total_count_page_1_share_percentage: 10
            },
            {
              feed_date: '05/08/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '05/09/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '05/10/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '05/11/2022',
              generic_total_count_page_1_share_percentage: 9
            },
            {
              feed_date: '05/12/2022',
              generic_total_count_page_1_share_percentage: 8
            },
            {
              feed_date: '05/13/2022',
              generic_total_count_page_1_share_percentage: 8
            },
            {
              feed_date: '05/14/2022',
              generic_total_count_page_1_share_percentage: 8.7
            }
          ],
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    },
    {
      success: true,
      entityType: 'dimension16',
      entityValue: 'unikw',
      data: [
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'unikw'
          },
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'generic_total_count_page_1_share_percentage',
          alias: 'generic_total_count_page_1',
          type: 'METRICS',
          PVP: {
            PVP_DIFF_generic_total_count_page_1_share_percentage: -2.4,
            PVP_generic_total_count_page_1_share_percentage: -2.4,
            generic_total_count_page_1_share_percentage: -2.4
          },
          RESULT: {
            generic_total_count_page_1_share_percentage: 14.5
          },
          TIMESERIES: [
            {
              feed_date: '04/15/2022',
              generic_total_count_page_1_share_percentage: 16.4
            },
            {
              feed_date: '04/16/2022',
              generic_total_count_page_1_share_percentage: 17
            },
            {
              feed_date: '04/17/2022',
              generic_total_count_page_1_share_percentage: 17
            },
            {
              feed_date: '04/18/2022',
              generic_total_count_page_1_share_percentage: 17
            },
            {
              feed_date: '04/19/2022',
              generic_total_count_page_1_share_percentage: 18
            },
            {
              feed_date: '04/20/2022',
              generic_total_count_page_1_share_percentage: 17
            },
            {
              feed_date: '04/21/2022',
              generic_total_count_page_1_share_percentage: 17
            },
            {
              feed_date: '04/22/2022',
              generic_total_count_page_1_share_percentage: 16
            },
            {
              feed_date: '04/23/2022',
              generic_total_count_page_1_share_percentage: 15
            },
            {
              feed_date: '04/24/2022',
              generic_total_count_page_1_share_percentage: 14
            },
            {
              feed_date: '04/25/2022',
              generic_total_count_page_1_share_percentage: 15
            },
            {
              feed_date: '04/26/2022',
              generic_total_count_page_1_share_percentage: 15
            },
            {
              feed_date: '04/27/2022',
              generic_total_count_page_1_share_percentage: 16
            },
            {
              feed_date: '04/28/2022',
              generic_total_count_page_1_share_percentage: 15
            },
            {
              feed_date: '04/29/2022',
              generic_total_count_page_1_share_percentage: 15
            },
            {
              feed_date: '04/30/2022',
              generic_total_count_page_1_share_percentage: 15
            },
            {
              feed_date: '05/01/2022',
              generic_total_count_page_1_share_percentage: 14
            },
            {
              feed_date: '05/02/2022',
              generic_total_count_page_1_share_percentage: 15
            },
            {
              feed_date: '05/03/2022',
              generic_total_count_page_1_share_percentage: 15
            },
            {
              feed_date: '05/04/2022',
              generic_total_count_page_1_share_percentage: 14
            },
            {
              feed_date: '05/05/2022',
              generic_total_count_page_1_share_percentage: 15
            },
            {
              feed_date: '05/06/2022',
              generic_total_count_page_1_share_percentage: 16
            },
            {
              feed_date: '05/07/2022',
              generic_total_count_page_1_share_percentage: 15
            },
            {
              feed_date: '05/08/2022',
              generic_total_count_page_1_share_percentage: 14
            },
            {
              feed_date: '05/09/2022',
              generic_total_count_page_1_share_percentage: 13
            },
            {
              feed_date: '05/10/2022',
              generic_total_count_page_1_share_percentage: 14
            },
            {
              feed_date: '05/11/2022',
              generic_total_count_page_1_share_percentage: 13
            },
            {
              feed_date: '05/12/2022',
              generic_total_count_page_1_share_percentage: 13
            },
            {
              feed_date: '05/13/2022',
              generic_total_count_page_1_share_percentage: 12
            },
            {
              feed_date: '05/14/2022',
              generic_total_count_page_1_share_percentage: 12.1
            }
          ],
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    },
    {
      success: true,
      entityType: 'dimension16',
      entityValue: 'unp0t',
      data: [
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'unp0t'
          },
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'generic_total_count_page_1_share_percentage',
          alias: 'generic_total_count_page_1',
          type: 'METRICS',
          PVP: {
            PVP_DIFF_generic_total_count_page_1_share_percentage: 0.4,
            PVP_generic_total_count_page_1_share_percentage: 0.4,
            generic_total_count_page_1_share_percentage: 0.4
          },
          RESULT: {
            generic_total_count_page_1_share_percentage: 3.9
          },
          TIMESERIES: [
            {
              feed_date: '04/15/2022',
              generic_total_count_page_1_share_percentage: 4.1
            },
            {
              feed_date: '04/16/2022',
              generic_total_count_page_1_share_percentage: 4.02
            },
            {
              feed_date: '04/17/2022',
              generic_total_count_page_1_share_percentage: 4.02
            },
            {
              feed_date: '04/18/2022',
              generic_total_count_page_1_share_percentage: 4.35
            },
            {
              feed_date: '04/19/2022',
              generic_total_count_page_1_share_percentage: 4.35
            },
            {
              feed_date: '04/20/2022',
              generic_total_count_page_1_share_percentage: 4.35
            },
            {
              feed_date: '04/21/2022',
              generic_total_count_page_1_share_percentage: 4.35
            },
            {
              feed_date: '04/22/2022',
              generic_total_count_page_1_share_percentage: 4.02
            },
            {
              feed_date: '04/23/2022',
              generic_total_count_page_1_share_percentage: 3.94
            },
            {
              feed_date: '04/24/2022',
              generic_total_count_page_1_share_percentage: 3.94
            },
            {
              feed_date: '04/25/2022',
              generic_total_count_page_1_share_percentage: 3.94
            },
            {
              feed_date: '04/26/2022',
              generic_total_count_page_1_share_percentage: 3.94
            },
            {
              feed_date: '04/27/2022',
              generic_total_count_page_1_share_percentage: 4.35
            },
            {
              feed_date: '04/28/2022',
              generic_total_count_page_1_share_percentage: 4.35
            },
            {
              feed_date: '04/29/2022',
              generic_total_count_page_1_share_percentage: 4.35
            },
            {
              feed_date: '04/30/2022',
              generic_total_count_page_1_share_percentage: 3.98
            },
            {
              feed_date: '05/01/2022',
              generic_total_count_page_1_share_percentage: 3.69
            },
            {
              feed_date: '05/02/2022',
              generic_total_count_page_1_share_percentage: 3.57
            },
            {
              feed_date: '05/03/2022',
              generic_total_count_page_1_share_percentage: 3.69
            },
            {
              feed_date: '05/04/2022',
              generic_total_count_page_1_share_percentage: 4.26
            },
            {
              feed_date: '05/05/2022',
              generic_total_count_page_1_share_percentage: 4.35
            },
            {
              feed_date: '05/06/2022',
              generic_total_count_page_1_share_percentage: 4.43
            },
            {
              feed_date: '05/07/2022',
              generic_total_count_page_1_share_percentage: 4.02
            },
            {
              feed_date: '05/08/2022',
              generic_total_count_page_1_share_percentage: 4.43
            },
            {
              feed_date: '05/09/2022',
              generic_total_count_page_1_share_percentage: 4.39
            },
            {
              feed_date: '05/10/2022',
              generic_total_count_page_1_share_percentage: 4.06
            },
            {
              feed_date: '05/11/2022',
              generic_total_count_page_1_share_percentage: 4.35
            },
            {
              feed_date: '05/12/2022',
              generic_total_count_page_1_share_percentage: 4.35
            },
            {
              feed_date: '05/13/2022',
              generic_total_count_page_1_share_percentage: 4.35
            },
            {
              feed_date: '05/14/2022',
              generic_total_count_page_1_share_percentage: 4.1
            }
          ],
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    },
    {
      success: true,
      entityType: 'dimension16',
      entityValue: 'unk2p',
      data: [
        {
          name: 'dimension16',
          alias: 'dimension16',
          type: 'METRICS',
          PVP: null,
          RESULT: {
            dimension16: 'unk2p'
          },
          TIMESERIES: [],
          PVP_TIMESERIES: null,
          SPLY: null
        },
        {
          name: 'generic_total_count_page_1_share_percentage',
          alias: 'generic_total_count_page_1',
          type: 'METRICS',
          PVP: {
            PVP_DIFF_generic_total_count_page_1_share_percentage: 2.9,
            PVP_generic_total_count_page_1_share_percentage: 2.9,
            generic_total_count_page_1_share_percentage: 2.9
          },
          RESULT: {
            generic_total_count_page_1_share_percentage: 7.5
          },
          TIMESERIES: [
            {
              feed_date: '04/15/2022',
              generic_total_count_page_1_share_percentage: 4.3
            },
            {
              feed_date: '04/16/2022',
              generic_total_count_page_1_share_percentage: 4.21
            },
            {
              feed_date: '04/17/2022',
              generic_total_count_page_1_share_percentage: 4.17
            },
            {
              feed_date: '04/18/2022',
              generic_total_count_page_1_share_percentage: 4.0
            },
            {
              feed_date: '04/19/2022',
              generic_total_count_page_1_share_percentage: 3.96
            },
            {
              feed_date: '04/20/2022',
              generic_total_count_page_1_share_percentage: 4.08
            },
            {
              feed_date: '04/21/2022',
              generic_total_count_page_1_share_percentage: 4.21
            },
            {
              feed_date: '04/22/2022',
              generic_total_count_page_1_share_percentage: 4.42
            },
            {
              feed_date: '04/23/2022',
              generic_total_count_page_1_share_percentage: 4.68
            },
            {
              feed_date: '04/24/2022',
              generic_total_count_page_1_share_percentage: 4.82
            },
            {
              feed_date: '04/25/2022',
              generic_total_count_page_1_share_percentage: 4.63
            },
            {
              feed_date: '04/26/2022',
              generic_total_count_page_1_share_percentage: 4.49
            },
            {
              feed_date: '04/27/2022',
              generic_total_count_page_1_share_percentage: 4.4
            },
            {
              feed_date: '04/28/2022',
              generic_total_count_page_1_share_percentage: 4.66
            },
            {
              feed_date: '04/29/2022',
              generic_total_count_page_1_share_percentage: 4.94
            },
            {
              feed_date: '04/30/2022',
              generic_total_count_page_1_share_percentage: 5.24
            },
            {
              feed_date: '05/01/2022',
              generic_total_count_page_1_share_percentage: 5.45
            },
            {
              feed_date: '05/02/2022',
              generic_total_count_page_1_share_percentage: 5.78
            },
            {
              feed_date: '05/03/2022',
              generic_total_count_page_1_share_percentage: 6.3
            },
            {
              feed_date: '05/04/2022',
              generic_total_count_page_1_share_percentage: 6.49
            },
            {
              feed_date: '05/05/2022',
              generic_total_count_page_1_share_percentage: 7.07
            },
            {
              feed_date: '05/06/2022',
              generic_total_count_page_1_share_percentage: 6.43
            },
            {
              feed_date: '05/07/2022',
              generic_total_count_page_1_share_percentage: 6.88
            },
            {
              feed_date: '05/08/2022',
              generic_total_count_page_1_share_percentage: 7.23
            },
            {
              feed_date: '05/09/2022',
              generic_total_count_page_1_share_percentage: 7.66
            },
            {
              feed_date: '05/10/2022',
              generic_total_count_page_1_share_percentage: 8.28
            },
            {
              feed_date: '05/11/2022',
              generic_total_count_page_1_share_percentage: 8.61
            },
            {
              feed_date: '05/12/2022',
              generic_total_count_page_1_share_percentage: 8.95
            },
            {
              feed_date: '05/13/2022',
              generic_total_count_page_1_share_percentage: 9.31
            },
            {
              feed_date: '05/14/2022',
              generic_total_count_page_1_share_percentage: 8.9
            }
          ],
          PVP_TIMESERIES: null,
          SPLY: null
        }
      ]
    }
  ]
};

export default data;
