<template>
  <span
    v-bind="$attrs"
    @click="click"
  >
    <slot />
  </span>
</template>

<script>
export default {
  methods: {
    click(event) {
      this.$emit('click', event);
    }
  }
};
</script>
