var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"fit-content u-cursor-pointer u-font-size-7 u-cursor-pointer u-color-grey-lighter",class:{
    'rule-builder-chip': _vm.chipSize === 'm',
    'filter-chip-s-match': _vm.chipSize === 'filter-chip-s-match'
  },on:{"click":_vm.handleChipClick}},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-spacing-ph-xs"},[(_vm.preTextIcon)?_c('span',{staticClass:"u-spacing-mr-xs"},[_c('rb-icon',{staticClass:"rb-icon rb-icon--small",attrs:{"icon":_vm.preTextIcon}})],1):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.preText))]),_c('div',{staticClass:"u-display-flex u-spacing-ml-xs"},_vm._l((_vm.chipSelections),function(selectionNested,tempSlectionsIndex){return _c('div',{key:tempSlectionsIndex,staticClass:"u-display-flex"},_vm._l((selectionNested),function(selection,index){return _c('div',{key:index},[(((selection || {}).prefix || {}).text)?_c('span',{staticClass:"u-spacing-mr-xs"},[_vm._v(_vm._s(((selection || {}).prefix || {}).text))]):_vm._e(),(((selection || {}).infix || {}).text)?_c('span',{staticClass:"u-spacing-mr-xs",class:[
              'u-color-' +
                ((((selection || {}).infix || {}).class || {}).color ||
                  'grey-base')
            ]},[_vm._v(" "+_vm._s(((selection || {}).infix || {}).text)+" ")]):_vm._e(),(((selection || {}).suffix || {}).text)?_c('span',{staticClass:"u-spacing-mr-xs"},[_vm._v(_vm._s(((selection || {}).suffix || {}).text))]):_vm._e()])}),0)}),0),(_vm.hasCaret)?_c('span',{staticClass:"u-spacing-mr-xs"},[_c('rb-icon',{staticClass:"rb-icon rb-icon--medium u-cursor-pointer",attrs:{"icon":'caret-down'}})],1):_vm._e(),_vm._t("before-cross"),(_vm.hasCross)?_c('span',{staticClass:"cross-icon u-spacing-pv-xxs",on:{"click":function($event){$event.stopPropagation();return _vm.handleCrossClick($event)}}},[_c('rb-icon',{staticClass:"rb-icon rb-icon--xx-small u-cursor-pointer",attrs:{"icon":'cross'}})],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }