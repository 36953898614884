var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-width-100 u-overflow-auto u-display-flex u-spacing-p-xxl"},[(_vm.showRemoveFilePopUp)?_c('modal',{attrs:{"modal-config":_vm.modalConfig},on:{"clickedOutside":_vm.closeModal}}):_vm._e(),_c('section',{staticClass:"createBudgetPlan--steps u-width-100"},[_c('budgetPlannerHeader',{attrs:{"step":_vm.step}}),(_vm.fileUploadStatus === 'not_started')?_c('section',{staticClass:"u-dispay-flex u-spacing-mb-l",staticStyle:{"max-width":"650px"}},[_c('div',{staticClass:"u-dispay-flex u-flex-justify-content-space-between u-flex-align-items-center"},[_c('div',{staticClass:"u-color-blue-base u-spacing-pb-s u-display-flex u-flex-align-content-center u-flex-direction-row",class:{ blockPointerEvents: _vm.downloadNewFileFromTemplateLoader },on:{"click":function($event){return _vm.downloadBudgetPlannerFile(
              true,
              'downloadNewFileFromTemplateLoader'
            )}}},[_c('rb-icon',{staticClass:"fs-download-new-budget-plan rb-icon--medium u-cursor-pointer",class:{ rotate: _vm.downloadNewFileFromTemplateLoader },attrs:{"icon":_vm.downloadNewFileFromTemplateLoader ? 'spinner' : 'download'}}),_c('div',{staticClass:"u-spacing-pl-s link-custom-hover u-font-size-5 u-cursor-pointer u-font-weight-600"},[_vm._v(" Download budget plan template ")])],1)]),_c('dropArea',{staticClass:"fs-upload-budget-plan",on:{"onFileUpload":_vm.handleFileUpload}})],1):_c('div',[(
          _vm.fileUploadStatus === 'complete' ||
          _vm.fileUploadStatus === 'incomplete' ||
          _vm.fileUploadStatus === 'icorrect_template'
        )?_c('div',{staticClass:"u-display-flex u-flex-align-content-center"},[(_vm.fileUploadStatus !== 'icorrect_template')?_c('div',{staticClass:"u-color-grey-lighter u-spacing-pr-l upload-link-custom u-spacing-pb-s u-display-flex u-flex-align-content-center u-flex-direction-row",class:{ blockPointerEvents: _vm.downloadNewFileFromTemplateLoader },on:{"click":function($event){return _vm.downloadBudgetPlannerFile(
              true,
              'downloadNewFileFromTemplateLoader'
            )}}},[_c('rb-icon',{staticClass:"rb-icon--medium u-cursor-pointer",class:{ rotate: _vm.downloadNewFileFromTemplateLoader },attrs:{"icon":_vm.downloadNewFileFromTemplateLoader ? 'spinner' : 'download'}}),_c('div',{staticClass:"u-spacing-pl-s u-font-size-5 u-cursor-pointer fs-download-new-budget-plan u-font-weight-600"},[_vm._v(" Download budget plan template ")])],1):_vm._e(),_c('div',{staticClass:"u-color-grey-lighter u-spacing-pb-s u-display-flex u-flex-align-content-center u-flex-direction-row upload-link-custom fs-reupload-budget-plan",on:{"click":_vm.reUploadPlan}},[_c('rb-icon',{staticClass:"rb-icon--medium u-cursor-pointer is-flipped",attrs:{"icon":"download"}}),_c('div',{staticClass:"u-spacing-pl-s u-font-size-5 u-cursor-pointer u-font-weight-600"},[_vm._v(" Reupload budget plan ")]),_c('dropArea',{ref:"reuploadDropArea",staticStyle:{"display":"none"},on:{"onFileUpload":_vm.onReupload}})],1)]):_vm._e(),_c('div',{staticClass:"u-spacing-p-m loaderCard",class:{
          errorBorder:
            _vm.fileUploadStatus === 'incomplete' ||
            _vm.fileUploadStatus === 'error' ||
            _vm.fileUploadStatus === 'icorrect_template'
        },staticStyle:{"max-width":"700px"},style:({
          background: _vm.fileUploadStatus === 'complete' ? '#007cf6' : '#fff'
        })},[(
            _vm.fileUploadStatus === 'started' || _vm.fileUploadStatus === 'error'
          )?_c('div',{staticClass:"u-width-100"},[_c('div',{staticClass:"u-display-flex u-flex-direction-row u-flex-justify-content-space-between u-spacing-mt-m u-font-weight-600"},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippy),expression:"tippy"}],staticClass:"u-font-size-4 u-text-overflow-ellipsis",attrs:{"title":_vm.fileDetails.name}},[_vm._v(" "+_vm._s(_vm.fileDetails.name)+" ")]),_c('div',{staticClass:"u-color-blue-base u-cursor-pointer u-font-size-5 link-custom-hover",on:{"click":function($event){return _vm.resetFileUpload()}}},[_vm._v(" Cancel ")])]),_c('div',{staticClass:"u-spacing-mt-m u-spacing-mb-s",class:_vm.fileUploadStatus === 'started' ? 'loader' : 'errorLoader'}),_c('div',{staticClass:"u-font-weight-400 u-font-size-5 u-spacing-mb-m",class:_vm.fileUploadStatus === 'started'
                ? 'u-color-blue-base'
                : 'u-color-red-base'},[_vm._v(" "+_vm._s(_vm.uploadMessage)+" ")])]):_c('div',{staticClass:"u-width-100 u-display-flex u-flex-align-items-center u-spacing-pv-m u-flex-justify-content-space-between"},[_c('div',{staticClass:"u-font-size-4 u-display-flex",class:{
              'u-color-grey-white': _vm.fileUploadStatus === 'complete',
              'u-color-red-base':
                _vm.fileUploadStatus === 'incomplete' ||
                _vm.fileUploadStatus === 'icorrect_template'
            }},[_c('div',{staticClass:"u-spacing-pl-m",staticStyle:{"padding-top":"2px"}},[_c('rb-icon',{staticClass:"rb-icon--bp-custom",style:(_vm.fileUploadStatus === 'complete'
                    ? ''
                    : 'transform: rotate(180deg)'),attrs:{"icon":_vm.fileUploadStatus === 'complete'
                    ? 'check-fill-circle'
                    : 'info-circle-fill'}})],1),_c('div',{staticClass:"u-spacing-ph-s",staticStyle:{"max-width":"300px"}},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:(_vm.tippy),expression:"tippy"}],staticClass:"u-font-weight-600 u-font-size-2 u-text-overflow-ellipsis",class:{
                  'u-color-grey-base':
                    _vm.fileUploadStatus === 'incomplete' ||
                    _vm.fileUploadStatus === 'icorrect_template'
                },attrs:{"title":_vm.fileDetails.name}},[_vm._v(" "+_vm._s(_vm.fileDetails.name)+" ")]),_c('div',{staticClass:"u-spacing-pt-xs u-font-size-5"},[_vm._v(" "+_vm._s(_vm.fileUploadStatus === 'incomplete' || _vm.fileUploadStatus === 'icorrect_template' ? _vm.uploadMessage : _vm.fileDetails.time)+" ")])])]),_c('div',{staticClass:"u-display-flex"},[_c('div',{staticClass:"defaultHollowButton"},[(_vm.fileUploadStatus === 'complete')?_c('rb-button',{staticClass:"u-spacing-mr-m fs-remove-budget-plan",staticStyle:{"min-width":"105px"},attrs:{"text":"Remove","type":"hollow","click-fn":_vm.onClickRemove}}):_c('div',{staticClass:"u-height-100 u-display-flex u-flex-align-items-center u-spacing-ph-l"},[_c('span',{staticClass:"link-custom-hover u-cursor-pointer u-font-size-6 u-color-blue-base fs-remove-budget-plan",on:{"click":_vm.onClickRemove}},[_vm._v("Remove")])])],1),(_vm.fileUploadStatus === 'complete')?_c('rb-button',{staticClass:"u-spacing-mr-m fs-download-existing-budget-plan",staticStyle:{"min-width":"105px"},attrs:{"text":_vm.downloadFileLoader ? '' : 'Download',"type":"filled","loading":_vm.downloadFileLoader,"disabled":_vm.downloadFileLoader,"click-fn":_vm.downloadBudgetPlanFromFilePath}}):_vm._e(),_c('div',{staticClass:"hollow-button-download"},[(_vm.fileUploadStatus === 'incomplete')?_c('rb-button',{staticClass:"u-spacing-mr-m fs-budget-plan-download-and-fix",staticStyle:{"min-width":"105px"},attrs:{"text":_vm.downloadFileLoader ? '' : 'Download and fix',"type":"hollow","loading":_vm.downloadFileLoader,"disabled":_vm.downloadFileLoader,"click-fn":_vm.downloadBudgetPlanFromFilePath}}):_vm._e(),(_vm.fileUploadStatus === 'icorrect_template')?_c('rb-button',{staticClass:"u-spacing-mr-m fs-download-new-budget-plan",staticStyle:{"min-width":"105px"},attrs:{"text":_vm.downloadNewFileFromButtonLoader
                    ? ''
                    : 'Download new template',"type":"hollow","disabled":_vm.downloadNewFileFromButtonLoader,"loading":_vm.downloadNewFileFromButtonLoader,"click-fn":_vm.downloadNewTemplate}}):_vm._e()],1)],1)])])]),_c('budgetPlannerFooter',{staticClass:"u-spacing-pt-xxl",attrs:{"show-left-btn":false,"disable-right-btn":_vm.disablePreviewButton,"btn-right-text":"Preview","custom-class-right":"fs-budget-plan-move-to-preview-step"},on:{"onClickBtnRight":_vm.nextStep}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }