<template>
  <section
    class="u-width-100 u-spacing-ph-xxl u-position-relative"
    :class="$route.query.manual ? 'u-spacing-pb-xxxl' : ''"
  >
    <div class="height-fix u-width-100 u-position-relative">
      <div
        class="u-bg-color-white-base u-spacing-pb-s u-position-fixed u-spacing-pt-xl u-spacing-pr-m float-campaign-summary"
      >
        <div class="u-spacing-mt-s">
          <budgetImpact
            :disable-launch-campaign="!selectedIds.length"
            :cards-list="budgetImpact"
            :load-cards="loadBudgetImpact"
            @launchCampaign="handleLauchCampaign"
          />
        </div>

        <div
          class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-center"
        >
          <div>
            <RbSplitTabs
              v-if="
                tablsListWithCount.PENDING > 0 && tablsListWithCount.READY > 0
              "
              :tabs-list="tabsList"
              :active-tab="selectedTab"
              :is-tabs-list="true"
              @tabClicked="handleTabClicked"
            />
          </div>
          <rb-button
            :click-fn="createOwnCampaign"
            :text="$route.query.manual ? 'Add Campaign' : 'Create Own Campaign'"
            :type="'hollow'"
            size="l"
            class="u-border-color-blue-base manual-campaign-button"
          />
        </div>
      </div>
      <div class="fill-absolute-height-dummy-div" />
      <campaignList
        class="u-spacing-pb-s"
        :campaign-list="campaignList"
        :active-tab="selectedTab"
        :tabs-list-with-count="tablsListWithCount"
        @removeCampaign="handleRemoveCampaign"
        @editCampaign="handleEditCampaign"
        @selectedItems="handleSelectedItem"
      />

      <div
        v-if="!$route.query.manual"
        v-tippy-html
        class="u-spacing-pb-s u-spacing-pt-m"
      >
        <rb-button
          class="u-min-width-100px"
          text="Back"
          type="hollow"
          :click-fn="prevStep"
        />
        <div
          id="leftButtonTippy"
          class="u-display-none"
        >
          <div class="u-spacing-p-l">
            <div class="u-font-size-5">Tooltip</div>
            <div
              class="u-font-weight-400 u-color-grey-light u-font-size-6 u-spacing-pt-m"
            >
              All the changes made in campaigns will be lost if you go back to
              <br />
              previous step.
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!$route.query.manual"
        class="u-font-size-6 u-spacing-pb-xxxl u-display-flex u-flex-align-items-center"
      >
        <rb-icon
          class="rb-icon--medium u-color-grey-lighter u-spacing-mr-xs"
          :icon="'info-circle-fill'"
        />
        All the changes made in campaigns will be lost if you go back to
        previous step.
      </div>
    </div>
    <loader
      v-if="campaignCreationLoader"
      class="fill--parent loader-z-index u-spacing-pt-xxl"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
    />
  </section>
</template>

<script>
import createCampaignHeader from '@/components/ams/campaign_creation/steps/common/header.vue';
import createCampaignFooter from '@/components/ams/campaign_creation/steps/common/footer.vue';
import { createCampaign } from '@/components/ams/campaign_creation/steps/setup/campaign/apiRequest.js';
import RbSplitTabs from '@/components/widgets/splitTabs.vue';
import budgetImpact from './budgetImpact';
import campaignList from './campaignList';
import HttpService from '@/utils/services/http-service';
import loader from '@/components/basic/loader';
import moment from 'moment-timezone';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import { cloneDeep } from 'lodash';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
export default {
  components: {
    loader,
    createCampaignHeader,
    createCampaignFooter,
    budgetImpact,
    campaignList,
    RbSplitTabs
  },
  props: {
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    },
    step: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data: () => ({
    campaignTypeMap: {
      sponsoredProducts: 'sponsoredProducts',
      createOwnCampaignSP: 'sponsoredProducts',
      sponsoredBrands: 'sponsoredBrands',
      createOwnCampaignSB: 'sponsoredBrands'
    },
    tippy: {
      html: '#leftButtonTippy',
      theme: 'light',
      placement: 'bottom-start',
      arrow: false,
      interactive: false,
      popperOptions: {
        modifiers: {
          preventOverflow: {
            enabled: false
          },
          hide: {
            enabled: false
          }
        }
      }
    },
    budgetImpact: [
      { label: 'Spend / Budget', value: null, unit: 'percentage' },
      { label: 'Budget Impact', value: null, unit: 'currency' },
      { label: 'Estimated Sales', value: null, unit: 'currency' }
    ],
    loadBudgetImpact: false,
    campaignCreationLoader: false,
    tabsList: [],
    selectedTab: 'Campaigns ready to launch',
    campaignList: [],
    selectedIds: []
  }),
  computed: {
    campaignsReadyToLaunch() {
      let targetsPresent = true;
      for (const item of this.allStepsData?.suggestedCampaigns) {
        if (!item.targets) {
          targetsPresent = false;
        }
      }
      return targetsPresent;
    },
    allStepsData() {
      return this.$store.getters[this.selectedValues.getter];
    },
    tablsListWithCount() {
      return {
        READY: this.campaignList?.filter((item) => !item.isPending)?.length,
        PENDING: this.campaignList?.filter((item) => item.isPending)?.length
      };
    }
  },
  watch: {
    tablsListWithCount: {
      handler(newVal) {
        this.tabsList = [];
        if (newVal.READY) {
          this.tabsList = [
            `${newVal.READY} Campaign${
              newVal.READY === 1 ? '' : 's'
            } ready to launch`
          ];
        }
        if (newVal.PENDING) {
          this.tabsList.push(
            `${newVal.PENDING} Campaign${
              newVal.PENDING === 1 ? '' : 's'
            } with pending actions`
          );
        }
        this.selectedTab = this.tabsList[0];
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.setGoalConfig();
    this.setSuggestedCamapignList();
    this.getBudgetImpact();
  },
  methods: {
    setGoalConfig() {
      if (
        this.allStepsData?.suggestedGoalConfig &&
        this.allStepsData?.suggestedGoals
      ) {
        const allStepsData = this.allStepsData;
        allStepsData.goalConfig = cloneDeep(allStepsData.suggestedGoalConfig);
        allStepsData.allGoals = cloneDeep(allStepsData.suggestedGoals);
        delete allStepsData.suggestedGoalConfig;
        delete allStepsData.suggestedGoals;
        this.$store.dispatch(this.selectedValues.action, {
          ...this.allStepsData
        });
      }
    },
    formSuggestedCampaignId(item) {
      return `${item?.suggestedCampaignId}_${item?.suggestedCampaignName}`;
    },
    async callExecuteApi(cube) {
      return await HttpLayer.post({
        APIData: cube
      });
    },
    async getBudgetImpact() {
      let totalBudget = 0;
      this.loadBudgetImpact = true;
      const spendVsBudgetCube = this.step.spendVsBudget({
        profile:
          this.allStepsData?.suggestedCampaigns?.[0]?.profile?.profile_id ||
          this.allStepsData?.profile?.profile_id ||
          null,
        portfolio: this.allStepsData?.portfolio?.portfolio_id
      });
      let spendVsBudget = null;
      if (spendVsBudgetCube) {
        spendVsBudget = await this.callExecuteApi(spendVsBudgetCube);
        spendVsBudget =
          spendVsBudget?.data?.[0]?.RESULT?.spend_vs_budget || null;
      }
      let totalRoas = null;
      const profilePortfolioIdMap = {};
      for (const item of this.campaignList) {
        if (this.selectedIds?.includes(this.formSuggestedCampaignId(item))) {
          const profileInfo = {
            profile: item?.profile?.profile_id,
            portfolio: item?.portfolio?.portfolio_id
          };
          totalBudget += item.budget;
          const profileKey = `${profileInfo?.profile || 'NA'}_${
            profileInfo?.portfolio || 'NA'
          }`;
          let roas = null;
          if (!profilePortfolioIdMap?.[profileKey]) {
            const roasCube = this.step.roas(profileInfo);
            roas = await this.callExecuteApi(roasCube);
            roas = roas?.data?.[0]?.RESULT?.roi * item.budget || 0;
            profilePortfolioIdMap[profileKey] = roas;
          } else {
            roas = profilePortfolioIdMap?.[profileKey];
          }
          totalRoas += roas;
          console.log(item);
        }
      }
      this.budgetImpact = [
        {
          label: 'Spend / Budget',
          value: spendVsBudget || null,
          unit: 'percentage'
        },
        {
          label: 'Budget Impact',
          value: totalBudget || null,
          unit: 'currency'
        },
        { label: 'Estimated Sales', value: totalRoas, unit: 'currency' }
      ];
      this.loadBudgetImpact = false;
    },
    handleSelectedItem(selectedItems) {
      this.selectedIds = selectedItems.map((item) =>
        this.formSuggestedCampaignId(item)
      );
      this.getBudgetImpact();
    },
    async handleLauchCampaign() {
      this.campaignCreationLoader = true;
      createCampaign(this.allStepsData, this.selectedIds, this);
    },
    async fetchTargets(suggCampaignId, skus) {
      const targetReq = cloneDeep(
        this.allStepsData?.goalConfig?.customMetadata?.campaigns.filter(
          (item) => item.suggestedCampaignId === suggCampaignId
        )?.[0]?.targeting?.request
      );
      let skuValues = '';
      skus.forEach((item) => {
        skuValues += `'${item.asin}',`;
      });
      skuValues = skuValues.slice(0, -1);
      deepReplaceObjectValues(targetReq, ':asin', skuValues);
      return await HttpLayer.post({
        APIData: targetReq
      });
    },
    updatePendingActions() {
      for (const item of this.campaignList) {
        item.messages = {
          length: 0
        };
        const { campaignType, targets, skus } = item;
        const skusAndTargetsExists = targets?.length && skus?.length;
        if (this.campaignTypeMap[campaignType] === 'sponsoredBrands') {
          this.validateSBFlow(item);
        } else {
          item.isPending = !skusAndTargetsExists;
          if (!item.targets?.length) {
            item.messages.length += 1;
            item.messages.targets = 'Add Targets to this campaign';
          }
          if (!item.skus?.length) {
            item.messages.length += 1;
            item.messages.skus = 'Add SKUs to this campaign';
          }
        }
      }
      this.$store.dispatch(this.selectedValues.action, {
        ...this.allStepsData,
        ...{ suggestedCampaigns: this.campaignList }
      });
    },

    validateSBFlow(item) {
      const {
        adFormat,
        targets,
        skus,
        selectedAsset,
        creativeSkus,
        adFormatSubType,
        storeOnAmazonPage,
        storeOnAmazonStore,
        brandInfo,
        customURL,
        homePage,
        selectedPages
      } = item;
      if (adFormat === 'productCollection') {
        if (!adFormatSubType) {
          item.isPending = true;
          item.messages.length += 1;
          item.messages.adFormat = 'Ad format is incomplete';
        }
        const commonValidations =
          targets?.length &&
          creativeSkus?.length === 3 &&
          brandInfo?.brandName &&
          selectedAsset;
        if (adFormatSubType === 'store_on_amazon') {
          item.isPending = !(
            commonValidations &&
            storeOnAmazonPage?.storePageUrl &&
            storeOnAmazonStore
          );
          if (!(storeOnAmazonPage?.storePageUrl && storeOnAmazonStore)) {
            item.messages.length += 1;
            item.messages.adFormat = 'Ad format is incomplete';
          }
        } else if (adFormatSubType === 'new_landing_page') {
          item.isPending = !(commonValidations && skus?.length > 2);
          if (!(skus?.length > 2)) {
            item.messages.length += 1;
            item.messages.skus = 'Add minimum of 3 SKUs to this campaign';
          }
        } else if (adFormatSubType === 'custom_url') {
          item.isPending = !(commonValidations && customURL);
          if (!customURL?.length) {
            item.messages.length += 1;
            item.messages.adFormat = 'Ad format custom url is incomplete';
          }
        }

        if (
          !(
            creativeSkus?.length === 3 &&
            brandInfo?.brandName?.length &&
            brandInfo?.headline?.length &&
            selectedAsset
          )
        ) {
          item.messages.length += 1;
          item.messages.creative = 'Creative is incomplete';
        }
      } else if (adFormat === 'storeSpotlight') {
        const commonValidations =
          homePage &&
          targets?.length &&
          selectedPages?.length === 3 &&
          brandInfo?.brandName &&
          selectedAsset;
        item.isPending = !commonValidations;
        if (
          !(
            selectedPages?.length === 3 &&
            brandInfo?.brandName?.length &&
            brandInfo?.headline?.length &&
            selectedAsset
          )
        ) {
          item.messages.length += 1;
          item.messages.creative = 'Creative is incomplete';
        }
      } else if (adFormat === 'video') {
        const commonValidations =
          targets?.length && creativeSkus?.length === 1 && selectedAsset;
        item.isPending = !commonValidations;
        if (!(creativeSkus?.length === 1 && selectedAsset)) {
          item.messages.length += 1;
          item.messages.creative = 'Creative is incomplete';
        }
        if (skus?.length !== 1) {
          item.messages.length += 1;
          item.messages.skus = 'Select 1 SKU';
        }
      }
      if (!item.targets?.length) {
        item.messages.length += 1;
        item.messages.targets = 'Add targets to this campaign';
      }
    },

    async setSuggestedCamapignList() {
      if (this.allStepsData?.suggestedCampaigns) {
        this.campaignList = this.allStepsData?.suggestedCampaigns;
        this.updatePendingActions();
        return;
      }
      this.campaignCreationLoader = true;
      const goalConfig = this.allStepsData?.goalConfig;
      const listItems = goalConfig?.metadata?.campaigns || [];
      const { profile, portfolio, budget, skus, singleSku } = this.allStepsData;
      let campaignListItems = [];
      for (const item of listItems) {
        if (singleSku) {
          campaignListItems = [
            ...campaignListItems,
            ...(await this.setSingleSkuCampaigns(
              item,
              profile,
              portfolio,
              budget,
              goalConfig,
              skus
            ))
          ];
        } else {
          campaignListItems.push(
            await this.setListItems(
              item,
              profile,
              portfolio,
              budget,
              goalConfig,
              skus
            )
          );
        }
      }
      this.campaignList = campaignListItems;
      this.campaignCreationLoader = false;
      this.updatePendingActions();
    },

    async setListItems(
      item,
      profile,
      portfolio,
      budget,
      goalConfig,
      skus,
      isSingleSku
    ) {
      if (!item?.targets?.length && item?.campaignType !== 'sponsoredBrands') {
        const response = await this.fetchTargets(
          item.suggestedCampaignId,
          skus
        );
        item.targets = transformer.mergeResultDimension(response.data, true);
        item?.targets?.forEach((target) => {
          // default target bids value to 1 if no value is present.
          const defaultValue = 1;
          if (item?.targetingType === 'product') {
            if (!target?.new_bid && target?.suggested_bid) {
              target.new_bid = cloneDeep(target.suggested_bid);
            } else {
              target.new_bid = defaultValue;
            }
            if (target?.competitor_asin) {
              target.entity_ui_label = `ASIN : ${target.competitor_asin}`;
            }
          } else {
            if (target?.exact_bid) {
              target.suggested_bid = cloneDeep(target.exact_bid);
            } else {
              target.exact_bid = defaultValue;
            }
            target.match_type = 'Exact';
          }
        });
      }
      item.budget = this.allStepsData?.budget || 100;
      item.suggestedCampaignName = this.formSuggestedCampaignName(
        item,
        skus,
        goalConfig,
        isSingleSku
      );
      item.isPending = false;
      item.status = 'READY_TO_LAUNCH';
      item.selected = false;
      item.startDate = moment().format('YYYYMMDD');
      item.endDate = null;
      item.profile = profile;
      item.portfolio = portfolio;
      item.budget = budget;
      item.skus = skus;
      return cloneDeep(item);
    },

    async setSingleSkuCampaigns(
      item,
      profile,
      portfolio,
      budget,
      goalConfig,
      skus
    ) {
      const listItems = [];
      for (const sku of skus) {
        listItems.push(
          await this.setListItems(
            item,
            profile,
            portfolio,
            budget,
            goalConfig,
            [sku],
            sku.asin
          )
        );
      }
      return listItems;
    },
    formSuggestedCampaignName(config, skus, goalConfig, isSingleSku) {
      const campaignTypeMap = {
        sponsoredProducts: 'SP',
        sponsoredBrands: 'SB'
      };
      const brandCount = {};
      const brand = {};
      for (const item of skus) {
        if (item?.brand) {
          if (brandCount[item?.brand]) {
            brandCount[item.brand]++;
          } else if (!brandCount[item?.brand]) {
            brandCount[item.brand] = 1;
          }
        }
      }
      for (const key in brandCount) {
        if (!brand?.name && !brand?.value) {
          brand.name = key;
          brand.value = brandCount[key];
        }
        if (brand.value > brandCount[key]) {
          brand.name = key;
          brand.value = brandCount[key];
        }
      }
      const singleSkuName = isSingleSku ? ` ${isSingleSku} |` : '';
      return `${campaignTypeMap[config.campaignType]} | ${
        config.campaignTargetingType
      } |${singleSkuName} ${brand.name} | ${
        config.targetingType
      } | Exact | ${this.campaignDate()}`;
    },
    nextStep() {
      this.$emit('nextStep', {});
    },
    prevStep() {
      this.$emit('prevStep', {});
    },
    campaignDate() {
      const date = moment.utc().format('YYYY-MM-DD HH:mm:ss');
      const stillUtc = moment.utc(date).toDate();
      return moment(stillUtc).local().format('MMMM Do YY, HH:mm:ss');
    },
    createOwnCampaign() {
      const suggestedCampaign = {
        skus: [],
        targets: [],
        own: true,
        negativeTargets: [],
        biddingStrategy: 'legacyForSales',
        startDate: moment().format('YYYYMMDD'),
        budget: this.allStepsData?.budget,
        profile: this.allStepsData?.profile,
        portfolio: this.allStepsData.portfolio,
        targetingType: 'keyword', // defaultRadioButtonSelection
        suggestedCampaignId: Math.floor(Math.random() * 90000) + 10000,
        suggestedCampaignName: `Your campaign | ${this.campaignDate()}`,
        campaignType: 'createOwnCampaignSP',
        adgroupBid: 1,
        spTargetingType: 'manual' // manual / auto : used in campaignType component
      };
      this.copySuggestedGoalForOwnCampaing();
      const allStepsData = { ...this.allStepsData };
      allStepsData.suggestedCampaigns.push(suggestedCampaign);
      this.$store.dispatch(this.selectedValues.action, { ...allStepsData });
      const query = {
        key: `${suggestedCampaign.suggestedCampaignId}-${
          this.allStepsData?.suggestedCampaigns?.length - 1
        }`,
        own: true
      };
      if (this.$route?.query?.manual) {
        query.manual = true;
      }
      this.$router.push({
        name: 'edit_suggested_campaign',
        query
      });
    },
    handleTabClicked(selectedTab) {
      this.selectedTab = selectedTab;
    },
    copySuggestedGoalForOwnCampaing() {
      const allStepsData = { ...this.allStepsData };
      const suggestedGoalConfig = cloneDeep(allStepsData.goalConfig);
      const suggestedGoals = cloneDeep(allStepsData.allGoals);
      this.$store.dispatch(this.selectedValues.action, {
        ...allStepsData,
        suggestedGoalConfig,
        suggestedGoals
      });
    },
    handleRemoveCampaign(item) {
      const { index } = item;
      const allStepsData = { ...this.allStepsData };
      allStepsData.suggestedCampaigns.splice(index, 1);
      this.$store.dispatch(this.selectedValues.action, { ...allStepsData });
      this.setSuggestedCamapignList();
      if (
        this.selectedTab !== 'Campaigns ready to launch' &&
        this.tablsList?.[1]
      ) {
        this.selectedTab = this.tablsList[1];
      }
      this.getBudgetImpact();
    },
    handleEditCampaign(item) {
      const { suggestedCampaignId, index, own } = item;
      if (own) {
        this.copySuggestedGoalForOwnCampaing();
      }
      const query = {
        key: `${suggestedCampaignId}-${index}`,
        own: own || false,
        edit: true
      };
      if (this.$route?.query?.manual) {
        query.manual = true;
      }
      this.$router.push({
        name: 'edit_suggested_campaign',
        query
      });
    }
  }
};
</script>

<style lang="css" scoped>
.fill-absolute-height-dummy-div {
  height: 230px;
}

.loader-z-index {
  z-index: 299 !important;
}

.float-campaign-summary {
  width: calc(90% - 100px);
  z-index: 298;
}

.rb-button--hollow {
  border-color: #007cf6;
  color: #007cf6;
  padding-left: 32px;
  padding-right: 32px;
}

.rb-button--hollow:hover {
  border-color: #007cf6;
  color: #007cf6;
}
</style>
