<template>
  <div class="u-display-inline-flex u-flex-align-items-center">
    <div v-if="showBookmark">
      <Bookmark v-if="isBookmarkRequireTitle" />
      <Bookmark
        v-if="!isBookmarkRequireTitle"
        data-cy="bookmarkIcon"
        :title="title"
        :path="path"
        :wrapper-class="wrapperClass"
      />
    </div>
    <h2
      class="u-font-size-2 u-color-grey-base u-font-weight-bold"
      data-cy="pageTitle"
    >
      {{ title }}
    </h2>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  author: 'Rajan V',
  props: {
    title: {
      type: String,
      default: ''
    },
    isBookmarkRequireTitle: {
      type: Boolean,
      default: false
    },
    showBookmark: {
      type: Boolean,
      default: true
    },
    path: {
      type: String,
      default: ''
    },
    wrapperClass: {
      type: String,
      default: ''
    }
  }
};
</script>
