import HttpService from '@/utils/services/http-service';
import dictionary from '@/pages/entity_details/configs/amazon/dictionary.js';
import { leftCustomBidValues } from '@/components/ams/campaign_creation/edit_configs/amazon/common/bulkCustomBid.js';

const sbKeywordBidFailure = {
  message: 'Could not fetch suggested keyword bids',
  duration: 6000,
  actionText: ''
};

export const keywordAdditionSuccessObject = {
  message: 'Keyword addition was successful',
  duration: 6000,
  actionText: ''
};
export const fewKeywordAdditionFailureObject = {
  message: 'Addition of a few keywords was unsuccessful',
  duration: 6000,
  actionText: ''
};

export const fewKeywordAdditionFailureObjectWithCount = (count) => {
  return {
    message: `${count} keywords already exist in your campaign and were not added. However, the remaining keywords were added successfully`,
    duration: 6000,
    actionText: ''
  };
};

export const keywordAdditionFailureObject = {
  message: 'Keyword addition was unsuccessful',
  duration: 6000,
  actionText: ''
};

export const keywordAdditionFailureObjectDuplicate = {
  message:
    'The keywords you tried to add are already present in your campaign and were not added.',
  duration: 6000,
  actionText: ''
};

const searchTermAdditionSuccessObject = {
  message: 'Search term addition was successful',
  duration: 6000,
  actionText: ''
};
const fewSearchTermAdditionFailureObject = {
  message: 'Addition of few search terms was unsuccessful',
  duration: 6000,
  actionText: ''
};
const searchTermAdditionFailureObject = {
  message: 'Search term addition was unsuccessful',
  duration: 6000,
  actionText: ''
};

const archiveKeywordSuccessObject = {
  message: 'Archiving keyword was successful',
  duration: 6000,
  actionText: ''
};
const fewArchiveKeywordFailureObject = {
  message: 'Archiving of a few keywords was successful',
  duration: 6000,
  actionText: ''
};
const archiveKeywordFailureObject = {
  message: 'Archiving keyword was unsuccessful',
  duration: 6000,
  actionText: ''
};

const combinedMessageObjectForKeywords = {
  allSuccessObject: keywordAdditionSuccessObject,
  someFailedObject: fewKeywordAdditionFailureObject,
  allFailedObject: keywordAdditionFailureObject
};

const combinedMessageObjectForSearchTerms = {
  allSuccessObject: searchTermAdditionSuccessObject,
  someFailedObject: fewSearchTermAdditionFailureObject,
  allFailedObject: searchTermAdditionFailureObject
};

const combinedMessageObjectForArchiveKeywords = {
  allSuccessObject: archiveKeywordSuccessObject,
  someFailedObject: fewArchiveKeywordFailureObject,
  allFailedObject: archiveKeywordFailureObject
};

function countDuplicateFailedKeywordAdditions(response) {
  let failingKeywords = 0;
  let duplicateCount = 0;
  response.forEach((kw) => {
    if (
      kw.actionStatus === 'error' &&
      kw.handlerResponse[0].code === 'FAILED'
    ) {
      failingKeywords++;
      const msg = kw.handlerResponse[0].description;
      const isErrorMsgOfDuplicateKeyword =
        msg.includes('already exists!') || msg.includes('duplicate');
      isErrorMsgOfDuplicateKeyword && duplicateCount++;
    }
  });
  const allKeywordsDuplicate = duplicateCount === failingKeywords;
  return { duplicateCount, allKeywordsDuplicate };
}

export function getSnackbarMessageObjectForKeywordAddition(
  arrayToTraverse,
  { allSuccessObject, someFailedObject, allFailedObject }
) {
  const didSomeFail = arrayToTraverse.some(
    (item) => item.actionStatus === 'error'
  );
  const didEveryFail = arrayToTraverse.every(
    (item) => item.actionStatus === 'error'
  );
  if (didSomeFail) {
    if (didEveryFail) {
      return allFailedObject;
    }
    return someFailedObject;
  }
  return allSuccessObject;
}

function handleResponseDataSuccess(
  context,
  response,
  messageObjectForAllScenarios,
  isErrorCountShown = false
) {
  const arrayToTraverse = response?.data?.response;
  let errorMsg = messageObjectForAllScenarios;
  if (isErrorCountShown) {
    const { duplicateCount, allKeywordsDuplicate } =
      countDuplicateFailedKeywordAdditions(response?.data?.response);
    errorMsg = {
      allSuccessObject: keywordAdditionSuccessObject,
      someFailedObject:
        duplicateCount > 0
          ? fewKeywordAdditionFailureObjectWithCount(duplicateCount)
          : fewKeywordAdditionFailureObject,
      allFailedObject: allKeywordsDuplicate
        ? keywordAdditionFailureObjectDuplicate
        : keywordAdditionFailureObject
    };
  }
  let messageObject = getSnackbarMessageObjectForKeywordAddition(
    arrayToTraverse,
    errorMsg
  );
  context.$snackbar.open(messageObject);
}

export const generateMatchTypeRows = (row, matchType, showKeyword) => {
  const tempRow = JSON.parse(JSON.stringify(row));
  const tempRecommendedKeyword = tempRow.recommended_keyword
    ? tempRow.recommended_keyword.toLowerCase()
    : null;
  tempRow.keyword = showKeyword ? tempRow.recommended_keyword : ' ';
  tempRow.key = tempRecommendedKeyword + '&' + matchType;
  tempRow.match_type = matchType;
  if (matchType === 'exact') {
    tempRow.bid = tempRow.exact_bid || '-';
    tempRow.newBid = tempRow.exact_bid || null;
  }
  if (matchType === 'broad') {
    tempRow.bid = tempRow.broad_bid || '-';
    tempRow.newBid = tempRow.broad_bid || null;
  }
  if (matchType === 'phrase') {
    tempRow.bid = tempRow.phrase_bid || '-';
    tempRow.newBid = tempRow.phrase_bid || null;
  }
  if (matchType === 'negativeExact') {
    tempRow.matchTypeToShow = 'Negative Exact';
  }
  if (matchType === 'negativePhrase') {
    tempRow.matchTypeToShow = 'Negative Phrase';
  }
  return tempRow;
};

export const spAddKeywordsRecommendationComponentConfig = (
  context,
  LeftTableDefaultTab = null
) => {
  return {
    ui_component: 'customActionPanel',
    props: {
      staticHeaderText: 'Add Keywords',
      widgetsConfig: {
        config: (vueRef) => {
          const widgetConfigs = {
            applyActionID: `${context.tabMetadata.page}_${context.$route.params.subEntityType}`,
            primaryKey: 'key',
            isMultipleAllowed: false,
            default: {
              noRowsRightTable: 'Add keywords to this list',
              selections: '',
              noRowsLeftTable: 'No keywords sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Error'
            },
            left: {
              tabs: {
                Suggested: {
                  localFilters: [
                    {
                      dimensionName: 'adgroup_id',
                      dimensionValue: context.entityId
                    }
                  ],
                  columnDefs: dictionary.add_keywords_sp.left.SUGGESTED(vueRef),
                  search: {
                    enableSearch: false
                  },
                  leftCustomBidValues,
                  showLeftCustomBid: true,
                  showFilterCheckBox: true,
                  filterCheckBoxSource: {
                    header: 'Match type:',
                    defaultSelected: ['Broad', 'Phrase', 'Exact'],
                    items: ['Broad', 'Phrase', 'Exact']
                  }
                },
                'Enter List': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'keyword',
                      buttonLabel: 'Add keywords'
                    }
                  },
                  showFilterCheckBox: true,
                  showLeftCustomBid: true,
                  leftCustomBidValues: [
                    {
                      title: 'Custom Bid',
                      value: 'custom_bid',
                      input: 'currency'
                    }
                  ],
                  filterCheckBoxSource: {
                    header: 'Match type:',
                    items: ['Broad', 'Phrase', 'Exact']
                  }
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                action: 'fetchTableDataExecuteApi',
                updateRows: (vueRef) => {
                  const newRows = [];
                  let fliterBroad = false;
                  let filterPhrase = false;
                  let filterExact = false;
                  const checkBoxValues = vueRef.leftFilterCheckBoxArray
                    .toString()
                    .toLowerCase();
                  fliterBroad = checkBoxValues.includes('broad');
                  filterPhrase = checkBoxValues.includes('phrase');
                  filterExact = checkBoxValues.includes('exact');
                  if (
                    (!filterPhrase && !filterExact && !fliterBroad) ||
                    (filterPhrase && filterExact && fliterBroad)
                  ) {
                    filterPhrase = true;
                    filterExact = true;
                    fliterBroad = true;
                  }
                  vueRef.leftTableData.rows.forEach((row) => {
                    if (fliterBroad) {
                      const broad = generateMatchTypeRows(
                        row,
                        'broad',
                        fliterBroad
                      );
                      newRows.push(broad);
                    }
                    if (filterPhrase) {
                      const phrase = generateMatchTypeRows(
                        row,
                        'phrase',
                        !fliterBroad
                      );
                      newRows.push(phrase);
                    }
                    if (filterExact) {
                      const exact = generateMatchTypeRows(
                        row,
                        'exact',
                        !fliterBroad && !filterPhrase && !fliterBroad
                      );
                      newRows.push(exact);
                    }
                  });
                  vueRef.leftTableRows = newRows;
                },
                localFilters: [
                  {
                    dimensionName: 'adgroup_id',
                    dimensionValue: context.entityId
                  }
                ],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                useExistingWhereClause: true,
                APIConfig: {
                  cubeName: 'ams_cm_keyword_recommendations_for_skus',
                  getLatestAvailableInsteadOfRollup: false,
                  timeseriesEnabled: false,
                  pvpenabled: false,
                  yoyenabled: false,
                  measuresList: [
                    'recommended_keyword',
                    'keyword_type',
                    'localized_ear',
                    'broad_bid',
                    'exact_bid',
                    'phrase_bid'
                  ],
                  groupByDimensionsList: ['recommended_keyword'],
                  orderByList: [
                    {
                      dimension: 'localized_ear',
                      direction: 'DESC'
                    }
                  ],
                  limit: 10,
                  page: 1,
                  where: {
                    dimensionNameValueList: [
                      {
                        dimensionName: 'adgroup_id',
                        dimensionValue: context.$route.params.subEntityId,
                        operator: 'LIKE'
                      }
                    ]
                  }
                },
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs: dictionary.add_keywords_sp.left.SUGGESTED(vueRef),
                rowClassRules: {
                  ' hideBorderAddKeywordsToCampaigns': function (params) {
                    if (params.data.keyword === ' ') {
                      return true;
                    }
                    return false;
                  }
                }
              },
              headerText: 'Keywords'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: true,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 10,
                  page: 1
                },
                columnDefs: dictionary.add_keywords_sp.right(vueRef)
              },
              headerText: () =>
                `Added Keywords (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {
              const adgroupId = context.entityId;
              const campaignId = context.parentEntityId;
              const campaignType = context.entityConfig.entityType;
              return new Promise((resolve, reject) => {
                const payload = rows.map((row) => ({
                  actionType: 'addKeywordToCampaigns',
                  actionSource: {
                    pageUrl: window.location.href
                  },
                  widget: 'campaign',
                  primaryKey: campaignId,
                  actionPayload: {
                    campaignId,
                    campaignType,
                    adgroup_id: adgroupId,
                    new_keyword_text: row.recommended_keyword,
                    new_match_type: row.match_type,
                    new_bid: row.newBid
                  },
                  viewPayload: {
                    campaignId,
                    campaignType,
                    adgroupId,
                    adgroupName: context.getAdGroupInfo?.data?.name || '', // update this to actual ad group name
                    keywordText: row.recommended_keyword,
                    matchType: row.match_type,
                    newBid: row.newBid
                  }
                }));
                HttpService.post('UPDATE_AMS_ACTIONS_MANAGER', payload)
                  .then((response) => {
                    if (response?.data?.success) {
                      handleResponseDataSuccess(
                        context,
                        response,
                        combinedMessageObjectForKeywords,
                        true
                      );
                    } else {
                      context.$snackbar.open(keywordAdditionFailureObject);
                    }
                    vueRef.$emit('afterAPIActionToCall');
                    resolve(true);
                  })
                  .catch((err) => {
                    context.$snackbar.open(keywordAdditionFailureObject);
                    reject(err);
                  });
              });
            },
            applyButtonDisabled() {
              const arrayToTraverse = vueRef.computedRightTableRows;
              for (const i in arrayToTraverse) {
                const newBid = arrayToTraverse[i]?.newBid;
                if (!newBid) {
                  return true;
                }
              }
              return !vueRef.rightTableRows.length;
            }
          };
          if (LeftTableDefaultTab) {
            widgetConfigs.left.defaultTab = LeftTableDefaultTab;
          }
          return widgetConfigs;
        }
      }
    },
    events: {
      afterAPIActionToCall(payload) {
        context.showAddCustomKeywordPanel = false;
        context.reloadComponent(true);
        context.showRecCustomComponent = false;
        context.recommendationFilter('');
        context.handleRecommendation({});
      },
      closePanelMethod() {
        if (LeftTableDefaultTab) {
          context.showAddCustomKeywordPanel = false;
          return;
        }
        context.showRecCustomComponent = false;
        context.handleRecommendation({});
      }
    }
  };
};

export const addEfficientSearchTermsAsKeywordsRecommendationComponentConfig = (
  context
) => {
  const isCampaignLevel = !(context.entityId && context.parentEntityId);
  const adgroupId = isCampaignLevel
    ? context?.tabMetadata?.adgroup_id
    : context.entityId;
  const campaignId = isCampaignLevel
    ? context.entityId
    : context.parentEntityId;
  const adgroupName = context.getAdGroupInfo?.data?.name || ''; // update this to actual ad group name
  return {
    ui_component: 'customActionPanel',
    props: {
      staticHeaderText: 'Add Search Terms',
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'key',
            isMultipleAllowed: false,
            default: {
              noRowsRightTable: 'Add search terms to this list',
              selections: '',
              noRowsLeftTable: 'No search terms sources found',
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Error'
            },
            left: {
              tabs: {
                Suggested: {
                  localFilters: [
                    {
                      dimensionName: isCampaignLevel
                        ? 'campaign_id'
                        : 'adgroup_id',
                      dimensionValue: context.entityId
                    }
                  ],
                  columnDefs:
                    dictionary.add_efficient_search_terms_sp_and_sb.left.SUGGESTED(
                      vueRef
                    ),
                  search: {
                    enableSearch: false
                  }
                },
                'Enter List': {
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'keyword',
                      buttonLabel: 'Add keywords'
                    }
                  },
                  showFilterCheckBox: true,
                  filterCheckBoxSource: {
                    header: 'Match type:',
                    items: ['Exact']
                  }
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                action: 'fetchTableDataExecuteApi',
                updateRows: (vueRef) => {
                  const newRows = [];
                  vueRef.leftTableData.rows.forEach((row) => {
                    const exact = generateMatchTypeRows(row, 'exact', true);
                    newRows.push(exact);
                  });
                  vueRef.leftTableRows = newRows;
                },
                localFilters: [
                  {
                    dimensionName: isCampaignLevel
                      ? 'campaign_id'
                      : 'adgroup_id',
                    dimensionValue: context.entityId
                  }
                ],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                APIConfig: {
                  cubeName:
                    'ams_cm_keyword_recommendations_efficient_searchterms',
                  getLatestAvailableInsteadOfRollup: false,
                  timeseriesEnabled: false,
                  pvpenabled: false,
                  yoyenabled: false,
                  measuresList: [
                    'recommended_keyword',
                    'exact_bid',
                    'attributedorders14d',
                    'phrase_bid',
                    'broad_bid'
                  ],
                  groupByDimensionsList: ['recommended_keyword'],
                  orderByList: [
                    {
                      dimension: 'attributedorders14d',
                      direction: 'DESC'
                    }
                  ],
                  limit: 10,
                  page: 1
                },
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs:
                  dictionary.add_efficient_search_terms_sp_and_sb.left.SUGGESTED(
                    vueRef
                  )
              },
              headerText: 'Search Terms'
            },
            right: {
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: true,
              body: {
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                APIConfig: {
                  limit: 10,
                  page: 1
                },
                columnDefs:
                  dictionary.add_efficient_search_terms_sp_and_sb.right(vueRef)
              },
              headerText: () =>
                `Added Search Terms (${vueRef.rightTableRows.length})`
            },
            apply(rows = []) {
              const campaignType = context.entityConfig.entityType;
              return new Promise((resolve, reject) => {
                const payload = rows.map((row) => ({
                  actionType: 'addKeywordToCampaigns',
                  actionSource: {
                    pageUrl: window.location.href
                  },
                  widget: 'campaign',
                  primaryKey: campaignId,
                  actionPayload: {
                    campaignId,
                    campaignType,
                    adgroup_id: adgroupId,
                    new_keyword_text: row.recommended_keyword,
                    new_match_type: row.match_type,
                    new_bid: row.newBid
                  },
                  viewPayload: {
                    campaignId,
                    campaignType,
                    adgroupId,
                    adgroupName,
                    keywordText: row.recommended_keyword,
                    matchType: row.match_type,
                    newBid: row.newBid
                  }
                }));
                HttpService.post('UPDATE_AMS_ACTIONS_MANAGER', payload)
                  .then((response) => {
                    if (response?.data?.success) {
                      handleResponseDataSuccess(
                        context,
                        response,
                        combinedMessageObjectForSearchTerms
                      );
                    } else {
                      context.$snackbar.open(searchTermAdditionFailureObject);
                    }
                    vueRef.$emit('afterAPIActionToCall');
                    resolve(true);
                  })
                  .catch((err) => {
                    context.$snackbar.open(searchTermAdditionFailureObject);
                    reject(err);
                  });
              });
            },
            applyButtonDisabled() {
              const arrayToTraverse = vueRef.computedRightTableRows;
              for (const i in arrayToTraverse) {
                const newBid = arrayToTraverse[i]?.newBid;
                if (!newBid) {
                  return true;
                }
              }
              return !vueRef.rightTableRows.length;
            }
          };
        }
      }
    },
    events: {
      afterAPIActionToCall(payload) {
        context.showRecCustomComponent = false;
        context.reloadComponent(true);
        context.recommendationFilter('');
        context.handleRecommendation({});
        context.reloadComponent(true);
      },
      closePanelMethod() {
        context.showRecCustomComponent = false;
        context.handleRecommendation({});
      }
    }
  };
};

export const addImportantKeywordsForBrandRecommendationComponentConfig = (
  context,
  LeftTableDefaultTab = null
) => ({
  ui_component: 'customActionPanel',
  props: {
    staticHeaderText: 'Add Keywords',
    widgetsConfig: {
      config: (vueRef) => {
        const widgetConfigs = {
          applyActionID: `${context.tabMetadata.page}`,
          primaryKey: 'key',
          isMultipleAllowed: false,
          default: {
            noRowsRightTable: 'Add keywords to this list',
            selections: '',
            noRowsLeftTable: 'No keywords sources found',
            leftBtnLabel: 'Apply',
            rightBtnLabel: 'Cancel',
            warningMessage: 'Error'
          },
          left: {
            tabs: {
              Suggested: {
                localFilters: [],
                columnDefs: dictionary.add_keywords_sp.left.SUGGESTED(vueRef),
                search: {
                  enableSearch: false
                },
                leftCustomBidValues,
                showLeftCustomBid: true,
                showFilterCheckBox: true,
                filterCheckBoxSource: {
                  header: 'Match type:',
                  defaultSelected: ['Broad', 'Phrase', 'Exact'],
                  items: ['Broad', 'Phrase', 'Exact']
                }
              },
              'Enter List': {
                ui_component: {
                  name: 'enterListPanel',
                  props: {
                    actionName: 'keyword',
                    buttonLabel: 'Add keywords'
                  }
                },
                showLeftCustomBid: true,
                leftCustomBidValues: [
                  {
                    title: 'Custom Bid',
                    value: 'custom_bid',
                    input: 'currency'
                  }
                ],
                showFilterCheckBox: true,
                filterCheckBoxSource: {
                  header: 'Match type:',
                  items: ['Broad', 'Phrase', 'Exact']
                }
              }
            },
            isColumnDefAutoConfigure: true,
            table: 'left',
            meta: {
              type: 'table',
              action: 'fetchTableDataExecuteApi',
              updateRows: (vueRef) => {
                if (vueRef?.activeTab === 'Enter List') return;
                const newRows = [];
                let fliterBroad = false;
                let filterPhrase = false;
                let filterExact = false;
                const checkBoxValues = vueRef.leftFilterCheckBoxArray
                  .toString()
                  .toLowerCase();
                fliterBroad = checkBoxValues.includes('broad');
                filterPhrase = checkBoxValues.includes('phrase');
                filterExact = checkBoxValues.includes('exact');
                if (
                  (!filterPhrase && !filterExact && !fliterBroad) ||
                  (filterPhrase && filterExact && fliterBroad)
                ) {
                  filterPhrase = true;
                  filterExact = true;
                  fliterBroad = true;
                }
                vueRef.leftTableData.rows.forEach((row) => {
                  if (fliterBroad) {
                    const broad = generateMatchTypeRows(
                      row,
                      'broad',
                      fliterBroad
                    );
                    newRows.push(broad);
                  }
                  if (filterPhrase) {
                    const phrase = generateMatchTypeRows(
                      row,
                      'phrase',
                      !fliterBroad
                    );
                    newRows.push(phrase);
                  }
                  if (filterExact) {
                    const exact = generateMatchTypeRows(
                      row,
                      'exact',
                      !fliterBroad && !filterPhrase && !fliterBroad
                    );
                    newRows.push(exact);
                  }
                });
                if (!newRows.length) {
                  // skip calling sugg bids api if sugg keywords do not exist
                  vueRef.leftTableRows = [];
                  return;
                }

                const sbKeywordBidRequestPayload = {
                  keywords: [],
                  campaignType: 'sb'
                };

                for (const row of newRows) {
                  sbKeywordBidRequestPayload.keywords.push({
                    keywordText: row?.recommended_keyword,
                    matchType: row?.match_type
                  });
                }

                vueRef.leftTableLoad = true;
                let keywordBids = [];
                HttpService.post(
                  'GET_RECOMMENDATIONS_BIDS_SB_AMS_ACTION_MANAGER',
                  sbKeywordBidRequestPayload
                )
                  .then((response) => {
                    keywordBids = response?.data?.keywordRecommendations || [];
                  })
                  .catch(() => {
                    vueRef.$snackbar.open(sbKeywordBidFailure);
                  })
                  .finally(() => {
                    for (const keywordItem of keywordBids) {
                      for (const row of newRows) {
                        const keywordBidExists =
                          keywordItem?.keyword === row?.recommended_keyword &&
                          keywordItem?.matchType === row?.match_type;
                        if (keywordBidExists) {
                          const { suggestedBid } = keywordItem;
                          row.bid = suggestedBid;
                          row.newBid = suggestedBid;
                        }
                      }
                    }
                    vueRef.leftTableRows = newRows;
                    vueRef.leftTableLoad = false;
                  });
              },
              localFilters: [
                {
                  dimensionName: 'campaign_id',
                  dimensionValue: context.parentEntityId
                }
              ],
              paginationAction: 'fetchTableDataExecuteApi'
            },
            footer: {
              show: false
            },
            body: {
              useExistingWhereClause: true,
              APIConfig: {
                cubeName: 'ams_cm_keyword_recommendations_for_brands',
                getLatestAvailableInsteadOfRollup: false,
                timeseriesEnabled: false,
                pvpenabled: false,
                yoyenabled: false,
                measuresList: [
                  'localized_ear',
                  'median_sfr_past_week',
                  'broad_bid',
                  'keyword_type',
                  'exact_bid',
                  'phrase_bid'
                ],
                groupByDimensionsList: ['recommended_keyword'],
                where: {
                  dimensionNameValueList: [
                    {
                      dimensionName: 'campaign_id',
                      dimensionValue: context.$route.params.parentEntityId,
                      operator: 'LIKE'
                    }
                  ]
                },
                orderByList: [
                  {
                    dimension: 'localized_ear',
                    direction: 'DESC'
                  }
                ],
                limit: 10,
                page: 1
              },
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              columnDefs: dictionary.add_keywords_sp.left.SUGGESTED(vueRef),
              rowClassRules: {
                ' hideBorderAddKeywordsToCampaigns': function (params) {
                  if (params.data.keyword === ' ') {
                    return true;
                  }
                  return false;
                }
              }
            },
            headerText: 'Keywords'
          },
          right: {
            table: 'right',
            searchKey: 'search',
            footer: {
              show: false
            },
            isColumnDefAutoConfigure: true,
            body: {
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              APIConfig: {
                limit: 10,
                page: 1
              },
              columnDefs: dictionary.add_keywords_sp.right(vueRef)
            },
            headerText: () => `Added Keywords (${vueRef.rightTableRows.length})`
          },
          apply(rows = []) {
            const adgroupId = context?.entityId;
            const campaignId = context?.parentEntityId;
            const campaignType = context.entityConfig.entityType;
            return new Promise((resolve, reject) => {
              const payload = rows.map((row) => ({
                actionType: 'addKeywordToCampaigns',
                actionSource: {
                  pageUrl: window.location.href
                },
                widget: 'campaign',
                primaryKey: campaignId,
                actionPayload: {
                  campaignId,
                  campaignType,
                  adgroup_id: adgroupId,
                  new_keyword_text: row.recommended_keyword,
                  new_match_type: row.match_type,
                  new_bid: row.newBid
                },
                viewPayload: {
                  campaignId,
                  campaignType,
                  adgroupId,
                  adgroupName: context.getAdGroupInfo?.data?.name || '', // update this to actual ad group name
                  keywordText: row.recommended_keyword,
                  matchType: row.match_type,
                  newBid: row.newBid
                }
              }));
              HttpService.post('UPDATE_AMS_ACTIONS_MANAGER', payload)
                .then((response) => {
                  if (response?.data?.success) {
                    handleResponseDataSuccess(
                      context,
                      response,
                      combinedMessageObjectForKeywords
                    );
                  } else {
                    context.$snackbar.open(keywordAdditionFailureObject);
                  }
                  vueRef.$emit('afterAPIActionToCall');
                  resolve(true);
                })
                .catch((err) => {
                  context.$snackbar.open(keywordAdditionFailureObject);
                  reject(err);
                });
            });
          },
          applyButtonDisabled() {
            const arrayToTraverse = vueRef.computedRightTableRows;
            for (const i in arrayToTraverse) {
              const newBid = arrayToTraverse[i]?.newBid;
              if (!newBid) {
                return true;
              }
            }
            return !vueRef.rightTableRows.length;
          }
        };
        if (LeftTableDefaultTab) {
          widgetConfigs.left.defaultTab = LeftTableDefaultTab;
        }
        return widgetConfigs;
      }
    }
  },
  events: {
    afterAPIActionToCall(payload) {
      context.showAddCustomKeywordPanel = false;
      context.reloadComponent(true);
      context.showRecCustomComponent = false;
      context.recommendationFilter('');
      context.handleRecommendation({});
    },
    closePanelMethod() {
      if (LeftTableDefaultTab) {
        context.showAddCustomKeywordPanel = false;
        return;
      }
      context.showRecCustomComponent = false;
      context.handleRecommendation({});
    }
  }
});

export const alignCampaignToBestPracticesRecommendationComponentConfig = (
  context
) => {
  const isCampaignLevel = !(context.entityId && context.parentEntityId);
  const adgroupId = isCampaignLevel
    ? context?.tabMetadata?.adgroup_id
    : context.entityId;
  const campaignId = isCampaignLevel
    ? context.entityId
    : context.parentEntityId;
  const adgroupName = context.getAdGroupInfo?.data?.name || ''; // update this to actual ad group name
  return {
    ui_component: 'customActionPanel',
    props: {
      staticHeaderText: 'Archive Keywords',
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'key',
            isMultipleAllowed: false,
            default: {
              noRowsRightTable: 'Add keywords to this list',
              selections: '',
              noRowsLeftTable: 'No keywords sources found',
              leftBtnLabel: 'Archive',
              rightBtnLabel: 'Cancel',
              warningMessage: 'Error'
            },
            left: {
              tabs: {
                Suggested: {
                  localFilters: [
                    {
                      dimensionName: isCampaignLevel
                        ? 'campaign_id'
                        : 'adgroup_id',
                      dimensionValue: context.entityId
                    }
                  ],
                  columnDefs:
                    dictionary.archive_keywords_sp_and_sb.left.SUGGESTED(
                      vueRef
                    ),
                  search: {
                    enableSearch: false
                  }
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                action: 'fetchTableDataExecuteApi',
                localFilters: [
                  {
                    dimensionName: isCampaignLevel
                      ? 'campaign_id'
                      : 'adgroup_id',
                    dimensionValue: context.entityId
                  }
                ],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                APIConfig: {
                  cubeName:
                    'ams_cm_keyword_recommendations_campaign_bestpractice',
                  getLatestAvailableInsteadOfRollup: false,
                  timeseriesEnabled: false,
                  pvpenabled: false,
                  yoyenabled: false,
                  measuresList: ['recommended_keyword', 'keyword_id', 'state'],
                  groupByDimensionsList: ['recommended_keyword'],
                  orderByList: [],
                  limit: 10,
                  page: 1
                },
                gridOptions: {
                  context: {
                    componentParent: vueRef
                  },
                  domLayout: 'normal'
                },
                columnDefs:
                  dictionary.archive_keywords_sp_and_sb.left.SUGGESTED(vueRef),
                rowSelection: {
                  mode: 'multiple',
                  onSelectionChanged: vueRef.handleLeftRowSelectionChange
                }
              },
              headerText: 'Keywords'
            },
            apply() {
              const rows = vueRef.leftRowSelectedRows;
              const campaignType = context.entityConfig.entityType;
              return new Promise((resolve, reject) => {
                const payload = rows.map((row) => ({
                  actionType: 'keywordMetadataChange',
                  actionSource: {
                    pageUrl: window.location.href
                  },
                  widget: 'campaign',
                  primaryKey: campaignId,
                  actionPayload: {
                    campaignId,
                    campaignType,
                    keyword_id: row?.data?.keyword_id,
                    adgroup_id: adgroupId,
                    state: 'archived'
                  },
                  viewPayload: {
                    adgroupName,
                    oldStatus: row?.data?.state,
                    newStatus: 'archived',
                    keyword_text: row?.data?.recommended_keyword,
                    adgroup_name: adgroupName
                  }
                }));
                HttpService.post('UPDATE_AMS_ACTIONS_MANAGER', payload)
                  .then((response) => {
                    if (response?.data?.success) {
                      handleResponseDataSuccess(
                        context,
                        response,
                        combinedMessageObjectForArchiveKeywords
                      );
                    } else {
                      context.$snackbar.open(archiveKeywordFailureObject);
                    }
                    vueRef.$emit('afterAPIActionToCall');
                    resolve(true);
                  })
                  .catch((err) => {
                    context.$snackbar.open(archiveKeywordFailureObject);
                    reject(err);
                  });
              });
            }
          };
        }
      }
    },
    events: {
      afterAPIActionToCall(payload) {
        context.showRecCustomComponent = false;
        context.recommendationFilter('');
        context.handleRecommendation({});
        context.reloadComponent(true);
      },
      closePanelMethod() {
        context.showRecCustomComponent = false;
        context.handleRecommendation({});
      }
    }
  };
};

export const addInEfficientSearchTermsAsNegativeTargetRecommendationComponentConfig =
  (context) => {
    const isCampaignLevel = !(context.entityId && context.parentEntityId);
    const adgroupId = isCampaignLevel
      ? context?.tabMetadata?.adgroup_id
      : context.entityId;
    const campaignId = isCampaignLevel
      ? context.entityId
      : context.parentEntityId;
    const adgroupName = context.getAdGroupInfo?.data?.name || ''; // update this to actual ad group name
    return {
      ui_component: 'customActionPanel',
      props: {
        staticHeaderText: 'Add Negative Keywords',
        widgetsConfig: {
          config: (vueRef) => {
            return {
              applyActionID: `${context.tabMetadata.page}_${context.$route.params.subEntityType}`,
              primaryKey: 'key',
              isMultipleAllowed: false,
              default: {
                noRowsRightTable: 'Add negative keywords to this list',
                selections: '',
                noRowsLeftTable: 'No negative keywords sources found',
                leftBtnLabel: 'Apply',
                rightBtnLabel: 'Cancel',
                warningMessage: 'Error'
              },
              left: {
                tabs: {
                  Suggested: {
                    localFilters: [
                      {
                        dimensionName: isCampaignLevel
                          ? 'campaign_id'
                          : 'adgroup_id',
                        dimensionValue: context.entityId
                      }
                    ],
                    columnDefs:
                      dictionary.add_negative_keywords_sp_and_sb.left.SUGGESTED(
                        vueRef
                      ),
                    search: {
                      enableSearch: false
                    },
                    showFilterCheckBox: true,
                    filterCheckBoxSource: {
                      header: 'Match type:',
                      defaultSelected: ['Negative phrase', 'Negative exact'],
                      items: ['Negative Phrase', 'Negative Exact']
                    }
                  },
                  'Enter List': {
                    ui_component: {
                      name: 'enterListPanel',
                      props: {
                        actionName: 'negative keyword',
                        buttonLabel: 'Add negative keywords'
                      }
                    },
                    showFilterCheckBox: true,
                    filterCheckBoxSource: {
                      header: 'Match type:',
                      items: ['Negative Phrase', 'Negative Exact']
                    }
                  }
                },
                isColumnDefAutoConfigure: true,
                table: 'left',
                meta: {
                  type: 'table',
                  action: 'fetchTableDataExecuteApi',
                  updateRows: (vueRef) => {
                    const newRows = [];
                    let filterPhrase = false;
                    let filterExact = false;
                    const checkBoxValues = vueRef.leftFilterCheckBoxArray
                      .toString()
                      .toLowerCase();
                    filterPhrase = checkBoxValues.includes('phrase');
                    filterExact = checkBoxValues.includes('exact');
                    if (
                      (!filterPhrase && !filterExact) ||
                      (filterPhrase && filterExact)
                    ) {
                      filterPhrase = true;
                      filterExact = true;
                    }
                    vueRef.leftTableData.rows.forEach((row) => {
                      if (filterPhrase) {
                        const phrase = generateMatchTypeRows(
                          row,
                          'negativePhrase',
                          true
                        );
                        newRows.push(phrase);
                      }
                      if (filterExact) {
                        const exact = generateMatchTypeRows(
                          row,
                          'negativeExact',
                          !filterPhrase && filterExact
                        );
                        newRows.push(exact);
                      }
                    });
                    vueRef.leftTableRows = newRows;
                  },
                  localFilters: [
                    {
                      dimensionName: isCampaignLevel
                        ? 'campaign_id'
                        : 'adgroup_id',
                      dimensionValue: context.entityId
                    }
                  ],
                  paginationAction: 'fetchTableDataExecuteApi'
                },
                footer: {
                  show: false
                },
                body: {
                  APIConfig: {
                    cubeName:
                      'ams_cm_negativekeyword_recommendations_inefficient_searchterms',
                    getLatestAvailableInsteadOfRollup: false,
                    timeseriesEnabled: false,
                    pvpenabled: false,
                    yoyenabled: false,
                    measuresList: ['recommended_keyword', 'acos14d'],
                    groupByDimensionsList: ['recommended_keyword'],
                    orderByList: [
                      {
                        dimension: 'acos14d',
                        direction: 'DESC'
                      }
                    ],
                    limit: 10,
                    page: 1
                  },
                  gridOptions: {
                    context: {
                      componentParent: vueRef
                    },
                    domLayout: 'normal'
                  },
                  columnDefs:
                    dictionary.add_negative_keywords_sp_and_sb.left.SUGGESTED(
                      vueRef
                    ),
                  rowClassRules: {
                    ' hideBorderAddKeywordsToCampaigns': function (params) {
                      if (params.data.keyword === ' ') {
                        return true;
                      }
                      return false;
                    }
                  }
                },
                headerText: 'Add Negative Keywords'
              },
              right: {
                table: 'right',
                searchKey: 'search',
                footer: {
                  show: false
                },
                isColumnDefAutoConfigure: true,
                body: {
                  gridOptions: {
                    context: {
                      componentParent: vueRef
                    },
                    domLayout: 'normal'
                  },
                  APIConfig: {
                    limit: 10,
                    page: 1
                  },
                  columnDefs:
                    dictionary.add_negative_keywords_sp_and_sb.right(vueRef)
                },
                headerText: () =>
                  `Added Negative Keywords (${vueRef.rightTableRows.length})`
              },
              apply(rows = []) {
                const campaignType = context.entityConfig.entityType;
                return new Promise((resolve, reject) => {
                  const payload = rows.map((row) => {
                    let matchType = null;
                    if (row?.match_type === 'negativeExact') {
                      matchType = 'Negative Exact';
                    }
                    if (row?.match_type === 'negativePhrase') {
                      matchType = 'Negative Phrase';
                    }
                    return {
                      actionType: 'addNegativeKeyword',
                      actionSource: {
                        pageUrl: window.location.href
                      },
                      widget: 'campaign',
                      primaryKey: campaignId,
                      actionPayload: {
                        campaignId,
                        campaignType,
                        adgroup_id: adgroupId,
                        new_keyword_text: row.recommended_keyword,
                        new_match_type: row.match_type
                      },
                      viewPayload: {
                        campaignId,
                        campaignType,
                        adgroupId,
                        adgroupName,
                        keywordText: row.recommended_keyword,
                        matchType
                      }
                    };
                  });
                  HttpService.post('UPDATE_AMS_ACTIONS_MANAGER', payload)
                    .then((response) => {
                      if (response?.data?.success) {
                        handleResponseDataSuccess(
                          context,
                          response,
                          combinedMessageObjectForKeywords
                        );
                      } else {
                        context.$snackbar.open(keywordAdditionFailureObject);
                      }
                      vueRef.$emit('afterAPIActionToCall');
                      resolve(true);
                    })
                    .catch((err) => {
                      context.$snackbar.open(keywordAdditionFailureObject);
                      reject(err);
                    });
                });
              }
            };
          }
        }
      },
      events: {
        afterAPIActionToCall(payload) {
          context.showRecCustomComponent = false;
          context.recommendationFilter('');
          context.handleRecommendation({});
          context.reloadComponent(true);
        },
        closePanelMethod() {
          context.showRecCustomComponent = false;
          context.handleRecommendation({});
        }
      }
    };
  };
