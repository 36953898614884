<template>
  <div
    ref="trigger"
    v-tippy="tippyOptions"
  >
    <div
      v-show="showFilterIcon"
      slot="trigger"
      class="icon-frame"
      @click.stop="openAddFilter"
    >
      <rb-button
        :title="'Filters'"
        :type="'icon'"
        :icon-right="'filter'"
        :size="'medium'"
        class="u-border-none u-filter-trigger-button"
      />
    </div>
    <tagsInput
      :class="customClass"
      :enable-style-api="enableStyleApi"
      :text-input="textInput"
      :get-tags-input-instance="getTagsInputInstance"
      :local-active-tags="localActiveTags"
      :buffered-tags="bufferedTags"
      :tags-aggregated-view="tagsAggregatedView"
      :tags-icons="tagsIcons"
      :delimiters="delimiters"
      :tags-aggregated-input-size="tagsAggregatedInputSize"
      :pre-input-icons="preInputIcons"
      :post-input-icons="postInputIcons"
      :tags-title-key="tagsTitleKey"
      :tag-color="tagColor"
      :is-input-disabled="isInputDisabled"
      :buffered-tag-icons="bufferedTagIcons"
      :input-placeholder="inputPlaceholder"
      :is-tag-clickable="isTagClickable"
      :aggregated-tag-title-text="aggregatedTagTitleText"
      :aggregated-buffer-tag-title-text="aggregatedBufferTagTitleText"
      :allow-multiple-tags="allowMultipleTags"
      @tagClicked="handleTagClicked"
      @openActiveTagsDropdown="handleOpenActiveTagsDropdown"
      @bufferedTagClicked="handleBufferedTagClicked"
      @openBufferedTagsDropdown="handleOpenBufferedTagsDropdown"
      @componentClick="handleComponentClick"
      @input="handleInput"
      @searchSubmit="handleSearchSubmit"
      @paste="handlePaste"
      @removeActiveTag="handleRemoveActiveTag"
      @clearBufferTags="handleClearBufferTags"
      @clearAll="handleClearAll"
      @enter="handleEnter"
    />
    <section
      v-show="showDropDown"
      :id="id"
      ref="dropdownMenu"
      class="tippy-drop-down u-border-radius-s"
    >
      <slot
        name="dropdown-contents"
        :dropdownData="dropdownData"
        :tagTitleStore="tagTitleStore"
        :handleSuggestionClickRemove="handleSuggestionClickRemove"
        :handleSuggestionClick="handleSuggestionClick"
        :localActiveTags="localActiveTags"
        :handleRemoveActiveTag="handleRemoveActiveTag"
        :textInput="textInput"
        :bufferedTags="bufferedTags"
        :bufferTagTitleStore="bufferTagTitleStore"
        :handleRemoveBufferedTag="handleRemoveBufferedTag"
      >
        <div
          :class="[
            'u-font-size-' + dropdownTextSize,
            'u-bg-color-' + dropdownBackgroundColor,
            containerClasses
          ]"
          class="u-overflow-y-auto text-overflow"
          :style="{
            'min-width': minDropdownWidth,
            'max-width': maxDropdownWidth,
            'max-height': maxDropdownHeight
          }"
        >
          <div class="">
            <div
              v-if="dropdownData.load"
              class="u-spacing-ph-m u-spacing-pv-s height"
            >
              <loader
                class="fill--parent"
                :loading="true"
                :color="'#3684bb'"
              />
            </div>
            <div
              v-else-if="dropdownData.error"
              class="u-spacing-ph-m u-spacing-pv-s u-display-flex u-flex-align-items-center u-flex-justify-content-center height"
            >
              <div
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
              >
                Something went wrong!!!
              </div>
            </div>
            <div
              v-else-if="dropdownData.noData"
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-center height"
            >
              <div
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
              >
                No data found
              </div>
            </div>
            <div v-else>
              <slot
                name="dropdown-header"
                :item="dropdownData"
                :textInput="textInput"
              />
              <slot
                name="dropdown-body"
                :item="dropdownData"
              >
                <div
                  v-for="(dropdownItem, index) in dropdownData.rows"
                  :key="index"
                  class="u-spacing-ph-m u-spacing-pv-s u-cursor-pointer u-font-weight-400 suggestion-item-tags-suggestion"
                  :style="[
                    enableStyleApi && hoverIndex === index && styleObject
                  ]"
                  @mouseenter="handleMouseEnter(index)"
                  @mouseleave="handleMouseLeave()"
                  @click.stop="handleSuggestionClick(dropdownItem)"
                >
                  <slot
                    name="item"
                    :item="dropdownItem"
                  >
                    {{ dropdownItem.title }}
                  </slot>
                </div>
              </slot>
              <slot
                name="dropdown-footer"
                :textInput="textInput"
                :handleMouseEnter="handleMouseEnter"
                :handleMouseLeave="handleMouseLeave"
                :handleSuggestionClick="handleSuggestionClick"
                :hoverIndex="hoverIndex"
                :styleObject="styleObject"
              />
            </div>
          </div>
        </div>
      </slot>
    </section>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
import tag from '@/components/widgets/tag';
import tagsInput from '@/components/widgets/tagsInput';
export default {
  components: {
    tag,
    loader,
    tagsInput
  },
  props: {
    customClass: {
      type: String,
      default: 'searchWrapper'
    },
    enableStyleApi: {
      type: Boolean,
      default: true
    },
    containerClasses: {
      type: String,
      default: 'tags-suggestion-container'
    },
    showDropDown: {
      type: Boolean,
      default: true
    },
    initialTags: {
      type: Array,
      default() {
        return [];
      }
    },
    showFilterIcon: {
      type: Boolean
    },
    aggregatedTagTitleText: {
      type: String,
      default: ''
    },
    addFilterInstance: {
      type: Object,
      default: null
    },
    aggregatedBufferTagTitleText: {
      type: String,
      default: ''
    },
    isTagClickable: {
      type: Boolean,
      default: false
    },
    bufferedTagIcons: {
      type: Array,
      default: () => []
    },
    hasTagsBuffer: {
      type: Boolean,
      default: false
    },
    isInputDisabled: {
      type: Boolean,
      default: false
    },
    preInputIcons: {
      type: Array,
      default: () => []
    },
    postInputIcons: {
      type: Array,
      default: () => []
    },
    tagsTitleKey: {
      type: String,
      default: null
    },
    hideDropdownAtSelect: {
      type: Boolean,
      default: false
    },
    maxDropdownHeight: {
      type: String,
      default: '400px'
    },
    delimiters: {
      type: Array,
      default: () => []
    },
    maxDropdownWidth: {
      type: String,
      default: 'null'
    },
    minDropdownWidth: {
      type: String,
      default: '300px'
    },
    tagsAggregatedInputSize: {
      type: Number,
      default: 50
    },
    tagsIcons: {
      type: Array,
      default: () => []
    },
    tagsAggregatedView: {
      type: Boolean,
      deafult: false
    },
    getTagsAndSuggestionsInstance: {
      type: Function,
      default() {}
    },
    openDirection: {
      type: String,
      default: 'bottom-start'
    },
    tagColor: {
      type: String,
      default: 'rgba(189, 16, 224, 0.1)'
    },
    tagFontSize: {
      type: String,
      default: '7'
    },
    tagFontColor: {
      type: String,
      default: 'grey-base'
    },
    tagCrossSize: {
      type: String,
      default: 'xx-small'
    },
    inputFontSize: {
      type: String,
      default: '5'
    },
    inputPlaceholder: {
      type: String,
      default: ''
    },
    dropdownData: {
      type: Object,
      default: () => {
        return { rows: [] };
      }
    },
    dropdownTextSize: {
      type: String,
      default: '5'
    },
    dropdownBackgroundColor: {
      type: String,
      default: 'grey-white'
    },
    dropdownHighlightTextColor: {
      type: String,
      default: null
    },
    dropdownHightlightColor: {
      type: String,
      default: 'rgba(189, 16, 224, 0.1)'
    },
    allowMultipleTags: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isTippyActive: false,
      isBulkPaste: false,
      textInput: '',
      id: null,
      showSuggestions: false,
      hoverIndex: null,
      localActiveTags: [],
      tagsInputInstance: null,
      showDuplicateAlert: true,
      tagTitleStore: {},
      bufferedTags: [],
      bufferTagTitleStore: {}
    };
  },

  computed: {
    tippyInstance() {
      return this?.$el?._tippy;
    },
    tippyOptions() {
      return {
        html: '#' + this.id,
        reactive: true,
        distance: 4,
        placement: this.openDirection,
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        duration: [0, 0],
        onShow: this.onShow,
        onHide: this.onHide,
        class: this.className,
        onShown: this.onShown
      };
    },
    styleObject() {
      return {
        'background-color': this.dropdownHightlightColor
      };
    }
  },
  watch: {
    initialTags(newValue) {
      this.localActiveTags = newValue;
    }
  },
  created() {
    this.id = 'dropdown-' + this._uid;
    // this.computedMinDropdownWidth = this.minDropdownWidth
    this.localActiveTags = this.initialTags;
    if (this.getTagsAndSuggestionsInstance) {
      this.getTagsAndSuggestionsInstance(this);
    }
  },
  mounted() {
    // if (this.minDropdownWidth === 'auto') {
    //   const inputDimensions = this.tagsInputInstance.$refs['input-field'].getBoundingClientRect()
    //   this.computedMinDropdownWidth = inputDimensions.width
    // } else {
    //   this.computedMinDropdownWidth = this.minDropdownWidth
    // }
    // this. = ;
  },
  methods: {
    handleClearAll() {
      this.showDuplicateAlert = true;
      this.handleClearBufferTags();
      this.clearAllActiveTags();
      this.resetTextInput();
      this.tippyInstance?.hide();
      this.$emit('clearAll');
    },
    pushBufferToActiveTags() {
      this.bulkAddTags(this.bufferedTags);
      this.handleClearBufferTags();
    },
    handleClearBufferTags() {
      this.showDuplicateAlert = true;
      this.bufferedTags = [];
      this.bufferTagTitleStore = {};
      // this.$set(this, 'bufferTagTitleStore', {})
    },
    openAddFilter() {
      if (this.addFilterInstance && this.addFilterInstance.open) {
        this.addFilterInstance.open();
      }
    },
    resetTextInput() {
      this.textInput = '';
      this.showDuplicateAlert = true;
    },
    handleBufferedTagClicked(data) {
      this.$emit('bufferedTagClicked', data);
    },
    handleTagClicked(data) {
      this.$emit('tagClicked', data);
    },
    textHasDelimiters(data) {
      const { length } = data;
      for (let i = 0; i < this.delimiters.length; i++) {
        if (data[length - 1] === this.delimiters[i].token) {
          return true;
        }
      }
      return false;
    },
    handleInput(data) {
      const { textInput } = data;
      if (this.hasTagsBuffer && this.textHasDelimiters(textInput)) {
        const delimiterRemovedString = textInput.slice(0, -1);
        this.handleAddActiveTag(delimiterRemovedString, undefined, 'buffer');
        this.resetTextInput();
      } else {
        this.textInput = textInput;
      }
      if (!this.isTippyActive) {
        this.tippyInstance?.show();
      }
      const payload = { textInput: this.textInput };
      this.$emit('textInput', payload);
    },
    containsDuplicates(title) {
      if (this.tagTitleStore[title]) {
        return true;
      } else {
        return false;
      }
    },
    bufferContainsDuplicates(title) {
      if (this.bufferTagTitleStore[title]) {
        return true;
      } else {
        return false;
      }
    },
    insertIntoTagTitleStore(title, id) {
      this.tagTitleStore[title] = id;
      // this.$set(this.tagTitleStore, title, id)
    },
    insertIntoBufferTagTitleStore(title, id) {
      this.bufferTagTitleStore[title] = id;
      // this.$set(this.bufferTagTitleStore, title, id)
    },
    alertToDuplicateEntry(duplicatesList = []) {
      this.$snackbar.open({
        message: `<div>Trying to insert a duplicate tags ${duplicatesList.join(
          ', '
        )}</div>`,
        duration: 5000,
        buttonColor: '#f5d908'
      });
    },
    bulkAddTags(arrayOfTags) {
      arrayOfTags.forEach((item) => {
        this.handleAddActiveTag(item, 'title');
      });
    },
    handleAddActiveTag(data, objectExtractKey, target) {
      // const start = performance.now()
      const tagData = this.getTagDataConverted(data, objectExtractKey);
      if (tagData.title) {
        if (target === 'buffer') {
          if (!this.bufferContainsDuplicates(tagData.title)) {
            this.bufferedTags.push(tagData);
            this.insertIntoBufferTagTitleStore(tagData.title, tagData.id);
          } else if (this.showDuplicateAlert) {
            this.alertToDuplicateEntry();
            this.showDuplicateAlert = false;
          }
        } else {
          if (!this.containsDuplicates(tagData.title)) {
            this.localActiveTags.push(tagData);
            this.insertIntoTagTitleStore(tagData.title, tagData.id);
          } else if (this.showDuplicateAlert) {
            this.alertToDuplicateEntry();
            this.showDuplicateAlert = false;
          }
        }
      } else {
      }
      // const end = performance.now()
      // console.log('handleAddActiveTag', end - start)
    },
    getTagDataConverted(data, objectExtractKey) {
      const id = 1 + Math.floor(Math.random() * 1000);
      if (typeof data === 'object' && data !== null) {
        const defaultKey = objectExtractKey || this.tagsTitleKey || 'title';
        const title = data[defaultKey];
        const tagId = data.id || id;
        return { id: tagId, title };
      } else if (typeof data === 'string' || data instanceof String) {
        const trimmedText = data.trim();
        if (trimmedText) {
          return { id, title: trimmedText };
        }
      } else if (typeof data === 'number') {
        return { id, title: data };
      } else {
        console.log('unknown data type');
      }
      return {};
    },
    handleRemoveBufferedTag(data) {
      const filteredList = this.bufferedTags.filter(
        (item) => item.id !== data.id
      );
      this.bufferedTags = filteredList;
      this.bufferTagTitleStore[data.title] = 0;
      // this.$set(this.bufferTagTitleStore, data.title, 0)
    },
    handleRemoveActiveTag(data) {
      const filteredList = this.localActiveTags.filter(
        (item) => item.id !== data.id
      );
      this.localActiveTags = filteredList;
      this.tagTitleStore[data.title] = 0;
      this.$emit('removeActiveTag', this.localActiveTags, data.title);
      // this.$set(this.tagTitleStore, data.title, 0)
    },
    handleKeydown(e) {
      if (
        e.key === 'Backspace' &&
        !this.tagsAggregatedView &&
        !this.textInput.length &&
        this.localActiveTags.length
      ) {
        e.preventDefault();
        const poppedData = this.localActiveTags.pop();
        // this.$set(this.tagTitleStore, poppedData.title, 0)
        delete this.tagTitleStore[poppedData.title];
      }
    },
    handleEnter() {
      if (this.textInput.length) {
        if (this.hasTagsBuffer) {
          this.handleAddActiveTag(this.textInput, undefined, 'buffer');
        } else {
          this.handleAddActiveTag(this.textInput);
          this.$emit('handleEnter', this.textInput);
        }
        this.resetTextInput();
      }
    },
    splitMulti(str, delimiters) {
      if (!delimiters.length) {
        return [str];
      }
      const tempChar = delimiters[0].token; // We can use the first token as a temporary join character
      for (var i = 1; i < delimiters.length; i++) {
        str = str.split(delimiters[i].token).join(tempChar);
      }
      str = str.split(tempChar);
      return str;
    },
    convertStringWithDelimitersToCsv(str) {
      const tagsAppendData = this.splitMulti(str, this.delimiters);
      return tagsAppendData.join(', ');
    },
    convertStringWithDelimitersToTags(str) {
      const tagsAppendData = this.splitMulti(str, [{ token: ', ' }]);
      if (tagsAppendData[tagsAppendData.length - 1] === '') {
        tagsAppendData.pop();
      }
      tagsAppendData.forEach((item) => {
        this.handleAddActiveTag(item);
      });
      this.isBulkPaste = false;
      this.resetTextInput();
    },
    clearAllActiveTags() {
      this.localActiveTags = [];
      this.tagTitleStore = {};
      // this.$set(this, 'tagTitleStore', {})
    },
    handlePaste(e) {
      const pastedData = e.clipboardData.getData('text');
      const tagsAppendData = this.splitMulti(pastedData, this.delimiters);
      tagsAppendData.forEach((item) => {
        if (this.hasTagsBuffer) {
          this.handleAddActiveTag(item, undefined, 'buffer');
        } else {
          this.handleAddActiveTag(item);
        }
      });
      this.isBulkPaste = true;
      // this.handleOpenActiveTagsDropdown()
    },
    handleSearchSubmit(data) {
      this.$emit('searchSubmit', data);
    },
    handleOpenActiveTagsDropdown(data) {
      this.$emit('openActiveTagsDropdown', data);
    },
    handleOpenBufferedTagsDropdown(data) {
      this.$emit('openBufferedTagsDropdown', data);
    },
    focusAtInput() {
      // const start = performance.now()
      this.tagsInputInstance.focusAtInputField();
      // const end = performance.now()
      // console.log('focusAtInput', end - start)
    },
    handleComponentClick() {
      this.$refs.trigger.click();
      // this.focusAtInput()
      const payload = { textInput: this.textInput };
      this.$emit('inputFieldClick', payload);
    },
    getTagsInputInstance(that) {
      this.tagsInputInstance = that;
    },
    handleMouseEnter(index) {
      this.hoverIndex = index;
    },
    handleMouseLeave() {
      this.hoverIndex = null;
    },
    onShow(instance) {
      this.isTippyActive = true;
    },
    onHide(instance) {
      this.isTippyActive = false;
    },
    onShown() {
      this.focusAtInput();
    },
    handleSuggestionClickRemove(data) {
      this.handleRemoveActiveTag(data);
    },
    handleSuggestionClick(data) {
      // this.resetTextInput();
      // const start = performance.now()
      this.handleAddActiveTag(data);
      if (this.hideDropdownAtSelect) {
        this.resetTextInput();
        this.tippyInstance?.hide();
      }
      this.$emit('suggestionClick', data);
      this.focusAtInput();
      // const end1 = performance.now()
      // console.log('handleSuggestionClick', end1 - start)
      // this.$emit('suggestionClick', data);
      // const end2 = performance.now()
      // console.log('handleSuggestionClick', end2 - start)
    },
    handleTagClick(data) {
      this.$emit('tagClicked', data);
    }
  }
};
</script>

<style lang="css" scoped>
.searchWrapper {
  height: 36px;
  border: 0;
}
.tippy-drop-down {
  position: relative;
  line-height: 1;
  box-shadow: 0 0 0 0 #caccce;
  max-height: 400px;
  overflow-y: auto;
}
.text-overflow {
  overflow-wrap: break-word;
}
.height {
  height: 100px;
}
</style>
