export function getSkusToAdd(tableSkus = [], fetchedSkus = []) {
  const skusToAdd = tableSkus.filter((item) => {
    const isSkuAlreadyPresent = fetchedSkus.find(
      (fetchedItem) => fetchedItem.itemId === item?.sku
    );
    if (isSkuAlreadyPresent === undefined) {
      return true;
    } else return false;
  });
  return skusToAdd;
}

export function getSkusToUpdate(tableSkus = [], fetchedSkus = []) {
  let skusToUpdate = tableSkus.filter((tableSkusItem) => {
    const isSkuBidUpdated = fetchedSkus.find(
      (fetchedSkuItem) =>
        tableSkusItem?.newBid &&
        tableSkusItem?.newBid !== fetchedSkuItem?.bid &&
        fetchedSkuItem?.itemId === tableSkusItem?.sku
    );
    if (isSkuBidUpdated) {
      return true;
    } else return false;
  });
  skusToUpdate = skusToUpdate.map((skuItem) => {
    if (skuItem.itemId) {
      return skuItem;
    } else {
      const fetchedFoundItem = fetchedSkus.find(
        (fetchedSkuItem) => fetchedSkuItem?.itemId === skuItem?.sku
      );
      const itemId = fetchedFoundItem?.itemId;
      skuItem.itemId = itemId;
      return skuItem;
    }
  });
  return skusToUpdate;
}

export function getSkusToDelete(tableSkus = [], fetchedSkus = []) {
  const skusToDelete = fetchedSkus.filter((item) => {
    const isSkuPresentInTableSkus = tableSkus.find(
      (tableSkuItem) => tableSkuItem?.sku === item.itemId
    );
    if (isSkuPresentInTableSkus) {
      return false;
    } else return true;
  });
  return skusToDelete;
}
