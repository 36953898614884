<template>
  <div class="u-display-flex">
    <div class="u-display-flex u-flex-direction-column">
      <!-- Profile 1 -->
      <section>
        <!-- Upload branch logo -->
        <div>
          <div class="u-font-size-5 u-font-weight-600 u-color-grey-base">
            Profile 1:
          </div>
          <div
            class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-mt-xl u-display-flex u-flex-align-items-center"
          >
            <ImageGuideLinesTippyVue to-element-name="infoTooltip1" />
            <span>Brand logo:</span
            ><rb-icon
              name="infoTooltip1"
              class="u-color-grey-lighter rb-icon--medium u-spacing-ml-xs u-cursor-pointer"
              icon="info-circle-fill"
            >
            </rb-icon>
          </div>
          <div
            class="u-spacing-mt-m u-display-flex u-width-560px u-flex-align-items-center"
          >
            <img
              ref="logoRef1"
              class="brand-name-logo"
            />

            <div class="u-spacing-pl-s u-font-size-7 u-color-blue-base">
              <input
                id="fileElm1"
                ref="fileElm1"
                data-cy="uploadLogo"
                class="u-display-none"
                type="file"
                accept="image/png"
                :multiple="false"
                @change="processFile1"
              />
              <span
                class="u-cursor-pointer"
                @click="chooseFile1"
                >Upload logo</span
              >
            </div>
          </div>
          <FormErrorMessage
            :show-error="Boolean(formErrorObject.profile1.file)"
            :error-text="formErrorObject.profile1.file"
          />
        </div>

        <!-- Brand name -->
        <div
          class="u-spacing-mt-l u-display-flex u-flex-direction-column u-width-100"
        >
          <span
            data-cy="BrandNameHeading"
            class="u-font-size-5 u-font-weight-600 u-color-grey-base"
            >Brand Name:</span
          >
          <rb-input
            v-model="brandName1"
            placeholder="Enter brand name"
            class="u-width-100 u-spacing-mt-s"
            @input="
              updateProfile1Info({
                brandName: brandName1,
                headline: headline1,
                url: url1,
                file: selectedFile1
              })
            "
          />
          <div
            class="u-spacing-mt-xs u-display-flex u-flex-justify-content-space-between"
            :class="{
              'u-flex-align-self-flex-end': !formErrorObject.profile1.brandName
            }"
          >
            <FormErrorMessage
              :show-error="Boolean(formErrorObject.profile1.brandName)"
              :error-text="formErrorObject.profile1.brandName"
            />
            <span class="u-font-size-8 u-font-weight-400 u-color-grey-lighter"
              >Max of 35 characters</span
            >
          </div>
        </div>

        <!-- Headline -->
        <div class="u-spacing-mt-l">
          <span
            data-cy="HeadlineHeading"
            class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-mt-xl"
            >Headline:</span
          >
          <div
            class="u-display-flex u-flex-direction-column u-width-100 u-spacing-mt-s"
          >
            <rb-input
              v-model="headline1"
              placeholder="Enter headline"
              class="u-width-100"
              @input="
                updateProfile1Info({
                  brandName: brandName1,
                  headline: headline1,
                  url: url1,
                  file: selectedFile1
                })
              "
            />
            <div
              class="u-spacing-mt-xs u-display-flex u-flex-justify-content-space-between"
              :class="{
                'u-flex-align-self-flex-end': !formErrorObject.profile1.headline
              }"
            >
              <FormErrorMessage
                :show-error="Boolean(formErrorObject.profile1.headline)"
                :error-text="formErrorObject.profile1.headline"
              />
              <span class="u-font-size-8 u-font-weight-400 u-color-grey-lighter"
                >Max of 45 characters</span
              >
            </div>
          </div>
        </div>

        <!-- Url -->
        <div class="u-spacing-mt-l">
          <span
            data-cy="URLHeading"
            class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-mt-xl"
            >URL:</span
          >
          <div
            class="u-display-flex u-flex-direction-column u-width-100 u-spacing-mt-s"
          >
            <rb-input
              v-model="url1"
              placeholder="Enter url"
              class="u-width-100"
              @input="
                updateProfile1Info({
                  brandName: brandName1,
                  headline: headline1,
                  url: url1,
                  file: selectedFile1
                })
              "
            />
            <FormErrorMessage
              :show-error="Boolean(formErrorObject.profile1.url)"
              :error-text="formErrorObject.profile1.url"
            />
          </div>
        </div>
      </section>

      <!-- Collapsible title for profile2 -->
      <div class="u-flex-align-items-center u-display-flex u-spacing-mt-m">
        <CollapsibleTitle
          class="u-spacing-pt-xl u-spacing-pr-s u-spacing-pb-l"
          title="Profile 2"
          :is-expanded="profile2expand"
          title-font-class="u-font-size-5 u-font-weight-600"
          @click="$emit('profile2ExpandClicked')"
        />
        <div class="u-spacing-pt-s">
          <div
            class="u-font-size-5 u-border-left u-font-weight-600 u-border-width-s u-border-color-grey-xxx-light u-color-grey-lighter u-spacing-pl-s u-spacing-pv-xxs"
          >
            Optional
          </div>
        </div>
      </div>
      <!-- Profile 2 -->
      <section v-show="profile2expand">
        <!-- Upload branch logo -->
        <div>
          <div
            class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-mt-xl u-display-flex u-flex-align-items-center"
          >
            <ImageGuideLinesTippyVue to-element-name="infoTooltip2" />
            <span>Brand logo:</span
            ><rb-icon
              name="infoTooltip2"
              class="u-color-grey-lighter rb-icon--medium u-spacing-ml-xs u-cursor-pointer"
              icon="info-circle-fill"
            >
            </rb-icon>
          </div>
          <div
            class="u-spacing-mt-m u-display-flex u-width-560px u-flex-align-items-center"
          >
            <img
              ref="logoRef2"
              class="brand-name-logo"
            />

            <div class="u-spacing-pl-s u-font-size-7 u-color-blue-base">
              <input
                id="fileElm2"
                ref="fileElm2"
                data-cy="uploadLogo"
                class="u-display-none"
                type="file"
                accept="image/png"
                :multiple="false"
                @change="processFile2"
              />
              <span
                class="u-cursor-pointer"
                @click="chooseFile2"
                >Upload logo</span
              >
            </div>
          </div>
          <FormErrorMessage
            :show-error="Boolean(formErrorObject.profile2.file)"
            :error-text="formErrorObject.profile2.file"
          />
        </div>

        <!-- Brand name -->
        <div
          class="u-spacing-mt-l u-display-flex u-flex-direction-column u-width-100"
        >
          <span class="u-font-size-5 u-font-weight-600 u-color-grey-base"
            >Brand Name:</span
          >
          <rb-input
            v-model="brandName2"
            placeholder="Enter brand name"
            class="u-width-100 u-spacing-mt-s"
            @input="
              updateProfile2Info({
                brandName: brandName2,
                headline: headline2,
                url: url2,
                file: selectedFile2
              })
            "
          />
          <div
            class="u-spacing-mt-xs u-display-flex u-flex-justify-content-space-between"
            :class="{
              'u-flex-align-self-flex-end': !formErrorObject.profile2.brandName
            }"
          >
            <FormErrorMessage
              :show-error="Boolean(formErrorObject.profile2.brandName)"
              :error-text="formErrorObject.profile2.brandName"
            />
            <span class="u-font-size-8 u-font-weight-400 u-color-grey-lighter"
              >Max of 30 characters</span
            >
          </div>
        </div>

        <!-- Headline -->
        <div class="u-spacing-mt-l">
          <span
            class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-mt-xl"
            >Headline:</span
          >
          <div
            class="u-display-flex u-flex-direction-column u-width-100 u-spacing-mt-s"
          >
            <rb-input
              v-model="headline2"
              placeholder="Enter headline"
              class="u-width-100"
              @input="
                updateProfile2Info({
                  brandName: brandName2,
                  headline: headline2,
                  url: url2,
                  file: selectedFile2
                })
              "
            />
            <div
              class="u-spacing-mt-xs u-display-flex u-flex-justify-content-space-between"
              :class="{
                'u-flex-align-self-flex-end': !formErrorObject.profile2.headline
              }"
            >
              <FormErrorMessage
                :show-error="Boolean(formErrorObject.profile2.headline)"
                :error-text="formErrorObject.profile2.headline"
              />
              <span class="u-font-size-8 u-font-weight-400 u-color-grey-lighter"
                >Max of 50 characters</span
              >
            </div>
          </div>
        </div>

        <!-- Url -->
        <div class="u-spacing-mt-l">
          <span
            class="u-font-size-5 u-font-weight-600 u-color-grey-base u-spacing-mt-xl"
            >URL:</span
          >
          <div
            class="u-display-flex u-flex-direction-column u-width-100 u-spacing-mt-s"
          >
            <rb-input
              v-model="url2"
              placeholder="Enter url"
              class="u-width-100"
              @input="
                updateProfile2Info({
                  brandName: brandName2,
                  headline: headline2,
                  url: url2,
                  file: selectedFile2
                })
              "
            />
            <FormErrorMessage
              :show-error="Boolean(formErrorObject.profile2.url)"
              :error-text="formErrorObject.profile2.url"
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import loader from '@/components/basic/loader.vue';
import CollapsibleTitle from '@/components/pages/strategy_builder/steps/collapsible-title.vue';
import FormErrorMessage from '@/components/ams/campaign_creation/walmart_steps/common/FormErrorMessage.vue';
import ImageGuideLinesTippyVue from '@/components/ams/campaign_creation/walmart_steps/profile/ImageGuideLinesTippy.vue';
export default {
  components: {
    loader,
    CollapsibleTitle,
    FormErrorMessage,
    ImageGuideLinesTippyVue
  },
  props: {
    load: {
      default: false,
      type: Boolean
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    },
    allStepsData: {
      type: Object,
      default: function () {
        return {};
      }
    },
    profile2expand: {
      type: Boolean,
      default: false
    },
    formErrorObject: {
      type: Object,
      default: {
        profile1: {
          file: '',
          brandName: '',
          headline: '',
          src: ''
        },
        profile2: {
          file: '',
          brandName: '',
          headline: '',
          src: ''
        }
      }
    }
  },
  data: () => ({
    brandName1: null,
    headline1: null,
    url1: null,
    selectedFile1: null,
    brandName2: null,
    headline2: null,
    url2: null,
    selectedFile2: null
  }),
  created() {
    this.updateProfile1Info();
    this.updateProfile2Info();
  },
  mounted() {
    // We are doing this on mounted because in the created hook,
    // the <img/> elements for both files are still not mounted,
    // hence we cannot set the src property for them.
    this.preFillFieldsFromFetchedData();
  },
  methods: {
    preFillFieldsFromFetchedData() {
      // This method is called by the parent when the fetching is complete
      const allStepsData = this.allStepsData;
      const fetchedProfileResponse = allStepsData?.fetchedProfileResponse || [];

      const [fetchedProfile1, fetchedProfile2] = fetchedProfileResponse;
      if (fetchedProfile1) {
        this.updateProfile1Info({
          brandName: fetchedProfile1?.searchAmpName,
          headline: fetchedProfile1?.headlineText,
          url: fetchedProfile1?.clickUrl
        });
        this.processFile1(fetchedProfile1?.logoUrl);
      }
      if (fetchedProfile2) {
        this.updateProfile2Info({
          brandName: fetchedProfile2?.searchAmpName,
          headline: fetchedProfile2?.headlineText,
          url: fetchedProfile2?.clickUrl
        });
        this.processFile2(fetchedProfile2?.logoUrl);
      }
    },
    chooseFile1() {
      this.$refs.fileElm1.click();
    },
    chooseFile2() {
      this.$refs.fileElm2.click();
    },
    processFile1(e) {
      const storedInfo = this.allStepsData?.profile1 || {};
      let allStepsData = this.allStepsData;
      if (typeof e === 'string') {
        this.$refs.logoRef1.src = e;
        storedInfo.file = e;
        this.selectedFile1 = e;
      } else {
        const file = e.target?.files?.[0];
        storedInfo.file = file;
        this.selectedFile1 = file;
        this.$refs.logoRef1.src = URL.createObjectURL(this.selectedFile1);
        this.$refs.logoRef1.onload = function () {
          URL.revokeObjectURL(this.$refs.logoRef1.src); // free memory
        };
      }

      allStepsData = {
        ...allStepsData,
        ...{ profile1: storedInfo }
      };
      this.$store.dispatch(this.selectedValues.action, allStepsData);
    },
    processFile2(e) {
      const storedInfo = this.allStepsData?.profile2 || {};
      let allStepsData = this.allStepsData;

      if (typeof e === 'string') {
        console.log('Inside if conditon');
        this.$refs.logoRef2.src = e;
        storedInfo.file = e;
        this.selectedFile2 = e;
      } else {
        const file = e.target?.files?.[0];
        storedInfo.file = file;
        this.selectedFile2 = file;
        this.$refs.logoRef2.src = URL.createObjectURL(this.selectedFile2);
        this.$refs.logoRef2.onload = function () {
          URL.revokeObjectURL(this.$refs.logoRef2.src); // free memory
        };
      }
      allStepsData = {
        ...allStepsData,
        ...{ profile2: storedInfo }
      };
      this.$store.dispatch(this.selectedValues.action, allStepsData);
    },
    updateProfile1Info(item) {
      let allStepsData = this.allStepsData;
      const storedInfo = this.allStepsData?.profile1;
      const brandInfo = item || storedInfo;
      this.brandName1 = brandInfo?.brandName;
      this.headline1 = brandInfo?.headline;
      this.url1 = brandInfo?.url;

      // During component created, upload the image again. make it null
      if (!item && brandInfo) {
        brandInfo.file = null;
        this.selectedFile1 = null;
      }
      allStepsData = {
        ...allStepsData,
        ...{ profile1: brandInfo }
      };
      this.$store.dispatch(this.selectedValues.action, allStepsData);
    },
    updateProfile2Info(item) {
      let allStepsData = this.allStepsData;
      const storedInfo = this.allStepsData?.profile2;
      const brandInfo = item || storedInfo;
      this.brandName2 = brandInfo?.brandName;
      this.headline2 = brandInfo?.headline;
      this.url2 = brandInfo?.url;

      // During component created, upload the image again. make it null
      if (!item && brandInfo) {
        brandInfo.file = null;
        this.selectedFile2 = null;
      }
      allStepsData = {
        ...allStepsData,
        ...{ profile2: brandInfo }
      };
      this.$store.dispatch(this.selectedValues.action, allStepsData);
    }
  }
};
</script>

<style lang="css" scoped>
.select-asset:hover {
  background: #007cf6;
  cursor: pointer;
}
.select-asset:hover .asset-px {
  color: #fff;
}

.brand-name-logo {
  height: 86px;
  width: 105px;
  max-height: 86px;
  max-width: 105px;
  object-fit: cover;
  background: url('/images/upload_file.png') no-repeat scroll 0 0;
  background-size: contain;
  border-radius: 4px;
}
</style>
