<template>
  <div class="u-display-flex start-and-no-end-date">
    <div class="u-spacing-pr-l u-flex-direction-column">
      <div
        data-cy="startDate"
        class="u-spacing-pb-s u-font-size-5 u-font-weight-600"
      >
        Start Date:
      </div>
      <div v-click-outside="hideStartCalendar">
        <span
          v-tippy="{ placement: 'top', arrow: true }"
          data-cy="clickStartDateCal"
          class="filter--token u-font-size-6 u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
          @click="openCalendar('startDate')"
        >
          <rb-icon
            icon="calendar"
            class="rb-icon--small u-spacing-mr-xs"
          />
          <span class="u-spacing-ph-xs u-line-height-1_2">
            {{ dateFormatFilter(datesValues.start, 'start') }}</span
          >
          <span>{{ timezoneString }}</span>
        </span>
        <datepicker
          ref="startDate"
          v-model="datesValues.start"
          style="right: -200px"
          name="startDate"
          :disabled-dates="disabledStartDates"
        />
      </div>
    </div>

    <div class="u-flex-direction-column">
      <div class="u-spacing-pb-s u-font-size-5">
        <span
          data-cy="enddate"
          class="u-font-weight-600"
          >End Date:</span
        >
        <span class="u-color-grey-lighter"> Optional</span>
      </div>
      <div
        v-click-outside="hideEndCalendar"
        class="entity-details-settings-calendar"
      >
        <span
          data-cy="clickEndDateCal"
          class="filter--token u-font-size-6 u-spacing-p-s u-cursor-pointer u-display-inline-flex u-flex-align-items-center"
          @click="openCalendar('endDate')"
        >
          <rb-icon
            icon="calendar"
            class="rb-icon--small u-spacing-mr-xs"
          />
          <span class="u-spacing-ph-xs u-line-height-1_2">
            {{ dateFormatFilter(datesValues.end, 'end') }}</span
          >
          <span v-if="datesValues.end !== null">{{ timezoneString }}</span>
        </span>
        <datepicker
          ref="endDate"
          v-model="datesValues.end"
          style="right: -100px"
          name="endDate"
          :disabled-dates="disabledEndDates"
        >
          <div
            slot="beforeCalendarHeader"
            class="no-end-date u-border-bottom u-border-color-grey-xx-light u-border-width-s u-spacing-p-s u-cursor-pointer u-spacing-mb-s"
            :class="noEndDate && 'u-color-white-base u-bg-color-orange-base'"
            data-cy="noEndDate"
            @click="selectNoEndDate"
          >
            No end date
          </div>
        </datepicker>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import Datepicker from 'vuejs-datepicker';
import loader from '@/components/basic/loader';
export default {
  name: 'StartAndNoEndDate',
  components: {
    loader,
    Datepicker
  },
  props: {
    defaultCalenderDates: {
      type: Object,
      default: () => ({
        start: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        end: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      })
    },
    timezone: {
      type: String,
      default: 'PST'
    },
    showTimeZone: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      noEndDate: true,
      datesValues: {
        start: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
        end: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      },
      disabledStartDates: {
        from: null,
        to: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      },
      disabledEndDates: {
        from: null,
        to: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      }
    };
  },
  computed: {
    timezoneString() {
      if (this.showTimeZone === true) {
        return ` ${this.timezone}`;
      }
      return null;
    }
  },
  watch: {
    'datesValues.start'() {
      this.validateDateChanges();
      this.disabledEndDates.to = this.datesValues.start;
      // ToDo
      // End time should be today or in the future
      // End time should also be after start time, i.e start time + 1 day
    },
    'datesValues.end'() {
      this.validateDateChanges();
    },
    defaultCalenderDates(newVal) {
      this.datesValues = newVal;
    }
  },
  created() {
    this.datesValues = this.defaultCalenderDates;
  },
  methods: {
    openCalendar(param) {
      this.$refs[param] && this.$refs[param].showCalendar();
    },
    datesValuesUpdated() {
      this.$emit('datesValuesUpdated', this.datesValues);
    },
    hideStartCalendar() {
      this.$refs?.startDate?.close();
    },
    hideEndCalendar() {
      this.noEndDate = !this.datesValues.end;
      this.$refs?.endDate?.close();
    },
    selectNoEndDate() {
      this.datesValues.end = null;
      this.hideEndCalendar();
    },
    dateFormatFilter: function (value, type) {
      if (value === null) return `No ${type} date`;
      return moment(value).format('MMM DD, YYYY');
    },
    dateFormatter(val) {
      if (val === null) return null;
      return moment(val).format('MM/DD/YYYY');
    },
    validateDateChanges() {
      if (
        new Date(this.datesValues.end) < new Date(this.datesValues.start) &&
        this.datesValues.end !== null
      ) {
        this.datesValues.end = this.datesValues.start;
      }
      this.datesValuesUpdated();
    }
  }
};
</script>

<style lang="css" scoped>
.start-and-no-end-date .filter--token {
  border-radius: 2px;
}
</style>
