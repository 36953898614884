export default {
  map: {
    upc: {
      title: 'SKU ID',
      type: 'String',
      page: ['sku'],
      isHiddenInTable: true,
      keyOrder: 1
    },
    enabled: {
      title: 'Active Status',
      showToolTip: true,
      toolTipText: 'Current status of the campaign',
      keyword_toolTipText: 'Current status of the keyword',
      search_toolTipText: 'Current status of the keyword',
      sku_toolTipText: 'Current status of the SKU',
      type: 'Icon',
      page: ['campaign', 'keyword', 'sku', 'adGroups'],
      campaign_keyOrder: 1,
      keyword_keyOrder: 1,
      search_keyOrder: 1,
      sku_keyOrder: 1,
      pinned: 'left'
    },
    SKU: {
      title: 'SKU',
      toolTipText: 'Instacart SKU',
      type: 'String',
      page: 'sku',
      sku_keyOrder: 2,
      pinned: 'left'
    },
    sku_title: {
      title: 'SKU Title',
      toolTipText: 'Instacart SKU title',
      type: 'String',
      page: 'sku',
      isHiddenInTable: true,
      sku_keyOrder: 2
    },
    timeline: {
      title: 'Timeline',
      toolTipText: 'Activity log',
      type: 'icon',
      page: 'common',
      campaign_keyOrder: 1,
      keyword_keyOrder: 1,
      search_keyOrder: 1,
      sku_keyOrder: 1,
      pinned: 'left'
    },
    keyword: {
      title: 'Keyword',
      toolTipText: 'Keyword',
      search_toolTipText: 'Keyword associated with the search term',
      type: 'String',
      pinned: 'left',
      page: ['keyword'],
      keyword_keyOrder: 1
    },
    ad_group_name: {
      title: 'Ad Group Name',
      toolTipText: 'Name of Ad Group',
      type: 'String',
      pinned: 'left',
      page: ['keyword', 'adGroups', 'sku'],
      campaign_keyOrder: 1,
      keyword_keyOrder: 1,
      adGroups_keyOrder: 2,
      sku_keyOrder: 2
    },
    campaign_name: {
      title: 'Campaign Name',
      toolTipText: 'Name of campaign',
      keyword_toolTipText: 'Campaign name of which the keyword is a part of',
      adGroups_toolTipText: 'Campaign name of which the ad group is a part of',
      sku_toolTipText: 'Campaign name of which the SKU is a part of',
      type: 'redirect',
      pinned: 'left',
      page: ['campaign', 'keyword', 'adGroups', 'sku'],
      campaign_keyOrder: 1,
      keyword_keyOrder: 4,
      adGroups_keyOrder: 3,
      sku_keyOrder: 2
    },
    campaign_type: {
      title: 'Campaign type',
      toolTipText: 'Type of campaign (Featured product)',
      type: 'String',
      page: ['campaign', 'keyword', 'adGroups', 'sku'],
      campaign_keyOrder: 3,
      keyword_keyOrder: 5,
      search_keyOrder: 6,
      sku_keyOrder: 3
    },
    budget_type: {
      title: 'Budget Type',
      toolTipText: 'Type of budget (Daily Budget/Max impressions)',
      type: 'String',
      page: ['campaign'],
      campaign_keyOrder: 12
    },
    table_status: {
      title: 'Status',
      field: 'status',
      toolTipText: 'Latest serving state of campaign',
      adGroups_toolTipText: 'Latest serving state of Ad Groups',
      keyword_toolTipText: 'Latest serving state of keyword',
      sku_toolTipText: 'Latest serving state of sku',
      type: 'String',
      page: ['campaign', 'keyword', 'sku', 'adGroups'],
      campaign_keyOrder: 5,
      adGroups_keyOrder: 5,
      sku_keyOrder: 5
    },
    profile_name: {
      title: 'Profile',
      toolTipText: 'Profile associated with the entity',
      type: 'String',
      // page: 'common',
      page: ['search'],
      campaign_keyOrder: 5,
      keyword_keyOrder: 5,
      search_keyOrder: 5,
      sku_keyOrder: 5
    },
    portfolio_name: {
      title: 'Portfolio',
      toolTipText: 'Portfolio associated with the entity',
      type: 'String',
      // page: 'common',
      page: ['search'],
      campaign_keyOrder: 5,
      keyword_keyOrder: 5,
      search_keyOrder: 5,
      sku_keyOrder: 5
    },
    creative_type: {
      title: 'Creative type',
      toolTipText: 'Creative type (Static Ads or Video Ads)',
      type: 'String',
      page: ['search'],
      campaign_keyOrder: 5,
      keyword_keyOrder: 5,
      search_keyOrder: 6
    },
    start_date: {
      title: 'Start Date',
      toolTipText: 'Start Date of the campaign',
      type: 'date',
      page: ['keyword'],
      keyword_keyOrder: 22
    },
    end_date: {
      title: 'End Date',
      toolTipText: 'End Date of the campaign',
      type: 'date',
      page: ['keyword'],
      keyword_keyOrder: 23
    },
    scheduled_start_at_date: {
      title: 'Start Date',
      toolTipText: 'Start Date of the campaign',
      type: 'date',
      page: ['campaign'],
      campaign_keyOrder: 22
    },
    scheduled_end_at_date: {
      title: 'End Date',
      toolTipText: 'End Date of the campaign',
      type: 'date',
      page: ['campaign'],
      campaign_keyOrder: 23
    },
    budget: {
      title: 'Budget',
      toolTipText: 'Lifetime budget allocated to the campaign',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'campaign',
      campaign_keyOrder: 12
    },
    target_daily_budget: {
      title: 'Target Daily Budget',
      toolTipText: 'Daily budget allocated to the campaign',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'campaign',
      campaign_keyOrder: 12
    },
    campaign_bid_type: {
      title: 'Bid Type',
      toolTipText:
        'Manual bid allows the user to set bids at keyword level whereas optimized bidding maximizes sales at the lowest cost per click (CPC) possible with continual bid optimization',
      type: 'STRING',
      unit: '',
      page: 'campaign',
      campaign_keyOrder: 12
    },
    roas_constraint: {
      title: 'Target ROAS',
      toolTipText:
        'The Instacart algorithm modifies bids to achieve the Target Roas goal for the Optimized bid type campaigns.',
      type: 'NUMERIC',
      unit: '',
      page: 'campaign',
      campaign_keyOrder: 12
    },
    spend: {
      title: 'Spend',
      toolTipText: 'Total spend on the campaign in the selected time period',
      keyword_toolTipText:
        'Total spend on the keyword in the selected time period',
      adGroups_toolTipText:
        'Total spend on the ad group in the selected time period',
      sku_toolTipText: 'Total spend on the SKU in the selected time period',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['campaign', 'keyword', 'adGroups', 'sku'],
      campaign_keyOrder: 7,
      keyword_keyOrder: 7,
      adGroups_keyOrder: 6,
      sku_keyOrder: 8
    },
    spend_percentage: {
      title: 'Spend %',
      type: 'NUMERIC',
      page: 'adGroups',
      toolTipText:
        'Share of Ad Group Spend allocated to the Ad Group, expressed as %',
      adGroups_keyOrder: 7,
      unit: 'PERCENTAGE'
    },
    paid_sales_1d: {
      title: 'Paid sales 1 day',
      toolTipText: '',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'graph',
      campaign_keyOrder: 7,
      keyword_keyOrder: 8,
      search_keyOrder: 9,
      sku_keyOrder: 10
    },
    paid_sales_7d: {
      title: 'Paid sales 7 days',
      toolTipText: '',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'graph',
      campaign_keyOrder: 7,
      keyword_keyOrder: 8,
      search_keyOrder: 9,
      sku_keyOrder: 10
    },
    attributed_sales: {
      title: 'Attributed sales (Last Touch)',
      toolTipText:
        '14-day attributed sales generated on the campaign in the selected time period using 14 day last touch attribution method',
      keyword_toolTipText:
        '14-day attributed sales generated on the campaign in the selected time period using 14 day last touch attribution method',
      sku_toolTipText:
        '14-day attributed sales generated on the campaign in the selected time period using 14 day last touch attribution method',
      adGroups_toolTipText:
        '14-day attributed sales generated on the campaign in the selected time period using 14 day last touch attribution method',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['sku', 'adGroups', 'keyword', 'campaign'],
      sku_keyOrder: 8,
      adGroups_keyOrder: 8,
      keyword_keyOrder: 8,
      campaign_keyOrder: 9
    },
    attributed_sales_percentage: {
      title: 'Attributed sales %',
      toolTipText:
        'Share of 14 day attributed sales at the Ad Group level allocated to the Ad Group, expressed as %',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['adGroups'],
      adGroups_keyOrder: 9
    },
    acos: {
      title: 'ACoS',
      toolTipText:
        'Advertised cost of sales generated from the campaign in the selected time period using 14 day attribution method calculated as Total spend divided by attributed sales',
      keyword_toolTipText:
        'Advertised cost of sales generated from the keyword in the selected time period using 14 day attribution method calculated as Total spend divided by attributed sales',
      adGroups_toolTipText:
        'Advertised cost of sales generated from the ad group in the selected time period using 14 day attribution method calculated as Total spend divided by attributed sales',
      sku_toolTipText:
        'Advertised cost of sales generated on the SKU in the selected time period using 14 day attribution method calculated as Total spend/ attributed sales',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['campaign', 'sku', 'adGroups', 'keyword'],
      sku_keyOrder: 9,
      keyword_keyOrder: 11,
      adGroups_keyOrder: 11
    },
    roi: {
      title: 'ROAS (Last Touch)',
      toolTipText:
        'Paid sales generated for each dollar of ad spend for the selected time period calculated as attributed sales divided by ad spend',
      keyword_toolTipText:
        'Paid sales generated for each dollar of ad spend for the selected time period calculated as attributed sales divided by ad spend',
      search_toolTipText:
        'Paid sales generated for each dollar of ad spend for the selected time period calculated as attributed sales divided by ad spend',
      sku_toolTipText:
        'Paid sales generated for each dollar of ad spend for the selected time period calculated as attributed sales divided by ad spend',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['adGroups', 'sku'],
      keyword_keyOrder: 8,
      search_keyOrder: 8,
      sku_keyOrder: 8
    },
    roas: {
      title: 'ROAS (Last Touch)',
      toolTipText:
        'Attributed sales generated for each dollar of ad spend for the selected time period calculated as attributed sales divided by ad spend, based on last touch attribution model',
      keyword_toolTipText:
        'Attributed sales generated for each dollar of ad spend for the selected time period calculated as attributed sales divided by ad spend, based on last touch attribution model',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['keyword', 'campaign'],
      keyword_keyOrder: 10,
      campaign_keyOrder: 10
    },
    impressions: {
      title: 'Impressions',
      toolTipText:
        'Total number of impressions generated from the campaign in the selected time period',
      keyword_toolTipText:
        'Total number of impressions generated from the keyword in the selected time period',
      adGroups_toolTipText:
        'Total number of impressions generated from the ad groups in the selected time period',
      sku_toolTipText:
        'Total number of impressions generated on the SKU in the selected time period',
      type: 'NUMERIC',
      page: 'common',
      campaign_keyOrder: 14,
      keyword_keyOrder: 16,
      search_keyOrder: 17,
      sku_keyOrder: 17
    },
    clicks: {
      title: 'Clicks',
      toolTipText:
        'Total number of clicks generated from the campaign in the selected time period',
      keyword_toolTipText:
        'Total number of clicks generated from the keyword in the selected time period',
      adGroups_toolTipText:
        'Total number of clicks generated from the ad groups in the selected time period',
      sku_toolTipText:
        'Total number of clicks generated on the SKU in the selected time period',
      type: 'NUMERIC',
      page: 'common',
      campaign_keyOrder: 14,
      keyword_keyOrder: 17,
      search_keyOrder: 18,
      sku_keyOrder: 18
    },
    ctr: {
      title: 'Click through rate',
      toolTipText:
        'Measure of how many clicks generated from the impressions in the selected time period calculated as clicks divided by impressions',
      sku_toolTipText:
        'Measure of how many clicks generated from the impressions in the selected time period on the SKU calculated as clicks divided by impressions',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: 'common',
      campaign_keyOrder: 15,
      keyword_keyOrder: 18,
      search_keyOrder: 19,
      sku_keyOrder: 19
    },
    attributed_quantities: {
      title: 'Units (Last Touch)',
      toolTipText:
        'Total number of units sold from the campaign in the selected time period using 14-day last touch attribution method',
      sku_toolTipText:
        'Total number of units sold from the campaign in the selected time period using 14-day last touch attribution method',
      adGroups_toolTipText:
        'Total number of units sold from the campaign in the selected time period using 14-day last touch attribution method',
      type: 'NUMERIC',
      page: ['sku', 'adGroups', 'keyword', 'campaign'],
      sku_keyOrder: 21,
      campaign_keyOrder: 21,
      keyword_keyOrder: 10
    },
    attributed_quantities_percentage: {
      title: 'Units %',
      toolTipText:
        'Share of Ad Group Units allocated to the Ad Group, expressed as %',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: 'adGroups',
      sku_keyOrder: 22
    },
    cpc: {
      title: 'Cost per click',
      toolTipText:
        'Average cost per click in the selected time period calculated as spend divided by clicks',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'common',
      campaign_keyOrder: 11,
      keyword_keyOrder: 9,
      search_keyOrder: 10,
      sku_keyOrder: 9
    },
    keyword_id: {
      title: 'Keyword ID',
      toolTipText: '',
      type: 'String',
      page: 'graph',
      campaign_keyOrder: 2,
      keyword_keyOrder: 3,
      search_keyOrder: 4,
      sku_keyOrder: 5
    },
    matching_type: {
      title: 'Match type keyword',
      toolTipText: 'Targeting type (exact)',
      search_toolTipText: 'Targeting type of the keyword (exact)',
      type: 'String',
      page: ['keyword'],
      campaign_keyOrder: 2,
      keyword_keyOrder: 6,
      search_keyOrder: 7,
      sku_keyOrder: 5
    },
    keyword_type: {
      title: 'Search Term type',
      toolTipText: 'Type of keyword (generic, branded, competitor)',
      search_toolTipText: 'Type of search term (generic, branded, competitor)',
      type: 'String',
      page: ['search', 'keyword'],
      campaign_keyOrder: 2,
      keyword_keyOrder: 2,
      search_keyOrder: 2,
      sku_keyOrder: 5
    },
    latest_cpc_bid: {
      title: 'Current Bid',
      toolTipText: 'Current bid amount on the keyword',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['keyword'],
      keyword_keyOrder: 9
    },
    default_bid: {
      title: 'Default Bid',
      toolTipText:
        'The default bid amount to be used for all items associated with this ad group',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'adGroups',
      adGroups_keyOrder: 9
    },
    suggested_bid: {
      title: 'Suggested Bid',
      toolTipText:
        'Instacart recommended bid to achieve a win rate of 50-75% or higher to ensure competitiveness against other advertisers in the category',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['adGroups', 'keyword'],
      adGroups_keyOrder: 8,
      keyword_keyOrder: 10
    },
    bid_type: {
      title: 'Bid Type',
      toolTipText:
        'Default or Override. If Default, it takes value from Ad Group settings',
      type: 'String',
      page: ['keyword'],
      keyword_keyOrder: 15
    },
    category: {
      title: 'Category',
      toolTipText:
        "SKU's Category as per Catalog Listing Report in Instacart console",
      type: 'String',
      page: 'sku',
      campaign_keyOrder: 2,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 5,
      display: false
    },
    bid_strength: {
      title: 'Bid Strength',
      toolTipText:
        'Indicates Instacart’s assessment of the competitiveness of bid. Bids at the stronger end can help ads reach a greater portion of impressions than those at the weaker end. When disabled, it implies more more data is needed to evaluate',
      type: 'String',
      page: ['adGroups', 'keyword'],
      keyword_keyOrder: 10,
      adGroups_keyOrder: 9
    },
    subcategory: {
      title: 'Sub Category',
      toolTipText:
        "SKU's Subcategory as per Catalog Listing Report in Instacart console",
      type: 'String',
      page: 'sku',
      campaign_keyOrder: 2,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 6,
      display: false
    },
    brand: {
      title: 'Brand',
      toolTipText: "SKU's Brand",
      type: 'String',
      page: 'sku',
      campaign_keyOrder: 2,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 7
    },
    inventory: {
      title: 'Inventory',
      toolTipText: 'Total inventory on hand for the SKU at Amazon warehouse',
      type: 'NUMERIC',
      page: 'graph',
      campaign_keyOrder: 2,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 16
    },
    inventory_latest: {
      title: 'Inventory',
      toolTipText: 'Total inventory on hand for the SKU at Amazon warehouse',
      type: 'NUMERIC',
      page: 'sku',
      campaign_keyOrder: 2,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 16,
      display: false
    },
    amazon_revenue: {
      title: 'Amazon revenue',
      toolTipText:
        'Revenue generated by Amazon on the SKU calculated as Units sold multiplied by Average selling price',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'sku',
      campaign_keyOrder: 2,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 12,
      display: false
    },
    pcogs: {
      title: 'PCOGS',
      toolTipText:
        'Calculated as units sold multiplied by cost of SKU to Amazon',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'sku',
      campaign_keyOrder: 2,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 13,
      display: false
    },
    total_sales: {
      title: 'Total sales',
      toolTipText: '',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'graph',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 5
    },
    iacos: {
      title: 'iAcos',
      type: 'NUMERIC',
      page: 'sku',
      sku_keyOrder: 9,
      unit: 'PERCENTAGE',
      toolTipText:
        'Incremental ACoS defined as AMS spend divided by Total sales (both paid and organic)',
      display: false
    },
    latest_offer_price: {
      title: 'Offer price',
      toolTipText: 'Current offer price of the SKU',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 25,
      display: false
    },
    subscribe_and_save_price: {
      title: 'Subscribe and Save Price',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'sku',
      toolTipText:
        'Subscribe and Save Price as per CIQ Market Intelligence data.',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 26,
      display: false
    },
    organic_sales: {
      title: 'Organic sales',
      toolTipText:
        'Measure of organic sales of the SKU calculated as (Total sales - Paid sales)',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 11,
      display: false
    },
    avg_selling_price: {
      title: 'Average Selling price',
      toolTipText: 'Average selling price of the SKU in the given time period',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 24
    },
    promotion_types: {
      title: 'Promo types',
      type: 'String',
      page: 'sku',
      toolTipText:
        ' Promotion Type active on the SKU as per latest CIQ Market Intellience data . VPC = Vendor Powered Coupon and DOTD = Deal of the Day',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 27,
      display: false
    },
    promotion_messages_daily: {
      title: 'Promotion Messages',
      type: 'String',
      page: '',
      toolTipText: 'Value of the promotion as seen on the product detail page.'
    },
    promotion_messages: {
      title: 'Promotion Messages',
      type: 'String',
      page: 'sku',
      toolTipText: 'Value of the promotion as seen on the product detail page.',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 28,
      display: false
    },
    active_promo: {
      title: 'Promotions',
      type: 'String',
      page: 'graph',
      toolTipText: 'Value of the promotion as seen on the product detail page.',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 31
    },
    category_1: {
      title: 'Category 1',
      type: 'String',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 34,
      toolTipText:
        'First Best Seller Category node name which appears on Product details page as per most recent CIQ Market Intelligence Data. ',
      display: false
    },
    category_1_best_seller_rank: {
      title: 'Category 1 BSR',
      type: 'NUMERIC',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 35,
      toolTipText:
        'Best Seller Category rank in first node as per most recent CIQ Market Intelligence Data. ',
      display: false
    },
    category_2: {
      title: 'Category 2',
      type: 'String',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 36,
      toolTipText:
        'Second Best Seller Category node name which appears on Product details page as per most recent CIQ Market Intelligence Data. ',
      display: false
    },
    category_2_best_seller_rank: {
      title: 'Category 2 BSR',
      type: 'NUMERIC',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 37,
      toolTipText:
        'Best Seller Category rank in second node as per most recent CIQ Market Intelligence Data. ',
      display: false
    },
    category_3: {
      title: 'Category 3',
      type: 'String',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 38,
      toolTipText:
        'Third Best Seller Category node name which appears on Product details page as per most recent CIQ Market Intelligence Data. ',
      display: false
    },
    category_3_best_seller_rank: {
      title: 'Category 3 BSR',
      type: 'NUMERIC',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 39,
      toolTipText:
        'Best Seller Category rank in third node as per most recent CIQ Market Intelligence Data. ',
      display: false
    },
    category_4: {
      title: 'Category 4',
      type: 'String',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 40,
      toolTipText:
        'Fourth Best Seller Category node name which appears on Product details page as per most recent CIQ Market Intelligence Data. ',
      display: false
    },
    category_4_best_seller_rank: {
      title: 'Category 4 BSR',
      type: 'NUMERIC',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 41,
      toolTipText:
        'Best Seller Category rank in fourth node as per most recent CIQ Market Intelligence Data. ',
      display: false
    },
    avg_product_rating: {
      title: 'Avg product rating',
      type: 'NUMERIC',
      page: 'sku',
      toolTipText:
        'Current average rating on the product details page out of 5',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 31,
      display: false
    },
    total_review_count: {
      title: 'Total review count',
      type: 'NUMERIC',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 32,
      toolTipText:
        'No. of reviews on the product detail pages as per latest CIQ market intelligence data',
      display: false
    },
    new_review_count: {
      title: 'New review count',
      type: 'NUMERIC',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 33,
      toolTipText: 'No. of new reviews received during selected date range. ',
      display: false
    },
    length_of_title: {
      title: 'Title Length',
      type: 'NUMERIC',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 29,
      toolTipText: 'Character length of Title on Product details page',
      display: false
    },
    length_of_description: {
      title: 'Length of description',
      type: 'NUMERIC',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 30,
      toolTipText: 'Character length of description on Product details page',
      display: false
    },
    ordered_units: {
      title: 'Ordered Unit',
      toolTipText:
        'Number of units (paid and organic) ordered by customers in the given time period',
      type: 'NUMERIC',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 4,
      sku_keyOrder: 23,
      display: false
    },
    sub_category_rank: {
      title: 'Subcategory (Sales Rank)',
      toolTipText: 'Sales rank in a subcategory',
      type: 'NUMERIC',
      page: 'sku',
      sku_keyOrder: 24,
      display: false
    },
    lbb_rate: {
      title: 'LBB rate',
      toolTipText:
        'Lost buy box for the selected period is the percentage glance views where the SKU had lost the buy box. LBB across the catalog is calculated as the percentage of total LBB glance views across all SKUs out of the total glance views.',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 11,
      sku_keyOrder: 42,
      display: false
    },
    rep_oos: {
      title: 'Rep OOS',
      toolTipText:
        'Rep OOS% for the selected time period is the percentage of glance views where the SKUs were not available for sale. Rep OOS across the catalog is calculated as the percentage of total unavailable glance views across all SKUs out of the total glance views.',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: 'sku',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 11,
      sku_keyOrder: 43,
      display: false
    },
    change_in_traffic_percentage: {
      title: 'Change in Traffic %',
      type: 'NUMERIC',
      page: 'graph',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 11,
      sku_keyOrder: 6
    },
    change_in_conversion_percentage: {
      title: 'Change in Conversion',
      type: 'NUMERIC',
      page: 'graph',
      campaign_keyOrder: 5,
      keyword_keyOrder: 10,
      search_keyOrder: 11,
      sku_keyOrder: 6
    },
    attributedSales14dSameSKU: {
      title: 'Ad SKU Paid sales',
      unit: 'CURRENCY',
      type: 'NUMERIC',
      page: ['sku'],
      toolTipText:
        'Aggregate value of attributed sales occurring within of click on ad where the purchased SKU was the same as the one advertised.',
      campaign_keyOrder: 11,
      keyword_keyOrder: 14,
      search_keyOrder: 16,
      sku_keyOrder: 10,
      display: false
    },
    planned_budget_pace_ratio: {
      title: 'Planned budget pace ratio',
      unit: '',
      type: 'NUMERIC',
      page: ['campaign'],
      toolTipText: 'Ratio of the % Budget Utilization to the % Time Elapsed',
      campaign_keyOrder: 11,
      keyword_keyOrder: 14,
      search_keyOrder: 16,
      sku_keyOrder: 10,
      display: false
    },
    budget_utilization: {
      toolTipText:
        'The campaign budget that has been spent expressed as a percentage of the lifetime budget',
      unit: 'PERCENTAGE',
      type: 'NUMERIC',
      page: ['campaign'],
      title: '% Budget Utilization',
      campaign_keyOrder: 11,
      keyword_keyOrder: 14,
      search_keyOrder: 16,
      sku_keyOrder: 10,
      display: false
    },
    time_elapsed: {
      toolTipText:
        'The duration of the campaign that has elapsed expressed as a percentage of the total campaign duration',
      unit: 'PERCENTAGE',
      type: 'NUMERIC',
      page: ['campaign'],
      title: 'Time Elapsed',
      campaign_keyOrder: 11,
      keyword_keyOrder: 14,
      search_keyOrder: 16,
      sku_keyOrder: 10,
      display: false
    },
    linear_attributed_sales: {
      title: 'Attributed Sales (Linear)',
      toolTipText:
        '14-day attributed sales generated on the campaign in the selected time period using 14 day linear attribution method',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['sku', 'adGroups', 'keyword', 'campaign']
    },
    linear_attributed_quantities: {
      title: 'Units (Linear)',
      toolTipText:
        'Total number of units sold from the campaign in the selected time period using 14-day linear attribution method',
      type: 'NUMERIC',
      page: ['sku', 'adGroups', 'keyword', 'campaign']
    },
    linear_roas: {
      title: 'ROAS (Linear)',
      toolTipText:
        'Attributed sales generated for each dollar of ad spend for the selected time period calculated as attributed sales divided by ad spend, based on linear attribution model',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['sku', 'adGroups', 'keyword', 'campaign']
    },
    cvr: {
      title: 'CVR (Units_Last_Touch)',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      toolTipText:
        'Measure of how many clicks converted into orders calculated as orders divided by clicks based on Last Touch attribution',
      page: ['sku', 'adGroups', 'keyword', 'campaign']
    },
    linear_cvr: {
      title: 'CVR (Units_Linear)',
      unit: 'PERCENTAGE',
      toolTipText:
        'Measure of how many clicks converted into orders calculated as orders divided by clicks based on linear attribution',
      type: 'NUMERIC',
      page: ['sku', 'adGroups', 'keyword', 'campaign']
    },
    missed_auction_participation_rate: {
      title: 'Missed Auctions',
      toolTipText:
        'The percentage of auctions that your ad groups couldn’t participate in due to budget constraints',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['campaign']
    },
    estimated_missed_impressions: {
      title: 'Estimated Missed Impressions',
      toolTipText:
        'The estimated impressions your campaign couldn’t capture due to missed auctions, uncompetitive bids, or other factors',
      type: 'NUMERIC',
      page: ['campaign']
    },
    estimated_missed_sales: {
      title: 'Estimated Missed Sales',
      toolTipText:
        'The estimated sales your campaign has missed due to budget constraints or other external factors',
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['campaign']
    },
    linear_ntb_attributed_sales: {
      title: 'New-to-brand sales (Linear)',
      toolTipText:
        "Sales that came from consumers who haven't purchased the  brand within the last 26 weeks. This data is available from August 1, 2022, onward.",
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['sku', 'adGroups', 'keyword', 'campaign']
    },
    linear_percent_ntb_attributed_sales: {
      title: 'New-to-brand sales % (Linear)',
      toolTipText:
        'The percentage of all the sales that were new-to-brand sales. This data is available from August 1, 2022, onward.',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['sku', 'adGroups', 'keyword', 'campaign']
    },
    ntb_attributed_sales: {
      title: 'New-to-brand sales (Last Touch)',
      toolTipText:
        "Sales that came from consumers who haven't purchased from the brand within the last 26 weeks. This data is available from August 1, 2022, onward.",
      type: 'NUMERIC',
      unit: 'CURRENCY',
      page: ['sku', 'adGroups', 'keyword', 'campaign']
    },
    percent_ntb_attributed_sales: {
      title: 'New-to-brand sales % (Last Touch)',
      toolTipText:
        'The percentage of all the sales that were new-to-brand sales. This data is available from August 1, 2022, onward.',
      type: 'NUMERIC',
      unit: 'PERCENTAGE',
      page: ['sku', 'adGroups', 'keyword', 'campaign']
    }
  }
};
