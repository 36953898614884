import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import HttpService from '@/utils/services/http-service';
import activitytransformer from '@/utils/services/activity-data-transformer';
import { entityModeMap } from '@/components/ams/campaigns/constants.js';
import {
  realtimeTableData,
  mutateRealtimeState,
  timeSeriesDimensionValue,
  commonMetricDataUpdate,
  populateStreamChartData,
  transforPayloadForRealtime,
  transformToFinalWhereClause,
  fetchStreamData
} from '@/components/ams/campaigns/storeHelper.js';
import { cloneDeep } from 'lodash';

const state = {
  keywordMode: 'realtime',
  workbenchKeywordsData: {},
  workbenchKeywordsSelectedFilters: {
    date_range: {
      value: 'Last 30 days'
    }
  },
  streamKeywordsTableData: {
    arr: [],
    tableData: {},
    mutations: {}
  },
  streamKeywordsToggle: false,
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  showBulkActionsBannerKeywords: {
    visibility: false,
    text: ''
  },
  keywordsStreamMap: {},
  showKeywordsActivityLog: false,
  keyWordPrimaryKey: null,
  page_wise_min_max_key: 'ams_keyword',
  keywrodsRealTimeMetadata: {},
  timeSeriesRollUpBy: 'DAY',
  keywordStreamMode: false,
  enableKeywordsPVPStream: false,
  keywordStreamChartData: {},
  keywordsIsAllSelected: false
};

const getters = {
  getAmazonKeywordMode: (state) => state.keywordMode,
  getIsAllSelectedStateKeywords: (state) => {
    return state.keywordsIsAllSelected;
  },
  getCampaignsKeywordsWhereClause: (state) => state.whereClause,
  getWorkbenchKeywordsSelectedFilters: (state) => {
    return state.workbenchKeywordsSelectedFilters;
  },
  getStreamKeywordsTableData: (state) => {
    return state.streamKeywordsTableData;
  },
  getStreamKeywordsToggle: (state) => {
    return state.streamKeywordsToggle;
  },
  getWorkbenchKeywordsData: (state) => {
    return state.workbenchKeywordsData;
  },
  getShowKeywordsActivityLog: (state) => {
    return state.showKeywordsActivityLog;
  },
  getKeywordPrimaryKey: (state) => {
    return state.keyWordPrimaryKey;
  },
  getKeywordsPageWiseMinMaxKey() {
    return state.page_wise_min_max_key;
  },
  getKeywordsRealTimeMetadata: (state) => state.keywrodsRealTimeMetadata,
  getKeywordsRollUpByValue(state) {
    return state.timeSeriesRollUpBy;
  },
  getKeywordsStreamMap: (state) => state.keywordsStreamMap,
  getKeywordsStreamMode: (state) => state.keywordStreamMode,
  getKeywordsStreamChartData: (state) => state.keywordStreamChartData,
  getKeywordsYTDMode: (state) => state.enableKeywordsPVPStream
};

const mutations = {
  SET_AMAZON_KEYWORD_MODE: (state, data) => {
    state.keywordMode = entityModeMap[data];
  },
  SET_SHOW_BULK_ACTIONS_BANNER_KEYWORDS: (state, data) => {
    state.showBulkActionsBannerKeywords = data;
  },
  SET_IS_ALL_SELECTED_STATE_KEYWORDS: (state, data) => {
    state.keywordsIsAllSelected = data;
  },
  SET_KEYWORD_STREAM_CHART_MODE: (state, data) => {
    state.keywordStreamMode = data;
  },
  SET_KEYWORDS_PVP_STREAM: (state, data) => {
    state.enableKeywordsPVPStream = data;
  },
  SET_STREAM_KEYWORDS_TABLE_DATA: (state, data) => {
    state.streamKeywordsTableData = data;
  },
  SET_STREAM_KEYWORDS_TOGGLE: (state, data) => {
    state.streamKeywordsToggle = data;
  },
  IWKKEYWORDS_REALTIME_UPDATE: (state, data) => {
    mutateRealtimeState(state, data);
  },
  IWKKEYWORDS_STREAM_DATA: (state, data) => {
    state.keywordsStreamMap = data;
  },
  IWKKEYWORDS_SUCCESS: (state, data) => {
    Vue.set(state.workbenchKeywordsData, data.key, {});
    if (data.xs || data.legends) {
      Vue.set(state.workbenchKeywordsData[data.key], 'data', {
        data: data.columns,
        xs: null,
        legends: data.legends
      });
    } else {
      Vue.set(state.workbenchKeywordsData[data.key], 'data', data.columns);
    }
    Vue.set(state.workbenchKeywordsData[data.key], 'rows', data.rows);
    Vue.set(state.workbenchKeywordsData[data.key], 'totalRows', data.totalRows);
    Vue.set(state.workbenchKeywordsData[data.key], 'metrics', data.metrics);
    Vue.set(
      state.workbenchKeywordsData[data.key],
      'metricsUnits',
      data.metricsUnits
    );
    Vue.set(state.workbenchKeywordsData[data.key], 'page', data.page);
    Vue.set(state.workbenchKeywordsData[data.key], 'load', false);
    Vue.set(state.workbenchKeywordsData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.workbenchKeywordsData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.workbenchKeywordsData[data.key], 'noData', true);
    }
  },
  IWKKEYWORDS_RESET: (state, data) => {
    Vue.set(state.workbenchKeywordsData, data.key, {});
    Vue.set(state.workbenchKeywordsData[data.key], 'columns', []);
    Vue.set(state.workbenchKeywordsData[data.key], 'rows', []);
    Vue.set(state.workbenchKeywordsData[data.key], 'load', true);
    Vue.set(state.workbenchKeywordsData[data.key], 'error', false);
    Vue.set(state.workbenchKeywordsData[data.key], 'noData', false);
    Vue.set(state.workbenchKeywordsData[data.key], 'metrics', {});
  },
  IWKKEYWORDS_ERROR: (state, data) => {
    Vue.set(state.workbenchKeywordsData, data.key, {});
    Vue.set(state.workbenchKeywordsData[data.key], 'columns', []);
    Vue.set(state.workbenchKeywordsData[data.key], 'rows', []);
    Vue.set(state.workbenchKeywordsData[data.key], 'load', false);
    Vue.set(state.workbenchKeywordsData[data.key], 'error', true);
    Vue.set(state.workbenchKeywordsData[data.key], 'noData', false);
    Vue.set(state.workbenchKeywordsData[data.key], 'metrics', {});
  },
  IWKKEYWORDS_SET_SELECTED_FILTER: (state, data) => {
    var dateRange = state.workbenchKeywordsSelectedFilters.date_range;
    Vue.set(state, 'workbenchKeywordsSelectedFilters', data.values);
    if (!state.workbenchKeywordsSelectedFilters.date_range) {
      Vue.set(state.workbenchKeywordsSelectedFilters, 'date_range', dateRange);
    }
    getFiltersInFormatWKKeywords(data, state);
  },
  OPEN_KEYWORDS_ACTIVITY_LOG: (state) => {
    Vue.set(state, 'showKeywordsActivityLog', true);
  },
  CLOSE_KEYWORDS_ACTIVITY_LOG: (state) => {
    Vue.set(state, 'showKeywordsActivityLog', false);
  },
  CHANGE_KEYWORD_PRIMARYKEY: (state, key) => {
    Vue.set(state, 'keyWordPrimaryKey', key);
  },
  KEYWORDS_ROLL_UP_BY_CHANGE: (state, key) => {
    Vue.set(state, 'timeSeriesRollUpBy', key);
  },
  KEYWORD_STREAM_DATA_RESET: (state, data) => {
    state.keywordStreamChartData = {
      data: state.enableKeywordsPVPStream
        ? state.keywordStreamChartData.data
        : [],
      rows: [],
      load: true,
      error: false,
      noData: false,
      metrics: {}
    };
  },
  KEYWORD_STREAM_DATA_SUCCESS: (state, data) => {
    state.keywordStreamChartData = {
      data: data.columns,
      pvp_data: data.pvp_columns,
      xs: null,
      load: false,
      error: false,
      noData: false,
      legends: data.legends,
      metrics: data.metrics,
      metricsUnits: data.metricsUnits
    };
  },
  KEYWORD_STREAM_DATA_ERROR: (state, data) => {
    state.keywordStreamChartData = {
      columns: [],
      rows: [],
      load: false,
      error: true,
      noData: false,
      metrics: {}
    };
  }
};

function getFiltersInFormatWKKeywords(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      if (oFilter.values[i].length > 0 && oFilter.values[i][0].operator) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][0].value,
          operator: oFilter.values[i][0].operator.operator
        });
      } else {
        for (var j = 0; j < oFilter.values[i].length; j++) {
          where.dimensionNameValueList.push({
            dimensionName: i,
            dimensionValue: oFilter.values[i][j]
          });
        }
      }
    } else {
      var date = oFilter.values[i];
      var _date = {
        from: date.from,
        to: date.to,
        name: date.name,
        page_wise_min_max_key: state.page_wise_min_max_key
      };
      var _pvpDate;
      if (date.compare && date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to,
          compare_name: date.compare_name
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  state.whereClause = where;
}

function transformAndCommitChartData(context, data, response) {
  if (response.data.length > 0) {
    var xs = {};
    for (var i = 0; i < response.data.length; i++) {
      var aTemp = [];
      aTemp = transformer.getChartDataInFormat(
        data,
        response.data[0].TIMESERIES
      );
    }

    for (i = 0; i < aTemp.length; i++) {
      if (aTemp[i][0] !== 'report_date') {
        xs[aTemp[i][0]] = 'report_date';
      }
    }

    context.commit('IWKKEYWORDS_SUCCESS', {
      key: data.meta.key,
      columns: aTemp,
      xs,
      metrics: data.body.getTagData
        ? data.body.getTagData(response.data)
        : undefined,
      metricsUnits: data.body.getTagUnitData
        ? data.body.getTagUnitData(response.data)
        : {}
    });
  } else {
    context.commit('IWKKEYWORDS_SUCCESS', {
      key: data.meta.key,
      columns: [],
      metrics: {}
    });
  }
}

const actions = {
  setIsAllSelectedStateKeywords: (context, data) => {
    context.commit('SET_IS_ALL_SELECTED_STATE_KEYWORDS', data);
  },
  setShowBulkActionsBannerKeywords: (context, data) => {
    context.commit('SET_SHOW_BULK_ACTIONS_BANNER_KEYWORDS', data);
  },
  setKeywordsStreamMode: (context, data) => {
    context.commit('SET_KEYWORD_STREAM_CHART_MODE', data);
  },
  setKeywordsPVPRealtime: (context, data) => {
    context.commit('SET_KEYWORDS_PVP_STREAM', data);
  },
  setStreamKeywordsToggle: (context, data) => {
    context.commit('SET_STREAM_KEYWORDS_TOGGLE', data);
  },
  setKeywordsRollUpByChanges: (context, data) => {
    context.commit('KEYWORDS_ROLL_UP_BY_CHANGE', data);
  },
  setWorkbenchKeywordsSelectedFilters: (context, data) => {
    context.commit('IWKKEYWORDS_SET_SELECTED_FILTER', data);
  },
  getWorkbenchKeywordsTableData: (context, data) => {
    const mutations = {
      reset: 'IWKKEYWORDS_RESET',
      error: 'IWKKEYWORDS_ERROR',
      success: 'IWKKEYWORDS_SUCCESS',
      realtimeMetadata: 'IWKKEYWORDS_REALTIME_UPDATE',
      streamData: 'IWKKEYWORDS_STREAM_DATA'
    };
    realtimeTableData(context, data, mutations);
  },
  getWorkbenchKeywordsChartData: async (context, data) => {
    context.commit('IWKKEYWORDS_RESET', {
      key: data.meta.key
    });
    const finalWhereClause = transformer.getCompleteWhereClause(
      (data.meta.localFilters || []).concat(data.meta.plotSelections || []),
      context.state.whereClause
    );
    transformToFinalWhereClause({
      data,
      configCheck: Vue.options.filters.config_check('feature.campaignListFix'),
      finalWhereClause,
      context
    });
    data.body.APIConfig.pvpenabled =
      data.body.APIConfig.where.pvpDate !== undefined;
    data.body.APIConfig.timeseriesDimension =
      timeSeriesDimensionValue[context.state.timeSeriesRollUpBy];
    data.body.APIConfig.timeseriesRollUpBy = context.state.timeSeriesRollUpBy;

    commonMetricDataUpdate(
      data.body.APIConfig.bundleCubeExecutionRequest,
      'timeseriesDimension',
      [timeSeriesDimensionValue[context.state.timeSeriesRollUpBy]]
    );

    commonMetricDataUpdate(
      data.body.APIConfig.bundleCubeExecutionRequest,
      'timeseriesRollUpBy',
      [context.state.timeSeriesRollUpBy]
    );
    const streamCommitMessage = {
      SUCCESS: 'KEYWORD_STREAM_DATA_SUCCESS',
      RESET: 'KEYWORD_STREAM_DATA_RESET',
      ERROR: 'KEYWORD_STREAM_DATA_ERROR'
    };
    context.commit(streamCommitMessage.RESET, {
      key: data.meta.key
    });
    try {
      const response = await HttpLayer.post({
        cube: 'EXECUTE_CUBE_CHART_API',
        APIData: data.body.APIConfig
      });
      if (!response.success) {
        context.commit('IWKKEYWORDS_ERROR', {
          key: data.meta.key
        });
      } else {
        response.data[0].TIMESERIES = transformer.getChartRollUpMapping(
          response.data[0].TIMESERIES,
          timeSeriesDimensionValue[context.state.timeSeriesRollUpBy]
        );
        transformAndCommitChartData(context, data, response);
      }
      const streamChartAPIPayload = transforPayloadForRealtime(
        cloneDeep(data.body.SreamAPIConfig),
        context.state,
        data,
        'Keywords'
      );
      if (context.getters.getStreamKeywordsToggle) {
        await populateStreamChartData(
          data,
          context,
          streamChartAPIPayload,
          streamCommitMessage,
          state.enableKeywordsPVPStream,
          'Keywords'
        );
      }
    } catch (error) {
      console.error(error);
    }
  },

  getWorkbenchKeywordsTableStreamData: async (context) => {
    if (
      context.getters.getStreamKeywordsTableData &&
      context.getters.getStreamKeywordsToggle
    ) {
      const { arr, tableData, mutations } =
        context.getters.getStreamKeywordsTableData;
      try {
        await fetchStreamData(context, tableData, arr, mutations);
      } catch (error) {
        console.error(error);
      }
    }
  },

  keywordActionApi: (context, data) => {
    return HttpService.post('AMS_ACTIONS', data);
  },

  getKeywordsActivityLog: (context, data) => {
    context.commit('ACTIVITY_LOG_RESET', {
      key: 'keyWordsAcitivityLog'
    });
    context.commit('CHANGE_KEYWORD_PRIMARYKEY', data.primaryKey);
    HttpService.get('AMS_ACTIONS', {
      append: '?widget=keyword&primaryKey=' + data.primaryKey
    }).then((response) => {
      if (response.data && !response.data.success) {
        context.commit('ACTIVITY_LOG_ERROR', {
          key: 'keyWordsAcitivityLog',
          rows: []
        });
      } else {
        context.commit('ACTIVITY_LOG_SUCCESS', {
          key: 'keyWordsAcitivityLog',
          rows: activitytransformer.getActivityData(response.data.response)
        });
      }
    });
  },

  openKeywordsActivityLog: (context) => {
    context.commit('OPEN_KEYWORDS_ACTIVITY_LOG');
  },

  closeKeywordsActivityLog: (context) => {
    context.commit('CLOSE_KEYWORDS_ACTIVITY_LOG');
    context.commit('CHANGE_KEYWORD_PRIMARYKEY', null);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
