<template>
  <div>
    <div>
      <span
        class="u-font-size-5 u-font-weight-600 u-color-grey-base"
        data-cy="biddingStrategyHeader"
        >Bidding strategy:</span
      >
      <div
        v-if="!isCreateAdGroupFlow"
        class="u-width-60"
        :class="{ 'u-display-flex': step.boolAdjustHalfFlex }"
      >
        <div
          :class="{
            'u-flex-2 u-spacing-pr-s': step.boolAdjustHalfFlex,
            'u-border-right u-border-color-grey-xxx-light': showBidAdjustment
          }"
        >
          <rbRadioGroup
            :radio-options="biddingStrategy"
            :desc-class="'u-line-height-1_6'"
            :container-class="radioContainerClass"
            :value="selectedValue"
            :is-column="true"
            data-cy="radioGroupSublabel"
            @onRadioChanged="handleRadioSelection"
          />
        </div>
        <!-- For SD this for "reach" strategy bid ajustment is there as per the mock -->
        <div
          v-if="step.boolAdjustHalfFlex"
          class="u-flex-3 u-display-flex u-flex-align-items-center u-font-size-3 u-spacing-pl-xxl"
        >
          <div
            v-if="showBidAdjustment"
            class="u-display-flex"
          >
            <div>
              <p
                class="u-font-size-6 u-spacing-pb-s u-font-weight-600 u-color-grey-base"
              >
                Default bid:
              </p>
              <rbNumberInput
                :options="bidOptions"
                @handleInput="updateBid"
                @handleValue="updateBid"
              />
            </div>
            <div class="u-spacing-mt-l">
              <span class="u-font-size-7 u-spacing-pl-s u-font-weight-400">
                Cost per 1,000 viewable impressions.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="u-font-size-5 u-spacing-mt-m"
      >
        <div>
          {{ currentBiddingStrategyForAdGroupCreation.label }}
        </div>
        <div class="u-spacing-mt-s u-width-50">
          {{ currentBiddingStrategyForAdGroupCreation.desc }}
        </div>
      </div>
    </div>
    <section class="u-display-flex u-spacing-mt-s" />
  </div>
</template>

<script>
import rbRadioGroup from '@/components/ams/campaign_creation/steps/common/rbRadioGroupSubLabel.vue';
import rbNumberInput from '@/components/widgets/rbNumberInput';
import Vue from 'vue';
export default {
  components: {
    rbRadioGroup,
    rbNumberInput
  },
  props: {
    defaultSelection: {
      type: String,
      default: 'legacyForSales'
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    },
    step: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  data: () => ({
    radioContainerClass: 'global-radio-wrapper u-spacing-mr-l u-spacing-mv-m',
    biddingStrategy: [
      {
        type: 'legacyForSales',
        label: 'Dynamic bids - down only',
        desc: "We'll lower your bids in real time when your ad may be less likely to convert to a sale. Any campaigns created before January 2019 used this setting."
      },
      {
        type: 'autoForSales',
        label: 'Dynamic bids - up and down',
        desc: "We'll raise your bids (by a maximum of 100%) in real time when your ad may be more likely to convert to a sale, and lower your bids when less likely to convert to a sale."
      },
      {
        type: 'manual',
        label: 'Fixed bids',
        desc: "We'll use your exact bid and any manual adjustments you set, and won't change your bids based on likelihood of a sale."
      }
    ],
    selectedValue: 'legacyForSales',
    bidOptions: {
      prefix: Vue.prototype.$currency,
      value: 1
    },
    showBidAdjustment: false
  }),
  computed: {
    isCreateAdGroupFlow() {
      if (this.$route.name === 'create_ad_group') {
        return true;
      } else {
        return false;
      }
    },
    currentBiddingStrategyForAdGroupCreation() {
      let biddingStrategy = this.biddingStrategy[0];
      const filteredBiddingStrategy = this.biddingStrategy.filter(
        (item) => item.type === this.defaultSelection
      );
      if (filteredBiddingStrategy.length > 0) {
        biddingStrategy = filteredBiddingStrategy[0];
      }
      return biddingStrategy;
    }
  },
  created() {
    this.selectedValue = this.defaultSelection;
    if (this?.step?.biddingStrategy) {
      this.biddingStrategy = this.step.biddingStrategy;
      this.bidOptions.value = this.step?.bidAdjustment?.value || 1;
      this.handleRadioSelection(this.selectedValue);
    }
  },
  methods: {
    handleRadioSelection(value) {
      // to display bid adjust inputbox at the right of the strategies for SD campaigns
      if (this?.step?.boolAdjustHalfFlex) {
        this.showBidAdjustment = value === this?.step?.showAdjustBidForStrategy;
      }
      this.$emit('selectedBiddingStrategy', value);
    },
    updateBid(value) {
      this.$emit('updateBid', value);
    }
  }
};
</script>
