<template>
  <div class="u-display-flex u-flex-align-items-center u-width-100">
    <div
      v-tippy="sortTooltipOptions"
      class="u-position-relative u-cursor-pointer"
    >
      <span
        v-if="Object.keys(sortingSelected).length"
        class="u-font-size-7 u-display-flex u-flex-align-items-center u-spacing-pv-xxs u-spacing-ph-s"
        @click="showSortingPopup = true"
      >
        <rb-icon
          icon="sort"
          class="rb-icon rb-icon--small u-spacing-mr-xs"
        />
        <span class="u-color-grey-mid-light"> Sort by: </span>
        <span class="u-font-weight-600 u-spacing-mh-xs">
          {{ getSortingDisplayText }}
        </span>
        <rb-icon
          :icon="'caret-down'"
          class="rb-icon--small u-color-grey-lighter u-cursor-pointer u-spacing-pl-s u-spacing-pr-m"
        />
        <rb-icon
          v-for="icon in sortingSelected.icons"
          :key="icon"
          :icon="icon"
          class="u-color-blue-base rb-icon--small"
        />
      </span>
      <div id="sorting-popup">
        <popupComponent
          :show-popup="showSortingPopup"
          class="popup-component"
          :header-title="showAdvancedSortingPopup ? metricClicked : 'Sort by'"
          :first-button-config="{ show: false }"
          :second-button-config="{ show: false }"
          :popup-class="'u-position-relative'"
          @headerIconClickFn="
            (showSortingPopup = false), (showAdvancedSortingPopup = false)
          "
        >
          <div
            v-show="!showAdvancedSortingPopup"
            slot="body"
            class="u-spacing-mv-s"
          >
            <span
              v-for="(option, index) in metricList"
              :key="index"
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-color-grey-light u-font-weight-normal"
              @click="handleMetricSelected(option)"
            >
              <span
                class="u-flex-1 u-font-size-5 u-spacing-pl-m u-spacing-pv-s u-cursor-pointer hover-effect"
                >{{ option.label }}
              </span>
            </span>
          </div>
          <div
            v-show="showAdvancedSortingPopup"
            slot="body"
            class="u-spacing-m-m"
          >
            <span
              v-for="(option, index) in getSortingOptions"
              :key="index"
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-mb-s u-color-grey-light u-font-weight-normal"
              @click.prevent="handleSortOrderSelection(option)"
            >
              <rb-radio
                v-model="selectedMetricSort"
                class="u-flex-1"
                :native-value="option.label"
              >
                {{ option.label }}
              </rb-radio>
              <span>
                <rb-icon
                  v-for="icon in option.icons"
                  :key="icon"
                  :icon="icon"
                  class="u-color-grey-x-light rb-icon--small"
                />
              </span>
            </span>
          </div>
        </popupComponent>
      </div>
    </div>
  </div>
</template>
<script>
import popupComponent from '@/components/basic/dialog/popupComponent.vue';
export default {
  components: {
    popupComponent
  },
  props: {
    enablePvpLevelSort: {
      type: Boolean,
      default: true
    },
    metricList: {
      type: Array,
      required: true
    },
    sortConfiguration: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      sortingSelected: this.sortConfiguration.sortingOptions,
      metricClicked: this.sortConfiguration.metric,
      selectedMetricWithSort: this.sortConfiguration.metric,
      showSortingPopup: false,
      showAdvancedSortingPopup: false,
      sortingOptions: [
        {
          label: 'Absolute Descending',
          icons: ['sort-desc'],
          direction: 'DESC'
        },
        {
          label: 'Absolute Ascending',
          icons: ['sort-asc'],
          direction: 'ASC'
        }
      ],
      pvpSortingOptions: [
        {
          label: 'Change Descending',
          icons: ['change', 'sort-desc'],
          sortByChange: true,
          direction: 'DESC'
        },
        {
          label: 'Change Ascending',
          icons: ['change', 'sort-asc'],
          sortByChange: true,
          direction: 'ASC'
        }
      ],
      selectedSorting: ''
    };
  },
  computed: {
    selectedMetricSort: {
      get() {
        if (this.metricClicked === this.sortConfiguration.metric) {
          return this.sortConfiguration.sortingOptions.label;
        }
      },
      set(newValue) {
        this.selectedSorting = newValue;
      }
    },
    sortTooltipOptions() {
      return {
        html: '#sorting-popup',
        reactive: true,
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        onShow: this.onShow,
        onHide: this.onHide
      };
    },
    getSortingDisplayText() {
      return this.selectedMetricWithSort;
    },
    getSortingOptions() {
      let updatedSortingOptions = [...this.sortingOptions];
      if (this.enablePvpLevelSort) {
        updatedSortingOptions.push(...this.pvpSortingOptions);
      }
      return updatedSortingOptions;
    }
  },
  methods: {
    handleMetricSelected(option) {
      this.metricClicked = option.label;
      this.showAdvancedSortingPopup = true;
      this.tippyInstance.show();
    },
    handleSortOrderSelection(option) {
      this.showSortingPopup = false;
      this.showAdvancedSortingPopup = false;
      this.selectedMetricWithSort = this.metricClicked;
      this.sortingSelected = option;
      this.handleMetricSortSelection();
      this.tippyInstance.hide();
    },
    handleMetricSortSelection() {
      const metricSortSelected = {
        metric: this.selectedMetricWithSort,
        sortingOptions: this.sortingSelected
      };
      this.$emit('metricSortSelected', metricSortSelected);
    },
    onShow(instance) {
      this.tippyInstance = instance;
    },
    onHide() {
      this.showSortingPopup = false;
      this.showAdvancedSortingPopup = false;
    }
  }
};
</script>
<style lang="css">
.hover-effect:hover {
  background-color: #007cf6;
  color: #fff;
}

.popup-component {
  box-shadow: none !important;
}
</style>
