<template>
  <section
    class="u-display-flex u-flex-direction-column"
    :class="{ 'u-height-100': isLoading }"
  >
    <section
      class="filterSection sticky u-spacing-mb-0 z-index-2 u-header-box-shadow"
    >
      <div class="u-flex-0 u-bg-color-grey-white u-spacing-ph-l">
        <h2
          class="u-font-size-2 u-spacing-mb-l u-color-grey-base u-font-weight-bold"
        >
          Recommendations
        </h2>
      </div>
    </section>
    <section
      class="alretSection u-spacing-ph-m u-flex-1 card u-envelop-width u-width-100 u-box-shadow-none"
      :class="{ 'u-position-relative': isLoading }"
    >
      <div class="u-display-flex u-flex-direction-column">
        <section class="u-spacing-mt-l">
          <h3
            class="u-font-size-3 u-font-weight-600 u-text-transform-uppercase u-color-grey-lighter u-spacing-m-0 u-display-flex"
            :class="{
              'u-opacity-60': computedBookmarkedAlerts.length === 0,
              'u-opacity-1': computedBookmarkedAlerts.length !== 0
            }"
          >
            Added to &nbsp;<span class="u-color-orange-base u-spacing-ml-xs"
              >&nbsp; My Workspace</span
            >
          </h3>
          <div
            v-if="computedBookmarkedAlerts.length === 0"
            class="no_bookmark u-spacing-mt-m"
          >
            <div class="no_bookmark_img_wrapper">
              <img
                src="/images/empty_bookmark.svg"
                alt="empty bookmark"
                class="u-width-100 u-height-100"
              />
            </div>
            <p
              class="u-font-size-7 u-spacing-grey-lighter u-font-weight-600 u-display-flex u-flex-justify-content-center u-spacing-mt-m u-color-grey-lighter"
            >
              Star your frequently used recommendation types to view them here.
            </p>
          </div>
          <div
            v-if="computedBookmarkedAlerts.length !== 0"
            class="u-width-100 u-display-flex u-flex-align-items-strech u-flex-wrap-yes u-flex-justify-content-start u-spacing-mt-m"
          >
            <div
              v-for="(elem, index) in computedBookmarkedAlerts"
              :key="elem && elem.recommendationLabel"
              :class="{
                'u-spacing-mr-m': index === 0 ? true : index % 3 !== 0
              }"
              class="cardSection u-display-flex u-flex-direction-column u-flex-align-items-flex-start u-spacing-mb-m"
              style="width: calc(25% - 1.2rem)"
              @click="handleNavigation(elem)"
            >
              <div
                class="u-display-flex u-flex-direction-row u-flex-justify-content-space-between u-spacing-p-m u-width-100"
              >
                <div
                  class="u-font-size-5 u-font-weight-600 u-color-grey-base u-display-flex u-flex-align-items-center u-width-100"
                >
                  <Bookmark
                    :path="bookmarkPath(elem)"
                    :title="elem.label"
                    :page-id="elem.pageId"
                    :icon-class="'rb-icon--xx-medium u-height-100'"
                    :wrapper-class="'u-spacing-mr-xs'"
                  />
                  <div
                    class="u-height-100 u-flex-align-items-center u-spacing-pt-xs u-spacing-mr-xs u-flex-1 u-ellipsis u-text-transform-capitalize"
                  >
                    {{ elem.label }}
                  </div>
                  <!-- <div class="right_side u-display-flex u-flex-align-items-center">
                    <rb-tooltip-header
                      id="tooltipTemplate"
                      :title="elem.label"
                      :message="elem.description"
                    />
                    <rb-icon
                      v-tippy="setTooltip.tippyOptions"
                      class="u-color-grey-lighter rb-icon--medium"
                      icon="info-circle-fill"
                    />
                  </div> -->
                </div>
              </div>

              <!-- <div
                class="u-display-flex u-flex-justify-content-space-between u-spacing-ph-m u-spacing-pb-m u-width-100"
              >
                <Tag
                  :tag-color="recommendationGroupColorMapping[elem.recommendationGroup]"
                  :title="elem.type"
                  :tag-font-color="'grey-base'"
                  :extra-wrp-class="'u-spacing-mr-0 u-spacing-extra-xs u-border-radius-xl'"
                />
              </div> -->
            </div>
          </div>
        </section>
        <section class="u-spacing-pt-m">
          <div
            v-if="recommendationList.length > 0"
            class="u-spacing-mt-m recommendation_card_wrapper u-spacing-mb-l"
          >
            <div
              v-for="elem in recommendationList"
              :key="elem && elem.label"
              class="recommendation_card u-flex-direction-column u-spacing-mb-m u-flex-justify-content-space-between"
              @click="handleNavigation(elem)"
            >
              <div
                class="u-display-flex u-flex-direction-row u-flex-justify-content-space-between u-spacing-p-m"
              >
                <div
                  class="u-font-size-5 u-font-weight-600 u-color-grey-base u-display-flex u-flex-align-items-center"
                >
                  <Bookmark
                    :path="bookmarkPath(elem)"
                    :title="elem.label"
                    :page-id="elem.pageId"
                    :icon-class="'rb-icon--xx-medium u-height-100'"
                    :wrapper-class="'u-spacing-mr-xs'"
                  />
                  <div
                    class="u-display-flex u-height-100 u-flex-align-items-center u-spacing-mt-xxs"
                  >
                    {{ elem.label }}
                  </div>
                </div>
                <!-- <div class="right_side u-display-flex u-flex-align-items-center">
                  <rb-tooltip-header
                    id="tooltipTemplate"
                    :title="elem.label"
                    :message="elem.description"
                  />
                  <rb-icon
                    v-tippy="setTooltip.tippyOptions"
                    class="u-color-grey-lighter u-spacing-mh-s rb-icon--medium"
                    icon="info-circle-fill"
                  />
                </div> -->
              </div>
              <div
                v-if="elem.collapse"
                class="top_alerts_wrap1 u-spacing-ph-m u-spacing-pv-l"
                style="background-color: rgba(0, 124, 246, 0.02)"
              >
                <div
                  class="u-font-size-6 u-font-weight-600 u-color-grey-light u-spacing-mb-m"
                >
                  {{ recomdetails.colum }}
                </div>
                <div
                  v-for="(item, itemIndex) in [1, 2, 3]"
                  :key="item"
                  class="u-overflow-hidden"
                  :class="{
                    'u-spacing-mb-m': itemIndex !== [1, 2, 3].length - 1
                  }"
                >
                  <rb-sku
                    class="u-text-overflow-ellipsis"
                    :title="recomdetails.title"
                    :image-url="recomdetails['image-url']"
                    :asin="recomdetails.asin"
                    :product-page-url="recomdetails['product-page-url']"
                  >
                    <div
                      slot="sku-context"
                      class="u-display-flex"
                    >
                      <!-- <rb-icon
                      class="rb-icon--medium u-color-blue-base rb-icon-14"
                      icon="bell"
                    /><span>recommendations</span> -->
                    </div>
                  </rb-sku>
                </div>
              </div>
              <!-- <div
                class="u-display-flex u-flex-justify-content-flex-start u-spacing-ph-m u-spacing-pb-m"
              >
                <Tag
                  :tag-color="recommendationGroupColorMapping[elem.recommendationGroup]"
                  :title="elem.type"
                  :tag-font-color="'grey-base'"
                  :extra-wrp-class="'u-spacing-mr-0 u-spacing-extra-xs u-border-radius-xl'"
                /> -->
              <!-- <p class="chipRound u-font-size-7 u-color-grey-base">
                    Subscribe
                  </p> -->
              <!-- </div> -->
            </div>
          </div>
          <div
            v-if="recommendationList.length === 0"
            class="u-font-size-5 u-font-weight-600 u-width-100 u-spacing-mb-xxl u-height-200 u-display-flex u-flex-align-items-center u-flex-justify-content-center"
          >
            <p class="u-font-size-3 u-text-align-center">
              No Recommendations found
            </p>
          </div>
        </section>
        <div>
          <Loader
            v-if="isLoading"
            class="fill--parent"
            :color="'#3fadf7'"
            :size="'4rem'"
            :thickness="'.2rem'"
          />
        </div>
        <div
          v-if="!isLoading && showError"
          class="u-display-flex u-spacing-p-m u-flex-justify-content-center u-flex-align-items-center u-flex-direction-column"
        >
          <div class="u-font-size-3 u-spacing-mt-xl u-font-weight-600">
            <p class="u-color-grey-base u-spacing-mr-s">
              Something went wrong!!!. Try again later
            </p>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import widgetsMixin from '@/components/widgetsMixin';
import rbFilterPanel from '@/components/widgets/filter-panel';
import Vue from 'vue';
// import { eventBus } from '@/utils/services/eventBus';
// import { alertDescription } from '@/pages/recommendations/recommendationsLanding/alertDescription.js';
import Loader from '@/components/basic/loader.vue';
import splitTabs from '@/components/widgets/splitTabs';
import RecommendationsTypeTabs from '@/components/widgets/recommendationsTypeTabs.vue';
import iconTextActions from '@/components/widgets/iconTextActions.vue';
import Tag from '@/components/widgets/tag';
import searchBox from '@/components/widgets/searchBox.vue';
import recommendationList from '@/pages/recommendations/recommendationsV2/recommendationsList.json';

export default {
  name: 'Alerts',
  author: 'Rajan V',
  components: {
    rbFilterPanel,
    Loader,
    splitTabs,
    RecommendationsTypeTabs,
    iconTextActions,
    Tag,
    searchBox
  },
  mixins: [widgetsMixin],
  data() {
    return {
      filterV2:
        Vue.options.filters.config_check('feature.filter_v2.enable') || false,
      recommendationList,
      isLoading: false,
      alertList: [],
      showError: false,
      recommendationGroupColorMapping: {
        Operations: 'rgba(151, 204, 4, 0.1)',
        Sales: 'rgba(189, 16, 224, 0.1)',
        Marketing: 'rgba(35, 181, 211, 0.1)'
      },
      searchText: ''
    };
  },
  computed: {
    setTooltip() {
      return {
        id: 'tooltipTemplate',
        tippyOptions: {
          html: '#' + 'tooltipTemplate',
          theme: 'rb-tooltip-header',
          placement: 'bottom',
          interactive: true,
          reactive: true,
          arrow: false
        }
      };
    },
    computedBookmarkedAlerts() {
      const list = this.$store.getters['bookmarkStore/getAllBookmarks'];
      const bookmarkedAlert = this.recommendationList.filter((recom) => {
        const index = list.findIndex(
          (listItem) => listItem.path === this.bookmarkPath(recom)
        );
        if (index !== -1) {
          return recom;
        }
        return false;
      });
      return bookmarkedAlert;
    }
  },
  created() {
    const recommendationConfig = Vue.options.filters.config_check(
      'feature.walmart.recommendations.enabled'
    );
    const enabledRecom = recommendationConfig?.split(',');
    if (enabledRecom?.length) {
      this.recommendationList = this.recommendationList.filter((recom) => {
        return enabledRecom.find((e) => recom.name.includes(e));
      });
    }
  },
  methods: {
    handleNavigation(alert) {
      const path = alert.name.split('walmart_recommendation_')[1];
      this.$router.push({
        path: `recommendations/${path}`
      });
    },
    bookmarkPath(elem) {
      const path = elem.name.split('walmart_recommendation_')[1];
      return `${this.$route.path}/${path}`;
    }
  }
};
</script>

<style lang="css" scoped>
.alert-card {
  border-radius: 4px;
  box-shadow: 0 1px 6px 0 rgba(43, 51, 59, 0.15);
  background-color: #ffffff;
  cursor: pointer;
}

.u-spacing-mt-40 {
  margin-top: 40px;
}

.u-width-23 {
  width: 23.8%;
}

.chipRound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 26px;
  background-color: rgba(0, 124, 246, 0.1);
  width: fit-content;
}

.top_alerts_wrap {
  max-width: 400px;
}

.container {
  width: 100%;
}

.recommendation_card_wrapper {
  columns: calc(35vw);
  column-gap: 20px;
}

.recommendation_card {
  break-inside: avoid-column;
  flex: 1 auto;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 #e9eaeb;
  border-bottom: 1px solid rgba(43, 51, 59, 0.05);
  border-top: 1px solid rgba(43, 51, 59, 0.05);
  background-color: #ffffff;
  cursor: pointer;
}

.cardHoverShown {
  display: none;
}

.recommendation_card:hover,
.cardSection:hover {
  box-shadow: 0 1px 8px 0 rgba(43, 51, 59, 0.15) !important;
}
.recommendation_card:hover .cardHoverShown,
.cardSection:hover .cardHoverShown {
  display: inline-block;
}
.recommendation_card:hover .cardHoverHidden,
.cardSection:hover .cardHoverHidden {
  display: none;
}

.cardSection {
  box-shadow: 0 1px 4px 0 #e9eaeb;
  border-radius: 4px;
  background-color: #ffffff;
  cursor: pointer;
}

.no_bookmark_img_wrapper {
  margin: 0 auto;
  width: 240px;
  height: 160px;
}

.u-opacity-60 {
  opacity: 0.6;
}

.u-spacing-extra-xs {
  padding-top: 4px;
  padding-bottom: 4px;
}

.u-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.u-height-200 {
  height: 200px;
}

.alretSection {
  background-color: #f5f7fa;
}

.u-box-shadow-none {
  box-shadow: none;
}

.u-text-transform-capitalize {
  text-transform: capitalize;
}
</style>

<style lang="css">
.recommendation-group .split-tabs {
  background-color: #fff;
}
</style>
