import {
  keywordRequest,
  negativeKeywordRequest
} from '@/components/ams/campaign_creation/walmart_steps/walmartRequestTemplate.js';

export default {
  requestObject: {
    campaignId: ':campaignId',
    adgroupId: ':adGroupId',
    state: ':state',
    advertiserId: ':advertiserId',
    adgroupType: ':adgroupType',
    request: {
      campaignRequest: {},
      adGroupRequest: {
        campaignId: ':campaignId',
        name: ':adGroupName',
        state: 'enabled',
        adGroupId: ':adGroupId'
      },
      productRequest: [],
      keywordRequest: [],
      negativeKeywordRequest: [],
      creativeRequest: [],
      reviewRequest: {
        campaignId: null,
        adGroupId: null,
        reviewId: null
      },
      campaignPlacementRequest: [],
      placementBidMultiplierRequest: [],
      platformBidMultiplierRequest: []
    }
  },
  productRequest: {
    adGroupId: ':adGroupId',
    campaignId: ':campaignId',
    imageUrl: ':imageUrl',
    productUrl: ':productUrl',
    productTitle: ':productTitle',
    state: 'enabled',
    sku: ':sku',
    bid: ':productBid'
  },
  keywordRequest: keywordRequest,
  negativeKeywordRequest: negativeKeywordRequest
};
