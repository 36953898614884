<template>
  <div
    v-if="searchTermsTableService"
    class="v2-forecast"
  >
    <div
      v-if="computedError"
      class="u-min-height-300 u-height-100 u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
    >
      <p>Error Occurred. Please try again later</p>
    </div>
    <div
      v-else-if="computedLoading"
      class="u-min-height-300 u-display-flex u-flex-direction-row u-height-100 u-width-100 u-flex-justify-content-center u-flex-align-items-center"
    >
      <loader
        class="u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </div>
    <div v-show="showSearchTermsTable && !computedLoading && !computedError">
      <TopContributorsTab
        :key="tabKey"
        class="u-spacing-mb-m u-spacing-ml-m"
        :button-options="buttonOptions"
        @buttonChanged="renderTable"
      />
      <rb-insights-table
        :table-column="searchTableColDef"
        :table-row="searchTermsTableData"
        sorting-change-event="sovSortEvent"
        :grid-options="searchTermsTableGridOptions"
      />
    </div>
  </div>
</template>

<script>
import SearchTermsTableService from '@/components/pages/insights/amazon/cva/services/combinedSovAssortment/SearchTerms';
import loader from '@/components/basic/loader';
import { cloneDeep } from 'lodash';
import {
  isCloseDC,
  getUpdatedTrendOptions,
  CONSTANTS,
  isAssortment
} from '@/components/pages/insights/amazon/cva/utils';
import TopContributorsTab from '@/components/pages/insights/amazon/shortage-invoices/TopContributorsTab.vue';

export default {
  name: 'CombinedSovAssortment',
  components: {
    loader,
    TopContributorsTab
  },
  props: {
    selectedWidget: {
      type: String,
      default: 'Brand View'
    },
    pageSettings: {
      type: Object,
      default: {}
    },
    service: {
      type: Object,
      required: true
    },
    clickedDimension: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      selectedChildTab: 'Organic',
      searchTermsTableGridOptions: {
        suppressColumnVirtualisation: true,
        context: {
          componentParent: this
        }
      },
      searchTermsTableService: null,
      childTabMapping: {
        Organic: 'organic_sov_rhs'
      },
      buttonOptions: CONSTANTS.buttonOptions,
      tabKey: 0,
      isAssortment: isAssortment(this.clickedDimension.name)
    };
  },
  computed: {
    showSearchTermsTable() {
      return this.selectedWidget === 'Search Terms';
    },
    computedLoading() {
      if (this.showSearchTermsTable) {
        return (
          this.searchTermsTableService?.metadata.loading ||
          this.searchTermsTableService.loading ||
          this.service?.loading
        );
      }
    },
    computedError() {
      if (this.showSearchTermsTable) {
        return (
          this.searchTermsTableService?.metadata.error ||
          this.searchTermsTableService.error
        );
      }
    },
    searchTableColDef() {
      return this.searchTermsTableService?.columns || [];
    },
    searchTermsTableData() {
      this.searchTermsTableGridOptions?.api?.sizeColumnsToFit();
      return this.searchTermsTableService?.getTableData() || [];
    }
  },
  watch: {
    'service.availableDimension': {
      handler(newVal, oldVal) {
        if (oldVal && newVal) {
          isCloseDC(newVal, this.clickedDimension.name);
        }
      }
    }
  },
  mounted() {
    this.searchTermsTableService = new SearchTermsTableService(
      'cva',
      'table2',
      459,
      100,
      this.clickedDimension
    );
    this.selectedChildTab = this.defaultTab;
    this.searchTermsTableService.computeColumnDefs();
    this.searchTermsTableService.fetchData(
      this.pageSettings,
      this.selectedChildTab
    );
    this.resetSelectedTab();
    this.fetchLiner();
  },
  methods: {
    async fetchLiner() {
      const widgetSettings = cloneDeep(this.pageSettings);
      const dbInsight = await this.searchTermsTableService.fetchInsights(
        isAssortment(this.clickedDimension.name)
          ? ['Double click Assortment']
          : ['Double click SoV'],
        widgetSettings
      );
      this.$emit('fetchLiner', dbInsight.data.entityData[0]?.entityValue);
    },
    renderTable(selectedButton) {
      this.searchTermsTableService.fetchData(
        this.pageSettings,
        this.selectedChildTab,
        selectedButton
      );
      this.resetSelectedTab(selectedButton);
    },
    resetSelectedTab(selectedButton = CONSTANTS.defaultTrendSelection) {
      this.buttonOptions = getUpdatedTrendOptions(
        this.buttonOptions,
        selectedButton
      );
      this.tabKey++;
    },
    handleBrandViewTabsClick() {},
    handleSearchTermsTabsClick() {}
  }
};
</script>
