







// TODO: @rhitik.b strictly type the variables in this file
import Vue, { PropType } from 'vue';
import { InputParams } from '../types/pie-chart-widget';
// @ts-ignore
import chart from '@/components/basic/chart';
import { computedStyles } from '@/components/pages/insights/amazon/market-share/utils';
// @ts-ignore
import { formatter } from '@/utils/helpers/formatter.js';

export default Vue.extend({
  name: 'PieChartWidget',
  components: {
    chart
  },
  props: {
    chartData: {
      type: [Object, Array],
      default: () => []
    },
    externalColorMaps: {
      type: Object,
      default: () => ({})
    },
    popupTitle: {
      type: String,
      default: null
    },
    isTitleClickable: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'm'
    },
    // eslint-disable-next-line vue/require-default-prop
    color: {
      type: Function
    },
    popupMetadata: {
      type: Object,
      default: () => ({})
    },
    interactionEnabled: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: 24
    }
  },
  data: () => {
    return {
      formattedChartData: {} as
        | unknown[]
        | { colors: unknown; color: Function | null },
      inputParams: undefined as unknown as InputParams
    };
  },
  computed: {
    computedStyles: function (): {
      legendsMinHeight?: string;
      legendSize?: string;
      legendDonutSpacing?: string;
      donutDimensions?: {
        height: number;
        width: number;
      };
    } {
      return computedStyles(this.size);
    },
    chartConfig(): unknown {
      const that = this;
      const val = {
        interaction: {
          enabled: this.interactionEnabled
        },
        chartOptions: {
          legend: false,
          type: 'donut',
          tooltip_format: {
            roundOff: 0
          },
          data: {
            color: that.color !== undefined ? that.color : null,
            colors: this.externalColorMaps
          },
          size: this.computedStyles.donutDimensions,
          tooltip: {
            contents: function (
              d: { id: string }[],
              defaultTitleFormat: unknown,
              defaultValueFormat: unknown,
              color: unknown
            ) {
              const popupMetadataId = d[0].id;
              const popupData = that.popupMetadata[popupMetadataId];
              let titleHtml = '';
              let coloredHtml = '';
              let uncoloredHtml = '';
              if (that.popupTitle) {
                titleHtml = `<section class="u-spacing-pt-m u-spacing-pb-m u-spacing-ph-m u-font-size-3 u-color-grey-base u-font-weight-600 u-border-bottom u-border-width-s u-border-color-grey-xxx-light">${that.popupTitle}</section>`;
              }
              (popupData?.uncolored || []).forEach(
                (uncoloredItem: {
                  brand: string;
                  percentage: number;
                  pvp: number;
                  pvpDirection: string;
                  colored: boolean;
                  barBackground: string;
                  value: {
                    data: number;
                    type: string;
                  };
                }) => {
                  const {
                    barBackground,
                    brand,
                    colored,
                    percentage,
                    pvp,
                    pvpDirection,
                    value
                  } = uncoloredItem;
                  uncoloredHtml += that.tooltipRow(
                    brand,
                    value
                      ? formatter(value.data, value.type)
                      : formatter(percentage, 'percentage'),
                    pvp,
                    pvpDirection,
                    colored,
                    barBackground ||
                      (that.color !== undefined && that.color('', d[0].id))
                  );
                }
              );
              popupData.colored.forEach(
                (coloredItem: {
                  brand: string;
                  percentage: number;
                  pvp: number;
                  pvpDirection: string;
                  colored: boolean;
                  barBackground: string;
                  value: {
                    data: number;
                    type: string;
                  };
                }) => {
                  const {
                    barBackground,
                    brand,
                    colored,
                    percentage,
                    pvp,
                    pvpDirection,
                    value
                  } = coloredItem;
                  coloredHtml += that.tooltipRow(
                    brand,
                    value
                      ? formatter(value.data, value.type)
                      : formatter(percentage, 'percentage'),
                    pvp,
                    pvpDirection,
                    colored,
                    barBackground ||
                      (that.color !== undefined && that.color('', d[0].id))
                  );
                }
              );
              if (uncoloredHtml.length) {
                uncoloredHtml = `<div class="u-spacing-pv-s u-border-bottom u-border-width-s u-border-color-grey-xxx-light">${uncoloredHtml}</div>`;
              }
              if (coloredHtml.length) {
                coloredHtml = `<div class="u-spacing-pv-s">${coloredHtml}</div>`;
              }
              const completeHtml = `
                <div class="u-font-size-7 u-bg-color-grey-white u-border-radius-s" style="box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15) !important; min-width: 250px; max-width: 300px">
                  ${titleHtml}
                  ${uncoloredHtml}
                  ${coloredHtml}
                </div>
              `;
              return completeHtml;
            }
          }
        },
        donut: {
          expand: false,
          label: {
            show: false,
            position: 'right'
          },
          width: this.width
        }
      };

      return val;
    }
  },
  watch: {
    chartData: {
      immediate: true,
      handler(newChartData): void {
        const formattedChartData = Array.isArray(newChartData)
          ? this.formatChartData(newChartData)
          : this.formatChartData(newChartData.data);
        const newFormattedChartData = this.formatInChartStructure(
          formattedChartData,
          this.externalColorMaps
        );
        this.formattedChartData = newFormattedChartData;
      }
    },
    externalColorMaps(newExternalColorMap): void {
      const formattedChartData = Array.isArray(this.chartData)
        ? this.formatChartData(this.chartData)
        : this.formatChartData(this.chartData.data);
      const formattedStructure = {
        data: formattedChartData
      };
      const newFormattedChartData = this.formatInChartStructure(
        formattedStructure,
        newExternalColorMap
      );
      this.formattedChartData = newFormattedChartData;
    }
  },
  methods: {
    handleTitleClick() {
      if (this.isTitleClickable) {
        this.$emit('titleClick', this);
      }
    },
    formatChartData(
      data: {
        brand: string;
        percentage: string;
      }[]
    ) {
      const chartData = data.map(
        (item: { brand: string; percentage: string }) => {
          return [item.brand, item.percentage];
        }
      );
      return chartData;
    },
    formatInChartStructure(data: unknown[] | object, colors: unknown) {
      if (Array.isArray(data)) {
        return data;
      } else {
        return {
          ...data,
          colors: colors,
          color: this.color !== undefined ? this.color : null
        };
      }
    },
    getColorClass(pvpDirection: string) {
      let color = 'u-color-';
      if (pvpDirection === '+') {
        color += 'green-base';
      } else if (pvpDirection === '-') {
        color += 'red-base';
      } else {
        color += 'grey-mid-light';
      }
      return color;
    },
    coloredBoxHtml(colored: boolean, barBackground: string) {
      if (colored) {
        return `<div
          class="u-spacing-mr-s u-border-top u-border-left u-border-bottom u-border-right u-border-width-s u-border-color-grey-base u-border-radius-xs"
          style="border-color: ${barBackground}; background-color: ${barBackground}; min-width: 12px; min-height: 12px;"
        >
        </div>`;
      }
      return '';
    },

    pvpIconHtml(pvpDirection: string) {
      let html = '';
      if (pvpDirection === '+') {
        html =
          '<span class="u-color-green-base rb-icon icon-arrow-up rb-icon--xx-small u-display-flex u-flex-align-items-center u-display-flex u-flex-justify-content-flex-center u-spacing-mh-xs"></span>';
      } else if (pvpDirection === '-') {
        html =
          '<span class="u-color-red-base rb-icon icon-arrow-down rb-icon--xx-small u-display-flex u-flex-align-items-center u-display-flex u-flex-justify-content-flex-center u-spacing-mh-xs"></span>';
      } else {
        html =
          '<span class="u-color-grey-mid-light rb-icon icon-arrow-up rb-icon--xx-small u-display-flex u-flex-align-items-center u-display-flex u-flex-justify-content-flex-center u-spacing-mh-xs"></span>';
      }
      return html;
    },
    tooltipRow(
      brand: string,
      percentage: number,
      pvp: number,
      pvpDirection: string,
      colored: boolean,
      barBackground: string
    ): string {
      const html = `
       <section
        class="u-display-flex u-flex-justify-content-space-between u-width-100 u-spacing-ph-m u-spacing-pv-s"
      >
        <div style="word-break: break-word;min-width: 50%;max-width: 50%;" class="u-display-flex u-flex-align-items-center">

          ${this.coloredBoxHtml(colored, barBackground)}
          <div class="u-display-block u-spacing-pr-s">${brand}</div>

        </div>
        <div class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between">
          <div style="width:40px" class="u-font-weight-600 u-color-grey-mid-light">
            ${percentage}
          </div>
          ${
            pvp === null
              ? '<div style="width:50px"  class="u-display-flex u-flex-justify-content-center"> NA </div>'
              : `<div style="width:50px"  class="u-display-flex ">
          ${this.pvpIconHtml(pvpDirection)}
            <div
              class="u-display-flex u-flex-align-items-center ${this.getColorClass(
                pvpDirection
              )}"
            >
              ${pvp}%
            </div>
          </div>`
          }
        </div>
      </section>`;
      return html;
    }
  }
});
