<template>
  <div
    v-if="!hideBanner"
    class="banner data-lag-banner u-font-size-6 u-spacing-pv-s u-spacing-ph-l u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
    :class="{ 'banner-with-negative-margin': applyNegativeMargin }"
  >
    <div class="u-display-flex">
      <rb-icon
        class="rb-icon--medium u-color-orange-base u-spacing-mr-s"
        :icon="iconName"
      />
      <div v-if="isStreamDataBanner">
        {{ title }}
      </div>
      <div v-else>
        <span>
          The filters and charts include data from inactive ASINs. Please
          <span
            class="u-color-blue-base u-cursor-pointer"
            @click="applyFilter"
            >click here</span
          >
          if you wish to filter them out.
        </span>
      </div>
    </div>
    <div
      v-if="isStreamDataBanner"
      class="u-cursor-pointer"
      @click="onCrossClick"
    >
      <rb-icon
        class="u-color-grey-mid-light rb-icon--small"
        :icon="'cross'"
      />
    </div>
  </div>
</template>

<script>
import { replaceOrderAndValue } from '@/components/pages/insights/amazon/share-of-voice/templates/common.js';
import { eventBus } from '@/utils/services/eventBus';

export default {
  name: 'ActiveFilterBanner',
  props: {
    title: {
      default: '',
      type: String
    },
    iconName: {
      default: 'info-circle-fill',
      type: String
    },
    filterInstance: {
      default: null,
      type: Object
    },
    forceApplyFilter: {
      default: false,
      type: Boolean
    },
    applyNegativeMargin: {
      default: false,
      type: Boolean
    },
    overrideHideBanner: {
      type: Boolean,
      default: false
    },
    isStreamDataBanner: {
      type: Boolean,
      default: false
    },
    bannerCrossClicked: {
      type: Boolean,
      default: false
    },
    pageKey: {
      type: String,
      default: ''
    },
    toggleBulkSelectBannerOnClose: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    hideBanner() {
      if (this.overrideHideBanner && !this.bannerCrossClicked) {
        return false;
      }
      const primaryFilters = this.filterInstance?.$props.primary;
      if (
        primaryFilters?.some((el) => el.dimensionName === 'smartfilter_status')
      ) {
        return this.filterInstance?.filtersOrder.includes('smartfilter_status');
      }
      return true;
    }
  },
  watch: {
    hideBanner(newVal) {
      eventBus.$emit('activeFilterBannerVisibility', !newVal);
    }
  },
  methods: {
    volumeRankPayload() {
      return {
        values: {
          smartfilter_status: ['Active']
        },
        order: ['smartfilter_status']
      };
    },
    appendFilterPayload(filtersPayload) {
      const { order, values } = this.volumeRankPayload();
      const keyToAppend = order[0];
      return replaceOrderAndValue(
        filtersPayload,
        keyToAppend,
        values[keyToAppend]
      );
    },
    applyFilter() {
      const currentFilters = this.filterInstance.getSelectedFilters();
      const newFilters = this.appendFilterPayload(currentFilters);
      const { order, values } = newFilters || {};
      let filteredValues = {};

      // Below code is written to remove all the Empty Filters from the values object
      Object.keys(values || {}).forEach((filterValue) => {
        if (
          (Array.isArray(values[filterValue]) && values[filterValue]?.length) ||
          (!Array.isArray(values[filterValue]) &&
            typeof values[filterValue] === 'object')
        ) {
          filteredValues[filterValue] = values[filterValue];
        }
      });

      const updatedNewFilters = {
        order: order,
        values: filteredValues
      };

      this.filterInstance.appendToFilters(updatedNewFilters);
      if (this.forceApplyFilter) {
        this.$nextTick(() => {
          this.filterInstance?.applyFilter(
            this.filterInstance?.selectedFilterValues
          );
        });
      }
    },
    onCrossClick() {
      this.bannerCrossClicked = !this.bannerCrossClicked;
      this.$emit('toggleBulkSelectBannerOnClose');
    }
  }
};
</script>

<style lang="css" scoped>
.data-lag-banner {
  background-color: rgba(255, 168, 0, 0.1);
}
.data-lag-banner .banner-with-negative-margin {
  margin: 0 -2.4rem;
}
</style>
