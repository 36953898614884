import {
  CUSTOM_PREFIX,
  STANDARD_PREFIX,
  STANDARD_PATH,
  CUSTOM_PATH,
  KEYWORDS_PATH,
  MY_BRANDS,
  OTHERS
} from './constants';
import {
  getWidgetUnsavedState,
  setUnsavedState,
  setInitialState
} from './views-tracking';
import helper from '@/utils/helpers/index.js';
import { isEqual, cloneDeep } from 'lodash';
import moment from 'moment';
import Vue from 'vue';

export const getSovDataGroups = () => {
  return {
    sov_top_2: 1,
    sov_top_3: 1,
    sov_top_5: 1,
    sov_top_10: 1,
    sov_total_count: 1
  };
};
export const digitalShelfLevels = (multiCrawl = false) => {
  return [
    ...(multiCrawl
      ? []
      : [
          {
            name: 'STANDARD',
            title: 'STANDARD',
            popupLabel: 'Standard Shelves',
            children: [
              {
                title: 'STANDARD SHELVES LEVEL 1',
                popupLabel: 'Level 1',
                name: 'LEVEL1',
                value: 'digital_shelf_l1',
                level: 1,
                type: 'standardShelf',
                api: {
                  prefix: 'standard~',
                  dimensionName: 'shelf_hierarchy'
                }
              },
              {
                title: 'STANDARD SHELVES LEVEL 2',
                popupLabel: 'Level 2',
                name: 'LEVEL2',
                value: 'digital_shelf_l2',
                level: 2,
                type: 'standardShelf',
                api: {
                  prefix: 'standard~',
                  dimensionName: 'shelf_hierarchy'
                }
              },
              {
                title: 'STANDARD SHELVES LEVEL 3',
                popupLabel: 'Level 3',
                name: 'LEVEL3',
                value: 'digital_shelf_l3',
                type: 'standardShelf',
                level: 3,
                api: {
                  prefix: 'standard~',
                  dimensionName: 'shelf_hierarchy'
                }
              },
              {
                title: 'STANDARD SHELVES LEVEL 4',
                popupLabel: 'Level 4',
                name: 'LEVEL4',
                value: 'digital_shelf_l4',
                type: 'standardShelf',
                level: 4,
                api: {
                  prefix: 'standard~',
                  dimensionName: 'shelf_hierarchy'
                }
              },
              {
                title: 'STANDARD SHELVES LEVEL 5',
                popupLabel: 'Level 5',
                name: 'LEVEL5',
                value: 'digital_shelf_l5',
                type: 'standardShelf',
                level: 5,
                api: {
                  prefix: 'standard~',
                  dimensionName: 'shelf_hierarchy'
                }
              }
            ]
          }
        ]),
    {
      name: 'CUSTOM',
      title: 'CUSTOM SHELVES',
      popupLabel: 'Custom Shelves',
      value: 'digital_shelf_l1',
      type: 'customShelf',
      level: 1,
      api: {
        prefix: 'custom~',
        dimensionName: 'shelf_hierarchy'
      }
    },
    {
      title: 'KEYWORDS',
      popupLabel: 'Keywords',
      name: 'KEYWORDS',
      value: 'search_term'
    }
  ];
};

export const accessKeys = () => {
  return [
    {
      brandKey: 'anchored_brand',
      popupKeys: []
    },
    {
      brandKey: 'client_brand',
      popupKeys: [
        { brandKey: 'client_brand_1' },
        { brandKey: 'client_brand_2' },
        { brandKey: 'client_brand_3' },
        { brandKey: 'client_brand_4' },
        { brandKey: 'client_brand_5' },
        { brandKey: 'client_brand_rest' }
      ]
    },
    {
      brandKey: 'comp_brand_1',
      popupKeys: []
    },
    {
      brandKey: 'comp_brand_2',
      popupKeys: []
    },
    {
      brandKey: 'comp_brand_3',
      popupKeys: []
    },
    {
      brandKey: 'others_brand',
      popupKeys: [
        { brandKey: 'others_brand_1' },
        { brandKey: 'others_brand_2' },
        { brandKey: 'others_brand_3' },
        { brandKey: 'others_brand_4' },
        { brandKey: 'others_brand_5' },
        { brandKey: 'others_brand_rest' }
      ]
    }
  ];
};

export const getMinFlatLevel = (selectedPaths, digitalShelfLevels) => {
  let minLevel = Infinity;
  if (selectedPaths.length === 1) {
    minLevel = digitalShelfLevels?.length;
  } else if (selectedPaths.length > 1) {
    minLevel = 0;
  }
  return minLevel;
};

export const getMinHierarchyLevel = (
  selectedPaths,
  allStandardHierarchy,
  digitalShelfLevels
) => {
  let minLevel = Infinity;
  const objectPointers = selectedPaths.map((path) => {
    return goToPathInObject(path, allStandardHierarchy);
  });
  if (selectedPaths.length) {
    minLevel = selectMinLevel(
      objectPointers,
      selectedPaths,
      digitalShelfLevels
    );
  }
  return minLevel;
};
export const findMinCommonLengthCount = (selectedStandardPaths) => {
  const endLengthMap = {};
  let minimumEndLength = Infinity;
  selectedStandardPaths.forEach((item) => {
    if (!endLengthMap[item.length]) {
      endLengthMap[item.length] = 1;
    } else {
      endLengthMap[item.length]++;
      if (minimumEndLength > item.length) {
        minimumEndLength = item.length;
      }
    }
  });
  return minimumEndLength;
};

export const filterStringStart = (filterStringArray, delimiter) => {
  const longestStringPath = {};
  const hierarchyArray = getFlattenedHeirarchy(filterStringArray, delimiter);
  for (let i = 0; i < hierarchyArray.length; i++) {
    const filterPaths = hierarchyArray[i];
    let mapTracker = longestStringPath;
    for (let j = 0; j < filterPaths.length; j++) {
      const currrentPath = filterPaths[j];
      if (!mapTracker[currrentPath]) {
        mapTracker[currrentPath] = {};
      }
      mapTracker = mapTracker[currrentPath];
    }
  }
  return longestStringPath;
};

export const getFlattenedHeirarchy = (heirarchyFilters, delimiter = '~') => {
  const flattenedHeirarchy = [];
  heirarchyFilters.forEach((filter) => {
    const splitFilters = filter.split(delimiter);
    flattenedHeirarchy.push(splitFilters);
  });
  return flattenedHeirarchy;
};

export const goToPathInObject = (paths, object) => {
  let objectPointer = object;
  for (let i = 0; i < paths.length; i++) {
    objectPointer = objectPointer[paths[i]]?.children || {};
  }
  return objectPointer;
};

export const selectMinLevel = (
  objectPointers,
  selectedStandardPaths,
  digitalShelfLevels
) => {
  let minimun = Infinity;
  let goToMinLevel = false;
  let minCount = 0;
  const minCommonLevel = findMinCommonLengthCount(selectedStandardPaths);
  if (isFinite(minCommonLevel)) {
    return minCommonLevel - 1;
  }
  for (let i = 0; i < objectPointers.length; i++) {
    const nestedChildred = Object.keys(objectPointers[i]);
    const pathLength = selectedStandardPaths[i].length;
    if (pathLength === minimun) {
      minCount++;
    }
    if (pathLength < minimun) {
      minimun = pathLength;
      minCount = 0;
    }
    if (nestedChildred.length) {
      goToMinLevel = true;
    }
  }
  if (minCount > 1) {
    return minimun - 1;
  }
  if (goToMinLevel) {
    return minimun;
  } else {
    return digitalShelfLevels.length;
  }
};

export const findInTree = (tree, treePath) => {
  let root = tree;
  treePath.forEach((pathName) => {
    if (Array.isArray(root)) {
      root = root.find((item) => item.name === pathName);
    } else {
      root = root[pathName];
    }
    if (root?.children) {
      root = root.children;
    }
  });
  return root;
};

export const titleOptionSelect = (context, data) => {
  const { selectedOptions = [], treePath = [] } = data || {};
  context.resetPagination();
  context.$emit('retainGlobalColors', false);
  const formattedTreePath = [];
  treePath.forEach((item) => {
    formattedTreePath.push(item.name);
  });
  setUnsavedState(context, 'titlePath', formattedTreePath);
  setUnsavedState(context, 'titleOptionSelected', selectedOptions[0].value);
};

export const selectedTitleOptions = (context, checkOnInitialState = false) => {
  const { titleOptionSelected, titlePath } = checkOnInitialState
    ? getWidgetUnsavedState(context, 'getInitialWidgetStates')
    : getWidgetUnsavedState(context);
  const root = findInTree(context.digitalShelfLevels, titlePath);
  if (root) {
    if (Array.isArray(root)) {
      const foundElemet = root.find(
        (item) => item.value === titleOptionSelected
      );
      if (foundElemet) {
        return foundElemet;
      } else {
        return root[0] || {};
      }
    } else {
      return root;
    }
  } else {
    return context.digitalShelfLevels.find(
      (item) => item.value === 'search_term'
    );
  }
};

export const setLeafLevel = (context, path, type = 'unsaved') => {
  const leafNode = findInTree(context.digitalShelfLevels, path);
  if (type === 'unsaved') {
    setUnsavedState(context, 'titlePath', []);
    setUnsavedState(context, 'titleOptionSelected', leafNode.value);
  } else if (type === 'initial') {
    setInitialState(context, 'titlePath', []);
    setInitialState(context, 'titleOptionSelected', leafNode.value);
  }
};

export const getSelectedHierarchies = (context) => {
  const selectedCustomPaths = getFlattenedHeirarchy(
    context.getSelectedCustomShelves
  );
  const selectedStandardPaths = getFlattenedHeirarchy(
    context.getSelectedStandardShelves
  );
  return { selectedCustomPaths, selectedStandardPaths };
};

export const setShelfLevel = (context) => {
  const { selectedCustomPaths, selectedStandardPaths } =
    getSelectedHierarchies(context);
  disableInHierarchy(context);
  const standardNode = findInTree(context.digitalShelfLevels, STANDARD_PATH);
  const customNode = findInTree(context.digitalShelfLevels, CUSTOM_PATH);
  const hierarchialMinLevel = getMinHierarchyLevel(
    selectedStandardPaths,
    context.getStandardHierarchy.rows,
    standardNode
  );
  const flatMinLevel = getMinFlatLevel(selectedCustomPaths, standardNode);
  if (
    !standardNode?.disable &&
    isFinite(hierarchialMinLevel) &&
    selectedStandardPaths.length
  ) {
    if (standardNode?.length > hierarchialMinLevel) {
      setUnsavedState(context, 'titlePath', STANDARD_PATH);
      setUnsavedState(
        context,
        'titleOptionSelected',
        standardNode?.[hierarchialMinLevel].value
      );
    } else {
      setLeafLevel(context, KEYWORDS_PATH);
    }
  } else if (!customNode.disable && isFinite(flatMinLevel)) {
    if (selectedCustomPaths.length === 1) {
      setLeafLevel(context, KEYWORDS_PATH);
    } else {
      setLeafLevel(context, CUSTOM_PATH);
    }
  }
  if (customNode.disable && standardNode?.disable) {
    setLeafLevel(context, KEYWORDS_PATH);
  }
  if (context.multiCrawl) {
    setLeafLevel(context, KEYWORDS_PATH);
  }
};

export const appendIsNotNull = (
  bundleKey,
  dimensionNameValueList,
  selectedOption
) => {
  if (bundleKey === 'sov_sfr_ki') {
    const titleSelectedWhere = (dimensionNameValueList || []).filter(
      (item) => item.dimensionName === selectedOption
    );
    if (titleSelectedWhere.length === 0) {
      dimensionNameValueList.push({
        dimensionName: selectedOption,
        operator: 'IS_NOT_NULL'
      });
    }
  }
};

export const appendAnchorFilter = (where, anchoredBrand) => {
  if (appendAnchorBrandCondition(anchoredBrand)) {
    where.dimensionNameValueList.push(
      getAnchorFilterPayload(anchoredBrand.title)
    );
  }
};

export const splitArrays = (arrayToBeSplit, splitFunctions) => {
  const trueArray = arrayToBeSplit.filter((item) => splitFunctions(item));
  const falseArray = arrayToBeSplit.filter((item) => !splitFunctions(item));
  return { trueArray, falseArray };
};

export const getFilteredShelfHierarchy = (
  dimensionNameValueList,
  titleSelectedOption
) => {
  if (!titleSelectedOption.api) {
    return dimensionNameValueList;
  }
  const { trueArray: withShelfHierarchy, falseArray: withoutShelfHierarchy } =
    splitArrays(
      dimensionNameValueList,
      (item) => item.dimensionName === titleSelectedOption.api.dimensionName
    );
  let formattedSHelfHierachy = [];
  const prefixToFilter =
    titleSelectedOption.api.prefix === STANDARD_PREFIX
      ? STANDARD_PREFIX
      : CUSTOM_PREFIX;
  formattedSHelfHierachy = withShelfHierarchy.filter((item) =>
    item.dimensionValue.includes(prefixToFilter)
  );
  if (!formattedSHelfHierachy.length) {
    formattedSHelfHierachy.push({
      dimensionName: titleSelectedOption.api.dimensionName,
      dimensionValue: titleSelectedOption.api.prefix,
      operator: 'STARTSWITH'
    });
  }
  return [...formattedSHelfHierachy, ...withoutShelfHierarchy];
};

export const disableInHierarchy = (context) => {
  const { selectedCustomPaths, selectedStandardPaths } =
    getSelectedHierarchies(context);
  const standardDisablePath = STANDARD_PATH.map((item) => ({ key: item }));
  const customDisablePath = CUSTOM_PATH.map((item) => ({ key: item }));
  context.setTitleDropdownContents();
  if (selectedCustomPaths.length) {
    for (let i = 0; i < context.digitalShelfLevels.length; i++) {
      helper.disableTreeNodes(
        context.digitalShelfLevels[i],
        standardDisablePath,
        'name'
      );
    }
  }
  if (selectedStandardPaths.length) {
    for (let i = 0; i < context.digitalShelfLevels.length; i++) {
      helper.disableTreeNodes(
        context.digitalShelfLevels[i],
        customDisablePath,
        'name'
      );
    }
  }
};

export const hasHierarchy = (context) => {
  const { titleOptionSelected, titlePath } = getWidgetUnsavedState(context);
  const notSearchTermCondition = titleOptionSelected !== 'search_term';
  if (notSearchTermCondition && isEqual(STANDARD_PATH, titlePath)) {
    return objectRootLength(context.getStandardHierarchy?.rows) !== 0;
  } else if (notSearchTermCondition && isEqual([], titlePath)) {
    return (context.getCustomHierarchy?.rows || []).length !== 0;
  }
  return true;
};
export const objectRootLength = (object) => {
  return Object.keys(object || {}).length;
};

export const isUnifiedClient = (productHeaders) => {
  const productHeadersMap = {
    salesiq: 0,
    marketingiq: 0,
    marketingiqlite: 0
  };
  productHeaders.forEach((header) => {
    productHeadersMap[(header || '').toLowerCase()] = 1;
  });

  return productHeadersMap.salesiq === 1 && productHeadersMap.marketingiq === 1;
};

export const isMarketInsightsEnabled = (productHeaders) => {
  const uniClient = isUnifiedClient(productHeaders);
  const isMarketInsightsFlag =
    window?.configs?.client?.feature?.insights?.sov_v2?.enable;
  return uniClient && isMarketInsightsFlag;
};

export const isIntradayCrawlingEnabled = (retailer) => {
  const enabledForRetailer = Vue.options.filters.config_check(
    `feature.insights.${retailer}_sov_v2.intraday.enable`
  );
  if (enabledForRetailer) {
    const onlyForInternalUsers =
      window?.configs?.client?.feature?.insights?.[`${retailer}_sov_v2`]
        ?.intraday?.internalUsersOnly;
    if (onlyForInternalUsers) {
      const isInternalUser = helper.internalUserCheck(window.user);
      const externalUsersList =
        window?.configs?.client?.feature?.insights?.[`${retailer}_sov_v2`]
          ?.intraday?.externalUsers ?? [];
      const isExternalUser = helper.externalUserCheck(
        window.user,
        externalUsersList
      );
      return isInternalUser || isExternalUser;
    }
    return true;
  }
  return false;
};

export const appendOrder = (order, valueToAppend) => {
  const foundOrder = order.find((item) => item === valueToAppend);
  if (!foundOrder) {
    order.push(valueToAppend);
  }
};
export const appendValue = (filterValues, keyToAppendOn, valuesToAppend) => {
  if (filterValues[keyToAppendOn]) {
    const target = filterValues[keyToAppendOn];
    const lookupMap = getLookupMap(target);
    valuesToAppend.forEach((value) => {
      if (!lookupMap[value]) {
        target.push(...valuesToAppend);
      }
    });
  } else {
    filterValues[keyToAppendOn] = [...valuesToAppend];
  }
};

export const appendOrderAndValue = (filters, keyToAppend, valueToAppend) => {
  const currentFilters = cloneDeep(filters);
  const { order, values } = currentFilters;
  appendOrder(order, keyToAppend);
  appendValue(values, keyToAppend, valueToAppend);
  return currentFilters;
};

export const replaceValue = (
  filterValues,
  keyToReplaceOn,
  valueToReplaceWith
) => {
  filterValues[keyToReplaceOn] = valueToReplaceWith;
};

export const replaceOrderAndValue = (filters, keyToAppend, valueToAppend) => {
  const currentFilters = cloneDeep(filters);
  const { order, values } = currentFilters;
  appendOrder(order, keyToAppend);
  replaceValue(values, keyToAppend, valueToAppend);
  return currentFilters;
};

export const getAllKeyPaths = (obj, key, prev = []) => {
  const result = [];
  const recurse = (obj, key, prev = []) => {
    const numberOfKeys = Object.keys(obj);
    if (numberOfKeys.length === 0) {
      result.push(prev);
    } else {
      for (const k in obj) {
        const path = [...prev, k];
        if (typeof obj[k] === 'object' || obj[k] instanceof Array) {
          recurse(obj[k], key, path);
        } else {
          result.push(path);
        }
      }
    }
  };
  recurse(obj, key, prev);
  return result;
};

export const getAllPathsWithKey = (obj, key, prev = []) => {
  const result = [];
  for (const k in obj) {
    const path = [...prev, k];
    if (k === key) {
      result.push(path);
    } else if (typeof obj[k] === 'object') {
      result.push(...getAllPathsWithKey(obj[k], key, path));
    }
  }
  return result;
};

export const getLookupMap = (array) => {
  return array.reduce((a, b) => {
    a[b] = 1;
    return a;
  }, {});
};

export const removeBlacklistedKeys = (paths, keyBlacklist) => {
  const blaclistMap = getLookupMap(keyBlacklist);
  return paths.map((path) => {
    return path.filter((item) => !blaclistMap[item]);
  });
};

export const getAllPathTillLevel = (obj, key, level, keyBlacklist) => {
  const allPaths = getAllPathsWithKey(obj, key, keyBlacklist);
  const filteredPaths = removeBlacklistedKeys(allPaths, keyBlacklist);
  return filteredPaths.filter((item) => item.length === level);
};

export const setRuleBuilderBrand = (obj, anchoredBrand) => {
  obj.brand = anchoredBrand.title;
  obj.infix = { text: anchoredBrand.title };
};

export const flattenArrayOfArrays = (arrayOfArrays) => {
  const flatArray = [];
  arrayOfArrays.forEach((nestedArray) => {
    flatArray.push(...nestedArray);
  });
  return flatArray;
};
export const appendAnchorBrandCondition = (anchoredBrand) => {
  if (
    anchoredBrand &&
    anchoredBrand.title &&
    anchoredBrand.title !== MY_BRANDS
  ) {
    return true;
  }
  return false;
};

export const isSovDataGroup = (dataGroup, sovDataGroups) => {
  if (dataGroup && sovDataGroups[dataGroup]) {
    return true;
  }
  return false;
};

export const getSegmentedBrandsData = (clientApiData, compApiData) => {
  const radioFormattedData = [
    { groupTitle: '', type: 'client', data: clientApiData },
    { groupTitle: 'Competitor Brand', type: 'comp', data: compApiData }
  ];
  const radioSelectedValue = clientApiData[0] || compApiData[0];
  return {
    selectedValue: radioSelectedValue,
    segmentedData: radioFormattedData
  };
};

export const splitIntoClientOrComp = (apiData) => {
  const clientApiData = [];
  const compApiData = [];
  for (const entityUnit of apiData) {
    const { client_flag: type } = entityUnit;
    const payload = {
      brand: entityUnit.entityValue,
      brandKey: entityUnit.entityType
    };
    if (type === 'client') {
      clientApiData.push(payload);
    } else {
      compApiData.push(payload);
    }
  }
  return { client: clientApiData, comp: compApiData };
};

export const filterMyBrandsData = (
  array,
  anchoredBrand,
  keyToCheck = 'entityValue'
) => {
  const _clientData = array.filter(
    (item) => item[keyToCheck] === anchoredBrand.title
  );
  const _compData = array.filter(
    (item) =>
      item[keyToCheck] !== anchoredBrand.title && item[keyToCheck] !== MY_BRANDS
  );
  return {
    client: _clientData,
    comp: _compData
  };
};

export const splitClientCompOnAnchor = (
  compApiData,
  clientApi,
  anchoredBrand,
  filterKey = 'brand'
) => {
  const splitData = splitIntoClientOrComp(compApiData);
  if (appendAnchorBrandCondition(anchoredBrand)) {
    const clientData = splitData.client.concat(clientApi);
    const filterAnchorData = filterMyBrandsData(
      clientData,
      anchoredBrand,
      filterKey
    );
    const _client = filterAnchorData.client.concat(filterAnchorData.comp);
    return {
      client: _client,
      comp: splitData.comp
    };
  } else {
    return splitData;
    // return {
    //   clientplitData.client,
    //   comp: splitData.comp
    // };
  }
};

export const setDonutLegendMetadata = (item, colorMap, anchoredBrand) => {
  item.colored = true;
  item.barBackground = colorMap?.[item.brand];
  if (item.brand !== OTHERS && appendAnchorBrandCondition(anchoredBrand)) {
    item.legendIcon = item.clientFlag === 'comp' ? 'legend-box' : 'star';
  } else {
    item.legendIcon = 'legend-box';
  }
  return item;
};

export const getAnchorFilterPayload = (anchoredBrandTitle) => {
  return {
    dimensionName: 'relevant_brands',
    dimensionValue: anchoredBrandTitle,
    operator: 'ARRAY_CONTAINS'
  };
};

export function tooltipRollupWidget(rollUpBy, maxDate) {
  return (xTick) => {
    let endDate = moment(xTick);
    let startDate = moment(endDate);
    if (rollUpBy !== 'dates') {
      // In case of roll up by weeks or months, we need
      if (rollUpBy === 'months') {
        startDate = startDate.startOf('month');
        endDate = endDate.endOf('month');
      } else if (rollUpBy === 'weeks') {
        startDate = startDate.startOf('week');
        endDate = endDate.endOf('week');
      }
      const maxDateObj = moment(maxDate);
      if (endDate.isAfter(maxDateObj)) {
        // If the day is after max date possible
        // set the end date to be the max date
        endDate = maxDateObj;
      }
      if (endDate.diff(startDate, 'days') >= 1) {
        return startDate.format('LL') + ' - ' + endDate.format('LL');
      } else {
        return startDate.format('LL');
      }
    }
  };
}
