<template>
  <div class="u-width-100">
    <section v-if="panelConfig.showBaseTemplate">
      <header
        class="title u-font-size-5 u-font-weight-600 u-spacing-pt-l u-spacing-ph-l u-text-case-upper"
      >
        {{ panelConfig.header }}
      </header>
      <div class="actionsPanelBody u-display-flex u-spacing-p-l">
        <div
          class="tableContainerSplitPanel"
          :style="{
            width: panelConfig.source.width ? panelConfig.source.width : '33%'
          }"
        >
          <slot name="source-header">
            <div
              v-if="panelConfig.result.header"
              class="tableHeading u-spacing-ph-m u-spacing-pv-s u-font-weight-600 u-font-size-5"
            >
              <div class="u-spacing-p-xs">
                {{ panelConfig.source.header }}
              </div>
            </div>
          </slot>
          <slot name="source-filter">
            <div
              v-if="panelConfig.source.filter"
              class="u-spacing-ph-l u-spacing-pt-l u-display-flex u-font-weight-600 u-font-size-5"
            >
              {{ panelConfig.source.filter.header }}
            </div>
            <div
              v-if="panelConfig.source.filter"
              class="u-spacing-ph-l u-spacing-pt-m u-display-flex u-font-weight-400 u-flex-justify-content-flex-start u-overflow-auto"
            >
              <rb-checkbox
                v-for="(item, i) in panelConfig.source.filter.items"
                :key="i"
                v-model="sourceCheckBox[i]"
                :style="
                  panelConfig.source.filter.filterStyle &&
                  panelConfig.source.filter.filterStyle[item]
                    ? panelConfig.source.filter.filterStyle[item]
                    : ''
                "
                native-value="false"
                @input="updateSourcePanelCheckBox()"
              >
                <div
                  v-if="
                    panelConfig.source.filter.vhtmlItems &&
                    panelConfig.source.filter.vhtmlItems[item]
                  "
                >
                  <!-- eslint-disable -->
                  <div v-html="panelConfig.source.filter.vhtmlItems[item]" />
                  <!-- eslint-enable -->
                </div>
                <div v-else>
                  {{ item }}
                </div>
              </rb-checkbox>
            </div>
          </slot>
          <slot name="source-body"> slot = "source-body" </slot>
        </div>
        <div
          class="tableContainerSplitPanel u-spacing-mh-l"
          :style="{
            width: panelConfig.result.width ? panelConfig.result.width : '33%'
          }"
        >
          <slot name="result-header">
            <div
              v-if="panelConfig.result.header"
              class="tableHeading u-spacing-ph-m u-spacing-pv-s u-font-weight-600 u-font-size-5"
            >
              <div class="u-spacing-p-xs">
                {{ panelConfig.result.header }}
              </div>
            </div>
          </slot>
          <slot name="result-filter">
            <div
              v-if="panelConfig.result.filter"
              class="u-spacing-ph-l u-spacing-pt-l u-display-flex u-font-weight-600 u-font-size-5"
              :class="
                panelConfig.result.filterClass
                  ? panelConfig.result.filterClass
                  : ''
              "
            >
              {{ panelConfig.result.filter.header }}
            </div>
            <div
              v-if="panelConfig.result.filter"
              class="u-spacing-ph-l u-spacing-pt-m u-display-flex u-font-weight-400 u-flex-justify-content-flex-start u-overflow-auto"
            >
              <rb-checkbox
                v-for="(item, i) in panelConfig.result.filter.items"
                :key="i"
                v-model="resultCheckBox[i]"
                native-value="false"
                :style="
                  panelConfig.result.filter.filterStyle &&
                  panelConfig.result.filter.filterStyle[item]
                    ? panelConfig.result.filter.filterStyle[item]
                    : ''
                "
                @input="updateResultPanelCheckBox()"
              >
                <div
                  v-if="
                    panelConfig.result.filter.vhtmlItems &&
                    panelConfig.result.filter.vhtmlItems[item]
                  "
                >
                  <!-- eslint-disable -->
                  <div v-html="panelConfig.result.filter.vhtmlItems[item]" />
                  <!-- eslint-enable -->
                </div>
                <div v-else>
                  {{ item }}
                </div>
              </rb-checkbox>
            </div>
          </slot>
          <slot name="result-body">
            <div class="tableBody">
              <slot name="result-body"> slot = "result-body" </slot>
            </div>
          </slot>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    panelData: {
      type: Object,
      default: () => ({
        sourceCheckBox: [],
        resultCheckBox: []
      })
    },
    panelConfig: {
      type: Object,
      default: () => ({
        showBaseTemplate: true,
        header: 'Default Header',
        source: {
          width: '43%',
          header: 'Source Header'
        },
        result: {
          width: '43%',
          header: 'Result Header'
        }
      })
    }
  },
  data: () => ({
    localPanelData: {},
    sourceCheckBox: [],
    resultCheckBox: []
  }),
  created() {
    if (
      this.panelConfig &&
      this.panelConfig.source &&
      this.panelConfig.source.filter &&
      this.panelData &&
      this.panelData.sourceCheckBox
    ) {
      this.sourceCheckBox = this.generateBooleanFilter(
        this.panelConfig.source.filter.items,
        this.panelData.sourceCheckBox
      );
    }
    if (
      this.panelConfig &&
      this.panelConfig.result &&
      this.panelConfig.result.filter &&
      this.panelData &&
      this.panelData.resultCheckBox
    ) {
      this.resultCheckBox = this.generateBooleanFilter(
        this.panelConfig.result.filter.items,
        this.panelData.resultCheckBox
      );
    }
    this.localPanelData = this.panelData;
  },
  methods: {
    generateBooleanFilter(allItems, checkedItems) {
      const values = [];
      let check = false;
      allItems.forEach((item) => {
        check = false;
        checkedItems.forEach((checkedItem) => {
          if (item === checkedItem) {
            check = true;
          }
        });
        values.push(check);
      });
      return values;
    },
    generateValues(booleanValues, allItems) {
      const values = [];
      booleanValues.forEach((val, index) => {
        if (val) {
          values.push(allItems[index]);
        }
      });
      return values;
    },
    updateSourcePanelCheckBox() {
      this.localPanelData.sourceCheckBox = this.generateValues(
        this.sourceCheckBox,
        this.panelConfig.source.filter.items
      );
      this.$emit('updatedPanelData', this.localPanelData);
    },
    updateResultPanelCheckBox() {
      this.localPanelData.resultCheckBox = this.generateValues(
        this.resultCheckBox,
        this.panelConfig.result.filter.items
      );
      this.$emit('updatedPanelData', this.localPanelData);
    }
  }
};
</script>

<style lang="css" scoped>
.containerVisibilty {
  visibility: unset;
}
.visibilityHidden {
  visibility: hidden;
}
.tableContainerSplitPanel {
  box-shadow: 0 0 4px 0 #caccce;
}
.tableContainerSplitPanel .tableHeading {
  box-shadow: 0 1px 4px -1px #caccce;
  position: relative;
  z-index: 11;
}
.tableContainerSplitPanel .tableBody {
  min-height: 400px;
  overflow: auto;
  position: relative;
  z-index: 0;
}
.tableContainerSplitPanel .message {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: #8b8f93;
}
.ag-row-diabled {
  opacity: 0.3;
  pointer-events: none;
}
.addKeywordPanel .ag-row.ag-row-level-0 div:nth-child(2) .cell--content,
.addKeywordPanel
  .ag-row.ag-row-first.ag-row-last
  div:nth-child(3)
  .cell--content {
  text-transform: capitalize;
}
.fillMessage {
  position: absolute;
  display: flex;
  text-align: center;
  opacity: 0.75;
  background: #fff;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}
</style>
