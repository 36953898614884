<template>
  <div
    class="collaboration expand"
    :style="{ top: topSpacing }"
  >
    <slot name="header">
      <div class="header">
        <span class="u-flex-1 u-spacing-p-m u-font-size-5 u-font-weight-600">{{
          heading
        }}</span>
        <loader
          v-show="loading"
          class="fill--parent"
          :color="'#3fadf7'"
          :size="'4rem'"
          :thickness="'.2rem'"
        />
        <div
          v-tippy="{ placement: 'top', arrow: true }"
          class="u-display-flex u-flex-align-items-center u-cursor-pointer"
          :title="'Download the list of uncategorized SKUs'"
        >
          <rb-download-button
            :key="'uncategorizedView'"
            :config="config"
            :download-text="''"
            :split-new-line-text="false"
          />
        </div>
        <div
          class="u-display-flex u-flex-align-items-center u-cursor-pointer u-spacing-mr-m"
        >
          <span @click="closeWidget">
            <rb-icon
              class="u-color-grey-lighter rb-icon--small"
              :icon="'cross'"
              :show-download-text="false"
            />
          </span>
        </div>
      </div>
    </slot>
    <slot name="body">
      <div class="u-spacing-mt-m">
        <div
          v-for="(sku, i) in uncategorizedSKUs"
          :key="i"
          class="u-display-flex u-flex-direction-column u-spacing-ph-m u-spacing-pv-xxs"
        >
          <div class="u-display-flex u-text-overflow-ellipsis">
            <rb-sku
              class="u-text-overflow-ellipsis"
              :title="sku.RESULT.product_name"
              :image-url="sku.RESULT.image_url"
              :asin="sku.RESULT.gtin"
            >
              <div
                slot="sku-context"
                class="u-spacing-p-0 u-spacing-mt-0"
              >
                <span class="u-color-grey-base-lighter"
                  >Ad Spend (MTD):$ {{ sku.RESULT.ad_spend }}K
                </span>
              </div>
            </rb-sku>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>
<script>
import eventComponent from '@/components/widgets/collaboration/eventComponent.vue';
import loader from '@/components/basic/loader';
import HttpService from '@/utils/services/http-service';
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
import { unCategorizedSKUConfig } from '@/components/ams/budgetPlanner/retailerConfig';

export default {
  components: {
    eventComponent,
    loader,
    rbDownloadButton
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          load: true
        };
      }
    },
    pageName: {
      type: String,
      default: 'Target Budget Optmimiser'
    },
    heading: {
      type: String,
      default: 'List of Uncategorized SKUs'
    },
    topSpacing: {
      type: String,
      default: '104px'
    },
    retailer: {
      type: String,
      default: 'target'
    }
  },
  data() {
    return {
      dateChip: new Date(),
      showDateChip: false,
      uncategorizedSKUs: [],
      loading: true,
      config: {
        header: {
          show: false,
          search: true,
          tooltip: ''
        },
        body: {
          APIConfig: unCategorizedSKUConfig[this.retailer]
        }
      }
    };
  },
  computed: {
    emptyStateMessage() {
      return `No activity has been logged for this ${this.pageName} yet.`;
    }
  },
  created() {
    this.getAllUncategorizedSKUs();
  },
  methods: {
    closeWidget() {
      this.$emit('closeUncategorizedView');
    },
    async getAllUncategorizedSKUs() {
      try {
        const response = await HttpService.post(
          'EXECUTE_CUBE_API',
          this.config.body.APIConfig
        );
        if (response && response.status === 200) {
          this.loading = false;
          this.uncategorizedSKUs = response?.data?.response?.data;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$snackbar.open({
          message: 'Something Went Wrong',
          duration: 6000,
          actionText: ''
        });
      }
    }
  }
};
</script>
<style lang="css" scoped>
.collaboration {
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: 22%;
  min-width: 22%;
  box-shadow: -2px 0 8px 0 rgba(43, 51, 59, 0.1);
  background: #fff;
  z-index: 101;
  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
}

.header {
  box-shadow: 0 0 4px 0 rgba(43, 51, 59, 0.2);
  display: flex;
  border: bottom;
  border-width: 1px;
  border-color: #e9eaeb;
}

.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.expand {
  transition: max-width 1s ease-in-out;
}

.line-height-1_5 {
  line-height: 1.5;
}
</style>
