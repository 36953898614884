<template>
  <modal
    v-if="showEdit"
    :modal-config="modalConfig"
  >
    <div slot="body">
      <div class="content-edit-container u-spacing-pv-l">
        <div
          class="current-content-score"
          :style="{ background: getColors(imageContentDetails.contentScore) }"
        >
          <rb-icon
            v-if="
              [SKU_STATE.IMPROVED, SKU_STATE.NEGATIVE].includes(
                imageContentDetails.state
              )
            "
            icon="warning"
            :class="{
              'u-color-yellow-base':
                SKU_STATE.IMPROVED === imageContentDetails.state,
              'u-color-red-base':
                SKU_STATE.NEGATIVE === imageContentDetails.state
            }"
          />
          <div>
            <p class="u-font-size-5 u-font-weight-600">
              <span class="u-text-transform-capitalize">{{
                imageContentDetails.contentType
              }}</span>
              content Score
              <span class="u-font-size-2 u-spacing-p-s u-font-weight-800">{{
                imageContentDetails.contentScore + '%'
              }}</span>
            </p>
            <p class="u-font-size-5">
              {{ imageContentDetails.contentScoreDescription }}
            </p>
          </div>
        </div>
      </div>
      <img
        :src="imageContentDetails.currentImageUrl"
        alt="current image"
        class="current-img"
      />
      <div class="container-base suggestion u-spacing-mv-xl">
        <div class="u-display-flex u-flex-justify-content-space-between">
          <p class="u-font-size-3 u-color-grey-mid-light u-font-weight-600">
            SOT Image
          </p>
        </div>
        <img
          :src="imageContentDetails.suggestedImageUrl"
          alt="SOT image"
          class="suggested-img"
        />
        <p class="u-font-size-3 u-color-grey-mid-light">Source Data: PIM</p>
        <rb-button
          text="Optimize content"
          type="filled"
          icon-left="segment"
          class="u-color-grey-white u-spacing-mt-m"
          :click-fn="matchSOTConentent.bind(this)"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import { getColors } from '@/pages/all_retailers/dso/utils/contentAutomation';
import { SKU_STATE } from '@/pages/all_retailers/dso/constant';
import modal from '@/components/widgets/modal.vue';

export default {
  name: 'EditSKUImage',
  components: {
    modal
  },
  props: {
    showEdit: {
      type: Boolean,
      default: false
    },
    imageContentDetails: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      SKU_STATE,
      modalConfig: {
        footer: {}
      }
    };
  },
  methods: {
    getColors,
    matchSOTConentent() {
      this.$emit('onMatchImageSOTContent', this.imageContentDetails);
    }
  }
};
</script>

<style scoped lang="css">
.container-base {
  border-radius: 4px;
  padding: 16px 12px;
}
.suggestion {
  background: #ebf5fe;
}
.current-content-score {
  background: #fffeea;
  padding: 16px 12px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
}
.current-img {
  width: 10rem;
  height: 10rem;
  border: 1px solid #e9eaeb;
}
.suggested-img {
  width: 20rem;
  height: 20rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
