<template>
  <div class="u-display-flex u-flex-direction-column u-overflow-x-hidden">
    <!-- <filter-panel :context="'reviews'" :maxDate="'review_ratings'" :emit="config.filters.emit" class="u-spacing-ph-m u-spacing-pt-m" :config="config.filters" :state="filterState" :apply-filter="applyFilter"></filter-panel> -->
    <div
      class="u-display-flex u-spacing-ph-l u-bg-color-grey-white u-spacing-pb-m u-header-box-shadow sticky"
      style="z-index: 100"
    >
      <PageTitle :title="'Reviews & Rating'" />
    </div>
    <section class="u-envelop-width u-width-100">
      <card-widget
        class="u-flex-1 u-spacing-mb-m u-spacing-mh-m"
        :filter-applied="widget1DateFilterApplied"
        :config="config.widgets['widget1']"
        :data="getInsightsReviewsData[config.widgets['widget1'].meta.key] || {}"
        :page-wise-min-max-key="pageWiseMinMaxKey"
        data-cy="review-count-widget"
      >
        <div slot="body">
          <div class="u-spacing-ph-l">
            <div class="u-display-flex u-flex-align-items-center">
              <div
                :style="{
                  'border-color':
                    reviewCountColors['0' + selectedShareClientBrand] ||
                    'transparent'
                }"
                class="custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative"
              >
                <span
                  v-if="shareClientBrands.length <= 0"
                  class="u-spacing-mr-m u-display-flex u-flex-align-items-center"
                >
                  <span class="u-font-size-5">My Brands</span>
                </span>
                <rb-select
                  v-if="shareClientBrands.length > 0"
                  :width="'240px'"
                  class="u-spacing-mr-m"
                  :send-details="true"
                  :on-close="shareClientBrandSelected"
                  :options="shareClientBrands"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                  >
                    <div class="u-display-flex u-flex-align-items-center">
                      <!-- <rb-icon :style="{ color: getColorPattern[1] }" :icon="'dot'"></rb-icon> -->
                      <span class="u-font-size-5">{{
                        selectedShareClientBrand
                      }}</span>
                      <rb-icon
                        class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                        :icon="'caret-down'"
                      />
                    </div>
                  </div>
                </rb-select>
                <metric
                  v-if="
                    selectedShareClientBrand &&
                    ((
                      getInsightsReviewsData[
                        config.widgets['widget1'].meta.key
                      ] || {}
                    ).metrics || {})[selectedShareClientBrand]
                  "
                  :size="'l'"
                  class="u-display-inline-flex u-spacing-mt-s"
                  :config="config.widgets['widget1'].body.tagConfig"
                  :data="
                    ((
                      getInsightsReviewsData[
                        config.widgets['widget1'].meta.key
                      ] || {}
                    ).metrics || {})[selectedShareClientBrand]
                  "
                />
                <!-- {{ scope.row.star4_new_reviews | num_format('','','',0) }} -->
                <span
                  v-if="!selectedShareClientBrand"
                  class="u-font-size-6 u-color-grey-lighter"
                  >No Data</span
                >
              </div>
              <div
                v-for="(val, index) in selectedShareCompBrand"
                :val="val"
                :style="{
                  'border-color': reviewCountColors['1' + val] || 'transparent'
                }"
                class="custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative"
              >
                <span
                  v-if="
                    index + 1 === selectedShareCompBrand.length && index > 0
                  "
                  class="removeLegend"
                  @click="removeLegend(index)"
                >
                  <rb-icon
                    class="u-color-grey-x-light rb-icon--small u-cursor-pointer"
                    :icon="'cross'"
                  />
                </span>
                <rb-select
                  v-if="val"
                  :width="'240px'"
                  class="u-spacing-mr-m"
                  :context="[index]"
                  :send-details="true"
                  :on-close="shareCompBrandSelected"
                  :options="shareCompBrands"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                  >
                    <div class="u-display-flex u-flex-align-items-center">
                      <!-- <rb-icon :style="{ color: getColorPattern[1] }" :icon="'dot'"></rb-icon> -->
                      <span class="u-font-size-5">{{ val }}</span>
                      <rb-icon
                        class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                        :icon="'caret-down'"
                      />
                    </div>
                  </div>
                </rb-select>
                <metric
                  v-if="
                    val &&
                    ((
                      getInsightsReviewsData[
                        config.widgets['widget1'].meta.key
                      ] || {}
                    ).metrics || {})[val]
                  "
                  :size="'l'"
                  class="u-display-inline-flex u-spacing-mt-s"
                  :config="config.widgets['widget1'].body.tagConfig"
                  :data="
                    ((
                      getInsightsReviewsData[
                        config.widgets['widget1'].meta.key
                      ] || {}
                    ).metrics || {})[val]
                  "
                />
                <span
                  v-if="!val"
                  class="u-font-size-6 u-color-grey-lighter"
                  >No Data</span
                >
              </div>
              <div
                v-if="
                  selectedShareCompBrand.length < 4 &&
                  selectedShareCompBrand.length < shareCompBrands.length
                "
                class="add-legend-button custom-chart-legend u-vertical-align-middle u-flex-align-items-center u-display-flex u-flex-direction-column"
              >
                <span @click="addMore">
                  <rb-icon
                    class="u-color-grey-x-light rb-icon--large u-cursor-pointer"
                    :icon="'add-circle-outline'"
                  />
                </span>
              </div>
            </div>
            <chart
              class="u-flex-1 u-spacing-mt-l"
              :config="config.widgets['widget1'].body.chart"
              :data="widget1Data"
            />
          </div>
        </div>
      </card-widget>

      <card-widget
        class="u-flex-1 u-spacing-mb-m u-spacing-mh-m"
        :filter-applied="widget2DateFilterApplied"
        :config="config.widgets['widget2']"
        :data="getInsightsReviewsData[config.widgets['widget2'].meta.key] || {}"
        :page-wise-min-max-key="pageWiseMinMaxKey"
      >
        <div slot="body">
          <div class="u-spacing-ph-l">
            <div class="u-display-flex u-flex-align-items-center">
              <div
                :style="{
                  'border-color':
                    assortmentColors[
                      '0' + selectedShareAssortmentClientBrand
                    ] || 'transparent'
                }"
                class="custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative"
              >
                <span
                  v-if="shareAssortmentClientBrands.length <= 0"
                  class="u-spacing-mr-m u-display-flex u-flex-align-items-center"
                >
                  <span class="u-font-size-5">My Brands</span>
                </span>
                <rb-select
                  v-if="shareAssortmentClientBrands.length > 0"
                  :width="'240px'"
                  class="u-spacing-mr-m"
                  :send-details="true"
                  :on-close="shareAssortmentClientBrandSelected"
                  :options="shareAssortmentClientBrands"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                  >
                    <div class="u-display-flex u-flex-align-items-center">
                      <span class="u-font-size-5">{{
                        selectedShareAssortmentClientBrand
                      }}</span>
                      <rb-icon
                        class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                        :icon="'caret-down'"
                      />
                    </div>
                  </div>
                </rb-select>
                <metric
                  v-if="
                    selectedShareAssortmentClientBrand &&
                    ((
                      getInsightsReviewsData[
                        config.widgets['widget2'].meta.key
                      ] || {}
                    ).metrics || {})[selectedShareAssortmentClientBrand]
                  "
                  :size="'l'"
                  class="u-display-inline-flex u-spacing-mt-s"
                  :config="config.widgets['widget2'].body.tagConfig"
                  :data="
                    ((
                      getInsightsReviewsData[
                        config.widgets['widget2'].meta.key
                      ] || {}
                    ).metrics || {})[selectedShareAssortmentClientBrand]
                  "
                />
                <span
                  v-if="!selectedShareAssortmentClientBrand"
                  class="u-font-size-6 u-color-grey-lighter"
                  >No Data</span
                >
              </div>
              <div
                v-for="(val, index) in selectedShareAssortmentCompBrand"
                :val="val"
                :style="{
                  'border-color': assortmentColors['1' + val] || 'transparent'
                }"
                class="custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative"
              >
                <span
                  v-if="
                    index + 1 === selectedShareAssortmentCompBrand.length &&
                    index > 0
                  "
                  class="removeLegend"
                  @click="removeAssortmentLegend(index)"
                >
                  <rb-icon
                    class="u-color-grey-x-light rb-icon--small u-cursor-pointer"
                    :icon="'cross'"
                  />
                </span>
                <rb-select
                  v-if="val"
                  :width="'240px'"
                  class="u-spacing-mr-m"
                  :context="[index]"
                  :send-details="true"
                  :on-close="shareAssortmentCompBrandSelected"
                  :options="shareAssortmentCompBrands"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-cursor-pointer"
                  >
                    <div class="u-display-flex u-flex-align-items-center">
                      <span class="u-font-size-5">{{ val }}</span>
                      <rb-icon
                        class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                        :icon="'caret-down'"
                      />
                    </div>
                  </div>
                </rb-select>
                <metric
                  v-if="
                    val &&
                    ((
                      getInsightsReviewsData[
                        config.widgets['widget2'].meta.key
                      ] || {}
                    ).metrics || {})[val]
                  "
                  :size="'l'"
                  class="u-display-inline-flex u-spacing-mt-s"
                  :config="config.widgets['widget2'].body.tagConfig"
                  :data="
                    ((
                      getInsightsReviewsData[
                        config.widgets['widget2'].meta.key
                      ] || {}
                    ).metrics || {})[val]
                  "
                />
                <span
                  v-if="!val"
                  class="u-font-size-6 u-color-grey-lighter"
                  >No Data</span
                >
              </div>
              <div
                v-if="
                  selectedShareAssortmentCompBrand.length < 4 &&
                  selectedShareAssortmentCompBrand.length <
                    shareAssortmentCompBrands.length
                "
                class="add-legend-button custom-chart-legend u-vertical-align-middle u-flex-align-items-center u-display-flex u-flex-direction-column"
              >
                <span @click="addMoreAssortment">
                  <rb-icon
                    class="u-color-grey-x-light rb-icon--large u-cursor-pointer"
                    :icon="'add-circle-outline'"
                  />
                </span>
              </div>
            </div>
            <chart
              style="height: 150px"
              class="u-flex-1 u-spacing-mt-l"
              :config="barChartConfigs"
              :data="widget2Data"
            />
          </div>
        </div>
      </card-widget>

      <card-widget
        class="searchPerformanceTable u-flex-1 u-spacing-mh-m u-spacing-mb-m"
        :config="config.widgets['widget3']"
        :data="getInsightsReviewsData[config.widgets['widget3'].meta.key] || {}"
        :page-wise-min-max-key="pageWiseMinMaxKey"
      >
        <div slot="body">
          <div>
            <rb-insights-table
              ref="SKU_Level_Breakdown_Table"
              :config="config.widgets['widget3']"
              :grid-options="gridOptions"
              :table-row="
                (
                  getInsightsReviewsData[config.widgets['widget3'].meta.key] ||
                  {}
                ).rows
              "
              :table-column="config.widgets['widget3'].body.columnDefs"
              style="width: 100%"
              :row-height="80"
              :enable-client-side-sorting="false"
              :enable-server-side-sorting="true"
              :pagination="true"
              :pagination-total-key="
                (
                  getInsightsReviewsData[config.widgets['widget3'].meta.key] ||
                  {}
                ).totalRows
              "
              :pagination-per-page-limit="
                config.widgets['widget3'].body.APIConfig.limit
              "
              :download-this-table="true"
            />
          </div>
        </div>
      </card-widget>
    </section>
  </div>
</template>

<script>
import chart from '@/components/basic/chart';
import widgetsUtil from '@/components/widgetMixin';
import widgetsConfig from './widgets';
import loader from '@/components/basic/loader';
import cardWidget from '@/components/widgets/cardWidget';
import metric from '@/components/basic/metric';
import renderColumnHeaders from '@/utils/services/el-table-column-header';
import starRating from '@/components/widgets/starRatings';
import rbPagination from '@/components/basic/pagination';
import Vue from 'vue';

var oObject = {
  beforeCreate() {
    this.config = widgetsConfig;
  },
  mixins: [widgetsUtil],
  created() {
    // this.$store.dispatch('getReviewsFilters');
    this.getReviewCount();
  },
  computed: {
    barChartConfigs() {
      var config = this.config.widgets.widget2.body.chart;
      config.groups = [this.barChartGroups];
      return config;
    },
    getInsightsReviewsData() {
      return this.$store.getters.getInsightsReviewsData;
    },
    widget1Data: {
      get: function () {
        var storeData =
          this.$store.getters.getInsightsReviewsData[
            this.config.widgets.widget1.meta.key
          ] || {};
        var widgetData = this.$store.getters.getReviewCountData;
        var aReturn = [];
        if (widgetData && Object.keys(widgetData).length > 0) {
          this.shareClientBrands = [];
          this.shareCompBrands = [];
          for (var i in widgetData) {
            if (
              widgetData[i] &&
              widgetData[i].data &&
              widgetData[i].data.brand &&
              widgetData[i].data.client_flag !== 'comp'
            ) {
              if (i && i.length > 0) {
                this.shareClientBrands.push({
                  title: i
                });
              }
            } else {
              if (i && i.length > 0 && i.toLowerCase() !== 'unknown') {
                this.shareCompBrands.push({
                  title: i
                });
              }
            }
          }
        }

        if (
          widgetData &&
          Object.keys(widgetData).length > 0 &&
          !this.selectedFromDropdownShare
        ) {
          if (this.shareClientBrands.length > 0) {
            if (
              this.reviewCountLegendAdded === false &&
              (this.selectedShareClientBrand === null ||
                this.$store.getters.getReviewCountLegendReset === true)
            ) {
              this.selectedShareClientBrand = this.shareClientBrands[0].title;
            }
          } else {
            this.selectedShareClientBrand = null;
          }

          for (var ij = 0; ij < this.selectedShareCompBrand.length; ij++) {
            if (this.shareCompBrands.length > ij) {
              if (
                this.$store.getters.getReviewCountLegendReset === true &&
                this.reviewCountLegendAdded === false
              ) {
                this.selectedShareCompBrand[ij] =
                  this.shareCompBrands[ij].title;
              }
              if (this.reviewCountLegendAdded === true) {
                if (this.selectedShareCompBrand[ij] === null) {
                  this.selectedShareCompBrand[ij] =
                    this.shareCompBrands[ij].title;
                }
              }
              // this.selectedShareCompBrand[ij] = this.shareCompBrands[ij].title;
            } else {
              this.selectedShareCompBrand[ij] = null;
            }
          }

          if (this.reviewCountLegendAdded === true) {
            this.reviewCountLegendAdded = false;
          }
          if (
            this.selectedShareCompBrand.length > this.shareCompBrands.length
          ) {
            var bPoint = -1;
            for (i = 0; i < this.selectedShareCompBrand.length; i++) {
              if (this.shareCompBrands[i] === undefined) {
                bPoint = i;
                break;
              }
            }
            this.selectedShareCompBrand = this.selectedShareCompBrand.slice(
              0,
              bPoint
            );
          } else if (
            this.selectedShareCompBrand.length === this.shareCompBrands.length
          ) {
            for (i = 0; i < this.shareCompBrands.length; i++) {
              this.selectedShareCompBrand[i] = this.shareCompBrands[i].title;
            }
          }
        }
        if (
          storeData &&
          storeData.load !== undefined &&
          storeData.load === false
        ) {
          this.selectedFromDropdownShare = false;
        }
        var colors = {};
        var colorIndex = 0;

        if (this.selectedShareClientBrand) {
          for (var jKey in widgetData[this.selectedShareClientBrand]) {
            if (jKey !== 'data') {
              aReturn.push(widgetData[this.selectedShareClientBrand][jKey]);
              if (!colors['0' + this.selectedShareClientBrand]) {
                colors['0' + this.selectedShareClientBrand] =
                  this.getColorPattern[colorIndex];
                colorIndex++;
              }
            }
          }
        }

        for (ij = 0; ij < this.selectedShareCompBrand.length; ij++) {
          if (
            this.selectedShareClientBrand &&
            this.selectedShareCompBrand[ij]
          ) {
            // for (var j in widgetData[this.selectedShareClientBrand]) {
            //   if (j !== 'data') {
            //     aReturn.push(widgetData[this.selectedShareClientBrand][j])
            //     if (!colors['0' + this.selectedShareClientBrand]) {
            //       colors['0' + this.selectedShareClientBrand] = this.getColorPattern[colorIndex];
            //       colorIndex++;
            //     }
            //   }
            // }

            for (var j in widgetData[this.selectedShareCompBrand[ij]]) {
              if (j !== 'data') {
                aReturn.push(widgetData[this.selectedShareCompBrand[ij]][j]);
                if (!colors['1' + this.selectedShareCompBrand[ij]]) {
                  colors['1' + this.selectedShareCompBrand[ij]] =
                    this.getColorPattern[colorIndex];
                  colorIndex++;
                }
              }
            }
          }
        }
        this.reviewCountColors = colors;
        if (storeData && storeData.data) {
          storeData.data.data = aReturn;
          storeData.data.colors = colors;
        }
        return Vue.util.extend({}, storeData.data);
      }
    },

    widget2Data: {
      get: function () {
        var storeData =
          this.$store.getters.getInsightsReviewsData[
            this.config.widgets.widget2.meta.key
          ] || {};
        var widgetData = this.$store.getters.getAssortmentAverageData;
        var aReturn = [];
        if (widgetData && Object.keys(widgetData).length > 0) {
          this.shareAssortmentClientBrands = [];
          this.shareAssortmentCompBrands = [];
          for (var i in widgetData) {
            if (
              widgetData[i] &&
              widgetData[i].data &&
              widgetData[i].data.brand &&
              widgetData[i].data.client_flag !== 'comp'
            ) {
              if (i && i.length > 0) {
                this.shareAssortmentClientBrands.push({
                  title: i
                });
              }
            } else {
              if (i && i.length > 0 && i.toLowerCase() !== 'unknown') {
                this.shareAssortmentCompBrands.push({
                  title: i
                });
              }
            }
          }
        }

        if (
          widgetData &&
          Object.keys(widgetData).length > 0 &&
          !this.selectedAssortmentFromDropdownShare
        ) {
          if (this.shareAssortmentClientBrands.length > 0) {
            if (
              this.assortmentLegendAdded === false &&
              (this.selectedShareAssortmentClientBrand === null ||
                this.$store.getters.getAssortmentLegendReset === true)
            ) {
              this.selectedShareAssortmentClientBrand =
                this.shareAssortmentClientBrands[0].title;
            }
          } else {
            this.selectedShareAssortmentClientBrand = null;
          }
          for (
            var ij = 0;
            ij < this.selectedShareAssortmentCompBrand.length;
            ij++
          ) {
            // if (this.shareAssortmentCompBrands.length > ij) {
            //   if (this.selectedShareAssortmentCompBrand[ij] === null) {
            //     this.selectedShareAssortmentCompBrand[ij] = this.shareAssortmentCompBrands[ij].title;
            //   }
            // } else {
            //   this.selectedShareAssortmentCompBrand[ij] = null;
            // }
            if (this.shareAssortmentCompBrands.length > ij) {
              if (
                this.$store.getters.getAssortmentLegendReset === true &&
                this.assortmentLegendAdded === false
              ) {
                this.selectedShareAssortmentCompBrand[ij] =
                  this.shareAssortmentCompBrands[ij].title;
              }
              if (this.assortmentLegendAdded === true) {
                if (this.selectedShareAssortmentCompBrand[ij] === null) {
                  this.selectedShareAssortmentCompBrand[ij] =
                    this.shareAssortmentCompBrands[ij].title;
                }
              }
              // this.selectedShareCompBrand[ij] = this.shareCompBrands[ij].title;
            } else {
              this.selectedShareCompBrand[ij] = null;
            }
          }

          if (this.assortmentLegendAdded === true) {
            this.assortmentLegendAdded = false;
          }
          if (
            this.selectedShareAssortmentCompBrand.length >
            this.shareAssortmentCompBrands.length
          ) {
            var bPoint = -1;
            for (i = 0; i < this.selectedShareAssortmentCompBrand.length; i++) {
              if (this.shareAssortmentCompBrands[i] === undefined) {
                bPoint = i;
                break;
              }
            }
            this.selectedShareAssortmentCompBrand =
              this.selectedShareAssortmentCompBrand.slice(0, bPoint);
          } else if (
            this.selectedShareAssortmentCompBrand.length ===
            this.shareAssortmentCompBrands.length
          ) {
            for (i = 0; i < this.shareAssortmentCompBrands.length; i++) {
              this.selectedShareAssortmentCompBrand[i] =
                this.shareAssortmentCompBrands[i].title;
            }
          }
        }
        if (
          storeData &&
          storeData.load !== undefined &&
          storeData.load === false
        ) {
          this.selectedAssortmentFromDropdownShare = false;
        }

        var colors = {};
        var colorIndex = 0;

        if (this.selectedShareAssortmentClientBrand) {
          for (var jKey in widgetData[
            this.selectedShareAssortmentClientBrand
          ]) {
            if (jKey !== 'data') {
              aReturn.push(
                widgetData[this.selectedShareAssortmentClientBrand][jKey]
              );
              if (!colors['0' + this.selectedShareAssortmentClientBrand]) {
                colors['0' + this.selectedShareAssortmentClientBrand] =
                  this.getColorPattern[colorIndex];
                colorIndex++;
              }
            }
          }
        }

        for (
          var jk = 0;
          jk < this.selectedShareAssortmentCompBrand.length;
          jk++
        ) {
          if (
            this.selectedShareAssortmentClientBrand &&
            this.selectedShareAssortmentCompBrand[jk]
          ) {
            // for (var j in widgetData[this.selectedShareAssortmentClientBrand]) {
            //   if (j !== 'data') {
            //     aReturn.push(widgetData[this.selectedShareAssortmentClientBrand][j])
            //     if (!colors['0' + this.selectedShareAssortmentClientBrand]) {
            //       colors['0' + this.selectedShareAssortmentClientBrand] = this.getColorPattern[colorIndex];
            //       colorIndex++;
            //     }
            //   }
            // }

            for (var j in widgetData[
              this.selectedShareAssortmentCompBrand[jk]
            ]) {
              if (j !== 'data') {
                aReturn.push(
                  widgetData[this.selectedShareAssortmentCompBrand[jk]][j]
                );
                if (!colors['1' + this.selectedShareAssortmentCompBrand[jk]]) {
                  colors['1' + this.selectedShareAssortmentCompBrand[jk]] =
                    this.getColorPattern[colorIndex];
                  colorIndex++;
                }
              }
            }
          }
        }

        if (storeData && storeData.data) {
          storeData.data.data = aReturn;
          storeData.data.data.splice(0, 0, [
            'x',
            '4-5 Stars',
            '3-4 Stars',
            '2-3 Stars',
            '1-2 Stars',
            '0 Star'
          ]);
        }
        var groups = [this.selectedShareAssortmentClientBrand].concat(
          JSON.parse(JSON.stringify(this.selectedShareAssortmentCompBrand))
        );
        var objTopass = {};
        this.assortmentColors = colors;
        if (storeData.data !== undefined) {
          storeData.data.colors = colors;
          objTopass = {
            data: storeData.data.data,
            groups: [groups]
          };
        }
        return Vue.util.extend({}, objTopass);
      }
    }
  },
  watch: {
    selectedShareAssortmentCompBrand: function (val) {
      var groups = [this.selectedShareAssortmentClientBrand];
      for (var i = 0; i < val.length; i++) {
        groups.push(val[i]);
      }
      this.barChartGroups = groups;
    }
  },
  data() {
    return {
      filterState: {
        getter: 'getInsightsReviewsSelectedFilter',
        setter: 'setInsightsReviewsSelectedFilter'
      },
      shareClientBrands: [],
      shareCompBrands: [],
      selectedShareClientBrand: null,
      selectedShareCompBrand: [null, null],
      shareAssortmentClientBrands: [],
      shareAssortmentCompBrands: [],
      selectedShareAssortmentClientBrand: null,
      selectedShareAssortmentCompBrand: [null, null],
      barChartGroups: [null, null],
      widget2Chart: this.config.widgets.widget2.body.chart,
      skuLevelBreakdownLimit: this.config.widgets.widget3.body.APIConfig.limit,
      widget1LegendsFirstLoad: true,
      reviewCountLegendAdded: false,
      widget2LegendsFirstLoad: false,
      assortmentLegendAdded: false,
      reviewCountColors: {},
      assortmentColors: {},
      gridOptions: {
        context: {
          componentParent: this
        }
      },
      pageWiseMinMaxKey: 'review_ratings'
    };
  },
  methods: {
    widget1DateFilterApplied(type) {
      if (type === 'date') {
        // this.selectedFromDropdownShare = true;
        if (this.widget1LegendsFirstLoad === false) {
          this.$store.dispatch('setReviewCountLegendDateApplied', true);
        }
        this.widget1LegendsFirstLoad = false;
      }
    },
    widget2DateFilterApplied(type) {
      if (type === 'date') {
        if (this.widget2LegendsFirstLoad === false) {
          this.$store.dispatch('setAssortmentLegendDateApplied', true);
        }
        this.widget2LegendsFirstLoad = false;
      }
    },
    // Add columnKey to el-table-column as widget1/ widget3 etc pagination , sorting and tooltip is handled with this key.
    // Below 3 functions can be pulled out into a tableMixin.
    sortTable(column, event) {
      if (column && column.column) {
        var widget = column.column.columnKey;
        var sortOrder = this.config.widgets[widget].body.APIConfig.orderByList;

        var currentOrder = null;
        if (column.order !== null) {
          currentOrder = column.order === 'ascending' ? 'ASC' : 'DESC';
          sortOrder = [
            {
              dimension: column.prop,
              direction: currentOrder
            }
          ];
        }

        this.config.widgets[widget].body.APIConfig.orderByList = sortOrder;
        this.config.widgets[widget].body.APIConfig.page = 1;
        var action = this.config.widgets[widget].meta.action;
        if (this.config.widgets[widget].meta.alternateAction) {
          action = this.config.widgets[widget].meta.alternateAction;
        }
        this.$store.dispatch(action, this.config.widgets[widget]);
      }
    },
    appendToolTip(h, { column, $index }) {
      var widget = column.columnKey;
      var tooltipTexts = this.config.widgets[widget].body.tooltipTexts || [];
      return renderColumnHeaders.renderHeader(
        h,
        column,
        true,
        tooltipTexts[$index]
      );
    },
    shareCompBrandSelected(context, selection) {
      if (selection && selection.length > 0) {
        this.selectedShareCompBrand[context[0]] = selection[0].title;
        this.selectedShareCompBrand.splice(0, 0);
        // this.selectedShareCompBrand.$set(context[0], selection[0].title);
        this.selectedFromDropdownShare = true;
      }
    },

    shareAssortmentCompBrandSelected(context, selection) {
      if (selection && selection.length > 0) {
        this.selectedShareAssortmentCompBrand[context[0]] = selection[0].title;
        this.selectedShareAssortmentCompBrand.splice(0, 0);
        // this.selectedShareCompBrand.$set(context[0], selection[0].title);
        this.selectedAssortmentFromDropdownShare = true;
      }
    },

    shareClientBrandSelected(context, selection) {
      if (selection && selection.length > 0) {
        this.selectedShareClientBrand = selection[0].title;
        this.selectedFromDropdownShare = true;
      }
    },

    shareAssortmentClientBrandSelected(context, selection) {
      if (selection && selection.length > 0) {
        this.selectedShareAssortmentClientBrand = selection[0].title;
        this.selectedAssortmentFromDropdownShare = true;
      }
    },
    addMore() {
      this.reviewCountLegendAdded = true;
      this.selectedShareCompBrand.push(null);
    },
    addMoreAssortment() {
      this.assortmentLegendAdded = true;
      this.selectedShareAssortmentCompBrand.push(null);
    },
    removeLegend(index) {
      this.$store.dispatch('setReviewCountLegendReset', false);
      this.$delete(this.selectedShareCompBrand, index);
    },
    removeAssortmentLegend(index) {
      this.$store.dispatch('setAssortmentLegendReset', false);
      this.$delete(this.selectedShareAssortmentCompBrand, index);
    },
    getReviewCount() {
      this.$store.dispatch('updateLocalFilterSelectedValue', {
        key: 'starRatings',
        value: ['Rating: All']
      });
      // this.config.widgets['widget1'].body.APIConfig.measuresList = ['total_new_reviews'];
      // this.$store.dispatch('getReviewCount', this.config.widgets['widget1']);
    },
    getSkuLevelBreakdownData() {
      this.$store.dispatch(
        'getSkuLevelBreakdownData',
        this.config.widgets.widget3
      );
    }
  },
  components: {
    chart,
    loader,
    cardWidget,
    metric,
    starRating,
    rbPagination
  }
};

export default oObject;
</script>
<style lang="css">
.add-legend-button.custom-chart-legend {
  border-top: 0 !important;
}

.removeLegend {
  position: absolute;
  right: 5px;
  top: 8px;
}

.custom-chart-legend:hover .removeLegend {
  display: flex;
}
</style>
