function setInlineManualCallbacks() {
  window.inm_callbacks = {
    // To Mimic a  click on behalf of the user.
    inmClickMimic: function (playerId, topicId, stepId, customData) {
      console.log(
        'Step #' + stepId + ' from topic ' + topicId + ' was activated.'
      );
      if (customData && customData.element) {
        document.querySelector(customData.element).click();
      }
    },
    // Move to Next Step Automatically after X seconds
    inmDelayNext: function (playerId, topicId, stepId, customData) {
      if (customData && customData.delay === undefined) customData.delay = 0;
      if (customData && customData.delay) {
        customData.delay = customData.delay * 1000;
        setTimeout(function () {
          window.inline_manual_player.goToStep('next');
        }, customData.delay);
      }
    }
  };
}

export default {
  init(apiKey, user) {
    var d = document;
    var script = d.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.charset = 'UTF-8';
    script.src = 'https://inlinemanual.com/embed/player.' + apiKey + '.bare.js';
    script.onload = function () {
      if (window.createInlineManualPlayer && window.inlineManualPlayerData) {
        window.inlineManualTracking = {
          uid: user.email,
          email: user.email,
          username: user.email.split('@')[0],
          roles: user.roles,
          plan: window.configs.client.feature.productHeader
        };
        window.createInlineManualPlayer(window.inlineManualPlayerData);
        window.inline_manual_player.setOptions({ hide_trigger: true }); // Hide Default Inline manual help button.
        setInlineManualCallbacks();
      }
    };
    d.getElementsByTagName('head')[0].appendChild(script);
  }
};
