export default {
  install(Vue, options) {
    Vue.prototype.isNull = (value) => {
      if (value || value === 0) {
        return false;
      }
      return true;
    };
    Vue.prototype.isObject = (value) => {
      return value.constructor === Object;
    };
    Vue.prototype.isArray = (value) => {
      return value.constructor === Array;
    };
    Vue.prototype.isEmptyObj = (value) => {
      if (Vue.prototype.isNull(value)) {
        return true;
      } else {
        return !(
          Vue.prototype.isObject(value) && !(Object.keys(value).length === 0)
        );
      }
    };
    Vue.prototype.getDisplayValue = (value, replace) => {
      return Vue.prototype.isNull(value) ? replace || 'NA' : value;
    };
  }
};
