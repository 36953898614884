<template>
  <card-widget
    class="u-flex-1 u-spacing-mb-m u-bg-color-grey-white"
    :config="config.widgets['widget2']"
    :data="tableData"
  >
    <div
      slot="header"
      class="u-display-flex u-flex-1"
      data-cy="tableHeaders"
    >
      <div
        class="u-display-flex u-width-100 u-flex-align-items-center u-flex-justify-content-space-between"
        data-cy="tableHeader"
      >
        <div
          class="u-font-size-3 u-font-weight-600 u-color-grey-lighter"
          :data-cy="`tableHeaderTitle-${config.widgets['widget2'].header.title}`"
        >
          {{ config.widgets['widget2'].header.title }}
        </div>
      </div>
    </div>
    <div slot="body">
      <div class="u-display-flex u-flex-wrap-yes bpTable">
        <rb-insights-table
          v-if="!tableData.load"
          :config="((config || {}).widgets || {})['widget2'] || {}"
          :grid-options="
            ((((config || {}).widgets || {})['widget2'] || {}).body || {})
              .gridOptions
          "
          :table-row="tableData.rows"
          :table-column="
            ((((config || {}).widgets || {})['widget2'] || {}).body || {})
              .columnDefs
          "
          :row-height="50"
          :enable-client-side-sorting="false"
          :enable-server-side-sorting="false"
          :pagination="false"
          :show-pagination-options="false"
          :has-expand="true"
          :has-default-row-expand="true"
          :levels="levels"
          :expand-cell-width="60"
        />
      </div>
    </div>
    <div
      slot="footer"
      class="u-color-grey-x-light u-font-size-7"
    >
      <div class="u-flex-align-items-center u-display-flex">
        <div class="u-spacing-mr-xs">
          Last Updated :
          {{
            ((((config || {}).widgets || {})['widget2'] || {})['footer'] || {})[
              'getMaxDateDashboard'
            ]
          }}
        </div>
        <rb-icon
          v-tippy="tippy"
          :title="getRetailerMessage"
          class="rb-icon--small tool-tip-hover-budget-planner u-cursor-pointer"
          icon="info-circle-fill"
        />
      </div>
    </div>
  </card-widget>
</template>

<script>
import widgetsConfig from '@/pages/budget-planner-v2/dashboard/widget.js';
import cardWidget from '@/components/widgets/cardWidget';
import rbDownloadButton from '@/components/widgets/rbDownloadButton';
import { retailerMessage } from '@/pages/budget-planner-v2/dashboard/retailerConfig.js';
import customHeaderComponent from '@/pages/budget-planner-v2/dashboard/widgets/table/components/customHeaderComponent.vue';
import CustomNumericComponent from '@/pages/budget-planner-v2/dashboard/widgets/table/components/customNumericComponent.vue';
import customStringComponent from '@/pages/budget-planner-v2/dashboard/widgets/table/components/customStringComponent.vue';
import monthToDateProgressTable from '@/pages/budget-planner-v2/dashboard/widgets/table/components/monthToDateProgressTable.vue';
import progressHeaderComponent from '@/pages/budget-planner-v2/dashboard/widgets/table/components/progressHeaderComponent.vue';
import Vue from 'vue';

export default {
  components: {
    cardWidget,
    rbDownloadButton
  },
  data: () => ({
    footerDetails: [
      {
        name: 'Overspending',
        color: 'rgba(217, 38, 62, 0.30)'
      },
      {
        name: 'Underspending',
        color: 'rgba(62, 169, 94, 0.30)'
      }
    ],
    gridOptions: {
      context: {
        componentParent: this
      }
    },
    widgetsConfig: {},
    config: {},
    message: 'Amazon data is typically delayed by upto 24 hours.',
    tippy: {
      placement: 'right',
      arrow: false,
      distance: 8,
      popperOptions: {
        modifiers: {
          preventOverflow: {
            enabled: false
          }
        }
      }
    },
    levels: [
      {
        level: 1,
        getter: 'budgetPlannerDashboardV2/getBudgetPlannerDashboardLevel1',
        action:
          'budgetPlannerDashboardV2/createBudgetPlannerDashboardTableLevel1'
      },
      {
        level: 2,
        getter: 'budgetPlannerDashboardV2/getBudgetPlannerDashboardLevel2',
        action:
          'budgetPlannerDashboardV2/createBudgetPlannerDashboardTableLevel2'
      }
    ]
  }),
  computed: {
    getRetailerMessage() {
      const retailer = this.$store.getters.getRetailer;
      return retailerMessage[retailer];
    },
    tableLoader() {
      return this.$store.getters[
        'budgetPlannerDashboardV2/getBPDashboardTableLoader'
      ];
    },
    tableData() {
      console.log(
        this.$store.getters[
          'budgetPlannerDashboardV2/getBudgetPlannerDashboardEntireBusiness'
        ]
      );
      return this.$store.getters[
        'budgetPlannerDashboardV2/getBudgetPlannerDashboardEntireBusiness'
      ];
    }
  },
  created() {
    Vue.component('customHeaderComponent', customHeaderComponent);
    Vue.component('CustomNumericComponent', CustomNumericComponent);
    Vue.component('customStringComponent', customStringComponent);
    Vue.component('monthToDateProgressTable', monthToDateProgressTable);
    Vue.component('progressHeaderComponent', progressHeaderComponent);

    this.widgetsConfig = widgetsConfig;
    this.parentRef = this;
    this.config = widgetsConfig.config(this);
    if (this.$store.getters.getRetailer === 'amazon') {
      this.$store.dispatch(
        'budgetPlannerDashboardV2/budgetPlanMTDProfilesTooltipContent',
        this.config.widgets.widget2
      );
    }
  }
};
</script>

<style lang="css">
.bpTable {
  min-height: 120px;
}
.bpTable .ag-cell {
  padding: 0px !important;
}
.bpTable .table-cell-component {
  padding: 16px;
}
.bpTable .ag-header {
  height: 80px !important;
}
.bpTable .ag-header-row {
  height: 80px !important;
}
.bpTable .ag-expand-cell {
  padding: 16px;
}
.bpTable .budgetPlanIQTipRowHover {
  visibility: hidden;
}
.bpTable .ag-row-hover .budgetPlanIQTipRowHover {
  visibility: visible;
  display: flex;
}
.tool-tip-hover-budget-planner {
  color: palette(blue);
}
.tool-tip-hover-budget-planner:hover {
  color: #2690f8;
}
</style>
