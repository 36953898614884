










































import Vue, { PropType } from 'vue';
// @ts-ignore
import loader from '@/components/basic/loader';
// @ts-ignore
import childMultiLevelSelector from '@/components/widgets/filter/child-multi-level-selector';
import { SelectorGroup } from '@/components/widgets/filter/selector/selector-group';
import { Category } from '@/components/widgets/filter/selector/backend-type';
import { constructGroupsFromHierarichalData } from '@/components/widgets/filter/selector/builder';
import {
  UIState,
  Selector,
  DisabledHook
} from '@/components/widgets/filter/selector/selector';

export default Vue.extend({
  name: 'MultiLevelSelectorDropdown',
  components: {
    loader,
    childMultiLevelSelector
  },
  props: {
    spawnInSameDirection: {
      type: Boolean,
      default: false
    },
    parentTippyId: {
      type: String,
      default: ''
    },
    initSelections: {
      type: Array as PropType<string[]>,
      default: () => {
        return [];
      }
    },
    filterData: {
      type: Object as PropType<Category | undefined>,
      default: () => {
        return {};
      }
    },
    topLevelMulti: {
      type: Boolean,
      default: true
    },
    selectorDisabledHook: {
      type: Function as PropType<DisabledHook>,
      default: undefined
    }
  },
  data() {
    return {
      selections: undefined as UIState[] | undefined,
      selectionGroup: undefined as SelectorGroup<Selector> | undefined
    };
  },
  computed: {
    filterDataCount(): number {
      return Object.keys(this.filterData || {}).length;
    }
  },
  watch: {
    filterData: {
      immediate: true,
      handler(newVal, oldVal): void {
        if (this.filterData) {
          const selectionGroup = constructGroupsFromHierarichalData(
            this.filterData,
            this.topLevelMulti,
            null,
            this.selectorDisabledHook
          );
          const selections: { [key: string]: boolean } = {};
          for (let selection of this.initSelections) {
            selections[selection] = true;
          }
          selectionGroup.applySelections(selections);
          this.selectionGroup = selectionGroup;
          this.selections = this.selectionGroup?.uiState();
        }
      }
    }
  },
  methods: {
    selectionHandler() {
      this.selections = this.selectionGroup?.uiState();
      this.$emit('selectionsChanged', this.selectionGroup?.selections);
    }
  }
});
