<template>
  <span
    class="u-spacing-mr-s u-display-inline-flex u-cursor-pointer u-position-relative u-height-100"
    :class="wrapperClass"
    @click.stop="triggerAPI()"
  >
    <rb-icon
      v-if="isBookmarked"
      v-tippy="tippyConfig"
      title="Remove from My Workspace"
      icon="star u-color-orange-base"
      data-cy="bookmarkedIcon"
      :class="iconClass"
    />
    <rb-icon
      v-if="!isBookmarked"
      v-tippy="tippyConfig"
      title="Add to My Workspace"
      icon="star-empty u-color-orange-base"
      data-cy="bookmarkIcon"
      :class="iconClass"
    />

    <!-- <loader
      :class="iconClass"
      :loading="isLoading"
      :color="'#007cf6'"
    /> -->
  </span>
</template>

<script>
import loader from '@/components/basic/loader';
import { debounce } from 'lodash';

export default {
  name: 'Bookmark',
  author: 'Rajan V',
  components: {
    loader
  },
  props: {
    iconClass: {
      type: String,
      default: 'rb-icon--x-medium'
    },
    path: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    wrapperClass: {
      type: String,
      default: ''
    },
    placement: {
      type: String,
      default: 'top'
    }
  },
  data() {
    return {
      tippyConfig: {
        placement: this.placement,
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    isBookmarked() {
      let path = this.path || this.$route.fullPath || this.$route.path;
      if (path?.charAt(path?.length - 1) === '/') {
        path = path.slice(0, -1);
      }
      const bookmark = this.$store.getters['bookmarkStore/getAllBookmarks'];
      const index = bookmark.findIndex((item) => item.path === path);
      return index !== -1;
    },
    isLoading() {
      return this.$store.getters['bookmarkStore/isLoading'];
    }
  },
  methods: {
    debounceMethod: debounce(function () {
      if (!this.isBookmarked) {
        this.bookmark();
      } else {
        this.removeBookmark();
      }
    }, 500),
    triggerAPI() {
      if (!this.isLoading) {
        if (!this.isBookmarked) {
          this.bookmark();
        } else {
          this.removeBookmark();
        }
      }
    },
    bookmark() {
      if (this.isLoading) {
        return;
      }
      const payload = {
        path: this.path || this.$route.fullPath || this.$route.path,
        title: this.title || ''
      };
      if (payload.path?.charAt(payload.path?.length - 1) === '/') {
        payload.path = payload.path.slice(0, -1);
      }
      this.$store
        .dispatch('bookmarkStore/addBookmark', payload)
        .then(() => {
          this.$logger.logEvent({
            eventType: 'add_bookmark',
            type: 'Click',
            page: `${payload.path}`,
            eventContext: 'Bookmark added'
          });
          this.$snackbar.open({
            message: 'Bookmark added successfully',
            duration: 2000,
            actionText: ''
          });
        })
        .catch((error) => {
          this.$snackbar.open({
            message: 'Something went wrong. Try again later' || error.msg,
            duration: 5000,
            actionText: ''
          });
        });
    },
    removeBookmark() {
      if (this.isLoading) {
        return;
      }
      const path = this.path || this.$route.path;
      this.$store
        .dispatch('bookmarkStore/removeBookmark', path)
        .then(() => {
          this.$logger.logEvent({
            eventType: 'remove_bookmark',
            type: 'Click',
            page: `${path}`,
            eventContext: 'Bookmark removed'
          });
          this.$snackbar.open({
            message: 'Bookmark removed successfully',
            duration: 2000,
            actionText: ''
          });
        })
        .catch((error) => {
          this.$snackbar.open({
            message: 'Something went wrong' || error.msg,
            duration: 5000,
            actionText: ''
          });
        });
    }
  }
};
</script>
