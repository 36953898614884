import { addSkuOnInput } from '@/pages/entity_details/configs/walmart/skuEnterListHelpers.js';

const fetchSavedState = (context, getter) => {
  const state = context.$store.getters[getter];
  return state;
};

const updateSavedState = (context, action, data) => {
  context.$store.dispatch(action, data);
};

export function getRetailerNameInSentenceCase(vueRef) {
  const retailer = vueRef.$store.getters.getRetailer;
  if (retailer === 'walmart') {
    return 'Walmart';
  } else if (retailer === 'samsclub_api') {
    return "Sams's Club";
  }
}

export function getRetailer(vueRef) {
  return vueRef?.$store?.getters?.getRetailer || 'walmart';
}

const getCubeName = (vueRef) => {
  const retailer = vueRef.$store.getters.getRetailer;
  if (retailer === 'walmart') {
    return 'walmart_sku_catalog';
  } else if (retailer === 'samsclub_api') {
    return 'samsclub_api_sku_catalog';
  }
};

const selectSkuLeftTable = (vueRef) => {
  return [
    {
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        widget: 'sku',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'product_title',
          columnAsinKey: 'sku',
          columnImageUrlKey: 'image_url',
          coloumnProductUrlKey: 'product_url',
          columnAsinHoverText: `View ${getRetailerNameInSentenceCase(
            vueRef
          )} detail page`,
          copyClipboardText: 'Item ID copied to clipboard',
          columnDisableProductLink: true,
          showOnUi: true,
          isFixed: true,
          getQuickFilterText: function (params) {
            return params?.data?.product_title + '_' + params?.data?.asin;
          }
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: vueRef.addAll,
        uiTooltip: 'Add all the filtered items with the best availability.',
        customStyles: 'cursor:pointer; color:#037ef6 !important;',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all the filtered items with the best availability.'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add this SKU',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: vueRef.addEntity,
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 86
        }
      }
    },
    {
      name: 'offer_price',
      type: 'currency',
      uiField: {
        uiLabel: 'SKU Price',
        uiType: 'currency',
        uiOrder: '3',
        metadata: {
          toolTipText: '',
          tableColumnName: 'offer_price',
          alignHeader: 'left',
          showOnUi: true,
          isFixed: false,
          width: 90,
          minWidth: 90
        }
      }
    },
    {
      name: 'availability_status',
      type: 'STRING',
      uiField: {
        uiLabel: 'Availability',
        uiType: 'string',
        uiOrder: '4',
        metadata: {
          toolTipText: '',
          tableColumnName: 'availability_status',
          showOnUi: true,
          isFixed: false,
          width: 90
        }
      }
    },
    {
      name: 'buy_box_pct',
      type: 'STRING',
      uiField: {
        uiLabel: 'Buybox win %',
        uiType: 'string',
        uiOrder: '5',
        metadata: {
          toolTipText: '',
          tableColumnName: 'buy_box_pct',
          showOnUi: true,
          isFixed: false,
          width: 110
        }
      }
    }
  ];
};
const selectSkuRightTable = (vueRef) => {
  return [
    {
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'product_title',
          columnAsinKey: 'sku',
          columnImageUrlKey: 'image_url',
          coloumnProductUrlKey: 'product_url',
          columnAsinHoverText: `View ${getRetailerNameInSentenceCase(
            vueRef
          )} detail page`,
          showOnUi: true,
          isFixed: false
        }
      }
    },
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '2',
        uiTooltip: 'Remove all the added items from the Ad Group.',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove the item from the Ad Group.',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 110
        }
      }
    }
  ];
};

export const adGroupCustomActionPanel = (context) => ({
  component: 'customActionPanel',
  props: {
    widgetsConfig: {
      config: (vueRef) => {
        return {
          primaryKey: 'sku',
          customActionPanelContainerClass:
            'u-height-100 campaign-creation-add-section-min-height',
          isMultipleAllowed: true,
          default: {
            hideHeader: true,
            hideFooter: true,
            noRowsRightTable: "Add SKU's to this list",
            selections: '',
            noRowsLeftTable: "No SKU's sources found",
            leftBtnLabel: 'Apply',
            rightBtnLabel: 'Cancel',
            warningMessage: ''
          },
          left: {
            rowHeight: 68,
            tabs: {
              "All SKU's": {
                columnDefs: selectSkuLeftTable(vueRef),
                search: {
                  enableSearch: true,
                  searchKey: 'search_text',
                  placeholder: 'Enter Item ID, Item Name, or Brand Name'
                }
              },
              'Enter List': {
                disableFilter: true,
                ui_component: {
                  name: 'enterListPanel',
                  props: {
                    actionName: 'SKU',
                    buttonLabel: 'Add SKU',
                    emitTextInput: true,
                    entityCount: 10
                  },
                  events: {
                    updateTextInput(data) {
                      data.cubeName = getCubeName(vueRef);
                      addSkuOnInput(data);
                    }
                  }
                }
              }
            },
            isColumnDefAutoConfigure: true,
            table: 'left',
            meta: {
              type: 'table',
              action: 'fetchTableDataExecuteApi',
              updateRows: (vueRef) => {},
              localFilters: [],
              paginationAction: 'fetchTableDataExecuteApi'
            },
            footer: {
              show: false
            },
            body: {
              APIConfig: {
                cubeName: getCubeName(vueRef),
                getLatestAvailableInsteadOfRollup: false,
                timeseriesEnabled: false,
                pvpenabled: false,
                yoyenabled: false,
                measuresList: [
                  'product_title',
                  'buy_box_pct',
                  'availability_pct',
                  'availability_status',
                  'brand_name',
                  'offer_price',
                  'image_url',
                  'product_url'
                ],
                groupByDimensionsList: ['sku'],
                orderByList: [],
                where: {
                  dimensionNameValueList: []
                },
                limit: 10,
                page: 1
              },
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              columnDefs: selectSkuLeftTable(vueRef)
            },
            headerText: "Add SKU's"
          },
          right: {
            rowHeight: 68,
            table: 'right',
            searchKey: 'search',
            footer: {
              show: false
            },
            isColumnDefAutoConfigure: true,
            body: {
              gridOptions: {
                context: {
                  componentParent: vueRef
                },
                domLayout: 'normal'
              },
              APIConfig: {
                limit: 10,
                page: 1
              },
              columnDefs: selectSkuRightTable(vueRef)
            },
            updateRightTableRows(vueRef) {
              const allStepsData = fetchSavedState(
                context,
                context?.selectedValues?.getter
              );
              const newRightTableData = [];
              const { fetchedSkusResponse } = allStepsData;
              if (fetchedSkusResponse && fetchedSkusResponse.length > 0) {
                fetchedSkusResponse.forEach((item) => {
                  const newSku = {
                    product_title: item?.name,
                    image_url: item?.itemImageUrl,
                    product_url: item?.itemImageUrl,
                    sku: item?.itemId.toString(),
                    isRowSelected: true
                  };
                  newRightTableData.push(newSku);
                });
              }
              vueRef.rightTableRows = newRightTableData;
              vueRef.refreshLeftTableIsRowSelected();
            },
            headerText: () => `Added SKU's (${vueRef.rightTableRows.length})`
          },
          apply(rows = []) {}
        };
      }
    }
  },
  events: {
    rightTableRows(rows) {
      let allStepsData =
        fetchSavedState(context, context?.selectedValues?.getter) || {};
      allStepsData.skus = rows;
      allStepsData = { ...allStepsData };
      updateSavedState(context, context?.selectedValues?.action, allStepsData);
    },
    afterAPIActionToCall(payload) {},
    closePanelMethod() {}
  }
});

export const selectSkuLeftTableForAutoSP = (vueRef) => {
  return [
    {
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        widget: 'sku',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'product_title',
          columnAsinKey: 'sku',
          columnImageUrlKey: 'image_url',
          coloumnProductUrlKey: 'product_url',
          columnAsinHoverText: `View ${getRetailerNameInSentenceCase(
            vueRef
          )} detail page`,
          copyClipboardText: 'Item ID copied to clipboard',
          columnDisableProductLink: true,
          showOnUi: true,
          isFixed: true,
          getQuickFilterText: function (params) {
            return params?.data?.product_title + '_' + params?.data?.asin;
          }
        }
      }
    },
    {
      name: 'Add all',
      type: 'custom',
      uiField: {
        uiLabel: 'Add all',
        uiType: 'custom',
        clickHeader: vueRef.addAll,
        uiTooltip: 'Add all the filtered items with the best availability.',
        customStyles: 'cursor:pointer; color:#037ef6 !important;',
        headerIcon: {
          icon: 'info-circle-fill',
          toolTip: 'Add all the filtered items with the best availability.'
        },
        uiOrder: '2',
        metadata: {
          toolTipText: 'Add this SKU',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          suppressSizeToFit: true,
          isFixed: false,
          iconClickEvent: vueRef.addEntity,
          displayIcon: 'plus',
          alternateIcon: 'check-fill-circle',
          alternateIconClass: 'u-color-green-base',
          width: 86
        }
      }
    },
    {
      name: 'suggested_bid',
      type: 'currency',
      uiField: {
        uiLabel: 'Suggested Bid',
        uiType: 'currency',
        uiOrder: '3',
        uiTooltip: 'Suggested bid for the item.',
        metadata: {
          toolTipText: '',
          tableColumnName: 'suggested_bid',
          showOnUi: true,
          isFixed: false,
          width: 90,
          minWidth: 90,
          alignHeader: 'left'
        }
      }
    },
    {
      name: 'offer_price',
      type: 'currency',
      uiField: {
        uiLabel: 'SKU Price',
        uiType: 'currency',
        uiOrder: '4',
        metadata: {
          toolTipText: '',
          tableColumnName: 'offer_price',
          alignHeader: 'left',
          showOnUi: true,
          isFixed: false,
          width: 90,
          minWidth: 90
        }
      }
    },
    {
      name: 'availability_status',
      type: 'STRING',
      uiField: {
        uiLabel: 'Availability',
        uiType: 'string',
        uiOrder: '5',
        metadata: {
          toolTipText: '',
          tableColumnName: 'availability_status',
          showOnUi: true,
          isFixed: false,
          width: 90
        }
      }
    },
    {
      name: 'buy_box_pct',
      type: 'STRING',
      uiField: {
        uiLabel: 'Buybox win %',
        uiType: 'string',
        uiOrder: '6',
        metadata: {
          toolTipText: '',
          tableColumnName: 'buy_box_pct',
          showOnUi: true,
          isFixed: false,
          width: 110
        }
      }
    }
  ];
};
export const selectSkuRightTableForAutoSP = (vueRef) => {
  return [
    {
      name: 'sku_details',
      type: 'sku',
      uiField: {
        uiLabel: 'SKU Details',
        uiType: 'custom',
        uiOrder: '1',
        metadata: {
          widget: 'sku',
          toolTipText: '',
          tableColumnName: 'SKU Details',
          columnTitleKey: 'product_title',
          columnAsinKey: 'sku',
          columnImageUrlKey: 'image_url',
          coloumnProductUrlKey: 'product_url',
          columnAsinHoverText: `View ${getRetailerNameInSentenceCase(
            vueRef
          )} detail page`,
          showOnUi: true,
          isFixed: true
        }
      }
    },
    {
      name: 'Suggested bid',
      type: 'custom',
      uiField: {
        widget: 'icon',
        uiLabel: 'Suggested bid',
        uiType: 'custom',
        uiOrder: '2',
        uiTooltip: 'Suggested bid for the specific SKU.',
        metadata: {
          toolTipText: 'Set current item bid to its suggested bid.',
          widget: 'icon',
          type: 'iconText',
          tableColumnName: 'suggestedBidToShow',
          formatType: 'currency',
          displayIcon: 'trending-neutral',
          showOnUi: true,
          isFixed: false,
          width: 90,
          iconClickEvent: (context, event) => {
            const rowNodes = {
              rowNodes: [context.params.node]
            };
            if (!context.params.data.newBid) {
              context.params.data.newBid = context.params.data.suggested_bid;
            }
            vueRef.rightTableInstance.redrawTableRows(rowNodes);
          }
        }
      }
    },
    {
      name: 'newBid',
      type: 'custom',
      uiField: {
        uiLabel: 'Bid',
        uiOrder: '3',
        uiType: 'custom',
        uiTooltip: 'CPC bid for the product. Minimum $0.20 for auto campaigns.',
        metadata: {
          toolTipText: '',
          widget: 'input',
          type: 'number',
          tableColumnName: 'newBid',
          defaultValueColumnName: 'newBid',
          showOnUi: true,
          isFixed: false,
          formatType: 'currency',
          width: 110,
          keyupEvent: '',
          onchangeEvent: (context, event) => {
            context.params.data.newBid = parseFloat(context.bidValue);
            vueRef.checkValidityForApply();
          }
        }
      }
    },
    {
      name: 'Remove all',
      type: 'custom',
      uiField: {
        uiLabel: 'Remove all',
        uiType: 'custom',
        uiOrder: '4',
        uiTooltip: 'Remove all the added items from the Ad Group.',
        customStyles:
          'cursor:pointer; color:#037ef6 !important;padding-left:12px;',
        clickHeader: vueRef.removeAll,
        metadata: {
          toolTipText: 'Remove the item from the Ad Group.',
          toolTipPosition: 'left',
          widget: 'icon',
          tableColumnName: 'na',
          showOnUi: true,
          isFixed: false,
          iconClickEvent: vueRef.removeEntity,
          displayIcon: 'cross',
          iconSize: 'small',
          suppressSizeToFit: true,
          width: 100
        }
      }
    }
  ];
};

export const adGroupCustomActionPanelForAutoSP =
  (adgroupType, type = 'create') =>
  (context) => ({
    component: 'customActionPanel',
    ui_component: 'customActionPanel',
    ...(type === 'edit'
      ? {
          staticHeaderText: 'Add Products'
        }
      : {}),
    props: {
      widgetsConfig: {
        config: (vueRef) => {
          return {
            primaryKey: 'sku',
            customActionPanelContainerClass:
              'u-height-100 campaign-creation-add-section-min-height',
            isMultipleAllowed: true,
            default: {
              ...(type === 'edit'
                ? {}
                : {
                    hideHeader: true,
                    hideFooter: true
                  }),
              noRowsRightTable: "Add SKU's to this list",
              selections: '',
              noRowsLeftTable: "No SKU's sources found",
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              warningMessage: ''
            },
            left: {
              rowHeight: 68,
              tabs: {
                "All SKU's": {
                  columnDefs:
                    adgroupType === 'sp_auto'
                      ? selectSkuLeftTableForAutoSP(vueRef)
                      : selectSkuLeftTable(vueRef),
                  search: {
                    enableSearch: true,
                    searchKey: 'search_text',
                    placeholder: 'Enter Item ID, Item Name, or Brand Name'
                  }
                },
                'Enter List': {
                  disableFilter: true,
                  ui_component: {
                    name: 'enterListPanel',
                    props: {
                      actionName: 'SKU',
                      buttonLabel: 'Add SKU',
                      emitTextInput: true,
                      entityCount: 2000
                    },
                    events: {
                      updateTextInput(data) {
                        data.cubeName = getCubeName(vueRef);
                        addSkuOnInput(data, adgroupType === 'sp_auto');
                      }
                    }
                  }
                }
              },
              isColumnDefAutoConfigure: true,
              table: 'left',
              meta: {
                type: 'table',
                action: 'fetchTableDataExecuteApi',
                ...(type === 'edit'
                  ? {
                      filters: {
                        emit: 'fetchTableDataExecuteApi',
                        hasSearch: false
                      },
                      filterState: {
                        getter: 'getExecuteApiSelectedFilters',
                        setter: 'fetchEntitiesExecuteApiFilters'
                      },
                      localFilters: [],
                      localStoreageFilterKey: `${getRetailer(
                        vueRef
                      )}-sp-add-sku`
                    }
                  : {}),
                ...(adgroupType === 'sp_auto'
                  ? {
                      updateRows: (vueRef) => {
                        const newRows = [];
                        vueRef.leftTableData.rows.forEach((rowItem) => {
                          if (!rowItem?.suggested_bid) {
                            rowItem.newBid = 0.2;
                            rowItem.suggestedBidToShow = '-';
                          } else {
                            rowItem.suggestedBidToShow = rowItem?.suggested_bid;
                            rowItem.newBid = rowItem?.suggested_bid;
                          }
                          newRows.push(rowItem);
                        });
                        vueRef.leftTableRows = newRows;
                      }
                    }
                  : {}),
                localFilters: [],
                paginationAction: 'fetchTableDataExecuteApi'
              },
              footer: {
                show: false
              },
              body: {
                APIConfig: {
                  cubeName: getCubeName(vueRef),
                  getLatestAvailableInsteadOfRollup: false,
                  timeseriesEnabled: false,
                  pvpenabled: false,
                  yoyenabled: false,
                  measuresList: [
                    'product_title',
                    'buy_box_pct',
                    'availability_pct',
                    'availability_status',
                    'brand_name',
                    'offer_price',
                    'image_url',
                    'product_url',
                    ...(adgroupType === 'sp_auto' ? ['suggested_bid'] : [])
                  ],
                  groupByDimensionsList: ['sku'],
                  orderByList: [],
                  where: {
                    dimensionNameValueList: []
                  },
                  limit: 10,
                  page: 1
                },
                columnDefs:
                  adgroupType === 'sp_auto'
                    ? selectSkuLeftTableForAutoSP(vueRef)
                    : selectSkuLeftTable(vueRef)
              },
              headerText: "Add SKU's"
            },
            right: {
              ...(type === 'edit'
                ? {
                    routeData: context.$route,
                    type: 'SKUS',
                    fetchAll: true,
                    altStore: 'entityDetails/getReviewStatusData',
                    meta: {
                      type: 'table',
                      action: 'entityDetails/fetchReviewStatusData',
                      localFilters: [
                        {
                          dimensionName: 'ad_group_id',
                          dimensionValue: context.entityId,
                          operator: 'EQUAL_TO'
                        }
                      ],
                      updateRows: (vueRef, rows = []) => {
                        const newRows = [];
                        for (let item of rows) {
                          if (
                            item.reviewStatus === 'rejected' ||
                            item.adGroupId !== parseInt(context.entityId)
                          ) {
                            continue;
                          }
                          item.sku = item.itemId;
                          item.product_title = item.name;
                          item.image_url = item.itemImageUrl;
                          item.product_url = item.itemPageUrl;
                          item.disabled = true;
                          item.toolTipText =
                            'Update the bids for existing skus from the Adjust bids action';
                          if (adgroupType === 'sp_auto') {
                            item.suggested_bid = '-';
                            item.newBid = item?.bid;
                          }
                          newRows.push(item);
                        }
                        vueRef.fetchedRightTableRows = {
                          rows: newRows,
                          totalRows: vueRef.rightTableData.totalRows
                        };
                        vueRef.rightTableRows = newRows;
                        vueRef.refreshLeftTableIsRowSelected();
                      }
                    }
                  }
                : {}),
              rowHeight: 68,
              table: 'right',
              searchKey: 'search',
              footer: {
                show: false
              },
              isColumnDefAutoConfigure: true,
              ...(adgroupType.includes('sp') && type !== 'create'
                ? {
                    keepFetchedRightTableRows: true
                  }
                : {}),
              body: {
                APIConfig: {
                  limit: 10,
                  page: 1
                },
                columnDefs:
                  adgroupType === 'sp_auto'
                    ? selectSkuRightTableForAutoSP(vueRef)
                    : selectSkuRightTable(vueRef)
              },
              updateRightTableRows(vueRef) {
                const allStepsData = fetchSavedState(
                  context,
                  context?.selectedValues?.getter
                );
                const newRightTableData = [];
                const { fetchedSkusResponse } = allStepsData;
                if (fetchedSkusResponse && fetchedSkusResponse.length > 0) {
                  fetchedSkusResponse.forEach((item) => {
                    const newSku = {
                      ...(adgroupType === 'sp_auto'
                        ? {
                            suggested_bid: '-',
                            newBid: item?.bid
                          }
                        : {}),
                      product_title: item?.name,
                      image_url: item?.itemImageUrl,
                      product_url: item?.itemImageUrl,
                      sku: item?.itemId.toString(),
                      isRowSelected: true
                    };
                    newRightTableData.push(newSku);
                  });
                }
                if (adgroupType === 'sp_manual' && type !== 'create') {
                  vueRef.fetchedRightTableRows = {
                    rows: newRightTableData,
                    totalRows: newRightTableData.length
                  };
                }
                vueRef.rightTableRows = newRightTableData;
                vueRef.refreshLeftTableIsRowSelected();
              },
              headerText: () => `Added SKU's (${vueRef.rightTableRows.length})`
            },
            applyButtonDisabled(vueRef) {
              const arrayToTraverse = vueRef.computedRightTableRows;
              for (const i in arrayToTraverse) {
                const newBid = arrayToTraverse[i]?.newBid;
                if (
                  adgroupType === 'sp_auto' &&
                  (!newBid ||
                    parseFloat(newBid) < 0.2 ||
                    parseFloat(newBid) > 20)
                ) {
                  context.showErrorMessage =
                    'Bid should be between $0.2 and $20, both inclusive.';
                  return true;
                }
              }
              const skuCount = vueRef.computedRightTableRows.length;
              if (skuCount < 1 || skuCount.length > 2000) {
                context.showErrorMessage =
                  'You can only enter 1-2000 items in the ad group.';
                return true;
              }
              context.showErrorMessage = null;
              const selections = arrayToTraverse.filter(
                (row) =>
                  vueRef.fetchedRightTableRows.rows.findIndex(
                    (fetchedRow) => fetchedRow.sku === row.sku
                  ) === -1
              );
              return !vueRef.rightTableRows.length || !selections.length;
            },
            apply(rows = []) {
              context?.tabConfig?.addProducts?.(context, rows, vueRef);
            }
          };
        }
      }
    },
    ...(type === 'edit'
      ? {
          events: {
            afterAPIActionToCall() {
              context.closeActionBar();
            },
            closePanelMethod() {
              context.showAddCustomProductPanel = false;
              context.closeActionBar();
            }
          }
        }
      : {
          events: {
            rightTableRows(rows) {
              let allStepsData =
                fetchSavedState(context, context?.selectedValues?.getter) || {};
              allStepsData.skus = rows;
              allStepsData = { ...allStepsData };
              updateSavedState(
                context,
                context?.selectedValues?.action,
                allStepsData
              );
            },
            afterAPIActionToCall(payload) {},
            closePanelMethod() {}
          }
        })
  });

export const getFailedSkuColumnDefinition = (vueRef) => [
  {
    name: 'sku_details',
    type: 'sku',
    uiField: {
      uiLabel: 'SKU Details',
      uiType: 'custom',
      widget: 'sku',
      uiOrder: '1',
      metadata: {
        widget: 'sku',
        toolTipText: '',
        tableColumnName: 'SKU Details',
        columnTitleKey: 'product_title',
        columnAsinKey: 'sku',
        columnImageUrlKey: 'image_url',
        coloumnProductUrlKey: 'product_url',
        columnAsinHoverText: `View ${getRetailerNameInSentenceCase(
          vueRef
        )} detail page`,
        showOnUi: true,
        isFixed: true,
        width: 300,
        getQuickFilterText: function (params) {
          return params?.data?.product_title + '_' + params?.data?.asin;
        }
      }
    }
  },
  {
    name: 'failure_reason',
    type: 'custom',
    uiField: {
      uiLabel: 'Failure Reason',
      uiType: 'custom',
      uiOrder: '2',
      metadata: {
        widget: 'dynamiccellcomponent',
        dynamicCellComponentParams: {
          componentName: 'FailedErrorTextTableCell',
          paramsToProps: (params) => {
            return {
              params,
              errorText: params.data?.failure_reason
            };
          }
        },
        toolTipText: '',
        tableColumnName: 'failure_reason',
        showOnUi: true,
        isFixed: false,
        width: 240
      }
    }
  }
];
