import HomeV2 from '@/components/homeV2.vue';
import DigitalShelfLanding from '@/pages/digital_shelf/route_config/index.vue';
import DigitalShelfRoutes from '@/pages/digital_shelf/route_config/route.js';
import Invite from '@/components/pages/invite/invite.vue';
import fileDownload from '@/pages/fileDownload/fileDownload.vue';
import toolsAndSettingsRoutes from '@/pages/toolsAndSettings/route.js';
import ToolsAndSettings from '@/pages/toolsAndSettings/route.vue';
import internalToolBox from '@/components/pages/ciq-internal-tools/internal-toolbox';
import internalToolRoutes from '@/components/pages/ciq-internal-tools/route.js';
import CiqEfundamentalsFAQ from '@/pages/ciq-efundamentals-announcement/index.vue';
import reportedIssues from '@/components/widgets/reportedIssues.vue';
import { proxyLinksRoute } from '@/router/commonRoutePaths.js';

const wayfairRoutes = {
  path: '/us/wayfair/retail',
  name: 'Home',
  component: HomeV2,
  children: [
    {
      path: '',
      name: 'Home',
      redirect: 'digital_shelf/market_insights'
    },
    {
      path: 'digital_shelf',
      name: 'digital_shelf',
      redirect() {
        return 'digital_shelf/market_insights';
      },
      component: DigitalShelfLanding,
      children: DigitalShelfRoutes.routes
    },
    {
      path: 'tools_and_settings',
      name: 'ToolsAndSettings',
      component: ToolsAndSettings,
      children: toolsAndSettingsRoutes.routes
    },
    {
      path: 'invite',
      name: 'invite',
      component: Invite
    },
    {
      path: 'reportedIssues',
      name: 'reportedIssues',
      component: reportedIssues
    },
    {
      path: 'internalToolBox',
      name: 'internalToolBox',
      component: internalToolBox
    },
    ...internalToolRoutes.routes,
    {
      path: 'file-download',
      component: fileDownload
    },
    {
      path: 'ciq-efundamentals-announcement',
      name: 'ciq-efundamentals-announcement',
      component: CiqEfundamentalsFAQ
    },
    proxyLinksRoute
  ]
};

export { wayfairRoutes };
