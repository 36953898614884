import HttpLayer from '@/utils/services/http-layer';

export default class DataService {
  data = {};

  constructor(
    intialState = {
      load: true,
      error: false,
      rows: {},
      noData: false
    }
  ) {
    this.data = intialState;
  }

  transformData(data, config) {
    return data;
  }

  getHttpLayerObject(requestCopy) {
    return {
      APIData: requestCopy
    };
  }

  sendDataRequest(requestCopy) {
    const requestPayload = this.getHttpLayerObject(requestCopy);
    return HttpLayer.post(requestPayload);
  }

  noDataCondtion(response) {
    return response.data?.length === 0;
  }

  async getData(requestCopy, config) {
    try {
      this.data = {
        load: true,
        error: false
      };
      const response = await this.sendDataRequest(requestCopy, config);
      if (response.data) {
        if (this.noDataCondtion(response)) {
          this.data = {
            rows: [],
            error: false,
            load: false,
            noData: true
          };
        } else {
          this.data = {
            rows: this.transformData(response.data, config),
            error: false,
            load: false,
            noData: false
          };
        }
      } else {
        this.data = {
          load: false,
          error: true
        };
      }
    } catch (e) {
      this.data = {
        load: false,
        error: true
      };
      console.log('e', e);
      throw e;
    }
  }
}
