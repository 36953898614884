import { cloneDeep } from 'lodash';
import { eventBus } from '@/utils/services/eventBus';
import { lzw_encode, lzw_decode } from '@/utils/helpers/stringUtils.js';
import RealTimeMetadataHeader from '@/components/ams/campaigns/tableCells/RealTimeMetadataHeader.vue';
import RealTimeMetadataCell from '@/components/ams/campaigns/tableCells/RealTimeMetadataCell.vue';
import { campaignStatusComponent } from '@/components/globals/dataTable/tableComponentsWrapper/custom-table-cells/campaignStatus';

const manageColumnsLSOldKey = 'globalManageColumnsPanel';
const manageColumnsLSKey = 'globalManageColumnsPanelV2';
export default {
  data() {
    return {
      manageColumnsKey: 0,
      managedColumns: []
    };
  },
  computed: {
    nonNegotiableColumns() {
      const fixedColumns =
        this.getAllTableColumns()
          .filter((column) => column.pinned)
          .map((column) => column.field) || [];
      return fixedColumns;
    },
    tableColumns() {
      if (!this.enableManageColumns) {
        return this.getAllTableColumns();
      }
      let tableCols = cloneDeep(this.getAllTableColumns());
      return tableCols
        .filter((item) => {
          return this.managedColumns.includes(item.field);
        })
        .sort(
          (item1, item2) =>
            this.managedColumns.indexOf(item1.field) -
            this.managedColumns.indexOf(item2.field)
        );
    },
    selectedColumns() {
      return this.managedColumns;
    },
    manageColumnsMasterMetrics() {
      let columnDefs = this.getAllTableColumns();
      let metrics = {};
      for (const column of columnDefs) {
        metrics[column.field] = {
          label: column.title,
          alias: column.field,
          metricName: column.field,
          metadata: {
            visible: true
          }
        };
      }
      return metrics;
    },
    unSelectedColumns() {
      let metrics = cloneDeep(this.manageColumnsMasterMetrics);
      return Object.keys(metrics || {}).filter(
        (metric) => !this.selectedColumns.includes(metric)
      );
    }
  },
  mounted() {
    this.setManagedColumns();
  },
  beforeDestroy() {
    eventBus.$off(this.manageColumnsPanelListener);
  },
  methods: {
    openManageColumns() {
      eventBus.$emit(this.manageColumnsPanelListener, 'open');
    },
    setRealtimeFields(dictionary, obj, entity, fieldName, retailer) {
      const fieldDetails = dictionary[fieldName];
      const metadata = {
        getter: fieldDetails?.getter?.[retailer]?.[entity]
      };
      if (fieldName === 'toggle_status') {
        delete obj.cellRenderer;
        obj.cellRendererFramework = campaignStatusComponent(
          {
            liveValue: ['keyword', 'skus'].includes(entity)
              ? 'enabled'
              : 'live',
            pausedValue: 'paused',
            proposalValue: 'proposal'
          },
          metadata
        );
        obj.cellRendererParams.showToolTip = fieldDetails?.showToolTip;
        obj.headerComponentParams.enableSorting = false;
        obj.minWidth = 100;
      }
      if (fieldDetails?.realTimeHeader?.includes(entity)) {
        obj.headerComponentFramework = 'DynamicHeaderComponent';
        obj.minWidth = 100;
        obj.headerComponentParams = {
          enableSorting: fieldName !== 'toggle_status',
          component: RealTimeMetadataHeader,
          columnDefsToProps: (params) => ({
            getter: fieldDetails.getter[retailer][entity],
            title: fieldDetails.title,
            type: fieldDetails?.type,
            unit: fieldDetails?.unit,
            toolTipText: fieldDetails.toolTipText,
            params: params
          })
        };
      }
      if (fieldDetails?.realTimeMetadataCell) {
        delete obj.cellRenderer;
        obj.cellRendererFramework = 'DynamicCellComponent';
        obj.minWidth = 100;
        obj.cellRendererParams = {
          component: RealTimeMetadataCell,
          paramsToProps: (params) => {
            return {
              field: params.column.colDef.field,
              type: fieldDetails?.type,
              unit: fieldDetails?.unit,
              getter: fieldDetails.getter[retailer][entity],
              row: params.data,
              realtime: fieldDetails?.realTimeMetadataCell
            };
          }
        };
      }
    },
    manageColumnsApply(data) {
      this.managedColumns = data.selectedColumns;
      const selectedColumns = data.selectedColumns;
      const deselectedColumns = this.extractFieldsFromColDefs().filter(
        (item) => {
          return !selectedColumns.includes(item);
        }
      );
      this.setColumnListToLS(selectedColumns, deselectedColumns);
      this.manageColumnsKey++;
    },
    extractFieldsFromColDefs() {
      let columnDefs = this.getAllTableColumns();
      return columnDefs.map((columnDef) => {
        return columnDef.field;
      });
    },
    removeDeletedColumns(selectedColumns) {
      const allColumns = this.extractFieldsFromColDefs();
      return selectedColumns.filter((col) => allColumns.includes(col));
    },
    removeUnselectedColumns(columns) {
      let unselectedColumns = this.getAllTableColumns()
        .filter((v) => v.headerComponentParams?.unselected)
        .map((v) => v.field);
      return columns.filter((v) => !unselectedColumns.includes(v));
    },
    getNewColumns(allSelectedColumms, selectedColumns) {
      const newColumns = allSelectedColumms.filter(
        (col) => !selectedColumns.includes(col)
      );
      return [...selectedColumns, ...newColumns];
    },
    convertOldLocalStorageData() {
      const globalManageColumnObject =
        JSON.parse(localStorage.getItem(manageColumnsLSOldKey)) || {};
      const oldSelectedColumns =
        globalManageColumnObject[this.manageColumnsPanelListener] || [];
      if (oldSelectedColumns.length > 0) {
        const deselectedColumns = this.extractFieldsFromColDefs().filter(
          (item) => {
            return !oldSelectedColumns.includes(item);
          }
        );
        this.setColumnListToLS(oldSelectedColumns, deselectedColumns);
        delete globalManageColumnObject[this.manageColumnsPanelListener];
        localStorage.setItem(
          manageColumnsLSOldKey,
          JSON.stringify(globalManageColumnObject)
        );
      }
    },
    setManagedColumns() {
      this.convertOldLocalStorageData();
      const columnsFromLocalStorage = this.getColumnListFromLS(
        this.manageColumnsPanelListener
      );
      let selectedColumns = columnsFromLocalStorage.selectedColumns || [];
      const deselectedColumns = columnsFromLocalStorage.deselectedColumns || [];
      if (selectedColumns.length > 0 || deselectedColumns.length > 0) {
        const allSelectedColumms = this.extractFieldsFromColDefs().filter(
          (col) => !deselectedColumns.includes(col)
        );
        if (allSelectedColumms.length !== selectedColumns.length) {
          selectedColumns = this.removeDeletedColumns(selectedColumns);
          selectedColumns = this.getNewColumns(
            allSelectedColumms,
            selectedColumns
          );
        }
        this.managedColumns = selectedColumns;
      } else {
        this.managedColumns = this.extractFieldsFromColDefs();
        this.managedColumns = this.removeUnselectedColumns(this.managedColumns);
      }
      this.manageColumnsKey++;
    },
    getGlobalManageColumnObject() {
      return (
        JSON.parse(lzw_decode(localStorage.getItem(manageColumnsLSKey))) || {}
      );
    },
    getColumnListFromLS(manageColumnsPanelListener) {
      return (
        this.getGlobalManageColumnObject()[manageColumnsPanelListener] || {}
      );
    },
    setColumnListToLS(selectedColumns, deselectedColumns) {
      const globalManageColumnObject = this.getGlobalManageColumnObject();
      globalManageColumnObject[this.manageColumnsPanelListener] = {
        selectedColumns,
        deselectedColumns
      };
      localStorage.setItem(
        manageColumnsLSKey,
        lzw_encode(JSON.stringify(globalManageColumnObject))
      );
    }
  }
};
