<template>
  <div class="u-display-flex u-flex-wrap-yes u-flex-justify-content-flex-end">
    <div>
      <div
        v-if="tooltip"
        class="selected-filters"
      >
        <rb-tooltip
          :label="dateRangeToolTip"
          :position="tooltipPosition"
        >
          <rb-select
            class="local--select--filter"
            :class="listPosition"
            :on-close="onFilter"
            :is-multi-select="multiSelect"
            :context="context"
            :options="listOptions"
            :send-details="true"
          >
            <div
              v-for="(item, index) in selectedItems"
              slot="trigger"
              class="local-filter--token u-display-inline-flex u-flex-align-items-center u-spacing-mr-xs"
              :class="{ isHighlighted: highlightTag }"
            >
              <rb-icon
                slot="trigger"
                class="rb-icon--small u-color-grey-lighter u-cursor-pointer"
                :icon="'filter'"
              />
              <span
                class="u-cursor-pointer u-line-height-1-1 u-spacing-ml-xs u-spacing-mr-s"
                >{{ staticKey }} {{ item.title ? item.title : item }}
              </span>
              <span v-if="!tagEdit">
                <rb-icon
                  slot="trigger"
                  class="rb-icon--xx-small u-color-grey-lighter u-cursor-pointer"
                  :icon="'arrow-down'"
                />&nbsp;
              </span>
              <span
                v-if="tagEdit"
                @click="removeThisItem(index)"
              >
                <rb-icon
                  :icon="'cross'"
                  class="rb-icon--x-small u-color-grey-light u-cursor-pointer u-line-height-1_2"
                />
              </span>
            </div>
          </rb-select>
        </rb-tooltip>
      </div>
      <!-- @click="emitThisTagEvent(item, index)"  -->
      <div
        v-if="!tooltip"
        class="selected-filters u-flex-align-items-flex-end"
      >
        <rb-select
          class="local--select--filter"
          :class="listPosition"
          :on-close="onFilter"
          :is-multi-select="multiSelect"
          :context="context"
          :options="listOptions"
          :send-details="true"
        >
          <div
            v-for="(item, index) in selectedItems"
            :key="index"
            slot="trigger"
            class="local-filter--token u-display-inline-flex u-flex-align-items-center u-spacing-mr-xs"
            :class="{ isHighlighted: highlightTag }"
          >
            <rb-icon
              slot="trigger"
              class="rb-icon--small u-cursor-pointer"
              :class="
                highlightTag ? 'u-color-grey-white' : 'u-color-grey-lighter'
              "
              :icon="'filter'"
            />
            <span
              class="u-cursor-pointer u-line-height-1-1 u-spacing-ml-xs u-spacing-mr-s"
              >{{ staticKey }} {{ item.title ? item.title : item }}
            </span>
            <span v-if="!tagEdit">
              <rb-icon
                slot="trigger"
                :class="
                  highlightTag ? 'u-color-grey-white' : 'u-color-grey-lighter'
                "
                class="rb-icon--xx-small u-cursor-pointer"
                :icon="'arrow-down'"
              />&nbsp;
            </span>
            <span
              v-if="tagEdit"
              @click="removeThisItem(index)"
            >
              <rb-icon
                :class="
                  highlightTag ? 'u-color-grey-white' : 'u-color-grey-light'
                "
                :icon="'cross'"
                class="rb-icon--x-small u-cursor-pointer u-line-height-1_2"
              />
            </span>
          </div>
        </rb-select>
      </div>
    </div>
    <!-- <div>
      <rb-select class="local--select--filter" :class="listPosition" :onClose="onFilter" :isMultiSelect="multiSelect" :context="context" :options="listOptions" :sendDetails="true">
        <rb-icon class="u-cursor-pointer" slot="trigger" :icon="'filter'"></rb-icon>
      </rb-select>
    </div> -->
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
export default {
  name: 'LocalSelectFilter',
  props: {
    context: {
      type: [Object, Array, String]
    },
    // value: {
    //   type: [String, Number, Boolean, Object, Array, Symbol, Function],
    //   default: null
    // },
    listPosition: {
      type: String,
      default: 'is-bottom-right'
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    tagEdit: {
      type: Boolean,
      default: true
    },
    tooltipPosition: {
      type: String,
      default: 'is-bottom'
    },
    listOptions: {
      type: Array,
      default: []
    },
    selectedValue: {
      type: Function
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: function () {
        return [];
      }
    },
    staticKey: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    isHighlighted: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      highlightTag: false,
      selectedItems: []
    };
  },
  computed: {
    anchorClasses() {
      return {
        'is-disabled': this.disabled,
        'is-paddingless': this.paddingless,
        'is-active': this.value !== null && this.value === this.$parent.selected
      };
    },
    itemClasses() {
      return {
        'dropdown-item': !this.hasLink,
        'is-disabled': this.disabled,
        'is-paddingless': this.paddingless,
        'is-active':
          this.value !== null && this.value === this.$parent.selected,
        'has-link': this.hasLink
      };
    },
    /**
     * Check if item can be clickable.
     */
    isClickable() {
      return !this.separator && !this.disabled;
    }
  },
  watch: {
    selectedItems: function (val) {
      if (val) {
        this.$emit('selected-value', val);
      }
    },

    value: function (val) {
      this.selectedItems = val;
      if (val.length > 0) {
        this.$emit('selected-value', val);
      }
    },

    isHighlighted: function (val) {
      this.removeHighlight(val);
    }
  },
  created() {
    this.selectedItems = this.value;
    this.removeHighlight(this.isHighlighted);
  },
  methods: {
    removeHighlight(val) {
      if (!val) {
        return;
      }
      setTimeout(() => {
        this.highlightTag = val;
      }, 250);
      setTimeout(() => {
        this.highlightTag = false;
      }, 5000);
    },
    onFilter(filter, item) {
      if (
        item &&
        item.length > 0 &&
        this.selectedValue &&
        this.selectedValue.constructor.name === 'Function' &&
        this.selectedItems !== item
      ) {
        this.selectedItems = item;
        this.selectedValue(filter, item);
      }
    },

    removeThisItem(index) {
      this.selectedItems.splice(index, 1);
    },

    emitThisTagEvent: function (item, index) {
      this.$emit('tag-event', item, index, this.selectedItems);
    }
  }
};
</script>

<style lang="css">
.local--select--filter.rb-select .rb-select-menu {
  right: 8px;
  left: auto;
  min-width: 200px;
  max-width: 300px;
  top: calc(100% + 8px);
}
</style>
