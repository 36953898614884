<template>
  <div
    class="u-display-flex u-flex-align-items-center shareCompBrandsSelectTrigger u-height-100"
  >
    <div
      data-cy="addMetricTile"
      class="metric-card-hover-trigger u-height-100"
    >
      <div
        class="custom-chart-legend u-display-inline-flex u-flex-direction-column u-position-relative u-cursor-pointer workbench-hover-card u-height-100"
      >
        <div
          class="u-spacing-p-m u-display-flex u-flex-align-items-center u-flex-justify-content-center u-height-100"
          style="min-height: 75px; min-width: 150px"
        >
          <span
            class="u-display-flex u-font-size-5 u-color-grey-lighter"
            data-cy="addMetricBtn"
          >
            <rb-icon
              class="rb-icon--small u-cursor-pointer u-color-grey-lighter u-spacing-mr-xs"
              :icon="triggerIcon"
            />{{ triggerText }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    triggerText: {
      type: String,
      default: 'Add metric'
    },
    triggerIcon: {
      type: String,
      default: 'add-circle-fill'
    }
  }
};
</script>

<style></style>
