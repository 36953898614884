<template>
  <div
    data-cy="actionBar"
    class="u-display-flex u-flex-direction-column u-height-100"
  >
    <div
      class="u-display-flex u-flex-align-items-center u-height-100 action-bar"
    >
      <div
        class="u-display-flex u-flex-direction-column u-color-grey-white u-spacing-pl-m"
      >
        <div
          class="u-display-flex u-flex-direction-column u-flex-align-items-center u-font-size-7 u-text-case-upper u-spacing-pt-xs u-display-flex"
        >
          <div>
            {{
              getSelectedEntities(
                customSelectionLength
                  ? customSelectionLength
                  : selections.length
              )
            }}
          </div>
          <span class="u-spacing-pl-xs"> Selected </span>
        </div>
      </div>
      <div
        class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-flex-1"
      >
        <div class="u-display-flex">
          <div class="action-group-divider" />
          <div
            v-if="allowedActions && allowedActions.length"
            class="u-display-flex"
          >
            <div
              v-for="action in allowedActions"
              :key="action.id + action.tippy"
              v-tippy="{ placement: 'top' }"
              :title="action.tippy"
            >
              <icon-text-actions
                :key="action.id"
                class="u-spacing-mh-s"
                :class="{
                  disabledAction:
                    action.disabled !== undefined && action.disabled == true
                }"
                :selections="selections"
                :parent-id="id"
                :action="action"
                @clicked="actionClicked"
              />
            </div>
          </div>
          <div
            v-else
            class="u-display-flex u-color-grey-white u-font-size-7 u-flex-align-items-center"
          >
            {{ noActionMessage }}
          </div>
        </div>
        <div
          class="u-display-flex u-flex-direction-column u-color-grey-white u-spacing-ph-m u-cursor-pointer closeButton u-spacing-p-s u-spacing-mr-m u-border-radius-xs"
          @click="closeBar"
        >
          <rb-icon
            class="rb-icon--small"
            :icon="'cross'"
          />
          <span
            class="u-font-size-8 u-text-case-upper u-spacing-pt-xs u-display-flex"
            >close</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */

import iconTextActions from '@/components/widgets/iconTextActions.vue';
import { formatter } from '@/utils/helpers/formatter.js';

export default {
  components: {
    iconTextActions
  },
  props: {
    customSelectionLength: {
      type: Number,
      default: null
    },
    passedActions: {
      type: Array,
      default: () => []
    },
    recommendationType: {
      type: String,
      default: ''
    },
    actionsConfig: {
      type: Object,
      default: () => {}
    },
    statusActionsDropdown: {
      type: Boolean,
      default: false
    },
    closeFn: Function,
    selections: {
      type: Array,
      default: function () {
        return [];
      }
    },
    noActionMessage: {
      type: String,
      default:
        "You don't have the required permissions to perform the necessary actions, please contact your admin for further help."
    }
  },
  data() {
    return {
      id: null
      // permittedActions: []
    };
  },
  computed: {
    allowedActions: function () {
      var roles = this.$store.getters.getSessionUser.roles;
      if (this.passedActions.length > 0) {
        return this.passedActions;
      }
      var hasRequiredRole =
        roles.indexOf(this.$store.state.alerts.actionRoleRequired) !== -1;
      if (this.recommendationType) {
        var isNonBoomerangAction =
          this.actionsConfig.enabledActions[this.recommendationType] ===
          undefined;
      }
      var actions = [];
      if (!isNonBoomerangAction) {
        if (hasRequiredRole) {
          actions = [
            {
              title: 'Approve',
              template: 'approve-workspace',
              icon: 'check-fill-circle',
              id: 'approve',
              endpoint: 'SUBMIT_ACTIONS',
              successMsg: 'approved.'
            },
            {
              title: 'Edit',
              template: 'approve-workspace',
              icon: 'pencil',
              id: 'edit',
              endpoint: 'SUBMIT_ACTIONS',
              successMsg: 'approved.'
            }
          ];
        }
      } else {
        actions = [
          {
            title: 'EDIT STATUS',
            template: 'approve-workspace',
            icon: 'task-status',
            endpoint: 'SUBMIT_ACTIONS',
            dropDown: true,
            dropDownOptions: [
              {
                title: 'Open',
                value: 'OPEN',
                id: 'nonBoomOpen',
                successMsg: 'marked as Open.'
              },
              {
                title: 'Approve',
                value: 'APPROVED',
                id: 'nonBoomApprove',
                successMsg: 'marked as Approved.'
              },
              {
                title: 'Action taken',
                value: 'ACTION_TAKEN',
                id: 'nonBoomActionTaken',
                successMsg: 'marked as Action taken.'
              }
            ]
          }
        ];
      }
      return actions;
    }
  },
  created() {
    this.id = 'actions' + this._uid;
  },
  methods: {
    actionClicked(action, dropDownSelectedOption) {
      if (this.passedActions.length > 0) {
        let removeSelectionState = true;
        if (
          action.removeSelectionState !== null &&
          action.removeSelectionState !== undefined
        ) {
          removeSelectionState = action.removeSelectionState;
        }
        this.$emit(
          'open-workspace',
          action.id,
          removeSelectionState,
          this.selections.length,
          dropDownSelectedOption,
          this.selections
        );
        return;
      }
      const actionObj =
        dropDownSelectedOption && dropDownSelectedOption.length
          ? dropDownSelectedOption[0]
          : action;
      const isBoomerangAction = !actionObj.id.startsWith('nonBoom');
      if (isBoomerangAction && this.selections.length === 1) {
        this.$emit(
          'open-workspace',
          { id: 'edit', successMsg: 'approved.' },
          true,
          this.selections.length,
          this.selections
        );
      } else {
        if (!isBoomerangAction) {
          this.$emit('submit-non-boom-action', actionObj);
          return;
        }
        this.$emit(
          'open-workspace',
          actionObj,
          isBoomerangAction,
          this.selections.length,
          this.selections
        );
      }
    },
    closeBar() {
      if (this.closeFn) {
        this.closeFn();
      }
    },
    getSelectedEntities(value) {
      return formatter(value, 'number', 'NA');
    }
  }
};
</script>

.
<style lang="css" scoped>
.action-bar {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 124, 246, 0.9),
    rgba(0, 124, 246, 0.9) 50%,
    #007cf6
  );
  padding: 8px 0;
}

.action-group-divider {
  width: 1px;
  background: rgba(255, 255, 255, 0.5);
  height: 24px;
  margin: auto 16px;
}

.closeButton:hover {
  background: rgba(43, 51, 59, 0.4);
  color: #fff;
}
.disabledAction {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}
</style>
