<template>
  <section>
    <section
      v-if="metadataLoaded"
      class="u-spacing-mb-m"
    >
      <h3
        class="u-text-align-left u-text-case-upper u-font-size-3 u-font-weight-600 u-spacing-ml-s u-color-grey-lighter"
      >
        {{ widgetTitle }}
      </h3>
      <div
        v-for="(metricRow, rowIndex) of metricRows[widgetName]"
        :key="'metricRow' + rowIndex"
      >
        <div
          :id="'metricRow' + rowIndex"
          class="u-display-flex u-spacing-mt-m u-spacing-pv-xs u-overflow-auto"
          :class="{ 'u-spacing-pb-0': expandedRows['metricRow' + rowIndex] }"
        >
          <metric-tile
            v-for="metric of metricRow"
            :id="metric"
            ref="metricTiles"
            :key="metric"
            :loading="loading"
            :metric-label="(metricsMetadata[metric] || {}).label"
            :chart-data="[(computedMetricsData[metric] || {}).timeSeries]"
            :metric-data="(computedMetricsData[metric] || {}).metricData || {}"
            :redirection-data="redirectionData[metric]"
            :disable-expand="
              !spotlightMetricsMap[metric] && !rcaMetricsMap[metric]
            "
          />
        </div>
        <div
          v-if="expandedRows['metricRow' + rowIndex]"
          :key="'expandRow' + rowIndex"
          ref="expandRows"
          class="expand-container u-flex-align-items-center u-spacing-p-l u-spacing-mh-s u-bg-color-white-base u-border-radius-s"
        >
          <div class="u-display-flex u-flex-justify-content-center">
            <div
              class="u-display-flex u-flex-align-items-center u-flex-direction-row u-width-100"
              :class="{
                'u-flex-justify-content-space-between': rcaAvailable,
                'u-flex-justify-content-flex-end': !rcaAvailable
              }"
            >
              <span
                v-if="rcaAvailable"
                class="u-font-size-3 u-text-case-upper u-font-weight-600 u-color-grey-lighter"
                >RCA For {{ lastExpandedTile.metricLabel }}</span
              >
              <div
                class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
              >
                <div @click="handleClose">
                  <rb-icon
                    :icon="'cross'"
                    class="u-cursor-pointer u-color-grey-lighter"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="rcaLoading"
            class="u-display-flex u-flex-justify-content-center u-flex-align-items-center u-width-100 u-min-height-200"
          >
            <loader
              class="u-bg-color-grey-white"
              :loading="true"
              :color="'#007cf6'"
            />
          </div>
          <div v-else>
            <rca-chart
              v-if="rcaAvailable && computedRCAData !== null"
              :tree-data="computedRCAData"
            />
            <section v-if="computedMetricSpotlightData.length">
              <metric-spotlight-widget
                :metric-label="lastExpandedTile.metricLabel"
                :spotlights-data="computedMetricSpotlightData"
              />
            </section>
          </div>
        </div>
      </div>
    </section>
    <section
      v-else-if="metadataLoading"
      class="u-spacing-m-m u-display-flex u-flex-justify-content-center u-flex-align-items-center u-width-100 u-min-height-200"
    >
      <loader
        class="u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </section>
  </section>
</template>

<script>
import RcaChart from '../organisms/rca-chart.vue';
import MetricTile from '../organisms/metric-tile.vue';
import loader from '@/components/basic/loader';
import metricSpotlightWidget from '@/components/pages/insights/amazon/rem-cc/widgets/metric-spotlight-widget.vue';

import { eventBus } from '@/utils/services/eventBus';
import { staticLocalSpotlightData } from '@/components/pages/insights/amazon/rem-cc/utils/constants.js';

export default {
  components: {
    RcaChart,
    MetricTile,
    loader,
    metricSpotlightWidget
  },
  props: {
    service: {
      type: Object,
      required: true
    },
    rcaService: {
      type: Object,
      default: () => ({})
    },
    metricSpotlightService: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      expandedRows: {},
      lastExpandedTile: null,
      spotlightsService: null,
      rcaAvailable: false,
      rcaMetricsMap: {
        actual_sales_marketsharelite: 'marketshare_rca',
        shipped_cogs: 'shipped_cogs_rca',
        roi: 'roas_rca',
        generic_total_count_page_1: 'sov_rca'
      },
      spotlightMetricsMap: {
        ordered_product_sales: 'ordered_product_sales_spotlights',
        actual_sales_marketsharelite: 'marketshare_spotlights',
        generic_total_count_page_1: 'sov_spotlights',
        roi: 'roas_spotlights',
        content_score: 'content_score_spotlights',
        avg_selling_price: 'avg_selling_price_spotlights',
        customer_sentiment: 'customer_sentiment_spotlights',
        shipped_cogs: 'shipped_cogs_spotlights',
        gross_retailer_margin_perc: 'gross_margin_spotlights',
        conversion_percentile: 'conversion_percentile_spotlights',
        actual_lbb_rate: 'actual_lbb_rate_spotlights',
        po_fill_rate: 'po_fill_rate_spotlights',
        spend: 'spend_spotlights'
      },
      redirectionData: {
        spend: {
          text: 'Go To Campaign Management',
          link: '/us/amazon/retail/advertising/campaign_management/campaigns'
        },
        avg_selling_price: {
          text: 'Go To IMAP',
          link: '/us/all/retail/imap'
        },
        actual_sales_marketsharelite: {
          text: 'Go To Marketshare',
          link: '/us/amazon/retail/digital_shelf/market_share/overview'
        },
        content_score: {
          text: 'Go To Content Automation',
          link: '/us/all/retail/content-automation'
        },
        po_fill_rate: {
          text: 'Go To PO Fill Rate',
          link: '/us/amazon/retail/supply_chain/po_fill_rate'
        },
        roi: {
          text: 'Go To Campaign Optimizer',
          link: '/us/amazon/retail/advertising/campaign_optimizer'
        },
        customer_sentiment: {
          text: 'Go To Reviews & Rating',
          link: '/us/amazon/retail/digital_shelf/reviews_and_ratings'
        },
        generic_total_count_page_1: {
          text: 'Go To Market Insights',
          link: '/us/amazon/retail/digital_shelf/market_insights'
        }
      }
    };
  },
  computed: {
    loading() {
      return this.service?.loading;
    },
    metadataLoaded() {
      return this.service?.metadata?.data;
    },
    rcaLoading() {
      return this.rcaService?.loading;
    },
    metadataLoading() {
      return this.service?.metadata?.loading;
    },
    widgetTitle() {
      return this.service?.metadata?.data?.label;
    },
    widgetName() {
      return this.service?.metadata?.data?.name;
    },
    metricsMetadata() {
      return this.service?.metadata?.data?.metrics;
    },
    computedMetricsData() {
      const data = {
        gross_retailer_margin_perc: {
          label: 'Gross margin %',
          metricData: {
            data: {
              value: 8.99,
              unit: 'PERCENTAGE'
            },
            pvp: 1.1
          },
          timeSeries: [
            'positive',
            8.845410311564535,
            9.371022066333314,
            9.688817630150472,
            10.05525689123749,
            9.255485066832621,
            9.201486657055769,
            7.828351671858252,
            7.565865920000241
          ]
        },
        shipped_cogs: {
          label: 'Shipped COGS',
          metricData: {
            data: {
              value: 44716424.34,
              unit: 'CURRENCY'
            },
            pvp: 5
          },
          timeSeries: [
            'positive',
            5726774.720000001,
            5400512.26,
            5539018.45,
            5913641.35,
            5864863.45,
            5946522.55,
            4681327.02,
            5643764.539999999
          ]
        },
        actual_sales_marketsharelite: {
          label: 'Marketshare',
          metricData: {
            data: {
              value: 12,
              unit: 'PERCENTAGE'
            },
            pvp: -2
          },
          timeSeries: ['negative', 10.42, 10.6, 13.3, 14, 11.75, 11.79]
        },
        conversion_percentile: {
          label: 'Unit Conversion',
          metricData: {
            data: {
              value: 31.3,
              unit: 'PERCENTAGE'
            },
            pvp: -11
          },
          timeSeries: [
            'negative',
            56.2,
            60.41,
            61.3,
            70,
            52.88,
            58.57,
            73.61,
            73.57
          ]
        },
        generic_total_count_page_1: {
          label: 'Total Category SOV (Page 1)',
          metricData: {
            data: {
              value: 12.5,
              unit: 'PERCENTAGE'
            },
            pvp: -2.5
          },
          timeSeries: [
            'negative',
            0.04,
            0.06,
            0.05,
            0.05,
            0.02,
            0.04,
            0.05,
            0.03,
            0.03
          ]
        },
        roi: {
          label: 'ROAS',
          metricData: {
            data: {
              value: 6.82,
              unit: 'CURRENCY'
            },
            pvp: 9.47
          },
          timeSeries: [
            'positive',
            7.2,
            6.17,
            5.59,
            6.36,
            7.69,
            8.51,
            6.23,
            7.62
          ]
        },
        spend: {
          label: 'Ad Spend',
          metricData: {
            data: {
              value: 1500000,
              unit: 'CURRENCY'
            },
            pvp: 1
          },
          timeSeries: [
            'positive',
            113056.95000000001,
            98457.44,
            98514.53,
            93605.66,
            81139.34,
            60560.36999999999,
            54833.7,
            53444.3
          ]
        },
        actual_lbb_rate: {
          label: 'LBB %',
          metricData: {
            isInverted: true,
            data: {
              value: 2.5,
              unit: 'PERCENTAGE'
            },
            pvp: 1.5
          },
          timeSeries: [
            'negative',
            1.97,
            2.56,
            1.73,
            1.92,
            1.21,
            1.52,
            1.85,
            2.13
          ]
        },
        actual_rep_oos: {
          label: 'Rep OOS %',
          metricData: {
            isInverted: true,
            data: {
              value: 7.95,
              unit: 'PERCENTAGE'
            },
            pvp: 1.25
          },
          timeSeries: ['negative', 8.31, 9.23, 9.4, 9.12, 6.68, 7.15, 6.7, 6.66]
        },
        po_fill_rate: {
          label: 'PO Fill Rate',
          metricData: {
            data: {
              value: 73.51362500083872,
              unit: 'PERCENTAGE'
            },
            pvp: -4.42
          },
          timeSeries: [
            'negative',
            60.65290882486503,
            69.9634663575176,
            66.44084668171736,
            69.38226048927156,
            81.0263309062879,
            77.76158536009594,
            75.90091979896938,
            79.30115476439488,
            75.71046767793602
          ]
        },
        avg_selling_price: {
          label: 'Average Selling Price',
          metricData: {
            data: {
              value: 22.08,
              unit: 'CURRENCY'
            },
            pvp: 5.75
          },
          timeSeries: [
            'positive',
            22.74,
            21.97,
            22.45,
            23.1,
            22.2,
            20.63,
            20.88,
            22.36
          ]
        },
        content_score: {
          label: 'Content Score',
          metricData: {
            data: {
              value: 82,
              unit: 'PERCENTAGE'
            },
            pvp: -3
          },
          timeSeries: ['negative', 86, 50, 96, 43, 85, 82]
        },
        customer_sentiment: {
          label: 'Customer Sentiment',
          metricData: {
            data: {
              value: 4.1,
              unit: ''
            },
            pvp: -0.2
          },
          timeSeries: ['negative', 1.1, 6.1, 3.1, 4.7, 4.1]
        },
        assortment_strength: {
          label: 'Assortment Strength',
          metricData: {
            data: {
              value: 85,
              unit: 'PERCENTAGE'
            },
            pvp: -5
          },
          timeSeries: ['negative', 82, 65, 83, 95, 90, 85]
        },
        branded_search: {
          label: 'Branded Search',
          metricData: {
            data: {
              value: 15,
              unit: 'PERCENTAGE'
            },
            pvp: -4
          },
          timeSeries: ['negative', 10, 21, 15, 19, 15]
        }
      };
      return data || this.service?.formattedMetricsData;
    },
    computedRCAData() {
      return this.rcaService?.formattedRCAData;
    },
    computedMetricSpotlightData() {
      return this.metricSpotlightService?.formattedSpotlightData || [];
    },
    metricRows() {
      return {
        remcc_input: [
          [
            'actual_lbb_rate',
            'actual_rep_oos',
            'avg_selling_price',
            'po_fill_rate',
            'spend'
          ],
          [
            'assortment_strength',
            'branded_search',
            'content_score',
            'customer_sentiment'
          ]
        ],
        remcc_output: [
          [
            'actual_sales_marketsharelite',
            'shipped_cogs',
            'gross_retailer_margin_perc'
          ]
        ],
        remcc_performance: [
          ['generic_total_count_page_1', 'conversion_percentile', 'roi']
        ]
      };
    }
  },
  watch: {
    'service.metadata.data': {
      handler(newVal, oldVal) {
        if (newVal) {
          this.service.fetchData();
        }
      },
      immediate: true
    }
  },
  created() {
    eventBus.$on('handleMetricTileExpand', (data) => {
      if (typeof data === 'string') {
        const tileRef = this.$refs.metricTiles.find(
          (tile) => tile.$attrs.id === data
        );
        if (tileRef) {
          tileRef.isExpanded = true;
          this.handleTileExpand(tileRef);
        } else {
          if (this.lastExpandedTile) {
            this.lastExpandedTile.isExpanded = false;
            this.expandedRows = {};
          }
        }
      } else {
        this.handleTileExpand(data);
      }
    });
  },
  methods: {
    fetchRCAData(selectedMetric) {
      this.rcaService.selectedMetric = selectedMetric;
      this.rcaService.rcaKey = this.rcaMetricsMap[selectedMetric];
      this.rcaService.fetchData();
    },
    handleTileExpand(tileRef) {
      const metricRowId = tileRef?.$el?.parentElement?.id;
      if (this.lastExpandedTile && this.lastExpandedTile !== tileRef) {
        // collapse the last expanded tile
        this.lastExpandedTile.isExpanded = false;
        if (this.lastExpandedTile.$parent !== tileRef.$parent) {
          // If the parent of tiles is different,
          // close the expanded container of previously expanded tile
          this.lastExpandedTile.$parent.expandedRows = {};
        }
      }
      if (tileRef.$parent === this) {
        if (Object.keys(this.rcaMetricsMap).includes(tileRef.$attrs.id)) {
          this.rcaAvailable = true;
          this.fetchRCAData(tileRef.$attrs.id);
        } else {
          this.rcaAvailable = false;
        }
        if (this.spotlightMetricsMap[tileRef.$attrs.id]) {
          this.fetchMetricSpotlightData(tileRef.$attrs.id, tileRef.metricLabel);
        } else {
          this.metricSpotlightService.formattedSpotlightData = null;
        }
        this.expandedRows = { [metricRowId]: true };
      }
      tileRef.$el.scrollIntoView({
        behavior: 'smooth'
      });
      this.lastExpandedTile = tileRef;
    },
    fetchMetricSpotlightData(selectedMetric, metricLabel) {
      if (staticLocalSpotlightData[selectedMetric]) {
        this.metricSpotlightService.formattedSpotlightData =
          staticLocalSpotlightData[selectedMetric];
        return;
      }
      this.metricSpotlightService.selectedMetric = selectedMetric;
      this.metricSpotlightService.metricLabel = metricLabel;
      this.metricSpotlightService.spotlightMetricKey =
        this.spotlightMetricsMap[selectedMetric];
      this.metricSpotlightService.fetchData();
    },
    handleClose() {
      this.expandedRows = {};
      this.lastExpandedTile.isExpanded = false;
    }
  }
};
</script>
