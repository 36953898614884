<template>
  <section class="u-spacing-mt-s">
    <section
      v-if="isDataApiFailed"
      class="u-min-height-100 u-width-100 u-bg-color-grey-white u-display-flex u-flex-align-items-center u-flex-justify-content-center"
    >
      <span class="u-font-size-5 u-color-grey-lighter">
        Error, Try later!
      </span>
    </section>
    <section v-else-if="isLoading">
      <Loader
        class="u-height-400 u-width-100 u-bg-color-grey-white"
        :loading="true"
        color="#007cf6"
      />
    </section>
    <div
      v-else
      class="u-display-flex u-flex-direction-column"
    >
      <div
        v-for="(categoryGroup, rowIndex) of categoryRows"
        :key="`metricRow${rowIndex}`"
      >
        <div
          v-if="rowIndex < visibleRows"
          class="u-spacing-mb-m"
        >
          <div
            :id="`metricRow${rowIndex}`"
            class="category-card-row u-display-flex"
          >
            <div
              v-for="(category, index) of categoryGroup"
              :key="index"
              :class="{
                'is-expanded': `${rowIndex}_${index}` === selectedCategoryIndex
              }"
              :style="{
                width: `calc((100% - (${numberOfColumns} - 1) * 1.6rem) / ${numberOfColumns})`
              }"
              class="chart-component u-bg-color-grey-white u-display-flex u-flex-direction-column u-border-radius-s box-shadow u-spacing-ph-m u-spacing-pt-m"
              @mouseover="showSortIcon = `${rowIndex}_${index}`"
              @mouseleave="showSortIcon = null"
            >
              <div class="u-spacing-pb-m u-cursor-pointer">
                <div
                  class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-center u-spacing-mt-xxs u-spacing-mb-s u-font-weight-600 u-font-size-6 u-link-primary"
                  @click="navigateToCategoryPage(category)"
                >
                  <div v-tippy="tippyOptions(`${rowIndex}_${index}`)">
                    <div
                      :id="'tooltip-' + `${rowIndex}_${index}`"
                      v-tippy-html
                      class="u-display-none"
                    >
                      <div class="max-w-md w-full u-text-case-title">
                        {{ category }}
                      </div>
                      <div class="u-spacing-mt-s u-color-grey-xx-light">
                        Click for "Category View"
                      </div>
                    </div>
                    <span
                      class="u-color-grey-light u-font-weight-600 u-line-height-1.3 u-font-size-6 u-border-bottom-dotted"
                    >
                      {{ category }}</span
                    >
                  </div>
                  <rb-icon
                    v-show="showSortIcon === `${rowIndex}_${index}`"
                    icon="sort-asc"
                    class="rotated-arrow rb-icon--small u-color-blue-base"
                  />
                </div>
              </div>
              <div>
                <div
                  v-if="isChartApiFailed"
                  class="widget-height u-display-flex u-flex-align-items-center"
                >
                  <span
                    class="u-line-height-1.5 u-font-size-5 u-color-grey-lighter"
                  >
                    Chart loading failed! Please refresh the page.
                  </span>
                </div>
                <Loader
                  v-if="isChartLoading"
                  :loading="isChartLoading"
                  class="u-bg-color-grey-white"
                  color="#3fadf7"
                  size="4rem"
                  thickness=".2rem"
                />
                <chartWithGradient
                  v-if="!isChartLoading && !isChartApiFailed"
                  :key="`${rowIndex}_${index}`"
                  class="u-spacing-mh-xs"
                  :metric-data="timeSeriesDataForCategories[category]"
                  :metadata="{
                    name: 'categoryCard_' + `${rowIndex}_${index}`
                  }"
                  :card-primary-metric-color-code="chartColor(category)"
                  :metrics-list="metricsList"
                  :chart-config="chartConfig"
                />
              </div>
              <metric
                :hide-zero="false"
                size="s"
                class="u-display-inline-flex"
                :config="metricConfig2 || {}"
                :data="
                  isMetricLoading
                    ? { tag1: 'loading' }
                    : MetricsDataForCategories[category].marketShareMetric || {}
                "
              />
              <span class="u-spacing-mt-m u-spacing-mb-xs">
                <span
                  class="u-spacing-mr-xs u-color-grey-lighter u-font-weight-400 u-font-size-6 u-line-height-1.3"
                >
                  Category Size
                </span>
                <br />
                <metric
                  :hide-zero="false"
                  size="xs"
                  class="u-display-inline-flex u-spacing-mt-xs"
                  :config="metricConfig1 || {}"
                  :data="
                    isMetricLoading
                      ? { tag1: 'loading' }
                      : MetricsDataForCategories[category].categorySize || {}
                  "
                />
              </span>
              <span class="u-spacing-mt-xs u-spacing-mb-xs">
                <span
                  class="u-spacing-mr-xs u-color-grey-lighter u-font-weight-400 u-font-size-6 u-line-height-1.3"
                >
                  Sales
                </span>
                <br />
                <metric
                  :hide-zero="false"
                  size="xs"
                  class="u-display-inline-flex u-spacing-mt-xs"
                  :config="metricConfig1 || {}"
                  :data="
                    isMetricLoading
                      ? { tag1: 'loading' }
                      : MetricsDataForCategories[category].sales || {}
                  "
                />
              </span>
              <div
                class="u-font-size-7 u-color-blue-base u-font-weight-600 u-cursor-pointer u-display-flex u-flex-align-items-center u-spacing-pv-m"
                @click.stop="
                  toggleDetailsAndSetCategory(index, rowIndex, category)
                "
              >
                <rb-icon
                  :icon="
                    `${rowIndex}_${index}` === selectedCategoryIndex
                      ? 'collapse'
                      : 'full-screen'
                  "
                  class="rb-icon--small u-spacing-mr-xs"
                />
                <span>{{
                  `${rowIndex}_${index}` === selectedCategoryIndex
                    ? 'Hide Details'
                    : 'Show Details'
                }}</span>
              </div>
            </div>
          </div>
          <section
            v-if="rowIndex === selectedParentRow"
            :key="categoryBreakdownKey"
            ref="breakdownContainer"
            class="box-shadow u-height-100 u-width-100 u-bg-color-grey-white u-border-radius-s u-spacing-mt-m u-spacing-pv-m"
          >
            <breakdown-view
              :category="breakdownViewCategory"
              :filter-data="filterData"
              :global-view-id="globalViewId"
              :global-settings="globalSettings"
              :sort-configuration="sortConfiguration"
              @category-details-clicked="handleCategoryDetailsClicked"
              @close-breakdown-view="handleCloseBreakDownView"
            />
          </section>
        </div>
      </div>
      <div
        v-if="categoryRows.length > defaultRowCount"
        class="u-font-size-5 u-color-blue-base u-font-weight-600 u-line-height-1.3 u-display-flex u-flex-align-items-center"
      >
        <span
          class="u-cursor-pointer"
          @click="toggleShowAllCategories"
        >
          {{ showAll ? 'Show less categories' : 'View all categories' }}
        </span>
        <rb-icon
          :icon="showAll ? 'sort-asc' : 'sort-desc'"
          class="rb-icon--small u-spacing-ml-xs"
        />
      </div>
    </div>
  </section>
</template>

<script>
import {
  categoryConstants,
  categoryInCardConstants,
  updateTimeSelectionForChart,
  setCategoryFilterInCategoryPage,
  msProConstants,
  getBasicRequestFormat,
  makeBundledRequest
} from '@/components/pages/insights/amazon/market-share/utils';
import metric from '@/components/basic/metric.vue';
import HttpService from '@/utils/services/http-service';
import chartWithGradient from '@/components/basic/chart-with-gradient.vue';
import Loader from '@/components/basic/loader.vue';
import breakdownView from '@/components/pages/insights/amazon/market_share_pro/molecules/breakdown-view.vue';

export default {
  components: { metric, chartWithGradient, Loader, breakdownView },
  props: {
    globalViewId: {
      type: Number,
      required: true
    },
    page: {
      type: String,
      default: ''
    },
    widget: {
      type: String,
      default: ''
    },
    numberOfColumns: {
      type: Number,
      default: 5
    },
    globalSettings: {
      type: Object,
      default: () => {}
    },
    filterData: {
      type: [Object, Array],
      default: () => {}
    },
    sortConfiguration: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      entityTypeCategory: 'category',
      categoryBreakdownKey: 0,
      myKey: 0,
      noOfWeeksInChart: 4,
      sortedCategoryList: [],
      visibleRows: 2,
      defaultRowCount: 2,
      isLoading: true,
      isDataApiFailed: false,
      showAll: false,
      showSortIcon: null,
      isChartLoading: true,
      isChartApiFailed: false,
      isMetricLoading: true,
      isSelected: false,
      selectedCategoryIndex: null,
      breakdownViewCategory: '',
      categoryRows: [],
      timeSeriesDataForCategories: [],
      selectedParentRow: null,
      readApiResponse: {},
      uiMetricMapping: {},
      dataApiResponseForMetrics: {},
      groupsChartData: {},
      MetricsDataForCategories: {},
      metricDataGroupMapping: {},
      displayShareInValue:
        this.globalSettings?.settingValue?.displayShareIn?.label,
      chartConfig: {
        xFormat: '%m/%d/%Y',
        axis: { x: { show: false }, y: { show: false } },
        legend: false,
        size: { height: 81 },
        tooltip: false,
        tooltip_format: {
          'Market Share:': { pre: '', roundoff: 2, suff: '%' }
        },
        point: false,
        line: { connectNull: true },
        padding: { left: -8, right: -7, bottom: 5 },
        regions: {},
        timeseries: 'week_start_date',
        tooltip_position: function (a, b, c, d) {
          return { top: -45 };
        }
      },
      metricsList: [
        {
          label: 'Market Share:',
          alias: 'marketShareMetric'
        }
      ]
    };
  },
  computed: {
    metricConfig1() {
      if (this.displayShareInValue === 'Units') {
        return {
          invertTag2: false,
          tag1Unit: {
            pre: '',
            roundoff: 2
          },
          tag2Unit: {
            suff: '%',
            roundoff: 2
          }
        };
      } else {
        return {
          invertTag2: false,
          tag1Unit: {
            pre: 'currency',
            roundoff: 2
          },
          tag2Unit: {
            suff: '%',
            roundoff: 2
          }
        };
      }
    },
    metricConfig2() {
      return {
        invertTag2: false,
        tag1Unit: {
          suff: '%',
          roundoff: 2
        },
        tag2Unit: {
          suff: '%',
          roundoff: 2
        }
      };
    }
  },
  watch: {
    globalSettings: {
      handler() {
        if (this.readApiResponse?.metrics) {
          this.isChartApiFailed = false;
          this.isDataApiFailed = false;
          this.defaultCategoryCard();
          this.fetchData();
          this.fetchTimeSeriesData();
        }
        this.displayShareInValue =
          this.globalSettings?.settingValue?.displayShareIn?.label;
      },
      deep: true,
      immediate: true
    },
    sortConfiguration: {
      handler() {
        if (this.readApiResponse?.metrics) {
          this.isDataApiFailed = false;
          this.defaultCategoryCard();
          this.fetchData();
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.fetchMetadata();
  },
  methods: {
    tippyOptions(id) {
      return {
        html: '#tooltip-' + id,
        placement: 'bottom',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      };
    },
    showErrorPageLevel(err) {
      this.isDataApiFailed = true;
      this.isLoading = false;
      console.log('err', err);
      this.$snackbar.open(msProConstants.errorMessage);
    },
    showErrorForChartComponent(err) {
      this.isChartApiFailed = true;
      this.isChartLoading = false;
      console.log('err', err);
      this.$snackbar.open(msProConstants.errorMessage);
    },
    chartColor(category) {
      const categoryData = this.timeSeriesDataForCategories[category][0];
      const { TIMESERIES } = categoryData;

      if (TIMESERIES === null) {
        return { marketShareMetric: msProConstants.chartColors.gray };
      } else {
        const noOfWeeksInChart = TIMESERIES.length;
        const metricName = categoryData?.name;
        const initialMetricValue = TIMESERIES[0]?.[metricName];
        const finalMetricValue = TIMESERIES[noOfWeeksInChart - 1]?.[metricName];
        if (finalMetricValue < initialMetricValue) {
          return { marketShareMetric: msProConstants.chartColors.red };
        } else if (finalMetricValue > initialMetricValue) {
          return { marketShareMetric: msProConstants.chartColors.green };
        } else {
          return { marketShareMetric: msProConstants.chartColors.gray };
        }
      }
    },
    setCategoryForBreakdownView(category) {
      this.breakdownViewCategory = category;
    },
    handleCloseBreakDownView() {
      this.selectedCategoryIndex = null;
      this.selectedParentRow = null;
    },
    navigateToCategoryPage(category) {
      setCategoryFilterInCategoryPage(category);
      this.$router.push({ name: categoryConstants.pageName });
    },
    handleCategoryDetailsClicked(category) {
      this.navigateToCategoryPage(category);
    },
    renderCategoryCard() {
      const newCategoryList = [...this.sortedCategoryList];
      this.categoryRows = [];
      while (newCategoryList.length > 0) {
        const chunk = newCategoryList.splice(0, this.numberOfColumns);
        this.categoryRows.push(chunk);
      }
    },
    toggleDetailsAndSetCategory(index, rowIndex, category) {
      this.categoryBreakdownKey++;
      this.toggleDetails(index, rowIndex);
      this.setCategoryForBreakdownView(category);
    },
    toggleDetails(index, rowIndex) {
      this.isSelected = this.selectedCategoryIndex !== `${rowIndex}_${index}`;
      this.selectedCategoryIndex = this.isSelected
        ? `${rowIndex}_${index}`
        : null;
      this.selectedParentRow = this.isSelected ? rowIndex : null;
    },

    toggleShowAllCategories() {
      this.showAll = !this.showAll;
      this.visibleRows = this.showAll
        ? this.categoryRows.length
        : this.defaultRowCount;
    },
    defaultCategoryCard() {
      this.visibleRows = this.defaultRowCount;
      this.showAll = false;
    },
    async fetchMetadata() {
      const body = {};
      body.widget = this.widget;
      body.page = this.page;
      body.globalViewId = this.globalViewId;
      try {
        const response = await HttpService.post('DASHBOARD_SERVICE', body, {
          append: '/widget/metadata/read'
        });
        if (response.status === 200) {
          this.readApiResponse = response.data;
          await this.fetchData();
          await this.fetchTimeSeriesData();
        }
      } catch (error) {
        this.showErrorPageLevel(error);
      }
    },

    async fetchData() {
      this.isLoading = true;
      this.handleCloseBreakDownView();
      this.isMetricLoading = true;
      const metricArray = this.getMetricDetails(
        this.readApiResponse.metrics,
        this.readApiResponse.metadata
      );
      const dataRequest = makeBundledRequest(
        this.readApiResponse.metadata.dataAPI.request,
        metricArray,
        this.globalSettings,
        this.entityTypeCategory,
        true,
        this.sortConfiguration
      );
      try {
        const response = await HttpService.post('MARKETSHARE', dataRequest, {
          append: '/marketshare/metrics/data/exec'
        });
        if (response.status === 200) {
          this.dataApiResponseForMetrics = response.data;
          this.getMappingForMetrics(this.dataApiResponseForMetrics.entityData);
        } else {
          this.showErrorPageLevel();
        }
      } catch (error) {
        this.showErrorPageLevel(error);
      }
    },

    getMetricDetails(metricDetails, metadata) {
      const threepSelection =
        this.globalSettings?.settingValue?.include3p?.value;
      const displayType =
        this.globalSettings?.settingValue?.displayShareIn?.label;
      const prefix = categoryInCardConstants.msPro_prefix;
      const metrics = categoryInCardConstants.metrics;
      const metricDataGroupMapping = {};

      for (const metricKey in metrics) {
        const metricName =
          metadata[metrics[metricKey]][displayType][threepSelection];
        const newName = metricName.replace(new RegExp(`^${prefix}`), '');
        const metricObject = {
          brandServiceKey: metricName,
          dashboardServiceKey: metricDetails[newName].name,
          metricUnit: metricDetails[newName].metadata.unit,
          dataGroup: metricDetails[newName].dataGroup,
          request: metricDetails[newName].api.request
        };
        metricDataGroupMapping[metrics[metricKey]] = metricObject;
      }
      this.buildUIMetricMapping(metricDataGroupMapping);
      this.metricDataGroupMapping = metricDataGroupMapping;
      return metricDataGroupMapping;
    },

    buildUIMetricMapping(metricDataGroupMapping) {
      const prefix = categoryInCardConstants.msPro_prefix;
      for (const metricKey in metricDataGroupMapping) {
        const metricNamewithPrefix =
          metricDataGroupMapping[metricKey].brandServiceKey;
        const metricName = metricNamewithPrefix.replace(
          new RegExp(`^${prefix}`),
          ''
        );
        this.uiMetricMapping[metricName] = metricKey;
      }
    },

    async fetchTimeSeriesData() {
      this.isChartLoading = true;
      let dataRequest = getBasicRequestFormat(
        this.readApiResponse.metadata.dataAPI.request,
        this.globalSettings,
        this.entityTypeCategory
      );
      dataRequest.where.dimensionNameValueList =
        this.globalSettings.where.dimensionNameValueList;
      dataRequest = updateTimeSelectionForChart(
        dataRequest,
        this.noOfWeeksInChart
      );
      dataRequest.operations.timeseriesEnabled = true;
      const timeSeriesMetricName =
        this.metricDataGroupMapping.marketShareMetric.dashboardServiceKey;
      const brandServiceKeyName =
        this.metricDataGroupMapping.marketShareMetric.brandServiceKey;
      dataRequest.metricsList[0] = timeSeriesMetricName;
      dataRequest.operations.orderByList[0].dimension = timeSeriesMetricName;
      try {
        const response = await HttpService.post('MARKETSHARE', dataRequest, {
          append: '/marketshare/metrics/data/exec'
        });
        if (response.status === 200) {
          this.groupsChartData = response.data;
          this.getTimeSeriesMapping(
            this.groupsChartData.entityData,
            brandServiceKeyName
          );
        } else {
          this.showErrorForChartComponent();
        }
      } catch (error) {
        this.showErrorForChartComponent(error);
      }
    },

    getTimeSeriesMapping(metricsData, timeSeriesMetricName) {
      const prefix = categoryInCardConstants.msPro_prefix;
      const metricNameWithoutPrefix = timeSeriesMetricName.replace(
        new RegExp(`^${prefix}`),
        ''
      );

      for (const metricData of metricsData) {
        const categoryName = metricData.entityValue;
        const timeSeriesData = metricData.data[1].TIMESERIES;
        this.timeSeriesDataForCategories[categoryName] = [];

        const metricObject = {
          TIMESERIES: timeSeriesData,
          name: metricNameWithoutPrefix,
          alias: 'marketShareMetric',
          type: 'METRICS'
        };

        this.timeSeriesDataForCategories[categoryName].push(metricObject);
      }

      this.isChartLoading = false;
    },

    getMappingForMetrics(metricsDataForCategories) {
      const metrics = categoryInCardConstants.metrics;
      const keys = Object.keys(metrics);
      const metricsDataForEachCategory = [];
      this.sortedCategoryList = [];
      for (const categoryData of metricsDataForCategories) {
        const categoryName = categoryData.entityValue;
        this.sortedCategoryList.push(categoryName);
        let dataObject = {};

        for (let j = 1; j <= keys.length; j++) {
          const metricName = categoryData.data[j].name;
          const resultValue = categoryData.data[j].RESULT[metricName];
          const metricUnit =
            this.metricDataGroupMapping[this.uiMetricMapping[metricName]]
              .metricUnit;
          const PVP_DIFF_TYPE =
            metricUnit === 'PERCENTAGE' ? 'PVP_DIFF_' : 'PVP_';
          const pvpMetricName = PVP_DIFF_TYPE + metricName;
          const pvpValue = categoryData.data[j].PVP[pvpMetricName];
          const metricObject = {
            tag1: resultValue,
            tag2: pvpValue
          };
          dataObject[this.uiMetricMapping[metricName]] = metricObject;
        }
        metricsDataForEachCategory[categoryName] = dataObject;
      }
      this.MetricsDataForCategories = metricsDataForEachCategory;
      this.isMetricLoading = false;
      this.renderCategoryCard();
      this.isLoading = false;
    }
  }
};
</script>

<style lang="css" scoped>
.u-height-400 {
  height: 400px;
}

.category-card-row {
  gap: 16px;
  height: 250 px;
}
.category-card-row .is-expanded {
  position: relative;
}
.category-card-row .is-expanded:after {
  content: ' ';
  display: block;
  background: #fff;
  height: 20px;
  width: 100%;
  bottom: -16px;
  position: absolute;
  left: 0em;
  clip-path: inset(0px -2px 0px -2px);
  box-shadow: -1px 0 11px -2px #caccce;
  z-index: 1;
  border-radius: 4px 4px 0 0;
}
.category-card-row .rotated-arrow {
  transform: rotate(90deg);
}
.widget-height {
  height: 65px;
}
</style>

<style lang="css">
.chart-component .c3-tooltip-container .card > :first-child {
  padding: 0.8rem;
  margin: 0;
  border: none;
}
.chart-component .c3-tooltip-container .card > :first-child span {
  font-size: 13px;
}
.chart-component .c3-tooltip-container .card > :nth-child(2) {
  display: none;
}
.chart-component .c3-tooltip-container .card > :last-child {
  padding: 0 0.8rem;
  font-size: 11px;
  min-width: 200px !important;
}
.chart-component .c3 .c3-line {
  stroke-width: 1px !important;
}
</style>
