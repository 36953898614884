<template>
  <span
    class="u-width-100 u-display-flex u-flex-direction-column u-flex-align-items-center"
  >
    <!-- <dataHeader
      :params="params"
      :display-name="commonName"
    /> -->
    <span
      class="u-color-grey-lighter col-header-title u-font-size-6 u-font-weight-600"
    >
      {{ commonName }}
    </span>
    <span
      class="u-display-flex u-flex-align-items-flex-start split-container u-width-100 u-border-color-grey-xxx-light u-border-top u-border-width-s"
    >
      <component
        :is="leftSection.ui_component"
        class="u-width-50 header-left-component u-spacing-p-0"
        v-bind="leftSection.props"
      />
      <component
        :is="rightSection.ui_component"
        class="header-right-component u-spacing-p-0 u-width-50 u-border-color-grey-xxx-light u-border-left u-border-width-s"
        v-bind="rightSection.props"
      />
    </span>
  </span>
</template>

<script>
export default {
  name: 'HeaderWithSplitSection',
  props: {
    commonName: {
      type: String,
      default: 'Zipcode'
    },
    params: {
      type: Object,
      default() {
        return {};
      }
    },
    leftSection: {
      type: Object,
      default() {
        return {
          ui_component: 'iconWithText',
          props: {
            keyTransformMap: {
              DELIVERY: { icon: 'shipment_tracker', text: 'Delivery' },
              PICKUP: { icon: 'bag', text: 'Pickup' }
            },
            text: 'DELIVERY',
            iconClasses: 'u-color-grey-lighter',
            textClasses: 'u-color-grey-base u-font-size-6'
          }
        };
      }
    },
    rightSection: {
      type: Object,
      default() {
        return {
          ui_component: 'iconWithText',
          props: {
            keyTransformMap: {
              DELIVERY: { icon: 'shipment_tracker', text: 'Delivery' },
              PICKUP: { icon: 'bag', text: 'Pickup' }
            },
            text: 'PICKUP',
            iconClasses: 'u-color-grey-lighter',
            textClasses: 'u-color-grey-base u-font-size-6'
          }
        };
      }
    }
  }
};
</script>

<style lang="css" scoped>
.splt-container,
.col-header-title {
  height: 50%;
}
</style>
