<template>
  <div
    :class="{
      'custom-tippy': true,
      'multi-liner': !oneLiner,
      'one-liner': oneLiner
    }"
  >
    <div class="background u-display-flex u-flex-align-items-center">
      <rb-icon
        v-if="icon !== ''"
        :icon="icon"
        :class="iconClass"
      />
      <span
        v-if="preText !== ''"
        class="u-spacing-pr-xs u-text-case-title title"
        >{{ preText }}</span
      >
      <span
        v-if="title !== ''"
        class="text"
        >{{ title }}</span
      >
    </div>
    <div
      class="secondary-text"
      v-html="text"
    >
      <!--  -->
    </div>
    <div
      v-if="note"
      class="note"
    >
      <div class="note-title">Note:</div>
      <div class="note-text">
        {{ note }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    preText: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      required: false,
      default: undefined
    },
    theme: {
      type: String,
      default: 'blackOnWhite'
    },
    note: {
      type: String,
      default: undefined
    },
    oneLiner: {
      type: Boolean,
      default: true
    },
    iconClass: {
      type: String,
      required: false,
      default: 'icon rb-icon--medium u-spacing-mr-xs'
    }
  }
};
</script>
<style lang="css" scoped>
.light-theme .text {
  color: #2b333b;
}
.light-theme .title {
  color: #2b333b;
}
.light-theme .background {
  color: #fff;
  background-color: #fff;
}
.light-theme .icon {
  color: #8b8f93;
}
.light-theme .secondary-text {
  color: #4b5158;
}
.dark-theme .text {
  color: #fff;
}
.dark-theme .title {
  color: #fff;
}
.dark-theme .icon {
  color: #fff;
}
.dark-theme .secondary-text {
  color: #fff;
}
.custom-tippy {
  max-width: 575px;
}
.one-liner .note {
  margin-top: 3.2rem;
  display: flex;
}
.one-liner .title {
  color: #caccce;
}
.one-liner .note-title {
  font-size: 1.4rem;
  font-weight: 600;
  margin-right: 0.4rem;
}
.one-liner .secondary-text {
  font-weight: 600;
  font-size: 1.4rem;
}
.one-liner .note-text {
  font-weight: 600;
  font-size: 1.4rem;
}
.multi-liner {
  padding: 16px;
}
.multi-liner .note {
  margin-top: 0.8rem;
  display: flex;
}
.multi-liner .note-title {
  font-size: 1.3rem;
  font-weight: 600;
  margin-right: 0.4rem;
}
.multi-liner .secondary-text {
  line-height: 1.5;
  font-weight: 400;
  font-size: 1.3rem;
  margin-top: 1.6rem;
}
.multi-liner .note-text {
  font-weight: 400;
  font-size: 1.3rem;
}
.one-liner {
  display: flex;
  align-items: center;
  flex-direction: row;
}
</style>
