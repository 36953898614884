import dictionary from './columnConfig.js';
import { formatter } from '@/utils/helpers/formatter.js';
import cellRenderer from '@/components/globals/dataTable/tableCellComponent.js';

var columnDefs = [];
const downloadConfig = [];
function getColumnDefinition(vueRef) {
  var _columnDefs = [];
  for (const i in dictionary.map) {
    if (
      (Array.isArray(dictionary.map[i].page) &&
        (dictionary.map[i].page.indexOf('common') !== -1 ||
          dictionary.map[i].page.indexOf('campaign') !== -1)) ||
      dictionary.map[i].page === 'common' ||
      dictionary.map[i].page === 'campaign'
    ) {
      const obj = {};
      obj.field = i;
      obj.title = dictionary.map[i].title;
      obj.headerComponentFramework = 'genericTableHeader';
      let keyType = 'string';
      if (dictionary.map[i].type === 'NUMERIC') {
        keyType = dictionary.map[i].unit ? dictionary.map[i].unit : 'NUMERIC';
      }
      if (dictionary.map[i].type === 'date') {
        keyType = dictionary.map[i].type;
      }
      obj.cellRendererParams = {
        keyType: keyType,
        formatterFn: formatter
      };
      obj.cellRenderer = cellRenderer;
      if (dictionary.map[i].type === 'Button') {
        delete obj.cellRenderer;
        obj.cellRendererFramework = 'buttonCell';
        obj.cellRendererParams.iconClickEvent = vueRef.downloadReport;
        obj.cellRendererParams.buttonText = 'Download';
        obj.minWidth = 120;
        obj.maxWidth = 120;
      }
      if (dictionary.map[i].type === 'status') {
        delete obj.cellRenderer;
        obj.cellRendererFramework = 'statusCell';
        obj.minWidth = 90;
        obj.maxWidth = 90;
      }
      obj.headerName = dictionary.map[i].title;
      obj.headerComponentParams = {
        enableSorting: true
      };
      if (obj.headerName === 'Date') {
        obj.headerComponentParams.sort = 'desc';
      }
      obj.keyOrder = dictionary.map[i].campaign_keyOrder;
      dictionary.map[i].pinned && (obj.pinned = dictionary.map[i].pinned);
      if (dictionary.map[i].toolTipText) {
        obj.headerComponentParams.toolTipText = dictionary.map[i].toolTipText;
      }
      obj.headerComponentParams.keyType = keyType;
      _columnDefs.push(obj);
    }
  }
  /**
   * Sort based on key order
   */
  columnDefs = _columnDefs.sort((a, b) => {
    return a.keyOrder - b.keyOrder;
  });
  return columnDefs;
}

export default {
  config: (that) => {
    return {
      getColumnDefinition: getColumnDefinition,
      backButtonClickEmit: 'productDiagnosticsBackButtonClicked',
      cardClickEmit: 'productDiagnosticsCardClicked',
      widgets: {
        widget1: {
          meta: {
            emit: 'productDiagnosticsCardFilterApplied',
            action: 'productDiagnosticsFetchCardDetails',
            listen: {}
          },
          header: {
            show: true,
            search: true,
            searchParams: ['source_name'],
            searchPlaceholder: 'Search for page name',
            title: 'PAGE HEALTH',
            tooltip: '',
            actions: {
              date: {
                mode: 'Single',
                action: {
                  setProductDiagnosticsDate: {},
                  productDiagnosticsFetchCardDetails: {
                    transform: function (config, data, that) {
                      return config;
                    }
                  }
                },
                maxDate: 'sri',
                defaultRangeSelect: 'last30Days'
              },
              setProductDiagnosticsCardsSearchData: {
                transform: function (config, data, that) {
                  var _config = config;
                  return _config;
                }
              },
              getProductDiagnosticsCardsSearchData: {
                transform: function (config, data, that) {
                  var _config = config;
                  return _config;
                }
              }
            }
          },
          footer: {
            show: true
          },
          body: {
            header: {
              headers: {
                cache: 'false'
              }
            },
            APIConfig: {
              cubeName: 'daily_test_report',
              measuresList: ['total_success', 'total_failed'],
              groupByDimensionsList: ['source_name'],
              orderByList: []
            }
          }
        },
        widget2: {
          meta: {
            type: 'table',
            primaryKey: 'campaign_id',
            emit: 'metricsCardClicked',
            action: 'getProductDiagonosticsTableData',
            paginationAction: 'productDiagnosticsPaginationTriggered',
            listen: {}
          },
          header: {
            show: true,
            search: true,
            searchParams: ['test_description', 'test_id'],
            searchPlaceholder: 'Search For Test Description Or Test ID',
            title: 'DETAILED REPORT OF THE PAGE',
            tooltip: '',
            actions: {
              date: {
                mode: 'Single',
                action: {
                  setProductDiagnosticsDate: {},
                  getProductDiagonosticsTableData: {
                    transform: function (config, data, that) {
                      return config;
                    }
                  }
                },
                maxDate: 'sri',
                defaultRangeSelect: 'last30Days'
              },
              setProductDiagnosticsTableSearchData: {
                transform: function (config, data, that) {
                  var _config = config;
                  return _config;
                }
              },
              getProductDiagnosticsTableSearchData: {
                transform: function (config, data, that) {
                  var _config = config;
                  return _config;
                }
              }
            }
          },
          footer: {
            show: true
          },
          body: {
            header: {
              headers: {
                cache: 'false'
              }
            },
            APIConfig: {
              cubeName: 'daily_test_report',
              measuresList: ['count'],
              groupByDimensionsList: [
                'test_id',
                'source_name',
                'success_count',
                'failed_count',
                'time_taken',
                'test_description',
                'test_type',
                'test_category',
                's3_path',
                'run_date',
                'test_status',
                'test_count'
              ],
              orderByList: [
                {
                  dimension: 'run_date',
                  direction: 'DESC'
                }
              ],
              limit: 20,
              page: 1
            },
            download: {
              fileName: 'productDiagnostics',
              columnMap: downloadConfig,
              action: 'downloadProductDiagnosticsTableData'
            },
            gridOptions: {
              suppressColumnVirtualisation: true,
              onRowClicked: that.openSidePanel,
              context: {
                componentParent: this
              },
              domLayout: 'normal'
            },
            columnDefs: getColumnDefinition(that)
          }
        },
        widget3: {
          meta: {
            emit: 'productDiagnosticsGraphDataRequested',
            action: 'productDiagnosticsGraphDetails',
            listen: {
              productDiagnosticsBackButtonClicked: {
                action: 'productDiagnosticsGraphDetails',
                transform: (config, data, that) => {
                  config.meta.localFilters = [];
                  return config;
                }
              },
              productDiagnosticsCardClicked: {
                action: 'productDiagnosticsGraphDetails',
                transform: (config, data, that) => {
                  if (data !== undefined) {
                    config.meta.localFilters = [];
                    if (data.length > 0) {
                      config.meta.localFilters.push({
                        dimensionName: 'source_name',
                        dimensionValue: data
                      });
                    }
                  }
                  return config;
                }
              }
            }
          },
          header: {
            show: true,
            title: 'ACCOUNT HEALTH',
            tooltip: ''
          },
          footer: {
            show: true
          },
          body: {
            chart: {
              chartOptions: {
                legend: false,
                type: 'line',
                xFormat: '%m/%d/%Y',
                timeseries: 'feed_date',
                tooltip_format: {
                  'Total Success': { pre: '', roundoff: 2 },
                  'Total Failed': { pre: '', roundoff: 2 }
                },
                axes: { 'Total Success': 'y', 'Total Failed': 'y2' },
                xDataKey: 'x',
                grid: 'xy',
                line: {
                  connectNull: false
                }
              }
            },
            header: {
              headers: {
                cache: 'false'
              }
            },
            APIConfig: {
              cubeName: 'daily_test_report',
              measuresList: ['total_success', 'total_failed'],
              groupByDimensionsList: ['run_date'],
              orderByList: []
            }
          }
        }
      }
    };
  }
};
