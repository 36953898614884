<template>
  <section
    class="u-bg-color-white-base u-spacing-p-l u-spacing-m-s u-box-shadow-xx-light u-border-radius-s u-cursor-default"
  >
    <h3 class="u-font-weight-600 u-color-grey-lighter u-text-align-left">
      E-COMMERCE PLANNER
    </h3>
    <p
      class="u-font-weight-400 u-color-grey-lighter u-font-size-5 u-spacing-mv-s"
    >
      Plan your business <span class="u-font-weight-600">3 years</span> ahead by
      entering <span class="u-font-weight-600">input values</span> and see how
      it affects your <span class="u-font-weight-600">output</span>.
    </p>
    <h4
      class="u-font-weight-600 u-color-grey-light u-font-size-3 u-spacing-mt-l u-spacing-mb-m"
    >
      Supporting Metrics
    </h4>
    <div>
      <rb-insights-table
        v-if="columnDefs.supportingMetrics.length"
        :grid-options="supportingMetricsGridOptions"
        :table-row="tableRows.supportingMetrics"
        :table-column="columnDefs.supportingMetrics"
        :get-row-height="getRowHeight"
        class="u-border-left u-border-width-s u-border-color-grey-xxx-light"
      />
    </div>
    <div
      class="u-font-weight-600 u-color-grey-base u-font-size-3 u-spacing-mv-m u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
    >
      <h4
        class="u-display-flex u-flex-align-items-center u-font-weight-600 u-color-grey-light"
      >
        <img
          src="https://cdn.rboomerang.com/remcc/simulation.svg"
          alt="caution img"
          class="u-spacing-mr-s"
        />
        Create Simulation for Input Metrics
      </h4>
      <div
        v-if="!service.outputMetricsLoading"
        class="u-border-color-blue-base u-border-all u-border-radius-s u-bg-color-white-base u-color-blue-base u-spacing-ph-l u-spacing-pv-s u-font-size-5 u-cursor-pointer"
        @click="handleSimulationDurationChange"
      >
        <rb-icon
          v-if="service.simulationComplete"
          icon="pencil"
          class="rb-icon--small"
        />
        <span class="u-spacing-pl-xs"> {{ simulationDurationText }}</span>
      </div>
    </div>
    <div>
      <rb-insights-table
        v-if="columnDefs.inputMetrics.length"
        :grid-options="inputMetricsGridOptions"
        :table-row="tableRows.inputMetrics"
        :get-row-height="getRowHeight"
        :row-class="'u-cursor-default'"
        :table-column="columnDefs.inputMetrics"
        class="u-border-left u-border-width-s u-border-color-grey-xxx-light"
      />
    </div>
    <div class="u-display-flex u-flex-justify-content-flex-end u-spacing-mv-m">
      <div
        v-if="service.simulationComplete && !service.outputMetricsLoading"
        class="u-color-green-base u-font-weight-600 u-font-size-4 u-display-flex u-flex-align-items-center"
      >
        <rb-icon icon="check-fill-circle" />
        <span>Simulation Complete</span>
      </div>
      <div
        v-else
        id="simulateBtn"
        class="u-bg-color-blue-base u-border-radius-s u-color-white-base u-spacing-ph-m u-font-size-4 u-cursor-pointer"
        :class="{
          'u-bg-color-grey-x-light u-pointer-events-none': !allowSimulation
        }"
        @click="handleSimulation"
      >
        {{ simulateBtnText }}
      </div>
    </div>
    <h4
      class="u-font-weight-600 u-color-grey-light u-font-size-3 u-spacing-mt-l u-spacing-mb-m"
    >
      Output Metrics
    </h4>
    <div
      v-if="service.outputMetricsLoading"
      id="loading-state"
      class="u-width-100 u-min-height-300 u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center u-border-radius-m"
    >
      <span
        class="u-color-grey-base u-font-size-5 u-font-weight-600 u-spacing-mb-xs"
        >Processing...</span
      >
      <span class="u-color-grey-x-light u-font-size-7 u-font-weight-600"
        >Please wait</span
      >
    </div>
    <div v-else-if="columnDefs.outputMetrics.length">
      <rb-insights-table
        v-if="columnDefs.outputMetrics.length"
        :grid-options="outputMetricsGridOptions"
        :table-row="tableRows.outputMetrics"
        :table-column="columnDefs.outputMetrics"
        :get-row-height="getRowHeight"
        class="u-border-left u-border-width-s u-border-color-grey-xxx-light"
      />
    </div>
    <div
      v-if="service.simulationComplete && !service.outputMetricsLoading"
      class="u-display-flex u-flex-justify-content-flex-end u-spacing-mv-m"
    >
      <div
        class="u-border-color-blue-base u-border-all u-border-radius-s u-bg-color-white-base u-color-blue-base u-spacing-ph-l u-spacing-pv-s u-font-size-5 u-font-weight-600 u-cursor-pointer"
        @click="handleResimulate"
      >
        Re-Simulate
      </div>
    </div>
  </section>
</template>

<script>
import ClickableIconWithText from '@/components/pages/insights/amazon/rem-cc/molecules/clickable-icon-with-text.vue';
import PlannerCell from '@/components/pages/insights/amazon/rem-cc/molecules/planner-cell.vue';
import HeaderWithCta from '@/components/globals/dataTable/tableComponentsWrapper/HeaderWithCta.vue';

import Vue from 'vue';
export default {
  name: 'EcomPlanner',
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      supportingMetricsGridOptions: {
        context: {
          componentParent: this
        }
      },
      inputMetricsGridOptions: {
        context: {
          componentParent: this
        }
      },
      outputMetricsGridOptions: {
        context: {
          componentParent: this
        }
      }
    };
  },
  computed: {
    columnDefs() {
      return this.service.columnDefs;
    },
    tableRows() {
      return this.service.tableRows;
    },
    simulationDurationText() {
      if (this.service.simulationComplete) {
        return 'Edit Simulation';
      }
      return `Plan ${
        this.service.threeYearSimulation ? '2023' : '3 year'
      } simulation`;
    },
    simulateBtnText() {
      return this.service.outputMetricsLoading ? 'Simulating' : 'Simulate';
    },
    allowSimulation() {
      let simulationEntities = [...this.service.simulationEntities];
      if (!this.service.threeYearSimulation) {
        simulationEntities = simulationEntities.slice(0, 1);
      }
      simulationEntities.push('2022');
      // Disallow simulation if any of the input is invalid
      return !Object.entries(this.service.editedValues).some(
        ([metric, editedValue]) => {
          return (
            simulationEntities.includes(metric.substring(0, 4)) &&
            editedValue?.error
          );
        }
      );
    }
  },
  created() {
    Vue.component('ClickableIconWithText', ClickableIconWithText);
    Vue.component('PlannerCell', PlannerCell);
    Vue.component('HeaderWithCta', HeaderWithCta);
  },
  methods: {
    handleSimulationDurationChange() {
      if (!this.service.simulationComplete) {
        this.service.threeYearSimulation = !this.service.threeYearSimulation;
        this.service.computeRowData('inputMetrics');
      }
      if (this.service.simulationComplete) {
        this.service.editSimulation();
      }
    },
    handleResimulate() {
      this.service.resetState();
    },
    handleSimulation() {
      if (
        !this.service.simulationComplete &&
        !this.service.outputMetricsLoading
      ) {
        this.service.computeOutputMetrics();
      }
    },
    getRowHeight(params) {
      if (params.data?.parentEntity && !params.data?.options?.percentageData) {
        return 70;
      }
      return 50;
    }
  }
};
</script>

<style>
#simulateBtn {
  padding-top: 10px;
  padding-bottom: 10px;
}
#loading-state {
  background-color: #f5f7fa;
}
</style>
