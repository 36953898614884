var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-display-flex u-flex-direction-column u-spacing-mh-s u-width-100 u-color-grey-mid-light"},[_vm._m(0),_c('div',{staticClass:"u-display-flex u-flex-direction-column u-flex-justify-content-space-around categoryWrapper u-position-relative"},_vm._l((_vm.topPerformers),function(category,index){return _c('div',{key:index,staticClass:"u-display-flex u-flex-align-items-center"},[_c('div',{staticClass:"u-flex-1 u-font-size-6 u-font-weight-600 u-text-case-title u-spacing-pr-m"},[(category.title)?_c('span',[_vm._v(" "+_vm._s(category.title)+" ")]):_c('span',[_vm._v(" Category Name NA ")]),_c('div',{staticClass:"u-spacing-pt-s u-font-weight-500 u-text-case-title"},[(category.volume)?_c('span',{staticClass:"u-color-grey-dark u-font-weight-600"},[_vm._v(" "+_vm._s(_vm.formatByCurrency(category.volume))+" "+_vm._s(_vm.subText)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.noSearchVolText)+" ")])])]),_c('div',{staticClass:"u-flex-1"},[_c('div',{staticClass:"u-height-category-bar"},[(category.categoryStrength)?_c('div',{staticClass:"u-display-flex u-height-100 u-line-height-category-bar"},[_c('span',{staticClass:"u-border-all u-border-radius-s u-spacing-pl-s",style:({
                width:
                  "" + (_vm.findCategoryStrength(
                    category.categoryStrength,
                    index
                  )) + '%',
                'background-color': ("" + (_vm.fillColors[index])),
                'border-color': ("" + (_vm.borderColors[index]))
              })}),_c('span',{staticClass:"u-font-size-5 u-font-weight-600 u-text-align-center u-spacing-pl-xs"},[_vm._v(" "+_vm._s(category.categoryStrength.toFixed(2))+"% ")])]):_c('span',{staticClass:"u-font-size-7"},[_vm._v(" SoV not available ")])])])])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-font-size-6 u-spacing-mv-s"},[_c('img',{staticClass:"u-color-blue-base u-spacing-mr-s",attrs:{"src":"https://cdn.rboomerang.com/cva/Dimension.svg"}}),_c('span',{staticClass:"u-font-weight-600"},[_vm._v(" Share of Voice ")]),_vm._v(" in Top Categories ")])}]

export { render, staticRenderFns }