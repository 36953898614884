import { findLastIndex } from 'lodash';
import { whatIfMetricsData } from '@/components/pages/insights/amazon/rem-cc/utils/constants';
import { formatter } from '@/utils/helpers/formatter.js';
export default class EcomPlannerService {
  tableRows = {
    supportingMetrics: [],
    inputMetrics: [],
    outputMetrics: []
  };

  columnDefs = {
    supportingMetrics: [],
    inputMetrics: [],
    outputMetrics: []
  };

  outputMetricsLoading = false;
  simulationComplete = false;
  collapsedRows = {};
  editedValues = {};
  editStates = {
    contribution_margin: {
      2022: false
    },
    category_growth: {
      2022: false
    }
  };

  errorMsg = {
    percent: 'Please enter a valid percentage number',
    currency: 'Please enter a valid number'
  };

  inputValidators = {
    percent: (value) => {
      try {
        return /^(100|\d{1,2})$/.test(value);
      } catch (err) {
        return false;
      }
    },
    currency: (value) => {
      try {
        return /^[\d]*\.?[\d]+$/.test(value);
      } catch (err) {
        return false;
      }
    }
  };

  simulationEntities = ['2023', '2024', '2025'];
  threeYearSimulation = false;
  metricData = {};
  outputMetricsData = {
    ciq_plan: {},
    status_quo: {}
  };

  constructor() {
    this.init();
  }

  resetState() {
    this.collapsedRows = {};
    this.editedValues = {};
    this.outputMetricsData = {
      ciq_plan: {},
      status_quo: {}
    };
    this.editStates = {
      contribution_margin: {
        2022: false
      },
      category_growth: {
        2022: false
      }
    };
    this.simulationComplete = false;
    this.computeColumnDefs('supportingMetrics');
    this.computeAllTableRows();
  }

  editSimulation() {
    this.editStates = {
      contribution_margin: {
        2022: false
      },
      category_growth: {
        2022: false
      }
    };
    this.simulationComplete = false;
    this.computeColumnDefs('supportingMetrics');
    this.computeAllTableRows();
  }

  getMetricData(type) {
    return (metric, year) => {
      return (
        this.outputMetricsData?.[type]?.[metric]?.[year] ||
        this.editedValues[year + '|' + metric] ||
        this.metricData[metric]
      )?.value;
    };
  }

  computeMetricData(metric, year = 2023, type = 'ciq_plan') {
    const typedGetMetricData = this.getMetricData(type);
    switch (metric) {
      case 'shipped_cogs': {
        const salesym1 = typedGetMetricData('shipped_cogs', year - 1); // ym1: year - 1
        const contentScore = typedGetMetricData('content_score', year) / 100;
        const contentScoreym1 =
          typedGetMetricData('content_score', year - 1) / 100;
        const adSpendym1 = typedGetMetricData('ad_spend', year - 1);
        const adSpend = typedGetMetricData('ad_spend', year);
        const roas = typedGetMetricData('roas', 2022);
        const categoryGrowth =
          typedGetMetricData('category_growth', 2022) / 100;
        const poFillRate = typedGetMetricData('fill_rate', year) / 100;
        const poFillRateym1 = typedGetMetricData('fill_rate', year - 1) / 100;
        let result;
        if (type === 'status_quo') {
          const adSpend2022 = typedGetMetricData('ad_spend', 2022);
          result =
            (salesym1 - adSpend2022 * roas) * (1 + categoryGrowth) +
            adSpend2022 * roas;
        } else {
          result =
            salesym1 -
            adSpendym1 * roas +
            salesym1 * categoryGrowth +
            Math.min(
              0.5 * salesym1,
              adSpend * roas +
                Math.min(
                  0.1 * salesym1,
                  (contentScore - contentScoreym1) * 3000000
                )
            ) +
            Math.min(
              0.15 * salesym1,
              ((poFillRate - poFillRateym1) * salesym1) / poFillRateym1
            );
        }
        return result;
      }
      case 'marketshare': {
        const sales = typedGetMetricData('shipped_cogs', year);
        const salesym1 = typedGetMetricData('shipped_cogs', year - 1);
        const categoryGrowth =
          typedGetMetricData('category_growth', 2022) / 100;
        const marketshareym1 = typedGetMetricData('marketshare', year - 1);
        const result = Math.min(
          15,
          (sales / (salesym1 * (1 + categoryGrowth))) * marketshareym1
        );
        return result;
      }
      case 'contribution_profit': {
        const sales = typedGetMetricData('shipped_cogs', year);
        const contributionMargin =
          typedGetMetricData('contribution_margin', 2022) / 100;
        const result = sales * contributionMargin;
        return result;
      }
      case 'roas': {
        if (type === 'status_quo') {
          return null;
        }
        const contributionProfit = typedGetMetricData(
          'contribution_profit',
          year
        );
        const contributionProfitym1 = typedGetMetricData(
          'contribution_profit',
          year - 1
        );
        const adSpendym1 = typedGetMetricData('ad_spend', year - 1);
        const adSpend = typedGetMetricData('ad_spend', year);
        const result =
          (contributionProfit - contributionProfitym1) / (adSpend - adSpendym1);
        return result;
      }
      default:
        return null;
    }
  }

  computeOutputMetrics() {
    this.outputMetricsData = {
      ciq_plan: {},
      status_quo: {}
    };
    this.outputMetricsLoading = true;
    this.simulationComplete = true;
    const outputMetrics = [
      'shipped_cogs',
      'marketshare',
      'contribution_profit',
      'roas'
    ];
    Object.keys(this.editStates).forEach((metric) => {
      const editedValue = this.editedValues['2022' + '|' + metric];
      if (!editedValue?.error) {
        this.metricData[metric].value =
          editedValue?.value || this.metricData[metric].value;
      }
    });
    let simulationEntities = [...this.simulationEntities];
    if (!this.threeYearSimulation) {
      simulationEntities = simulationEntities.slice(0, 1);
    }
    simulationEntities.forEach((entity) => {
      outputMetrics.forEach((metric) => {
        const ciqPlanValue = this.computeMetricData(metric, parseInt(entity));
        const statusQuoValue = this.computeMetricData(
          metric,
          parseInt(entity),
          'status_quo'
        );
        this.outputMetricsData.ciq_plan[metric] = {
          ...this.outputMetricsData.ciq_plan[metric]
        };
        this.outputMetricsData.status_quo[metric] = {
          ...this.outputMetricsData.status_quo[metric]
        };
        this.outputMetricsData.ciq_plan[metric][entity] = {
          value: ciqPlanValue
        };
        this.outputMetricsData.status_quo[metric][entity] = {
          value: statusQuoValue
        };
      });
    });
    this.computeColumnDefs('supportingMetrics');
    this.computeAllTableRows();
    setTimeout(() => {
      this.outputMetricsLoading = false;
    }, 2000);
  }

  getExpandIcon(hidden) {
    return hidden ? 'arrow-right' : 'arrow1-down';
  }

  init() {
    this.computeColumnDefs('supportingMetrics');
    this.computeColumnDefs('inputMetrics');
    this.computeColumnDefs('outputMetrics');
    this.computeAllTableRows();
  }

  computeAllTableRows() {
    this.computeRowData('supportingMetrics');
    this.computeRowData('inputMetrics');
    this.computeRowData('outputMetrics');
  }

  computeRowData(tableKey) {
    this.collapsedRows[tableKey + '2022'] = false;
    this.tableRows[tableKey] = [
      this.getTableRow('entity', '2022', { tableKey }),
      this.getTableRow('actuals', '2022', { tableKey })
    ];
    if (tableKey !== 'supportingMetrics') {
      let simulationEntities = [...this.simulationEntities];
      if (!this.threeYearSimulation) {
        simulationEntities = simulationEntities.slice(0, 1);
      }
      simulationEntities.forEach((entity) => {
        this.collapsedRows[tableKey + entity] = false;
        if (tableKey === 'outputMetrics') {
          if (this.simulationComplete) {
            this.tableRows[tableKey].push(
              this.getTableRow('entity', entity, { tableKey }),
              this.getTableRow('status_quo', entity, { tableKey }),
              this.getTableRow('ciq_plan', entity, { tableKey })
            );
          }
        } else {
          this.tableRows[tableKey].push(
            this.getTableRow('entity', entity, { tableKey }),
            this.getTableRow('simulation', entity, { tableKey })
          );
        }
      });
    }
  }

  handleRowExpand(tableKey, params) {
    const parentEntity = params.data.entity;
    let collapsedRowData;
    if (this.collapsedRows[tableKey + parentEntity]?.length) {
      collapsedRowData = this.collapsedRows[tableKey + parentEntity].map(
        (rowData) => {
          const options = rowData.options;
          return rowData.operationType === 'simulation'
            ? this.getTableRow('simulation', parentEntity, options)
            : rowData;
        }
      );
    }
    this.collapsedRows[tableKey + parentEntity] =
      !this.collapsedRows[tableKey + parentEntity];
    const isCollapsed = this.collapsedRows[tableKey + parentEntity];
    const expandIcon = this.getExpandIcon(isCollapsed);
    this.tableRows[tableKey][params.rowIndex].values.icon = expandIcon;
    if (isCollapsed) {
      this.collapsedRows[tableKey + parentEntity] = this.tableRows[
        tableKey
      ].filter((row) => row.parentEntity === parentEntity);
      this.tableRows[tableKey] = this.tableRows[tableKey].filter(
        (row) => row.parentEntity !== parentEntity
      );
    } else {
      this.tableRows[tableKey].splice(
        params.rowIndex + 1,
        0,
        ...(collapsedRowData || {})
      );
    }
  }

  onInputChange(value, params, tableKey) {
    const parentEntity = params.data.parentEntity;
    const field = params.colDef.field;
    const error =
      !this.inputValidators?.[params.value.metricData.unit]?.(value) || false;
    this.editedValues = {
      ...this.editedValues,
      [parentEntity + '|' + field]: {
        value,
        error
      }
    };
    if (!error && tableKey === 'supportingMetrics') {
      this.metricData[field].value = value;
      if (field === 'contribution_margin') {
        this.metricData.contribution_profit.value =
          (value * this.metricData.shipped_cogs.value) / 100;
      }
    }
    this.tableRows[tableKey].splice(
      params.rowIndex,
      1,
      this.getTableRow('simulation', parentEntity, {
        ...params.data.options,
        tableKey
      })
    );
  }

  deleteRow(tableKey, params) {
    this.tableRows[tableKey] = this.tableRows[tableKey].filter(
      (_row, index) => params.rowIndex !== index
    );
    Object.keys(this.editStates).forEach((metric) => {
      const parentEntity = params.data.parentEntity;
      if (this.editedValues[parentEntity + '|' + metric]?.error) {
        this.editedValues[parentEntity + '|' + metric] = undefined;
      }
      this.editStates[metric][parentEntity] = false;
    });
    this.metricData.contribution_profit.value =
      (this.metricData.contribution_margin.value *
        this.metricData.shipped_cogs.value) /
      100;
    this.computeRowData('outputMetrics');
    this.computeColumnDefs(tableKey);
    this.computeRowData(tableKey);
  }

  handleMetricSimulation(tableKey, params) {
    const field = params.column.colDef.field;
    const editState = this.editStates[field];
    const editableEntities = Object.keys(this.editStates[field]);
    Object.keys(editState).forEach((entity) => {
      editState[entity] = true;
    });
    this.computeColumnDefs(tableKey);
    editableEntities.forEach((entity) => {
      if (!this.collapsedRows[tableKey + entity]) {
        let foundIndex = findLastIndex(
          this.tableRows[tableKey],
          (row) =>
            row.parentEntity === entity && row.operationType === 'actuals'
        );
        const removeItemCount =
          this.tableRows[tableKey][foundIndex + 1]?.operationType ===
          'simulation'
            ? 1
            : 0;
        this.tableRows[tableKey].splice(
          foundIndex + 1,
          removeItemCount,
          this.getTableRow('simulation', entity, { deletable: true, tableKey })
        );
      } else {
        this.collapsedRows[tableKey + entity] = this.collapsedRows[
          tableKey + entity
        ]?.map((rowData) => {
          return rowData.operationType === 'simulation'
            ? this.getTableRow('simulation', entity, {
                deletable: true,
                tableKey
              })
            : rowData;
        });
      }
    });
  }

  computeColumnDefs(tableKey) {
    if (tableKey.length) {
      const columns = [
        {
          field: 'values',
          headerName: 'Values',
          headerComponentFramework: 'genericTableHeader',
          headerClass: 'u-font-weight-600 u-font-size-5',
          width: 270,
          minWidth: 270,
          maxWidth: 270,
          headerComponentParams: {
            enableSorting: false
          },
          cellRendererFramework: 'DynamicCellComponent',
          cellRendererParams: {
            component: 'ClickableIconWithText',
            paramsToProps: (params) => {
              return {
                iconName: params.value?.icon,
                iconText: params.value?.iconText,
                textClasses:
                  (params.data.entity ? 'u-font-weight-600' : '') +
                    ' ' +
                    params.value?.textClasses || '',
                iconClasses: 'rb-icon--medium',
                containerClasses:
                  'u-display-flex u-color-grey-base' +
                    ' ' +
                    params.value?.containerClasses || '',
                onIconClick:
                  params.value?.onIconClick?.bind?.(this, tableKey, params) ||
                  this.handleRowExpand.bind(this, tableKey, params)
              };
            }
          }
        }
      ];
      this.metricData = { ...this.metricData, ...whatIfMetricsData[tableKey] };
      whatIfMetricsData[tableKey].columnOrder.forEach((metric) => {
        columns.push(
          this.getMetricColumnDef(tableKey, {
            field: metric,
            ...whatIfMetricsData[tableKey][metric]
          })
        );
      });
      this.columnDefs[tableKey] = columns;
    }
  }

  getTableRow(type, entity, options = {}) {
    let rowDataObject = {};
    if (
      type === 'status_quo' ||
      type === 'ciq_plan' ||
      (type === 'actuals' &&
        options?.tableKey === 'outputMetrics' &&
        this.simulationComplete)
    ) {
      rowDataObject = this.getPercentageRowData(type, entity, options);
    } else if (type === 'actuals' || type === 'simulation') {
      rowDataObject = this.getSimulationRowData(type, entity, options);
    } else {
      rowDataObject.values = {
        icon: 'arrow1-down',
        iconText: entity,
        containerClasses: `u-font-size-4`,
        textClasses: entity !== '2022' ? 'u-color-blue-base' : undefined
      };
      rowDataObject.entity = entity;
    }

    return rowDataObject;
  }

  getPercentageRowData(type, entity, options) {
    const rowDataObject = {};
    const colors = {
      ciq_plan: '#3EA95E20',
      status_quo: '#FF607220',
      actuals: '#23B5D320'
    };
    this.columnDefs?.[options?.tableKey]?.slice(1).forEach((colDef, index) => {
      const field = colDef.field;
      const value =
        type === 'actuals'
          ? this.metricData[field].value
          : this.outputMetricsData[type]?.[field]?.[entity]?.value;
      const ciqPlanValues =
        Object.values(this.outputMetricsData.ciq_plan?.[field] || {}).map(
          (metricData) => metricData.value
        ) || [];
      const statusQuoValues =
        Object.values(this.outputMetricsData.status_quo?.[field] || {}).map(
          (metricData) => metricData.value
        ) || [];
      // Find the max value from ciq plan, status quo and actual value
      const maxValue = Math.max(
        ...ciqPlanValues,
        ...statusQuoValues,
        this.metricData[field].value || 0
      );
      rowDataObject[field] = {
        percentageData: {
          value: (value / maxValue) * 100 || null,
          label: formatter(value, this.metricData[field].unit),
          backgroundColor: colors[type],
          containerClasses:
            'u-font-size-5 u-font-weight-600 u-color-grey-base u-text-align-center u-spacing-pv-m u-width-100 u-height-100 u-display-flex u-flex-justify-content-center u-flex-align-items-center'
        }
      };
    });
    rowDataObject.parentEntity = entity;
    rowDataObject.operationType = type;
    rowDataObject.options = { ...options, percentageData: true };
    let iconText;
    if (type === 'actuals') {
      iconText = 'Actuals';
    } else if (type === 'status_quo') {
      iconText = 'Status Quo';
    } else {
      iconText = 'CIQ Plan';
    }
    rowDataObject.values = {
      iconText,
      containerClasses: `u-spacing-pl-m u-font-size-4`
    };
    return rowDataObject;
  }

  getSimulationRowData(type, entity, options) {
    const rowDataObject = {};
    this.columnDefs?.[options?.tableKey].forEach((colDef, index) => {
      if (index) {
        const field = colDef.field;
        let metricValue = this.metricData[field].value;
        if (type === 'simulation') {
          metricValue =
            this.editedValues?.[entity + '|' + field]?.value ?? metricValue;
        }

        rowDataObject[field] = {
          editable:
            !this.simulationComplete &&
            ((type === 'simulation' &&
              this.editStates[field] &&
              this.editStates[field][entity] !== false) ||
              entity !== '2022'),
          metricData: {
            unit: this.metricData[field].unit,
            value: metricValue
          },
          showError: this.editedValues?.[entity + '|' + colDef.field]?.error
        };
      }
    });
    rowDataObject.parentEntity = entity;
    rowDataObject.operationType = type;
    rowDataObject.options = Object.keys(options).length ? options : undefined;
    let iconText;
    if (type === 'actuals') {
      iconText = 'Actuals';
    } else if (this.simulationComplete) {
      iconText = 'Simulated Value';
    } else {
      iconText = 'Enter Simulation Value';
    }
    const showDeleteIcon = !this.simulationComplete && options.deletable;
    rowDataObject.values = {
      iconText,
      containerClasses: `u-spacing-pl-m u-width-100 u-display-flex u-font-size-4 ${
        showDeleteIcon
          ? 'u-flex-direction-row-reverse u-flex-justify-content-space-between'
          : ''
      }`,
      icon: showDeleteIcon ? 'cross' : '',
      onIconClick: showDeleteIcon && this.deleteRow.bind(this)
    };
    return rowDataObject;
  }

  getMetricColumnDef(tableKey, metric) {
    const showSimulateButton =
      !this.simulationComplete &&
      this.editStates[metric.field] &&
      Object.values(this.editStates[metric.field]).every(
        (editState) => !editState
      );
    return {
      field: metric.field,
      headerName: metric.label,
      headerComponentFramework: 'HeaderWithCta',
      headerClass: !showSimulateButton
        ? 'u-display-flex u-flex-justify-content-flex-end'
        : '',
      headerComponentParams: {
        headerText: metric.label,
        textStyles: 'u-font-size-5 u-color-grey-lighter u-font-weight-600',
        ...(showSimulateButton
          ? {
              onCtaClick: (params) =>
                this.handleMetricSimulation(tableKey, params),
              ctaText: 'Simulate',
              containerStyles:
                'u-width-100 u-display-flex u-flex-justify-content-space-between',
              ctaStyles:
                'u-spacing-ml-s u-cursor-pointer u-color-blue-base u-font-weight-600 u-font-size-5'
            }
          : {})
      },
      cellRendererFramework: 'DynamicCellComponent',
      suppressKeyboardEvent: (params) => {
        return true;
      },
      minWidth: 225,
      cellStyle: (params) => {
        return { padding: params.value?.percentageData ? '4px' : '8px' };
      },
      cellRendererParams: {
        component: 'PlannerCell',
        paramsToProps: (params) => {
          const hideData =
            params.data?.operationType === 'simulation' &&
            ((!this.editStates[metric.field] &&
              tableKey === 'supportingMetrics') ||
              (this.editStates[metric.field] &&
                this.editStates[metric.field][params.data?.parentEntity] ===
                  false));
          return {
            metricData: params.value?.metricData,
            percentageData: params.value?.percentageData,
            onInputChange: (value) => {
              this.onInputChange(value, params, tableKey);
            },
            editable: params.value?.editable,
            showError: params.value?.showError,
            errorText: this.errorMsg?.[params.value?.metricData?.unit] || '',
            hideData
          };
        }
      }
    };
  }
}
