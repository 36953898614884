import Vue from 'vue';
import {
  formatter,
  getLocale,
  getTippyString
} from '@/utils/helpers/formatter.js';
import { navigate_to_sdp } from '@/router/dynamicRoutes.js';
import { isNil } from 'lodash';
const { filters } = require('@ciq-dev/ciq-ui');

const commonEntityProps = {
  title: 'Entity',
  toolTipText: 'ASIN of the growth SKU',
  type: 'asin',
  columnType: 'entity',
  pinned: true,
  key: 'sku_details',
  field: 'skuid',
  enableSorting: false,
  keyOrder: 1,
  headerCheckboxSelection: false
};

const commonValueVsPvpVsLYCellProps = {
  columnType: 'valueVsPvpVsLYCell',
  title: '',
  toolTipText: '',
  type: 'custom',
  unit: '',
  field: '',
  enableSorting: false,
  keys: {
    value: 'metricValue',
    type: 'metricType',
    unit: 'metricUnit',
    firstPvpValue: 'currentPeriod_data1',
    firstPvpValueDiff: 'currentPeriod_data2',
    firstPvpValueInverted: 'currentPeriod_invertTag',
    secondPvpValue: 'previousPeriod_data1',
    secondPvpValueDiff: 'previousPeriod_data2',
    secondPvpValueInverted: 'previousPeriod_invertTag'
  }
};

const commonChildrenProps = {
  columnType: 'children',
  title: '',
  toolTipText: '',
  type: 'custom',
  unit: '',
  field: '',
  enableSorting: true,
  keys: {
    value: 'childMetricValue',
    type: 'childMetricType',
    unit: 'childMetricUnit'
  }
};

const commonChildren = [
  {
    title: 'Retail+3P',
    field: 'childColumn',
    keyOrder: 1,
    ...commonChildrenProps
  },
  {
    title: 'Retail',
    field: 'childColumn',
    keyOrder: 2,
    ...commonChildrenProps
  },
  {
    title: '3P',
    field: 'childColumn',
    keyOrder: 3,
    ...commonChildrenProps
  }
];

const columnConfig = {
  global: {
    column: {
      entity: {
        title: 'Entity',
        toolTipText: 'ASIN of the growth SKU',
        type: 'asin',
        columnType: 'entity',
        pinned: 'left',
        key: 'sku_details',
        field: 'skuid',
        enableSorting: false,
        keys: {
          imageUrl: 'image_url',
          productPageUrl: 'product_url',
          name: 'title',
          asin: 'asin',
          count: 'count'
        },
        keyOrder: 1,
        headerCheckboxSelection: false
      },
      measure: {
        title: 'Entity',
        columnType: 'measure',
        toolTipText: 'ASIN of the growth SKU',
        type: 'numeric',
        unit: 'currency',
        keyOrder: 2,
        field: 'metricValue',
        enableSorting: true
      },
      currentPeriod: {
        columnType: 'currentPeriod',
        title: 'Change compared to previous period',
        toolTipText: '',
        type: 'numeric',
        unit: 'percentage',
        field: 'currentPeriod',
        enableSorting: true,
        sort: 'desc',
        keyOrder: 3,
        keys: {
          data1: 'currentPeriod_data1',
          data1_type: 'currentPeriod_data1_type',
          data2: 'currentPeriod_data2',
          data2_type: 'currentPeriod_data2_type',
          invertTag: 'currentPeriod_invertTag'
        }
      },
      samePeriodLastYear: {
        columnType: 'samePeriodLastYear',
        title: 'Change compared to same period last year',
        toolTipText: '',
        type: 'numeric',
        unit: 'percentage',
        field: 'previousPeriod',
        enableSorting: true,
        keyOrder: 4,
        keys: {
          data1: 'previousPeriod_data1',
          data1_type: 'previousPeriod_data1_type',
          data2: 'previousPeriod_data2',
          data2_type: 'previousPeriod_data2_type',
          invertTag: 'previousPeriod_invertTag'
        }
      }
    }
  },
  amazon_3p: {
    column: {
      entity: {
        ...commonEntityProps,
        keys: {
          imageUrl: 'sku_3p_all__latest_image_url',
          productPageUrl: 'sku_3p_all__product_url',
          name: 'sku_3p_all__title',
          showImage: true,
          asin: 'asin',
          count: 'count'
        }
      },
      valueVsPvpVsLYCell: {
        ...commonValueVsPvpVsLYCellProps
      }
    }
  },
  amazon_b2b: {
    column: {
      entity: {
        ...commonEntityProps,
        keys: {
          imageUrl: 'sku_b2b_all__image_url',
          productPageUrl: 'sku_b2b_all__product_url',
          name: 'sku_b2b_all__title',
          showImage: true,
          asin: 'asin',
          count: 'count'
        }
      },
      valueVsPvpVsLYCell: {
        ...commonValueVsPvpVsLYCellProps
      }
    }
  },
  amazon_eu: {
    column: {
      entity: {
        ...commonEntityProps,
        keys: {
          imageUrl: 'latest_image_url',
          productPageUrl: 'product_url',
          name: 'asin_name',
          showImage: true,
          asin: 'asin',
          count: 'count'
        }
      },
      region: {
        title: 'Region',
        toolTipText: 'Region of the growth SKU',
        type: 'custom',
        columnType: 'valueVsPvpVsLYCell',
        pinned: true,
        field: 'region',
        enableSorting: true,
        keyOrder: 2,
        keys: {
          value: 'metricValue'
        }
      },
      valueVsPvpVsLYCell: {
        ...commonValueVsPvpVsLYCellProps
      }
    }
  },
  amazon_hybrid: {
    column: {
      entity: {
        ...commonEntityProps,
        keys: {
          imageUrl: 'latest_image_url',
          productPageUrl: 'latest_product_page_url',
          name: 'parent_asin_title',
          showImage: true,
          asin: 'asin',
          count: 'count'
        }
      },
      valueVsPvpVsLYCell: {
        ...commonValueVsPvpVsLYCellProps
      }
    }
  },
  amazon_hybrid_split: {
    column: {
      entity: {
        ...commonEntityProps,
        keys: {
          imageUrl: 'latest_image_url',
          productPageUrl: 'latest_product_page_url',
          name: 'title',
          showImage: true,
          asin: 'asin',
          count: 'count'
        }
      },
      children: [
        {
          ...commonChildrenProps,
          title: 'Retail+3P',
          field: 'retailPlus3P_childColumn',
          keyOrder: 1,
          valueVsPvpVsLYCell: {
            ...commonValueVsPvpVsLYCellProps
          }
        },
        {
          ...commonChildrenProps,
          title: 'Retail',
          field: 'retail_childColumn',
          keyOrder: 2,
          valueVsPvpVsLYCell: {
            ...commonValueVsPvpVsLYCellProps
          }
        },
        {
          ...commonChildrenProps,
          title: '3P',
          field: '3P_childColumn',
          keyOrder: 3,
          valueVsPvpVsLYCell: {
            ...commonValueVsPvpVsLYCellProps
          }
        }
      ]
    }
  }
};

const skuCellDisplay = Vue.extend({
  data() {
    return {
      renderParams: {
        name: 'title',
        asin: 'asin',
        imageUrl: 'image_url',
        productPageUrl: 'product_url',
        showImage: true,
        count: 'count'
      }
    };
  },
  computed: {
    enableSDP() {
      if (!isNil(this.getMetadata?.enableSDP)) {
        return this.getMetadata?.enableSDP;
      }
      return this.$store.getters.getProgram !== 'fresh';
    },
    getTooltip() {
      if (this.getMetadata?.skuTooltip) {
        return this.getMetadata.skuTooltip;
      }
      return 'View Amazon detail page';
    },
    getCopyClipboardText() {
      return this.getMetadata?.copyClipboardText ?? 'ASIN copied to clipboard';
    },
    getMetadata() {
      return this.$store.getters?.getTopMoversMetaData?.metadata;
    },
    isRecommendationDataFetched() {
      return this.$store.getters.getIsRecommendationDataFetched;
    }
  },
  created() {
    if (this.renderParams !== undefined) {
      this.renderParams = this.params?.renderParams;
    }
  },
  methods: {
    navigateToSDP(asin) {
      // Persisting the dates while navigating from BO - SDP.
      const localStorageBoFilterValue = JSON.parse(
        localStorage.getItem('businessInsights-filters')
      );
      localStorage.setItem(
        `SDP-calendar-web${asin}`,
        JSON.stringify(localStorageBoFilterValue)
      );
      navigate_to_sdp(asin);
    },
    urlLink() {
      const routeData = this.$router.resolve({
        name: 'All',
        query: {
          name: 'asin',
          type: 'asin',
          bc_recommendation_type: 'asin',
          selectedAsin: this.params.data[this.renderParams.asin]
        }
      });
      window.open(routeData.href, '_blank');
      // return 'recommendations/all?name=asin&type=asin&bc_recommendation_type=asin&selectedAsin=' + this.params.data[this.renderParams.asin];
    },
    getComputedData(value) {
      if (typeof value === 'object') {
        return value?.metricValue;
      }
      return value;
    }
  },
  template:
    '<div class="u-width-100"><rb-sku v-if="params.data.asin !== undefined" :id="getComputedData(params.data.asin)" :copy-clipboard-text="getCopyClipboardText" :title="getComputedData(params.data[renderParams.name])" :rb-asin-tippy-text="getTooltip" :asin="getComputedData(params.data[renderParams.asin])" :imageUrl="getComputedData(params.data[renderParams.imageUrl])" :showImage="renderParams.showImage" :productPageUrl="getComputedData(params.data[renderParams.productPageUrl])" :enableSDP="enableSDP" @navigateToSDP="navigateToSDP"><div slot="sku-context" class="u-display-flex"><div v-if="isRecommendationDataFetched && params.data[renderParams.count] > 0" class="u-display-flex"><rb-icon class="rb-icon--medium u-color-blue-base rb-icon-14" icon="bell"></rb-icon><a class="link u-display-flex u-font-weight-normal" target="_blank" @click.stop="urlLink">View {{ params.data[renderParams.count] }}&nbsp;<span v-if="params.data[renderParams.count] > 1">recommendations</span><span v-if="params.data[renderParams.count] === 1">recommendation</span></a></div><loader v-else :loading="!isRecommendationDataFetched" class="" color="#007cf6" size="8px"></loader></div></rb-sku><div v-if="params.data.asin === undefined && params.data[renderParams.imageUrl] === undefined"><span>{{ params.data[renderParams.name] }}</span></div></div>'
});

const metricCellDisplay = Vue.extend({
  data() {
    return {
      renderParams: {
        invertTag: 'invertTag',
        data1: 'data1',
        data1_type: 'data1_type',
        data2: 'data2',
        data2_type: 'data2_type'
      }
    };
  },
  created() {
    if (this.params?.renderParams !== undefined) {
      this.renderParams = this.params?.renderParams;
    }
  },
  methods: {
    formatter: formatter,
    getTippyString,
    getLocale
  },
  template: `<div class="u-width-100 u-display-flex u-height-100 u-flex-justify-content-flex-end">
    <div class="u-display-flex u-flex-align-items-center u-spacing-mr-m" v-if="!params.data[renderParams.data1] && !params.data[renderParams.data2]">
      <span>NA</span>
    </div>
    <div v-else class="u-display-flex u-flex-align-items-center u-spacing-mr-m u-flex-direction-column u-flex-justify-content-center">
      <span
        :title="getTippyString(params.data[renderParams.data1], getLocale, '')"
        v-if="params.data[renderParams.invertTag] === true"
        v-trend-indicator="{value: params.data[renderParams.data1]}"
        :value="params.data[renderParams.data1]"
        class="secondary_metric"
        :class="{'u-color-red-base': params.data[renderParams.data1] > 0, 'u-color-green-base': params.data[renderParams.data1] < 0}"
      >
        {{formatter(params.data[renderParams.data1], params.data[renderParams.data1_type])}}
      </span>
      <span
        v-else
        v-trend-indicator="{value: params.data[renderParams.data1]}"
        :title="getTippyString(params.data[renderParams.data1], getLocale, '')"
        :value="params.data[renderParams.data1]"
        class="secondary_metric"
        :class="{'u-color-red-base': params.data[renderParams.data1] < 0, 'u-color-green-base': params.data[renderParams.data1] > 0,'u-spacing-pl-s': params.data[renderParams.data1_type] !== 'CURRENCY'}"
      >
        {{formatter(params.data[renderParams.data1], params.data[renderParams.data1_type])}}
      </span>
      <p :value="params.data[renderParams.data2]"
        v-if="params.data[renderParams.data2_type] !== params.data[renderParams.data1_type]"
        class="secondary_metric u-spacing-ml-m u-spacing-mt-xxs">
        {{formatter(params.data[renderParams.data2], params.data[renderParams.data2_type])}}
      </p>
    </div>
  </div>`
});

export { columnConfig, skuCellDisplay, metricCellDisplay };
