import { render, staticRenderFns } from "./categoryBreakdown.vue?vue&type=template&id=36b33415&scoped=true&"
import script from "./categoryBreakdown.vue?vue&type=script&lang=js&"
export * from "./categoryBreakdown.vue?vue&type=script&lang=js&"
import style0 from "./categoryBreakdown.vue?vue&type=style&index=0&lang=css&"
import style1 from "./categoryBreakdown.vue?vue&type=style&index=1&id=36b33415&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b33415",
  null
  
)

export default component.exports