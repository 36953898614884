import Campaigns from '@/components/ams/campaigns/campaigns/index.vue';
import Keywords from '@/components/ams/campaigns/keywords/index.vue';
import SearchTerms from '@/components/ams/campaigns/search_terms/index.vue';
import SKUs from '@/components/ams/campaigns/skus/index.vue';
import CreateBulkCampaign from '@/components/ams/campaign_creation/setupSuggestedCampaign.vue';
import CreateCampaigns from '@/components/ams/campaign_creation/create-campaigns.vue';
import CreateManualCampaigns from '@/components/ams/campaign_creation/create-manual-campaigns.vue';
import CreateBulkSheetCampaigns from '@/components/ams/campaign_creation/create-bulk-sheet-campaigns.vue';
import EditSuggestedCampaign from '@/components/ams/campaign_creation/editSuggestedCampaign.vue';
import CampaignCreated from '@/components/ams/campaign_creation/campaignCreated.vue';
import SetupAdGroupCreation from '@/components/ams/campaign_creation/setupAdGroupCreation.vue';
import CONSTANTS from '@/utils/constants';
// dynamically importing only when user clicks on campaign details, imporoves performance on app launch
const EntityDetails = () => import('@/pages/entity_details/index.vue');
export default {
  configCheckRequired: true,
  routes: [
    {
      path: 'campaigns/:entityType/:entityId',
      name: 'EntityDetails',
      title: 'Campaign Details',
      component: EntityDetails,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM,
        appendEsDataSetName: true
      }
    },
    {
      path: 'campaigns/:entityType/:entityId/create_ad_group',
      name: 'create_ad_group',
      title: 'Create Ad Group',
      component: SetupAdGroupCreation,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM,
        appendEsDataSetName: true
      }
    },
    {
      path: 'campaigns/:entityType/:entityId/:subEntityType/:subEntityId',
      name: 'SubEntityDetails',
      title: 'Ad Group Details',
      component: EntityDetails,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM,
        appendEsDataSetName: true
      }
    },
    {
      path: 'campaigns/create_campaigns',
      name: 'create_campaigns',
      title: 'Campaign Creator',
      component: CreateCampaigns,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM,
        appendEsDataSetName: true
      }
    },
    {
      path: 'campaigns/create_manual_campaigns',
      name: 'create_manual_campaigns',
      title: 'Campaign Creator',
      component: CreateManualCampaigns,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM,
        appendEsDataSetName: true
      }
    },
    {
      path: 'campaigns/create_bulk_sheets_campaigns',
      name: 'create_bulk_sheets_campaigns',
      title: 'Campaign Creator',
      component: CreateBulkSheetCampaigns,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM,
        appendEsDataSetName: true
      }
    },
    {
      path: 'campaigns/setup_suggested_campaign',
      name: 'setup_suggested_campaign',
      title: 'Campaign Creator',
      component: CreateBulkCampaign,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM,
        appendEsDataSetName: true
      }
    },
    {
      path: 'campaigns/campaign_created',
      name: 'campaign_created',
      title: 'Campaign Creator',
      component: CampaignCreated,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM,
        appendEsDataSetName: true
      }
    },
    {
      path: 'campaigns/edit_suggested_campaign',
      name: 'edit_suggested_campaign',
      title: 'Campaign Creator',
      component: EditSuggestedCampaign,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM,
        appendEsDataSetName: true
      }
    },
    {
      path: 'campaigns',
      name: 'Campaigns',
      title: 'Campaigns',
      component: Campaigns,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM,
        appendEsDataSetName: true,
        key: 'cm_campaigns'
      }
    },
    {
      path: 'keywords',
      name: 'Keywords',
      title: 'Keywords',
      component: Keywords,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'keywords',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM,
        appendEsDataSetName: true,
        key: 'cm_keywords'
      }
    },
    {
      path: 'search_terms',
      name: 'Search Terms',
      title: 'Search Terms',
      component: SearchTerms,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'search_terms',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM,
        appendEsDataSetName: true,
        key: 'cm_search_terms'
      }
    },
    {
      path: 'ad_skus',
      name: 'AMS SKUs',
      title: 'Ad SKUs',
      component: SKUs,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'ams_skus',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM,
        appendEsDataSetName: true,
        key: 'cm_ad_skus'
      }
    },
    {
      path: '',
      redirect: () => {
        return 'campaigns';
      }
    }
  ]
};
