import { replacePlaceHolderWithData } from '@/utils/common/dashboard-service-utils';
// @ts-ignore
import HttpService from '@/utils/services/http-service';
import { AxiosResponse } from 'axios';
import {
  CategoryShareDownloadInputParams,
  constructRequest
} from '../service/data-api';
import { modifyPayloadFor3p } from '@/components/pages/insights/amazon/market-share/utils';
export interface RequestFormat {
  page: string;
  widget: string;
  entityType: string;
  metricsList: string[];
  dimensionsList: any[];
  eventsList: any[];
  enablePaginationCount: boolean;
  operations: Operations;
  where: Where;
}

export interface Operations {
  enableNewPVPFormulaForSOV: boolean;
  getSharePercentage: boolean;
  getSharePercentageV2: boolean;
  timeseriesEnabled: boolean;
  timeseriesDimension: string;
  pvpenabled: boolean;
  enableDedupBeforeRollup: boolean;
  timeseriesRollupBy: string;
  additionalDedupAxesApartFromSelectedMeasuresAndGroupByDimensions: string[];
  orderByList: OrderByList[];
}

export interface OrderByList {
  dimension: string;
  direction: string;
}

export interface Where {
  dimensionNameValueList: DimensionNameValueList[];
  date: Date;
  pvpDate: PvpDate;
  excludeDimensionsFromSharePercentage: any[];
}

export interface DimensionNameValueList {
  dimensionName: string;
  dimensionValue: string;
  operator: string;
}

export interface Date {
  from: string;
  to: string;
}

export interface PvpDate {
  from: string;
  to: string;
}

export interface Response {
  url: string;
  status: boolean;
}

export abstract class DownloadDataSerivce {
  async getData(
    widgetRequest: RequestFormat,
    downloadApiParams: CategoryShareDownloadInputParams
  ): Promise<AxiosResponse<Response>> {
    const requestBody = await this.generateDownloadReqBody(
      widgetRequest,
      downloadApiParams
    );
    modifyPayloadFor3p(requestBody);
    return await HttpService.post('DASHBOARD_SERVICE', requestBody, {
      append: '/entity/report/download'
    });
  }

  abstract generateDownloadReqBody(
    widgetRequest: RequestFormat,
    downloadApiParams: CategoryShareDownloadInputParams
  ): RequestFormat;
}

export class PieChartMarketShareCategoryPageDownloadDataService extends DownloadDataSerivce {
  generateDownloadReqBody(
    widgetRequest: RequestFormat,
    downloadApiParams: CategoryShareDownloadInputParams
  ): RequestFormat {
    return constructRequest(widgetRequest, downloadApiParams);
  }
}
