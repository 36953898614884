<template>
  <div
    v-show="showComponent"
    class="u-position-relative u-display-flex u-flex-direction-column u-flex-1"
  >
    <span
      class="u-display-inline-flex u-flex-justify-content-space-between u-flex-align-items-center u-spacing-mh-xxl"
    >
      <slot name="action_header" />
    </span>
    <loader
      v-if="loading"
      class="entity_loader u-color-blue-base fill--parent"
      :color="'#3684bb'"
      :loading="loading"
    />
    <div class="taxonomy-edit-table u-overflow-y-auto">
      <rb-insights-table
        :config="tableConfig"
        :table-column="columnDefs"
        :row-class-rules="gridOptions.rowClassRules"
        :table-row="allRows"
        :row-height="45"
        :grid-options="gridOptions"
        :pagination="true"
        :pagination-total-key="tableConfig.totalRows"
        :pagination-per-page-limit="tableConfig.body.APIConfig.limit"
        :show-pagination-options="true"
      />
    </div>
    <slot name="table_footer" />
  </div>
</template>
<script>
import collapsiblePage from '@/components/basic/collapsiblePage.vue';
import uploadFooter from '@/components/widgets/upload/footer.vue';
import uploadHeader from '@/components/widgets/upload/header.vue';

export default {
  name: 'Preview',
  components: {
    collapsiblePage,
    uploadFooter,
    uploadHeader
  },
  props: {
    showComponent: {
      type: Boolean,
      default: false
    },
    allRows: {
      type: Array,
      default() {
        return [];
      }
    },
    columnDefs: {
      type: Array,
      default() {
        return [];
      }
    },
    subEntityCount: {
      type: Number,
      default: 0
    },
    skusCount: {
      type: Number,
      default: 0
    },
    catalogCount: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    uploadType: {
      type: String,
      default: 'overwrite'
    },
    searchPlaceholder: {
      type: String,
      default: 'Search'
    },
    tableConfig: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      gridOptions: {
        context: {
          componentParent: this
        },
        rowClassRules: {},
        defaultColDef: {
          flex: 1,
          wrapText: true,
          autoHeight: true
        }
      }
    };
  },
  watch: {
    allRows() {
      if (this.allRows.length) {
        this.$nextTick(() => {
          this.gridOptions.api.sizeColumnsToFit();
        });
      }
    },
    showComponent() {
      if (this.showComponent) {
        this.$nextTick(() => {
          this.gridOptions.api.sizeColumnsToFit();
        });
      }
    },
    tableConfig(nv) {}
  }
};
</script>

<style lang="css" scoped>
.taxonomy-edit-table .rb-insights-table-container {
  height: auto;
}
</style>
