<template>
  <section v-if="isEnabled">
    <SystemTransparencyIndicator
      v-if="healthStatus.data.details && healthStatus.data.details.length > 0"
      :services-status="healthStatus.data.details || []"
      :overall-status="healthStatus.data.overall_status"
      :is-loading="healthStatus.loading"
      @toggleDrawer="toggleDrawer"
      @triggerDrawerAPI="() => (shouldTriggerDrawerAPI = true)"
    />
    <SystemTransparencyDrawer
      :services-status="healthStatus.data.details || []"
      :overall-status="healthStatus.data.overall_status"
      :is-open="isDrawerOpen"
      :should-trigger-drawer-a-p-i="shouldTriggerDrawerAPI"
      @closeDrawer="closeDrawer"
      @triggerDrawerAPI="() => (shouldTriggerDrawerAPI = false)"
    />
  </section>
</template>

<script>
import SystemTransparencyIndicator from '@/components/widgets/system-transparency/system-transparency-indicator.vue';
import SystemTransparencyDrawer from '@/components/widgets/system-transparency/system-transparency-drawer/index.vue';
import {
  FETCH_HEALTH_STATUS,
  GET_SUMMARIZED_HEALTH_STATUS,
  GET_IS_DRAWER_OPEN,
  UPDATE_IS_DRAWER_OPEN,
  GET_DATES,
  isTransparencyDashboardEnabled
} from '@/components/widgets/system-transparency/system-transparency-utils.js';
export default {
  name: 'SystemTransparency',
  components: {
    SystemTransparencyIndicator,
    SystemTransparencyDrawer
  },
  data() {
    return {
      intervalId: null,
      shouldTriggerDrawerAPI: false,
      shouldShowIcon: false
    };
  },
  computed: {
    healthStatus() {
      const delay = 300000;
      const healthStatus = this.$store.getters[GET_SUMMARIZED_HEALTH_STATUS];
      if (healthStatus.error) {
        this.$snackbar.open({
          message: healthStatus.error,
          duration: 5000,
          buttonColor: '#f5d908',
          actionText: ' '
        });
      }

      if (healthStatus?.data?.overall_status && !this.intervalId) {
        const intervalId = setInterval(() => {
          this.fetchStatuses();
        }, delay);
        this.intervalId = intervalId;
      }
      return healthStatus || {};
    },
    isDrawerOpen() {
      return this.$store.getters[GET_IS_DRAWER_OPEN];
    },
    dates() {
      return this.$store.getters[GET_DATES];
    },
    retailer() {
      return this.$store.getters.getRetailer || '';
    },
    isEnabled() {
      const retailer = this.$store.getters.getRetailer;
      return isTransparencyDashboardEnabled(retailer);
    }
  },
  watch: {
    dates(newValue) {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.fetchStatuses(newValue);
    }
  },
  created() {
    this.fetchStatuses();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch(UPDATE_IS_DRAWER_OPEN, !this.isDrawerOpen);
    },
    closeDrawer() {
      this.$store.dispatch(UPDATE_IS_DRAWER_OPEN, false);
    },
    fetchStatuses(dates) {
      const pageKey = this.$route.meta.key;
      if (!this.healthStatus.loading && this.isEnabled) {
        this.$store.dispatch(FETCH_HEALTH_STATUS, {
          pageKey,
          dates: dates || this.dates,
          isSummarized: true
        });
      }
    }
  }
};
</script>
