<template>
  <rb-insights-table
    v-show="!isLoading"
    ref="table-widget-ref"
    :config="tableConfig"
    :grid-options="tableGridOptions"
    :table-row="(data || {}).rows"
    :table-column="getColumnDefs"
    style="width: 100%"
    :row-height="80"
    :enable-client-side-sorting="false"
    :enable-server-side-sorting="true"
    :pagination="true"
    :pagination-total-key="
      (((data || {}).rows || [])[0] || {}).totalEntityCount
    "
    :pagination-change-event="paginationChangeEvent"
    :table-header-sticky-obj="stickyObj"
    :sorting-change-event="sortChangeEvent"
    :pagination-per-page-limit="tableConfig.body.APIConfig.limit"
    :download-this-table="false"
    :show-pagination-options="true"
    :row-per-page-list="tableConfig.rowPerPageList"
  />
</template>
<script>
import cardWidget from '@/components/widgets/cardWidget';
import { cloneDeep } from 'lodash';
import { eventBus } from '@/utils/services/eventBus';
import loader from '@/components/basic/loader';
import HttpService from '@/utils/services/http-service';
import { downloadFile } from '@/components/pages/skuDetails/helper.js';
import {
  saveSortingState,
  constants
} from '@/components/pages/insights/amazon/market-share/utils.ts';
export default {
  name: 'TableWidget',
  owner: 'Rajan V',
  components: {
    cardWidget,
    loader
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    metadata: Object,
    // eslint-disable-next-line vue/require-default-prop
    skuCheckBox: {
      type: Object
    },
    // eslint-disable-next-line vue/require-default-prop
    globalSettings: {
      type: [Array, Object]
    },
    dataService: {
      type: Object,
      default: () => ({})
    },
    page: {
      type: String,
      default: null
    },
    widgetName: {
      type: String,
      default: null
    },
    nonFilterableAttributesColumns: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      getColumnDefs: null,
      data: {},
      checkBoxChangeEvent: 'checkBoxChangeEvent',
      paginationChangeEvent: 'paginationTableWidget',
      sortChangeEvent: 'sortingTableWidget',
      tableConfig: {
        title: '52 WEEK CATEGORY SHARE SUMMARY',
        tooltip: '',
        meta: {},
        header: {
          search: false,
          show: true,
          actions: {
            download: {}
          }
        },
        footer: {
          show: true
        },
        body: {
          APIConfig: {
            page: 1,
            limit: 10
          }
        },
        rowPerPageList: [
          { title: 10 },
          { title: 20 },
          { title: 30 },
          { title: 50 }
        ]
      },
      stickyObj: {
        stickySelector: '.ag-header',
        scrollAbleSelector: '.page-body#pageBody',
        addSpaceElementsSelector: ['.page-header', '.sticky-panel'],
        callOnNextTick: true,
        styles: [
          {
            key: 'backgroundColor',
            value: 'white'
          },
          {
            key: 'zIndex',
            value: '2'
          }
        ]
      },
      actionIcons: [
        {
          emit: 'download',
          icon: 'download',
          loading: false,
          error: false,
          tippyTitle:
            'Download Excel with week level Market Share for the selected Manufacturer and time period.'
        }
      ],
      downloadApi: {
        error: false,
        loading: false
      },
      tableGridOptions: {
        context: {
          componentParent: this
        }
      }
    };
  },
  computed: {
    lastUpdateDate() {
      return this.formatter(this.metadata?.calendar?.max_date, 'date');
    },
    isLoading() {
      return this.dataService?.isLoading;
    }
  },
  watch: {
    globalSettings: {
      handler(newVal, oldVal) {
        if (
          this.metadata?.metrics &&
          newVal !== undefined &&
          oldVal !== undefined
        ) {
          this.getColumnDefs = this.dataService?.getColumn?.(
            this.nonFilterableAttributesColumns
          );
          this.resetPagination();
          this.getData();
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.init();
    eventBus.$on(this.paginationChangeEvent, (data) => {
      this.tableConfig.body.APIConfig.page = data;
      this.dataService.pagination.page = data;
      this.$nextTick(() => {
        this.dataService.pagination.limit =
          this.tableConfig.body.APIConfig.limit;
        this.getData();
      });
    });
    eventBus.$on(this.sortChangeEvent, (data, config) => {
      const orderByList = [
        {
          dimensionMapping: data.colDef.sortDimensionMapping,
          dimension: this.metadata.metrics[data.colDef.field].name,
          direction: data.sort.toUpperCase()
        }
      ];
      this.dataService.operations.orderByList = orderByList;
      saveSortingState('skuPage', orderByList);
      this.dataService.pagination.page = 1;
      this.getData();
    });
    eventBus.$on(this.checkBoxChangeEvent, (data) => {
      if (!data?.your && !data?.comp) {
      } else this.getData();
    });
  },

  destroyed() {
    eventBus.$off(this.paginationChangeEvent);
    eventBus.$off(this.sortChangeEvent);
    eventBus.$off(this.checkBoxChangeEvent);
  },
  methods: {
    async init() {
      this.getColumnDefs = this.dataService?.getColumn?.(
        this.nonFilterableAttributesColumns
      );
      await this.getData();
    },
    async getData() {
      const threepSelection =
        this.globalSettings?.settingValue?.include3p?.value;
      let metricsList =
        this.metadata?.metadata?.dimensionMapping?.salesField?.[
          threepSelection
        ];
      const metricsListDetails = this.metadata.metrics[metricsList];
      if (metricsListDetails.api) {
        const payLoad = this.dataService.generatePayload(
          metricsListDetails,
          this.globalSettings,
          this.skuCheckBox
        );
        const apiDetails = cloneDeep(metricsListDetails.api);
        this.data = await this.dataService?.getData(
          payLoad,
          apiDetails,
          this.metadata
        );
        console.log(this.data, 'this.data');
        setTimeout(() => {
          this.tableGridOptions?.api?.sizeColumnsToFit();
        }, 1);
      }
    },
    resetPagination() {
      this.dataService.pagination.page = 1;
      this.tableConfig.body.APIConfig.page = 1;
    },
    updateActionsIconDownload(index) {
      const actionIconsCopy = JSON.parse(JSON.stringify(this.actionIcons));
      actionIconsCopy[index] = {
        ...actionIconsCopy[index],
        ...this.downloadApi
      };
      // this.actionIcons = actionIconsCopy;
      this.actionIcons = Object.assign({}, actionIconsCopy);
    },
    async handleDownload(index) {
      try {
        this.downloadApi.loading = true;
        this.downloadApi.error = false;
        this.updateActionsIconDownload(index);
        await this.downloadReport();
      } catch (e) {
        console.error('e', e);
        this.downloadApi.loading = false;
        this.downloadApi.error = true;
        this.updateActionsIconDownload(index);
      } finally {
        this.downloadApi.loading = false;
        this.updateActionsIconDownload(index);
        setTimeout(() => {
          this.downloadApi.error = false;
          this.updateActionsIconDownload(index);
        }, 1000);
      }
    },
    downloadReport() {
      return new Promise(async (resolve, reject) => {
        try {
          const { endPoint, request, service } =
            this.metadata.downloadApiTemplate;
          const metricsListDetails =
            this.metadata.metrics[
              this.globalSettings.settingValue?.displayShareIn?.metricsList
            ];
          if (metricsListDetails.api) {
            const payLoad = this.dataService.generatePayload(
              metricsListDetails,
              this.globalSettings
            );
            delete payLoad.operations.limit;
            delete payLoad.operations.page;
            const keyPresent =
              payLoad.where?.dimensionNameValueList?.find(
                (item) => item.dimensionName === constants.THREE_P_GROUP_FLAG
              ) || [];
            if (keyPresent?.length > 0) {
              payLoad.dimensionsList.push(keyPresent[0].item.dimensionName);
            }
            payLoad.operations.max_date = this.metadata?.calendar?.max_date;
            payLoad.operations.min_date = this.metadata?.calendar?.min_date;
            const { data } = await HttpService.post(service, payLoad, {
              append: endPoint
            });
            downloadFile(data.url);
          }
          resolve();
        } catch (e) {
          reject(e);
          console.error('error');
        }
      });
    }
  }
};
</script>

<style lang="css" scoped>
.u-height-400 {
  height: 400px;
}
.divider {
  width: 1px;
  height: 16px;
  background-color: #e9eaeb;
}
.radio-wrapper {
  min-width: 135px;
}
</style>
