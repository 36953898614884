<template>
  <div class="u-color-grey-base u-position-relative">
    <span
      v-if="isError"
      v-tippy="tippy"
      title="Something went wrong!"
      class="u-display-flex"
    >
      <rb-icon
        class="rb-icon--medium u-color-red-base"
        :icon="'error-fill'"
      />
    </span>
    <span
      v-else
      class="u-display-flex"
      :class="cellAlignment"
    >
      <span>
        <span :title="columnData">
          {{ columnData }}
        </span>

        <div
          v-if="streamCell"
          class="u-display-flex u-spacing-pt-xs u-flex-align-items-center u-flex-justify-content-flex-end u-color-grey-lighter u-font-size-7 u-font-weight-regular"
        >
          <streamMetricLabel
            icon-class="rb-icon--small"
            :stream-metric-tooltip="streamMetricTooltip"
            parent-class=""
            :show-label="false"
            :delay="[700, 0]"
          />
          <div class="u-spacing-pl-xxs">
            {{ streamData || 'NA' }}
          </div>
        </div>
      </span>
    </span>
  </div>
</template>

<script>
import { formatter } from '@/utils/helpers/formatter.js';
import streamMetricLabel from '@/components/ams/campaigns/components/streamMetricLabel.vue';
import { streamMetricTooltip } from '@/components/ams/campaigns/constants.js';

export default {
  components: {
    streamMetricLabel
  },
  props: {
    unit: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: undefined
    },
    field: {
      type: String,
      default: undefined
    },
    streamCell: {
      type: Boolean,
      default: false
    },
    streamGetter: {
      type: String,
      default: null
    },
    getter: {
      type: String,
      default: undefined
    },
    row: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      localValue: null,
      streamMetricTooltip: streamMetricTooltip,
      tippy: {
        placement: 'bottom-start',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    streamData() {
      const value =
        this.$store?.getters?.[this.streamGetter]?.data?.[
          this.primaryKey
        ]?.[0]?.[this.field];
      return formatter(value, this.unit || this.type);
    },
    metadata() {
      return this.$store.getters[this.getter];
    },
    primaryKey() {
      return this.row?.[this.metadata?.primaryKey];
    },
    columnData() {
      let value = this.row?.[this.field];
      if (this.metadata?.realtime) {
        if (this.isFetched) {
          value = this.getLatestMetadata();
        } else if (this.localValue) {
          value = this.localValue;
        }
      }

      return formatter(value, this.unit || this.type);
    },
    isFetched() {
      return this.metadata.dataFetched;
    },
    isError() {
      return this.metadata.apiFailed;
    },
    cellAlignment() {
      const rightAlignedKeys = ['numeric'];
      return rightAlignedKeys.includes(this.type.toLowerCase())
        ? 'u-flex-justify-content-flex-end'
        : 'u-flex-justify-content-flex-start';
    }
  },
  watch: {
    isFetched(value) {
      if (value) this.localValue = this.getLatestMetadata();
    }
  },
  methods: {
    getLatestMetadata() {
      return this.metadata?.data?.[this.primaryKey]?.[this.field];
    }
  }
};
</script>
