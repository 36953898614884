var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'top', arrow: false }),expression:"{ placement: 'top', arrow: false }"}],staticClass:"config-icon-container u-display-flex u-flex-direction-column u-flex-align-items-items-center u-flex-align-justify-content-center",class:[
    'u-color-' + (((_vm.config.icon || {}).class || {}).color || 'grey-lighter'),
    {
      'u-cursor-pointer': (_vm.config.click || {}).emit
    },
    _vm.config.extraClasses
  ],attrs:{"title":(_vm.config.tooltip || {}).title},on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave,"click":_vm.handleClick}},[_c('rb-icon',{class:[
      'rb-icon--' + (((_vm.config.icon || {}).class || {}).size || 'medium')
    ],style:(_vm.config.icon.style),attrs:{"icon":_vm.config.icon.icon}}),(_vm.config.subText)?_c('div',{class:[
      'u-font-size-' +
        ((((_vm.config.subText || {}).text || {}).class || {}).size || '7'),
      'u-spacing-mt-' + (((_vm.config.icon || {}).class || {}).marginTop || 'xs')
    ]},[_vm._v(" "+_vm._s(_vm.config.subText.text)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }