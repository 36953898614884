<template>
  <div class="u-position-relative">
    <modal
      v-if="showModal"
      :modal-config="modalConfig"
      @clickedOutside="modalVisible(false)"
    >
      <div
        slot="header"
        class="u-text-align-center u-font-size-1 u-font-weight-600 u-width-100 u-spacing-pt-xxl u-spacing-ph-xl"
      >
        Custom catalog "{{ selectedTag }}" will be removed from "{{
          selectedEntity
        }}"
      </div>
    </modal>
    <customEntity
      :widgets-config="widgetsConfig"
      class="custom-catalog-container"
      :custom-load="load"
      @entityInstance="entityInstance"
    >
      <div
        slot="top-right"
        class="u-display-flex"
      >
        <div
          class="u-spacing-pr-l legend-container-height u-flex-align-items-center u-display-flex"
        >
          <div
            class="u-spacing-pv-xs u-border-radius-s u-spacing-ph-s tag-background-color"
          />
          <div class="u-spacing-pl-xs u-font-size-7">Custom Catalog</div>
        </div>
        <div class="u-spacing-pt-xxs">
          <div
            class="u-spacing-ph-l u-border-color-grey-xxx-light u-border-width-s u-border-left u-border-right"
          >
            <rb-button
              slot="trigger"
              :click-fn="createCustomCatalog"
              text="Create a custom catalog"
              size="s"
              icon-left="plus"
              type="filled"
            />
          </div>
        </div>
        <div
          v-for="(iconData, index) in headerIcons"
          :key="index"
        >
          <div
            class="u-spacing-ml-l u-color-grey-lighter u-position-relative u-cursor-pointer u-display-flex u-flex-direction-column u-flex-align-items-center u-flex-justify-content-center"
            @click="handleIconClick(iconData)"
          >
            <loader
              v-if="iconData.load"
              class="fill--parent u-bg-color-grey-white"
              :loading="true"
              :color="'#007cf6'"
            />
            <rb-icon
              :icon="iconData.icon"
              :class="['rb-icon--' + iconData.size || 'medium']"
            />
            <div
              data-cy="actionTitle"
              class="u-font-size-8 u-spacing-mt-xs"
            >
              {{ iconData.title }}
            </div>
          </div>
        </div>
      </div>
    </customEntity>
  </div>
</template>
<script>
import customEntity from '@/pages/toolsAndSettings/entityTagging/common/viewCustomEntity/index.vue';
import config from './widgets.js';
import moment from 'moment';
import loader from '@/components/basic/loader';
import modal from '@/components/widgets/modal.vue';
export default {
  components: {
    customEntity,
    modal,
    loader
  },
  data() {
    return {
      widgetsConfig: null,
      childInstance: null,
      selectedEntity: null,
      load: false,
      selectedTag: null,
      showModal: false,
      headerIcons: [{ icon: 'download', title: 'DOWNLOAD', load: false }],
      path: 'catalog/tag',
      modalConfig: {
        body: {
          text: 'Are you sure you want to perform this action?'
        },
        footer: {
          leftButton: {
            text: 'Cancel',
            onClick: () => {
              this.showModal = false;
            },
            icon: '',
            show: true
          },
          rightButton: {
            text: 'Remove',
            onClick: () => {
              this.handleRemoveTag();
            },
            icon: '',
            show: true
          }
        }
      }
    };
  },
  created() {
    this.widgetsConfig = config;
  },
  methods: {
    handleIconClick(iconData) {
      if (iconData.title === 'DOWNLOAD') {
        this.download(iconData);
      }
    },
    download(iconData) {
      iconData.load = true;
      let message = '';
      this.$store
        .dispatch('performEntityTagsDownlaod', {
          APIConfig: this.widgetsConfig.APIConfig,
          columnMap: [
            {
              field: 'asin',
              title: 'ASIN'
            },
            {
              field: 'asin_name',
              title: 'Title'
            },
            {
              field: 'tags',
              title: 'Tags'
            }
          ],
          entity: 'sku',
          fileName: 'Custom catalog - ' + moment().format('lll')
        })
        .then(() => {
          message = 'Downloaded custom catalog successfully';
        })
        .catch(() => {
          message = 'Custom catalog download failed, please try again';
        })
        .finally(() => {
          this.openSnackbar(message);
          iconData.load = false;
        });
    },
    createCustomCatalog() {
      this.$router.push({ path: this.path });
    },
    modalVisible(value) {
      this.showModal = value;
    },
    handleRemoveTag() {
      const data = {
        tag: {
          level1: this.selectedTag,
          type: 'custom',
          customType: 'Custom'
        },
        entityDetail: {
          value: this.selectedEntity,
          type: 'sku'
        }
      };
      let message = '';
      this.load = true;
      this.$store
        .dispatch('removeEntityFromATag', data)
        .then(() => {
          message = 'Custom catalog was created successfully';
        })
        .catch(() => {
          message = 'Error, please try again';
        })
        .finally(() => {
          this.openSnackbar(message);
          this.load = false;
          this.childInstance.fetchTableRows();
        });
    },
    openSnackbar(message) {
      const snackbarObject = {
        message: '',
        duration: 5000,
        actionText: ''
      };
      snackbarObject.message = message;
      this.$snackbar.open(snackbarObject);
    },
    entityInstance(that) {
      this.childInstance = that;
      that.customCatalogDropdown = (params, instance) => {
        this.customCatalogTagDropdown(params, instance);
      };
    },
    customCatalogTagDropdown(params, instance) {
      this.selectedEntity = instance?.$parent?.params?.data?.asin;
      if (!params[0]) return;
      this.selectedTag = params?.title || '';
      if (params[0].key === 'manage_custom_catalog') {
        this.$router.push({ path: this.path, query: { tag: params.title } });
      } else if (params[0].key === 'remove_custom_catalog') {
        this.modalVisible(true);
      }
    }
  }
};
</script>

<style lang="css">
.custom-catalog-container .tags_view_cell_container .new-tags-selection:hover {
  background-color: rgba(189, 16, 224, 0.1);
}
.custom-catalog-container .tags_view_cell_container .tag_token_container {
  background-color: rgba(189, 16, 224, 0.1);
}
.custom-catalog-container .legend-container-height {
  height: 30px;
}
.custom-catalog-container .legend-container-height .tag-background-color {
  background: rgba(189, 16, 224, 0.1);
}
</style>
