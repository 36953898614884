import CreatePlanV2 from '@/pages/budget-planner-v2/create-plan/index.vue';
import CONSTANTS from '@/utils/constants';
import BudgetPlanDashboardV2 from '@/pages/budget-planner-v2/dashboard/index.vue';
import Actions from '@/components/ams/budgetPlanner/actions/index.vue';

export default {
  configCheckRequired: true,
  routes: [
    {
      path: 'create',
      name: 'create-budget-plan-v2',
      component: CreatePlanV2,
      page: 'budgetPlannerV2',
      config: 'budgetPlannerV2',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'edit',
      name: 'edit-budget-plan-v2',
      component: CreatePlanV2,
      page: 'budgetPlannerV2',
      config: 'budgetPlannerV2',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: '',
      name: 'budget-planner-dashboard-v2',
      component: BudgetPlanDashboardV2,
      page: 'budgetPlannerV2',
      config: 'budgetPlannerV2',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'actions/keywords',
      name: 'budget-plan-keyword-bid-v2',
      component: Actions,
      productRoles: ['MarketingIQ'],
      page: 'budgetPlannerV2',
      config: 'budgetPlannerV2',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'actions/campaigns',
      name: 'budget-plan-campaign-budget-v2',
      component: Actions,
      productRoles: ['MarketingIQ'],
      page: 'budgetPlannerV2',
      config: 'budgetPlannerV2',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: '*',
      redirect: () => {
        return 'budget_optimizer_v2';
      }
    }
  ]
};
