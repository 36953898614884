<template>
  <section
    class="rb_list_select_wrp u-display-flex u-width-100 u-flex-direction-row u-flex-wrap-yes"
  >
    <div
      v-for="item in options"
      :key="item[displayKeyToRead]"
    >
      <div
        v-if="showSelectComponent(item)"
        @click="select(item)"
      />
      <div
        v-if="!showCustomComponent(item[displayKeyToRead])"
        class="u-display-flex u-border-width-s u-border-radius-xl u-border-all u-border-color-grey-xxx-light u-spacing-mr-s selected_item u-spacing-mb-m u-cursor-pointer u-font-size-5"
        :class="{
          'u-spacing-ph-l u-spacing-pv-m': size === 'medium',
          'u-spacing-ph-m u-spacing-pv-s': size === 'small',
          active: (value && value[displayKeyToRead]) === item[displayKeyToRead]
        }"
        @click="select(item)"
      >
        {{ item[displayKeyToRead] }}
      </div>
      <p
        v-if="showCustomComponent(item[displayKeyToRead])"
        @click="customLastDaysSelected()"
      >
        <rb-previous-days-selector
          :on-create="getCustomLastDaysControlInstance"
          :active="
            (value && value[displayKeyToRead]) === item[displayKeyToRead]
          "
          :class="{
            active:
              (value && value[displayKeyToRead]) === item[displayKeyToRead]
          }"
          size="small"
          :number-of-days="decideNumberOfDays(value, item)"
          :number-of-days-excluded="decideExcludedNumberOfDays(value, item)"
          @apply="customLastDayApply"
        />
      </p>
    </div>
  </section>
</template>

<script>
import rbPreviousDaysSelector from './rbPreviousDaysSeletor.vue';
/* eslint-disable vue/require-default-prop */

export default {
  name: 'RbListSelect',
  owner: 'Rajan',
  components: {
    rbPreviousDaysSelector
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Array,
      default: () => []
    },
    selectedValue: {
      type: [Object, String]
    },
    size: {
      type: String,
      default: 'medium'
    },
    displayKeyToRead: {
      type: String,
      default: 'name'
    }
  },
  methods: {
    getCustomLastDaysControlInstance(customLastDaysInstance) {
      this.customLastDaysInstance = customLastDaysInstance;
    },
    select(item) {
      // this.$emit('change', item);
      this.$emit('input', item);
    },
    customLastDaysSelected() {
      const itemWithState = this.customLastDaysInstance.getCurrentState();
      this.$emit('input', itemWithState);
    },
    customLastDayApply(customItem) {
      this.$emit('input', customItem);
    },
    showSelectComponent(item) {
      return !!item.dropdownOptions;
    },
    showCustomComponent(displayName) {
      return displayName === 'Choose custom last X days';
    },
    decideNumberOfDays(selectedItem, optionsItem) {
      if (
        selectedItem &&
        this.showCustomComponent(selectedItem[this.displayKeyToRead])
      ) {
        return selectedItem.numberOfDays;
      } else {
        return optionsItem.numberOfDays;
      }
    },
    decideExcludedNumberOfDays(selectedItem, optionsItem) {
      if (
        selectedItem &&
        this.showCustomComponent(selectedItem[this.displayKeyToRead])
      ) {
        return selectedItem.numberOfDaysExcluded;
      } else {
        return optionsItem.numberOfDaysExcluded;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.selected_item {
  border-radius: 25px;
}

.active {
  border-color: #007cf6;
  color: #007cf6;
}
</style>
