import {
  generatePlotRowChip,
  plotRowsPayloadExtraction
} from '@/pages/campaign-management/common.js';
import metricsDictionary from '@/pages/campaign-management/retailers/dictionary.js';
import {
  commonTableActionIcons,
  baseChartConfig,
  commonChartActionIcons,
  additionalDateRanges,
  gridOptions
} from '@/pages/campaign-management/constants.js';
import {
  getFinalMeasuresList,
  getBaseActionType
} from '@/pages/campaign-management/retailers/criteo/utils.js';
import CriteoKeywordsTableDataService from '@/pages/campaign-management/retailers/criteo/keywords/criteoKeywordsTableDataService';
import HttpService from '@/utils/services/http-service';
import requestConfig from '@/pages/campaign-management/retailers/criteo/keywords/requestConfig.json';
import { klona } from 'klona/json';

const WIDGET = 'keyword';
const PRIMARY_KEY = 'keyword_id';

function chartRequest(retailer) {
  return {
    ...klona(requestConfig.chart),
    measuresList: getFinalMeasuresList(
      klona(requestConfig.chart.measuresList),
      retailer
    )
  };
}

function tableRequest(retailer) {
  return {
    ...klona(requestConfig.table),
    measuresList: getFinalMeasuresList(
      klona(requestConfig.table.measuresList),
      retailer
    )
  };
}

function getEditStatusActionObject(context, retailer) {
  return {
    icon: 'task-status',
    title: 'Edit Status',
    id: 'edit-status',
    dropDown: true,
    dropDownOptions: [
      { title: 'Restore', value: 'restore' },
      { title: 'Remove', value: 'remove' }
    ],
    getInvalidSelections: (selections) =>
      selections.filter(
        (row) => row.data.targeting_type.toLowerCase() !== 'manual'
      ),
    invalidSelectionsMessage:
      'Invalid selections have been automatically deselected.',
    async handler(_, dropDownSelection, selections) {
      const baseActionType = getBaseActionType(retailer);
      const newStatus = dropDownSelection[0].value;
      const payload = selections.map((row) => {
        const { keyword_id, line_item_id, line_item_name, keyword, bid } =
          row.data;
        return {
          actionType: `${baseActionType}AddRemoveKeywordsToLineItem`,
          actionSource: { pageUrl: window.location.href },
          actionPayload: {
            actionType: `${baseActionType}AddRemoveKeywordsToLineItem`,
            line_item_id,
            keyword,
            // because keywords tab only displays keywords with match type - PositiveExactMatch
            keywordMatchType: 'PositiveExactMatch',
            is_deleted: newStatus === 'remove',
            bid: bid
          },
          widget: WIDGET,
          primaryKey: keyword_id,
          viewPayload: {
            line_item_name,
            keyword,
            is_deleted: newStatus === 'remove',
            bid: newStatus === 'restore' ? bid : undefined,
            actionType: `${baseActionType}AddRemoveKeywordsToLineItem`,
            keywordMatchType:
              newStatus === 'restore' ? 'PositiveExactMatch' : undefined
          }
        };
      });
      await HttpService.post('AMS_ACTIONS_ACTION_LOG', payload);
      context?.$refs?.campaignManagement?.$refs?.tableWidget?.switchBlueBar();
    }
  };
}

function getAdjustBidActionObject(retailer, context) {
  return {
    icon: 'dollar',
    title: 'Adjust Bid',
    id: 'adjust-bids',
    customState: {
      component: 'BidChangePanel',
      props: {
        title: 'Adjust Bid',
        actionPanelType: 'keywords',
        apiVersion: 'actionlog',
        retailer: retailer,
        widget: WIDGET,
        applyButtonTooltip:
          'If the line item has a maximum CPC bid set, then the maximum CPC bid will always be respected regardless of whether the keyword bid that was entered is greater.',
        infoBoxText:
          'Auto keywords will be added as manual keywords, after which the bid will be updated accordingly.',
        primaryKey: PRIMARY_KEY,
        customPayload({ bidChangePanelRef, values }) {
          const baseActionType = getBaseActionType(retailer);
          return bidChangePanelRef.selectedValues.map((row) => {
            const {
              keyword_id,
              line_item_id,
              line_item_name,
              keyword,
              targeting_type,
              bid
            } = row.data;
            const oldBid = parseFloat(bid);
            const newBid = parseFloat(values[keyword_id]);
            if (targeting_type === 'Auto') {
              return {
                actionType: `${baseActionType}AddRemoveKeywordsToLineItem`,
                actionSource: { pageUrl: window.location.href },
                actionPayload: {
                  line_item_id,
                  keyword,
                  bid: newBid,
                  actionType: `${baseActionType}AddRemoveKeywordsToLineItem`,
                  keywordMatchType: 'PositiveExactMatch'
                },
                widget: WIDGET,
                primaryKey: keyword_id,
                viewPayload: {
                  line_item_name,
                  keyword,
                  bid: newBid,
                  keywordMatchType: 'PositiveExactMatch',
                  actionType: `${baseActionType}AddRemoveKeywordsToLineItem`
                }
              };
            } else {
              return {
                actionType: `${baseActionType}LineItemKeywordsUpdateBids`,
                actionSource: {
                  pageUrl: window.location.href
                },
                actionPayload: {
                  line_item_id,
                  keyword,
                  actionType: `${baseActionType}LineItemKeywordsUpdateBids`,
                  bid: newBid
                },
                widget: WIDGET,
                primaryKey: keyword_id,
                viewPayload: {
                  line_item_id,
                  keyword,
                  oldBid,
                  newBid,
                  actionType: `${baseActionType}LineItemKeywordsUpdateBids`
                }
              };
            }
          });
        }
      },
      events: {
        afterAPIActionToCall(payload) {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
          context?.$refs?.campaignManagement?.$refs?.tableWidget.closeActionsWorkflow();
        },
        closePanelMethod: () => {
          context?.$refs?.campaignManagement?.$refs?.tableWidget.removeCustomCustomComponents();
        }
      }
    }
  };
}

export default (context, tab, retailer) => {
  const namespace = 'campaignManagement/';
  return {
    namespace,
    bookmark: {
      title: 'Keywords'
    },
    filters: {
      additionalRanges: additionalDateRanges(),
      date: {
        max: 'criteo_common_campaigns.max_feed_date',
        min: 'criteo_common_campaigns.min_feed_date'
      },
      api: {
        cube: 'criteo_common_keywords_workbench',
        page: `${retailer}_keywords`,
        endPoint: 'FETCH_FILTERS_V2'
      },
      commonDateKey: `${retailer}-cm-common-date`,
      id: 'keywords-filter',
      page: `${retailer}_keywords`,
      listenerEvent: `${retailer}-keywords-filters`,
      state: {
        setter: namespace + 'setGlobalFilters',
        getter: namespace + 'getGlobalFilters'
      },
      config: {
        search: {
          enable: true,
          width: '240px',
          emit: 'search-submit-keyword',
          placeholder: 'Search for a Keyword',
          metadata: {
            dimensionName: 'search',
            operator: 'ILIKE'
          }
        },
        emit: 'set-campaign-managment-keywords-filter'
      }
    },
    widget1: {
      widget: WIDGET,
      headerOptions: commonChartActionIcons(),
      dynamicComponents: {
        plotRows(plotRowData) {
          return generatePlotRowChip.call(this, plotRowData);
        }
      },
      // change name to chart widget and table widget
      api: {
        request: chartRequest(retailer)
      },
      chart: {
        props: {
          showBigTooltip: true,
          chartConfig: {
            chartOptions: {
              ...baseChartConfig().chartOptions,
              axis_format: {
                y: { pre: 'currency' },
                y2: { pre: 'currency' }
              }
            }
          },
          ...metricsDictionary(tab, 'chart'),
          selectedMetricLimit: 2,
          minimumMetric: 6
        }
      }
    },
    widget2: {
      dataService: new CriteoKeywordsTableDataService(),
      activityBar: {
        pageType: 'keywords'
      },
      messageBar: {
        pageType: 'keywords'
      },
      actionsBar: {
        actions: [
          {
            icon: 'show-chart',
            title: 'PLOT ROWS',
            id: 'show-chart',
            removeSelectionState: false,
            handler(selectionLength, dropDownSelection, selections) {
              const payload = plotRowsPayloadExtraction(
                selections,
                PRIMARY_KEY,
                false
              );
              // this points to table-widget because have used call in the method call
              this.$emit('plotRowData', payload);
            }
          },
          getEditStatusActionObject(context, retailer),
          getAdjustBidActionObject(retailer, context)
        ]
      },
      widget: WIDGET,
      primaryKey: PRIMARY_KEY,
      headerOptions: commonTableActionIcons(),
      api: {
        request: tableRequest(retailer),
        blacklistMetricsForEmail: [
          'keyword_id',
          'min_bid',
          'max_bid',
          'line_item_id'
        ],
        metadata: {
          paginationKey: 'final_auto_cubesdk_count'
        }
      },
      email: {
        fileSuffix: 'keywords'
      },
      metrics: metricsDictionary(tab, 'table').allMetrics,
      table: {
        props: {
          gridOptions: gridOptions(),
          enableClientSideSorting: false,
          enableServerSideSorting: true,
          paginationChangeEvent: 'campaign-management-pagination',
          sortingChangeEvent: 'campaign-management-table-sort'
        },
        events: {}
      },
      plotRowsPayloadExtractor: plotRowsPayloadExtraction
    }
  };
};
