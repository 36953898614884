import Vue from 'vue';
import { capitalize } from '@/utils/helpers/formatter';

export const campaignStatusComponent = (statusMapping, metadata = null) => {
  const statusColorMap = {
    [statusMapping.liveValue]: 'u-color-green-base',
    [statusMapping.pausedValue]: 'u-color-orange-base',
    [statusMapping.proposalValue]: 'u-color-blue-base',
    [statusMapping.inReviewValue]: 'u-color-yellow-base',
    [statusMapping.rejectedValue]: 'u-color-red-base'
  };
  return Vue.extend({
    data() {
      return {
        localValue: null,
        tippy: {
          placement: 'bottom-start',
          arrow: false,
          popperOptions: {
            modifiers: {
              preventOverflow: {
                enabled: false
              },
              hide: {
                enabled: false
              }
            }
          }
        }
      };
    },
    computed: {
      realtimeStatus() {
        const storeMetadata = this.$store.getters[metadata?.getter];
        const row =
          storeMetadata?.data?.[this.params.data?.[storeMetadata?.primaryKey]];
        const status = row?.[this.campaignStatusField];
        return {
          isRealtime: storeMetadata?.realtime || false,
          status,
          dataFetched: storeMetadata?.dataFetched,
          isFailed: storeMetadata?.apiFailed
        };
      },
      iconClass() {
        return statusColorMap[this.campaignStatus] || 'u-color-grey-lighter';
      },
      campaignStatusField() {
        const storeMetadata = metadata?.getter || '';
        const retailer = this?.$store?.getters?.getRetailer;
        const defValue =
          retailer === 'amazon' &&
          storeMetadata === 'getCampaignsRealTimeMetadata'
            ? 'campaign_status'
            : 'status';

        return statusMapping.statusField || defValue;
      },
      campaignStatus() {
        if (
          this.params.data[this.campaignStatusField] &&
          !this.realtimeStatus.isRealtime
        ) {
          const statusValue =
            typeof this.params.data[this.campaignStatusField] !== 'string'
              ? this.params.data[this.campaignStatusField]
              : this.params.data[this.campaignStatusField]?.toLowerCase();

          return statusValue;
        } else {
          const statusValue =
            (typeof this.realtimeStatus?.status !== 'string'
              ? this.realtimeStatus?.status
              : this.realtimeStatus?.status?.toLowerCase()) ||
            this.localValue ||
            (typeof this.params.data[this.campaignStatusField] !== 'string'
              ? this.params.data[this.campaignStatusField]
              : this.params.data[this.campaignStatusField]?.toLowerCase());
          return statusValue;
        }
      },
      showTooltip() {
        return this.params.showToolTip;
      },
      toolTipText() {
        if (this.params.tooltipFn) {
          return this.params.tooltipFn(this.params.data);
        }
        if (!this.campaignStatus) {
          return '';
        } else {
          return capitalize(this.campaignStatus);
        }
      }
    },
    watch: {
      'realtimeStatus.dataFetched': {
        handler(newVal) {
          if (newVal)
            this.localValue = this.realtimeStatus?.status?.toLowerCase();
        }
      }
    },
    template: `
    <div class="u-spacing-ml-s">
    <span
      v-if="realtimeStatus.isRealtime && realtimeStatus.isFailed"
      v-tippy="tippy"
      title="Something went wrong!"
      class="u-display-flex"
    >
      <rb-icon
        class="rb-icon--medium u-color-red-base"
        :icon="'error-fill'"
      />
    </span>
    <div v-else>
    <div v-if="campaignStatus !== null && campaignStatus !== undefined">
      <div
        v-if="showTooltip"
        v-tippy="tippy"
        :title="toolTipText"
      >
        <rb-icon class="rb-icon--medium" :class="iconClass" icon="task-status" />
      </div>
      <div v-else>
        <rb-icon class="rb-icon--medium" :class="iconClass" icon="task-status" />
      </div>
    </div>
    <span v-else class="u-color-grey-lighter">NA</span>
  </div>
  </div>
      `
  });
};
