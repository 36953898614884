import { getRetailerConfig } from '@/pages/dashboard_service_campaign_management/promoteiq/commonUtils';

export function getActionsConfig(retailer, action) {
  const config = {
    'edit-status': {
      dropDownOptions: [
        { title: 'Block', value: 'Block' },
        { title: 'Unblock', value: 'Unblock' }
      ],
      campaignIdKey: 'promoteiq_cm_keyword_campaign_id'
    },
    block: {
      campaignIdKey: 'promoteiq_cm_keyword_campaign_id',
      validator(row) {
        return true;
      },
      payload: {
        actionType: `${retailer}CampaignBulkBlocklistKeywords`,
        actionPayload(row) {
          return {
            campaignId: row?.data?.promoteiq_cm_keyword_campaign_id,
            campaignType: 'productAds',
            update_keyword: [
              {
                new_text: row?.data?.promoteiq_cm_keyword_keyword,
                new_keyword_isNegative: 'true',
                new_keyword_bidMultiplier: '0'
              }
            ]
          };
        },
        viewPayload(row) {
          return {
            campaignId: row?.data?.promoteiq_cm_keyword_campaign_id,
            campaignType: 'productAds',
            new_text: row?.data?.promoteiq_cm_keyword_keyword,
            new_keyword_isNegative: 'true',
            new_keyword_bidMultiplier: '0'
          };
        }
      },
      shouldSubmitForReview: false
    },
    unblock: {
      campaignIdKey: 'promoteiq_cm_keyword_campaign_id',
      validator(row) {
        return true;
      },
      payload: {
        actionType: `${retailer}CampaignBulkUnblocklistKeywords`,
        actionPayload(row) {
          return {
            campaignId: row?.data?.promoteiq_cm_keyword_campaign_id,
            campaignType: 'productAds',
            update_keyword: [
              {
                new_text: row?.data?.promoteiq_cm_keyword_keyword,
                new_keyword_isNegative: 'false',
                new_keyword_bidMultiplier: String(
                  getRetailerConfig(retailer).bidValues.min
                )
              }
            ]
          };
        },
        viewPayload(row) {
          return {
            campaignId: row?.data?.promoteiq_cm_keyword_campaign_id,
            campaignType: 'productAds',
            new_text: row?.data?.promoteiq_cm_keyword_keyword,
            new_keyword_isNegative: 'false',
            new_keyword_bidMultiplier: String(
              getRetailerConfig(retailer).bidValues.min
            )
          };
        }
      },
      shouldSubmitForReview: false
    }
  };
  return config[action];
}

export function getActionBarActions(context, retailer) {
  return [
    {
      icon: 'dollar',
      title: 'Adjust Bid Modifier',
      id: 'adjust-bids',
      customState: {
        component: 'BidChangePanel',
        props: {
          title: 'Adjust Bid Modifier',
          actionPanelType: 'keywords',
          apiVersion: 'actionlog',
          retailer: context.$store.getters.getRetailer,
          widget: 'keyword',
          primaryKey: 'keyword_id',
          infoBoxText:
            'The Keyword bid should be at least the greatest value of the Minimum Bid across all products on the keyword.',
          customPayload({ bidChangePanelRef, values }) {
            const payload = [];
            bidChangePanelRef.selectedValues.forEach((row) => {
              const campaignId = row.data.promoteiq_cm_keyword_campaign_id;
              const keyword = row.data.promoteiq_cm_keyword_keyword;
              const oldBidMultiplier = String(
                row.data.promoteiq_cm_keyword_bid_modifier
              );
              const bidMultiplier = values[row.data.keyword_id];
              payload.push({
                widget: 'keyword',
                actionType: 'chewy_promoteiqKeywordBulkChangeBidMultiplier',
                actionSource: {
                  pageUrl: window.location.href
                },
                primaryKey: row?.data?.keyword_id,
                actionPayload: {
                  campaignId: campaignId,
                  campaignType: 'productAds',
                  update_keyword: [
                    {
                      new_text: keyword,
                      new_keyword_isNegative: 'false',
                      new_keyword_bidMultiplier: bidMultiplier
                    }
                  ]
                },
                viewPayload: {
                  campaignId: campaignId,
                  campaignType: 'productAds',
                  new_text: keyword,
                  new_keyword_isNegative: 'false',
                  newBidMultiplier: bidMultiplier,
                  oldBidMultiplier: oldBidMultiplier
                },
                previousEntityValue: '',
                newEntityValue: ''
              });
            });
            return payload;
          },
          bidDisplayText: {
            keywords: 'Bid Modifier'
          },
          dropdownOptions: [
            {
              title: 'Change by percentage',
              value: 'percent'
            },
            {
              title: 'Change by absolute value',
              value: 'absolute'
            }
          ]
        },
        events: {
          afterAPIActionToCall(payload) {
            this.$emit('openSnackbar', 'Action has been submitted');
            this.removeCustomCustomComponents();
            this.closeActionsWorkflow();
          },
          closePanelMethod() {
            this.removeCustomCustomComponents();
          }
        }
      }
    }
  ];
}
