<template>
  <div
    class="u-position-relative u-bg-color-white-base u-width-100 u-spacing-p-l u-display-flex u-flex-direction-column"
  >
    <loader
      class="fill--parent u-bg-color-grey-white"
      :loading="load || payloadLoader"
      :color="'#007cf6'"
    />
    <div
      v-show="selections.length > 1"
      class="fillMessage fill--parent u-font-size-3"
    >
      Multiple Campaigns are not allowed
    </div>
    <div>
      <div class="title u-font-size-5 u-spacing-pb-l u-font-weight-600">
        ADD SKUS ({{ selections.length }} campaign{{
          selections.length > 1 ? 's' : ''
        }}
        selected)
      </div>
      <div
        v-if="showAdgroup"
        class="u-display-flex u-flex-align-items-center u-spacing-pb-s"
      >
        <div class="u-font-size-5 u-spacing-pr-s u-font-weight-600">
          Adgroup:
        </div>
        <rb-select
          :send-details="true"
          :options="adgroups"
          :on-close="onAdgroupClose"
          class="u-width-160px u-position-relative"
        >
          <div
            slot="trigger"
            class="u-cursor-pointer"
          >
            <div
              class="u-display-flex u-spacing-p-xs u-width-100 u-flex-justify-content-space-between u-border-all u-border-color-grey-xx-light u-border-width-s u-width-100"
            >
              <span
                class="u-font-size-5 u-text-overflow-ellipsis u-spacing-ml-s u-text-overflow-ellipsis u-color-grey-base"
                >{{
                  !selectedAdgroup
                    ? 'Select Adgroup'
                    : selectedAdgroup.ad_group_name
                }}</span
              >
              <rb-icon
                class="rb-icon--small u-spacing-ml-xs u-color-grey-lighter"
                :icon="'caret-down'"
              />
            </div>
          </div>
          <template
            slot="item"
            slot-scope="option"
          >
            <div>{{ option.ad_group_name }}</div>
          </template>
        </rb-select>
      </div>
    </div>
    <rb-input
      ref="searchBox"
      v-model="skus"
      rows="2"
      placeholder="Enter SKUs seperated by comma"
      type="textarea"
      class="search--input-s u-max-width-400px"
      :rounded="false"
    />
    <div class="u-spacing-pt-xl">
      <rb-button
        text="Cancel"
        :click-fn="onClose"
        size="s"
        class="u-spacing-mr-12"
      />
      <rb-button
        text="Apply"
        type="filled"
        :click-fn="onApply"
        size="s"
        :loading="payloadLoader"
        :disabled="validateAction"
      />
    </div>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
import { commons } from '@/components/ams/campaigns/commons.js';

export default {
  components: {
    loader
  },
  props: {
    actionType: {
      default: 'instacartAdGroupProductsAdd',
      type: String
    },
    showAdgroup: {
      default: false,
      type: Boolean
    },
    load: {
      default: false,
      type: Boolean
    },
    adgroups: {
      default: () => {
        return [];
      },
      type: Array
    },
    selections: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      selectedAdgroup: null,
      skus: '',
      payloadLoader: false
    };
  },
  computed: {
    validateAction() {
      let allow = true;
      if (this.showAdgroup) {
        if (this.selectedAdgroup && this.skus.length) {
          allow = false;
        }
      } else if (!this.showAdgroup) {
        if (this.skus.length) {
          allow = false;
        }
      }
      return allow;
    },
    slectionsLength() {
      return this.selections.length;
    }
  },
  watch: {
    slectionsLength: {
      handler: function (newVal, oldVal) {
        if (newVal === 1 && !oldVal && this.showAdgroup) {
          this.selectedAdgroup = null;
          this.$emit('refreshAdgroup');
        }
      },
      deep: true
    }
  },
  methods: {
    createPayloadRequest() {
      const allSkus = this.skus.split(',');
      const payload = [];
      const selectedRow = this.selections[0].data;
      const selectedAdGroup = this.showAdgroup
        ? this.selectedAdgroup.ad_group_id
        : selectedRow.ad_group_id;
      const selectedAdgroupName = this.showAdgroup
        ? this.selectedAdgroup.ad_group_name
        : selectedRow.ad_group_name;
      for (const item of allSkus) {
        payload.push({
          actionPayload: {
            profileId: 0,
            campaignId: selectedRow.campaign_id,
            campaignType: selectedRow.campaign_type,
            adgroupId: selectedAdGroup,
            asin: item?.trim()
          },
          viewPayload: {
            name: item?.trim(),
            campaignId: selectedRow.campaign_id,
            adgroupId: selectedAdGroup,
            asin: item?.trim(),
            adgroupName: selectedAdgroupName
          },
          actionType: this.actionType,
          actionSource: {
            pageUrl: window.location.href
          }
        });
      }
      return payload;
    },
    onApply() {
      const requestPayload = this.createPayloadRequest();
      var that = this;
      that.loader = true;
      const othersObj = {
        loader: that.loader,
        successMessage: 'Action has been submitted',
        errorMessage: 'Something went wrong.',
        successFn: () => {},
        errorFn: () => {},
        finallyFn: () => {
          that.loader = false;
          this.$emit('afterAPIActionToCall');
        }
      };
      commons.bidChangeApiAction.call(
        this,
        that.selections,
        requestPayload,
        othersObj
      );
    },
    onClose() {
      this.$emit('close');
    },
    onAdgroupClose(s, selection) {
      if (!selection) return;
      this.selectedAdgroup = selection[0];
    }
  }
};
</script>

<style scoped lang="css">
.fillMessage {
  position: absolute;
  display: flex;
  text-align: center;
  opacity: 0.75;
  background: #fff;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}
</style>
