<template>
  <div class="u-spacing-ph-l u-spacing-pv-m">
    <loader
      class="fill--parent u-bg-color-grey-white"
      :loading="loading"
      :color="'#007cf6'"
    >
    </loader>
    <div v-if="!loading">
      <div
        v-if="!isEmpty(adGroupsOfSelectedCampaigns)"
        class="u-display-flex u-flex-align-items-flex-end u-width-50"
      >
        <div
          class="u-border-radius-s u-border-all u-border-width-xs u-border-color-grey-xxx-light u-font-size-6 u-spacing-p-xs u-display-inline-block u-spacing-mr-s u-text-overflow-ellipsis"
        >
          {{ firstCampaignName }}... +
          {{ adGroupsOfSelectedCampaigns.length - 1 }} more
        </div>
        <TippyTableWithHeader
          header-text="Ad Groups"
          :column-definition="groupsTableColDefinition"
          :data-rows="adGroupsOfSelectedCampaigns"
          ad-group-id-key="adgroup_id"
          ad-group-name-key="adgroup_name"
          @onSelectionChange="onAdGroupsSelectionsChange"
        >
          <rb-button
            slot="trigger-content"
            class="edit-groups-btn"
            :text="'Edit'"
            :type="'filled'"
            size="s"
            icon-left="pencil"
          />
        </TippyTableWithHeader>
      </div>
      <div
        v-else
        class="u-font-size-5 u-color-red-base"
      >
        Ad groups for the selected campaigns are not present, hence no action
        can be taken.
      </div>
    </div>
  </div>
</template>

<script>
import loader from '@/components/basic/loader';
import TippyTableWithHeader from '@/components/ams/actionPanels/table-tooltip.vue';
import HttpLayer from '@/utils/services/http-layer';
import { isEmpty, isEqual } from 'lodash';
import { eventBus } from '@/utils/services/eventBus';

// only for use in DS CM when CM Ad Skus action requires Ad Groups. not a generic component.
export default {
  components: {
    loader,
    TippyTableWithHeader
  },
  props: {
    parentRef: {
      type: Object,
      required: true
    },
    aggregator: {
      type: String,
      required: true
    },
    groupsTableColDefinition: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      adGroupsOfSelectedCampaigns: []
    };
  },
  computed: {
    firstCampaignName() {
      return this.adGroupsOfSelectedCampaigns[0]?.[
        `${this.aggregator}_cm_adgroup_campaign_name`
      ];
    }
  },
  watch: {
    'parentRef.selections': {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.fetchAdGroupsForSelectedCampaigns();
        }
      }
    }
  },
  created() {
    this.fetchAdGroupsForSelectedCampaigns();
  },
  methods: {
    isEmpty,
    async fetchAdGroupsForSelectedCampaigns() {
      this.loading = true;
      const selectedCampaigns = this.parentRef.selections;
      const apiData = {
        cubeName: `${this.aggregator}_campaigns_adgroup_workbench`,
        measuresList: [
          `${this.aggregator}_cm_adgroup_campaign_id`,
          `${this.aggregator}_cm_adgroup_campaign_name`,
          `${this.aggregator}_cm_adgroup_adgroup_id`,
          `${this.aggregator}_cm_adgroup_adgroup_name`,
          `${this.aggregator}_cm_adgroup_adgroup_status`,
          `${this.aggregator}_cm_adgroup_spend`,
          `${this.aggregator}_cm_adgroup_roas`,
          `${this.aggregator}_cm_adgroup_base_bid`
        ],
        groupByDimensionsList: ['campaign_id'],
        where: {
          dimensionNameValueList: selectedCampaigns.map((campaign) => ({
            dimensionName: 'campaign_id',
            dimensionValue: campaign.data?.campaign_id
          }))
        }
      };
      try {
        const response = await HttpLayer.post({
          APIData: apiData
        });
        this.adGroupsOfSelectedCampaigns = response.data.map(
          (data) => data.RESULT
        );
      } catch (e) {
        console.error('Error fetching ad groups for selected campaigns', e);
      } finally {
        this.loading = false;
      }
    },
    onAdGroupsSelectionsChange(selections) {
      eventBus.$emit('onAdGroupsSelection', selections);
    }
  }
};
</script>
