<template>
  <div
    class="action_wrp u-overflow-auto u-height-100 u-position-absolute u-width-100"
  >
    <div class="u-display-flex u-flex-direction-column u-width-100">
      <div class="u-display-flex u-flex-justify-content-space-between">
        <div class="u-flex-direction-column">
          <Strategy-Header :step="_step" />
          <rb-list-select
            v-model="selectedValues.actions"
            :size="'small'"
            :options="_entityActionList"
            :display-key-to-read="'actionName'"
            @input="handleTabSelect"
          />
        </div>

        <infoBox
          v-if="infoBox.show"
          class="u-max-width-20 u-flex-align-self-flex-start u-spacing-mb-m"
          :title="infoBox.title"
          :description="infoBox.description"
        />
      </div>
      <div
        v-if="inlineMessages.show"
        class="pageBackground u-spacing-p-l u-display-block u-flex-justify-content-space-between u-width-100 u-font-size-5 u-color-grey-light"
      >
        <div
          v-for="(message, key) in inlineMessages.messages"
          :key="key"
        >
          <span
            v-for="(text, textKey) in message"
            :key="textKey"
          >
            <span
              :class="{
                'u-font-weight-600': text.textType && text.textType === 'bold'
              }"
            >
              {{ text.value }}
            </span>
          </span>
        </div>
      </div>
      <div
        v-if="
          selectedValues.actions &&
          selectedValues.actions.metadata &&
          selectedValues.actions.metadata.component === 'BidMultiplier'
        "
      >
        <BidMultiplierChange
          :steps="steps"
          @form-data-updated="bidMultiplierFormData"
        />
      </div>
      <section class="u-margin-top-40">
        <div
          v-for="(actionSteps, index) in (selectedValues.actions || {})
            .substeps || []"
          :key="index"
          class="u-spacing-mb-m"
        >
          <CollapsibleTitle
            class="u-spacing-mb-m"
            :title="actionSteps.title"
            :is-expanded="actionSteps.expand"
            @click="handleActionsSubstepTitle(index)"
          />
          <GenericActionPanel
            v-show="actionSteps.expand"
            class="u-spacing-mb-m"
            :form-config="actionSteps.formConfig"
            :form-data="actionSteps.formData"
            :general-warning-message="genericActionPanelWarningMessage"
            @form-data-updated="formDataArrayUpdate($event, index)"
          />
        </div>
      </section>
      <div
        v-if="
          selectedValues.actions &&
          !selectedValues.actions.substeps &&
          selectedValues.actions.actionType &&
          availableActionTypes.includes(selectedValues.actions.actionType)
        "
        class="u-spacing-mt-s"
      >
        <GenericActionPanel
          ref="actionPanel"
          :form-config="priceSelectionConfig"
          :form-data="formData"
          :general-warning-message="genericActionPanelWarningMessage"
          @form-data-updated="actionPanelDataChange"
        >
          <BidCpc
            v-if="bidCpcEnabled"
            slot="dynamic"
            :initial-form-data="
              ((selectedValues || {}).actionPanelValues || {}).requestObj ||
              undefined
            "
            @updateFormData="updateFormData"
          />
        </GenericActionPanel>
      </div>
      <div
        v-if="
          selectedValues.actions &&
          selectedValues.actions['lockouts'].length > 0
        "
        class="u-spacing-mt-l"
      >
        <Lockout
          ref="lockoutRef"
          :config="
            (selectedValues.actions && selectedValues.actions['lockouts']) || []
          "
          :set-form-values="lockoutData"
          :entity-type="
            (selectedValues.strategyEntityType &&
              selectedValues.strategyEntityType.entityName) ||
            ''
          "
          @getLockoutChangeValue="getLockoutValues"
        />
      </div>
    </div>

    <section class="u-margin-top-80 u-margin-bottom-80 u-display-flex">
      <rb-button
        id="confirmButton"
        :text="'Back'"
        :type="'hollow'"
        :click-fn="movePrev.bind(this)"
        class="u-spacing-mr-m u-spacing-ph-xl u-spacing-pv-s"
      />
      <rb-button
        id="confirmButton"
        :text="'Next'"
        :disabled="disabledButton"
        :type="'filled'"
        :click-fn="moveNext.bind(this)"
      />
    </section>
  </div>
</template>
<script>
import BidMultiplierChange from '@/components/pages/strategy_builder/steps/action-panels/bidMultiplier.vue';
import StrategyHeader from './header';
import rbListSelect from '@/components/widgets/rbListSelect';
import multiRetailerConfig from '@/components/pages/strategy_builder/multiRetailerConfig.js';
import Lockout from '@/components/pages/strategy_builder/steps/lockoutHandler.vue';
import stepsMixin from '@/components/pages/strategy_builder/steps/stepsMixin.js';
import Vue from 'vue';
import GenericActionPanel from '@/components/pages/strategy_builder/steps/genericActionPanel.vue';
import CollapsibleTitle from '@/components/pages/strategy_builder/steps/collapsible-title.vue';
import { get, set, cloneDeep } from 'lodash';
import infoBox from '@/components/widgets/infoBox';
import utils from '@/utils/helpers/';
import BidCpc from '@/components/pages/strategy_builder/steps/bidCpc.vue';
import bidCpcConfig from '@/components/pages/strategy_builder/steps/bidCpcConfig';

export default {
  name: 'ActionStep',
  components: {
    StrategyHeader,
    rbListSelect,
    Lockout,
    GenericActionPanel,
    BidMultiplierChange,
    CollapsibleTitle,
    infoBox,
    BidCpc
  },
  mixins: [stepsMixin],
  props: {
    step: {
      type: Object,
      default: function () {
        return {};
      }
    },
    selectedValues: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    const retailer = this.$store.getters.getRetailer;
    const isWalmartRetailerAndMigratedClient =
      this.isWalmartRetailerAndMigratedClientCheck();
    const retailerActionStep =
      multiRetailerConfig.actionStep[retailer] ||
      multiRetailerConfig.actionStep.default;
    const retailerActionType = multiRetailerConfig.actionType;
    return {
      steps: [],
      availableActionTypes: [],
      bidChangePanelTitle: 'Adjust Bid',
      formData: {
        priceValue: null,
        priceSetterType: '0',
        priceSource: '1',
        selectedPriceChangeType: {
          title: 'Change by percentage',
          value: 'Percent'
        },
        floorCeilingValue: null
      },
      priceSelectionConfig: {
        showMatchTypeSelection: false,
        showPriceSourceSelection: false,
        showFloorCeilingValue: true,
        pageKey: 'Keyword',
        priceType: 'budget',
        matchTypeOptions: [
          { title: 'exact' },
          { title: 'broad' },
          { title: 'phrase' }
        ],
        priceSetterActions: {
          0: 'newBids',
          1: 'increaseBids',
          2: 'decreaseBids'
        },
        priceChangeTypeOptions: [
          {
            title: 'Change by percentage',
            value: 'Percent'
          },
          {
            title: 'Change by absolute value',
            value: 'Absolute'
          }
        ],
        validationObj: retailerActionStep.validationObj
      },
      isWalmartRetailerAndMigratedClient,
      genericActionPanelWarningMessage: '',
      retailerActionStep,
      retailerActionType,
      retailer
    };
  },
  computed: {
    bidCpcEnabled() {
      return (
        bidCpcConfig.applicableRetailers.includes(this.retailer) &&
        this.priceSelectionConfig.priceType === 'bid' &&
        this.formData.priceSetterType === '0' // set new bid
      );
    },
    inlineMessages() {
      return {
        show:
          (this.selectedValues.actions?.hasMetadata &&
            this.selectedValues.actions?.metadata?.inlineMessages?.length) ||
          false,
        messages: this.selectedValues.actions?.metadata?.inlineMessages || []
      };
    },
    infoBox() {
      return {
        show:
          (this.selectedValues.actions?.hasMetadata &&
            this.selectedValues.actions?.metadata?.infoBox) ||
          false,
        description:
          this.selectedValues.actions?.metadata?.infoBox?.description || null,
        title: this.selectedValues.actions?.metadata?.infoBox?.title || null
      };
    },
    _step() {
      const { header } = this.step;
      if (header?.title) {
        header.title = this.entityTitleFilter(
          header.placeholder || '',
          this.selectedValues?.strategyEntityType?.entityName || ''
        );
      }
      this.step.header = { ...header };
      return { ...this.step };
    },
    _entityActionList() {
      const strategyEntityType = this.selectedValues?.strategyEntityType;
      if (this.metaDataConfig[this.step?.keyToRead] && strategyEntityType) {
        switch (strategyEntityType.entityType) {
          case 'KEYWORD':
            if (this.retailer === 'chewy_promoteiq') {
              this.priceSelectionConfig.priceType = 'bid modifier';
            } else {
              this.priceSelectionConfig.priceType = 'bid';
            }
            break;
          case 'AD_GROUP':
            this.priceSelectionConfig.priceType = 'bid';
            break;
          case 'CAMPAIGN':
            this.priceSelectionConfig.priceType = 'budget';
            break;
          case 'SKU':
          case 'PRODUCTADS':
            this.priceSelectionConfig.priceType = 'bid';
            this.priceSelectionConfig.pageKey = 'SKUs';
            break;
          case 'LINE_ITEM':
            this.priceSelectionConfig.pageKey = 'Line Items';
            break;
        }
        // Calling this method as this method changes the priceSelectionConfig.priceType based on multiple other conditions
        this.handleSelectedValuesActionsChangeInWatch(
          this.selectedValues.actions
        );
        return [
          ...this.metaDataConfig[this.step.keyToRead][
            strategyEntityType.entityType
          ]
        ];
      }
      return [];
    },
    lockoutData() {
      const params = this.selectedValues.executionTemplate;
      if (params.lockoutMetadata) {
        const metricType = Object.keys(params.lockoutMetadata.metrics)[0] || '';
        const metricKey =
          Object.keys(params.lockoutMetadata.metrics[metricType])[0] || '';
        const metric =
          params?.lockoutMetadata?.metrics[metricType][metricKey] || {};
        this.selectedValues.actions.lockouts.forEach((element) => {
          if (element.metricsPeriod === metricType) {
            element.metrics.forEach((item) => {
              if (item.metric === metric.attribute) {
                const operators = Object.keys(item.operators).map(
                  (operator) => {
                    return {
                      item: operator,
                      operator: item.operators[operator]
                    };
                  }
                );
                const selectedOperator = operators.filter((filtervalue) => {
                  if (filtervalue.item === metric.operator) {
                    return filtervalue;
                  }
                })[0];
                // TODO: refs is anti pattern. Need to change this logic.
                this.$nextTick(() => {
                  if (this.$refs.lockoutRef) {
                    this.$refs.lockoutRef.selectedOption = item;
                    this.$nextTick(() => {
                      this.$refs.lockoutRef.selectedOperator = selectedOperator;
                      this.$refs.lockoutRef.selectedValue = metric.value;
                      this.$refs.lockoutRef.validateValues();
                    });
                  }
                });
              }
            });
          }
        });
      }
      return {};
    },
    disabledButton() {
      let isDisable = true;
      if (
        this.selectedValues.actions &&
        this.selectedValues.actions?.metadata?.validationType ===
          'onlyOneFilled'
      ) {
        const allStepsValidity = this.steps.map((step) => {
          if (step.fillRequired) {
            const targetFields = step.radioOptions.find(
              (radioOption) => radioOption.value === step.selectedRadio
            );
            const currentStepValidity = targetFields.children.map((field) => {
              const fields = field.fields;
              const isOneFieldFilled = fields.reduce((a, b) => {
                return a || b?.formData?.priceValue;
              }, false);

              const isCeilingFloorFilled = field.guardRail
                ? field.guardRail.formData[field.guardRail.formConfig.target]
                : false;
              // 1 is if both one field and ceiling-floor value is filled
              // -1 if only one of them is filled and 0 if none is filled
              if (isOneFieldFilled && isCeilingFloorFilled) return 1;
              if (isOneFieldFilled || isCeilingFloorFilled) return -1;
              return 0;
            });
            if (!targetFields.children.some((a) => a.guardRail)) {
              return currentStepValidity.some((a) => a === -1);
            }
            return (
              currentStepValidity.some((a) => a === 1) &&
              !currentStepValidity.some((a) => a === -1)
            );
          }
        });
        isDisable = !allStepsValidity.reduce((a, b) => a || b, false);

        this.$parent.$emit('stepsGlobalEvent', {
          type: 'ValidationBtn',
          values: { step: this.step.textMapping, valid: !isDisable }
        });
        return isDisable;
      } else if (!this.selectedValues?.actionsPanelSubsteps) {
        if (this.selectedValues?.actions?.actionName) {
          if (
            this.selectedValues.actions.actionType &&
            this.availableActionTypes.includes(
              this.selectedValues.actions.actionType
            )
          ) {
            if (
              this.selectedValues?.lockoutValues?.valid &&
              this.selectedValues?.actionPanelValues?.valid
            ) {
              isDisable = false;
            } else {
              isDisable = true;
            }
          } else {
            isDisable = false;
          }
        } else {
          isDisable = true;
        }
        this.$parent.$emit('stepsGlobalEvent', {
          type: 'ValidationBtn',
          values: { step: this.step.textMapping, valid: !isDisable }
        });
        return isDisable;
      } else {
        let isValid = null;
        if (!this.selectedValues.actionsPanelSubsteps) {
          isValid = false;
        } else {
          isValid = this.selectedValues.actionsPanelSubsteps.reduce((a, b) => {
            return a && b.valid;
          }, true);
        }
        this.$parent.$emit('stepsGlobalEvent', {
          type: 'ValidationBtn',
          values: { step: this.step.textMapping, valid: isValid }
        });
        return !isValid;
      }
    }
  },
  watch: {
    'selectedValues.executionTemplate': {
      deep: true,
      handler(newValue, oldValue) {
        this.setActionData();
      }
    },
    'selectedValues.actions': {
      handler: function (newValue) {
        this.handleSelectedValuesActionsChangeInWatch(newValue);
      },
      deep: true
    },
    'selectedValues.scopeFilterValues': {
      handler: function (newScopeFilteredValues) {
        this.updateRetailerActionStep(newScopeFilteredValues);
      },
      immediate: true
    },
    formData: {
      handler: function () {
        this.updateGenericActionPanelWarningMessage();
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.availableActionTypes = multiRetailerConfig.availableActionTypes;
  },
  methods: {
    updateFormData(formData) {
      const requestObject = { ...formData };
      let valid = false;
      if (formData.dynamicMetric) {
        requestObject.field = 'newBid';
        valid =
          requestObject.numericActionType !== 'Set'
            ? !!requestObject.value
            : true;
      } else {
        valid = !!requestObject.value;
      }
      requestObject.value = (requestObject.value || '').toString();
      Vue.set(this.selectedValues, 'actionPanelValues', {
        ...this.selectedValues.actionPanelValues,
        requestObj: requestObject,
        valid
      });
    },
    handleSelectedValuesActionsChangeInWatch(newValue) {
      // entity type changed
      if (newValue) {
        // Target changes.
        // resetting form on actions type change
        if (this.selectedValues?.actions?.substeps) {
          // no real use case for this watch incase of substeps, update as use cases come up
          return;
        }
        if (!this.steps?.length && this.selectedValues?.actions?.metadata) {
          this.steps = cloneDeep(this.selectedValues.actions.metadata?.steps);
          return;
        }

        if (newValue.actionType) {
          const isCitrusRetailer = this.retailer.includes('_citrus');
          const isPromoteIQRetailer = this.retailer.includes('_promoteiq');
          if (isCitrusRetailer && !this.retailerActionType[this.retailer]) {
            const appender = this.retailer.replace('citrus', '').toUpperCase();
            const actionType = newValue.actionType.replace(appender, '');
            this.priceSelectionConfig.priceType =
              this.retailerActionType.citrus_retailer[actionType];
          } else if (
            isPromoteIQRetailer &&
            !this.retailerActionType[this.retailer]
          ) {
            const actionType = newValue.actionType.replace(
              `${this.retailer.toUpperCase()}_`,
              ''
            );
            this.priceSelectionConfig.priceType =
              this.retailerActionType.promoteiq_retailer[actionType];
          } else if (this.retailerActionType[this.retailer]) {
            this.priceSelectionConfig.priceType =
              this.retailerActionType[this.retailer][newValue.actionType];
          }
        }
        const retailerKey = this.retailer.toLowerCase();
        const actionTypeKey = newValue.actionType;
        if (multiRetailerConfig.actionType?.[retailerKey]?.[actionTypeKey]) {
          this.priceSelectionConfig.priceType =
            multiRetailerConfig.actionType[retailerKey][actionTypeKey];
        }
        this.actionPanelDataChange(this.formData);
      }
    },
    getCampaignTypesArrayFromScopedFilters(
      scopeFilteredValues = this.selectedValues.scopeFilteredValues
    ) {
      let campaignTypesArray = [];
      if (scopeFilteredValues) {
        if (scopeFilteredValues.campaign_type) {
          // This means that a campaign_type object is present
          campaignTypesArray = scopeFilteredValues.campaign_type;
        } else if (Array.isArray(scopeFilteredValues)) {
          // This means that the scope filtered values are coming from the backend, edit screen
          // It will look something like this
          // [{"dimensionName":"campaign_type","dimensionValue":"SB","operator":"EQUAL_TO"}]
          // [{"dimensionName":"campaign_type","dimensionValue":"SB","operator":"EQUAL_TO"},{"dimensionName":"campaign_type","dimensionValue":"SP","operator":"EQUAL_TO"}]
          const filteredArrayItems = scopeFilteredValues.filter(
            (filterItem) => filterItem.dimensionName === 'campaign_type'
          );
          campaignTypesArray = filteredArrayItems.map(
            (campaignTypeArrayItem) => campaignTypeArrayItem.dimensionValue
          );
        }
      }
      return campaignTypesArray;
    },
    updateRetailerActionStep(
      scopeFilteredValues = this.selectedValues.scopeFilteredValues
    ) {
      const retailer = this.retailer;
      // Assign the default/retailer specific actionStep initially.
      let retailerActionStep =
        multiRetailerConfig.actionStep[retailer] ||
        multiRetailerConfig.actionStep.default;
      if (this.isWalmartRetailerAndMigratedClient) {
        let actionStepKey = 'default';
        const campaignTypesArray =
          this.getCampaignTypesArrayFromScopedFilters(scopeFilteredValues);
        if (campaignTypesArray.length > 0) {
          const isSPSelected = campaignTypesArray.includes('SP');
          const isSBSelected = campaignTypesArray.includes('SB');
          // If both are present, that means both are selected OR if none of those two are present it means the campaign_type is an empty array
          const isSPandSBSelected = isSPSelected && isSBSelected;
          if (isSPSelected) {
            actionStepKey = 'walmartSP';
          }
          if (isSBSelected) {
            actionStepKey = 'walmartSB';
          }
          if (isSPandSBSelected) {
            actionStepKey = 'walmartSPandSB';
          }
        } else {
          // This means campaign type filter was not selected, which means all types are selected by default
          actionStepKey = 'walmartSPandSB';
        }
        retailerActionStep = multiRetailerConfig.actionStep[actionStepKey];
        this.retailerActionStep = retailerActionStep;
        // Update validation object in this watch function
        this.updatePriceSelectionConfigValidationObject(
          retailerActionStep.validationObj
        );
      }
    },
    updatePriceSelectionConfigValidationObject(newValidationObject) {
      this.priceSelectionConfig.validationObj = newValidationObject;
    },
    updateGenericActionPanelWarningMessage() {
      if (
        this.isWalmartRetailerAndMigratedClient &&
        this.retailerActionStep.warningMessageValidationObject
      ) {
        const newFormData = this.formData;
        const warningMessageValidationObject =
          this.retailerActionStep.warningMessageValidationObject;
        const minVal = warningMessageValidationObject.absolute.min;
        const maxVal = warningMessageValidationObject.absolute.max;
        const priceValue = parseFloat(newFormData.priceValue);
        const floorCeilingValue = parseFloat(newFormData.floorCeilingValue);
        // Value to check can be a direct input price value, or even a floor/ceiling value
        const valueToCheck = priceValue || floorCeilingValue;
        if (valueToCheck) {
          if (valueToCheck >= minVal && valueToCheck <= maxVal) {
            // Update the warning message if it passes this condition
            this.genericActionPanelWarningMessage =
              warningMessageValidationObject?.warningMessage;
            return;
          }
        }
      }
      this.genericActionPanelWarningMessage = '';
    },
    isWalmartRetailerAndMigratedClientCheck() {
      const isWalmartRetailer = this.retailer === 'walmart';
      let campaignCreationEmails = '';
      campaignCreationEmails =
        Vue.options.filters.config_check(
          'feature.walmartCampaignCreationEmails'
        ) || '';
      const campaignCreationEmailsArr = campaignCreationEmails.split(',');
      const isInternalUser =
        utils.internalUserCheck(window.user) ||
        campaignCreationEmailsArr.includes(window?.user?.email);
      return isInternalUser && isWalmartRetailer;
    },
    bidMultiplierFormData() {
      const bidMultiplierFormData = [];
      this.steps.forEach((el) => {
        const selectedRadio = el.radioOptions.find(
          (option) => option.value === el.selectedRadio
        );

        selectedRadio.children.forEach((child) => {
          child.fields.forEach((field) => {
            if (field.formData.priceValue) {
              const objectToPush = {
                field: field.field,
                value: field.formData.priceValue,
                numericActionType: el.selectedRadio,
                numericActionChangeType: 'Percent'
              };

              if (child.guardRail) {
                objectToPush.guardRail = child.guardRail.formData;
              }
              bidMultiplierFormData.push(objectToPush);
            }
          });
        });
      });

      this.$set(
        this.selectedValues,
        'bidMultiplierData',
        bidMultiplierFormData
      );
    },
    handleTabSelect(evt) {
      this.formData.priceValue = null;
      this.formData.floorCeilingValue = null;
      // since, by design, we reuse the same validation criteria for all actions under a retailer, but the validation criteria can change based on the action type
      switch (evt.actionType) {
        case 'CHEWY_PROMOTEIQ_KEYWORD_BID_MODIFIER_CHANGE':
          this.updatePriceSelectionConfigValidationObject(
            multiRetailerConfig.actionStep.chewy_promoteiq
              .bidModifierValidationObj
          );
          break;
        case 'KROGER_SKU_BID_CHANGE':
          this.updatePriceSelectionConfigValidationObject(
            multiRetailerConfig.actionStep.kroger.skuBidValidationObj
          );
          break;
        case 'KROGER_ADGROUP_BASE_BID_CHANGE':
          this.updatePriceSelectionConfigValidationObject(
            multiRetailerConfig.actionStep.kroger.adGroupBaseBidValidationObj
          );
          break;
        default:
          this.updatePriceSelectionConfigValidationObject(
            multiRetailerConfig.actionStep[this.retailer]?.validationObj ??
              multiRetailerConfig.actionStep.default.validationObj
          );
          break;
      }
    },
    setActionData() {
      const params = this.selectedValues.executionTemplate;
      if (params.actionFields && params.actionFields.length > 0) {
        if (
          !this.steps.length &&
          this.selectedValues.actions?.metadata?.steps
        ) {
          const steps = cloneDeep(this.selectedValues.actions.metadata.steps);
          this.steps = steps.map((step) => {
            const path = this.getFieldPaths(step);
            return this.getNewStep(params.actionFields, step, path);
          });
          this.$nextTick(() => {
            this.bidMultiplierFormData();
          });
        } else {
          if (this.selectedValues.actions?.substeps) {
            this.selectedValues.actions.substeps.forEach((step, index) => {
              const tempObj = { ...params.actionFields[index] };
              this.setStateFromApi(tempObj, step.formConfig, step.formData);
              this.$nextTick(() => {
                this.formDataArrayUpdate(this.selectedValues);
              });
            });
          } else {
            const tempObj = { ...params.actionFields[0] };
            this.setStateFromApi(
              tempObj,
              this.priceSelectionConfig,
              this.formData
            );
          }
          this.$nextTick(() => {
            this.actionPanelDataChange(this.formData);
          });
        }
      }
      return {};
    },
    getFieldPaths(step) {
      const path = [];
      step.radioOptions.forEach((radioOption, radioIndex) => {
        radioOption.children.forEach((child, childIndex) => {
          child.fields.forEach((_, fieldIndex) => {
            path.push({
              actionType: `radioOptions.${radioIndex}.value`,
              field: `radioOptions.${radioIndex}.children.${childIndex}.fields.${fieldIndex}.field`,
              update: [
                { key: 'numericActionType', path: 'selectedRadio' },
                {
                  key: 'guardRail',
                  path: `radioOptions.${radioIndex}.children.${childIndex}.guardRail.formData`
                },
                {
                  key: 'value',
                  path: `radioOptions.${radioIndex}.children.${childIndex}.fields.${fieldIndex}.formData.priceValue`
                }
              ]
            });
          });
        });
      });
      return path;
    },
    getNewStep(actionFields, step, path) {
      const newStep = cloneDeep(step);
      actionFields.forEach((actionField) => {
        const { update = [] } =
          path.find(
            ({ actionType, field }) =>
              get(newStep, field, null) === actionField.field &&
              get(newStep, actionType, null) === actionField.numericActionType
          ) || {};
        update?.forEach(({ key, path }) => {
          if (actionField?.[key] !== null) {
            set(newStep, path, actionField?.[key]);
          }
        });
      });
      return newStep;
    },
    setStateFromApi(tempObj, formConfig = {}, formData = {}) {
      (formConfig?.priceChangeTypeOptions || [])?.forEach((option) => {
        if (option.value === tempObj.numericActionChangeType) {
          formData.selectedPriceChangeType = option;
        }
      });
      if (
        ((tempObj.field === 'newBid' || tempObj.field === 'newBidMultiplier') &&
          tempObj.numericActionType === 'Set') ||
        tempObj.dynamicMetric ||
        tempObj.numericActionType === 'Set'
      ) {
        formData.priceSetterType = '0';
      } else if (tempObj.numericActionType === 'Increase') {
        formData.priceSetterType = '1';
        formData.floorCeilingValue = tempObj.guardRail.max;
      } else if (tempObj.numericActionType === 'Decrease') {
        formData.priceSetterType = '2';
        formData.floorCeilingValue = tempObj.guardRail.min;
      }
      formData.priceValue = tempObj.value;
    },
    handleActionsSubstepTitle(clickIndex) {
      const actions = { ...this.selectedValues.actions };
      actions.substeps = actions.substeps.map((item, index) => {
        if (clickIndex === index) {
          item.expand = !item.expand;
        }
        return item;
      });
      Vue.set(this.selectedValues, 'actions', actions);
    },
    transformSubstepsActionPanelData(paramsArray, paramConfigArray) {
      const payload = [];
      for (let i = 0; i < paramsArray.length; i++) {
        const formattedParam = this.transformActionPanelData(
          paramsArray[i],
          paramConfigArray[i]
        );
        payload.push(formattedParam);
      }
      return payload;
    },
    transformActionPanelData(params, config) {
      const _config = config || this.priceSelectionConfig;
      const obj = {
        valid: true,
        ...params
      };
      if (!params.priceValue && !_config.isEmptyPriceValid) {
        obj.valid = false;
      }
      if (_config.isEmptyPriceValid && params.priceValue === '') {
        params.priceValue = null;
      }
      if (params.priceSetterType !== '0' && !params.floorCeilingValue) {
        obj.valid = false;
      }
      if (
        params.priceError ||
        params.floorCeilingError ||
        params.customInputError
      ) {
        obj.valid = false;
      }
      const requestObj = {};

      if (_config.priceType === 'bid') {
        requestObj.field = 'newBid';
      } else if (_config.priceType === 'bid modifier') {
        requestObj.field = 'newBidMultiplier';
      } else if (_config.priceType === 'budget') {
        requestObj.field = 'newDailyBudget';
      }

      if (this.retailer === 'kroger') {
        if (_config.priceType === 'base Bid') {
          requestObj.field = 'newBaseBid';
        } else if (_config.priceType === 'budget') {
          requestObj.field = 'newBudget';
        }
      }

      /**
       * Target and Sams's Club Retailer Changes. This if for criteo based retailers
       */
      if (multiRetailerConfig?.criteoRetailers.includes(this.retailer)) {
        if (_config.priceType === 'bid') {
          requestObj.field = 'newBid';
        } else if (_config.priceType === 'budget') {
          requestObj.field = 'newDailyPacing';
        } else if (_config.priceType === 'maxbid') {
          requestObj.field = 'newMaxBid';
        } else if (_config.priceType === 'newSetTargetBid') {
          requestObj.field = 'newSetTargetBid';
        }
      }
      //  Change ends.

      // These conditions are for citrus based reatilers
      if (multiRetailerConfig?.citrusRetailers.includes(this.retailer)) {
        if (_config.priceType === 'maxbid') {
          requestObj.field = 'newMaxBid';
        }
      }

      const type = _config.priceSetterActions[params.priceSetterType];
      requestObj.numericActionType =
        type.indexOf('new') !== -1
          ? 'Set'
          : type.indexOf('increase') !== -1
          ? 'Increase'
          : 'Decrease';
      if (params?.selectedPriceChangeType?.value) {
        requestObj.numericActionChangeType =
          params.selectedPriceChangeType.value;
      }
      requestObj.value = params.priceValue;
      if (requestObj.numericActionType === 'Increase') {
        requestObj.guardRail = { max: params.floorCeilingValue, min: 0 };
      } else if (requestObj.numericActionType === 'Decrease') {
        requestObj.guardRail = { max: null, min: params.floorCeilingValue };
      }
      obj.requestObj = requestObj;
      return obj;
    },
    moveNext() {
      this.formatDisplayText(this.step.textMapping);
      this.$emit('nextStep', {
        allStep: this.selectedValues,
        currentStep: this.step
      });
    },
    movePrev() {
      this.$emit('prevStep', {
        allStep: this.selectedValues,
        currentStep: this.step
      });
    },
    getLockoutValues(params) {
      Vue.set(this.selectedValues, 'lockoutValues', params);
      // this.$emit('getLockoutValue', params);
    },
    formDataArrayUpdate(params, index) {
      const paramsArray = this.selectedValues.actions.substeps.map(
        (step) => step.formData
      );
      const paramConfig = this.selectedValues.actions.substeps.map(
        (step) => step.formConfig
      );
      const formattedSubstepFormData = this.transformSubstepsActionPanelData(
        paramsArray,
        paramConfig
      );
      Vue.set(
        this.selectedValues,
        'actionsPanelSubsteps',
        formattedSubstepFormData
      );
    },
    actionPanelDataChange(params) {
      this.formData = params;
      const obj = this.transformActionPanelData(params);
      this.retailerBasedTransformation(obj);
      Vue.set(this.selectedValues, 'actionPanelValues', obj);
    },
    retailerBasedTransformation(obj) {
      if (
        bidCpcConfig.applicableRetailers.includes(this.retailer) &&
        this.selectedValues?.executionTemplate?.actionFields?.[0]?.field ===
          'newBid'
      ) {
        if (
          this.selectedValues?.executionTemplate?.actionFields?.[0]
            ?.dynamicMetric
        ) {
          obj.requestObj = {
            ...obj.requestObj,
            ...this.selectedValues.executionTemplate.actionFields[0]
          };
        }
      }
    }
  }
};
</script>
<style lang="css" scoped>
.rotate-icon {
  transform: rotate(90deg);
}
</style>
