import { render, staticRenderFns } from "./downloadCenterIcon.vue?vue&type=template&id=963fa380&scoped=true&"
import script from "./downloadCenterIcon.vue?vue&type=script&lang=js&"
export * from "./downloadCenterIcon.vue?vue&type=script&lang=js&"
import style0 from "./downloadCenterIcon.vue?vue&type=style&index=0&id=963fa380&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "963fa380",
  null
  
)

export default component.exports