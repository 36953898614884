import CampaignManagementWrapper from '@/pages/campaign-management/campaign-management-wrapper.vue';
import WalmartEntityDetails from '@/pages/entity_details/index.vue';
import CONSTANTS from '@/utils/constants';

const getCampaignManagementRoutes = () => {
  const routes = [
    {
      path: 'campaigns/:entityType/:entityId',
      name: 'walmartEntityDetails',
      title: 'Walmart Campaign Details',
      component: WalmartEntityDetails,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns'
    },
    {
      path: 'campaigns/:entityType/:entityId/:subEntityType/:subEntityId',
      name: 'SubEntityDetails',
      title: 'Ad Group Details',
      component: WalmartEntityDetails,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns'
    },
    {
      path: 'campaigns',
      name: 'Campaigns',
      title: 'Campaigns',
      component: CampaignManagementWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'campaigns',
      name: 'Campaigns',
      title: 'Campaigns',
      component: CampaignManagementWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'campaigns',
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      path: 'keywords',
      name: 'Keywords',
      title: 'Keywords',
      component: CampaignManagementWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'keywords'
    },
    {
      path: 'ad_skus',
      name: 'AMS SKUs',
      title: 'Ad SKUs',
      component: CampaignManagementWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'ams_skus'
    },
    {
      path: 'search_terms',
      name: 'Search Terms',
      title: 'Search Terms',
      component: CampaignManagementWrapper,
      productRoles: ['MarketingIQ'],
      page: 'campaignManagement',
      config: 'search_terms'
    }
  ].map((route) => ({
    ...route,
    meta: {
      productLine: CONSTANTS.PRODUCT_LINE.RMM,
      appendEsDataSetName: true
    }
  }));
  routes.push({
    path: '',
    redirect: () => {
      return 'campaigns';
    }
  });
  return routes;
};

export default {
  configCheckRequired: true,
  routes: getCampaignManagementRoutes()
};
