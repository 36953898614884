<template>
  <section
    ref="trigger"
    v-tippy="{
      html: '#' + id,
      reactive: true,
      distance: 4,
      placement: 'bottom',
      interactive: true,
      theme: 'dropdown',
      trigger: 'click',
      duration: [0, 0],
      onShow: onShow,
      onHide: onHide
    }"
  >
    <slot name="trigger">
      <div
        v-if="!showChip"
        class="u-display-flex u-cursor-pointer"
      >
        <rb-icon
          class="u-font-size-5 rb-icon--small u-spacing-mr-xs u-color-grey-lighter"
          icon="add-circle-fill"
        />
        <div class="u-font-size-5 u-color-grey-lighter">
          {{ emptyStateTriggerText }}
        </div>
      </div>
      <div v-else>
        <slot name="rule-builder-chip">
          <ruleBuilderChip
            :pre-text-icon="preTextIcon"
            :chip-selections="chipSelections"
            :has-cross="chipHasCross"
            :pre-text="chipPreText"
            :chip-size="chipSize"
            @crossClick="handleRuleBuilderChipCrossClick"
          >
            <section slot="before-cross">
              <slot name="before-cross" />
            </section>
          </ruleBuilderChip>
        </slot>
      </div>
    </slot>
    <section
      :id="id"
      class="u-border-radius-s"
    >
      <ruleBuilderV2
        ref="rule-builder"
        :root="root"
        :load="load"
        :error="error"
        :no-data="noData"
        @reset="handleRuleBuilderReset"
        @ruleApply="handleRuleApply"
      />
    </section>
  </section>
</template>

<script>
import ruleBuilderChip from '../atoms/rule-builder-chip';
import ruleBuilderV2 from '../molecules/rule-builder-v2';
export default {
  components: {
    ruleBuilderChip,
    ruleBuilderV2
  },
  props: {
    preTextIcon: {
      type: String,
      default: null
    },
    chipSize: {
      type: String,
      default: 'm'
    },
    chipPreText: {
      type: String,
      default: 'Filter by:'
    },
    chipHasCross: {
      type: Boolean,
      default: true
    },
    root: {
      type: Object,
      default: () => ({})
    },
    load: {
      type: Boolean,
      default: false
    },
    noData: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    chipSelections: {
      type: Array,
      default: () => []
    },
    emptyStateTriggerText: {
      type: String,
      default: 'Add a filter'
    }
  },
  data() {
    return {
      // tippyOptions: {},
      id: null,
      selections: [],
      tempSelections: [],
      popupKey: 0,
      isTippyActive: false,
      tippyInstance: null,
      isNextApplyActive: true
    };
  },
  computed: {
    showChip() {
      if (!this.chipSelections.length) {
        return false;
      }
      return true;
    },
    tippyOptions() {
      return {
        html: '#' + this.id,
        reactive: true,
        distance: 4,
        placement: 'bottom',
        interactive: true,
        theme: 'dropdown',
        trigger: 'click',
        duration: [0, 0],
        onShow: this.onShow,
        onHide: this.onHide
      };
    }
  },
  created() {
    this.id = 'dropdown-' + this._uid;
  },
  methods: {
    handleRuleApply(data) {
      this.$emit('ruleApply', data);
    },
    handleRuleBuilderReset() {
      this.$el._tippy.hide();
    },
    handleRuleBuilderChipCrossClick() {
      this.$emit('ruleApply', []);
    },
    handleCancel() {
      this.resetToInitialPopupState();
    },
    onShow() {
      this.isTippyActive = true;
    },
    onHide() {
      this.isTippyActive = false;
      this.$refs['rule-builder'].resetToInitialPopupState();
    }
  }
};
</script>
