<template>
  <div>
    <div
      v-for="(badge, index) in badgeList"
      :key="index"
      class="sku--badge"
    >
      <rb-badge
        v-if="badge.labelText"
        :title="badge.labelText"
        :is-tooltip-enabled="true"
        :tooltip-text="badge.tooltipText"
        class="rb-badge-rounded"
        :class="badge.class"
      />
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/utils/services/eventBus';

export default {
  props: {
    asinId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      badgeList: []
    };
  },
  created() {
    this.getBadgeDetails();
    eventBus.$on('catalogAsinDataReceived', () => {
      this.getBadgeDetails();
    });
  },
  destroyed() {
    eventBus.$off('catalogAsinDataReceived');
  },
  methods: {
    getBadgeDetails() {
      const asin = this.asinId;
      this.$store.dispatch('getDistributionType', { asin }).then((response) => {
        this.badgeList = response;
      });
    }
  }
};
</script>
