var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"editable-tags-cell-container"},[_vm._l((_vm.currentEntities),function(item,key){return _c('div',{key:key,staticClass:"edit-tag-cell-list-item",style:(_vm.editableCellsStyle)},[(_vm.interactiveStateData === 'edit' && _vm.showDropDown)?_c('rb-select-v2',{staticClass:"u-spacing-mr-s u-max-width-100",attrs:{"search-enabled":true,"client-search":false,"search-listener":_vm.skuListener,"send-details":true,"on-close":_vm.onEditOptionSelect,"options":_vm.options},on:{"active-change":_vm.handleDropdownActiveChange}},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-spacing-p-s u-cursor-pointer",class:{ 'active-dropdown': _vm.isDropdownActive },attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{staticClass:"u-flex-1 u-font-size-5 u-text-overflow-ellipsis"},[_vm._v(" "+_vm._s(_vm.selectedOption.title)+" ")]),_c('rb-icon',{staticClass:"u-flex-0 rb-icon--medium u-spacing-ml-s u-color-grey-base",attrs:{"icon":'caret-down'}})],1)]):(_vm.interactiveStateData === 'edit' || _vm.isTagEditable(item))?_c('addTagsPanel',{staticClass:"edit-cell-tag editable-tags-suggestion-container",class:{
        'edit-tag-cell-list-item-edit':
          _vm.interactiveStateData === 'edit' || _vm.isTagEditable(item)
      },attrs:{"tags-suggestions-container-classes":"editable-tags-suggestion-container","enable-style-api":false,"input-placeholder":"","show-footer":false,"show-header":false,"header-text":'YOUR CATEGORIES',"suggestions-data":_vm.getSearchSuggestions,"tags-icons":[
        { icon: 'cross', emit: 'removeActiveTag', color: 'grey-mid-light' }
      ],"initial-tags":_vm.getItemTags(item)[0].title ? _vm.getItemTags(item) : [],"allow-multiple-tags":false},on:{"textInput":_vm.handleTextInput,"tagsChanged":function($event){return _vm.tagsChanged($event, item)}}}):(_vm.interactiveStateData === 'edited')?_c('edited-tag-cell',{staticClass:"tags_view_cell_container u-display-flex u-width-100 u-flex-wrap-yes u-height-100 u-overflow-auto",attrs:{"enable-style-api":false,"edited-entity":item,"enable-text-shrink":true}}):(_vm.interactiveStateData === 'view')?_c('tags-view-cell',{staticClass:"u-display-flex u-width-100 u-flex-wrap-yes u-height-100 u-overflow-auto",class:{
        'empty-tags-cell':
          !_vm.currentEntitiesData.length ||
          _vm.getItemTags(item)[0].type == 'newTagTrigger',
        tags_view_cell_container: _vm.currentEntitiesData.length || _vm.assignable
      },attrs:{"prefer-emitter":_vm.preferEmitter,"enable-style-api":false,"dropdown-options":_vm.dropdownOptions,"tags-list":_vm.getItemTags(item)},on:{"onDropdownOptionSelect":function($event){return _vm.onDropdownOptionSelect($event, item)},"tagClicked":_vm.tagClicked}}):_vm._e()],1)}),(!_vm.currentEntities.length)?_c('span',[_vm._v("NA")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }