<template>
  <div
    id="sankey-chart-widget"
    :class="`rra-sankey-chart ${defaultClass} u-spacing-mv-0`"
  >
    <widgetContainer
      :key="myKey"
      :header-options="headerOptionsComp"
      :header-container-grid-styles="headerContainerGridStyles"
      title="Shortage Invoices Journey Map"
      :is-loading="isLoading"
      :footer-options="footerOptions()"
    >
      <div
        id="cardContainer"
        slot="body"
      >
        <div
          class="u-display-flex u-flex-direction-column u-position-relative u-overflow-hidden"
        >
          <div class="u-display-flex u-flex-direction-column">
            <rb-filter-panel
              v-if="showWidgetData"
              :key="filterSettings.uiKey"
              add-filter-text="Profit Recovery by:"
              listener-event="rra-sankey-filter"
              :disable-between-operator="!filterSettings.enableBetweenOperator"
              :data="filterData"
              :is-always-on-filter="true"
              :primary="primaryData"
              emit="SankeyChartEvent"
              :enable-save="false"
              :custom-config="customConfig"
              :enable-remove-all="false"
              chip-padding-top="0px"
              chip-padding-bottom="0px"
              selections-joining-string=" + "
              :default-filters="defaultFilters"
              :enable-minimum-selection="true"
              @filterMounted="handleFilterMount"
            />
            <div class="u-display-flex u-flex-justify-content-center">
              <div
                v-if="showWidgetData"
                class="u-width-100"
              >
                <sankey-chart
                  :key="sankeyKey"
                  :show-powered-by-c-i-q-text="true"
                  :chart-data="{ nodes, links }"
                  :color-code="cardPrimaryMetricColorCode"
                  :hyperlinks-array="hyperlinkArray"
                  :metric-config="metricConfig"
                  :metric-data="metricDisplayData"
                  :total-value-of-initial-node="totalValue"
                  :downloadable-metric="true"
                  inital-node-name="Invoiced Amount"
                />
              </div>
              <div v-else>
                <noDataState
                  showable-text="Date Type is not compatible hence there is no data"
                  date-select-trigger-text="Change Date Type"
                  icon="calendar"
                  custom-class="u-spacing-mv-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </widgetContainer>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import widgetContainer from '@/components/widgets/custom_widgets/cw_container.vue';
import { isEqual, union, isEmpty } from 'lodash';
import cwMixin from '@/components/widgets/custom_widgets/cw_mixin.js';
import { getMetricData } from '@/components/widgets/custom_widgets/custom_widget_components/cards/utility.js';
import { eventBus } from '@/utils/services/eventBus';
import transformer from '@/utils/services/data-transformer';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import noDataState from './no-data-state.vue';
import sankeyChart from '@/components/basic/sankey/sankey-chart.vue';
import { provide, ref, watchEffect } from '@vue/composition-api';
import { v4 as uuidv4 } from 'uuid';
import { replacePlaceHolderWithData } from '@/utils/common/dashboard-service-utils.js';
import {
  fetchDownloadStatusAtInterval,
  generateOrchestratorAPICallPayload
} from '@/components/widgets/downloadCenter/downloadCenterUtils.js';
import HttpService from '@/utils/services/http-service';
const dashUtils = require('@/utils/common/dashboard-service-utils.js');

export default {
  components: {
    widgetContainer,
    sankeyChart,
    rbFilterPanel,
    noDataState
  },
  mixins: [cwMixin],
  props: {
    cardWidgetTitle: {
      type: String,
      default: ''
    },
    cardWidgetIcon: {
      type: String,
      default: ''
    },
    widgetRequestParams: {
      type: Object,
      default: () => {}
    },
    defaultClass: {
      type: String,
      default: () => ''
    },
    showWidgetData: {
      type: Boolean,
      default: true
    },
    handleOnClickExport: {
      type: Function,
      default: () => {}
    },
    onClickHeader: {
      type: Function,
      default: () => {}
    },
    stopFetch: {
      type: Boolean,
      default: false
    },
    footerOptions: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const metadata = ref(props.metadata);
    const widgetRequestParams = ref(props.widgetRequestParams);

    watchEffect(() => {
      metadata.value = props.metadata;
      widgetRequestParams.value = props.widgetRequestParams;
    });
    provide('metadata', metadata);
    provide('widgetRequestParams', widgetRequestParams);
    return {
      metadata,
      widgetRequestParams
    };
  },
  data() {
    return {
      myKey: 1,
      isLoading: true,
      metricsList: [],
      metricData: [],
      metricConfig: {},
      metricDisplayData: {},
      sankeyKey: 0,
      nodes: [],
      links: [],
      totalValue: 1,
      metricSortedOrderArray: [],
      filterSettings: {
        uiKey: 0,
        enableBetweenOperator: true
      },
      filterData: [
        {
          ciq_filter: 'CIQ'
        },
        {
          ciq_filter: 'You'
        },
        {
          ciq_filter: 'Amazon'
        }
      ],
      primaryData: [
        {
          dimensionColumn: 'ciq_filter',
          dimensionLabel: 'Profit Recovery by: ',
          dimensionName: 'ciq_filter',
          dimensionType: 'REVENUE_RECOVERY_BY'
        }
      ],
      customConfig: {
        title: 'Profit Recovery by',
        anchor: 'ciq_filter',
        type: 'REVENUE_RECOVERY_BY',
        config: {
          dimensionColumn: 'ciq_filter',
          dimensionLabel: 'Profit Recovery by',
          dimensionName: 'ciq_filter',
          dimensionType: 'REVENUE_RECOVERY_BY'
        },
        smart: true,
        multi: true
      },
      hyperlinkArray: [
        {
          title: 'Invoiced vs Shortages',
          key: 'total_invoiced_amount',
          selected: true
        },
        {
          title: 'Recovery In Phase 1',
          key: 'disputed_amount_phase_one'
        },
        {
          title: 'Recovery In Phase 2',
          key: 'disputed_amount_phase_two'
        },
        {
          title: 'Recovery In Phase 3',
          key: 'disputed_amount_phase_three'
        }
      ],
      defaultFilters: {
        values: {
          ciq_filter: ['CIQ', 'You', 'Amazon']
        },
        order: []
      },
      isDownloadingExcel: false
    };
  },
  computed: {
    cardFooterLabel() {
      return this.widgetRequestParams.metadata.cardFooterLabel;
    },
    cardPrimaryMetricColorCode() {
      return this.widgetRequestParams.metadata.cardPrimaryMetricColorCode;
    },
    defaultConfig() {
      return this.widgetRequestParams.metadata.defaultConfig;
    },
    cardPrimaryMetricIconName() {
      return this.widgetRequestParams.metadata.cardPrimaryMetricIconName;
    },
    cardMetricToolTip() {
      return this.widgetRequestParams.metadata.cardMetricToolTip;
    },
    selectedMetricsList1() {
      return this.widgetRequestParams.metadata
        .selectedMetricsListRevenueRecoveryFilterUnaffected;
    },
    selectedMetricsList2() {
      return this.widgetRequestParams.metadata
        .selectedMetricsListRevenueRecoveryFilterAffected;
    }
  },
  watch: {
    widgetRequestParams(newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        if (this.stopFetch) return;
        this.fetchData().then(() => {
          this.$nextTick(() => {
            this.getCardMetricLIst();
            this.metricSortedOrderGenerator();
            this.sankeyDataGenerator();
          });
        });
      }
    },
    minMaxDateRange() {
      this.lastUpdatedDate = moment(this.minMaxDate?.maxDate).format('LL');
    }
  },
  async created() {
    this.setFilterToLocalStorage();
    this.generateMetricConfig();
    eventBus.$on('metric-clicked', (metricId) => {
      this.metricId = metricId;
      this.downloadBucket();
    });
  },
  destroyed() {
    eventBus.$off('SankeyChartEvent');
    eventBus.$off('metric-clicked');
  },
  methods: {
    setFilterToLocalStorage() {
      const filter = localStorage.getItem('rra-sankey-filter');
      if (isEmpty(JSON.parse(filter))) {
        localStorage.setItem(
          'rra-sankey-filter',
          JSON.stringify({ ciq_filter: ['CIQ', 'You', 'Amazon'] })
        );
      }
    },
    generateMetricDisplayData(metricData) {
      metricData.forEach((metric) => {
        const obj = {};
        // metric RESULT is the tag1 or the primary value, while the PVP one is for tag2
        obj.tag1 = metric.RESULT[metric.name];
        obj.tag2 = metric.PVP['PVP_' + metric.name];
        // If metric meta unit is percentage then we need the PVP_DIFF
        if (this.metadata.metrics[metric.name].metadata.unit === 'PERCENTAGE') {
          obj.tag2 = metric.PVP['PVP_DIFF_' + metric.name];
        }
        // Assign the created object to the metric data
        this.metricDisplayData[this.metadata.metrics[metric.name].keyName] =
          obj;
      });
    },
    generateMetricConfig() {
      const metricConfig = {};
      const metricData = Object.values(this.metadata.metrics);
      // Construct the required configs
      metricData.forEach((metric) => {
        const tagConfig = metric.metadata.tagConfig;
        metricConfig[metric.keyName] = tagConfig;
      });
      this.metricConfig = metricConfig;
    },
    getCardMetricLIst() {
      this.metricsList = [];
      Object.keys(this.metadata.metrics).forEach((metric) => {
        this.metricsList.push(this.metadata.metrics[metric]);
      });
    },
    async fetchDataAPICall() {
      const metricsGrouping = dashUtils.groupApis(this.metadata.metrics);
      let response;
      for (const groupName of Object.keys(metricsGrouping)) {
        const api = metricsGrouping[groupName].api;
        const request = dashUtils.replacePlaceHolderWithData(
          api.request,
          this.widgetRequestParams
        );
        request.metricsList = [
          ...this.selectedMetricsList1,
          ...this.selectedMetricsList2
        ];
        request.enablePaginationCount = true;

        await dashUtils
          .fetchDataAPI(request, api)
          .then((args) => {
            response = args?.entityData[0]?.data;
          })
          .catch((error) => {
            console.log('An Error Occured', error.message);
          });
      }
      const data = {};
      response.forEach((e) => {
        data[':' + e.name] = e.RESULT[e.name];
      });
      this.mergeByProperty(this.metricData, response, 'alias');
      this.generateMetricDisplayData(this.metricData);
    },

    async fetchData() {
      try {
        this.isLoading = true;
        await this.fetchDataAPICall();
        this.sankeyKey++;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log('An Error Occured', error.message);
        this.myKey = Math.random();
      }
    },
    cardMetricData(selectedCardLabel, type) {
      let finalObj = {};
      if (type === 'primary') {
        finalObj = getMetricData(selectedCardLabel, this.metricData);
      } else {
        finalObj = getMetricData(
          `${this.cardFooterLabel?.toLowerCase()}_${selectedCardLabel}`,
          this.metricData
        );
      }
      return finalObj;
    },
    sankeyDataGenerator() {
      this.nodes = this.defaultConfig.nodes.map((node) => {
        if (node.id === 'total_invoiced_amount') {
          this.totalValue = this.cardMetricData(node.id, 'primary').tag1;
        }
        return {
          ...node,
          actualValue: this.cardMetricData(node.id, 'primary').tag1,
          color: this.cardPrimaryMetricColorCode[node.id],
          icon: this.cardPrimaryMetricIconName[node.id],
          metricTooltip: this.cardMetricToolTip?.[node.id]
        };
      });
      this.links = this.defaultConfig.links.map((link, index) => {
        return {
          ...link,
          value: this.cardMetricData(link.target, 'primary').tag1 || 0,
          color: this.cardPrimaryMetricColorCode[link.target]
        };
      });
    },
    metricSortedOrderGenerator() {
      let copyArray = [...this.defaultConfig.nodes];
      copyArray.sort((first, second) => {
        return (
          this.cardMetricData(first.id, 'primary').tag1 -
          this.cardMetricData(second.id, 'primary').tag1
        );
      });
      this.metricSortedOrderArray = copyArray;
    },
    handleFilterMount(instance) {
      if (instance?.getSelectedFilters()) {
        let filters = instance.getSelectedFilters();
        let dimensionNameValueList = [];
        if (filters?.values) {
          const widgetArray = ['shortages_rra_overview_sanki'];
          widgetArray.map((widget) => {
            const tempWidgetParams = this.getWidgetRequestParams(widget);
            const where = transformer.generateWhereClause({}, filters, true);
            where?.dimensionNameValueList?.forEach((el) => {
              el.operator = 'ILIKE';
            });

            tempWidgetParams[':dimensionNameValueList'] =
              union(
                tempWidgetParams[':dimensionNameValueList'],
                where.dimensionNameValueList
              ) || [];
            tempWidgetParams[':metricsList'] = this.selectedMetricsList;
            this.updateWidgetRequestParams(tempWidgetParams, widget);
          });
        }
        eventBus.$on('SankeyChartEvent', (data) => {
          filters = instance.getSelectedFilters();
          const filterData = {
            values: { ...data, ...filters.values }
          };
          delete filterData.values.date_range;
          const where = {};
          // Passing empty dimensionNameValueList when all three (CIQ, You and Amazon) are selected
          if (data?.ciq_filter?.length !== 3) {
            where.dimensionNameValueList = data?.ciq_filter?.map(
              (filterValue) => {
                return {
                  dimensionName: 'ciq_filter',
                  dimensionValue: filterValue,
                  operator: 'ILIKE'
                };
              }
            );
          } else {
            where.dimensionNameValueList = [];
          }
          dimensionNameValueList = where.dimensionNameValueList || [];
          const widgetArray = ['shortages_rra_overview_sanki'];
          widgetArray.map((widget) => {
            const tempWidgetParams = this.getWidgetRequestParams(widget);
            let updatedTempWidgetParams = tempWidgetParams[
              ':dimensionNameValueList'
            ].filter((item) => item.dimensionName !== 'ciq_filter');
            tempWidgetParams[':dimensionNameValueList'] = union(
              updatedTempWidgetParams,
              dimensionNameValueList
            );
            this.updateWidgetRequestParams(tempWidgetParams, widget);
          });
        });
      }
    },
    mergeByProperty(target, source, prop) {
      source.forEach((sourceElement) => {
        let targetElement = target.find((targetElement) => {
          return sourceElement[prop] === targetElement[prop];
        });
        targetElement
          ? Object.assign(targetElement, sourceElement)
          : target.push(sourceElement);
      });
    },
    async downloadBucket() {
      try {
        const widgetName = this.metadata.name;
        const requestId = uuidv4();
        const fileName = 'Custom Report being generated';
        const entityType = this.metricId;
        const requestPayload = this.getDownloadPayload(
          this.widgetRequestParams,
          requestId,
          fileName,
          entityType,
          widgetName
        );
        const orchestratorPayload = generateOrchestratorAPICallPayload({
          metadata: this.metadata,
          widgetRequestParams: this.widgetRequestParams,
          downloadPayload: requestPayload,
          requestId,
          urlComponents: this.urlComponents,
          currentSelectedView: this.widgetRequestParams?.title,
          currentSelectedWidget: this.metadata?.label,
          fileName
        });
        return HttpService.post(
          'ORCHESTRATOR_TRIGGER_DOWNLOAD',
          orchestratorPayload
        )
          .then(() => {
            eventBus.$emit('triggerDownloadingAnimation');
            this.$store.dispatch('fetchRecentDownloads');
            fetchDownloadStatusAtInterval(orchestratorPayload?.request_id);
          })
          .catch(() => {
            eventBus.$emit('triggerDownloadingAnimation');
            this.$store.dispatch('fetchRecentDownloads');
            this.$snackbar.open({
              message: `<span class="u-display-flex u-align-items-center u-spacing-pr-xl"><span class="rb-icon icon-info-circle-fill rb-icon--medium u-color-red-base"></span> <span class="u-spacing-pt-xxs u-spacing-pl-s">Download Failed for 1 file</span></span>`,
              duration: 5000,
              showIcon: false,
              customStyles:
                'position:fixed; top:16px; left:50%; transform:translate(-50%,0%);',
              actionText: 'View downloads',
              onAction: () => {
                eventBus.$emit('downloadCenterOpen', 'open');
              },
              buttonColor: '#FFD500',
              type: 'u-text-case-normal',
              actionTextCase: 'none'
            });
          });
      } catch (error) {
        const errorMessage = `Failed to download report for metric ${
          this.metricId
        }: ${error.message || 'Unknown error'}`;
        this.$snackbar.open({
          message: errorMessage,
          duration: 6000,
          actionText: ''
        });
        console.error(errorMessage, error);
      }
    },
    getDownloadPayload(
      requestParams,
      requestId,
      fileName,
      entityType,
      widgetName
    ) {
      const currentWidgetMeta = this.metadata;
      requestParams[':folder_name'] = `${window.clientName}_${
        requestParams.id
      }_${Date.now()}`;
      requestParams[':requestId'] = requestId;
      requestParams[':fileName'] = fileName;
      requestParams[':entityType'] = entityType;
      requestParams[':widget'] = widgetName;
      const baseTemplate = currentWidgetMeta.metadata.download_api_template;
      let requestPayload = replacePlaceHolderWithData(
        baseTemplate,
        requestParams
      );
      requestPayload = replacePlaceHolderWithData(
        requestPayload,
        requestParams
      );
      return requestPayload;
    }
  }
};
</script>

<style lang="css">
.rra-sankey-chart .vertical-line {
  width: 1px;
  height: 45px;
  flex-grow: 0;
  background-color: #e9eaeb;
}
.rra-sankey-chart .metric-component-2 {
  padding-left: 24px !important;
  padding-right: 3px !important;
  margin-bottom: 16px !important;
  margin-top: 16px !important;
}
.rra-sankey-chart .u-color-grey-x-light {
  color: #2b333b;
}
.rra-sankey-chart .widget-description {
  font-size: 14px !important;
}
.rra-sankey-chart svg {
  padding-inline: 24px;
  overflow: scroll !important;
}
.rra-sankey-chart .u-ciq-style {
  position: sticky;
  display: inline-block;
  height: 0;
  top: 16px;
  z-index: 2;
  left: 0;
  width: 100%;
  text-align: right;
  font-size: 14px;
  padding-right: 16px;
}
.rra-sankey-chart .u-ciq-style-2 {
  text-align: left;
  padding-left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}
.rra-sankey-chart .tooltip {
  font-size: 13px;
}
</style>
