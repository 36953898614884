<template>
  <section>
    <sticky-filter-panel
      v-if="!getGlobalViews.load && showFilter"
      ref="sticky-filter-panel"
      :key="stickyFilterKey"
      :show-filter="showFilter"
      class="sticky-panel sticky-filter-panel"
      :filter-data="filterData"
      :primary-filter-data="primaryFilterData"
      :secondary-filter-data="secondaryFilterData"
      :filter-local-storage-key="filterLocalStorageKey"
      :filter-emit="filterEmit"
      :options-list="(getGlobalViews || {}).rows || []"
      :selected-option="getSelectedView"
      :filter-state="filterState"
      :default-date-post-range="defaultDateRange"
      :min-max-date="minMaxDate"
      :hide-view-btn="hideViewBtn"
      :hide-update-view="hideUpdateView"
      :is-update-view-loading="isUpdateViewLoading"
      :is-save-view-loading="isSaveViewLoading"
      :enable-open-filter="enableOpenFilter"
      :enable-time-select="multiCrawl"
      :filter-key="filterKey"
      :additional-ranges="additionalRanges"
      :external-filter-selection-listener="externalFilterSelectionListener"
      @savedFilterApplied="handleSaveFilterApplied"
      @exportWidgets="handleExportWidgets"
      @toggleEdit="handleToggleEdit"
      @input="handleVrSelectInput"
      @updateView="handleUpdateView"
      @resetView="handleResetView"
      @onCreate="onCreate"
      @filterMounted="handleFilterMount"
      @filterBeforeDestroy="handleFilterBeforeDestroy"
    >
      <div
        slot="preDatePicker"
        class="u-display-flex u-flex-align-items-center"
      >
        <div
          id="inline-manual-trigger"
          v-tippy="{ placement: 'top', arrow: false }"
          class="u-border-radius-xs u-spacing-p-s u-border-all u-border-width-s u-border-color-grey-xxx-light u-cursor-pointer u-spacing-mr-s u-bg-color-grey-white u-display-flex u-height-36px"
          title="Know more about this dashboard"
          expoter="display-none"
          @click="triggerInlineManual()"
        >
          <rb-icon
            class="u-color-grey-lighter"
            :icon="'info-circle-fill'"
          />
        </div>
      </div>
      <div slot="footer">
        <Settings
          :types="getPageFiltersMetadata.sovTypes"
          :placements="getPageFiltersMetadata.sovPlacements"
          :keyword-type="getPageFiltersMetadata.sovKeywordType"
          :selected-type="getSelectedSovType"
          :selected-placement="getSelectedSovPlacement"
          :selected-keyword-type="getSelectedKeywordType"
          :selected-crawl-type="crawlType"
          :namespace="namespace"
          :widget-name="'settings'"
          :brands-options="getAnchorBrandsOptions"
          :crawl-type-options="crawlTypes"
          :enable-crawl-type-select="intradayCrawlingAvailable"
          :anchored-brand="getAnchoredBrand"
          @previousAnchorBrand="handlePreviousAnchorBrand"
          @setCrawlType="handleCrawlType"
          @apply="handleApply"
          @pushToUnsavedState="handlePushUnsavedState"
        />
      </div>
    </sticky-filter-panel>
    <section
      v-if="!showFilter"
      class="view-port u-display-flex u-flex-direction-column"
    >
      <loader
        class="u-height-100 u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </section>
    <section
      v-else-if="getGlobalViews.error || getPageList.error"
      class="u-display-flex u-spacing-p-m u-maxWidth u-height-100 u-flex-justify-content-center u-flex-align-items-center u-flex-direction-column"
    >
      <div class="u-display-flex noskuImage_container">
        <img
          alt="error"
          src="/images/no_sku_found.png"
          class="u-width-100"
        />
      </div>
      <div class="u-font-size-3 u-spacing-mt-xl u-font-weight-600">
        <p class="u-color-grey-base u-spacing-mr-s">Something went wrong!</p>
      </div>
    </section>
    <section v-else>
      <section class="u-envelop-width">
        <section
          class="u-spacing-mh-m u-spacing-mv-m u-display-flex slide-card"
        >
          <allDigitalShelves
            ref="allDigitalShelves"
            class="widget-1 u-spacing-mr-m u-width-40"
            :data-service="widget1DataService"
            :namespace="namespace"
            :external-color-maps="globalColorMaps"
            :widget-name="widget1Name"
            :page="page"
            :types="getPageFiltersMetadata.sovTypes"
            :placements="getPageFiltersMetadata.sovPlacements"
            :keyword-type="getPageFiltersMetadata.sovKeywordType"
            :sov-data-groups="sovDataGroups"
            :page-wise-min-max-key="pageWiseMinMaxKey"
            :multi-crawl="multiCrawl"
            :intraday-crawling-available="intradayCrawlingAvailable"
            @newDigitalShelfData="handleWidget1DigitalShelfData"
          />
          <benchmarkBrands
            ref="benchmarkBrands"
            class="widget-2 widget-2-width"
            :external-color-maps="globalColorMaps"
            :data-service="widget2DataService"
            :namespace="namespace"
            :widget-name="widget2Name"
            :initial-state="getInitialWidgetStates"
            :unsaved-state="getUnsavedWidgetStates"
            :page="page"
            :types="getPageFiltersMetadata.sovTypes"
            :placements="getPageFiltersMetadata.sovPlacements"
            :keyword-type="getPageFiltersMetadata.sovKeywordType"
            :page-wise-min-max-key="pageWiseMinMaxKey"
            :multi-crawl="multiCrawl"
            @clientCompData="handleWidget2ClientCompData"
            @brandsData="handleWidget2BrandData"
            @pushToUnsavedState="handlePushUnsavedState"
            @pushToInitialState="handlePushToInitialState"
          />
        </section>
        <section class="u-spacing-mh-m u-spacing-mb-m slide-card">
          <sovByShelves
            ref="sovByShelves"
            class="widget-3 u-width-100"
            :sov-data-groups="sovDataGroups"
            :data-service="widget3DataService"
            :namespace="namespace"
            :widget-name="widget3Name"
            :filter-config-v2="widget3FilterConfigV2"
            :sort-config-v2="widget3SortConfigV2"
            :external-color-maps="globalColorMaps"
            :initial-state="getInitialWidgetStates"
            :unsaved-state="getUnsavedWidgetStates"
            :page="page"
            :types="getPageFiltersMetadata.sovTypes"
            :placements="getPageFiltersMetadata.sovPlacements"
            :keyword-type="getPageFiltersMetadata.sovKeywordType"
            :previous-anchor-brand="previousAnchorBrand"
            :brands-list="getBrandsList"
            :standard-hierarchy="getStandardHierarchy"
            :client-brands="getClientBrandsData"
            :page-wise-min-max-key="pageWiseMinMaxKey"
            :multi-crawl="multiCrawl"
            :intraday-crawling-available="intradayCrawlingAvailable"
            @newMetadata="handleWidget3Metadata"
            @newDigitalShelfData="handleWidget3DigitalShelfData"
            @radioSelection="handleSortRadioSelection"
            @ruleApply="handleRuleApply"
            @sortRuleApply="handleSortRuleApply"
            @retainGlobalColors="handleRetainGlobalColors"
            @pushToUnsavedState="handlePushUnsavedState"
            @pushToInitialState="handlePushToInitialState"
            @setWhereClause="handleSetWhereClause"
            @setKeywordSelection="handleKeywordSelection"
            @addToFilter="handleAddToFilter"
            @keywordsDriver="handleSovByShelvesKeywordsDriver"
            @setAccessedFrom="handleSetAccessedFrom"
            @created="handleSovByShelvesCreated"
          />
        </section>
        <section class="u-spacing-mh-m u-spacing-mb-m slide-card">
          <performanceOverview
            ref="performanceOverview"
            class="widget-4"
            :data-service="widget4DataService"
            :namespace="namespace"
            :page="page"
            :initial-state="getInitialWidgetStates"
            :unsaved-state="getUnsavedWidgetStates"
            :widget-name="widget4Name"
            :sov-type="getSelectedSovType"
            :sov-placement="getSelectedSovPlacement"
            :types="getPageFiltersMetadata.sovTypes"
            :placements="getPageFiltersMetadata.sovPlacements"
            :keyword-type="getPageFiltersMetadata.sovKeywordType"
            :sov-data-groups="sovDataGroups"
            :page-wise-min-max-key="pageWiseMinMaxKey"
            :multi-crawl="multiCrawl"
            :intraday-crawling-available="intradayCrawlingAvailable"
            @pushToUnsavedState="handlePushUnsavedState"
            @pushToInitialState="handlePushToInitialState"
            @setKeywordSelection="handleKeywordSelection"
            @setAccessedFrom="handleSetAccessedFrom"
          />
        </section>

        <section
          v-if="multiCrawl"
          class="u-spacing-mh-m u-spacing-mb-m slide-card"
        >
          <sovTimeOfDayPerformance
            ref="sovTimeOfDayPerformance"
            class="widget-7"
            :data-service="widget7DataService"
            :namespace="namespace"
            :page="page"
            :initial-state="getInitialWidgetStates"
            :unsaved-state="getUnsavedWidgetStates"
            :widget-name="widget7Name"
            :types="getPageFiltersMetadata.sovTypes"
            :keyword-type="getPageFiltersMetadata.sovKeywordType"
            :sov-data-groups="sovDataGroups"
            :page-wise-min-max-key="pageWiseMinMaxKey"
            @pushToUnsavedState="handlePushUnsavedState"
            @pushToInitialState="handlePushToInitialState"
          />
        </section>
        <section class="u-spacing-mh-m u-spacing-mb-m slide-card">
          <drivers
            ref="drivers"
            class="widget-5"
            :brands-list="getBrandsList"
            :standard-hierarchy="getStandardHierarchy"
            :client-brands="getClientBrandsData"
            :data-service="widget5DataService"
            :namespace="namespace"
            :initial-state="getInitialWidgetStates"
            :unsaved-state="getUnsavedWidgetStates"
            :page="page"
            :widget-name="widget5Name"
            :metrics-base-config="metricsBaseConfig"
            :expression-base-config="expressionBaseConfig"
            :text-input-base-config="textInputBaseConfig"
            :radio-base-config="radioBaseConfig"
            :sov-type="getSelectedSovType"
            :sov-placement="getSelectedSovPlacement"
            :types="getPageFiltersMetadata.sovTypes"
            :placements="getPageFiltersMetadata.sovPlacements"
            :keyword-type="getPageFiltersMetadata.sovKeywordType"
            :previous-anchor-brand="previousAnchorBrand"
            :sov-data-groups="sovDataGroups"
            :page-wise-min-max-key="pageWiseMinMaxKey"
            :multi-crawl="multiCrawl"
            :intraday-crawling-available="intradayCrawlingAvailable"
            @pushToUnsavedState="handlePushUnsavedState"
            @pushToInitialState="handlePushToInitialState"
            @showOverlay="showOverlayKeywordSelected"
            @setWhereClause="handleSetWhereClause"
            @setKeywordSelection="handleKeywordSelection"
            @addToFilter="handleAddToFilter"
            @keywordsDriver="handleDriverKeywordsDriver"
            @created="handleDriversCreated"
            @setAccessedFrom="handleSetAccessedFrom"
          />
        </section>
      </section>
    </section>
    <span v-if="!getGlobalViews.load && showFilter">
      <sovSnapshotsIntradayOverlay
        v-if="intradayCrawlingAvailable"
        v-show="isOverlayVisible"
        ref="sovSnapshotsIntradayOverlay"
        class="slide-card corners-rounded overlay-snapshot u-width-100 box-shadows"
        :title="'SOV Snapshots'"
        :intermediate-height="160"
        :image-data-service1="sovImagesDataService1"
        :snapshot-shares-service1="snapShotSharesService1"
        :dropdown-service1="snapshotDropdown1"
        :crawl-times-service1="sovCrawlTimesService1"
        :image-data-service2="sovImagesDataService2"
        :snapshot-shares-service2="snapShotSharesService2"
        :dropdown-service2="snapshotDropdown2"
        :crawl-times-service2="sovCrawlTimesService2"
        :namespace="namespace"
        :widget-name="widget6Name"
        :snapshot-widget-wrapper-class="snapshotWidgetWrapperClass"
        :external-keyword-selected="externalKeywordSelected"
        :global-where-clause="widget6WhereClause"
        :snapshot-filter-pretext="snapshotFilterPretext"
        :snapshot-widget-colors="snapshotWidgetColors"
        :is-marketing-client="isMarketingClient"
        :retailer-brand-name="getRetailerBrandName"
        :page-wise-min-max-key="pageWiseMinMaxKey"
        :external-client-brand="externalClientBrand"
        :multi-crawl="multiCrawl"
        @keywordsDataRequest="handleKeywordsRequest"
        @sovDataRequest="handleSovRequest"
        @newLegendData="handleRefreshColors"
        @close="handleClose"
      >
      </sovSnapshotsIntradayOverlay>
      <sovSnapshot
        v-else
        v-show="isOverlayVisible"
        ref="sovSnapshot"
        class="slide-card corners-rounded overlay-snapshot u-width-100 box-shadows"
        :class="snapshotWidgetWrapperClass"
        :intermediate-height="160"
        :image-data-service="sovImagesDataService1"
        :snapshot-shares-service="snapShotSharesService1"
        :dropdown-service="snapshotDropdown1"
        :namespace="namespace"
        :widget-name="widget6Name"
        :external-keyword-selected="externalKeywordSelected"
        :global-where-clause="widget6WhereClause"
        :filter-pretext="snapshotFilterPretext"
        :colors-map="snapshotWidgetColors"
        :is-marketing-client="isMarketingClient"
        :retailer-brand-name="getRetailerBrandName"
        :page-wise-min-max-key="pageWiseMinMaxKey"
        :external-client-brand="externalClientBrand"
        @keywordsDataRequest="handleKeywordsRequest"
        @sovDataRequest="handleSovRequest"
        @close="handleClose"
        @newLegendData="handleRefreshColors"
      >
        <div
          slot="footer-right"
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-flex-end u-font-size-6"
        >
          <div
            class="u-color-grey-lighter u-display-flex no-wrap u-spacing-mr-xs"
          >
            {{ (snapshotRightFooter || {}).prefix }}
          </div>
          <div
            class="u-color-grey-base u-display-flex no-wrap u-font-weight-600"
          >
            {{ (snapshotRightFooter || {}).infix }}
          </div>
        </div>
      </sovSnapshot>
    </span>
  </section>
</template>

<script>
import chart from '@/components/basic/chart';
import { eventBus } from '@/utils/services/eventBus';
import loader from '@/components/basic/loader';
import allDigitalShelves from './templates/allDigitalShelves';
import benchmarkBrands from './templates/benchmarkBrands';
import sovByShelves from './templates/sovByShelves';
import sovSnapshotsIntradayOverlay from './templates/sovSnapshotsIntradayOverlay.vue';
import performanceOverview from './templates/performanceOverview';
import sovTimeOfDayPerformance from './templates/sovTimeOfDayPerformance.vue';
import drivers from './templates/drivers';
import sovSnapshot from './templates/sovSnapshot.vue';
import CardContainer from './organisms/card-container';
import stickyFilterPanel from './molecules/sticky-filter-panel';
import widgetMixin from '@/components/widgetMixin';
import moment from 'moment-timezone';
import Settings from './molecules/settings.vue';
import DataService from './service/data';
import DonutDataService from './service/donut-chart';
import SovImageDataService from './service/sov-snapshot-image';
import SnapShotSharesService from './service/sov-snapshot-shares.js';
import SingleSelectDropdown from './service/single-select-dropdown.js';
import SovCrawlTimes from './service/sov-crawl-times.js';
import { cloneDeep } from 'lodash';
import {
  HIERARCHY_RAW_DELIMITER,
  SNAPSHOT_WIDGET_WRAPPER_CLASS,
  MY_BRANDS,
  OVERALL_BUSINESS,
  SOV_FILTER_APPLY
} from './templates/constants';
import {
  filterFromArray,
  findInArray
} from './templates/dashboard-service-utils';
import { compareObjects } from './templates/views-tracking';
import transformer from '@/utils/services/data-transformer.js';
import CONSTANTS from '@/utils/constants.js';

import {
  replaceOrderAndValue,
  getAllPathTillLevel,
  filterStringStart,
  getAllKeyPaths,
  objectRootLength,
  flattenArrayOfArrays,
  getSegmentedBrandsData,
  splitClientCompOnAnchor,
  appendAnchorBrandCondition,
  getSovDataGroups,
  getAnchorFilterPayload,
  disableInHierarchy,
  isIntradayCrawlingEnabled
} from './templates/common';
export default {
  components: {
    chart,
    loader,
    stickyFilterPanel,
    Settings,
    allDigitalShelves,
    benchmarkBrands,
    sovByShelves,
    sovSnapshotsIntradayOverlay,
    performanceOverview,
    sovTimeOfDayPerformance,
    drivers,
    sovSnapshot,
    CardContainer
  },
  mixins: [widgetMixin],
  data() {
    return {
      additionalRanges: {
        add: ['lastMonth', 'lastWeek'],
        order: {
          lastWeek: 3,
          lastMonth: 3
        }
      },
      sovDataGroups: getSovDataGroups(),
      previousAnchorBrand: {},
      driverCreated: false,
      sovByShelvesCreated: false,
      externalFilterSelectionListener: 'set-external-selection',
      snapshotWidgetColors: {},
      snapshotFilterPretext: null,
      snapshotRightFooter: {},
      widget3NavigateToKeywords: false,
      widget5NavigateToKeywords: false,
      snapshotWidgetWrapperClass: SNAPSHOT_WIDGET_WRAPPER_CLASS,
      externalKeywordSelected: null,
      externalWhereClause: [],
      isOverlayVisible: false,
      filterMounted: false,
      standardHierarchyPromise: null,
      customHierarchyPromise: null,
      renderKey: 0,
      pageFetched: false,
      enableOpenFilter: false,
      filterInstanceCreated: false,
      stickyFilterKey: 0,
      filterKey: 0,
      recomputeGlovbalColors: false,
      isUpdateViewLoading: false,
      isSaveViewLoading: false,
      defaultDateRange: 'last30Days',
      filterInstance: null,
      hideViewBtn: true,
      hideUpdateView: true,
      widget1Data: null,
      widget2Data: null,
      widget3Data: null,
      colorPallete: [
        { hex: '#007cf6' },
        { hex: '#caccce' },
        { hex: '#3ea95e' },
        { hex: '#ffd500' },
        { hex: '#23b5d3' },
        { hex: '#97cc04' },
        { hex: '#f7981c' },
        { hex: '#ff6072' },
        { hex: '#bd10e0' },
        { hex: '#d7263d' },
        { hex: '#4f14e3' },
        { hex: '#73ff61' },
        { hex: '#eefa0a' },
        { hex: '#61ffed' },
        { hex: '#c2ff17' },
        { hex: '#f73edf' },
        { hex: '#8a0cff' },
        { hex: '#ff0000' },
        { hex: '#9e9cff' },
        { hex: '#a7dfb8' },
        { hex: '#fffda4' },
        { hex: '#b6f1e9' },
        { hex: '#c4fff7' },
        { hex: '#02e0a9' },
        { hex: '#ff74ab' },
        { hex: '#bc73ff' },
        { hex: '#83b7eb' },
        { hex: '#008b8b' },
        { hex: '#05d3fc' },
        { hex: '#c4a837' },
        { hex: '#6b5941' },
        { hex: '#a81890' },
        { hex: '#8c7382' },
        { hex: '#7c1f1a' }
      ],
      globalColorMaps: null,
      namespace: 'sov/',
      widget1Name: 'allDigitalShelves',
      widget2Name: 'benchmarkBrands',
      widget3Name: 'sovByShelves',
      widget4Name: 'performanceOverview',
      widget5Name: 'drivers',
      widget6Name: 'sovSnapshot',
      widget7Name: 'sovTimeOfDayPerformance',
      pageId: null,
      widget1DataService: {},
      widget2DataService: {},
      widget3DataService: {},
      widget4DataService: {},
      widget5DataService: {},
      widget7DataService: {},
      sovImagesDataService1: {},
      snapShotSharesService1: {},
      snapshotDropdown1: {},
      sovImagesDataService2: {},
      snapShotSharesService2: {},
      sovCrawlTimesService1: {},
      sovCrawlTimesService2: {},
      snapshotDropdown2: {},
      widget2BrandsData: {},
      showFilter: false,
      filterEmit: SOV_FILTER_APPLY,
      page: 'SOV',
      filterLocalStorageKey: 'sov-filters',
      pageTypeLocalStorageKey: 'sov-page-types',
      minMaxDate: {},
      widget3SortConfig: {
        load: true,
        error: false,
        noData: false,
        stepConfig: []
      },
      widget3SortConfigV2: {
        load: true,
        error: false,
        noData: false,
        root: {}
      },
      widget3FilterConfigV2: {
        load: true,
        error: false,
        noData: false,
        root: {}
      },
      widget3FilterConfig: {
        load: true,
        error: false,
        noData: false,
        stepConfig: []
      },
      widget3SortRadioSelectSelectedValue: [],
      metricsBaseConfig: {
        component: 'ruleBuidlerMetrics',
        stepTitle: 'Metrics',
        key: 'ruleBuilderRadioSelect',
        keepAlive: true,
        props: {
          ruleBuilderChipConfig: {
            radio: {
              prefix: {},
              suffix: {}
            }
          }
        }
      },
      textInputBaseConfig: {
        component: 'ruleBuilderTextInput',
        stepTitle: 'Share of Voice of',
        key: 'ruleBuilderRadioSelect',
        keepAlive: true,
        props: {}
      },
      expressionBaseConfig: {
        component: 'ruleBuilderExpression',
        stepTitle: 'Expression',
        key: 'ruleBuilderExpression',
        keepAlive: false,
        props: {
          ruleBuilderChipConfig: {
            queryBuilder: {
              prefix: {},
              suffix: {}
            },
            radio: {
              prefix: {},
              suffix: {}
            }
          },
          radioSelects: [
            { label: 'Absolute', value: 'ABSOLUTE' },
            { label: 'Change', value: 'CHANGE' }
          ],
          selectedRadio: { label: 'Absolute', value: 'ABSOLUTE' },
          absoluteRange: {
            percentage: {
              min: 0,
              max: 100
            },
            numeric: {
              min: 0
            }
          },
          selectedText: '',
          hasQueryBuilder: true,
          metrics: {
            config: {
              dimensionLabel: 'Sales',
              dimensionName: 'paid_sales',
              dimensionColumn: 'paid_sales',
              dimensionType: 'EXPRESSION',
              addtionalMetadata: {
                expressionField: {
                  expressionType: 'NUMERIC',
                  operators: [
                    'EQUAL_TO',
                    'GREATER_THAN',
                    'GREATER_THAN_OR_EQUAL_TO',
                    'LESS_THAN',
                    'LESS_THAN_OR_EQUAL_TO',
                    'NOT_EQUAL_TO',
                    'IS_NULL',
                    'IS_NOT_NULL'
                  ]
                },
                type: 'NUMERIC',
                unit: 'PERCENTAGE'
              }
            }
          }
        }
      },
      radioBaseConfig: {
        component: 'ruleBuilderRadioSelect',
        stepTitle: 'Share of Voice of',
        key: 'ruleBuilderRadioSelect',
        keepAlive: true,
        props: {
          ruleBuilderChipConfig: {
            radio: {
              prefix: {},
              suffix: {}
            }
          }
        }
      },
      pageWiseMinMaxKey: 'SOV',
      refreshLimit: 5,
      refreshCount: 0,
      crawlType: CONSTANTS.crawlTypes.allKeywords
    };
  },
  computed: {
    externalClientBrand() {
      if (this.isMyBrandsAnchored) {
        return null;
      } else {
        const { anchoredBrand } = this.getUnsavedSettings;
        return anchoredBrand?.title;
      }
    },
    isMyBrandsAnchored() {
      const { anchoredBrand } = this.getUnsavedSettings;
      if (!anchoredBrand || anchoredBrand?.title === MY_BRANDS) {
        return true;
      }
      return false;
    },
    getAnchorBrandsOptions() {
      return [{ title: MY_BRANDS, client_flag: 'client' }].concat(
        this.getClientBrandsData.rows
      );
    },
    getClientBrandsData() {
      return this.$store.getters[this.namespace + 'getClientBrandsData'];
    },
    getAnchoredBrand() {
      const { anchoredBrand } = this.getUnsavedSettings;
      const foundObject = findInArray(
        anchoredBrand?.title || MY_BRANDS,
        this.getClientBrandsData.rows,
        'title'
      );
      return (
        foundObject || {
          title: anchoredBrand?.title || MY_BRANDS
        }
      );
    },
    getRetailerBrandName() {
      return this.$store.getters.getRetailerBrandName;
    },
    isMarketingClient() {
      return this.$store.getters.getProductConfig.indexOf('MarketingIQ') > -1;
    },
    widget6WhereClause() {
      const globalWhereClause = this.globalWhereClause();
      globalWhereClause.dimensionNameValueList.push(
        ...this.externalWhereClause
      );
      return globalWhereClause;
    },
    getCustomHierarchy() {
      return this.$store.getters[this.namespace + 'getCustomHierarchy'];
    },
    getStandardHierarchy() {
      return this.$store.getters[this.namespace + 'getStandardHierarchy'];
    },
    getBrandsList() {
      return this.$store.getters[this.namespace + 'getBrandsData'];
    },
    getInitialWidgetStates() {
      return this.$store.getters[this.namespace + 'getInitialWidgetStates'];
    },
    getUnsavedWidgetStates() {
      return this.$store.getters[this.namespace + 'getUnsavedWidgetStates'];
    },
    filterState() {
      return {
        getter: this.namespace + 'getGlobalFilters',
        setter: this.namespace + 'setGlobalFilters'
      };
    },
    getUnsavedSettings() {
      return this.$store.getters[this.namespace + 'getUnsavedWidgetStates']
        ?.settings;
    },
    getSelectedSovType() {
      return filterFromArray(
        this.getUnsavedSettings?.selectedType || [],
        this.getPageFiltersMetadata?.sovTypes || []
      );
    },
    getSelectedSovPlacement() {
      return findInArray(
        this.getUnsavedSettings?.selectedPlacement,
        this.getPageFiltersMetadata?.sovPlacements || []
      );
    },
    getSelectedKeywordType() {
      return filterFromArray(
        this.getUnsavedSettings?.selectedKeywordType || [],
        this.getPageFiltersMetadata.sovKeywordType || []
      );
    },
    getPageFilters() {
      return this.$store.getters[this.namespace + 'getPageList'];
    },
    getSelectedView() {
      return this.$store.getters[this.namespace + 'getSelectedView'];
    },
    getGlobalViews() {
      return this.$store.getters[this.namespace + 'getGlobalViews'];
    },
    getPageList() {
      return this.$store.getters[this.namespace + 'getPageList'];
    },
    getPageFiltersMetadata() {
      return this.getPageList?.rows?.metadata?.pageFilters || {};
    },
    intradayCrawlingAvailable() {
      return isIntradayCrawlingEnabled(this?.$store?.getters?.getRetailer);
    },
    multiCrawl() {
      return this.crawlType.value === CONSTANTS.crawlTypes.multiCrawl.value;
    }
  },
  created() {
    this.crawlTypes = Object.values(CONSTANTS.crawlTypes);
    this.changingCrawlType = false;
    this.initialize();
  },
  destroyed() {
    eventBus.$off(this.filterEmit);
    this.$store.dispatch(this.namespace + 'resetStore');
  },
  methods: {
    changeCrawlType(crawlType, init = true) {
      this.crawlType = crawlType;
      let crawlTypePageTitle = Object.values(CONSTANTS.crawlTypes).find(
        (v) => v === crawlType
      ).page;
      this.page = crawlTypePageTitle;
      this.pageWiseMinMaxKey = crawlTypePageTitle;
      if (init) {
        this.changingCrawlType = true;
        this.initialize();
      }
    },
    triggerInlineManual() {
      window.inline_manual_player.activateTopic(90606);
    },
    handleKeywordsRequest(request) {
      const { anchoredBrand } =
        this.$store.getters[this.namespace + 'getUnsavedWidgetStates'].settings;
      if (appendAnchorBrandCondition(anchoredBrand)) {
        request.where.dimensionNameValueList.push(
          getAnchorFilterPayload(anchoredBrand.title)
        );
      }
    },
    handleSovRequest(request) {
      const { anchoredBrand } =
        this.$store.getters[this.namespace + 'getUnsavedWidgetStates'].settings;
      if (appendAnchorBrandCondition(anchoredBrand)) {
        request.operations.totalClientBrandsForDigitalShelf = 0;
        request.operations.anchoredBrand = anchoredBrand.title;
        request.where.dimensionNameValueList.push(
          getAnchorFilterPayload(anchoredBrand.title)
        );
      }
    },
    getFormattedBrandsList() {
      const { client, comp } = this.getClientCompData();
      return getSegmentedBrandsData(client, comp);
    },
    getClientCompData() {
      const { rows: compApiData } =
        this.$store.getters[this.namespace + 'getBrandsData'];
      const { anchoredBrand } =
        this.$store.getters[this.namespace + 'getUnsavedWidgetStates'].settings;
      const { rows: clientApi } =
        this.$store.getters[this.namespace + 'getClientBrandsData'];
      return splitClientCompOnAnchor(compApiData, clientApi, anchoredBrand);
    },
    handleSovByShelvesCreated(context) {
      // on component create, navigate to keywords for sovByShelves
      const { label } = this.$store.getters[this.namespace + 'getSelectedView'];
      if (
        label === OVERALL_BUSINESS &&
        !this.sovByShelvesCreated &&
        !objectRootLength(this.getStandardHierarchy.rows)
      ) {
        context.setDefaultStateToKeywords();
      }
      this.sovByShelvesCreated = true;
    },
    handleDriversCreated(context) {
      // on component create, navigate to keywords for driver
      const { label } = this.$store.getters[this.namespace + 'getSelectedView'];
      if (
        label === OVERALL_BUSINESS &&
        !this.driverCreated &&
        !objectRootLength(this.getStandardHierarchy.rows)
      ) {
        context?.setDefaultStateToKeywords();
      }
      this.driverCreated = true;
    },
    handleRefreshColors(payload) {
      const clientKeys = Object.keys(payload);
      const colorMap = clientKeys.reduce((a, client) => {
        if (this.globalColorMaps?.brandToColor[client]) {
          a[client] = this.globalColorMaps?.brandToColor[client];
        }
        return a;
      }, {});
      colorMap[MY_BRANDS] = this.globalColorMaps?.brandToColor[MY_BRANDS];
      colorMap.Others = 'grey-light';
      this.snapshotWidgetColors = colorMap;
    },
    handleClose() {
      this.isOverlayVisible = false;
    },
    handleSetAccessedFrom(payload) {
      if (payload.hierarchy === 'shelf') {
        this.snapshotFilterPretext = `Keywords in shelf - ${payload.text}:`;
        this.snapshotRightFooter = {
          prefix: 'Accessed from shelf:',
          infix: payload.text
        };
      } else if (payload.hierarchy === 'search_term') {
        this.snapshotFilterPretext = 'Keywords:';
        this.snapshotRightFooter = {
          prefix: 'Accessed from keyword:',
          infix: payload.text
        };
      }
    },
    handleSovByShelvesKeywordsDriver(payload) {
      this.handleKeywordsDriver(payload);
      this.$refs?.sovByShelves?.$el?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    },
    handleDriverKeywordsDriver(payload) {
      this.handleKeywordsDriver(payload);
      this.$refs?.drivers?.$el?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    },
    handleKeywordsDriver(payload) {
      this.widget3NavigateToKeywords = true;
      this.widget5NavigateToKeywords = true;
      this.handleAddToFilter(payload);
    },
    updateStandardShelfSelections(values, anchor) {
      // unable to change thr selections when hiearcrhy filters exists previously
      this.enableOpenFilter = false;
      eventBus.$emit(this.externalFilterSelectionListener, { values, anchor });
      this.$nextTick(() => {
        this.enableOpenFilter = true;
      });
    },
    formulateFilterStrings(filterAnchoredValues) {
      const startsWithStringsFiltered = filterStringStart(
        filterAnchoredValues,
        HIERARCHY_RAW_DELIMITER
      );
      const allStringPaths = getAllKeyPaths(startsWithStringsFiltered);
      const pathStrings = allStringPaths.map((item) =>
        item.join(HIERARCHY_RAW_DELIMITER)
      );
      return pathStrings;
    },
    getFilterStringsArray(title, type, level, currentFilters) {
      if (type === 'standardShelf') {
        const standardHierarchy =
          this.$store.getters[this.namespace + 'getStandardHierarchy'].rows;
        let paths = getAllPathTillLevel(standardHierarchy, title, level, [
          'children'
        ]);
        paths = paths.map((v) => v.join(HIERARCHY_RAW_DELIMITER));
        if (paths.length) {
          const currFilter = currentFilters.values[type]?.[0];
          if (currFilter) {
            return [paths.find((v) => v.startsWith(currFilter)) ?? paths[0]];
          }
          return [paths[0]];
        } else {
          return [];
        }
      } else if (type === 'customShelf') {
        return [title];
      }
    },
    handleAddToFilter(payload) {
      const { title, type, level } = payload;
      const currentFilters = this.filterInstance.getSelectedFilters();
      const filterFormatted = this.getFilterStringsArray(
        title,
        type,
        level,
        currentFilters
      );
      const newFilters = replaceOrderAndValue(
        currentFilters,
        type,
        filterFormatted
      );
      newFilters.values[type] = this.formulateFilterStrings(
        newFilters.values[type]
      );
      this.updateStandardShelfSelections(newFilters.values[type], type);
      this.enableOpenFilter = false;
      this.$nextTick(() => {
        this.filterInstance.appendToFilters(newFilters);
        this.enableOpenFilter = true;
      });
    },
    handleKeywordSelection(payload) {
      this.externalWhereClause = [];
      this.externalKeywordSelected = payload;
      this.showOverlay();
    },
    handleSetWhereClause(payload) {
      this.externalWhereClause = payload;
      this.externalKeywordSelected = null;
      this.showOverlay();
    },
    showOverlay() {
      this.$nextTick(() => {
        this.isOverlayVisible = true;
        if (this.intradayCrawlingAvailable) {
          this.$refs.sovSnapshotsIntradayOverlay.getData();
        } else {
          this.$refs.sovSnapshot.getData();
        }
      });
    },
    showOverlayKeywordSelected(payload) {
      this.showOverlay();
    },
    handleFilterMount(instance) {
      this.filterMounted = true;
      this.fetchBrands('brandsAPI', 'fetchBrands');
      this.fetchBrands('subBrandsApi', 'fetchClientSubBrands');
      eventBus.$on(this.filterEmit, (data) => {
        this.setGlobalFiltersInUnsavedState();
        this.setCustomWidgetStates();
        this.refreshWidgets();
      });
    },
    handleFilterBeforeDestroy() {
      this.filterMounted = false;
      eventBus.$off(this.filterEmit);
    },
    findSelectedView(params) {
      const viewList =
        this.$store.getters[this.namespace + 'getGlobalViews']?.rows || [];
      return this.getViewFromViewId(viewList, params);
    },
    getViewFromViewId(viewList, params) {
      return (
        viewList.filter((view) => {
          if (parseInt(params) === view.globalViewId) {
            return view;
          }
        })[0] || viewList[0]
      );
    },
    routeToId(id) {
      this.$router.push({
        name: 'market_insights',
        params: { id: id }
      });
      this.handleViewBasedonParams(id);
    },
    handleViewBasedonParams(params) {
      const viewList =
        this.$store.getters[this.namespace + 'getGlobalViews']?.rows || [];
      let selectedView = '';
      if (params && viewList.length > 0) {
        selectedView =
          viewList.filter((view) => {
            if (parseInt(params) === view.globalViewId) {
              return view;
            }
          })[0] || null;
        if (!selectedView) {
          selectedView = viewList[0];
          this.routeToId(selectedView?.globalViewId);
          return;
        }

        const initialState = {
          filters: selectedView?.pageUserFilters,
          ...selectedView?.widgetUserSelectionMetadata
        };
        this.$store.dispatch(this.namespace + 'setSelectedView', selectedView);
        this.$store.dispatch(
          this.namespace + 'setInitialWidgetState',
          initialState
        );
        if (this.intradayCrawlingAvailable) {
          this.setPageTypeToLocalStorage(params);
        }
        this.fetchPageList();
      } else if (this.viewList && this.viewList.length > 0) {
        selectedView = this.viewList[0];
        this.$router.push({
          name: 'businessInsights',
          params: { id: selectedView.globalViewId }
        });
        const initialState = {
          filters: selectedView?.pageUserFilters,
          ...selectedView?.widgetUserSelectionMetadata
        };
        this.$store.dispatch(this.namespace + 'setSelectedView', selectedView);
        this.$store.dispatch(
          this.namespace + 'setInitialWidgetState',
          initialState
        );
        this.fetchPageList();
      }
    },
    createBrandsRequet(brandsAPI) {
      // const unsavedSettings = getUnsavedSettings(this);
      const metric = this.getUnsavedWidgetStates.settings.selectedPlacement;
      const { service, request, endPoint } = cloneDeep(brandsAPI);
      const requestCopy = cloneDeep(request);
      requestCopy.metricsList = [metric];
      requestCopy.widget = 'benchmarkBrands';
      requestCopy.page = this.page;
      requestCopy.operations.orderByList = [
        ...(requestCopy?.operations?.orderByList || []),
        {
          dimension: `${metric}_share_percentage`,
          direction: 'DESC'
        }
      ];
      requestCopy.where = this.globalWhereClause();
      return { service, request: requestCopy, endPoint };
    },
    globalWhereClause() {
      const globalWhereClause = cloneDeep(
        this.$store.getters[this.namespace + 'getGlobalFilters']
      );
      const where = {
        date: {},
        pvpDate: {},
        dimensionNameValueList: [],
        excludeDimensionsFromSharePercentage: []
      };
      where.date.from = globalWhereClause?.date_range?.from;
      where.date.to = globalWhereClause?.date_range?.to;
      where.date.name = globalWhereClause?.date_range?.name;
      where.date.page_wise_min_max_key = this.pageWiseMinMaxKey;
      where.pvpDate.from = globalWhereClause?.date_range?.compare_from;
      where.pvpDate.to = globalWhereClause?.date_range?.compare_to;
      where.pvpDate.compare_name = globalWhereClause?.date_range?.compare_name;
      if (this.multiCrawl) {
        where.time = {};
        where.pvpTime = {};
        where.time.from = globalWhereClause?.time_range?.startTime;
        where.time.to = globalWhereClause?.time_range?.endTime;
        where.pvpTime.from = globalWhereClause?.time_range?.compareToStartTime;
        where.pvpTime.to = globalWhereClause?.time_range?.compareToEndTime;
      }
      where.dimensionNameValueList =
        globalWhereClause?.dimensionNameValueList || [];
      return where;
    },
    getFormmatedSettings(settingsObject = {}, settingValues = {}) {
      const {
        selectedKeywordType: settingsKeywordType,
        selectedPlacement: settingsSelectedPlacement,
        selectedType: settingsSelectedType
      } = settingsObject;
      const { sovKeywordType, sovPlacements, sovTypes } = settingValues;
      const keywordType = filterFromArray(settingsKeywordType, sovKeywordType);
      const selectedPlacement = findInArray(
        settingsSelectedPlacement,
        sovPlacements
      );
      const selectedType = filterFromArray(settingsSelectedType, sovTypes);
      return { keywordType, selectedPlacement, selectedType };
    },
    handleWidget3Metadata(metadata) {
      const { filtersDetails, sortDetails } = metadata.customMetadata;
      this.setInitialRuleBuidlerState(
        this.widget3SortConfigV2,
        sortDetails,
        false
      );
      this.setInitialRuleBuidlerState(
        this.widget3FilterConfigV2,
        filtersDetails,
        true
      );
      this.handleWidget2ClientCompData();
    },
    setInitialRuleBuidlerState(config, metrics, hasExpressionInput) {
      const baseExpressionConfig = cloneDeep(this.expressionBaseConfig);
      const baseRadioSelectConfig = cloneDeep(this.radioBaseConfig);

      const baseExpressionConfigNumberic = cloneDeep(this.expressionBaseConfig);
      delete baseExpressionConfigNumberic.props.metrics.config.addtionalMetadata
        .unit;

      baseExpressionConfig.nextStep = null;
      baseExpressionConfig.props.hasQueryBuilder = hasExpressionInput;
      baseExpressionConfigNumberic.props.hasQueryBuilder = hasExpressionInput;
      baseRadioSelectConfig.nextStep = baseExpressionConfig;
      config.root = cloneDeep(this.metricsBaseConfig);
      metrics.forEach((item) => {
        if (item.dimensionName === 'SOV') {
          item.nextStep = baseRadioSelectConfig;
        } else {
          item.nextStep = baseExpressionConfigNumberic;
        }
      });
      config.root.props = {
        options: metrics
      };
      config.load = false;
    },
    handleSaveFilterApplied() {
      this.filterKey++;
    },
    resetFilters() {
      this.filterData = [];
      this.primaryFilterData = [];
      this.secondaryFilterData = {};
    },
    onCreate(instance) {
      this.filterInstance = instance;
      this.filterInstanceCreated = true;
    },
    getDefaultDateRangeObject() {
      return {
        name: 'last30Days',
        compare: undefined,
        compare_name: undefined
      };
    },
    setGlobalFiltersInUnsavedState() {
      const filters = this.getSelectedFiltersValues();
      const payload = { filters };
      this.handlePushUnsavedState(payload);
    },
    setWidgetsMetadataToInitialState() {
      this.$store.dispatch(this.namespace + 'resetWidgetsToInitialState');
    },
    reinitView() {
      this.filterInstanceCreated = false;
      this.showFilter = false;
      setTimeout(() => {
        this.showFilter = true;
      });
    },
    handleResetView() {
      const initialState = cloneDeep(
        this.$store.getters[this.namespace + 'getInitialWidgetStates']
      );
      this.setWidgetsMetadataToInitialState();
      this.validateView();
      const payload = this.createLocalStoragePayload(initialState);
      this.setFiltersLocalStorage(this.filterLocalStorageKey, payload);
      this.reinitView();
    },
    createLocalStoragePayload(state) {
      if (state.filters?.selectedFilter) {
        const payload = transformer.transformFilterFromAPI(
          state.filters?.selectedFilter?.filter
        );
        const dateRange = state?.filters?.date_range || {};
        const timeRange = state?.filters?.time_range || {};
        return this.createFilterState(payload, dateRange, timeRange);
      }
    },
    createFilterState(payload, dateRange, timeRange) {
      return {
        order: payload.order,
        values: {
          ...payload.values,
          date_range: dateRange,
          ...(this.multiCrawl ? { time_range: timeRange } : {})
        }
      };
    },
    createDraftViewPayload() {
      const { globalViewId, label } =
        this.$store.getters[this.namespace + 'getSelectedView'];
      const pageId = this.pageId;
      const page = this.page;
      return {
        name: label,
        page,
        pageId,
        globalViewId
      };
    },
    sendCurrentUnsavedToDraft() {
      const payload = this.createDraftViewPayload();
      return this.$store.dispatch(this.namespace + 'draftView', payload);
    },
    handlePushUnsavedState(payload) {
      this.$store.dispatch(this.namespace + 'setUnsavedWidgetState', payload);
      this.validateView();
    },
    handlePushToInitialState(payload) {
      this.$store.dispatch(this.namespace + 'setInitialWidgetState', payload);
      this.validateView();
    },
    conmpareHashes(getter1, getter2) {
      const initialState = this.$store.getters[this.namespace + getter1] || {};
      const unsavedState = this.$store.getters[this.namespace + getter2] || {};
      return compareObjects(initialState, unsavedState);
    },
    validateView(type) {
      const isEqualWidgetStates = this.conmpareHashes(
        'getInitialWidgetStates',
        'getUnsavedWidgetStates'
      );
      if (isEqualWidgetStates) {
        this.hideViewBtn = true;
      } else {
        this.hideViewBtn = false;
        const { currentUser } =
          this.$store.getters[this.namespace + 'getSelectedView'];
        if (!currentUser) {
          this.hideUpdateView = true;
        } else {
          this.hideUpdateView = false;
        }
      }
    },
    getSelectedFiltersValues() {
      const getFilterValues = this.filterInstance?.getSelectedFilters() || {};
      if (getFilterValues.values) {
        return {
          date_range: {
            ...getFilterValues?.values.date_range
          },
          ...(this.multiCrawl
            ? {
                time_range: {
                  ...getFilterValues?.values.time_range
                }
              }
            : {}),
          selectedFilter: {
            filter: [...this.filterInstance?.getFiltersFullMetadata()],
            order: [...getFilterValues.order]
          }
        };
      }
      return getFilterValues;
    },
    handleRetainGlobalColors(data) {
      this.retainGlobalColors = data;
    },
    handleWidget1DigitalShelfData(data) {
      this.widget1Data = data;
      this.handleRetainGlobalColors(false);
      this.generateGlobalColorMap();
    },
    handleWidget3DigitalShelfData(data) {
      this.widget3Data = data;
      this.generateGlobalColorMap();
    },
    handleWidget2BrandData(data) {
      this.handleRetainGlobalColors(false);
      const { clientApiData, compApiData } = data;
      const clientData = this.transformBrandsData(clientApiData);
      const compData = this.transformBrandsData(compApiData);
      this.widget2Data = [...clientData, ...compData];
      this.generateGlobalColorMap();
    },
    transformBrandsData(data) {
      return data.map((item) => ({
        brandKey: 'brand_by_client_flag',
        brand: item.name,
        percentage: item.result,
        clientType: item.clientType
      }));
    },
    setBrandsData(target, selectedValue, segmentedData) {
      target.props.selectedValue = cloneDeep(selectedValue);
      target.props.segmentedData = cloneDeep(segmentedData);
    },
    handleWidget2ClientCompData() {
      const {
        selectedValue: radioSelectedValue,
        segmentedData: radioFormattedData
      } = this.getFormattedBrandsList();
      const newV2SortConfig = { ...this.widget3SortConfigV2 };
      const sortRadioSelectStep =
        newV2SortConfig.root.props.options[1].nextStep;
      const newV2FilterConfig = { ...this.widget3FilterConfigV2 };
      const filterRadioSelectStep =
        newV2FilterConfig.root.props.options[1].nextStep;
      this.setBrandsData(
        sortRadioSelectStep,
        radioSelectedValue,
        radioFormattedData
      );
      this.setBrandsData(
        filterRadioSelectStep,
        radioSelectedValue,
        radioFormattedData
      );

      this.widget3SortConfigV2 = {
        ...newV2SortConfig,
        load: false,
        noData: false,
        error: false
      };
      this.widget3FilterConfigV2 = {
        ...newV2FilterConfig,
        load: false,
        noData: false,
        error: false
      };
    },
    generateGlobalColorMap() {
      const colorMaps =
        this.retainGlobalColors && this.globalColorMaps
          ? this.globalColorMaps
          : {};
      this.retainGlobalColors = false;
      const baseColorMap = this.getBaseColorMap();
      this.mergeColorMapWithBaseState(colorMaps, baseColorMap, 'brandToIndex');
      this.mergeColorMapWithBaseState(colorMaps, baseColorMap, 'indexToBrand');
      this.mergeColorMapWithBaseState(colorMaps, baseColorMap, 'brandToColor');
      const widget1DigitalShelfDataCopy = cloneDeep(this.widget1Data) || [];
      const widget2DigitalShelfDataCopy = cloneDeep(this.widget2Data) || [];
      const widget3DigitalShelfDataCopy = cloneDeep(this.widget3Data) || [];

      const widget1FlatDigitalShelf = flattenArrayOfArrays(
        widget1DigitalShelfDataCopy
      );
      const widget3FlatDigitalShelf = flattenArrayOfArrays(
        widget3DigitalShelfDataCopy
      );

      const widget1ToBeSortedData = this.getDataToBeSorted(
        widget1FlatDigitalShelf
      );
      const widget2ToBeSortedData = this.getDataToBeSorted(
        widget2DigitalShelfDataCopy
      );
      const widget3ToBeSortedData = this.getDataToBeSorted(
        widget3FlatDigitalShelf
      );

      const widget1baseSortData = Object.values(widget1ToBeSortedData);
      const widget2baseSortData = Object.values(widget2ToBeSortedData);
      const widget3baseSortData = Object.values(widget3ToBeSortedData);
      const sortedWidget3Data = widget3baseSortData.sort(function (a, b) {
        return b.percentage - a.percentage || b.frequency - a.frequency;
      });
      const allBrandsData = [
        ...sortedWidget3Data,
        ...widget1baseSortData,
        ...widget2baseSortData
      ];
      const { anchoredBrand } =
        this.$store.getters[this.namespace + 'getUnsavedWidgetStates'].settings;

      const palletteColors = this.getColorMapFromPallette(
        allBrandsData,
        colorMaps,
        anchoredBrand?.title || MY_BRANDS
      );
      const hashedColorSet = this.generateHashedColors(
        allBrandsData,
        colorMaps,
        anchoredBrand?.title || MY_BRANDS
      );
      palletteColors.brandToColor = {
        ...hashedColorSet,
        ...palletteColors.brandToColor
      };
      this.globalColorMaps = palletteColors;
      this.snapshotWidgetColors = null;
    },
    mergeColorMapWithBaseState(colorMaps, baseColorMaps, key) {
      colorMaps[key] = { ...(colorMaps?.[key] || {}), ...baseColorMaps[key] };
    },
    getBaseColorMap() {
      return {
        brandToIndex: {
          'My Brands': 0,
          Others: 1
        },
        indexToBrand: {
          0: 'My Brands',
          1: 'Others'
        },
        brandToColor: {
          'My Brands': '#007cf6',
          Others: '#e9eaeb'
        }
      };
    },
    generateHashedColors(dataArray, colorSet, anchoredBrand = MY_BRANDS) {
      // generate a hex based on the brand name
      const generatedColors = {};
      dataArray.forEach((item) => {
        if (anchoredBrand === MY_BRANDS && item.clientType === 'client') {
          colorSet.brandToColor[item.brand] = this.getSubBrandsColor();
        } else if (item.brand === MY_BRANDS) {
          colorSet.brandToColor[item.brand] = this.getSubBrandsColor();
        }
        if (!colorSet.brandToColor[item.brand]) {
          generatedColors[item.brand] = '#' + this.getColorHex(item.brand);
        }
      });
      return generatedColors;
    },
    hashCode(str) {
      let hash = 0;
      if (str) {
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
          hash = hash & hash;
        }
      }
      return hash;
    },
    intToHex(i) {
      const color = (i & 0x00ffffff).toString(16).toUpperCase();
      return '00000'.substring(0, 6 - color.length) + color;
    },
    getColorHex(text) {
      const generatedHashCode = this.hashCode(text);
      return this.intToHex(generatedHashCode);
    },
    getSubBrandsColor() {
      const baseColorMap = this.getBaseColorMap();
      return baseColorMap.brandToColor[MY_BRANDS];
    },
    getColorMapFromPallette(
      dataArray,
      colorMap = {},
      anchoredBrand = MY_BRANDS
    ) {
      let i = 0;
      let j = 0;
      while (i < dataArray.length) {
        const arrayItem = dataArray[i];
        if (anchoredBrand === MY_BRANDS && arrayItem.clientType === 'client') {
          // if client brand then return client blue
          colorMap.brandToColor[arrayItem.brand] = this.getSubBrandsColor();
          i++;
        } else if (anchoredBrand === arrayItem.brand) {
          colorMap.brandToColor[arrayItem.brand] = this.getSubBrandsColor();
          i++;
        }
        // if color already generated dont generate again, else assign next available color from the pallete
        if (!colorMap.brandToColor.hasOwnProperty(arrayItem.brand)) {
          while (j < this.colorPallete.length) {
            if (!colorMap.indexToBrand[j]) {
              colorMap.brandToIndex[arrayItem.brand] = j;
              colorMap.brandToColor[arrayItem.brand] = this.colorPallete[j].hex;
              colorMap.indexToBrand[j] = arrayItem.brand;
              break;
            }
            j++;
          }
        }
        i++;
      }
      return colorMap;
    },
    getDataToBeSorted(dataArray) {
      const frequencyMap = {};
      dataArray.forEach((item) => {
        if (!frequencyMap[item.brand]) {
          frequencyMap[item.brand] = {};
          frequencyMap[item.brand].brand = item.brand;
          frequencyMap[item.brand].frequency = 1;
          frequencyMap[item.brand].percentage = item.percentage;
          frequencyMap[item.brand].clientType = item.clientType;
        } else {
          frequencyMap[item.brand].frequency++;
          if (frequencyMap[item.brand].percentage < item.percentage) {
            frequencyMap[item.brand].percentage = item.percentage;
          }
        }
      });
      return frequencyMap;
    },
    handleSortRadioSelection(data) {
      this.widget3SortRadioSelectSelectedValue = [data];
    },
    handleSortRuleApply(data) {
      const newRuleBuilderConfig = this.widget3SortConfig.stepConfig.map(
        (item, index) => {
          if (!item.props) {
            item.props = {};
          }
          item.props.selectedValue = data[index];
          return item;
        }
      );
      this.widget3SortConfig.stepConfig = newRuleBuilderConfig;
    },
    handleRuleApply(data) {
      const newRuleBuilderConfig = this.widget3FilterConfig.stepConfig.map(
        (item, index) => {
          if (!item.props) {
            item.props = {};
          }
          item.props.selectedValue = data[index];
          return item;
        }
      );
      this.widget3FilterConfig.stepConfig = newRuleBuilderConfig;
    },
    async getGlobalFilters(cube, urlFactoryId, page, where) {
      this.resetFilters();
      await this.fetchFilters(
        cube,
        urlFactoryId,
        page,
        where,
        undefined,
        undefined,
        false
      );
      if (!this.multiCrawl) {
        this.primaryFilterData.push({
          dimensionColumn: 'standardShelf',
          dimensionLabel: 'Standard Shelf',
          dimensionName: 'standardShelf',
          dimensionType: 'DIGITAL_SHELF',
          smart: false
        });
      }
      this.primaryFilterData.push({
        dimensionColumn: 'customShelf',
        dimensionLabel: 'Custom Shelf',
        dimensionName: 'customShelf',
        dimensionType: 'DIGITAL_SHELF',
        smart: false
      });
    },
    handlePreviousAnchorBrand(payload) {
      this.previousAnchorBrand = payload;
    },
    handleCrawlType(payload) {
      if (payload && payload.value !== this.crawlType.value) {
        this.changeCrawlType(payload);
      }
    },
    handleApply() {
      this.$nextTick(() => {
        this.$refs.sovByShelves?.changeFilterRuleBuilderSelectedBrand();
        this.$refs.sovByShelves?.changeSortRuleBuilderSelectedBrand();
        this.$refs.drivers?.setUpdatedSovMetrics();
        this.$refs.drivers?.changeAxisSelectedBrand('x');
        this.$refs.drivers?.changeAxisSelectedBrand('y');
        this.$refs.drivers?.changeAxisSelectedBrand('z');
        this.$refs.drivers?.changeShareOfVoiceFilterOnAnchor();
        this.refreshWidgets();
      });
    },
    setCustomWidgetStates() {
      this.$refs?.benchmarkBrands?.selectRollup();
      this.$refs?.performanceOverview?.selectRollup();
      if (this.widget3NavigateToKeywords) {
        this.widget3NavigateToKeywords = false;
        this.$refs?.sovByShelves?.setShelfToKeywords();
      } else {
        this.$refs?.sovByShelves?.setShelfLevel();
      }
      if (this.widget5NavigateToKeywords) {
        this.$refs?.drivers?.setShelfToKeywords();
        this.widget5NavigateToKeywords = false;
      } else {
        this.$refs?.drivers?.setShelfLevel();
      }
      // set custom state for title dropdown based on filters applied, enable/dsiable contents
      disableInHierarchy(this.$refs?.drivers);
      disableInHierarchy(this.$refs?.sovByShelves);
    },
    async refreshWidgets() {
      this.refreshCount++;
      if (this.refreshCount > this.refreshLimit) {
        const payload = this.createDraftViewPayload();
        this.$store.dispatch(this.namespace + 'draftView', payload);
        setTimeout(() => {
          location.reload();
        }, 10);
      }
      this.fetchBrands('brandsAPI', 'fetchBrands');
      this.fetchBrands('subBrandsApi', 'fetchClientSubBrands');
      this.handleRetainGlobalColors();
      this.$refs?.allDigitalShelves?.getData();
      this.$refs?.benchmarkBrands?.getData();
      this.$refs?.performanceOverview?.resetPagination();
      this.$refs?.performanceOverview?.getData();
      if (this.multiCrawl) {
        this.$refs?.sovTimeOfDayPerformance?.getData();
      }
      this.$refs?.sovByShelves?.resetPagination();
      this.$refs?.drivers?.resetPagination();
      this.$nextTick(() => {
        if (this.intradayCrawlingAvailable) {
          this.$refs?.sovSnapshotsIntradayOverlay?.initialiseDate();
          this.handleClose();
        } else if (this.isOverlayVisible) {
          this.$refs?.sovSnapshot?.getData();
        }
      });
    },
    handleExportWidgets() {
      const month = moment().format('MM');
      const day = moment().format('DD');
      const config = {
        imagePath: '/images/export-loading.png',
        loaderText: 'PDF is being generated and will be downloaded shortly.',
        template: {
          header: true,
          elementSelectors: [{ selector: '.sticky-filter-panel' }]
        },
        elementSelector: '.slide-card',
        fileName: `Share Of Voice_${month}/${day}.pdf`
      };
      eventBus.$emit('triggerExport', config);
    },
    async handleUpdateView(payload) {
      try {
        const { mode, data, type } = payload;
        const requestPayload = this.createDraftViewPayload();
        if (type === 'name') {
          requestPayload.name = data.label;
          requestPayload.globalViewId = data.globalViewId;
        }
        if (mode === 'save') {
          const index = this.$store.getters[
            this.namespace + 'getGlobalViews'
          ].rows.findIndex((item) => item.label === data);
          if (index < 0) {
            delete requestPayload.globalViewId;
            requestPayload.name = data;
            this.isSaveViewLoading = true;
            const { data: responseData } = await this.$store.dispatch(
              this.namespace + 'saveViewAction',
              requestPayload
            );
            const { globalView } = responseData;
            this.$store.dispatch(
              this.namespace + 'appendToViewList',
              globalView
            );
            this.isSaveViewLoading = false;
            this.routeToId(globalView?.globalViewId);
            this.sendCurrentUnsavedToDraft();
            this.validateView();
          } else {
            this.openSnackbar('The selected view name already exists');
          }
        }
        if (mode === 'update') {
          this.isUpdateViewLoading = true;
          await this.$store.dispatch(
            this.namespace + 'saveViewAction',
            requestPayload
          );
          this.$store.dispatch(this.namespace + 'updateViewTitle', data);
          await this.sendCurrentUnsavedToDraft();
          this.isUpdateViewLoading = false;
          this.$store.dispatch(this.namespace + 'saveUnsavedState');
          this.validateView();
        }
        if (mode === 'delete') {
          await this.$store.dispatch(this.namespace + 'deleteView', data);
          const selectedView =
            this.$store.getters[this.namespace + 'getSelectedView'];
          this.$store.dispatch(this.namespace + 'removeFromViewList', data);
          if (selectedView.globalViewId === data.globalViewId) {
            const globalView =
              this.$store.getters[this.namespace + 'getGlobalViews'].rows[0];
            this.handleVrSelectInput(globalView);
          }
        }
      } catch (e) {
        this.isSaveViewLoading = false;
        this.isUpdateViewLoading = false;
        this.openSnackbar();
      }
    },
    handleToggleEdit(view) {
      this.$store.dispatch(this.namespace + 'toggleEdit', view);
      const viewList = this.$store.getters['sov/getGlobalViews'].rows;
      viewList.forEach((item) => {
        if (item.globalViewId === view.globalViewId) {
          if (!view.edit) {
            setTimeout(() => {
              document
                .querySelector(`div[title="${item.label}"]`)
                .closest('.select__dropdown-list-item')
                ?.classList?.add('overrideHover');
              const input = document.querySelector(
                `#input${view.globalViewId}`
              );
              input?.focus();
              input?.select();
            });
          } else {
            document
              .querySelector(`div[title="${item.label}"]`)
              .closest('.select__dropdown-list-item')
              ?.classList?.remove('overrideHover');
          }
        } else {
          document
            .querySelector(`div[title="${item.label}"]`)
            .closest('.select__dropdown-list-item')
            ?.classList?.remove('overrideHover');
        }
        return item;
      });
    },
    handleVrSelectInput(selectedOption) {
      this.driverCreated = false;
      this.sovByShelvesCreated = false;
      // manually navigating to keywords shelf for clients with no standard shelf, driverCreated and sovByShelvesCreated part of the condition
      if (selectedOption && selectedOption.label) {
        this.routeToId(selectedOption?.globalViewId);
        this.$store.dispatch(
          this.namespace + 'viewChangeContentRestore',
          selectedOption
        );
      }
    },
    // Doing this to persist MultiCrawl as a setting
    checkPageTypeFromLocalStorage() {
      const id = this.$route?.params?.id;
      if (id) {
        const pageTypes = this.getLocalStorage(this.pageTypeLocalStorageKey);
        const pageType = pageTypes?.[id];
        if (pageType && pageType === CONSTANTS.crawlTypes.multiCrawl.page) {
          this.changeCrawlType(CONSTANTS.crawlTypes.multiCrawl, false);
        }
      }
    },
    setPageTypeToLocalStorage(id) {
      const pageTypes =
        this.getLocalStorage(this.pageTypeLocalStorageKey) ?? {};
      if (this.multiCrawl) {
        this.setLocalStorage(this.pageTypeLocalStorageKey, {
          ...pageTypes,
          [id]: CONSTANTS.crawlTypes.multiCrawl.page
        });
      }
    },
    fetchShelves() {
      this.standardHierarchyPromise = this.$store
        .dispatch(this.namespace + 'fetchStandardShelves')
        .catch((err) => {
          console.error('err', err);
        });
      this.customHierarchyPromise = this.$store
        .dispatch(this.namespace + 'fetchCustomShelves')
        .catch((err) => {
          console.error('err', err);
        });
    },
    async initialize() {
      this.fetchShelves();
      try {
        this.showFilter = false;
        if (this.intradayCrawlingAvailable && !this.changingCrawlType) {
          this.checkPageTypeFromLocalStorage();
        }
        const { viewList, response } = await this.$store.dispatch(
          this.namespace + 'fetchGlobalView',
          {
            page: this.page,
            persistSettings: this.changingCrawlType
          }
        );
        let globalViewId = '';
        if (this.$route?.params?.id) {
          globalViewId = this.$route?.params?.id;
          this.handleViewBasedonParams(globalViewId);
        } else {
          globalViewId = response.data?.userUnsavedGlobalView?.globalViewId;
          if (globalViewId) {
            this.routeToId(globalViewId);
          } else {
            this.routeToId(viewList[0]?.globalViewId);
          }
        }
        this.changingCrawlType = false;
        this.handleClose();
      } catch (e) {
        console.error('e', e);
        this.openSnackbar();
      }
    },
    fetchBrands(brandsAPIKey, storeDispatchKey) {
      try {
        const brandsAPI =
          this.$store.getters[this.namespace + 'getPageList'].rows.API[
            brandsAPIKey
          ];
        const payload = this.createBrandsRequet(brandsAPI);
        this.$store.dispatch(this.namespace + storeDispatchKey, payload);
      } catch (e) {
        console.error('e', e);
      }
    },
    setDataServices(response) {
      const metadataConfigs = {};
      response.data.widgets.forEach((widget) => {
        metadataConfigs[widget.widgetName] = widget;
      });
      const widget1MetadataConfig = metadataConfigs[this.widget1Name];
      const widget2MetadataConfig = metadataConfigs[this.widget2Name];
      const widget3MetadataConfig = metadataConfigs[this.widget3Name];
      const widget4MetadataConfig = metadataConfigs[this.widget4Name];
      const widget5MetadataConfig = metadataConfigs[this.widget5Name];
      const widget6MetadataConfig = metadataConfigs[this.widget6Name];
      this.widget1DataService = new DonutDataService(
        this.page,
        widget1MetadataConfig.widgetName,
        this.pageId,
        this.getSelectedView.globalViewId
      );
      this.widget2DataService = new DataService(
        this.page,
        widget2MetadataConfig.widgetName,
        this.pageId,
        this.getSelectedView.globalViewId
      );
      this.widget3DataService = new DonutDataService(
        this.page,
        widget3MetadataConfig.widgetName,
        this.pageId,
        this.getSelectedView.globalViewId
      );
      this.widget4DataService = new DataService(
        this.page,
        widget4MetadataConfig.widgetName,
        this.pageId,
        this.getSelectedView.globalViewId
      );
      this.widget5DataService = new DataService(
        this.page,
        widget5MetadataConfig.widgetName,
        this.pageId,
        this.getSelectedView.globalViewId
      );
      this.sovImagesDataService1 = new SovImageDataService(
        this.page,
        widget6MetadataConfig.widgetName,
        this.pageId,
        this.getSelectedView.globalViewId
      );
      this.sovImagesDataService2 = new SovImageDataService(
        this.page,
        widget6MetadataConfig.widgetName,
        this.pageId,
        this.getSelectedView.globalViewId
      );
      this.snapShotSharesService1 = new SnapShotSharesService(
        this.page,
        widget6MetadataConfig.widgetName,
        this.pageId,
        this.getSelectedView.globalViewId
      );
      this.snapShotSharesService2 = new SnapShotSharesService(
        this.page,
        widget6MetadataConfig.widgetName,
        this.pageId,
        this.getSelectedView.globalViewId
      );
      this.snapshotDropdown1 = new SingleSelectDropdown(
        this.page,
        widget6MetadataConfig.widgetName,
        this.pageId,
        this.getSelectedView.globalViewId
      );
      this.snapshotDropdown2 = new SingleSelectDropdown(
        this.page,
        widget6MetadataConfig.widgetName,
        this.pageId,
        this.getSelectedView.globalViewId
      );
      this.sovCrawlTimesService1 = new SovCrawlTimes(
        this.page,
        widget6MetadataConfig.widgetName,
        this.pageId,
        this.getSelectedView.globalViewId
      );
      this.sovCrawlTimesService2 = new SovCrawlTimes(
        this.page,
        widget6MetadataConfig.widgetName,
        this.pageId,
        this.getSelectedView.globalViewId
      );
      if (this.multiCrawl) {
        const widget7MetadataConfig = metadataConfigs[this.widget7Name];
        this.widget7DataService = new DataService(
          this.page,
          widget7MetadataConfig.widgetName,
          this.pageId,
          this.getSelectedView.globalViewId
        );
      }
    },
    async fetchPageList() {
      try {
        await Promise.all([
          this.customHierarchyPromise,
          this.standardHierarchyPromise
        ]);
        this.showFilter = false;
        this.removeLocalStorage(this.filterLocalStorageKey);
        this.removeLocalStorage(`${this.filterLocalStorageKey}-order`);
        const response = await this.$store.dispatch(
          this.namespace + 'fetchPageList',
          { page: this.page }
        );
        this.pageFetched = true;
        this.showFilter = true;
        this.pageId = response.data.id;
        if (response.status === 200) {
          const { cubeName, pageName, where } =
            response.data.API.filterAPI.request;
          this.getGlobalFilters(cubeName, 'FETCH_FILTERS_V2', pageName, where);
          this.setDataServices(response);
          if (
            response.data.calendar.min_date &&
            response.data.calendar.max_date
          ) {
            this.minMaxDate.minDate = moment(
              response.data.calendar.min_date,
              'YYYY-MM-DD'
            ).format('MM/DD/YYYY');
            this.minMaxDate.maxDate = moment(
              response.data.calendar.max_date,
              'YYYY-MM-DD'
            ).format('MM/DD/YYYY');
          } else {
            const today = moment('YYYY-MM-DD');
            const yearBack = moment('YYYY-MM-DD').subtract('1', 'years');
            this.minMaxDate.maxDate = today.format('MM/DD/YYYY');
            this.minMaxDate.minDate = yearBack.format('MM/DD/YYYY');
          }
          const initialState = cloneDeep(
            this.$store.getters[this.namespace + 'getInitialWidgetStates']
          );
          const unsavedState = cloneDeep(
            this.$store.getters[this.namespace + 'getUnsavedWidgetStates']
          );
          const initialFilters = cloneDeep(initialState?.filters);
          const unsavedFilters = cloneDeep(unsavedState?.filters);
          const targetFilterState = unsavedFilters || initialFilters;
          const targetFilterValue =
            targetFilterState.selectedFilter?.filter || {};

          this.defaultDateRange = targetFilterValue?.date_range?.name;
          const storageValues = transformer.transformFilterFromAPI(
            targetFilterState?.selectedFilter?.filter || []
          );
          const filterPayload = this.createFilterState(
            storageValues,
            targetFilterState?.date_range || {},
            targetFilterState?.time_range || {}
          );
          this.handlePushUnsavedState({ filters: targetFilterState });
          this.validateView();
          this.setFiltersLocalStorage(
            this.filterLocalStorageKey,
            filterPayload
          );
        }
      } catch (err) {
        console.error('err', err);
        this.openSnackbar();
      }
    },
    isJsonParsable(string) {
      try {
        return JSON.parse(string);
      } catch (e) {
        return string;
      }
    },
    setFiltersLocalStorage(filterLocalStorageKey, storageValues) {
      if (storageValues?.values) {
        if (Object.keys(storageValues?.values).length > 0) {
          Object.keys(storageValues?.values).forEach((key) => {
            if (storageValues.order.indexOf(key) !== -1) {
              storageValues.values[key] = storageValues.values[key].map(
                (item) => {
                  return this.isJsonParsable(item);
                }
              );
            }
          });
        }
        this.setLocalStorage(filterLocalStorageKey, storageValues.values);
        this.setLocalStorage(
          `${filterLocalStorageKey}-order`,
          storageValues.order
        );
      }
    },
    setLocalStorage(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    getLocalStorage(key) {
      return JSON.parse(localStorage.getItem(key));
    },
    removeLocalStorage(key) {
      localStorage.removeItem(key);
    },
    openSnackbar(message = 'Something Went Wrong !!!') {
      this.$snackbar.open({
        message: message,
        duration: 5000,
        buttonColor: '#f5d908',
        actionText: ' '
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    try {
      const payload = this.createDraftViewPayload();
      this.$store.dispatch(this.namespace + 'draftView', payload);
    } catch (e) {
      console.error('e', e);
    } finally {
      next();
    }
  }
};
</script>
<style scoped lang="css" scoped>
.intermediate-height-w3 {
  height: 566px;
}
.intermediate-height-w5 {
  height: 332px;
}

.nav-transparent {
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(43, 51, 59, 0.15);
}
.view-port {
  height: calc(100vh - 72px);
}
.tippy-drop-down {
  position: relative;
  line-height: 1;
  box-shadow: 0 0 0 0 #caccce;
  max-height: 400px;
  overflow-y: scroll;
}
.widget-2-width {
  width: calc(60% - 16px);
}
.u-height-36px {
  height: 36px;
}
.corners-rounded {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.overlay-snapshot {
  position: sticky;
  bottom: -23px;
  z-index: 99;
}
.box-shadows {
  box-shadow: 0 1px 16px 0 rgb(43 51 59 / 25%);
}
.sticky-panel {
  position: sticky;
  top: 0;
  z-index: 99;
}
</style>
