<template>
  <div
    class="radio-select-with-input u-display-flex u-height-100 u-position-relative u-flex-align-items-center"
  >
    <div class="u-flex-direction-column">
      <div
        v-if="title"
        class="u-font-size-5 u-font-weight-600 u-spacing-pv-m"
      >
        {{ title }}
      </div>
      <div
        v-if="showRadioOptions"
        :class="{
          'u-border-right u-border-color-grey-xxx-light u-border-width-s u-spacing-pr-xxl':
            radioConfigObj.type && showInputValue
        }"
      >
        <div
          v-for="(item, index) in radioConfig"
          :key="item.value"
          :class="[index != radioConfig.length - 1 ? 'u-spacing-pb-m' : '']"
        >
          <rb-radio
            v-model="radioValue"
            :native-value="item.value"
            :disabled="item.disabled"
            class="u-font-size-5 u-width-100 u-spacing-mr-s"
            @input="onRadioUpdate"
          >
            <div>{{ item.title }}</div>
          </rb-radio>
          <div
            v-if="item.description"
            class="u-max-width-320px u-font-size-5 u-spacing-ph-l u-spacing-pt-s u-color-grey-lighter"
          >
            {{ item.description }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showInputValue && radioConfigObj.type === 'input'"
      class="u-height-100 u-flex-direction-row u-flex-justify-content-center u-spacing-ph-xxl u-display-flex"
      :class="[title && 'u-spacing-pt-xxl']"
    >
      <div v-if="inputTitle.length > 0 || showInputTitle">
        <div class="u-font-size-5 u-font-weight-600 u-spacing-mb-xs">
          {{ inputTitle }}
        </div>
        <div class="">
          <rb-radio
            v-if="bidSuggestion.show"
            v-model="radioBidSuggestion"
            native-value="currentBid"
            class="u-font-size-5 u-width-100 u-spacing-pb-s u-flex-direction-row"
            @input="toggleBidSuggestion"
          >
            <div>Custom bid</div>
          </rb-radio>
          <div class="u-display-flex">
            <span
              data-cy="currency"
              class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
            >
              {{ $currency }}
            </span>
            <input
              ref="bidRef"
              v-model="inputValue"
              :type="bidSuggestion.multipleSuggestions ? 'text' : 'number'"
              :disabled="
                radioBidSuggestion !== 'currentBid' ||
                (bidSuggestion.multipleSuggestions &&
                  !bidSuggestion.secondTitle)
              "
              data-cy="inputValue"
              class="rb-input currency-input u-display-flex u-flex-align-items-center"
              step="0.01"
              @input="onUpdate"
            />
          </div>
          <div
            v-if="
              radioBidSuggestion === 'currentBid' &&
              warningMessage &&
              !errorMessage
            "
            class="u-display-flex error-width u-color-orange-base u-spacing-mt-s u-flex-align-items-center"
          >
            <div>
              <rb-icon
                icon="warning"
                class="rb-icon--small"
              />
            </div>
            <div class="u-spacing-ml-xs u-font-size-7">
              {{ warningMessage }}
            </div>
          </div>
          <div
            v-if="errorMessage && radioBidSuggestion === 'currentBid'"
            class="u-display-flex error-width u-color-red-base u-spacing-mt-s u-flex-align-items-center"
          >
            <div v-if="!showTooltip">
              <rb-icon
                icon="cross-fill-circle"
                class="rb-icon--small"
              />
            </div>
            <div v-else>
              <rb-icon
                class="u-color-red-base rb-icon--small"
                icon="cross-fill-circle"
              />
            </div>
            <div
              class="u-spacing-ml-xs u-font-size-7"
              :class="{ 'u-color-red-base': showTooltip }"
            >
              {{ errorMessage }}
            </div>
          </div>
          <div
            v-if="bidSuggestion.multipleSuggestions"
            class="u-max-width-70 u-font-size-7 u-spacing-ph-xs u-spacing-pt-s u-color-grey-lighter"
          >
            {{ bidSuggestion.currentBidNote }}
          </div>
          <slot name="below-right-side-input" />
        </div>
      </div>
      <div v-else>
        <div class="">
          <rb-radio
            v-if="bidSuggestion.show"
            v-model="radioBidSuggestion"
            native-value="currentBid"
            class="u-font-size-5 u-width-100 u-spacing-pb-s u-flex-direction-row"
            @input="toggleBidSuggestion"
          >
            <div>Current bid</div>
          </rb-radio>
          <div class="u-display-flex u-flex-direction-column">
            <div class="u-display-flex">
              <span
                data-cy="currency"
                class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
              >
                {{ $currency }}
              </span>
              <input
                ref="bidRef"
                v-model="inputValue"
                :type="bidSuggestion.multipleSuggestions ? 'text' : 'number'"
                :disabled="bidSuggestion.multipleSuggestions ? true : false"
                data-cy="inputValue"
                class="rb-input currency-input u-display-flex u-flex-align-items-center"
                step="0.01"
                @input="onUpdate"
              />
            </div>
            <div
              v-if="errorMessage"
              class="u-display-flex u-color-red-base u-spacing-mt-s u-flex-align-items-center"
            >
              <div>
                <rb-icon
                  class="rb-icon--small"
                  icon="cross-fill-circle"
                />
              </div>
              <div class="u-spacing-ml-xs u-font-size-7">
                {{ errorMessage }}
              </div>
            </div>
          </div>
          <div
            v-if="bidSuggestion.multipleSuggestions"
            class="u-max-width-70 u-font-size-7 u-spacing-ph-xs u-spacing-pt-s u-color-grey-lighter"
          >
            {{ bidSuggestion.currentBidNote }}
          </div>
          <slot name="below-right-side-input" />
        </div>
      </div>
      <div
        v-if="bidSuggestion.show"
        :class="{
          'u-spacing-ph-xxl u-width-260px': !bidSuggestion.secondTitle,
          'u-width-260px u-spacing-pl-xxl': bidSuggestion.secondTitle
        }"
      >
        <rb-radio
          v-if="bidSuggestion.show"
          v-model="radioBidSuggestion"
          native-value="suggestedBid"
          class="u-font-size-5 u-width-560 u-spacing-pb-s u-flex-direction-row"
          @input="toggleBidSuggestion"
        >
          <div>{{ bidSuggestion.title }}</div>
        </rb-radio>
        <div class="u-display-flex">
          <span
            class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
          >
            {{ $currency }}
          </span>
          <input
            v-model="bidSuggestion.value"
            type="text"
            class="rb-input currency-input u-display-flex u-flex-align-items-center"
            :disabled="true"
            @input="onUpdate"
          />
        </div>
        <div
          v-if="bidSuggestion.multipleSuggestions || bidSuggestion.secondTitle"
          class="u-max-width-70 u-font-size-7 u-spacing-ph-xs u-spacing-pt-s u-color-grey-lighter"
        >
          {{ bidSuggestion.suggestedBidNote }}
        </div>
        <slot name="below-right-side-input" />
      </div>
      <div
        v-if="bidSuggestion.show && bidSuggestion.secondTitle"
        class="u-width-260px"
      >
        <rb-radio
          v-if="bidSuggestion.show"
          v-model="radioBidSuggestion"
          native-value="suggestedMinBid"
          class="u-font-size-5 u-width-560 u-spacing-pb-s u-flex-direction-row"
          @input="toggleBidSuggestion"
        >
          <div>{{ bidSuggestion.secondTitle }}</div>
        </rb-radio>
        <div class="u-display-flex">
          <span
            class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
          >
            {{ $currency }}
          </span>
          <input
            v-model="bidSuggestion.secondValue"
            type="text"
            class="rb-input currency-input u-display-flex u-flex-align-items-center"
            :disabled="true"
            @input="onUpdate"
          />
        </div>
        <div
          v-if="bidSuggestion.multipleSuggestions || bidSuggestion.secondTitle"
          class="u-max-width-70 u-font-size-7 u-spacing-ph-xs u-spacing-pt-s u-color-grey-lighter"
        >
          {{ bidSuggestion.secondSuggestedBidNote }}
        </div>
        <slot name="below-right-side-input" />
      </div>
    </div>
    <div
      v-else-if="showInputValue && radioConfigObj.type === 'dropdown'"
      class="dropdown-radio-select-with-input u-height-100 u-display-flex u-flex-direction-column u-flex-justify-content-center"
      :class="[
        title && 'u-spacing-pt-xxl',
        !boolBidAdjust && 'u-spacing-ph-xxl'
      ]"
    >
      <div
        v-if="inputTitle.length > 0 || showInputTitle"
        class="u-display-flex u-flex-align-items-flex-end"
      >
        <div class="">
          <div class="u-display-flex u-flex-align-items-flex-end">
            <div>
              <div class="u-font-size-5 u-font-weight-600 u-spacing-mb-xs">
                {{ inputTitle }}
              </div>
              <div class="u-max-width-200px">
                <rb-select
                  class="valueDropdown"
                  :send-details="true"
                  :on-close="optionSelected"
                  :options="radioConfigObj && radioConfigObj.dropdown"
                >
                  <div
                    slot="trigger"
                    class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
                  >
                    <div
                      class="u-display-flex u-text-overflow-ellipsis u-flex-direction-column u-spacing-p-s u-font-size-5"
                      data-cy="selectedBidValue"
                    >
                      {{ selectedDropdown && selectedDropdown.label }}
                    </div>
                    <rb-icon
                      class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                      data-cy="carrotDownIcon"
                      :icon="'caret-down'"
                    />
                  </div>
                  <template
                    slot="item"
                    slot-scope="option"
                  >
                    <div class="u-display-flex u-flex-align-items-center">
                      {{ option.label }}
                    </div>
                  </template>
                </rb-select>
              </div>
            </div>
            <div
              v-if="selectedDropdown && selectedDropdown.value"
              class="u-spacing-pl-s"
            >
              <div
                class="u-display-flex u-flex-0 u-flex-align-items-center u-width-20"
                :class="{ percentBox: selectedDropdown.value === 'percentage' }"
              >
                <span
                  v-if="selectedDropdown.value !== 'percentage'"
                  class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                  data-cy="currency"
                >
                  {{ $currency }}
                </span>
                <input
                  v-model="inputValue"
                  data-cy="inputValue"
                  type="number"
                  class="rb-input currency-input rb-width u-display-flex u-flex-align-items-center"
                  step="0.01"
                  @input="onUpdate"
                />
                <span
                  v-if="selectedDropdown.value === 'percentage'"
                  data-cy="percentageSign"
                  class="appender appender-percentage u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pr-s u-flex-0"
                >
                  %
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="u-display-flex"
      >
        <div class="u-max-width-200px">
          <rb-select
            class="valueDropdown"
            :send-details="true"
            :on-close="optionSelected"
            :options="radioConfigObj && radioConfigObj.dropdown"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
            >
              <div
                class="u-display-flex u-text-overflow-ellipsis u-flex-direction-column u-spacing-p-s u-font-size-5"
                data-cy="selectedBidValue"
              >
                {{ selectedDropdown && selectedDropdown.label }}
              </div>
              <rb-icon
                class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                data-cy="carrotDownIcon"
                :icon="'caret-down'"
              />
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <div class="u-display-flex u-flex-align-items-center">
                {{ option.label }}
              </div>
            </template>
          </rb-select>
        </div>
        <div
          v-if="selectedDropdown && selectedDropdown.value"
          class="u-spacing-pl-s"
        >
          <div
            class="u-display-flex u-flex-0 u-flex-align-items-center u-width-20"
            :class="{ percentBox: selectedDropdown.value === 'percentage' }"
          >
            <span
              v-if="selectedDropdown.value !== 'percentage'"
              class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
              data-cy="currency"
            >
              {{ $currency }}
            </span>
            <input
              v-model="inputValue"
              type="number"
              data-cy="inputValue"
              class="rb-input currency-input rb-width u-display-flex u-flex-align-items-center"
              step="0.01"
              @input="onUpdate"
            />
            <span
              v-if="selectedDropdown.value === 'percentage'"
              data-cy="percentageSign"
              class="appender appender-percentage u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pr-s u-flex-0"
            >
              %
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="errorMessage"
        class="u-display-flex u-color-red-base u-spacing-mt-s u-flex-align-items-center"
      >
        <div v-if="!showTooltip">
          <rb-icon
            icon="cross-fill-circle"
            class="rb-icon--small"
          />
        </div>
        <div v-else>
          <rb-icon
            class="u-color-grey-lighter rb-icon--small"
            icon="alerts"
          />
        </div>
        <div
          class="u-spacing-ml-xs u-font-size-7"
          :class="{ 'u-color-grey-lighter': showTooltip }"
        >
          {{ errorMessage }}
        </div>
      </div>
      <slot name="below-right-side-dropdown" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    showTooltip: {
      type: Boolean,
      default: false
    },
    inputTitle: {
      type: String,
      default: ''
    },
    showInputTitle: {
      type: Boolean,
      default: false
    },
    disableMaxImpressions: {
      type: Boolean,
      default: false
    },
    defaultRadioSelected: {
      type: String,
      default: 'test'
    },
    errorMessage: {
      type: String,
      default: ''
    },
    showInputValue: {
      type: Boolean,
      default: true
    },
    overrideBudgetCheck: {
      type: Boolean,
      default: false
    },
    overrideDefaultValue: {
      type: String,
      default: ''
    },
    showRadioOptions: {
      type: Boolean,
      default: true
    },
    boolBidAdjust: {
      type: Boolean,
      default: false
    },
    radioConfig: {
      type: Array,
      default: () => {
        return [
          {
            title: 'Test',
            value: 'test',
            type: 'dropdown',
            dropdown: [
              {
                label: 'Change by percentage',
                value: 'percentage'
              },
              {
                label: 'Change absolute value',
                value: 'absolute_value'
              }
            ]
          },
          {
            title: 'Test1',
            value: 'test1',
            type: 'input'
          }
        ];
      }
    },
    defaultInputValue: {
      type: Number,
      default: 0
    },
    bidSuggestion: {
      type: Object,
      default: () => {
        return { show: false };
      }
    },
    warningMessage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedDropdown: {},
      radioValue: null,
      radioBidSuggestion: 'currentBid',
      inputValue: null
    };
  },
  computed: {
    radioConfigObj() {
      const radioObj = this.radioConfig.filter(
        (item) => item.value === this.radioValue
      );
      return radioObj.length && radioObj[0];
    }
  },
  watch: {
    radioBidSuggestion(val) {
      this.$emit('radioBidSuggestionData', val);
    },
    defaultInputValue(val) {
      this.inputValue = val;
    },
    overrideBudgetCheck(val) {
      this.radioValue = val
        ? this.overrideDefaultValue
        : this.defaultRadioSelected;
    },

    radioConfigObj(newValue) {
      if (this.radioConfigObj.type === 'dropdown') {
        this.selectedDropdown =
          (this.radioConfigObj?.dropdown && this.radioConfigObj?.dropdown[0]) ||
          {};
      }
    },
    disableMaxImpressions(val) {
      if (val) {
        this.radioValue = 'override_daily_budget';
      }
    },
    bidSuggestion(newVal, oldVal) {
      if (newVal.show) {
        this.toggleBidSuggestion();
      }
    }
  },
  created() {
    if (this.overrideBudgetCheck) {
      this.radioValue = this.overrideDefaultValue;
    } else {
      this.radioValue = this.defaultRadioSelected;
    }
    this.inputValue = this.defaultInputValue;
    if (this.bidSuggestion.show) {
      this.radioBidSuggestion = 'currentBid';
      this.toggleBidSuggestion();
    }
    this.onUpdate();
  },
  methods: {
    getInputValue() {
      if (
        this.radioConfigObj?.type === 'input' &&
        this.radioBidSuggestion === 'suggestedBid'
      ) {
        return this.bidSuggestion?.value?.toString();
      } else {
        if (this.radioBidSuggestion === 'suggestedMinBid') {
          return this.bidSuggestion?.secondValue?.toString();
        } else return this.inputValue;
      }
    },
    optionSelected(c, selectedOption) {
      if (selectedOption?.length) {
        this.selectedDropdown = selectedOption[0];
        this.onUpdate();
      }
    },
    onRadioUpdate() {
      this.inputValue = null;
      this.toggleBidSuggestion();
      this.onUpdate();
    },
    toggleBidSuggestion() {
      if (this.bidSuggestion.show && this.radioConfigObj.type === 'input') {
        if (
          this.bidSuggestion.multipleSuggestions ||
          (this.bidSuggestion.show && !this.bidSuggestion.multipleSuggestions)
        ) {
          this.inputValue = this.bidSuggestion?.currentBid;
          this.radioBidSuggestion = this.radioBidSuggestion || 'currentBid';
        } else {
          this.inputValue = this.inputValue
            ? this.inputValue
            : this.bidSuggestion?.currentBid;
        }
      }
      this.onUpdate();
    },
    onUpdate() {
      this.$nextTick(() => {
        const updatedValues = {
          radioValue: this.radioValue,
          inputValue: this.getInputValue(),
          inputType: this.radioConfigObj?.type,
          dropdownLabel:
            this.radioConfigObj?.type === 'dropdown'
              ? this.selectedDropdown.label ||
                (this.radioConfig?.dropdown?.[0] &&
                  this.radioConfig?.dropdown[0]?.label)
              : null,
          dropdownValue:
            this.radioConfigObj?.type === 'dropdown'
              ? this.selectedDropdown?.value ||
                (this.radioConfig?.dropdown?.[0] &&
                  this.radioConfig?.dropdown[0]?.value)
              : null
        };
        this.$emit('onUpdate', updatedValues);
      });
    }
  }
};
</script>

<style lang="css" scoped>
.valueDropdown {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  color: #4b5158;
}

.rb-width {
  width: 60px;
}
.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
}

.rb-input::-webkit-input-placeholder {
  color: #caccce;
}
.rb-input::-moz-placeholder {
  color: #caccce;
}
.rb-input:-ms-input-placeholder {
  color: #caccce;
}
.rb-input:-moz-placeholder {
  color: #caccce;
}

.percentBox .rb-input {
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.percentBox .rb-input.currency-input {
  border-right: 0;
}

.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
}
.appender-percentage {
  height: 36px;
}
.error-width {
  width: 140px;
}
</style>
