<template>
  <div>
    <div class="u-display-flex u-bg-color-white-base">
      <span
        v-if="controlsCount.length > 0"
        class="u-cursor-pointer u-flex-align-self-center"
        :class="{
          'disabled-opacity u-pointer-events-none': activeControl === 0
        }"
        @click="handlePreviousClick"
      >
        <rb-icon icon="angled-arrow-left" />
      </span>
      <spotlight-card
        v-for="(spotlight, index) in spotlightsToShow"
        :key="index"
        :spotlight-data="spotlight"
        class="u-flex-1 u-spacing-p-m u-spacing-m-s u-border-radius-s u-bg-color-grey-lighter-bgd u-border-all u-border-width-s u-border-color-grey-xxx-light"
      />
      <span
        v-if="controlsCount.length > 0"
        class="u-cursor-pointer u-flex-align-self-center"
        :class="{
          'disabled-opacity u-pointer-events-none':
            activeControl === controlsCount.length - 1
        }"
        @click="handleNextClick"
      >
        <rb-icon icon="angled-arrow-right" />
      </span>
    </div>
    <ul
      v-if="controlsCount.length !== 1"
      class="controls"
    >
      <li
        v-for="(control, index) in controlsCount"
        :key="control"
        class="control"
        :class="{ active: index === activeControl }"
        @click="activeControl = index"
      />
    </ul>
  </div>
</template>

<script>
import SpotlightCard from '@/components/pages/insights/amazon/cva/atoms/spotlight-card.vue';
export default {
  components: {
    SpotlightCard
  },
  props: {
    noOfSpotlightsToShow: {
      type: Number,
      default: 3
    },
    spotlightsData: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      activeControl: 0
    };
  },
  computed: {
    controlsCount() {
      const count = Math.ceil(
        this.spotlightsData.length / this.noOfSpotlightsToShow
      );
      return new Array(count);
    },
    spotlightsToShow() {
      const startIndex = this.activeControl * this.noOfSpotlightsToShow;
      const endIndex = startIndex + this.noOfSpotlightsToShow;
      return this.spotlightsData.slice(startIndex, endIndex);
    }
  },
  methods: {
    handlePreviousClick() {
      if (this.activeControl > 0) {
        this.activeControl--;
      }
    },
    handleNextClick() {
      if (this.activeControl < this.controlsCount.length - 1) {
        this.activeControl++;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.controls {
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding-bottom: 0.8rem;
}
.controls .control {
  width: 8px;
  height: 8px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #e9eaeb;
  margin: 0.4rem;
}
.controls .control.active {
  background-color: #007cf6;
}
</style>
