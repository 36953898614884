<template>
  <div
    v-if="skusTableService"
    id="in_stock_perf"
  >
    <generic-tabs-component
      :tabs-list="skuTypes"
      :shadow="false"
      :tab-styles="{ gap: '0px' }"
      :class="{
        'u-border-bottom u-border-width-s u-border-color-grey-xxx-light':
          computedError || computedLoading
      }"
      @handleTabsClick="handleSkuTypeChange"
    />
    <div
      v-if="computedError"
      class="u-min-height-300 u-height-100 u-bg-color-grey-white container u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
    >
      <p>Error Occurred. Please try again later</p>
    </div>
    <div
      v-else-if="computedLoading"
      class="u-min-height-300 u-display-flex u-flex-direction-row u-height-100 u-width-100 u-flex-justify-content-center u-flex-align-items-center"
    >
      <loader
        class="u-bg-color-grey-white"
        :loading="true"
        :color="'#007cf6'"
      />
    </div>
    <div
      v-if="brandViewService"
      v-show="!showSkusTable"
    >
      <!-- will be replaced by benchmarkBrands -->
      <rb-insights-table
        :table-column="brandTableColDef"
        :table-row="table1Data"
        :grid-options="brandTableGridOptions"
      />
    </div>
    <div v-show="showSkusTable && !computedLoading && !computedError">
      <rb-insights-table
        :table-column="skusTableColDefs"
        :table-row="skusTableData"
        sorting-change-event="rnrSortEvent"
        :grid-options="skusTableGridOptions"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import BrandViewService from '@/components/pages/insights/amazon/cva/services/reviewsAndRatings/BrandView';
import SkusService from '@/components/pages/insights/amazon/cva/services/reviewsAndRatings/SkusService';
import genericTabsComponent from '@/components/basic/genericTabsComponent.vue';
import loader from '@/components/basic/loader';
import skuWithRating from '@/components/basic/skuWithRating.vue';
import percentageWithCell from '@/components/basic/percentageBackgroundComponent.vue';
import selectCell from '@/components/pages/insights/amazon/cva/atoms/select-cell.vue';
import radioGroupDropdown from '@/components/pages/insights/amazon/cva/molecules/radio-group-dropdown.vue';
import { isCloseDC } from '@/components/pages/insights/amazon/cva/utils';

export default {
  name: 'ContentScore',
  components: { genericTabsComponent, loader },
  props: {
    selectedWidget: {
      type: String,
      default: 'Brand View'
    },
    pageSettings: {
      type: Object,
      default: {}
    },
    service: {
      type: Object,
      required: true
    },
    clickedDimension: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      brandViewService: null,
      skusTableService: null,
      skuTypes: ['Your SKUs', 'Competitor SKUs'],
      selectedSkus: 'Your SKUs',
      brandViewGridOptions: {
        context: {
          componentParent: this
        }
      },
      skusTableGridOptions: {
        context: {
          componentParent: this
        }
      }
    };
  },
  computed: {
    brandViewColumnDefs() {
      const columns = this.brandViewService?.getColumnDefs() || [];
      return [...columns];
    },
    showSkusTable() {
      return this.selectedWidget === 'SKUs';
    },
    computedLoading() {
      if (this.showSkusTable) {
        return (
          this.skusTableService?.metadata.loading ||
          this.skusTableService.loading ||
          this.service?.loading
        );
      }
    },
    computedError() {
      if (this.showSkusTable) {
        return (
          this.skusTableService?.metadata.error || this.skusTableService.error
        );
      }
    },
    skusTableColDefs() {
      const columns = this.skusTableService?.getColumnDefs() || [];
      return [...columns];
    },
    brandViewData() {
      return this.brandViewService?.tableRows;
    },
    skusTableData() {
      this.skusTableGridOptions?.api?.sizeColumnsToFit();
      return this.skusTableService?.getTableData();
    }
  },
  watch: {
    'service.availableDimension': {
      handler(newVal, oldVal) {
        if (oldVal && newVal) {
          isCloseDC(newVal, this.clickedDimension.name);
        }
      }
    }
  },
  created() {
    Vue.component('skuWithRating', skuWithRating);
    Vue.component('selectCell', selectCell);
    Vue.component('percentageWithCell', percentageWithCell);
    Vue.component('radioGroupDropdown', radioGroupDropdown);
  },
  mounted() {
    this.skusTableService = new SkusService('cva', 'table2', 459, 100);
    this.skusTableService.fetchData(this.pageSettings, this.selectedSkus);
    this.fetchLiner();
  },
  methods: {
    async fetchLiner() {
      const x = await this.skusTableService.fetchInsights(
        ['Double click RnR'],
        this.pageSettings
      );
      this.$emit('fetchLiner', x.data.entityData[0]?.entityValue);
    },
    handleSkuTypeChange(selected) {
      this.selectedSkus = selected;
      this.skusTableService.fetchData(this.pageSettings, selected);
    },
    applyRadioSelection(newSelection, oldSelection) {
      this.brandViewService?.applyRadioSelection(newSelection, oldSelection);
    }
  }
};
</script>
