<template>
  <div class="sankey-box u-position-relative">
    <div
      class="u-ciq-style u-ciq-style-2 u-color-grey-mid-light u-position-absolute"
      expoter="visibility-hidden"
    >
      <div
        v-for="(item, i) in hyperlinksArray"
        :key="i"
        class="u-color-grey-lighter u-font-size-7 u-display-flex u-flex-align-items-center"
      >
        <rb-icon
          class="dot-icon u-spacing-ml-s u-spacing-mr-xs u-color-blue-base"
          icon="dot"
          :class="{ 'u-color-grey-lighter rb-icon--xx-small': !item.selected }"
        />
        <span
          class="u-cursor-pointer"
          :class="{ 'u-color-blue-base u-font-size-6': item.selected }"
          @click="scrollToNode(item.key, i)"
          >{{ item.title }}</span
        >
      </div>
    </div>
    <div
      v-if="showPoweredByCIQText"
      class="u-ciq-style u-color-grey-lighter u-position-absolute u-font-weight-600"
    >
      Powered by
      <span class="u-color-grey-base"
        >Commerce<span class="u-color-blue-base">IQ</span></span
      >
    </div>
    <svg
      id="sankey-chart-svg"
      ref="sankey-chart-svg"
      class="sankey-chart-svg"
    />
  </div>
</template>

<script>
import * as d3 from 'd3';
import { sankey, sankeyLinkHorizontal, sankeyLeft } from 'd3-sankey';
import Vue from 'vue';
import sankeyMetric from './sankey-metric.vue';
import { store } from '@/store/store';

export default {
  props: {
    chartWidth: {
      type: Number,
      default: 2500
    },
    chartHeight: {
      type: Number,
      default: 600
    },
    showPoweredByCIQText: {
      type: Boolean,
      default: false
    },
    chartData: {
      type: Object,
      default: () => ({ nodes: [], links: [] })
    },
    hyperlinksArray: {
      type: Array,
      default: () => []
    },
    colorCode: {
      type: Object,
      default: () => ({})
    },
    metricConfig: {
      type: Object,
      default: () => ({})
    },
    metricData: {
      type: Object,
      default: () => ({})
    },
    totalValueOfInitialNode: {
      type: Number,
      default: 1
    },
    initalNodeName: {
      type: String,
      default: ''
    },
    downloadableMetric: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      minNodeWidth: 16
    };
  },
  watch: {
    chartData: {
      immediate: true,
      deep: true,
      handler() {
        this.sankeyChartGenerator();
      }
    }
  },
  methods: {
    sankeyChartGenerator() {
      const { nodes, links } = this.chartData;
      if (nodes.length === 0) return;
      const width = this.chartWidth;
      const height = this.chartHeight;
      const nodeWidth = 15; // width of each node
      const nodePadding = 90; // vertical padding between nodes
      const marginTop = 80; // top margin, in pixels
      const marginRight = 200; // right margin, in pixels
      const marginBottom = 80; // bottom margin, in pixels
      const marginLeft = 10; // left margin, in pixels

      const ENABLE_LINKS_GRADIENTS = true;

      d3.selectAll('.sankey-chart-svg > g > *').remove();

      const svg = d3
        .select(this.$refs['sankey-chart-svg'])
        .attr('viewBox', [0, 0, width, height])
        .attr(
          'style',
          `width: ${width}px; max-height: ${height}px; overflow: auto; background-color: white; font-family: ProximaNova;`
        )
        .attr('class', 'sankey-chart-svg');

      // Initialize nodes and links for sankey chart
      sankey()
        .nodeId((d) => d.id)
        .nodeWidth(nodeWidth)
        .nodePadding(nodePadding)
        .nodeAlign(sankeyLeft)
        .extent([
          [marginLeft, marginTop],
          [width - marginRight, height - marginBottom]
        ])({ nodes, links });

      this.nodeAndLinkReconstruction(nodes, links);

      // Construct visible node on the chart
      svg
        .append('g')
        .attr('stroke', '#000')
        .attr('stroke-width', '0')
        .selectAll('rect')
        .data(nodes)
        .enter()
        .append('rect')
        .attr('x', (d) => d.x0)
        .attr('y', (d) => d.y0)
        .attr('height', (d) => {
          return d.y1 - d.y0 || 0;
        })
        .attr('width', (d) => (d.actualValue ? d.x1 - d.x0 : 0))
        .attr('fill', (d) => d.color)
        .attr('id', (d) => 'node-' + d.id);

      svg
        .append('g')
        .attr('stroke', '#000')
        .attr('stroke-width', '0')
        .selectAll('rect')
        .data(nodes)
        .enter()
        .append('circle')
        .attr('cx', (d) => d.x0)
        .attr('cy', (d) => d.y0)
        .attr('r', (d) => (d.actualValue ? 0 : 4))
        .attr('fill', (d) => d.color);

      const link = svg
        .append('g')
        .attr('fill', 'none')
        .attr('stroke-opacity', 0.3)
        .selectAll('g')
        .data(links)
        .enter()
        .append('g')
        .style('mix-blend-mode', 'multiply');

      if (ENABLE_LINKS_GRADIENTS) {
        link
          .append('linearGradient')
          .attr('id', (d) => (d.uid = `${d.source.id}-to-${d.target.id}`))
          .attr('gradientUnits', 'userSpaceOnUse')
          .attr('x1', (d) => d.source.x1)
          .attr('x2', (d) => d.target.x0)
          .attr('y1', (d) => d.source.y1)
          .attr('y2', (d) => d.target.y0)
          .call((gradient) =>
            gradient
              .append('stop')
              .attr('offset', '0%')
              .attr('stop-color', (d) =>
                d.target.value ? d.source.color : '#000'
              )
              .attr('stop-opacity', (d) => (d.target.value ? '0.3' : '1'))
          )
          .call((gradient) =>
            gradient
              .append('stop')
              .attr('offset', '100%')
              .attr('stop-color', (d) =>
                d.target.value ? d.target.color : '#000'
              )
              .attr('stop-opacity', (d) => (d.target.value ? '0.3' : '1'))
          );
      }

      link
        .append('path')
        .attr('d', sankeyLinkHorizontal())
        .attr('stroke', (d) =>
          !ENABLE_LINKS_GRADIENTS ? d.color : `url(#${d.uid})`
        )
        .attr('stroke-width', (d) =>
          d.target.value ? Math.max(1, d.width) : '2'
        )
        .attr('stroke-dasharray', (d) => (d.target.value ? 0 : 2));

      svg
        .append('g')
        .attr('font-family', 'ProximaNova')
        .selectAll('text')
        .data(nodes)
        .enter()
        .append('foreignObject')
        .attr('x', (d) => (d.actualValue ? d.x1 : d.x0))
        .attr('y', (d) => (d.actualValue ? d.y0 : d.y0 - 22))
        .attr('width', 250)
        .attr('height', 70)
        .append('xhtml:div')
        .attr('id', (d) => d.id);

      this.addMetricsToChart();
    },
    nodeAndLinkReconstruction(nodes, links) {
      // Reconstruct y0 and y1 values for nodes and links, when there is no data in sankey
      const nodeYMap = {};

      if (this.totalValueOfInitialNode) {
        nodes.forEach((node) => {
          if (node.y1 !== node.y0) {
            if (node.y1 - node.y0 < this.minNodeWidth) {
              node.y1 = node.y0 + this.minNodeWidth;
            }
          }
          nodeYMap[node.id] = { y0: node.y0, y1: node.y1 };
        });

        links.forEach((link) => {
          link.width = link.width
            ? Math.max(link.width, this.minNodeWidth - 2)
            : 0;
          if (link.width <= this.minNodeWidth) {
            link.source.y1 = nodeYMap[link.source.id].y1;
            link.target.y1 = nodeYMap[link.target.id].y1;
            link.source.y0 = nodeYMap[link.source.id].y0;
            link.target.y0 = nodeYMap[link.target.id].y0;
            // if (link.y0 < (link.source.y0 + (link.width / 2)) && link.y0 > (link.source.y1 - (link.width / 2))) { link.y0 = (link.source.y0 + link.source.y1) / 2; }
            link.y0 = (link.source.y0 + link.source.y1) / 2;
            link.y1 = (link.target.y0 + link.target.y1) / 2;
          }
        });

        return;
      }

      const layerMap = nodes.reduce((acc, node) => {
        if (acc[node.layer]) {
          acc[node.layer].push(node.id);
        } else {
          acc[node.layer] = [node.id];
        }
        return acc;
      }, {});

      nodes.forEach((el) => {
        const idx = layerMap[el.layer].indexOf(el.id);
        const y =
          ((idx + 1) * this.chartHeight) / (layerMap[el.layer].length + 1);
        nodeYMap[el.id] = el.y0 = el.y1 = y;
      });

      links.forEach((el) => {
        el.y0 = el.source.y0 = el.source.y1 = nodeYMap[el.source.id];
        el.y1 = el.target.y0 = el.target.y1 = nodeYMap[el.target.id];
      });
    },
    addMetricsToChart() {
      this.chartData.nodes.forEach((node) => {
        if (document.getElementById(node.id)) {
          const metricComponent = Vue.extend({
            store: store,
            components: { sankeyMetric },
            render: (h) => {
              return h(sankeyMetric, {
                props: {
                  metricIcon: node.icon,
                  metricColor: node.color,
                  metricName: node.name,
                  metricTooltip: node.metricTooltip,
                  metricConfig: this.metricConfig[node.id],
                  metricData: this.metricData[node.id],
                  nodeValue: node.actualValue,
                  startNodeName: this.initalNodeName,
                  totalValue: this.totalValueOfInitialNode || 1,
                  metricId: node.id,
                  downloadableMetric: this.downloadableMetric
                }
              });
            }
          });
          const mc = new metricComponent();
          mc.$mount('#' + node.id);
        }
      });
    },
    scrollToNode(id, index) {
      this.hyperlinksArray.forEach((el) => this.$set(el, 'selected', false));
      this.$set(this.hyperlinksArray[index], 'selected', true);
      document.getElementById('node-' + id).scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
    }
  }
};
</script>

<style lang="css">
.sankey-box {
  margin: 8px 0px 24px;
  border-radius: 4px;
  border: solid 1px #e9eaeb;
  overflow: auto;
  width: 100%;
  max-height: 656px;
}
.sankey-box .sankey-chart-svg {
  background-color: white;
  fill: white;
  overflow: scroll;
  font-family: 'ProximaNova';
}
</style>
