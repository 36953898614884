export default {
  renderHeader: (h, column, toolTipRequired, toolTipText) => {
    if (toolTipRequired === undefined || toolTipRequired === null) {
      toolTipRequired = false;
      return h(
        'span',
        {
          class: {
            'el-table--columnHeaderLabel': true
          }
        },
        [h('span', column.label)]
      );
    }
    return h(
      'el-tooltip',
      {
        attrs: {
          effect: 'dark',
          content: '' + toolTipText,
          placement: 'top',
          transition: ''
        }
      },
      [
        h(
          'span',
          {
            class: {
              'el-table--columnHeaderLabel': true
            }
          },
          [h('span', column.label)]
        )
      ]
    );
  }
};
