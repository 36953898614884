<template>
  <!-- Desc: config driven header component for entity details -->
  <div
    class="u-width-100 u-display-flex u-bg-color-grey-white u-font-size-3 u-spacing-mb-m u-bg-color-grey-white u-spacing-ph-l u-header-box-shadow"
  >
    <div
      v-if="entityConfig && entityId"
      class="u-display-flex u-width-100"
    >
      <loader
        class="fill--parent"
        :loading="metadata.status === 'loading'"
        :color="'#3684bb'"
      />

      <div v-if="openActivity">
        <activity-log
          v-if="getShowCampaignsActivityLog || showActionsLog"
          :data="getActivityLogData"
          page-name="campaigns"
          :close-activity-log="closeActivityLog"
          top-spacing="0px"
        />
      </div>

      <div class="u-display-flex u-flex-direction-column u-width-100">
        <div
          class="u-display-flex u-spacing-pb-s u-flex-justify-content-space-between"
        >
          <div class="u-flex-align-items-center u-display-flex u-max-width-70">
            <div @click="redirectTo">
              <rb-icon
                icon="arrow_back"
                class="u-color-grey-lighter arrow--entity_details u-icon-size-2 u-cursor-pointer u-spacing-mr-s"
              />
            </div>
            <div>
              <Bookmark
                v-if="entityConfig.bookmark.value"
                :title="
                  entityConfig.bookmark.fromMetadata
                    ? metadata.data[entityConfig.bookmark.title]
                    : entityConfig.bookmark.title
                "
                :path="$route.fullPath"
              />
            </div>
            <div class="u-text-overflow-ellipsis">
              <div
                v-if="
                  entityConfig[linesHeader] &&
                  entityConfig[linesHeader].items.length
                "
                :class="entityConfig[linesHeader].class"
                class="u-width-100"
              >
                <div
                  v-for="(lineItem, lineIndex) in entityConfig[linesHeader]
                    .items"
                  :key="`${lineIndex}_${linesHeader}`"
                >
                  <attribute
                    v-if="lineItem.itemType === 'attribute'"
                    :line-item="lineItem"
                    :line-index="lineIndex"
                    :metadata="metadata.data"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="u-display-flex u-min-width-30">
            <rb-filter-panel
              id="filterID"
              :key="filterKey"
              :data="filterData"
              :primary="primaryFilterData"
              :secondary-data="secondaryFilterData"
              :listener-event="filterConfig.filterLocalStorageKey"
              :config="filterConfig.config"
              :loader-status="loaderStatus"
              :emit="filterConfig.config.emit"
              :has-search="true"
              :new-date="true"
              :allow-date-compare="true"
              :show-data-missing-msg="showDataMissingMsg"
              :data-missing-date-range="'August 11th, 2023, to October 9th, 2023,'"
              :enable-save="false"
              :page="filterConfig.page"
              :hide-filter="true"
              :hide-add-filter="true"
              default-date-post-range="last30Days"
              :max-date="entityConfig.maxFeedDate || minMaxDate"
              :enable-remove-all="false"
              :show-notification="false"
              :enable-open-filter="false"
              :additional-ranges="additionalRanges"
              @savedFilterApplied="filterKey++"
              @filterMounted="handleFilterMount"
            />
            <div
              v-if="
                entityConfig &&
                entityConfig.activityLog &&
                entityConfig.activityLog.show
              "
              class="u-spacing-pl-s"
            >
              <div
                class="activity-log-entity-details u-cursor-pointer u-spacing-ph-m u-spacing-pv-s u-border-all u-border-color-grey-xxx-light u-border-radius-s u-border-width-s"
                @click="openSidePanel"
              >
                <rb-icon
                  title="Activity log"
                  icon="timeline"
                  class="rb-icon--medium u-color-grey-lighter"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div
            v-for="(
              { lineName, lineConfig }, index
            ) in subLinesWithLineConfigArray()"
            :key="index"
            class="u-display-flex u-width-100 u-flex-justify-content-space-between u-spacing-pl-xxxl"
          >
            <div
              v-if="lineConfig && lineConfig.items.length"
              :class="lineConfig.class"
              class="u-display-flex u-flex-wrap-yes"
            >
              <div
                v-for="(lineItem, lineIndex) in lineConfig.items"
                :key="`${lineIndex}_${lineName}`"
              >
                <attribute
                  v-if="lineItem.itemType === 'attribute'"
                  :line-item="lineItem"
                  :line-index="lineIndex"
                  :metadata="metadata.data"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import loader from '@/components/basic/loader';
import activityLog from '@/components/widgets/collaboration/collaboration.vue';
import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import widgetsMixin from '@/components/widgetsMixin';
import attribute from '@/pages/entity_details/common_components/header/attribute.vue';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import { commons } from '@/components/ams/campaigns/commons.js';
import { cloneDeep } from 'lodash';

export default {
  components: {
    rbFilterPanel,
    loader,
    attribute,
    activityLog
  },
  mixins: [widgetsMixin],
  props: {
    entityConfig: {
      default: null,
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    showDataMissingMsg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      linesHeader: 'line1',
      subLines: ['line2', 'line3'],
      filterKey: 0,
      showActionsLog: false,
      openActivity: false,
      minMaxDate: {},
      filterConfig: {},
      additionalRanges: commons.additionalDateRanges()
    };
  },
  computed: {
    metadata() {
      return this.$store.getters[this.entityConfig.metadata_api_state.getter];
    },
    getActivityLogData() {
      return this.$store.getters.getActivityLog.CampaignsActivityLog;
    },
    getShowCampaignsActivityLog() {
      return this.$store.getters.getShowCampaignsActivityLog;
    }
  },

  created() {
    // hit apis here
    this.filterConfig = this.entityConfig.filters;
  },
  methods: {
    subLinesWithLineConfigArray() {
      let updatedArray = [];
      for (let line of this.subLines) {
        let lineConfig = null;
        if (typeof this.entityConfig[line] === 'function') {
          lineConfig = this.entityConfig[line](this);
        } else {
          lineConfig = this.entityConfig[line];
        }
        updatedArray.push({ lineName: line, lineConfig });
      }
      return updatedArray;
    },
    openSidePanel() {
      this.showActionsLog = true;
      this.openActivity = true;
      this.$store.dispatch('openCampaignsActivityLog');
      this.$store.dispatch('getCampaignsActivityLog', {
        primaryKey: this.$route.params.entityId
      });
    },
    closeActivityLog() {
      if (!this.showActionsLog && !this.openActivity) {
        return;
      }
      this.$store.dispatch('closeCampaignsActivityLog');
      this.showActionsLog = false;
      this.openActivity = false;
    },
    redirectTo() {
      // eslint-disable-next-line prefer-const
      let { config, value, path } = cloneDeep(this.entityConfig?.redirectTo);
      if (config && value) {
        if (typeof config === 'function') {
          config = config(this);
        }
        if (config?.params?.entityId) {
          const entityId = this.$route.params.entityId;
          deepReplaceObjectValues(config, ':entityId', entityId);
        }
        this.$router.push(config);
      } else if (path) {
        this.$store.dispatch('closeCampaignsActivityLog');
        this.$router.push(path);
      }
    },
    handleFilterMount() {
      this.$emit('filterMounted');
    }
  }
};
</script>
<style lang="css">
.arrow--entity_details {
  height: 21;
  width: 21;
}
.arrow--entity_details svg {
  height: 21;
  width: 21;
}
.activity-log-entity-details:hover,
.activity-log-entity-details:focus {
  border: 1px solid #caccce;
}
</style>
<style scoped>
.u-width-750px {
  width: 750px !important;
}
</style>
