<template>
  <section
    class="campaign-creation-edit u-position-fixed widget--full-screen"
    @click.stop="removeFocus"
  >
    <loader
      v-show="loading"
      slot="loader"
      class="fill--parent"
      :color="'#3fadf7'"
      :size="'4rem'"
      :thickness="'.2rem'"
      style="z-index: 300"
    />
    <div class="u-position-fixed model u-width-100 campaign-creation-edit">
      <!-- Adgroup Name Edit Start -->
      <section
        class="campaign-creation-edit--header u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-p-l"
      >
        <div
          class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-flex-1"
        >
          <div
            class="u-display-flex u-flex-align-items-center"
            style="height: 2.4rem"
          >
            <span @click="closeWithoutSave()">
              <rb-icon
                :icon="'cross'"
                data-cy="crossIcon"
                class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-mr-s"
              />
            </span>
            <div
              class="u-display-flex"
              :style="{
                width: showEdit
                  ? adGroupName.length === 0
                    ? '23ch'
                    : adGroupName.length * 11.5 < 800
                    ? `${adGroupName.length * 11}px`
                    : '60%'
                  : '100%'
              }"
            >
              <input
                ref="adGroup-creation-input"
                v-model="adGroupName"
                type="text"
                data-cy="suggestedCampaignNameAndEdit"
                placeholder="Untitled"
                class="heading--input u-width-100 u-font-size-2 u-font-weight-bold text-underline"
                :class="
                  showEdit || adGroupName.length === 0
                    ? 'u-pointer-events-none u-text-overflow-ellipsis text-underline-color-white'
                    : ''
                "
                style="padding-left: 2px"
                @click.self.stop="() => {}"
              />
            </div>
            <div
              v-if="showEdit"
              class=""
              @click.stop="onEditAdgroupName"
            >
              <rb-icon
                data-cy="pencilIcon"
                class="rb-icon--x-medium u-cursor-pointer u-spacing-ml-s u-color-grey-lighter"
                :icon="'pencil'"
              />
            </div>
          </div>

          <FormErrorMessage
            class="u-spacing-ml-l"
            :show-error="Boolean(adgroupNameErrorMessage)"
            :error-text="adgroupNameErrorMessage"
          />
        </div>
      </section>
      <!-- Adgroup Name Edit ends -->
      <!-- Scroll section -->
      <StepComponent
        :config="stepsConfig"
        :panel-width="10"
        :all-steps-data="pageData"
        :enabled-bread-crumbs="[]"
        :show-step-bullet-description="true"
        bullet-point-class="u-spacing-pt-m"
        all-bullet-points-class="marign-top-campaign-creation-bullets"
        class="u-width-100"
        :set-active-selection="setActiveSelection"
        @activeSelectionUpdated="activeSelectionUpdated"
      />
      <!-- Scroll section -->
      <loader
        v-show="false"
        class="fill--parent"
        :color="'#3fadf7'"
        :size="'4rem'"
        :thickness="'.2rem'"
      />
    </div>
  </section>
</template>

<script>
import config from '@/components/ams/ad_group_creation/setup_configs/index.js';
import StepComponent from '@/components/widgets/stepComponentV2.vue';
import loader from '@/components/basic/loader.vue';
import modal from '@/components/widgets/modal.vue';
import stepsMixin from '@/components/pages/strategy_builder/steps/stepsMixin.js';
import FormErrorMessage from '@/components/ams/campaign_creation/walmart_steps/common/FormErrorMessage.vue';
import { cloneDeep } from 'lodash';

export default {
  name: 'CreateAdgroup',
  components: {
    StepComponent,
    loader,
    modal,
    FormErrorMessage
  },
  mixins: [stepsMixin],
  data() {
    return {
      stepsConfig: [],
      header: {},
      pageData: null,
      setActiveSelection: 0,
      showEdit: true,
      loading: false,
      campaignId: null,
      selectedAdgroupType: 'autoAdgroup',
      adGroupId: null,
      advertiserId: null,
      adgroupCreateDataService: {},
      adgroupNameFilledFirstTimeOnFetch: false,
      adgroupNameErrorMessage: '',
      adgroupNameAlreadyExists: false
    };
  },
  computed: {
    retailer() {
      return this.$store.getters.getRetailer;
    },
    allStepsData() {
      return this.$store.getters?.[this.pageData?.getter] || {};
    },
    adgroupTargetingType() {
      return this.allStepsData?.targetingType || 'manual';
    },
    isAdgroupAlreadyCreated() {
      const { adGroupId } = this.$route.query;
      if (adGroupId) {
        return true;
      }
      return false;
    },
    adGroupName: {
      get() {
        return this.allStepsData.adGroupName || '';
      },
      set(name) {
        this.updateAdgroupName(name);
      }
    }
  },
  watch: {
    allStepsData: {
      handler(newVal) {
        const { adgroupType, fetchedAdGroupResponse } = newVal;
        if (this.selectedAdgroupType !== adgroupType && adgroupType) {
          this.selectedAdgroupType = adgroupType;
          this.setConfigs();
        }
        if (
          fetchedAdGroupResponse &&
          fetchedAdGroupResponse?.name &&
          !this.adgroupNameFilledFirstTimeOnFetch
        ) {
          // This condition will only hit once. When the response is filled in allStepsData,
          // we update the adGroupName and change the boolean of adgroupNameFilledFirstTimeOnFetch to true
          // So that we do not keep updating the adgroup name on every allStepsDataChange
          this.adGroupName = fetchedAdGroupResponse?.name;
          this.adgroupNameFilledFirstTimeOnFetch = true;
          this.loading = false;
        }
      },
      deep: true,
      immediate: true
    },
    selectedAdgroupType(newVal) {
      this.appendToQueryParams({
        adgroupType: newVal
      });
    },
    adgroupTargetingType() {
      this.setConfigs();
    },
    adGroupName(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateAdgroupName();
        this.checkAdgroupNameValidity();
      }
    },
    adgroupNameAlreadyExists() {
      this.updateAdgroupNameAlreadyExists();
    }
  },
  created() {
    this.loading = true;
    this.setConfigs();
    const { campaignType } = this.$route.query;
    const allStepsData = { ...this.allStepsData };
    allStepsData.campaignType = campaignType;
    allStepsData.adgroupType = campaignType?.split?.('_')?.[1] + 'Adgroup';
    this.$store.dispatch(this.pageData.action, { ...allStepsData });
    this.updateAdgroupName();
    this.updateAdgroupNameAlreadyExists();
    this.checkAdgroupNameValidity();
    this.loading = false;

    // If adgroup is already created, wait for adgroup details to be fetched, watch for fetchedAdGroupResponse
    // in the watch property and then update the adgroup name accordingly.
    if (this.isAdgroupAlreadyCreated) {
      this.loading = true;
    }
  },
  methods: {
    checkAdgroupNameValidity() {
      // This adgroup name already exists.
      let errorMessage = '';
      if (this.adgroupNameAlreadyExists) {
        errorMessage = 'This Ad Group name already exists.';
      }
      if (!this.adGroupName) {
        errorMessage = 'Please enter an Ad Group name';
      } else if (this.adGroupName.length > 255) {
        errorMessage = 'The name should be less than 255 characters.';
      }
      this.adgroupNameErrorMessage = errorMessage;
    },
    appendToQueryParams(keyValPairs) {
      const query = { ...this.$route.query, ...keyValPairs };
      this.$router.replace({ query: query });
    },
    setConfigs() {
      const { campaignType } = this.$route.query;
      const clonedConfig = cloneDeep(config);
      this.stepsConfig =
        clonedConfig?.[this.retailer]?.[campaignType]?.stepsConfig;
      this.pageData = clonedConfig?.[this.retailer]?.[campaignType];
    },
    activeSelectionUpdated(selectionIndex) {
      this.setActiveSelection = selectionIndex;
    },
    async removeFocus() {
      this.$refs?.['adGroup-creation-input']?.blur();
      this.showEdit = true;
    },
    onEditAdgroupName() {
      this.showEdit = false;
      this.$refs['adGroup-creation-input'].focus();
    },
    updateAdgroupName(adGroupName = this.allStepsData?.adGroupName) {
      const allStepsData = { ...this.allStepsData };
      allStepsData.adGroupName = adGroupName;
      this.$store.dispatch(this.pageData.action, { ...allStepsData });
    },
    async updateAdgroupNameAlreadyExists(newVal) {
      const allStepsData = { ...this.allStepsData };
      const adgroupNameAlreadyExists = newVal || this.adgroupNameAlreadyExists;
      allStepsData.adgroupNameAlreadyExists = adgroupNameAlreadyExists;
      await this.$store.dispatch(this.pageData.action, { ...allStepsData });
    },
    closeWithoutSave() {
      this.$store.dispatch(this.pageData.action, {});
      // This will take the user back to adgroup management page
      this.pageData?.redirectTo?.(this);
    }
  }
};
</script>

<style lang="css">
.text-underline-color-white {
  text-decoration-color: #fff !important;
}
</style>
