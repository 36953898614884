<template>
  <div>
    <div
      class="u-position-relative u-bg-color-white-base u-width-100 u-spacing-p-l u-display-flex u-flex-direction-column"
    >
      <loader
        class="fill--parent u-bg-color-grey-white"
        :loading="payloadLoader"
        :color="'#007cf6'"
      />
      <div
        v-show="selections.length > 1"
        class="fillMessage fill--parent u-font-size-3"
      >
        Multiple Campaigns are not allowed
      </div>
      <div>
        <div
          class="title u-font-size-5 u-spacing-pb-l u-font-weight-600 u-text-case-upper"
        >
          CHANGE BIDDING SETTINGS ({{ selections.length }} campaign{{
            selections.length > 1 ? 's' : ''
          }}
          selected)
        </div>

        <div class="u-color-grey-light u-display-flex u-flex-direction-column">
          <RadioGroup
            :radio-options="bidTypeOptions"
            :value="bidType"
            :is-column="true"
            @onRadioChanged="handleBidTypeChange"
          >
            <div
              v-if="isOptimizedBidType"
              :slot="`below_radio_${bidType}`"
              class="u-color-grey-light u-spacing-ml-l u-spacing-mt-m"
            >
              <rb-checkbox
                v-model="minRoasChecked"
                :disabled="disableMinRoas"
              >
                <div class="u-font-size-6">Set Target ROAS:</div>
              </rb-checkbox>

              <div class="u-display-flex u-spacing-mt-s">
                <span
                  class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                  :class="
                    disableMinRoas || !minRoasChecked
                      ? 'u-color-grey-xx-light'
                      : ''
                  "
                >
                  {{ $currency }}
                </span>
                <input
                  v-model="minRoasValue"
                  type="number"
                  :disabled="disableMinRoas || !minRoasChecked"
                  min="0"
                  class="rb-input currency-input u-display-flex u-flex-align-items-center"
                  step="0.01"
                />
              </div>
              <div
                v-if="showDailyBudget && isOptimizedBidType"
                class="u-spacing-mt-m"
              >
                <div class="u-display-flex u-flex-align-items-center">
                  <div>
                    <rb-icon
                      class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-pb-l"
                      icon="info-circle-fill"
                    />
                  </div>
                  <div class="u-font-size-5 u-spacing-ml-xs">
                    Requires Daily Budget
                  </div>
                </div>
                <div class="u-font-size-6 u-line-height-1-1">
                  Switching to optimized bidding means that the default and
                  override CPC bids will <br />
                  no longer be applied. This change will take effect within the
                  next business day.
                </div>

                <div class="u-color-grey-light u-spacing-mt-m">
                  <div class="u-font-size-6">Daily budget:</div>

                  <div class="u-display-flex u-spacing-mt-s">
                    <span
                      class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                    >
                      {{ $currency }}
                    </span>
                    <input
                      v-model="dailyBudgetValue"
                      type="number"
                      min="20"
                      class="rb-input currency-input u-display-flex u-flex-align-items-center"
                      step="0.1"
                    />
                  </div>
                </div>
                <div class="u-color-grey-light u-spacing-mt-m">
                  <rb-checkbox v-model="lifetimeBudgetChecked">
                    <div class="u-font-size-6">Lifetime budget:</div>
                  </rb-checkbox>

                  <div class="u-display-flex u-spacing-mt-s">
                    <span
                      class="appender currency-symbol u-display-flex u-flex-align-items-center u-font-size-6 u-spacing-pl-s u-flex-0"
                      :class="
                        !lifetimeBudgetChecked ? 'u-color-grey-xx-light' : ''
                      "
                    >
                      {{ $currency }}
                    </span>
                    <input
                      v-model="lifetimeBudgetValue"
                      type="number"
                      :disabled="!lifetimeBudgetChecked"
                      min="0"
                      class="rb-input currency-input u-display-flex u-flex-align-items-center"
                      step="0.01"
                    />
                  </div>
                </div>
              </div>
            </div>
          </RadioGroup>
          <!-- <div
            v-tippy="{ placement: 'bottom', arrow: true }"
            :title="disabledManual ? disabledErrorMessage : ''"
            class="u-width-50"
          > -->
          <div class="u-width-50">
            <div
              v-if="
                bidType === 'manual' &&
                adgroupTableData.rows.length > 0 &&
                !disableManual
              "
              class="u-spacing-mt-m"
            >
              <div class="u-display-flex u-flex-align-items-center">
                <div>
                  <rb-icon
                    class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-pb-l"
                    icon="info-circle-fill"
                  />
                </div>
                <div class="u-font-size-5 u-spacing-ml-xs">
                  Requires Ad Group Bid
                </div>
              </div>
              <div class="u-font-size-6 u-line-height-1-1">
                Switching to manual bidding means that you’ll have to define a
                maximum default CPC bid for all<br />
                ad groups within this campaign. This change will take effect
                within the next business day.
              </div>
            </div>
            <div
              v-if="
                bidType === 'manual' &&
                adgroupTableData.rows.length > 0 &&
                !disableManual
              "
              class="table-right paginationFooter u-border-color-grey-xxx-light u-border-left u-border-width-s u-spacing-mb-m u-spacing-mt-l"
              style="width: 100%; height: 54vh"
            >
              <rb-insights-table
                class="card"
                :grid-options="gridOptions"
                :config="tableConfigsLeft"
                :auto-configure="adgroupTableData"
                style="width: 100%"
                :row-height="66"
                :enable-client-side-sorting="false"
                :enable-server-side-sorting="false"
                :pagination="true"
                :client-side-pagination="true"
                :pagination-total-key="adgroupTableData.rows.length"
                :get-current-instance="getCurrentSourceInstance"
                :pagination-per-page-limit="10"
                :download-this-table="false"
                :show-pagination-options="false"
                :row-no-hover-state="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="u-width-100 u-border-top u-border-bottom u-border-color-grey-xxx-light u-border-width-s u-display-flex u-spacing-pv-m u-spacing-ph-l u-bg-color-white-base"
    >
      <div
        v-if="applyButtonDisabled"
        v-tippy="{ placement: 'top', arrow: true }"
        :title="applyButtonDisabled ? tooltipErrorMessage : ''"
      >
        <rb-button
          text="Apply"
          type="filled"
          :click-fn="onApply"
          :disabled="true"
          class="u-spacing-mr-12"
          size="s"
        />
      </div>
      <rb-button
        v-else
        text="Apply"
        type="filled"
        :click-fn="onApply"
        :disabled="false"
        class="u-spacing-mr-12"
        size="s"
      />
      <rb-button
        text="Cancel"
        :click-fn="onClose"
        size="s"
      />
    </div>
  </div>
</template>

<style lang="css" scoped>
.paginationFooter {
  border: 1px solid #4b5158 !important;
  background: none !important;
  padding-bottom: 14px;
}
.table-right {
  height: 420px !important;
}
.appender {
  line-height: normal;
  height: 32px;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
  border-left: 0;
}
.appender.currency-symbol {
  border: 1px solid #e9eaeb;
  border-right: 0;
}
.rb-input {
  font-size: 13px;
  color: #2b333b;
  outline: 0;
  line-height: 1;
  border: 1px solid #e9eaeb;
  border-radius: 2px;
}
.rb-input.currency-input {
  border-left: 0;
  padding-left: 4px;
  height: 32px;
}
</style>
<script>
import loader from '@/components/basic/loader';
import config, { bidSettingsFilter } from './bidSettings';
import { commons } from '@/components/ams/campaigns/commons.js';
import transformer from '@/utils/services/data-transformer';
import HttpService from '@/utils/services/http-service';
import RadioGroup from '../../../campaign-creation/components/radio-group.vue';
export default {
  name: 'BidSettings',
  components: {
    loader,
    RadioGroup
  },
  props: {
    actionType: {
      default: 'instacartCampaignEditBidStatus',
      type: String
    },
    selections: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      disableManual: false,
      showDailyBudget: false,
      payloadLoader: false,
      adGroupDataForViewPayload: [],
      rbTableSourceInstance: null,
      tableConfigsLeft: {
        body: {
          APIConfig: {
            page: 1
          }
        }
      },
      gridOptions: {
        domLayout: 'normal',
        context: {
          componentParent: {}
        }
      },
      adGroupsData: [],
      oldAdGroupData: [],
      adGroupMetadata: [],
      minRoasChecked: false,
      lifetimeBudgetChecked: false,
      bidType: 'null',
      minRoasValue: null,
      dailyBudgetValue: null,
      lifetimeBudgetValue: null,
      selectedCampaignBidType: '',
      optimizedBiddingGoals: ['acquire_ntb', 'max_sales'],
      config: null,
      disableMinRoas: false,
      tooltipErrorMessage: '',
      disabledErrorMessage: ''
    };
  },
  computed: {
    bidTypeOptions() {
      return bidSettingsFilter(this.selectedCampaignBidType);
    },
    isOptimizedBidType() {
      return this.optimizedBiddingGoals.includes(this.bidType);
    },
    isManualSelected() {
      return this.bidType === 'manual';
    },
    budgetType() {
      return this.selections[0]?.data?.budget_type;
    },
    updatedGroupData() {
      let result = false;
      for (let i = 0; i < this.adGroupsData?.length; i++) {
        if (
          +this.adGroupsData[i]?.default_bid !==
          this.oldAdGroupData[i]?.default_bid
        ) {
          result = true;
        }
      }
      return result;
    },
    checkMinBidValue() {
      let result = false;
      for (let i = 0; i < this.adGroupsData?.length; i++) {
        if (+this.adGroupsData[i]?.default_bid <= 0.15) {
          result = true;
        }
      }
      return result;
    },
    // disabledManual() {
    //   if (this.adgroupTableData.rows.length === 0 ) {
    //     this.disabledErrorMessage = "Ad Group is mandatory";
    //     return true;
    //   }
    //   else{
    //     this.disabledErrorMessage = "";
    //     return false;
    //   }
    // },
    applyButtonDisabled() {
      if (!this.bidType) {
        return true;
      }
      if (
        this.bidType === 'acquire_ntb' &&
        this.selections[0]?.data?.optimized_bidding_enabled &&
        this.minRoasChecked &&
        this.minRoasValue < 0.1
      ) {
        this.tooltipErrorMessage =
          'ROAS should be more than 0.1 for Boost New to Brand';
        return true;
      }
      if (
        this.isOptimizedBidType &&
        this.selections[0]?.data?.optimized_bidding_enabled &&
        this.minRoasChecked &&
        this.minRoasValue === this.selections[0]?.data?.roas_constraint
      ) {
        this.tooltipErrorMessage = 'You had not changed Set Target ROAS';
        return true;
      }
      if (
        String(this.budgetType) === 'daily_budget' &&
        this.isOptimizedBidType &&
        this.minRoasChecked &&
        !this.minRoasValue
      ) {
        this.tooltipErrorMessage = 'Add Set Target ROAS';
        return true;
      }
      if (
        String(this.budgetType) === 'max_impressions' &&
        this.isOptimizedBidType
      ) {
        return false;
      }
      if (
        String(this.budgetType) === 'daily_budget' &&
        this.isOptimizedBidType &&
        (!this.dailyBudgetValue || this.dailyBudgetValue < 20)
      ) {
        if (!this.dailyBudgetValue) {
          this.tooltipErrorMessage = 'Daily budget is required';
        } else {
          this.tooltipErrorMessage = 'Daily budget cannot be less than 20$';
        }
        return true;
      }
      if (
        String(this.budgetType) === 'daily_budget' &&
        this.isOptimizedBidType &&
        this.dailyBudgetValue &&
        this.minRoasChecked &&
        !this.minRoasValue
      ) {
        this.tooltipErrorMessage = 'Add Target ROAS';
        return true;
      }
      if (
        this.budgetType === 'max_impressions' &&
        this.isOptimizedBidType &&
        this.dailyBudgetValue &&
        this.lifetimeBudgetChecked &&
        !this.lifetimeBudgetValue
      ) {
        this.tooltipErrorMessage = 'Add Lifetime budget';
        return true;
      }
      if (
        this.budgetType === 'max_impressions' &&
        this.isOptimizedBidType &&
        this.dailyBudgetValue &&
        this.lifetimeBudgetChecked &&
        +this.lifetimeBudgetValue <= +this.dailyBudgetValue
      ) {
        this.tooltipErrorMessage =
          'Lifetime budget should be more than the Daily budget';
        return true;
      }
      if (
        this.budgetType === 'max_impressions' &&
        this.isOptimizedBidType &&
        ~~this.dailyBudgetValue < 20
      ) {
        this.tooltipErrorMessage = 'Daily budget should be minimum 20 dollars';
        return true;
      }
      if (this.isManualSelected && this.checkMinBidValue) {
        this.tooltipErrorMessage = 'Min Bid value should be greater than $0.15';
        return true;
      }
      return false;
    },
    adgroupTableData() {
      return {
        rows: this.adGroupsData,
        columns: this.adGroupMetadata
      };
    }
  },
  watch: {
    minRoasChecked(val) {
      if (val === false) {
        this.minRoasValue = null;
      }
    },
    selections(val) {
      commons.scrollPage(0);
      this.lifetimeBudgetValue = this.selections[0]?.data?.budget;
      if (val.length === 0) {
        this.$emit('close');
      } else if (val.length === 1) {
        this.selectedCampaignBidType =
          this.selections[0]?.data?.optimized_bidding_goal;
        this.bidType = this.selectedCampaignBidType;
        if (
          this.selections[0]?.data?.budget_type === 'daily_budget' &&
          this.selections[0]?.data?.optimized_bidding_enabled &&
          ~~this.selections[0]?.data?.target_daily_budget < 20
        ) {
          this.bidType = 'manual';
          // this.disableMinRoas = true;
          this.minRoasValue = null;
        } else if (
          this.selections[0]?.data?.budget_type === 'max_impressions' &&
          this.selections[0]?.data?.optimized_bidding_enabled
        ) {
          this.bidType = 'manual';
        } else if (this.selections[0]?.data?.budget_type === 'daily_budget') {
          this.showDailyBudget = true;
        }
        if (this.isOptimizedBidType) {
          this.disableManual = false;
          this.minRoasChecked = true;
          this.disableMinRoas = false;
          this.minRoasValue = this.selections[0]?.data?.roas_constraint;
        } else {
          this.bidType = 'manual';
          this.getAdGroupsData();
          this.disableManual = true;
          this.minRoasValue = this.selections[0]?.data?.roas_constraint;
        }
      }
    },
    bidType(val) {
      if (this.optimizedBiddingGoals.includes(val)) {
        this.showDailyBudget = true;
        if (this.selections[0]?.data?.optimized_bidding_enabled) {
          this.minRoasChecked = true;
        }
      } else if (this.isManualSelected) {
        this.minRoasValue = null;
        this.dailyBudgetValue = this.selections[0]?.data?.target_daily_budget;
        this.minRoasChecked = false;
        this.getAdGroupsData();
      }
    }
  },
  created() {
    commons.scrollPage(0);
    this.selectedCampaignBidType =
      this.selections[0]?.data?.optimized_bidding_goal;
    this.bidType = this.selectedCampaignBidType;
    this.lifetimeBudgetValue = this.selections[0]?.data?.budget;
    this.config = config;
    this.adGroupMetadata = this.config.adGroupColumnMetadata(this);
    if (
      this.selections[0]?.data?.budget_type === 'daily_budget' &&
      this.selections[0]?.data?.target_daily_budget < 20
    ) {
      this.bidType = 'manual';
      this.minRoasValue = null;
    } else if (this.selections[0]?.data?.budget_type === 'max_impressions') {
      this.bidType = 'manual';
    } else {
      this.showDailyBudget = true;
    }
    if (this.isOptimizedBidType) {
      this.disableManual = false;
      this.minRoasChecked = true;
      this.disableMinRoas = false;
      this.minRoasValue = this.selections[0]?.data?.roas_constraint;
    } else {
      this.bidType = 'manual';
      this.disableManual = false;
      this.minRoasValue = this.selections[0]?.data?.roas_constraint;
    }
  },
  methods: {
    addSuggestedBid(context) {
      if (!context.params.data.suggested_bid) return;
      let id = context.params.data.ad_group_id;
      this.adGroupsData = this.adGroupsData.map((item) => {
        if (item.ad_group_id === id) {
          item.default_bid = context.params.data.suggested_bid;
        }
        return item;
      });
      this.rbTableSourceInstance.redrawTableRows();
    },

    addAllSuggestedBids(context) {
      this.adGroupsData = this.adGroupsData.map((item) => {
        if (item.suggested_bid) {
          item.default_bid = item.suggested_bid;
        }
        return item;
      });
      this.rbTableSourceInstance.redrawTableRows();
    },

    getCurrentSourceInstance(context) {
      this.rbTableSourceInstance = context;
    },
    getAdGroupsData() {
      this.config.reqBody.where.dimensionNameValueList[0].dimensionValue =
        this.selections[0]?.data?.campaign_id;
      HttpService.post('EXECUTE_CUBE_API', this.config.reqBody)
        .then((response) => {
          this.adGroupsData = [
            ...transformer.mergeResultDimension(response.data.response.data)
          ];
          this.oldAdGroupData = [
            ...transformer.mergeResultDimension(response.data.response.data)
          ];
        })
        .catch((error) => console.log(error));
    },

    getAdGroupDataForViewPayload() {
      this.adGroupDataForViewPayload = [];
      if (this.isOptimizedBidType) return;
      for (let i = 0; i < this.adGroupsData?.length; i++) {
        if (
          +this.adGroupsData[i]?.default_bid !==
          this.oldAdGroupData[i]?.default_bid
        ) {
          this.adGroupDataForViewPayload.push({
            adgroupId: this.adGroupsData[i].ad_group_id,
            adgroupName: this.adGroupsData[i].ad_group_name,
            oldBidValue: this.oldAdGroupData[i]?.default_bid,
            newBidValue: +this.adGroupsData[i]?.default_bid
          });
        }
      }
    },
    createPayloadRequest() {
      const payload = [];
      const selectedRow = this.selections[0].data;
      const biddingType = this.isOptimizedBidType;
      let budgetTypeValue = selectedRow?.budget_type;
      if (budgetTypeValue === 'max_impressions') {
        budgetTypeValue = 'daily_budget';
      }
      let lifetimeBudgetForViewPayload = this.lifetimeBudgetValue
        ? +this.lifetimeBudgetValue
        : null;
      if (biddingType) {
        payload.push({
          actionPayload: {
            campaignId: selectedRow?.campaign_id,
            campaignType: selectedRow?.campaign_type,
            newBudgetType: budgetTypeValue,
            profileId: 0,
            newOptimizedBiddingEnabled: biddingType,
            newBudget: this.dailyBudgetValue ? +this.dailyBudgetValue : null,
            newLifetimeBudget: this.lifetimeBudgetValue
              ? +this.lifetimeBudgetValue
              : null,
            previousLifetimeBudget: selectedRow?.budget,
            newRoasConstraint: this.minRoasValue ? +this.minRoasValue : null,
            newOptimizedBiddingGoal: this.bidType
          },
          viewPayload: {
            newOptimizedBiddingEnabled:
              biddingType !== selectedRow?.optimized_bidding_enabled
                ? biddingType
                  ? 'Optimized'
                  : 'Manual'
                : null,
            oldOptimizedBiddingEnabled:
              biddingType !== selectedRow?.optimized_bidding_enabled
                ? selectedRow?.optimized_bidding_enabled
                  ? 'Optimized'
                  : 'Manual'
                : null,
            campaign_name: selectedRow?.campaign_name,
            campaign_id: selectedRow?.campaign_id,
            newBudgetType: 'Daily Budget',
            oldBudgetType:
              budgetTypeValue !== selectedRow?.budget_type
                ? selectedRow?.budget_type === 'daily_budget'
                  ? 'Daily Budget'
                  : 'Max Impressions'
                : null,
            daily_budget: this.dailyBudgetValue ? +this.dailyBudgetValue : null,
            old_lifetime_budget:
              selectedRow?.budget !== lifetimeBudgetForViewPayload
                ? selectedRow?.budget
                : null,
            lifetime_budget:
              selectedRow?.budget !== lifetimeBudgetForViewPayload
                ? lifetimeBudgetForViewPayload
                : null,
            roas_constraint: this.minRoasValue ? +this.minRoasValue : null,
            actionType: this.actionType,
            ad_group_name: null,
            new_bid: null,
            previous_bid: null
          },
          actionType: this.actionType,
          actionSource: {
            pageUrl: window.location.href
          }
        });
      } else {
        budgetTypeValue = 'daily_budget';
        payload.push({
          actionPayload: {
            campaignId: selectedRow?.campaign_id,
            campaignType: selectedRow?.campaign_type,
            newBudgetType: budgetTypeValue,
            profileId: 0,
            newOptimizedBiddingEnabled: biddingType,
            newBudget: this.dailyBudgetValue ? +this.dailyBudgetValue : null,
            newLifetimeBudget: this.lifetimeBudgetValue
              ? +this.lifetimeBudgetValue
              : null,
            previousLifetimeBudget: selectedRow?.budget,
            newRoasConstraint: this.minRoasValue ? +this.minRoasValue : null,
            newOptimizedBiddingGoal: biddingType ? this.bidType : null
          },
          actionType: this.actionType,
          actionSource: {
            pageUrl: window.location.href
          },
          viewPayload: {
            newOptimizedBiddingEnabled:
              biddingType !== selectedRow?.optimized_bidding_enabled
                ? biddingType
                  ? 'Optimized'
                  : 'Manual'
                : null,
            oldOptimizedBiddingEnabled:
              biddingType !== selectedRow?.optimized_bidding_enabled
                ? selectedRow?.optimized_bidding_enabled
                  ? 'Optimized'
                  : 'Manual'
                : null
          }
        });
      }
      return payload;
    },
    createPayloadRequestForManual() {
      const payload = [];
      const selectedRow = this.selections[0].data;
      for (const data of this.adGroupDataForViewPayload) {
        payload.push({
          actionPayload: {
            campaignId: selectedRow?.campaign_id,
            profileId: 0,
            campaignType: selectedRow?.campaign_type,
            newOptimizedBiddingEnabled: false,
            adgroupId: data?.adgroupId,
            newBid: data?.newBidValue,
            previousBid: data?.oldBidValue
          },
          viewPayload: {
            campaignId: selectedRow?.campaign_id,
            campaign_name: selectedRow?.campaign_name,
            newBudgetType: 'Daily Budget',
            campaignType: selectedRow?.campaign_type,
            newOptimizedBiddingEnabled: false,
            adgroupName: data?.adgroupName,
            newBid: data?.newBidValue,
            previousBid: data?.oldBidValue
          },
          actionType: 'instacartCampaignAdgroupBidChange',
          actionSource: {
            pageUrl: window.location.href
          }
        });
      }
      return payload;
    },

    onApply() {
      const requestPayload = this.createPayloadRequest();
      var that = this;
      that.loader = true;
      const othersObj = {
        loader: that.loader,
        successMessage: 'Action has been submitted',
        errorMessage: 'Something went wrong.',
        successFn: () => {},
        errorFn: () => {},
        finallyFn: () => {
          that.loader = false;
          this.$emit('afterAPIActionToCall');
        }
      };
      commons.bidChangeApiAction.call(
        this,
        that.selections,
        requestPayload,
        othersObj
      );
      if (this.isManualSelected && this.adgroupTableData.rows.length > 0) {
        this.onApplyForManual();
      }
    },
    onApplyForManual() {
      this.getAdGroupDataForViewPayload();
      const requestPayload = this.createPayloadRequestForManual();
      var that = this;
      that.loader = true;
      const othersObj = {
        loader: that.loader,
        successMessage: 'Action has been submitted',
        errorMessage: 'Something went wrong.',
        successFn: () => {},
        errorFn: () => {},
        finallyFn: () => {
          that.loader = false;
          this.$emit('afterAPIActionToCall');
        }
      };
      commons.bidChangeApiAction.call(
        this,
        that.selections,
        requestPayload,
        othersObj
      );
    },
    onClose() {
      this.$emit('close');
    },
    handleBidTypeChange(newValue) {
      this.bidType = newValue;
    }
  }
};
</script>
