import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import { cloneDeep } from 'lodash';
import { formatOperatorWhereClause } from '@/utils/helpers/utils';

const state = {
  insightsPOFillFilterValues: {},
  poFillRateFilters: [],
  insightsPOFillRateData: {},
  insightsPOFillRateSelectedFilter: {
    date_range: {
      name: 'Last 90 days'
    }
  },
  insightsPOFillRateWhereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  filtersMapping: {
    po_category: 'category',
    po_subcategory: 'subcategory',
    po_product_group: 'product_group',
    po_brand: 'brand'
  },
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  page_wise_min_max_key: 'po_fill_rate'
};

const getters = {
  getInsightsPOFillFilterValues: (state) => {
    return state.insightsPOFillFilterValues;
  },
  getInsightsPOFillRateSelectedFilter: (state) => {
    return state.insightsPOFillRateSelectedFilter;
  },
  getInsightsPOFillRateData: (state) => {
    return state.insightsPOFillRateData;
  },
  getPoFillPageWiseMinMaxKey: (state) => {
    return state.page_wise_min_max_key;
  }
};

const mutations = {
  IPOFILLRATE_SUCCESS: (state, data) => {
    Vue.set(state.insightsPOFillRateData, data.key, {});
    if (data.xs || data.legends) {
      Vue.set(state.insightsPOFillRateData[data.key], 'data', {
        data: data.columns,
        xs: data.xs,
        legends: data.legends
      });
    } else {
      Vue.set(state.insightsPOFillRateData[data.key], 'data', data.columns);
    }
    Vue.set(state.insightsPOFillRateData[data.key], 'rows', data.rows);
    Vue.set(
      state.insightsPOFillRateData[data.key],
      'totalRows',
      data.totalRows
    );
    Vue.set(state.insightsPOFillRateData[data.key], 'metrics', data.metrics);
    Vue.set(state.insightsPOFillRateData[data.key], 'load', false);
    Vue.set(state.insightsPOFillRateData[data.key], 'page', data.page);
    Vue.set(state.insightsPOFillRateData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.insightsPOFillRateData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.insightsPOFillRateData[data.key], 'noData', true);
    }
  },
  IPOFILLRATE_RESET: (state, data) => {
    Vue.set(state.insightsPOFillRateData, data.key, {});
    Vue.set(state.insightsPOFillRateData[data.key], 'columns', []);
    Vue.set(state.insightsPOFillRateData[data.key], 'rows', []);
    Vue.set(state.insightsPOFillRateData[data.key], 'load', true);
    Vue.set(state.insightsPOFillRateData[data.key], 'error', false);
    Vue.set(state.insightsPOFillRateData[data.key], 'noData', false);
  },
  IPOFILLRATE_ERROR: (state, data) => {
    Vue.set(state.insightsPOFillRateData, data.key, {});
    Vue.set(state.insightsPOFillRateData[data.key], 'columns', []);
    Vue.set(state.insightsPOFillRateData[data.key], 'rows', []);
    Vue.set(state.insightsPOFillRateData[data.key], 'load', false);
    Vue.set(state.insightsPOFillRateData[data.key], 'error', true);
    Vue.set(state.insightsPOFillRateData[data.key], 'noData', false);
  },
  IPOFILLRATE_SET_SELECTED_FILTER: (state, data) => {
    Vue.set(
      state.insightsPOFillRateSelectedFilter,
      data.filterValueKey,
      data.values
    );
    getFiltersInFormat5(data, state);
  },
  SET_PO_FILTER_VALUES: (state, data) => {
    Vue.set(state.insightsPOFillFilterValues, data.filterValueKey, data.values);
  }
};

function getFiltersInFormat5(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      for (var j = 0; j < oFilter.values[i].length; j++) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][j]
        });
      }
    } else {
      var date = oFilter.values[i];
      var _date = {
        from: date.from,
        to: date.to,
        name: date.name,
        page_wise_min_max_key: state.page_wise_min_max_key
      };
      var _pvpDate;
      if (date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to,
          compare_name: date.compare_name
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  state.whereClause = where;
}

const actions = {
  setInsightsPOFillRateSelectedFilter: (context, data) => {
    context.commit('IPOFILLRATE_SET_SELECTED_FILTER', data);
  },
  updatePOFillRateSelectedFilter: (context, data) => {
    setTimeout(function () {
      var filters = transformer.getUniqueFilters(
        context.state.poFilters || [],
        'po_',
        data.mapping
      );
      context.commit('SET_FILTER_DATA', filters);
    }, 1500);
  },
  downloadPODetailsTableData: (context, data) => {
    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      formatOperatorWhereClause(cloneDeep(context.state.whereClause))
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = context.state.whereClause.date; // getPoFillRateWhereClauseDate(context);
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    delete data.body.APIConfig.where.tagWhereClause;
    return HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      return transformer.mergeResultDimension(response.data);
    });
  },
  getPODetailsTableData: (context, data) => {
    context.commit('IPOFILLRATE_RESET', {
      key: data.meta.key
    });
    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      formatOperatorWhereClause(cloneDeep(context.state.whereClause))
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = context.state.whereClause.date; // getPoFillRateWhereClauseDate(context);
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    delete data.body.APIConfig.where.tagWhereClause;
    for (
      var i = 0;
      i < data.body.APIConfig.where.dimensionNameValueList.length;
      i++
    ) {
      if (
        data.body.APIConfig.where.dimensionNameValueList[i].dimensionName ===
        'search'
      ) {
        data.body.APIConfig.where.dimensionNameValueList.splice(i, 1);
      }
    }

    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('IPOFILLRATE_ERROR', { key: data.meta.key });
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data, true);
        if (_aArray.length > 0) {
          context.commit('IPOFILLRATE_SUCCESS', {
            key: data.meta.key,
            rows: _aArray,
            totalRows: _aArray[0].count_total,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('IPOFILLRATE_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },
  downloadSKULevelSummaryData: (context, data) => {
    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      formatOperatorWhereClause(cloneDeep(context.state.whereClause))
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = context.state.whereClause.date; // getPoFillRateWhereClauseDate(context);
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    delete data.body.APIConfig.where.tagWhereClause;
    return HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      return transformer.mergeResultDimension(response.data);
    });
  },
  getSKULevelSummaryData: (context, data) => {
    context.commit('IPOFILLRATE_RESET', {
      key: data.meta.key
    });
    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      formatOperatorWhereClause(cloneDeep(context.state.whereClause))
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = context.state.whereClause.date; // getPoFillRateWhereClauseDate(context);
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    delete data.body.APIConfig.where.tagWhereClause;
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('IPOFILLRATE_ERROR', { key: data.meta.key });
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data, true);
        if (_aArray.length > 0) {
          context.commit('IPOFILLRATE_SUCCESS', {
            key: data.meta.key,
            rows: _aArray,
            totalRows: _aArray[0].count_total,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('IPOFILLRATE_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },
  getSKUsData: (context, data) => {
    context.commit('IPOFILLRATE_RESET', {
      key: data.meta.key
    });
    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      formatOperatorWhereClause(cloneDeep(context.state.whereClause))
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = context.state.whereClause.date; // getPoFillRateWhereClauseDate(context);
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    delete data.body.APIConfig.where.tagWhereClause;
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('IPOFILLRATE_ERROR', { key: data.meta.key });
      } else {
        var _aArray = [];
        response.data = transformer.mergeResultDimension(response.data, true);
        if (
          response.data.length > 0 &&
          response.data[0].ordered_units !== undefined &&
          response.data[0].fulfilled_units !== undefined
        ) {
          _aArray.push({
            text: 'SKUs ordered',
            units: response.data[0].ordered_units || 0,
            perc: 1
          });

          _aArray.push({
            text: 'SKUs fulfilled',
            units: response.data[0].fulfilled_units || 0,
            perc:
              response.data[0].fulfilled_units / response.data[0].ordered_units
          });
        }
        context.commit('IPOFILLRATE_SUCCESS', {
          key: data.meta.key,
          columns: _aArray,
          page: data.body.APIConfig.page
        });
      }
    });
  },
  getUnfulfilledRevenueData: (context, data) => {
    context.commit('IPOFILLRATE_RESET', {
      key: data.meta.key
    });
    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      formatOperatorWhereClause(cloneDeep(context.state.whereClause))
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = context.state.whereClause.date; // getPoFillRateWhereClauseDate(context);
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate;
    delete data.body.APIConfig.where.tagWhereClause;
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('IPOFILLRATE_ERROR', { key: data.meta.key });
      } else {
        if (response.data.length > 0) {
          context.commit('IPOFILLRATE_SUCCESS', {
            key: data.meta.key,
            rows: response.data,
            metrics: data.body.getTagData
              ? data.body.getTagData(response.data)
              : undefined,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('IPOFILLRATE_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },
  getPOFillRateData: (context, data) => {
    context.commit('IPOFILLRATE_RESET', {
      key: data.meta.key
    });
    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      formatOperatorWhereClause(cloneDeep(context.state.whereClause))
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = context.state.whereClause.date; // getPoFillRateWhereClauseDate(context);
    data.body.APIConfig.where.pvpDate = context.state.whereClause.pvpDate; // getPoFillRateWhereClauseDate(context);
    delete data.body.APIConfig.where.tagWhereClause;
    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('IPOFILLRATE_ERROR', { key: data.meta.key });
      } else {
        if (response.data.length > 0) {
          context.commit('IPOFILLRATE_SUCCESS', {
            key: data.meta.key,
            rows: response.data,
            metrics: data.body.getTagData
              ? data.body.getTagData(response.data)
              : undefined,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('IPOFILLRATE_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },
  getAsins: (context, data) => {
    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      formatOperatorWhereClause(cloneDeep(context.state.whereClause))
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = context.state.whereClause.date; // getPoFillRateWhereClauseDate(context);
    delete data.body.APIConfig.where.tagWhereClause;
    for (
      var i = 0;
      i < data.body.APIConfig.where.dimensionNameValueList.length;
      i++
    ) {
      if (
        data.body.APIConfig.where.dimensionNameValueList[i].dimensionName ===
        'asin'
      ) {
        data.body.APIConfig.where.dimensionNameValueList.splice(i, 1);
      }
    }
    if (data.meta.from !== 'search') {
      context.commit('SET_PO_FILTER_VALUES', {
        filterValueKey: 'asin',
        values: []
      });
    }

    HttpLayer.post({
      APIData: {
        cubeName: 'po_fill_rate_v2',
        measuresList: ['title', 'image_url'],
        groupByDimensionsList: ['asin'],
        timeseriesDimension: 'ordered_on',
        where: data.body.APIConfig.where,
        timeseriesEnabled: false,
        pvpenabled: false,
        yoyenabled: false,
        orderByList: [],
        limit: 50,
        page: 1
      }
    })
      .then((response) => {
        if (!response.success) {
          context.commit('IPOFILLRATE_ERROR', { key: data.meta.key });
        } else {
          var _aArray = [];
          if (response.success && response.data.length > 0) {
            _aArray = transformer.mergeResultDimension(response.data);
          }

          context.commit('SET_PO_FILTER_VALUES', {
            filterValueKey: 'asin',
            values: _aArray
          });

          if (_aArray.length === 0 && data.meta.from !== 'search') {
            context.commit('IPOFILLRATE_SUCCESS', {
              key: data.meta.key,
              rows: []
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        context.commit('IPOFILLRATE_ERROR', { key: data.meta.key });
      });
  },
  getPOFillRateFilters: (context, data) => {
    HttpLayer.post({
      APIData: {
        cubeName: 'po_fill_rate_v2',
        measuresList: [],
        groupByDimensionsList: [
          'category',
          'subcategory',
          'product_group',
          'brand'
        ],
        timeseriesDimension: 'ordered_on',
        where: {
          date: {},
          dimensionNameValueList: []
        },
        orderByList: [],
        getLatestAvailableInsteadOfRollup: true,
        timeseriesEnabled: false,
        pvpenabled: false,
        yoyenabled: false
      }
    }).then((response) => {
      if (!response.success) {
      } else {
        context.state.poFilters = response.data;
        context.commit(
          'SET_FILTER_DATA',
          transformer.getUniqueFilters(response.data, 'po_')
        );
      }
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
