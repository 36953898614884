<template>
  <div class="createStratgey u-position-fixed">
    <div
      v-if="stage.load || stage.noData || stage.error"
      class="u-position-relative u-flex-1 u-display-flex u-flex-direction-column u-position-relative createStratgey"
    >
      <loader
        v-if="stage.load"
        class="fill--parent"
        :loading="stage.load"
        :color="'#007cf6'"
      />
      <div
        v-if="stage.noData || !stage"
        class="fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter line-height-1_5"
      />
      <div
        v-if="stage.error"
        class="fill--parent u-display-flex u-flex-justify-content-center u-flex-align-items-center u-font-size-5 u-spacing-p-m u-text-align-center u-color-grey-lighter"
      >
        <p>Error Occurred. Please try again later</p>
      </div>
    </div>
    <div
      v-if="!stage.load && !stage.error"
      class="u-position-fixed modal createStratgey"
    >
      <div
        class="createStratgey--header u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-p-l"
      >
        <div
          class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-width-80"
        >
          <div>
            <span @click="navigateToStrategies()">
              <rb-icon
                :icon="'cross'"
                class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-mr-s"
              />
            </span>
            <input
              v-model="strategy.header.title"
              type="text"
              maxlength="56"
              :class="{
                'active-state': isTitleInputClicked,
                ' u-color-grey-lighter': !isTitleInputClicked
              }"
              onClick="this.select();"
              class="heading--input u-font-size-2 u-font-weight-bold"
              style="width: 100%"
              onkeypress="this.style.width = ((this.value.length + 1) * 16) + 'px';"
              @click="titleInputClicked()"
            />
            <rb-icon
              v-if="strategyNameDuplicate"
              v-tippy="tippy"
              :icon="'error-fill'"
              class="u-color-red-base rb-icon--small u-spacing-mr-xs u-cursor-pointer"
              :title="'Strategy name already exist'"
            />
          </div>
          <input
            v-model="strategy.header.desc"
            type="text"
            maxlength="160"
            :placeholder="strategy.header.placeholder"
            class="heading--input strategy-desc u-font-size-6 u-spacing-pt-s u-spacing-ml-l"
          />
        </div>
        <div class="u-font-size-7 u-text-align-right">
          <div v-if="submitLoading">
            <div class="mini-spinner">
              <div class="bounce1" />
              <div class="bounce2" />
              <div class="bounce3" />
            </div>
          </div>
          <rb-button
            v-show="!submitLoading"
            id="confirmButton"
            :text="'Confirm & Save'"
            :type="'filled'"
            :disabled="headerNextDisabled"
            :click-fn="strategyNextStep"
          />
        </div>
      </div>
      <div class="scrollable">
        <div
          class="createStratgey--body u-display-flex u-spacing-mt-xl u-flex-justify-content-center u-width-100"
        >
          <div
            class="u-width-80"
            style="padding-bottom: 400px"
          >
            <div
              v-for="(step, index) in steps"
              :key="index"
              class="step-class--pseudo"
            >
              <component
                :is="step.component"
                :all-steps-data="steps"
                :index="index"
                :step="step"
                :mode="mode"
                :action-status="actionStatus"
                @nextStep="nextStepStatus"
                @stepDataOut="setStepData"
                @loadNextButton="loadNextButton"
              />
            </div>
            <div
              v-if="!actionStatus.passStatus"
              class="u-display-flex u-flex-align-items-center u-spacing-mt-m"
            >
              <rb-icon
                :icon="'warning'"
                class="u-color-blue-base rb-icon--small u-cursor-pointer u-spacing-mr-xs"
              />
              <span class="u-font-size-6 u-color-blue-base">{{
                actionStatus.message
              }}</span>
            </div>
            <div>
              <span
                class="u-display-flex u-flex-align-items-center u-spacing-mt-l"
              >
                <div v-if="submitLoading">
                  <div class="mini-spinner">
                    <div class="bounce1" />
                    <div class="bounce2" />
                    <div class="bounce3" />
                  </div>
                </div>
                <rb-button
                  v-else
                  :text="nextStepMessage"
                  :type="'filled'"
                  :size="'s'"
                  :disabled="nextDisabled"
                  :click-fn="strategyNextStep"
                />
                <span @click="navigateToStrategies()">
                  <rb-button
                    class="u-spacing-ml-s"
                    :text="'Cancel'"
                    :type="'hollow'"
                    :size="'s'"
                  />
                </span>
              </span>
            </div>
            <div class="u-spacing-mt-l u-display-flex u-flex-direction-column">
              <span v-if="strategyNameDuplicate">
                <error-message :error-message="'Strategy name already exist'" />
              </span>
              <span
                v-if="errorList.length > 0"
                class="u-spacing-mt-s"
              >
                <div v-for="error in errorList">
                  <error-message :error-message="error" />
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import step1 from '@/components/pages/strategies/create/templates/step1.vue';
import step2 from '@/components/pages/strategies/create/templates/step2.vue';
import step3 from '@/components/pages/strategies/create/templates/step3.vue';
import step4 from '@/components/pages/strategies/create/templates/step4.vue';
import actionScheduler from '@/components/pages/strategies/create/templates/actionScheduler.vue';
import config from '@/components/pages/strategies/create/config.js';
import moment from 'moment-timezone';
import transformer from '@/utils/services/data-transformer';
import loader from '@/components/basic/loader';
import Vue from 'vue';
import errorMessage from './templates/errorMessage.vue';
import { isLineItemRetailer } from '@/components/pages/strategies/create/utils.js';
export default {
  name: 'BoomerangCreateStrategy',
  description: 'Create strategy',
  owner: 'Nikhil Talwar',
  components: {
    step1,
    step2,
    step3,
    step4,
    actionScheduler,
    errorMessage,
    loader
  },
  data() {
    return {
      config: {},
      currentStep: 0,
      nextDisabled: true,
      validationList: [],
      headerNextDisabled: true,
      formFilled: false,
      submitLoading: false,
      strategyNameDuplicate: false,
      overLappingErrorMessage: '',
      stage: {
        load: false
      },
      timeZoneOptions: [],
      editAPIResponse: {},
      mode: 'create',
      indexMapping: null,
      strategyStatus: 'LIVE',
      isTitleInputClicked: false,
      tippy: {
        placement: 'right',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }
      },
      actionStatus: {
        start: {
          status: true,
          actions: []
        },
        end: {
          status: true,
          actions: []
        },
        passStatus: true
      },
      showedUp: false,
      errorList: []
    };
  },
  computed: {
    strategy() {
      return (this.config || {}).strategy;
    },
    steps() {
      return this.strategy.steps.slice(0, this.currentStep + 1);
    },
    nextStepMessage() {
      if (this.strategy.steps.length > this.currentStep + 1) {
        return 'Continue';
      } else {
        if (this.showedUp) {
          return 'Yes, I confirm';
        }
        return 'Confirm & Save';
      }
    }
  },
  created() {
    const pathArray = this.$route.path.split('/');
    this.mode = pathArray[pathArray.length - 1];
    this.strategyMode(this.mode);
  },
  mounted() {
    this.tippyInstanceUpdate();
    if (this.mode === 'create') {
      const stepNodes = document.querySelectorAll('.step-class--pseudo');
      const lastNode = stepNodes[stepNodes.length - 1];
      lastNode.scrollIntoView();
    }
  },
  methods: {
    titleInputClicked() {
      this.isTitleInputClicked = true;
    },
    strategyMode(mode) {
      const retailer = this.$store.getters.getRetailer || 'amazon';
      this.config = config[retailer].config();
      this.indexMapping = this.strategy.indexMapping;
      switch (mode) {
        case 'create':
          this.config = this.fillConfig(config[retailer].config());
          this.defaultValidationSet();
          break;
        case 'edit':
          this.config.steps = this.getStrategyData();
          this.defaultValidationSet();
          break;
      }
    },
    getStrategyData() {
      this.stage.load = true;
      const that = this;
      const id = this.$route.query.strategyUniqueId;
      if (!id) {
        this.$router.push({
          name: 'strategies'
        });
      }
      for (const element of this.strategy.steps) {
        element.strategyUniqueId = id;
      }
      this.$store
        .dispatch('getStrategyData', id)
        .then((data) => {
          that.editAPIResponse = data.data;
          that.formatDataForEdit(data.data);
          that.$set(that.stage, 'load', false);
        })
        .catch((err) => {
          if (err.status) {
            this.$snackbar.open({
              message: err.message,
              duration: 10000,
              buttonColor: '#f5d908',
              actionText: '              '
            });
            this.$router.push({
              name: 'strategies'
            });
          }
        });
    },
    formatDataForEdit(data) {
      const steps = this.config.strategy.steps;
      const { strategyScheduler, rule, action } = this.indexMapping;
      this.strategyStatus = data.strategyStatus;
      // ----- Main Head <Start> ------
      this.strategy.header.title = decodeURIComponent(data.strategyName);
      this.strategy.header.desc = decodeURIComponent(data.description);
      // ----- Main Head <Stop> ------
      // ----- Strategy Scheduler <Start>-----
      const momentStartDate = moment(data.strategyStartDate)
        .tz(data.timezone)
        .format('YYYY-MM-DD HH:mm:ss');
      const momentEndDate = moment(data.strategyEndDate)
        .tz(data.timezone)
        .format('YYYY-MM-DD HH:mm:ss');
      console.log(momentStartDate, momentEndDate);
      // console.log(new Date(momentStartDate), new Date(momentEndDate))
      steps[strategyScheduler].data = {};
      steps[strategyScheduler].data.mode = 'edit';
      steps[strategyScheduler].data.startDate = new Date(momentStartDate);
      steps[strategyScheduler].data.endDate = new Date(momentEndDate);
      steps[strategyScheduler].data.timezone = {};
      steps[strategyScheduler].data.timezone.value = data.timezone;
      // ----- Strategy Scheduler <End>-----
      // ----- RULE <Start> -----
      steps[rule].data = {};
      steps[rule].data.mode = 'edit';
      steps[rule].data.load = true;
      steps[rule].data.targetList = this.formatargetList(data.entities);
      steps[rule].data.filters = transformer.convertDimensionsToFilterFormat(
        data.rules
      );
      // ----- RULE <End> -----
      // ----- Action <Start> -----
      steps[action].data = {};
      steps[action].data.mode = 'edit';
      steps[action].data.actions = [];
      steps[action].data.actions = this.createActions(data.actions);
      // ----- Action <End> -----
      this.currentStep = 3;
      return steps;
    },
    addMissingData(action) {
      action.actionType = this.config.strategy.editMapper[action.actionType];
      return action;
    },
    formatargetList(list) {
      const formatList = [];
      const isLineItem = isLineItemRetailer(this.$store.getters.getRetailer);

      for (const element of list) {
        const { metadata } = element;

        const obj = isLineItem
          ? {
              line_item_name: metadata.name,
              line_item_id: element.entityId,
              ...metadata
            }
          : {
              campaign_id: element.entityId,
              profile_id: metadata.profileId,
              campaign_type: metadata.campaignType,
              promoteiq_cm_campaign_placement_type: metadata.placementType,
              advertiser_id: metadata.advertiserId,
              campaign_name: metadata.name,
              ...metadata
            };

        formatList.push(obj);
      }

      return formatList;
    },
    getTimeStops(start, end) {
      let startTime = moment(start, 'HH:mm');
      let endTime = moment(end, 'HH:mm');
      if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day');
      }
      let timeStops = [];
      while (startTime.isBefore(endTime)) {
        timeStops.push(moment(startTime).format('HH:mm'));
        startTime.add(60, 'minutes');
      }
      return timeStops;
    },
    genrateTimeOptions() {
      const hoursFormatList = this.getTimeStops('00:00', '24:00');
      hoursFormatList.push('23:59');
      for (const hour of hoursFormatList) {
        const timeObj = {
          title: moment(hour, ['HH:mm']).format('hh:mm A'),
          value: hour
        };
        this.timeZoneOptions.push(timeObj);
      }
    },
    setTimeSelection(time) {
      const index = this.timeZoneOptions.findIndex((element) => {
        return element.value === time;
      });
      return this.timeZoneOptions[index];
    },
    setDefaultDateRange(passedDate, years) {
      const startDate = new Date();
      const defaultDate = new Date(passedDate);
      const toDate = new Date();
      toDate.setFullYear(startDate.getFullYear() + years || 1);
      startDate.setDate(startDate.getDate() - 1);
      const range = {
        fromDate: startDate,
        toDate: toDate,
        defaultDate: defaultDate
      };
      return range;
    },
    frequencyListGenerator(frequencyList) {
      const mainFrequencyList = JSON.parse(
        JSON.stringify(
          this.config.strategy.steps[this.indexMapping.action].frequencyList
        )
      );
      const activeDays = [];
      for (const frequency of frequencyList) {
        const index = mainFrequencyList.findIndex((element) => {
          return element.value === frequency;
        });
        mainFrequencyList[index].active = true;
        activeDays.push(mainFrequencyList[index]);
      }
      return { mainFrequencyList, activeDays };
    },
    createActions(actions) {
      this.genrateTimeOptions();
      const formatedActions = [];
      const step = this.config.strategy.steps[this.indexMapping.action];
      for (let action of actions) {
        this.addMissingData(action);
        const executionTemplate = action.executionTemplate;
        const schedule = action.schedule;
        const startDate = moment(schedule.fromDate)
          .tz(schedule.timezone)
          .format('YYYY-MM-DD HH:mm:ss');
        const endDate = moment(schedule.toDate)
          .tz(schedule.timezone)
          .format('YYYY-MM-DD HH:mm:ss');
        const actionObj = {
          selectedActionType: step.actionTypeOptions.find((element) => {
            return element.value === action.actionType;
          }),
          selectedOperation: {
            value: executionTemplate.operation,
            title: step.operationTypes[action.actionType]?.operation?.find(
              (op) => op.value.includes(executionTemplate.operation)
            )?.title
          },
          selectedMode: step.operationTypes[action.actionType].mode.find(
            (element) => {
              return element.value === executionTemplate.mode;
            }
          ),
          formatType: executionTemplate.mode,
          selectedStartTime: this.setTimeSelection(schedule.fromTime),
          selectedEndTime: this.setTimeSelection(schedule.toTime),
          frequencyList: this.frequencyListGenerator(schedule.frequency)
            .mainFrequencyList,
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          newValue: executionTemplate.value,
          actionId: action.actionId,
          deleted: action.deleted,
          activeDays: this.frequencyListGenerator(schedule.frequency)
            .activeDays,
          startDateRange: this.setDefaultDateRange(startDate),
          endDateRange: this.setDefaultDateRange(endDate, 10),
          startDateDisabled:
            moment(new Date(startDate)).diff(moment(new Date())) < 0,
          dateValidation: true,
          frequencyValidation: true,
          timeValidation: true,
          newValueValidation: true
        };
        formatedActions.push(actionObj);
      }
      return formatedActions;
    },
    fillConfig(config) {
      const steps = config.strategy.steps;
      const storeData = this.$store.getters.getdayPartingState;
      if (!storeData) {
        return config;
      }
      steps[this.indexMapping.rule].data = {};
      steps[this.indexMapping.rule].data.filters = storeData.filters;
      steps[this.indexMapping.rule].data.targetList = storeData.targetList;
      this.currentStep = 1;
      return config;
    },
    tippyInstanceUpdate() {
      const button = document.getElementById('confirmButton');
      this.tippyInstance = (button || {})._tippy;
    },
    strategyNextStep() {
      if (this.formFilled) {
        this.createAPIFormating();
      } else {
        this.currentStep++;
      }
    },
    checkActionDates() {
      const steps = this.config.strategy.steps;
      const actions = steps[this.indexMapping.action].data.actions;
      const minDate = moment(
        steps[this.indexMapping.strategyScheduler].data.startDate
      ).format('YYYY-MM-DD');
      const maxDate = moment(
        steps[this.indexMapping.strategyScheduler].data.endDate
      ).format('YYYY-MM-DD');
      const returnObj = {
        start: {
          status: true,
          actions: []
        },
        end: {
          status: true,
          actions: []
        },
        actions: [],
        passStatus: true
      };
      console.log(minDate, maxDate);
      for (let i = 0; i < actions.length; i++) {
        const actionStartDate = moment(actions[i].startDate).format(
          'YYYY-MM-DD'
        );
        const actionEndDate = moment(actions[i].endDate).format('YYYY-MM-DD');
        const startDiff = moment(minDate).diff(moment(actionStartDate));
        const endDiff = moment(actionEndDate).diff(moment(maxDate));
        console.log(startDiff);
        console.log(endDiff);
        if (startDiff > 0) {
          returnObj.start.status = false;
          returnObj.start.actions.push(i);
          returnObj.actions.push(i);
          returnObj.passStatus = false;
          console.log('Start is small');
        }
        if (endDiff > 0) {
          returnObj.end.status = false;
          returnObj.end.actions.push(i);
          returnObj.passStatus = false;
          returnObj.actions.push(i);
        }
      }
      let message =
        'The strategy dates are affecting the dates for the above highlighted actions.';
      returnObj.message = message;
      return returnObj;
    },
    nextStepStatus(disable, index) {
      this.nextDisabled = true;
      this.headerNextDisabled = true;
      this.formFilled = false;
      this.validationList[index] = !disable;
      for (let i = 0; i <= this.currentStep; i++) {
        if (this.validationList[i] === false) {
          this.nextDisabled = true;
          break;
        }
        this.nextDisabled = false;
      }
      const falseIndex = this.validationList.findIndex((element) => {
        return element === false;
      });
      if (falseIndex <= -1) {
        this.headerNextDisabled = false;
        this.formFilled = true;
      }
    },
    loadNextButton(value) {
      this.submitLoading = value;
    },
    setStepData(value) {
      this.config.strategy.steps[value.index].data = value;
      Vue.set(
        this.config.strategy.steps,
        value.index,
        this.config.strategy.steps[value.index]
      );
      this.actionStatus.passStatus = true;
      this.showedUp = false;
    },
    defaultValidationSet() {
      this.strategy.steps.forEach(() => {
        this.validationList.push(false);
      });
    },
    createAPIFormating() {
      this.submitLoading = true;
      const steps = this.config.strategy.steps;
      const entities = this.getEntitiesInFormat(
        steps[this.indexMapping.target].data.targetList
      );
      const strategySchedulerStep = {
        strategyStartDate: moment(
          new Date(steps[this.indexMapping.strategyScheduler].data.startDate)
        ).format('YYYY-MM-DD'),
        strategyEndDate: moment(
          new Date(steps[this.indexMapping.strategyScheduler].data.endDate)
        ).format('YYYY-MM-DD'),
        strategyName: escape(this.strategy.header.title),
        targetType: 'STATIC',
        strategyType: 'DAY_PARTING',
        rules: this.addTypeToWhereClause(
          transformer.generateWhereClause(
            {},
            steps[this.indexMapping.rule].data.filters
          ).dimensionNameValueList
        ),
        timezone:
          steps[this.indexMapping.strategyScheduler].data.timezone.value,
        strategyStatus: this.strategyStatus,
        description: escape(this.strategy.header.desc)
      };
      const targetStep = {
        entities: entities
      };
      const actionStep = {
        actions: this.getActionsInFormat(
          steps[this.indexMapping.action].data.actions,
          entities
        )
      };
      const finalObj = {
        ...strategySchedulerStep,
        ...targetStep,
        ...actionStep
      };
      const that = this;
      const request = {
        ...that.editAPIResponse,
        ...finalObj
      };
      request.mode = this.mode;
      this.$store
        .dispatch('createStratgey', request)
        .then((result) => {
          this.$router.push({
            name: 'strategies'
          });
        })
        .catch((err) => {
          if (err.response.status === 409) {
            that.errorHandling(err.response.data.errors);
          } else {
            that.$set(that.stage, 'error', true);
          }
          that.submitLoading = false;
        });
    },
    addTypeToWhereClause(rules) {
      if (!rules) {
        return null;
      }
      for (const rule of rules) {
        if (rule.operator) {
          rule.type = 'EXPRESSION';
        } else {
          rule.type = 'CLASSIFICATION';
        }
      }
      return rules;
    },
    errorHandling(errors) {
      this.strategyNameDuplicate =
        errors.DUPLICATE_STRATEGY_NAME !== undefined
          ? errors.DUPLICATE_STRATEGY_NAME
          : false;
      const overLapArray =
        errors.TIME_OVERLAP !== undefined ? errors.TIME_OVERLAP : [];
      const triggerMissing = errors.TRIGGER_MISSING_ACTION_INDEX;
      this.errorList = [];
      if (overLapArray && overLapArray.length > 0) {
        this.overLappingErrorMessage = '';
        for (let i = 0; i < overLapArray.length; i++) {
          this.config.strategy.steps[this.indexMapping.action].data.actions[
            overLapArray[i]
          ].overLapping = true;
          Vue.set(
            this.config.strategy.steps,
            this.indexMapping.action,
            this.config.strategy.steps[this.indexMapping.action]
          );
          this.overLappingErrorMessage += `Action ${overLapArray[i] + 1}`;
          if (overLapArray.length === i + 1) {
            this.overLappingErrorMessage += ' ';
          } else {
            this.overLappingErrorMessage += ', ';
          }
        }
        if (overLapArray.length > 0) {
          this.overLappingErrorMessage += 'are overlapping with each other.';
        }
        this.errorList.push(this.overLappingErrorMessage);
      }
      if (triggerMissing !== undefined) {
        this.config.strategy.steps[this.indexMapping.action].data.actions[
          triggerMissing
        ].overLapping = true;
        Vue.set(
          this.config.strategy.steps,
          this.indexMapping.action,
          this.config.strategy.steps[this.indexMapping.action]
        );
        const errorMessage = `Please choose an appropriate frequency and date range on Action ${
          triggerMissing + 1
        }`;
        this.errorList.push(errorMessage);
      }
    },
    getEntitiesInFormat(targetList) {
      const isLineItem = isLineItemRetailer(this.$store.getters.getRetailer);

      return targetList.map((item) => {
        const formatObj = {
          entityId: isLineItem
            ? item.line_item_id.toString()
            : item.campaign_id.toString(),
          entityType: isLineItem ? 'LINE_ITEM' : 'CAMPAIGN',
          metadata: isLineItem
            ? {
                entityId: item.line_item_id.toString(),
                line_item_id: item.line_item_id.toString(),
                campaignType: item?.campaign_type?.toString(),
                entityType: 'LINE_ITEM',
                adgroupId: 'null',
                name: item.line_item_name
              }
            : {
                entityId: item.campaign_id.toString(),
                campaignId: item.campaign_id.toString(),
                campaignType: item?.campaign_type?.toString(),
                placementType:
                  item?.promoteiq_cm_campaign_placement_type?.toString(),
                entityType: 'CAMPAIGN',
                profileId: item?.profile_id?.toString(),
                clientId: item.client_id,
                adgroupId: 'null',
                name: item.campaign_name,
                advertiserId: item?.advertiser_id
              }
        };
        return formatObj;
      });
    },
    getActionsInFormat(actions, entities) {
      const formatedActions = [];
      const APIMapper = this.config.strategy.APIMapper;
      const isLineItem = isLineItemRetailer(this.$store.getters.getRetailer);

      for (let i = 0; i < actions.length; i++) {
        const item = actions[i];
        let actionType;

        if (isLineItem) {
          const lineItemActions = {
            budget: 'LINE_ITEM_BUDGET_CHANGE',
            bid: 'LINE_ITEM_BID_CHANGE'
          };
          actionType =
            lineItemActions[item.selectedActionType.value] || undefined;
        } else {
          actionType = APIMapper.actionType[item.selectedActionType.value];
        }

        const formatObj = {
          actionType: actionType,
          executionTemplate: this.getExecutionTemplate(item),
          schedule: this.getScheduleFormat(item),
          entities: entities,
          actionIndex: i,
          actionId: item.actionId || '',
          deleted: item.deleted || false
        };

        formatedActions.push(formatObj);
      }

      return formatedActions;
    },
    getExecutionTemplate(action) {
      const obj = {
        type: 'dayparting',
        mode: action.selectedMode?.value,
        operation: action.selectedOperation?.value,
        value: action.newValue
      };
      return obj;
    },
    getScheduleFormat(action) {
      const frequency = [];
      for (const activeDay of action.activeDays) {
        frequency.push(activeDay.value);
      }
      const steps = this.config.strategy.steps;
      const startTime = moment(action.selectedStartTime.value, ['HH:mm']);
      const endTime = moment(action.selectedEndTime.value, ['HH:mm']);
      const diff = moment.duration(endTime.diff(startTime));
      const duration = diff.asMinutes();
      const obj = {
        frequency: frequency,
        fromDate: moment(new Date(action.startDate)).format('YYYY-MM-DD'),
        fromTime: action.selectedStartTime.value,
        toDate: moment(new Date(action.endDate)).format('YYYY-MM-DD'),
        toTime: action.selectedEndTime.value,
        duration: duration,
        timezone: steps[this.indexMapping.strategyScheduler].data.timezone.value
      };
      return obj;
    },
    navigateToStrategies() {
      this.$router.push({
        name: 'strategies'
      });
    }
  }
};
</script>

<style scoped lang="css">
.createStratgey {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0px;
  z-index: 200;
  background: #f5f7fa;
}
.createStratgey .createStratgey--header {
  z-index: 300;
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  position: relative;
}
.createStratgey .createStratgey--filter {
  background: #f5f7fa;
  height: 109px;
}
.heading--input {
  max-width: 75%;
  border: 0;
  outline: none;
  font-family: 'ProximaNova', sans-serif;
}
.heading--input:focus {
  border: 0;
  outline: none;
}
.scrollable {
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 200;
}
.mini-spinner {
  border: 0;
  width: 36px;
  text-align: center;
  padding: 2px;
}
.active-state {
  color: #4b5158;
}
.strategy-desc::placeholder {
  color: #aaadb1;
}
.error-highlight {
  border: 1px;
  border-color: #d7263d;
}
</style>
