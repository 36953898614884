import { cloneDeep } from 'lodash';
import HttpService from '@/utils/services/http-service';

const commonStateStructure = () => {
  // created default structure as it is used for most states.
  return { load: false, rows: [], error: false, totalRows: 0 };
};

export const state = {
  allStepsData: {},
  keywords: commonStateStructure()
};
export const getters = {
  getAllStepsData: (state) => state.allStepsData,
  getKeywords: (state) => state.keywords
};

export const mutations = {
  MUTATE_ALL_STEPS_DATA(state, data) {
    state.allStepsData = cloneDeep(data);
  },
  KEYWORDS(state, data) {
    state.keywords = cloneDeep(data);
  }
};
export const actions = {
  updateAllStepsData(context, data) {
    context.commit('MUTATE_ALL_STEPS_DATA', data);
  },
  fetchKeywords(context, data) {
    const requestPayload = {
      adGroupId: data?.meta?.adGroupId, // 771093919
      resource: 'keyword'
    };
    context.commit('KEYWORDS', {
      rows: [],
      load: true,
      error: false,
      totalRows: 0
    });
    HttpService.post('GET_RECOMMENDATIONS_AMS_ACTION_MANAGER', requestPayload)
      .then((response) => {
        context.commit('KEYWORDS', {
          rows: response.data?.recommendations,
          load: false,
          error: false,
          totalRows: response.data?.recommendations.length
        });
      })
      .catch(() => {
        context.commit('KEYWORDS', {
          rows: [],
          load: false,
          error: false,
          totalRows: 0
        });
      });
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
