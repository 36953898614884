// import Vue from 'vue';
import router from './index';
import { store } from '@/store/store';
// import { getCookie, deleteCookie } from '@/utils/helpers/cookie.js';

export function routeDecider(user, routeParams, logback) {
  // if (routeParams?.query?.redirect === 'true') {
  //   console.log(routeParams?.query, getCookie('enableNewUX'));
  //   deleteCookie('enableNewUX');
  // }
  if (!user) {
    if (routeParams && routeParams.query) {
      const queryParams = routeParams.query;
      if (queryParams.authToken) {
        const authToken = window.atob(queryParams.authToken);
        document.cookie = authToken;
        const parser = new URL(window.location);
        if (queryParams.url) {
          window.location = parser.origin + '/' + queryParams.url;
        } else {
          window.location = parser.origin;
        }
      }
    }

    if (logback === '') {
      router.push('/login');
    } else {
      router.push(
        '/login' + '?returnslink=' + encodeURIComponent(window.location)
      );
    }
  } else {
    var currentPath, queryParams;
    if (routeParams) {
      currentPath = routeParams.path;
      queryParams = routeParams.query;
    }
    if (!routeParams || currentPath === '/' || currentPath === '/login') {
      var href = window.location.href;
      if (href.indexOf('returnslink') !== -1) {
        href = href.split('returnslink=')[1];
        href = href.split('&')[0];
        href = decodeURIComponent(href);
        href = href.split(window.location.origin)[1];
        router.push(decodeURIComponent(href));
      } else {
        // For BI release, Default landing page is handling through athena canvas configs
        // const defaultLandingPage = '/Business-Overview'; // Vue.options.filters.config_check('feature.app.landing') ||
        if (store.getters.getRootPath) {
          router.push({ path: store.getters.getRootPath });
        } else {
          router.push({ name: 'Home' });
        }
      }
    } else {
      if (queryParams) {
        router.push({
          path: currentPath,
          query: queryParams
        });
      } else {
        router.push({
          path: currentPath
        });
      }
    }
  }
}
