<template>
  <div
    :style="paddingLeft"
    class="u-width-100 u-flex-0 u-color-grey-base u-text-case-title u-text-align-left u-text-overflow-ellipsis u-flex-align-items-center"
  >
    <div class="u-flex-align-items-center u-display-flex">
      <span
        v-tippy="tippy"
        :title="cellValue"
        >{{ cellValue }}</span
      >
      <rb-icon
        v-if="checkIfUnctegorised"
        v-tippy="tippy"
        class="rb-icon--medium u-color-orange-base u-spacing-ml-xs"
        :icon="'warning'"
        :title="'SKUs under this category are not mapped to any brand'"
      />
      <div
        v-if="checkIfUnctegorised"
        class="optimize-budget-plan cellHover u-width-120px u-font-weight-600 u-spacing-pl-m u-font-size-7 u-color-blue-base"
        @click="openUncategorisedSKUPanel"
      >
        <span class="u-spacing-ml-xs hover-span u-text-align-center"
          >View SKUs</span
        >
      </div>
      <div
        v-else-if="validateEntity"
        class="optimize-budget-plan cellHover u-width-120px u-font-weight-600 u-spacing-pl-m u-font-size-7 u-color-blue-base"
        @click="routeTo"
      >
        <span class="u-spacing-ml-xs hover-span u-text-align-center"
          >Optimize budget</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { eventBus } from '@/utils/services/eventBus';

export default {
  props: {
    params: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tippy: {
        placement: 'bottom-start',
        arrow: false,
        popperOptions: {
          modifiers: {
            offset: {
              offset: '30px, 4px'
            },
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      }
    };
  },
  computed: {
    dashboardTable() {
      return this.$store.getters[
        'budgetPlannerDashboardV2/getBudgetPlannerDashboardTable'
      ];
    },
    validateEntity() {
      let isValid = true;
      const disableForRetailers = ['walmart', 'target', 'instacart'];
      const retailer = this.$store.getters.getRetailer;
      if (
        this.params.data.entity_type === 'ENTIRE_BUSINESS' ||
        disableForRetailers.includes(retailer)
      ) {
        isValid = false;
      }
      return isValid;
    },
    paddingLeft() {
      let padding = '0';
      const entityType = this.params.data.entity_type;
      if (entityType === 'PROFILE') {
        padding = '16';
      } else if (entityType === 'PORTFOLIO') {
        padding = '32';
      }
      return `padding-left: ${padding}px;`;
    },
    checkIfUnctegorised() {
      const cellValue = this.params.data[this.params.colDef.field];
      return cellValue.toLowerCase() === 'Uncategorized'.toLocaleLowerCase();
    },
    cellValue() {
      return this.params.data[this.params.colDef.field] || 'NA';
    }
  },
  created() {
    console.log('this.params', this.params);
  },
  methods: {
    openUncategorisedSKUPanel() {
      eventBus.$emit('openBudgetPlannerUncategorized');
    },
    routeTo() {
      let query = {};
      if (this.params.data.entity_type === 'PROFILE') {
        query = {
          profile_name: this.cellValue
        };
      } else {
        const parentProfile = this.dashboardTable.filter(
          (row) => row.entity_id === this.params.data.related_entity_id
        );
        if (parentProfile.length > 0) {
          query = {
            profile_name: parentProfile[0].entity_name,
            portfolio_name: this.cellValue
          };
        }
      }
      this.$router.push({ name: 'budget-plan-campaign-budget', query });
    }
  }
};
</script>
