<template>
  <section
    class="u-bg-color-white-base u-spacing-m-s u-spacing-p-m u-border-radius-m u-box-shadow-xx-light"
  >
    <div
      class="u-display-flex u-flex-justify-content-space-between u-flex-direction-column"
    >
      <h3
        class="u-font-size-3 u-font-weight-600 u-text-align-left u-text-case-upper u-color-grey-lighter"
      >
        E-Commerce Planner
      </h3>
      <h4
        class="u-font-size-4 u-font-weight-600 u-color-grey-lighter u-spacing-pt-m u-spacing-pb-s u-display-flex u-flex-align-items-center"
      >
        <span>
          <img
            src="https://cdn.rboomerang.com/remcc/simulation.svg"
            alt="caution img"
            class="u-spacing-mr-s"
          />
        </span>
        Create simulation to see how it effects your output metrics
      </h4>
      <div
        class="u-font-weight-600 u-font-size-5 u-display-flex u-flex-align-items-center"
      >
        <div class="u-font-size-5 u-display-flex u-flex-align-items-center">
          <plan-icon class="opacity-0_5 u-bg-color-pink-base" />
          <plan-icon class="opacity-0_5 u-bg-color-green-base" />
          2022 Performance
          <rb-icon
            class="iq-icon u-color-purple-base u-spacing-mb-xs"
            icon="light-bulb"
          />
        </div>
        <div class="u-display-flex u-flex-align-items-center u-spacing-ml-l">
          <plan-icon class="u-border-dashed u-border-color-blue-base" />2023
          Planned
        </div>
      </div>
      <div class="u-display-flex">
        <div
          class="u-display-flex u-flex-direction-column u-spacing-pr-xl u-border-color-grey-light u-border-right-xx-light"
        >
          <div>
            <!-- Input metrics widget for ecomm calculator -->
            <div
              class="u-font-weight-600 u-font-size-4 u-spacing-mt-m u-color-grey-light u-spacing-mb-m"
            >
              Input Metrics
            </div>
            <div class="u-display-flex">
              <template v-for="(widget_name, rowIndex) of remcc_input">
                <planner-tile
                  :key="rowIndex"
                  :is-editable="true"
                  :metric-name="widget_name.metricName"
                  :index="rowIndex"
                  :current-val="widget_name.currentVal"
                  :planned-val="widget_name.plannedVal"
                  :unit="widget_name.unit"
                  :simulated="simulatedValues[widget_name.metricName]"
                  :simulation-complete="simulationComplete"
                  @onInputChange="onInputChange"
                />
              </template>
            </div>
          </div>
          <div>
            <div
              class="u-font-weight-600 u-font-size-4 u-spacing-mt-m u-color-grey-light u-spacing-mb-m"
            >
              Supporting Variables
            </div>
            <div class="u-display-flex">
              <template
                v-for="(widget_name, rowIndex) of remcc_supporting_metrics"
              >
                <planner-tile
                  :key="rowIndex"
                  :is-editable="true"
                  :metric-name="widget_name.metricName"
                  :index="rowIndex"
                  :unit="widget_name.unit"
                  :simulated="simulatedValues[widget_name.metricName]"
                  :simulation-complete="simulationComplete"
                  @onInputChange="onInputChange"
                />
              </template>
            </div>
          </div>
          <div
            class="u-spacing-mt-m u-display-flex u-flex-justify-content-flex-end"
          >
            <rb-button
              v-if="simulationComplete"
              id="reset-btn"
              :text="'Reset'"
              :type="'hollow'"
              size="m"
              :click-fn="resetSimulation"
            />
            <rb-button
              :text="'Simulate'"
              :type="'filled'"
              size="m"
              :click-fn="findGrossSales"
            />
          </div>
        </div>
        <div class="u-spacing-pl-l">
          <!-- Output metrics widget for ecomm calculator -->
          <div
            class="u-font-weight-600 u-font-size-4 u-spacing-mt-m u-color-grey-light u-spacing-mb-m"
          >
            Output Metrics
          </div>
          <div class="u-display-flex">
            <template v-for="(widget_name, rowIndex) of remcc_output">
              <planner-tile
                :key="rowIndex"
                :metric-name="widget_name.metricName"
                :index="rowIndex"
                :current-val="widget_name.currentVal"
                :planned-val="widget_name.plannedVal"
                :unit="widget_name.unit"
                :simulated="simulatedValues[widget_name.metricName]"
                :simulation-complete="simulationComplete"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from 'vue';
import plannerTile from './planner-tile.vue';
import PlanIcon from '../atoms/plan-icon.vue';
export default Vue.extend({
  components: {
    plannerTile,
    PlanIcon
  },
  data() {
    return {
      simulationComplete: false,
      remcc_output: [
        {
          metricName: 'market share',
          currentVal: 12,
          plannedVal: 15,
          unit: 'percent'
        },
        {
          metricName: 'shipped cogs',
          currentVal: 210000000,
          plannedVal: 200000000,
          unit: 'usd'
        }
      ],
      remcc_input: [
        {
          metricName: 'ad spend',
          currentVal: 9000000,
          plannedVal: 9100000,
          unit: 'usd'
        },
        {
          metricName: 'po fill rate',
          currentVal: 75,
          plannedVal: 70,
          unit: 'percent'
        },
        {
          metricName: 'content score',
          currentVal: 85,
          plannedVal: 70,
          unit: 'percent'
        }
      ],
      remcc_supporting_metrics: [
        {
          metricName: 'organic growth',
          unit: 'percent'
        },
        {
          metricName: 'contribution profit',
          unit: 'usd'
        }
      ],
      simulatedValues: {}
    };
  },
  methods: {
    onInputChange(inputData) {
      this.simulatedValues = { ...this.simulatedValues, ...inputData };
    },
    resetSimulation() {
      this.simulationComplete = false;
      this.simulatedValues = {};
    },
    findGrossSales() {
      const sales_2022 = this.remcc_output[1].currentVal; // shipped_cogs
      const ad_spend_2022 = this.remcc_input[0].currentVal;
      const content_score_2022 = this.remcc_input[2].currentVal / 100;
      const po_fill_rate_2022 = this.remcc_input[1].currentVal / 100;
      const roas = 6.82;
      const organic_growth = this.simulatedValues['organic growth'] / 100;
      const ad_spend_2023 = this.simulatedValues['ad spend'];
      const po_fill_rate_2023 = this.simulatedValues['po fill rate'] / 100;
      const content_score_2023 = this.simulatedValues['content score'] / 100;
      const variable_factor = 3000000;
      const marketshare_2022 = this.remcc_output[0].currentVal;
      const gross_sales_2023 =
        sales_2022 -
        ad_spend_2022 * roas +
        sales_2022 * organic_growth +
        Math.min(
          0.5 * sales_2022,
          ad_spend_2023 * roas +
            Math.min(
              0.1 * sales_2022,
              (content_score_2023 - content_score_2022) * variable_factor
            )
        ) +
        Math.min(
          0.15 * sales_2022,
          ((po_fill_rate_2023 - po_fill_rate_2022) * sales_2022) /
            po_fill_rate_2022
        );
      Vue.set(this.simulatedValues, 'shipped cogs', gross_sales_2023);
      const marketshare_2023 = parseInt(
        Math.min(
          20,
          (gross_sales_2023 / (sales_2022 * (1 + organic_growth))) *
            marketshare_2022
        )
      );
      Vue.set(this.simulatedValues, 'market share', marketshare_2023);
      this.simulationComplete = true;
    }
  }
});
</script>
<style scoped lang="css">
.u-border-right-xx-light {
  border-right: 1px solid #e9eaeb;
}
#reset-btn {
  color: #007cf6;
  border-color: #007cf6;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  margin-right: 1.6rem;
}
</style>
