<template>
  <div class="u-display-flex u-flex-direction-column">
    <span class="u-font-size-6 u-color-grey-light line-height-1_5"
      ><span
        class="u-color-blue-base u-spacing-p-xxs u-spacing-mr-xxs u-border-radius-xs u-bg-color-blue-xx-light"
        style=""
        :title="data.email"
        >{{ username }} </span
      >{{ message }}</span
    >
    <span
      class="u-font-size-7 u-line-height-1 u-color-grey-lighter u-spacing-mt-xs"
      >{{ data.date.time }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          name: 'NA',
          message: 'NA',
          date: {
            time: 'NA'
          }
        };
      }
    }
  },
  computed: {
    username() {
      if (this.data.username) {
        return '@' + this.data.username.split('@')[0];
      }
      return '@user';
    },
    message() {
      if (typeof this.data.message === 'function') {
        return this.data.message(this.data);
      }
      return this.data.message;
    }
  }
};
</script>

<style lang="css" scoped>
.line-height-1_5 {
  line-height: 1.5;
}
</style>
