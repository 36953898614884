<template>
  <div class="u-display-flex">
    <div
      v-click-outside="handleDropdown"
      class="u-width-100 u-position-relative"
    >
      <rb-input
        v-model="computedInput"
        :class="inputClasses"
        :placeholder="placeholder"
        @focus="showDropdown = true"
      ></rb-input>
      <div
        v-if="computedInput && showDropdown"
        class="u-width-100 u-position-absolute u-border-radius-s u-display-flex u-flex-direction-column u-spacing-mt-xxs dropdown-body"
      >
        <span
          v-for="(suggestion, i) in computedSuggestions"
          :key="`suggestion-${i}`"
          class="u-font-size-6 u-spacing-pv-s u-spacing-ph-m u-cursor-pointer dropdown-item"
          @click.stop="() => itemClick(suggestion)"
        >
          {{ suggestion }}
        </span>
        <span
          v-if="computedInput !== computedSuggestions[0]"
          class="u-font-size-6 u-font-weight-600 u-spacing-pv-s u-spacing-ph-m u-cursor-pointer dropdown-item"
          @click.stop="() => itemClick(computedInput)"
        >
          {{ computedInput }}
          <span class="u-color-grey-lighter">(Add as new)</span>
        </span>
      </div>
    </div>
    <slot name="post-input" />
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'InputWithSuggestions',
  props: {
    input: {
      type: String,
      default: ''
    },
    inputClasses: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    suggestions: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      showDropdown: false
    };
  },
  computed: {
    computedInput: {
      get() {
        return this.input;
      },
      set: debounce(function (value) {
        this.$emit('input', value);
      }, 1000)
    },
    computedSuggestions() {
      return this.suggestions.filter((suggestion) =>
        suggestion?.includes(this.input)
      );
    }
  },
  methods: {
    handleDropdown() {
      this.$nextTick(() => {
        if (this.showDropdown) this.showDropdown = false;
      });
    },
    itemClick(suggestion) {
      this.$emit('input', suggestion);
      this.showDropdown = false;
    }
  }
};
</script>

<style lang="css" scoped>
.dropdown-body {
  max-height: 400px;
  overflow-y: scroll;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(43, 51, 59, 0.2);
  z-index: 5;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}
.dropdown-body > span:hover {
  background-color: rgba(255, 213, 0, 0.1);
}
</style>
