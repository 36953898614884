<template>
  <div
    class="u-position-relative u-font-size-5 u-spacing-pv-m u-spacing-pr-xxl"
  >
    <p class="u-font-size-3 u-font-weight-600">
      {{ $t('market_share') }}
    </p>
    <p class="u-font-size-4 u-font-weight-600 u-spacing-pt-l">
      <span v-if="direction === 'Drop'">Decreased by </span>
      <span v-if="direction === 'Increase'">Increased by </span>
      <span
        >{{
          formatter(alert.attributes.client_brands_ms_change_abs, 'percent')
        }}
        {{ $t('last_7_days_compared_to_prior_week') }}.</span
      >
    </p>
    <p class="u-font-size-6 u-spacing-pt-s">
      {{ $t('last_appeared_on_')
      }}{{ formatter(alert.attributes.Last_appeared_on, 'date') }} &bull;
      {{ $t('appeared_') }}{{ formatter(alert.attributes.recency, 'string')
      }}{{ $t('_out_of_last_30_days') }}
    </p>
    <div class="u-display-flex u-spacing-pv-xl">
      <div class="u-spacing-pr-s">
        <key-value-pair
          :item-key="$t('market_share')"
          :item-value="
            formatter(alert.attributes.post_client_brands_ms, 'percent') +
            ' (' +
            $t('last_7_days') +
            '), ' +
            formatter(alert.attributes.pre_client_brands_ms, 'percent') +
            ' (' +
            $t('week_prior_to_last_7_days') +
            ')'
          "
        />
      </div>
    </div>

    <div class="detail-view--card chart--card u-max-width-100">
      <rb-category-share
        :title="'Category and share'"
        :data="marketWidgetData"
      />
      <div class="date--block u-spacing-pv-s u-spacing-ph-l u-spacing-mb-l">
        {{ $t('last_updated') }}:
        {{ formatter(alert.attributes.Last_Updated_Date, 'date') }}
      </div>
    </div>

    <div class="detail-view--card chart--card u-max-width-100">
      <p
        v-tippy="tippyOptions"
        :title="$t('my_brands_bm_rc_tooltip')"
        class="u-display-inline-block u-spacing-pt-l u-spacing-pl-m u-font-size-5 u-font-weight-600 u-color-grey-lighter u-spacing-pb-l u-text-case-upper"
      >
        {{ $t('my_brands_bm_rc') }}
      </p>
      <rb-tabs
        v-model="selectedClientBrandsTab"
        class="u-spacing-ph-m"
        :type="'underlined'"
        :tab-list="detailsTableTabs"
      />
      <div
        class="u-width-100"
        style="height: 400px"
      >
        <rb-data-table
          :grid-options="gridOptions1"
          :table-columns="tableColumns"
          :table-data="clientBrandsTableData"
          :no-rows-message="'No data present.'"
        />
      </div>
      <div class="date--block u-spacing-pv-s u-spacing-ph-l u-spacing-mb-l">
        {{ $t('last_updated') }}:
        {{ formatter(alert.attributes.Last_Updated_Date, 'date') }}
      </div>
    </div>

    <div class="detail-view--card chart--card u-max-width-100">
      <p
        v-tippy="tippyOptions"
        :title="$t('comp_brands_bm_rc_tooltip')"
        class="u-display-inline-block u-spacing-pt-l u-spacing-pl-m u-font-size-5 u-font-weight-600 u-color-grey-lighter u-spacing-pb-l u-text-case-upper"
      >
        {{ $t('comp_brands_bm_rc') }}
      </p>
      <rb-tabs
        v-model="selectedCompBrandsTab"
        class="u-spacing-ph-m"
        :type="'underlined'"
        :tab-list="detailsTableTabs"
      />
      <div
        class="u-width-100"
        style="height: 400px"
      >
        <rb-data-table
          :grid-options="gridOptions2"
          :table-columns="tableColumns"
          :table-data="compBrandsTableData"
          :no-rows-message="'No data present.'"
        />
      </div>
      <div class="date--block u-spacing-pv-s u-spacing-ph-l">
        {{ $t('last_updated') }}:
        {{ formatter(alert.attributes.Last_Updated_Date, 'date') }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import chart from '@/components/basic/chart';
import starRating from '@/components/widgets/starRatings';
import keyValuePair from '@/components/basic/keyValuePair';
import rbCategoryShare from '@/components/widgets/marketshareWidget';

export default {
  components: {
    chart,
    starRating,
    keyValuePair,
    rbCategoryShare
  },
  props: {
    alert: {
      type: Object,
      default: null
    },
    // eslint-disable-next-line vue/require-default-prop
    formatter: {
      type: Function
    }
  },
  data() {
    return {
      currency: Vue.prototype.$currency,
      gridOptions1: {
        context: {
          componentParent: this
        }
      },
      gridOptions2: {
        context: {
          componentParent: this
        }
      },
      detailsTableTabs: ['Gainers', 'Losers'],
      tippyOptions: {
        placement: 'right',
        arrow: false,
        popperOptions: {
          modifiers: {
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }
        }
      },
      tableColumns: [],
      selectedClientBrandsTab: 0,
      selectedCompBrandsTab: 0,
      marketWidgetData: [],
      showPerformanceOverview: Vue.options.filters.config_check(
        'feature.recommendations.performanceOverview.enable'
      )
    };
  },
  computed: {
    direction() {
      return this.alert.alertType.split(' ')[2];
    },
    clientBrandsTableData() {
      let array =
        this.selectedClientBrandsTab === 0
          ? this.alert.attributes.Top_gainers_client_values_array
          : this.alert.attributes.Top_losers_client_values_array;
      array = (JSON.parse(array) || []).sort(function (a, b) {
        return a.PRIORITY_ORDER - b.PRIORITY_ORDER;
      });
      return array;
    },
    compBrandsTableData() {
      let array =
        this.selectedCompBrandsTab === 0
          ? this.alert.attributes.Top_gainers_comp_values_array
          : this.alert.attributes.Top_losers_comp_values_array;
      array = (JSON.parse(array) || []).sort(function (a, b) {
        return a.PRIORITY_ORDER - b.PRIORITY_ORDER;
      });
      return array;
    }
  },
  mounted() {
    if (this.alert.attributes.summary_chart_array) {
      let chart = JSON.parse(this.alert.attributes.summary_chart_array);
      // Format all the values
      chart = chart.map((item) => {
        item.LW_VALUE_FORMAT = this.formatter(item.LW_VALUE, 'currency');
        item.PW_VALUE_FORMAT = this.formatter(item.PW_VALUE, 'currency');
        item.trendIncrese = item.CHANGE_VALUE > 0;
        item.CHANGE_VALUE_FORMAT = this.formatter(
          Math.abs(item.CHANGE_VALUE),
          'percent'
        );
        return item;
      });

      const categoryMarketShare = chart.filter((item) => {
        return item.BRAND_NAME === 'Category';
      })[0];
      // Client Brands
      let clientBrands = chart.filter((item) => {
        if (item.BRAND_NAME !== 'Category') {
          item.priorWeekChange = parseInt(
            (item.PW_VALUE / categoryMarketShare.PW_VALUE) * 100
          );
          item.lastWeekChange = parseInt(
            (item.LW_VALUE / categoryMarketShare.LW_VALUE) * 100
          );
          if (item.CLIENT_BRAND_FLAG === 'Y') {
            item.colors = '#23b5d3';
          }
          return item;
        } else {
          return false;
        }
      });

      // sort based on the lw_value
      clientBrands = clientBrands.sort((a, b) => {
        return b.LW_VALUE - a.LW_VALUE;
      });

      if (categoryMarketShare) {
        const cate = this.alert.attributes.bc_category_alert_type.split('›');
        categoryMarketShare.BRAND_NAME =
          cate.length > 0
            ? categoryMarketShare.BRAND_NAME +
              ' : ' +
              cate[cate.length - 1].trim()
            : categoryMarketShare.BRAND_NAME;
      }

      this.marketWidgetData.push(categoryMarketShare);
      this.marketWidgetData.push(...clientBrands);
    }
    if (this.direction === 'Increase') {
      this.selectedCompBrandsTab = 1;
    } else if (this.direction === 'Drop') {
      this.selectedClientBrandsTab = 1;
    }
  },
  beforeMount() {
    this.tableColumns = [
      {
        field: 'sku_details',
        minWidth: 220,
        pinned: 'left',
        suppressSorting: true,
        headerName: 'SKU Details',
        cellRendererFramework: Vue.extend({
          template:
            '<rb-sku :image-url="params.data.IMAGE_URL" :title="params.data.TITLE" :asin="params.data.SKU" :product-page-url="params.data.PRODUCT_URL"></rb-sku>'
        })
      },
      {
        headerName: 'Last 2 weeks Revenue',
        field: 'SKU_TOTAL_REVENUE',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'currency',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText: "Retailer's revenue for last 2 weeks",
          keyType: 'currency'
        }
      },
      {
        headerName: 'Market Share% Change (Absolute)',
        field: 'SKU_PRE_PERIOD_MKT_SHARE_CHANGE_ABS',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'percent',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText:
            'Market share% for last 7 days minus Market share% for prior week',
          keyType: 'percent'
        }
      },
      {
        headerName: 'Market Share% (Last 7 days)',
        width: 120,
        suppressSizeToFit: true,
        field: 'SKU_POST_PERIOD_MKT_SHARE',
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'percent',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText: 'Market share% of this SKU for last 7 days',
          keyType: 'percent'
        }
      },
      {
        headerName: 'Market Share% (Prior Period)',
        field: 'SKU_PRE_PERIOD_MKT_SHARE',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'percent',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText:
            'Market share% of this SKU for a week prior to last 7 days',
          keyType: 'percent'
        }
      },
      {
        field: 'SKU_REVENUE_CHANGE_DOLLAR',
        headerName: `Revenue change (${this.currency})`,
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'currency',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText:
            'Change in revenue of the SKU in last 7 days compared to prior week',
          keyType: 'currency'
        }
      },
      {
        field: 'SKU_PRE_PERIOD_MKT_SHARE_CHANGE_PERC',
        headerName: 'Revenue Change (%)',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'percent',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText:
            'Change % in revenue of the SKU in last 7 days compared to prior week',
          keyType: 'percent'
        }
      },
      {
        field: 'SKU_POST_PERIOD_REVENUE',
        headerName: `Revenue (${this.currency}) (Last 7 days)`,
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'currency',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText: 'Revenue of the SKU in last 7 days',
          keyType: 'currency'
        }
      },
      {
        field: 'SKU_PRE_PERIOD_REVENUE',
        headerName: `Revenue (${this.currency}) (Prior Period)`,
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'currency',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText: 'Revenue of the SKU in prior week',
          keyType: 'currency'
        }
      },
      {
        field: 'REASON_LIST',
        headerName: 'Reasons',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'string',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText: 'Possible reasons for market share change',
          keyType: 'string'
        }
      },
      {
        field: 'POST_PERIOD_OOS_DAYS_1P_ONLY',
        headerName: 'OOS days (Last 7 days)',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'number',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText: 'Number of OOS days in last 7 days',
          keyType: 'number'
        }
      },
      {
        field: 'PRE_PERIOD_OOS_DAYS_1P_ONLY',
        headerName: 'OOS days (Prior week)',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'number',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText: 'Number of OOS days in prior week',
          keyType: 'number'
        }
      },
      {
        field: 'POST_PERIOD_LBB_DAYS_1P_ONLY',
        headerName: 'LBB days (Last 7 days)',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'number',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText: 'Number of LBB days in last 7 days',
          keyType: 'number'
        }
      },
      {
        field: 'PRE_PERIOD_LBB_DAYS_1P_ONLY',
        headerName: 'LBB days (Prior week)',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'number',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText: 'Number of LBB days in prior week',
          keyType: 'number'
        }
      },
      {
        field: 'POST_PERIOD_UNAVAILABLE_DAYS_COUNT',
        headerName: 'Unavailable days (Last 7 days)',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'number',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText: 'Number of unavailable days in last 7 days',
          keyType: 'number'
        }
      },
      {
        field: 'PRE_PERIOD_UNAVAILABLE_DAYS_COUNT',
        headerName: 'Unavailable days (Prior week)',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'number',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText: 'Number of unavailable days in prior week',
          keyType: 'number'
        }
      },
      {
        field: 'POST_PERIOD_VPC_DAYS_1P_ONLY',
        headerName: 'VPC days (Last 7 days)',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'number',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText: 'Number of VPC days in last 7 days',
          keyType: 'number'
        }
      },
      {
        field: 'PRE_PERIOD_VPC_DAYS_1P_ONLY',
        headerName: 'VPC days (Prior week)',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'number',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText: 'Number of VPC days in prior week',
          keyType: 'number'
        }
      },
      {
        field: 'AVG_SAS_1P_PRICE_CHANGE_PERC',
        headerName: 'Offer price change%',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'percent',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText:
            'Percentage change in the offer price in the last 7 days compared to prior week',
          keyType: 'percent'
        }
      },
      {
        field: 'POST_PERIOD_LIGHTING_DEAL_DAYS_1P_ONLY',
        headerName: 'Lightning deal days (Last 7 days)',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'number',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText: 'Number of Lightning deals day in last 7 days',
          keyType: 'number'
        }
      },
      {
        field: 'PRE_PERIOD_LIGHTING_DEAL_DAYS_1P_ONLY',
        headerName: 'Lightning deal days (prior week)',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'number',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText: 'Number of Lightning deals day in prior week',
          keyType: 'number'
        }
      },
      {
        field: 'CONTENT_CHANGE_FLAG',
        headerName: 'Content Change',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'string',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText:
            'If there was any content change in last 7 days compared to prior week (both title and description)',
          keyType: 'string'
        }
      },
      {
        field: 'POST_PERIOD_PURCHASE_RESTRICTIONS',
        headerName: 'Purchase restriction (Last 7 days)',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'string',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText:
            'If there was any purchase restriction on the SKU in last 7 days',
          keyType: 'string'
        }
      },
      {
        field: 'PRE_PERIOD_PURCHASE_RESTRICTIONS',
        headerName: 'Purchase restriction (Prior week)',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'string',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText:
            'If there was any purchase restriction on the SKU in prior week',
          keyType: 'string'
        }
      },
      {
        field: 'AVG_RATING_CHANGE',
        headerName: 'Avg Rating change',
        width: 120,
        suppressSizeToFit: true,
        cellRendererFramework: 'genericTableCell',
        cellRendererParams: {
          keyType: 'number',
          formatterFn: this.formatter
        },
        headerComponentFramework: 'genericTableHeader',
        headerComponentParams: {
          toolTipText:
            'Absolute change in the rating in last 7 days compared to prior week',
          keyType: 'number'
        }
      }
    ];
  }
};
</script>

<style scoped lang="css">
.icon--task .rb-icon {
  height: 16px;
  width: 16px;
}
.icon--task .rb-icon::before {
  font-size: 16px;
}
</style>
