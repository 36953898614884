<template>
  <rb-select
    v-if="!!options"
    class="u-border-all u-border-radius-s u-border-width-s u-border-color-grey-xxx-light u-color-grey-base typeSelect"
    :send-details="true"
    :on-close="onSelectItem"
    :options="options"
  >
    <div
      slot="trigger"
      class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
      data-cy="SelectItem"
    >
      <div class="u-display-flex u-flex-align-items-center u-spacing-p-s">
        <div class="u-display-flex u-flex-direction-column">
          <span class="u-font-size-7">{{
            (selectedOption && selectedOption[entitykey]) ||
            (selectedOption && selectedOption.label) ||
            'NA'
          }}</span>
        </div>
      </div>
      <rb-icon
        class="rb-icon--medium u-spacing-mh-s u-color-grey-lighter"
        :icon="'caret-down'"
        data-cy="caretDownIcon"
      />
    </div>
    <template
      slot="item"
      slot-scope="option"
    >
      <div class="u-display-flex u-flex-align-items-center u-font-size-7">
        {{ option[entitykey] || option.label }}
      </div>
    </template>
  </rb-select>
</template>

<script>
export default {
  props: {
    options: {
      default: () => [],
      type: Array
    },
    defaultOption: {
      default: () => {},
      type: Object
    },
    entitykey: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      selectedOption: {}
    };
  },
  watch: {
    defaultOption: {
      handler(newValue) {
        if (newValue) {
          this.selectedOption = newValue;
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {},
  methods: {
    onSelectItem(index, value) {
      if (!value?.length) return;
      this.selectedOption = value[0];
      this.$emit('selectedItem', this.selectedOption);
    }
  }
};
</script>

<style></style>
