// import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import HttpService from '@/utils/services/http-service';
import transformer from '@/utils/services/data-transformer';
import utils from '../../utils/helpers/index';
import moment from 'moment-timezone';
import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';

const state = {
  timezonelist: [],
  clientTimezone: null,
  globalSnakBack: {
    text: ''
  },
  clientBrowserTimezone: null
};

const getters = {
  getClientTimezone: (state) => {
    return state.clientTimezone;
  },
  getTimezoneList: (state) => {
    return state.clientTimezone;
  },
  getClientBrowserTimezone: (state) => {
    return state.clientBrowserTimezone;
  }
};

const mutations = {
  COMMON_SET_TIMEZONE_LIST: (state, list) => {
    Vue.set(state, 'timezonelist', list);
  },
  COMMON_SET_CLIENT_TIMEZONE: (state, clientTimezone) => {
    Vue.set(state, 'clientTimezone', clientTimezone);
  },
  GLOBAL_SNACK_BAR_MESSAGE: (state, payload) => {
    Vue.set(state, 'globalSnakBack', payload);
  },
  COMMON_SET_CLIENT_BROWSER_TIMEZONE: (state, clientBrowserTimezone) => {
    Vue.set(state, 'clientBrowserTimezone', clientBrowserTimezone);
  }
};

const actions = {
  commonTableDownloadPromiseReturnAction: (context, data, completeConfig) => {
    var postObj = {
      APIData: data
    };
    if (data.cube !== undefined) {
      postObj = {
        APIData: data.apiConfig,
        cube: data.cube
      };
    }
    return HttpLayer.post(postObj).then((response) => {
      return transformer.mergeResultDimension(response.data, true);
    });
  },

  amsWorkbenchActionApi: (context, data) => {
    return HttpService.post('AMS_ACTIONS', data);
  },

  amsWorkbenchActionLogApi: (context, data) => {
    return HttpService.post('AMS_ACTIONS_ACTION_LOG', data);
  },

  updateBulkAmsActionsManager: (context, data) => {
    let payload = data;
    // convert normal payload to bulk payload
    if (data?.length && !data[0].uuid) {
      payload = [
        {
          uuid: data[0].campaign_id || uuidv4(),
          payload: data
        }
      ];
    }
    return HttpService.post('UPDATE_BULK_AMS_ACTIONS_MANAGER', payload);
  },

  bulkAmsActionsManagerBatchAction: (context, payload) => {
    return HttpService.post('BULK_SELECT_ALL', payload);
  },

  updateBulkAmsActionsManagerBatchAction: (context, payload) => {
    return HttpService.post('UPDATE_BULK_BATCH_ACTIONS_MANAGER', payload);
  },

  amsWorkbenchMetadataUpdateApi: (context, data) => {
    return HttpService.post('UPDATE_AMS_ACTIONS_MANAGER', data);
  },

  commonSetTimezone: (context) => {
    var timezones = moment.tz.names();
    const formatList = [];
    for (let i = 0; timezones.length > i; i++) {
      const formatZone = moment.tz(timezones[i]).format('Z z');
      const shortForm = moment.tz(timezones[i]).format('z');
      const gmtValue = 'GMT' + moment.tz(timezones[i]).format('Z');
      const zone = {
        fullForm: `GMT ${formatZone} ${timezones[i]}`,
        value: timezones[i],
        shortForm: shortForm,
        gmtValue
      };
      formatList.push(zone);
    }
    const uniqueZones = [...new Set(formatList)];
    const clientTimezone = utils.getClientTimezone();
    const browserTimezone =
      Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'Asia/Manila'; // Added PST as the default timezone
    const defaultIndex = uniqueZones.findIndex((element) => {
      return element.shortForm === clientTimezone;
    });
    const browserTZIndex = uniqueZones.findIndex((element) => {
      return element.value === browserTimezone;
    });

    context.commit('COMMON_SET_TIMEZONE_LIST', uniqueZones);
    context.commit('COMMON_SET_CLIENT_TIMEZONE', uniqueZones[defaultIndex]);
    context.commit(
      'COMMON_SET_CLIENT_BROWSER_TIMEZONE',
      uniqueZones[browserTZIndex]
    );
  },
  showGlobalSnack: ({ commit }, payload) => {
    commit('GLOBAL_SNACK_BAR_MESSAGE', payload);
  },
  globalStoreLogger(context, data) {
    HttpLayer.post({
      urlId: 'EVENT_LOGS',
      APIData: data
    }).then((data) => {
      console.log(data);
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
