<template>
  <div class="u-width-100 overlay-positioning">
    <div
      class="boxShadow u-bg-color-grey-white u-display-flex u-flex-direction-column u-border-radius-s"
    >
      <div
        class="u-display-flex u-flex-justify-content-space-between u-flex-align-items-center u-flex-direction-row u-width-100 u-spacing-ph-m u-spacing-pt-m"
      >
        <span
          class="u-font-size-3 u-text-case-upper u-font-weight-600 u-color-grey-lighter"
        >
          More About {{ cardExpandedTitle }}
        </span>
        <div
          class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between"
        >
          <div
            v-if="(keyObservations || []).length && categoryLandscape"
            class="u-font-size-6 u-spacing-mr-m u-spacing-ph-m u-spacing-pv-s u-border-all u-border-radius-xxxl u-border-color-grey-xxx-light u-cursor-pointer"
            @click.stop="
              $refs.keyObservation.scrollIntoView({ behavior: 'smooth' })
            "
          >
            View Key Insights/Observations
          </div>
          <div @click="closeExpandedCard">
            <rb-icon
              :icon="'cross'"
              class="u-cursor-pointer u-color-grey-lighter"
            />
          </div>
        </div>
      </div>
      <slot
        v-if="categoryLandscape"
        name="categoryLandscapeExpandedCard"
      />
      <slot
        v-if="competitorLandscape"
        name="competitorLandscapeExpandedCard"
      />
      <div
        v-if="(keyObservations || []).length && categoryLandscape"
        class="u-spacing-p-m u-display-flex u-flex-direction-column u-border-bottom u-border-color-grey-xxx-light"
      >
        <span
          ref="keyObservation"
          class="u-font-size-4 u-font-weight-bold u-spacing-mv-m"
          >Key Observations:</span
        >
        <OverviewObservations :observations="keyObservations" />
      </div>
      <div
        v-if="lastUpdateDate"
        class="u-font-size-5 u-spacing-p-m"
      >
        Last Updated: {{ formattedDate }}
      </div>
    </div>
  </div>
</template>

<script>
import OverviewObservations from '@/components/pages/insights/amazon/cva/atoms/overview-observation.vue';
import moment from 'moment';
export default {
  components: {
    OverviewObservations
  },
  props: {
    categoryLandscape: {
      type: Boolean,
      default: false
    },
    competitorLandscape: {
      type: Boolean,
      default: false
    },
    keyObservations: {
      type: Array,
      default: () => []
    },
    lastUpdateDate: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isKnowMoreClicked: false
    };
  },
  computed: {
    cardExpandedTitle() {
      return this.categoryLandscape
        ? 'Category Landscape'
        : 'Competitive Insights';
    },
    formattedDate() {
      return moment(this.lastUpdateDate).format('MMM Do YYYY');
    }
  },
  methods: {
    closeExpandedCard() {
      return this.categoryLandscape
        ? this.$emit('closeCategoryLandscape')
        : this.$emit('closeCompetativeLandscape');
    }
  }
};
</script>
<style scoped>
.overlay-positioning {
  position: absolute;
  top: calc(100% - -13px);
}
.back-on-widget {
  position: absolute;
  right: 4em;
  top: 12em;
}
.boxShadow {
  box-shadow: 0 0 4px 0 #caccce;
}
</style>
