import AmazonReviews from '@/components/pages/insights/amazon/reviews/reviews.vue';
import PriceWar from '@/components/pages/insights/amazon/pricewar/pricewar.vue';
import Market_Insight from '@/components/pages/insights/amazon/share-of-voice/page.vue';
import Content from '@/components/pages/insights/amazon/content/content.vue';
import ShareOfVoice from '@/pages/digital_shelf/share_of_voice/index.vue';
import Inventory from '@/pages/digital_shelf/inventory/inventory.vue';
import regional_availability from '@/pages/digital_shelf/regional_availability/index.vue';
import PricingAndPromotions from '@/pages/digital_shelf/pricing_and_promotions/index.vue';
import CategoryPage from '@/components/pages/insights/amazon/market-share/category-page.vue';
import PassThroughRoute from '@/components/widgets/routerView.vue';
import SellerInsights from '@/pages/digital_shelf/seller_insights/index.vue';
import MarketShareSKUPage from '@/components/pages/insights/amazon/market-share/pages/sku/page.vue';
import MarketShareOverviewPage from '@/components/pages/insights/amazon/market-share/pages/overview/page.vue';
import ContentScorecard from '@/pages/digital_shelf/content/content_landing.vue';
import CONSTANTS from '@/utils/constants';

export default {
  configCheckRequired: true,
  routes: [
    {
      title: 'Market Insights',
      path: 'market_insights/:id?',
      name: 'market_insights',
      page: 'insights',
      config: 'sov_v2',
      productRoles: ['MarketingIQ'],
      component: Market_Insight,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.RMM
      }
    },
    {
      title: 'Share of Voice',
      path: 'share_of_voice',
      name: 'Share of voice',
      page: 'insights',
      config: 'search',
      productRoles: ['SalesIQ', 'MarketingIQ'],
      component: ShareOfVoice,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.REM
      }
    },
    {
      title: 'Market Share',
      path: 'market_share',
      name: 'market_share',
      page: 'insights',
      config: 'sov_v2',
      productRoles: ['MarketingIQ'],
      component: PassThroughRoute,
      children: [
        {
          name: 'marketshareSkus',
          path: 'sku/:id?',
          component: MarketShareSKUPage,
          meta: {
            productLine: CONSTANTS.PRODUCT_LINE.MARKET_SHARE
          }
        },
        {
          path: 'overview/:id?',
          name: 'marketShareOverview',
          component: MarketShareOverviewPage,
          meta: {
            productLine: CONSTANTS.PRODUCT_LINE.MARKET_SHARE
          }
        },
        {
          name: 'category-page',
          path: 'category/:id?',
          component: CategoryPage,
          meta: {
            productLine: CONSTANTS.PRODUCT_LINE.MARKET_SHARE
          }
        },
        {
          path: '',
          redirect: 'overview/'
          // beforeEnter: (to, from) => {
          //   console.log(to, from);
          //   // reject the navigation
          //   // return false;
          // },
        }
      ]
    },
    {
      title: 'Market Share',
      path: 'market_share_lite',
      name: 'market_share_lite',
      page: 'insights',
      config: 'market_share_lite',
      productRoles: ['SalesIQ'],
      component: PassThroughRoute,
      children: [
        {
          path: 'overview/:id?',
          name: 'marketShareOverview_lite',
          component: MarketShareOverviewPage,
          meta: {
            productLine: CONSTANTS.PRODUCT_LINE.MARKET_SHARE
          }
        },
        {
          name: 'category-page-lite',
          path: 'category/:id?',
          component: CategoryPage,
          meta: {
            productLine: CONSTANTS.PRODUCT_LINE.MARKET_SHARE
          }
        },
        {
          path: '',
          redirect: 'overview/'
        }
      ]
    },
    ...[
      {
        path: 'price_war',
        name: 'Price War',
        page: 'insights',
        config: 'pricewar',
        productRoles: ['SalesIQ', 'MarketingIQ'],
        component: PriceWar,
        props: (route) => {
          return {
            to_date: route.query.to_date
          };
        }
      },
      {
        title: 'Reviews & Rating',
        path: 'reviews_and_ratings',
        name: 'Reviews & Ratings',
        page: 'insights',
        config: 'reviews',
        productRoles: ['SalesIQ', 'MarketingIQ'],
        component: AmazonReviews
      },
      {
        path: 'content_audit',
        title: 'Content Audit',
        name: 'Content',
        page: 'insights',
        config: 'content',
        productRoles: ['SalesIQ', 'MarketingIQ'],
        component: Content
      },
      {
        path: 'content_scorecard',
        title: 'Content Scorecard',
        name: 'Content Scorecard',
        page: 'insights',
        config: 'content',
        productRoles: ['SalesIQ', 'MarketingIQ'],
        component: ContentScorecard
      },
      {
        path: 'regional_availability',
        title: 'Regional Availability',
        name: 'regional_availability',
        page: 'insights',
        config: 'regional_availability',
        productRoles: ['SalesIQ', 'MarketingIQ'],
        component: regional_availability
      },
      {
        path: 'inventory',
        title: 'Inventory Availability',
        name: 'inventory_availability',
        page: 'insights',
        config: 'sov_v2',
        //             config: 'inventory_availability',
        productRoles: ['SalesIQ', 'MarketingIQ'],
        component: Inventory
      },
      {
        // keeping the path and route name 'pricing' since we already have a price_war for amazon.
        path: 'pricing',
        name: 'pricing',
        title: 'Price War',
        page: 'insights',
        config: 'sov_v2',
        productRoles: ['SalesIQ', 'MarketingIQ'],
        component: PricingAndPromotions
      },
      {
        path: 'seller_insights',
        name: 'seller_insights',
        title: 'Seller Insights',
        page: 'insights',
        productRoles: ['SalesIQ', 'MarketingIQ'],
        component: SellerInsights
      },
      {
        path: 'content',
        name: 'content',
        title: 'Content Scorecard',
        page: 'insights',
        config: 'sov_v2',
        productRoles: ['SalesIQ', 'MarketingIQ'],
        component: ContentScorecard
      }
    ].map((route) => ({
      ...route,
      meta: {
        productLine: CONSTANTS.PRODUCT_LINE.ESM
      }
    })),
    {
      path: '',
      redirect: () => {
        return 'market_insights';
      }
    }
  ]
};
