<template>
  <section
    class="campaign-creation-setup-multiple u-position-fixed widget--full-screen"
  >
    <div
      class="u-position-fixed model u-width-100 campaign-creation-setup-multiple"
    >
      <!-- Header Start -->
      <div class="">
        <section
          class="campaign-creation-setup--header u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-spacing-p-l"
        >
          <div
            class="u-display-flex u-flex-direction-column u-flex-justify-content-flex-start u-flex-1"
          >
            <div class="u-display-flex u-flex-align-items-center">
              <span @click="closeWithoutSave()">
                <rb-icon
                  :icon="'cross'"
                  class="u-color-grey-lighter rb-icon--medium u-cursor-pointer u-spacing-mr-s"
                />
              </span>
              <span
                class="u-font-size-1 u-font-weight-600"
                data-cy="campaignCreateHeader"
                >Campaign Creator
              </span>
            </div>
            <span
              class="u-font-size-4 u-color-grey-mid-light u-spacing-pt-s u-spacing-ml-l"
              data-cy="campaignCreaterSubHeading"
              >Create multiple types of campaigns
            </span>
          </div>
        </section>
      </div>
      <!-- Header ends -->
      <!-- Scroll section -->
      <div
        class="u-display-flex u-flex-justify-content-center u-flex-align-items-center u-spacing-mt-120"
      >
        <div
          v-for="(option, index) in createOptions"
          :key="option.title"
          class="create-option-box u-border-all u-border-width-s u-border-color-grey-xxx-light u-border-radius-xs u-text-align-center u-cursor-pointer"
          :class="index < createOptions.length ? 'u-spacing-m-l' : ''"
          @click="goToRoute(option.route)"
        >
          <rb-icon
            class="rb-icon--x-large u-color-blue-base"
            :icon="option.icon"
          />
          <p
            class="u-color-grey-base u-font-size-2 u-font-weight-600 u-spacing-pb-s u-spacing-pt-xl"
            :data-cy="`createCampaignType-${option.title}`"
          >
            {{ option.title }}
          </p>
          <p
            class="u-color-grey-lighter u-font-size-4 u-font-weight-400 u-line-height-1-5"
            :data-cy="`campaignTypeDescription-${option.title}`"
          >
            {{ option.description }}
          </p>
        </div>
      </div>
      <!-- Scroll section -->
      <loader
        v-show="false"
        class="fill--parent"
        :color="'#3fadf7'"
        :size="'4rem'"
        :thickness="'.2rem'"
      />
    </div>
  </section>
</template>

<script>
import config from '@/components/ams/campaign_creation/setup_configs/index.js';
import loader from '@/components/basic/loader';
import modal from '@/components/widgets/modal.vue';
export default {
  components: {
    loader,
    modal
  },
  data() {
    return {
      pageData: null,
      createOptions: [
        {
          icon: 'add-shopping-cart',
          title: 'Assisted Campaigns',
          description:
            'Choose a goal and launch bulk campaigns in just 3 steps with campaign recommendations.',
          route: 'setup_suggested_campaign'
        },
        {
          icon: 'campaign_management',
          title: 'Manual Campaigns',
          description:
            'Create your campaigns from scratch. Get complete control of your campaign inputs.',
          route: 'create_manual_campaigns'
        }
      ]
    };
  },
  computed: {
    retailer() {
      return this.$store.getters.getRetailer;
    }
  },
  created() {
    this.pageData = config?.[this.retailer];
  },
  methods: {
    closeWithoutSave() {
      this.$store.dispatch(this.pageData.action, {});
      this.$router.push({
        name: this.pageData?.closeCreateOptionsRouteName
      });
    },
    goToRoute(routeName) {
      this.$router.push({
        name: routeName
      });
    }
  }
};
</script>

<style lang="css">
.campaign-creation-setup-multiple .rb-icon--x-large:before {
  color: #007cf6;
  font-size: 80px;
}
.campaign-creation-setup-multiple {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 200;
  left: 0px;
  background-color: #ffffff;
}
.campaign-creation-setup-multiple .step-component-v2 .scrollable {
  padding-top: 0px;
}
.campaign-creation-setup-multiple
  .step-component-v2
  .marign-top-campaign-creation-bullets {
  margin-top: 160px;
  padding-left: 8px;
}
.campaign-creation-setup-multiple .campaign-creation-setup--header {
  z-index: 300;
  background-color: #ffffff;
  box-shadow: 2px 1px 8px 0 #e9eaeb;
  position: relative;
}
.campaign-creation-setup-multiple .u-spacing-mt-120 {
  margin-top: 120px;
}
.campaign-creation-setup-multiple .create-screen-height {
  height: 80%;
}
.campaign-creation-setup-multiple .create-option-box {
  height: auto;
  width: 362px;
  padding: 40px;
}
.campaign-creation-setup-multiple .create-option-box:hover {
  border-color: #007cf6;
}
.campaign-creation-setup-multiple .rb-icon--x-large {
  height: 80px;
  width: 80px;
}
</style>
