import Vue from 'vue';
import HttpLayer from '@/utils/services/http-layer';
import transformer from '@/utils/services/data-transformer';
import { cloneDeep } from 'lodash';

const state = {
  insightsContentFilterValues: {},
  contentFilters: [],
  insightsContentData: {},
  insightsContentSelectedFilter: {},
  insightsContentWhereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  whereClause: {
    dimensionNameValueList: [],
    date: {}
  },
  filtersMapping: {
    content_category: 'category',
    content_subcategory: 'subcategory',
    content_product_group: 'product_group',
    content_brand: 'mapped_brand'
  },
  page_wise_min_max_key: 'pi'
};

const getters = {
  getInsightsContentFilterValues: (state) => {
    return state.insightsContentFilterValues;
  },
  getInsightsContentSelectedFilter: (state) => {
    return state.insightsContentSelectedFilter;
  },
  getInsightsContentData: (state) => {
    return state.insightsContentData;
  },
  getContentPageWiseMinMaxKey: (state) => {
    return state.page_wise_min_max_key;
  }
};

const mutations = {
  ICONTENT_SUCCESS: (state, data) => {
    Vue.set(state.insightsContentData, data.key, {});
    if (data.xs || data.legends) {
      Vue.set(state.insightsContentData[data.key], 'data', {
        data: data.columns,
        xs: data.xs,
        legends: data.legends
      });
    } else {
      Vue.set(state.insightsContentData[data.key], 'data', data.columns);
    }
    Vue.set(state.insightsContentData[data.key], 'rows', data.rows);
    Vue.set(state.insightsContentData[data.key], 'totalRows', data.totalRows);
    Vue.set(state.insightsContentData[data.key], 'metrics', data.metrics);
    Vue.set(state.insightsContentData[data.key], 'page', data.page);
    Vue.set(state.insightsContentData[data.key], 'load', false);
    Vue.set(state.insightsContentData[data.key], 'error', false);
    if (data.columns && data.columns.length === 0) {
      Vue.set(state.insightsContentData[data.key], 'noData', true);
    }

    if (data.rows && data.rows.length === 0) {
      Vue.set(state.insightsContentData[data.key], 'noData', true);
    }
  },
  ICONTENT_RESET: (state, data) => {
    Vue.set(state.insightsContentData, data.key, {});
    Vue.set(state.insightsContentData[data.key], 'columns', []);
    Vue.set(state.insightsContentData[data.key], 'rows', []);
    Vue.set(state.insightsContentData[data.key], 'load', true);
    Vue.set(state.insightsContentData[data.key], 'error', false);
    Vue.set(state.insightsContentData[data.key], 'noData', false);
  },
  ICONTENT_ERROR: (state, data) => {
    Vue.set(state.insightsContentData, data.key, {});
    Vue.set(state.insightsContentData[data.key], 'columns', []);
    Vue.set(state.insightsContentData[data.key], 'rows', []);
    Vue.set(state.insightsContentData[data.key], 'load', false);
    Vue.set(state.insightsContentData[data.key], 'error', true);
    Vue.set(state.insightsContentData[data.key], 'noData', false);
  },
  ICONTENT_SET_SELECTED_FILTER: (state, data) => {
    Vue.set(
      state.insightsContentSelectedFilter,
      data.filterValueKey,
      data.values
    );
    getFiltersInFormat5(data, state);
  },
  SET_PO_FILTER_VALUES: (state, data) => {
    Vue.set(
      state.insightsContentFilterValues,
      data.filterValueKey,
      data.values
    );
  }
};

// function getFiltersInFormat5 (oFilter, state) {
//   var where = state.insightsContentSelectedFilter;
//   where['dimensionNameValueList'] = [];
//
//   for (var i in oFilter) {
//     if (i !== 'date_range') {
//       for (var j in oFilter[i]) {
//         if (typeof oFilter[i][j] === typeof true) {
//           where['dimensionNameValueList'].push({
//             dimensionName: state.filtersMapping[i] || i,
//             dimensionValue: j
//           });
//         } else {
//           if (oFilter[i][j].items && oFilter[i][j].name) {
//             for (var k in oFilter[i][j].items) {
//               where['dimensionNameValueList'].push({
//                 dimensionName: state.filtersMapping[oFilter[i][j].name] || oFilter[i][j].name,
//                 dimensionValue: k
//               });
//             }
//           }
//         }
//       }
//     }
//   }
//   state.insightsContentSelectedFilter = where;
// }

function getFiltersInFormat5(oFilter, state) {
  var where = state.whereClause;
  where.dimensionNameValueList = [];
  for (var i in oFilter.values) {
    if (i !== 'date_range') {
      for (var j = 0; j < oFilter.values[i].length; j++) {
        where.dimensionNameValueList.push({
          dimensionName: i,
          dimensionValue: oFilter.values[i][j]
        });
      }
    } else {
      var date = oFilter.values[i];
      var _date = {
        from: date.from,
        to: date.to,
        name: date.name,
        page_wise_min_max_key: state.page_wise_min_max_key
      };
      var _pvpDate;
      if (date.compare_from && date.compare_to) {
        _pvpDate = {
          from: date.compare_from,
          to: date.compare_to,
          compare_name: date.compare_name
        };
      }
      where.date = _date;
      where.pvpDate = _pvpDate;
    }
  }
  state.whereClause = where;
}

function getContentWhereClauseDate(context) {
  var date = context.getters.getInsightsContentSelectedFilter.date_range;
  var returnDate = {
    to: null,
    from: null
  };

  if (date) {
    returnDate = {
      to: date.to,
      from: date.from,
      name: date.name,
      page_wise_min_max_key: state.page_wise_min_max_key
    };
  } else {
    returnDate = {
      to: context.getters.getDateRangeValues.pi['Last 90 days'].to,
      from: context.getters.getDateRangeValues.pi['Last 90 days'].from,
      name: 'last90Days',
      page_wise_min_max_key: state.page_wise_min_max_key
    };
  }

  return returnDate;
}

const actions = {
  setInsightsContentSelectedFilter: (context, data) => {
    context.commit('ICONTENT_SET_SELECTED_FILTER', data);
  },
  updateContentSelectedFilter: (context, data) => {
    setTimeout(function () {
      var filters = transformer.getUniqueFilters(
        context.state.contentFilters || [],
        'content_',
        data.mapping
      );
      context.commit('SET_FILTER_DATA', filters);
    }, 1500);
  },
  downloadInContentPage: (context, data) => {
    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      cloneDeep(context.state.whereClause)
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = getContentWhereClauseDate(context);
    delete data.body.APIConfig.where.tagWhereClause;

    return HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      return transformer.mergeResultDimension(response.data, false, 'brand');
    });
  },
  getContentFilters: (context, data) => {
    HttpLayer.post({
      APIData: {
        cubeName: 'content_index_v2',
        measuresList: [],
        groupByDimensionsList: [
          'category',
          'subcategory',
          'product_group',
          'brand'
        ],
        timeseriesDimension: 'feed_date',
        where: {
          date: {},
          dimensionNameValueList: [
            {
              dimensionName: 'is_catalog_sku',
              dimensionValue: 1
            }
          ]
        },
        orderByList: [],
        getLatestAvailableInsteadOfRollup: true,
        timeseriesEnabled: false,
        pvpenabled: false,
        yoyenabled: false
      }
    }).then((response) => {
      if (!response.success) {
      } else {
        context.state.contentFilters = response.data;
        context.commit(
          'SET_FILTER_DATA',
          transformer.getUniqueFilters(response.data, 'content_')
        );
      }
    });
  },
  getContentIssuesData: (context, data) => {
    context.commit('ICONTENT_RESET', {
      key: data.meta.key
    });

    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      cloneDeep(context.state.whereClause)
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = getContentWhereClauseDate(context);
    delete data.body.APIConfig.where.tagWhereClause;

    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('ICONTENT_ERROR', { key: data.meta.key });
      } else {
        if (response.data.length > 0) {
          context.commit('ICONTENT_SUCCESS', {
            key: data.meta.key,
            metrics: data.body.getTagData
              ? data.body.getTagData(response.data[0])
              : undefined,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('ICONTENT_SUCCESS', {
            key: data.meta.key,
            metrics: {},
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },
  getSKUCountForContentIssuesData: (context, data) => {
    context.commit('ICONTENT_RESET', {
      key: data.meta.key
    });
    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      cloneDeep(context.state.whereClause)
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = getContentWhereClauseDate(context);
    delete data.body.APIConfig.where.tagWhereClause;

    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('ICONTENT_ERROR', { key: data.meta.key });
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data, true);
        if (_aArray.length > 0) {
          context.commit('ICONTENT_SUCCESS', {
            key: data.meta.key,
            rows: _aArray,
            totalRows: _aArray[0].count_total,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('ICONTENT_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  },
  getSKULevelAnalysisData: (context, data) => {
    context.commit('ICONTENT_RESET', {
      key: data.meta.key
    });

    const finalWhereClause = transformer.getCompleteWhereClause(
      data.meta.localFilters,
      cloneDeep(context.state.whereClause)
    );
    const transformedWhereClause =
      transformer.transformWhereClauseWithTag(finalWhereClause);
    data.body.APIConfig.where = transformedWhereClause;
    data.body.APIConfig.tagWhereClause =
      transformedWhereClause.tagWhereClause || [];
    data.body.APIConfig.where.date = getContentWhereClauseDate(context);
    delete data.body.APIConfig.where.tagWhereClause;

    HttpLayer.post({
      APIData: data.body.APIConfig
    }).then((response) => {
      if (!response.success) {
        context.commit('ICONTENT_ERROR', { key: data.meta.key });
      } else {
        var _aArray = [];
        _aArray = transformer.mergeResultDimension(response.data, true);
        if (_aArray.length > 0) {
          context.commit('ICONTENT_SUCCESS', {
            key: data.meta.key,
            rows: _aArray,
            totalRows: _aArray[0].count,
            page: data.body.APIConfig.page
          });
        } else {
          context.commit('ICONTENT_SUCCESS', {
            key: data.meta.key,
            rows: [],
            page: data.body.APIConfig.page
          });
        }
      }
    });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
