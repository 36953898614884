<template>
  <div
    class="u-display-flex u-flex-align-items-center u-flex-justify-content-center"
  >
    <chart
      :config="chartConfig"
      :data="chartData"
    />
  </div>
</template>

<script>
import chart from '@/components/basic/chart.vue';
export default {
  components: {
    chart
  },
  props: {
    percentage: {
      type: Number,
      default: 0
    }
  },
  computed: {
    chartConfig() {
      return {
        interaction: { enabled: false },
        chartOptions: {
          legend: false,
          type: 'donut',
          tooltip_format: { roundOff: 0 },
          data: {
            colors: this.getColors()
          },
          size: { height: 80, width: 80 }
        },
        donut: {
          expand: true,
          label: { show: false, position: 'right' },
          width: 8,
          title: `${this.percentage}%`
        },
        xAxisType: 'sales'
      };
    },
    chartData() {
      return [
        ['percentage', this.percentage],
        ['restPercentage', 100 - this.percentage]
      ];
    }
  },
  methods: {
    getColors() {
      if (this.percentage > 0 && this.percentage <= 65) {
        return { percentage: '#D7263D', restPercentage: '#FFD2D8' };
      } else if (this.percentage > 65 && this.percentage <= 80) {
        return { percentage: '#FFA800', restPercentage: '#FFF8D5' };
      } else {
        return { percentage: '#3EA95E', restPercentage: '#DDFFE7' };
      }
    }
  }
};
</script>
